import { DateTime } from "luxon";
import * as microsoftTeams from "@microsoft/teams-js";
import { IPersonaSharedProps } from "@fluentui/react";
import { SelectionEntry } from "../docLab/features/archiveContent";
import _ from "lodash";
import { ArchiveRoleId, ArchiveRoleIdWeight } from "../docLab/models/constants";
import teamsAuthService from "../modules/authentication/teamsAuthService";

type Key = string | number;

const oneGigabyte = 1073741824;
const oneMegabyte = 1048576;
const oneKilobyte = 1024;

class HelpersClass {
    normalize<TKey extends Key, TItem>(array: TItem[], keySelector: (item: TItem) => TKey) {
        return array.reduce((acc, curr) => { acc[keySelector(curr)] = curr; return acc }, {} as Record<TKey, TItem>);
    }

    normalizeMapped<TKey extends Key, TItem, TValue>(array: TItem[], keySelector: (item: TItem) => TKey, valueSelector: (item: TItem) => TValue) {
        return array.reduce((acc, curr) => { acc[keySelector(curr)] = valueSelector(curr); return acc }, {} as Record<TKey, TValue>);
    }

    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async deferred<T>(promise: Promise<T>, setter: React.Dispatch<React.SetStateAction<boolean>>, delay: number, minDuration: number) {
        let deferred = false;
        const handler = setTimeout(async () => {
            deferred = true;

            setter(true);
            await Promise.all([promise, Helpers.delay(minDuration)]);
            setter(false);
        }, delay);

        await promise;
        if (!deferred)
            clearTimeout(handler);
    }

    formatSize(size: number) {
        let formatted = size / oneGigabyte;
        let unit = "GB";

        if (formatted < 0.5) {
            formatted = size / oneMegabyte;
            unit = "MB";
        }

        if (formatted < 0.5) {
            formatted = size / oneKilobyte;
            unit = "KB";
        }

        return `${formatted.toFixed(2)} ${unit}`;
    }

    isSizeInMbSmallerThan(size: number, maxSizeMb: number) {
        const formatted = size / oneMegabyte;
        if (formatted >= maxSizeMb)
            return false;
        return true;
    }

    capitalize = (str: string): string => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    /*eslint-disable @typescript-eslint/no-explicit-any*/
    keysLowerCase = (obj: any) => {
        const newProperties: any = {};

        for (const [key, value] of Object.entries(obj)) {
            newProperties[key.toLowerCase()] = value;
        }
        return newProperties;
    }

    getFileExtension(filename: string) {
        if (!filename) return "";

        const extIndex = filename.lastIndexOf('.');
        if (extIndex === -1)
            return "";

        return filename.substring(extIndex);
    }

    getPreviewFile(fileId?: number, path?: string) {
        const url = window.location.origin + "/preview/" + (path ? ('/' + encodeURIComponent(path)) : fileId);
        if (!teamsAuthService.currentUserPrincipalName) {
            const strWindowFeatures = "location=yes,height=800,width=900,scrollbars=yes,status=yes";
            window.open(url, "_blank", strWindowFeatures);
        }
        else
            return microsoftTeams.authentication.authenticate({
                url: url,
                width: 1000,
                height: 800,
            });
    }

    getKnowledgeLabPreviewFile(id: number, type?: string) {
        const url = window.location.origin + `/preview/${type ?? type + "/"}/${id}`;
        if (!teamsAuthService.currentUserPrincipalName) {
            const strWindowFeatures = "location=yes,height=800,width=900,scrollbars=yes,status=yes";
            window.open(url, "_blank", strWindowFeatures);
        }
        else
            return microsoftTeams.authentication.authenticate({
                url: url,
                width: 1000,
                height: 800,
            });
    }

    getFilename(filename: string) {
        if (!filename) return "";

        const extIndex = filename.lastIndexOf('.');
        if (extIndex === -1)
            return filename;

        return filename.substring(0, extIndex);
    }

    formatToRelativeDate = (value: string) => {
        if (value) {
            let date = DateTime.fromISO(value, { zone: 'utc' }).toLocal();
            if (date > DateTime.now()) {
                date = DateTime.now().minus({
                    seconds: 1
                });
            }
            const totalMinutes = date.diffNow().negate().as('minutes');

            if (totalMinutes < 60 * 48)
                return date.toRelative();

            return date.toLocaleString(DateTime.DATETIME_SHORT);

        }
        else return value
    }

    getColorWithOpacity(color: string, opacity: number) {
        const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return color + _opacity.toString(16).toUpperCase();
    }

    getMaxScaleForDashBoard(value: number[]) {
        return Math.max(...value, 0) + Math.ceil(Math.max(...value, 0) * 0.1);
    }

    getShortDate(value: Date) {
        return DateTime.fromISO(value.toString(), { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATE_SHORT);
    }
    mapStringToPersona = (user: string): IPersonaSharedProps | undefined => {
        return {
            text: `${user}`,
            secondaryText: `${user}`,
            imageInitials: `${user[0].toUpperCase() || ""}${user.split(' ')[1][0].toUpperCase() || ""}`
        }
    }

    isNumber(property?: string) {
        return Number.isInteger(parseFloat(property || ""));
    }

    retrieveMinArchiveRoleId = (selectedItems: SelectionEntry[]) => {
        const tempItems = [...selectedItems];
        const archiveRoleIdWeight = _.map(tempItems, function (obj) {
            return _.assign(obj, _.find(ArchiveRoleIdWeight, { archiveRoleId: obj.archiveRoleId }));
        });
        return _.orderBy(archiveRoleIdWeight, ['weight'], ['asc'])[0].archiveRoleId;
    }

    convertToMB = (size: number): number => {
        return size / oneMegabyte;
    }

    retrieveArchiveRoleName = (archiveRoleId: ArchiveRoleId) => {
        const archivesRole = {
            0: "Owner",
            1: "Contributor",
            2: "Reader",
            3: "Architect",
            4: "Professional",
            5: "Uploader"
        }

        return archivesRole[archiveRoleId];
    }
}

export const Helpers = new HelpersClass();