import { useCallback, useMemo } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { KnowledgeDsRoutes, KnowledgeDsTab } from "../routes/routes";

export interface INavigator {
    isKnowledgeDsRoute: boolean;
    currentTab?: KnowledgeDsTab;
    changeTab: (tab?: KnowledgeDsTab) => void
    goToPath: (path: string) => void;
    goBack: () => void;
}

export const useNavigator = (): INavigator => {
    const location = useLocation();
    const history = useHistory();
    const routePath = useMemo(() =>
        matchPath<{ tab: KnowledgeDsTab }>(location.pathname, { path: KnowledgeDsRoutes.knowledgeLabOptionals }),
        [location.pathname]);

    const isKnowledgeDsRoute = useMemo(() => routePath !== null, [routePath]);

    const currentTab = useMemo(() => routePath?.params.tab ?? KnowledgeDsTab.feedback, [routePath]);

    const changeTab = useCallback((tab?: KnowledgeDsTab) => {
        history.push(KnowledgeDsRoutes.generateKnowledgeDsTabPath(tab ?? KnowledgeDsTab.feedback));
    }, [history]);

    const goToPath = useCallback((path: string) => history.push(path), [history]);

    const goBack = useCallback(() => history.goBack(), [history]);

    return {
        isKnowledgeDsRoute: isKnowledgeDsRoute,
        currentTab: currentTab,
        changeTab: changeTab,
        goToPath: goToPath,
        goBack: goBack
    }
}