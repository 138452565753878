import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import datasetList from './features/datasetList';
import feedbackList from './features/feedbackList';
const dashboardStore = configureStore({
    reducer: {
        feedbackList: feedbackList,
        datasetList: datasetList
    }

});
export type DashboardState = ReturnType<typeof dashboardStore.getState>;
export type DashboardDispatch = typeof dashboardStore.dispatch;
export default dashboardStore;
export const useDashboardState = (): DashboardState => dashboardStore.getState();
export const useDashboardDispatch = (): DashboardDispatch => useDispatch<DashboardDispatch>();
export const useDashboardSelector: TypedUseSelectorHook<DashboardState> = useSelector;