import { styled } from "@fluentui/react";
import { EditProfileModalBase } from "./editProfileModal.base";
import { getStyles } from "./editProfileModal.styles";
import { IEditProfileModalBaseProps, IEditProfileModalBasePropsStyles, IEditProfileModalBaseStyles } from "./editProfileModal.types";

const EditProfileModal = styled<IEditProfileModalBaseProps, IEditProfileModalBasePropsStyles, IEditProfileModalBaseStyles>(
    EditProfileModalBase, 
    getStyles,
    undefined,
    { scope: 'EditProfileModal'}
)

export default EditProfileModal;