import { styled } from "@fluentui/react";
import { getStyles } from "./authorizeRole.styles";
import { IAuthorizeRolePropsStyles, IAuthorizeRoleStyles, IAuthorizeRoleProps } from "./authorizeRole.types";
import { AuthorizeRoleBase } from "./authorizeRole.base";

const AuthorizeRole = styled<IAuthorizeRoleProps, IAuthorizeRolePropsStyles, IAuthorizeRoleStyles>(
    AuthorizeRoleBase,
    getStyles,
    undefined,
    { scope: 'AuthorizeRole' }
);

export default AuthorizeRole;