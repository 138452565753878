import { styled } from "@fluentui/react";
import { getStyles } from "./datasets.styles";
import { IDatasetsPropsStyles, IDatasetsStyles, IDatasetsProps } from "./datasets.types";
import { DatasetsBase } from "./datasets.base";

const Datasets = styled<IDatasetsProps, IDatasetsPropsStyles, IDatasetsStyles>(
    DatasetsBase,
    getStyles,
    undefined,
    { scope: 'Datasets' }
);

export default Datasets;