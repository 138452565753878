import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner"
import { DefaultButton, mergeStyleSets, PrimaryButton } from "@fluentui/react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage"
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types"
import { Helpers } from "../../../../../utilities/helpers"
import { useOnMount } from "../../../../../utilities/hooks"
import { useActivityLabDispatch } from "../../../../activityLabStore"
import { loadPortfolioList } from "../../../../features/portfolioList"
import { portfolioApi } from "../../../../services/portfolio/portfolio.api"
import { CreatePortfolioRequest } from "../../../../services/portfolio/portfolio.contracts"

export type CreatingPortfolioPageData = {
    name?: string;
}

export interface ICreateAPortfolioPageProps {
    initialState: CreatingPortfolioPageData;
    onClose?: () => void;
}

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '> :not(:last-child)': {
            marginBottom: 20
        }
    },
    label: {
        fontSize: 24,
        fontWeight: 600,
        textAlign: 'center'
    },
    errorButtons: {
        display: 'flex',
        justifyContent: 'space-around',
        alignSelf: 'stretch'
    }
})

export const CreatingPortfolioPageResult = (props: ICreateAPortfolioPageProps) => {
    const [success, setSuccess] = useState<boolean | undefined>(undefined);
    const [creating, setCreating] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const dispatch = useActivityLabDispatch();
    const { t } = useTranslation(['createPortfolioModal', 'common']);

    useOnMount(() => void createPortfolio());
    useEffect(() => { success && dispatch(loadPortfolioList()) }, [dispatch, success]);

    const createPortfolio = async () => {
        if (success)
            return;

        setCreating(true);
        setSuccess(undefined);

        const data = props.initialState;

        const request: CreatePortfolioRequest = {
            name: data.name ?? ""
        }

        try { await Promise.all([portfolioApi.createPortfolio(request), Helpers.delay(500)]); setSuccess(true); }
        catch { setErrorCount(errorCount + 1); setSuccess(false); }
        finally { setCreating(false); }
    }

    return (
        <div className={classNames.container}>
            {creating && !success &&
                <>
                    <TeamsSpinner />
                    <span className={classNames.label}>{t('creatingPortfolio')}</span>
                </>
            }
            {!creating && success &&
                <>
                    <TeamsImage imageName={ImageName.WellDone} style={{ width: '80%' }} />
                    <span className={classNames.label}>{t('common:niceWork')}</span>
                    <PrimaryButton text={t('common:leave')} onClick={props.onClose} style={{ flex: '0 0 auto' }} />
                </>
            }
            {!creating && success === false &&
                <>
                    <div className={classNames.label}>{t('common:genericErrorApi')}{t('ofArchive')}</div>
                    <div className={classNames.errorButtons}>
                        {errorCount < 3 && <DefaultButton text={t('common:tryAgain')} onClick={createPortfolio} />}
                        <PrimaryButton text={t('common:leave')} onClick={props.onClose} />
                    </div>
                </>
            }
        </div>
    )
}