/*eslint-disable sonarjs/cognitive-complexity */
import { classNamesFunction, IColumn, Icon, PrimaryButton, SelectionMode, ShimmeredDetailsList, TooltipHost } from "@fluentui/react";
import { ConstrainMode, Selection } from '@fluentui/react/lib/DetailsList';
import { nanoid } from "@reduxjs/toolkit";
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import FileIconCell from "../../../../common/components/fileIconCell/fileIconCell";
import { Section } from "../../../../common/components/tagPanel/tagPanel.types";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { Helpers } from "../../../../utilities/helpers";
import { useOnMount } from "../../../../utilities/hooks";
import { useDocLabDispatch } from "../../../docLabStore";
import { loadFolderContent, selectItems, setCurrentArchive, setIsDeeplink, showTagPanel, useArchiveContent } from "../../../features/archiveContent";
import { insertCall, setCall } from "../../../features/callNotification";
import { resetAllSearchResults } from "../../../features/searchContent";
import { setToolbarActions, ToolbarAction } from "../../../features/toolbarActions";
import { ToolbarDelegateType } from "../../../hooks/useActionDelegate";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { ArchiveItem, FileApprovalStatus, IndexingStatus } from "../../../models/archiveItem";
import { Call, callsList } from "../../../models/callsApi";
import { ArchiveRoleId, ArchiveStatusFilesApproval } from "../../../models/constants";
import { ActionEntry, getArchiveContentActionsByIds } from "../../../utilities/archiveContentActions";
import { FilesBreadcrumb } from "./archiveContent.breadcrumb";
import { IArchiveContentProps, IArchiveContentPropsStyles, IArchiveContentStyles } from "./archiveContent.types";
import FileNameCell from "./fileNameCell/fileNameCell";
import { JsonSchemaField } from "../../../models/archive";
import { MetadataSelect } from "../../../models/metadataDependency";
import { archivesApi } from "../../../services/archives/archives.api";
import { DisplayedFileMetadata } from "../../../../activityLab/models/fileShort";

const getClassNames = classNamesFunction<IArchiveContentPropsStyles, IArchiveContentStyles>();

const getModifiedDate = (item: ArchiveItem) => {
    return Helpers.formatToRelativeDate(item.createdOn);
}

export const ArchiveContentBase = (props: IArchiveContentProps) => {
    const { items, isLoading, forbiddenErrorOnDeeplink, isError, selectedItems, orderBy, isAscending, isDeeplink, selectedItemsActionIds, noMore, currentPageNumber, archive, breadcrumb, currentFolderMetadataJsonSchemaFields } = useArchiveContent();
    const { t } = useTranslation(['archiveContent', 'archiveContentActions', 'common']);
    const dispatch = useDocLabDispatch();
    const currentArchive = useCurrentArchive();
    const [lastFolder, setLastFolder] = useState(-1);
    const [openedPreviewDocument, setOpenedPreviewDocument] = useState(isDeeplink);
    const [deferredLoading, setDeferredLoading] = useState(false);
    const [metadataList, setMetadataList] = useState<MetadataSelect[]>([]);
    const [displayedFilesMetadata, setDisplayedFilesMetadata] = useState<DisplayedFileMetadata[]>([]);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, isEmptyList: items.length === 0 });
    const readonly = useMemo(() => currentArchive?.currentUserRoleId === ArchiveRoleId.Reader, [currentArchive]);

    useOnMount(() => {  
        dispatch(loadFolderContent({ resetPage: true }));
        registerEvent();
    });

    useEffect(() => {       
        setMetadataFieldList(); 
    }, [currentFolderMetadataJsonSchemaFields])  //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (breadcrumb && breadcrumb.length > 0 && isDeeplink) {
            setLastFolder(breadcrumb[breadcrumb.length - 1].id)
        }
    }, [breadcrumb, dispatch, isDeeplink])

    useEffect(() => {
        if (forbiddenErrorOnDeeplink) {
            const id = nanoid();
            const forbiddenLink: Call = {
                type: callsList.forbiddenLink,
                nameOperation: t('common:archiveLinkForbiddenOperation'),
            }
            const payload = { requestId: id, notification: forbiddenLink }
            dispatch(insertCall(payload));
            const failurePayload = { requestId: id, success: false, message: isError && forbiddenErrorOnDeeplink ? t('common:archiveLinkForbiddenMessage') : t('common:archiveForbidden') }
            dispatch(setCall(failurePayload));
        }
    }, [forbiddenErrorOnDeeplink, t, dispatch, isError])

    useEffect(() => {
        if (props.isDeepLinkfolder === undefined || props.fileId === undefined || lastFolder === -1)
            return;
        if (isDeeplink) {
            const action = dispatch(loadFolderContent({
                folderId: lastFolder,
                orderBy: "Name",
                isAscending: true,
                resetPage: true,
                deeplink: true
            }));
            Helpers.deferred(action, setDeferredLoading, 200, 250);
            if (!props.isDeepLinkfolder && props.fileId !== undefined && !isNaN(props.fileId) && openedPreviewDocument) {
                openPreview(props.fileId)
            }
        }
    }, [lastFolder, props.isDeepLinkfolder, dispatch, props.fileId, isDeeplink, openedPreviewDocument])
        
    useEffect(() => {
        if (archive?.id === currentArchive?.id)
            return

        dispatch(setCurrentArchive({archive: currentArchive}));
        dispatch(resetAllSearchResults());
        if (!currentArchive)
            return;

        const action = dispatch(loadFolderContent({
            folderId: currentArchive.rootFolderId,
            orderBy: "Name",
            isAscending: true,
            resetPage: true,
            deeplink: false
        }));
        Helpers.deferred(action, setDeferredLoading, 100, 550);
    }, [currentArchive, archive, dispatch]); //eslint-disable-line react-hooks/exhaustive-deps

    const mapToolbarActions = useCallback((actions: ActionEntry[], t: TFunction<string[]>): ToolbarAction[] => {
        const filteredToolbarActions = actions.filter(action => (action.toBeIndexedCheck && selectedItems[0].indexed) || !action.toBeIndexedCheck);
        return filteredToolbarActions.map(action => {
            let subActions = undefined;
            if (action.subActionItems)
                subActions = mapToolbarActions(action.subActionItems, t);

            return {
                id: action.id,
                type: ToolbarDelegateType.files,
                label: action.label ? t(`archiveContentActions:${action.label}`) : "",
                icon: action.icon,
                subActions: subActions
            }
        });
    }, [selectedItems]);

    useEffect(() => {
        const actions = mapToolbarActions(getArchiveContentActionsByIds(selectedItemsActionIds), t);
        dispatch(setToolbarActions(actions));
    }, [selectedItemsActionIds, dispatch, t, mapToolbarActions]);

    const registerEvent = useCallback(() => {
        const listElm = document.querySelector('#detailsListZone .ms-DetailsList-contentWrapper');
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50) {
                const button = document.getElementById("click");
                button && button.click();
            }
        });
    }, []);

    const loadMore = useCallback(() => {
        if (isLoading || noMore)
            return;

        const action = dispatch(loadFolderContent({ resetPage: false }));
        Helpers.deferred(action, setDeferredLoading, 200, 250);
    }, [dispatch, isLoading, noMore]);

    const changeFolder = useCallback((folderId: number) => {
        const action = dispatch(loadFolderContent({
            folderId: folderId,
            resetPage: true,
        }));
        Helpers.deferred(action, setDeferredLoading, 200, 250);
        dispatch(setIsDeeplink(false));
    }, [dispatch]);

    const openPreview = (fileId: number) => {
        Helpers.getPreviewFile(fileId);
        setOpenedPreviewDocument(false);
    }

    const setMetadataFieldList = async () => { 
        if(!currentArchive?.id)
            return

        if(currentArchive?.displayedMetadataKey.length > 0 && currentFolderMetadataJsonSchemaFields !== null && currentFolderMetadataJsonSchemaFields !== undefined){
            const list: MetadataSelect[] = [];
            currentFolderMetadataJsonSchemaFields.forEach((field: JsonSchemaField) => {
                if(currentArchive.displayedMetadataKey.includes(field.name))
                    list.push({
                        metadata: field.name,
                        title: field.title
                    })
            });
            setMetadataList([...list]);
        }
        else{
            setMetadataList([]);
            setDisplayedFilesMetadata([]);
        }

        try{
            const displyedFileMetadata = await archivesApi.getDisplayedFilesMetadata(currentArchive?.id, currentArchive?.displayedMetadataKey);
            setDisplayedFilesMetadata(displyedFileMetadata);
        }catch{
            console.log("ERROR");
        }
    }

    const sortByColumn = useCallback((_: React.MouseEvent, column: IColumn) => {
        if (isLoading)
            return;

        const action = dispatch(loadFolderContent({
            orderBy: column.fieldName,
            isAscending: column.isSortedDescending,
            resetPage: true
        }));
        dispatch(setIsDeeplink(false));
        Helpers.deferred(action, setDeferredLoading, 200, 250);
    }, [isLoading, dispatch]);

    const retriveMetadataValue = (item: ArchiveItem, metadata: string) => {
        if(item.isFolder) 
            return null

        const fileMetadata = displayedFilesMetadata.find(file => file.id === item.id);
        
        if (fileMetadata) {
            const metadataItem = fileMetadata.displayedMetadata.find(m => m.key === metadata);    
            if (metadataItem)
                return metadataItem.value;
        }
        return null;
    }

    const columns: IColumn[] = useMemo(() => {
        const archiveMetadataRequired: boolean = currentArchive?.metadataJsonSchema !== undefined && currentArchive.metadataRequired;
        let columnsList: IColumn[] = [
            {
                key: 'file-type',
                name: 'File Type',
                iconName: 'Page',
                isIconOnly: true,
                fieldName: '',
                minWidth: 20,
                maxWidth: 20,
                styles: {
                    cellTitle: {
                        placeContent: 'center'
                    }
                },
                onRender: function getItemCell(item: ArchiveItem) {
                    const extIndex = item.name.lastIndexOf('.');
                    const ext = extIndex !== -1 ? item.name.substring(extIndex) : "";
                    return <FileIconCell fileExtension={ext} isFolder={item.isFolder} />
                }
            },
            {
                key: 'Name',
                name: t("columns.name"),
                fieldName: 'Name',
                minWidth: 190,
                maxWidth: 300,
                isSorted: orderBy === 'Name',
                isSortedDescending: !isAscending,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: sortByColumn,
                onRender: function getItemName(item: ArchiveItem) {
                    const totalHours = DateTime.fromISO(item.createdOn, { zone: 'utc' })
                        .diffNow().negate().as('hours');

                    return <FileNameCell
                        name={item.name}
                        isNewItem={totalHours < 24}
                        onClick={() => item.isFolder ? changeFolder(item.id) : openPreview(item.id)}
                    />
                }
            },
            {
                key: 'metadata',
                name: 'Metadata',
                iconName: '',
                isIconOnly: true,
                fieldName: '',
                minWidth: 20,
                maxWidth: 20,
                onRender: function getMetadata(item: ArchiveItem) {
                    return archiveMetadataRequired && !readonly &&
                        (item.metadataSavedOn === undefined ||
                            item.metadataSavedOn === null) &&
                        item.indexingStatus === IndexingStatus.Indexed &&
                        <TooltipHost content={t('metadataWarning')}>
                            <Icon
                                onClick={(ev) => { dispatch(showTagPanel({ isOpen: true, defaultSection: Section.metadata })); ev.preventDefault(); ev.stopPropagation() }}
                                iconName="AlertSolid"
                                styles={classNames.subComponentStyles.metadataRequiredIcon} />
                        </TooltipHost>
                }
            },
            {
                key: 'CreatedOn',
                name: t("columns.createdon"),
                fieldName: 'CreatedOn',
                minWidth: 130,
                maxWidth: 130,
                isResizable: true,
                isSorted: orderBy === 'CreatedOn',
                isSortedDescending: !isAscending,
                onColumnClick: sortByColumn,
                onRender: getModifiedDate,
            },
            {
                key: 'CreatedBy',
                name: t("columns.createdby"),
                fieldName: 'CreatedBy',
                minWidth: 90,
                maxWidth: 120,
                isResizable: true,
                isSorted: orderBy === 'CreatedBy',
                isSortedDescending: !isAscending,
                onColumnClick: sortByColumn,
                onRender: function getCreatedBy(item: ArchiveItem) {
                    return <span>{item.createdBy}</span>;
                },
            },
            {
                key: 'IndexingStatus',
                name: t("columns.indexStatus"),
                fieldName: 'IndexingStatus',
                minWidth: 75,
                maxWidth: 95,
                isResizable: true,
                isSorted: orderBy === 'IndexingStatus',
                isSortedDescending: !isAscending,
                onColumnClick: sortByColumn,
                onRender: function getStatus(item: ArchiveItem) {
                    return <span>{t("indexStatus." + IndexingStatus[item.indexingStatus])}</span>;
                },
            }
        ];
        if (!archiveMetadataRequired || readonly)
            columnsList = columnsList.filter(column => column.key !== "metadata");

        return columnsList;
    }, [sortByColumn, readonly, isAscending, classNames.subComponentStyles.metadataRequiredIcon, dispatch, currentArchive?.metadataJsonSchema, currentArchive?.metadataRequired, orderBy, t, changeFolder]);

    /*eslint-disable  sonarjs/no-duplicate-string */
    const approvalStatusColumns: IColumn[] = [ 
        {
            key: 'ApprovalStatus',
            name: t("columns.approvalStatus"),
            fieldName: 'ApprovalStatus',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isSorted: orderBy === 'ApprovalStatus',
            isSortedDescending: !isAscending,
            onColumnClick: sortByColumn,
            onRender: function getStatus(item: ArchiveItem) {
                return <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <span style={{ marginRight: 10 }}>
                        {item.approvalStatus !== null && item.approvalStatus !== undefined ? t("approvalStatus." + FileApprovalStatus[item.approvalStatus]) : null}
                    </span>
                    {item.rejectionNote &&
                    <TooltipHost
                        style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'end'}}
                        content={<span>{item.rejectionNote}</span>}
                    >
                        <Icon styles={{ root: { cursor: 'pointer' } }} iconName="Info"/>
                    </TooltipHost>}
                </div>
            }
        },
        {
            key: 'AlreadyApproved',
            name: t("columns.alreadyApproved"),
            fieldName: 'AlreadyApproved',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isSorted: orderBy === 'AlreadyApproved',
            isSortedDescending: !isAscending,
            onColumnClick: sortByColumn,
            onRender: function getStatus(item: ArchiveItem) {
                return <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <span style={{ marginRight: 10 }}>
                        {item.alreadyApproved !== null && item.alreadyApproved !== undefined ?  t(`search:filters.alreadyApproved.${item.alreadyApproved}`) : null}
                    </span>
                </div>
            }
        }
    ];

    const metadataColumns: IColumn[] =  useMemo(() => {
        const columnsList: IColumn[] = []
        metadataList.forEach((metadataSelect: MetadataSelect) => {
            columnsList.push({
                key: metadataSelect.metadata,
                name: metadataSelect.title,
                fieldName: metadataSelect.metadata,
                minWidth: 80, 
                maxWidth: 80, 
                isResizable: true,
                onRender: function getMetadataColumn(item: ArchiveItem) {
                    return (
                        <>
                            <span>{retriveMetadataValue(item, metadataSelect.metadata)}</span>
                        </>
                    );
                },
            });
        });
        return columnsList;
    },[metadataList, displayedFilesMetadata]); //eslint-disable-line react-hooks/exhaustive-deps

    const selection = useMemo(() => new Selection({
        onSelectionChanged: () => {
            const selectionDetails = selection.getSelection();
            dispatch(selectItems(selectionDetails.map(i => {
                const item = i as ArchiveItem;
                return {
                    id: item.id,
                    name: item.name,
                    isFolder: item.isFolder,
                    indexed: !item.isFolder && item.indexingStatus === IndexingStatus.Indexed, 
                    archiveRoleId: item.archiveRoleId,
                    sourceType: item.sourceType,
                    approvalStatus: item.approvalStatus,
                    alreadyApproved: item.alreadyApproved
                }
            })));
        }
    }), [dispatch]);

    useEffect(() => {
        if (selectedItems.length === 0) {
            selection.setAllSelected(false);
        }
    }, [selectedItems, selection])

    useEffect(() => {
        if (!isLoading) registerEvent()
    }, [isLoading]); //eslint-disable-line react-hooks/exhaustive-deps

    const emptyFolder = useCallback(() => {
        if (items.length !== 0)
            return null;

        return (
            <div className={classNames.emptyFolder}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyFolder")}
                />
            </div>
        )
    }, [classNames.emptyFolder, items.length, t]);

    if (!currentArchive)
        return null;

    return (
        <div className={classNames.root} id={"detailsListZone"}>
            {isError && !forbiddenErrorOnDeeplink &&
                <TeamsImage
                    imageName={ImageName.Error1}
                    scale={0.3}
                    fullContainer
                    caption={t("errorLoading")}
                />
            }
            {isError && forbiddenErrorOnDeeplink &&
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <TeamsImage
                        styles={{
                            img: {
                                width: 'auto'
                            }
                        }}
                        imageName={ImageName.Forbidden}
                        caption={t("common:archiveLinkForbiddenMessage")}
                    />
                    <PrimaryButton styles={{
                        root: {
                            marginTop: '10px'
                        }
                    }} text={t("common:retryFolder")} onClick={
                        () => { changeFolder(currentArchive.rootFolderId) }
                    } />
                </div>
            }
            {!isError &&
                <>
                    <FilesBreadcrumb fileId={props.fileId} isDeepLinkfolder={props.isDeepLinkfolder} />
                    <ShimmeredDetailsList
                        items={items}
                        columns={currentArchive.filesApproval === ArchiveStatusFilesApproval.Enabled ? columns.concat(approvalStatusColumns, metadataColumns) : columns.concat(metadataColumns)}
                        getKey={(item: ArchiveItem) => item && `${item.isFolder ? 'f' : ''}${item.id}`}
                        selection={selection}
                        selectionMode={SelectionMode.multiple}
                        enableShimmer={currentPageNumber === 0 && (deferredLoading || isLoading)}
                        constrainMode={ConstrainMode.unconstrained}
                        styles={classNames.subComponentStyles.shimmeredDetailsList}
                        detailsListStyles={classNames.subComponentStyles.detailsList}
                        onRenderDetailsFooter={emptyFolder}
                    />
                    <div className={classNames.load}>
                        <PrimaryButton id={noMore ? "noClick" : "click"} onClick={loadMore} />
                    </div>
                </>
            }
        </div>
    );
}
