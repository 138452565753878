export type KnowledgeFeedback = {
    id: number,
    knowledgeLabId: number,
    knowledgeLabName: string,
    fileId?: number,
    fileName: string,
    entityId?: number,
    creatorName: string,
    creatorEmail: string,
    approverName: string,
    approverEmail: string,
    skillName: string,
    metadataName: string,
    oldValue: string,
    newValue: string,
    createdOn: string,
    updatedOn?: string,
    feedbackStatus: FeedbackStatus
}

export enum FeedbackStatus {
    Pending = 0,
    Approved = 1,
    Rejected = 2,
    DatasetCreated = 3,
}

export const knowledgeDsStatusText = [
    { key: FeedbackStatus.Pending, value: "status.pending" },
    { key: FeedbackStatus.Approved, value: "status.approved" },
    { key: FeedbackStatus.Rejected, value: "status.rejected" },
    { key: FeedbackStatus.DatasetCreated, value: "status.datasetCreated" },
]

