import { DefaultButton, PrimaryButton } from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AddMembersPage,
  MembersPageData,
} from "../../../../common/components/modalPages/addMembersPage";
import { AddMembersPageType } from "../../../../utilities/constants";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { IUser } from "../../../../common/interfaces/IUser";
import { useCurrentKnowledge } from "../../../hooks/useCurrentKnowledge";
import { IMembersModalProps } from "./knowledgeLabMembersModal.types";
import { KnowledgeLabRoleId } from "../../../models/knowledgeLabRoleId";
import { knowledgeLabApi } from "../../../services/knowledgeLab.api";
import { KnowledgeLabMembership } from "../../../models/knowledgeLabMembership";

export const ArchiveMembersModalBase = (props: IMembersModalProps) => {
  const { t } = useTranslation(["addMembersToArchive", "common"]);
  const currentKnowledge = useCurrentKnowledge();
  const [data, setData] = useState<MembersPageData>({
    isValid: false,
    selectedPeople: [],
  });
  const [addMembersBtnDisabled, setAddMembersBtnDisabled] = useState<boolean>(false);

  const addMembers = async () => {
    if (!currentKnowledge) return;
    setAddMembersBtnDisabled(true);

    const memberships: KnowledgeLabMembership[] = data.selectedPeople.map(
      (member) => ({
        userId: member.id,
        knowledgeLabRoleId: member.roleId as KnowledgeLabRoleId ?? KnowledgeLabRoleId.Reader,
      })
    );

    try {
      await knowledgeLabApi.addMembers(currentKnowledge.id, memberships);
      props.operationEnded(true);
    } catch (error) {
      console.log(error); //TODO GESTIRE
      props.operationEnded(false);
    }
  };

  const footer = (
    <>
      <DefaultButton
        text={t("common:cancel")}
        style={{ marginRight: "2px" }}
        onClick={() => props.operationEnded(true)}
      />
      <PrimaryButton
        text={t("common:continue")}
        onClick={addMembers}
        disabled={data.selectedPeople.length === 0 || addMembersBtnDisabled}
      />
    </>
  );

  const getUsers = async (keyword: string, currentUsers: IUser[]) => {
    if (currentKnowledge && keyword.length > 2) {
      return await knowledgeLabApi.getKnowledgeLabAvailableMembers({
        knowledgeLabId: currentKnowledge.id,
        pageNumber: 0,
        pageSize: 20,
        keyword: keyword,
        userIds: currentUsers.map((x) => x.id),
      });
    }
    return [];
  };

  return (
    <EdiModal
      title={`${t("updateMembership")}  ${currentKnowledge?.name}`}
      width={800}
      height={600}
      isOpen={props.isOpen}
      showCloseIcon={true}
      onCloseClick={() => props.operationEnded(true)}
      body={
        <AddMembersPage
          onDataChange={setData}
          initialState={{ selectedPeople: data.selectedPeople, isValid: true }}
          translationNamespace="membersPageForAddKnowledgeLabMembers"
          getUsers={(keyword: string) => getUsers(keyword, data.selectedPeople)}
          modalType={AddMembersPageType.KnowledgeLab}
          enableAllowFolderToggle={true}
          enableRoleSelection={true}
        />
      }
      footer={footer}
    ></EdiModal>
  );
};
