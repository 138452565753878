import React, { useState } from "react";
import { classNamesFunction, DetailsList, DetailsRow, IColumn, Icon, IconButton, IDetailsRowProps, SelectionMode, Stack, TextField, TooltipHost } from "@fluentui/react";
import { IFormalAspectTresholdsPropsStyles, IFormalAspectTresholdsStyles, IFormalAspectTresholdsProps, IFormalAspectTresholds } from "./formalAspectTresholds.types";
import { useTranslation } from "react-i18next";
import { Constants } from "../../../docLab/models/constants";
import { useOnMount } from "../../../utilities/hooks";
const getClassNames = classNamesFunction<IFormalAspectTresholdsPropsStyles, IFormalAspectTresholdsStyles>();

export const FormalAspectTresholdsBase = (props: IFormalAspectTresholdsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['formalAspectTresholds']);
    const [items, setItems] = useState<IFormalAspectTresholds[]>(props.labels);
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const formalAspectsLabel: Record<number, string> = { 0: t('archiveSettings:wordNumber'), 1: t('archiveSettings:tableNumber'), 2: t('archiveSettings:pageNumber'), 3: t('archiveSettings:paragraphNumber'), 4: t('archiveSettings:imageNumber') }
    const getFormalAspectTresholds = () => {
        if (!items.find(x => x.text === '') && items.length < 3)
            items.push({ text: '', labelFrom: -1, labelTo: -1 });
        setItems(items);
    }
    useOnMount(() => void getFormalAspectTresholds());

    const getDefaultLabelsValue = () => {
        if (items.filter(x => x.text !== '').length === 1)
            return 0;
        return items.filter(x => x.text !== '').reduce((prev, current) => (prev.labelTo > current.labelTo) ? prev : current).labelTo + 1;
    }

    const labelRange = (item: IFormalAspectTresholds) => {
        return <Stack
            horizontal
            horizontalAlign='stretch'
            verticalAlign='center'
            tokens={{ childrenGap: 30 }}
        >
            <Stack.Item style={{ visibility: (item.text ? 'visible' : 'hidden') }}>
                <TooltipHost content={item.labelFrom?.toString()} id={item.labelFrom?.toString()} styles={{ root: { display: 'flex', alignItems: 'center' } }}>

                    <TextField
                        styles={classNames.subComponentStyles.range}
                        type='number'
                        autoComplete='off'
                        inputMode='numeric'
                        prefix={t('labelFrom')}
                        value={item.labelFrom?.toString()}
                        onChange={(_, value) => onFromChanged(Number(value), item)}
                        disabled={!item.text || props.readOnly}
                        className={item.text && item.isValid ? '' : classNames.errorPrefix}
                        errorMessage={item.text && item.isValid ? '' : ' '}
                    />
                </TooltipHost>
            </Stack.Item>
            <Stack.Item style={{ visibility: (item.text ? 'visible' : 'hidden') }}>
                <TooltipHost content={item.labelTo?.toString()} id={item.labelTo?.toString()} styles={{ root: { display: 'flex', alignItems: 'center' } }}>
                    <TextField
                        styles={classNames.subComponentStyles.range}
                        type='number'
                        autoComplete='off'
                        defaultValue={'0'}
                        inputMode='numeric'
                        disabled={props.readOnly}
                        prefix={t('labelTo')}
                        value={item.labelTo?.toString()}
                        onChange={(_, value) => onToChanged(Number(value), item)}
                        className={item.text && item.isValid ? '' : classNames.errorPrefix}
                        errorMessage={item.text && item.isValid ? '' : ' '}
                    />
                </TooltipHost>
            </Stack.Item>
        </Stack>;
    }

    const columns: IColumn[] = [{
        key: 'column1',
        name: formalAspectsLabel[props.formalAspects],
        minWidth: 300,
        maxWidth: 300,
        headerClassName: classNames.headerRow,
        onRender: function columnRender(item: IFormalAspectTresholds) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', background: palette.white }}>
                    {item.text ? <Icon styles={{ root: { background: palette.white, color: palette.black } }} iconName='tag' /> : ''}
                    <TooltipHost styles={classNames.subComponentStyles.tooltipStyle} content={item.text}>
                        <TextField
                            maxLength={Constants.MAX_LENGTH_ARCHIVE_NAME}
                            onChange={(_, value) => onTextChanged(value ?? '', item)}
                            value={item.text}
                            autoComplete='off'
                            placeholder={t('addLabel')}
                            disabled={props.readOnly}
                            styles={{
                                fieldGroup: { backgroundColor: palette.white, borderRadius: '0px 2px 2px 0px' },
                                field: {
                                    color: palette.black,
                                    'input': { '&::placeholder': { color: palette.black } },
                                    borderBottom: (item.text === '' ? '2px solid ' + palette.themePrimary : ''),
                                    width: '280px'
                                }
                            }} />
                    </TooltipHost>
                </div>)
        }
    },
    {
        key: 'column2',
        name: t('columnTresholds'),
        minWidth: 370,
        maxWidth: 370,
        headerClassName: classNames.headerRow,
        onRender: function columnRender(item: IFormalAspectTresholds) {
            return (labelRange(item))
        },
    },
    {
        key: 'column3',
        name: '',
        minWidth: 20,
        maxWidth: 20,
        headerClassName: classNames.headerRow,
        onRender: function columnRender(item: IFormalAspectTresholds) {
            return (
                item.text ?
                    <IconButton
                        iconProps={{ iconName: "Cancel" }}
                        styles={classNames.subComponentStyles.iconButton()}
                        onClick={() => removeLabel(item)}
                        disabled={props.readOnly}
                    /> : undefined)
        },
    },
    {
        key: 'column4',
        name: '',
        minWidth: 210,
        maxWidth: 210,
        headerClassName: classNames.headerRow,
        onRender: function columnRender(item: IFormalAspectTresholds) {
            return (item.text !== '' && props.labelIsDuplicated(items[items.indexOf(item)]) ? <span style={{ color: palette.redDark }}>{t('duplicateLabel')}</span> :
                item.text && !item.isValid ? <span style={{ color: palette.redDark }}>{t('tresholds')}</span> : '')
        },
    }
    ];

    const onTextChanged = (value: string, item: IFormalAspectTresholds) => {
        const newItems = items.slice()
        const index = newItems.indexOf(item)
        newItems[index].text = value

        if (newItems[index].labelFrom === -1) {
            newItems[index].labelFrom = getDefaultLabelsValue()
            newItems[index].labelTo = getDefaultLabelsValue()
        }

        if (!checkError(item.labelFrom, item.labelTo, item.text || ''))
            newItems[index].isValid = true;

        if (newItems[index].isValid && !props.labelIsDuplicated(newItems[index])) {
            props.onSave(newItems.filter(x => x.isValid));
            newItems[index].isValid = true;
        } else
            newItems[index].isValid = false;
        //creo il nuovo textField
        if (!newItems.find(x => x.text === '') && newItems.length < 3)
            newItems.push({ text: '', labelFrom: -1, labelTo: -1, isValid: true });
        setItems(newItems);
        if (value === '')
            removeLabel(item);
    }

    const onFromChanged = (value: number, item: IFormalAspectTresholds) => {
        const newItems = items.slice();
        const index = newItems.indexOf(item);
        newItems[index].labelFrom = value;
        if (item.text && checkError(value, item.labelTo, item.text))
            newItems[index].isValid = false;
        else
            newItems[index].isValid = true;

        const itemsToUpdate = newItems.filter(x => x.labelTo === item.labelFrom && x.text !== item.text)[0];
        if (value < item.labelTo && itemsToUpdate)
            newItems[newItems.indexOf(itemsToUpdate)].isValid = true;

        if (value > 0 && newItems[index].isValid && !props.labelIsDuplicated(newItems[index]) && value <= item.labelTo) {
            newItems[index].isValid = true;
            props.onSave(newItems.filter(x => x.isValid))
        }
        else
            newItems[index].isValid = false;
        setItems(newItems);
    }

    const onToChanged = (value: number, item: IFormalAspectTresholds) => {
        const newItems = items.slice();
        const index = newItems.indexOf(item);
        newItems[index].labelTo = value;
        if (item.text && checkError(value, item.labelFrom, item.text))
            newItems[index].isValid = false;
        else
            newItems[index].isValid = true;


        const itemsToUpdate = newItems.filter(x => x.labelFrom === item.labelTo)[0];
        if (value > 0 && value < item.labelTo && itemsToUpdate)
            newItems[newItems.indexOf(itemsToUpdate)].isValid = true;

        if (newItems[index].isValid && !props.labelIsDuplicated(newItems[index]) && value >= item.labelFrom)
            props.onSave(newItems.filter(x => x.isValid));
        else
            newItems[index].isValid = false;
        setItems(newItems);
    }

    const removeLabel = (item: IFormalAspectTresholds) => {
        const newItems = items.filter(x => x !== item).slice();
        if (!newItems.find(x => x.text === ''))
            newItems.push({ text: '', labelFrom: -1, labelTo: -1, isValid: true });
        props.onSave(newItems.filter(x => x.isValid))
        setItems(newItems)
    }

    const checkError = (from: number, to: number, text: string) => {
        const start = from > to ? to : from;
        const end = from > to ? from : to;

        const error =
            items.filter(x => x.text !== text && x.text !== '')
                .some(x => (x.labelFrom <= end && x.labelTo >= end)
                    || (x.labelFrom <= start && x.labelTo >= start)
                    || (start <= x.labelFrom && end >= x.labelTo)
                );
        if (error || start > Constants.MAX_INTEGER || end > Constants.MAX_INTEGER)
            return true;
        return false;
    }
    return (
        <div style={{ position: 'relative', left: 166, width: 'calc(100% - 166px)', overflow: 'hidden scroll' }}>
            <DetailsList
                items={items}
                columns={columns}
                selectionMode={SelectionMode.none}
                isHeaderVisible={true}
                styles={classNames.subComponentStyles.detailsList}
                onRenderRow={(props?: IDetailsRowProps) => {
                    if (!props) return null;
                    return <DetailsRow {...props} styles={classNames.subComponentStyles.detailsRow} />;
                }}
            />
        </div>
    )
}