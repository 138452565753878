import React from "react";
import { Icon, TooltipHost } from "@fluentui/react";
import { IActivityExpiredProps } from "./activityExpired.types";
import { useTranslation } from "react-i18next";
import { isActivityExpired, isActivityNotStarted } from "../utilities";

export const ActivityExpiredBase = (props: IActivityExpiredProps) => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    const { t } = useTranslation(['activity', 'common']);

    return (
        <div>
            {isActivityExpired(props.activity.status, props.activity.dueDate) || isActivityNotStarted(props.activity.status, props.activity.startDate) ?
                <TooltipHost content={isActivityExpired(props.activity.status, props.activity.dueDate) ? t('activitiesBoard.activityExpired') : t('activitiesBoard.activityNotStarted')}>
                    <Icon
                        styles={{ root: { marginLeft: '5px', fontSize: '20px', color: palette.red, paddingTop: 5, cursor: 'context-menu' } }}
                        iconName="Warning"
                    /></TooltipHost>
                : ''}

        </div>
    );
}