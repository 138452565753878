import { IStyleFunction } from "@fluentui/react"
import { IMultiStepModalPropsStyles, IMultiStepModalStyles } from "../../../../../../common/components/multiStepModal/multiStepModal.types";
import { ICreateProfileModalBaseStyles, ICreateProfileModalBasePropsStyles } from "./createProfileModal.types";


export const CreateProfileModalGlobalClassNames = {
    root: 'edi-create-profile-modal-root',
    footerButtons: 'edi-create-profile-footer',
    footerContainer: 'edi-create-profile-footer-container',
    centerContainer: 'edi-create-profile-center-container'
}

export const getStyles: IStyleFunction<ICreateProfileModalBasePropsStyles, ICreateProfileModalBaseStyles> = (props: ICreateProfileModalBasePropsStyles): ICreateProfileModalBaseStyles => {
    return {
        root: [
            CreateProfileModalGlobalClassNames.root,
            {
            }
        ],
        footerButtons:[
            CreateProfileModalGlobalClassNames.footerButtons,
            {
                'margin-left': '10px'
            }
        ],
        footerContainer: [
            CreateProfileModalGlobalClassNames.footerContainer,
            {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }
        ],
        centerContainer: [
            CreateProfileModalGlobalClassNames.centerContainer,
            {
                display:'flex',
                flexDirection:'column',
                alignItems: 'center'
            }
        ],
        subComponentStyles: {
            ediModal: (props: IMultiStepModalPropsStyles): IMultiStepModalStyles => {
                return {
                    body: { marginTop: '10px !important' },
                    bodyContent: {},
                    title: {},
                    closeIcon: {},
                    container: { width: '800px', overflow: 'hidden' },
                    content: {},
                    footer: {
                    },
                    header: {},
                    subComponentStyles: {
                        iconButton: {}
                    },
                    subTitle: {}
                }
            }
        }
    }
}