import { styled } from "@fluentui/react";
import { ImportDocumentModalBase } from "./importDocumentModal.base";
import { getStyles } from "./importDocumentModal.styles";
import { IImportDocumentModalProps, IImportDocumentModalPropsStyles, IImportDocumentModalStyles } from "./importDocumentModal.types";

const KnowledgeLabImportDocumentModal = styled<IImportDocumentModalProps, IImportDocumentModalPropsStyles, IImportDocumentModalStyles>(
    ImportDocumentModalBase,
    getStyles,
    undefined,
    { scope: 'DocLab' }
);

export default KnowledgeLabImportDocumentModal;