import { DateTime } from "luxon";
import { ArchiveRoleId } from "./constants";

export type ArchiveItem = {
    id: number;
    parentId: number;
    isFolder: boolean;
    name: string;
    createdBy: string;
    createdById: string;
    createdOn: string;
    //metadata: any; //eslint-disable-line @typescript-eslint/no-explicit-any
    metadataSavedOn?: DateTime;
    indexingStatus: IndexingStatus;
    archiveRoleId: ArchiveRoleId;
    size?: number;
    sourceType?: FileSourceType;
    approvalStatus?: FileApprovalStatus;
    approverName?: string;
    approvalDate?: DateTime;
    rejectionNote?: string;
    metadataEditAuthorId?: string;
    deletedOn?: string;
    deletedBy?: string;
    deletedById?: string;
    alreadyApproved?: boolean;
    fileId?: number;
    folderId?: number;
    metadata?: string;
}

export enum FileSourceType {
    EDI = 0,
    EWE = 1,
    EWE_Aux = 2
}

export enum IndexingStatus {
    ToBeIndexed = 0,
    Indexed = 1,
    Deleted = 2
}

export enum FileApprovalStatus {
    NA = 0,
    ToBeApproved = 1,
    Approved = 2,
    Rejected = 3,
    Migrated = 4
}