import { User } from "../../docLab/models/user";
import { WorkFlowApprover } from "./workflowApprover";

export enum WorkFlowStepTypes {
    OnlyOneApprover = 0,
    OnlyOneSignedApprover = 1,
    EveryoneMustApprove = 2
}

export enum WorkFlowStepStatus {
    Idle = 0,
    ToBeApproved = 1,
    Approved = 2,
    Refused = 3
}

export enum WorkFlowStepRelativeStatus {
    Idle = 0,
    ToBeApproved = 1,
    Approved = 2,
    Refused = 3,
    ToMe = 4
}

export type WorkFlowStep = {
    id: number;
    workFlowId: number;
    name: string;
    type: WorkFlowStepTypes ;
    status: WorkFlowStepStatus;
    relativeStatus: WorkFlowStepRelativeStatus;
    order: number;
    createdOn: Date;
    documents?: StepDocuments[];
    lastUpdatedOn: Date;
    approvedOn: Date;
    comment: string;
    approvers: WorkFlowApprover[];
    involvedPeople?: User[];
}

export type StepDocuments = {
    id: number;
    workFlowstepId: number;
    document: string;
    createdOn: Date;
    lastUpdatedOn: Date;
}
