/*eslint-disable @typescript-eslint/no-explicit-any */
/*eslint-disable sonarjs/no-identical-functions*/
import { KnowledgeLabState, KnowledgeLabDispatch } from "../knowledgeLabStore";
import { addToCompare, setDetailsModalOpen, setSelectedElement } from "../features/knowledgeLabContent";
import { INavigator } from "../hooks/useNavigator";
import { KnowledgeLabShort } from "../models/knowledgeLab";
import { KnowledgeLabRoleId } from "../models/knowledgeLabRoleId";
import { Document, SearchResult } from "../services/search/search.contracts";
import { startDownload } from "../../docLab/features/downloadStatus";
import { nanoid } from "@reduxjs/toolkit";
import { knowledgeLabApi } from "../services/knowledgeLab.api";
import { Helpers } from "../../utilities/helpers";
import { filePreviewSubPath } from "../../utilities/constants";
// import { KnowledgeElement } from "../models/knowledgeElement";
// import { setCurrentResults } from "../features/searchContent";


export type SearchEntry = {
    id: string;
    knowledgeRoles: KnowledgeLabRoleId[];
    label: string;
    icon?: string;
    hideIfElementIsFavourite?: boolean;
    hideIfElementIsNotFavourite?: boolean;
    subActionItems?: SearchEntry[];
    onClick: (state: KnowledgeLabState, knowledge: KnowledgeLabShort, navigator: INavigator, dispatch: KnowledgeLabDispatch, document?: Document, searchResult?: SearchResult, title?: string) => void;
}

// const updateFavourites = (element: KnowledgeElement, document: Document, dispatch: KnowledgeLabDispatch, searchResult?: SearchResult) => {
//     const additionalData = { ...document.additionalData };
//     if (additionalData)
//         additionalData["isFavourite"] = String(element.isFavourite);
//     const newDocument = {
//         ...document,
//         additionalData: additionalData
//     }
//     dispatch(setSelectedElement(newDocument));
//     if (searchResult) {
//         const foundIndex = searchResult.values.findIndex(x => x.document.id === document.id);
//         let newSearchResult = { ...searchResult };
//         const values = [...newSearchResult.values];
//         values[foundIndex] = { ...values[foundIndex], document: newDocument };
//         newSearchResult = { ...newSearchResult, values: values }
//         dispatch(setCurrentResults(newSearchResult))
//     }
// }

const addToCompareAction: SearchEntry = {
    id: "addToCompare",
    knowledgeRoles: [KnowledgeLabRoleId.Owner, KnowledgeLabRoleId.Contributor, KnowledgeLabRoleId.Reader],
    label: "commands.addToCompare",
    onClick: (_, __, ___, dispatch, document, searchResult, title) => {
        if (document) {
            dispatch(addToCompare({
                id: parseInt(document.id),
                fileId: document.fileRef,
                title: title ?? document.id,
                content: "",
                isFavourite: (document.additionalData ? document.additionalData["isFavourite"] === "true" : false)
            }));
        }
    }

}


const actions: SearchEntry[] = [
    {
        id: "details-clause",
        knowledgeRoles: [KnowledgeLabRoleId.Owner, KnowledgeLabRoleId.Contributor, KnowledgeLabRoleId.Reader],
        label: 'commands.details',
        onClick: (_, __, ___, dispatch, document) => {
            if (document) {
                dispatch(setSelectedElement(document));
                dispatch(setDetailsModalOpen(true));
            }
        }
    },
    {
        id: "view-file",
        knowledgeRoles: [KnowledgeLabRoleId.Owner, KnowledgeLabRoleId.Contributor],
        label: 'commands.viewFile',
        onClick: (_, __, ___, ____, document) => {
            if (document) {
                Helpers.getKnowledgeLabPreviewFile(document.fileRef, filePreviewSubPath.knowledge);
            }
        }
    },
    {
        id: "download-file",
        knowledgeRoles: [KnowledgeLabRoleId.Owner, KnowledgeLabRoleId.Contributor],
        label: 'commands.downloadFile',
        onClick: (_, __, ___, dispatch, document) => {
            if (document) {
                knowledgeLabApi.getFilesById([document.fileRef]).then((files: any[]) => {
                    const file = files[0];
                    dispatch(startDownload({ requestId: nanoid(), file: { id: document.fileRef, name: file.name }, downloadFunction: knowledgeLabApi.downloadFile }));
                });
            }
        }
    },
    // {
    //     id: "add-favourites",
    //     knowledgeRoles: [KnowledgeLabRoleId.Owner, KnowledgeLabRoleId.Contributor, KnowledgeLabRoleId.Reader],
    //     label: 'commands.addToFavourites',
    //     hideIfElementIsFavourite: true,
    //     onClick: (_, __, ___, dispatch, document, searchResult) => {
    //         if (document)
    //             knowledgeLabApi.addElementToFavourites(parseInt(document.id)).then((element: KnowledgeElement) => {
    //                 if (element) {
    //                     updateFavourites(element, document, dispatch, searchResult);
    //                 }
    //             });
    //     }
    // },
    // {
    //     id: "remove-favourites",
    //     knowledgeRoles: [KnowledgeLabRoleId.Owner, KnowledgeLabRoleId.Contributor, KnowledgeLabRoleId.Reader],
    //     label: 'commands.removeFromFavourites',
    //     hideIfElementIsNotFavourite: true,
    //     onClick: (_, __, ___, dispatch, document, searchResult) => {
    //         if (document)
    //             knowledgeLabApi.removeElementFromFavourites(parseInt(document.id)).then((element: KnowledgeElement) => {
    //                 if (element) {
    //                     updateFavourites(element, document, dispatch, searchResult);
    //                 }
    //             });
    //     }
    // }
];


export const getSearchActions = (knowledgeRoleId: KnowledgeLabRoleId, isFavourite = false, isCompareEnabled = false) => {
    let filteredActions = actions.filter(entry =>
        entry.knowledgeRoles.includes(knowledgeRoleId));

    if (isFavourite)
        filteredActions = filteredActions.filter(a => !a.hideIfElementIsFavourite)
    else
        filteredActions = filteredActions.filter(a => !a.hideIfElementIsNotFavourite)

    if (isCompareEnabled)
        filteredActions.push(addToCompareAction);

    return filteredActions;
}
