import { IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction, ITooltipHostStyles } from "@fluentui/react"
import { IFormalAspectTresholdsPropsStyles, IFormalAspectTresholdsStyles } from "./formalAspectTresholds.types"

export const FormalAspectTresholdsGlobalClassNames = {
    root: 'edi-formal-aspect-tresholds-root',
    errorPrefix: 'edi-formal-aspect-tresholds-errorPrefix',
    headerRow: 'edi-formal-aspect-tresholds-headerRow',
}

export const getStyles: IStyleFunction<IFormalAspectTresholdsPropsStyles, IFormalAspectTresholdsStyles> = (props: IFormalAspectTresholdsPropsStyles): IFormalAspectTresholdsStyles => {
    const { palette, semanticColors } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            FormalAspectTresholdsGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        errorPrefix: [
            FormalAspectTresholdsGlobalClassNames.errorPrefix,
            {
                ' .ms-TextField-prefix': {
                    backgroundColor: semanticColors.errorText,
                }
            }
        ],
        headerRow: [
            FormalAspectTresholdsGlobalClassNames.headerRow,
            {
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                }
            }
        ],
        subComponentStyles: {
            tooltipStyle: (): ITooltipHostStyles => {
                return {
                    root: {
                        display: 'flex',
                        alignItems: 'center',
                    }
                }
            },
            iconButton: {
                root: {
                    color: palette.neutralDark,
                    marginLeft: 'auto',
                    marginTop: '4px',
                    marginRight: '2px',
                },
                rootHovered: {
                    color: palette.neutralPrimary,
                    background: 'unset'
                }
            },
            detailsList: (): IDetailsListStyles => {
                return {
                    root: {
                        overflowX: 'scroll',
                        overflowY: 'hidden'
                    },
                    contentWrapper: { borderBottom: '1px solid ' + palette.neutralTertiary },
                    focusZone: {},
                    headerWrapper: {
                        '> .ms-DetailsHeader': {
                            paddingTop: 0,
                            borderBottomColor: 'transparent'
                        }
                    },
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        // backgroundColor: palette.white,
                        borderBottom: 'unset',
                        ':hover': {
                            backgroundColor: 'unset'
                        }
                    },
                    cell: {
                        display: 'flex',
                        height: '55px',
                        alignItems: 'center',
                    },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},


                }
            },
            range: {
                field: {
                    backgroundColor: palette.white,
                    color: palette.black,
                    width: '120px',
                    'input': {
                        '&::placeholder':
                        {
                            color: palette.black
                        }
                    }
                },
                prefix: {
                    backgroundColor: palette.themePrimary,
                    color: palette.white
                },
                errorMessage: {
                    position: 'absolute'
                }
            }
        }
    };
}