import React from "react";
import SplitTag from "./SplitTag";
import {
  generalSplitClick,
  generalHandleMouseUp,
  generateSplits,
} from "./utils/annotation-utils";
import { Span, AnnotateTag } from "../types/annotate-types";
import { TextAnnotateProps } from "./TextAnnotate";

export interface AnnotatorProps<T> extends TextAnnotateProps<T> {
  isBlendable: boolean;
}

const TextAnnotator = <T extends AnnotateTag>({
  content,
  search,
  value,
  isBlendable,
  onChange,
  getSpan,
  style,
  className,
  totalCount,
  selectedElement
}: AnnotatorProps<T>) => {

  const annotateGetSpan = (span: T): T => {
    if (getSpan) return getSpan(span) as T;
    return { start: span.start, end: span.end } as T;
  };

  const decoratedMouseUp = () => {

    return () =>
      generalHandleMouseUp(
        content,
        value,
        isBlendable,
        annotateGetSpan,
        onChange
      );
  };

  const decoratedHandleSplitClick = () => {

    return (split: Span) =>
      generalSplitClick(split, value, isBlendable, onChange);
  };

  const handleMouseUp = decoratedMouseUp();
  const handleSplitClick = decoratedHandleSplitClick();

  const splits = generateSplits(content, value, isBlendable);

  function replaceSpecialCharacters(searchString: string | undefined) {
    if (searchString === undefined || searchString === null)
      return searchString

    return searchString.replaceAll(
      /[.+*?[\](){}|^$\\]/g,
      (x: string) => `\\${x}`
    );
  }


  const countSearch = (content: string, search: string | undefined) => {
    const regex = new RegExp(replaceSpecialCharacters(search) || "", "gmi");
    return (content.match(regex) || []).length;
  }

  const partialCount = (numberArray: number[], n: number) => {
    if (n === 0)
      return 0;
    const tmp = numberArray.slice(0, n);
    return (tmp.reduce((totale, x) => totale + x, 0));

  }


  const renderSplitter = () => {
    let sumArray: number[] = [];
    splits.forEach(itm => sumArray = sumArray.concat(countSearch(itm.content, search)));
    totalCount(sumArray.reduce((totale, x) => totale + x, 0));
    return splits.map((split, idx) => (
      <SplitTag
        search={search}
        key={`${split.start}-${split.end}`}
        {...split}
        onClick={handleSplitClick}
        startCounter={partialCount(sumArray, idx)}
        selectedElement={selectedElement}
      />
    ));

  }

  return (
    <div className={className} style={style} onMouseUp={handleMouseUp}>
      {
        renderSplitter()
      }
    </div>
  );
};

export default TextAnnotator;
