import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { KnowledgeDsFilter } from "../components/feedback/feedback.types";
import { useKnowledgeDsSelector } from "../knowledgeDsStore";
import { KnowledgeDataset } from "../models/dataset";

export type DatasetListState = {
    filters: KnowledgeDsFilter;
    selectedItems: KnowledgeDataset[];
    noMore: boolean;
    refresh: boolean;
}

const emptyFilters = {
    pageNumber: 0,
    pageSize: 20,
    orderBy: "CreatedOn",
    isAscending: false,
    fileName: undefined,
    skillName: undefined,
    knowledgeName: undefined,
    feedbackStatus: undefined,
    dateFrom: undefined,
    dateTo: undefined
}

const initialState: DatasetListState = {
    filters: emptyFilters,
    selectedItems: [],
    noMore: false,
    refresh: false,
}

const datasetListSlice = createSlice({
    name: 'knowledgeDS-dataset',
    initialState,
    reducers: {
        setFilters: (state: DatasetListState, action: PayloadAction<KnowledgeDsFilter>) => {
            state.filters = action.payload;
        },
        resetDatasetFilters: (state: DatasetListState) => {
            state.filters = emptyFilters;
            state.noMore = false;
        },
        setSelectedDatasets: (state: DatasetListState, action: PayloadAction<KnowledgeDataset[]>) => {
            state.selectedItems = action.payload;
        },
        setNoMore: (state: DatasetListState, action: PayloadAction<boolean>) => {
            state.noMore = action.payload;
        },
        clearSelectedItems: (state: DatasetListState) => {
            state.selectedItems = [];
        },
        setRefresh: (state: DatasetListState, action: PayloadAction<boolean>) => {
            state.refresh = action.payload;
        }

    }
})

export const { setFilters, resetDatasetFilters, setSelectedDatasets, clearSelectedItems, setNoMore, setRefresh } = datasetListSlice.actions;
export default datasetListSlice.reducer;

export const useDatasetList = () => useKnowledgeDsSelector(state => state.datasetList);