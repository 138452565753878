import {
  BasePeoplePicker, FontWeights, IBasePickerProps, IBasePickerStyles, IBreadcrumbStyleProps, IBreadcrumbStyles, ICalloutContentStyleProps, ICalloutContentStyles, IContextualMenuItemStyleProps,
  IContextualMenuItemStyles, IContextualMenuStyleProps, IContextualMenuStyles, IDetailsColumnProps, IDetailsColumnStyles, IDetailsHeaderProps,
  IDetailsHeaderStyles, IDetailsRowProps, IDetailsRowStyles, IDropdownStyleProps, IDropdownStyles, INavStyleProps, INavStyles, IPeoplePickerItemSuggestionProps, IPeoplePickerItemSuggestionStyles, IPivotStyleProps, IPivotStyles, ISpinnerStyleProps, ISpinnerStyles, ISuggestionsItemStyleProps,
  ISuggestionsItemStyles, ISuggestionsStyleProps, ISuggestionsStyles, ITextFieldProps, ITextFieldStyles, ITooltipStyleProps, ITooltipStyles
} from "@fluentui/react";

export const tooltipStyles = (props: ITooltipStyleProps) : ITooltipStyles =>{
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
  return{
    root:{},
    content:{},
    subText: {
      color: palette.themePrimary
    }
  }
}

export const dropdownStyles = (props: IDropdownStyleProps): IDropdownStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {},
    label: {},
    dropdown: {
      selectors: {
        ':focus': {
          '::after': {
            border: 'none',
            borderBottom: '2px solid ' + palette.themePrimary
          }
        }
      }
    },
    title: {
      border: 'none',
      backgroundColor: palette.neutralLight //'rgb(240,240,240)'
    },
    caretDownWrapper: {},
    caretDown: {},
    errorMessage: {},
    dropdownItemsWrapper: {},
    dropdownItems: {},
    dropdownItem: {},
    dropdownItemSelected: {},
    dropdownItemDisabled: {},
    dropdownItemSelectedAndDisabled: {},
    dropdownItemHidden: {},
    dropdownOptionText: {},
    dropdownDivider: {},
    dropdownItemHeader: {},
    panel: {},
    callout: {},
    subComponentStyles: {
      label: {},
      multiSelectItem: {},
      panel: {}
    }
  }
}

export const spinnerStyles = (props: ISpinnerStyleProps): ISpinnerStyles => {
  return {
    circle: {
      // borderColor: props.theme.palette.themePrimary, To Check
    },
  };
};

export const suggestionsPeoplePickerStyles = (props: ISuggestionsStyleProps): ISuggestionsStyles => {
  return {
    suggestionsContainer: {
      maxHeight: "40vh",
    },
    forceResolveButton: {},
    noSuggestions: {},
    root: {},
    title: {},
    searchForMoreButton: {},
    subComponentStyles: {
      spinner: {},
    },
    suggestionsAvailable: {},
  };
};

export const suggestionsItemStyles = (props: ISuggestionsItemStyleProps): ISuggestionsItemStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
  return {
    root: {},
    itemButton: {
      selectors: {
        ":hover": {
          background: palette.themeLight,
        },
      },
    },
    closeButton: {},
  };
};

export const navStyles = (props: INavStyleProps): INavStyles => {
  const { isExpanded } = props;
  const { semanticColors, palette, spacing, fonts } = props.theme;

  return {
    root: {
      padding: "5px 0",
      background: semanticColors.menuItemBackgroundPressed,
      fontSize: spacing.s1,
      ".ms-Nav-link": {
        height: "28px",
      },
      height: "100%",
      selectors: {
        ".is-selected button": {
          //backgroundColor: palette.neutralLighterAlt
          backgroundColor: palette.white,
          ":hover": {
            backgroundColor: palette.white,
          },
        },
      },
    },
    linkText: {},
    link: {
      padding: "0 10px",
      selectors: {
        ".ms-Nav-compositeLink:hover &": {
          backgroundColor: palette.neutralLighterAlt,
          color: palette.black,
        },
        ":active": {
          ".ms-Button-flexContainer": {
            position: "unset",
          },
        },
      },
    },
    compositeLink: {},
    chevronButton: {
      fontSize: fonts.medium.fontSize,
      color: semanticColors.infoIcon,
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0,
      selectors: {
        ":hover": {
          backgroundColor: palette.neutralLighterAlt,
          color: palette.black,
        },
      },
    },
    chevronIcon: [
      {
        fontSize: fonts.xSmall.fontSize,
        transform: "rotate(-90deg)",
      },
      isExpanded && {
        transform: "rotate(0deg)",
        top: "5px",
      },
    ],
    navItems: {},
    navItem: {},
    group: {},
    groupContent: {
      marginBottom: "0px",
    },
  };
};

export const pivotStyles = (props: IPivotStyleProps): IPivotStyles => {
  return {
    root: {},
    link: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 8,
      marginRight: 8,

      "&:hover": {
        backgroundColor: "inherit",
        color: props.theme?.palette.black,
      },
      "&:active": {
        backgroundColor: "inherit",
      },

      "&::before": {
        transition: "none",
        height: 3,
      },

      "&:not(.is-selected):hover::before": {
        backgroundColor: "#bdbdbd",
        right: 0,
        left: 0,
      },
    },
    linkIsSelected: {
      cursor: "default",
      color: props.theme.palette.black,

      "&:hover": {
        cursor: "default",
      },

      "&::before": {
        right: 0,
        left: 0,
      },
    },
    linkContent: {},
    text: {
      fontSize: 14,
    },
    count: {},
    icon: {},
    linkInMenu: {},
    overflowMenuButton: {},
  };
};

export const contextualMenuStyles = (props: IContextualMenuStyleProps): IContextualMenuStyles => {
  return {
    root: {
      marginTop: 1,
    },
    container: {},
    header: {},
    list: {
      padding: "8px 0",
    },
    title: {},
    subComponentStyles: {
      callout: (props: ICalloutContentStyleProps): ICalloutContentStyles => {
        return {
          root: {},
          container: {},
          beak: {},
          beakCurtain: {},
          calloutMain: {
            boxShadow: "0 0.2rem 1.6rem 0 rgb(37 36 35 / 30%)",
          },
        };
      },
      menuItem: (
        props: IContextualMenuItemStyleProps
      ): IContextualMenuItemStyles => {
        return {
          root: {
            paddingLeft: 8,
            paddingRight: 32,
            color: "#424242",
          },
          icon: {
            color: '#424242'
          }
        }
      }
    }
  }
}

export const breadcrumbStyles = (props: IBreadcrumbStyleProps): IBreadcrumbStyles => {
  const { palette, semanticColors } = props.theme;

  return {
    root: {
      margin: "8px 0px 0px",
      paddingLeft: "24px",
      paddingRight: "24px",
      lineHeight: "20px"
    },
    list: {},
    listItem: {
    },
    chevron: {},
    overflow: {},
    overflowButton: {},
    itemLink: {
      fontSize: "unset",
      color: semanticColors.buttonText,
      lineHeight: "40px",
      height: "40px",
      fontWeight: "400",
      selectors: {
        ':hover': {
          color: palette.themePrimary,
          textDecoration: "none",
          cursor: "pointer",
          background: "none"
        },
        ':last-child': {
          fontWeight: "700 !important",
          color: semanticColors.buttonText
        },
        ':active': {
          color: palette.themePrimary + " !important",
          textDecoration: "none",
          cursor: "pointer",
          background: "none !important"
        },
      }
    },
    item: {
      fontSize: "unset",
      color: semanticColors.buttonText,
      lineHeight: "40px",
      height: "40px",
      fontWeight: "400",
      selectors: {
        ':last-child': {
          fontWeight: "700 !important",
          color: semanticColors.buttonText
        },
      }
    },
  }
}

export const detailsRowStyles = (props: IDetailsRowProps): IDetailsRowStyles => {
  const { fonts, semanticColors, palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      backgroundColor: semanticColors.bodyBackground,
      selectors: {
        ':hover': {
          backgroundColor: semanticColors.listItemBackgroundHovered
        },
        '&.is-selected': {
          backgroundColor: semanticColors.listItemBackgroundChecked,
          borderBottom: `1px solid ${palette.neutralLighter}`,
        },
        '&.is-selected:not(:hover)': {
          backgroundColor: palette.white,
        }
      }
    },
    cell: {
      fontSize: fonts.medium.fontSize,
      color: palette.black
    },
    cellAnimation: {},
    cellUnpadded: {},
    cellPadded: {},
    checkCell: {},
    isRowHeader: {
      color: palette.black,
      fontWeight: fonts.medium.fontWeight
    },
    isMultiline: {},
    fields: {},
    cellMeasurer: {},
    checkCover: {},
    check: {}
  }
}

export const detailsColumnStyles = (props: IDetailsColumnProps): IDetailsColumnStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      height: '32px'
    },
    cellName: {
      fontSize: '12px',
      fontWeight: '400'
    },
    cellTitle: {
      color: palette.black
    },
    sortIcon: {
      color: palette.black
    },
    gripperBarVerticalStyle: {},
    cellTooltip: {},
    iconClassName: {},
    nearIcon: {},
    accessibleLabel: {},
    filterChevron: {},
    borderAfterDropping: {},
    noBorderAfterDropping: {},
    borderWhileDragging: {},
    noBorderWhileDragging: {}
  }
}

export const detailsHeaderStyles = (props: IDetailsHeaderProps): IDetailsHeaderStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      height: '32px',
      lineHeight: '32px',
      borderBottomColor: palette.neutralQuaternary
    },
    cellIsCheck: {
      height: '32px'
    },
    check: {},
    cellWrapperPadded: {},
    cellIsActionable: {},
    cellIsEmpty: {},
    cellSizer: {},
    cellSizerStart: {},
    cellSizerEnd: {},
    cellIsResizing: {},
    cellIsGroupExpander: {},
    collapseButton: {},
    checkTooltip: {},
    sizingOverlay: {},
    dropHintCircleStyle: {},
    dropHintCaretStyle: {},
    dropHintLineStyle: {},
    dropHintStyle: {},
    accessibleLabel: {},
  }
}

export const textFieldStyles = (props: ITextFieldProps): ITextFieldStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      marginBottom: 1
    },
    fieldGroup: {
      border: 'none',
      color: palette.black,
      backgroundColor: palette.neutralLighter,
      '::after': {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: 0,
        width: '100%',
        inset: '0px'
      }
    },
    prefix: {},
    suffix: {},
    field: {
      '::placeholder': {
        color: palette.black
      },
      color: palette.black
    },
    icon: {},
    description: {},
    wrapper: {},
    errorMessage: {},
    revealButton: {},
    revealSpan: {},
    revealIcon: {},
    subComponentStyles: {
      label: {
        root: {
          fontWeight: FontWeights.regular,
          color: palette.black
        }
      }
    }
  }
}

export const peoplePickerStyles = (props: IBasePickerProps<BasePeoplePicker>): IBasePickerStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      width: "100%",
      marginRight: "20px",
    },
    text: {
      borderLeft: "none",
      borderRight: "none",
      borderTop: "none",
      borderRadius: 0,
      selectors: {
        "::after": {
          borderLeft: "none",
          borderRight: "none",
          borderTop: "none",
          borderRadius: 0,
        },
      },
    },
    input: {
      color: palette.black,
      backgroundColor: palette.neutralLighter
    },
    itemsWrapper: {
      ".ms-PickerPersona-container": {
        //border: "1px solid" + palette.neutralSecondary,
        //background: "transparent",
        // marginBottom: "5px",
        // ".ms-TooltipHost": {
        //   color: palette.themePrimary,
        // },
      },
    },
    screenReaderText: {}
  };
};

export const peoplePickerItemSuggestionStyles = (props: IPeoplePickerItemSuggestionProps): IPeoplePickerItemSuggestionStyles => {
  return {
    root: {
      ":hover": {
        backgroundColor: '#f3f2f1'
      }
    },
    personaWrapper: {},
    subComponentStyles: {
      persona: {}
    }
  }
}