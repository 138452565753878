import { IStyleFunction } from "@fluentui/react"
import { IDeleteKnowledgeElementModalPropsStyles, IDeleteKnowledgeElementModalStyles } from "./deleteKnowledgeElementModal.types"

export const DeleteKnowledgeDocumentModalGlobalClassNames = {
    root: 'edi-delete-knowledge-document-modal-root'
}

export const getStyles: IStyleFunction<IDeleteKnowledgeElementModalPropsStyles, IDeleteKnowledgeElementModalStyles> = (props: IDeleteKnowledgeElementModalPropsStyles): IDeleteKnowledgeElementModalStyles => {
    return {
        root: [
            DeleteKnowledgeDocumentModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}