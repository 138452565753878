/*eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from "react";
import { classNamesFunction, DefaultButton, Label, PrimaryButton, Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import MultiStepModal from "../../../../common/components/multiStepModal/multiStepModal";
import { useOnMount } from "../../../../utilities/hooks";
import KnowledgeEdiFileSelector from "../../knowledgeEdiFileSelector/knowledgeEdiFileSelector";
import { IImportDocumentModalProps, IImportDocumentModalPropsStyles, IImportDocumentModalStyles } from "./importDocumentModal.types";
import { FileSource } from "../choiceInputSource/choiceInputSource.types";
import ChoiceInputSource from "../choiceInputSource/choiceInputSource";
import KnowledgeSharepointFileSelector from "../../knowledgeSharepointFileSelector/knowledgeSharepointFileSelector";
import { NavigableItem } from "../../../models/navigableFile";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import FileImporterOverview from "../fileImporterOverview/fileImporterOverview";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { knowledgeLabApi } from "../../../services/knowledgeLab.api";
import { useKnowledgeLabSelector } from "../../../knowledgeLabStore";
import KnowledgeLabFileEnrichment from "../../knowledgeLabFileEnrichment/knowledgeLabFileEnrichment";
import KnowledgeLabFileUploader from "../../knowledgeLabFileUploader/knowledgeLabFileUploader";
import { FileFormatExtension } from "../../../../utilities/constants";
import axios, { CancelTokenSource } from "axios";
import { KnowledgeHelpers } from "../../../utilities/helpers";

const getClassNames = classNamesFunction<IImportDocumentModalPropsStyles, IImportDocumentModalStyles>();

enum ImportDocumentStep {
    chooseSource = 0,
    fileSharepointOverview = 1,
    fileEdiOverview = 2,
    selectLocalFile = 3,
    selectEdiFile = 4,
    selectSharepointFile = 5,
    fileEnrichment = 6,
    uploadFiles = 7,
    completed = 8,
}

export const ImportDocumentModalBase = (props: IImportDocumentModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [currentStep, setCurrentStep] = useState(ImportDocumentStep.chooseSource);
    const [previousStep, setPreviousStep] = useState(ImportDocumentStep.chooseSource);
    const [fileTypes, setFileTypes] = useState<string[]>([]);
    const [fileTypeExtensions, setFileTypeExtensions] = useState<string[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<NavigableItem[]>([]);
    const [overviewMessage, setOverviewMessage] = useState<string>();
    const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
    const [uploadLoading, setUploadLoading] = useState<boolean>(false);
    const { selectedKnowledgeLab } = useKnowledgeLabSelector(state => state.knowledgeLabList);
    const { t, i18n } = useTranslation('importKnowledgeLabDocument')
    const enableFileEnrichment = selectedKnowledgeLab?.configurations.enableFileEnrichment;

    useOnMount(() => {
        setCurrentStep(ImportDocumentStep.chooseSource);
        // const cancelToken = axios.CancelToken.source();
        // setCancelToken(cancelToken);
    });

    useEffect(() => {
        if (props.isOpen) {
            const cancelToken = axios.CancelToken.source();
            setCancelToken(cancelToken);
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (!selectedKnowledgeLab)
            return;

        const typesExtensions: string[] = selectedKnowledgeLab.configurations?.mimeTypes.map(i => FileFormatExtension.find(a => a.key === i)?.extension.replace('.', '') ?? '') ?? [];
        setFileTypeExtensions(typesExtensions);
        setFileTypes(selectedKnowledgeLab.configurations?.mimeTypes ?? []);

        const currentLang = (i18n.language).split("-")[0];
        const currentTranslations = selectedKnowledgeLab?.configurations?.translations;
        setOverviewMessage(KnowledgeHelpers.getKnowledgeTranslation("pickerInfo", currentTranslations, currentLang));

    }, [selectedKnowledgeLab]); //eslint-disable-line react-hooks/exhaustive-deps

    const onEnrichmentTag = (itemId: string, metadata: any) => { //eslint-disable-line @typescript-eslint/no-explicit-any
        const updatedFiles = selectedFiles.map(file => file.id !== itemId ? file : { ...file, metadata: metadata });
        setSelectedFiles(updatedFiles);
    }

    const someMetadataNotPopulated = () => { //eslint-disable-line @typescript-eslint/no-explicit-any
        return selectedFiles?.some( file =>
            {
                if(file?.metadata !== undefined){
                const metaJson = JSON.parse(file?.metadata);
                if (Object.keys(metaJson).length === 3)
                    return false;                
                }
                return true;
            }
        ); 
        
    }

    const closeModal = () => {
        setCurrentStep(ImportDocumentStep.chooseSource);
        setSelectedFiles([]);
        cancelToken?.cancel();
        props.onClose();
    }

    const nextStep = (currentStep: ImportDocumentStep) => {

        setPreviousStep(currentStep);

        switch (currentStep) {

            case ImportDocumentStep.fileSharepointOverview:
            case ImportDocumentStep.fileEdiOverview:
            case ImportDocumentStep.selectLocalFile:
                enableFileEnrichment ? setCurrentStep(ImportDocumentStep.fileEnrichment)
                    : setCurrentStep(ImportDocumentStep.uploadFiles);
                break;

            case ImportDocumentStep.selectEdiFile:
                setCurrentStep(ImportDocumentStep.fileEdiOverview);
                break;

            case ImportDocumentStep.selectSharepointFile:
                setCurrentStep(ImportDocumentStep.fileSharepointOverview);
                break;

            case ImportDocumentStep.fileEnrichment:
                setCurrentStep(ImportDocumentStep.uploadFiles);
                break;

            case ImportDocumentStep.uploadFiles:
                setCurrentStep(ImportDocumentStep.completed);
                break;

            default:
                break;
        }
    }

    const onUploadEvent = (result: boolean) => {
        setUploadLoading(result);
    }

    const onSendFile = (formData: FormData): Promise<any> => { //eslint-disable-line @typescript-eslint/no-explicit-any
        const path = formData.get("FilePath");
        const driveId = formData.get("DriveId");
        if (path) {
            //edi file
            return knowledgeLabApi.copyFilesFromBlob(formData, cancelToken?.token)
        } else if (driveId) {
            //sharepoint file
            return knowledgeLabApi.copyFilesFromSharepoint(formData, cancelToken?.token)
        }
        return knowledgeLabApi.uploadFiles(formData, cancelToken?.token)
    }

    const steps = [
        {//0
            title: `${t('title')}`,
            body: <>
                <ChoiceInputSource
                    onElementChoice={(e) => {
                        switch (e) {
                            case FileSource.local: setCurrentStep(ImportDocumentStep.selectLocalFile); break;
                            case FileSource.edi: setCurrentStep(ImportDocumentStep.fileEdiOverview); break;
                            case FileSource.sharepoint: setCurrentStep(ImportDocumentStep.fileSharepointOverview); break;
                        }
                    }}></ChoiceInputSource>
            </>,
            footer: <>
                <Stack tokens={{ padding: 10 }}>
                    <Stack.Item align="end">
                        <DefaultButton style={{ marginRight: '10px' }} onClick={closeModal}>{t("cancelButton")}</DefaultButton>
                    </Stack.Item>
                </Stack>
            </>
        },
        {//1 Sharepoint
            title: t("importTitle"),
            body: <>
                <FileImporterOverview onImport={() => { setCurrentStep(ImportDocumentStep.selectSharepointFile) }} files={selectedFiles} setFiles={setSelectedFiles} imageName={ImageName.Sharepoint} pickerMessage={overviewMessage} fileTypes={fileTypes}></FileImporterOverview>
            </>,
            footer: <>
                <Stack tokens={{ padding: 10 }}>
                    <Stack.Item align="end">
                        <DefaultButton style={{ marginRight: '10px' }} onClick={closeModal}>{t("cancelButton")}</DefaultButton>
                        <PrimaryButton disabled={selectedFiles.length === 0} style={{ marginRight: '10px' }} onClick={() => { nextStep(ImportDocumentStep.fileSharepointOverview); }}>{t("confirmButton")}</PrimaryButton>
                    </Stack.Item>
                </Stack>
            </>
        },
        {//2 Edi DocLab
            title: t("importTitle"),
            body: <>
                <FileImporterOverview onImport={() => { setCurrentStep(ImportDocumentStep.selectEdiFile) }} files={selectedFiles} setFiles={setSelectedFiles} imageName={ImageName.EdiAppLogo} pickerMessage={overviewMessage} fileTypes={fileTypes} isEdiFile={true}></FileImporterOverview>
            </>,
            footer: <>
                <Stack tokens={{ padding: 10 }}>
                    <Stack.Item align="end">
                        <DefaultButton style={{ marginRight: '10px' }} onClick={closeModal}>{t("cancelButton")}</DefaultButton>
                        <PrimaryButton disabled={selectedFiles.length === 0} style={{ marginRight: '10px' }} onClick={() => { nextStep(ImportDocumentStep.fileEdiOverview); }}>{t("confirmButton")}</PrimaryButton>
                    </Stack.Item>
                </Stack>
            </>
        },
        {//3 local file
            title: t("importTitle"),
            body: <>

                <FileImporterOverview onImport={() => { setCurrentStep(ImportDocumentStep.selectLocalFile) }} files={selectedFiles} setFiles={setSelectedFiles} imageName={ImageName.EdiAppLogo} pickerMessage={overviewMessage} isLocalFile={true} fileTypes={fileTypes}></FileImporterOverview>

            </>,
            footer: <>
                <Stack tokens={{ padding: 10 }}>
                    <Stack.Item align="end">
                        <DefaultButton style={{ marginRight: '10px' }} onClick={closeModal}>{t("cancelButton")}</DefaultButton>
                        <PrimaryButton style={{ marginRight: '10px' }} disabled={selectedFiles.length === 0} onClick={() => { nextStep(ImportDocumentStep.selectLocalFile); }}>{t("confirmButton")}</PrimaryButton>
                    </Stack.Item>
                </Stack>
            </>

        },
        {//4
            title: t("steps.ediFileSelector.title"),
            body: <>
                <KnowledgeEdiFileSelector defaultSelected={selectedFiles} onChange={(files) => setSelectedFiles(files)} allowedFileTypes={fileTypeExtensions}></KnowledgeEdiFileSelector>
            </>,
            footer: <>
                <Stack tokens={{ padding: 10 }}>
                    <Stack.Item align="end">
                        <DefaultButton style={{ marginRight: '10px' }} onClick={closeModal}>{t("cancelButton")}</DefaultButton>
                        <PrimaryButton style={{ marginRight: '10px' }} disabled={selectedFiles.length === 0} onClick={() => { nextStep(ImportDocumentStep.selectEdiFile); }}>{t("confirmButton")}</PrimaryButton>
                    </Stack.Item>
                </Stack>
            </>

        },
        {//5
            title: t("steps.sharepointFileSelector.title"),
            body: <>
                <KnowledgeSharepointFileSelector defaultSelected={selectedFiles} onChange={(files) => setSelectedFiles(files)} allowedFileTypes={fileTypeExtensions}></KnowledgeSharepointFileSelector>
            </>,
            footer: <>
                <Stack tokens={{ padding: 10 }}>
                    <Stack.Item align="end">
                        <DefaultButton style={{ marginRight: '10px' }} onClick={closeModal}>{t("cancelButton")}</DefaultButton>
                        <PrimaryButton style={{ marginRight: '10px' }} disabled={selectedFiles.length === 0} onClick={() => { nextStep(ImportDocumentStep.selectSharepointFile); }}>{t("confirmButton")}</PrimaryButton>
                    </Stack.Item>
                </Stack>
            </>

        },
        {//6
            title: "Tagga i report",
            body: <>
                <KnowledgeLabFileEnrichment
                    files={selectedFiles}
                    fileTypes={fileTypes}
                    onEnrichmentTagEdit={onEnrichmentTag}
                   
                />
            </>,
            footer: <>
                <Stack tokens={{ padding: 10 }}>
                    <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                        <Stack.Item align="start">
                            <Label style={{ color: 'red' }}>* Campo obbligatorio</Label>
                        </Stack.Item>
                        <Stack.Item align="end">
                            <DefaultButton style={{ marginRight: '10px' }} onClick={() => { setCurrentStep(previousStep); }}>Indietro</DefaultButton>
                            <PrimaryButton style={{ marginRight: '10px' }} disabled = {someMetadataNotPopulated()} onClick={() => { nextStep(ImportDocumentStep.fileEnrichment); }}>{t("confirmButton")}</PrimaryButton>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </>

        },
        {//7
            title: t("steps.uploadFiles.title"),
            body: <>
                <KnowledgeLabFileUploader
                    files={selectedFiles}
                    fileTypes={fileTypes}
                    sendFiles={(formData) => onSendFile(formData)}
                    isUploading={onUploadEvent}
                    maxNumFilesUploadable={10000}
                />
            </>,
            footer: <>
                <Stack tokens={{ padding: 10 }}>
                    <Stack.Item align="end">
                        <DefaultButton style={{ marginRight: '10px' }} onClick={closeModal}>{t("steps.uploadFiles.stopButton")}</DefaultButton>
                        <PrimaryButton style={{ marginRight: '10px' }} disabled={uploadLoading} onClick={() => { nextStep(ImportDocumentStep.uploadFiles) }}>{t("confirmButton")}</PrimaryButton>
                    </Stack.Item>
                </Stack>
            </>

        },
        {//8
            body: <>
                <div className={classNames.completeStepContainer}>
                    <TeamsImage imageName={ImageName.Sandglass} className={classNames.wellDoneImage} fullContainer scale={0.8} />
                    <div className={classNames.footer}>
                        {t('steps.completed.content')}
                        <div>
                            <PrimaryButton text={t('steps.completed.closeButton')}
                                allowDisabledFocus
                                onClick={closeModal}
                                styles={{ root: { padding: '0px 80px', marginTop: '20px' } }}
                            />
                        </div>
                    </div>
                </div>
            </>
        }

    ]

    const showCloseIcon = () => {
        if (currentStep === ImportDocumentStep.completed || currentStep === ImportDocumentStep.uploadFiles)
            return false;

        return true;
    };
    return (
        <>
            {
                props.isOpen &&
                <MultiStepModal
                    className={classNames.root}
                    showCloseIcon={showCloseIcon()}
                    onCloseClick={() => {
                        closeModal();
                    }}
                    width={currentStep === ImportDocumentStep.chooseSource ? 600 : 800}
                    height={600}
                    steps={steps}
                    isOpen={props.isOpen}
                    activeStep={currentStep}
                />
            }
        </>
    );
}