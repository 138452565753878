import { DefaultEffects, IStyleFunction } from "@fluentui/react"
import { IDashboardContainerPropsStyles, IDashboardContainerStyles } from "./dashboardContainer.types"

export const DashboardContainerGlobalClassNames = {
    root: 'edi-dashboard-container-root'
}

export const getStyles: IStyleFunction<IDashboardContainerPropsStyles, IDashboardContainerStyles> = (props: IDashboardContainerPropsStyles): IDashboardContainerStyles => {
    const { palette } = props.theme!;//eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            DashboardContainerGlobalClassNames.root,
            {
                height: '82vh',
                overflowY: 'auto',
                overflowX: 'hidden'
            }
        ],
        title: {
            fontWeight: 'bold',
            fontSize: '21px',
            padding: 15,
        },
        filecount: {
            fontWeight: 'bold',
            fontSize: '16px',
            padding: 15
        },
        error: {
            color: palette.redDark,
            fontWeight: 'bold',
            height: '100%'
        },
        empty: {
            fontWeight: 'bold',
            height: '100%'
        },
        iconStyle:
        {
            fontSize: 14,
            marginLeft: '5px',
            marginRight: '10px',
        },
        spinner: {
            width: '100%',
            height: '100%',
            flexDirection: 'column'
        },
        stackItem: {
            boxShadow: DefaultEffects.elevation8,
            padding: 15,
            background: palette.white
        },
        flexCenter: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        refreshButton: {
            transform: 'rotate(90deg)'
        },
        subComponentStyles: {
            iconButton: {
                root: {
                    color: palette.black,
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`,
                    color: palette.themePrimary
                },
                rootExpanded: {
                    backgroundColor: 'transparent',
                    color: palette.themePrimary,
                    textShadow: 'rgb(37 36 35) 0px 0px 1px'
                },
                rootPressed: {
                    backgroundColor: 'transparent',

                },
                rootHovered: {
                    backgroundColor: 'transparent',
                },
                rootDisabled: {
                    backgroundColor: 'transparent'
                }
            }
        }
    };
}