/*eslint-disable @typescript-eslint/no-explicit-any*/
/*eslint-disable sonarjs/no-identical-functions*/
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Section } from '../../common/components/tagPanel/tagPanel.types';
import { DocLabState, useDocLabSelector } from '../docLabStore';
import { Archive, JsonSchemaField, ReportType } from '../models/archive';
import { ArchiveItem, FileApprovalStatus, FileSourceType } from '../models/archiveItem';
import { ArchiveRoleWithContentItem } from '../models/archiveRoleWithContentItem';
import { ArchiveRoleId, ArchiveTypeId, Constants, DeletedType, ItemToCopy } from '../models/constants';
import { archivesApi } from '../services/archives/archives.api';
import { SharepointLinkItem } from '../services/archives/archives.contracts';
import { getArchiveContentActionIds, SelectionScopes } from '../utilities/archiveContentActions';
import { ArchiveRoleWithFileDeleted } from '../models/archiveRoleWithFileDeleted';

export type LoadFolderContent = {
    folderId?: number;
    orderBy?: string;
    isAscending?: boolean;
    resetPage: boolean;
    filterForTypes?: boolean;
    deeplink?: boolean;
}

export type SelectionEntry = {
    id: number;
    name?: string;
    isFolder: boolean;
    indexed?: boolean;
    archiveRoleId?: ArchiveRoleId;
    sourceType?: FileSourceType;
    approvalStatus?: FileApprovalStatus;
    alreadyApproved?: boolean;
    metadata?: string;
    folderId?: number;
}

export type loadBreadcrumbEntries = {
    fileId: number;
    isFolder: boolean;
}
export type BreadcrumbEntry = {
    id: number;
    label: string;
}
export type TagMetadataPanel = {
    isOpen: boolean;
    defaultSection: Section;
}

export type ArchiveJsonSchemaLink = {
    archiveLinks: SharepointLinkItem[];
    isLoading: boolean;
    errors: string[];
}

export type Copy = {
    showModal: boolean;
    type: ItemToCopy;
    generateBeLink?: boolean;
}

export type ExportReportInfo = {

}

export type DocumentUploadMetadata = {
    documentId: string;
    documentMetadata: any;
    documentErrorMetadata: any;
}

export type ArchiveContentState = {
    archive?: Archive;
    breadcrumb: BreadcrumbEntry[];
    currentFolderId: number;
    orderBy: string;
    isAscending: boolean;
    currentPageNumber: number;
    items: ArchiveItem[];
    archiveRoleId?: ArchiveRoleId; //Role on parent folder 
    selectedItems: SelectionEntry[];
    selectedItemsActionIds: string[];
    isLoading: boolean;
    isError: boolean;
    uploadFilesModalShown: boolean;
    translationModalShown: boolean;
    folderModalShown: boolean;
    copyLinkModalShown: Copy;
    noMore: boolean;
    lastRequestId: string;
    renameModalShown: boolean;
    renameArchiveModalShown: boolean;
    isDeeplink: boolean;
    managePermissionModalShown: boolean;
    manageProfilePermissionModalShown: boolean;
    manageProfilePermissionModalType?: string;
    qCStartModalShown: boolean;
    deleteModalShown: boolean;
    deleteModalType?: string;
    navigationModalShown: boolean;
    navigationModalType?: string;
    tagPanelShown: TagMetadataPanel;
    createTemplateShown: boolean;
    templateShown: boolean,
    currentTemplateId: number | undefined,
    tocListNeedReload: boolean,
    forbiddenErrorOnDeeplink: boolean,
    archiveExportShown: boolean;
    archiveExportScadenzaShown: boolean;
    folderZipExportShown: boolean;
    exportModalManagementReportShown:boolean;
    exportModalFilesMetadataChangesShown:boolean;
    selectedReportIdExportShown: number;
    selectedReportType?: ReportType;
    archiveJsonSchemaLink: ArchiveJsonSchemaLink;
    archiveRolesDest?:ArchiveRoleId[];
    archiveTypes?:ArchiveTypeId[];
    workflowDetailsModalShown?: boolean;
    exportMetadataModalShown: boolean;
    importMetadataModalShown: boolean;
    importACPVModalShown: boolean;
    importDocsTreeModalShown: boolean;
    downloadWfDetailsModalShown?: boolean;
    documentUploadMetadata: DocumentUploadMetadata[];
    filesApprovalDocumentModalShown?: boolean;
    approvalStatusToUpdate?: FileApprovalStatus;
    deletedFiles?: ArchiveItem[];
    deletedCurrentType?: DeletedType;
    currentFolderMetadataJsonSchemaFields?: JsonSchemaField[];
    archivesHasUnivocityRole?: boolean;
}

const initialState: ArchiveContentState = {
    breadcrumb: [],
    currentFolderId: 0,
    orderBy: 'Name',
    isAscending: true,
    currentPageNumber: 0,
    items: [],
    selectedItems: [],
    selectedItemsActionIds: [],
    isLoading: false,
    isError: false,
    uploadFilesModalShown: false,
    translationModalShown: false,
    folderModalShown: false,
    noMore: false,
    lastRequestId: '',
    renameModalShown: false,
    renameArchiveModalShown: false,
    copyLinkModalShown: {showModal: false, type: ItemToCopy.DeepLink},
    managePermissionModalShown: false,
    manageProfilePermissionModalShown: false,
    qCStartModalShown: false,
    deleteModalShown: false,
    navigationModalShown: false,
    tagPanelShown: { isOpen: false, defaultSection: Section.tag },
    createTemplateShown: false,
    templateShown: false,
    currentTemplateId: undefined,
    tocListNeedReload: false,
    isDeeplink: true,
    forbiddenErrorOnDeeplink: false,
    archiveExportShown: false,
    archiveExportScadenzaShown: false,
    folderZipExportShown: false,
    exportModalManagementReportShown:false,
    exportModalFilesMetadataChangesShown:false,
    selectedReportIdExportShown: 0,
    selectedReportType: undefined,
    archiveJsonSchemaLink: { archiveLinks: [], isLoading: false, errors: []},
    workflowDetailsModalShown: false,
    exportMetadataModalShown: false,
    importMetadataModalShown: false,
    importACPVModalShown: false,
    importDocsTreeModalShown: false,
    downloadWfDetailsModalShown: false,
    documentUploadMetadata: [],
    filesApprovalDocumentModalShown: false,
    approvalStatusToUpdate: undefined,
    deletedFiles: [],
    deletedCurrentType: undefined,
}

const pageSize = Constants.PAGE_SIZE;

export const loadRecycleBin = createAsyncThunk<ArchiveRoleWithFileDeleted, LoadFolderContent, {state: DocLabState}>(
    'archives/load-recyclbin-content',
    async (request: LoadFolderContent, thunkApi) => {
        const { archiveContent } = thunkApi.getState();
        return await archivesApi.getFilesDeleted({
            id: request.folderId ?? archiveContent.currentFolderId,
            isAscending: request.isAscending ?? archiveContent.isAscending,
            orderBy: request.orderBy ?? archiveContent.orderBy,
            pageNumber: request.resetPage ? 0 : archiveContent.currentPageNumber,
            pageSize: pageSize,
        });
    }
);

export const loadFolderContent = createAsyncThunk<ArchiveRoleWithContentItem, LoadFolderContent, { state: DocLabState }>(
    'archives/load-archive-content',
    async (request: LoadFolderContent, thunkApi) => {
        const { archiveContent } = thunkApi.getState();

        return await archivesApi.getFolderContent({
            id: request.folderId ?? archiveContent.currentFolderId,
            isAscending: request.isAscending ?? archiveContent.isAscending,
            orderBy: request.orderBy ?? archiveContent.orderBy,
            pageNumber: request.resetPage ? 0 : archiveContent.currentPageNumber,
            pageSize: pageSize
        });
        
    }
);

export const getJsonSchemaProperty = createAsyncThunk('archives/jsonschema-properties', async (archiveId: number) => {
    return await archivesApi.getJsonSchemaProperty(archiveId);
})

export const refreshJsonSchema = createAsyncThunk('archives/refresh-jsonschema-properties', async (archiveId: number) => {
    return await archivesApi.refreshJsonSchema(archiveId);
})

const getSelectionScope = (state: ArchiveContentState) => {
    let scope = SelectionScopes.None;

    state.selectedItems.forEach(entry => {
        if (entry.isFolder) {
            switch (scope) {
                case SelectionScopes.None: scope = SelectionScopes.Folder; break;
                case SelectionScopes.Folder: scope = SelectionScopes.Folders; break;
                case SelectionScopes.File: scope = SelectionScopes.FilesAndFolders; break;
                case SelectionScopes.Files: scope = SelectionScopes.FilesAndFolders; break;
                case SelectionScopes.FilesAndFolders: return scope;
            }
        }
        else {
            switch (scope) {
                case SelectionScopes.None: scope = SelectionScopes.File; break;
                case SelectionScopes.File: scope = SelectionScopes.Files; break;
                case SelectionScopes.Folder: scope = SelectionScopes.FilesAndFolders; break;
                case SelectionScopes.Folders: scope = SelectionScopes.FilesAndFolders; break;
                case SelectionScopes.FilesAndFolders: return scope;
                case SelectionScopes.FileDeleted: scope = SelectionScopes.FileDeleted; break;
            }
        }
    });

    return scope;
}


export const calculateBreadcrumbFromDeeplink = createAsyncThunk('folders/load-breadcrumb', async (request: loadBreadcrumbEntries) => {
    return await archivesApi.getBreadcrumbFromDeeplink(request.fileId, request.isFolder);
})

export const getArchivesSettings = createAsyncThunk('archives/settings', async (archiveId: number) => {
    return await archivesApi.getArchiveSettings(archiveId);
})

const calculateBreadcrumbEntries = (state: ArchiveContentState, folderId: number) => {
    const index = state.breadcrumb.findIndex(entry => entry.id === folderId);
    if (index !== -1)
        return state.breadcrumb.slice(0, index + 1);

    const folderName = state.items.find(item => item.isFolder && item.id === folderId)?.name;
    return [...state.breadcrumb, { id: folderId, label: folderName ?? state.archive?.name ?? "" }];
}

const archiveContentSlice = createSlice({
    name: 'docLab-archive-content',
    initialState,
    reducers: {
        setCurrentArchive: (state: ArchiveContentState, action: PayloadAction<{archive: Archive | undefined, avoidOverwrite?: boolean}>) => {
            const archive = action.payload?.archive;
            state.archive = archive;
            state.currentFolderId = archive?.rootFolderId ?? 0;
            if(!action.payload?.avoidOverwrite){
                state.breadcrumb = [];
            }
            state.selectedItemsActionIds = archive ? getArchiveContentActionIds(SelectionScopes.None, archive, state.selectedItems, state.archiveRoleId, state.breadcrumb.length) : [];
        },
        setDeletedFiles: (state: ArchiveContentState, action: PayloadAction<ArchiveItem[]>) => {
            state.deletedFiles = action.payload;
            state.selectedItemsActionIds = [];
        },
        selectItemsOnDelete: (state: ArchiveContentState, action: PayloadAction<SelectionEntry[]>) => {
            state.selectedItems = action.payload;
            state.selectedItemsActionIds = action.payload.length ? getArchiveContentActionIds(SelectionScopes.FileDeleted, state.archive, state.selectedItems, state.archiveRoleId, state.breadcrumb.length) : [];
        },
        selectItems: (state: ArchiveContentState, action: PayloadAction<SelectionEntry[]>) => {
            state.selectedItems = action.payload;
            state.selectedItemsActionIds = getArchiveContentActionIds(getSelectionScope(state), state.archive, state.selectedItems, state.archiveRoleId,  state.breadcrumb.length);
        },
        showUploadFilesModal: (state: ArchiveContentState, action: PayloadAction<boolean>) => {
            state.uploadFilesModalShown = action.payload;
        },
        showTranslationModal: (state: ArchiveContentState, action: PayloadAction<boolean>) => {
            state.translationModalShown = action.payload;
        },
        setIsDeeplink: (state: ArchiveContentState, action: PayloadAction<boolean>) => {
            state.isDeeplink = action.payload;
        },
        showCreateFolderModal: (state: ArchiveContentState, action: PayloadAction<boolean>) => {
            state.folderModalShown = action.payload;
        },
        showRenameModal: (state: ArchiveContentState, action: PayloadAction<boolean>) => {
            state.renameModalShown = action.payload;
        },
        showRenameArchiveModal: (state: ArchiveContentState, action: PayloadAction<boolean>) => {
            state.renameArchiveModalShown = action.payload;
        },                
        showCopyLinkModal: (state: ArchiveContentState, action: PayloadAction<Copy>) => {
            state.copyLinkModalShown = action.payload;
        },
        showArchiveExport:(state: ArchiveContentState, action: PayloadAction<{show: boolean, reportId: number}>) => {
            state.archiveExportShown = action.payload.show;
            state.selectedReportIdExportShown = action.payload.reportId;
        },
        showFolderZipExport:(state: ArchiveContentState, action: PayloadAction<{show: boolean, reportId: number}>) => {
            state.folderZipExportShown = action.payload.show;
            state.selectedReportIdExportShown = action.payload.reportId;
        },
        showExportModalManagementReport:(state: ArchiveContentState, action: PayloadAction<{show: boolean, reportId: number}>) => {
            state.exportModalManagementReportShown = action.payload.show;
            state.selectedReportIdExportShown = action.payload.reportId;
        },
        showExportFilesMetadataChangesReport:(state: ArchiveContentState, action: PayloadAction<{show: boolean, reportId: number}>) => {
            state.exportModalFilesMetadataChangesShown = action.payload.show;
            state.selectedReportIdExportShown = action.payload.reportId;
        },
        showManagePermissionModal: (state: ArchiveContentState, action: PayloadAction<boolean>) => {
            state.managePermissionModalShown = action.payload;
        },
        showManageProfilePermissionModal: (state: ArchiveContentState, action: PayloadAction<{show: boolean, type?: string}>) => {
            state.manageProfilePermissionModalShown = action.payload.show;
            state.manageProfilePermissionModalType = action.payload.type ? action.payload.type : undefined;
        },
        showDeleteModal: (state: ArchiveContentState, action: PayloadAction<{show: boolean, type?: string, deletedType?: DeletedType}>) => {
            state.deleteModalShown = action.payload.show;
            state.deleteModalType = action.payload.type ? action.payload.type : undefined;
            state.deletedCurrentType = action.payload.deletedType ? action.payload.deletedType : undefined;
        },
        showNavigationModal: (state: ArchiveContentState, action: PayloadAction<{
            show: boolean, 
            roles?: ArchiveRoleId[], 
            type?: string, 
            archiveTypes?: ArchiveTypeId[]}>) => {
                state.archiveRolesDest = action.payload.roles ? action.payload.roles : [];
                state.navigationModalShown = action.payload.show;
                state.navigationModalType=action.payload.type;
                state.archiveTypes = action.payload.archiveTypes ? action.payload.archiveTypes : [];
        },
        showQCStartModal: (state: ArchiveContentState, action: PayloadAction<boolean>) => {
            state.qCStartModalShown = action.payload;
        },
        showTagPanel: (state: ArchiveContentState, action: PayloadAction<TagMetadataPanel>) => {
            state.tagPanelShown = action.payload;
        },
        showCreateTemplate: (state: ArchiveContentState, action: PayloadAction<boolean>) => {
            state.createTemplateShown = action.payload;
        },
        showTemplate: (state: ArchiveContentState, action: PayloadAction<boolean>) => {
            state.templateShown = action.payload
        },
        setCurrentTemplate: (state: ArchiveContentState, action: PayloadAction<number | undefined>) => {
            state.currentTemplateId = action.payload
        },
        tocListNeedReloadFired: (state: ArchiveContentState, action: PayloadAction<boolean>) => {
            state.tocListNeedReload = action.payload
        },
        setArchiveExportScadenzaShow: (state: ArchiveContentState, action: PayloadAction<{show: boolean, reportId: number, reportType?: ReportType}>) => {
            state.archiveExportScadenzaShown = action.payload.show;
            state.selectedReportIdExportShown = action.payload.reportId;
            state.selectedReportType = action.payload.reportType;
        },
        setArchiveManagementReportShow:(state: ArchiveContentState, action: PayloadAction<{show: boolean, reportId: number, reportType?: ReportType}>) => {
            state.archiveExportScadenzaShown = action.payload.show;
            state.selectedReportIdExportShown = action.payload.reportId;
            state.selectedReportType = action.payload.reportType;
        },

        setArchiveFilesMetadataChangesReportShow:(state: ArchiveContentState, action: PayloadAction<{show: boolean, reportId: number, reportType?: ReportType}>) => {
            state.exportModalFilesMetadataChangesShown = action.payload.show;
            state.selectedReportIdExportShown = action.payload.reportId;
            state.selectedReportType = action.payload.reportType;
        },
        setArchiveInfo: (state: ArchiveContentState, action: PayloadAction<{types: ArchiveTypeId[], roles: ArchiveRoleId[]}>)=>{
            state.archiveTypes = action.payload.types;
            state.archiveRolesDest = action.payload.roles;
        },
        showWorkflowDetailsModal: (state: ArchiveContentState, action: PayloadAction<boolean>) => {
            state.workflowDetailsModalShown = action.payload;
        },
        showExportMetadataModal:(state: ArchiveContentState, action: PayloadAction<{show: boolean, reportId: number}>) => {
            state.exportMetadataModalShown = action.payload.show;
            state.selectedReportIdExportShown = action.payload.reportId;
        },
        showImportMetadataModal:(state: ArchiveContentState, action: PayloadAction<{show: boolean, reportId: number}>) => {
            state.importMetadataModalShown = action.payload.show;
            state.selectedReportIdExportShown = action.payload.reportId;
        },
        showImportACPVModal:(state: ArchiveContentState, action: PayloadAction<{show: boolean, reportId: number}>) => {
            state.importACPVModalShown = action.payload.show;
            state.selectedReportIdExportShown = action.payload.reportId;
        },
        showImportDocsTreeModal:(state: ArchiveContentState, action: PayloadAction<{show: boolean, reportId: number}>) => {
            state.importDocsTreeModalShown = action.payload.show;
            state.selectedReportIdExportShown = action.payload.reportId;
        },
        showDownloadWfDetailsModal: (state: ArchiveContentState, action: PayloadAction<boolean>) => {
            state.downloadWfDetailsModalShown = action.payload;
        },
        setDocumentUploadMetadata: (state: ArchiveContentState, action: PayloadAction<DocumentUploadMetadata[]>) => {
            state.documentUploadMetadata = action.payload;
        },
        showFilesApprovalDocumentModal: (state: ArchiveContentState, action: PayloadAction<{show: boolean, updateStatus?: FileApprovalStatus}>) => {
            state.filesApprovalDocumentModalShown = action.payload.show;
            state.approvalStatusToUpdate = action.payload.updateStatus;
        },
        setArchivesHasUnivocityRole: (state: ArchiveContentState, action: PayloadAction<{archivesHasUnivocityRole: boolean}>) => {
            state.archivesHasUnivocityRole = action.payload.archivesHasUnivocityRole;
            console.log (state.archivesHasUnivocityRole);
        }
    },
    extraReducers: builder => {
        builder.addCase(loadFolderContent.pending, (state, action) => {
            
            state.isLoading = true;
            state.isError = false;
            state.lastRequestId = action.meta.requestId;
            state.forbiddenErrorOnDeeplink = false;
            state.archiveRoleId = undefined; 
            const request = action.meta.arg;

            if (request.folderId) {
                state.currentFolderId = request.folderId;

                if (!request.deeplink) {
                    state.breadcrumb = calculateBreadcrumbEntries(state, request.folderId);
                }
                state.selectedItemsActionIds = getArchiveContentActionIds(getSelectionScope(state), state.archive, state.selectedItems, state.archiveRoleId, state.breadcrumb.length);
            }

            if (request.isAscending !== undefined) state.isAscending = request.isAscending;
            if (request.orderBy) state.orderBy = request.orderBy;
            request.resetPage ? state.currentPageNumber = 0 : state.currentPageNumber = state.currentPageNumber + 1;
        });

        builder.addCase(loadFolderContent.fulfilled, (state, action) => {
            if (state.lastRequestId !== action.meta.requestId)
                return;
            state.forbiddenErrorOnDeeplink = false;
            state.isLoading = false;
            state.currentFolderMetadataJsonSchemaFields = action.payload.metadataJsonSchemaFields;
            if (action.meta.arg.resetPage)
                state.items = [];

            if (action.payload.contentItems.length > 0)
                state.items = [...state.items, ...action.payload.contentItems];

            state.noMore = action.payload?.contentItems.length < pageSize;
            state.archiveRoleId = action.payload.archiveRoleId; 

            state.selectedItemsActionIds = getArchiveContentActionIds(getSelectionScope(state), state.archive, state.selectedItems, state.archiveRoleId, state.breadcrumb.length);
        });

        builder.addCase(loadFolderContent.rejected, (state, action) => {
            if (state.lastRequestId !== action.meta.requestId)
                return;

            state.isLoading = false;
            state.forbiddenErrorOnDeeplink = action.error.message?.toLowerCase() === "forbidden";
            state.isError = true;
            state.items = [];
            state.archiveRoleId = undefined;
            state.selectedItemsActionIds = getArchiveContentActionIds(SelectionScopes.None, state.archive, state.selectedItems, state.archiveRoleId, state.breadcrumb.length);
        });

        builder.addCase(calculateBreadcrumbFromDeeplink.pending, (state) => {
            state.isLoading = true;
            state.forbiddenErrorOnDeeplink = false;
        });

        builder.addCase(calculateBreadcrumbFromDeeplink.fulfilled, (state, action) => {
            const request = action.meta.arg;
            if (request !== undefined) {
                state.breadcrumb = [...action.payload];
                state.selectedItemsActionIds = getArchiveContentActionIds(getSelectionScope(state), state.archive, state.selectedItems, state.breadcrumb.length);
            }
        });

        builder.addCase(calculateBreadcrumbFromDeeplink.rejected, (state, action) => {
            state.isLoading = false;
            state.forbiddenErrorOnDeeplink = action.error.message?.toLowerCase() === "forbidden";
        });

        builder.addCase(getJsonSchemaProperty.pending, (state) => {
            state.archiveJsonSchemaLink.isLoading = true;
            state.archiveJsonSchemaLink.archiveLinks = [];
            state.archiveJsonSchemaLink.errors = [];
        });

        builder.addCase(getJsonSchemaProperty.fulfilled, (state, action) => {
            state.archiveJsonSchemaLink.archiveLinks = action.payload;
            state.archiveJsonSchemaLink.isLoading = false;
        });

        builder.addCase(getJsonSchemaProperty.rejected, (state) => {
            state.archiveJsonSchemaLink.isLoading = false;
        });

        builder.addCase(refreshJsonSchema.pending, (state) => {
            state.archiveJsonSchemaLink.isLoading = true;
            state.archiveJsonSchemaLink.errors = [];
        });

        builder.addCase(refreshJsonSchema.fulfilled, (state, action) => {
            state.archiveJsonSchemaLink.archiveLinks = action.payload;
            state.archiveJsonSchemaLink.isLoading = false;
        });

        builder.addCase(refreshJsonSchema.rejected, (state, action) => {
            state.archiveJsonSchemaLink.isLoading = false;
            state.archiveJsonSchemaLink.errors = JSON.parse(action.error.message ?? "[]");
        });

        builder.addCase(loadRecycleBin.pending, (state, action) => {
            state.isLoading = true;
            state.isError = false;
            state.lastRequestId = action.meta.requestId;
            state.forbiddenErrorOnDeeplink = false;
            state.archiveRoleId = undefined; 
            const request = action.meta.arg;

            if (request.folderId) {
                state.currentFolderId = request.folderId;

                if (!request.deeplink) {
                    state.breadcrumb = calculateBreadcrumbEntries(state, request.folderId);
                }
                state.selectedItemsActionIds = getArchiveContentActionIds(SelectionScopes.FileDeleted, state.archive, state.selectedItems, state.archiveRoleId, state.breadcrumb.length);
            }

            if (request.isAscending !== undefined) state.isAscending = request.isAscending;
            if (request.orderBy) state.orderBy = request.orderBy;
            request.resetPage ? state.currentPageNumber = 0 : state.currentPageNumber = state.currentPageNumber + 1;
        });

        builder.addCase(loadRecycleBin.fulfilled, (state, action) => {
            if (state.lastRequestId !== action.meta.requestId)
                return;
            state.forbiddenErrorOnDeeplink = false;
            state.isLoading = false;
            if (action.meta.arg.resetPage)
                state.deletedFiles = [];

            if (action.payload.fileDeleted.length > 0)
                state.deletedFiles = [...state.deletedFiles!, ...action.payload.fileDeleted]; /* eslint-disable-line @typescript-eslint/no-non-null-assertion */

            state.noMore = action.payload?.fileDeleted.length < pageSize;
            state.archiveRoleId = action.payload.archiveRoleId; 

            state.selectedItemsActionIds = [];
        });

        builder.addCase(loadRecycleBin.rejected, (state, action) => {
            if (state.lastRequestId !== action.meta.requestId)
                return;

            state.isLoading = false;
            state.forbiddenErrorOnDeeplink = action.error.message?.toLowerCase() === "forbidden";
            state.isError = true;
            state.deletedFiles = [];
            state.archiveRoleId = undefined;
            state.selectedItemsActionIds = getArchiveContentActionIds(SelectionScopes.FileDeleted, state.archive, state.selectedItems, state.archiveRoleId, state.breadcrumb.length);
        });
    }
})

export const { setCurrentArchive, selectItems, showUploadFilesModal, showArchiveExport, showFolderZipExport, setIsDeeplink, showDeleteModal, showNavigationModal, showCreateFolderModal, showRenameModal, showRenameArchiveModal, showCopyLinkModal, showManagePermissionModal, showManageProfilePermissionModal, showQCStartModal, showTagPanel, showCreateTemplate, showTemplate, setCurrentTemplate, tocListNeedReloadFired, setArchiveExportScadenzaShow, showTranslationModal, setArchiveInfo, showWorkflowDetailsModal, showExportMetadataModal, showImportMetadataModal, showImportACPVModal, showImportDocsTreeModal, showDownloadWfDetailsModal, setDocumentUploadMetadata, showFilesApprovalDocumentModal, setDeletedFiles, selectItemsOnDelete,setArchiveManagementReportShow,setArchiveFilesMetadataChangesReportShow,setArchivesHasUnivocityRole } = archiveContentSlice.actions;
export default archiveContentSlice.reducer;

export const useArchiveContent = () => useDocLabSelector(state => state.archiveContent);
export const useArchiveListContent = () => useDocLabSelector(state => state.archiveList);