import React from "react";
import { IAuthorizeRoleProps } from "./authorizeRole.types";
import { useKnowledgeLabList } from "../../../features/knowledgeLabList";


export const AuthorizeRoleBase = (props: IAuthorizeRoleProps) => {
    const knowledgelab = useKnowledgeLabList();

    return (
        <div>
            {props.admittedRoles.find(i => i === knowledgelab.currentKnowledgeLabRoleId) !== undefined ? props.children : null}
        </div>
    );
}