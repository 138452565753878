import { CancelToken } from "axios";
import { ActivityPriority, ActivityStatus, PhaseStatus } from "../../models/constants";

export type GetActivitiesRequest = {
    pageNumber: number;
    pageSize: number;
    orderBy: string | undefined;
    isAscending: boolean | undefined;
    status: number | undefined,
    priorities: ActivityPriority[] | undefined;
    activityStatus: ActivityStatus[] | undefined;
    phaseStatus: PhaseStatus[] | undefined;
    templateActivities: number[] | undefined;
    createdOn?: Date;
    dueDate?: Date;
    portfolioId: number | undefined;
    cancelToken?: CancelToken;
}

export const defaultGetActivitiesRequest: GetActivitiesRequest = {
    pageNumber: 0,
    pageSize: 20,
    orderBy: 'CreatedOn',
    isAscending: false,
    status: undefined,
    priorities: [],
    activityStatus: [],
    phaseStatus: [],
    templateActivities: [],
    createdOn: undefined,
    dueDate: undefined,
    portfolioId: undefined
}