export type Dataset = {
    id: number,
    name: string,
    trainerName: string,
    trainerEmail: string,
    skillName: string,
    createdOn: string,
    updatedOn?: string,
    datasetStatus: DatasetStatus
}

export enum DatasetStatus {
    Ready = 0,
    Sent = 1,
    Created = 2,
    Training = 3,
    Trained = 4,
    Deployed = 5,
    Error = -1,
}

export const dashboardDatasetStatusText = [
    { key: DatasetStatus.Ready, value: "status.ready" },
    { key: DatasetStatus.Sent, value: "status.sent" },
    { key: DatasetStatus.Created, value: "status.created" },
    { key: DatasetStatus.Training, value: "status.training" },
    { key: DatasetStatus.Trained, value: "status.trained" },
    { key: DatasetStatus.Deployed, value: "status.deployed" },
    { key: DatasetStatus.Error, value: "status.error" }
]