/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable sonarjs/no-identical-functions */
/*eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState } from "react";
import { classNamesFunction, IconButton, keyframes, Label, Stack } from "@fluentui/react";
import { IDashboardContainerPropsStyles, IDashboardContainerStyles, IDashboardContainerProps } from "./dashboardContainer.types";
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { useTranslation } from "react-i18next";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { ErrorsType } from "../../../../docLab/models/callsApi";
import { useAsyncApi } from "../../../../utilities/hooks";
import { useCurrentPortfolio } from "../../../hooks/useCurrentPortfolio";
import { activityApi } from "../../../services/activity/activity.api";
import { ActivityForActivityLeader, ActivitySplitByPriority, ActivitySplitByStatus } from "../../../models/activity";
import { Doughnut } from "react-chartjs-2";
import { ActivityPriority, ActivityStatus, PortfolioRoleId } from "../../../models/constants";
import GraphActivityForLeader from "../graphActivityForLeader/graphActivityForLeader";
import GraphWorkflowStatus from "../graphWorkflowStatus/graphWorkflowStatus";
import GraphWorkflowUsers from "../graphWorkflowUsers/graphWorkflowUsers";
import { workflowApi } from "../../../services/workflow/workflow.api";
import { WorkFlowForUser, WorkFlowSplitByStatus } from "../../../models/workflow";
import _ from "lodash";

const getClassNames = classNamesFunction<IDashboardContainerPropsStyles, IDashboardContainerStyles>();

export const DashboardContainerBase = (props: IDashboardContainerProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { palette, semanticColors } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const [activitiesStatus, setActivitiesStatus] = useState<Array<ActivitySplitByStatus>>([]);
    const [activitiesPriorities, setActivitiesPriorities] = useState<Array<ActivitySplitByPriority>>([]);
    const [activitiesForLeader, setActivitiesForLeader] = useState<Array<ActivityForActivityLeader>>([]);
    const [sentWorkflowsStatus, setSentWorkflowsStatus] = useState<Array<WorkFlowSplitByStatus>>([]);
    const [receivedWorkflowsStatus, setReceivedWorkflowsStatus] = useState<Array<WorkFlowSplitByStatus>>([]);
    const [workflowsForUser, setWorkflowsForUser] = useState<Array<WorkFlowForUser>>([]);

    const [errorActivitiesStatus, setErrorActivitiesStatus] = useState<string>("");
    const [errorActivitiesPriorities, setErrorActivitiesPriorities] = useState<string>("");
    const [errorActivitiesForLeader, setErrorActivitiesForLeader] = useState<string>("");
    const [errorWorkflowsForUser, setErrorWorkflowsForUser] = useState<string>("");
    const [errorSentWorkflowsStatus, setErrorSentWorkflowsStatus] = useState<string>("");
    const [errorReceivedWorkflowsStatus, setErrorReceivedWorkflowsStatus] = useState<string>("");

    const [refresh, setRefresh] = useState<boolean>(true);
    const [globalLoading, setGlobalLoading] = useState(true);
    const { t } = useTranslation(['dashboard', 'activity', 'common']);

    const genericErrorApiTraslate = t('common:genericErrorApi');
    const genericEmptyGraph = t('empty');
    const currentPortfolio = useCurrentPortfolio();
    const retryToRefresh = () => {
        _.delay(() => setRefresh(true), 500);
      }
    useEffect(() => {
        if (currentPortfolio && refresh) {
            getActivitiesStatus();
            getActivitiesPriorities();
            getActivitiesForLeader();
            getWorkflowsForUser();
            getReceivedWorkflowsStatus();
            getSentWorkflowsStatus();
            setRefresh(false);
        }
        else if (refresh)
            retryToRefresh()
    }, [refresh, currentPortfolio])//eslint-disable-line react-hooks/exhaustive-deps


    const { execute: getActivitiesStatus, loading: loadingGetActivitiesStatus } = useAsyncApi<void, Array<ActivitySplitByStatus>>({
        func: async () => {
            if (!currentPortfolio)
                return [];
            try {
                const result = await activityApi.getActivitiesSplitByStatus(currentPortfolio.id);
                setActivitiesStatus(result);
                return result;
            }
            catch (er) {
                const error: ErrorsType = er as ErrorsType;
                if (error.code === 403) {
                    setErrorActivitiesStatus(t("errors.errorCode403"));
                }
                else if (error.code === 422) {
                    setErrorActivitiesStatus(t("errors.errorCode422"));
                } else {
                    setErrorActivitiesStatus(genericErrorApiTraslate);
                }
                return [];
            }
        }
    });

    const { execute: getActivitiesPriorities, loading: loadingGetActivitiesPriorities } = useAsyncApi<void, Array<ActivitySplitByPriority>>({
        func: async () => {
            if (!currentPortfolio)
                return [];
            try {
                const result = await activityApi.getActivitiesSplitByPriority(currentPortfolio.id);
                setActivitiesPriorities(result);
                return result;
            }
            catch (er) {
                const error: ErrorsType = er as ErrorsType;
                if (error.code === 403) {
                    setErrorActivitiesPriorities(t("errors.errorCode403"));
                }
                else if (error.code === 422) {
                    setErrorActivitiesPriorities(t("errors.errorCode422"));
                } else {
                    setErrorActivitiesPriorities(genericErrorApiTraslate);
                }
                return [];
            }
        }
    });

    const { execute: getActivitiesForLeader, loading: loadingGetActivitiesForLeader } = useAsyncApi<void, Array<ActivityForActivityLeader>>({
        func: async () => {
            if (!currentPortfolio)
                return [];
            try {
                const result = await activityApi.getActivitiesForActivityLeader(currentPortfolio.id);
                setActivitiesForLeader(result);
                return result;
            }
            catch (er) {
                const error: ErrorsType = er as ErrorsType;
                if (error.code === 403) {
                    setErrorActivitiesForLeader(t("errors.errorCode403"));
                }
                else if (error.code === 422) {
                    setErrorActivitiesForLeader(t("errors.errorCode422"));
                } else {
                    setErrorActivitiesForLeader(genericErrorApiTraslate);
                }
                return [];
            }
        }
    });

    const { execute: getReceivedWorkflowsStatus, loading: loadingGetReceivedWorkflowsStatus } = useAsyncApi<void, Array<WorkFlowSplitByStatus>>({
        func: async () => {
            if (!currentPortfolio)
                return [];
            try {
                const result = await workflowApi.getWorkflowsStatus({ portfolioId: currentPortfolio.id, getSentWorkFlows: false });
                setReceivedWorkflowsStatus(result);
                return result;
            }
            catch (er) {
                const error: ErrorsType = er as ErrorsType;
                if (error.code === 403) {
                    setErrorReceivedWorkflowsStatus(t("errors.errorCode403"));
                }
                else if (error.code === 422) {
                    setErrorReceivedWorkflowsStatus(t("errors.errorCode422"));
                } else {
                    setErrorReceivedWorkflowsStatus(genericErrorApiTraslate);
                }
                return [];
            }
        }
    });

    const { execute: getSentWorkflowsStatus, loading: loadingGetSentWorkflowsStatus } = useAsyncApi<void, Array<WorkFlowSplitByStatus>>({
        func: async () => {
            if (!currentPortfolio)
                return [];
            try {
                const result = await workflowApi.getWorkflowsStatus({ portfolioId: currentPortfolio.id, getSentWorkFlows: true });
                setSentWorkflowsStatus(result);
                return result;
            }
            catch (er) {
                const error: ErrorsType = er as ErrorsType;
                if (error.code === 403) {
                    setErrorSentWorkflowsStatus(t("errors.errorCode403"));
                }
                else if (error.code === 422) {
                    setErrorSentWorkflowsStatus(t("errors.errorCode422"));
                } else {
                    setErrorSentWorkflowsStatus(genericErrorApiTraslate);
                }
                return [];
            }
        }
    });

    const { execute: getWorkflowsForUser, loading: loadingGetWorkflowsForUser } = useAsyncApi<void, Array<WorkFlowForUser>>({
        func: async () => {
            if (!currentPortfolio || currentPortfolio.portfolioRoleId === PortfolioRoleId.Viewer)
                return [];
            try {
                const result = await workflowApi.getWorkflowsForUser(currentPortfolio.id);
                setWorkflowsForUser(result);
                return result;
            }
            catch (er) {
                const error: ErrorsType = er as ErrorsType;
                if (error.code === 403) {
                    setErrorWorkflowsForUser(t("errors.errorCode403"));
                }
                else if (error.code === 422) {
                    setErrorWorkflowsForUser(t("errors.errorCode422"));
                } else {
                    setErrorWorkflowsForUser(genericErrorApiTraslate);
                }
                return [];
            }
        }
    });

    useEffect(() => {
        if (!(loadingGetActivitiesStatus || loadingGetActivitiesPriorities || loadingGetActivitiesForLeader || loadingGetWorkflowsForUser || loadingGetReceivedWorkflowsStatus || loadingGetSentWorkflowsStatus || refresh))
            setGlobalLoading(false);
    }, [loadingGetActivitiesStatus, loadingGetActivitiesPriorities, loadingGetActivitiesForLeader, loadingGetWorkflowsForUser, loadingGetReceivedWorkflowsStatus, loadingGetSentWorkflowsStatus, refresh]);

    const activitiesStatusData = {
        labels: activitiesStatus.map(as => t(`activity:status:${as.status}`)),
        datasets: [
            {
                data: activitiesStatus.map(as => as.count),
                backgroundColor: [semanticColors.bodyText, palette.green, palette.redDark, palette.yellow],
                borderColor: 'transparent'
            },
        ],
    };
    const activityStatusDoughnutOptions = {
        maintainAspectRatio: false,
        responsive: true,
        cutout: 90,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    color: semanticColors.bodyText
                }
            },
            tooltip: {
                callbacks: {
                    afterLabel: (context: any) => { //eslint-disable-line @typescript-eslint/no-explicit-any
                        const activityStatus = context.dataIndex as ActivityStatus;

                        const statusCount = activitiesStatus.filter(as => as.status === activityStatus);
                        if (statusCount.length > 0)
                            return `${((statusCount[0].count / _.sum(activitiesStatus.map(as => as.count))) * 100).toFixed(2)}%`;
                    }
                }
            }
        },
    };

    const activitiesPriorityData = {
        labels: activitiesPriorities.map(ap => t(`activity:priority:${ap.priority}`)),
        datasets: [
            {
                data: activitiesPriorities.map(ap => ap.count),
                backgroundColor: [palette.themeTertiary, palette.themeSecondary, palette.themePrimary],
                borderColor: 'transparent'
            },
        ],
    };
    const activityPriorityDoughnutOptions = {
        maintainAspectRatio: false,
        responsive: true,
        cutout: 90,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    color: semanticColors.bodyText
                }
            },
            tooltip: {
                callbacks: {
                    afterLabel: (context: any) => { //eslint-disable-line @typescript-eslint/no-explicit-any
                        const activityPriority = context.dataIndex as ActivityPriority;
                        const priorityCount = activitiesPriorities.filter(ap => ap.priority === activityPriority);
                        if (priorityCount.length > 0)
                            return `${((priorityCount[0].count / _.sum(activitiesPriorities.map(ap => ap.count))) * 100).toFixed(2)}%`;
                    }
                }
            }
        },
    };

    return (
        <React.Fragment>
            {!currentPortfolio && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsImage imageName={ImageName.Oops1} /></div>}
            {currentPortfolio &&
                <React.Fragment>
                    <div style={{ height: 50 }}>
                        <div className={classNames.refreshButtonContainer}>
                            <IconButton
                                disabled={refresh || globalLoading}
                                className={classNames.refreshButton}
                                styles={classNames.subComponentStyles.iconButton()}
                                style={refresh ? { animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite` } : {}}
                                iconProps={{ iconName: "Refresh" }}
                                onClick={() => { setRefresh(true); setGlobalLoading(true); }}
                            />
                        </div>
                    </div>

                    {/* MAIN STACK */}
                    <Stack className={classNames.root} tokens={{ childrenGap: 20, padding: 15 }}>

                        <Stack horizontal tokens={{ childrenGap: 20, padding: 0 }}>
                            <Stack.Item align="stretch" style={{ width: 'calc(50% - 35px)', height: 370 }} className={classNames.stackItem}>
                                <div className={classNames.title}>{t('portfolio.activitiesStatus.title')}</div>
                                <div className={classNames.flexCenter} style={{ height: 300 }}>
                                    {globalLoading && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
                                    {!errorActivitiesStatus && !globalLoading && activitiesStatus && activitiesStatus.length > 0
                                        && <React.Fragment>
                                            <span className={classNames.doughnutTotal}>{_.sum(activitiesStatus.map(as => as.count))}</span>
                                            <Doughnut data={activitiesStatusData} options={activityStatusDoughnutOptions} />
                                        </React.Fragment>
                                    }
                                    {!errorActivitiesStatus && !globalLoading && (!activitiesStatus || activitiesStatus.length === 0) && <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
                                    {errorActivitiesStatus && !globalLoading && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorActivitiesStatus}</Label>}
                                </div>
                            </Stack.Item>
                            <Stack.Item align="stretch" style={{ width: 'calc(50% - 35px)', height: 370 }} className={classNames.stackItem}>
                                <div className={classNames.title}>{t('portfolio.activitiesPriorities.title')}</div>
                                <div className={classNames.flexCenter} style={{ height: 300 }}>
                                    {globalLoading && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
                                    {!errorActivitiesPriorities && !globalLoading && activitiesPriorities && activitiesPriorities.length > 0
                                        && <React.Fragment>
                                            <span className={classNames.doughnutTotal}>{_.sum(activitiesPriorities.map(ap => ap.count))}</span>
                                            <Doughnut data={activitiesPriorityData} options={activityPriorityDoughnutOptions} />
                                        </React.Fragment>
                                    }
                                    {!errorActivitiesPriorities && !globalLoading && (!activitiesPriorities || activitiesPriorities.length === 0) && <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
                                    {errorActivitiesPriorities && !globalLoading && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorActivitiesPriorities}</Label>}
                                </div>
                            </Stack.Item>
                        </Stack>

                        <Stack horizontal>
                            <Stack.Item style={{ width: '100%', height: 500, overflow: 'hidden' }} className={classNames.stackItem}>
                                <div className={classNames.title}>{t('portfolio.activitiesLeaders.title')}</div>
                                <div className={classNames.flexCenter} style={{ height: 400, overflow: 'auto hidden' }}>
                                    {globalLoading && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
                                    {!errorActivitiesForLeader && !globalLoading && activitiesForLeader && activitiesForLeader.length > 0
                                        && <GraphActivityForLeader activitiesForLeader={activitiesForLeader} />}
                                    {!errorActivitiesForLeader && !globalLoading && (!activitiesForLeader || activitiesForLeader.length === 0) && <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
                                    {errorActivitiesForLeader && !globalLoading && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorActivitiesForLeader}</Label>}
                                </div>
                            </Stack.Item>
                        </Stack>

                        <Stack horizontal>
                            <Stack.Item style={{ width: '100%', height: 370 }} className={classNames.stackItem}>
                                <div className={classNames.title}>{t('portfolio.workflowStatus.title')}</div>
                                <div style={{ height: 300 }}>
                                    {globalLoading && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
                                    {!globalLoading && errorReceivedWorkflowsStatus === "" && errorSentWorkflowsStatus === "" && (receivedWorkflowsStatus || sentWorkflowsStatus)
                                        && <GraphWorkflowStatus
                                            initialSentWorkflows={sentWorkflowsStatus}
                                            initialReceivedWorkflows={receivedWorkflowsStatus} />}
                                    {!errorReceivedWorkflowsStatus && !errorSentWorkflowsStatus && !globalLoading && !receivedWorkflowsStatus && !sentWorkflowsStatus &&
                                        <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
                                    {errorReceivedWorkflowsStatus && errorSentWorkflowsStatus && !globalLoading && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorReceivedWorkflowsStatus}</Label>}
                                </div>
                            </Stack.Item>
                        </Stack>

                        {currentPortfolio?.portfolioRoleId === PortfolioRoleId.Owner &&
                            <Stack horizontal>
                                <Stack.Item style={{ width: '100%', height: 500, overflow: 'hidden' }} className={classNames.stackItem}>
                                    <div className={classNames.title}>{t('portfolio.workflowUsers.title')}</div>
                                    <div className={classNames.flexCenter} style={{ height: 400, overflow: 'auto hidden' }}>
                                        {globalLoading && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
                                        {!errorWorkflowsForUser && !globalLoading && workflowsForUser && workflowsForUser.length > 0
                                            && <GraphWorkflowUsers workflowUsers={workflowsForUser} />}
                                        {!errorWorkflowsForUser && !globalLoading && (!workflowsForUser || workflowsForUser.length === 0) && <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
                                        {errorWorkflowsForUser && !globalLoading && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorWorkflowsForUser}</Label>}
                                    </div>
                                </Stack.Item>
                            </Stack>
                        }
                    </Stack>
                </React.Fragment>
            }
        </React.Fragment>
    );
}