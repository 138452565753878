import { useCallback, useMemo } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { dashboardRoutes, dashboardTab } from "../routes/routes";

export interface INavigator {
    isdashboardRoute: boolean;
    currentTab?: dashboardTab;
    changeTab: (tab?: dashboardTab) => void
    goToPath: (path: string) => void;
    goBack: () => void;
}

export const useNavigator = (): INavigator => {
    const location = useLocation();
    const history = useHistory();
    const routePath = useMemo(() =>
        matchPath<{ tab: dashboardTab }>(location.pathname, { path: dashboardRoutes.dashboardOptionals }),
        [location.pathname]);

    const isdashboardRoute = useMemo(() => routePath !== null, [routePath]);

    const currentTab = useMemo(() => {
        return routePath?.params.tab ?? dashboardTab.feedback
    }, [routePath]);

    const changeTab = useCallback((tab?: dashboardTab) => {
        const toPush = dashboardRoutes.generatedashboardTabPath(tab ?? dashboardTab.feedback);
        history.push(toPush);
    }, [history]);

    const goToPath = useCallback((path: string) => history.push(path), [history]);

    const goBack = useCallback(() => history.goBack(), [history]);

    return {
        isdashboardRoute: isdashboardRoute,
        currentTab: currentTab,
        changeTab: changeTab,
        goToPath: goToPath,
        goBack: goBack
    }
}