import { IDetailsListStyleProps, IDetailsListStyles, IShimmeredDetailsListStyleProps, IShimmeredDetailsListStyles, IStyleFunction } from "@fluentui/react"
import { IDatasetsPropsStyles, IDatasetsStyles } from "./datasets.types"

export const DatasetsGlobalClassNames = {
    root: 'edi-datasets-root',
    personaCell: 'edi-datasets-persona',
    rootDialog: 'edi-datasets-delete-modal-root',
    buttonContainer: 'edi-datasets-delete-modal-buttonContainer',
    footerContainer: 'edi-datasets-delete-modal-footerContainer',
    datasetName: 'edi-datasets-datasetName'
}

export const getStyles: IStyleFunction<IDatasetsPropsStyles, IDatasetsStyles> = (props: IDatasetsPropsStyles): IDatasetsStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            DatasetsGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            },
            props.isEmptyList && {
                '> .ms-Viewport .ms-DetailsList-contentWrapper': {
                    flexBasis: '0 !important',
                }
            },
        ],
        emptyFolder: {
            height: '204px',
            paddingTop: '52px',
            paddingBottom: '174px'
        },
        personaCell: [
            DatasetsGlobalClassNames.personaCell,
            {
                ".ms-Persona": {
                    justifyContent: "center!important"
                }
            }
        ],
        buttonContainer: [
            DatasetsGlobalClassNames.buttonContainer,
            {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'
            }],
        footerContainer: [
            DatasetsGlobalClassNames.footerContainer,
            {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
            }],
        subComponentStyles: {
            shimmeredDetailsList: (props: IShimmeredDetailsListStyleProps): IShimmeredDetailsListStyles => {
                return {
                    root: {
                        '::after': {
                            display: 'none'
                        },
                        position: 'relative'
                    }
                }
            },
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto'
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        flexBasis: 'calc(100vh - 201px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            },
            iconButton: {
                root: {
                    height: '100%',
                    float: 'right'
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`
                },
                menuIcon: {
                    display: 'none !important'
                },
                rootExpanded: {
                    backgroundColor: 'inherit'
                },
                rootPressed: {
                    backgroundColor: 'inherit'
                },
                rootHovered: {
                    backgroundColor: 'inherit',
                }
            },
            dialogFooterContainer: {
                actions: {
                    margin: '8px 0 0'
                }
            },
            primaryButtonDisabled: {
                root: {
                    selectors: {
                        ':disabled': {
                            backgroundColor: palette.neutralQuaternary, // 'rgb(237, 235, 233)',
                            color: palette.neutralTertiary, //'rgb(200, 198, 196)',
                            pointerEvents: 'none'
                        },
                    }
                },
            }
        },
        datasetName: {
            cursor: 'pointer',
            selectors: {
                ':hover': {
                    textDecoration: 'underline'
                }
            }

        }
    };
}