import { FontWeights, IStyleFunction } from "@fluentui/react"
import { ISubParagraphModalPropsStyles, ISubParagraphModalStyles } from "./subParagraphModal.types"

export const SubParagraphModalGlobalClassNames = {
    root: 'edi-sub-paragraph-modal-root',
    container: 'edi-sub-paragraph-modal-container',
    content: 'edi-sub-paragraph-modal-content',
    header: 'edi-sub-paragraph-modal-header',
    title: 'edi-sub-paragraph-modal-title',
    closeIcon: 'edi-sub-paragraph-modal-close-icon',
    body: 'edi-sub-paragraph-modal-body',
    bodyContent: 'edi-sub-paragraph-modal-body-content',
    bodyScrollableContent: 'edi-sub-paragraph-modal-body-scroll-content',
    footer: 'edi-sub-paragraph-modal-footer',
    upperContent: 'edi-sub-paragraph-modal-upper-content',
}

export const getStyles: IStyleFunction<ISubParagraphModalPropsStyles, ISubParagraphModalStyles> = (props: ISubParagraphModalPropsStyles): ISubParagraphModalStyles => {

    const { palette, fonts } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            SubParagraphModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        container: [
            SubParagraphModalGlobalClassNames.container,
            {
                padding: '27px 0px 0px 0px',
                width: props.width,
                height: props.height,
                right: 34,
                overflow: "hidden"
            }
        ],
        upperContent: [
            SubParagraphModalGlobalClassNames.upperContent,
            {
                padding: '0px 32px 0px 32px',
                height: '100%',
            }
        ],
        content: [
            SubParagraphModalGlobalClassNames.content,
            {
                '*::-webkit-scrollbar': {
                    width: 16,
                    height: 16
                },
                '*::-webkit-scrollbar-thumb': {
                    border: '5px solid transparent',
                    backgroundClip: 'padding-box',
                    backgroundColor: 'transparent',
                    borderRadius: 25
                },
                '*:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: palette.neutralSecondary// 'rgba(66, 66, 66, 0.3)'
                },
                '*::-webkit-scrollbar-thumb:hover': {
                    border: '2px solid transparent'
                },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                '> :not(:first-child)': {
                    marginTop: 27
                },
                overflow: "hidden"

            }
        ],
        header: [
            SubParagraphModalGlobalClassNames.header,
            fonts.large,
            {
                display: 'flex',
                justifyContent: 'space-between',
                // alignItems: 'flex-start',
                alignItems: 'center',
                fontWeight: FontWeights.bold,
                height: 36
            }
        ],
        title: [
            SubParagraphModalGlobalClassNames.title,
            {
                wordBreak: 'break-word'
            }
        ],
        closeIcon: [
            SubParagraphModalGlobalClassNames.closeIcon,
            {
            }
        ],
        body: [
            SubParagraphModalGlobalClassNames.body,
            {
                flexGrow: 1,
                height: 'calc(100% - 36px)'
            }
        ],
        bodyContent: [
            SubParagraphModalGlobalClassNames.bodyContent,
            {
                height: '100%',
                '> :first-child': {
                    height: '100%',
                },
            }
        ],
        footer: [
            SubParagraphModalGlobalClassNames.footer,
            {
                paddingTop: '10px',
                textAlign: 'right',
                position: 'sticky',
                bottom: '0px'
            }
        ],
        loadingErrorContainer: {
            height: 'calc(100%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        subComponentStyles: {
            iconButton: {
                root: {
                    color: palette.neutralDark,
                    marginLeft: 'auto',
                    marginTop: '4px',
                    marginRight: '2px',
                },
                rootHovered: {
                    color: palette.neutralPrimary,
                }
            }
        },
        copyButtonDiv: {
            width: '100%',
            textAlign: 'end'
        },
    };
}