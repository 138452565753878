import { styled } from "@fluentui/react";
import { getStyles } from "./folderContent.styles";
import { IFolderContentPropsStyles, IFolderContentStyles, IFolderContentProps } from "./folderContent.types";
import { FolderContentBase } from "./folderContent.base";

const FolderContent = styled<IFolderContentProps, IFolderContentPropsStyles, IFolderContentStyles>(
    FolderContentBase,
    getStyles,
    undefined,
    { scope: 'FolderContent' }
);

export default FolderContent;