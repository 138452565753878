import { IFolder } from "../../../../docLab/models/folder";

export interface IManagerFolder extends IFolder{
  collapsed: boolean;
  hidden: boolean;
  selected: boolean;
  canExpand: boolean;
  canSelect: boolean;
  documentOfId: number | undefined;
  selectedAllChildren: boolean;
}

export type SearchResultSelectedFolder = {
  folderIds: number[];
  foldersNames: string;
}

export const FolderProfileMaxNumber = 4500;