import { styled } from "@fluentui/react";
import { EditTopicsOfInterestModalBase } from "./editTopicsOfInterestModal.base";
import { getStyles } from "./editTopicsOfInterestModal.styles";
import { IEditTopicsOfInterestModalBaseProps, IEditTopicsOfInterestModalBasePropsStyles, IEditTopicsOfInterestModalBaseStyles } from "./editTopicsOfInterestModal.types";

const EditTopicsOfInterestModal = styled<IEditTopicsOfInterestModalBaseProps, IEditTopicsOfInterestModalBasePropsStyles, IEditTopicsOfInterestModalBaseStyles>(
    EditTopicsOfInterestModalBase, 
    getStyles,
    undefined,
    { scope: 'EditTopicsOfInterestModal'}
)

export default EditTopicsOfInterestModal;