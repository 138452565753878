/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useCallback } from "react";
import { DetailsList, DetailsRow, IColumn, SelectionMode, classNamesFunction } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IDocVerifyModalBodyProps, IDocVerifyModalBodyPropsStyles, IDocVerifyModalBodyStyles } from "./docVerifyModalBody.types";
import TeamsImage from "../teamsImage/teamsImage";
import { ImageName } from "../teamsImage/teamsImage.types";
import { DocVerifySource, ResultDocumentMetadataUnivocity } from "../../../docLab/models/archiveUnivocityRule";
import FileIconCell from "../fileIconCell/fileIconCell";

const getClassNames = classNamesFunction<IDocVerifyModalBodyPropsStyles, IDocVerifyModalBodyStyles>();

export const DocVerifyModalBodyBase = (props: IDocVerifyModalBodyProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['docVerifyPages']);

    const emptyList = useCallback(() => {
        if (props.item.notUnivocityDocumentList.length !== 0)
            return null;

        return (
            <div className={classNames.emptyList}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyList")}
                />
            </div>
        )
    }, [props.item.notUnivocityDocumentList.length, t, classNames.emptyList]);

    const setTableLabel = () => {
        let label = ''

        if (props.item.isUserBlocked) {
            if (props.requestSource === DocVerifySource.Upload)
                label = t('tableTitle1')
            else
                label = t('tableTitle2')
        }
        else {
            if (props.requestSource === DocVerifySource.Upload)
                if (props.item.errorOnFileMetadataList.length > 0)
                    label = t('tableTitle3')
                else
                    label = t('tableTitle4')
            else
                if (props.item.errorOnFileMetadataList.length > 0)
                    label = t('tableTitle5')
                else
                    label = t('tableTitle6')
        }

        return label;
    }

    const columns: IColumn[] = [
        {
            key: 'Name',
            name: t("columns.nameDoc"),
            fieldName: 'Name',
            minWidth: 190,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            onRender: function getItemName(item: ResultDocumentMetadataUnivocity) {
                const extIndex = item.fileName.lastIndexOf('.');
                const ext = extIndex !== -1 ? item.fileName.substring(extIndex) : "";
                return <div className={classNames.cellDocName}>
                    <FileIconCell fileExtension={ext} isFolder={false} />
                    <span className={classNames.title} title={item.fileName}>{item.fileName}</span>
                </div>
            }
        },
        {
            key: 'NamePrevDoc',
            name: t("columns.NamePrevDoc"),
            fieldName: 'NamePrevDoc',
            minWidth: 190,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            onRender: function getItemName(item: ResultDocumentMetadataUnivocity) {
                const extIndex = item.fileDuplicateName.lastIndexOf('.');
                const ext = extIndex !== -1 ? item.fileDuplicateName.substring(extIndex) : "";
                return <div className={classNames.cellDocName}>
                    <FileIconCell fileExtension={ext} isFolder={false} />
                    <span className={classNames.title} title={item.fileDuplicateName}>{item.fileDuplicateName}</span>
                </div>
            }
        },
        {
            key: 'Attribute1',
            name: t("columns.Attribute1"),
            fieldName: 'Attribute1',
            minWidth: 190,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            onRender: function getItemName(item: ResultDocumentMetadataUnivocity) {
                return <span>{item.metadataFileValue1}</span>
            }
        },
        {
            key: 'Attribute2',
            name: t("columns.Attribute2"),
            fieldName: 'Attribute2',
            minWidth: 190,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            onRender: function getItemName(item: ResultDocumentMetadataUnivocity) {
                return <span>{item.metadataFileValue2}</span>
            }
        },
        {
            key: 'Attribute3',
            name: t("columns.Attribute3"),
            fieldName: 'Attribute3',
            minWidth: 190,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            onRender: function getItemName(item: ResultDocumentMetadataUnivocity) {
                return <span>{item.metadataFileValue3}</span>
            }
        }
    ]

    return (
        <div className={classNames.root}>
            {props.item.errorOnFileMetadataList.length > 0 &&
                props.item.errorOnFileMetadataList.map((fileError, index) => {
                    return (<div key={index} className={classNames.errorStyle}>{t('error') + fileError.fileName + " => " + fileError.errorOnMetadata}</div>)
                })
            }
            {props.item.notUnivocityDocumentList.length > 0 &&
                <>
                    <div className={classNames.tableTitle} >{setTableLabel()}</div>
                    <DetailsList
                        items={props.item.notUnivocityDocumentList}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        isHeaderVisible={true}
                        getKey={((item: ResultDocumentMetadataUnivocity) => item.fileName.toString())}
                        setKey="none"
                        styles={classNames.subComponentStyles.detailsList}
                        onRenderRow={props => props ? <DetailsRow {...props} /> : null}
                        onRenderDetailsFooter={emptyList}
                    />
                </>
            }
        </div>
    )
}