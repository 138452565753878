import { classNamesFunction } from "@fluentui/react";
import { IFileIconCellPropsStyles, IFileIconCellStyles, IFileIconCellProps } from "./fileIconCell.types";
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import React from "react";

const getClassNames = classNamesFunction<IFileIconCellPropsStyles, IFileIconCellStyles>();

export const FileIconCellBase = (props: IFileIconCellProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    return (
        <div className={classNames.fileIconWrapper}>
            {props.isFolder ?
                <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20 })} />
                :
                props.fileExtension === ".p7m" ?
                <Icon
                iconName="PageLock"
                style={{color: "darkgrey", fontSize: "larger"}}                
                />
                :
                <Icon {...getFileTypeIconProps({ type: FileIconType.genericFile, extension: props.fileExtension, size: 20 })} />
            }
        </div>
    );
}