/*eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from "react";
import { classNamesFunction, DatePicker, defaultDatePickerStrings, Icon } from "@fluentui/react";
import { IDatePickerProps, IDatePickerPropsStyles, IDatePickerStyles } from "./datePicker.types";
import { useOnMount } from "../../../utilities/hooks";
import { useTranslation } from "react-i18next";

const getClassname = classNamesFunction<IDatePickerPropsStyles, IDatePickerStyles>();
/*eslint-disable sonarjs/no-identical-functions */

export const DatePickerBase = (props: IDatePickerProps) => {
    const classNames = getClassname(props.styles, { theme: props.theme, className: props.className });

    const { t } = useTranslation(['createActivity', 'common']);
    const [value, setValue] = useState<Date | undefined>();

    useEffect(() => {
        setValue(props.inputValue);
    }, [props.inputValue])


    useOnMount(() => {
        // Ovveride for datepicker label
        defaultDatePickerStrings.days = t("dataPickerStrings.days", { returnObjects: true });
        defaultDatePickerStrings.months = t("dataPickerStrings.months", { returnObjects: true })
        defaultDatePickerStrings.closeButtonAriaLabel = t("dataPickerStrings.closeButtonAriaLabel", { returnObjects: true })
        defaultDatePickerStrings.dayMarkedAriaLabel = t("dataPickerStrings.dayMarkedAriaLabel", { returnObjects: true })
        defaultDatePickerStrings.goToToday = t("dataPickerStrings.goToToday", { returnObjects: true })
        defaultDatePickerStrings.invalidInputErrorMessage = t("dataPickerStrings.invalidInputErrorMessage", { returnObjects: true })
        defaultDatePickerStrings.isRequiredErrorMessage = t("dataPickerStrings.isRequiredErrorMessage", { returnObjects: true })
        defaultDatePickerStrings.monthPickerHeaderAriaLabel = t("dataPickerStrings.monthPickerHeaderAriaLabel", { returnObjects: true })
        defaultDatePickerStrings.shortMonths = t("dataPickerStrings.shortMonths", { returnObjects: true })
        defaultDatePickerStrings.shortDays = t("dataPickerStrings.shortDays", { returnObjects: true })
    });

    const onParseDateFromString = React.useCallback(
        (newValue: string): Date => {
            const previousValue = value || new Date();
            const newValueParts = (newValue || '').trim().split('/');
            const day =
                newValueParts.length > 0 ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10))) : previousValue.getDate();
            const month =
                newValueParts.length > 1
                    ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
                    : previousValue.getMonth();
            let year = newValueParts.length > 2 ? parseInt(newValueParts[2], 10) : previousValue.getFullYear();
            if (year < 100) {
                year += previousValue.getFullYear() - (previousValue.getFullYear() % 100);
            }
            const date = new Date(year, month, day);

            //fix per cancellare la data se non valida
            if (props.minDate && date < props.minDate)
                return props.minDate;

            return date;
        },
        [value, props.minDate],
    );

    const onFormatDate = (date?: Date): string => {
        if (date) {
            let day = '' + date?.getDate();
            let month = '' + (date?.getMonth() + 1);
            const year = date?.getFullYear();

            if (date?.getDate() < 10)
                day = '0' + date?.getDate();
            if ((date?.getMonth() + 1) < 10)
                month = '0' + (date?.getMonth() + 1);

            return !date ? '' : day + '/' + month + '/' + year;
        }
        return '';
    };

    const withResetButton = () => {
        return <div className={classNames.container} >
            <DatePicker
                isRequired={props.isRequired}
                allowTextInput={props.allowTextInput}
                showMonthPickerAsOverlay
                highlightSelectedMonth
                disableAutoFocus={props.disableAutoFocus !== undefined ? props.disableAutoFocus : true}
                disabled={props.disabled}
                label={props.label}
                value={props.inputValue ?? value}
                onSelectDate={(newValue) => {
                    if (newValue) {
                        props.dateCallback(newValue);
                        setValue(newValue);
                    }
                }}
                formatDate={onFormatDate}
                parseDateFromString={onParseDateFromString}
                // DatePicker uses English strings by default. For localized apps, you must override this prop.
                strings={defaultDatePickerStrings}
                minDate={props.minDate}
                styles={{
                    root:{
                        flexGrow: 1
                    }
                }}
                placeholder={props.placeholder}
                className={classNames.root}
                textField={ props.isRequiredErrorMessage === '' ? {errorMessage: props.isRequiredErrorMessage} : {}}
            />
            <Icon
            onClick={(ev)=>{props.onReset && props.onReset(); ev.preventDefault();ev.stopPropagation();}}
            className={props.resetIconStyle}
            styles={{
                root: {
                    fontSize: 10,
                    padding: "7px 5px 10px",
                    cursor: "pointer"
                }
            }} iconName="ChromeClose"/>
        </div>
    }

    return (
        props.resetButton ? withResetButton() :
            <DatePicker
                isRequired={props.isRequired}
                allowTextInput={props.allowTextInput}
                showMonthPickerAsOverlay
                highlightSelectedMonth
                disableAutoFocus={props.disableAutoFocus !== undefined ? props.disableAutoFocus : true}
                disabled={props.disabled}
                label={props.label}
                value={props.inputValue ?? value}
                onSelectDate={(newValue) => {
                    if (newValue) {
                        props.dateCallback(newValue);
                        setValue(newValue);
                    }
                }}
                formatDate={onFormatDate}
                parseDateFromString={onParseDateFromString}
                // DatePicker uses English strings by default. For localized apps, you must override this prop.
                strings={defaultDatePickerStrings}
                minDate={props.minDate}
                maxDate={props.maxDate}
                placeholder={props.placeholder}
                className={classNames.root}
                textField={ props.isRequiredErrorMessage === '' ? {errorMessage: props.isRequiredErrorMessage} : {}}
            />
    );
}