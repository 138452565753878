import { styled } from "@fluentui/react";
import { getStyles } from "./renameItemModal.styles";
import { IRenameItemModalPropsStyles, IRenameItemModalStyles, IRenameItemModalProps } from "./renameItemModal.types";
import { RenameItemModalBase } from "./renameItemModal.base";

const RenameItemModal = styled<IRenameItemModalProps, IRenameItemModalPropsStyles, IRenameItemModalStyles>(
    RenameItemModalBase,
    getStyles,
    undefined,
    { scope: 'RenameItemModal' }
);

export default RenameItemModal;