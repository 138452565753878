/*eslint-disable @typescript-eslint/no-explicit-any */
/*eslint-disable sonarjs/cognitive-complexity */

import React, { classNamesFunction, Dropdown, TextField } from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../../../common/components/datePicker/datePicker";
import { FormItemType } from "../../../../../utilities/constants";
import InputTextTyped from "../../../common/inputTextTyped/inputTextTyped";

import { IFormItemProps, IFormItemPropsStyles, IFormItemStyles } from "./formItem.types";

const getClassNames = classNamesFunction<IFormItemPropsStyles, IFormItemStyles>();

export enum FieldForm {
  Description = 0,
  ActivityType,
  ActivityPiority,
  ActivityStartDate,
  ActivityDueDate,
  ActivityBudget,
  ActivityStatus
}

export enum PhaseFieldForm {
  Description = 0,
  StartDate,
  DueDate,
  Status
}

export const FormItemBase = (props: IFormItemProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, alignHorizontal: props.alignHorizontal });
  const { t } = useTranslation(["infoAndStatus", "common"])
  const [value] = useState({ data: props.defaultValue, isValid: false });

  const input = () => {
    switch (props.type) {
      case FormItemType.Text: return <TextField
        label={props.label}
        disabled={props.disabled}
        maxLength={props.maxLength || 50}
        resizable={false}
        defaultValue={value.data}
        placeholder={props.disabled ? "" : props.placeHolder}
        styles={classNames.subComponentStyles.textField}
      />;

      case FormItemType.DatePicker: return <>
        <div className={classNames.labelContainerClassname}>
          <label>{props.label}</label></div>
        <DatePicker
          styles={classNames.subComponentStyles.datePicker}
          inputValue={props.defaultData}
          disabled={props.disabled}
          dateCallback={(newValue) => undefined}
          placeholder={props.disabled ? "" : t('insertDatePlaceholder')}
        /></>;

      case FormItemType.Dropdown: return <div style={props.alignHorizontal ?
        { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 50 } : undefined}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}>
          <label>{props.label}</label></div>
        <Dropdown
          disabled={props.disabled}
          options={props.options || []}
          styles={classNames.subComponentStyles.dropdown}
          defaultSelectedKey={props.dropdownSelected}
          placeholder={props.disabled ? "" : props.placeHolder}
          required={props.required}
          errorMessage={undefined}
        /></div>;

      case FormItemType.MultiText: return <TextField
        label={props.label}
        maxLength={props.maxLength || 255}
        resizable={false}
        rows={props.rows || 4}
        disabled={props.disabled}
        multiline
        styles={classNames.subComponentStyles.textField}
        defaultValue={value.data}
        placeholder={props.disabled ? "" : props.placeHolder}
      />;
      case FormItemType.Numeric: return <div></div>;
      case FormItemType.TextCurrency: return <>
        <div style={{paddingBottom:5}} className={classNames.labelContainerClassname}>
          <label>{props.label}</label>
        </div>
        <InputTextTyped
          dropdownOptions={props.options || []}
          disabled={props.disabled}
          inputValue={props.defaultValue || "0"}
          dropdownPlaceholder={"€/$"}
          selectedDropodown={props.dropdownSelected}
          textValueCallback={() =>  undefined }
          dropdownValueCallback={(option) => undefined}
        /></>
      default: return <></>
    }
  }

  return (
    <div className={props.className}>
      {input()}
    </div>
  );
}