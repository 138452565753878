import { generatePath } from "react-router-dom";
import { AppRoutes, AppTab } from "../../app/utilities/routes";

export enum KnowledgeDsTab {
    feedback = 'feedback',
    datasets = 'datasets',
}

class KnowledgeDsRoutesClass {
    public knowledgeDsBase = AppRoutes.generateRouteForTab(AppTab.knowledgeDS);
    public knowledgeLabOptionals = `${this.knowledgeDsBase}/:tab(${KnowledgeDsTab.feedback}|${KnowledgeDsTab.datasets})?`;

    generateKnowledgeDsPath() {
        return generatePath(`${this.knowledgeDsBase}`);
    }

    generateKnowledgeDsTabPath(tab: KnowledgeDsTab) {
        return `${this.generateKnowledgeDsPath()}/${tab}`;
    }
}

export const KnowledgeDsRoutes = new KnowledgeDsRoutesClass();