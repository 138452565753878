import { styled } from "@fluentui/react";
import { getStyles } from "./portfoliosList.styles";
import { IPortfoliosListPropsStyles, IPortfoliosListStyles, IPortfoliosListProps } from "./portfoliosList.types";
import { PortfoliosListBase } from "./portfoliosList.base";

const PortfoliosList = styled<IPortfoliosListProps, IPortfoliosListPropsStyles, IPortfoliosListStyles>(
    PortfoliosListBase,
    getStyles,
    undefined,
    { scope: 'PortfoliosList' }
);

export default PortfoliosList;