import { styled } from "@fluentui/react";
import { SharepointLinkBase } from "./sharepointLink.base";
import { getStyles } from "./sharepointLink.styles";
import { ISharepointLinkProps, ISharepointLinkPropsStyles, ISharepointLinkStyles } from "./sharepointLink.types";

const SharepointLink = styled<ISharepointLinkProps, ISharepointLinkPropsStyles, ISharepointLinkStyles>(
    SharepointLinkBase,
    getStyles,
    undefined,
    { scope: 'SharepointLink' }
);

export default SharepointLink;