/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable sonarjs/no-duplicate-string*/
import React, { useEffect, useState } from "react";
import { classNamesFunction, Icon, IconButton, List, ScrollablePane, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { IExportReportSettingsBaseProps, IExportReportSettingsBasePropsStyles, IExportReportSettingsBaseStyles } from "./exportReportSettings.types"
import { ExportStatus, ReportType } from "../../../models/archive";
import RowReport from "./RowReport/RowReport";
import { archivesApi } from "../../../services/archives/archives.api";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { DateTime } from "luxon";
import { useDocLabDispatch, useDocLabState } from "../../../docLabStore";
import { setArchiveExportScadenzaShow, setArchiveFilesMetadataChangesReportShow, setArchiveManagementReportShow, showArchiveExport, showFolderZipExport } from "../../../features/archiveContent";
import { startDownload } from "../../../features/downloadStatus";
import { nanoid } from "@reduxjs/toolkit";
import ExportModalScadenza from "./exportModalScadenza/ExportModalScadenza";
import { useTranslation } from "react-i18next";
import { setToolbarActions } from "../../../features/toolbarActions";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import ExportFolderZipModal from "./exportFolderZipModal/exportFolderZipModal";
import ExportModalManagementReport from "./ExportModalManagementReport/ExportModalManagementReport";
import ExportModalFilesMetadataChangesReport from "./ExportFilesMetadataChanges/ExportModalFilesMetadataChangesReport";

const  getClassNames = classNamesFunction<IExportReportSettingsBasePropsStyles, IExportReportSettingsBaseStyles>();

export const ExportReportSettingsBase = (props: IExportReportSettingsBaseProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [items, setItems] = useState<Report[]>([]);
    const currentArchive = useCurrentArchive();
    const [loading, setLoading] = useState(false);
    const dispatch = useDocLabDispatch();
    const docLabState = useDocLabState();
    const { t } = useTranslation(['exportArchiveModal']);

    useEffect(() => {
        dispatch(setToolbarActions([]));
        getMetadata();
    }, [currentArchive?.id]) //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(!docLabState.archiveContent.archiveExportShown && !docLabState.archiveContent.exportModalFilesMetadataChangesShown && !docLabState.archiveContent.archiveExportScadenzaShown && !docLabState.archiveContent.folderZipExportShown){
            getMetadata();
        }              

    }, [docLabState.archiveContent.archiveExportShown, docLabState.archiveContent.exportModalFilesMetadataChangesShown, docLabState.archiveContent.archiveExportScadenzaShown, docLabState.archiveContent.folderZipExportShown]) //eslint-disable-line react-hooks/exhaustive-deps

    async function getMetadata() {
        const report : Report[] = [];

        try {
            setLoading(true);
            const result = await archivesApi.getReportMetadata(currentArchive?.id ?? 0, false);
            
            result.forEach(element => {
                const createdOn = new Date(element?.createdOn ?? '');
                const dateFormat = "dd/MM/yyyy HH:mm";
                const zoneLocal = DateTime.now();
                let stringDateDownloadLink = "";
    
                if(element?.status === ExportStatus.onGoing || element?.status === ExportStatus.inError){
                    const previousCreatedOn = new Date(element?.previousReportCreatedOn ?? '');
                    stringDateDownloadLink = DateTime.fromJSDate(previousCreatedOn ?? new Date(), {zone: zoneLocal.zone}).toFormat(dateFormat);
                }
        
                const stringDateFile = DateTime.fromJSDate(createdOn ?? new Date(), {zone: zoneLocal.zone}).toFormat(dateFormat);
                if(stringDateDownloadLink === '')
                    stringDateDownloadLink = stringDateFile;
                
                let centralReportFileName = t('reportName.' + element.reportName).trim();
                if (element.reportType === ReportType.ManagementReport)
                    centralReportFileName = t('managementReportCentralFileName').trim();

                let fileName;
                if (element.reportType === ReportType.ENITER)
                    fileName = getAdditionalTitleFolderZip(element.configs) + '_' + stringDateDownloadLink + '.zip';
                else
                    fileName = currentArchive?.name + '_' + centralReportFileName + '_' + stringDateDownloadLink + '.xlsx';

                const isFieldSelection = getIsFieldSelection(element.configs) ?? true;

                const newItem : Report = 
                {
                    createdOn: stringDateDownloadLink,
                    status: element.status,
                    title: (!element.reportName || element.reportName === '') ? element.archiveReportId?.toString() ?? '' : t('reportName.'+ element.reportName),
                    description: (!element.reportDescription || element.reportDescription === '') ? '' : t(element.reportDescription),
                    fileName: fileName,
                    previousCreatedOn: element.previousReportCreatedOn?.toString(),
                    noReport: element.status === ExportStatus.notExisting || (element.status === ExportStatus.inError && element.previousReportCreatedOn === null),
                    reportId: element.archiveReportId,
                    type: element.reportType,
                    isFieldSelection: isFieldSelection,
                    position: element.reportType === ReportType.Metadata && isFieldSelection ? 0 : element.reportType === ReportType.ENITER ? 1 : element.reportType !== ReportType.ManagementReport? 2 : 3
                }
                report.push(newItem); 
            });
        }
        catch(error) {
            console.error(error);
        }
        finally {
            report.sort((obj1, obj2) => {
                return obj1.position - obj2.position;                
            })
            setItems(report);
            setLoading(false);
        }            
    }

    const onRenderCell = (item?: Report): JSX.Element => {
        return (
            <RowReport
                item={item}
                noReport={item?.noReport}
                onStartNewReport={() => {
                    if (item?.type === ReportType.Metadata && item.isFieldSelection) {                        
                        dispatch(showArchiveExport({show: true, reportId: item.reportId ?? 0}));
                    }
                    if (item?.type === ReportType.Metadata && !item.isFieldSelection) {                        
                        dispatch(setArchiveExportScadenzaShow({show: true, reportId: item.reportId ?? 0, reportType: ReportType.Metadata}));
                    }
                    if (item?.type === ReportType.Keyphrases) {                        
                        dispatch(setArchiveExportScadenzaShow({show: true, reportId: item.reportId ?? 0, reportType: ReportType.Keyphrases}));
                    }
                    if (item?.type === ReportType.ENITER) {                        
                        dispatch(showFolderZipExport({show: true, reportId: item?.reportId ?? 0}));
                    }
                    if (item?.type === ReportType.ManagementReport){
                        dispatch(setArchiveManagementReportShow({show: true, reportId: item.reportId ?? 0, reportType: ReportType.ManagementReport}));
                    }
                    if (item?.type === ReportType.FilesMetadataChanges){
                        dispatch(setArchiveFilesMetadataChangesReportShow({show: true, reportId: item.reportId ?? 0, reportType:ReportType.FilesMetadataChanges}))
                    }
                }}
                onFileClick={() => {
                    dispatch(startDownload(
                        { requestId: nanoid(), file: { id: item?.reportId ?? 0, name: item?.fileName ?? ''}, downloadFunction: archivesApi.downloadReport }));
                }}
                fileExtension={item?.type === ReportType.ENITER ? 'zip' : 'xls'}
                translationName={t('exportArchiveModal')}
            />
        );
    }

    const getIsFieldSelection = (jsonItem: any): boolean | undefined => { //eslint-disable-line @typescript-eslint/no-explicit-any
        if (jsonItem && jsonItem != null) {
            for (const [key, value] of Object.entries(jsonItem)) {
                if (key === "IsFieldSelectionAllowed") {
                    return value === true;
                }
            }
        }
    }

    const getAdditionalTitleFolderZip = (jsonItem: any): string | undefined => { //eslint-disable-line @typescript-eslint/no-explicit-any
        if(jsonItem && jsonItem != null){
            for (const [key, value] of Object.entries(jsonItem)) {
                if(key === "AdditionalTitleFolderZip"){
                    return value as string;
                }
            }
        }
    }

    return (
        <>
        <ScrollablePane style={{position:'relative', overflow:'scroll', height:'100%' }}>
            <Stack className={classNames.root}> 
                <Stack className={classNames.title} horizontal verticalAlign={'center'} styles={{root: {marginBottom: '15px'}}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingRight: '40px'}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Icon iconName={"ExploreData"} className={classNames.iconTitle} />
                            <span style={{ paddingLeft: 10 }}>Reports</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{fontSize: '12px', marginBottom: '3px', marginRight: '40px'}}>
                                { t('note')}
                            </div>
                            <IconButton
                                iconProps={{ iconName: "Refresh" }}
                                onClick={() => getMetadata()}
                            />
                        </div>
                    </div>
                </Stack>
                <Stack>
                    {
                        items.length === 0 && !loading ? <>
                            <TeamsImage
                                imageName={ImageName.SurprisedWoman}
                                style={{
                                    height: '250px',
                                    width: '400px',
                                    alignSelf: 'center'
                                }}
                                caption={t('noReportAvaiable')}
                            />
                        </> : <></>
                    }
                    {loading ? 
                        <Spinner size={SpinnerSize.large} /> : <List  items={items} onRenderCell={onRenderCell} /> 
                    }            
                </Stack>
                {docLabState.archiveContent.archiveExportScadenzaShown && <ExportModalScadenza onClose={() => dispatch(setArchiveExportScadenzaShow({reportId: 0, show: false}))} />}
                {docLabState.archiveContent.folderZipExportShown && <ExportFolderZipModal />}
                {docLabState.archiveContent.exportModalManagementReportShown && <ExportModalManagementReport onClose={() => dispatch(setArchiveManagementReportShow({reportId: 0, show: false}))} />}
                {docLabState.archiveContent.exportModalFilesMetadataChangesShown && <ExportModalFilesMetadataChangesReport onClose={() => dispatch(setArchiveFilesMetadataChangesReportShow({reportId: 0, show: false}))} />}   
            </Stack></ScrollablePane>
        </>
    )
}

export type Report = {
    title: string;
    description: string;
    createdOn?: string;
    status: ExportStatus;
    fileName?: string;
    noReport?: boolean;
    previousCreatedOn?: string;
    reportId: number | null;
    type: ReportType;
    isFieldSelection: boolean;
    position: number;
    errorMessage?: string;
}