import { useCallback, useMemo } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { loadKnowledge } from "../features/knowledgeLabList";
import { useKnowledgeLabDispatch } from "../knowledgeLabStore";
import { KnowledgeLabShort } from "../models/knowledgeLab";
import { KnowledgeLabRoutes, KnowledgeSettingsTab, KnowledgeTab } from "../routes/routes";

export interface INavigator {
    isKnowledgeLabRoute: boolean;
    currentKnowledgeLabId?: number;
    currentKnowledgeLabTab?: KnowledgeTab;
    changeKnowledgeLab: (knowledgeLabId: KnowledgeLabShort, tab?: KnowledgeTab) => void
    changeKnowledgeLabTab: (tab: KnowledgeTab) => void
    goToPath: (path: string) => void;
    goBack: () => void;
    goToKnowledgeSettingsTab: (tab: KnowledgeSettingsTab, knowledgeLabId?: number) => void;
}

export const useNavigator = (): INavigator => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useKnowledgeLabDispatch();
    const routePath = useMemo(() =>
        matchPath<{ knowledgelabId: string, tab: KnowledgeTab }>(location.pathname, { path: KnowledgeLabRoutes.knowledgeLabOptionals }),
        [location.pathname]);

    const isKnowledgeLabRoute = useMemo(() => routePath !== null, [routePath]);

    const currentKnowledgeLabId = useMemo(() => {
        return routePath?.params.knowledgelabId ? +routePath?.params.knowledgelabId : undefined;
    }, [routePath]);

    const currentKnowledgeLabTab = useMemo(() => routePath?.params.tab, [routePath]);

    const changeKnowledgeLab = useCallback((lab: KnowledgeLabShort, tab?: KnowledgeTab) => {
        history.push(KnowledgeLabRoutes.generateKnowledgeLabTabPath(lab.id, tab ?? KnowledgeTab.search));
        dispatch(loadKnowledge(lab.id));
    }, [currentKnowledgeLabTab, history, dispatch]); //eslint-disable-line react-hooks/exhaustive-deps

    const changeKnowledgeLabTab = useCallback((tab: KnowledgeTab) => {
        if (!currentKnowledgeLabId)
            return;

        history.push(KnowledgeLabRoutes.generateKnowledgeLabTabPath(currentKnowledgeLabId, tab));
    }, [currentKnowledgeLabId, history]);

    const goToPath = useCallback((path: string) => history.push(path), [history]);

    const goBack = useCallback(() => history.goBack(), [history]);

    const goToKnowledgeSettingsTab = useCallback((tab: KnowledgeSettingsTab, knowledgeLabId?: number) => {
        if (!knowledgeLabId && !currentKnowledgeLabId)
            return;

        history.push(KnowledgeLabRoutes.generateKnowledgeLabSettingsTabPath(knowledgeLabId ?? currentKnowledgeLabId ?? 0, tab));
    }, [currentKnowledgeLabId, history])

    return {
        isKnowledgeLabRoute: isKnowledgeLabRoute,
        currentKnowledgeLabId: currentKnowledgeLabId,
        currentKnowledgeLabTab: currentKnowledgeLabTab,
        changeKnowledgeLab: changeKnowledgeLab,
        changeKnowledgeLabTab: changeKnowledgeLabTab,
        goToPath: goToPath,
        goBack: goBack,
        goToKnowledgeSettingsTab: goToKnowledgeSettingsTab
    }
}