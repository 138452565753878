import { IScrollablePaneStyles, IStyleFunction } from "@fluentui/react"
import { IActivityBoardPropsStyles, IActivityBoardStyles } from "./activityBoard.types"

export const ActivityBoardGlobalClassNames = {
    root: 'edi-activity-board-root'
}

export const getStyles: IStyleFunction<IActivityBoardPropsStyles, IActivityBoardStyles> = (props: IActivityBoardPropsStyles): IActivityBoardStyles => {
    return {
        root: [
            ActivityBoardGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        emptyBoard: {
            alignSelf: 'center'
        },
        subComponentStyles: {
            scrollablePane: (): IScrollablePaneStyles => {
                return {
                    root: {},
                    contentContainer: {
                        overflowY: 'auto'
                    },
                    stickyAbove: {},
                    stickyBelow: {},
                    stickyBelowItems: {}
                }
            },
        }
    };
}