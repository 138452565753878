import { styled } from "@fluentui/react";
import { DocumentTagsListBase } from "./documentTagsList.base";
import { getStyles } from "./documentTagsList.styles";
import { IDocumentTagsListProps, IDocumentTagsListPropsStyles, IDocumentTagsListStyles } from "./documentTagsList.types";

const DocumentTagsList = styled<IDocumentTagsListProps, IDocumentTagsListPropsStyles, IDocumentTagsListStyles>(
    DocumentTagsListBase,
    getStyles,
    undefined,
    { scope: 'DocumentTagsList' }
);

export default DocumentTagsList;