import { FontWeights, IIconStyles, IStyleFunction } from "@fluentui/react"
import { IAccordionAnalysisPropsStyles, IAccordionAnalysisStyles } from "./accordionAnalysis.types";

export const AccordionAnalysisGlobalClassNames = {
    root: 'edi-accordion-root',
    header: 'edi-accordion-header',
    title: 'edi-accordion-title'
}

export const getStyles: IStyleFunction<IAccordionAnalysisPropsStyles, IAccordionAnalysisStyles> = (props: IAccordionAnalysisPropsStyles): IAccordionAnalysisStyles => {
    return {
        root: [
            AccordionAnalysisGlobalClassNames.root,
            {
            }
        ],
        header: [
            AccordionAnalysisGlobalClassNames.header,
            {
                
                padding: 10,
                fontSize: '20px',
                alignItems: 'center',
                userSelect: 'none'
            }
        ],
        title: [
            AccordionAnalysisGlobalClassNames.title,
            {
                fontWeight: FontWeights.semibold,
                paddingLeft: 10,
                cursor: 'pointer'
            }
        ],
        subComponentStyles: {
            icon: (): IIconStyles => {
                return {
                    root: {
                        fontSize: '10px',
                        //paddingTop: 5,
                        userSelect: 'none',
                        cursor: 'pointer',
                    }
                }
            }
        }
    };
}