import { apiClient } from '../../../modules/apiClient/apiClient';
import { svcPaths } from '../../../modules/apiClient/config';
import { CurrentUserInfo } from './user.contracts';

class UsersApiClass {
    async getCurrentUserInfo(id: string) {
        const response = await apiClient.get<CurrentUserInfo>(`${svcPaths.users}/users/${id}`);
        return response.data
    }
} 

export const usersApi = new UsersApiClass();