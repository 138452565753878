import { IStyleFunction } from "@fluentui/react"
import { IGlossaryCheckContentPropsStyles, IGlossaryCheckContentStyles } from "./glossaryCheckContent.types"

export const GlossaryCheckContentGlobalClassNames = {
    root: 'edi-glossary-check-content-root',
    iconResized: 'icon-resized',
    contentContainer: 'content-container'
}

export const getStyles: IStyleFunction<IGlossaryCheckContentPropsStyles, IGlossaryCheckContentStyles> = (props: IGlossaryCheckContentPropsStyles): IGlossaryCheckContentStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    
    return {
        root: [
            GlossaryCheckContentGlobalClassNames.root,
            {
                color: palette.black
            }
        ],
        iconResized: [
            GlossaryCheckContentGlobalClassNames.iconResized,
            {
                marginTop: 15,
                height: 30,
                width: 30,
                fontSize: 24,
            }
        ],
        contentContainer: [
            GlossaryCheckContentGlobalClassNames.contentContainer,
            {
                marginTop: 15
            }
        ]
    };
}