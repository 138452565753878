import { styled } from "@fluentui/react";
import { getStyles } from "./shortElementViewer.styles";
import { IShortElementViewerPropsStyles, IShortElementViewerStyles, IShortElementViewerProps } from "./shortElementViewer.types";
import { ShortElementViewerBase } from "./shortElementViewer.base";

const ShortElementViewer = styled<IShortElementViewerProps, IShortElementViewerPropsStyles, IShortElementViewerStyles>(
    ShortElementViewerBase,
    getStyles,
    undefined,
    { scope: 'ShortElementViewer' }
);

export default ShortElementViewer;