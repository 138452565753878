import { DefaultButton, Dialog, DialogFooter, IButtonStyles, IconButton, PrimaryButton } from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export interface IRemoveButtonProps {
    buttonStyles?: IButtonStyles;
    disabled?: boolean;
    removeItem: (input: string) => void;
    item : string;
    message : string;
}

export const RemoveButton = (props: IRemoveButtonProps) => {
    const {  buttonStyles,  } = props;
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const { t } = useTranslation(['common']);

    return (
        <>
            <IconButton
                disabled={props.disabled }
                styles={buttonStyles}
                iconProps={{ iconName: "Cancel" }}
                onClick={() => setShowConfirmDialog(true)}
            />
            <Dialog
                hidden={!showConfirmDialog}
                dialogContentProps={{ title : props.message}}
            >
                <DialogFooter>
                    <DefaultButton  onClick={() => setShowConfirmDialog(false)} text={t('abort')} />
                    <PrimaryButton  onClick={() => {props.removeItem(props.item);setShowConfirmDialog(false)}} text={t('continue')} />
                </DialogFooter>
            </Dialog>
        </>
    )
}