import { styled } from "@fluentui/react";
import { IImportACPVModalProps, IImportACPVModalPropsStyles, IImportACPVModalStyles } from "./importACPVModal.types";
import { getStyles } from "./importACPVModal.styles";
import { ImportACPVModalBase } from "./importACPVModal.base";

const ImportACPVModal = styled<IImportACPVModalProps, IImportACPVModalPropsStyles, IImportACPVModalStyles>(
    ImportACPVModalBase,
    getStyles,
    undefined,
    { scope: 'ImportACPVModal' }
);

export default ImportACPVModal;