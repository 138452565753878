import { styled } from "@fluentui/react";
import { ITopicInfoModalProps, ITopicInfoModalPropsStyles, ITopicInfoModalStyles } from "./topicInfoModals.types";
import { getStyles } from "./topicInfoModal.styles";
import { TopicInfoModalBase } from "./topicInfoModal.base";

const TopicInfoModal = styled<ITopicInfoModalProps, ITopicInfoModalPropsStyles, ITopicInfoModalStyles>(
    TopicInfoModalBase, 
    getStyles,
    undefined,
    { scope: 'TopicInfoModal'}
)

export default TopicInfoModal;