import React, { useCallback, useEffect, useState } from "react";
import { classNamesFunction, Dropdown, Icon, IDropdownOption, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { ISetPropertiesPropsStyles, ISetPropertiesStyles, ISetPropertiesProps } from "./setProperties.types";
import { useTranslation } from "react-i18next";
import { Constants } from "../../../../models/constants";
import { useOnMount } from "../../../../../utilities/hooks";
import { activityApi } from "../../../../services/activity/activity.api";
import { GetActivitiesRequest } from "../../../../services/activity/activity.contracts";
import { useCurrentPortfolio } from "../../../../hooks/useCurrentPortfolio";
import { Helpers } from "../../../../../utilities/helpers";
import { ErrorsType } from "../../../../../docLab/models/callsApi";
import { ActivityShort } from "../../../../models/activity";
/*eslint-disable sonarjs/cognitive-complexity */

const getClassNames = classNamesFunction<ISetPropertiesPropsStyles, ISetPropertiesStyles>();
export type FirstPageData = {
    name: string;
    description?: string;
    activityKey: string | undefined | number;
    isValid: boolean;
    activityId: number;
    activity: ActivityShort | undefined;
}

export const SetPropertiesBase = (props: ISetPropertiesProps) => {
    const [data, setData] = useState<FirstPageData>(props.initialState);
    const [validationIcon, setValidationIcon] = useState('Error');
    const [error, setError] = useState<boolean>(false);
    const [activitiesDropdown, setActivitiesDropdown] = useState<IDropdownOption[]>([]);
    const [loadingActivities, setLoadingActivities] = useState<boolean>(true);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, validationIcon: validationIcon });
    const { t } = useTranslation(['createWorkflow', 'common']);
    const [activities, setActivities] = useState<ActivityShort[]>([]);
    const currentPortfolio = useCurrentPortfolio();

    useOnMount(() => {
        async function fetch() {
            const activitiesRequest: GetActivitiesRequest = {
                pageNumber: 0,
                pageSize: 10000,
                orderBy: "CreatedOn",
                isAscending: false,
                status: undefined,
                priorities: [],
                activityStatus: [],
                phaseStatus: [],
                templateActivities: [],
                createdOn: undefined,
                dueDate: undefined,
                portfolioId: currentPortfolio?.id
            }
            setError(false)
            try {
                const dropdownElements: IDropdownOption[] = [];
                const [elements] = await Promise.all([activityApi.getActivityList(activitiesRequest), Helpers.delay(500)]);
                setActivities(elements);
                elements.forEach(item => {
                    const dropdownElement: IDropdownOption = {
                        key: item.id,
                        text: item.name,
                        title: item.name,
                        data: item.id
                    }
                    dropdownElements.push(dropdownElement);
                });
                setActivitiesDropdown(dropdownElements);
            }
            catch (ex) {
                const error: ErrorsType = ex as ErrorsType;
                console.log("Error while retrieving activities: ", error.message)
                setError(true)
            }
            finally {
                setLoadingActivities(false)
            }
        }
        fetch();

    })

    const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
        setData({ ...data, isValid: !error, name: value?.trim() ?? "" });
        setValidationIcon(error ? 'Error' : 'Completed');
    }, [data]);

    useEffect(() => props.onWorkflowDataChange(data), [data, props]);

    const validateWorkflowName = useCallback((value: string) => {
        if (!value || value.trim() === "")
            return ''; //return `${t('common:fieldRequired')}`;

        setValidationIcon('ProgressRingDots');
    }, []);

    return (
        loadingActivities ? <div className={classNames.errorLoadingContainer}><Spinner size={SpinnerSize.large} /></div> :
            error ?
                <div className={classNames.errorLoadingContainer}>
                    {t('errorRetrievingActivities')}
                </div> :
                activitiesDropdown.length === 0 ? <div className={classNames.errorLoadingContainer}>{t('noActivities')}</div> :
                    <>
                        <div className={classNames.formItem}>
                            <TextField
                                label={t('setProperties.nameLabel')}
                                maxLength={Constants.MAX_WORKFLOW_NAME}
                                placeholder={t('setProperties.namePlaceholder')}
                                onGetErrorMessage={validateWorkflowName}
                                required
                                defaultValue={data.name.trim()}
                                autoFocus
                                autoComplete={"off"}
                                styles={classNames.subComponentStyles.textField}
                                onNotifyValidationResult={onNameValidationResult}
                                onChange={(_, newValue) => setData({ ...data, name: newValue?.trim() || "", isValid: !newValue })}
                                onRenderSuffix={() => <Icon iconName={validationIcon} />}
                            />
                        </div>
                        <div className={classNames.formItem}>
                            <TextField
                                label={t('setProperties.descriptionLabel')}
                                maxLength={Constants.MAX_WORKFLOW_DESCRIPTION}
                                resizable={false}
                                rows={4}
                                multiline
                                defaultValue={data.description?.trim()}
                                placeholder={t('setProperties.descriptionPlaceholder')}
                                onChange={(_, newValue) => setData({ ...data, description: newValue?.trim() || "" })}
                            />
                        </div>
                        <div className={classNames.root}>
                            <div className={classNames.dropdownItem}>
                                <Dropdown
                                    selectedKey={props.initialState.activityKey}
                                    label={t('setProperties.activityLabel')}
                                    options={activitiesDropdown}
                                    calloutProps={{
                                        directionalHintFixed: true
                                    }}
                                    onChange={(_, newValue) => {
                                        setData({ ...data, activityKey: newValue?.key, activityId: newValue?.data, activity: activities.find(x => x.id === newValue?.key)})
                                    }}
                                    required
                                    styles={classNames.subComponentStyles.dropdown}
                                    placeholder={t('setProperties.activityPlaceholder')}
                                    errorMessage=""
                                />
                            </div>
                        </div>
                    </>
    )
}