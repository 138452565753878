import { styled } from "@fluentui/react";
import { getStyles } from "./createArchiveModal.styles";
import { ICreateArchiveModalPropsStyles, ICreateArchiveModalStyles, ICreateArchiveModalProps } from "./createArchiveModal.types";
import { CreateArchiveModalBase } from "./createArchiveModal.base";

const CreateArchiveModal = styled<ICreateArchiveModalProps, ICreateArchiveModalPropsStyles, ICreateArchiveModalStyles>(
    CreateArchiveModalBase,
    getStyles,
    undefined,
    { scope: 'CreateArchiveModal' }
);

export default CreateArchiveModal;