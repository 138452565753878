import { IStyleFunction } from "@fluentui/react"
import { IDashboardDocAnalysisPropsStyles, IDashboardDocAnalysisStyles } from "./dashboardDocAnalysis.types"

export const DashboardDocAnalysisGlobalClassNames = {
    root: 'edi-dashboard-doc-analysis-root'
}

export const getStyles: IStyleFunction<IDashboardDocAnalysisPropsStyles, IDashboardDocAnalysisStyles> = (props: IDashboardDocAnalysisPropsStyles): IDashboardDocAnalysisStyles => {
    return {
        root: [
            DashboardDocAnalysisGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}