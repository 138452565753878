import { FontSizes, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react"
import { IFileSchedulerPropsStyles, IFileSchedulerStyles } from "./fileScheduler.types";

export const FileSchedulerGlobalClassNames = {
    root: 'edi-file-scheduler-root'
}

export const getStyles: IStyleFunction<IFileSchedulerPropsStyles, IFileSchedulerStyles> = (props: IFileSchedulerPropsStyles): IFileSchedulerStyles => {
    return {
        body: {
            height: '100%',
            overflow:'hidden'
        },
        leftContainer: {
            marginRight: 10,
            height: '100%',
            width: 160,
            '@media(max-width:800px)': {
                width: 110
            }
        },
        rightContainer: {
            // '> :not(:first-child)': {
            //     // marginTop: 10,
            // },
            height:'100%',
            width:'calc(100% - 170px)',
            '@media(max-width:800px)': {
                width: 'calc(100% - 120px)'
            }
        },
        disclaimers: {
            '> :not(:first-child)': {
                marginTop: 5
            }
        },
        fileList: {
            height:'calc(100% - 85px)',
            " .ms-Viewport": {
                height: '100%', 
                " .ms-DetailsList": {
                    height: '100%', 
                }
            }
        },
        textParagraph: {
            fontSize: FontSizes.size12,
            fontWeight: 'bold', 
        },
        detailsListContainer: {
           " .ms-DetailsRow-cell": {
                display: 'flex',
                alignItems: 'center'
            }
        },
        wellDoneImage: {
            flexBasis: '50%',
            flexShrink: 0
        },
        secondFooterContainer: {
            display: 'flex',
            alignItems: 'center',
            height: '100%'
        },
        subfirstFooterContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        firstFooterContainer: {
            paddingTop: '10px',
            textAlign: 'right'
        },
        footer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center'
        },
        spinner: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        progressIndicatorbody: {
            width: '30%'
        },
        progressIndicatoritemName: {
            textAlign: 'left'
        },
        progressIndicatoritemDescription: {
            textAlign: 'right'
        },
        subComponentStyles: {
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                    },
                    cell: { alignItems: 'center', textOverflow: 'unset', display: 'flex' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
        }
    };
}