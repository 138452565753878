import { styled } from "@fluentui/react";
import { EditProfileFolderModalBase } from "./editProfileFolderModal.base";
import { getStyles } from "./editProfileFolderModal.style";
import { IEditProfileFolderModalBasePropsStyles, IEditProfileFolderModalBaseStyles, IEditProfileFolderModalProps } from "./editProfileFolderModal.types";

const EditProfileFolderModal = styled<IEditProfileFolderModalProps, IEditProfileFolderModalBasePropsStyles, IEditProfileFolderModalBaseStyles>(
    EditProfileFolderModalBase, 
    getStyles,
    undefined,
    { scope: 'EditProfileFolderModal'}
)

export default EditProfileFolderModal;