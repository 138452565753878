import { IStyleFunction } from "@fluentui/react"
import { IKnowledgeSharepointFileSelectorPropsStyles, IKnowledgeSharepointFileSelectorStyles } from "./knowledgeSharepointFileSelector.types"

export const KnowledgeSharepointFileSelectorGlobalClassNames = {
    root: 'edi-knowledge-sharepoint-file-selector-root',
    img: 'edi-knowledge-sharepoint-file-selector-image-img',
}

export const getStyles: IStyleFunction<IKnowledgeSharepointFileSelectorPropsStyles, IKnowledgeSharepointFileSelectorStyles> = (props: IKnowledgeSharepointFileSelectorPropsStyles): IKnowledgeSharepointFileSelectorStyles => {
    return {
        root: [
            KnowledgeSharepointFileSelectorGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        img: [
            KnowledgeSharepointFileSelectorGlobalClassNames.img,
            {
                width: `20px`,
                height: `20px`,
            }
        ],
    };
}