/* eslint-disable sonarjs/cognitive-complexity */
import { Check, classNamesFunction, ConstrainMode, IColumn, Icon, IconButton, MessageBar, MessageBarType, PrimaryButton, SelectionMode, ShimmeredDetailsList, Stack, StackItem, TextField } from "@fluentui/react";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Archive } from "../../../docLab/models/archive";
import { ArchiveItem } from "../../../docLab/models/archiveItem";
import { ArchiveRoleId, Constants, ContentItemIndexingStatus, ContentItemScope } from "../../../docLab/models/constants";
import { archivesApi } from "../../../docLab/services/archives/archives.api";
import { Helpers } from "../../../utilities/helpers";
import { useAsyncApi, useOnMount } from "../../../utilities/hooks";
import FileIconCell from "../fileIconCell/fileIconCell";
import TeamsImage from "../teamsImage/teamsImage";
import { ImageName } from "../teamsImage/teamsImage.types";
import { FolderBreadcrumb } from "./folderContent.breadcrumb";
import { IFolderContentProps, IFolderContentPropsStyles, IFolderContentStyles } from "./folderContent.types";

const getClassNames = classNamesFunction<IFolderContentPropsStyles, IFolderContentStyles>();

interface IListData {
    indexingStatus: ContentItemIndexingStatus;
    scope: ContentItemScope;
    folderId: number;
    folderName: string;
    name: string;
    orderBy: string;
    isAsc: boolean;
    pageNumber: number;
    pageSize: number;
}

type GetFolderContentParams = {
    contentIndexingStatus?: ContentItemIndexingStatus;
    contentScope?: ContentItemScope;
    folderId?: number;
    folderName?: string;
    name?: string;
    orderBy?: string;
    isAsc?: boolean;
    resetPage: boolean;
}

const defaultListData: IListData = {
    indexingStatus: ContentItemIndexingStatus.All,
    scope: ContentItemScope.All,
    folderId: 0,
    name: '',
    orderBy: 'Name',
    folderName: '',
    isAsc: true,
    pageNumber: 0,
    pageSize: Constants.PAGE_SIZE_START_ANALYSIS
}

export const FolderContentBase = (props: IFolderContentProps) => {
    const [data, setData] = useState<IListData>(defaultListData);
    const [currentArchive] = useState<Archive | undefined>(props.archive);
    const { t } = useTranslation(['docAnalysisStartModal', 'common']);
    const [deferredLoading, setDeferredLoading] = useState(false);
    const [noMore, setNoMore] = useState(true);
    const [keyword, setKeyword] = useState("");
    const [archiveItems, setArchiveItems] = useState<ArchiveItem[]>([]);
    const [isMaxFilesSelected, setIsMaxFilesSelected] = useState(false);
    const [selectedAllEnabled, setSelectedAllEnabled] = useState(false);
    const [selectedItems, setSelectedItems] = useState<ArchiveItem[]>(props.selection || []);
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    const { loading, error, execute: getFolderContent, value: items } = useAsyncApi<GetFolderContentParams, ArchiveItem[]>({
        func: async (input: GetFolderContentParams): Promise<ArchiveItem[]> => {
            const newData: IListData = {
                folderId: input.folderId ?? data.folderId,
                folderName: items?.find(i => i.id === input.folderId ?? data.folderId)?.name ?? currentArchive?.name ?? '',
                name: input.name || '',
                orderBy: input.orderBy ?? data.orderBy,
                isAsc: input.isAsc ?? data.isAsc,
                pageNumber: input.resetPage ? 0 : data.pageNumber + 1,
                pageSize: defaultListData.pageSize,
                scope: input.contentScope || ContentItemScope.All,
                indexingStatus: input.contentIndexingStatus || ContentItemIndexingStatus.All
            }

            setData(newData);

            if (input.resetPage)
                setNoMore(false);

            const response = await archivesApi.getFolderContent({
                id: newData.folderId,
                isAscending: newData.isAsc,
                orderBy: newData.orderBy,
                pageNumber: newData.pageNumber,
                pageSize: newData.pageSize,
                name: newData.name,
                scope: newData.scope,
                indexingStatus: props.contentIndexingStatusConfiguration
            });

            setNoMore(newData.pageSize > response.contentItems?.length || response.contentItems?.length === 0);

            setSelectedAllEnabled(false);

            let result = input.resetPage ? response.contentItems : (items ? items.concat(response.contentItems) : []);

            if (props.isFromReport)
                result = result.filter(r => r.archiveRoleId === ArchiveRoleId.Architect || r.archiveRoleId === ArchiveRoleId.Owner);

            setArchiveItems(result);

            return result;
        }
    });

    useEffect(() => {
        if (error) {
            setSelectedItems([]);
            props.isError(true);
        }
        else {
            props.isError(false);
        }

    }, [error, props]);

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, isEmptyList: !items || items.length === 0 });

    useOnMount(() => {
        registerEvent();
        if (!currentArchive)
            return;
        const action = getFolderContent({ folderId: currentArchive.rootFolderId, resetPage: true, contentIndexingStatus: props.contentIndexingStatusConfiguration, name: keyword, contentScope: setContentScope(keyword) });
        Helpers.deferred(action, setDeferredLoading, 200, 250);
    });

    const registerEvent = useCallback(() => {
        const listElm = document.querySelector('#folderContentDetailsListZone'); //#modalListZone .ms-DetailsList-contentWrapper
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50) {
                const button = document.getElementById("click_FolderContent");
                button && button.click();
            }
        });
    }, []);

    const sortByColumn = (_: React.MouseEvent, column: IColumn) => {
        if (loading)
            return;

        const action = getFolderContent({
            orderBy: column.fieldName,
            isAsc: column.fieldName === data.orderBy ? !data.isAsc : true,
            resetPage: true,
            name: keyword, contentScope: setContentScope(keyword)
        });
        Helpers.deferred(action, setDeferredLoading, 200, 250);
    };

    const loadMore = useCallback(() => {
        if (loading || noMore) return;
        const action = getFolderContent({ resetPage: false });
        Helpers.deferred(action, setDeferredLoading, 200, 250);
    }, [loading, noMore, getFolderContent]);

    const emptyFolder = useCallback(() => {
        if (loading || (items?.length !== 0))
            return null;

        return (
            <div className={classNames.emptyFolder}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyFolder")}
                />
            </div>
        )
    }, [classNames.emptyFolder, items, t, loading]);

    const toggleHeaderClicked = () => {
        if ((archiveItems.filter(x => !x.isFolder).length > (props.maxNumFiles))) {
            setIsMaxFilesSelected(true);
            return;
        }

        if (selectedItems.length > (props.maxNumFiles - 1))
            return null

        let newItems = [];

        if (props.isGlossaryCheck)
            newItems = selectedAllEnabled
                ? selectedItems.filter(item => !items?.some(y => y.id === item.id))
                : selectedItems.concat(items?.filter(item => !selectedItems.some(y => y.id === item.id)) || []);
        else
            newItems = selectedAllEnabled
                ? newItems = selectedItems.filter(item => !items?.some(y => y.id === item.id) && !item.isFolder)
                : newItems = selectedItems.concat(items?.filter(item => !selectedItems.some(y => y.id === item.id) && !item.isFolder) || []);

        setSelectedItems(newItems);
        props.onSelectionChange([...newItems]);
        setSelectedAllEnabled(!selectedAllEnabled);
    }

    const onRenderHeader = () => {
        return props.selectAllEnabled && archiveItems && archiveItems.length > 0
            ?
            <div data-checked={true} onClick={() => toggleHeaderClicked()} className={classNames.checkboxHeader}>
                <Check checked={selectedAllEnabled} />
            </div>
            :
            <></>
    }

    const columns: IColumn[] = [{
        key: 'selection',
        name: 'selection',
        isIconOnly: true,
        onRenderHeader: onRenderHeader,
        minWidth: 20,
        maxWidth: 20,
        onRender: function getItemCell(item: ArchiveItem) {
            if (item.isFolder && props.disableFolderSelection)
                return null;

            if (!item.isFolder && (!props.enabledFileTypes ||
                !props.enabledFileTypes?.some(type => type.toLowerCase() === Helpers.getFileExtension(item.name).toLowerCase()))) {
                return null;
            }

            if (!item.isFolder && item.archiveRoleId === ArchiveRoleId.Reader)
                return null;

            const checked = selectedItems.some(i => i.id === item.id);

            const toggleSelected = () => {
                let newItems;

                if (props.maxNumFiles === 1) {
                    newItems = checked ? [] : [item];
                }
                else {
                    if ((selectedItems.length > (props.maxNumFiles - 1) && !checked) || props.creatingAnalysis)
                        return;
                    if (checked)
                        newItems = _.remove([...selectedItems], i => i.id !== item.id);
                    else
                        newItems = [...selectedItems, item];
                }

                setSelectedItems(newItems);
                setIsMaxFilesSelected(false);
                props.onSelectionChange([...newItems]);
            }

            return (
                <div data-checked={checked} onClick={() => toggleSelected()} className={classNames.checkbox}>
                    <Check checked={checked} />
                </div>
            )
        }
    }, {
        key: 'file-type',
        name: 'File Type',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: '',
        minWidth: 20,
        maxWidth: 20,
        onRender: function getItemCell(item: ArchiveItem) {
            const extIndex = item.name.lastIndexOf('.');
            const ext = extIndex !== -1 ? item.name.substring(extIndex) : "";
            return <FileIconCell fileExtension={ext} isFolder={item.isFolder} />
        }
    }, {
        key: 'Name',
        name: t("name"),
        fieldName: 'Name',
        minWidth: 100,
        maxWidth: 350,
        isSorted: data.orderBy === 'Name',
        isSortedDescending: !data.isAsc,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: sortByColumn,
        onRender: function getItemName(item: ArchiveItem) {
            return (
                <span
                    onClick={() => {
                        (item.isFolder && !props.isGlossaryCheck) || (item.isFolder && props.isGlossaryCheck && !selectedItems.some((obj) => obj.id === item.id))
                            ?
                            getFolderContent({ folderId: item.id, resetPage: true, name: keyword, contentScope: setContentScope(keyword) })
                            :
                            (props.canShowPreview && Helpers.getPreviewFile(item.id))
                    }}
                    style={{
                        cursor: (item.isFolder && !props.isGlossaryCheck) || (item.isFolder && props.isGlossaryCheck && !selectedItems.some((obj) => obj.id === item.id)) || props.canShowPreview
                            ?
                            'pointer'
                            :
                            'default'
                    }}
                >
                    {item.name}
                </span>
            )
        }
    }, {
        key: 'CreatedOn',
        name: t("createdOn"),
        fieldName: 'CreatedOn',
        minWidth: 130,
        maxWidth: 130,
        isResizable: true,
        isSorted: data.orderBy === 'CreatedOn',
        isSortedDescending: !data.isAsc,
        onColumnClick: sortByColumn,
        onRender: function render(item: ArchiveItem) {
            return Helpers.formatToRelativeDate(item.createdOn);
        }
    }, {
        key: 'CreatedBy',
        name: t("createdBy"),
        fieldName: 'CreatedBy',
        minWidth: 130,
        maxWidth: 250,
        isResizable: true,
        isCollapsible: true,
        isSorted: data.orderBy === 'CreatedBy',
        isSortedDescending: !data.isAsc,
        onColumnClick: sortByColumn,
        onRender: function getCreatedBy(item: ArchiveItem) {
            return <span>{item.createdBy}</span>;
        },
    }];

    const setContentScope = (name: string) => {
        return name ? ContentItemScope.File : ContentItemScope.All
    }

    return (
        <div className={classNames.root} id={"modalListZone"}>
            {error &&
                <TeamsImage
                    imageName={ImageName.Error1}
                    scale={0.3}
                    fullContainer
                    caption={t("errorLoading")}
                />
            }
            {!error &&
                <>
                    {isMaxFilesSelected && <MessageBar styles={{ root: { marginTop: '10px' } }} messageBarType={MessageBarType.warning} messageBarIconProps={{ iconName: 'Warning' }}>
                        {t('common:maxFilesSelected')}
                    </MessageBar>}

                    <Stack horizontal>
                        <StackItem grow={4}>
                            <div className={classNames.breadcrumbContainer}>
                                <div className={classNames.iconContainer}>
                                    {props.onLevelUp && <Icon onClick={() => { if (props.onLevelUp) props.onLevelUp(true) }} styles={{ root: { fontSize: '16px', cursor: 'pointer' } }} iconName="up" />}
                                </div>
                                <FolderBreadcrumb
                                    folderId={data.folderId}
                                    folderName={data.folderName}
                                    onClick={id => {
                                        setKeyword("");
                                        getFolderContent({ folderId: id, resetPage: true, name: keyword, contentScope: setContentScope(keyword) })
                                    }}
                                />
                            </div>
                        </StackItem>
                        {!props.notViewSearchBar &&
                            <StackItem align="end">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', background: palette.neutralLight }}>
                                    <TextField placeholder={t("glossaryCheck.searchDocument")}
                                        value={keyword}
                                        onChange={(_, value) => setKeyword(value || '')}
                                        styles={{
                                            field: { color: palette.neutralPrimary, 'input': { '&::placeholder': { color: palette.neutralPrimary } } }
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && e.currentTarget.value) {
                                                getFolderContent({ folderId: data.folderId, resetPage: true, name: e.currentTarget.value, contentScope: setContentScope(e.currentTarget.value) })
                                            }

                                        }}
                                        maxLength={props.searchFieldMaxLenght || Constants.MAX_LENGHT_25}
                                    />
                                    <IconButton
                                        iconProps={{ iconName: "Cancel" }}
                                        styles={{
                                            rootHovered: { background: palette.neutralLight }
                                        }}
                                        style={{ height: 20, color: palette.black }}
                                        onClick={() => {
                                            if (keyword) {
                                                setKeyword(''); (getFolderContent({ folderId: data.folderId, resetPage: true }))
                                            }
                                        }}
                                    />
                                </div>
                            </StackItem>}
                    </Stack>

                    <div id={"folderContentDetailsListZone"} style={{ height: props.isSearchOrCompareAnalysis ? 'calc(100% - 10px)' : isMaxFilesSelected ? 'calc(100% - 112px)' : 'calc(100% - 70px)', overflow: 'overlay' }}>
                        <ShimmeredDetailsList
                            items={archiveItems ?? []}
                            columns={columns}
                            getKey={(item: ArchiveItem) => item && `${item.isFolder ? 'f' : ''}${item.id}`}
                            selectionMode={SelectionMode.none}
                            enableShimmer={data.pageNumber === 0 && (deferredLoading || loading)}
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderDetailsFooter={emptyFolder}
                            onShouldVirtualize={() => false}
                        />
                    </div>
                </>
            }
            <div className={classNames.load}>
                <PrimaryButton id={noMore ? "noClick_FolderContent" : "click_FolderContent"} onClick={loadMore} />
            </div>
        </div>
    );
}