import { IStyleFunction } from "@fluentui/react"
import { IActivitiesBoardsPropsStyles, IActivitiesBoardsStyles } from "./activitiesBoards.types"

export const ActivitiesBoardsGlobalClassNames = {
    root: 'edi-activities-boards-root'
}

export const getStyles: IStyleFunction<IActivitiesBoardsPropsStyles, IActivitiesBoardsStyles> = (props: IActivitiesBoardsPropsStyles): IActivitiesBoardsStyles => {
    return {
        root: [
            ActivitiesBoardsGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}