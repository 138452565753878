/*eslint-disable sonarjs/cognitive-complexity */
import React from "react";
import { Callout, classNamesFunction, FocusTrapCallout, IconButton, IIconProps } from "@fluentui/react";
import { IEdiCalloutPropsStyles, IEdiCalloutStyles, IEdiCalloutProps } from "./ediCallout.types";

const getClassNames = classNamesFunction<IEdiCalloutPropsStyles, IEdiCalloutStyles>();

const initialIconProps: IIconProps = {
    iconName: 'Cancel'
}
export const EdiCalloutBase = (props: IEdiCalloutProps) => {
    const classNames = getClassNames(props.styles, {
        theme: props.theme,
        className: props.className,
        styles: props.styles,
        width: props.width,
        height: props.height
    });

    const calloutContent = (
        <div className={classNames.content}>
            {!props.hideHeader && !props.bodyOnly &&
                <React.Fragment>
                    <div className={classNames.header}>
                        <div className={classNames.title}>{props.title}</div>
                        {props.showCloseIcon &&
                            <div className={classNames.closeIcon}>
                                <IconButton
                                    styles={classNames.subComponentStyles.iconButton()}
                                    iconProps={{ ...initialIconProps, ...props.closeIconProps }}
                                    onClick={props.onCloseClick}
                                />
                            </div>
                        }
                    </div>
                    {props.subtitle && <div className={classNames.subTitle}>{props.subtitle}</div>}
                </React.Fragment>

            }
            <div className={classNames.body}>
                <div className={classNames.bodyContent}>
                    {props.body}
                </div>
            </div>
            {props.footer && !props.bodyOnly && <div className={classNames.footer}>{props.footer}</div>}
        </div>
    );


    return (
        <React.Fragment>
            {props.focusTrap ?
                <FocusTrapCallout
                    role={props.role || 'alertdialog'}
                    className={`${classNames.root} ${props.className ? props.className : ''}`}
                    gapSpace={props.gapSpace || 0}
                    target={`${props.targetType}${props.target}`}
                    onDismiss={props.onDismissed}
                    setInitialFocus
                    coverTarget={props.coverTarget}
                >
                    {calloutContent}
                </FocusTrapCallout>
                :
                <Callout
                    role={props.role || 'alertdialog'}
                    className={`${classNames.root} ${props.className ? props.className : ''}`}
                    gapSpace={props.gapSpace || 0}
                    target={`${props.targetType}${props.target}`}
                    onDismiss={props.onDismissed}
                    setInitialFocus
                    coverTarget={props.coverTarget}
                >
                    {calloutContent}
                </Callout>
            }
        </React.Fragment>
    );
}
