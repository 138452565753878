import { styled } from "@fluentui/react";
import { DocAnalysisStartModalBase } from "./docAnalysisStartModal.base";
import { getStyles } from "./docAnalysisStartModal.styles.";
import { IDocAnalysisStartModalProps, IDocAnalysisStartModalPropsStyles, IDocAnalysisStartModalStyles } from "./docAnalysisStartModal.types";

const DocAnalysisStartModal = styled<IDocAnalysisStartModalProps, IDocAnalysisStartModalPropsStyles, IDocAnalysisStartModalStyles>(
    DocAnalysisStartModalBase,
    getStyles,
    undefined,
    { scope: 'docAnalysisStartModal' }
);

export default DocAnalysisStartModal;