/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useState } from "react";
import { classNamesFunction,  DefaultButton, PrimaryButton, TextField } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IFeedbackConfirmModalProps, IFeedbackConfirmModalPropsStyles, IFeedbackConfirmModalStyles } from "./feedbackConfirmModal.types";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { FeedbackStatus } from "../../../models/feedback";

const getClassNames = classNamesFunction<IFeedbackConfirmModalPropsStyles, IFeedbackConfirmModalStyles>();

export const FeedbackConfirmModalBase = (props: IFeedbackConfirmModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation('feedbackConfirmModal');
    const [note, setNote] = useState<string>("");

    const body = (
          <TextField
            style={{height: 150}}
            label={t("comment")}
            maxLength={1000}
            multiline
            resizable={false}
            onChange={(_, newValue) => {
                if(newValue)
                    setNote(newValue);
            }}
          />
      );
    
      const footer = (
        <div className={classNames.firstFooterContainer}>
          <DefaultButton
            style={{ margin: '0px 4px' }}
            text={t("cancel")}
            onClick={props.onClose}
          />
          <PrimaryButton
            style={{ margin: '0px 4px' }}
            text={props.action === FeedbackStatus.Approved || props.action === FeedbackStatus.ReadyForDataset ? t("approve") : t("reject")}
            onClick={() => props.onConfirm(note)}
          />
        </div>
      );

    return (
        <EdiModal
            isOpen={props.isOpen}
            showCloseIcon={false}
            onCloseClick={props.onClose}
            title={props.action === FeedbackStatus.Approved || props.action === FeedbackStatus.ReadyForDataset ? t("approveFeedback") : t("rejectFeedback")}
            body={body}
            footer={footer}
            width={500}
        />
    );
}
