/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useEffect, useMemo, useState } from "react";
import { ActionButton, Checkbox, classNamesFunction, DetailsList, DetailsListLayoutMode, DirectionalHint, IColumn, IconButton, IContextualMenuProps, IListProps, Label, Spinner, SpinnerSize, Stack, StackItem, TextField } from "@fluentui/react";
import { IDocumentsPropsStyles, IDocumentsStyles, IDocumentsProps, KnowledgeDocumentFilter } from "./documents.types";
import { useCurrentKnowledge } from "../../hooks/useCurrentKnowledge";
import { KnowledgeFile } from "../../models/file";
import { useOnMount } from "../../../utilities/hooks";
import { knowledgeLabApi } from "../../services/knowledgeLab.api";
import { IndexingStatus } from "../../../docLab/models/archiveItem";
import FileIconCell from "../../../common/components/fileIconCell/fileIconCell";
import { Helpers } from "../../../utilities/helpers";
import TeamsImage from "../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../common/components/teamsImage/teamsImage.types";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../common/components/datePicker/datePicker";
import DocumentTagPanel from "../documentTagPanel/documentTagPanel";
import { filePreviewSubPath } from "../../../utilities/constants";
import { nanoid } from "@reduxjs/toolkit";
import { startDownload } from "../../features/downloadStatus";
import { useDispatch } from "react-redux";
import AuthorizeRole from "../common/authorizeRole/authorizeRole";
import { KnowledgeLabRoleId } from "../../models/knowledgeLabRoleId";
import DeleteKnowledgeDocumentModal from "../deleteKnowledgeDocumentModal/deleteKnowledgeDocumentModal";
import { ISelection, Selection } from '@fluentui/react/lib/DetailsList';
import { setSelectedDocuments, useKnowledgeLabList } from "../../features/knowledgeLabList";
import { useKnowledgeLabContent, setForceDocumentReload } from "../../features/knowledgeLabContent";
import { KnowledgeHelpers } from "../../utilities/helpers";

const getClassNames = classNamesFunction<IDocumentsPropsStyles, IDocumentsStyles>();

export const DocumentsBase = (props: IDocumentsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const currentKnowledge = useCurrentKnowledge();

    const [reset, setReset] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [canLoadOnScroll, setCanLoadOnScroll] = useState<boolean>(false);
    const [viewDocumentId, setViewDocumentId] = useState<number | undefined>(undefined);
    const [startSearch, setStartSearch] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [documents, setDocuments] = useState<KnowledgeFile[]>([]);
    const { t, i18n } = useTranslation('knowledgeDocuments');
    const dispatch = useDispatch();
    const knowledgelab = useKnowledgeLabList();
    const knowledgeLabConent = useKnowledgeLabContent();
    const emptyFilters = {
        user: '',
        fileName: '',
        dateFrom: undefined,
        dateTo: undefined,
        indexed: false,
        notIndexedWithError: false,
        pageIndex: 0,
        pageSize: 50
    }

    const [filters, setFilters] = useState<KnowledgeDocumentFilter>(emptyFilters);

    const currentLang = (i18n.language).split("-")[0];
    const currentTranslations = currentKnowledge?.configurations?.translations;
    const entitiesName = KnowledgeHelpers.getKnowledgeTranslation("elementsPlaceHolder", currentTranslations, currentLang);

    useOnMount(() => {
        dispatch(setForceDocumentReload(false));
        dispatch(setSelectedDocuments(undefined))
    })

    const onChangeFilter = () => {
        dispatch(setSelectedDocuments(undefined))
        setFilters(prev => (
            { ...prev, pageIndex: 0 }));
        setReset(true);
        setStartSearch(true);
    }

    const loadDocuments = () => {
        setLoading(true);
        if (currentKnowledge)
            knowledgeLabApi.getDocuments(currentKnowledge.id, filters)
                .then((result) => {
                    if (filters.pageIndex > 0)
                        setDocuments(documents.concat(result));
                    else
                        setDocuments(result);
                    if (result.length >= filters.pageSize) setCanLoadOnScroll(true);
                    else setCanLoadOnScroll(false)
                })
                .finally(() => {
                    setLoading(false);
                    setReset(false);
                    setStartSearch(false);
                })
    }

    useEffect(() => {
        loadDocuments();
    }, [currentKnowledge]) //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!knowledgeLabConent.forceDocumentReload) return;
        onChangeFilter();
        loadDocuments();

        dispatch(setForceDocumentReload(false))

    }, [knowledgeLabConent.forceDocumentReload]) //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (startSearch) {
            loadDocuments();
            setStartSearch(reset)
        }
    }, [startSearch]) //eslint-disable-line react-hooks/exhaustive-deps

    const onRenderSuffix = () => <IconButton iconProps={{ iconName: 'search' }} aria-label="Search" onClick={() => onChangeFilter()} />

    const filtersPanel = () => {
        return <div style={{ width: '100%' }}>
            <Stack className={classNames.filterPanel}>
                <Stack horizontal horizontalAlign={'end'}>
                    {/* <Stack.Item>
                        <Label>{t('filters.resetFilters')}</Label>
                    </Stack.Item> */}
                    <Stack.Item align="end" className={classNames.filterPanelItem}>
                        <ActionButton
                            style={{ position: "relative", right: 0 }}
                            onClick={() => {
                                setFilters(emptyFilters);
                                onChangeFilter();
                            }}>
                            <span style={{ color: palette.themePrimary, textDecoration: 'underline' }}>{t('filters.resetFilters')}</span>
                        </ActionButton>
                    </Stack.Item>
                </Stack>

                <Stack.Item className={classNames.filterPanelItem} style={{ width: '226px' }}>
                    <Label>{t('filters.user')}</Label>
                    <TextField
                        value={filters.user}
                        onRenderSuffix={onRenderSuffix}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') onChangeFilter();
                        }}

                        onChange={(e, n) => setFilters(prev => ({ ...prev, user: n }))} />
                </Stack.Item>
                <Stack.Item className={classNames.filterPanelItem} style={{ width: '226px' }}>
                    <Label>{t('filters.fileName')}</Label>
                    <TextField
                        value={filters.fileName}
                        onRenderSuffix={onRenderSuffix}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') onChangeFilter();
                        }}
                        onChange={(e, n) => setFilters(prev => ({ ...prev, fileName: n }))} />
                </Stack.Item>
                <Stack.Item className={classNames.filterPanelItem}>
                    <Label>{t('filters.documentStatus')}</Label>
                    <Stack.Item style={{ margin: '0 0 8px 0' }}>
                        <Checkbox
                            checked={filters.indexed}
                            label={t('filters.indexed')}
                            onChange={(e, n) => {
                                setFilters(prev => ({ ...prev, indexed: n }));
                                onChangeFilter();
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item style={{ margin: '0 0 8px 0' }}>
                        <Checkbox
                            checked={filters.notIndexedWithError}
                            label={t('filters.notIndexed')}
                            onChange={(e, n) => {
                                setFilters(prev => ({ ...prev, notIndexedWithError: n }));
                                onChangeFilter();
                            }} />
                    </Stack.Item>
                </Stack.Item>
                <Stack.Item className={classNames.filterPanelItem}>
                    <Label>{t('filters.loadingDate')}</Label>
                    <Stack horizontal>
                        <StackItem style={{ width: '226px' }}>
                            <DatePicker
                                placeholder={t('filters.from')}
                                inputValue={filters.dateFrom}
                                maxDate={filters.dateTo}
                                dateCallback={e => {
                                    if (e) {
                                        const c = new Date(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate()));
                                        setFilters(prev => ({ ...prev, dateFrom: c }));
                                        onChangeFilter();
                                    }
                                }}
                            />
                        </StackItem>
                        <StackItem>
                            {
                                filters.dateFrom &&
                                <ActionButton
                                    style={{ height: '35px' }}
                                    iconProps={{ iconName: 'cancel' }}
                                    onClick={() => {
                                        setFilters(prev => ({ ...prev, dateFrom: undefined }));
                                        onChangeFilter();
                                    }
                                    }></ActionButton>
                            }
                        </StackItem>
                    </Stack>
                    <Stack horizontal>
                        <StackItem style={{ width: '226px' }}>
                            <DatePicker
                                placeholder={t('filters.to')}
                                inputValue={filters.dateTo}
                                minDate={filters.dateFrom}
                                dateCallback={e => {
                                    if (e) {
                                        const c = new Date(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate()));
                                        setFilters(prev => ({ ...prev, dateTo: c }));
                                        onChangeFilter();
                                    }
                                }}
                            />
                        </StackItem>
                        <StackItem>
                            {
                                filters.dateTo &&
                                <ActionButton
                                    style={{ height: '35px' }}
                                    iconProps={{ iconName: 'cancel' }}
                                    onClick={() => {
                                        setFilters(prev => ({ ...prev, dateTo: undefined }));
                                        onChangeFilter();
                                    }
                                    }></ActionButton>
                            }
                        </StackItem>
                    </Stack>

                </Stack.Item>
            </Stack>
        </div>
    }

    const getFileExtension = (name: string): string => {
        const extensionPosition = name.split('.').length - 1;
        return name.split('.')[extensionPosition >= 0 ? extensionPosition : 0];
    }

    const menuProps = (document: KnowledgeFile): IContextualMenuProps => {
        return {
            items: [
                {
                    key: 'view',
                    text: t('actions.view'),
                    onClick: () => {
                        Helpers.getKnowledgeLabPreviewFile(document.id, filePreviewSubPath.knowledge);
                    },
                },
                {
                    key: 'download',
                    text: t('actions.download'),
                    onClick: () => {
                        dispatch(startDownload({ requestId: nanoid(), file: { id: document.id, name: document.name }, downloadFunction: knowledgeLabApi.downloadFile }));
                    },
                },
                {
                    key: 'delete',
                    text: t('actions.delete'),
                    onClick: () => {
                        setDeleteModalOpen(true);
                    },
                }
                , {
                    key: 'viewTags',
                    text: `${t('actions.viewTags')} & ${entitiesName}`,
                    onClick: () => {
                        setViewDocumentId(document.id)
                    },
                }
            ],
            directionalHint: DirectionalHint.rightTopEdge
        }
    }

    const _renderItemColumn = (item: KnowledgeFile, index: number | undefined, column: IColumn | undefined) => {
        if (!column) return <></>

        const content = item[column.fieldName as keyof KnowledgeFile] as string;

        if (column.key === 'fileExtension') {
            return <FileIconCell isFolder={false} fileExtension={getFileExtension(item.name)} />
        }
        else if (column.key === 'createdOn') {
            return <span>{Helpers.formatToRelativeDate(content)}</span>
        }
        else if (column.key === 'name') {
            return <div className={classNames.workflowListNameWrapper}>
                {content}
                <div className={classNames.menuIconWrapper}>
                    <IconButton
                        styles={classNames.subComponentStyles.iconButton()}
                        iconProps={{ iconName: 'More' }}
                        menuProps={menuProps(item)}
                    />
                </div>
            </div>
        }
        else if (column.key === 'indexingStatus') {
            switch (item.indexingStatus) {
                case IndexingStatus.Indexed as number: return <span>{t('filters.indexed')}</span>
                case IndexingStatus.ToBeIndexed as number: return <span>{t('filters.notIndexed')}</span>
            }
            return <></>
        }
        else {
            return <span>{content}</span>
        }
    }

    const columns: IColumn[] = [
        {
            key: 'fileExtension',
            name: 'File Extension',
            isIconOnly: true,
            fieldName: '',
            minWidth: 16,
            maxWidth: 30
        },
        {
            key: 'name',
            name: t("columns.fileName"),
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 350,
            isResizable: true
        },
        {
            key: 'createdOn',
            name: t('columns.dataImport'),
            fieldName: 'createdOn',
            minWidth: 16,
            maxWidth: 150,
            isResizable: true
        },
        {
            key: 'createdBy',
            name: t('columns.importedBy'),
            fieldName: 'createdBy',
            minWidth: 16,
            maxWidth: 150,
            isResizable: true
        },
        {
            key: 'indexingStatus',
            name: t('columns.indexingStatus'),
            fieldName: 'indexingStatus',
            minWidth: 16,
            maxWidth: 150,
            isResizable: true
        },
    ];
    const shimmeredDetailsListProps: IListProps = {
        renderedWindowsAhead: 0,
        renderedWindowsBehind: 0,
    };

    const onScroll = (event: any) => { //eslint-disable-line @typescript-eslint/no-explicit-any
        if ((canLoadOnScroll && !loading) && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 10) {
            setFilters(prev => ({ ...prev, pageIndex: filters.pageIndex + 1 }));
            setStartSearch(true);
        }
    }

    const getSelectedItems = useMemo((): ISelection | undefined => {
        if (reset || knowledgeLabConent.forceDocumentReload)
            return undefined;

        const selection: Selection = new Selection({
            onSelectionChanged: () => {
                const selectionDetails = selection.getSelection();
                const selected = selectionDetails.map(i => i as KnowledgeFile);
                dispatch(setSelectedDocuments(selected))
            }
        });

        return selection;
    }, [dispatch, reset, knowledgeLabConent.forceDocumentReload])  //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {knowledgelab.isLoading ?
                <div style={{ height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Spinner size={SpinnerSize.medium} />
                </div>
                :
                <AuthorizeRole admittedRoles={[KnowledgeLabRoleId.Contributor, KnowledgeLabRoleId.Owner]}>
                    <div>
                        <Stack horizontal={true} style={{ height: 'calc(100vh - 100px)', minWidth: '300px' }}>
                            <Stack style={{ width: '300px', padding: '15px 20px 0 20px' }}>
                                {filtersPanel()}
                            </Stack>
                            <Stack style={{ width: 'calc(100% - 300px)', height: '100%', padding: '15px 20px 15px 20px' }}>
                                {(documents.length !== 0 && !reset) &&
                                    <div className={classNames.listContent} onScroll={onScroll} id="accordion-scrollable" style={{ height: 'calc(100% - 10px)', overflow: 'overlay', paddingRight: '15px' }} data-is-scrollable="true">
                                        <DetailsList
                                            selection={getSelectedItems}
                                            layoutMode={DetailsListLayoutMode.justified}
                                            setKey="items"
                                            items={documents ?? []}
                                            columns={columns}
                                            isHeaderVisible={true}
                                            ariaLabelForGrid="Item details"
                                            onRenderItemColumn={_renderItemColumn}
                                            listProps={shimmeredDetailsListProps}
                                        />
                                    </div>
                                }
                                {
                                    (!reset && !loading && documents.length === 0) &&
                                    <TeamsImage
                                        imageName={ImageName.EmptySearch}
                                        scale={0.3}
                                        fullContainer
                                        style={{ marginBottom: 30 }}
                                        caption={t("emptyDocumentList")}
                                    />
                                }
                                {
                                    (loading && reset) &&
                                    <div style={{ height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Spinner size={SpinnerSize.medium} />
                                    </div>
                                }
                            </Stack>
                        </Stack>
                        {viewDocumentId && <DocumentTagPanel
                            fileId={viewDocumentId ?? 0}
                            dismissPanel={() => {
                                if (!knowledgeLabConent.isDetailsModalOpen)
                                    setViewDocumentId(undefined)
                            }}
                        />}
                        {<DeleteKnowledgeDocumentModal
                            isOpen={deleteModalOpen}
                            documents={knowledgelab.selectedDocuments ?? []}
                            onComplete={() => {
                                dispatch(setForceDocumentReload(true))
                            }}
                            onClose={() => {
                                setDeleteModalOpen(false);
                            }}
                        />}
                    </div>
                </AuthorizeRole>

            }
        </>
    );
}