import { styled } from "@fluentui/react";
import { getStyles } from "./tableOfContent.styles";
import { ITableOfContentPropsStyles, ITableOfContentStyles, ITableOfContentProps } from "./tableOfContent.types";
import { TableOfContentBase } from "./tableOfContent.base";

const TableOfContent = styled<ITableOfContentProps, ITableOfContentPropsStyles, ITableOfContentStyles>(
    TableOfContentBase,
    getStyles,
    undefined,
    { scope: 'TableOfContent' }
);

export default TableOfContent;