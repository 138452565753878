import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import archiveContentSlice from './features/archiveContent';
import docAnalysisContentSlice from './features/docAnalysisContent';
import archiveList from './features/archiveList';
import callNotification from './features/callNotification';
import downloadStatus from './features/downloadStatus';
import toolbarActions from './features/toolbarActions';
import searchContent from './features/searchContent';
import profileContent from './features/profileContent';

const docLabStore = configureStore({
    reducer: {
        archiveList: archiveList,
        archiveContent: archiveContentSlice,
        docAnalysisContent: docAnalysisContentSlice,
        toolbarActions: toolbarActions,
        callNotification: callNotification,
        downloadStatus: downloadStatus,
        searchContent: searchContent,
        profileContent: profileContent
    }
});

export type DocLabState = ReturnType<typeof docLabStore.getState>;
export type DocLabDispatch = typeof docLabStore.dispatch;
export default docLabStore;

export const useDocLabState = (): DocLabState => docLabStore.getState();
export const useDocLabDispatch = (): DocLabDispatch => useDispatch<DocLabDispatch>();
export const useDocLabSelector: TypedUseSelectorHook<DocLabState> = useSelector;