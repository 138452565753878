import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { classNamesFunction, DefaultButton, DetailsList, DetailsListLayoutMode, Dialog, DialogFooter, IconButton, PrimaryButton, SelectionMode, Toggle } from "@fluentui/react";
import { nanoid } from "@reduxjs/toolkit";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import TeamsImage from "../../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../../common/components/teamsImage/teamsImage.types";
import { Helpers } from "../../../../../../utilities/helpers";
import { useAsyncApi, useOnMount } from "../../../../../../utilities/hooks";
import { useDocLabDispatch } from "../../../../../docLabStore";
import { insertCall, setCall } from "../../../../../features/callNotification";
import { useNavigator } from "../../../../../hooks/useNavigator";
import { Call, callsList } from "../../../../../models/callsApi";
import { AnalysisConfig, AnalysisConfigTyped, TableOfContentSetting } from "../../../../../models/docAnalysis";
import { docAnalyzesApi } from "../../../../../services/docAnalyzes/docAnalyzes.api";
import CreateTableOfContentModal from "./createToc/createTableOfContentModal/createTableOfContentModal";
import { ITocSettingsProps, ITocSettingsPropsStyles, ITocSettingsStyles } from "./tocSettings.types";
/*eslint-disable sonarjs/cognitive-complexity */
const getClassNames = classNamesFunction<ITocSettingsPropsStyles, ITocSettingsStyles>();

export const TocSettingsBase = (props: ITocSettingsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['docAnalysisSettings', 'common']);
    const { currentArchiveId } = useNavigator();
    const [deleteTemplateModalOpened, setDeleteTemplateModalOpened] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [idTemplate, setIdTemplate] = useState(-1);
    const [isManaging, setIsManaging] = useState(false);
    const dispatch = useDocLabDispatch();

    const { execute, error, loading, value: configs } = useAsyncApi<void, AnalysisConfigTyped<TableOfContentSetting>[]>({
        keepResultsWhileReloading: true,
        func: async () => {
            if (!currentArchiveId) return [];
            return await docAnalyzesApi.getTocConfigs({ archiveId: currentArchiveId });
        }
    });

    useOnMount(() => void execute());

    const updateTemplateStatus = async (configId: number) => {
        setIsManaging(true)
        try {
            await docAnalyzesApi.updateTemplateStatus(configId);
            await execute();
        }
        catch { /*Error*/ }
        finally {
            setIsManaging(false)
        }
    }

    /*eslint-disable sonarjs/no-duplicate-string */
    const deleteTemplate = async (configId: number) => {
        setIsManaging(true);
        const deleteTemplateCall: Call = {
            type: callsList.deleteTemplate,
            nameOperation: t('tableOfContent.deleteActionName'),
            errors: [
                { code: 403, message: t("tableOfContent.deleteAction403") },
                { code: 500, message: t('common:genericErrorApi') }
            ]
        };
        setDeleteTemplateModalOpened(0);
        const id = nanoid();
        const payload = { requestId: id, notification: deleteTemplateCall }
        dispatch(insertCall(payload));
        try {
            await docAnalyzesApi.deleteTemplate(configId);
            const successPayload = { requestId: id, success: true, message: t('tableOfContent.deleteSuccessMessage') }
            dispatch(setCall(successPayload));
            await execute();
        }
        catch (error) {
            let errorMessage = '';
            if (error.code === 403) {
                errorMessage = t("tableOfContent.deleteAction403")
            }
            else {
                errorMessage = t('common:genericErrorApi')
            }
            const failurePayload = { requestId: id, success: false, message: errorMessage }
            dispatch(setCall(failurePayload));
        }
        finally {
            setIsManaging(false);
        }
    }

    const columns = [{
        key: 'name',
        name: t('tableOfContent.name'),
        minWidth: 200,
        maxWidth: 250,
        headerClassName: classNames.headerRow,
        onRender: function renderTemplateDetails(config: AnalysisConfigTyped<TableOfContentSetting>) {
            return <span style={{ cursor: 'pointer' }}
                onClick={() => { setShowModal(true); setIdTemplate(config.id) }}
            >{config.name}</span>;
        }
    },
    {
        key: 'status',
        name: 'status',
        isIconOnly: true,
        isResizable: false,
        minWidth: 40,
        maxWidth: 80,
        headerClassName: classNames.headerRow,
        onRender: function render(config: AnalysisConfig) {
            return (
                <Toggle
                    disabled={isManaging}
                    checked={config.isActive}
                    styles={{ root: { marginBottom: 0 } }}
                    onChange={() => updateTemplateStatus(config.id)}
                />
            );
        }
    },
    {
        key: 'createdBy',
        name: t('tableOfContent.createdBy'),
        minWidth: 100,
        maxWidth: 250,
        headerClassName: classNames.headerRow,
        onRender: function render(config: AnalysisConfig) {
            return config.user;
        }
    },
    {
        key: 'createdOn',
        name: t('tableOfContent.createdOn'),
        minWidth: 100,
        maxWidth: 250,
        targetWidthProportion: 3,
        headerClassName: classNames.headerRow,
        onRender: function render(config: AnalysisConfig) {
            return Helpers.formatToRelativeDate(config.createdOn);
        }
    },
    {
        key: 'remove',
        name: 'remove',
        isIconOnly: true,
        isResizable: false,
        minWidth: 30,
        maxWidth: 80,
        headerClassName: classNames.headerRow,
        onRender: function render(config: AnalysisConfig) {
            return (
                <IconButton
                    disabled={isManaging}
                    iconProps={{ iconName: "Delete" }}
                    onClick={() => setDeleteTemplateModalOpened(config.id)}
                />
            );
        }
    }];

    const emptyList = useCallback(() => {
        if (loading || (configs && configs.length > 0))
            return null;

        return (
            <div>
                <TeamsImage
                    styles={{
                        img: {
                            width: '25%',
                            height: '25%'
                        }
                    }}
                    imageName={ImageName.EmptyFolderDrop}
                    caption={t("common:emptyList")}
                />
            </div>
        )
    }, [loading, configs, t]);

    return (
        <>
            <div style={{ paddingLeft: 30, paddingTop: 15 }}>
                <PrimaryButton
                    iconProps={{ iconName: 'Add' }}
                    styles={{ icon: { height: 'auto' } }}
                    text={t("tableOfContent.addTemplate")}
                    onClick={() => setShowModal(true)}

                />
                {configs &&
                    <DetailsList
                        layoutMode={DetailsListLayoutMode.justified}
                        styles={classNames.subComponentStyles.detailsList}
                        items={configs || []}
                        getKey={(item: AnalysisConfigTyped<TableOfContentSetting>) => item.id.toString()}
                        onRenderDetailsFooter={emptyList}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                    />
                }
                {error && <TeamsImage imageName={ImageName.Error2} styles={{
                    img: {
                        width: '25%',
                        height: '25%'
                    }
                }} caption={t('common:genericErrorApi')} />}
                {loading && configs === undefined &&
                    <div className={classNames.spinner}>
                        <TeamsSpinner />
                    </div>
                }
                {
                    showModal && <CreateTableOfContentModal idTemplate={idTemplate >= 0 ? idTemplate : undefined} onClose={(reload) => {
                        setShowModal(false);
                        setIdTemplate(-1);
                        if (reload)
                            execute();
                    }} />
                }
            </div>
            <Dialog hidden={deleteTemplateModalOpened === 0} dialogContentProps={{
                title: t('tableOfContent.deleteModal.title'),
                subText: t('tableOfContent.deleteModal.description')
            }}>
                <DialogFooter>
                    <DefaultButton text={t('common:dialogButtonCancel')} onClick={() => setDeleteTemplateModalOpened(0)} />
                    <PrimaryButton text={t('common:dialogButtonConfirmDeleting')} onClick={async () => {
                        await deleteTemplate(deleteTemplateModalOpened);

                    }} />
                </DialogFooter>
            </Dialog>
        </>
    )
}