import { BasePeoplePicker, FontWeights, IBasePickerProps, IBasePickerStyles, IBreadcrumbStyleProps, IBreadcrumbStyles, IDetailsColumnProps, IDetailsColumnStyles, IDetailsHeaderProps, IDetailsHeaderStyles, IDetailsRowProps, IDetailsRowStyles, IDropdownStyleProps, IDropdownStyles, INavStyleProps, INavStyles, IPivotStyleProps, IPivotStyles, ISuggestionsItemStyleProps, ISuggestionsItemStyles, ISuggestionsStyleProps, ISuggestionsStyles, ITextFieldProps, ITextFieldStyles, ITooltipStyleProps, ITooltipStyles, ISearchBoxProps, ISearchBoxStyles, ICheckboxStyleProps, ICheckboxStyles } from '@fluentui/react';

export const tooltipStyles = (props: ITooltipStyleProps): ITooltipStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
  return {
    root: {},
    content: {},
    subText: {
      color: palette.themePrimary
    }
  }
}

export const textFieldStyles = (props: ITextFieldProps): ITextFieldStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      marginBottom: 1
    },
    fieldGroup: {
      border: 'none',
      color: palette.black,
      backgroundColor: palette.neutralLighter,
      '::after': {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: 0,
        width: '100%',
        inset: '0px'
      }
    },
    prefix: {
      color: palette.white,
    },
    suffix: {},
    field: {
      '::placeholder': {
        color: palette.black
      },
      color: palette.neutralPrimary
    },
    icon: {},
    description: {},
    wrapper: {},
    errorMessage: {},
    revealButton: {},
    revealSpan: {},
    revealIcon: {},
    subComponentStyles: {
      label: {
        root: {
          fontWeight: FontWeights.regular,
          color: palette.black
        }
      }
    }
  }
}

export const dropdownStyles = (props: IDropdownStyleProps): IDropdownStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {},
    label: {},
    dropdown: {
      selectors: {
        ':focus': {
          '::after': {
            border: 'none',
            borderBottom: '2px solid ' + palette.themePrimary
          },
          '.ms-Dropdown-caretDown': {
            color: 'white'
          }
        },
        ':hover': {
          '.ms-Dropdown-caretDown': {
            color: 'white'
          }
        },
        ':active': {
          '.ms-Dropdown-caretDown': {
            color: 'white'
          }
        }
      }
    },
    title: {
      border: 'none',
      backgroundColor: palette.neutralLight, //'rgb(240,240,240)'
      color: palette.black
    },
    caretDownWrapper: {},
    caretDown: {},
    errorMessage: {},
    dropdownItemsWrapper: {},
    dropdownItems: {},
    dropdownItem: {
      color: palette.black,
      selectors: {
        ':hover': {
          backgroundColor: palette.yellowLight,
          ':focus': {
            backgroundColor: palette.yellowLight,
            color: palette.themePrimary
          }
        }
      }
    },
    dropdownItemSelected: {},
    dropdownItemDisabled: {},
    dropdownItemSelectedAndDisabled: {},
    dropdownItemHidden: {},
    dropdownOptionText: {},
    dropdownDivider: {},
    dropdownItemHeader: {},
    panel: {},
    callout: {},
    subComponentStyles: {
      label: {},
      multiSelectItem: {},
      panel: {}
    }
  }
}

export const peoplePickerStyles = (props: IBasePickerProps<BasePeoplePicker>): IBasePickerStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      width: '100%',
      marginRight: '20px'
    },
    text: {
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      borderBottom: '1px solid ' + palette.themePrimary,
      selectors: {
        '::after':
        {
          borderLeft: 'none',
          borderRight: 'none',
          borderTop: 'none'
        }
      },
      backgroundColor: 'transparent'
    },
    input: {
      color: palette.white,
      backgroundColor: 'black'
    },
    itemsWrapper: {
      '.ms-PickerPersona-container': {
        border: '1px solid' + palette.neutralSecondary,
        marginBottom: '5px',
        background: 'transparent',
        '.ms-TooltipHost': {
          color: palette.themePrimary
        },
        '.ms-PickerItem-removeButton': {
          color: palette.themePrimary,
          selectors: {
            ':hover': {
              background: palette.yellowLight
            }
          }
        }
      }
    },
    screenReaderText: {}

  }
}

export const suggestionsPeoplePickerStyles = (props: ISuggestionsStyleProps): ISuggestionsStyles => {
  return {
    suggestionsContainer: {
      maxHeight: '40vh'
    },
    forceResolveButton: {},
    noSuggestions: {},
    root: {},
    title: {},
    searchForMoreButton: {},
    subComponentStyles: {
      spinner: {}
    },
    suggestionsAvailable: {}
  }
}

export const suggestionsItemStyles = (props: ISuggestionsItemStyleProps): ISuggestionsItemStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {},
    itemButton: {
      selectors: {
        ':hover': {
          background: palette.blue
        },
        '.ms-PeoplePicker-personaContent':
        {
          selectors: {
            ':hover': {
              backgroundColor: palette.blue
            }
          }
        }
      }
    },
    closeButton: {}
  }

}

// export const modalUploadFile = (props: IUploadFileModalProps): IUploadFileModalStyles => {
//   return {
//     uploadButton: {
//       border: '1px solid !important '
//     },
//     subComponentStyles: {
//       errorStyle: {},
//       textField: {}
//     }
//   }
// }

export const pivotStyles = (props: IPivotStyleProps): IPivotStyles => {
  return {
    root: {},
    link: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 8,
      marginRight: 8,
      color: props.theme.palette.yellowLight,

      '&:hover': {
        backgroundColor: 'inherit',
        color: props.theme?.palette.yellowLight
      },
      '&:active': {
        backgroundColor: 'inherit',
      },

      '&::before': {
        transition: 'none',
        height: 3,
      },

      '&:not(.is-selected):hover::before': {
        backgroundColor: props.theme?.palette.yellowLight,
        right: 0,
        left: 0,
      }
    },
    linkIsSelected: {
      cursor: 'default',
      color: props.theme.palette.black,

      '&:hover': {
        cursor: 'default',
        color: props.theme.palette.black
      },

      '&::before': {
        right: 0,
        left: 0
      }
    },
    linkContent: {},
    text: {
      fontSize: 14
    },
    count: {},
    icon: {},
    linkInMenu: {},
    overflowMenuButton: {}
  }
}

export const navStyles = (props: INavStyleProps): INavStyles => {
  const { isExpanded } = props;
  const { palette, semanticColors, spacing, fonts } = props.theme;

  return {
    root: {
      padding: '5px 0',
      background: semanticColors.menuItemBackgroundPressed,
      fontSize: spacing.s1,
      '.ms-Nav-link': {
        height: '28px',
        // '.ms-Icon':{
        //   color:'red'
        // }
      },
      height: '100%',
      selectors: {
        '.is-selected button': {
          backgroundColor: palette.yellowLight,
          color: palette.themeDarker,
          'i': {
            color: palette.themeDarker
          }
        }
      },
    },
    linkText: {},
    link: {
      color: '#fff',
      selectors: {
        '.ms-Nav-compositeLink:hover &': {
          backgroundColor: palette.yellowLight,
          color: '#000'
        },
        // '.ms-Button-flexContainer':{
        //   'i':{
        //     color: palette.themePrimary
        //   }
        // },
        ':active': {
          '.ms-Button-flexContainer': {
            position: 'unset'
          }
        }
      },
    },
    compositeLink: {},
    chevronButton: {
      fontSize: fonts.medium.fontSize,
      color: '#fff',
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0,
      selectors: {
        ':hover': {
          backgroundColor: palette.yellowLight,
          color: '#000'
        },
      },
    },
    chevronIcon: [
      {
        fontSize: fonts.xSmall.fontSize,
        transform: 'rotate(-90deg)'
      },
      isExpanded && {
        transform: 'rotate(0deg)',
        top: '5px'
      }
    ],
    navItems: {},
    navItem: {
      borderBottom: '1px solid white',
      borderLeft: '1px solid white'
    },
    group: {},
    groupContent: {
      marginBottom: '0px'
    }
  };
}

export const breadcrumbStyles = (props: IBreadcrumbStyleProps): IBreadcrumbStyles => {
  return {
    root: {
      margin: "8px 0px 0px",
      paddingLeft: "24px",
      paddingRight: "24px",
      lineHeight: "20px"
    },
    list: {},
    listItem: {
    },
    chevron: {},
    overflow: {},
    overflowButton: {},
    itemLink: {
      fontSize: "unset",
      color: "#fff",
      lineHeight: "40px",
      height: "40px",
      fontWeight: "400",
      callout: {

      },
      selectors: {
        ':hover': {
          color: "#ffff00",
          textDecoration: "none",
          cursor: "pointer",
          background: "none"
        },
        ':active': {
          color: "#ffff00 !important",
          textDecoration: "none !important",
          cursor: "pointer !important",
          background: "none !important"
        },
        ':last-child': {
          fontWeight: "700 !important",
          color: "#ffffff !important"
        },
      }
    },
    item: {
      fontSize: "unset",
      color: "#fff",
      lineHeight: "40px",
      height: "40px",
      fontWeight: "400",
      selectors: {
        ':last-child': {
          fontWeight: "700 !important",
          color: "#ffffff !important"
        },
      }
    },
  }
}

export const detailsRowStyles = (props: IDetailsRowProps): IDetailsRowStyles => {
  const { fonts, semanticColors, palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      backgroundColor: semanticColors.bodyBackground,
      selectors: {
        ':hover': {
          backgroundColor: semanticColors.listItemBackgroundHovered,
          color: palette.white
        },
        '&.is-selected': {
          backgroundColor: semanticColors.listItemBackgroundChecked,
          borderBottom: `1px solid ${palette.neutralLighter}`,
          color: palette.white
        },
        '&.is-selected:not(:hover)': {
          backgroundColor: semanticColors.listItemBackgroundChecked,
          color: palette.white
        },
        '&.is-selected:hover': {
          backgroundColor: semanticColors.listItemBackgroundHovered,
          color: palette.white
        }
      }
    },
    cell: {
      fontSize: fonts.medium.fontSize,

    },
    cellAnimation: {},
    cellUnpadded: {},
    cellPadded: {},
    checkCell: {},
    isRowHeader: {
      color: 'inherit !important',
      fontWeight: fonts.medium.fontWeight,
    },
    isMultiline: {},
    fields: {},
    cellMeasurer: {},
    checkCover: {},
    check: {}
  }
}

export const detailsColumnStyles = (props: IDetailsColumnProps): IDetailsColumnStyles => {
  const { semanticColors, palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      height: '32px',
    },
    cellName: {
      fontSize: '12px',
      fontWeight: '400',
    },
    cellTitle: {
      color: palette.black,
      selectors: {
        ':hover': {
          backgroundColor: semanticColors.listItemBackgroundHovered,
          color: palette.white
        }
      }
    },
    sortIcon: {
      color: palette.black
    },
    gripperBarVerticalStyle: {},
    cellTooltip: {},
    iconClassName: {},
    nearIcon: {},
    accessibleLabel: {},
    filterChevron: {},
    borderAfterDropping: {},
    noBorderAfterDropping: {},
    borderWhileDragging: {},
    noBorderWhileDragging: {}
  }
}

export const detailsHeaderStyles = (props: IDetailsHeaderProps): IDetailsHeaderStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      height: '32px',
      lineHeight: '32px',
      borderBottomColor: palette.neutralQuaternary,

    },
    cellIsCheck: {
      height: '32px'
    },
    check: {},
    cellWrapperPadded: {},
    cellIsActionable: {},
    cellIsEmpty: {},
    cellSizer: {},
    cellSizerStart: {},
    cellSizerEnd: {},
    cellIsResizing: {},
    cellIsGroupExpander: {},
    collapseButton: {},
    checkTooltip: {},
    sizingOverlay: {},
    dropHintCircleStyle: {},
    dropHintCaretStyle: {},
    dropHintLineStyle: {},
    dropHintStyle: {},
    accessibleLabel: {},
  }
}


export const searchBoxStyles = (props: ISearchBoxProps): ISearchBoxStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
    },
    iconContainer: {},
    icon: {},
    field: {
      color: palette.black
    },
    clearButton: {},
  }
}

export const checkBoxStyles = (props: ICheckboxStyleProps): ICheckboxStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {},
    input: {},
    label: {},
    checkbox: {
      backgroundColor: palette.black,
      borderRadius: '2px'
    },
    checkmark: { color: palette.white },
    text: {},
  }
}