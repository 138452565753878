import { apiClient } from '../../../modules/apiClient/apiClient';
import { svcPaths } from '../../../modules/apiClient/config';
import { SearchIndex, SearchRequest } from './search.contracts';
/*eslint-disable sonarjs/no-duplicate-string */
/*eslint-disable no-useless-escape*/

class SearchApiClass {
    async search(request: SearchRequest) {
        const payload = {
            criteria: request.value,
            maxHighlights: (request.maxHighlights ?? 0) > 0 ? request.maxHighlights : 0,
            filters: request.filters?.slice().concat({ key: 'KnowledgeLabId', value: request.currentKnowledgeId }),
            pageNumber: request.currentPage,
            pageSize: request.pageSize,
            orderBy: request.orderedBy,
            isAscending: request.isAscending,
            multiLanguageSearch: request.multiLanguageSearch,
            scope: request.scope
        }
        const response = await apiClient.post(`${svcPaths.search}/searchknowledge`, payload);
        return response.data;
    }

    async getIndexField() {
        const response = await apiClient.get<SearchIndex>(`${svcPaths.search}/knowledge`);
        return response.data;
    }
}

export const searchApi = new SearchApiClass();
