import { Archive } from "../models/archive";
import { ArchiveRoleId, ArchiveTypeId } from "../models/constants";
import { INavigator } from "../hooks/useNavigator";
import { ArchiveSettingsTab } from "./routes";
import { DocLabDispatch } from "../docLabStore";
import { showRenameArchiveModal, showDeleteModal } from "../features/archiveContent";

export type ActionArchiveEntry = {
    archiveTypes: ArchiveTypeId[];
    archiveRoles: ArchiveRoleId[];
    label: string;
    icon?: string;
    onClick: (archive: Archive, navigator: INavigator, dispatch: DocLabDispatch) => void;
    condition?: (archive: Archive) => boolean;
    subActionItems?: ActionArchiveEntry[];
}

const actions: ActionArchiveEntry[] = [
    {
        archiveTypes: [ArchiveTypeId.Unit],
        archiveRoles: [ArchiveRoleId.Owner],
        label: 'commands.manageTopicsOfInterest',
        condition: (archive) => archive.isTopicOfInterestActive,
        icon: 'EditNote',
        onClick: (archive, navigator) => navigator.goToArchiveSettingsTab(ArchiveSettingsTab.topicsOfInterestManagement, archive.id)
    },
    {
        archiveTypes: [ArchiveTypeId.Unit],
        archiveRoles: [ArchiveRoleId.Contributor, ArchiveRoleId.Reader, ArchiveRoleId.Architect, ArchiveRoleId.Professional, ArchiveRoleId.Uploader],
        label: 'commands.manageInterests',
        condition: (archive) => archive.isTopicOfInterestActive,
        icon: 'EditNote',
        onClick: (archive, navigator) => navigator.goToArchiveSettingsTab(ArchiveSettingsTab.interestsManagement, archive.id)
    },
    {
        archiveTypes: [ArchiveTypeId.Unit],
        archiveRoles: [ArchiveRoleId.Owner],
        label: 'commands.archiveSettings',
        icon: 'Settings',
        onClick: (archive, navigator) => navigator.goToArchiveSettingsTab(ArchiveSettingsTab.main, archive.id)
    },
    {
        archiveTypes: [ArchiveTypeId.Unit],
        archiveRoles: [ArchiveRoleId.Owner],
        label: "common:manageUsers",
        condition: (archive) => !archive.newUserHandling,
        icon: 'PlayerSettings',
        onClick: (archive, navigator) => navigator.goToArchiveSettingsTab(ArchiveSettingsTab.users, archive.id)
    },
    {
        archiveTypes: [ArchiveTypeId.Unit],
        archiveRoles: [ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        label: "common:viewUsers",
        condition: (archive) => !archive.newUserHandling,
        icon: 'People',
        onClick: (archive, navigator) => navigator.goToArchiveSettingsTab(ArchiveSettingsTab.users, archive.id)
    },
    {
        archiveTypes: [ArchiveTypeId.Unit],
        archiveRoles: [ArchiveRoleId.Owner],
        label: 'common:manageUsers',
        icon: 'PlayerSettings',
        condition: (archive) => archive.newUserHandling,
        onClick: (archive, navigator) => navigator.goToArchiveSettingsTab(ArchiveSettingsTab.profileManagement, archive.id)
    },
    {
        archiveTypes: [ArchiveTypeId.Unit],
        archiveRoles: [ArchiveRoleId.Owner],
        label: 'commands.docAnalysisSettings',
        icon: 'DocumentManagement',
        onClick: (archive, navigator) => navigator.goToArchiveSettingsTab(ArchiveSettingsTab.docAnalysis, archive.id)
    },
    {
        archiveTypes: [ArchiveTypeId.Unit],
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        label: 'commands.exportArchive',
        icon: 'ExploreData',
        onClick: (archive, navigator) => navigator.goToArchiveSettingsTab(ArchiveSettingsTab.exportReport, archive.id)
    },
    {
        archiveTypes: [ArchiveTypeId.Unit],
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor],
        label: 'commands.importExport',
        icon: 'Switch',
        condition: (archive) => archive.metadataJsonSchema !== null,
        onClick: (archive, navigator) => navigator.goToArchiveSettingsTab(ArchiveSettingsTab.importExportMetadta, archive.id)
    },
    {
        archiveTypes: [ArchiveTypeId.Unit],
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional],
        label: 'commands.recycleBin',
        icon: 'RecycleBin',
        condition: (archive) => archive.isRecycleBinActive,
        onClick: (archive, navigator) => navigator.goToArchiveSettingsTab(ArchiveSettingsTab.recycleBin, archive.id)
    },
    {
        archiveTypes: [ArchiveTypeId.Unit],
        archiveRoles: [ArchiveRoleId.Owner],
        label: 'commands.renameArchive',
        icon: 'Rename',
        onClick: (archive, navigator, dispatch) => dispatch(showRenameArchiveModal(true)),
    },
    {
        archiveTypes: [ArchiveTypeId.Unit],
        archiveRoles: [ArchiveRoleId.Owner],
        label: "commands.deleteArchive",
        icon: 'Delete',
        condition: (archive) => !archive.isPermanent,
        onClick: (archive, __, dispatch) => dispatch(showDeleteModal({show: true, type: 'DELETE_ARCHIVE'})) 
    },
];
 
export const getArchiveActions = (archiveTypeId: ArchiveTypeId, archiveRoleId: ArchiveRoleId, archive: Archive) => {
    return actions.filter(entry =>
        entry.archiveTypes.includes(archiveTypeId) &&
        (entry.condition === undefined || entry.condition(archive) === true) &&
        entry.archiveRoles.includes(archiveRoleId));
}