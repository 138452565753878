import React from "react";

import { classNamesFunction, DetailsList, DetailsRow, IColumn, SelectionMode } from "@fluentui/react";
import { NavigableItem } from "../../models/navigableFile";
import {
    IKnowledgeLabFileEnrichmentProps,
    IKnowledgeLabFileEnrichmentPropsStyles,
    IKnowledgeLabFileEnrichmentStyles
}
    from "./knowledgeLabFileEnrichment.types";

import { useKnowledgeLabSelector } from "../../knowledgeLabStore";
import JsonForm from "../../../common/components/tagPanel/jsonForm/jsonForm";
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';
import { useCurrentKnowledge } from "../../hooks/useCurrentKnowledge";

const getClassNames = classNamesFunction<IKnowledgeLabFileEnrichmentPropsStyles, IKnowledgeLabFileEnrichmentStyles>();
const generateColumnOnJsonSchemaBased = (columns: IColumn[], props: IKnowledgeLabFileEnrichmentProps,
    jsonSchema: any, className: string, getTitleFromJsonSchema: (s:string)=>string) => { //eslint-disable-line @typescript-eslint/no-explicit-any
    if (jsonSchema?.properties !== null) {
        const order = (
            "source" in jsonSchema?.properties &&
            "country" in jsonSchema?.properties &&
            "date" in jsonSchema?.properties
          ) ? {"source": "source", "country": "country", "date": "date"} 
          : jsonSchema?.properties;

        for (const name in order) {
            const columnName = name;
            const element = jsonSchema?.properties[name];
            const width = getWidth(name);
            
            columns.push(
                {
                    key: columnName,
                    name:  getTitleFromJsonSchema(name),
                    ariaLabel: 'tags',
                    isResizable: true,
                    fieldName: '',
                    minWidth: width,
                    maxWidth: width,
                    isCollapsible: true,
                    headerClassName: className,
                    onRender: function columnRender(item: NavigableItem) {

                        const metadataObject = JSON.parse(item.metadata ? item.metadata : "{}");
                        const currentMetadata = JSON.parse(`{"${columnName}": "${metadataObject[columnName]}"}`);
                        const customSchemaJSON = `{ "type": "object", "properties": { "${columnName}": ${JSON.stringify(element)} } }`;
                        const customSchema = JSON.parse(customSchemaJSON);

                        return <JsonForm
                            searchText=""
                            formData={metadataObject[columnName] ? currentMetadata : {}}
                            schema={customSchema}
                            uiSchema={{}}
                            onChange={(field) => {
                                metadataObject[columnName] = field.formData[columnName];
                                props.onEnrichmentTagEdit(item.id, JSON.stringify(metadataObject));
                               console.log("err"+[...field.errors]);
                            }}
                            onChangeSearchText={(str: string) => console.log("SearchText" + str)}
                            hasErrorFE={() => console.log("error")}
                            disabled={false}
                            onSubmit={() => console.log("Submit")}
                            onError={(err) => console.log("Errors: ", err)}
                        />
                    }
                }
            );

        }
    }
}

function getWidth(name: string) {
    if (name === "country") {
        return 220;
    } else if (name === "source" || name ===  "date") {
        return 110;
    }
    else {
        return 150;
    }
}

export const KnowledgeLabFileEnrichmentBase = (props: IKnowledgeLabFileEnrichmentProps) => {

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
   
    const { selectedKnowledgeLab } = useKnowledgeLabSelector(state => state.knowledgeLabList);
    const calloutProps = { gapSpace: 0 };
    // The TooltipHost root uses display: inline by default.
    // If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'flex', alignItems: 'center', marginBottom: '15px'} };
    const tooltipId = useId('tooltip');
    const currentKnowledge = useCurrentKnowledge();

    const getTitleFromJsonSchema = (s: string) => {
        const res =  currentKnowledge?.jsonSchemaFields.find(f => f.name === s)?.title;
        return res?? '';
    };

    const columns: IColumn[] = [
        {
            key: 'column0',
            name: "Nome report",
            ariaLabel: 'name',
            isResizable: false,
            fieldName: 'name',
            minWidth: 150,
            maxWidth: 150,
            isCollapsible: true,
            headerClassName: classNames.root,
            onRender: function columnRender(item: NavigableItem) {
                return    <TooltipHost
                content={item.name}
                id={tooltipId}
                styles={hostStyles}
                calloutProps={calloutProps}>
                <span> {item.name} </span> 
              </TooltipHost>
            }
        }
    ];

    generateColumnOnJsonSchemaBased(columns, props, selectedKnowledgeLab?.metadataShortJsonSchema, classNames.root, getTitleFromJsonSchema);

    return (
        <>
            <DetailsList
                items={props.files}
                columns={columns}
                styles={classNames.subComponentStyles.detailsList}
                setKey={"tagFiles"}
                getKey={(item: NavigableItem) => item.id}
                selectionMode={SelectionMode.none}
                onRenderRow={props => props ? <DetailsRow {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
            />
        </>
    );
}
