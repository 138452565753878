import { Icon, IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, ITheme, keyframes, mergeStyleSets, TextField } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helpers } from "../../../../../utilities/helpers";
import { Constants } from "../../../../models/constants";
import { archivesApi } from "../../../../services/archives/archives.api";

export type FirstPageData = {
    name?: string;
    description?: string;
    isValid: boolean;
}

export interface ICreateArchiveFirstPageProps {
    theme?: ITheme;
    initialState: FirstPageData;
    onArchiveDataChange: (input: FirstPageData) => void;
}

const classNames = mergeStyleSets({
    formItem: [
        'edi-create-archive-modal-form-item',
        {
            ':not(:first-child)': {
                marginTop: 30
            }
        }
    ]
})

export const CreateArchiveFirstPage = (props: ICreateArchiveFirstPageProps) => {
    const [data, setData] = useState<FirstPageData>(props.initialState);
    const [validationIcon, setValidationIcon] = useState('Error');
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const { t } = useTranslation(['createArchiveModal', 'common']);
    useEffect(() => props.onArchiveDataChange(data), [data, props]);

    const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
        setData({ ...data, isValid: !error, name: value ?? "" });
        setValidationIcon(error ? 'Error' : 'Completed');
    }, [data]);

    const onDescriptionValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
        setData({ ...data, isValid: data.isValid, description: value ?? "" });
    }, [data]);

    const validateArchiveName = useCallback(async (value: string) => {
        //if (!value)
        //    return `${t('common:fieldRequired')}`;

        setValidationIcon('ProgressRingDots');

        try {
            if (value) {
                const [isValid] = await Promise.all([archivesApi.isArchiveNameAvailable(value), Helpers.delay(500)]);
                return isValid ? "" : t('nameNotAvailable');
            }
        }
        catch {
            return t('common:genericErrorCheckName');
        }
    }, [t]);

    const styles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = () => {
        return {
            errorMessage: {
                position: 'absolute'
            },
            suffix: [
                { background: 'transparent', },
                validationIcon === 'Error' && { color: palette.redDark },
                validationIcon === 'Completed' && { color: palette.greenDark },
                validationIcon === 'ProgressRingDots' && {
                    '> i': {
                        animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite`
                    }
                }
            ]
        }
    }

    return (
        <>
            <div className={classNames.formItem}>
                <TextField
                    label={t('common:name')}
                    maxLength={Constants.MAX_LENGTH_ARCHIVE_NAME}
                    placeholder={t('namePlaceholder')}
                    deferredValidationTime={250}
                    onGetErrorMessage={validateArchiveName}
                    required
                    defaultValue={data.name}
                    autoFocus
                    autoComplete={"off"}
                    styles={styles}
                    onNotifyValidationResult={onNameValidationResult}
                    onChange={() => setData({ ...data, isValid: false })}
                    onRenderSuffix={() => <Icon iconName={validationIcon} />}
                />
            </div>
            <div className={classNames.formItem}>
                <TextField
                    label={t('common:description')}
                    maxLength={Constants.MAX_LENGTH_ARCHIVE_DESCRIPTION}
                    resizable={false}
                    rows={4}
                    multiline
                    defaultValue={data.description}
                    placeholder={t('descriptionPlaceholder')}
                    deferredValidationTime={250}
                    onNotifyValidationResult={onDescriptionValidationResult}
                />
            </div>
        </>
    )
}