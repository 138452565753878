import { IStyleFunction } from "@fluentui/react"
import { IDocumentTranslationModalPropsStyles, IDocumentTranslationModalStyles } from "./documentTranslationModal.types";

export const DocumentTranslationModalGlobalClassNames = {
    root: 'edi-document-translations-root',
    buttonsContainer: 'edi-document-translations-buttonsContainer',
    downloadPage: 'edi-document-translations-download-Page',
    leaveButton: 'edi-document-translations-leave-button',
    spinnerBody: 'edi-document-translations-spinner',
    spinnerMessage: 'edi-document-translations-spinner-message',
}

export const getStyles: IStyleFunction<IDocumentTranslationModalPropsStyles, IDocumentTranslationModalStyles> = (props: IDocumentTranslationModalPropsStyles): IDocumentTranslationModalStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [DocumentTranslationModalGlobalClassNames.root, {
                
        }],
        buttonsContainer:[DocumentTranslationModalGlobalClassNames.buttonsContainer,{
            display:'flex',
            flexDirection:'row',
            gap: 5,
            justifyContent: 'flex-end'
        }],
        leaveButton:[DocumentTranslationModalGlobalClassNames.leaveButton,{
            display:'flex',
            flexDirection:'row',
            gap: 5,
            justifyContent: 'center'
        }],
        downloadPage: [DocumentTranslationModalGlobalClassNames.downloadPage,{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }],
        spinnerBody: [DocumentTranslationModalGlobalClassNames.spinnerBody,{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%'
        }],
        spinnerMessage: [DocumentTranslationModalGlobalClassNames.spinnerMessage,{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20
        }],
        subComponentStyles: {            
            dropdown: {
                root: {
                    padding: '5px 0',
                },
                label: {
                    color: palette.black,
                    fontWeight: 400,
                },
                title: {},
                caretDownWrapper: {},
                caretDown: {},
                errorMessage: {},
                dropdownItemsWrapper: {},
                dropdownItems: {},
                dropdownItem: {},
                dropdownItemSelected: {},
                dropdownItemDisabled: {},
                dropdownItemSelectedAndDisabled: {},
                dropdownItemHidden: {},
                dropdownOptionText: {},
                dropdownDivider: {},
                dropdownItemHeader: {},
                panel: {},
                callout: {
                    '*::-webkit-scrollbar': {
                        width: 16,
                        height: 16
                    },
                    '*::-webkit-scrollbar-thumb': {
                        border: '4px solid transparent',
                        backgroundClip: 'padding-box',
                        backgroundColor: 'transparent',
                        borderRadius: 25
                    },
                    '*:hover::-webkit-scrollbar-thumb': {
                        backgroundColor: palette.neutralSecondary// 'rgba(66, 66, 66, 0.3)'
                    },
                    '*::-webkit-scrollbar-thumb:hover': {
                        border: '2px solid transparent'
                    }
                },
                subComponentStyles: {
                    label: {},
                    multiSelectItem: {},
                    panel: {}
                }
            },
        }
    }
};

