import { IStyleFunction } from "@fluentui/react"
import { IMultiStepModalPropsStyles, IMultiStepModalStyles } from "../../../../common/components/multiStepModal/multiStepModal.types"
import { ICreateArchiveModalPropsStyles, ICreateArchiveModalStyles } from "./createArchiveModal.types"

export const getStyles: IStyleFunction<ICreateArchiveModalPropsStyles, ICreateArchiveModalStyles> = (props: ICreateArchiveModalPropsStyles): ICreateArchiveModalStyles => {
    const { palette } = props.theme!;  // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        subComponentStyles: {
            ediModal: (props: IMultiStepModalPropsStyles): IMultiStepModalStyles => {
                return {
                    body: {},
                    bodyContent: {},
                    title: {},
                    closeIcon: {},
                    container: {},
                    content: {},
                    footer: {
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    },
                    header: {},
                    subComponentStyles: {
                        iconButton: {}
                    },
                    subTitle: {}
                }
            }
        },
        requiredTextLeft:{
            display:'flex', 
            alignItems:'center',
            gap: 5,
            justifyContent: "flex-start",
            bottom: 0,
            left:40,
            color: palette.redDark,
            background: palette.white
        }
    }
}