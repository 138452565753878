/*eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from "react";
import { classNamesFunction, DefaultButton, Dialog, DialogFooter, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useDocLabDispatch, useDocLabState } from "../../../../docLabStore";
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive";
import { IModalStepData } from "../../../../../common/components/multiStepModal/multiStepModal.types";
import MultiStepModal from "../../../../../common/components/multiStepModal/multiStepModal";
import { showFolderZipExport } from "../../../../features/archiveContent";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { IExportFolderZipModalProps, IExportFolderZipModalPropsStyles, IExportFolderZipModalStyles } from "./exportFolderZipModal.types";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import FolderContent from "../../../../../common/components/folderContent/folderContent";
import { ArchiveItem } from "../../../../models/archiveItem";
import { useAsyncApi } from "../../../../../utilities/hooks";
import { archivesApi } from "../../../../services/archives/archives.api";
import { ErrorsType } from "../../../../models/callsApi";
import { ReportType } from "../../../../models/archive";
import { CreateReportMetadataRequest } from "../../../../services/archives/archives.contracts";

const getClassNames = classNamesFunction<IExportFolderZipModalPropsStyles, IExportFolderZipModalStyles>();

enum ExportFolderZipSteps {
    chooseFolder = 0,
    exporting = 1
}

export const ExportFolderZipModalBase = (props: IExportFolderZipModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['exportArchiveModal', 'common']);
    const dispatch = useDocLabDispatch();
    const currentArchive = useCurrentArchive();
    const docLabState = useDocLabState();
    const [selectedFolderId, setSelectedFolderId] = useState<number>(0);
    const [showDialog, setShowDialog] = useState(false);
    const [currentStep, setCurrentStep] = useState<ExportFolderZipSteps>(ExportFolderZipSteps.chooseFolder);

    const { execute: createExportFolderZip, loading, error } = useAsyncApi<void, void>({
        func: async () => {
            if (!currentArchive)
                return;
            try {
                const request: CreateReportMetadataRequest = {
                    archiveReportId: docLabState.archiveContent.selectedReportIdExportShown,
                    type: ReportType.ENITER,
                    additionalInput: {
                        SelectedFolderId: selectedFolderId
                    }
                }
                await archivesApi.createReportMetadata(request);
            }
            catch (er: any){ //eslint-disable-line @typescript-eslint/no-explicit-any
                const error: ErrorsType = er as ErrorsType;
                console.log("Error: ", error);
            }
        }
    });

    const bodyChooseFolder = () => {
        return (
            <div style={{height: '100%', overflow: 'overlay'}}>
                <FolderContent
                    archive={currentArchive}
                    creatingAnalysis={false}
                    isError={isError => isError}
                    isFromReport={true}
                    maxNumFiles={1}
                    notViewSearchBar={true}
                    selectAllEnabled={false}
                    onSelectionChange={(selectedItems: ArchiveItem[]) => selectedItems.length > 0 ? setSelectedFolderId(selectedItems[0].id) : setSelectedFolderId(0) }
                />
            </div>
        )
    }

    const bodyExportingContent = () => {
        return (
            <div className={classNames.secondFooterContainer}>
                {loading ?
                    <div className={classNames.centerContentContainer}>
                        <Spinner size={SpinnerSize.large} />
                    </div> : error ?
                        renderError() :
                    <div className={classNames.centerContentContainer}>
                        <TeamsImage
                            imageName={ImageName.Sandglass}
                            className={classNames.sandglassImage}
                            fullContainer
                            scale={0.8}
                        />
                        <div className={classNames.exportingFooter}>
                            {t('operationInProgress')}
                            <PrimaryButton
                                text={t('common:leave')}
                                allowDisabledFocus
                                onClick={() => dispatch(showFolderZipExport({show: false, reportId: 0}))}
                                styles={{ root: { padding: '0px 80px', marginTop: '20px' } }}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }

    const footer = () => {
        return (
            <div className={classNames.buttonContainer}>
                <DefaultButton
                    style={{marginRight: '10px'}}
                    onClick={() => {                    
                        dispatch(showFolderZipExport({show: false, reportId: 0}));
                    }}
                    text={t('common:previousButton')}
                />
                <PrimaryButton
                    onClick={() => {
                        switch(currentStep){
                            case ExportFolderZipSteps.chooseFolder: {
                                setCurrentStep(ExportFolderZipSteps.exporting);
                                createExportFolderZip();
                                break;
                            }
                            case ExportFolderZipSteps.exporting:
                                dispatch(showFolderZipExport({show: false, reportId: 0}));
                                break;
                            default:
                                dispatch(showFolderZipExport({show: false, reportId: 0}));
                                break;
                        }
                    }}
                    text={t('common:nextButton')}
                    disabled={selectedFolderId === 0}
                />
            </div>
        )
    }

    const renderError = () => {
        return <div className={classNames.centerContentContainer}>
            <TeamsImage
                imageName={ImageName.Error1}
                className={classNames.sandglassImage}
                fullContainer
                scale={0.8}
            />
            <div className={classNames.exportingFooter}>
                {t('operationFailed')}
                <div className={classNames.buttonContainer} style={{marginTop: '20px'}}>
                    <DefaultButton
                        style={{marginRight: '10px'}}
                        text={t('common:close')}
                        allowDisabledFocus
                        onClick={() => dispatch(showFolderZipExport({show: false, reportId: 0}))}
                    />
                    <PrimaryButton
                        text={t('common:retry')}
                        allowDisabledFocus
                        onClick={() => createExportFolderZip()}
                    />
                </div>
            </div>
        </div>
    }
    
    const steps: IModalStepData[] = [
        {
            title: t('folderZipModal.titleExport'),
            body: bodyChooseFolder(),
            footer: footer()
        },
        {
            bodyOnly: true,
            body: bodyExportingContent()
        }
    ]

    return (
        <>
            <MultiStepModal
                isOpen
                showCloseIcon
                width={600}
                height={600}
                hideScrollbarBody
                onCloseClick={currentStep === ExportFolderZipSteps.exporting ? () => dispatch(showFolderZipExport({show: false, reportId: 0})) : () => setShowDialog(true)}
                activeStep={currentStep}
                steps={steps}
                animateInitialStep
            />
            <Dialog
                hidden={!showDialog}
                dialogContentProps={{ title: `${t('stopCreating')}` }}
            >
                <DialogFooter>
                    <DefaultButton
                        style={{marginRight: '10px'}}
                        onClick={() => setShowDialog(false)}
                        text={t('common:dialogButtonCancel')}
                    />
                    <PrimaryButton
                        onClick={() => dispatch(showFolderZipExport({show: false, reportId: 0}))}
                        text={t('common:dialogButtonConfirm')}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
}