import React, { useEffect, useMemo, useState} from "react";
import { ISelectProfileMemberBaseProps, ISelectProfileMemberBasePropsStyles, ISelectProfileMemberBaseStyles } from "./selectProfileMember.types";
import { classNamesFunction, PrimaryButton, NormalPeoplePicker, IPersonaProps, IBasePickerSuggestionsProps, DetailsList, DetailsRow, SelectionMode, IColumn, PersonaSize, Persona, IconButton, IBasePickerStyles, mergeStyleSets, useTheme, TooltipHost, Label, TooltipDelay, Stack  } from "@fluentui/react";
import { archivesApi } from "../../../docLab/services/archives/archives.api";
import { AddMembersPageType } from "../../../utilities/constants";
import { useTranslation } from "react-i18next";
import { UserProfile } from "../../../docLab/models/user";
import { IUser } from "../../interfaces/IUser";
import { mapper } from "../../../utilities/mapper";
import IconTag from "../iconTag/iconTag";
import currentUser from "../../../modules/authentication/currentUser";

const getClassNames = classNamesFunction<ISelectProfileMemberBasePropsStyles, ISelectProfileMemberBaseStyles>();

export interface IExtPersonaProps extends IPersonaProps {
    userData: IUser;
}

export type MembersPageData = {
    isValid: boolean;
    selectedPeople: UserProfile[];
}

export interface IAddMembersPageProps {
    initialState: MembersPageData;
    onDataChange: (input: MembersPageData) => void;
    translationNamespace: string;
    getUsers: (keyword: string, currentUsers: IUser[]) => Promise<IUser[]>;
    modalType: AddMembersPageType;
    enableAllowFolderToggle: boolean;
    toggleAlwaysTrue?: boolean;
    enableRoleSelection: boolean;
}

export const SelectProfileMemberBase = (props: ISelectProfileMemberBaseProps) => {    
    const [data, setData] = useState<MembersPageData>({isValid: false, selectedPeople: []});
    const [pickerBuffer, setPickerbuffer] = useState<IExtPersonaProps[]>([]);
    const theme = useTheme();
    const { t } = useTranslation(['common']);
    const classNames2 = getClassNames(props.styles, { theme: props.theme, className: props.className });

    useEffect(() => {
        setData({isValid: data.isValid, selectedPeople: props.userToRender ?? []})
    }, [props.userToRender]); //eslint-disable-line react-hooks/exhaustive-deps

    const searchForAvailableMembers = (filter: string, selectedItems?: IPersonaProps[]) => {
        const elements = selectedItems as IExtPersonaProps[];
        return archivesApi.getNewAvailableProfileMember(props.archiveId, filter, 1, 0, 
            elements.map(e => e.userData.id).concat(props.notAvaiableUserIds ?? []))
            .then(result => {
                return result.map(u => mapUserToPersona(u))
            })
    }

    const mapUserToPersona = (user: IUser): IExtPersonaProps => {
        return {
            ...mapper.mapUserToPersona(user),
            userData: user
        }
    }
    
    const movePickerBufferToMembers = () => {
        const newState = { ...data };
 
        newState.selectedPeople = newState.selectedPeople.concat(pickerBuffer.map(user => user.userData as UserProfile));
        newState.isValid = newState.selectedPeople.length > 0;

        props.selectedUser(newState.selectedPeople);
        setData(newState);
        setPickerbuffer([]);
    }

    const addPeopleToPickerBuffer = (people?: IPersonaProps[]) => {
        const converted: IExtPersonaProps[] | undefined = people?.map(p => p as IExtPersonaProps);
        setPickerbuffer(converted ?? []);
    }

    const removeMember = (member: UserProfile) => {
        const newState = { ...data };
        newState.selectedPeople = data.selectedPeople.filter(p => p.id !== member.id);
        newState.isValid = newState.selectedPeople.length > 0;

        props.selectedUser(newState.selectedPeople);
        if(props.removedUser)
             props.removedUser(member.id);
        setData(newState);
    }    

    const renderMemberPersona = (item: UserProfile) => {
        const mapped = mapUserToPersona(item);
        return <Persona {...mapped} size={PersonaSize.size40} coinSize={32} />
    };

    const remainingMembersTooltip = (remainingMembersList: string[]): JSX.Element => {
        return (
            <Stack horizontalAlign={'start'} tokens={{ childrenGap: 2 }}>
                {remainingMembersList.map((x, i) => <Label key={i} className={classNames2.remainingIconTagContainer}>{x}</Label>)}
            </Stack>);
    }

    const renderProfiles = (item: UserProfile) => {
        const membersList = item.profiles.slice(0, 2);
        const remainingMembersList = item.profiles.slice(2, item.profiles.length);
        return (
            <div className={classNames2.containerTag}>
                {membersList.map((u, i) => {
                    return (
                        <IconTag key={u+i} label={u} labelClassName={classNames2.iconTagLabel} tooltip />                               
                    )
                })}
                {remainingMembersList.length > 0 &&
                    <TooltipHost
                        id={'remainingMemberTooltips'}
                        content={remainingMembersTooltip(remainingMembersList)}
                        delay={TooltipDelay.zero}>
                        <Label
                            styles={classNames2.subComponentStyles.templatesCountCircle}>+{remainingMembersList.length}
                        </Label>
                    </TooltipHost>
                }
            </div>
        );
    };

    const renderMemberRemoveButton = (member: UserProfile) => 
        currentUser.currentUserId !== member.id ? <IconButton iconProps={{ iconName: "Cancel" }} onClick={() => removeMember(member)}/> : <></>;

    const availableColumns = () : IColumn[] => {
        const columns = [
            { key: 'persona',  name: 'persona', minWidth: 120, onRender: renderMemberPersona },
            { key: 'profiles', name: 'profiles', minWidth: 450, onRender: renderProfiles }
        ];
        if(!props.readonly)
            columns.push({ key: 'remove', name: 'remove', minWidth: 24, onRender: renderMemberRemoveButton });

        return columns;
    };

    const classNames = mergeStyleSets({
        disclaimerZone: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 30
        },
        peoplePickerZone: {
            display: 'flex',
            marginBottom: '20px'
        },
        peoplePickerSuggestions: {
            padding: '8px 0'
        },
        peoplePickerSuggestionItem: {
            '::after': {
                display: 'none',                 
            },
            ".is-suggested": {
                background: theme.palette.neutralQuaternary
            }
        },
        detailsListRow: {
            background: 'transparent !important',
        },
        checkboxAlign: {
            height: '100%', 
            alignItems: 'center', 
            ".ms-Checkbox-label": {
                alignItems: 'center', 
                height: '32px'
            }, 
            ".ms-Checkbox-text": {
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap', 
                fontSize: '13px', 
                lineHeight: '12px'
            }, 
        }
    })

    const suggestionOptions: IBasePickerSuggestionsProps = {
        showRemoveButtons: false,
        suggestionsClassName: classNames.peoplePickerSuggestions,
        suggestionsItemClassName: classNames.peoplePickerSuggestionItem,
        loadingText: t('common:loading'),
        noResultsFoundText: t('noResults'),
    };
    
    const peoplePickerStyles = useMemo((): IBasePickerStyles => ({
        input: {
            backgroundColor: 'transparent'
        },
        text: {
            border: '1px solid rgb(240, 240, 240) !important',
            borderRadius: 2,
            backgroundColor: 'rgb(240, 240, 240)',
        },
        itemsWrapper: {
            ".ms-PickerPersona-container": {
                background: 'rgb(245, 245, 245)',
                border: "1px solid rgb(245, 245, 245)",
                ':hover': {
                    background: 'rgba(98, 100, 167, 0.2)',
                    border: "1px solid rgba(98, 100, 167, 0.2)",
                }
            },
        },
        root: {},
        screenReaderText: {}
    }), []);
    
    return (
        <>
            {!props.visualizeMode && 
                <div className={classNames.peoplePickerZone}>
                    <NormalPeoplePicker
                        styles={peoplePickerStyles}
                        onResolveSuggestions={searchForAvailableMembers}
                        resolveDelay={500}
                        pickerSuggestionsProps={suggestionOptions}
                        selectedItems={pickerBuffer}
                        onChange={addPeopleToPickerBuffer}
                    />
                    <PrimaryButton text={t("common:add")} onClick={movePickerBufferToMembers} disabled={pickerBuffer.length === 0} />
                </div>}
            <div>
                <DetailsList
                    items={data.selectedPeople}
                    columns={availableColumns()}
                    selectionMode={SelectionMode.none}
                    isHeaderVisible={false}
                    onRenderRow={props => props ? <DetailsRow {...props} className={classNames.detailsListRow} /> : null}
                />
            </div>
        </>
    );
}