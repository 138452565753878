import { IStyleFunction } from "@fluentui/react"
import { ITopicInfoModalPropsStyles, ITopicInfoModalStyles } from "./topicInfoModals.types";

export const TopicInfoModalGlobalClassNames = {
    root: 'edi-profile-view-root'
}

export const getStyles: IStyleFunction<ITopicInfoModalPropsStyles, ITopicInfoModalStyles> = (props: ITopicInfoModalPropsStyles): ITopicInfoModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [ TopicInfoModalGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px',
                selectors: {
                    ' .edi-profile-view-action': {
                        right: '15px',
                        bottom: '15px',
                    }
                }
            }            
        ],
        footerButtons:
        {
            'margin-left': '10px'
        },
        errorMessage: {
            color: palette.red
        },
        bodyContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start'
        },
        rowContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '5px'
        }
    }
}