/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
import { classNamesFunction, ConstrainMode, DetailsRow, IColumn, IconButton, Icon,
    IDetailsRowProps, Label, Persona, PersonaSize, 
    SelectionMode, ShimmeredDetailsList, Stack, TooltipDelay, TooltipHost } from "@fluentui/react";
import React, { useState } from "react";
import { IManageInterestsBaseProps, IManageInterestsBasePropsStyles, IManageInterestsBaseStyles } from "./manageInterests.types";
import { useTranslation } from "react-i18next";
import { useDocLabDispatch } from "../../../docLabStore";
import { setToolbarActions } from "../../../features/toolbarActions";
import IconTag from "../../../../common/components/iconTag/iconTag";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import { mapper } from "../../../../utilities/mapper";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { archivesApi } from "../../../services/archives/archives.api";
import EditTopicsOfInterestModal from "./editTopicsOfInterestModal/editTopicsOfInterestModal";
import { NonOwnerTopics, TopicShort } from "./topicModel/topic";
import DeleteTopicsModal from "./deleteTopicsModal/deleteTopicsModal";
import currentUser from "../../../../modules/authentication/currentUser";

const getClassNames = classNamesFunction<IManageInterestsBasePropsStyles, IManageInterestsBaseStyles>();

export const ManageInterestsBase = (props: IManageInterestsBaseProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const dispatch = useDocLabDispatch();
    const currentArchive = useCurrentArchive();
    const { t } = useTranslation(['topicOfInterest', 'common']);
    const [editTopicsOfInterestModal, setEditTopicsOfInterestModal] = useState<{opened: boolean, topics : TopicShort[]}>();
    const [archiveMember, setArchiveMember] = useState<NonOwnerTopics>();
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false);
    const [associatedTopics, setAssociatedTopics] = useState<TopicShort[]>();
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
    const [deleteTopicsErrorMessage, setDeleteTopicsErrorMessage] = useState<string>();
    const [topicIdsToUpdate, setTopicIdsToUpdate] = useState<number[]>([]);

    useOnMount(() => {
        dispatch(setToolbarActions([]));
        getNonOwnerTopics();
        if (!currentArchive)
            return;
    })

    const { execute: getNonOwnerTopics, loading } = useAsyncApi<void, NonOwnerTopics>({
        func: async (): Promise<NonOwnerTopics> => {
            if (!currentArchive)
                return Promise.reject("Current archive is not available");

            const response = await archivesApi.getNonOwnerTopics(currentArchive.id);
            setArchiveMember(response);
            setAssociatedTopics(response.topics);

            return response;
        }
    })

    const updateTopics = async () => {

        const currentUserId = currentUser.currentUserId;
        try{
            await archivesApi.updateNonOwnerTopics(currentUserId, topicIdsToUpdate);
        }
        finally{
            onEditModalClose();
        }
    }

    const onEditModalClose = () => {
        getNonOwnerTopics();
    }

    const deleteTopics = async () => {
        setLoadingDelete(true);
        const currentUserId = currentUser.currentUserId;

        try{
            await archivesApi.deleteUserTopics(currentUserId);
        // eslint-disable-next-line
        }catch (ex: any) {
            if(ex.subCode === 40001)
                setDeleteTopicsErrorMessage(t('deleteErrorMessage'));
            else
                setDeleteTopicsErrorMessage(t('common:genericErrorApi'));
        }finally{
            setLoadingDelete(false);
            onDeleteModalClose();
        }
    }

    const onDeleteModalClose = () => {
        setIsDeleteConfirmationModalOpen(false);
        getNonOwnerTopics();
    }
    
    const remainingTopicsTooltip = (remainingTopicsList: TopicShort[]): JSX.Element => {
        return (
            <Stack horizontalAlign={'start'} tokens={{ childrenGap: 2 }}>
                {remainingTopicsList.map((topic, i) => <Label key={i} className={classNames.remainingIconTagContainer}>{topic.topicName}</Label>)}
            </Stack>);
    }

    let columns: IColumn[] = [
        {
            key: 'persona',
            name: t('common:name'),
            minWidth: 100,
            maxWidth: 350,
            headerClassName: classNames.headerRow,
            onRender: function renderMemberPersona(member: NonOwnerTopics) {
                return (
                    <Persona {...mapper.mapUserToPersona(member)} size={PersonaSize.size40} coinSize={32} />
                );
            }
        },         
        {
            key: 'topic',
            name: t('topics'),
            minWidth: 150,
            headerClassName: classNames.headerRow,
            isSortedDescending: true,
            onRender: function renderMemberPersona(member: NonOwnerTopics) {
                const topicsList = (member?.topics ?? []).slice(0, 3);
                const remainingTopicsList = (member?.topics ?? []).slice(3, member?.topics?.length);
                return (
                    <div className={classNames.containerTag}>
                        {topicsList.map((topic, i) => {
                            return (
                                <IconTag key={topic.topicId+i} label={topic.topicName} labelClassName={classNames.iconTagLabel} tooltip />                               
                            )
                        })}
                        {remainingTopicsList.length > 0 &&
                            <TooltipHost
                                id={'remainingMemberTooltips'}
                                content={remainingTopicsTooltip(remainingTopicsList)}
                                delay={TooltipDelay.zero}>
                                <Label
                                    styles={classNames.subComponentStyles.templatesCountCircle}>+{remainingTopicsList.length}
                                </Label>
                            </TooltipHost>
                        }
                    </div>
                )
            }
        },
        {
            key: 'add',
            name: '',
            minWidth: 10,
            styles: { cellTitle: { padding: 0, justifyContent: 'center' } },
            onRenderHeader: function renderAdd() {
                return (
                    <div>
                        <IconButton 
                            iconProps={{ iconName: "Add" }} 
                            style={{ width: '30px', height: '30px', fontSize: '20px' }}  
                            // disabled={isDuplicateMember}
                            onClick={(e) => {
                                setEditTopicsOfInterestModal({opened: true, topics: archiveMember?.topics?? []})
                                e.stopPropagation();
                            }}
                        />
                    </div>
                );
            }
        },
        {
            key: 'edit',
            name: '',
            minWidth: 10,
            styles: { cellTitle: { padding: 0, justifyContent: 'center' } },
            onRenderHeader: function renderEdit() {
                return (
                    <div>
                        <IconButton 
                            iconProps={{ iconName: "Edit" }} 
                            style={{ width: '30px', height: '30px', fontSize: '20px' }} 
                             // disabled={isDuplicateMember} 
                            onClick={(e) => {
                                setEditTopicsOfInterestModal({opened: true, topics: archiveMember?.topics?? []})
                                e.stopPropagation();
                            }}
                        />
                    </div>
                );
            }
        },
        {
            key: 'delete',
            name: '',
            minWidth: 10,
            styles: { cellTitle: { padding: 0, justifyContent: 'center' } },
            onRenderHeader: function renderRemove() {
                return (
                    <div>
                        <IconButton 
                            iconProps={{ iconName: "Delete" }} 
                            style={{ width: '30px', height: '30px', fontSize: '20px' }}  
                            onClick={(e) => {
                                setIsDeleteConfirmationModalOpen(true);
                                e.stopPropagation(); 
                            }}
                        />
                    </div>
                );
            }
        }
    ];

    if (archiveMember?.topics?.length === 0) {
        columns = columns.filter((column) => (column.key !== 'edit' &&  column.key !== 'delete'));
    }
    else 
        columns = columns.filter((column) => column.key !== 'add');


    return (
        <>
            <Stack className={classNames.root}>

                <Stack horizontal horizontalAlign="space-between" verticalAlign={'center'}>
                    <Stack.Item className={classNames.title}>
                    <Icon iconName={"SearchAndApps"} className={classNames.titleIcon} />
                        <span style={{ paddingLeft: 10 }}>{t('manageInterests')}</span>
                    </Stack.Item>
                </Stack>

                <div id={"memberviewDetailsListZone"} >
                    <ShimmeredDetailsList
                        items={[archiveMember]}
                        styles={classNames.subComponentStyles.shimmeredDetailsList}
                        detailsListStyles={classNames.subComponentStyles.detailsList}
                        columns={columns}
                        getKey={(item: NonOwnerTopics) => item && item.id}
                        selectionMode={SelectionMode.none}
                        enableShimmer={loading}
                        constrainMode={ConstrainMode.unconstrained}
                        onShouldVirtualize={() => false}
                        isHeaderVisible={true}        
                        onRenderRow={(props?: IDetailsRowProps) =>
                            props ? <DetailsRow {...props} styles={classNames.subComponentStyles.row} /> : null}                                    
                    />
                </div>

            </Stack> 
            
            <EditTopicsOfInterestModal
                isOpen={editTopicsOfInterestModal?.opened ?? false}
                topics={archiveMember?.topics ?? []}
                onClose={() => setEditTopicsOfInterestModal({opened: false, topics: []})}
                onConfirm={() => updateTopics()}
                setTopicIdsToUpdate={(topicIds: number[]) => setTopicIdsToUpdate(topicIds)}
                archiveId={currentArchive?.id ?? 0}
            /> 

            {isDeleteConfirmationModalOpen && associatedTopics &&
                <DeleteTopicsModal
                    title={t("deleteTopicsTitle")}
                    subtitle={t("deleteTopicsMessage")}
                    isOpen={isDeleteConfirmationModalOpen}
                    onClose={() => onDeleteModalClose()}
                    onConfirm={() => deleteTopics()}
                    errorMessage={deleteTopicsErrorMessage}
                    loading={loadingDelete}
                />
            }   
        </>
    )
}