import { styled } from "@fluentui/react";
import { ManageInterestsBase } from "./manageInterests.base";
import { getStyles } from "./manageInterests.styles";
import { IManageInterestsBaseProps, IManageInterestsBasePropsStyles, IManageInterestsBaseStyles } from "./manageInterests.types";

const ManageInterests = styled<IManageInterestsBaseProps, IManageInterestsBasePropsStyles, IManageInterestsBaseStyles>(
    ManageInterestsBase, 
    getStyles,
    undefined,
    { scope: 'ManageInterests'}
)

export default ManageInterests;