import { styled } from "@fluentui/react";
import { getStyles } from "./navigationItemModal.styles";
import { INavigationItemPropsStyles, INavigationItemStyles, INavigationItemProps } from "./navigationItemModal.types";
import { NavigationItemModalBase } from "./navigationItemModal.base";

const NavigationItemModal = styled<INavigationItemProps, INavigationItemPropsStyles, INavigationItemStyles>(
    NavigationItemModalBase,
    getStyles,
    undefined,
    { scope: 'FolderNavigation' }
);

export default NavigationItemModal;