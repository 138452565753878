import { IDropdownStyleProps, IDropdownStyles, IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";

export interface IDocumentTranslationModalProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<IDocumentTranslationModalPropsStyles, IDocumentTranslationModalStyles>;
    className?: string;
    onCloseClick?: () => void;
    onSuccess: () => void;
    // ADD COMPONENT PROPS HERE
}

export interface IDocumentTranslationModalPropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<IDocumentTranslationModalPropsStyles, IDocumentTranslationModalStyles>;
    className?: string;
    // ADD STYLE PROPS HERE FOR CONDITIONAL CSS GENERATION
}

export interface IDocumentTranslationModalStyles {
    root: IStyle;
    buttonsContainer: IStyle;
    downloadPage: IStyle;
    leaveButton: IStyle;
    spinnerBody: IStyle;
    spinnerMessage: IStyle;
    subComponentStyles: {
        dropdown:  IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>,
    }
}

export enum DocumentTranslationEnum {
    languageChoicePage,
    translationPage,
    downloadPage
}

export type KeyValueLanguagesType = {
    key: string, 
    value: string
}
