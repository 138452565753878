import { styled } from "@fluentui/react";
import { IDocVerifyModalFooterProps, IDocVerifyModalFooterPropsStyles, IDocVerifyModalFooterStyles } from "./docVerifyModalFooter.types";
import { getStyles } from "./docVerifyModalFooter.styles";
import { DocVerifyModalFooterBase } from "./docVerifyModalFooter.base";

const DocVerifyModalFooter = styled<IDocVerifyModalFooterProps, IDocVerifyModalFooterPropsStyles, IDocVerifyModalFooterStyles>(
    DocVerifyModalFooterBase,
    getStyles,
    undefined,
    { scope: 'DocVerifyModalFooter' }
);

export default DocVerifyModalFooter;