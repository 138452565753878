import React from "react";
import { classNamesFunction, Icon, TextField, TooltipHost } from "@fluentui/react";
import { ITagPropsStyles, ITagStyles, ITagProps } from "./tag.types";
import { useTranslation } from "react-i18next";

const getClassNames = classNamesFunction<ITagPropsStyles, ITagStyles>();

/* eslint-disable sonarjs/cognitive-complexity */
export const TagBase = (props: ITagProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, formalAspects: props.keyTag === "FormalAspects" });
    const { t } = useTranslation(['tagPanel', 'common']);

    const getTagContent = () => {
        return (
            <>
                {props.value.title && <span className={classNames.propertyContainer}> {props.value.title}</span>}
                <TooltipHost styles={classNames.subComponentStyles.tooltipStyle} content={props.tooltipContent ?? props.value.value}>
                    <span className={classNames.propertyName}> {props.value.value}</span>
                </TooltipHost>
                {props.value.isPinned && <Icon className={classNames.pinIcon} iconName={"Pinned"} />}
                {props.selectableTag && !props.value.toDelete &&
                    <div><Icon
                        onClick={props.onCancelSelected}
                        style={props.small ? { fontSize: 'xx-small' } : { fontSize: 'small' }}
                        className={classNames.pinIcon}
                        iconName={"Cancel"}
                    />
                    </div>
                }
                {props.selectableTag && props.value.toDelete &&
                    <Icon
                        onClick={props.onCancelSelected}
                        style={props.small ? { fontSize: 'xx-small' } : { fontSize: 'small' }}
                        className={classNames.pinIcon}
                        iconName={"Add"}
                    />
                }
            </>
        )
    }

    const getFormalAspectsTagContent = () => {
        const formalAspectName = t('values.' + props.value.title);
        return (
            <>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', flexBasis: '50%' }}>
                    <TooltipHost styles={{ root: { display: 'flex', cursor: 'context-menu' } }} content={props.tooltipContent ?? formalAspectName}>
                        {props.value.title &&
                            <span className={classNames.propertyName}>{formalAspectName}</span>}
                    </TooltipHost>
                </div>

                <div className={classNames.formalAspectsValue} style={!props.value.isPinned && !props.extraLabel ? { flexBasis: '50%', borderRadius: '0 10px 10px 0' } : {}}>
                    <TooltipHost styles={classNames.subComponentStyles.tooltipStyle} content={props.value.value}>
                        {(!props.selectableTag || props.value.title === "FileFormatExtension") ?
                            <span style={{ padding: "0 8px"}}>{props.value.value}</span>
                            :
                            <TextField
                                type="number"
                                inputMode="numeric"
                                value={props.value.value}
                                min={0}
                                step={1}
                                onChange={(e) => props.onChangeValue && props.onChangeValue(e.currentTarget.value)}
                            />
                        }
                    </TooltipHost>
                </div>
                {props.value.isPinned && <div className={classNames.formalAspectsPinIcon}><Icon className={classNames.pinIcon} iconName={"Pinned"} /></div>}
                {props.extraLabel &&
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', flexBasis: '50%' }}>
                        <TooltipHost styles={{ root: { display: 'flex', cursor: 'context-menu' } }} content={props.extraLabel}>
                                <span className={classNames.propertyName}>{props.extraLabel}</span>
                        </TooltipHost>
                    </div>
                }
            </>
        )
    }

    const getStyle = (): React.CSSProperties | undefined => {
        if (props.keyTag === "Languages")
            return { flexBasis: '10%' }

        if (props.small && props.selectableTag)
            return { height: 'unset', 
            minWidth: 'unset',
            width: 'fit-content',
            cursor: 'pointer',
            margin: '3px 6px', 
            padding: '3px 1px',
            maxWidth: '15em' }

        else if (props.selectableTag)
            return { cursor: 'pointer' }

        return {}
    }

    return (
        <div style={getStyle()} className={!props.value.toDelete ? classNames.tagsContainer : classNames.tagsContainerDisabled}>
            {props.keyTag === "FormalAspects" ? getFormalAspectsTagContent() :
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                    {getTagContent()}
                </div>}
        </div>
    );
}