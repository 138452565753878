import { styled } from "@fluentui/react";
import { getStyles } from "./favouriteCard.styles";
import { IFavouriteCardPropsStyles, IFavouriteCardStyles, IFavouriteCardProps } from "./favouriteCard.types";
import { FavouriteCardBase } from "./favouriteCard.base";

const FavouriteCard = styled<IFavouriteCardProps, IFavouriteCardPropsStyles, IFavouriteCardStyles>(
    FavouriteCardBase,
    getStyles,
    undefined,
    { scope: 'FavouriteCard' }
);

export default FavouriteCard;