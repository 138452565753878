/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { classNamesFunction, DefaultButton, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IDeleteTopicModalProps, IDeleteTopicModalPropsStyles, IDeleteTopicModalStyles } from "./deleteTopicModal.types";
import EdiModal from "../../../../../../common/components/ediModal/ediModal";

const getClassNames = classNamesFunction<IDeleteTopicModalPropsStyles, IDeleteTopicModalStyles>();
export const DeleteTopicModalBase = (props: IDeleteTopicModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['topicOfInterest', 'common']);

    const modalFooter = () => {
        return <div className={classNames.footerContainer}>
                {props.errorMessage !== '' && props.errorMessage !== undefined &&
                    <span className={classNames.errorMessage}>{props.errorMessage}</span>
                }
                {props.loading &&
                    <Spinner className={classNames.footerButtons} size={SpinnerSize.large} />
                }
                <DefaultButton
                    className={classNames.footerButtons}
                    disabled={props.loading}
                    onClick={() => props.onClose && props.onClose()}
                    text={t('common:close')}
                />
                <PrimaryButton
                    className={classNames.footerButtons}
                    disabled={props.loading}
                    onClick={() => props.onConfirm()}
                    text={t('common:confirm')}
                />
            </div>
    }

    return (
        <EdiModal
            isOpen={props.isOpen}
            onCloseClick={props.onClose}
            title={props.title}
            subtitle={props.subtitle}         
            body={modalFooter()}
            width={410}
            height={200}
        />
    )
}