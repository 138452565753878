import { styled } from "@fluentui/react";
import { getStyles } from "../inputTextTyped/inputTextTyped.styles";
import { InputTextTypedBase } from "./inputTextTyped.base";
import { IInputTextTypedProps, IInputTextTypedPropsStyles, IInputTextTypedStyles } from "./inputTextTyped.types";

const InputTextTyped = styled<IInputTextTypedProps, IInputTextTypedPropsStyles, IInputTextTypedStyles>(
    InputTextTypedBase,
    getStyles,
    undefined,
    { scope: 'InputTextTyped' }
);

export default InputTextTyped;