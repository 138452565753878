/*eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useMemo, useState} from "react";
import { IFolderTreeViewerBasePropsStyles, IFolderTreeViewerBaseStyles, IFolderTreeViewerModalProps } from "./folderTreeViewer.types";
import { classNamesFunction, IconButton, IIconProps, Checkbox, SearchBox, TooltipHost, Label, Spinner, SpinnerSize} from "@fluentui/react";
import FileIconCell from "../fileIconCell/fileIconCell";
import _ from 'lodash';
import { FolderTreeManager } from "./folderManager/folderTreeManager";
import { IManagerFolder } from "./folderManager/IManagerFolder";
import { useTranslation } from "react-i18next";
import { useOnMount } from "../../../utilities/hooks";

const getClassNames = classNamesFunction<IFolderTreeViewerBasePropsStyles, IFolderTreeViewerBaseStyles>();

export const FolderTreeViewerBase = (props: IFolderTreeViewerModalProps) => {
    const [folders, setFolders] = useState<IManagerFolder[]>(props.folders);
    //const treeManager: FolderTreeManager = new FolderTreeManager(props.folders, props.singleFolderSelection, props.showRootFolder);
    const [searchText, setSearchText] = useState<string | undefined>('');
    const [clickedFolderId, setClickedFolderId] = useState<number>(0);
    const [folderSelected, setFolderSelected] = useState<number>(0);
    const [downloadedFolder, setDownloadedFolder] = useState<number[]>([]);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['folderTreeViewer']);

    const treeManager: FolderTreeManager = useMemo(() => {
        return new FolderTreeManager(props.folders, props.singleFolderSelection, props.showRootFolder);
    }, [props.folders, props.singleFolderSelection, props.showRootFolder]);
    
    useOnMount(() => {
        treeManager.collapseAll();
        if(props.expandSelectedFolder){
            if(props.singleFolderSelection)
                treeManager.expandSingleSelected();
            else
                treeManager.expandSelected();
        }
        updateFolderView();
    });

    useEffect(() => {
        updateFolderView();
    }, [treeManager]); //eslint-disable-line react-hooks/exhaustive-deps

    const updateFolderView = () => {
        const temp = _.cloneDeep(treeManager.defaultFolders);
        setFolders(temp);
    }
    const collapse = (folder: IManagerFolder) => {
        treeManager.tryCollapseOrExpand(folder.id);
        updateFolderView()
    }

    const onChange = (folders: IManagerFolder[]) => {
        props.onChange(_.cloneDeep(folders));
    }

    const select = (folder: IManagerFolder) => {
        if(props.isReadOnly) return;
        treeManager.trySelectOrUnselect(folder.id);
        updateFolderView();
        onChange(treeManager.defaultFolders);
    }

    const selectAll = () => {
        if(props.isReadOnly) return;
        treeManager.trySelectAllOrUnselect();
        updateFolderView();
        onChange(treeManager.defaultFolders);
    }

    const startSearch = (value: string | undefined) => {
        if(value === undefined || value === '' || value === null) treeManager.collapseAll();
        else treeManager.expandAll();
        setSearchText(value);
        updateFolderView();
    }

    const collapsedIcon: IIconProps = { iconName: 'ChevronRight'}
    const expandedIcon: IIconProps = { iconName: 'ChevronDown'}

    const minDepthToViewWithRoot = 1;
    const minDepthToView = 2;
    const getClass = (expand: boolean) => {
        return !expand ? classNames.expandChevron : '';
    }
    const renderRows = (folders: IManagerFolder[]) => {
        return folders.map(folder => {
            return (
                !folder.hidden && 
                (<>
                    <div key={folder.id} className={classNames.row} style={{paddingLeft: (props.showRootFolder ? folder.depth-minDepthToViewWithRoot : folder.depth-minDepthToView)*30 + 'px' }}>
                        <Checkbox
                            id={folder.id+'_checkbox'}
                            checked={folder.selected}
                            onChange={(ev, checked) => {select(folder); setFolderSelected(folder.id);} }
                            className={classNames.customCheckbox}
                            disabled={!folder.canSelect}
                        />
                        {treeManager.getChildren(folder).length > 0 && 
                        <IconButton className={classNames.chevron + ' ' + getClass(folder.canExpand) } iconProps={folder.collapsed ? collapsedIcon : expandedIcon} onClick={() => { 
                            collapse(folder);
                            if(props.isFolderBulk && folder.collapsed && !downloadedFolder.includes(folder.id)){
                                setClickedFolderId(folder.id);
                                setDownloadedFolder(downloadedFolder.concat(folder.id));
                                if(props.onGetNextFolders)
                                    props.onGetNextFolders(folder, folderSelected === folder.id ? folder.selected : undefined);
                            }
                        }}></IconButton>
                        }
                        <div className={classNames.folderIcon}>
                            <TooltipHost content={folder.name}>
                                <FileIconCell isFolder={!folder.documentOfId} fileExtension="" />
                            </TooltipHost>
                        </div>
                        
                        <TooltipHost content={folder.name}>
                            <Label className={classNames.column}>
                                {folder.name}
                            </Label>
                        </TooltipHost>

                        <TooltipHost content={folder.fullPathName}>
                            <Label className={classNames.longColumn}>
                                    {folder.fullPathName}
                            </Label>
                        </TooltipHost>
                    </div>
                    {props.loading && folder.documentOfId === clickedFolderId &&
                        <div key={folder.id} className={classNames.row} style={{paddingLeft: (props.showRootFolder ? folder.depth-minDepthToViewWithRoot : folder.depth-minDepthToView)*30 + 'px' }}>
                            <Spinner size={SpinnerSize.medium} />
                        </div>
                    }
                </>
                )
            )
        });
    }

    function filterRows(){
        if(folders === undefined) return []
        return (searchText === undefined || searchText === '' || searchText === null) 
            ? folders.filter(f => props.singleFolderSelection ? f.depth >= 1 : f.depth >= minDepthToView) 
            : folders.filter(f => f.name.toLowerCase().includes(searchText.toLowerCase()) && (props.showRootFolder ? f.depth >= minDepthToViewWithRoot : f.depth >= minDepthToView));
    }

    return (
        <>
            <div className={classNames.row} style={{flexDirection: 'row-reverse', padding: '0px 0px 10px 0px'}}>
                {/* {props.isFolderBulk &&
                    <PrimaryButton
                            text={t('search')}
                            disabled={props.loading}
                            onClick={() => console.log()}
                    />
                } */}
                <SearchBox
                    styles={{ root: { width: 200, marginRight: '15px', right: '0px'} }}
                    placeholder={t('searchFolders')}
                    onClear={ev => {
                        startSearch('');
                    }}
                    onChange={(_, newValue) => {startSearch(newValue);}}
                    iconProps={ undefined }
                    disabled={props.isFolderBulk}
                />
                <div style={{width: '100%', paddingLeft: '5px'}}>
                    <TooltipHost content={props.isFolderBulk ? t('expandAllBulk') : t('expandAll')}>
                        <IconButton
                            iconProps={{ iconName: "CalculatorAddition" }}
                            onClick={() => {treeManager.expandAll(); updateFolderView()}}
                            disabled={props.isFolderBulk}
                        />
                    </TooltipHost>
                    <TooltipHost content={t('collapseAll')}>
                        <IconButton
                            iconProps={{ iconName: "CalculatorSubtract" }}
                            onClick={() => {treeManager.collapseAll(); updateFolderView()}}
                        />
                    </TooltipHost>
                </div>
            </div>
            <div className={classNames.row} style={{paddingLeft:'0px', paddingTop:'8px', paddingBottom: '8px'}}>
                <Checkbox
                    id={'selectAll_checkbox'}
                    checked={treeManager.allSelected()}
                    onChange={selectAll}
                    className={classNames.customCheckbox}
                />
                <div style={{width:'70px'}}></div>
                <label className={classNames.column}>{t('name')}</label>
                <label className={classNames.column}>{t('path')}</label>
            </div>
            <div className={classNames.treeViewerSelector}>
                {renderRows(filterRows())}
            </div>
        </>
    );
        
}