import { styled } from "@fluentui/react";
import { getStyles } from "./renamePortfolioModal.styles";
import { RenamePortfolioModalBase } from "./renamePortfolioModal.base";
import {
    IRenamePortfolioModalProps,
    IRenamePortfolioModalPropsStyles,
    IRenamePortfolioModalStyles
} from "./renamePortfolioModal.types";

const RenamePortfolioModal = styled<
    IRenamePortfolioModalProps,
    IRenamePortfolioModalPropsStyles,
    IRenamePortfolioModalStyles>(
        RenamePortfolioModalBase,
        getStyles,
        undefined,
        { scope: 'RenamePortfolioModal' }
    );

export default RenamePortfolioModal;