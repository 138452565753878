import { IStyleFunction } from "@fluentui/react"
import { IIconButtonSelectedAllPropsStyles, IIconButtonSelectedAllStyles } from "./iconButtonSelectedAll.types";

export const IconButtonSelectedAllGlobalClassNames = {
    root: 'edi-IconButtonSelectedAll-root'
}

export const getStyles: IStyleFunction<IIconButtonSelectedAllPropsStyles, IIconButtonSelectedAllStyles> = (props: IIconButtonSelectedAllPropsStyles): IIconButtonSelectedAllStyles => {
    return {
        root: [
            IconButtonSelectedAllGlobalClassNames.root,
            {
            }
        ]
    };
}