import { IStyleFunction } from "@fluentui/react"
import { IDocVerifyLoadingPagePropsStyles, IDocVerifyLoadingPageStyles } from "./docVerifyLoadingPage.types";

export const DocVerifyLoadingPageGlobalClassNames = {
    root: 'edi-file-docVerifyLoadingPage-root'
}

export const getStyles: IStyleFunction<IDocVerifyLoadingPagePropsStyles, IDocVerifyLoadingPageStyles> = (props: IDocVerifyLoadingPagePropsStyles): IDocVerifyLoadingPageStyles => {
    return {
        root: {
        },
        bodyContainer: {
            display: 'flex',
            alignItems: 'center',
            height: '100%'
        },
        wellDoneImage: {
            flexBasis: '50%',
            flexShrink: 0
        },
        footer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center'
        },
    };
}