import { IStyleFunction } from "@fluentui/react";
import { INavigationItemPropsStyles, INavigationItemStyles } from "./navigationItemModal.types";

export const NavigationItemGlobalClassNames = {
    root: 'edi-folder-navigation-root',
}

export const getStyles: IStyleFunction<INavigationItemPropsStyles, INavigationItemStyles> = (props: INavigationItemPropsStyles): INavigationItemStyles => {
    return {
        root: [
            NavigationItemGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            },
        ],
        wellDoneImage: {
            flexBasis: '50%',
            flexShrink: 0
        },
        secondFooterContainer: {
            display: 'flex',
            alignItems: 'center',
            height: '100%'
        },
        footer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center'
        }
    };    
}