import { styled } from "@fluentui/react";
import { getStyles } from "./feedbackConfirmModal.styles";
import { FeedbackConfirmModalBase} from "./feedbackConfirmModal.base";
import { IFeedbackConfirmModalProps, IFeedbackConfirmModalPropsStyles, IFeedbackConfirmModalStyles } from "./feedbackConfirmModal.types";

const FeedbackConfirmModal = styled<IFeedbackConfirmModalProps, IFeedbackConfirmModalPropsStyles, IFeedbackConfirmModalStyles>(
    FeedbackConfirmModalBase,
    getStyles,
    undefined,
    { scope: 'FeedbackConfirmModal' }
);

export default FeedbackConfirmModal;