import { FontSizes, FontWeights, IStyleFunction } from "@fluentui/react"
import { IRowReportBasePropsStyles, IRowReportBaseStyles } from "./RowReport.types"

export const RowReportGlobalClassNames = {
    root: 'edi-export-report-settings-root',
    title: 'edi-export-report-title',
    iconTitle: 'edi-export-report-icon-title',
    rowContainer: 'edi-export-row-container',
    titleDescriptionContainer: 'edi-title-description-container',
    rowTitle: 'edi-row-title',
    rowDescription: 'edi-row-description',
    statusContainer: 'edi-row-status',
    tooltipStyle: 'edi-row-tooltip',
    invisibleItem: 'edi-row-item-invisible',
    rowSubLevelContainer: 'edi-row-sub-level-container',
    containerErrorMessage: 'edi-row-report-container-error-message',
    statusCompleted: 'edi-row-report-status-completed',
    statusError: 'edi-row-report-status-error',
    statusOnGoing: 'edi-row-report-status-ongoing',
    statusCompletedWithError: 'edi-row-report-status-completed-with-error'
}

export const getStyles: IStyleFunction<IRowReportBasePropsStyles, IRowReportBaseStyles> = (props: IRowReportBasePropsStyles): IRowReportBaseStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    
    return {
        root: [
            RowReportGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px'
            }
        ],
        iconTitle: [
            RowReportGlobalClassNames.iconTitle,
            {
                padding: 7,
                border: '1.5px solid',
                borderRadius: '50%',
                fontSize: 26
            }
        ],
        title: [
            RowReportGlobalClassNames.title,
            {
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingBottom: 10
            }
        ],
        rowContainer: [
            RowReportGlobalClassNames.rowContainer,
            {
                display: 'flex',
                backgroundColor: palette.white,
                marginBottom: '2px',
                padding: '10px 40px 10px 53px',
                flexDirection: 'column'
            }
        ],
        titleDescriptionContainer: [
            RowReportGlobalClassNames.titleDescriptionContainer,
            {
                width: '100%',
                maxWidth: '370px',
                fontSize: '12px'
            }
        ],
        rowTitle: [
            RowReportGlobalClassNames.rowTitle,
            {
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingBottom: 2
            }
        ],
        rowDescription: [
            RowReportGlobalClassNames.rowDescription,
            {
                textOverflow: 'ellipsis',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }
        ],
        tooltipStyle: [
            RowReportGlobalClassNames.tooltipStyle,
            {
                cursor:'pointer',
                justifyContent:'flex-start',
                alignItems: 'center',
                color: 'rgb(98, 100, 167)',
                fontWeight: '600',
                gap: 5,
                marginLeft: '5px',
                textOverflow: 'ellipsis',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }
        ],
        invisibleItem: [
            RowReportGlobalClassNames.invisibleItem,
            {
                visibility: 'hidden'
            }
        ],
        rowSubLevelContainer: [
            RowReportGlobalClassNames.rowSubLevelContainer,
            {
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '15px',
                height: '60px',
                width: '100%'
            }
        ],
        containerErrorMessage: [
            RowReportGlobalClassNames.containerErrorMessage,
            {
                fontSize: '14px',
                minWidth: '300px',
                maxWidth: '450px',
                marginTop: '10px',
                color: palette.red,                
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
            }
        ],
        statusCompleted: [
            RowReportGlobalClassNames.statusCompleted,
            {
                color: 'rgb(16, 124, 16)'
            }
        ],
        statusError: [
            RowReportGlobalClassNames.statusError,
            {
                color: 'rgb(168, 0, 0)'
            }
        ],
        statusOnGoing: [
            RowReportGlobalClassNames.statusOnGoing,
            {
                color: 'rgb(216, 59, 1);'
            }
        ],
        statusCompletedWithError: [
            RowReportGlobalClassNames.statusCompletedWithError,
            {
                color: palette.greenDark
            }
        ],
        //border-bottom: 1px solid rgba(37, 36, 35, 0.05);
    }
}