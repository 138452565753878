/*eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from "react";
import { classNamesFunction, ScrollablePane, Stack, StackItem, TextField } from "@fluentui/react";
import { IFavouritesProps, IFavouritesPropsStyles, IFavouritesStyles } from "./favourites.types";
import { useTranslation } from "react-i18next";
import { useCurrentKnowledge } from "../../hooks/useCurrentKnowledge";
import { FavouriteElement } from "../../models/element";
import { knowledgeLabApi } from "../../services/knowledgeLab.api";
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import FavouriteCard from "./favouriteCard/favouriteCard";
import TeamsImage from "../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../common/components/teamsImage/teamsImage.types";
import { useKnowledgeLabList } from "../../features/knowledgeLabList";
import { KnowledgeHelpers } from "../../utilities/helpers";
import { setRefreshFavourites, useKnowledgeLabContent } from "../../features/knowledgeLabContent";
import { useKnowledgeLabDispatch } from "../../knowledgeLabStore";

const getClassNames = classNamesFunction<IFavouritesPropsStyles, IFavouritesStyles>();
export const FavouritesBase = (props: IFavouritesProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [elements, setElements] = useState<FavouriteElement[]>();
    const knowledgelab = useKnowledgeLabList();
    const knowledgelabContent = useKnowledgeLabContent();

    const currentKnowledge = useCurrentKnowledge();

    const dispatch = useKnowledgeLabDispatch();

    const { t, i18n } = useTranslation(['knowledgeFavourites', 'common']);

    const currentLang = (i18n.language).split("-")[0];
    const currentTranslations = currentKnowledge?.configurations?.translations;
    const favouritesTitle = KnowledgeHelpers.getKnowledgeTranslation("favouritesTitle", currentTranslations, currentLang);

    const searchFavourites = () => {

        setIsLoading(true);
        setIsError(false);

        if (currentKnowledge?.id) {
            knowledgeLabApi.searchFavourites(keyword, currentKnowledge?.id).catch(() => {
                setIsError(true);
                setIsLoading(false);
            }
            )
                .then((elements: FavouriteElement[]) => {
                    setElements(elements);
                    setIsLoading(false)
                });
        }
    }

    useEffect(() => {
        if (knowledgelabContent.refreshFavourites && !knowledgelabContent.isDetailsModalOpen) {
            dispatch(setRefreshFavourites(false));
            searchFavourites();
        }
    }, [knowledgelabContent.refreshFavourites, knowledgelabContent.isDetailsModalOpen]);//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        searchFavourites();
    }, [currentKnowledge?.id]);//eslint-disable-line react-hooks/exhaustive-deps

    const printResults = () => {
        if (elements && elements.length > 0)
            return elements.map(el =>
                <FavouriteCard key={el.id} element={el} onElementRemoved={searchFavourites} />)
        else if (!keyword) {
            return <TeamsImage
                imageName={ImageName.EmptyFavourites}
                scale={0.2}
                fullContainer
                style={{ marginBottom: 30 }}
                caption={t("common:emptyFavourites")}
            />;
        } else {
            return <TeamsImage
                imageName={isError ? ImageName.Error3 : ImageName.NoResults}
                scale={0.2}
                fullContainer
                style={{ marginBottom: 30 }}
                caption={t(isError ? "common:genericErrorApi" : "common:emptySearch")}
            />;
        }
    }

    return (
        <>
            {knowledgelab.isLoading ?
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                    <TeamsSpinner />
                </div>
                :
                <div className={classNames.root}>
                    <Stack>
                        <StackItem>
                            <h3>{favouritesTitle ?? t("defaultTitle")}</h3>
                        </StackItem>
                        <StackItem>
                            <div className={classNames.searchContainer}>
                                <TextField
                                    disabled={isLoading || !currentKnowledge?.id}
                                    key={currentKnowledge?.id}
                                    placeholder={t("knowledgeFavourites:searchBox")}
                                    value={keyword}
                                    styles={classNames.subComponentStyles.textFieldStyle}
                                    onChange={(_, value) => setKeyword(value || '')}
                                    iconProps={{
                                        iconName: 'search',
                                        styles: {
                                            root: {
                                                color: palette.themePrimary,
                                                fontSize: '25px',
                                                paddingBottom: '4px',
                                                cursor: 'pointer',
                                                pointerEvents: 'auto'
                                            }
                                        },
                                        onClick: () => searchFavourites()
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter')
                                            searchFavourites();
                                    }
                                    }
                                />
                            </div>
                        </StackItem>
                        <StackItem>
                            <ScrollablePane className={classNames.scrollablePane}>
                                <div>
                                    {isLoading ? <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                                        <TeamsSpinner />
                                    </div> : <div className={classNames.cardsContainer}>{printResults()}</div>}
                                </div>
                            </ScrollablePane>

                        </StackItem>

                    </Stack>
                </div>
            }
        </>
    );
}