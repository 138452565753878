import { DefaultPalette, IStyleFunction } from "@fluentui/react"
import { IFilesApprovalDocumentModalPropsStyles, IFilesApprovalDocumentModalStyles } from "./filesApprovalDocumentModal.types";

export const FilesApprovalDocumentModalGlobalClassNames = {
    root: 'edi-files-approval-document-modal-root',
    tooltipContent: 'edi-files-approval-document-modal-tooltipContent',
    mainContainer: 'edi-files-approval-document-modal-mainContainer',
    rejectContainer: 'edi-files-approval-document-modal-rejectContainer',
    mainFooterContainer: 'edi-files-approval-document-modal-mainFooterContainer',
    footerContainer: 'edi-files-approval-document-modal-footerContainer',
    centerContainer: 'edi-files-approval-document-modal-centerContainer',
    errorContainer: 'edi-files-approval-document-modal-errorContainer'
}

export const getStyles: IStyleFunction<IFilesApprovalDocumentModalPropsStyles, IFilesApprovalDocumentModalStyles> = (props: IFilesApprovalDocumentModalPropsStyles): IFilesApprovalDocumentModalStyles => {
    return {
        root: [
            FilesApprovalDocumentModalGlobalClassNames.root,
            {
            }
        ],
        tooltipContent: [
            FilesApprovalDocumentModalGlobalClassNames.tooltipContent,
            {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                cursor: 'pointer',
            }
        ],
        mainContainer: [
            FilesApprovalDocumentModalGlobalClassNames.mainContainer,
            {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                height: '100%'
            }
        ],
        rejectContainer: [
            FilesApprovalDocumentModalGlobalClassNames.rejectContainer,
            {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                width: '100%',
                height: '100%'
            }
        ],
        mainFooterContainer: [
            FilesApprovalDocumentModalGlobalClassNames.mainFooterContainer,
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: 10
            }
        ],
        errorContainer: [
            FilesApprovalDocumentModalGlobalClassNames.errorContainer,
            {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                marginRight: 10,
                color: DefaultPalette.redDark
            }
        ],
        footerContainer: [
            FilesApprovalDocumentModalGlobalClassNames.footerContainer,
            {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%'
            }
        ],
        centerContainer: [
            FilesApprovalDocumentModalGlobalClassNames.centerContainer,
            {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%'
            }
        ]
    };
}