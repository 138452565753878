import { KnowledgeLabTranslation } from "../models/knowledgeLabSetting";

class KnowledgeHelpersClass {

    getKnowledgeTranslation = (value: string, translations: KnowledgeLabTranslation[] | undefined, lang: string): string | undefined => {
        const currentTranslations = translations?.find(t => t.lang === lang) ?? undefined;
        return currentTranslations?.values[value];
    }
}

export const KnowledgeHelpers = new KnowledgeHelpersClass();