/*eslint-disable sonarjs/no-duplicate-string */
/*eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback } from 'react'
import MultiStepModal from "../../../../../../common/components/multiStepModal/multiStepModal";
import { ICreateProfileFolderModalProps, ICreateProfileModalBasePropsStyles, ICreateProfileModalBaseStyles, Folder } from './createProfileModal.types';
import { Dropdown, IDropdownOption, TextField, Label, DefaultButton, PrimaryButton, Spinner, SpinnerSize, Icon } from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { classNamesFunction } from "@fluentui/react";
import FolderTreeViewer from "../../../../../../common/components/folderTreeViewer/folderTreeViewer";
import { useOnMount } from '../../../../../../utilities/hooks';
import { archivesApi } from "../../../../../services/archives/archives.api";
import { IFolder } from '../../../../../models/folder';
import { CreateProfileModalGlobalClassNames } from './createProfileModal.style';
import { IManagerFolder } from '../../../../../../common/components/folderTreeViewer/folderManager/IManagerFolder';
import { useCurrentArchive } from "../../../../../hooks/useCurrentArchive";
import TeamsImage from '../../../../../../common/components/teamsImage/teamsImage';
import { ImageName } from '../../../../../../common/components/teamsImage/teamsImage.types';
import SelectProfileMember from '../../../../../../common/components/selectProfileMember/selectProfileMember'
import { Helpers } from '../../../../../../utilities/helpers';
import { ArchiveRoleId } from '../../../../../models/constants';
import { ErrorDetails } from '../../../../../../modules/apiClient/apiClient';
import _ from 'lodash';

const getClassNames = classNamesFunction<ICreateProfileModalBasePropsStyles, ICreateProfileModalBaseStyles>();

export const CreateProfileModalBase = (props: ICreateProfileFolderModalProps) => {
    enum CreateProfileStep {
        setupParameters = 0,
        selectfolders = 1,
        selectUsers = 2,
        profileCreation = 3,
        profileCreated = 4
    }

    enum CreationStatus {
        inProgress = 0,
        success = 1,
        failed = 2
    }

    const options: IDropdownOption<number>[] = [
        { key: ArchiveRoleId.Architect, text: 'Architect' },
        { key: ArchiveRoleId.Contributor, text: 'Contributor' },
        { key: ArchiveRoleId.Professional, text: 'Professional' },
        { key: ArchiveRoleId.Uploader, text: 'Uploader' },
        { key: ArchiveRoleId.Reader, text: 'Reader' }
    ]

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const currentArchive = useCurrentArchive();
    const [currentStep, setCurrentStep] = useState<CreateProfileStep>(CreateProfileStep.setupParameters);
    const [currentName, setCurrentName] = useState<string | undefined>(undefined);
    const [currentDescription, setCurrentDescription] = useState<string | undefined>(undefined);
    const [currentRole, setCurrentRole] = useState<number | undefined>(undefined);
    const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
    const [folders, setFolders] = useState<Folder[]>([]);
    const [creationStatus, setCreationStatus] = useState<CreationStatus>(CreationStatus.inProgress);
    const [defaultSelectedFolders, setDefaultSelectedFolders] = useState<IManagerFolder[]>([]);
    const [validationIcon, setValidationIcon] = useState('Error');
    const [errorMessageFooter, setErrorMessageFooter] = useState('');
    const [loadingFolders, serLoadingFolders] = useState<boolean>(false);
    const [foldersWithState, setFoldersWithState] = useState<FolderWithState[]>([]);

    type FolderWithState = {
        folderId: number;
        state: boolean;
    }

    const { t } = useTranslation(['profileManagement', 'common', 'profileView']);

    useOnMount(() => {
        getFolders();
    });

    const convertToManagerFolders = (folders: IFolder[]) => {
        const fs = folders.map(f => {
            const tf = new Folder();
            tf.id = f.id;
            tf.name = f.name;
            tf.parentId = f.parentId;
            tf.createdBy = f.createdBy;
            tf.createdOn = f.createdOn;
            tf.depth = f.depth;
            tf.fullPathName = f.fullPathName;
            return tf;
        });

        const result: Folder[] = [];
        const ids = fs.map(object => {
            return object.id
        });
        let maxId = Math.max(...ids);
        fs.forEach(f => {
            result.push(f);
            const documentOf = new Folder();
            documentOf.name = 'Documents in ' + f.name;
            maxId = maxId + 1;
            documentOf.id = maxId;
            documentOf.depth = f.depth + 1;
            documentOf.documentOfId = f.id;
            documentOf.parentId = f.id;
            documentOf.canExpand = false;
            documentOf.canSelect = true;
            documentOf.fullPathName = f.fullPathName === ""
                ? "Documents in " + f.name
                : f.fullPathName + "\\Documents in " + f.name
            result.push(documentOf);
        });
        return result;
    }

    const convertToManagerFoldersBulk = (folders: IManagerFolder[]) => {
        const fs = folders.map(f => {
            const tf = new Folder();
            tf.id = f.id;
            tf.name = f.name;
            tf.parentId = f.parentId;
            tf.createdBy = f.createdBy;
            tf.createdOn = f.createdOn;
            tf.depth = f.depth;
            tf.fullPathName = f.fullPathName;
            tf.selected = f.parentId === null ? f.selected : f.selectedAllChildren;
            tf.collapsed = f.collapsed;
            tf.hidden = f.hidden;
            tf.selectedAllChildren = f.selectedAllChildren;
            return tf;
        });

        const result: Folder[] = [];
        const ids = fs.map(object => {
            return object.id
        });
        let maxId = Math.max(...ids);
        fs.forEach(f => {
            result.push(f);
            const documentOf = new Folder();
            documentOf.name = 'Documents in ' + f.name;
            maxId = maxId + 1;
            documentOf.id = maxId;
            documentOf.depth = f.depth + 1;
            documentOf.documentOfId = f.id;
            documentOf.parentId = f.id;
            documentOf.canExpand = false;
            documentOf.canSelect = true;
            documentOf.selected = f.selected;
            documentOf.hidden = f.collapsed ? true : false;
            documentOf.fullPathName = f.fullPathName === ""
                ? "Documents in " + f.name
                : f.fullPathName + "\\Documents in " + f.name

            result.push(documentOf);
        });
        return result;
    }

    const getFolders = async () => {
        try {
            if (!props.isProfileFoldersBulk) {
                archivesApi.getAllFolders(props.archiveId).then((result) => {
                    setFolders(convertToManagerFolders(result));
                });
            }
            else {
                const childFolders = await archivesApi.getNextFolderLevel(0, props.profileIdOwner, false);
                const foldersBulk = convertToManagerFoldersBulk(childFolders);
                setFolders(foldersBulk);

                const foldersBulkwithoutDocument = foldersBulk.filter(f => f.documentOfId === undefined);

                foldersBulkwithoutDocument.forEach(folder => {
                    if (!foldersWithState.find(f => f.folderId === folder.id)) {
                        const folderWithState: FolderWithState = { folderId: folder.id, state: folder.selected }
                        setFoldersWithState(prevState => [...prevState, folderWithState]);
                    }
                });
            }
        }
        catch (er) {
            const error: ErrorDetails = er as ErrorDetails;
            switch (error.code) {
                case 400:
                    if (error.subCode === 1)
                        setErrorMessageFooter(t('profileView:editFolderModal.errorProfileNotFound'));
                    if (error.subCode === 2)
                        setErrorMessageFooter(t('profileView:editFolderModal.errorParentFolderNotFound'));
                    break;
                case 422:
                    setErrorMessageFooter(t('common:genericErrorApi'));
                    break;
                default:
                    setErrorMessageFooter(t('common:genericErrorApi'));
                    break;
            }
        }
    }

    const canConfirm = () => {
        return (currentStep === CreateProfileStep.setupParameters && !(currentName === undefined || currentRole === undefined || folders.length === 0))
            || (currentStep === CreateProfileStep.selectfolders && defaultSelectedFolders.length > 0)
            || (currentStep === CreateProfileStep.selectUsers /*&& selectedMembers.length > 0*/);
    }

    const createProfile = () => {
        setCreationStatus(CreationStatus.inProgress);
        setCurrentStep(CreateProfileStep.profileCreation);

        const selectedFoldTempWithoutDoc = defaultSelectedFolders.filter(f => (f.selected || defaultSelectedFolders.some(doc => doc.documentOfId === f.id && doc.selected)) && (f.documentOfId === undefined || f.documentOfId === null));

        const treeFolderList = selectedFoldTempWithoutDoc.map(f => ({
            folderId: f.id,
            retrieveFolderChildren: (props.isProfileFoldersBulk !== undefined && props.isProfileFoldersBulk) ? f.selected : false,
        }));

        const profile = {
            name: currentName,
            description: currentDescription,
            archiveRoleId: currentRole,
            folders: treeFolderList,
            users: selectedMembers,
            foldersChildrenToRetrieve: props.isProfileFoldersBulk !== undefined && props.isProfileFoldersBulk ? true : false
        }
        archivesApi.createProfile(props.archiveId, profile).then(() => {
            setCreationStatus(CreationStatus.success);
        })
            .catch(() => setCreationStatus(CreationStatus.failed));
    }

    const validateProfileName = useCallback(async (value: string) => {
        if (!value)
            return `${t('common:fieldRequired')}`;

        setValidationIcon('ProgressRingDots');

        try {
            const [isValid] = await Promise.all([archivesApi.isProfileNameAvailable(props.archiveId, value), Helpers.delay(500)]);
            return isValid ? "" : t('common:nameNotAvailable');
        }
        catch {
            return t('common:genericErrorCheckName');
        }
    }, [t]); //eslint-disable-line react-hooks/exhaustive-deps

    const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
        if (error === '')
            setCurrentName(value);
        else
            setCurrentName(undefined);
        setValidationIcon(error ? 'Error' : 'Completed');
    }, [currentName]); //eslint-disable-line react-hooks/exhaustive-deps

    const modalBodySetupParameters = () => {
        return (<>
            <Label>{t('profileView.newProfile.nameLabel')}</Label>
            <TextField
                placeholder={`${t('profileView.newProfile.insertProfileName')}`}
                onChange={(_, newValue) =>
                    //checkName(newValue))
                    setCurrentName(newValue)
                }
                defaultValue={currentName}
                onGetErrorMessage={validateProfileName}
                onNotifyValidationResult={onNameValidationResult}
                onRenderSuffix={() => <Icon iconName={validationIcon} />}
            />
            <Label>{t('profileView.newProfile.descriptionLabel')}</Label>
            <TextField
                multiline={true}
                onChange={((_, newValue) => setCurrentDescription(newValue))}
                defaultValue={currentDescription}></TextField>
            <Label>{t('profileView.newProfile.assignRole')}</Label>
            <Dropdown
                options={options || []}
                placeholder={`${t('profileView.newProfile.selectProfileRole')}`}
                errorMessage={!currentRole ? t('common:fieldRequired') : ""}
                onChange={((_, newValue) => {
                    setCurrentRole(newValue?.key as number)
                })}
                defaultSelectedKey={currentRole}
            />
        </>)
    }

    const onChangeSelectedFolders = (changedFolders: IManagerFolder[]) => {
        const temp = _.cloneDeep(changedFolders)
        temp
            .filter(f => f.documentOfId !== undefined && f.documentOfId !== null)
            .forEach(f => {
                const container = temp.find(i => i.id === f.documentOfId && f.selected);
                if (container !== undefined)
                    container.selected = true;
            });
        const selected = temp.filter(f => (f.documentOfId === undefined || f.documentOfId === null) && f.selected);

        if (props.isProfileFoldersBulk !== undefined && props.isProfileFoldersBulk) {
            setDefaultSelectedFolders(changedFolders);
            const folderWithState: FolderWithState[] = [];
            changedFolders.forEach(f => {
                const temp: FolderWithState = { folderId: f.id, state: f.selected }
                folderWithState.push(temp);
            });
            setFoldersWithState(folderWithState);
        }
        else
            setDefaultSelectedFolders(selected);
    }

    const getNextFolders = async (folder: IManagerFolder, folderSelected: boolean | undefined) => {
        if (!props.profileIdOwner)
            return;
        const temp = folders.filter(f => f.parentId === folder.id && f.documentOfId === undefined);

        if (temp.length > 0)
            return;

        serLoadingFolders(true);
        let status: boolean = folderSelected ?? false;

        if (folderSelected === undefined) {
            const parent = foldersWithState.find(f => f.folderId === folder.parentId);
            if (parent) {
                const test = folders.find(f => f.id === parent?.folderId)?.parentId
                if (test === null)
                    status = folder.selected;
                else
                    status = parent.state;
            }
        }

        if (!foldersWithState.find(f => f.folderId === folder.id)) {
            const folderWithState: FolderWithState = { folderId: folder.id, state: status }
            setFoldersWithState(prevState => [...prevState, folderWithState]);
        }

        try {
            const childFolders = await archivesApi.getNextFolderLevel(folder.id, props.profileIdOwner, status);
            if (childFolders.length > 0) {
                setFolders([]);
                const folderList = folders.filter(f => f.documentOfId === undefined || f.documentOfId === null);
                const updatedFolderList = folderList.map((folder) => ({
                    ...folder,
                    selectedAllChildren: folder.selected,
                }));

                const index = updatedFolderList.findIndex(f => f.id === folder.id);
                if (index !== -1)
                    updatedFolderList.splice(index + 1, 0, ...childFolders);

                const foldersBulk = convertToManagerFoldersBulk(updatedFolderList);
                setFolders(foldersBulk);
            }
        }
        catch (er) {
            const error: ErrorDetails = er as ErrorDetails;
            switch (error.code) {
                case 400:
                    if (error.subCode === 1)
                        setErrorMessageFooter(t('profileView:editFolderModal.errorProfileNotFound'));
                    if (error.subCode === 2)
                        setErrorMessageFooter(t('profileView:editFolderModal.errorParentFolderNotFound'));
                    break;
                case 422:
                    setErrorMessageFooter(t('common:genericErrorApi'));
                    break;
                default:
                    setErrorMessageFooter(t('common:genericErrorApi'));
                    break;
            }
        }
        finally {
            serLoadingFolders(false);
        }
    }

    const modalBodySelectFolders = () => {
        return (
            <div style={{ height: '100%' }}>
                <div style={{ height: '90px' }}>
                    <Label>{t('profileView.newProfile.folderVisibility')}</Label>
                    <p>{t('profileView.newProfile.folderVisibilityDescription')}</p>
                </div>
                <div style={{ height: 'calc(100% - 90px' }}>
                    <FolderTreeViewer
                        key={'folder-tree-viewer'}
                        folders={folders}
                        onChange={onChangeSelectedFolders}
                        isFolderBulk={props.isProfileFoldersBulk !== undefined && props.isProfileFoldersBulk ? true : false}
                        loading={props.isProfileFoldersBulk !== undefined && props.isProfileFoldersBulk ? loadingFolders : false}
                        onGetNextFolders={(folder, selected) => getNextFolders(folder, selected)}
                    />
                </div>
            </div>
        )
    }

    const modalBodySelectUsers = () => {
        //inserire qui selettore utenti
        return (<>
            <Label>{t('profileView.newProfile.userVisibility')}</Label>
            <p>{t('profileView.newProfile.userVisibilityDescription')}</p>
            <SelectProfileMember
                archiveId={currentArchive?.id ?? 0}
                selectedUser={(users) => setSelectedMembers(users?.map(u => u.id) ?? [])}
                readonly={false}
                visualizeMode={false}
            />
        </>)
    }

    const modalBodyCreation = () => {
        if (creationStatus === CreationStatus.inProgress)
            return <Spinner style={{ height: '-webkit-fill-available' }} size={SpinnerSize.large} />
        else if (creationStatus === CreationStatus.success)
            return (
                <div className={classNames.centerContainer}>
                    <TeamsImage imageName={ImageName.WellDone} style={{ height: '400px' }} />
                    <Label>{t("profileView.successMessage")}</Label>
                    <PrimaryButton style={{ width: '110px', marginTop: '30px' }} onClick={props.onComplete}>{t("common:gotIt")}</PrimaryButton>
                </div>
            )
        else
            return (
                <div className={classNames.centerContainer}>
                    <TeamsImage imageName={ImageName.Error3} style={{ height: '400px' }} />
                    <Label>{t("common:genericErrorApi")}</Label>
                    <PrimaryButton style={{ width: '110px', marginTop: '30px' }} onClick={props.onClose}>{t("common:close")}</PrimaryButton>
                </div>
            )
    }

    const confirm = (stateId: number) => {
        if ((currentArchive?.managedByIag && currentStep === CreateProfileStep.selectfolders) || currentStep === CreateProfileStep.selectUsers) {
            createProfile();
        }
        else {
            setCurrentStep(stateId + 1);
        }
    }

    const modalFooter = () => {
        return (
            <div>
                {(currentStep === CreateProfileStep.setupParameters) &&
                    (
                        <>
                            <DefaultButton className={classNames.footerButtons} onClick={props.onClose}>{t('profileView.newProfile.cancel')}</DefaultButton>
                            <PrimaryButton disabled={!canConfirm()} className={classNames.footerButtons} onClick={() => { confirm(currentStep) }}>{t('profileView.newProfile.next')}</PrimaryButton>
                        </>
                    )
                }
                {(currentStep === CreateProfileStep.selectfolders) &&
                    (
                        <div className={classNames.footerContainer}>
                            <div style={{ fontSize: '14px', color: 'rgb(164, 38, 44)' }}>
                                {errorMessageFooter}
                            </div>
                            <div>
                                <DefaultButton className={classNames.footerButtons} onClick={() => { setCurrentStep(CreateProfileStep.setupParameters); }}>{t('profileView.newProfile.previous')}</DefaultButton>
                                <PrimaryButton disabled={!canConfirm()} className={classNames.footerButtons} onClick={() => { confirm(currentStep) }}>{t('profileView.newProfile.next')}</PrimaryButton>
                            </div>
                        </div>
                    )
                }
                {(currentStep === CreateProfileStep.selectUsers) &&
                    (
                        <div className={classNames.footerContainer}>
                            <div style={{ fontSize: '14px', color: 'rgb(164, 38, 44)' }}>
                                {errorMessageFooter}
                            </div>
                            <div>
                                <DefaultButton className={classNames.footerButtons} onClick={() => { setCurrentStep(CreateProfileStep.selectfolders); }}>{t('profileView.newProfile.previous')}</DefaultButton>
                                <PrimaryButton disabled={!canConfirm()} className={classNames.footerButtons} onClick={() => { createProfile() }}>{t('profileView.newProfile.save')}</PrimaryButton>
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }

    const titleModal = "profileView.newProfile.createNewProfile";
    const steps = [
        {
            title: `${t(titleModal)}`,
            body: modalBodySetupParameters(),
            footer: modalFooter()
        },
        {
            title: `${t(titleModal)}`,
            body: modalBodySelectFolders(),
            footer: modalFooter()
        },
        {
            title: `${t(titleModal)}`,
            body: modalBodySelectUsers(),
            footer: modalFooter()
        },
        {
            body: modalBodyCreation()
        }
    ]

    return (
        <div>
            <MultiStepModal
                styles={classNames.subComponentStyles.ediModal}
                className={CreateProfileModalGlobalClassNames.root}
                isOpen={props.isOpen}
                width={800}
                height={700}
                showCloseIcon={true}
                onCloseClick={props.onClose}
                activeStep={currentStep}
                steps={steps}
                animateInitialStep
            />
        </div>
    )
}