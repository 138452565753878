import { FontWeights, IStyleFunction } from "@fluentui/react";
import { IEdiModalPropsStyles, IEdiModalStyles } from "./ediModal.types";

export const EdiModalGlobalClassNames = {
    container: 'edi-modal-container',
    content: 'edi-modal-content',
    header: 'edi-modal-header',
    title: 'edi-modal-title',
    closeIcon: 'edi-modal-close-icon',
    body: 'edi-modal-body',
    bodyContent: 'edi-modal-body-content',
    footer: 'edi-modal-footer',
    subTitle: 'edi-modal-subtitle'
}

export const getStyles: IStyleFunction<IEdiModalPropsStyles, IEdiModalStyles> = (props: IEdiModalPropsStyles): IEdiModalStyles => {
    const { palette, fonts } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        container: [
            EdiModalGlobalClassNames.container,
            {
                padding: '27px 32px 32px 32px',
                width: props.width,
                height: props.height,
                right: 34
            }
        ],
        content: [
            EdiModalGlobalClassNames.content,
            {
                '*::-webkit-scrollbar': {
                    width: 16,
                    height: 16
                },
                '*::-webkit-scrollbar-thumb': {
                    border: '5px solid transparent',
                    backgroundClip: 'padding-box',
                    backgroundColor: 'transparent',
                    borderRadius: 25
                },
                '*:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: palette.neutralSecondary// 'rgba(66, 66, 66, 0.3)'
                },
                '*::-webkit-scrollbar-thumb:hover': {
                    border: '2px solid transparent'
                },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                '> :not(:first-child)': {
                    marginTop: 27
                }

            }
        ],
        header: [
            EdiModalGlobalClassNames.header,
            fonts.large,
            {
                display: 'flex',
                justifyContent: 'space-between',
                // alignItems: 'flex-start',
                alignItems: 'center',
                fontWeight: FontWeights.bold,
                minHeight: 36
            }
        ],
        subTitle: [
            EdiModalGlobalClassNames.subTitle,
            fonts.medium,
            {
               fontWeight: FontWeights.semibold,
               color: palette.neutralPrimary
            }
        ],
        title: [
            EdiModalGlobalClassNames.title,
            {
                wordBreak: 'break-word'
            }
        ],
        closeIcon: [
            EdiModalGlobalClassNames.closeIcon,
            {
            }
        ],
        body: [
            EdiModalGlobalClassNames.body,
            {
                flexGrow: 1,
                overflowY: 'auto'
            }
        ],
        bodyContent: [
            EdiModalGlobalClassNames.bodyContent,
            {
                height: '100%'
            }
        ],
        footer: [
            EdiModalGlobalClassNames.footer,
            {
                paddingTop: '10px',
                textAlign: 'right'
            }
        ],
        subComponentStyles: {
            iconButton: {
                root: {
                    color: palette.neutralDark,
                    marginLeft: 'auto',
                    marginTop: '4px',
                    marginRight: '2px',
                },
                rootHovered: {
                    color: palette.neutralPrimary,
                }
            }
        }
    };
}