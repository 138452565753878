/*eslint-disable @typescript-eslint/no-explicit-any*/

export enum callsList {
    createFolder = 0,
    renameFolder = 1,
    deleteItem = 2,
    createStandard = 3,
    deleteStandard = 4,
    deleteTemplate = 5,
    saveTemplate = 6,
    toogleChange = 7,
    forbiddenLink = 8,
    moveItems = 9,
    copyItems = 10,
    updatePermissionsFolder = 11,
    duplicateProfile = 12,
    moveToRecycleBin = 13,
    restoreFromRecycleBin = 14
}

export type ErrorsType = {
    code: number;
    message: string;
    detail?: string;
}

export type Call = {
    type: number;
    nameOperation: string;
    errors?: ErrorsType[];
}