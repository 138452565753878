import { IStyleFunction } from "@fluentui/react"
import { IUsersPropsStyles, IUsersStyles } from "./users.types"

export const UsersGlobalClassNames = {
    root: 'edi-users-root'
}

export const getStyles: IStyleFunction<IUsersPropsStyles, IUsersStyles> = (props: IUsersPropsStyles): IUsersStyles => {

    return {
        root: [
            UsersGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}