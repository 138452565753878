/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable @typescript-eslint/no-non-null-assertion*/
import React, { useEffect, useState } from "react";
import { SelectionEntry, loadRecycleBin, selectItems, showDeleteModal, useArchiveContent } from "../../../features/archiveContent";
import { useDocLabDispatch } from "../../../docLabStore";
import { useTranslation } from "react-i18next";
import { IDeleteModalProps } from "../../common/deleteModal/deleteModal.types";
import { archivesApi } from "../../../services/archives/archives.api";
import { Call, callsList } from "../../../models/callsApi";
import { insertCall, setCall } from "../../../features/callNotification";
import { nanoid } from "@reduxjs/toolkit";
import { DeleteModalLayoutBase } from "../../common/deleteModal/deleteModalLayout";
import DocVerifyLoadingPage from "../../../../common/components/docVerifyLoadingPage/docVerifyLoadingPage";
import DocVerifyModalBody from "../../../../common/components/docVerifyModalBody/docVerifyModalBody";
import DocVerifyModalFooter from "../../../../common/components/docVerifyModalFooter/docVerifyModalFooter";
import { DocVerifySource, FileNameWithMetadata, ResultDocumentsMetadataUnivocityWithError } from "../../../models/archiveUnivocityRule";
import MultiStepModal from "../../../../common/components/multiStepModal/multiStepModal";

enum VerifyUnivocitySteps {
    docVerifyLoading = 0,
    docVerify = 1
}

export const RestoreFromRecycleBinBase = (props: IDeleteModalProps) => {
    const { deleteModalShown, selectedItems, archivesHasUnivocityRole } = useArchiveContent();
    const dispatch = useDocLabDispatch();
    const { t } = useTranslation(['restoreFromRecycleBinModal', 'common']);
    const [documentUnivocityError, setDocumentUnivocityError] = useState<ResultDocumentsMetadataUnivocityWithError>();
    const [currentStep, setCurrentStep] = useState(VerifyUnivocitySteps.docVerifyLoading);
    const [loadingDoc, setLoadingDoc] = useState<boolean>(true);

    useEffect(() => {
        if (documentUnivocityError !== undefined)
            setLoadingDoc(false);
    }, [documentUnivocityError]);

    const restoreFromRecycleBinItem = async () => {
        const restoreFromRecycleBin: Call = {
            type: callsList.restoreFromRecycleBin,
            nameOperation: selectedItems.length > 1 ? t('operationMultiItems') : t('operationFile'),
            errors: [
                { code: 403, message: t('notPermissions') },
                { code: 500, message: t('common:deleteGenericErrorApi').concat(selectedItems.length > 1 ? t('ofItems') : selectedItems[0].isFolder ? t('ofFolder') : t('ofFile')) }
            ]
        }

        const id = nanoid();
        const payload = { requestId: id, notification: restoreFromRecycleBin }
        dispatch(insertCall(payload));
        props.onClose && props.onClose();

        try {
            await archivesApi.recycleBinRestore(selectedItems);

            const successPayload = { requestId: id, success: true, message: selectedItems.length > 1 ? t('successMessageItems').concat(`${selectedItems.length}`) : t('successMessageFile') }
            dispatch(setCall(successPayload));
        }
        catch (error: any) { //eslint-disable-line @typescript-eslint/no-explicit-any
            let errorMessage = '';
            if (error.code === 403) {
                errorMessage = t('notPermissions');
            }
            else {
                switch (error.subCode) {
                    case (410): errorMessage = t('fileAlreadyExists'); break;
                    case (411): errorMessage = t('filesAlreadyExists'); break;
                    default: errorMessage = t('common:genericErrorApi'); break;
                }
            }
            const failurePayload = { requestId: id, success: false, message: errorMessage }
            dispatch(setCall(failurePayload));
        }
        finally {
            dispatch(loadRecycleBin({ resetPage: true }));
            dispatch(selectItems([]));
        }
    }

    const saveMetadata = async () => {
        const restoreFromRecycleBin: Call = {
            type: callsList.restoreFromRecycleBin,
            nameOperation: selectedItems?.length > 1 ? t('operationMultiItems') : t('operationFile'),
            errors: [
                { code: 403, message: t('notPermissions') },
                { code: 500, message: t('common:deleteGenericErrorApi').concat(selectedItems?.length > 1 ? t('ofItems') : selectedItems[0]?.isFolder ? t('ofFolder') : t('ofFile')) }
            ]
        }

        const id = nanoid();
        const payload = { requestId: id, notification: restoreFromRecycleBin }
        dispatch(insertCall(payload));
        props.onClose && props.onClose();

        try {
            await archivesApi.recycleBinRestore(selectedItems);
            const successPayload = { requestId: id, success: true, message: selectedItems.length > 1 ? t('successMessageItems').concat(`${selectedItems.length}`) : t('successMessageFile') }
            dispatch(setCall(successPayload));
            setLoadingDoc(true);
            dispatch(showDeleteModal({ show: false }));
            dispatch(loadRecycleBin({ resetPage: true }));
        }
        catch (error: any) { //eslint-disable-line @typescript-eslint/no-explicit-any
            let metadataErrorTemp = '';
            if (error.subCode === 410)
                metadataErrorTemp = (t('errorMultipleFiles'));
            else
                metadataErrorTemp = (t('common:genericErrorApi'));

            const failurePayload = { requestId: id, success: false, message: metadataErrorTemp }
            dispatch(setCall(failurePayload));
            backToRecycleBin();
        }
        finally {
            dispatch(loadRecycleBin({ resetPage: true }));
            dispatch(selectItems([]));
            setLoadingDoc(false);
        }
    }

    const onNextClick = () => {
        if (documentUnivocityError?.errorOnFileMetadataList.length !== 0 || documentUnivocityError?.notUnivocityDocumentList.length !== 0)
            setCurrentStep(VerifyUnivocitySteps.docVerify);
        else
            saveMetadata();
    }

    const docVerifyLoadingPage = () => {
        return (
            <DocVerifyLoadingPage
                folderId={selectedItems.map(s => s.folderId!)}
                files={selectedItems.map((a: SelectionEntry): FileNameWithMetadata => ({
                    fileId: a.id,
                    fileName: a.name!,
                    metadata: a.metadata === undefined || a.metadata === null ? "{}" : a.metadata,
                }))}
                onVerifyResult={(item) => setDocumentUnivocityError(item)}
                onNext={onNextClick}
                onClose={() => backToRecycleBin()}
                loadingData={loadingDoc}
            />
        );
    }

    const backToRecycleBin = () => {
        dispatch(showDeleteModal({ show: false }));
        dispatch(loadRecycleBin({ resetPage: true }));
        setDocumentUnivocityError(undefined);
        setLoadingDoc(true);
        setCurrentStep(VerifyUnivocitySteps.docVerifyLoading);
    }

    const backToRecycleBinSave = () => {
        dispatch(showDeleteModal({ show: false }));
        dispatch(loadRecycleBin({ resetPage: true }));
        saveMetadata();
        setDocumentUnivocityError(undefined);
        setLoadingDoc(true);
        setCurrentStep(VerifyUnivocitySteps.docVerifyLoading);
    }

    const docVerifyFooter = () => {
        if (documentUnivocityError?.isUserBlocked !== undefined) {
            return (
                <DocVerifyModalFooter
                    isUserBlocked={documentUnivocityError.isUserBlocked}
                    documentUnivocityError={documentUnivocityError}
                    onClose={() => backToRecycleBinSave()}
                    onBack={() => backToRecycleBin()}
                    onNext={async () => saveMetadata()}
                    loading={loadingDoc}
                />
            );
        }
    }

    const docVerifyBody = () => {
        const emptyError: ResultDocumentsMetadataUnivocityWithError = {
            errorOnFileMetadataList: [],
            isUserBlocked: true,
            notUnivocityDocumentList: []
        }
        return (
            <DocVerifyModalBody
                item={documentUnivocityError ? documentUnivocityError : emptyError}
                requestSource={DocVerifySource.MetadataPanel}
            />
        );
    }

    const scheduleSteps = [
        {
            bodyOnly: true,
            body: docVerifyLoadingPage()
        },
        {
            title: selectedItems.some(s => documentUnivocityError?.notUnivocityDocumentList.some(n => n.fileId === s.id)) ? t('verificationResultTitleWithErrorRules') : t('verificationResultTitle'),
            body: docVerifyBody(),
            footer: docVerifyFooter()
        }
    ];

    return (
        <div>
            {/* Se esiste almeno una regola, attiva il secondo, altrimenti attiva il primo */}
            {!archivesHasUnivocityRole && (
                <DeleteModalLayoutBase
                    {...props}
                    Title={t('title')}
                    onDismiss={() => { dispatch(showDeleteModal({ show: false })) }}
                    SubText={
                        selectedItems.length && selectedItems.length > 1
                            ? t('messageItems')
                            : t('messageFile')
                    }
                    onClick={restoreFromRecycleBinItem}
                    PrimaryButtonText={t('common:dialogButtonConfirmContinue')}
                    DefaultButtonText={t('common:dialogButtonCancel')}
                    IsOpen={deleteModalShown}
                />
            )}

            {archivesHasUnivocityRole && (
                <MultiStepModal
                    subtitle={''}
                    isOpen={deleteModalShown}
                    onCloseClick={() => backToRecycleBin()}
                    steps={scheduleSteps}
                    activeStep={currentStep}
                    height={520}
                    width={755}
                    animateInitialStep
                />
            )}
        </div>
    )
}