import React, { useState } from "react";
import { classNamesFunction, ContextualMenu, Icon, IContextualMenuItem, INavLinkGroup, Nav } from "@fluentui/react";
import { IPortfoliosListPropsStyles, IPortfoliosListStyles, IPortfoliosListProps } from "./portfoliosList.types";
import { useOnMount } from "../../../../utilities/hooks";
import { Portfolio } from "../../../models/portfolio";
import { useNavigator } from "../../../hooks/useNavigator";
import TitleListSection from "../../../../common/components/titleListSection/titleListSection";
import NavigationContainer from "../../../../common/components/navigationContainer/navigationContainer";
import { ActivityTab } from "../../../utilities/routes";
import { getPortfolioActions } from "../../../utilities/portfolioActions";
import { useTranslation } from "react-i18next";
import CreatePortfolioModal from "../createPortfolioModal/createPortfolioModal";
import { useActivityLabDispatch } from "../../../activityLabStore";
import { loadPortfolioList, usePortfolioList } from "../../../features/portfolioList";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import currentUser from "../../../../modules/authentication/currentUser";
import { EdiUserRoleId } from "../../../../utilities/constants";
import axios from "axios";
/*eslint-disable sonarjs/cognitive-complexity */

const getClassNames = classNamesFunction<IPortfoliosListPropsStyles, IPortfoliosListStyles>();
export const PortfoliosListBase = (props: IPortfoliosListProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [openedMenuId, setOpenedMenuId] = useState<number>();
    const [createPortfolioModal, setCreatePortfolioModal] = useState<boolean>(false);
    const navigator = useNavigator();
    const { portfolioList, portfolioIds, isLoading, isError } = usePortfolioList();
    const canUserCreatePortfolio = currentUser.getUserRoles?.includes(EdiUserRoleId.ActivityLabAdmin);
    const { t } = useTranslation(['portfoliosList', 'common']);
    const dispatch = useActivityLabDispatch();

    useOnMount(() => {
        const cancelTokenSource = axios.CancelToken.source();
        void dispatch(loadPortfolioList(cancelTokenSource.token)).then((result) => {
            const portfolios = result.payload as Portfolio[];
            if (!portfolios) return;
            let portfolioToBeSelected = portfolios.find((portfolio) => portfolio.id === navigator.currentPortfolioId)?.id;

            if (!portfolioToBeSelected && portfolios.length > 0) {
                portfolioToBeSelected = portfolios[0].id;
            }

            if (portfolioToBeSelected !== undefined && navigator.isActivityLabRoute && !props.isWorkflowDeepLink){
                navigator.changePortfolio(portfolioToBeSelected);

            }
        });

        
        return () => cancelTokenSource.cancel();
    });


    const changePortfolio = (key: string | undefined) => {
        key && navigator.changePortfolio(+key, ActivityTab.board);
    }

    const mapPortfolio = (portfolio: Portfolio) => {
        return {
            key: portfolio.id.toString(),
            name: portfolio.name,
            url: "",
            portfolio: portfolio
        };
    };

    const portfolioNavLinkGroup: INavLinkGroup = {
        links:
            portfolioIds
                .map((id) => portfolioList[id])
                .map(mapPortfolio) ?? [],
    };

    const getContextualMenuItems = (portfolio: Portfolio): IContextualMenuItem[] => {
        return getPortfolioActions(portfolio.portfolioRoleId).map(
            (action) => ({
                key: action.label,
                text: t(action.label),
                iconProps: { iconName: action.icon },
                onClick: () => action.onClick(portfolio, navigator, dispatch),
            })
        );
    };

    const renderPortfolioList = (groups: INavLinkGroup[]) => {
        return (
            portfolioNavLinkGroup.links.length === 0 ?
                <div className={classNames.emptyFolderContainer}>
                    <TeamsImage styles={{ img: { width: '60%' } }} imageName={ImageName.EmptyFolderDrop} caption={t('common:emptyList')} />
                </div> :
                <Nav
                    styles={classNames.subComponentStyles.nav}
                    groups={groups}
                    onLinkClick={(_, item) => { changePortfolio(item?.key) }}
                    selectedKey={navigator.currentPortfolioId?.toString()}
                    onRenderLink={(link) => (
                        <>
                            <div className={classNames.renderLinkStyle}>{link?.name}</div>
                            {getContextualMenuItems(link?.portfolio).length > 0 && <>
                                <Icon
                                    id={`more_${link?.portfolio.id}`}
                                    iconName="More"
                                    className={classNames.moreButton}
                                    styles={classNames.subComponentStyles.moreIcon}
                                    style={openedMenuId === link?.portfolio.id ? { visibility: 'visible' } : undefined}
                                    onClick={ev => {
                                        navigator.currentPortfolioId?.toString() === link?.key && ev.stopPropagation();
                                        setOpenedMenuId(link?.portfolio.id);
                                    }}
                                />
                                {openedMenuId === link?.portfolio.id &&
                                    <ContextualMenu
                                        items={getContextualMenuItems(link?.portfolio)}
                                        target={`#more_${link?.portfolio.id}`}
                                        onDismiss={() => setOpenedMenuId(undefined)}
                                    />
                                }
                            </>}
                        </>
                    )}
                />
        );
    };

    return (
        <>
            <div className={classNames.root}>
                <TitleListSection
                    title={t('title')}
                    isReloading={isLoading}
                    refreshButtonHandler={() => dispatch(loadPortfolioList())}
                />
                <NavigationContainer
                    isError={isError}
                    isLoading={isLoading}
                    canUserCreateResource={canUserCreatePortfolio}
                    createResourceText={t('createNew')}
                    onClickFooterButton={() => setCreatePortfolioModal(true)}
                    iconName={"FormLibraryMirrored"}
                >
                    <>
                        {portfolioNavLinkGroup.links.length > 0 && renderPortfolioList([portfolioNavLinkGroup])}
                    </>
                </NavigationContainer>
            </div>
            {canUserCreatePortfolio && createPortfolioModal && <CreatePortfolioModal onClose={() => setCreatePortfolioModal(false)} />}
        </>
    );
}