/*eslint-disable sonarjs/cognitive-complexity */
import { classNamesFunction, Spinner, SpinnerSize, TooltipHost } from "@fluentui/react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import AccordionAnalysis from "../../../../../common/components/accordionAnalysis/accordionAnalysis";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { Helpers } from "../../../../../utilities/helpers";
import { Labels } from "../../../../models/constants";
import { IAnalyzesData, IAnalyzesProperties, IResult } from "../../../../models/docAnalysis";
import { docAnalyzesApi } from "../../../../services/docAnalyzes/docAnalyzes.api";
import Results from "../results";
import { IQualityCheckResultsProps, IQualityCheckResultsPropsStyles, IQualityCheckResultsStyles } from "./qualityCheckResult.types";

const getClassNames = classNamesFunction<IQualityCheckResultsPropsStyles, IQualityCheckResultsStyles>();

export const QualityCheckResultsBase = (props: IQualityCheckResultsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [loading, setLoading] = useState(false);
    const [docs, setDocs] = useState<IResult<IAnalyzesData[]>[]>();
    const [apiInput, setApiInput] = useState<{ Name: string, Description: string }>();
    const [apiResult, setApiResult] = useState<IResult<IAnalyzesData[]>[]>([]);
    const [errorCode, setErrorCode] = useState<number|undefined>(undefined)
    const [documentIndex, setDocumentIndex] = useState(0);
    const location = useLocation();
    const splittedLocation = location.pathname.split('/');
    const id = splittedLocation.pop();

    const { t } = useTranslation(['common', 'qualityCheckResult', 'docAnalysis', 'archiveContentActions', 'analysisResults']);
    const emptyMessage = `${t('analysisResults:empty')}`;


    useEffect(() => {
        if (!apiResult)
            return;

        const titles: string[] = [];
        apiResult.map(items => titles.push(items.fileName));

        const finalResult: IResult<IAnalyzesData[]>[] = [];
        for (let i = 0; i < apiResult.length; i++) {
            const result: IAnalyzesData[] = []

            if (apiResult[i].data && apiResult[i].data.length > 0) {
                const items = apiResult[i].data;
                const lowest = _.minBy(items, item => item.level)?.level ?? 0;

                apiResult[i].data.forEach((item) => {
                    if (item.level === lowest)
                        result.push(item);
                    else {
                        let last = result[result.length - 1];

                        if (last) {
                            do {
                                if (item.level === last.level + 1) {
                                    last.children ? last.children.push(item) : last.children = [item];
                                    break;
                                }
                                else {
                                    if (last.children)
                                        last = last.children[last.children.length - 1]
                                    else
                                        break;
                                }
                            } while (true) /* eslint-disable-line no-constant-condition */
                        }
                    }
                });
            }

            finalResult.push({
                fileId: apiResult[i].fileId,
                fileName: apiResult[i].fileName,
                path: apiResult[i].path,
                error: apiResult[i].error,
                warning: apiResult[i].warning,
                data: result
            })
        }
        setDocs(finalResult);
    }, [apiResult]);

    const getAnalysis = async () => {
        setLoading(true);
        setErrorCode(undefined);
        try {
            if (id) {
                const [APIresult] = await Promise.all([docAnalyzesApi.getAnalysisById(+id), Helpers.delay(500)]);
                return APIresult;
            }
        }
        catch (error) {
            console.log("ERROR: ", error);
            setErrorCode(error.code);
        }
        finally {
            setLoading(false);
        }

        return null;
    };

    useEffect(() => {
        (async () => {
            const res = await getAnalysis();
            if (!res)
                return;

            try { setApiResult(JSON.parse(res.result ?? "")); } catch { setApiResult([]); }
            try { setApiInput(JSON.parse(res.input ?? "")); } catch { setApiInput({ Name: "", Description: "" }); }
        })()
    }, []); //eslint-disable-line react-hooks/exhaustive-deps


    const handleLabel = (LabelId: number) => {
        let returnString = '';
        const docAnalysis = 'docAnalysis:labels.types.';
        switch (LabelId) {
            case 0: returnString = `${t(docAnalysis + Labels.Negligible)}`; break;
            case 1: returnString = `${t(docAnalysis + Labels.Small)}`; break;
            case 2: returnString = `${t(docAnalysis + Labels.Medium)}`; break;
            case 3: returnString = `${t(docAnalysis + Labels.Large)}`; break;
            default: returnString = ""; break;
        }
        return returnString;
    }

    const renderCheck = (item: IAnalyzesProperties[]) => {
        return (
            item.map((property, index) => {
                const newProperties = Helpers.keysLowerCase(property);
                const label = (newProperties.labelid === undefined || newProperties.labelid === null) ? -1 : newProperties.labelid;
                return (
                    <>
                        <div key={newProperties.name + "_" + index} className={classNames.checkContainer}>
                            <span className={classNames.resultCheck}>{`${t('analysisResults:qualityCheckResult.' + newProperties.name.toLowerCase())}`}</span>
                            <TooltipHost content={t('analysisResults:tooltipText')}>
                                <span className={classNames.value} style={label < 0 ? { borderRadius: '0 10px 10px 0' } : {}}>{`${newProperties.value}`}</span>
                            </TooltipHost>
                            {/* <Dropdown styles={classNames.subComponentStyles.dropdown} options={AvailableOptions} defaultSelectedKey={"ok"}></Dropdown> */}
                            {label >= 0 && <span className={classNames.labelResultChek}>{`${handleLabel(label)}`}</span>}
                        </div>
                    </>)
            })
        )
    }
    const renderNode = () => {
        if (loading)
            return;
        if (docs && docs[documentIndex] && docs[documentIndex].error)
            return <div>{t('analysisResults:error')}</div>
        if (docs && docs[documentIndex] && docs[documentIndex].data.length === 0)
            return <div>{emptyMessage}</div>

        return docs && docs[documentIndex] && renderAccordionsRecursive(docs[documentIndex].data);
    }

    const renderAccordionsRecursive = (array: IAnalyzesData[]) => {
        if (loading || !array)
            return;
        return (
            array.map((par, index) => {
                return (
                    <div key={par.name + index} style={{ paddingLeft: par.level * 20 }}>
                        <AccordionAnalysis key={"ch_" + par.name} title={par.name} opened={false} stickyHeader>
                            <>
                                <div className={classNames.renderCheckContainer}>
                                    {renderCheck(par.properties)}</div>
                                {par.children && par.children.length > 0 && renderAccordionsRecursive(par.children)}
                            </>
                        </AccordionAnalysis>
                    </div>
                )
            }))
    }

    const qualityCheckBody = () => {
        return (
            <>
                {loading ? <Spinner style={{ position: 'absolute', left: '50%', top: 'calc(50% - 18px)' }} size={SpinnerSize.large} /> :
                    docs && docs.length > 0 ? renderNode() : errorCode === 403 ? <div><TeamsImage
                    styles={{
                        img: {
                            width: '25%'
                        }
                    }}
                    imageName={ImageName.Forbidden}
                    caption={t("docAnalysis:forbidden")}
                /></div> : <div>{emptyMessage}</div>}
            </>
        )
    }

    return (

        <>
            <Results
                apiInput={apiInput}
                content={qualityCheckBody()}
                docs={docs}
                isLoading={loading}
                changeDocument={(document) => setDocumentIndex(document)}
                iconName={"AnalyticsReport"}
                sectionTitle={`${t('analysisResults:qualityCheckResult.title')}`}
            />

        </>
    );
}