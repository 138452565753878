import { IStyleFunction } from "@fluentui/react"
import { IDeleteTopicsModalPropsStyles, IDeleteTopicsModalStyles } from "./deleteTopicsModal.types";

export const DeleteTopicsModalGlobalClassNames = {
    root: 'edi-delete-topics-root'
}

export const getStyles: IStyleFunction<IDeleteTopicsModalPropsStyles, IDeleteTopicsModalStyles> = (props: IDeleteTopicsModalPropsStyles): IDeleteTopicsModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [ DeleteTopicsModalGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px',
                selectors: {
                    ' .edi-profile-view-action': {
                        right: '15px',
                        bottom: '15px',
                    }
                }
            }            
        ],
        footerContainer:{
            overflow: 'hidden',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        },
        footerButtons:
        {
            'margin-left': '10px',
            marginBottom: '2px'
        },
        errorMessage: {
            color: palette.red
        }
    }
}