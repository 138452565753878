import { styled } from "@fluentui/react";
import { getStyles } from "./qualityCheckSettings.styles";
import { IQualityCheckSettingsPropsStyles, IQualityCheckSettingsStyles, IQualityCheckSettingsProps } from "./qualityCheckSettings.types";
import { QualityCheckSettingsBase } from "./qualityCheckSettings.base";

const QualityCheckSettings = styled<IQualityCheckSettingsProps, IQualityCheckSettingsPropsStyles, IQualityCheckSettingsStyles>(
    QualityCheckSettingsBase,
    getStyles,
    undefined,
    { scope: 'QualityCheckSettings' }
);

export default QualityCheckSettings;