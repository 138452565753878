import React from "react";
import { classNamesFunction, IconButton, IContextualMenuProps, Stack, StackItem, TooltipHost } from "@fluentui/react";
import { IFavouriteCardPropsStyles, IFavouriteCardStyles, IFavouriteCardProps } from "./favouriteCard.types";
import { useTranslation } from "react-i18next";
import { useKnowledgeLabDispatch, useKnowledgeLabSelector } from "../../../knowledgeLabStore";
import { setDetailsModalOpen, setRefreshFavourites, setSelectedElement } from "../../../features/knowledgeLabContent";
import { FavouriteElement } from "../../../models/element";
import { knowledgeLabApi } from "../../../services/knowledgeLab.api";
import { KnowledgeHelpers } from "../../../utilities/helpers";

const getClassNames = classNamesFunction<IFavouriteCardPropsStyles, IFavouriteCardStyles>();

export const FavouriteCardBase = (props: IFavouriteCardProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const { t, i18n } = useTranslation("knowledgeFavourites");
    const dispatch = useKnowledgeLabDispatch();
    const { selectedKnowledgeLab } = useKnowledgeLabSelector(state => state.knowledgeLabList);

    const currentLang = (i18n.language).split("-")[0];
    const currentTranslations = selectedKnowledgeLab?.configurations?.translations;
    const defaultTitle = KnowledgeHelpers.getKnowledgeTranslation("defaultTitle", currentTranslations, currentLang);

    const element = props.element;

    const getAdditionalData = (data: Record<string, string>) => {
        const keys = Object.keys(data);

        return keys.map((k) => {
            const content = data[k] ?? " - ";
            return <TooltipHost key={k} content={content}>
                <span>
                    {content}
                </span>
            </TooltipHost>
        });

    }

    const openModal = (element: FavouriteElement) => {
        dispatch(setSelectedElement({ id: element.id.toString(), userCreatedId: "", fileRef: element.fileRef ?? 0, userCreatedName: "", dateCreated: "" }));
        dispatch(setDetailsModalOpen(true));
        dispatch(setRefreshFavourites(true));
    }

    const removeFromFavourites = (elementId: number) => {
        knowledgeLabApi.removeElementFromFavourites(elementId).then(() => {
            if (props.onElementRemoved)
                props.onElementRemoved();
        });

    }

    const getActions = (element: FavouriteElement): IContextualMenuProps => {
        return {
            items: [
                {
                    key: "view",
                    text: t("card.actions.view"),
                    onClick: () => { openModal(element) }
                },
                {
                    key: "remove",
                    text: t("card.actions.remove"),
                    onClick: () => { removeFromFavourites(element.id) }
                }
            ]
        }
    }

    const getElementContent = () => {
        let content = props.element.content;

        if (content && content.length > 300)
            content = content.substring(0, 300) + " [...]";
        return content;
    }

    return (
        <div className={classNames.root}>
            <Stack verticalAlign="space-between" style={{ height: "100%" }}>
                <StackItem className={classNames.titleSection}>
                    {/* title  section*/}
                    <span
                        onClick={() => {
                            openModal(element)
                        }}
                    >{props.element.title ?? defaultTitle}
                    </span>
                    <IconButton
                        styles={classNames.subComponentStyles.iconButton()}
                        iconProps={{ iconName: 'More' }}
                        menuProps={getActions(element)}
                    />

                </StackItem>
                <StackItem className={classNames.contentSection}>
                    {/* content section */}
                    <span>{getElementContent()}</span>
                </StackItem>
                <StackItem className={classNames.viewsSection}>
                    {/* views section */}
                    <span style={{ color: palette.themePrimary, marginTop: 5, marginBottom: 5 }}>{t("card.views")}: <b>{props.element.viewsCount}</b></span>
                </StackItem>
                <StackItem className={classNames.additionalDataSection}>
                    {/* additional data section */}
                    {props.element.additionalData && getAdditionalData(props.element.additionalData)}
                </StackItem>
            </Stack>
        </div>
    );
}