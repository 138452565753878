import { IStyleFunction } from "@fluentui/react"
import { IExportModalScadenzaBasePropsStyles, IExportModalScadenzaBaseStyles } from "./ExportModalScadenza.types"

export const ExportModalScadenzaGlobalClassNames = {
    root: 'edi-export-report-settings-root',
    secondFooterContainer: 'edi-export-scadenza-second-footer-container',
    exportingFooter: 'edi-export-scadenza-exporting-footer-container',
    sandglassImage: 'edi-export-scadenza-sandglass-image',
    centerContentContainer: 'edi-export-center-content-container',
    buttonContainer: 'edi-export-button-container',
    buttonFooter: 'edi-export-button-footer'
}

export const getStyles: IStyleFunction<IExportModalScadenzaBasePropsStyles, IExportModalScadenzaBaseStyles> = (props: IExportModalScadenzaBasePropsStyles): IExportModalScadenzaBaseStyles => {

    return {
        root: [
            ExportModalScadenzaGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px'
            }
        ],
        centerContentContainer: [
            ExportModalScadenzaGlobalClassNames.centerContentContainer,
            {
                display: 'flex',
                alignItems: 'center',
                height: '100%'
            }
        ],
        exportingFooter: [
            ExportModalScadenzaGlobalClassNames.exportingFooter,
            {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center'
            }
        ],
        sandglassImage: [
            ExportModalScadenzaGlobalClassNames.sandglassImage,
            {
                flexBasis: '50%',
                flexShrink: 0
            }
        ],
        secondFooterContainer: [
            ExportModalScadenzaGlobalClassNames.secondFooterContainer,
            {
                display: 'flex',
                alignItems: 'center',
                height: '100%'
            }
        ],
        buttonContainer: [
            ExportModalScadenzaGlobalClassNames.buttonContainer,
            {
                display: 'flex',
                flexDirection: 'initial',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '5px'
            }
        ],
        buttonFooter:[
            ExportModalScadenzaGlobalClassNames.buttonFooter,
            {
                display:"flex",
                flexDirection:"row", 
                alignItems:"center"
            }
        ]
        
    }
}