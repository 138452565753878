import React from "react";
import { ActionButton, classNamesFunction, Pivot, PivotItem, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { IProfileManagementBaseProps, IProfileManagementBasePropsStyles, IProfileManagementBaseStyles } from "./profileManagement.types";
import MemberView from "./memberView/memberView";
import ProfileView from "./profileView/profileView";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import { useDocLabDispatch } from "../../../docLabStore";
import { setToolbarActions } from "../../../features/toolbarActions";
import { useTranslation } from "react-i18next";
import { archivesApi } from "../../../services/archives/archives.api";
import { svcPaths } from "../../../../modules/apiClient/config";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";

const getClassNames = classNamesFunction<IProfileManagementBasePropsStyles, IProfileManagementBaseStyles>();

export const ProfileManagementBase = (props: IProfileManagementBaseProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const dispatch = useDocLabDispatch();
    const currentArchive = useCurrentArchive();
    const { t } = useTranslation(['profileManagement']);

    useOnMount(() => {
        dispatch(setToolbarActions([]));
    })

    const { execute: generateReport, loading: generatingReport } = useAsyncApi<void, void>({
        func: async () => {
            await archivesApi.downloadGenericFile(`${svcPaths.archives}/profile/${currentArchive?.id}/profilereport`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', currentArchive?.name + '_UserManagement.xlsx')
        }
    });

    return (
        <Stack className={classNames.root}>

            <Stack horizontal horizontalAlign="space-between" verticalAlign={'center'}>
                <Stack.Item className={classNames.title}>
                    <span style={{ paddingLeft: 10 }}>{t('manageUser')}</span>
                </Stack.Item>

                <Stack.Item style={{display: 'flex'}}>
                    {generatingReport && 
                        <Spinner size={SpinnerSize.small} />
                    }
                    <ActionButton
                        disabled={generatingReport}
                        onClick={() => generateReport()}
                        >
                        {t('reportDonwload')}
                    </ActionButton>
                </Stack.Item>     

            </Stack>
            
            <Pivot>
                <PivotItem headerText={t('viewMember')}>
                    <MemberView />
                </PivotItem>
                <PivotItem headerText={t('viewProfile')}>
                    <ProfileView />
                </PivotItem>
            </Pivot>       
                
        </Stack> 
    )
}