import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { KnowledgeLab, KnowledgeLabShort } from "../models/knowledgeLab";
import { knowledgeLabApi } from "../services/knowledgeLab.api";
import { useKnowledgeLabSelector } from "../knowledgeLabStore";
import { KnowledgeLabRoleId } from "../models/knowledgeLabRoleId";
import { KnowledgeFile } from "../models/file";

export type KnowledgeLabListState = {
  knowledgeLabList: KnowledgeLabShort[],
  selectedKnowledgeLab: KnowledgeLabShort | undefined,
  currentKnowledgeLabRoleId: KnowledgeLabRoleId | undefined,
  selectedDocuments: KnowledgeFile[] | undefined,
  isLoading: boolean;
  isError: boolean;
  isDeeplink: boolean;
};

const initialState: KnowledgeLabListState = {
  knowledgeLabList: [],
  selectedKnowledgeLab: undefined,
  currentKnowledgeLabRoleId: undefined,
  selectedDocuments: undefined,
  isLoading: false,
  isError: false,
  isDeeplink: true
};

export const loadKnowledgeLabList = createAsyncThunk(
  "knowledgeLabList/load-knowledgeLabs",
  async (knowledgeId?: number) => {
    const knowledges = await knowledgeLabApi.getKnowledgeLabList()

    let knowledge: KnowledgeLab | undefined;
    if (knowledgeId)
      knowledge = await knowledgeLabApi.getKnowledgeLab(knowledgeId);

    return { knowledges: knowledges, knowledge };

  }
);

export const loadKnowledge = createAsyncThunk("archives/load-knowledge", async (knowledgeId: number) => await knowledgeLabApi.getKnowledgeLab(knowledgeId));

const knowledgeLabListSlice = createSlice({
  name: "knowledgeLab-knowledge-list",
  initialState,
  reducers: {
    setSelectedDocuments: (state: KnowledgeLabListState, action: PayloadAction<KnowledgeFile[] | undefined>) => {
      state.selectedDocuments = action.payload;
    },
    setSelectedKnowledgeLab: (state: KnowledgeLabListState, action: PayloadAction<KnowledgeLabShort>) => {
      state.selectedKnowledgeLab = action.payload;
      state.currentKnowledgeLabRoleId = action.payload.roleId
    },
    setIsDeeplink: (state: KnowledgeLabListState, action: PayloadAction<boolean>) => {
      state.isDeeplink = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadKnowledgeLabList.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(loadKnowledgeLabList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.knowledgeLabList = action.payload.knowledges;
      if (action.payload.knowledge) {
        const result = action.payload.knowledge;
        const knowledge = state.knowledgeLabList.find(i => i.id === result.id);
        if (knowledge) {
          knowledge.jsonSchema = result.jsonSchema;
          knowledge.jsonSchemaFields = result.jsonSchemaFields;
          knowledge.metadataShortJsonSchema = result.metadataShortJsonSchema;
          knowledge.uiSchema = result.uiSchema;
          knowledge.metadataMapping = result.metadataMapping;
          knowledge.titleFieldName = result.titleFieldName;
          knowledge.searchProperties = result.searchProperties;
          knowledge.commonProperties = result.commonProperties;
          knowledge.roleId = result.roleId;
          state.currentKnowledgeLabRoleId = result.roleId;
          state.selectedKnowledgeLab = knowledge;
        }
      }
    });

    builder.addCase(loadKnowledgeLabList.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.knowledgeLabList = [];
    });

    builder.addCase(loadKnowledge.fulfilled, (state, action) => {
      const result = action.payload;
      const knowledge = state.knowledgeLabList.find(i => i.id === result.id);
      if (knowledge) {
        knowledge.jsonSchema = result.jsonSchema;
        knowledge.jsonSchemaFields = result.jsonSchemaFields;
        knowledge.metadataShortJsonSchema = result.metadataShortJsonSchema;
        knowledge.uiSchema = result.uiSchema;
        knowledge.metadataMapping = result.metadataMapping;
        knowledge.titleFieldName = result.titleFieldName;
        knowledge.searchProperties = result.searchProperties;
        knowledge.commonProperties = result.commonProperties;
        state.selectedKnowledgeLab = knowledge;
        state.currentKnowledgeLabRoleId = knowledge.roleId
      }

    })
  }
});

export default knowledgeLabListSlice.reducer;
export const { setSelectedKnowledgeLab, setSelectedDocuments } = knowledgeLabListSlice.actions;
export const useKnowledgeLabList = () => useKnowledgeLabSelector((state) => state.knowledgeLabList);