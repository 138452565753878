import { styled } from "@fluentui/react";
import { ImportExportMetadataBase } from "./importExport.base";
import { getStyles } from "./importExport.styles";
import { IImportExportMetadataProps, IImportExportMetadataPropsStyles, IImportExportMetadataStyles } from "./importExport.types";

const ImportExportMetadata = styled<IImportExportMetadataProps, IImportExportMetadataPropsStyles, IImportExportMetadataStyles>(
    ImportExportMetadataBase,
    getStyles,
    undefined,
    { scope: 'ImportExportMetadata'}
)

export default ImportExportMetadata;