import { styled } from "@fluentui/react";
import { getStyles } from "./tag.styles";
import { ITagPropsStyles, ITagStyles, ITagProps } from "./tag.types";
import { TagBase } from "./tag.base";

const Tag = styled<ITagProps, ITagPropsStyles, ITagStyles>(
    TagBase,
    getStyles,
    undefined,
    { scope: 'Tag' }
);

export default Tag;