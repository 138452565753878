import { styled } from "@fluentui/react";
import { getStyles } from "./fileNameCell.styles";
import { IFileNameCellPropsStyles, IFileNameCellStyles, IFileNameCellProps } from "./fileNameCell.types";
import { FileNameCellBase } from "./fileNameCell.base";

const FileNameCell = styled<IFileNameCellProps, IFileNameCellPropsStyles, IFileNameCellStyles>(
    FileNameCellBase,
    getStyles,
    undefined,
    { scope: 'FileNameCell' }
);

export default FileNameCell;