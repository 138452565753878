import React, { useMemo, useState } from "react";
import { classNamesFunction, PrimaryButton } from "@fluentui/react";
import { ICreatePortfolioModalPropsStyles, ICreatePortfolioModalStyles, ICreatePortfolioModalProps } from "./createPortfolioModal.types";
import { CreatePortfolio, FirstPageData } from "./page/firstPage";
import MultiStepModal from "../../../../common/components/multiStepModal/multiStepModal";
import { useTranslation } from "react-i18next";
import { CreatingPortfolioPageResult } from "./page/creatingPortfolioPageResult";

const getClassNames = classNamesFunction<ICreatePortfolioModalPropsStyles, ICreatePortfolioModalStyles>();


enum CreatePortfolioStep {
    setName = 0,
    creatingPortfolio = 1
}

export const CreatePortfolioModalBase = (props: ICreatePortfolioModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [firstPageData, setFirstPageData] = useState<FirstPageData>({ name: "", isValid: false });
    const [currentStep, setCurrentStep] = useState(CreatePortfolioStep.setName);
    const [isMutating, setIsMutating] = useState(false);
    const { t } = useTranslation('createPortfolioModal')
    
    const footer = useMemo(() => {
        return (
            <>
                <span className={classNames.requiredTextLeft}>* {t('common:fieldRequired')}</span> 
                <span></span>
                <div className={classNames.buttonContainer}>
                    <PrimaryButton text={t('common:nextButton')}
                        disabled={isMutating || !firstPageData.isValid}
                        onClick={() => { setCurrentStep(CreatePortfolioStep.creatingPortfolio)}}
                    />
                </div>
            </>
        );
    }, [firstPageData.isValid, t, isMutating, classNames.requiredTextLeft, classNames.buttonContainer]);

    const steps = [
        {
            title: `${t('pageTitle')}`,
            body: <CreatePortfolio theme={props.theme} onPortfolioChange={setFirstPageData} initialState={firstPageData} />,
            footer: footer
        },
        {
            bodyOnly: true,
            body: <CreatingPortfolioPageResult onClose={props.onClose} initialState={
                { name: firstPageData.name }
            } />
        },
    ]

    return (
        <MultiStepModal
            styles={classNames.subComponentStyles.ediModal}
            isOpen={true}
            showCloseIcon={currentStep !== CreatePortfolioStep.creatingPortfolio}
            onCloseClick={props.onClose}
            activeStep={currentStep}
            steps={steps}
            onStepChange={setIsMutating}
            animateInitialStep
        />
    );
}