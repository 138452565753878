import * as Config from './config';

class AppEntity {
    private appId?: string;
    private entityId?: string;

   constructor() {
        this.appId = Config.environment[window.location.host].appId;
        this.entityId = Config.environment[window.location.host].entityId;
    }

    public get getAppId(){
        return this.appId;
    }

    public get getEntityId(){
        return this.entityId;
    }
}

export default new AppEntity();