import { IDetailsHeaderStyleProps, IDetailsHeaderStyles, IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react"
import { IDocumentTagsListPropsStyles, IDocumentTagsListStyles } from "./documentTagsList.types";

export const DocumentTagsListGlobalClassNames = {
    root: 'edi-documentTagsList-root',
    emptyList: 'edi-documentTagsList-emptyList',
    subComponentStyles: 'edi-documentTagsList-subComponentStyles'
}

export const getStyles: IStyleFunction<IDocumentTagsListPropsStyles, IDocumentTagsListStyles> = (props: IDocumentTagsListPropsStyles): IDocumentTagsListStyles => {
    return {
        root: [
            DocumentTagsListGlobalClassNames.root,
            {
                height: '100%',
                overflow:'hidden'
            }
        ],
        emptyList: [
            DocumentTagsListGlobalClassNames.emptyList,
            {
                position: 'absolute',
                top: '60px',
                width: '150px',
                left: 'calc(50% - 88px)'
            }
        ],
        subComponentStyles: {
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                    },
                    headerWrapper: {
                    },
                    contentWrapper: {
                    },
                    focusZone: {}
                }
            },
            detailsHeader: (props: IDetailsHeaderStyleProps): IDetailsHeaderStyles => {
                return {
                    root: {
                        borderBottom: '0px'
                    },
                    cellIsCheck: {},
                    check: {},
                    cellWrapperPadded: {},
                    cellIsActionable: {},
                    cellIsEmpty: {},
                    cellSizer: {},
                    cellSizerStart: {},
                    cellSizerEnd: {},
                    cellIsResizing: {},
                    cellIsGroupExpander: {},
                    collapseButton: {},
                    checkTooltip: {},
                    sizingOverlay: {},
                    dropHintCircleStyle: {},
                    dropHintCaretStyle: {},
                    dropHintLineStyle: {},
                    dropHintStyle: {},
                    accessibleLabel: {},
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        borderBottom: '0px',
                        selectors: {
                            ':hover': {
                                backgroundColor: 'unset'
                            }
                        },
                    },
                    cell: { alignSelf: 'center', textOverflow: 'unset', paddingBottom: '0px', paddingTop: '0px !important', height: '100%' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            }
        }
    };
}