import React, { useState } from "react";
import { ActionButton, classNamesFunction, IconButton, Label, ScrollablePane, Stack, TextField, TooltipHost } from "@fluentui/react";
import { IFeedbackFiltersPropsStyles, IFeedbackFiltersStyles, IFeedbackFiltersProps } from "./feedbackFilters.types";
import DatePicker from "../../../../common/components/datePicker/datePicker";
import { useTranslation } from "react-i18next";
import { resetFeedbackFilters, setBaseFeedbackFilters, setFilters, setSelectedItems, useFeedbackList } from "../../../features/feedbackList";
import { useKnowledgeDsDispatch } from "../../../knowledgeDsStore";
import { FeedbackStatus, knowledgeDsStatusText } from "../../../models/feedback";
import { FacetType, FlatFacet, ISkillResultsTagValue } from "../../../../knowledgeLab/services/search/search.contracts";
import Tag from "../../../../common/components/tag/tag";
import { useOnMount } from "../../../../utilities/hooks";
import { knowledgeDsApi } from "../../../services/knowledgeDS.api";

const getClassNames = classNamesFunction<IFeedbackFiltersPropsStyles, IFeedbackFiltersStyles>();

export const FeedbackFiltersBase = (props: IFeedbackFiltersProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['knowledgeDsFeedback', 'common', 'knowledgeDsToolbar']);
    const { filters } = useFeedbackList();
    const dispatch = useKnowledgeDsDispatch();
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    const onRenderSuffix = () => <IconButton iconProps={{ iconName: 'search' }} aria-label="Search" onClick={() => onChangeFilter()} />
    const value = t(knowledgeDsStatusText.find(t => t.key === FeedbackStatus.Pending)?.value ?? "");
    const baseFacet: FlatFacet = {
        key: "feedbackStatus",
        value: value,
        type: FacetType.Value,
        text: value
    }


    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [selectedFacet, setSelectedFacet] = useState<FlatFacet[]>([]);
    const [labs, setLabs] = useState<string[]>([]);
    const [skills, setSkills] = useState<string[]>([]);

    useOnMount(() => {
        knowledgeDsApi.getFeedbackFilters().then((r) => {
            setLabs(r.knowledgeLabs);
            setSkills(r.skills);
            if (filters.feedbackStatus === FeedbackStatus.Pending)
                setSelectedFacet([baseFacet]);
        })
    })

    const onChangeFilter = () => {
        dispatch(setSelectedItems([]))
    }

    const getStatusFilter = () => {
        const values = Object.values(FeedbackStatus) as FeedbackStatus[];
        return values.map(v =>
            <Stack.Item key={v}>
                <span style={{ cursor: 'pointer', fontWeight: ((filters.feedbackStatus === v) ? 'bold' : undefined), color: ((filters.feedbackStatus === v) ? palette.themePrimary : '') }} onClick={() => {
                    dispatch(setFilters({ ...filters, feedbackStatus: v, pageNumber: 0 }));
                    if (selectedFacet) {
                        const currentFacets = [...selectedFacet];
                        const facet = currentFacets.find(f => f.key === "feedbackStatus");
                        const value = t(knowledgeDsStatusText.find(t => t.key === v)?.value ?? "");
                        if (facet) {
                            const index = currentFacets.indexOf(facet);
                            currentFacets[index] = { ...currentFacets[index], value: value }

                        } else {
                            const newFacet: FlatFacet = {
                                key: "feedbackStatus",
                                value: value,
                                type: FacetType.Value,
                                text: value
                            }

                            currentFacets.push(newFacet)
                        }
                        setSelectedFacet(currentFacets);
                    }


                }}>{t(knowledgeDsStatusText.find(t => t.key === v)?.value ?? "")}</span>
            </Stack.Item>
        );
    }

    const getTagByType = (x: FlatFacet) => {
        if (x.type === FacetType.Value && x.value)
            return getTag(x, x.value.toString(), { ...x, text: x.value.toString() })
        if (x.type === FacetType.Value)
            return x.values?.map((val: string) => {
                return getTag(x, val, { ...x, text: val });
            })
        if (x.type === FacetType.Range || x.type === FacetType.DateRange)
            return getTag(x, x.text || '', x);
    }

    const removeSelectedFacet = (f: FlatFacet) => {
        if (selectedFacet) {
            const newFacets = selectedFacet.filter(i => i.key !== f.key);
            setSelectedFacet(newFacets);
            const currentFilters = { ...filters };
            const prop = f.key as keyof typeof filters;
            currentFilters[prop] = undefined;
            dispatch(setFilters(currentFilters))
        }

    }

    const getTag = (facet: FlatFacet, value: string, facetToRemove: FlatFacet) => {
        const y: ISkillResultsTagValue = { value: value, title: '', isPinned: false, toDelete: false }; //TODO generalizare tipo in input al componente Tag
        return <Stack.Item key={`StackItem_${facet.key}_${y.value.trim()}`}>
            <Tag
                onCancelSelected={() => removeSelectedFacet(facetToRemove)}
                selectableTag
                small
                key={`${facet.key}_${y.value.trim()}`}
                value={y}
                keyTag={`${facet.key}_${y.value.trim()}`} />
        </Stack.Item>
    }

    return (
        <div style={{
            height: '100%',
            position: 'relative',
            maxHeight: 'inherit',
            paddingLeft: 20
        }}>

            <Stack>
                <ScrollablePane className={classNames.scrollablePane}>
                    <Stack horizontal horizontalAlign={'end'}>
                        <Stack.Item align="end" style={{ marginBottom: "10px" }}>
                            <ActionButton
                                style={{ position: "relative", right: 0 }}
                                onClick={() => {
                                    setSelectedFacet([baseFacet]);
                                    dispatch(setBaseFeedbackFilters());
                                }}>
                                <span style={{ color: palette.themePrimary, textDecoration: 'underline' }}>{t('filters.resetFilters')}</span>
                            </ActionButton>
                        </Stack.Item>
                    </Stack>

                    <Stack horizontal style={{ marginTop: '15px' }} className={classNames.filterPanelItem}>
                        <Stack.Item align="center" style={{ alignItems: 'center' }} >
                            <span style={{ fontWeight: 'bold', fontSize: '1em' }}>{t('filters.filter')}</span>
                        </Stack.Item>

                        {selectedFacet?.length !== 0 && <Stack.Item align="center">
                            <TooltipHost
                                content={t('filters.resetFilter')}
                                id={"resetFilters"}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                            >
                                <IconButton iconProps={{ iconName: 'ClearFilter' }} onClick={() => {
                                    setSelectedFacet([]);
                                    dispatch(resetFeedbackFilters());
                                }} />
                            </TooltipHost>
                        </Stack.Item>}

                    </Stack>
                    <Stack horizontal={true} wrap>
                        {selectedFacet?.map((x: FlatFacet) => {
                            return getTagByType(x)
                        })}
                    </Stack>

                    <Stack.Item className={classNames.filterPanelItem} >
                        <Label>{t('filters.fileName')}</Label>
                        <TextField
                            value={fileName}
                            onRenderSuffix={onRenderSuffix}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') dispatch(setFilters({ ...filters, fileName: fileName, pageNumber: 0 }));
                            }}
                            onChange={(e, n) => setFileName(n)} />
                    </Stack.Item>
                    <Stack.Item className={classNames.filterPanelItem}>
                        <Label>{t('filters.feedbackStatus')}</Label>
                        <Stack.Item style={{ margin: '0 0 8px 0' }}>
                            {
                                getStatusFilter()
                            }
                        </Stack.Item>
                    </Stack.Item>

                    {labs.length > 0 &&
                        <Stack.Item className={classNames.filterPanelItem}>
                            <Label>{t('filters.knowledgeLabName')}</Label>
                            <Stack.Item style={{ margin: '0 0 8px 0' }}>
                                {
                                    labs.map(l =>
                                        <Stack.Item key={l}>
                                            <span style={{ cursor: 'pointer', fontWeight: ((filters.knowledgeName === l) ? 'bold' : undefined), color: ((filters.knowledgeName === l) ? palette.themePrimary : '') }} onClick={() => {
                                                dispatch(setFilters({ ...filters, knowledgeName: l, pageNumber: 0 }));
                                                if (selectedFacet) {
                                                    const currentFacets = [...selectedFacet];
                                                    const facet = currentFacets.find(f => f.key === "knowledgeName");
                                                    if (facet) {
                                                        const index = currentFacets.indexOf(facet);
                                                        currentFacets[index] = { ...currentFacets[index], value: l }

                                                    } else {
                                                        const newFacet: FlatFacet = {
                                                            key: "knowledgeName",
                                                            value: l,
                                                            type: FacetType.Value,
                                                            text: l
                                                        }

                                                        currentFacets.push(newFacet)
                                                    }
                                                    setSelectedFacet(currentFacets);
                                                }

                                            }}>{l}</span>
                                        </Stack.Item>
                                    )
                                }
                            </Stack.Item>
                        </Stack.Item>
                    }

                    {skills.length > 0 &&
                        <Stack.Item className={classNames.filterPanelItem}>
                            <Label>{t('filters.skillName')}</Label>
                            <Stack.Item style={{ margin: '0 0 8px 0' }}>
                                {
                                    skills.map(s =>
                                        <Stack.Item key={s}>
                                            <span style={{ cursor: 'pointer', fontWeight: ((filters.skillName === s) ? 'bold' : undefined), color: ((filters.skillName === s) ? palette.themePrimary : '') }} onClick={() => {
                                                dispatch(setFilters({ ...filters, skillName: s, pageNumber: 0 }));
                                                if (selectedFacet) {
                                                    const currentFacets = [...selectedFacet];
                                                    const facet = currentFacets.find(f => f.key === "skillName");
                                                    if (facet) {
                                                        const index = currentFacets.indexOf(facet);
                                                        currentFacets[index] = { ...currentFacets[index], value: s }

                                                    } else {
                                                        const newFacet: FlatFacet = {
                                                            key: "skillName",
                                                            value: s,
                                                            type: FacetType.Value,
                                                            text: s
                                                        }

                                                        currentFacets.push(newFacet)
                                                    }
                                                    setSelectedFacet(currentFacets);
                                                }

                                            }}>{s}</span>
                                        </Stack.Item>
                                    )
                                }
                            </Stack.Item>
                        </Stack.Item>
                    }


                    <Stack.Item className={classNames.filterPanelItem}>
                        <Label>{t('filters.creationDate')}</Label>
                        <Stack horizontal>
                            <Stack.Item style={{ width: '246px' }}>
                                <DatePicker
                                    placeholder={t('filters.from')}
                                    inputValue={filters.dateFrom}
                                    maxDate={filters.dateTo}
                                    dateCallback={e => {
                                        if (e) {
                                            const c = new Date(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate()));
                                            dispatch(setFilters({ ...filters, dateFrom: c, pageNumber: 0 }));
                                        }
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                {
                                    filters.dateFrom &&
                                    <ActionButton
                                        style={{ height: '35px' }}
                                        iconProps={{ iconName: 'cancel' }}
                                        onClick={() => {
                                            dispatch(setFilters({ ...filters, dateFrom: undefined, pageNumber: 0 }));
                                        }
                                        }></ActionButton>
                                }
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal>
                            <Stack.Item style={{ width: '246px' }}>
                                <DatePicker
                                    placeholder={t('filters.to')}
                                    inputValue={filters.dateTo}
                                    minDate={filters.dateFrom}
                                    dateCallback={e => {
                                        if (e) {
                                            const c = new Date(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate()));
                                            dispatch(setFilters({ ...filters, dateTo: c, pageNumber: 0 }));
                                        }
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                {
                                    filters.dateTo &&
                                    <ActionButton
                                        style={{ height: '35px' }}
                                        iconProps={{ iconName: 'cancel' }}
                                        onClick={() => {
                                            dispatch(setFilters({ ...filters, dateTo: undefined, pageNumber: 0 }));
                                            onChangeFilter();
                                        }
                                        }></ActionButton>
                                }
                            </Stack.Item>
                        </Stack>

                    </Stack.Item>
                </ScrollablePane>
            </Stack>


        </div>
    );
}