import { IStyleFunction } from "@fluentui/react"
import { IDeleteKnowledgeDocumentModalPropsStyles, IDeleteKnowledgeDocumentModalStyles } from "./deleteKnowledgeDocumentModal.types"

export const DeleteKnowledgeDocumentModalGlobalClassNames = {
    root: 'edi-delete-knowledge-document-modal-root'
}

export const getStyles: IStyleFunction<IDeleteKnowledgeDocumentModalPropsStyles, IDeleteKnowledgeDocumentModalStyles> = (props: IDeleteKnowledgeDocumentModalPropsStyles): IDeleteKnowledgeDocumentModalStyles => {
    return {
        root: [
            DeleteKnowledgeDocumentModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}