import { styled } from "@fluentui/react";
import { getStyles } from "./folderPermissionModal.styles";
import { IFolderPermissionModalPropsStyles, IFolderPermissionModalStyles, IFolderPermissionModalProps } from "./folderPermissionModal.types";
import { FolderPermissionModalBase } from "./folderPermissionModal.base";

const FolderPermissionModal = styled<IFolderPermissionModalProps, IFolderPermissionModalPropsStyles, IFolderPermissionModalStyles>(
    FolderPermissionModalBase,
    getStyles,
    undefined,
    { scope: 'FolderPermissionModal' }
);

export default FolderPermissionModal;