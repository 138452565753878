import { styled } from "@fluentui/react";
import { FolderTreeViewerBase } from "./folderTreeViewer.base";
import { getStyles } from "./folderTreeViewer.styles";
import { IFolderTreeViewerBasePropsStyles, IFolderTreeViewerBaseStyles, IFolderTreeViewerModalProps } from "./folderTreeViewer.types";

const FolderTreeViewer = styled<IFolderTreeViewerModalProps, IFolderTreeViewerBasePropsStyles, IFolderTreeViewerBaseStyles>(
    FolderTreeViewerBase, 
    getStyles,
    undefined,
    { scope: 'FolderTreeViewer'}
)

export default FolderTreeViewer;