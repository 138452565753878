import React, { useState } from "react";
import { classNamesFunction, DirectionalHint, IconButton, IContextualMenuProps } from "@fluentui/react";
import { IShortElementViewerPropsStyles, IShortElementViewerStyles, IShortElementViewerProps } from "./shortElementViewer.types";
import { ElementShort } from "../../../models/element";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { useDispatch } from "react-redux";
import { setDetailsModalOpen, setSelectedElement } from "../../../features/knowledgeLabContent";
import { Document } from "../../../services/search/search.contracts";
import { useTranslation } from "react-i18next";
import { knowledgeLabApi } from "../../../services/knowledgeLab.api";
import { useKnowledgeLabSelector } from "../../../knowledgeLabStore";
import { KnowledgeHelpers } from "../../../utilities/helpers";
import DeleteKnowledgeElementModal from "../../deleteKnowledgeElementModal/deleteKnowledgeElementModal";
/*eslint-disable sonarjs/cognitive-complexity */

const getClassNames = classNamesFunction<IShortElementViewerPropsStyles, IShortElementViewerStyles>();

export const ShortElementViewerBase = (props: IShortElementViewerProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion  
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation('knowledgeDocuments');
    const { selectedKnowledgeLab } = useKnowledgeLabSelector(state => state.knowledgeLabList);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [elementToDelete, setElementToDelete] = useState<ElementShort>();

    const currentLang = (i18n.language).split("-")[0];
    const currentTranslations = selectedKnowledgeLab?.configurations?.translations;
    const defaultTitle = KnowledgeHelpers.getKnowledgeTranslation("defaultTitle", currentTranslations, currentLang);
    const elementName = KnowledgeHelpers.getKnowledgeTranslation("elementPlaceHolder", currentTranslations, currentLang);

    const menuProps = (element: ElementShort): IContextualMenuProps => {

        const items = [
            {
                key: 'view',
                text: t('actions.view'),
                onClick: () => {
                    openElementDetail(element);
                },
            },
            {
                key: 'delete',
                text: t('actions.delete') + ` ${elementName}`,
                onClick: () => {
                    setElementToDelete(element);
                    setIsModalOpen(true);
                },
            }
        ]

        // if (element.isFavourite) {
        //     items.push({
        //         key: 'remove-from-favourites',
        //         text: t('actions.removeFromFavourites'),
        //         onClick: () => {
        //             knowledgeLabApi.removeElementFromFavourites(element.id).then(
        //                 () => {
        //                     if (props.onElementsUpdate)
        //                         props.onElementsUpdate({ ...element, isFavourite: false })
        //                 }
        //             )
        //         }
        //     })
        // } else {
        //     items.push({
        //         key: 'add-to-favourites',
        //         text: t('actions.addToFavourites'),
        //         onClick: () => {
        //             knowledgeLabApi.addElementToFavourites(element.id).then(
        //                 () => {
        //                     if (props.onElementsUpdate)
        //                         props.onElementsUpdate({ ...element, isFavourite: true })
        //                 }
        //             )
        //         }
        //     })
        // }

        return {
            items: items,
            directionalHint: DirectionalHint.rightCenter
        }
    }


    const openElementDetail = (element: ElementShort) => {
        const doc = {
            id: `${element.id}`,
            fileRef: element.fileId,
        } as Document;
        dispatch(setSelectedElement(doc));
        dispatch(setDetailsModalOpen(true));
    }


    const onRenderCell = (item?: ElementShort, index?: number | undefined): JSX.Element => {
        if (!item)
            return <div></div>;

        return (
            <>
                <div className={classNames.itemHeader}>
                    {item.id !== null &&
                        <span style={{ color: palette.themePrimary, cursor: 'pointer', fontWeight: 'bold' }} onClick={() => openElementDetail(item)}
                        >{item.title ?? defaultTitle}
                        </span>}
                    <IconButton
                        styles={classNames.subComponentStyles.iconButton()}
                        iconProps={{ iconName: 'More' }}
                        menuProps={menuProps(item)}
                    />
                </div>
                <div key={index} className={classNames.itemCell} data-is-focusable={true}>
                    <div className={classNames.itemContent}>
                        {item.content}
                    </div>
                </div>
            </>
        );
    }

    const onDelete = () => {
        if (elementToDelete)
            knowledgeLabApi.deleteElement(elementToDelete.id).then(() => {
                if (props.onRemoveElement)
                    props.onRemoveElement(elementToDelete);
                setIsModalOpen(false);
            })
    }


    return (
        <div style={{ marginTop: '50px' }}>
            {props.elements && props.elements.length ?
                <>
                    <div className={classNames.root}>
                        {
                            props.elements?.map(elem => {
                                return <div key={'elem_' + elem.id}>{onRenderCell(elem)}</div>
                            })
                        }
                    </div>
                    <DeleteKnowledgeElementModal
                        isOpen={isModalOpen}
                        elementId={elementToDelete?.id ?? 0}
                        onClose={() => setIsModalOpen(false)}
                        onComplete={onDelete}
                    />
                </>

                : <TeamsImage
                    imageName={ImageName.EmptySearch}
                    scale={0.3}
                    fullContainer
                    style={{ marginBottom: 30 }}
                    caption={"No elements."}
                />}
        </div>

    );
}