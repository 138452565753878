import { styled } from "@fluentui/react";
import { getStyles } from "./addFile.styles";
import { IAddFilePropsStyles, IAddFileStyles, IAddFileProps } from "./addFile.types";
import { AddFileBase } from "./addFile.base";

const AddFile = styled<IAddFileProps, IAddFilePropsStyles, IAddFileStyles>(
    AddFileBase,
    getStyles,
    undefined,
    { scope: 'AddFile' }
);

export default AddFile;