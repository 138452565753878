/*eslint-disable sonarjs/no-duplicate-string */

import { DefaultEffects, IScrollablePaneStyles, IStyleFunction } from "@fluentui/react"
import { ISearchPropsStyles, ISearchStyles } from "./search.types"

export const SearchBoxGlobalClassNames = {
  root: 'edi-search-box-root',
  filtersContent: 'edi-search-filters-content',
  resultsContent: 'edi-search-resuts-content',
  searchContainer: 'edi-search-container',
  advancedDropdown: 'edi-search-advanced-dropdown',
  advancedDropdownHidden: 'edi-search-advanced-dropdown-hidden',
  subMenuSearchContainer: 'edi-sub-menu-search-container',
  selectionFolderContainer: 'edi-selection-folder-container',
  listFolderContainer: 'edi-list-folder-container',
  tooltipHostStyle: 'edi-tooltip-host-style',
  advancedDropdownLabel: 'edi-search-advanced-dropdown-label',
  advancedDropdownContainer: 'edi-search-advanced-dropdown-container'
}

export const getStyles: IStyleFunction<ISearchPropsStyles, ISearchStyles> = (props: ISearchPropsStyles): ISearchStyles => {
  const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    customList: {
      display: 'flex',
      flexDirection: 'column',
      '.ms-List-surface': {
        overflowY: 'overlay',
        flexBasis: 'calc(100vh - 337px)',
      },
      '.ms-List-page': {
        boxShadow: DefaultEffects.elevation8,
        marginRight: 17,
        marginLeft: 17,
        marginTop: 5,
        marginBottom: 17
      }
    },
    itemHeader: {
      display: 'flex',
      paddingLeft: 15,
      paddingTop: 15,
      color: palette.black,
      background: palette.white,
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    showAllButton: {
      fontStyle: 'italic',
      color: palette.themePrimary,
      cursor: 'pointer',
      fontWeight: 'bold'
    },
    itemCell: [
      {
        padding: 10,
        minHeight: 54,
        background: palette.white,
        boxSizing: 'border-box',
        borderBottom: `1px solid ` + palette.themeSecondary,
        display: 'flex',
      },
    ],
    itemIcon: {
      fontSize: '40px',
      minWidth: 40,
      minHeight: 40
    },
    itemContent: {
      marginLeft: 15,
      overflow: 'hidden',
      flexGrow: 1
    },
    itemName: [
      {
        //display: '-webkit-box',
        //wordBreak: 'break-word',
        //'-webkit-line-clamp': '3',
        //'-webkit-box-orient': 'vertical',
        //overflow: 'hidden',
        //height: '60px',
        fontStyle: 'italic',
        fontSize: 13,
        ':not(:first-child)': {
          marginTop: '10px'
        },
        ':not(:last-child)': {
          marginBottom: '10px',
        }
      }
    ],
    errorMessage: {
      color: palette.redDark,
      fontSize: '12px'
    },
    dropDown: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    spinner: {
      width: '100%',
      height: '70%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },
    root: [
      SearchBoxGlobalClassNames.root,
      {
        height: '100%',
        minWidth: 'calc(100% - 320px)'
      }
    ],
    filtersContent: [
      SearchBoxGlobalClassNames.filtersContent,
      {
        background: palette.neutralLight,
        flexBasis: `280px`,
        overflowY: 'auto',
        height: 'auto',
        '@media(min-width: 1282px)': { // 1350 - 68
          flexBasis: "320px"
        }
      }
    ],
    containerSearchFilter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '15px',
      height: 40,
    },
    resultsContent: [
      SearchBoxGlobalClassNames.resultsContent,
      {
        overflow: 'hidden',
      }
    ],
    searchContainer: [
      SearchBoxGlobalClassNames.searchContainer,
      {
        display: 'flex',
        flexDirection: 'column'
      }
    ],
    advancedDropdown: [SearchBoxGlobalClassNames.advancedDropdown,
    {
      display: 'flex',
      justifyContent: 'space-between !important',
      alignItems: 'center !important',
      padding: '17px 17px 0px 15px',
      '>.edi-set-properties-dropdownItemMultiselect': {
        width: '30% !important'
      }
    }
    ],
    advancedDropdownHidden: [
      SearchBoxGlobalClassNames.advancedDropdownHidden,
      {
        display: 'flex',
        justifyContent: 'flex-end !important',
        alignItems: 'center !important',
        padding: '17px 17px 0px 15px',
        '>.edi-set-properties-dropdownItemMultiselect': {
          width: '30% !important'
        }
      }
    ],
    subMenuSearchContainer: [
      SearchBoxGlobalClassNames.subMenuSearchContainer,
      {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      }
    ],
    selectionFolderContainer: [
      SearchBoxGlobalClassNames.selectionFolderContainer,
      {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '50%',
        paddingLeft: 17
      }
    ],
    listFolderContainer: [
      SearchBoxGlobalClassNames.listFolderContainer,
      {
        display: 'flex',
        flexDirection: 'row',
        //flexWrap: 'wrap',
        alignItems: 'center'
      }
    ],
    tooltipHostStyle: [
      SearchBoxGlobalClassNames.tooltipHostStyle,
      {
        ":global(.ms-TooltipHost)": {
          display: 'inline-grid'
        }
      }
    ],
    advancedDropdownLabel: [
      SearchBoxGlobalClassNames.advancedDropdownLabel,
      {
        marginRight: '15px',
        whiteSpace: 'nowrap'
      }
    ],
    advancedDropdownContainer: [
      SearchBoxGlobalClassNames.advancedDropdownContainer,
      {
        alignItems: 'center', 
        display: 'flex',
        width: '35%'
      }
    ],      
    subComponentStyles: {
      iconButton: {
        root: {
          height: 19
        },
        iconHovered: {
          textShadow: `0 0 1px ${palette.black}`
        },
        menuIcon: {
          display: 'none !important'
        },
        rootExpanded: {
          backgroundColor: 'inherit'
        },
        rootPressed: {
          backgroundColor: 'inherit'
        },
        rootHovered: {
          backgroundColor: 'inherit',
        }
      },
      filterSearch: {
        root: {
          paddingTop: '14px'
        },
        field: {
          fontSize: 16,
          color: palette.black,
          background: 'transparent',
          '::placeholder': {
            fontSize: 18,
            color: palette.neutralSecondary
          },
        },
        fieldGroup: {
          height: 40,
          background: 'none',
          backgroundColor: 'none'
        },
        errorMessage: {
          position: 'absolute'
        },
        wrapper: {
          height: 'unset'
        }
      },
      textFieldStyle: {
        root: {
          padding: '15px 17px'
        },
        field: {
          fontSize: 20,
          color: palette.black,
          boxShadow: DefaultEffects.elevation8,
          '::placeholder': {
            fontSize: 20
          },
        },
        fieldGroup: {
          height: 40,
          background: palette.white + ' !important'
        },
        errorMessage: {
          position: 'absolute'
        },
        wrapper: {
          height: 'unset'
        }
      },
      rangeTextFieldStyle: {
        field: {
          color: palette.black,
          background: palette.white + " !important",
          borderRadius: '0 5px 5px 0',
          boxShadow: 'rgb(136 136 136) 0px 1px 8px 0px',

        },
        fieldGroup: {
          height: 20,
          width: 110,
        },
        errorMessage: {
          position: 'absolute'
        },
        wrapper: {
          height: 'unset'
        },
        prefix: {
          backgroundColor: palette.themePrimary,
          color: palette.white,
          borderRadius: '5px 0 0 5px'
        }
      },
      checkBoxStyle: {
        root: {
          alignSelf: 'flex-end',
          paddingRight: 17
        }
      },
      scrollablePane: (): IScrollablePaneStyles => {
        return {
          root: {},
          contentContainer: {
            width: '100%',
            paddingRight: 17,
            overflowY: 'overlay'
          },
          stickyAbove: {},
          stickyBelow: {},
          stickyBelowItems: {}
        }
      }
    }
  }
}