import { styled } from "@fluentui/react";
import { getStyles } from "./formalAspectTresholds.styles";
import { IFormalAspectTresholdsPropsStyles, IFormalAspectTresholdsStyles, IFormalAspectTresholdsProps } from "./formalAspectTresholds.types";
import { FormalAspectTresholdsBase } from "./formalAspectTresholds.base";

const FormalAspectTresholds = styled<IFormalAspectTresholdsProps, IFormalAspectTresholdsPropsStyles, IFormalAspectTresholdsStyles>(
    FormalAspectTresholdsBase,
    getStyles,
    undefined,
    { scope: 'FormalAspectTresholds' }
);

export default FormalAspectTresholds;