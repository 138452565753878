import { IStyleFunction } from "@fluentui/react"
import { IDeleteAnalysisModalPropsStyles, IDeleteAnalysisModalStyles } from "./deleteAnalysisModal.types";

export const DeleteAnalysisModalGlobalClassNames = {
    root: 'edi-delete-analysis-modal-root',
    buttonContainer: 'edi-delete-analysis-modal-buttonContainer',
    footerContainer: 'edi-delete-analysis-modal-footerContainer',
    errorMessageStyle: 'edi-delete-analysis-modal-errorMessage'
}

export const getStyles: IStyleFunction<IDeleteAnalysisModalPropsStyles, IDeleteAnalysisModalStyles> = (props: IDeleteAnalysisModalPropsStyles): IDeleteAnalysisModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            DeleteAnalysisModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        buttonContainer: [
            DeleteAnalysisModalGlobalClassNames.buttonContainer,
            {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'
            }],
        footerContainer: [
            DeleteAnalysisModalGlobalClassNames.footerContainer,
            {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
            }],
        errorMessageStyle: [
            DeleteAnalysisModalGlobalClassNames.errorMessageStyle,
            {
                color: palette.redDark,
                fontSize: '12px'
            }],
        subComponentStyles: {
            dialogFooterContainer: {
                actions: {
                    margin: '8px 0 0'
                }
            },
            primaryButtonDisabled: {
                root: {
                    selectors: {
                        ':disabled': {
                            backgroundColor: palette.neutralQuaternary, // 'rgb(237, 235, 233)',
                            color: palette.neutralTertiary, //'rgb(200, 198, 196)',
                            pointerEvents: 'none'
                        },
                    }
                },
            }
        }
    };
}