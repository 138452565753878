/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable @typescript-eslint/no-explicit-any*/
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { IFileMetadataOnUploadProps, IFileMetadataOnUploadPropsStyles, IFileMetadataOnUploadStyles } from "./fileMetadataOnUpload.types";
import { DefaultButton, DetailsList, DetailsRow, FontIcon, IColumn, IconButton, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack, StackItem, classNamesFunction } from "@fluentui/react";
import { DocumentUploadMetadata, setDocumentUploadMetadata } from "../../../features/archiveContent";
import FileIconCell from "../../../../common/components/fileIconCell/fileIconCell";
import JsonForm from "../../../../common/components/tagPanel/jsonForm/jsonForm";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { useOnMount } from "../../../../utilities/hooks";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { ArchiveRoleId } from "../../../models/constants";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { FileStatus, IFileToUpload } from "../uploadFileModal/uploadFileModal.types";
import { useDocLabDispatch, useDocLabState } from '../../../docLabStore';
import { archivesApi } from '../../../services/archives/archives.api';

const getClassNames = classNamesFunction<IFileMetadataOnUploadPropsStyles, IFileMetadataOnUploadStyles>();

export const FileMetadataOnUploadBase = (props: IFileMetadataOnUploadProps) => {
    const { t } = useTranslation(['uploadFileModal', 'common']);
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    const [loading, setLoading] = useState(false);
    const [apiErrorForm, setApiErrorForm] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [jsonFormKey, setJsonFormKey] = useState<number>(0);
    const [mainPropertiesList, setMainPropertiesList] = useState<string[]>([]);
    const [isDependenciesButtonVisible, setIsDependenciesButtonVisible] = useState<boolean>(false);
    const [updateErrorListAfterDependency, setUpdateErrorListAfterDependency] = useState(false);

    const currentArchive = useCurrentArchive();
    const dispatch = useDocLabDispatch();
    const docLabState = useDocLabState();
    const readonly = useMemo(() => currentArchive?.currentUserRoleId === ArchiveRoleId.Reader, [currentArchive]);

    const [items, setItems] = useState<IFileToUpload[]>(props.files);
    const [currentDocumentId, setCurrentDocumentId] = useState('');
    const [disableSaveMetadata, setDisableSaveMetadata] = useState<boolean>(true);
    const [displayNoElements, setDisplayNoElements] = useState<boolean>(false);
    const [formDataDict, setFormDataDict] = useState<DocumentUploadMetadata[]>([]);
    const [calcHeight, setCalcHeight] = useState('');

    useOnMount(() => {
        (async () => {
            await getAdditionalDataForDependencies();
        })();

        if(props.files[0])
            setCurrentDocumentId(props.files[0].id);

        const completeDocumentMetadataArray = docLabState.archiveContent.documentUploadMetadata;
        setFormDataDict(completeDocumentMetadataArray);

        //the first time the fileStatus are undefined
        items.forEach(i => {
            const found = completeDocumentMetadataArray.some(c => c.documentId === i.id);
            if (i.fileStatus === undefined || !found)
                i.fileStatus = FileStatus.FileMetadataRequired
        });
    });

    const getAdditionalDataForDependencies = async () => {
        if (!currentArchive?.id)
            return;
        try {
            setLoading(true);
            const mainPropResult = await archivesApi.getMainPropertiesList(currentArchive.id);
            setMainPropertiesList(mainPropResult);
        }
        finally {
            setLoading(false);
        }
    }

    const formDataSetDependencies = (formDataJson: any) => {
        if (formDataJson !== null && formDataJson !== undefined && formDataJson !== '' && formDataJson !== {} as any && mainPropertiesList.length !== 0) {
            const formDataKey = Object.keys(formDataJson);
            if (formDataKey.some(x => mainPropertiesList.includes(x)))
                setIsDependenciesButtonVisible(true);
            else
                setIsDependenciesButtonVisible(false);
        }
        else
            setIsDependenciesButtonVisible(false);
    }

    const updateDependencies = async () => {
        const documentInfo = formDataDict.find(f => f.documentId === currentDocumentId);
        let newFormData = documentInfo?.documentMetadata;
        try {
            setLoading(true);
            newFormData = await archivesApi.updateFormDataDependencies(currentArchive?.id ?? 0, newFormData);
            newFormData = newFormData.formDataMergeDocument;
        }
        finally {
            setLoading(false);
        }

        addToFormDataDict(currentDocumentId, newFormData, documentInfo?.documentErrorMetadata);
        setJsonFormKey(jsonFormKey + 1);
        setUpdateErrorListAfterDependency(true);
    }

    useEffect(() => {
        //used to update the metadata error list on change of formData after an updateDependency
        setJsonFormKey(jsonFormKey + 1);
    }, [updateErrorListAfterDependency]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        formDataSetDependencies(formDataDict.find(f => f.documentId === currentDocumentId)?.documentMetadata);
    }, [mainPropertiesList, currentDocumentId, formDataDict]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const div1 = document.getElementById('divUpdateDependencies');
        const div2 = document.getElementById('divSaveMetadata');
    
        if (div1 && div2) {
            const calcNewHeight = `calc(100% - ${div1.clientHeight + div2.clientHeight}px)`;
            setCalcHeight(calcNewHeight);
        }
    }, [isDependenciesButtonVisible, currentDocumentId]);

    const addToFormDataDict = (key: string, value: any, error: any) => {
        if (formDataDict.some(f => f.documentId === key)) {
            const result = formDataDict.map((f) => f.documentId === key ? { ...f, documentMetadata: value, documentErrorMetadata: error } : f);
            setFormDataDict(result);
        }
        else {
            setFormDataDict([...formDataDict, { documentId: key, documentMetadata: value, documentErrorMetadata: error }]);
        }
    }

    useEffect(() => {
        const documentInfo = formDataDict.find(d => d.documentId === currentDocumentId);
        (!documentInfo?.documentErrorMetadata || (documentInfo.documentErrorMetadata && documentInfo.documentErrorMetadata.length > 0) || !documentInfo.documentMetadata)
            ? setDisableSaveMetadata(true)
            : setDisableSaveMetadata(false);
    }, [formDataDict, currentDocumentId]);

    useEffect(() => {
            saveMetadataInStateStoreOnChange();
    }, [formDataDict]); //eslint-disable-line react-hooks/exhaustive-deps

    const getColumns = (): IColumn[] => {
        return [
            {
                key: 'column1',
                name: 'fileStatus',
                minWidth: 10,
                maxWidth: 10,
                onRender: function columnRender(item: IFileToUpload) {
                    return iconRender(item)
                }
            },
            {
                key: 'column2',
                name: 'fileType',
                minWidth: 20,
                maxWidth: 20,
                onRender: function columnRender(item: IFileToUpload) {
                    const extIndex = item.fileName.lastIndexOf('.');
                    const ext = extIndex !== -1 ? item.fileName.substring(extIndex) : "";
                    return <FileIconCell fileExtension={ext} />
                }
            },
            {
                key: 'column3',
                name: 'fileName',
                isResizable: true,
                minWidth: 170,
                maxWidth: 185,
                onRender: function columnRender(item: IFileToUpload) {
                    return (
                        <span
                            style={{ textDecoration: currentDocumentId === item.id ? 'underline' : 'none' }}
                            className={classNames.textOverflow}>
                            {item.fileName}
                        </span>
                    )
                }
            },
            {
                key: 'column4',
                name: 'deleteFile',
                minWidth: 10,
                maxWidth: 10,
                onRender: function columnRender(item: IFileToUpload) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            <IconButton
                                iconProps={{ iconName: "Cancel" }}
                                style={{ height: 18, color: palette.black }}
                                onClick={() => removeFileData(item)}
                            />
                        </div>
                    )
                }
            }
        ];
    }

    const iconRender = (item: IFileToUpload) => {
        switch (item.fileStatus) {
            case FileStatus.FileMetadataSaved:
                return <FontIcon iconName="CompletedSolid" style={{ fontSize: 16, color: palette.green }} />;
            case FileStatus.FileMetadataRequired:
                return <FontIcon iconName="AlertSolid" style={{ fontSize: 16, color: palette.red }} />;
            default:
                return null;
        }
    }

    const removeFileData = (item: IFileToUpload) => {
        setFormDataDict(formDataDict.filter(f => f.documentId !== item.id));
        const newArray = items.filter(i => i.id !== item.id);
        setItems(newArray);
        props.onFileUpdated && props.onFileUpdated(newArray);

        if(newArray.length < 1)
            setDisplayNoElements(true);

        if(newArray.length > 0)
            setCurrentDocumentId(newArray[0].id);

        //remove from the redux state the document deleted
        const completeDocumentMetadataArray = docLabState.archiveContent.documentUploadMetadata;
        if(completeDocumentMetadataArray.length > 0) {
            const newDocArray = completeDocumentMetadataArray.filter(i => i.documentId !== item.id);
            dispatch(setDocumentUploadMetadata(newDocArray));
        }
    }

    useEffect(() => {
        //used when setItems it's called and so items are changed (after a call of removeFileData)
        if(items.length > 0 && !items.some(i => i.id === currentDocumentId))
            setCurrentDocumentId(items[0].id);

        //insert in the if above and uncomment it, if after each remove of a file, you want to visualize the first file in the list
            //const detailsListElement = document.getElementById('divDetailsList');
            //if(detailsListElement)
                //detailsListElement.scrollTop = 0;
    }, [items, currentDocumentId]);

    const content = () => {
        return (
            <div>
                {loading ?
                    <Spinner size={SpinnerSize.large} />
                    :
                    jsonschemaValue.metadataJsonSchema !== undefined && jsonschemaValue.metadataJsonSchema !== null && !displayNoElements ?
                    <div>
                        {formContent}
                    </div>
                    :
                    <div style={{ height: '100%' }}>
                        <TeamsImage
                            imageName={ImageName.EmptyFolderDrop}
                            scale={0.7}
                            fullContainer
                        />
                        <div className={classNames.caption}>
                            <span className={classNames.captionRow} >
                                {t("common:noMetadata")}
                            </span>
                        </div>
                    </div>
                }
            </div>
        )
    }

    const jsonschemaValue = useMemo(() => {
        return {
            metadataJsonSchema: currentArchive?.metadataShortJsonSchema,
            metadataUiSchema: currentArchive?.metadataUISchema
        }
    }, [currentArchive]);

    const formContent = useMemo(() => {
        return (
            <div style={{ marginTop: 10 }}>
                {apiErrorForm ?
                <div className={classNames.loadingErrorContainer}>
                    {t('metadata.errorApi')}
                </div> :
                <JsonForm
                    hasErrorFE={() => setApiErrorForm(true)}
                    onChangeSearchText={(str) => setSearchText(str)}
                    searchText={searchText}
                    disabled={readonly}
                    onError={(errors) => console.log("ERRORI: ", errors)}
                    onSubmit={() => console.log("submit")}
                    formData={formDataDict?.some(x => x.documentId === currentDocumentId) ? formDataDict?.find(x => x.documentId === currentDocumentId)?.documentMetadata : {}}
                    schema={{ ...jsonschemaValue?.metadataJsonSchema }}
                    uiSchema={jsonschemaValue?.metadataUiSchema}
                    onChange={(ev) => {
                        addToFormDataDict(currentDocumentId, { ...ev.formData }, [...ev.errors]);
                    }}
                    errorsOnChangeFormData={(errors) => {
                        if (updateErrorListAfterDependency) { 
                            const currentDocument = formDataDict.find(f => f.documentId === currentDocumentId);
                            addToFormDataDict(currentDocumentId, currentDocument?.documentMetadata, errors);
                        }
                        setUpdateErrorListAfterDependency(false);
                    }}
                />}
            </div>
        );
    }, [currentArchive, apiErrorForm, classNames.loadingErrorContainer, t, readonly, searchText, currentDocumentId, jsonFormKey]); //eslint-disable-line react-hooks/exhaustive-deps

    const updateFileStatus = () => {
        const itemToUpdate = items.find(i => i.id === currentDocumentId);
        if(itemToUpdate) {
            itemToUpdate.fileStatus = FileStatus.FileMetadataSaved;
            iconRender(itemToUpdate);
        }
    }

    const saveMetadataInStateStore = () => {
        updateFileStatus();
        const elementToSave = formDataDict.find(f => f.documentId === currentDocumentId);
        const completeDocumentMetadataArray = docLabState.archiveContent.documentUploadMetadata;

        if (elementToSave?.documentErrorMetadata.length === 0) {
            if(completeDocumentMetadataArray !== undefined && completeDocumentMetadataArray.length > 0 && elementToSave) {
                const indexToUpdate = completeDocumentMetadataArray.findIndex(item => item.documentId === currentDocumentId);

                if (indexToUpdate !== -1) {
                    const updatedArray = [...completeDocumentMetadataArray];
                    updatedArray[indexToUpdate] = elementToSave;
                    dispatch(setDocumentUploadMetadata(updatedArray));
                }
                else {
                    dispatch(setDocumentUploadMetadata([...completeDocumentMetadataArray, elementToSave]));
                }
            }
            else
                dispatch(setDocumentUploadMetadata([...formDataDict.filter(f => f.documentId === currentDocumentId)]));
        }
    }

    const saveMetadataInStateStoreOnChange = () => {
        const elementToSave = formDataDict.find(f => f.documentId === currentDocumentId);
        if(elementToSave === undefined || elementToSave === null)
            return

        const completeDocumentMetadataArray = docLabState.archiveContent.documentUploadMetadata;
        const itemToUpdate = items.find(i => i.id === currentDocumentId);

        if (elementToSave?.documentErrorMetadata.length !== 0 && itemToUpdate) {
            itemToUpdate.fileStatus = FileStatus.FileMetadataRequired;
            iconRender(itemToUpdate);
        }

        if(completeDocumentMetadataArray !== undefined && completeDocumentMetadataArray.length > 0) {
            const indexToUpdate = completeDocumentMetadataArray.findIndex(item => item.documentId === currentDocumentId);

            if (indexToUpdate !== -1) {
                const updatedArray = [...completeDocumentMetadataArray];
                updatedArray[indexToUpdate] = elementToSave;
                dispatch(setDocumentUploadMetadata(updatedArray));
            }
            else {
                dispatch(setDocumentUploadMetadata([...completeDocumentMetadataArray, elementToSave]));
            }
        }
        else{
            dispatch(setDocumentUploadMetadata([...formDataDict.filter(f => f.documentId === currentDocumentId)]));
        }
    }

    return (
        !displayNoElements ?
        <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { width: '100%', height: '100%', overflowY: 'hidden', overflowX: 'hidden' } }}>
            <StackItem styles={{ root: { width: '50%', height: '100%', overflowY: 'scroll', overflowX: 'hidden' } }} id='divDetailsList'>
                <DetailsList
                    items={items}
                    columns={getColumns()}
                    setKey="listOfFiles"
                    isHeaderVisible={false}
                    getKey={(item: IFileToUpload) => item.id}
                    selectionMode={SelectionMode.none}
                    onShouldVirtualize={() => false}
                    onRenderRow={props => props ?
                        <div
                            style={{ cursor: 'pointer', backgroundColor: currentDocumentId === props.item.id ? palette.neutralTertiary : palette.neutralLighter }}
                            onClick={() => setCurrentDocumentId(props.item.id)}>
                            <DetailsRow
                                {...props}
                                styles={currentDocumentId === props.item.id ?
                                    classNames.subComponentStyles.detailsRowClicked :
                                    classNames.subComponentStyles.detailsRow
                                }
                            />
                        </div> : null
                    }
                />
            </StackItem>
            <StackItem styles={{ root: { width: '50%', height: '100%', overflowY: 'hidden', overflowX: 'hidden' } }}>
                <StackItem className={classNames.updateDependenciesContainer} id='divUpdateDependencies'>
                    {isDependenciesButtonVisible && !displayNoElements &&
                        <PrimaryButton
                            text={t('updateDependencies')}
                            onClick={updateDependencies}
                        />
                    }
                </StackItem>
                <StackItem styles={{ root: { height: calcHeight, overflowY: 'scroll', overflowX: 'hidden' } }}>
                    {content()}
                </StackItem>
                <StackItem className={classNames.saveMetadataContainer} id='divSaveMetadata'>
                    {!displayNoElements && <DefaultButton
                        disabled={disableSaveMetadata}
                        onClick={saveMetadataInStateStore}
                        text={t('saveMetadata')}
                        className={disableSaveMetadata ? classNames.saveMetadataButtonOff : classNames.saveMetadataButtonOn}
                        style={{ border: 0, boxShadow: 'none', textDecoration: 'underline', padding: 0 }}
                    />}
                </StackItem>
            </StackItem>
        </Stack>
        :
        <Stack styles={{ root: { height: '90%' } }}>
            <TeamsImage
                imageName={ImageName.EmptyFolderDrop}
                scale={1}
                fullContainer
            />
            <Stack className={classNames.caption}>
                <span className={classNames.captionRow} >
                    {t("noFilesInTheList")}
                </span>
            </Stack>
        </Stack>
    );
}