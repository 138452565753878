/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useState } from "react";
import { DefaultButton, IconButton, PrimaryButton, SearchBox, Spinner, SpinnerSize, StackItem, TextField, classNamesFunction } from "@fluentui/react";
import { IEditTopicsOfInterestModalBaseProps, IEditTopicsOfInterestModalBasePropsStyles, IEditTopicsOfInterestModalBaseStyles } from "./editTopicsOfInterestModal.types";
import EdiModal from "../../../../../common/components/ediModal/ediModal";
import { useTranslation } from "react-i18next";
import { useOnMount } from "../../../../../utilities/hooks";
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive";
import { archivesApi } from "../../../../services/archives/archives.api";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import Accordion from "../../../../../common/components/accordion/accordion";
import { TopicShort } from "../topicModel/topic";

const getClassNames = classNamesFunction<IEditTopicsOfInterestModalBasePropsStyles, IEditTopicsOfInterestModalBaseStyles>();

export const EditTopicsOfInterestModalBase = (props: IEditTopicsOfInterestModalBaseProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['topicOfInterest']);
    const currentArchive = useCurrentArchive();
    const [loading, setLoading] = useState<boolean>(false);
    const [topics, setTopics] = useState<TopicShort[]>([]);
    const [searchTopics, setSearchTopics] = useState<TopicShort[]>([]);
    const [associatedTopics, setAssociatedTopics] = useState<TopicShort[]>([]);
    const [nonAssociatedTopics, setNonAssociatedTopics] = useState<TopicShort[]>([]);
    const [modifiedTopics, setModifiedTopics] = useState<TopicShort[]>([]);
    const [modifiedTopicIds, setModifiedTopicIds] = useState<number[]>([]);
    const [continueEnable, setContinueEnable] = useState(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);

    useOnMount(() => {
        getTopics();
    })

    useEffect(() => { 
        setAssociatedTopics(props.topics);
        const nonAssociated = topics.filter((topic) => {
            return !props.topics.some((associatedTopic) => associatedTopic.topicId === topic.topicId);
        });
        setNonAssociatedTopics(nonAssociated);
    }, [props.topics, topics]) 

    useEffect(() => { 
        props.setTopicIdsToUpdate(modifiedTopicIds);
        // eslint-disable-next-line
    }, [modifiedTopicIds]) 

    const getTopics = async (value?: any) => { //eslint-disable-line @typescript-eslint/no-explicit-any 
        if(currentArchive?.id === undefined || currentArchive?.id === null)
            return;

        setLoading(true);

        try{
            const result = await archivesApi.getTopicsNonOwner(currentArchive?.id, value ? value : null);

            if (value)
                setSearchTopics(result);
            else if (!value || value === undefined || !isSearching){
                setTopics(result);
                setSearchTopics([]);
            }
        }
        finally{
            setLoading(false);
        }
    }

    const renderAccordions = (topics: TopicShort[]) => {
        const sortedTopics = topics.slice().sort((a, b) => a.topicName.localeCompare(b.topicName));
        return sortedTopics.map((topic, i) => (
          <Accordion 
            title={`${topic.topicName}`} 
            key={topic.topicId}
            customHeader={actionForTopic(topic)} 
            styles={{
                title: {
                    overflow: 'auto',
                    overflowX: 'hidden'
                },
            }}
          >  
          <>
            {topic.topicDescription && 
                <div className={classNames.accordionDetailContainer}> 
                    <TextField 
                        defaultValue={topic.topicDescription}
                        disabled={true}
                        multiline
                        autoAdjustHeight
                        styles={ classNames.subComponentStyles.textField }
                    /> 
                </div>   
            }
            </>
          </Accordion>
        ));
      };

    const actionForTopic = (topic: TopicShort) => {
        const isAssociatedTopic = associatedTopics.some(t => t.topicId === topic.topicId);
        const isNonAssociatedTopic = nonAssociatedTopics.some(t => t.topicId === topic.topicId);
        if (isAssociatedTopic){
            return <div style={{marginLeft: 'auto'}}>
                <IconButton 
                    iconProps={{ iconName: "Cancel" }} 
                    style={{ width: '30px', height: '30px', fontSize: '20px' }}  
                    onClick={(e) => {
                        removeTopic(topic);
                        e.stopPropagation(); 
                    }}
                />
            </div>
        }
        else if (isNonAssociatedTopic){
            return <div style={{marginLeft: 'auto'}}>
                <IconButton 
                    iconProps={{ iconName: "Add" }} 
                    style={{ width: '30px', height: '30px', fontSize: '20px' }}  
                    onClick={(e) => {
                        addTopic(topic);
                        e.stopPropagation();
                    }}
                />
            </div>
        }
    }

    const removeTopic = async (topicToDelete: TopicShort) => {

        setContinueEnable(true);

        if(modifiedTopics.some(topic => topic.topicId === topicToDelete.topicId)){
            const filteredModifiedTopic = modifiedTopics.filter(topic => topic.topicId !== topicToDelete.topicId);
            setModifiedTopics(filteredModifiedTopic);

            const filteredModifiedTopicId = modifiedTopicIds.filter(topicId => topicId !== topicToDelete.topicId);
            setModifiedTopicIds(filteredModifiedTopicId);

        }
        else{
            setModifiedTopics(prevModifiedTopics => [...prevModifiedTopics, topicToDelete]);
            setModifiedTopicIds(prevModifiedTopicIds => [...prevModifiedTopicIds, topicToDelete.topicId]);
        }

        const updatedAssociatedTopic  = associatedTopics.filter(topic => topic.topicId !== topicToDelete.topicId);
        setAssociatedTopics(updatedAssociatedTopic);

        if(nonAssociatedTopics.some(topic => topic.topicId === topicToDelete.topicId))
            return;
        else
            setNonAssociatedTopics(prevNonAssociatedTopics => [...prevNonAssociatedTopics, topicToDelete]);
    }

    const addTopic = async (topicToAdd: TopicShort) => {

        setContinueEnable(true);

        if(modifiedTopics.some(topic => topic.topicId === topicToAdd.topicId)){
            const filteredModifiedTopic = modifiedTopics.filter(topic => topic.topicId !== topicToAdd.topicId);
            setModifiedTopics(filteredModifiedTopic);

            const filteredModifiedTopicId = modifiedTopicIds.filter(topicId => topicId !== topicToAdd.topicId);
            setModifiedTopicIds(filteredModifiedTopicId);
        }
        else{
            setModifiedTopics(prevModifiedTopics => [...prevModifiedTopics, topicToAdd]);
            setModifiedTopicIds(prevModifiedTopicIds => [...prevModifiedTopicIds, topicToAdd.topicId]);
        }
        
        const updatedNonAssociatedTopic  = nonAssociatedTopics.filter(topic => topic.topicId !== topicToAdd.topicId);
        setNonAssociatedTopics(updatedNonAssociatedTopic);
        
        if(associatedTopics.some(topic => topic.topicId === topicToAdd.topicId))
            return;
        else
            setAssociatedTopics(prevAssociatedTopics => [...prevAssociatedTopics, topicToAdd]);
    }

    const header = (
        <StackItem style={{ marginTop: '10px' }}>
            <span>{t('selectTopics')}</span>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <SearchBox
                    styles={{ root: { width: '100%', marginRight: '15px', marginTop: '10px' } }}
                    placeholder={t('searchTopic')}
                    onSearch={(newValue) => {
                        if(newValue !== ''){
                            setIsSearching(true);
                            getTopics(newValue);
                        }
                        else{
                            setIsSearching(false);
                            setSearchTopics([]);
                        }
                    }}
                    onClear={() => {
                        setIsSearching(false);
                        setSearchTopics([]);
                    }}
                />
            </div>
        </StackItem>
    )

    const body = (
        <>
            {loading ?  
                <div style={{ height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}>
                    <Spinner size={SpinnerSize.large} />
                </div> : isSearching ? 
                <StackItem style={{ overflowX: 'hidden' }}>
                    { renderAccordions(searchTopics) }
                </StackItem> :
                topics.length > 0 ?
                <StackItem style={{ overflowX: 'hidden' }}>
                    { renderAccordions(associatedTopics) }
                    { renderAccordions(nonAssociatedTopics) }
                </StackItem> : 
                <TeamsImage
                    imageName={ImageName.SurprisedWoman}
                    style={{
                        height: '300px',
                        width: '500px',
                        margin: '35px 0px 0px 60px'
                    }}
                    caption={t('noTopic')}
                />
            }  
        </>
    )

    const footer = (<div>
        <DefaultButton 
            onClick={() => {
                setAssociatedTopics([]);
                setNonAssociatedTopics([]);
                setSearchTopics([]);
                setIsSearching(false);
                setModifiedTopics([]);
                setModifiedTopicIds([]);
                setContinueEnable(false);
                getTopics();
                props.onClose();
            }} 
            style={{ margin: '0px 4px' }}
        >
            {t('leave')}
        </DefaultButton>
        <PrimaryButton
            onClick={() => {
                setIsSearching(false);
                setSearchTopics([]);
                setContinueEnable(false);
                props.onConfirm();
                props.onClose();
                setModifiedTopics([]);
                setModifiedTopicIds([]);
            }}
            style={{ margin: '0px 4px' }}
            disabled={!continueEnable || modifiedTopics.length === 0}
        >
            {t('common:confirm')}
        </PrimaryButton>
    </div>)

    return (
        <EdiModal 
            title={t('updateTopicsOfInterest')}
            headerCustom={header}
            body={body}
            isOpen={props.isOpen}
            height={650}
            width={700}
            footer={footer}
            onCloseClick={() => {
                setAssociatedTopics([]);
                setNonAssociatedTopics([]);
                setSearchTopics([]);
                setIsSearching(false);
                setModifiedTopics([]);
                setModifiedTopicIds([]);
                setContinueEnable(false);
                getTopics();
                props.onClose();
            }}
            showCloseIcon={true}
        />
    )   
}