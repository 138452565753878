import { IStyleFunction } from "@fluentui/react"
import { ICopyLinkModalPropsStyles, ICopyLinkModalStyles } from "./copyLinkModal.types"

export const CopyLinkModalGlobalClassNames = {
    root: 'edi-copy-link-modal-root',
    iconStyle: 'edi-copy-link-modal-iconStyle',
    shimmer: 'edi-copy-link-modal-shimmer'
}

export const getStyles: IStyleFunction<ICopyLinkModalPropsStyles, ICopyLinkModalStyles> = (props: ICopyLinkModalPropsStyles): ICopyLinkModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            CopyLinkModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        iconStyle:[CopyLinkModalGlobalClassNames.iconStyle,{
            color: palette.green
        }],
        shimmer: [CopyLinkModalGlobalClassNames.shimmer, {
            height: 33,
            '>.ms-Shimmer-shimmerWrapper': {
                height: '100%'
            }
        }],
        subComponentStyles: {
            textField: {
                suffix: {
                    width: '14px'
                },
                fieldGroup: {
                    selectors: {
                        '::after': {
                            borderColor: props.copied ? palette.green : undefined
                        }
                    }
                }
            }
        }
    };
}