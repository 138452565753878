import { styled } from "@fluentui/react";
import { getStyles } from "./knowledgeSharepointFileSelector.styles";
import { IKnowledgeSharepointFileSelectorPropsStyles, IKnowledgeSharepointFileSelectorStyles, IKnowledgeSharepointFileSelectorProps } from "./knowledgeSharepointFileSelector.types";
import { KnowledgeSharepointFileSelectorBase } from "./knowledgeSharepointFileSelector.base";

const KnowledgeSharepointFileSelector = styled<IKnowledgeSharepointFileSelectorProps, IKnowledgeSharepointFileSelectorPropsStyles, IKnowledgeSharepointFileSelectorStyles>(
    KnowledgeSharepointFileSelectorBase,
    getStyles,
    undefined,
    { scope: 'KnowledgeSharepointFileSelector' }
);

export default KnowledgeSharepointFileSelector;