import { styled } from "@fluentui/react";
import { getStyles } from "./activityExpired.styles";
import { IActivityExpiredPropsStyles, IActivityExpiredStyles, IActivityExpiredProps } from "./activityExpired.types";
import { ActivityExpiredBase } from "./activityExpired.base";

const ActivityExpired = styled<IActivityExpiredProps, IActivityExpiredPropsStyles, IActivityExpiredStyles>(
    ActivityExpiredBase,
    getStyles,
    undefined,
    { scope: 'ActivityExpired' }
);

export default ActivityExpired;