import { IStyleFunction } from "@fluentui/react"
import { IPortfolioMembersModalPropsStyles, IPortfolioMembersModalStyles } from "./portfolioMembersModal.types"

export const PortfolioMembersModalGlobalClassNames = {
    root: 'edi-portfolio-members-modal-root'
}

export const getStyles: IStyleFunction<IPortfolioMembersModalPropsStyles, IPortfolioMembersModalStyles> = (props: IPortfolioMembersModalPropsStyles): IPortfolioMembersModalStyles => {
    return {
        root: [
            PortfolioMembersModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}