import React from "react";
import { ActionButton, classNamesFunction, Icon } from "@fluentui/react";
import { INavigationContainerPropsStyles, INavigationContainerStyles, INavigationContainerProps } from "./navigationContainer.types";
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import TeamsImage from "../teamsImage/teamsImage";
import { ImageName } from "../teamsImage/teamsImage.types";
// import { activitiesApi } from "../../../activityLab/services/activities/activities.api";
// import { useTranslation } from "react-i18next";

const getClassNames = classNamesFunction<INavigationContainerPropsStyles, INavigationContainerStyles>();

export const NavigationContainerBase = (props: INavigationContainerProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    // const { t } = useTranslation(['portfoliosList', 'common']);

    const footer = (
        <div className={classNames.footerContainer}>
            <ActionButton
                className={classNames.createArchiveButton}
                iconProps={{ iconName: props.iconName }}
                onClick={props.onClickFooterButton}
            >
                <Icon
                    className={classNames.createArchivePlusIcon}
                    iconName="CircleAdditionSolid"
                />
                {props.createResourceText}
            </ActionButton>
            {/* {props.createResourceText === t('createNew') ? <div>
                <IconButton
                    styles={classNames.subComponentStyles.reminderIconButton()}
                    iconProps={{ iconName: "MailReminder" }}
                    onClick={() => activitiesApi.CheckExpiringActivitiesOrPhase()}
                /></div> : ''} */}
        </div>
    )
    return (
        <>
            <div className={classNames.navigationContainer} style={!props.canUserCreateResource ? { height: 'calc(100% - 42px)' } : {}} >
                {props.isLoading && (
                    <div className={classNames.spinner}>
                        <TeamsSpinner />
                    </div>
                )}
                {props.isError && <TeamsImage imageName={ImageName.Oops1} scale={0.5} fullContainer />}
                {!props.isLoading && !props.isError && (
                    <>
                        {props.children}
                    </>
                )}
            </div>
            {(props.canUserCreateResource && props.showFooter !== false) && footer}
        </>
    );
}