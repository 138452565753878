/*eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useState } from "react";
import { classNamesFunction, DefaultButton, Dropdown, Icon, IDropdownOption, Label, PrimaryButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { ICreateTopicModalProps, ICreateTopicModalPropsStyles, ICreateTopicModalStyles } from "./createTopicModal.types";
import MultiStepModal from "../../../../../../common/components/multiStepModal/multiStepModal";
import { useCurrentArchive } from "../../../../../hooks/useCurrentArchive";
import { archivesApi } from "../../../../../services/archives/archives.api";
import { IFolder } from "../../../../../models/folder";
import { useOnMount } from "../../../../../../utilities/hooks";
import FolderTreeViewer from "../../../../../../common/components/folderTreeViewer/folderTreeViewer";
import { IManagerFolder } from "../../../../../../common/components/folderTreeViewer/folderManager/IManagerFolder";
import { Folder } from "../../../profileManagement/profileView/createProfileModal/createProfileModal.types";
import { Helpers } from "../../../../../../utilities/helpers";
import { JsonSchemaField } from "../../../../../models/archive";

const getClassNames = classNamesFunction<ICreateTopicModalPropsStyles, ICreateTopicModalStyles>();

enum TopicSteps {
    props = 0,
    folder = 1,
    metadata = 2
  }

export const CreateTopicModalBase = (props: ICreateTopicModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['topicOfInterest', 'common']);
    const currentArchive = useCurrentArchive();
    const [currentStep, setCurrentStep] = useState(TopicSteps.props);
    const [topicName, setTopicName] = useState<string>();
    const [metadataKey, setMetadataKey] = useState<string>();
    const [metadataValues, setMetadataValues] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [disablePropsSave, setDisablePropsSave] = useState<boolean>(true);
    const [disableFolderSave, setDisableFolderSave] = useState<boolean>(true);
    const [disableMetadataSave, setDisableMetadataSave] = useState<boolean>(true);
    const [keyPhrasesValues, setKeyPhrasesValues] = useState<string>();
    const [isLoadingFolder, setIsLoadingFolder] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [folders, setFolders] = useState<Folder[]>([]);
    const [selectedFolders, setSelectedFolders] = useState<IManagerFolder[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isNameValid, setIsNameValid] = useState<boolean>(false);
    const [validationIcon, setValidationIcon] = useState('Error');
    const [metadataList, setMetadataList] = useState<IDropdownOption[]>([]);

    useOnMount(() => {
        getFolders().then(() => {
            setIsLoadingFolder(false);
        })
        setTopicValue();
        retriveMetadata();
    })

    useEffect(() => {
        if((topicName !== undefined && topicName.trim() !== '') && isNameValid)
            setDisablePropsSave(false);
        else
            setDisablePropsSave(true);
    }, [topicName, isNameValid]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(selectedFolders.length !== 0)
            setDisableFolderSave(false);
        else
            setDisableFolderSave(true);
    }, [selectedFolders]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(metadataKey !== undefined && metadataKey.trim() !== '' && metadataValues !== undefined && metadataValues.trim() !== '')
            setDisableMetadataSave(false);
        else
            setDisableMetadataSave(true);
    }, [metadataKey, metadataValues]); //eslint-disable-line react-hooks/exhaustive-deps

    const convertToManagerFolders = (folders: IFolder[]) => {
        return folders.map(f => {
            const tf = new Folder();
            tf.id = f.id;
            tf.name = f.name;
            tf.parentId = f.parentId;
            tf.createdBy = f.createdBy;
            tf.createdOn = f.createdOn;
            tf.depth = f.depth;
            tf.fullPathName = f.fullPathName;
            return tf;
        });
    }

    const closeModal = () => {
        if(props.onClose)
            props.onClose()
    }

    const retriveMetadata = () => {
        if (!currentArchive)
            return;
        const list: IDropdownOption[] = [];
        const keysMetadata = currentArchive.metadataJsonSchemaFields;
        keysMetadata.forEach((field: JsonSchemaField) => {
            list.push({
                key: field.name,
                text: field.title
            })
        });
        setMetadataList([...list]);
    }

    const setTopicValue = () => {
        if(!props.topic)
            return
        setTopicName(props.topic?.name);
        setDescription(props.topic?.description);
        setIsNameValid(true);

        const topicMetadataKey = Object.keys(props.topic?.metadata)[0];
        const valuesArray = props.topic?.metadata[topicMetadataKey];
        const valuesString = valuesArray.length > 1 ? valuesArray.slice(0).join(', ') : valuesArray[0]

        setMetadataKey(topicMetadataKey);
        setMetadataValues(valuesString);
        setKeyPhrasesValues(props.topic?.keyPhrases);
    }

    const validateProfileName = useCallback(async (value: string) => {
        if (!value && !topicName)
            return `${t('common:fieldRequired')}`;  //eslint-disable-line sonarjs/no-duplicate-string

            setValidationIcon('ProgressRingDots');

            try {
                const [isValid] = await Promise.all([archivesApi.isTopicNameAvailable(props.archiveId, value), Helpers.delay(500)]);
                if(isValid || value === props.topic?.name){
                    setValidationIcon('Accept');
                    setIsNameValid(true);
                    return ""
                }
                else{
                    setValidationIcon('StatusCircleErrorX');
                    setIsNameValid(false);
                    return t('common:nameNotAvailable')
                }
            }
            catch {
                return t('common:genericErrorCheckName');
            }
    }, [t]); //eslint-disable-line react-hooks/exhaustive-deps

    const validateField = useCallback(async (value: string) => {
        if (!value)
            return `${t('common:fieldRequired')}`;

        return ''
    }, [t]); //eslint-disable-line react-hooks/exhaustive-deps

    const modalBodySetupParameters = () => {
        return <>
            {isLoadingFolder && <Spinner style={{ height: 'inherit' }} size={SpinnerSize.large} /> }
            {!isLoadingFolder &&
                <>
                    <Label>{t('newTopic.nameLabel')}</Label>
                    <TextField
                        placeholder={`${t('newTopic.insertTopicName')}`}
                        onChange={(_, newValue) => setTopicName(newValue) }
                        defaultValue={topicName}
                        onGetErrorMessage={validateProfileName}
                        onRenderSuffix={() => <Icon iconName={validationIcon} />}
                    />

                    <Label>{t('newTopic.descriptionLabel')}</Label>
                    <TextField
                        multiline
                        autoAdjustHeight
                        onChange={((_, newValue) => setDescription(newValue))}
                        defaultValue={description}
                    />
                </>
            }
        </>
    }

    const getFolders = async () => {
        setIsLoadingFolder(true);
        let archiveFolders : Folder[] = [];

        await archivesApi.getAllFolders(props.archiveId).then((result) => {
            archiveFolders = convertToManagerFolders(result);
        });
        if(props.topic){
            const fs: Folder[] = [];
            archiveFolders.forEach(f => {

                if(props.topic?.topicFolders.find(idF => idF.folderId === f.id)){
                    f.selected = true;
                }
                
                fs.push(f);
            });
            const documentInFolderSelected = fs.filter(f => f.selected === true);
            const selected = fs.filter(f => documentInFolderSelected.some(d => d.parentId === f.id));
            setSelectedFolders(selected);
            setFolders(fs);
        }
        else
            setFolders(archiveFolders);
    }

    const onChangeSelectedFolders = (changedFolders: IManagerFolder[]) => {
        changedFolders
            .forEach(f => {
                const container = changedFolders.find(i => i.id === f.id && f.selected);
                if(container !== undefined)
                    container.selected = true;
            });

        const selected = changedFolders.filter(f => (f.documentOfId === undefined || f.documentOfId === null) && f.selected);
        setSelectedFolders(selected);
    }

    const folderBody = () => {
        return <>
            {folders &&
                <div style={{ height: '100%'}}>
                        <FolderTreeViewer
                            key={'folder-tree-viewer'}
                            folders={folders}
                            onChange={onChangeSelectedFolders}
                            expandSelectedFolder={true}
                            singleFolderSelection={true}
                            showRootFolder={true}
                        />
                </div>
            }
          </>
    }

    const metadataBody = () => {
        return <>
            {loading ? <Spinner style={{ height: 'inherit' }} size={SpinnerSize.large} />
            :
            <>
            <div className={classNames.metadataContainer}>
                <div style={{width: '49%'}}>
                    <Label>{t('newTopic.metadataLabel')}</Label>
                    <Dropdown
                        options={metadataList}
                        onChange={(_, option) => {
                            setMetadataKey(option?.key as string)
                        }}
                        defaultSelectedKey={metadataKey}
                        required={metadataKey ? false : true}
                        errorMessage={metadataKey ? '' : t('common:fieldRequired')}
                    />
                </div>
                <div style={{width: '49%'}}>
                    <Label>{t('newTopic.metadataValues')}</Label>
                    <TextField
                        onChange={((_, newValue) => setMetadataValues(newValue))}
                        defaultValue={metadataValues}
                        onGetErrorMessage={validateField}
                    />
                </div>
            </div>
            <div className={classNames.keyPhraseContainer}>
                <div style={{width: '49%'}}>
                    <TextField
                        disabled={true}
                        defaultValue={t('newTopic.KeyPhraseLabel')}
                    />
                </div>
                <div style={{width: '49%'}}>
                    <Label>{t('newTopic.metadataValues')}</Label>
                    <TextField
                        onChange={((_, newValue) => setKeyPhrasesValues(newValue))}
                        defaultValue={keyPhrasesValues}
                    />
                </div>
            </div>
            </>
            }
        </>
    }

    const saveTopic = async () => {
        setLoading(true);

        try{
            const foldersId = selectedFolders.map(i => i.id);

            const topic = {
                id : props.topic ? props.topic.id : null,
                archiveId: currentArchive?.id,
                name: topicName,
                description: description,
                folderIds: foldersId,
                metadataKey: metadataKey,
                metadataValues: metadataValues,
                keyPhrases: keyPhrasesValues
            }

            if(props.topic){
                await archivesApi.updateTopic(topic);
            }
            else
                await archivesApi.createTopic(topic);

        }catch{
            setErrorMessage(t('common:genericErrorApi'))
        }
        finally{
            if(props.onSave)
                props.onSave();
            setLoading(false);
            closeModal();
        }
    }

    const modalFooter = () => {
        return (
            <div>
                {(currentStep === TopicSteps.props) &&
                    (
                        <>
                            <DefaultButton className={classNames.footerButtons} onClick={closeModal}>{t('newTopic.cancel')}</DefaultButton>
                            <PrimaryButton className={classNames.footerButtons} disabled={disablePropsSave} onClick={() => { setCurrentStep(TopicSteps.folder)}}>{t('newTopic.next')}</PrimaryButton>
                        </>
                    )
                }
                {(currentStep === TopicSteps.folder) &&
                    (
                        <>
                            <DefaultButton className={classNames.footerButtons} onClick={() => setCurrentStep(TopicSteps.props)}>{t('newTopic.back')}</DefaultButton>
                            <PrimaryButton className={classNames.footerButtons} disabled={disableFolderSave} onClick={() => {setCurrentStep(TopicSteps.metadata)}}>{t('newTopic.next')}</PrimaryButton>
                        </>
                    )
                }
                {(currentStep === TopicSteps.metadata) &&
                    (
                        <>
                            {errorMessage !== '' && <span className={classNames.errorMessage}>{errorMessage}</span>}
                            <DefaultButton className={classNames.footerButtons} disabled={loading} onClick={() => setCurrentStep(TopicSteps.folder)}>{t('newTopic.back')}</DefaultButton>
                            <PrimaryButton className={classNames.footerButtons} disabled={disableMetadataSave || loading} onClick={() => saveTopic()}>{t('newTopic.save')}</PrimaryButton>
                        </>
                    )
                }
            </div>
            )
    }

    const steps = [
        {
            title: props.topic ? t('newTopic.updateTitle') : t('newTopic.title'), //eslint-disable-line sonarjs/no-duplicate-string
            body: modalBodySetupParameters(),
            footer: modalFooter()
        },
        {
            title: props.topic ? t('newTopic.updateTitle') : t('newTopic.title'), //eslint-disable-line sonarjs/no-duplicate-string
            body: folderBody(),
            footer: modalFooter()
        },
        {
            title: props.topic ? t('newTopic.updateTitle') : t('newTopic.title'), //eslint-disable-line sonarjs/no-duplicate-string
            body: metadataBody(),
            footer: modalFooter()
        }
      ];

    return (
        <>
            <MultiStepModal
                subtitle={currentStep === TopicSteps.metadata ? t('newTopic.subtitleMetadata') : t('newTopic.subtitle')}
                isOpen={props.isOpen}
                showCloseIcon={loading ? false : true}
                onCloseClick={closeModal}
                steps={steps}
                activeStep={currentStep}
                height={700}
                width={650}
                animateInitialStep
            />
        </>
    )
}