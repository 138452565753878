import { classNamesFunction, ContextualMenu, Icon, IContextualMenuItem, INavLinkGroup, Nav } from "@fluentui/react";
import { nanoid } from "@reduxjs/toolkit";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import NavigationContainer from "../../../../common/components/navigationContainer/navigationContainer";
import TitleListSection from "../../../../common/components/titleListSection/titleListSection";
import currentUser from "../../../../modules/authentication/currentUser";
import { EdiUserRoleId } from "../../../../utilities/constants";
import { useOnMount } from "../../../../utilities/hooks";
import { useDocLabDispatch } from "../../../docLabStore";
import { setIsDeeplink } from "../../../features/archiveContent";
import { loadArchives, useArchiveList } from "../../../features/archiveList";
import { insertCall, setCall } from "../../../features/callNotification";
import { useNavigator } from "../../../hooks/useNavigator";
import { Archive } from "../../../models/archive";
import { Call, callsList } from "../../../models/callsApi";
import { ArchiveTypeId } from "../../../models/constants";
import { getArchiveActions } from "../../../utilities/archiveActions";
import { ArchiveTab } from "../../../utilities/routes";
import CreateArchiveModal from "../../files/createArchiveModal/createArchiveModal";
import { IArchiveListProps, IArchiveListPropsStyles, IArchiveListStyles } from "./archiveList.types";
/* eslint-disable sonarjs/cognitive-complexity */
const getClassNames = classNamesFunction<IArchiveListPropsStyles, IArchiveListStyles>();

export const ArchiveListBase = (props: IArchiveListProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const [createArchive, setCreateArchive] = useState(false);
  const [openedMenuId, setOpenedMenuId] = useState<number>();
  const { archiveIds, archives, isLoading, isError } = useArchiveList();
  const canUserCreateArchive = currentUser.getUserRoles?.includes(EdiUserRoleId.DocLabAdmin);
  const navigator = useNavigator();
  const { t } = useTranslation('archiveList');
  const dispatch = useDocLabDispatch();

  useOnMount(() => {
    const cancelTokenSource = axios.CancelToken.source();
    void dispatch(loadArchives()).then((result) => {
      const res = result.payload as {archives: Archive[], archive: Archive};
      const archives = res.archives;
      if (!archives) return;

      let archiveToBeSelected = archives.find((archive) => archive.id === navigator.currentArchiveId)?.id;

      if (!archiveToBeSelected && props.isDeepLinkDocAnaysis) {
        const firstIdArchive = archives.find(archive => archive.typeId !== ArchiveTypeId.Personal)?.id || -1;
        navigator.changeArchive(firstIdArchive, ArchiveTab.files);
        deeplinkFail();
      }

      if (!archiveToBeSelected && archives.length > 0) {
        archiveToBeSelected = archives.find(archive => archive.typeId !== ArchiveTypeId.Personal)?.id;
      }

      if (archiveToBeSelected && navigator.isDocLabRoute && !props.isDeepLinkDocAnaysis)
        navigator.changeArchive(archiveToBeSelected);
    });
    return () => cancelTokenSource.cancel();
  });

  const deeplinkFail = () => {
    const id = nanoid();
    const forbiddenLink: Call = {
      type: callsList.forbiddenLink,
      nameOperation: t('common:archiveLinkForbiddenOperation'),
    }
    const payload = { requestId: id, notification: forbiddenLink }
    dispatch(insertCall(payload));
    const failurePayload = { requestId: id, success: false, message: t('common:archiveLinkForbiddenMessage') }
    dispatch(setCall(failurePayload));
  }
  
  const mapArchive = (archive: Archive) => {
    return {
      key: archive.id.toString(),
      name: archive.name,
      url: "",
      archive: archive,
    };
  };

  const personalNavLinkGroup: INavLinkGroup = {
    links:
      archiveIds
        .map((id) => archives[id])
        .filter((archive) => archive.typeId === ArchiveTypeId.Personal)
        .map(mapArchive) ?? [],
  };

  const unitNavLinkGroup: INavLinkGroup = {
    links:
      archiveIds
        .map((id) => archives[id])
        .filter((archive) => archive.typeId === ArchiveTypeId.Unit)
        .map(mapArchive) ?? [],
  };

  const getContextualMenuItems = (archive: Archive): IContextualMenuItem[] => {
    return getArchiveActions(archive.typeId, archive.currentUserRoleId, archive).map(
      (action) => ({
        key: action.label,
        text: t(action.label),
        iconProps: { iconName: action.icon },
        onClick: () => action.onClick(archive, navigator,dispatch)
      })
    );
  };

  const changeArchive = (key: string | undefined) => {
    dispatch(setIsDeeplink(false));
    key && navigator.changeArchive(+key, ArchiveTab.files);
  }

  const renderArchiveList = (groups: INavLinkGroup[], type: ArchiveTypeId) => {
    return (
      <Nav
        styles={classNames.subComponentStyles.nav}
        groups={groups}
        onLinkClick={(_, item) => { changeArchive(item?.key) }}
        selectedKey={navigator.currentArchiveId?.toString()}
        onRenderLink={(link) => (
          <>
            <div className={classNames.renderLinkStyle}>{link?.name}</div>
            {type === ArchiveTypeId.Unit && (
              <>
              {getContextualMenuItems(link?.archive).length > 0 &&
                <Icon
                  id={`more_${link?.archive.id}`}
                  iconName="More"
                  className={classNames.moreButton}
                  styles={classNames.subComponentStyles.moreIcon}
                  style={openedMenuId === link?.archive.id ? { visibility: 'visible' } : undefined}
                  onClick={ev => {
                    navigator.currentArchiveId?.toString() === link?.key && ev.stopPropagation();
                    setOpenedMenuId(link?.archive.id);
                  }}
                />
              }
                {openedMenuId === link?.archive.id &&
                  <ContextualMenu
                    items={getContextualMenuItems(link?.archive)}
                    target={`#more_${link?.archive.id}`}
                    onDismiss={() => setOpenedMenuId(undefined)}
                  />
                }
              </>
            )}
          </>
        )}
      />
    );
  };

  return (
    <div className={classNames.root}>
      <TitleListSection
        title={t("title")}
        isReloading={isLoading}
        refreshButtonHandler={() => dispatch(loadArchives(navigator.currentArchiveId))}
      />

      <NavigationContainer
        isError={isError}
        isLoading={isLoading}
        canUserCreateResource={canUserCreateArchive}
        createResourceText={t("createNew")}
        onClickFooterButton={() => setCreateArchive(true)}
        iconName={"Archive"}
      >
        <>
          {personalNavLinkGroup.links.length > 0 &&
            renderArchiveList([personalNavLinkGroup], ArchiveTypeId.Personal)}
          {renderArchiveList([unitNavLinkGroup], ArchiveTypeId.Unit)}
        </>
      </NavigationContainer>

      {canUserCreateArchive && createArchive && <CreateArchiveModal onClose={() => setCreateArchive(false)} />}
    </div>
  );
};
