/*eslint-disable sonarjs/cognitive-complexity */
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { classNamesFunction, DetailsList, DetailsRow, Icon, IDetailsRowProps, Persona, PersonaSize, PrimaryButton, ScrollablePane, SelectionMode, Stack } from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';
import _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "../../../../common/components/accordion/accordion";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import { useDocLabDispatch } from "../../../docLabStore";
import { setToolbarActions } from "../../../features/toolbarActions";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { ArchiveRoleId } from "../../../models/constants";
import { User } from "../../../models/user";
import { archivesApi } from "../../../services/archives/archives.api";
import { mapper } from "../../../../utilities/mapper";
import { IArchiveMembersSettingsProps, IArchiveMembersSettingsPropsStyles, IArchiveMembersSettingsStyles } from "./archiveMembersSettings.types";
import { MemberRoleDropdown } from "./subComponents/memberRoleDropdown";
import { RemoveMemberButton } from "./subComponents/removeMemberButton";
import ArchiveMembersModal from "../archiveMembersModal/archiveMembers/archiveMembersModal";

const getClassNames = classNamesFunction<IArchiveMembersSettingsPropsStyles, IArchiveMembersSettingsStyles>();

const getTotalMembersCount = (members?: Record<ArchiveRoleId, User[]>) => {
    if (!members || Object.keys(members).length === 0)
        return 0;
    return _.values(members)
        .map(list => list?.length ?? 0)
        .reduce((prev, curr) => prev + curr);
}

export const ArchiveMembersSettingsBase = (props: IArchiveMembersSettingsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(["archiveMembersSettings", "common"]);
    const currentArchive = useCurrentArchive();
    const dispatch = useDocLabDispatch();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const readonly = useMemo(() => currentArchive?.currentUserRoleId !== ArchiveRoleId.Owner, [currentArchive]);

    const { execute: loadMembers, value: members, loading } =
        useAsyncApi<void, Record<ArchiveRoleId, User[]>>({
            keepResultsWhileReloading: true,
            func: async () => {
                if (!currentArchive)
                    return {} as Record<ArchiveRoleId, User[]>;

                const archiveMembers = await archivesApi.getArchiveMembers({
                    archiveId: currentArchive.id,
                    pageNumber: 0,
                    pageSize: 0
                });

                return _.groupBy(archiveMembers, member => member.roleId) as Record<ArchiveRoleId, User[]>;
            }
        });

    useOnMount(() => {
        loadMembers();
        dispatch(setToolbarActions([]));
    });

    const totalMembersCount = useMemo(() => getTotalMembersCount(members), [members]);

    const columns = useMemo(() => {
        if (!currentArchive)
            return [];

        return [
            {
                key: 'persona',
                name: t('common:name'),
                minWidth: 100,
                headerClassName: classNames.headerRow,
                onRender: function renderMemberPersona(member: User) {
                    return (
                        <Persona {...mapper.mapUserToPersona(member)} size={PersonaSize.size40} coinSize={32} />
                    );
                }
            },
            {
                key: 'role',
                name: t('common:role'),
                minWidth: 120,
                headerClassName: classNames.headerRow,
                onRender: function renderMemberRoles(member: User) {
                    return (
                        <MemberRoleDropdown
                            archiveId={currentArchive.id}
                            dropdownStyles={classNames.subComponentStyles.rolesDropdown}
                            member={member}
                            totalCount={totalMembersCount}
                            onRoleChangeCompleted={loadMembers}
                            disabled={readonly}
                        />
                    );
                }
            },
            {
                key: 'remove',
                name: '',
                minWidth: 24,
                headerClassName: classNames.headerRow,
                onRender: function renderMemberRemoveButton(member: User) {
                    return (
                        <RemoveMemberButton
                            archiveId={currentArchive.id}
                            buttonStyles={classNames.subComponentStyles.removeButton()}
                            member={member}
                            onRemoveMemberCompleted={loadMembers}
                            disabled={readonly || totalMembersCount< 3}
                        />
                    );
                }
            }
        ];
    }, [classNames, currentArchive, loadMembers, totalMembersCount, readonly, t]);

    const membersForRole = (role: ArchiveRoleId) => {
        const singleRoleMembers = members && members[role];
        if (!singleRoleMembers)
            return null;

        return (
            <Accordion title={`${ArchiveRoleId[role]} (${singleRoleMembers.length})`} opened={true} stickyHeader>
                <DetailsList
                    styles={classNames.subComponentStyles.list}
                    items={singleRoleMembers}
                    getKey={(item: User) => item.id}
                    onRenderRow={(props?: IDetailsRowProps) =>
                        props ? <DetailsRow {...props} styles={classNames.subComponentStyles.row} /> : null}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                />
            </Accordion>
        );
    }

    return (
        <>
            <Stack className={classNames.root}>
                <Stack className={classNames.title} horizontal verticalAlign={'center'}>
                    <Icon iconName={readonly ? "People" : "PlayerSettings"} className={classNames.titleIcon} />
                    <span style={{ paddingLeft: 10 }}>{readonly ? t('common:viewUsers') : t('common:manageUsers')}</span>
                </Stack>
                <Stack horizontal className={classNames.upperSection} horizontalAlign={'end'}>
                    <PrimaryButton
                        iconProps={{ iconName: 'AddFriend' }}
                        styles={{ icon: { height: 'auto' } }}
                        text={t("addMembers")}
                        onClick={showModal}
                        disabled={readonly}
                    />
                </Stack>
                <Stack.Item verticalFill className={classNames.lowerSection}>
                    {loading && totalMembersCount === 0 ?
                        <div className={classNames.loaderContainer}>
                            <TeamsSpinner />
                        </div> :
                        <ScrollablePane styles={classNames.subComponentStyles.scrollablePane}>
                            {membersForRole(ArchiveRoleId.Owner)}
                            {membersForRole(ArchiveRoleId.Architect)}
                            {membersForRole(ArchiveRoleId.Contributor)}
                            {membersForRole(ArchiveRoleId.Professional)}
                            {membersForRole(ArchiveRoleId.Uploader)}
                            {membersForRole(ArchiveRoleId.Reader)}
                        </ScrollablePane>
                    }
                </Stack.Item>
            </Stack>
            {isModalOpen && <ArchiveMembersModal
                isOpen={isModalOpen}
                operationEnded={(success: boolean) => {
                    hideModal();

                    if (success)
                        loadMembers();
                }}
            />}
        </>
    );
}