import { styled } from "@fluentui/react";
import { getStyles } from "./createFolderModal.styles";
import { ICreateFolderModalPropsStyles, ICreateFolderModalStyles, ICreateFolderModalProps } from "./createFolderModal.types";
import { CreateFolderModalBase } from "./createFolderModal.base";

const CreateFolderModal = styled<ICreateFolderModalProps, ICreateFolderModalPropsStyles, ICreateFolderModalStyles>(
    CreateFolderModalBase,
    getStyles,
    undefined,
    { scope: 'CreateFolderModal' }
);

export default CreateFolderModal;