import { styled } from "@fluentui/react";
import { InfoStatusBase } from "./infoStatus.base";
import { getStyles } from "./infoStatus.styles";
import { IInfoStatusProps, IInfoStatusPropsStyles, IInfoStatusStyles } from "./infoStatus.types";


const InfoStatus = styled<IInfoStatusProps, IInfoStatusPropsStyles, IInfoStatusStyles>(
    InfoStatusBase,
    getStyles,
    undefined,
    { scope: 'InfoStatusBase' }
);

export default InfoStatus;