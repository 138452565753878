import { styled } from "@fluentui/react";
import { getStyles } from "./deleteKnowledgeElementModal.styles";
import { IDeleteKnowledgeElementModalPropsStyles, IDeleteKnowledgeElementModalStyles, IDeleteKnowledgeElementModalProps } from "./deleteKnowledgeElementModal.types";
import { DeleteKnowledgeElementModalBase } from "./deleteKnowledgeElementModal.base";

const DeleteKnowledgeDocumentModal = styled<IDeleteKnowledgeElementModalProps, IDeleteKnowledgeElementModalPropsStyles, IDeleteKnowledgeElementModalStyles>(
    DeleteKnowledgeElementModalBase,
    getStyles,
    undefined,
    { scope: 'DeleteKnowledgeElementModal' }
);

export default DeleteKnowledgeDocumentModal;