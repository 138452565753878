import { styled } from "@fluentui/react";
import { ProfileViewBase } from "./profileView.base";
import { getStyles } from "./profileView.styles";
import { IProfileViewBaseProps, IProfileViewBasePropsStyles, IProfileViewBaseStyles } from "./profileView.types";

const ProfileView = styled<IProfileViewBaseProps, IProfileViewBasePropsStyles, IProfileViewBaseStyles>(
    ProfileViewBase, 
    getStyles,
    undefined,
    { scope: 'ProfileView'}
)

export default ProfileView;