import { FontSizes, FontWeights, IDetailsListStyleProps, IDetailsListStyles, IIconStyleProps, IIconStyles, IShimmeredDetailsListStyleProps, IShimmeredDetailsListStyles, IStyleFunction } from "@fluentui/react";
import { IRecycleBinPropsStyles, IRecycleBinStyles } from "./recycleBin.types";

export const  RecycleBinGlobalClassNames = {
    root: 'edi-recyclebin-settings-root',
    title: 'edi-recyclebin-settings-title',
    iconTitle: 'edi-recyclebin-report-icon-title',
    iconStyle: 'edi-recyclebin-settings-iconStyle',
}

export const getStyles: IStyleFunction<IRecycleBinPropsStyles, IRecycleBinStyles> = (props: IRecycleBinPropsStyles): IRecycleBinStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            RecycleBinGlobalClassNames.root,
            {
                height: '100%',
                // '> .ms-Viewport': {
                //     height: '100%'
                // },
                padding: '15px 25px 0px 25px'
            },
            props.isEmptyList && {
                '> .ms-Viewport .ms-DetailsList-contentWrapper': {
                    flexBasis: '0 !important',
                }
            }
        ],
        iconTitle: [
            RecycleBinGlobalClassNames.iconTitle,
            {
                padding: 7,
                border: '1.5px solid',
                borderRadius: '50%',
                fontSize: 26
            }
        ],
        title: [
            RecycleBinGlobalClassNames.title,
            {
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingBottom: 10
            }
        ],    
        emptyFolder: {
            height: '204px',
            paddingTop: '52px',
            paddingBottom: '174px'
        },
        load:{
            display: 'none'
        },
        subComponentStyles: {
            shimmeredDetailsList: (props: IShimmeredDetailsListStyleProps): IShimmeredDetailsListStyles => {
                return {
                    root: {
                        '::after': {
                            display: 'none'
                        },
                        position: 'relative'
                    }
                }
            },
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto'
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        // flexBasis: 'calc(100vh - 153px)',
                        flexBasis: 'calc(100vh - 236px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { 
                            overflowX: 'auto' 
                        }
                    },
                    focusZone: {},
                }
            },
            metadataRequiredIcon: (props: IIconStyleProps): IIconStyles => {
                return{
                    root:{
                        cursor:'pointer',
                        color: palette.redDark
                    }
                }
            }
        }
    };
}