import React, { useEffect, useState } from "react";
import { classNamesFunction, DefaultButton, Label, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../../../common/components/ediModal/ediModal";
import { ITopicInfoModalProps, ITopicInfoModalPropsStyles, ITopicInfoModalStyles } from "./topicInfoModals.types";

const getClassNames = classNamesFunction<ITopicInfoModalPropsStyles, ITopicInfoModalStyles>();

export const TopicInfoModalBase = (props: ITopicInfoModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['topicOfInterest', 'common']);
    const [metadataKey, setMetadataKey] = useState<string>();
    const [metadataValues, setMetadataValues] = useState<string>();

    useEffect(() => {
        setTopicValue();
    }, [props.topic]); //eslint-disable-line react-hooks/exhaustive-deps

    const setTopicValue = () => {
        if(!props.topic)
            return

        const topicMetadataKey = Object.keys(props.topic?.metadata)[0];
        const valuesArray = props.topic?.metadata[topicMetadataKey];
        const valuesString = valuesArray.length > 1 ? valuesArray.slice(0).join(', ') : valuesArray[0]

        setMetadataKey(topicMetadataKey);
        setMetadataValues(valuesString);
    }

    const body = () => {
        return <div className={classNames.bodyContainer}>
                    <div style={{width: '100%', gap: 5}}>
                        <Label>{t('newTopic.nameLabel')}</Label>
                        <TextField
                            defaultValue={props.topic.name}
                            disabled={true}
                            styles={{field: {textOverflow: 'inherit'}}}
                        />
                        <Label>{t('newTopic.descriptionLabel')}</Label>
                        <TextField 
                            multiline 
                            autoAdjustHeight
                            defaultValue={props.topic.description}
                            disabled={true}
                            styles={{field: {textOverflow: 'inherit'}}}
                        />  
                    </div>
                <div className={classNames.rowContainer} style={{alignItems: 'flex-start'}}>
                    <div style={{width: '49%'}}>
                        <Label>{t('newTopic.metadataLabel')}</Label>
                        <TextField
                            defaultValue={metadataKey}
                            disabled={true}
                            styles={{field: {textOverflow: 'inherit'}}}
                        />
                    </div>
                    <div style={{width: '49%'}}>
                        <Label>{t('newTopic.metadataValues')}</Label>
                        <TextField 
                            defaultValue={metadataValues}
                            disabled={true}
                            styles={{field: {textOverflow: 'inherit'}}}
                        />  
                    </div>
                </div>
                <div className={classNames.rowContainer} style={{alignItems: 'flex-end'}}>
                    <div style={{width: '49%'}}>
                        <TextField 
                            disabled={true}
                            defaultValue={t('newTopic.KeyPhraseLabel')}
                        />
                    </div>
                    <div style={{width: '49%'}}>
                        <Label>{t('newTopic.metadataValues')}</Label>
                        <TextField 
                            defaultValue={props.topic.keyPhrases}
                            disabled={true}
                        />  
                    </div>
                </div>
            </div>
    }

    const modalFooter = () => {
        return <div>
                {props.errorMessage !== '' && props.errorMessage !== undefined &&
                    <span className={classNames.errorMessage}>{props.errorMessage}</span>
                }
                {props.loading && <Spinner className={classNames.footerButtons} size={SpinnerSize.large} />}
                <DefaultButton className={classNames.footerButtons} disabled={props.loading} onClick={() => props.onClose && props.onClose()}>{t('common:close')}</DefaultButton>
            </div>
    }

    return (
        <EdiModal
            isOpen={props.isOpen}
            onCloseClick={props.onClose}
            showCloseIcon={true}
            title={t('topicInfo')}       
            body={body()}
            footer={modalFooter()}
            width={700}
            height={650}
        />
    )
}