import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dashboardFilter } from "../components/feedback/feedback.types";
import { useDashboardSelector } from "../dashboardStore";
import { Dataset } from "../models/dataset";

export type DatasetListState = {
    filters: dashboardFilter;
    selectedItems: Dataset[];
    allDatasetItems: Dataset[];
    noMore: boolean;
    refresh: boolean;
}

const emptyFilters = {
    pageNumber: 0,
    pageSize: 20,
    orderBy: "CreatedOn",
    isAscending: false,
    datasetName: undefined,
    fileName: undefined,
    skillId: undefined,
    moduleInstanceId: undefined,
    feedbackStatus: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    datasetId: undefined,
    extraProperty: undefined,
    datasetStatus: undefined
}

const initialState: DatasetListState = {
    filters: emptyFilters,
    selectedItems: [],
    allDatasetItems: [],
    noMore: false,
    refresh: false,
}

const datasetListSlice = createSlice({
    name: 'dashboard-dataset',
    initialState,
    reducers: {
        setFilters: (state: DatasetListState, action: PayloadAction<dashboardFilter>) => {
            state.filters = action.payload;
        },
        resetDatasetFilters: (state: DatasetListState) => {
            state.filters = emptyFilters;
            state.noMore = false;
        },
        setSelectedDatasets: (state: DatasetListState, action: PayloadAction<Dataset[]>) => {
            state.selectedItems = action.payload;
        },
        setAllDatasetItems: (state: DatasetListState, action: PayloadAction<Dataset[]>) => {
            state.allDatasetItems = action.payload;
        },
        setNoMore: (state: DatasetListState, action: PayloadAction<boolean>) => {
            state.noMore = action.payload;
        },
        clearSelectedItems: (state: DatasetListState) => {
            state.selectedItems = [];
        },
        setRefresh: (state: DatasetListState, action: PayloadAction<boolean>) => {
            state.refresh = action.payload;
        }

    }
})

export const { setFilters, resetDatasetFilters, setSelectedDatasets, setAllDatasetItems, clearSelectedItems, setNoMore, setRefresh } = datasetListSlice.actions;
export default datasetListSlice.reducer;

export const useDatasetList = () => useDashboardSelector(state => state.datasetList);