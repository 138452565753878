import { styled } from "@fluentui/react";
import { getStyles } from "./knowledgeDetailsModal.styles";
import { IKnowledgeDetailsModalPropsStyles, IKnowledgeDetailsModalStyles, IKnowledgeDetailsModalProps } from "./knowledgeDetailsModal.types";
import { KnowledgeDetailsModalBase } from "./knowledgeDetailsModal.base";

const KnowledgeDetailsModal = styled<IKnowledgeDetailsModalProps, IKnowledgeDetailsModalPropsStyles, IKnowledgeDetailsModalStyles>(
    KnowledgeDetailsModalBase,
    getStyles,
    undefined,
    { scope: 'KnowledgeDetailsModal' }
);

export default KnowledgeDetailsModal;