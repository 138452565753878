export type ConfigAppEntry = {
    appId: string
    entityId: string
}

const entityId = "467e9559-3e4d-4640-9c6e-09662c7b30a5"

export const environment: Record<string, ConfigAppEntry> = {
    'localhost:3000': {
        appId: "35401e7f-d05f-41ff-bfa7-957da926410f",
        entityId: entityId
    },
    'sd-edi-app.eni.com': {
        appId: "bd3cdcf9-6681-48f2-b72c-4447948226d8",
        entityId: entityId
    },
    'st-edi-app.eni.com': {
        appId: "b31a37d6-7a47-4aed-b7ef-0b1b0a024e36",
        entityId: entityId
    },
    'pp-edi-app.eni.com': {
        appId: "5531e000-2684-4319-94c9-66313db56b02",
        entityId: entityId
    },
    'edi-app.eni.com': {
        appId: "e68885b3-c6d1-44cd-b207-4d0e8d4e4796",
        entityId: entityId
    }
}