import { styled } from "@fluentui/react";
import { getStyles } from "./activitiesList.styles";
import { IActivitiesListPropsStyles, IActivitiesListStyles, IActivitiesListProps } from "./activitiesList.types";
import { ActivitiesListBase } from "./activitiesList.base";

const ActivitiesList = styled<IActivitiesListProps, IActivitiesListPropsStyles, IActivitiesListStyles>(
    ActivitiesListBase,
    getStyles,
    undefined,
    { scope: 'ActivitiesList' }
);

export default ActivitiesList;