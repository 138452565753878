import { ActivityLabDispatch } from "../activityLabStore";
import { showRenamePortfolioModal } from "../features/portfolioList";
import { INavigator } from "../hooks/useNavigator";
import { PortfolioRoleId } from "../models/constants";
import { Portfolio } from "../models/portfolio";
import { ActivityTabSettings } from "./routes";

export type ActionPortfolioEntry = {
    portfolioRoles: PortfolioRoleId[];
    label: string;
    icon?: string;
    onClick: (portfolio: Portfolio, navigator: INavigator, dispatch: ActivityLabDispatch) => void;
    subActionItems?: ActionPortfolioEntry[];
}

const actions: ActionPortfolioEntry[] = [
    {
        portfolioRoles: [PortfolioRoleId.Owner],
        label: 'commands.settingsPortfolio',
        icon: 'Settings',
        onClick: (portfolio, navigator) => navigator.goToPortfolioSettingsTab(ActivityTabSettings.settings, portfolio.id)
    },
    {
        portfolioRoles: [PortfolioRoleId.Owner],
        label: 'commands.settingsUsers',
        icon: 'PlayerSettings',
        onClick: (portfolio, navigator) => navigator.goToPortfolioSettingsTab(ActivityTabSettings.users, portfolio.id)
    },
    {
        portfolioRoles: [PortfolioRoleId.Owner],
        label: 'commands.renamePortfolio',
        icon: 'Rename',
        onClick: (portfolio, navigator, dispatch) => dispatch(showRenamePortfolioModal(true)),
    }
];

export const getPortfolioActions = (portfolioRoleId: PortfolioRoleId) => {
    return actions.filter(entry =>
        entry.portfolioRoles.includes(portfolioRoleId));
}