import { styled } from "@fluentui/react";
import { getStyles } from "./knowledgeLabFileUploader.styles";
import { IKnowledgeLabFileUploaderPropsStyles, IKnowledgeLabFileUploaderStyles, IKnowledgeLabFileUploaderProps } from "./knowledgeLabFileUploader.types";
import { KnowledgeLabFileUploaderBase } from "./knowledgeLabFileUploader.base";

const KnowledgeLabFileUploader = styled<IKnowledgeLabFileUploaderProps, IKnowledgeLabFileUploaderPropsStyles, IKnowledgeLabFileUploaderStyles>(
    KnowledgeLabFileUploaderBase,
    getStyles,
    undefined,
    { scope: 'KnowledgeLabFileUploader' }
);

export default KnowledgeLabFileUploader;