import React, { useCallback, useState } from "react";
import { classNamesFunction, DetailsList, DetailsListLayoutMode, DetailsRow, IconButton, IDetailsRowProps, PrimaryButton, SelectionMode, Toggle, TooltipHost } from "@fluentui/react";
import { IActivitySettingsTemplatePropsStyles, IActivitySettingsTemplateStyles, IActivitySettingsTemplateProps } from "./activitySettingsTemplate.types";
import { TemplateActivity } from "../../../../models/templateActivity";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { useTranslation } from "react-i18next";
import { Helpers } from "../../../../../utilities/helpers";
import { templateApi } from "../../../../services/template/template.api";
import CreateTemplateModal from "../createTemplateModal/createTemplateModal";
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";

const getClassNames = classNamesFunction<IActivitySettingsTemplatePropsStyles, IActivitySettingsTemplateStyles>();

export const ActivitySettingsTemplateBase = (props: IActivitySettingsTemplateProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [managing, setManaging] = useState(false);
    const { t } = useTranslation(['templateActivity', 'common']);
    const [showModal, setShowModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateActivity>();

    const changeStatus = async (itemId: number) => {
        setManaging(true);
        try {
            await templateApi.enableTemplate(itemId);
            props.reloadItems();
        }
        catch (ex) {
            console.log("error: ", ex)
        }
        finally {
            setManaging(false);
        }
    }

    const columns = [{
        key: 'name',
        name: t('columns.name'),
        minWidth: 250,
        maxWidth: 350,
        headerClassName: classNames.headerRow,
        onRender: function renderTemplateName(item: TemplateActivity) {
            return (
                <div className={classNames.nameAndDescriptionContainer}>
                    <div className={classNames.nameStyle}>{item.name}</div>
                    <TooltipHost content={item.description}>
                        <div className={classNames.descriptionStyle}>{item.description}</div>
                    </TooltipHost>
                </div>
            );
        }
    },
    {
        key: 'status',
        name: t('columns.status'),
        isResizable: false,
        minWidth: 30,
        maxWidth: 70,
        headerClassName: classNames.headerRow,
        onRender: function render(item: TemplateActivity) {
            return (
                <Toggle
                    disabled={managing}
                    checked={item.active}
                    styles={{ root: { marginBottom: 0 } }}
                    onChange={async () => { await changeStatus(item.id) }}
                />
            );
        }
    },
    {
        key: 'createdBy',
        name: t('columns.createdBy'),
        minWidth: 90,
        maxWidth: 230,
        headerClassName: classNames.headerRow,
        onRender: function render(item: TemplateActivity) {
            return <div className={classNames.wrapRow}>{item.createdBy}</div>
        }
    },
    {
        key: 'createdOn',
        name: t('columns.createdOn'),
        minWidth: 100,
        maxWidth: 200,
        targetWidthProportion: 3,
        headerClassName: classNames.headerRow,
        onRender: function render(item: TemplateActivity) {
            return <div className={classNames.ellipseRow}>{Helpers.formatToRelativeDate(item.createdOn)}</div>
        }
    },
    {
        key: 'edit',
        name: 'edit',
        isIconOnly: true,
        isResizable: false,
        minWidth: 30,
        maxWidth: 40,
        headerClassName: classNames.headerRow,
        onRender: function render(item: TemplateActivity) {
            return (
                <TooltipHost content={t("common:commands.edit")}>
                    <IconButton
                        disabled={managing}
                        iconProps={{ iconName: "Edit" }}
                        onClick={() => { setSelectedTemplate(item); setShowModal(true) }}
                    />
                </TooltipHost>
            );
        }
    }
        // ,{
        //     key: 'remove',
        //     name: 'remove',
        //     isIconOnly: true,
        //     isResizable: false,
        //     minWidth: 30,
        //     maxWidth: 40,
        //     headerClassName: classNames.headerRow,
        //     onRender: function render(item: TemplateActivity) {
        //         return (
        //             <TooltipHost content={t('common:commands.delete')}>
        //                 <IconButton
        //                     disabled={managing}
        //                     iconProps={{ iconName: "Delete" }}
        //                 />
        //             </TooltipHost>
        //         );
        //     }
        // }
    ];


    const emptyList = useCallback(() => {
        if (managing || props.listTemplate.length > 0)
            return null;

        return (
            <div>
                <TeamsImage
                    styles={{
                        img: {
                            width: '25%',
                            height: '25%'
                        }
                    }}
                    imageName={ImageName.EmptyFolderDrop}
                    caption={t("common:emptyList")}
                />
            </div>
        )
    }, [managing, props.listTemplate, t]);

    return (
        <>
            <PrimaryButton
                iconProps={{ iconName: 'Add' }}
                styles={{ root: { margin: '10px 0 20px' }, icon: { height: 'auto' } }}
                text={t("addTemplate")}
                onClick={() => props.openModal()}
            />
            {  props.listLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}><TeamsSpinner /></div> :
                <DetailsList
                    layoutMode={DetailsListLayoutMode.justified}
                    styles={classNames.subComponentStyles.detailsList}
                    items={props.listTemplate || []}
                    getKey={(item: TemplateActivity) => item.id.toString()}
                    onRenderRow={(props?: IDetailsRowProps) => {
                        if (!props) return null;
                        return (
                            <>
                                <DetailsRow {...props}
                                    styles={classNames.subComponentStyles.detailsRow}
                                />
                            </>
                        )
                    }}
                    columns={columns}
                    // onShouldVirtualize={() => false} da scommentare se con tanti item si presentano spazi bianchi durante lo scroll
                    onRenderDetailsFooter={emptyList}
                    selectionMode={SelectionMode.none}
                />}
            {showModal && selectedTemplate && <CreateTemplateModal edit={true} template={selectedTemplate} onClose={async (reload) => { setShowModal(false); reload && props.reloadItems() }} />}
            {/* reload && await getTemplateList(true) */}
        </>
    );
}