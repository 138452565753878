import { useCallback, useMemo } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { useActivityLabDispatch } from "../activityLabStore";
import { loadActivities, removeAllActivities, updateCurrentPortfolioId } from "../features/activityList";
import { ActivityStatus } from "../models/constants";
import { ActivityLabRoutes, ActivityTab, ActivityTabSettings } from "../utilities/routes";

export interface INavigator {
    isActivityLabRoute: boolean;
    currentPortfolioId?: number;
    currentActivityLabTab?: ActivityTab;
    changePortfolio: (portfolioId: number, tab?: ActivityTab) => void
    changeActivityLabTab: (tab: ActivityTab) => void,
    goToPortfolioPath: (path: string) => void;
    goToPath: (path: string) => void;
    goBack: () => void;
    goToPortfolioSettingsTab: (tab: ActivityTabSettings, portfolioId?: number) => void
}

export const useNavigator = (): INavigator => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useActivityLabDispatch();

    const routePath = useMemo(() =>
        matchPath<{ portfolioId: string, tab: ActivityTab }>(location.pathname, { path: ActivityLabRoutes.activityLabOptionals }),
        [location.pathname]);

    const isActivityLabRoute = useMemo(() => routePath !== null, [routePath]);

    const currentPortfolioId = useMemo(() => routePath?.params.portfolioId ? +routePath?.params.portfolioId : undefined, [routePath]);

    const currentActivityLabTab = useMemo(() => routePath?.params.tab, [routePath]);

    const changePortfolio = useCallback((portfolioId: number, tab?: ActivityTab) => {
        dispatch(updateCurrentPortfolioId(portfolioId));
        dispatch(removeAllActivities());
        getActivities(portfolioId);
        history.push(ActivityLabRoutes.generateActivityLabTabPath(portfolioId, tab ?? currentActivityLabTab ?? ActivityTab.board));
    }, [currentActivityLabTab, history, dispatch]); //eslint-disable-line react-hooks/exhaustive-deps

    const changeActivityLabTab = useCallback((tab: ActivityTab) => {
        if (!currentPortfolioId)
            return;
        history.push(ActivityLabRoutes.generateActivityLabTabPath(currentPortfolioId, tab));
    }, [currentPortfolioId, history]);

    const goToPortfolioSettingsTab = useCallback((tab: ActivityTabSettings, portfolioId?: number) => {
        if (!portfolioId || !currentPortfolioId)
            return;
        
        history.push(ActivityLabRoutes.generateActivitySettingsTabPath(portfolioId ?? currentPortfolioId ?? 0, tab));
    }, [currentPortfolioId, history])


    const goToPortfolioPath = useCallback((path: string) => {
        if (!currentPortfolioId)
            return;

        history.push(`${ActivityLabRoutes.generatePortfolioPath(currentPortfolioId)}/${path}`);
    }, [currentPortfolioId, history])

    const goToPath = useCallback((path: string) => history.push(path), [history]);

    const goBack = useCallback(() => history.goBack(), [history]);

    function getActivities(id: number){

        for (const status in ActivityStatus) {
            if (!isNaN(+status)) {
                dispatch(loadActivities({
                    portfolioId: id,
                    activityStatus: +status,
                    forceRefresh: true
                }));
            }
        }
    }

    return {
        isActivityLabRoute: isActivityLabRoute,
        currentPortfolioId: currentPortfolioId,
        currentActivityLabTab: currentActivityLabTab,
        changePortfolio: changePortfolio,
        changeActivityLabTab: changeActivityLabTab,
        goToPortfolioPath: goToPortfolioPath,
        goToPath: goToPath,
        goBack: goBack,
        goToPortfolioSettingsTab: goToPortfolioSettingsTab
    }
}