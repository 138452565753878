/*eslint-disable sonarjs/cognitive-complexity*/
import React from "react";
import { loadFolderContent, loadRecycleBin, selectItems, selectItemsOnDelete, showDeleteModal, useArchiveContent } from "../../../features/archiveContent";
import { useDocLabDispatch } from "../../../docLabStore";
import { useTranslation } from "react-i18next";
import { IDeleteModalProps } from "../../common/deleteModal/deleteModal.types";
import { archivesApi } from "../../../services/archives/archives.api";
import { Call, callsList } from "../../../models/callsApi";
import { insertCall, setCall } from "../../../features/callNotification";
import { nanoid } from "@reduxjs/toolkit";
import { DeleteModalLayoutBase } from "../../common/deleteModal/deleteModalLayout";
import { DeletedType } from "../../../models/constants";

export const DeleteItemModalBase = (props: IDeleteModalProps) => {
    const { deleteModalShown, selectedItems, deletedCurrentType } = useArchiveContent();
    const dispatch = useDocLabDispatch();
    const { t } = useTranslation(['deleteModal', 'common']);

    const deleteItem = async () => {
        const deleteItemCall: Call = {
            type: callsList.deleteItem,
            nameOperation: selectedItems.length > 1 ? t('operationMultiItems') : selectedItems[0].isFolder ? t('operationFolder') : t('operationFile'),
            errors: [
                { code: 403, message: t('notPermissions') },
                { code: 500, message: t('common:deleteGenericErrorApi').concat(selectedItems.length > 1 ? t('ofItems') : selectedItems[0].isFolder ? t('ofFolder') : t('ofFile')) }
            ]
        }
        const id = nanoid();
        const payload = { requestId: id, notification: deleteItemCall }
        dispatch(insertCall(payload));
        props.onClose && props.onClose();

        try {
            let result;

            if(deletedCurrentType === DeletedType.ByRecycleBin)
                result = await archivesApi.deleteFromRecycleBin(selectedItems);
            else
                result = await archivesApi.deleteItems(selectedItems, deletedCurrentType ?? DeletedType.ByFiles);
            const successPayload = {
                requestId: id, success: true,
                message: selectedItems.length > 1 ? t('successMessageItems').concat(` (${result.folderIds.length} folders, ${result.fileIds.length} files)`)
                    : selectedItems[0].isFolder
                        ? t('successMessageFolder')
                        : t('successMessageFile')
            }

            dispatch(setCall(successPayload));
            if (deletedCurrentType === DeletedType.ByRecycleBin) {
                await dispatch(loadRecycleBin({ resetPage: true }));
                dispatch(selectItemsOnDelete([]));
            }
            else {
                await dispatch(loadFolderContent({ resetPage: true }));
                dispatch(selectItems([]));
            }
        }
        catch (error: any) { //eslint-disable-line @typescript-eslint/no-explicit-any
            let errorMessage = '';
            if (error.code === 403) {
                errorMessage = t('notPermissions');
            }
            else {
                errorMessage = t('common:genericErrorApi');
            }
            const failurePayload = { requestId: id, success: false, message: errorMessage }
            dispatch(setCall(failurePayload));
        }
    }

    return (
        <DeleteModalLayoutBase
            {...props}
            Title={t('title')}
            onDismiss={() => { dispatch(showDeleteModal({ show: false })) }}
            SubText={selectedItems.length && selectedItems.length > 1 ? t('messageItems') : selectedItems[0].isFolder ? t('messageFolder') : t('messageFile')}
            onClick={deleteItem}
            PrimaryButtonText={t('common:dialogButtonConfirmDeleting')}
            DefaultButtonText={t('common:dialogButtonCancel')}
            IsOpen={deleteModalShown}
        />
    );
}