import { useKnowledgeLabList } from "../features/knowledgeLabList";
import { KnowledgeLabShort } from "../models/knowledgeLab";

import { useNavigator } from "./useNavigator";

export const useCurrentKnowledge = (): KnowledgeLabShort | undefined => {
    const { currentKnowledgeLabId } = useNavigator();
    const { knowledgeLabList } = useKnowledgeLabList();

    if (!currentKnowledgeLabId)
        return;

    return knowledgeLabList.find(k => k.id === currentKnowledgeLabId);
}