import { AnimationClassNames, DefaultEffects, FontSizes, FontWeights, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react"
import { ITocSettingsPropsStyles, ITocSettingsStyles } from "./tocSettings.types"

export const TocSettingsGlobalClassNames = {
    root: 'edi-toc-settings-root',
    grid: 'edi-toc-settings-grid',
    spinner: 'edi-toc-settings-spinner',
    rowTitle: 'edi-toc-settings-row-title',
    headerRow: 'edi-toc-settings-header-row',
}

export const getStyles: IStyleFunction<ITocSettingsPropsStyles, ITocSettingsStyles> = (props: ITocSettingsPropsStyles): ITocSettingsStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            TocSettingsGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        grid: [
            TocSettingsGlobalClassNames.grid,
            {
                display: 'grid',
                gridTemplateColumns: '100px 50px 50px',
                background: palette.white,
                boxShadow: DefaultEffects.elevation4,
                marginBottom: 2,
                maxWidth: 200,
                '> *': {
                    padding: '5px 10px',
                    color: palette.neutralPrimary
                },
                '> :first-child': {
                    backgroundColor: palette.neutralSecondary,
                    color: palette.white,
                    fontWeight: FontWeights.semibold
                },
            }
        ],
        spinner: [
            TocSettingsGlobalClassNames.spinner,
            {
                width: '100%',
                height: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        ],
        rowTitle: [
            TocSettingsGlobalClassNames.rowTitle,
            AnimationClassNames.fadeIn400,
            {
                backgroundColor: palette.white,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 11,
                fontSize: FontSizes.size14,
                color: palette.black,
            }
        ],
        headerRow: [
            TocSettingsGlobalClassNames.headerRow,
            {
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                }
            }
        ],
        subComponentStyles: {
            detailsList: (): IDetailsListStyles => {
                return {
                    root: {},
                    contentWrapper: {},
                    focusZone: {},
                    headerWrapper: {
                        '> .ms-DetailsHeader': {
                            paddingTop: 0,
                            borderBottomColor: 'transparent'
                        }
                    },
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                const { palette } = props.theme;

                return {
                    root: {
                        backgroundColor: palette.white,
                        ':hover': {
                            backgroundColor: palette.white
                        }
                    },
                    cell: { alignSelf: 'center' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
        }
    };
}