/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable @typescript-eslint/no-unused-vars*/
import React, { useState } from "react";
import { classNamesFunction, DefaultButton, Dialog, DialogType, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { showDownloadWfDetailsModal, useArchiveContent } from "../../../features/archiveContent";
import { useTranslation } from "react-i18next";
import { archivesApi } from "../../../services/archives/archives.api";
import { useDocLabDispatch } from "../../../docLabStore";
import { IDownloadWfDetailsModalProps, IDownloadWfDetailsModalPropsStyles, IDownloadWfDetailsModalStyles } from "./downloadWfDetailsModal.types";

const getClassNames = classNamesFunction<IDownloadWfDetailsModalPropsStyles, IDownloadWfDetailsModalStyles>();

export const DownloadWfDetailsModalBase = (props: IDownloadWfDetailsModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const dispatch = useDocLabDispatch();
    const { downloadWfDetailsModalShown, selectedItems } = useArchiveContent();
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const { t } = useTranslation(['downloadWfDetailsModal', 'common']);

    const generateExcelFile = async () => {
        try{
            setLoading(true);
            await archivesApi.exportWorkflowInfo(selectedItems[0].id, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')       
            dispatch(showDownloadWfDetailsModal(false))     
        }
        catch{
            setErrorMessage(t('downloadError'));
        }
        finally{
            setLoading(false);
        }
    }

    const dialogContentProps = {
        type: DialogType.normal,
        title: t('downloadWfDetails'),
        subText: t('title')
    };

    return (
        <Dialog
            isOpen={downloadWfDetailsModalShown}
            onDismiss={props.onClose}
            dialogContentProps={dialogContentProps}
        >
            { errorMessage !== undefined ? <span style={{color: 'red'}}>{errorMessage}</span> : null}
                <div className={classNames.footerContainer}>                
                    { loading && <Spinner style={{marginLeft: '6px'}} size={SpinnerSize.medium} /> }
                    <DefaultButton
                        disabled={loading} 
                        style={{marginLeft: '6px'}} 
                        onClick={() => dispatch(showDownloadWfDetailsModal(false))} 
                    >
                        {t('common:close')}
                    </DefaultButton>
                    <PrimaryButton 
                        disabled={loading}
                        style={{marginLeft: '6px'}}
                        onClick={generateExcelFile}
                    >
                            {t('common:commands.download')}
                    </PrimaryButton>
                </div>
        </Dialog>
    );
}