import { IStyleFunction } from "@fluentui/react"
import { IKeywordListPropsStyles, IKeywordListStyles } from "./keywordList.types";

export const KeywordListGlobalClassNames = {
    root: 'edi-keywordlist-root',
    headerRow: 'edi-keywordlist-headerRow',
    item: 'edi-keywordlist-item',
    buttonHeader:'edi-keywordlist-buttonHeader',
}
export const getStyles: IStyleFunction<IKeywordListPropsStyles, IKeywordListStyles> = (props: IKeywordListPropsStyles): IKeywordListStyles => {
    return {
        root: [
            KeywordListGlobalClassNames.root,
            {
            }
        ],
        commonLabel: {
            paddingTop: '0px',
            paddingBottom: '0px',
            fontSize: '12px'
        },
        descriptionLabel: {
            paddingTop: '0px',
            paddingBottom: '8px',
            fontSize: '12px'
        },
        center: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
        },
        container: {
            display: 'flex',
            flexdirection: 'row',
        },
        search: {
            minWidth: '500px',
            paddingLeft: '8px'
        },
        list: {
            width: '516px',
            height: '270px',
            maxHeight: '270px',
            overflowY: 'scroll'
        },
        errorMessage: {
            color: '#A4262C',
            paddingLeft: '8px'
        },
        item: [
            KeywordListGlobalClassNames.item,
            { 
                display: 'flex',
                flexdirection: 'row'                
            } 
        ],
        buttonHeader: [
            KeywordListGlobalClassNames.buttonHeader,
            { 
                padding: 0,
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                },             
            } 
        ],
        itemName: {
            padding: '0px',
            width: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        warningMessage: {
            color: 'orange',
            paddingLeft: '8px'
        },
        headerRow: [
            KeywordListGlobalClassNames.headerRow,
            { 
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                },
                
            }
        ]
    };
}