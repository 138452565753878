import { apiClient } from '../../../modules/apiClient/apiClient';
import { svcPaths } from '../../../modules/apiClient/config';
import { IArchiveTagsSetting } from '../../components/settings/docLabSettings/docLabSettings.types';
import { configurationProperties, ITag, SearchIndex, SearchRequest, SkillConfigurations } from './search.contracts';

class SearchApiClass {
    async search(request: SearchRequest) {

        const selectedArchivesId = request.selectedArchivesId?.map(e => ({key: 'ArchiveId', value: e}));

        const payload = {
            criteria: request.value,
            maxHighlights: (request.maxHighlights ?? 0) > 0 ? request.maxHighlights : 0,
            filters: request.filters?.slice().concat(selectedArchivesId || []),
            pageNumber: request.currentPage,
            pageSize: request.pageSize,
            orderBy: request.orderedBy,
            isAscending: request.isAscending,
            multiLanguageSearch: request.multiLanguageSearch,
            scope: request.scope,
            folderIds: request.folderIds
        }
        const response = await apiClient.post(`${svcPaths.search}/search`, payload);
        return response.data;
    }

    async getSkillResult(fileId: number) {
        const response = await apiClient.get<ITag>(`${svcPaths.search}/skillsresults/${fileId}`);
        return response.data;
    }

    async upsertSkillResult(fileId: number, skillResults: ITag) {
        const toSave = skillResults;

        toSave.tags.forEach(tag => {
            tag.values = tag.values.filter(x => !x.toDelete);
        });

        const payload = {
            skillResults: toSave
        };
        const response = await apiClient.put(`${svcPaths.search}/skillsresults/${fileId}`, payload);
        return response.data;
    }

    async getKeyphrasesExtractor(archiveId: number) {
        const response = await apiClient.get<SkillConfigurations>(`${svcPaths.search}/configurations/keyphrasesextractor/${archiveId}`,);
        return response.data;
    }

    async putKeyphrasesExtractor(archiveId: number, properties: configurationProperties) {
        const payload = { archiveId: archiveId, properties: properties };

        const response = await apiClient.put<void>(`${svcPaths.search}/configurations/keyphrasesextractor/${archiveId}`, payload);
        return response.data;
    }

    async getArchiveTagsSettings(archiveId: number) {
        const response = await apiClient.get<IArchiveTagsSetting[]>(`${svcPaths.search}/tagssetting/${archiveId}`);
        return response.data;
    }

    async upsertArchiveTagsSetting(archiveId: number, properties: IArchiveTagsSetting) {
        const payload = { archiveId: archiveId, tagSection: properties.tagSection, enabled: properties.enabled, settings: properties.settings };

        const response = await apiClient.put<void>(`${svcPaths.search}/tagssetting`, payload);
        return response.data;
    }

    async getIndexField() {
        const response = await apiClient.get<SearchIndex>(`${svcPaths.search}/archive`);
        return response.data;
    }
}

export const searchApi = new SearchApiClass();
