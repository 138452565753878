import { apiClient } from "../../../modules/apiClient/apiClient";
import { svcPaths } from "../../../modules/apiClient/config";
import { ListFile } from "../../models/fileShort";
import { CreateWorkFlowPayload, WorkFlow, WorkFlowShort, WorkFlowForUser, WorkFlowSplitByStatus } from "../../models/workflow";
import { GetWorkFlowsRequest, GetWorkflowsStatusRequest } from "./workflow.contracts";

class WorkflowApiClass {
  async getPagedListFromSPO(activityId: number, folderId?: string, pageSize?: number, skipToken?: string) {
    const response = await apiClient.get<ListFile>(`${svcPaths.activities}/workflow/filesteam?activityId=${activityId}&folderId=${folderId}&pageSize=${pageSize}&skipToken=${skipToken}`);
    return response.data;
  }

  async getWorkFlowList(activitiesRequest: GetWorkFlowsRequest) {
    const response = await apiClient.get<WorkFlowShort[]>(`${svcPaths.activities}/workflow`, {
      params: {
        pageNumber: activitiesRequest.pageNumber,
        pageSize: activitiesRequest.pageSize,
        orderBy: activitiesRequest.orderBy,
        isAscending: activitiesRequest.isAscending,
        portfolioId: activitiesRequest.portfolioId,
        isCreator: activitiesRequest.isCreator
      }
    });
    return response.data
  }

  async getWorkflowById(id: number) {
    const response = await apiClient.get<WorkFlow>(`${svcPaths.activities}/workflow/${id}`);
    return response.data;
  }

  async getWorkflowsStatus(request: GetWorkflowsStatusRequest) {
    const response = await apiClient.get<Array<WorkFlowSplitByStatus>>(`${svcPaths.activities}/workflow/workflows-status`, {
      params: {
        portfolioId: request.portfolioId,
        getSentWorkFlows: request.getSentWorkFlows,
        dateFrom: request.dateFrom,
        dateTo: request.dateTo
      }
    });
    return response.data;
  }

  async getWorkflowsForUser(portfolioId: number) {
    const response = await apiClient.get<Array<WorkFlowForUser>>(`${svcPaths.activities}/workflow/workflows-for-users?portfolioId=${portfolioId}`);
    return response.data;
  }

  async createWorkflow(workflow: CreateWorkFlowPayload) {
    const response = await apiClient.post<WorkFlow>(`${svcPaths.activities}/workflow`, workflow);
    return response.data;
  }

  async delete(workFlowId: number) {
    const response = await apiClient.delete(`${svcPaths.activities}/workflow/${workFlowId}`);
    return response.data;
  }
}

export const workflowApi = new WorkflowApiClass();