import React, { useEffect, useMemo, useState } from "react";
import { classNamesFunction, DefaultButton, Dialog, DialogFooter, Icon, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, TooltipHost } from "@fluentui/react";
import { useDocLabDispatch } from "../../../docLab/docLabStore";
import Panel from "../panel/panel";
import { ITagPanelPropsStyles, ITagPanelStyles, ITagPanelProps, Section } from "./tagPanel.types";
import { searchApi } from "../../../docLab/services/search/search.api";
import { Helpers } from "../../../utilities/helpers";
import { ISkillResultsTagValue, ITagValue } from "../../../docLab/services/search/search.contracts";
import { loadFolderContent, showTagPanel, useArchiveContent } from "../../../docLab/features/archiveContent";
import Tag from "../tag/tag";
import AccordionAnalysis from "../accordionAnalysis/accordionAnalysis";
import { useTranslation } from "react-i18next";
import TeamsImage from "../teamsImage/teamsImage";
import { ImageName } from "../teamsImage/teamsImage.types";
import { ErrorsType } from "../../../docLab/models/callsApi";
import { ArchiveRoleId, FormalAspectsSettingsKeys } from "../../../docLab/models/constants";
import { useCurrentArchive } from "../../../docLab/hooks/useCurrentArchive";
import { archivesApi } from "../../../docLab/services/archives/archives.api";
import JsonForm from "./jsonForm/jsonForm";
import _ from "lodash";
import { useAsyncApi, useOnMount } from "../../../utilities/hooks";
import { FileFormatExtension } from "../../../utilities/constants";
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { appInsightReactPlugin } from "../../../modules/appInsights/appInsights";
import { Archive } from "../../../docLab/models/archive";
import { useSearchContent } from "../../../docLab/features/searchContent";
import { ArchiveUnivocityRule, DocVerifySource, FileNameWithMetadata, ResultDocumentsMetadataUnivocityWithError } from "../../../docLab/models/archiveUnivocityRule";
import MultiStepModal from "../multiStepModal/multiStepModal";
import DocVerifyLoadingPage from "../docVerifyLoadingPage/docVerifyLoadingPage";
import DocVerifyModalFooter from "../docVerifyModalFooter/docVerifyModalFooter";
import DocVerifyModalBody from "../docVerifyModalBody/docVerifyModalBody";
/*eslint-disable @typescript-eslint/no-explicit-any */
/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable sonarjs/no-duplicate-string */

const getClassNames = classNamesFunction<ITagPanelPropsStyles, ITagPanelStyles>();
type errorType = {
    text: string;
    toBeIndexed: boolean;
}

enum VerifyUnivocitySteps {
    docVerifyLoading = 0,
    docVerify = 1
}

export const TagPanelBase = (props: ITagPanelProps) => {
    const dispatch = useDocLabDispatch();

    const [tags, setTags] = useState<ITagValue[]>([]);
    const [initialTags, setInitialTags] = useState<ITagValue[]>([]);

    const currentArchive = useCurrentArchive();
    const { t } = useTranslation(['tagPanel', 'common']);
    const { tagPanelShown, selectedItems, currentFolderId } = useArchiveContent();
    const { selectionEntryList } = useSearchContent();
    const selectedList = selectedItems.length > 0 ? selectedItems : selectionEntryList;

    const [errorMessage, setErrorMessage] = useState<errorType | undefined>(undefined);
    const [metadataError, setMetadataError] = useState<string>();
    const [section, setSection] = useState<Section>(currentArchive?.isDefaultViewAdditionalMetadata ? Section.metadata : props.default || Section.tag);
    const [loading, setLoading] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);

    const [showConfirmModal, setConfirmModal] = useState<boolean>(false);
    const [savingMetadata, setSavingMetadata] = useState<boolean>(false);
    const [formData, setFormData] = useState<any | undefined>(undefined);
    const [initialFormState, setInitialFormState] = useState<any>({});
    const [success, setSuccess] = useState<boolean | undefined>(undefined);
    const [searchText, setSearchText] = useState("");
    const [showFormConfirm, setShowFormConfirm] = useState(false);
    const [apiErrorForm, setApiErroForm] = useState(false);
    const [formErrorsList, setFormErrorsList] = useState<any | undefined>(undefined);

    const [showCloseDialog, setShowCloseDialog] = useState(false);
    const [showChangeDialog, setShowChangeDialog] = useState({ isOpen: false, newSection: section });
    const [fileExtension, setFileExtension] = useState("");
    const [firstRenderList, setFirstRenderList] = useState({tagPanel: true, metadataPanel: true});
    const [isNoTagsExtension, setIsNoTagsExtension] = useState<boolean>(false);
    const [isDependenciesButtonVisible, setIsDependenciesButtonVisible] = useState<boolean>(false);
    const [jsonFormKey, setJsonFormKey] = useState<number>(0);
    const [mainPropertiesList, setMainPropertiesList] = useState<string[]>([]);
    const [notUsedUpdateDependencies, setNotUsedUpdateDependencies] = useState<boolean>(true);
    const [archiveUnivocityRules, setArchiveUnivocityRules] = useState<ArchiveUnivocityRule[]>([]);
    const [currentStep, setCurrentStep] = useState(VerifyUnivocitySteps.docVerifyLoading);
    const [isVerifyUnivocityModalOpen, setIsVerifyUnivocityModalOpen] = useState<boolean>(false);
    const [loadingDoc, setLoadingDoc] = useState<boolean>(true);
    const [filesNameWithMetadata, setFilesNameWithMetadata] = useState<FileNameWithMetadata[]>([]);
    const [documentUnivocityError, setDocumentUnivocityError] = useState<ResultDocumentsMetadataUnivocityWithError>();

    const NoTagsFileExtension = ["jpg", "jpeg", "dwg", "p7m", "zip", "rar", "tif", "dwf"];

    const readonly = useMemo(() => selectedList[0].archiveRoleId === ArchiveRoleId.Reader, [selectedList]);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, noTags: tags.length === 0, readonlyForm: readonly });
    const applicationinsights = useTrackEvent(appInsightReactPlugin, "openedTagPanel", { tagPanelInfo: {}});

    const { execute: getArchiveFromFile, loading: loadingArchiveFromFile, value: archiveFromFile } = useAsyncApi<number, Archive>({
        func: async (fileId: number) => {
            return await archivesApi.getArchiveFromFile(fileId);
        }
    });

    useOnMount(() => {        
        const extension = selectedList[0].name?.toLowerCase().split('.').pop();
        if (!extension)
            return

        if (NoTagsFileExtension.includes(extension)){
            setSection(Section.metadata);
            setFileExtension(extension);
            setIsNoTagsExtension(true);
        }
        
        (async () => {            
            if (!NoTagsFileExtension.includes(extension))
                await loadTags(); 

            await loadFormData();
            await getAdditionalDataForDependencies();
            if (selectedList[0])
                await getArchiveFromFile(selectedList[0].id);
        })()
    });

    useEffect(() => {
        if (section === Section.tag && firstRenderList.tagPanel && archiveFromFile?.id) {
            setFirstRenderList({tagPanel: false, metadataPanel: firstRenderList.metadataPanel});
            applicationinsights({tagPanelInfo: {PanelType: "tagPanel", archiveId: archiveFromFile?.id, fileId: selectedList[0].id }});
        }
        if (section === Section.metadata && firstRenderList.metadataPanel && archiveFromFile?.id) {
            setFirstRenderList({tagPanel: firstRenderList.tagPanel, metadataPanel: false});
            applicationinsights({tagPanelInfo: {PanelType: "metadataPanel", archiveId: archiveFromFile?.id, fileId: selectedList[0].id }});
        }
    }, [section, loadingArchiveFromFile]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(currentArchive?.id)
            setUserArchiveUnivocityRules(currentArchive.id);
    }, [currentArchive?.id]); //eslint-disable-line react-hooks/exhaustive-deps

    const setUserArchiveUnivocityRules = async (id: number) => {
        try {
            const numerOfRules = await archivesApi.getArchiveUnivocityRules(id);
            setArchiveUnivocityRules(numerOfRules);
        }
        catch(error) {
            console.log("ERROR: ", error);
        }
    }

    const loadFormData = async () => {
        if (!currentArchive?.id)
            return;
        try {
            setApiErroForm(false);
            setLoading(true);
            const gottenMetadata = await archivesApi.getFileMetadata(selectedList[0].id);
            setInitialFormState(gottenMetadata);
            setFirstLoad(true);
        }
        catch (er) {
            setFormData({});
            setApiErroForm(true);
            const error: ErrorsType = er as ErrorsType;
            if (error.code === 403) {
                const errorType: errorType = {
                    text: t('forbiddenError'),
                    toBeIndexed: false
                }
                setErrorMessage(errorType);
            }
            else {
                const errorType: errorType = {
                    text: t('common:genericErrorApi'),
                    toBeIndexed: false
                }
                setErrorMessage(errorType);
            }
        }
        finally {
            setLoading(false);
        }
    }

    const getAdditionalDataForDependencies = async () => {
        if (!currentArchive?.id)
            return;
        try {
            setLoading(true);
            const mainPropResult = await archivesApi.getMainPropertiesList(currentArchive.id);
            setMainPropertiesList(mainPropResult);
        }
        finally {
            setLoading(false);
        }
    }

    const loadTags = async (notFirstLoad?: boolean) => {
        if (!currentArchive?.id)
            return;

        try {
            setLoading(true);
            setErrorMessage(undefined);
            const skillResult = await searchApi.getSkillResult(selectedList[0].id); 
            setInitialTags(skillResult.tags)
            const extension = getFileExtensions(skillResult.tags);
            setFileExtension(extension);
            setTags(skillResult.tags);
        }
        catch (er) {
            const error: ErrorsType = er as ErrorsType;
            if (error.code === 403) {
                const errorType: errorType = {
                    text: t('forbiddenError'),
                    toBeIndexed: false
                }
                setErrorMessage(errorType);
            }
            else {
                const errorType: errorType = {
                    text: t('common:genericErrorApi'),
                    toBeIndexed: false
                }
                setErrorMessage(errorType);
            }
        }
        finally {
            setLoading(false);
        }
    }

    const getFileExtensions = (tag: ITagValue[]) => {
        let valueToReturn = "";
        tag.forEach(t => {
            if(t && t.key && t.key === "FormalAspects"){
                t.values.forEach(v => {
                    if(v && v.title && v.title === "FileFormatExtension" && v.value){
                        valueToReturn = FileFormatExtension.find(a => a.extension.includes(v.value))?.extension ?? "";
                    }
                })
            }
        });

        return valueToReturn;
    }

    const getToolTipText = (entry: ITagValue) : string | undefined => {
        if(entry && entry.key === "FormalAspects" && fileExtension !== "" && (fileExtension.includes("msg") || fileExtension.includes("ppt"))){
            return t('formalAspectWithoutExtension');
        }
        else
            return undefined;
    }   

    useEffect(() => {
        formDataSetDependencies(formData);
    }, [mainPropertiesList]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (section === Section.tag)
            setIsDependenciesButtonVisible(false);
    }, [section]);

    useEffect(() => {
        if (loading || loadingArchiveFromFile || !firstLoad)
            return;
        if (formData) {
            setInitialFormState(formData);
            setFirstLoad(false);
        }
        else
            setFirstLoad(false);
    }, [firstLoad, formData, loading, formErrorsList, loadingArchiveFromFile]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (firstLoad)
            return;
        if ((formData === undefined && formErrorsList === undefined) || (formData && formErrorsList)) {
            setInitialFormState(formData);
        }
    }, [firstLoad]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setFormData(initialFormState);
    }, [initialFormState])

    const saveTags = async () => {
        try {
            setConfirmModal(false);
            setErrorMessage(undefined);
            setLoading(true);
            await Promise.all([searchApi.upsertSkillResult(selectedList[0].id, { tags: tags }), Helpers.delay(500)]);
            const [skillResult] = await Promise.all([searchApi.getSkillResult(selectedList[0].id), Helpers.delay(500)]);
            setTags(skillResult.tags);
        }
        catch (er) {
            const error: ErrorsType = er as ErrorsType;
            if (error.code === 403) {
                const errorType: errorType = {
                    text: t('forbiddenError'),
                    toBeIndexed: false
                }
                setErrorMessage(errorType);
            }
            else {
                const errorType: errorType = {
                    text: t('common:genericErrorApi'),
                    toBeIndexed: false
                }
                setErrorMessage(errorType);
            }
        }
        finally {
            setLoading(false)
        }
    }

    const toggleTag = (index: number, entryKey: string) => {
        const toUpdate = [...tags];
        const tag = toUpdate.find(x => x.key === entryKey);

        if (tag)
            tag.values[index].toDelete = !tag?.values[index].toDelete

        setTags([...toUpdate]);
    }

    const changeFormalAspectsValue = (index: number, entryKey: string, value: string) => {
        const toUpdate = [...tags];
        const i = toUpdate.findIndex(x => x.key === entryKey);

        if (i > -1)
            toUpdate[i].values[index].value = isNaN(parseInt(value)) ? 0 : parseInt(value);

        setTags([...toUpdate]);
    }

    const evaluateLabel = (tag: ISkillResultsTagValue, entry: ITagValue) => {
        if (tag.value === null)
            return "";

        if (entry.settings) {
            const fa = FormalAspectsSettingsKeys.find(x => x.key === tag.title);

            if (fa) {
                const labels = entry.settings.find(x => x.FormalAspects === fa.value)?.Labels;

                if (labels) {
                    const match = labels.find(x => tag.value <= x.labelTo && tag.value >= x.labelFrom);

                    if (match)
                        return match.text;
                }
            }
        }

        return "";
    }

    const tagEntry = (entry: ITagValue) => {
        return (<div key={entry.key} className={classNames.root}>
            <AccordionAnalysis key={entry.key} title={<div className={classNames.titleAccordion}>{`${t('keys.' + entry.key)}`}
                <TooltipHost content={`${t('tooltipText.' + entry.key)}`}><Icon className={classNames.iconStyle} iconName={"Info"} /></TooltipHost> </div>} opened={true} stickyHeader>
                <div className={classNames.tagAccordionContainer}>{entry.values.map((tag, ind) => {
                    return (
                        <Tag
                            key={ind}
                            value={tag}
                            keyTag={entry.key}
                            selectableTag={entry.key !== "Languages" && !tag.isPinned && !readonly}
                            onCancelSelected={() => toggleTag(ind, entry.key)}
                            onChangeValue={(value) => changeFormalAspectsValue(ind, entry.key, value)}
                            extraLabel={evaluateLabel(tag, entry)}
                            tooltipContent={getToolTipText(entry)}
                        />
                    )
                })}
                </div>
            </AccordionAnalysis>
        </div>)
    }

    const pivotHeader = () => {
        if(loadingArchiveFromFile)
            return <></>

        return archiveFromFile?.metadataJsonSchema !== undefined && archiveFromFile?.metadataJsonSchema !== null ? 
        <div className={classNames.pivotHeaderContainer}>
            <Pivot
                headersOnly={true}
                defaultSelectedKey={props.default?.toString()}
                onLinkClick={(item, ev) => changeTab(item, ev)}
                selectedKey={section.toString()}
            >
                {!isNoTagsExtension &&
                    <PivotItem headerButtonProps={{
                        disabled: loading || loadingArchiveFromFile,
                        styles: classNames.pivotItemsDisabled
                    }} key={Section.tag} itemKey={Section.tag.toString()} headerText={t('metadata.pivotTag')} />
                }
                <PivotItem headerButtonProps={{
                    disabled: loading || loadingArchiveFromFile,
                    styles: classNames.pivotItemsDisabled
                }} key={Section.metadata} itemKey={Section.metadata.toString()} headerText={t('metadata.pivotMetadata')} />
            </Pivot >
            {isDependenciesButtonVisible && 
                <PrimaryButton
                    text={t('updateDependencies')}
                    onClick={updateDependencies}
                />
            }
        </div> : <></>
    }

    const changeTab = async (item?: PivotItem, ev?: any) => {
        const hasNotBeenChanged: boolean = section === Section.metadata ?
            (!formData || (_.isEqual(initialFormState, formData))) : _.isEqual([...initialTags], [...tags]);

        const newSection = item && item.props.itemKey === Section.tag.toString() ?
            Section.tag : Section.metadata;

        if (!hasNotBeenChanged && !readonly) {
            ev.preventDefault();
            ev.stopPropagation();
            setShowCloseDialog(false);
            setShowChangeDialog({ isOpen: true, newSection: newSection })
        }
        else {
            setSection(newSection)
            if (item && item.props.itemKey === Section.metadata.toString()) {
                await loadFormData();
            }
        }
    }

    const confirmFormDialog = () => {
        return <Dialog
            hidden={!showFormConfirm}
            dialogContentProps={{ title: `${t('metadata.confirmSave')}` }}
        >
            {savingMetadata && <Spinner size={SpinnerSize.large} />}
            {success !== undefined && success === false && !savingMetadata && 
            <div className={classNames.errorForm}>{metadataError} <p>{t('metadata.tryAgain')}</p></div>}
            {!savingMetadata && <DialogFooter>
                <DefaultButton onClick={() => { setShowFormConfirm(false); setSuccess(undefined) }} text={t('common:dialogButtonCancel')} />
                <PrimaryButton onClick={async () => saveMetadata()} text={t('common:confirm')} />
            </DialogFooter>}
        </Dialog>
    }

    const content = () => {
        return (<div style={{ height: '100%' }}>
            {pivotHeader()}
            {section === Section.tag ? 
                tagContent() 
                : 
                (loading || loadingArchiveFromFile) ?  <Spinner size={SpinnerSize.large} />
                :
                archiveFromFile?.metadataJsonSchema !== undefined && archiveFromFile?.metadataJsonSchema !== null ? 
                    <>{settingsContent}{metadataFooter()}{confirmFormDialog()}</> 
                : 
                <>
                    <div className={classNames.emptyFolder}>
                        <TeamsImage
                            imageName={ImageName.EmptyFolderDrop}
                            fullContainer
                        />               
                        <div className={classNames.caption}>  
                            <span className={classNames.captionRow}>{t("common:noTags") + NoTagsFileExtension.join(', ')}</span>
                            <span className={classNames.captionRow}>{t("common:noMetadata")}</span>
                        </div>       
                    </div>
                </>
            }
        </div>)
    }

    const tagContent = () => {
        return <>
            {
                (loading || loadingArchiveFromFile) ? 
                    <div className={classNames.loadingErrorContainer}>
                        <Spinner size={SpinnerSize.large} />
                    </div> 
                    :
                    errorMessage ?
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> {errorMessage.toBeIndexed && <TeamsImage styles={{ img: { width: '40%', height: '40%' } }} imageName={ImageName.InProgress} />} <div>{errorMessage.text}</div></div>
                        :
                        tags.length > 0 ?
                            <div style={{ height: '100%' }}>{tags.map(entry => tagEntry(entry))}</div> 
                            :
                            <div>{t('common:emptyList')}</div>
            }
            {
                !loading && !loadingArchiveFromFile && !errorMessage && !readonly &&
                <div className={classNames.footer}>
                    <DefaultButton
                        style={{ marginRight: 8 }}
                        text={t("common:leave")}
                        onClick={(ev: any) => onPanelDismiss(ev)} />
                    <PrimaryButton
                        style={{ marginTop: 10 }}
                        text={t('common:save')}
                        onClick={() => setConfirmModal(true)}
                    />
                </div>
            }
            <Dialog
                hidden={!showConfirmModal}
                dialogContentProps={{ title: `${t('common:confirmSave')}` }}
            >
                <DialogFooter>
                    <DefaultButton onClick={() => setConfirmModal(false)} text={t('common:dialogButtonCancel')} />
                    <PrimaryButton onClick={saveTags} text={t('common:confirm')} />
                </DialogFooter>
            </Dialog>
        </>
    }

    const saveMetadata = async () => {
        try {
            setLoadingDoc(true);
            setSavingMetadata(true);
            await archivesApi.addFileMetadata(selectedList[0].id, formData);
            setSuccess(true);
            setShowFormConfirm(false);
            setIsVerifyUnivocityModalOpen(false);
            dispatch(showTagPanel({ ...tagPanelShown, isOpen: false }));
            dispatch(loadFolderContent({ resetPage: true }));
        }
        catch (ex: any) {
            switch (ex.subCode) {
                case 40401: setMetadataError(t('metadata.userNotFound')); break;
                case 40402: setMetadataError(t('metadata.fileNotFound')); break;
                case 40403: setMetadataError(t('metadata.noFileId')+`${ex.detail}`); break;
                case 40404: setMetadataError(t('metadata.noUserAccess')+`${ex.detail}`); break;
                case 40405: setMetadataError(t('metadata.fileJsonSchemaError')+`${ex.detail}`); break;
                case 40406: setMetadataError(t('metadata.errorMainProperty')+`${ex.detail}`); break;
                case 40407: setMetadataError(t('metadata.sameFile')+`${ex.detail}`); break;
                default: setMetadataError(t('metadata.errorSaving')); break;
            }
            setSuccess(false);
        }
        finally {
            setSavingMetadata(false);
            setLoadingDoc(false);
        }
    }

    const buildFilesNameWithMetadata = () => {
        const filesNameWithMetadataTemp: FileNameWithMetadata[] = [];
    
        selectedList.forEach(file => {
            const fileNameWithMetadataTemp: FileNameWithMetadata = {
                fileId: file.id,
                fileName: file.name ? file.name : '',
                metadata: formData
            }
            filesNameWithMetadataTemp.push(fileNameWithMetadataTemp);
        });
        setFilesNameWithMetadata(filesNameWithMetadataTemp);        
        setIsVerifyUnivocityModalOpen(true);
    }

    const metadataFooter = () => {
        return <div className={classNames.footerContainer}>
            {savingMetadata && <Spinner size={SpinnerSize.medium} />}
            {!readonly && 
            <div className={classNames.buttonFormContainer}>
                <span className={classNames.buttonFormContainerLeft}>* {t('metadata.requiredField')}</span>
                <div className={classNames.buttonFormContainerRight}>
                    <DefaultButton
                        disabled={loading || loadingArchiveFromFile}
                        onClick={(ev: any) => onPanelDismiss(ev)}
                        text={t('common:leave')}
                    />
                    <PrimaryButton
                        disabled={!formErrorsList || (formErrorsList && formErrorsList.length > 0) || loading || !formData  || loadingArchiveFromFile || (_.isEqual(initialFormState, formData) && notUsedUpdateDependencies)}
                        onClick={() => {
                            if(archiveUnivocityRules.length > 0)
                                buildFilesNameWithMetadata();
                            else
                                setShowFormConfirm(true);
                        }}
                        text={t('common:save')}
                    />
                </div>
            </div>}
        </div>
    }

    const jsonschemaValue = useMemo(() => {
        if (archiveFromFile)
            return {
                metadataJsonSchema: archiveFromFile.metadataJsonSchema,
                metadataUiSchema: archiveFromFile.metadataUISchema
            }
        else {
            return {
                metadataJsonSchema: currentArchive?.metadataJsonSchema,
                metadataUiSchema: currentArchive?.metadataUISchema
            }
        }
    }, [archiveFromFile, currentArchive]) 

    const formDataSetDependencies = (formDataJson: any) => {
        if (!formDataJson)
            return;

        if (formDataJson !== null && formDataJson !== undefined && formDataJson !== '' && formDataJson !== {} as any && section === Section.metadata && mainPropertiesList.length !== 0)
        {
            const formDataKey = Object.keys(formDataJson);
            if (formDataKey.some(x => mainPropertiesList.includes(x)) && !readonly) {
                setIsDependenciesButtonVisible(true);
            }
        }
    }

    const updateDependencies = async () => {
        let newFormData = formData;
        try {
            setLoading(true);
            newFormData = await archivesApi.updateFormDataDependencies(currentArchive?.id ?? 0, formData);
            newFormData = newFormData.formDataMergeDocument;
        }
        finally {
            setLoading(false);
        }

        setInitialFormState(newFormData);
        setJsonFormKey(jsonFormKey + 1);
        setNotUsedUpdateDependencies(false);
    }

    useEffect(() => {
        formDataSetDependencies(formData);
    }, [formData]) //eslint-disable-line react-hooks/exhaustive-deps

    const settingsContent = useMemo(() => {
        //setFirstRenderList({metadataPanel: firstRenderList.metadataPanel, tagPanel: false})
        return <div style={{ marginTop: 10 }}>
            {(loading  || loadingArchiveFromFile) ? 
            <div className={classNames.loadingErrorContainer}>
                <Spinner size={SpinnerSize.large} />
            </div> :
                apiErrorForm ? <div className={classNames.loadingErrorContainer}>
                    {t('metadata.errorApi')}
                </div> :
                    <JsonForm
                        hasErrorFE={() => setApiErroForm(true)}
                        onChangeSearchText={(str) => setSearchText(str)}
                        searchText={searchText}
                        disabled={readonly}
                        onError={(errors) => console.log("ERRORI: ", errors)}
                        onSubmit={() => console.log("submit")}
                        formData={{...initialFormState }}
                        schema={{ ...jsonschemaValue?.metadataJsonSchema }}
                        uiSchema={jsonschemaValue?.metadataUiSchema}
                        onChange={(ev) => {
                            setFormData({ ...ev.formData });
                            setFormErrorsList([...ev.errors]);
                        }}
                        errorsOnChangeFormData={(errors) => setFormErrorsList(errors)}
                    />}
        </div>
    }, [currentArchive, apiErrorForm, classNames.loadingErrorContainer, t, readonly, searchText, loading, loadingArchiveFromFile, jsonFormKey]); //eslint-disable-line react-hooks/exhaustive-deps

    const dialogContentProps = {
        title: showChangeDialog.isOpen ? t('changeTabMessage') : t('dialogMessage'),
    }

    const closeDialog = async () => {
        setFormData(initialFormState);
        if (showChangeDialog.isOpen) {
            setShowCloseDialog(false);
            setShowChangeDialog({ ...showChangeDialog, isOpen: false });
            setSection(showChangeDialog.newSection);
            await loadTags(true);
        }
        else if (showCloseDialog) {
            setShowCloseDialog(false);
            dispatch(showTagPanel({ ...tagPanelShown, isOpen: false }));
        }
        else {
            dispatch(showTagPanel({ ...tagPanelShown, isOpen: false }));
        }
    }

    const onPanelDismiss = (ev?: React.SyntheticEvent | KeyboardEvent) => {
        const hasNotBeenChanged: boolean = section === Section.metadata ?
            (!formData || (_.isEqual(initialFormState, formData) && notUsedUpdateDependencies))
            : _.isEqual([...initialTags], [...tags]);

        if (ev && !hasNotBeenChanged && !readonly) {
            ev.preventDefault();
            setShowCloseDialog(true);
        }
        else {
            closeDialog();
        }
    }

    useEffect(() => {
        if (documentUnivocityError !== undefined)
            setLoadingDoc(false);
    }, [documentUnivocityError]); //eslint-disable-line react-hooks/exhaustive-deps

    const onNextClick = () => {
        if (documentUnivocityError?.errorOnFileMetadataList.length !== 0 ||  documentUnivocityError?.notUnivocityDocumentList.length !== 0)
            setCurrentStep(VerifyUnivocitySteps.docVerify);
        else
            saveMetadata();
    }

    const docVerifyLoadingPage = () => {
        return (
            <DocVerifyLoadingPage
                folderId={[currentFolderId]}
                files={filesNameWithMetadata}
                onVerifyResult={(item) => setDocumentUnivocityError(item)}
                onNext={onNextClick}
                onClose={() => backOnMetadataChangePage()}
                loadingData={loadingDoc}
            />
        );
    }

    const docVerifyBody = () => {
        const emptyError: ResultDocumentsMetadataUnivocityWithError = {
            errorOnFileMetadataList: [],
            isUserBlocked: true,
            notUnivocityDocumentList: []
        }
        return (
            <DocVerifyModalBody
                item={documentUnivocityError ? documentUnivocityError : emptyError}
                requestSource={DocVerifySource.MetadataPanel}
            />
        );
    }

    const backOnMetadataChangePage = () => {
        setShowCloseDialog(false);
        setIsVerifyUnivocityModalOpen(false);
        setDocumentUnivocityError(undefined);
        setLoadingDoc(true);
        setCurrentStep(VerifyUnivocitySteps.docVerifyLoading);
    }

    const docVerifyFooter = () => {
        if (documentUnivocityError?.isUserBlocked !== undefined) {
            return(
                <DocVerifyModalFooter
                    isUserBlocked={documentUnivocityError.isUserBlocked}
                    documentUnivocityError={documentUnivocityError}
                    onClose={() => backOnMetadataChangePage()}
                    onBack={() => backOnMetadataChangePage()}
                    onNext={async () => saveMetadata()}
                    loading={loadingDoc}
                />
            );
        }
    }

    const scheduleSteps = [
        {
            bodyOnly: true,
            body: docVerifyLoadingPage()
        },
        {
            title: t('verificationResultTitle'),
            body: docVerifyBody(),
            footer: docVerifyFooter()
        }
    ];

    return <>
        <Panel
            headerTitle={t("title")}
            isOpen={tagPanelShown.isOpen}
            content={content()}
            isLightDismiss={true}
            onDismiss={onPanelDismiss}
            onDismissed={() => console.log("dismissed")}
            styles={
            isNoTagsExtension &&
            (archiveFromFile?.metadataJsonSchema === undefined || archiveFromFile?.metadataJsonSchema === null) &&
            !loading &&
            !loadingArchiveFromFile
                ? classNames.subComponentStyles.panelStyleNoItems
                : classNames.subComponentStyles.panelStyle
            }
            customWidth="690px"
        />
        {((showCloseDialog || showChangeDialog.isOpen) && !isVerifyUnivocityModalOpen)&& 
            <Dialog
                hidden={false}
                dialogContentProps={dialogContentProps}
            >
                <DialogFooter>
                    <DefaultButton onClick={() => { setShowCloseDialog(false); setShowChangeDialog({ ...showChangeDialog, isOpen: false }) }} text={t('common:dialogButtonCancel')} />
                    <PrimaryButton onClick={closeDialog} text={t('common:dialogButtonConfirmClose')} />
                </DialogFooter>
            </Dialog>
        }
        <MultiStepModal
            subtitle={''}
            isOpen={isVerifyUnivocityModalOpen}
            showCloseIcon={currentStep !== VerifyUnivocitySteps.docVerifyLoading}
            onCloseClick={() => backOnMetadataChangePage()}
            steps={scheduleSteps}
            activeStep={currentStep}
            height={520}
            width={755}
            animateInitialStep
        />
    </>
}