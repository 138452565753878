/*eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from "react";
import { classNamesFunction, IStackTokens, Stack, StackItem, TooltipHost } from "@fluentui/react";
import MemberList from "../../common/memberList/memberList";
import { IActivityCardProps, IActivityCardPropsStyles, IActivityCardStyles } from "./activityCard.types";
import { DateTime } from "luxon";
import ActivityType from "../../common/activityType/activityType";
import ActivityExpired from "../../common/activityExpired/activityExpired";
import { useTranslation } from "react-i18next";
import { isActivityExpired, isActivityNotStarted } from "../../common/utilities";
import InfoStatus from "../../infoStatus/infoStatus";
import { PersonaDetails } from "../../../../models/user";

const getClassNames = classNamesFunction<IActivityCardPropsStyles, IActivityCardStyles>();

export const ActivityCardBase = (props: IActivityCardProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [showInfo, setShowInfo] = useState(false)
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    const { t } = useTranslation(['activity', 'common']);
    const propertiesGap: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    return (
        <>
            <Stack verticalAlign="center" tokens={propertiesGap} className={classNames.root} >
                <StackItem>
                    <Stack horizontal tokens={{ childrenGap: 10 }} style={{justifyContent: 'space-between'}}>
                        <StackItem align="end" style={{ maxWidth: props.activity.templateActivityName ? '150px' : '300px', width: 'fit-content' }}>
                            <TooltipHost styles={classNames.subComponentStyles.tooltipStyle} content={props.activity.name}>
                                <div
                                    onClick={() => setShowInfo(true)}
                                    className={classNames.activityName} >
                                    {props.activity.name}
                                </div>
                            </TooltipHost>
                        </StackItem>
                        <StackItem style={{ maxWidth: props.activity.templateActivityName ? '140px' : '0px' }}>
                            <ActivityType activity={props.activity}></ActivityType>
                        </StackItem>
                    </Stack>
                </StackItem>
                <StackItem>
                    <Stack style={{ color: (isActivityNotStarted(props.activity.status, props.activity.startDate) ? palette.red : palette.black) }} horizontal tokens={{ childrenGap: 5 }}>
                        <StackItem style={{ fontSize: 12}}>{t('activitiesBoard.startDate')}</StackItem>
                        <StackItem style={{ fontSize: 12}}>{props.activity.startDate ? (DateTime.fromISO(props.activity.startDate.toString(), { zone: 'utc' })).toFormat('dd/MM/yyyy') : ''}</StackItem>
                    </Stack>
                </StackItem>
                <StackItem style={{ marginTop: 2, marginBottom: 8 }}>
                    <Stack style={{ color: (isActivityExpired(props.activity.status, props.activity.dueDate) ? palette.red : palette.black) }} horizontal tokens={{ childrenGap: 5 }}>
                        <StackItem style={{ fontSize: 12}}>{t('activitiesBoard.dueDate')}</StackItem>
                        <StackItem style={{ fontSize: 12}}>{props.activity.dueDate ? (DateTime.fromISO(props.activity.dueDate.toString(), { zone: 'utc' })).toFormat('dd/MM/yyyy') : ''}</StackItem>
                    </Stack>
                </StackItem>
                <StackItem>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <MemberList members={props.activity.members as PersonaDetails[]} />
                        <ActivityExpired activity={props.activity}></ActivityExpired>
                    </div>
                </StackItem>
            </Stack>
            {showInfo && <InfoStatus closeModal={() => setShowInfo(false)} activityId={props.activity.id} />}
        </>
    );
}