import { styled } from "@fluentui/react";
import { getStyles } from "./searchAndCompareResults.styles";
import { ISearchAndComparePropsStyles, ISearchAndCompareStyles, ISearchAndCompareProps } from "./searchAndCompareResults.types";
import { SearchAndCompareBase } from "./searchAndCompareResults.base";

const SearchAndCompareResults = styled<ISearchAndCompareProps, ISearchAndComparePropsStyles, ISearchAndCompareStyles>(
    SearchAndCompareBase,
    getStyles,
    undefined,
    { scope: 'SearchAndCompareResults' }
);

export default SearchAndCompareResults;