import { styled } from "@fluentui/react";
import { getStyles } from "./portfolioMembersSettings.styles";
import { IPortfolioMembersSettingsPropsStyles, IPortfolioMembersSettingsStyles, IPortfolioMembersSettingsProps } from "./portfolioMembersSettings.types";
import { PortfolioMembersSettingsBase } from "./portfolioMembersSettings.base";

const PortfolioMembersSettings = styled<IPortfolioMembersSettingsProps, IPortfolioMembersSettingsPropsStyles, IPortfolioMembersSettingsStyles>(
    PortfolioMembersSettingsBase,
    getStyles,
    undefined,
    { scope: 'PortfolioMembersSettings' }
);

export default PortfolioMembersSettings;