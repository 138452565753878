import { IStyleFunction } from "@fluentui/react"
import { ISecondPagePropsStyles, ISecondPageStyles } from "./secondPage.types";

export const SecondPageGlobalClassNames = {
    root: 'edi-document-translations-root',
    spinnerBody: 'edi-document-translations-spinner',
    spinnerMessage: 'edi-document-translations-spinner-message',
}

export const getStyles: IStyleFunction<ISecondPagePropsStyles, ISecondPageStyles> = (props: ISecondPagePropsStyles): ISecondPageStyles => {
    //const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [SecondPageGlobalClassNames.root, {
                
        }],
        spinnerBody: [SecondPageGlobalClassNames.spinnerBody,{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%'
        }],
        spinnerMessage: [SecondPageGlobalClassNames.spinnerMessage,{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20
        }],
    }
};

