import { styled } from "@fluentui/react";
import { getStyles } from "./graphWorkflowStatus.styles";
import { IGraphWorkflowStatusPropsStyles, IGraphWorkflowStatusStyles, IGraphWorkflowStatusProps } from "./graphWorkflowStatus.types";
import { GraphWorkflowStatusBase } from "./graphWorkflowStatus.base";

const GraphWorkflowStatus = styled<IGraphWorkflowStatusProps, IGraphWorkflowStatusPropsStyles, IGraphWorkflowStatusStyles>(
    GraphWorkflowStatusBase,
    getStyles,
    undefined,
    { scope: 'GraphWorkflowStatus' }
);

export default GraphWorkflowStatus;