import { styled } from "@fluentui/react";
import { getStyles } from "./results.styles";
import { IResultsPropsStyles, IResultsStyles, IResultsProps } from "./results.types";
import { ResultsBase } from "./results.base";

const Results = styled<IResultsProps, IResultsPropsStyles, IResultsStyles>(
    ResultsBase,
    getStyles,
    undefined,
    { scope: 'Results' }
);

export default Results;