import { IStyleFunction } from "@fluentui/react"
import { IGraphWorkflowUsersPropsStyles, IGraphWorkflowUsersStyles } from "./graphWorkflowUsers.types"

export const GraphWorkflowUsersGlobalClassNames = {
    root: 'edi-graph-workflow-users-root'
}

export const getStyles: IStyleFunction<IGraphWorkflowUsersPropsStyles, IGraphWorkflowUsersStyles> = (props: IGraphWorkflowUsersPropsStyles): IGraphWorkflowUsersStyles => {
    return {
        root: [
            GraphWorkflowUsersGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}