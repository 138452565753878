import { IStyleFunction } from "@fluentui/react"
import { IChoiceInputSourcePropsStyles, IChoiceInputSourceStyles } from "./choiceInputSource.types"

export const ChoiceInputSourceGlobalClassNames = {
    root: 'edi-set-properties-root',
    formItem: 'edi-set-properties-formItem',
    dropdownItem: 'edi-set-properties-dropdownItem',
    fileContainer: 'edi-set-properties-fileContainer',
    errorLoadingContainer: 'edi-set-properties-errorLoadingContainer'
}

export const getStyles: IStyleFunction<IChoiceInputSourcePropsStyles, IChoiceInputSourceStyles> = (props: IChoiceInputSourcePropsStyles): IChoiceInputSourceStyles => {
    // const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            ChoiceInputSourceGlobalClassNames.root,
            {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 20,
                alignItems: 'center'
            }
        ]
    };
}