import { styled } from "@fluentui/react";
import { IManageAssociationTopicUsersProps, IManageAssociationTopicUsersPropsStyles, IManageAssociationTopicUsersStyles } from "./manageAssociationTopicUsers.types";
import { getStyles } from "./manageAssociationTopicUsers.styles";
import { ManageAssociationTopicUsersBase } from "./manageAssociationTopicUsers.base";

const ManageAssociationTopicUsers = styled<IManageAssociationTopicUsersProps, IManageAssociationTopicUsersPropsStyles, IManageAssociationTopicUsersStyles>(
    ManageAssociationTopicUsersBase, 
    getStyles,
    undefined,
    { scope: 'ManageAssociationTopicUsers'}
)

export default ManageAssociationTopicUsers;