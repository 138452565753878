/*eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useMemo, useState } from "react";
import { classNamesFunction } from "@fluentui/utilities";
import { ICreateActivityProps, ICreateActivityPropsStyles, ICreateActivityStyles } from "./createActivity.types";
import { Dropdown, IDropdownOption, TextField, IPersonaProps, IBasePickerStyles, Label, DefaultButton, PrimaryButton, Icon, IBasePickerSuggestionsProps, mergeStyleSets, useTheme } from "@fluentui/react";
import { useOnMount } from "../../../../utilities/hooks";
import { useTranslation } from "react-i18next";
import { User } from "../../../../docLab/models/user";
import { mapper } from "../../../../utilities/mapper";
import InputTextTyped from "../../common/inputTextTyped/inputTextTyped";
import { useConst } from '@fluentui/react-hooks';
import { CreateActivityRequest } from "../../../services/activities/activities.contracts";
import { activitiesApi } from "../../../services/activities/activities.api";
import { IUser } from "../../../../common/interfaces/IUser";
import { templateApi } from "../../../services/template/template.api";
import { ActivityPriority, ActivityStatus } from "../../../models/constants";
import { ErrorsType } from "../../../../docLab/models/callsApi";
import DatePicker from "../../../../common/components/datePicker/datePicker";
import { DateTime } from "luxon";
import MultiStepModal from "../../../../common/components/multiStepModal/multiStepModal";
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import PeoplePicker from "../../../../common/components/peoplePicker/peoplePicker";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { useActivityLabDispatch } from "../../../activityLabStore";
import { loadActivities } from "../../../features/activityList";

const getClassNames = classNamesFunction<ICreateActivityPropsStyles, ICreateActivityStyles>();

export interface IExtPersonaProps extends IPersonaProps {
    userData: User;
}

enum CreateActivityStep {
    setupParameters = 0,
    spinner = 1,
    creating = 2,
}

export const CreateActivityBase = (props: ICreateActivityProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['createActivity', 'common']);
    const [showMinLengthAlert, setShowMinLengthAlert] = useState(true); //eslint-disable-line @typescript-eslint/no-unused-vars
    const dispatch = useActivityLabDispatch();

    const today = useConst(new Date(Date.now()));
    const tommorrow = () => {
        const result = new Date(today);
        result.setDate(today.getDate() + 1);
        return result;
    };

    // State for form validations
    const [activityName, setActivityName] = useState('');
    const [descriprionText, setDescriptionText] = useState('');
    const [startDateTime, setStartDateTime] = useState<Date | undefined>();
    const [endDateTime, setEndDateTime] = useState<Date | undefined>();
    const [minEndDateTime, setMinEndDateTime] = useState<Date>(tommorrow);
    const [priorityDropdown, setPriorityDropdown] = useState<IDropdownOption>();
    const [budgetText, setBudgetText] = useState<number>(0);
    const [budgetDropdown, setBudgetDropdown] = useState<IDropdownOption>();
    const [templateActivities, setTemplateActivities] = useState<IDropdownOption[]>([]);
    const [templateActivity, setTemplateActivity] = useState<IDropdownOption>();
    const [pickerBuffer, setPickerbuffer] = useState<IExtPersonaProps[]>([]);
    const [errorMessageFooter, setErrorMessageFooter] = useState('');
    const [currentStep, setCurrentStep] = useState<CreateActivityStep>(CreateActivityStep.setupParameters);
    const [selectedTemplateActivityKey, setSelectedTemplateActivityKey] = useState<number | null>();

    const optionsDropDown: IDropdownOption[] = [
        { key: ActivityPriority.P1, text: t('low') },
        { key: ActivityPriority.P2, text: t('medium') },
        { key: ActivityPriority.P3, text: t('high') }
    ]

    const budgetDropDown: IDropdownOption[] = [
        { key: "EUR", text: "€" },
        { key: "USD", text: "$" }
    ]

    const theme = useTheme();

    useOnMount(() => {
        async function fetch() {
            const result = await templateApi.getTemplateListForUser(props.portfolioId);
            const temp: IDropdownOption[] = [];

            result.forEach(item => {
                const option: IDropdownOption = {
                    key: item.id,
                    text: item.name
                }
                temp.push(option)
            })

            setTemplateActivities(temp);
        }
        fetch();

        setPriorityDropdown(optionsDropDown[0]);
    });

    useEffect(() => {
        if (startDateTime) {
            const temp = new Date(startDateTime);
            temp.setDate(startDateTime.getDate() + 1);
            setMinEndDateTime(temp);
        }
    }, [startDateTime]);

    useEffect(() => {
        if (templateActivity === undefined) {
            setSelectedTemplateActivityKey(null);
        }
        else {
            setSelectedTemplateActivityKey(Number(templateActivity?.key));
        }

    }, [templateActivity]);

    const searchForAvailableMembers = async (filter: string, selectedItems?: IPersonaProps[]) => {

        const selectedUsers = selectedItems ? selectedItems.map(x => mapPersonToUser(x)) : [];
        let filteredUser: IUser[] = []
        try {
            await getUsers(filter, selectedUsers)?.then(response => {
                const pickerBufferSelectedUserIds = pickerBuffer.map(user => user.userData.id);
                filteredUser = response.filter(user => !pickerBufferSelectedUserIds.includes(user.id))
            })
                .catch(error => {
                    console.log("error")
                    filteredUser = [];
                })
            if (filter.length > 2 && filteredUser.length === 0) {
                setShowMinLengthAlert(false)
            }
            else { setShowMinLengthAlert(true) }
            return filteredUser.map(user => mapUserToPersona(user));
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }

    const getUsers = async (keyword: string, currentUsers: IUser[]) => {
        if (keyword.length > 2) {
            return await activitiesApi.getActivityAvaiableLeaders({
                portfolioId: props.portfolioId,
                pageNumber: 0,
                pageSize: 20,
                keyword: keyword,
                userIds: currentUsers.map(x => x.id),
                excludeUser: false
            });
        }
        return []
    }

    const mergedClassName = mergeStyleSets({
        disclaimerZone: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 30
        },
        peoplePickerZone: {
            display: 'flex',
            marginBottom: '20px'
        },
        peoplePickerSuggestions: {
            padding: '8px 0'
        },
        peoplePickerSuggestionItem: {
            '::after': {
                display: 'none'
            },
            ".is-suggested": {
                background: theme.palette.neutralQuaternary
            }
        },
        detailsListRow: {
            background: 'transparent !important',
        },
        label: {
            fontSize: 24,
            fontWeight: 600,
            textAlign: 'center'
        },
        spinnerContainer: {
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '> :not(:last-child)': {
                marginBottom: 20
            }
        }
    });

    const mapPersonToUser = (person: IPersonaProps): IUser => {
        const converted: IExtPersonaProps | undefined = person as IExtPersonaProps;
        return {
            ...mapper.mapPersonaToUser(converted)
        }
    }

    const mapUserToPersona = (user: IUser): IExtPersonaProps => {
        return {
            ...mapper.mapUserToPersona(user),
            userData: user as User
        }
    }

    const addPeopleToPickerBuffer = (people?: IPersonaProps[]) => {
        const converted: IExtPersonaProps[] | undefined = people?.map(p => p as IExtPersonaProps);
        setPickerbuffer(converted ?? []);
    }

    const peoplePickerStyles = useMemo((): IBasePickerStyles => ({
        input: {
            backgroundColor: 'transparent'
        },
        text: {
            border: '1px solid rgb(240, 240, 240) !important',
            borderRadius: 2,
            backgroundColor: 'rgb(240, 240, 240)',
        },
        itemsWrapper: {
            ".ms-PickerPersona-container": {
                background: 'rgb(245, 245, 245)',
                border: "1px solid rgb(245, 245, 245)",
                ':hover': {
                    background: 'rgba(98, 100, 167, 0.2)',
                    border: "1px solid rgba(98, 100, 167, 0.2)",
                }
            },
        },
        root: {},
        screenReaderText: {}
    }), []);

    const validateForm = (): boolean => {
        if ((activityName && activityName.length > 0) && pickerBuffer.length > 0 && startDateTime !== undefined && endDateTime !== undefined)
            return true;

        return false;
    }

    const suggestionOptions: IBasePickerSuggestionsProps = {
        showRemoveButtons: false,
        suggestionsClassName: mergedClassName.peoplePickerSuggestions,
        suggestionsItemClassName: mergedClassName.peoplePickerSuggestionItem,
        loadingText: t('common:loading'),
        noResultsFoundText: showMinLengthAlert ? t('minLengthAlert') : t('noResults'),
    };

    const createActivity = async () => {
        const request: CreateActivityRequest = {
            templateId: templateActivity?.key as number,
            budget: budgetText,
            description: descriprionText,
            startDate: DateTime.fromJSDate(startDateTime ?? today).toFormat("dd/MM/yyyy"),
            dueDate: DateTime.fromJSDate(endDateTime ?? today).toFormat("dd/MM/yyyy"),
            leaders: pickerBuffer.map(u => u.userData.id),
            name: activityName,
            currency: budgetDropdown?.key as string,
            portfolioId: props.portfolioId,
            priority: priorityDropdown?.key as ActivityPriority
        }

        try {
            setCurrentStep(CreateActivityStep.spinner);
            await activitiesApi.createActivity(request);
            dispatch(loadActivities({
                activityStatus: ActivityStatus.Planned,
                forceRefresh: true,
                portfolioId: props.portfolioId,
                pageSize: undefined
            }));
            props.onCreated && props.onCreated(request.name);
            setCurrentStep(CreateActivityStep.creating);
        } catch (err) {
            setCurrentStep(CreateActivityStep.setupParameters);
            const error: ErrorsType = err as ErrorsType;
            if (error.code === 409) {
                setErrorMessageFooter(t("analysisAlreadyExist"))
                setActivityName('');
            }
            else {
                setErrorMessageFooter(t("error"))
            }
        }
    }

    const onRenderCaretDown = (): JSX.Element => {
        return <>
            <div>
                {templateActivity && <Icon iconName="Cancel" onClick={() => {
                    setTemplateActivity(undefined);
                }} />}
                <Icon iconName="ChevronDown" style={{ fontSize: '12px', paddingLeft: '10px' }} />
            </div>

        </>
    };

    const modalBody = () => {
        return (<>
            <div className={classNames.container}>
                <div style={{ width: '100%' }}>
                    <TextField
                        label={t('activityName')}
                        required
                        placeholder={t('activityNamePlaceholder')}
                        maxLength={250}
                        onChange={(_, newValue) => setActivityName(newValue || '')}
                        //errorMessage={activityName === '' ? t('common:fieldRequired') : undefined}
                        errorMessage={ undefined }
                        value={activityName}
                    />
                </div>

                <div style={{ width: '100%', height: 'auto' }}>
                    <Label>{t('description')}</Label>
                    <TextField
                        rows={3}
                        resizable={false}
                        multiline
                        maxLength={200}
                        onChange={(_, newValue) => setDescriptionText(newValue || '')}
                        value={descriprionText}
                    />
                </div>

                <div className={classNames.multiFormItem}>
                    <div style={{ width: '48%' }}>
                        <DatePicker
                            isRequired
                            key={"startDate"}
                            dateCallback={(newValue) => {
                                if (endDateTime && newValue > endDateTime) {
                                    setEndDateTime(undefined);
                                    setStartDateTime(newValue);
                                }
                                else
                                    setStartDateTime(newValue);
                            }}
                            minDate={today}
                            placeholder={t('selectDay')}
                            label={t('startDate')}
                            inputValue={startDateTime}
                            isRequiredErrorMessage= { '' }
                        />
                    </div>
                    <div style={{ width: '48%' }}>
                        <DatePicker
                            isRequired
                            key={"endDate"}
                            dateCallback={(newValue) => {
                                setEndDateTime(newValue);
                            }}
                            minDate={minEndDateTime}
                            placeholder={t('selectDay')}
                            label={t('endDate')}
                            inputValue={endDateTime}
                            isRequiredErrorMessage= { '' }
                        />
                    </div>
                </div>

                <div className={classNames.multiFormItem}>
                    <div style={{ width: '48%' }}>
                        <Label>{t('selectBudget')}</Label>
                        <InputTextTyped
                            inputValue={budgetText.toString()}
                            dropdownOptions={budgetDropDown}
                            dropdownValueCallback={(newValue?: IDropdownOption) => {
                                setBudgetDropdown(newValue);
                            }}
                            textValueCallback={(newValue: string) => {
                                const value = parseFloat(newValue);
                                if (value === 0) {
                                    setBudgetDropdown(undefined);
                                }

                                setBudgetText(value);
                            }}
                            dropdownPlaceholder={"€/$"}
                        />
                    </div>
                    <div style={{ width: '48%' }}>
                        <Label>{t('priority')}</Label>
                        <Dropdown
                            options={optionsDropDown}
                            selectedKey={priorityDropdown?.key}
                            onChange={(_, option) => setPriorityDropdown(option)}
                            defaultSelectedKey={optionsDropDown[0].key}
                            defaultValue={optionsDropDown[0].text}
                        />
                    </div>
                </div>

                <div className={classNames.multiFormItem}>
                    <div style={{ width: '48%' }}>
                        <PeoplePicker
                            onResolveSuggestion={searchForAvailableMembers}
                            pickerSuggestionsProps={suggestionOptions}
                            stylePeoplePicker={peoplePickerStyles}
                            resolveDelay={500}
                            selectedItems={pickerBuffer}
                            onChange={addPeopleToPickerBuffer}
                            placeholder={t("leadersPlaceholder")}
                            isRequired
                            //errorMessage={t('common:fieldRequired')}
                            errorMessage={ undefined }
                            title={t('toActivityLeader')}
                        />
                    </div>
                    <div style={{ width: '48%' }}>
                        <Label>{t('activityTemplate')}</Label>
                        <Dropdown
                            onRenderCaretDown={onRenderCaretDown}
                            options={templateActivities}
                            onChange={(_, option) => {
                                setTemplateActivity(option);
                            }}
                            selectedKey={selectedTemplateActivityKey}
                        />
                    </div>
                </div>
            </div>
        </>)
    }

    const footer = (
        <div>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', textAlign: 'left' }}>
                <Icon iconName="Group" style={{ width: '30px', height: '30px', fontSize: '20px', display: 'flex', alignItems: 'center' }} />
                <Label style={{ fontSize: '11px' }}>{t('messageForActivity')}</Label>
            </div>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ fontSize: '14px', color: 'rgb(164, 38, 44)' }}>
                    {errorMessageFooter}
                </div>
            </div>
            <div style={{ fontSize: '14px', display: 'flex' }} >
                <span className={classNames.requiredTextLeft}>* {t('common:fieldRequired')}</span> 
                <div style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', position: 'absolute', right: '40px' }} >
                    <DefaultButton style={{ margin: '0px 4px' }} onClick={props.onClose}>{t('common:cancel')}</DefaultButton>
                    <PrimaryButton onClick={createActivity} style={{ margin: '0px 4px' }} disabled={!validateForm()}>{t('common:create')}</PrimaryButton>
                </div>
            </div>
        </div>
    );

    const secondPageBodyModal = () => {
        return (
            <div className={classNames.secondFooterContainer}>
                <TeamsImage imageName={ImageName.Sandglass} className={classNames.wellDoneImage} fullContainer scale={0.8} />
                <div className={classNames.footer}>
                    {t('endMessage')}
                    <div>
                        <PrimaryButton text={t('end')}
                            allowDisabledFocus
                            onClick={props.onClose}
                            styles={{ root: { padding: '0px 80px', marginTop: '20px' } }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const steps = [
        {
            title: `${t("title")}`,
            body: modalBody(),
            footer: footer
        },
        {
            bodyOnly: true,
            body: <>
                <div className={mergedClassName.spinnerContainer}>
                    <TeamsSpinner />
                    <span className={mergedClassName.label}>{t('creatingActivity')}</span>
                </div>
            </>
        },
        {
            bodyOnly: true,
            body: secondPageBodyModal()
        },
    ]

    return (
        // <EdiModal
        //     width={600}
        //     height={600}
        //     isOpen={true}
        //     body={modalBody()}
        //     onCloseClick={props.onClose}
        //     showCloseIcon={true}
        //     title={t("title")}
        //     footer={footer}
        // />
        <MultiStepModal
            isOpen={true}
            width={600}
            height={600}
            showCloseIcon={currentStep === CreateActivityStep.setupParameters}
            onCloseClick={props.onClose}
            activeStep={currentStep}
            steps={steps}
            animateInitialStep
        />
    )
}