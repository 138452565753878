import React from "react";
import { classNamesFunction, Panel, PanelType } from "@fluentui/react";
import { IPanelPropsStyles, IPanelStyles, IPanelProps } from "./panel.types";

const getClassNames = classNamesFunction<IPanelPropsStyles, IPanelStyles>();

export const PanelBase = (props: IPanelProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className }); //eslint-disable-line @typescript-eslint/no-unused-vars

    return (
        <Panel
            headerText={props.headerTitle || ""}
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            closeButtonAriaLabel="Close"
            onDismissed={props.onDismissed}
            styles={props.styles}
            isLightDismiss={props.isLightDismiss}
            type={props.smallPanel ? PanelType.smallFixedFar : props.largePanel && !props.customWidth ? PanelType.large : PanelType.custom}
            customWidth={props.customWidth}
        >
            <div>
                {props.content}
            </div>
        </Panel>
    );
}