/* eslint-disable sonarjs/cognitive-complexity */
import { classNamesFunction, ConstrainMode, DetailsRow, IColumn, IDetailsRowProps, Label, Persona, PersonaSize, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack, StackItem, TooltipDelay, TooltipHost } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import IconTag from "../../../../../common/components/iconTag/iconTag";
import { Helpers } from "../../../../../utilities/helpers";
import { useAsyncApi, useOnMount } from "../../../../../utilities/hooks";
import { mapper } from "../../../../../utilities/mapper";
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive";
import { Constants } from "../../../../models/constants";
import { UserWithProfiles } from "../../../../models/userWithProfiles";
import { archivesApi } from "../../../../services/archives/archives.api";
import { IMemberViewBaseProps, IMemberViewBasePropsStyles, IMemberViewBaseStyles } from "./memberView.types";

const getClassNames = classNamesFunction<IMemberViewBasePropsStyles, IMemberViewBaseStyles>();

interface GetArchiveMembersWithProfilesParams {
    archiveId?: number;
    isAscending?: boolean; 
    keyword?: string; 
    resetPage: boolean; 
}

interface DefaultRequestData {
    archiveId: number;
    keyword: string;
    isAscending: boolean;
    pageNumber: number;
    pageSize: number;
}

const defaultRequestData: DefaultRequestData = {
    archiveId: 0, 
    keyword: '', 
    isAscending: true,
    pageNumber: 0,
    pageSize: Constants.PAGE_SIZE, 
}

export const MemberViewBase = (props: IMemberViewBaseProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const currentArchive = useCurrentArchive();
    const [deferredLoading, setDeferredLoading] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [membersProfile, setMembersProfile] = useState<UserWithProfiles[]>([]);
    const [data, setData] = useState<DefaultRequestData>(defaultRequestData);
    const { t } = useTranslation(['profileManagement', 'common']);
    const [noMore, setNoMore] = useState(true);

    const { execute: loadMembersWithProfiles, loading } = useAsyncApi<GetArchiveMembersWithProfilesParams, UserWithProfiles[]>({
        func: async (input: GetArchiveMembersWithProfilesParams): Promise<UserWithProfiles[]> => {
            if (!currentArchive)
                return [];

                const newData: DefaultRequestData = {
                    archiveId: currentArchive.id, 
                    pageSize: data.pageSize,
                    pageNumber: input.resetPage ? 0 : data.pageNumber + 1,
                    isAscending: input.isAscending ?? data.isAscending, 
                    keyword: input.keyword || ''
                }

                setData(newData);

                if (input.resetPage)
                    setNoMore(false);

                const response = await archivesApi.getArchiveMembersWithProfiles({
                    archiveId: newData.archiveId,
                    pageNumber: newData.pageNumber,
                    pageSize: newData.pageSize,
                    isAscending: newData.isAscending, 
                    keyword: newData.keyword
                });

                setNoMore(newData.pageSize > response?.length || response?.length === 0);

                const result = input.resetPage 
                    ? response 
                    : (membersProfile 
                        ? membersProfile.concat(response) 
                        : response);

                setMembersProfile(result); 

                return result;
            }
        });

    useOnMount(() => {
        registerEvent();
        if (!currentArchive)
            return;

        const action = loadMembersWithProfiles({
            resetPage: true
        });
        
        Helpers.deferred(action, setDeferredLoading, 200, 250);
    });

    const registerEvent = useCallback(() => {
        const listElm = document.querySelector('#memberviewDetailsListZone .ms-DetailsList-contentWrapper'); //#modalListZone .ms-DetailsList-contentWrapper
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50) {
                const button = document.getElementById("click_FolderContent");
                button && button.click();
            }
        });
    }, []);

    const sortByColumn = (_: React.MouseEvent, column: IColumn) => {
        if (loading || !currentArchive)
            return;

        const action = loadMembersWithProfiles({
            isAscending: !data.isAscending, 
            keyword: keyword, 
            resetPage: true,
        });

        Helpers.deferred(action, setDeferredLoading, 200, 250);
    };

    const loadMore = useCallback(() => {
        if (loading || noMore) return;
        const action = loadMembersWithProfiles({resetPage: false});
        
        Helpers.deferred(action, setDeferredLoading, 200, 250);
    }, [loading, noMore, loadMembersWithProfiles]);

    const remainingMembersTooltip = (remainingMembersList: string[]): JSX.Element => {
        return (
            <Stack horizontalAlign={'start'} tokens={{ childrenGap: 2 }}>
                {remainingMembersList.map((x, i) => <Label key={i} className={classNames.remainingIconTagContainer}>{x}</Label>)}
            </Stack>);
    }

    const columns: IColumn[] = [
        {
            key: 'persona',
            name: t('common:name'),
            minWidth: 100,
            maxWidth: 350,
            headerClassName: classNames.headerRow,
            onColumnClick: sortByColumn,
            isSorted: true,
            isSortedDescending: !data.isAscending,
            onRender: function renderMemberPersona(member: UserWithProfiles) {
                return (
                    <Persona {...mapper.mapUserToPersona(member)} size={PersonaSize.size40} coinSize={32} />
                );
            }
        },         
        {
            key: 'profile',
            name: t('memberProfileView.profiles'),
            minWidth: 150,
            headerClassName: classNames.headerRow,
            onRender: function renderMemberPersona(member: UserWithProfiles) {
                const membersList = member.profileNames.slice(0, 3);
                const remainingMembersList = member.profileNames.slice(3, member.profileNames.length);
                return (
                    <div className={classNames.containerTag}>
                        {membersList.map((u, i) => {
                            return (
                                <IconTag key={u+i} label={u} labelClassName={classNames.iconTagLabel} tooltip />                               
                            )
                        })}
                        {remainingMembersList.length > 0 &&
                            <TooltipHost
                                id={'remainingMemberTooltips'}
                                content={remainingMembersTooltip(remainingMembersList)}
                                delay={TooltipDelay.zero}>
                                <Label
                                    styles={classNames.subComponentStyles.templatesCountCircle}>+{remainingMembersList.length}
                                </Label>
                            </TooltipHost>
                        }
                    </div>
                )
            }
        },
        // {
        //     key: "remove",
        //     name: "",
        //     minWidth: 40,
        //     headerClassName: classNames.headerRow,
        //     onRender: function renderMemberRemoveButton(member: UserWithProfiles) {
        //         return (
        //             currentArchive && !currentArchive.managedByIag && 
        //                 <RemoveMemberButton
        //                     portfolioId={currentPortfolioId}
        //                     buttonStyles={classNames.subComponentStyles.iconButton()}
        //                     member={member}
        //                     onRemoveMemberCompleted={async () => await loadMembers(memberSearchKeyword)}
        //                 />
        //         )
        //     }                        
        // }, 
    ];

    return (

        <Stack className={classNames.root}>

            <StackItem>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <SearchBox
                        styles={{ root: { width: 300, marginRight: '15px' } }}
                        placeholder={t("memberProfileView.search")}
                        onSearch={(newValue) => {
                            setKeyword(newValue || '')
                            loadMembersWithProfiles({ keyword: newValue, resetPage: true, })
                        }}
                        onClear={() => {
                            setKeyword('')
                            loadMembersWithProfiles({ keyword: '', resetPage: true, })
                        }}
                    />
                </div>
            </StackItem>
                                                          
            <div id={"memberviewDetailsListZone"} >
                <ShimmeredDetailsList
                    items={membersProfile ?? []}
                    styles={classNames.subComponentStyles.shimmeredDetailsList}
                    detailsListStyles={classNames.subComponentStyles.detailsList}
                    columns={columns}
                    getKey={(item: UserWithProfiles) => item && item.id}
                    selectionMode={SelectionMode.none}
                    enableShimmer={data.pageNumber === 0 && (deferredLoading || loading)}
                    constrainMode={ConstrainMode.unconstrained}
                    onShouldVirtualize={() => false}
                    isHeaderVisible={true}        
                    onRenderRow={(props?: IDetailsRowProps) =>
                        props ? <DetailsRow {...props} styles={classNames.subComponentStyles.row} /> : null}                                    
                />
            </div>

            <div className={classNames.load}>
                <PrimaryButton id={noMore ? "noClick_FolderContent" : "click_FolderContent"} onClick={loadMore} />
            </div>     
                
        </Stack>        
    )
}