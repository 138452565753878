import { styled } from "@fluentui/react";
import { getStyles } from "./tocSettings.styles";
import { ITocSettingsPropsStyles, ITocSettingsStyles, ITocSettingsProps } from "./tocSettings.types";
import { TocSettingsBase } from "./tocSettings.base";

const TocSettings = styled<ITocSettingsProps, ITocSettingsPropsStyles, ITocSettingsStyles>(
    TocSettingsBase,
    getStyles,
    undefined,
    { scope: 'TocSettings' }
);

export default TocSettings;