import { ILabelStyles, IStyleFunction } from "@fluentui/react"
import { IMemberListPropsStyles, IMemberListStyles } from "./memberList.types"

export const MemberListGlobalClassNames = {
    root: 'edi-member-list-root'
}

export const getStyles: IStyleFunction<IMemberListPropsStyles, IMemberListStyles> = (props: IMemberListPropsStyles): IMemberListStyles => {
    const { semanticColors, palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            MemberListGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],        
        remainingIconTagContainer: {
            padding: '0 5px',
            width: '100%',
            textAlign: 'left',
            justifyContent: 'start',
            color: palette.themePrimary
        },
        memberStyle:{
            backgroundColor: palette.yellow, 
            fontWeight: 'bold', 
            color: palette.white, 
            width: '35px',
            height: '35px', 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 8, 
        },
        activityMemberStyle:{
            backgroundColor: palette.themePrimary, 
            fontWeight: 'bold',  
            color: palette.white, 
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 8, 
        },
        activityLeaderStyle:{
            backgroundColor: palette.themeTertiary, 
            fontWeight: 'bold',  
            color: palette.themePrimary,
            width: '30px',
            height: '30px',  
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 8, 
        },
        subComponentStyles: {
            templatesCountCircle: (): ILabelStyles => {
                return {
                    root: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        fontSize: '12px',
                        backgroundColor: semanticColors.variantBorder,
                        color: palette.themePrimary,
                    }
                }
            }
        }
    };
}