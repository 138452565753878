/*eslint-disable sonarjs/cognitive-complexity */
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { DefaultButton, DetailsList, DetailsListLayoutMode, DetailsRow, IColumn, Icon, IconButton, IDetailsColumnStyleProps, IDetailsColumnStyles, IDetailsListStyles, IDetailsRowProps, IIconStyles, mergeStyleSets, SelectionMode, Stack, StackItem, TextField, Toggle, TooltipHost, useTheme } from "@fluentui/react";
import React, { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import TeamsImage from "../../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../../common/components/teamsImage/teamsImage.types";
import { Helpers } from "../../../../../../utilities/helpers";
import { useOnMount } from "../../../../../../utilities/hooks";
import { Constants } from "../../../../../models/constants";
import { TemplatePhase } from "../../../../../models/templatePhase";
import { templateApi } from "../../../../../services/template/template.api";
import Phase from "../phase/phase";

export type SecondPageData = {
    phases: TemplatePhase[];
}

export interface ICreateTemplateSecondPageProps {
    initialState: SecondPageData;
    edit?: boolean;
    templateActivityId?: number;
    onTemplateDataChange: (input: SecondPageData) => void;
}

export const CreateTemplateSecondPage = (props: ICreateTemplateSecondPageProps) => {
    const { t } = useTranslation(['createActivityTemplate', 'common']);
    const theme = useTheme();
    const [data, setData] = useState<SecondPageData>(props.initialState);
    const [name, setName] = useState('');
    const [alreadyExists, setAlreadyExists] = useState(false)
    const [description, setDescription] = useState('');
    const idNameTextField = "phase name";
    const [isLoading, setIsLoading] = useState(false);

    useOnMount(() => {
        if (props.edit) { getTemplateActivity(); }
    });

    const getTemplateActivity = async () => {
        if (props.templateActivityId) {
            setIsLoading(true)
            const [templateActivity] = await Promise.all([templateApi.getTemplateActivityById(props.templateActivityId), Helpers.delay(500)]);
            setData({ phases: templateActivity.templatePhases })
            props.onTemplateDataChange({ phases: templateActivity.templatePhases });
            setIsLoading(false)
        } else {
            props.onTemplateDataChange(data);
        }
    }

    const classNames = mergeStyleSets({
        formItem: [
            'edi-create-template-modal-form-item',
            {
                ':not(:first-child)': {
                    marginTop: 10
                },
            }
        ],
        headerRow: [
            'edi-create-template-modal-form-headerRow',
            {
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                }
            }
        ],
        headerIcon: ['edi-create-template-modal-form-headerIcon', {
            marginLeft: '5px',
            cursor: 'pointer'
        }],
        warningMessage: ['edi-create-template-modal-form-warningMessage', {
            color: theme.palette.orangeLighter,
            fontSize: 12
        }],
        subComponentStyles: {
            list: (): IDetailsListStyles => {
                return {
                    root: {},
                    contentWrapper: {
                    },
                    focusZone: {},
                    headerWrapper: {
                        '> .ms-DetailsHeader': {
                            borderBottomColor: 'transparent',
                            background: 'transparent'
                        }
                    }
                }
            },
            icon: (): IIconStyles => {
                return {
                    root: {
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginBottom: 15,
                    }
                }
            },
            column: (props: IDetailsColumnStyleProps): IDetailsColumnStyles => {
                return {
                    root: {},
                    gripperBarVerticalStyle: {},
                    cellTooltip: {},
                    cellTitle: {},
                    cellName: {
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        alignItems: 'center',
                        overflow: 'initial'
                    },
                    iconClassName: {},
                    nearIcon: {},
                    accessibleLabel: {},
                    sortIcon: {},
                    filterChevron: {},
                    borderAfterDropping: {},
                    noBorderAfterDropping: {},
                    borderWhileDragging: {},
                    noBorderWhileDragging: {},
                }
            },
            toggleColumn: (props: IDetailsColumnStyleProps): IDetailsColumnStyles => {
                return {
                    root: {},
                    gripperBarVerticalStyle: {},
                    cellTooltip: {},
                    cellTitle: {
                        justifyContent: 'center'
                    },
                    cellName: {
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        alignItems: 'center',
                        overflow: 'initial'
                    },
                    iconClassName: {},
                    nearIcon: {},
                    accessibleLabel: {},
                    sortIcon: {},
                    filterChevron: {},
                    borderAfterDropping: {},
                    noBorderAfterDropping: {},
                    borderWhileDragging: {},
                    noBorderWhileDragging: {},
                }
            }
        }
    })

    const reload = (newOrderedList: TemplatePhase[]) => {
        newOrderedList.forEach((element, ind) => {
            element.order = ind;
            element.id = ind;
        });
        setData({ phases: newOrderedList })
        props.onTemplateDataChange({ phases: newOrderedList });
    }

    const deleteItem = (item: TemplatePhase) => {
        const list = [...data.phases];
        const newList = list.filter(element => element !== item);
        reload(newList);
    }

    const moveItem = (item: TemplatePhase, up: boolean) => {
        const list = [...data.phases];
        if (up) {
            const index = list.indexOf(item)
            const previousItem = list[index - 1];
            list[index - 1] = item;
            list[index] = previousItem
        }
        else {
            const index = list.indexOf(item)
            const nextItem = list[index + 1];
            list[index + 1] = item;
            list[index] = nextItem;
        }
        reload(list);
    }

    const columns: IColumn[] = useMemo(() => [{
        key: 'settedPhase',
        name: t('columns.settedPhases'),
        fieldName: '',
        minWidth: 200,
        maxWidth: 400,
        headerClassName: classNames.headerRow,
        styles: classNames.subComponentStyles.column,
        onRender: function getItemCell(item: TemplatePhase) {
            return (
                <div key={item.name}>
                    <Stack horizontal>
                        <StackItem style={{ marginRight: 10, display: 'flex', flexDirection: 'column' }}>
                            <IconButton
                                styles={{ root: { height: '28px' } }}
                                onClick={() => deleteItem(item)}
                                iconProps={{ iconName: "Delete" }} />
                            <IconButton
                                disabled={item.order === 0}
                                styles={{ root: { height: '28px', transform: 'rotate(180deg)' } }}
                                onClick={() => moveItem(item, true)}
                                iconProps={{ iconName: 'DrillDownSolid' }} />
                            <IconButton
                                disabled={item.order === data.phases.length - 1}
                                onClick={() => moveItem(item, false)}
                                styles={{ root: { height: '28px' } }}
                                iconProps={{ iconName: 'DrillDownSolid' }} />
                        </StackItem>
                        <Phase name={item.name.trim()} description={item.description.trim()} />
                    </Stack>
                </div>)
        }
    }, {
        key: 'channelTeams',
        name: t('columns.channelTeams'),
        fieldName: 'channelTeams',
        minWidth: 10,
        maxWidth: 10,
        styles: classNames.subComponentStyles.toggleColumn,
        headerClassName: classNames.headerRow,
        onRenderHeader: function toggleHeader() {
            return <div>
                <span>{t('columns.channelTeams')}</span>
                <TooltipHost content={t('toogleTooltip')}><Icon
                    styles={{ root: { marginLeft: '5px', cursor: 'pointer' } }}
                    iconName="Info"
                /></TooltipHost></div>
        },
        onRender: function getItemName(item: TemplatePhase) {
            return <div style={{ textAlign: 'center' }}><Toggle
                checked={item.channel}
                styles={{ root: { marginBottom: 0 }, container: { justifyContent: 'center' } }}
                onChange={(ev, checked) => changeToggleHandler(item, checked)}
            /></div>
        }
    }], [t, classNames.subComponentStyles.column, classNames.headerRow, data.phases.length]); //eslint-disable-line react-hooks/exhaustive-deps

    const changeToggleHandler = (item: TemplatePhase, checked?: boolean) => {
        if (checked === undefined)
            return;
        const list = [...data.phases];
        list[list.indexOf(item)].channel = checked;
        setData({ phases: list });
        props.onTemplateDataChange(data);
    }

    const emptyList = useCallback(() => {
        if (data.phases.length !== 0)
            return null;
        return (
            <div style={{ minHeight: 100 }}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    styles={{ img: { width: '25%' } }}
                    caption={t("common:emptyList")}
                />
            </div>
        )
    }, [data.phases, t]);

    const listPhases = () => {
        return (
            isLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}><TeamsSpinner /></div> :
                <div>
                    <DetailsList
                        styles={classNames.subComponentStyles.list}
                        layoutMode={DetailsListLayoutMode.justified}
                        items={data.phases || []}
                        onRenderDetailsFooter={emptyList}
                        getKey={(item: TemplatePhase) => item.order.toString()}
                        onRenderRow={(props?: IDetailsRowProps) => {
                            if (!props) return null;
                            return (
                                <>
                                    <DetailsRow
                                        styles={{
                                            root: { background: 'transparent !important' },
                                            fields: { alignItems: 'center' },
                                            cell: { paddingLeft: 0 }
                                        }} {...props} />
                                </>
                            )
                        }}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                    /></div>
        )
    }

    const addPhase = () => {
        const list = [...data.phases];
        const newPhase: TemplatePhase = {
            id: list.length,
            channel: true,
            description: description.trim(),
            name: name.trim(),
            templateActivityId: 0,
            order: list.length
        }
        setData({ ...data, phases: list.concat(newPhase) });
        props.onTemplateDataChange({ phases: list.concat(newPhase) });
        setName("");
        setDescription("");
    }

    const buttonContainer = () => {
        return <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className={classNames.warningMessage}>{data.phases.length === Constants.MAX_PHASES_NUMBER && t('maxLength')}</div>
            <div style={{ padding: '10px 0 0' }}>
                <TooltipHost content={data.phases.length < Constants.MAX_PHASES_NUMBER ? t('addPhase') : ''}>
                    {/* <IconButton
                        iconProps={{ iconName: 'Add' }}
                        disabled={data.phases.length === Constants.MAX_PHASES_NUMBER || name.trim() === "" || alreadyExists}
                        onClick={addPhase}
                    /> */}
                    <DefaultButton
                        text={t("addPhase")}
                        disabled={data.phases.length === Constants.MAX_PHASES_NUMBER || name.trim() === "" || alreadyExists}
                        onClick={addPhase}
                    />
                </TooltipHost>

            </div></div>
    }

    const onChangeHandler = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue === undefined)
            return;
        if (ev.currentTarget.id === idNameTextField) {
            setName(newValue);
            setAlreadyExists(false);
        }
        else
            setDescription(newValue)
    }

    const validatePhaseName = useCallback((value: string) => {
        if (!value || value.trim() === "")
            return ""; //return `${t('common:fieldRequired')}`;

        if (data.phases.find(phase => phase.name.trim().toLowerCase() === value.trim().toLowerCase())) {
            setAlreadyExists(true);
            return t('phaseNameError');
        }
    }, [t, data.phases]);


    return (
        <div>
            <div style={{ marginBottom: '10px' }}>{props.edit ? t('subEditTitle') : t('text')}</div>
            <div className={classNames.formItem}>
                <TextField
                    onGetErrorMessage={validatePhaseName}
                    maxLength={Constants.MAX_LENGTH_TEMPLATE_NAME}
                    placeholder={t('namePhasePlaceholder')}
                    required
                    value={name}
                    onChange={(ev, newValue) => onChangeHandler(ev, newValue)}
                    id={idNameTextField}
                    autoFocus
                    autoComplete={"off"}
                    styles={{ root: { width: 'calc(100% - 10px)' } }}
                />
            </div>
            <div className={classNames.formItem}>
                <TextField
                    maxLength={Constants.MAX_LENGTH_TEMPLATE_DESCRIPTION}
                    resizable={false}
                    rows={4}
                    multiline
                    value={description}
                    onChange={(ev, newValue) => onChangeHandler(ev, newValue)}
                    placeholder={t('descriptionPhasePlaceholder')}
                    styles={{ root: { width: 'calc(100% - 10px)' } }}
                />
            </div>
            {buttonContainer()}
            {listPhases()}
        </div>
    )
}