import { usePortfolioList } from "../features/portfolioList";
import { Portfolio } from "../models/portfolio";
import { useNavigator } from "./useNavigator";

export const useCurrentPortfolio = (): Portfolio | undefined => {
    const { currentPortfolioId } = useNavigator();
    const { portfolioList } = usePortfolioList();

    if (!currentPortfolioId)
        return;

    return portfolioList[currentPortfolioId];
}