import { styled } from "@fluentui/react";
import { getStyles } from "./knowledgeEdiFileSelector.styles";
import { IKnowledgeEdiFileSelectorPropsStyles, IKnowledgeEdiFileSelectorStyles, IKnowledgeEdiFileSelectorProps } from "./knowledgeEdiFileSelector.types";
import { KnowledgeEdiFileSelectorBase } from "./knowledgeEdiFileSelector.base";

const KnowledgeEdiFileSelector = styled<IKnowledgeEdiFileSelectorProps, IKnowledgeEdiFileSelectorPropsStyles, IKnowledgeEdiFileSelectorStyles>(
    KnowledgeEdiFileSelectorBase,
    getStyles,
    undefined,
    { scope: 'KnowledgeEdiFileSelector' }
);

export default KnowledgeEdiFileSelector;