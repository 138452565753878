import { generatePath } from "react-router-dom";
import { AppRoutes, AppTab } from "../../app/utilities/routes";
import { AnalysisTypeId } from "../models/docAnalysis";

export enum ArchiveTab {
    dashboard = 'dashboard',
    export = 'export-archive',
    files = 'files',
    search = 'search',
    docAnalysis = 'doc-analysis'
}

export enum ArchiveSettingsTab {
    main = 'main',
    users = 'users',
    search = 'search',
    docAnalysis = 'doc-analysis',
    exportReport = 'export-report',
    profileManagement = 'profile-management',
    importExportMetadta = 'import-export-metadata',
    recycleBin = 'recycle-bin',
    topicsOfInterestManagement = 'topics-of-interest-management',
    interestsManagement = 'interests-management'
}

class DocLabRoutesClass {
    public docLab = AppRoutes.generateRouteForTab(AppTab.docLab);
    public archives = `${this.docLab}/archives`;
    public archive = `${this.archives}/:archiveId(\\d+)`;
    public archiveTab = `${this.archive}/:tab(${ArchiveTab.dashboard}|${ArchiveTab.files}|${ArchiveTab.search}|${ArchiveTab.docAnalysis})`;
    public docLabOptionals = `${this.docLab}/:archives(archives)?/:archiveId(\\d+)?/:tab(${ArchiveTab.dashboard}|${ArchiveTab.files}|${ArchiveTab.search}|${ArchiveTab.docAnalysis})?`;

    generateArchivePath(archiveId: number) {
        return generatePath(`${this.archives}/:archiveId`, { archiveId: archiveId });
    }

    generateAnalysisPath(archiveId: number, analysisId: number, type: AnalysisTypeId) {
        return generatePath(`${this.generateArchivePath(archiveId)}/${ArchiveTab.docAnalysis}/:type/:analysisId`, { type: type, analysisId: analysisId });
    }

    generateArchiveTabPath(archiveId: number, tab: ArchiveTab) {
        return `${this.generateArchivePath(archiveId)}/${tab}`;
    }

    generateArchiveSettingsTabPath(archiveId: number, tab: ArchiveSettingsTab) {
        return `${this.generateArchivePath(archiveId)}/settings/${tab}`;
    }
}

export const DocLabRoutes = new DocLabRoutesClass();