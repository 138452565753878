import React from "react";
import { classNamesFunction } from "@fluentui/react";
import { Provider } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import docLabStore from "../../../docLab/docLabStore";
import DocLabRoot from "../../../docLab/components/common/root/root";
import { AppRoutes, AppTab } from "../../utilities/routes";
import Header from "../header/header";
import { ITeamsProps, ITeamsPropsStyles, ITeamsStyles } from "./teams.types";
import { useMemo } from "react";
import { useOnMount } from "../../../utilities/hooks";
import activityLabStore from "../../../activityLab/activityLabStore";
import knowledgeLabStore from "../../../knowledgeLab/knowledgeLabStore";
import knowledgeDsStore from "../../../knowledgeDS/knowledgeDsStore";
import ActivityLabRoot from "../../../activityLab/components/common/root/root";
import KnowledgeLabRoot from "../../../knowledgeLab/components/common/root/root";
import KnowledgeDsRoot from "../../../knowledgeDS/components/common/root/root";
import DashboardRoot from "../../../dashboard/components/common/root/root";
import dashboardStore from "../../../dashboard/dashboardStore";


const getClassNames = classNamesFunction<ITeamsPropsStyles, ITeamsStyles>();

export const TeamsBase = (props: ITeamsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const docLabRoute = useMemo(() => AppRoutes.generateRouteForTab(AppTab.docLab), []);
    const activityLabRoute = useMemo(() => AppRoutes.generateRouteForTab(AppTab.activityLab), []);
    const knowledgeLabRoute = useMemo(() => AppRoutes.generateRouteForTab(AppTab.knowledgeLab), []);
    const knowledgeDsRoute = useMemo(() => AppRoutes.generateRouteForTab(AppTab.knowledgeDS), []);
    const dashboardRoute = useMemo(() => AppRoutes.generateRouteForTab(AppTab.dashboard), []);

    const history = useHistory();
    useOnMount(() => {
        if (props.teamsContext?.subEntityId) {
            history.replace(atob(props.teamsContext?.subEntityId))
        }
    })

    return (
        <div className={classNames.root}>
            <Header />
            <Switch>
                <Route path={docLabRoute}>
                    <Provider store={docLabStore}>
                        <DocLabRoot ctx={props.teamsContext} />
                    </Provider>
                </Route>
                <Route path={activityLabRoute}>
                    <Provider store={activityLabStore}>
                        <ActivityLabRoot ctx={props.teamsContext} />
                    </Provider>
                </Route>
                <Route path={knowledgeLabRoute}>
                    <Provider store={knowledgeLabStore}>
                        <KnowledgeLabRoot ctx={props.teamsContext}></KnowledgeLabRoot>
                    </Provider>
                </Route>
                <Route path={knowledgeDsRoute}>
                    <Provider store={knowledgeDsStore}>
                        <KnowledgeDsRoot ctx={props.teamsContext}></KnowledgeDsRoot>
                    </Provider>
                </Route>
                <Route path={dashboardRoute}>
                    <Provider store={dashboardStore}>
                        <DashboardRoot ctx={props.teamsContext}></DashboardRoot>
                    </Provider>
                </Route>
            </Switch>
        </div>
    );
}