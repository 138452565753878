/*eslint-disable sonarjs/cognitive-complexity */
import { classNamesFunction, DetailsHeader, DetailsList, DetailsRow, IColumn, Persona, PersonaSize, PrimaryButton, ScrollablePane, SelectionMode, Spinner, SpinnerSize, Stack, StackItem, TooltipHost } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "../../../../../../common/components/accordion/accordion";
import { FileExtensions, SharePointDocumentPathSuffixes } from "../../../../../../docLab/models/constants";
import { Helpers } from "../../../../../../utilities/helpers";
import { mapper } from "../../../../../../utilities/mapper";
import { useCurrentPortfolio } from "../../../../../hooks/useCurrentPortfolio";
import { useNavigator } from "../../../../../hooks/useNavigator";
import { WorkFlow, WorkFlowRelativeStatus, WorkFlowStatus } from "../../../../../models/workflow";
import { WorkFlowStep, WorkFlowStepStatus } from "../../../../../models/workflowStep";
import { ActivityTab } from "../../../../../utilities/routes";
import WorkFlowApprovalStep from "../../workflowApprovalStep/workflowApprovalStep";
import { WorkFlowDetails, WorkFlowStepDetails } from "../common";
import StepStatus from "../stepStatus/stepStatus";
import { IWorkflowDetailsProps, IWorkflowDetailsPropsStyles, IWorkflowDetailsStyles } from "./workflowDetails.types";

const getClassNames = classNamesFunction<IWorkflowDetailsPropsStyles, IWorkflowDetailsStyles>();

export const WorkflowDetailsBase = (props: IWorkflowDetailsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['workflowDetails', 'stepStatus', 'common']);
    const [workFlow, setWorkFlow] = useState<WorkFlowDetails<WorkFlow>>();
    const [workFlowStep, setWorkFlowStep] = useState<WorkFlowStep>();
    const [isWFAssignedToMe, setIsWFAssignedToMe] = useState<boolean>(false);
    const navigator = useNavigator();
    const currentPortfolio = useCurrentPortfolio();

    useEffect(() => {
        if (props.workFlow) {
            setWorkFlow({
                workFlow: props.workFlow,
                stepsDetails: props.workFlow.steps.sort((a, b) => a.order > b.order ? 1 : -1).map((x, i) => {
                    return {
                        step: { ...x },
                        prevStep: (x.order !== 0) ? props.workFlow?.steps.filter(s => s.order === (x.order - 1))[0] : undefined,
                        isLastStep: x.order === ((props.workFlow?.steps.length || 1) - 1),
                        isUnusedStep: props.workFlow?.status === WorkFlowStatus.Refused && x.status === WorkFlowStepStatus.Idle
                    }
                })
            });
            setWorkFlowStep(props.workFlow.steps.filter(x => x.status === WorkFlowStepStatus.ToBeApproved)[0])
            setIsWFAssignedToMe(props.workFlow.relativeStatus === WorkFlowRelativeStatus.ToMe);
        }
    }, [props.workFlow]);

    const emptyList = useCallback(() => {
        if (workFlow?.stepsDetails?.length !== 0)
            return null;

        return (
            <div className={classNames.emptyList}>
                <span>{t("common:emptyList")}</span>
            </div>
        )
    }, [workFlow?.stepsDetails?.length, t, classNames.emptyList]);

    const getHistorySection = (item: WorkFlowStepDetails<WorkFlowStep>) => {
        return item.step.status !== WorkFlowStepStatus.Idle && item.step.approvers.map(x => {
            const content = `${x.user.firstName} ${x.user.lastName} ${t('stepStatus:userAction')} ${t(`stepStatus:approverStatus.${x.status}`)} ${t('iter.userActionWithDate')} ${x.updatedOn ? Helpers.getShortDate(x.updatedOn) : ''}`
            return <>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    <span key={x.user.id}>
                        <TooltipHost content={content}>
                            <div className={classNames.tooltipContent} >
                                {content}
                            </div>
                        </TooltipHost>
                    </span>
                </div>
                <br></br>
            </>
        })
    }

    const getCommentsSection = (item: WorkFlowStepDetails<WorkFlowStep>) => {
        return item.step.approvers.map(x => {
            const content = `${x.user.firstName} ${x.user.lastName} ${t('iter.userCommented')} "${x.comment}"`
            return x.comment && item.step.status !== WorkFlowStepStatus.Idle ?
                <>
                    <div key={x.user.id} style={{ whiteSpace: 'pre-wrap' }}>
                        <TooltipHost content={content}>
                            <div className={classNames.tooltipContent} >
                                <span>{x.user.firstName} {x.user.lastName}</span><span>{t('iter.userCommented')}</span><span style={{ fontStyle: 'italic' }}>{`"${x.comment}"`}</span>
                            </div>
                        </TooltipHost>
                    </div>
                    <br></br>
                </> : ''
        })
    }

    const approversActionsColumns: IColumn[] = [
        {
            key: 'actionHistory',
            name: t('iter.column.actionHistory'),
            ariaLabel: 'actionHistory',
            fieldName: '',
            minWidth: 200,
            maxWidth: 230,
            isResizable: true,
            onRender: getHistorySection
        },
        {
            key: 'comments',
            name: t('iter.column.comments'),
            ariaLabel: 'comments',
            isResizable: true,
            fieldName: '',
            minWidth: 100,
            maxWidth: 130,
            onRender: getCommentsSection
        }
    ];

    const statusColumn: IColumn[] = [
        {
            key: 'status',
            name: t("iter.column.status"),
            ariaLabel: 'status',
            fieldName: '',
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            onRender: function getValue(item: WorkFlowStepDetails<WorkFlowStep>) {
                return <span>{t(`stepStatus:status.${item.step.status}`)}</span>
            }
        }
    ];

    const rulesColumn: IColumn[] = [
        {
            key: 'rules',
            name: t('iter.column.rules'),
            ariaLabel: 'rules',
            fieldName: '',
            minWidth: 180,
            maxWidth: 200,
            isResizable: true,
            onRender: function getValue(item: WorkFlowStepDetails<WorkFlowStep>) {
                return <span style={{ whiteSpace: 'normal' }}>{t(`stepStatus:type.${item.step.type}`)}</span>
            }
        }
    ];

    const stepColumn: IColumn[] = [
        {
            key: 'step',
            name: t("iter.column.step"),
            ariaLabel: 'step',
            fieldName: '',
            minWidth: 130,
            maxWidth: 420,
            isResizable: true,
            className: classNames.step,
            onRender: function getValue(item: WorkFlowStepDetails<WorkFlowStep>) {
                return <>
                    <StepStatus isSummary={props.isSummary} item={item}></StepStatus>
                </>
            }
        }
    ];

    const getDocumentSharepointPath = () => {
        return workFlow?.workFlow.documentUrl?.concat(
            Helpers.getFileExtension(workFlow.workFlow.documentUrl) === FileExtensions.Pdf ?
                '' :
                (!props.isSummary && workFlow?.workFlow.status === WorkFlowStatus.Ongoing ?
                    SharePointDocumentPathSuffixes.Edit :
                    SharePointDocumentPathSuffixes.ViewOnly));
    }

    return (<>
        {!workFlow || !currentPortfolio ?
            <div style={{ height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Spinner size={SpinnerSize.large} /></div>
            :
            <>
                <Stack className={classNames.root}>
                    {!props.isSummary ?
                        <StackItem className={classNames.title}>
                            <div className={classNames.titleContainer}>
                                <span>{workFlow?.workFlow.name}</span>
                                <div className={classNames.closeBtn}>
                                    <PrimaryButton onClick={() => { navigator.changePortfolio(currentPortfolio?.id || 0, ActivityTab.workflow) }} >{t('common:close')}</PrimaryButton>
                                </div>
                            </div>
                        </StackItem> : ''}
                    <StackItem><span>{props.workFlow && props.workFlow.relativeStatus !== undefined ? t(`info.status.${props.workFlow?.relativeStatus}`) : ''}</span></StackItem>
                    <StackItem><span>{props.workFlow && props.workFlow.relativeStatus !== undefined ? t(`info.secondLineStatus.${props.workFlow?.relativeStatus}`) : ''}</span></StackItem>
                    <StackItem className={classNames.mainContent}>
                        <ScrollablePane>
                            <Accordion styles={classNames.subComponentStyles.accordion} title={t('info.title')} opened={true} stickyHeader>
                                <div className={classNames.accordionContent}>
                                    <Stack tokens={{ childrenGap: 10 }}>
                                        <Stack horizontal tokens={{ childrenGap: 30 }}>
                                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                <StackItem><span>{t('info.activityName')}</span></StackItem>
                                                <StackItem><span>{workFlow?.workFlow.activityName}</span></StackItem>
                                            </Stack>
                                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                <StackItem><span>{t('info.document')}</span></StackItem>
                                                <StackItem style={{ width: props.isSummary ? '200px' : 'inherit' }}>
                                                    <TooltipHost content={workFlow.workFlow.documentName}>
                                                        <div className={props.isSummary ? classNames.tooltipContent : ''} >
                                                            <span className={classNames.documentUrl} onClick={() => { return workFlow.workFlow.documentUrl ? Helpers.getPreviewFile(0, getDocumentSharepointPath()) : null }}>{workFlow.workFlow.documentName}
                                                            </span>
                                                        </div>
                                                    </TooltipHost>
                                                </StackItem>
                                            </Stack>
                                        </Stack>
                                        {props.isSummary ? '' :
                                            <Stack tokens={{ childrenGap: 5 }}>
                                                <StackItem><span>{t('info.createdBy')}</span></StackItem>
                                                <StackItem> <Persona {...mapper.mapUserToPersona(workFlow?.workFlow.creator)} size={PersonaSize.size24} coinSize={32} /></StackItem>
                                            </Stack>
                                        }
                                    </Stack>
                                </div>
                            </Accordion>

                            {!props.isSummary && workFlowStep && isWFAssignedToMe &&
                                <Accordion styles={classNames.subComponentStyles.accordion} title={t('action.title')} opened={true} stickyHeader >
                                    <div className={classNames.accordionContent}>
                                        <WorkFlowApprovalStep onReload={() => props.onReload()} stepId={workFlowStep.id} stepOrder={workFlowStep.order} stepType={workFlowStep.type} stepName={workFlowStep.name} ></WorkFlowApprovalStep>
                                    </div>
                                </Accordion>
                            }

                            <Accordion styles={classNames.subComponentStyles.accordion} title={t('iter.title')} opened={!isWFAssignedToMe} stickyHeader>
                                <div className={classNames.accordionContent}>
                                        <DetailsList
                                            items={workFlow?.stepsDetails || []}
                                            columns={props.isSummary ? stepColumn.concat(rulesColumn) : stepColumn.concat(statusColumn).concat(rulesColumn).concat(approversActionsColumns)}
                                            selectionMode={SelectionMode.none}
                                            isHeaderVisible={true}
                                            onRenderDetailsHeader={props => props ? <DetailsHeader {...props} styles={classNames.subComponentStyles.detailsHeader} /> : null}
                                            getKey={((item: WorkFlowStepDetails<WorkFlowStep>) => item.step.id.toString())}
                                            setKey="none"
                                            styles={classNames.subComponentStyles.detailsList}
                                            onRenderRow={props => props ? <DetailsRow {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
                                            onRenderDetailsFooter={emptyList}
                                        />
                                </div>
                            </Accordion>
                        </ScrollablePane>
                    </StackItem>
                </Stack>
            </>
        }</>
    );
}

