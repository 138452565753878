import React from "react";
import { classNamesFunction } from "@fluentui/react";
import { ICardAccordionBaseProps, ICardAccordionBasePropsStyles, ICardAccordionBaseStyles } from "./cardAccordion.types";

const getClassNames = classNamesFunction<ICardAccordionBasePropsStyles, ICardAccordionBaseStyles>();

export const CardAccordionBase = (props: ICardAccordionBaseProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    return (
        <div className={classNames.root} style={{height: props.height ?? 75, minWidth: props.width ?? 180}}>
            <div className={classNames.title}>{props.title}</div>
            <div>{props.content}</div>
            {props.action !== undefined ? 
                <div className={classNames.action} onClick={props.action}>{props.actionText ?? "action"}</div> 
                : <div></div>
                } 
        </div>
    )
}