import { Dropdown, IDropdownOption, IDropdownStyleProps, IDropdownStyles, IStyleFunctionOrObject } from "@fluentui/react";
import React from "react";
import { useMemo } from "react";
import currentUser from "../../../../../modules/authentication/currentUser";
import { useAsyncApi } from "../../../../../utilities/hooks";
import { ArchiveRoleId } from "../../../../models/constants";
import { User } from "../../../../models/user";
import { archivesApi } from "../../../../services/archives/archives.api";

export interface IMemberRoleDropdownProps {
    archiveId: number;
    member: User;
    totalCount: number;
    dropdownStyles: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
    onRoleChangeCompleted: () => void;
    disabled?: boolean;
}

export const AvailableRoles: IDropdownOption[] = [
    { key: ArchiveRoleId.Owner, text: "Owner" },
    { key: ArchiveRoleId.Architect, text: "Architect" },
    { key: ArchiveRoleId.Contributor, text: "Contributor" },
    { key: ArchiveRoleId.Professional, text: "Professional" },
    { key: ArchiveRoleId.Uploader, text: "Uploader" },
    { key: ArchiveRoleId.Reader, text: "Reader", selected: true }
]

export const MemberRoleDropdown = (props: IMemberRoleDropdownProps) => {
    const { member, dropdownStyles, archiveId, onRoleChangeCompleted } = props;
    const disabled = useMemo(() => member.id === currentUser.currentUserId, [member.id]);

    const updateMemberRole = useAsyncApi<{ member: User, newRoledId: ArchiveRoleId }, void>({
        func: async (request) => {
            await archivesApi.updateMemberRole(archiveId, request.member.id, request.newRoledId);
        }
    });

    return (
        <Dropdown
            disabled={props.disabled || disabled || updateMemberRole.loading}
            styles={dropdownStyles}
            defaultSelectedKey={member.roleId}
            options={AvailableRoles}
            onChange={async (_, option) => {
                if (!option)
                    return;

                await updateMemberRole.execute({ member, newRoledId: (option.key as ArchiveRoleId) });

                if (!updateMemberRole.error)
                    onRoleChangeCompleted();
            }}
        />
    );
}