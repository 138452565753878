import { apiClient } from "../../../modules/apiClient/apiClient"; 
import { svcPaths } from "../../../modules/apiClient/config";
import { ApproveWorkFlowStep } from "./workflowstep.contracts";

class WorkFlowStepApiClass {
    async ApproveWorkFlowStep(request: ApproveWorkFlowStep) {
        const response = await apiClient.put<void>(`${svcPaths.activities}/workflowstep/${request.id}`, request);
        return response.data;
    }
}

export const workFlowStepApi = new WorkFlowStepApiClass();