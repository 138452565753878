import { IDropdownStyles, IPivotStyles, IStyleFunction } from "@fluentui/react"
import { IResultsPropsStyles, IResultsStyles } from "./results.types"

export const ResultsGlobalClassNames = {
    root: 'edi-results-root',
    container: 'edi-results-container',
    descriptionContainer: 'edi-results-descriptionContainer',
    buttonsContainer: 'edi-results-buttonsContainer',
    title: 'edi-results-title',
    subtitle: 'edi-results-subtitle',
    label: 'edi-results-label',
    titleIcon: 'edi-results-label',
    titleContainer: 'edi-results-titleContainer'
}

export const getStyles: IStyleFunction<IResultsPropsStyles, IResultsStyles> = (props: IResultsPropsStyles): IResultsStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            ResultsGlobalClassNames.root,
            {
                display: 'flex',
                flexDirection: 'column'
            }
        ],
        buttonsContainer: [
            ResultsGlobalClassNames.buttonsContainer,
            {
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '5px 8px 0px 0px'
            }],
        titleContainer: [ResultsGlobalClassNames.titleContainer, {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }],
        descriptionContainer: [ResultsGlobalClassNames.descriptionContainer,
        {
            height: '60px',
            fontSize: '16px',
            margin: '8px',
            overflow: 'auto',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        }],
        container: [
            ResultsGlobalClassNames.container,
            {
                position: 'relative',
                background: palette.white,
                overflowY: 'auto',
                flexBasis: 'calc(100vh - 265px)',
                margin: '10px 8px',
                padding: '10px',
                boxShadow: 'rgb(136 136 136) 0px 1px 8px 0px'

            }
        ],
        containerNoDocs: [
            ResultsGlobalClassNames.container,
            {
                position: 'relative',
                background: palette.white,
                overflowY: 'auto',
                flexBasis: 'calc(100vh - 215px)',
                margin: '10px 8px',
                padding: '10px',
                boxShadow: 'rgb(136 136 136) 0px 1px 8px 0px'

            }
        ],
        titleIcon: [
            ResultsGlobalClassNames.titleIcon,
            {
                padding: 7,
                border: '1.5px solid',
                borderRadius: '50%',
                fontSize: 26
            }
        ],
        title: [
            ResultsGlobalClassNames.title,
            {
                display: 'flex',
                flexFlow: ' row nowrap',
                width: 'auto',
                height: 'auto',
                boxSizing: 'border-box',
                alignItems: 'center',
                fontSize: '18px',
                fontWeight: '600',
                paddingLeft: '10px'
            }],
        subtitle: [
            ResultsGlobalClassNames.subtitle,
            {
                display: 'flex',
                flexFlow: ' row nowrap',
                width: 'auto',
                height: 'auto',
                boxSizing: 'border-box',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: '400',
                paddingLeft: '10px'
            }],
        subComponentStyles: {
            dropdown: (): IDropdownStyles => {
                return {
                    root: {},
                    label: {},
                    dropdown: {
                        selectors: {
                            ':focus': {
                                '::after': {
                                    border: 'none !important'
                                }
                            },
                            ':active': {
                                '.ms-Dropdown-title': {
                                    borderColor: 'unset'
                                }
                            }
                        }
                    },
                    title: {},
                    caretDownWrapper: {},
                    caretDown: {},
                    errorMessage: {},
                    dropdownItemsWrapper: {},
                    dropdownItems: {},
                    dropdownItem: {},
                    dropdownItemSelected: {},
                    dropdownItemDisabled: {},
                    dropdownItemSelectedAndDisabled: {},
                    dropdownItemHidden: {},
                    dropdownOptionText: {},
                    dropdownDivider: {},
                    dropdownItemHeader: {},
                    panel: {},
                    callout: {},
                    subComponentStyles: {
                        label: {},
                        multiSelectItem: {},
                        panel: {}
                    }
                }
            },
            pivotContainer: (): IPivotStyles => {
                return {
                    root: {
                        whiteSpace: 'nowrap',
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        paddingBottom: '5px'
                    },
                    link: {},
                    linkIsSelected: {},
                    linkContent: {},
                    text: {},
                    count: {},
                    icon: {},
                    linkInMenu: {},
                    overflowMenuButton: {}
                }
            }
        }
    };
}