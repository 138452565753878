/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useEffect, useState } from "react";
import { classNamesFunction, CommandBar, ICommandBarItemProps, IconButton, IIconProps, Label, Modal, ResponsiveMode, Spinner, SpinnerSize, Stack, TextField } from "@fluentui/react";
import { IFeedbackModalPropsStyles, IFeedbackModalStyles, IFeedbackModalProps } from "./feedbackModal.types";
import { DashboardTextType } from "../../../../utilities/constants";
import { dashboardApi } from "../../../services/dashboard.api";
import { useFeedbackList } from "../../../features/feedbackList";
import { useTranslation } from "react-i18next";
import { DashboardRoleId } from "../../../models/dashboardRoleId";
import { Feedback, FeedbackStatus, FeedbackType } from "../../../models/feedback";
import CopyActionCommand from "../../../../knowledgeLab/components/common/copyActionButton/copyActionCommand";
import FeedbackConfirmModal from "../feedbackConfirmModal/feedbackConfirmModal";

const getClassNames = classNamesFunction<IFeedbackModalPropsStyles, IFeedbackModalStyles>();

export const FeedbackModalBase = (props: IFeedbackModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, width: "60%", height: "80%" });

    const feedbackList = useFeedbackList();
    const { t } = useTranslation('dashboardToolbar');

    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [modalContent, setModalContent] = useState<string>();
    const [feedback, setFeedback] = useState<Feedback>();
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
    const [action, setAction] = useState<FeedbackStatus>();


    useEffect(() => {
        if (props.documentType !== undefined && props.documentId !== undefined) {
            setError(false);
            setLoading(true);

            dashboardApi.getElementDetails(props.documentId)
                .then((feedback) => {
                    setFeedback(feedback);
                })
                .catch((e) => {
                    setError(true);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [props.documentId, props.documentType]);

    useEffect(() => {
        if (props.documentType === DashboardTextType.element)
            setModalContent(feedback?.elementContent);
        else
            setModalContent(feedback?.fileContent);
    }, [feedback, props.documentType])


    const updateFeedbackStatus = (status: FeedbackStatus, note?: string) => {
        if (props.documentId) {
            dashboardApi.updateFeedback([props.documentId], status, undefined, note)
                .then(() => {
                    if (props.onCloseClick)
                        props.onCloseClick();
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }

    const approveFeedbackAction: ICommandBarItemProps = {
        key: "approveFeedback",
        text: t('actions.approveFeedback'),
        iconProps: { iconName: "StatusCircleCheckmark", style: { fontSize: 20 } },
        onClick: () => {
            setIsOpenConfirmModal(true);
            setAction(FeedbackStatus.Approved);
            //updateFeedbackStatus(FeedbackStatus.Approved);
        },
        disabled: (feedback?.feedbackStatus !== FeedbackStatus.Pending &&
            feedback?.feedbackStatus !== FeedbackStatus.Rejected) ||
            feedback?.feedbackType === FeedbackType.NER
    }

    const approveFeedbackForDatasetAction: ICommandBarItemProps = {
        key: "approveFeedbackForDataset",
        text: t('actions.approveFeedbackForDataset'),
        iconProps: { iconName: "StatusCircleCheckmark", style: { fontSize: 20 } },
        onClick: () => {
            setIsOpenConfirmModal(true);
            setAction(FeedbackStatus.ReadyForDataset);
            //updateFeedbackStatus(FeedbackStatus.ReadyForDataset);
        },
        disabled: feedback?.feedbackStatus === FeedbackStatus.DatasetCreated || feedback?.feedbackType === FeedbackType.NER
    }

    const rejectFeedbackAction: ICommandBarItemProps = {
        key: "rejectFeedback",
        text: t('actions.rejectFeedback'),
        iconProps: { iconName: "StatusCircleErrorX", style: { fontSize: 20 } },
        onClick: () => {
            setIsOpenConfirmModal(true);
            setAction(FeedbackStatus.Rejected);
            //updateFeedbackStatus(FeedbackStatus.Rejected);
        },
        disabled: feedback?.feedbackStatus === FeedbackStatus.Rejected
    }

    const actions = () => {
        const acts = [] as ICommandBarItemProps[];

        if (feedbackList.currentDashboardRoleId === DashboardRoleId.DataScientist)
            acts.push(approveFeedbackForDatasetAction);
        else
            acts.push(approveFeedbackAction);

        acts.push(rejectFeedbackAction);

        return acts;
    }

    const formatDateToLocalTime = (updatedOn: string | undefined) => {
        if (updatedOn !== null && updatedOn !== undefined && updatedOn !== " ") {
            const dateToConvert = new Date(updatedOn);
            updatedOn = `${dateToConvert.toLocaleDateString()} ${dateToConvert.toLocaleTimeString()}`;
        }

        return updatedOn;
    }

    const modalBody =
        <>
            <Stack>
                {loading
                    ? <div className={classNames.loadingErrorContainer}>
                        <Spinner size={SpinnerSize.large} />
                    </div>
                    : (error ? <span>{' something went wrong :( '}</span>
                        :

                        <Stack horizontal style={{ height: '100%' }}>

                            <Stack.Item className={classNames.modalMetadata}>

                                <Label>{t('metadataFromUser')}</Label>
                                <TextField value={feedback?.feedbackType === FeedbackType.NER ? "NER" : feedback?.newValue} disabled readOnly />

                                <Label>{t('previousMetadata')}</Label>
                                <TextField value={feedback?.feedbackType === FeedbackType.NER ? "NER" : feedback?.oldValue} disabled readOnly />

                                <Label>{t('metadataModifiedBy')}</Label>
                                <TextField value={feedback?.creatorName} disabled readOnly />

                                <Label>{t('dateEditMetadata')}</Label>
                                <TextField value={formatDateToLocalTime(feedback?.createdOn)} disabled readOnly />

                                <Label>{t('OriginalAiExtractedMetadata')}</Label>
                                <TextField value={feedback?.feedbackType === FeedbackType.CLASSIFICATION ? feedback?.originalAiExtractedMetadataValue : ''} disabled readOnly />

                                <Label>{t('statusFeedback')}</Label>
                                <TextField value={feedback?.feedbackStatus !== undefined ? FeedbackStatus[feedback?.feedbackStatus] : ''} disabled readOnly />

                                <Label>{t('lastUserEditFeedback')}</Label>
                                <TextField value={feedback?.approverName} disabled readOnly />

                                <Label>{t('dateLastFeedbackEdit')}</Label>
                                <TextField value={formatDateToLocalTime(feedback?.updatedOn)} disabled readOnly />

                                <Label>{t('noteFeedback')}</Label>
                                <TextField multiline autoAdjustHeight value={feedback?.note} disabled readOnly resizable={false}/>

                            </Stack.Item>

                            <Stack className={classNames.modalParagraph}>
                                <Stack.Item >
                                    <div className={classNames.copyButtonDiv}>
                                        <CopyActionCommand
                                            content={modalContent ?? ""}
                                            textPlaceHolder=""
                                        />
                                    </div>
                                </Stack.Item>
                                <Stack.Item style={{ height: 'calc(100% - 100px)', overflowY: 'scroll', overflowX: 'hidden' }}>
                                    <p>{modalContent}</p>
                                </Stack.Item>
                            </Stack>

                        </Stack>
                    )}
            </Stack>
        </>

    const modalFooter = <CommandBar items={actions()} />

    const initialIconProps: IIconProps = {
        iconName: 'Cancel'
    }

    return (
        <div>
            <Modal
                isOpen={props.isOpen}
                className={props.className}
                containerClassName={classNames.container}
                responsiveMode={ResponsiveMode.large}
                scrollableContentClassName={classNames.content}
                onDismissed={props.onCloseClick}
                isBlocking={true}
            >
                <div className={classNames.upperContent}>
                    <div>
                        <div className={classNames.header}>
                            <div className={classNames.title}>{props.title}</div>
                            <div className={classNames.closeIcon}>
                                <IconButton
                                    styles={classNames.subComponentStyles.iconButton()}
                                    iconProps={{ ...initialIconProps }}
                                    onClick={props.onCloseClick}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classNames.body}>
                        <div className={classNames.bodyContent}>
                            {modalBody}
                        </div>
                    </div>
                </div>
                <div className={classNames.footer}>{modalFooter}</div>
            </Modal>
            <FeedbackConfirmModal
                isOpen={isOpenConfirmModal}
                onClose={() => setIsOpenConfirmModal(false)}
                onConfirm={(note) =>{
                    if(action)
                        updateFeedbackStatus(action, note);

                    setIsOpenConfirmModal(false);
                    if(props.onCloseClick)
                        props.onCloseClick();
                }}
                action={action}
            />
        </div>
    );
}
