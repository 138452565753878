import { IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";
import { IManagerFolder } from "../../../../../../common/components/folderTreeViewer/folderManager/IManagerFolder";
import { IMultiStepModalPropsStyles, IMultiStepModalStyles } from "../../../../../../common/components/multiStepModal/multiStepModal.types";

export interface ICreateProfileModalBaseStyles {
    root: IStyle;
    footerButtons: IStyle;
    footerContainer: IStyle;
    centerContainer: IStyle;
    subComponentStyles: {
        ediModal: IStyleFunctionOrObject<IMultiStepModalPropsStyles, IMultiStepModalStyles>;
    }
}

export interface ICreateProfileModalBaseProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ICreateProfileModalBasePropsStyles, ICreateProfileModalBaseStyles>;
    className?: string;
    isOpen: boolean;
    archiveId: number;
    onClose?: () => void;
    onComplete?: () => void;
}

interface ICreateProfileFolderModalWithProfileProps extends ICreateProfileModalBaseProps {
    profileIdOwner: number;
    isProfileFoldersBulk: boolean;
}

interface ICreateProfileFolderModalWithoutProfileProps extends ICreateProfileModalBaseProps {
    profileIdOwner?: never;
    isProfileFoldersBulk? : never;
}
export type ICreateProfileFolderModalProps = ICreateProfileFolderModalWithProfileProps | ICreateProfileFolderModalWithoutProfileProps;

export interface ICreateProfileModalBasePropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ICreateProfileModalBasePropsStyles, ICreateProfileModalBaseStyles>;
    className?: string;
}

export class Folder implements IManagerFolder{
    id: number;
    name: string;
    parentId: number | undefined;
    createdBy: string | undefined;
    createdOn: string | undefined;
    depth: number;
    collapsed: boolean;
    hidden: boolean;
    selected: boolean;
    canExpand: boolean;
    canSelect: boolean;
    documentOfId: number | undefined;
    fullPathName: string | undefined;
    selectedAllChildren: boolean;

    constructor(){
        this.id = 0;
        this.depth = 0;
        this.name = '';
        this.collapsed = false;
        this.hidden = false;
        this.selected = false;
        this.canExpand = true;
        this.canSelect = true;
        this.selectedAllChildren = false;
    }
}

export class Profile {
    name: string | undefined;
    description: string | undefined;
    number: number | undefined;
}

export type TreeFolderIdView = {
    folderId: number;
    retrieveFolderChildren: boolean;
}

export type TreeFolderIdViewBulk = TreeFolderIdView & {selected : boolean;}