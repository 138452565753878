import React, { useEffect, useState } from "react";
import { classNamesFunction, Icon, Stack, StackItem, TooltipHost } from "@fluentui/react";
import { IAddFilePropsStyles, IAddFileStyles, IAddFileProps } from "./addFile.types";
import { useTranslation } from "react-i18next";
import SPOFolderContent from "../SPOfolderContent/SPOfolderContent";
import { FileShort } from "../../../../models/fileShort";

const getClassNames = classNamesFunction<IAddFilePropsStyles, IAddFileStyles>();
export type SecondPageData = {
    file: FileShort | undefined;
    isPdf: boolean;
}

export const AddFileBase = (props: IAddFileProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['createWorkflow', 'common']);
    const [data, setData] = useState<SecondPageData>(props.initialState);

    const updateData = (newFile: FileShort) => {
        setData({ isPdf: newFile && newFile.mimeType === "application/pdf", file: newFile });
    }

    useEffect(() => props.onWorkflowDataChange(data), [data, props]);

    return (
        <div>
            <Stack className={classNames.root} horizontal>
                <StackItem style={{ marginRight: 5 }}>{t('addFile.selectDoc')}</StackItem>
                <TooltipHost content={t('addFile.tooltipText')}>
                    <Icon
                        styles={{ root: { marginTop: 2, cursor: 'pointer' } }}
                        iconName="Info"
                    />
                </TooltipHost>
            </Stack>
            <Stack>
                <SPOFolderContent
                    isError={(err) => console.log(err)}
                    maxNumFiles={1}
                    disableFolderSelection
                    activityId={props.activityId}
                    enabledFileTypes={['.doc', '.docx', '.pdf', '.xlsx', '.pptx', '.ppt', '.msg']}
                    onSelectionChange={(newFile: FileShort[]) => updateData(newFile[0])}
                    selectAllEnabled={false}
                    selection={data.file ? [data.file] : []}
                />
            </Stack>
        </div>
    );
}