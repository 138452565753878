/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { classNamesFunction, DefaultButton, DetailsList, DetailsRow, IColumn, IconButton, IDetailsRowProps, Label, Persona, PersonaSize, PrimaryButton, SearchBox, SelectionMode, Spinner, SpinnerSize, Stack, StackItem, TooltipDelay, TooltipHost } from "@fluentui/react";
import { useOnMount } from "../../../../../utilities/hooks";
import Accordion from "../../../../../common/components/accordion/accordion";
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive";
import { useTranslation } from "react-i18next";
import { archivesApi } from '../../../../services/archives/archives.api';
import { IManageAssociationTopicUsersProps, IManageAssociationTopicUsersPropsStyles, IManageAssociationTopicUsersStyles } from "./manageAssociationTopicUsers.types";
import { Topic, TopicWithUsers, UserWithTopics } from "../topicModel/topic";
import { ErrorsType } from "../../../../models/callsApi";
import { mapper } from "../../../../../utilities/mapper";
import IconTag from "../../../../../common/components/iconTag/iconTag";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import DeleteTopicModal from "../manageTopicsView/deleteTopicModal/deleteTopicModal";
import TopicInfoModal from "./topicInfoModal/topicInfoModal";
import EdiModal from "../../../../../common/components/ediModal/ediModal";
import AddUsersToTopicModal from "./addUsersToTopicModal/addUsersToTopicModal";

const getClassNames = classNamesFunction<IManageAssociationTopicUsersPropsStyles, IManageAssociationTopicUsersStyles>();

export const ManageAssociationTopicUsersBase = (props: IManageAssociationTopicUsersProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['topicOfInterest', 'common']);
    const currentArchive = useCurrentArchive();
    const [loadingTopics, setLoadingTopics] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [topicList, setTopicList] = useState<TopicWithUsers[]>([]);
    const [canLoadMore, setCanLoadMore] = useState(false);
    const pageSize = 20;
    const [pageNumber, setPageNumber] = useState(0);
    const [searchBy, setSearchBy] = useState('');
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState<boolean>(false);
    const [currentTopicId, setCurrentTopicId] = useState<number>(0);
    const [currentUserIds, setCurrentUserIds] = useState<string[]>([]);
    const [selectedTopic, setSelectedTopic] = useState<Topic>();
    const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
    const [userIdsToAdd, setUserIdsToAdd] = useState<string[]>([]);

    useOnMount(() => {
        getTopics(searchBy, true);
    });

    const getTopics = async (nameTopic?: string, reset = false) => {
        if (currentArchive?.id === undefined || currentArchive?.id === null)
            return;
        setLoadingTopics(true);
        const pNumber = reset ? 0 : pageNumber;

        try {
            const topicsWithUsers = await archivesApi.getTopicsWithUsers(currentArchive?.id, pageSize, pNumber, nameTopic);

            if (reset)
                setTopicList(topicsWithUsers);
            else
                setTopicList(topicList.concat(topicsWithUsers));

            if (topicsWithUsers.length >= pageSize) {
                setCanLoadMore(true);
                setPageNumber(pNumber + 1);
            }
            else
                setCanLoadMore(false);
        }
        catch (ex) {
            const error: ErrorsType = ex as ErrorsType;
            setErrorMessage("Error while retrieving topics: " + error.message);
        }
        finally {
            setLoadingTopics(false);
        }
    }

    const titleAccordion = (topic: TopicWithUsers) => {
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignContent: 'center'}}>
                    <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {topic.name} ({topic.users.length})
                    </span>
                </div>
            </div>
        )
    }

    const printCustomHeader = (topic: TopicWithUsers) => {
        return (
            <div className={classNames.flexContainer}>
                <IconButton
                    iconProps={{ iconName: "View" }}
                    className={classNames.iconButtonAccordion}
                    onClick={(e) => {
                        setSelectedTopic(topic);
                        setCurrentTopicId(topic.id);
                        setIsInfoModalOpen(true);
                        e.stopPropagation();
                    }}
                />
                <IconButton 
                    iconProps={{ iconName: "AddFriend" }}
                    className={classNames.iconButtonAccordion}
                    onClick={(e) => {
                        setSelectedTopic(topic);
                        setCurrentTopicId(topic.id);
                        setShowAddUserModal(true);
                        e.stopPropagation();
                    }}
                />
                <IconButton 
                    iconProps={{ iconName: "Delete" }}
                    className={classNames.iconButtonAccordion}
                    disabled={topic.users.length === 0}
                    onClick={(e) => {
                        setSelectedTopic(topic);
                        openDeleteUsersModal(topic.id, topic.users.map(u => u.id));
                        e.stopPropagation(); 
                    }}
                />
            </div>
        )
    }

    const deleteUsersFromTopic = async (topicId: number, userIds: string[]) => {
        if (currentArchive?.id === undefined || currentArchive?.id === null)
            return;

        setLoading(true);
        try {
            await archivesApi.deleteUsersTopic(topicId, userIds);
            getTopics(searchBy, true);
        }
        catch (ex: any) {
            const error: ErrorsType = ex as ErrorsType;
            setErrorMessage("Error while deleting user/s: " + error.message);
        }
        finally {
            setLoading(false);
            setShowDeleteConfirmModal(false);
        }
    }

    const openDeleteUsersModal = (topicId: number, userIds: string[]) => {
        setShowDeleteConfirmModal(true);
        setCurrentTopicId(topicId);
        setCurrentUserIds(userIds);
    }

    const columns: IColumn[] = [
        {
            key: 'name',
            name: t('nameLabel'),
            minWidth: 100,
            maxWidth: 400,
            isSorted: false,
            onRender: function renderUserName(user: UserWithTopics) {
                return (
                    <Persona
                        {...mapper.mapUserToPersona(user)}
                        size={PersonaSize.size40}
                        coinSize={32}
                    />
                );
            }
        },
        {
            key: 'topics',
            name: t('topicsLabel'),
            minWidth: 100,
            onRender: function renderTopics(userWithTopics: UserWithTopics) {
                const listOfTopic = userWithTopics.topics.slice(0, 3);
                const remainingTopicList = userWithTopics.topics.slice(3, userWithTopics.topics.length);
                return (
                    <div className={classNames.topicList}>
                        {listOfTopic.map((u, i) => {
                            return (
                                <div key={i} className={"icontag-container"}>
                                    <IconTag
                                        key={u.id}
                                        label={u.name}
                                        labelClassName={classNames.labelBox}
                                        className={classNames.iconTagLabel}
                                        tooltip
                                    />
                                </div>
                            )
                        })}
                        {remainingTopicList.length > 0 &&
                            <div style={{ marginLeft: 5 }}>
                                <TooltipHost
                                    content={remainingTopicList.map((x, i) => <Label key={i} className={classNames.remainingIconTagLabel}>{x.name}</Label>)}
                                    delay={TooltipDelay.zero}>
                                    <Label className={classNames.countCircle}>
                                        +{remainingTopicList.length}
                                    </Label>
                                </TooltipHost>
                            </div>
                        }
                    </div>
                )
            }
        },
        {
            key: 'remove',
            name: '',
            minWidth: 25,
            onRender: function renderRemoveButton(user: UserWithTopics) {
                return (
                    <IconButton
                        iconProps={{iconName: 'Cancel'}}
                        title={t('common:remove')}
                        ariaLabel="Remove"
                        disabled={loadingTopics}
                        onClick={() => openDeleteUsersModal(user.currentTopicId, [user.id])}
                    />
                );
            }
        }
    ]

    const onScroll = (event: any) => {
        if ((canLoadMore && !loadingTopics) && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 10) {
            getTopics(searchBy);
        }
    }

    const footer = (
        <div className={classNames.flexFooterContainer}>
            <div style={{marginRight: 20}}>
                {loading && <Spinner
                    size={SpinnerSize.medium}
                />}
            </div>
            <DefaultButton
                text={t("common:cancel")}
                style={{ marginRight: 10 }}
                disabled={loading}
                onClick={() => {
                    setShowAddUserModal(false);
                    setUserIdsToAdd([]);
                }}
            />
            <PrimaryButton
                text={t("common:continue")}
                onClick={() => addUsers()}
                disabled={loading || userIdsToAdd.length === 0}
            />
        </div>
    )

    const addUsers = async () => {
        if (currentTopicId === undefined || currentTopicId === null || currentTopicId === 0)
            return;

        setLoading(true);
        try {
            await archivesApi.addUsersToTopic(currentTopicId, userIdsToAdd);
            getTopics(searchBy, true);
        }
        finally {
            setLoading(false);
            setShowAddUserModal(false);
            setUserIdsToAdd([]);
        }
    }

    const getUsers = async (keyword: string, userIds: string[]) => {
        if (currentTopicId === undefined || currentTopicId === null || currentTopicId === 0 || keyword.length <= 2)
            return [];

        try {
            return await archivesApi.getAvailableUsersNotAssociateToTopic(currentTopicId, keyword, userIds);
        }
        catch {
            return [];
        }
    }

    return (
        <>
            <Stack className={classNames.root}>
                <Stack horizontal>
                    <StackItem className={classNames.stackContainer}>
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            <SearchBox
                                defaultValue={searchBy}
                                styles={{ root: { width: 300, marginRight: '15px'} }}
                                placeholder={t('topicSearch')}
                                onSearch={(newValue: string) => {
                                    setSearchBy(newValue || '');
                                    getTopics(newValue, true);
                                }}
                                onClear={() => {
                                    setSearchBy('');
                                    getTopics('', true);
                                }}
                            />
                        </div>
                    </StackItem>
                </Stack>
                <Stack>
                    <div className={classNames.detailListContainer}>
                        {!loadingTopics && topicList.length === 0 &&
                            <div className={classNames.containerEmptyList}>
                                <TeamsImage
                                    imageName={ImageName.NoResults}
                                    caption={errorMessage !== '' ? errorMessage : t('common:emptySearch')}
                                />
                            </div>
                        }
                        <div
                            onScroll={onScroll}
                            style={{ height: 'calc(100% - 80px)', overflow: 'overlay', paddingRight: '15px' }}
                        >
                            {(loadingTopics && topicList.length < pageSize) ?
                                <div className={classNames.divContainer}>
                                    <Spinner size={SpinnerSize.large} />
                                </div> :
                                topicList.map((topic, i) => {
                                    return (
                                        <Accordion
                                            key={i}
                                            styles={{ root: { '.edi-accordion-title': {width: '100%', overflow: 'hidden', textOverflow: 'ellipsis'} } }}
                                            title={titleAccordion(topic)}
                                            customHeader={printCustomHeader(topic)}
                                            opened={false}
                                        >
                                            <DetailsList
                                                styles={classNames.subComponentStyles.list}
                                                selectionMode={SelectionMode.none}
                                                items={topic.users ?? []}
                                                getKey={(item: UserWithTopics) => item && item.id}
                                                columns={columns}
                                                onRenderRow={(props?: IDetailsRowProps) =>
                                                    props ? (
                                                        <DetailsRow {...props} styles={classNames.subComponentStyles.row} />
                                                    ) : null
                                                }
                                            />
                                        </Accordion>
                                    );
                                })
                            }
                        </div>
                    </div>
                </Stack>
            </Stack>
            {showDeleteConfirmModal &&
                <DeleteTopicModal
                    title={currentUserIds.length === 1 ? t("deleteUserFromTopicTitle") : t("deleteUsersFromTopicTitle")}
                    subtitle={currentUserIds.length === 1 ? t("deleteUserFromTopicSubtitle") : t("deleteUsersFromTopicSubtitle")}
                    isOpen={showDeleteConfirmModal}
                    onClose={() => setShowDeleteConfirmModal(false)}
                    onConfirm={() => deleteUsersFromTopic(currentTopicId, currentUserIds)}
                    errorMessage={errorMessage}
                    loading={loading}
                />
            }
            {selectedTopic &&
                <TopicInfoModal
                    isOpen={isInfoModalOpen}
                    onClose={() => setIsInfoModalOpen(false)}
                    topic={selectedTopic}
                    errorMessage={errorMessage}
                    loading={loadingTopics}
                />
            }
            <EdiModal
                title={`${t("titleAddUsersModal")} ${selectedTopic?.name}`}
                width={800}
                height={600}
                isOpen={showAddUserModal}
                showCloseIcon={true}
                onCloseClick={() => {
                    setShowAddUserModal(false);
                    setUserIdsToAdd([]);
                }}
                body={
                    <AddUsersToTopicModal
                        setUserIdsToAdd={(a: string[]) => setUserIdsToAdd(a)}
                        getAvailableUsers={(keyword: string, userIds: string[]) => getUsers(keyword, userIds)}
                    />
                }
                footer={footer}
            />
        </>
    );
}