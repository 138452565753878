import { styled } from "@fluentui/react";
import { getStyles } from "./dashboardDocAnalysis.styles";
import { IDashboardDocAnalysisPropsStyles, IDashboardDocAnalysisStyles, IDashboardDocAnalysisProps } from "./dashboardDocAnalysis.types";
import { DashboardDocAnalysisBase } from "./dashboardDocAnalysis.base";

const DashboardDocAnalysis = styled<IDashboardDocAnalysisProps, IDashboardDocAnalysisPropsStyles, IDashboardDocAnalysisStyles>(
    DashboardDocAnalysisBase,
    getStyles,
    undefined,
    { scope: 'DashboardDocAnalysis' }
);

export default DashboardDocAnalysis;