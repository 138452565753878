import { styled } from "@fluentui/react";
import { getStyles } from "./detailListDocAnalysis.styles";
import { IDetailListDocAnalysisPropsStyles, IDetailListDocAnalysisStyles, IDetailListDocAnalysisProps } from "./detailListDocAnalysis.types";
import { DetailListDocAnalysisBase } from "./detailListDocAnalysis.base";

const DetailListDocAnalysis = styled<IDetailListDocAnalysisProps, IDetailListDocAnalysisPropsStyles, IDetailListDocAnalysisStyles>(
    DetailListDocAnalysisBase,
    getStyles,
    undefined,
    { scope: 'DetailListDocAnalysis' }
);

export default DetailListDocAnalysis;