import { classNamesFunction, ProgressIndicator } from "@fluentui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDocLabDispatch } from "../../../docLabStore";
import { DownloadStatus, endDownload, removeDownload, setDownloadProgress, useDownloadStatus } from "../../../features/downloadStatus";
import { IDownloadNotificationProps, IDownloadNotificationPropsStyles, IDownloadNotificationStyles } from "./downloadNotification.types";

const getClassNames = classNamesFunction<IDownloadNotificationPropsStyles, IDownloadNotificationStyles>();

export const DownloadNotificationBase = (props: IDownloadNotificationProps) => {
    const downloadStatus = useDownloadStatus();
    const dispatch = useDocLabDispatch();
    const { t } = useTranslation('downloadNotification');

    const classNames = getClassNames(props.styles, {
        theme: props.theme, className: props.className, activeDownloadCount: downloadStatus.downloadQueue.length
    });

    useEffect(() => {
        downloadStatus.downloadQueue.forEach(async requestId => {
            const downloadInfo = downloadStatus.status[requestId];
            if (!downloadInfo || downloadInfo.started)
                return;

            const onDownloadProgress = (progress: number) => {
                dispatch(setDownloadProgress({ requestId: requestId, progress }));
            };

            dispatch(setDownloadProgress({ requestId: requestId, progress: 0 }));

            let objectUrl: string;

            try {
                const response = await downloadInfo.downloadFile(downloadInfo.file.id, onDownloadProgress);
                const data = new Blob([response], { type: 'application/octet-stream' });
                objectUrl = window.URL.createObjectURL(data);
                const tempLink = document.createElement('a');
                tempLink.href = objectUrl;
                tempLink.setAttribute('download', downloadInfo.file.name);
                document.body.appendChild(tempLink);
                tempLink.click();


                dispatch(endDownload({ requestId: requestId, success: true }));

            }
            catch (error) {
                dispatch(endDownload({ requestId: requestId, success: false }));
            }
            finally {
                setTimeout(() => {
                    dispatch(removeDownload(requestId));
                    if (objectUrl)
                        window.URL.revokeObjectURL(objectUrl);
                }, 3000);
            }
        });
    }, [dispatch, downloadStatus.status, downloadStatus.downloadQueue]);

    const downloadStatusEntry = (key: string, entry: DownloadStatus) => (
        <div key={key} className={classNames.downloadEntry}>
            {entry.progress >= 0 && entry.success === undefined &&
                <>
                    <ProgressIndicator
                        percentComplete={entry.progress / 100}
                        className={classNames.progressBar}
                        styles={classNames.subComponentStyles.progressBar}
                    />
                    <div className={classNames.downloadText}>
                        <span>{t('downloading')}</span>
                        <span>{entry.file.name}</span>
                    </div>
                </>
            }
            {entry.progress === 100 && entry.success === true &&
                <div className={classNames.downloadText}>
                    <span>{t('downloaded')}</span>
                    <span>{entry.file.name}</span>
                </div>
            }
            {entry.progress === 100 && entry.success === false &&
                <div className={classNames.downloadText}>
                    <span>{t('error')}</span>
                    <span>{entry.file.name}</span>
                </div>
            }
        </div>
    )

    return (
        <div className={classNames.root}>
            {downloadStatus.downloadQueue.map(requestId =>
                downloadStatusEntry(requestId, downloadStatus.status[requestId]))
            }
        </div>
    );
}