/*eslint-disable sonarjs/cognitive-complexity */
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { classNamesFunction, DetailsList, DetailsRow, Icon, IDetailsRowProps, Persona, PersonaSize, PrimaryButton, ScrollablePane, SelectionMode, Stack } from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';
import _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "../../../../common/components/accordion/accordion";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import { useKnowledgeLabDispatch } from "../../../knowledgeLabStore";
import { useCurrentKnowledge } from "../../../hooks/useCurrentKnowledge";
import { mapper } from "../../../../utilities/mapper";
import { IKnowledgeLabMembersSettingsPropsStyles, IKnowledgeLabMembersSettingsProps, IKnowledgeLabMembersSettingsStyles } from "./knowledgeLabMembersSettings.types";
import { MemberRoleDropdown } from "./subComponents/memberRoleDropdown";
import { RemoveMemberButton } from "./subComponents/removeMemberButton";
import { KnowledgeLabRoleId } from "../../../models/knowledgeLabRoleId";
import { knowledgeLabApi } from "../../../services/knowledgeLab.api";
import { setToolbarActions } from "../../../features/toolbarActions";
import { User } from "../../../models/user";
import KnowledgeLabMembersModal from "../knowledgeLabMembersModal/knowledgeLabMembersModal";

const getClassNames = classNamesFunction<IKnowledgeLabMembersSettingsPropsStyles, IKnowledgeLabMembersSettingsStyles>();

const getTotalMembersCount = (members?: Record<KnowledgeLabRoleId, User[]>) => {
    if (!members || Object.keys(members).length === 0)
        return 0;
    return _.values(members)
        .map(list => list?.length ?? 0)
        .reduce((prev, curr) => prev + curr);
}

export const KnowledgeLabMembersSettingsBase = (props: IKnowledgeLabMembersSettingsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(["archiveMembersSettings", "common"]);
    const currentKnowledge = useCurrentKnowledge();
    const dispatch = useKnowledgeLabDispatch();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const readonly = useMemo(() => currentKnowledge?.roleId !== KnowledgeLabRoleId.Owner, [currentKnowledge]);

    const { execute: loadMembers, value: members, loading } =
        useAsyncApi<void, Record<KnowledgeLabRoleId, User[]>>({
            keepResultsWhileReloading: true,
            func: async () => {
                if (!currentKnowledge)
                    return {} as Record<KnowledgeLabRoleId, User[]>;

                const archiveMembers = await knowledgeLabApi.getKnowledgeLabMembers({
                    knowledgeLabId: currentKnowledge.id,
                    pageNumber: 0,
                    pageSize: 0
                });

                return _.groupBy(archiveMembers, member => member.roleId) as Record<KnowledgeLabRoleId, User[]>;
            }
        });

    useOnMount(() => {
        loadMembers();
        dispatch(setToolbarActions([]));
    });

    const totalMembersCount = useMemo(() => getTotalMembersCount(members), [members]);

    const columns = useMemo(() => {
        if (!currentKnowledge)
            return [];

        return [
            {
                key: 'persona',
                name: t('common:name'),
                minWidth: 100,
                headerClassName: classNames.headerRow,
                onRender: function renderMemberPersona(member: User) {
                    return (
                        <Persona {...mapper.mapUserToPersona(member)} size={PersonaSize.size40} coinSize={32} />
                    );
                }
            },
            {
                key: 'role',
                name: t('common:role'),
                minWidth: 120,
                headerClassName: classNames.headerRow,
                onRender: function renderMemberRoles(member: User) {
                    return (
                        <MemberRoleDropdown
                            knowledgeLabId={currentKnowledge.id}
                            dropdownStyles={classNames.subComponentStyles.rolesDropdown}
                            member={member}
                            totalCount={totalMembersCount}
                            onRoleChangeCompleted={loadMembers}
                            disabled={readonly}
                        />
                    );
                }
            },
            {
                key: 'remove',
                name: '',
                minWidth: 24,
                headerClassName: classNames.headerRow,
                onRender: function renderMemberRemoveButton(member: User) {
                    return (
                        <RemoveMemberButton
                            knowledgeLabId={currentKnowledge.id}
                            buttonStyles={classNames.subComponentStyles.removeButton()}
                            member={member}
                            onRemoveMemberCompleted={loadMembers}
                            disabled={readonly || totalMembersCount < 3}
                        />
                    );
                }
            }
        ];
    }, [classNames, currentKnowledge, loadMembers, totalMembersCount, readonly, t]);

    const membersForRole = (role: KnowledgeLabRoleId) => {
        const singleRoleMembers = members && members[role];
        if (!singleRoleMembers)
            return null;

        return (
            <Accordion title={`${KnowledgeLabRoleId[role]} (${singleRoleMembers.length})`} opened={true} stickyHeader>
                <DetailsList
                    styles={classNames.subComponentStyles.list}
                    items={singleRoleMembers}
                    getKey={(item: User) => item.id}
                    onRenderRow={(props?: IDetailsRowProps) =>
                        props ? <DetailsRow {...props} styles={classNames.subComponentStyles.row} /> : null}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                />
            </Accordion>
        );
    }

    return (
        <div className={classNames.pageContainer}>
            <ScrollablePane>
                <Stack className={classNames.root}>
                    <Stack className={classNames.title} horizontal verticalAlign={'center'}>
                        <Icon iconName={readonly ? "People" : "PlayerSettings"} className={classNames.titleIcon} />
                        <span style={{ paddingLeft: 10 }}>{readonly ? t('common:viewUsers') : t('common:manageUsers')}</span>
                    </Stack>
                    <Stack horizontal className={classNames.upperSection} horizontalAlign={'end'}>
                        <PrimaryButton
                            iconProps={{ iconName: 'AddFriend' }}
                            styles={{ icon: { height: 'auto' } }}
                            text={t("addMembers")}
                            onClick={showModal}
                            disabled={readonly}
                        />
                    </Stack>
                    <Stack.Item verticalFill className={classNames.lowerSection}>
                        {loading && totalMembersCount === 0 ?
                            <div className={classNames.loaderContainer}>
                                <TeamsSpinner />
                            </div> :
                            <ScrollablePane styles={classNames.subComponentStyles.scrollablePane}>
                                {membersForRole(KnowledgeLabRoleId.Owner)}
                                {membersForRole(KnowledgeLabRoleId.Contributor)}
                                {membersForRole(KnowledgeLabRoleId.Reader)}
                            </ScrollablePane>
                        }
                    </Stack.Item>
                </Stack>
                {isModalOpen && <KnowledgeLabMembersModal
                    isOpen={isModalOpen}
                    operationEnded={(success: boolean) => {
                        hideModal();

                        if (success)
                            loadMembers();
                    }}
                />}

            </ScrollablePane>

        </div>
    );
}