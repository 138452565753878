import { DefaultButton, PrimaryButton } from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../../common/components/ediModal/ediModal";
import {
  AddMembersPage,
  MembersPageData,
} from "../../../../../common/components/modalPages/addMembersPage";
import { IUser } from "../../../../../common/interfaces/IUser";
import { AddMembersPageType } from "../../../../../utilities/constants";
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive";
import { ArchiveMembership } from "../../../../models/archiveMembership";
import { ArchiveRoleId } from "../../../../models/constants";
import { archivesApi } from "../../../../services/archives/archives.api";
import { IMembersModalProps } from "../membersModal.types";

export const ArchiveMembersModalBase = (props: IMembersModalProps) => {
  const { t } = useTranslation(["addMembersToArchive", "common"]);
  const currentArchive = useCurrentArchive();
  const [data, setData] = useState<MembersPageData>({
    isValid: false,
    selectedPeople: [],
  });
  const [addMembersBtnDisabled, setAddMembersBtnDisabled] = useState<boolean>(false);

  const addMembers = async () => {
    if (!currentArchive) return;
    setAddMembersBtnDisabled(true);

    const memberships: ArchiveMembership[] = data.selectedPeople.map(
      (member) => ({
        userId: member.id,
        archiveRoleId: member.roleId as ArchiveRoleId ?? ArchiveRoleId.Reader,
        allowSubFolders: member.allowSubFolders
      })
    );
    
    try {
      await archivesApi.addMembers(currentArchive.id, memberships);
      props.operationEnded(true);
    } catch (error) {
      console.log(error); //TODO GESTIRE
      props.operationEnded(false);
    }
  };

  const footer = (
    <>
      <DefaultButton
        text={t("common:cancel")}
        style={{ marginRight: "2px" }}
        onClick={() => props.operationEnded(true)}
      />
      <PrimaryButton
        text={t("common:continue")}
        onClick={addMembers}
        disabled={data.selectedPeople.length === 0 || addMembersBtnDisabled}
      />
    </>
  );

  const getUsers = async (keyword: string, currentUsers: IUser[]) => {
    if (currentArchive && keyword.length > 2) {
      return await archivesApi.getArchiveAvailableMembers({
        archiveId: currentArchive.id,
        pageNumber: 0,
        pageSize: 20,
        keyword: keyword,
        userIds: currentUsers.map((x) => x.id),
      });
    }
    return [];
  };

  return (
    <EdiModal
      title={`${t("updateMembership")}  ${currentArchive?.name}`}
      width={800}
      height={600}
      isOpen={props.isOpen}
      showCloseIcon={true}
      onCloseClick={() => props.operationEnded(true)}
      body={
        <AddMembersPage
          onDataChange={setData}
          initialState={{ selectedPeople: data.selectedPeople, isValid: true }}
          translationNamespace="membersPageForAddArchiveMembers"
          getUsers={(keyword: string) => getUsers(keyword, data.selectedPeople)}
          modalType={AddMembersPageType.Archive}
          enableAllowFolderToggle={true}
          enableRoleSelection={true}
        />
      }
      footer={footer}
    ></EdiModal>
  );
};
