/*eslint-disable sonarjs/cognitive-complexity */

import React, { useCallback, useEffect, useState } from "react";
import { classNamesFunction, DetailsList, DetailsRow, IColumn, Icon, PrimaryButton, SelectionMode, TooltipHost } from "@fluentui/react";
import { IDetailListDocAnalysisPropsStyles, IDetailListDocAnalysisStyles, IDetailListDocAnalysisProps } from "./detailListDocAnalysis.types";
import { AnalyzesStatus } from "../../../models/constants";
import { useTranslation } from "react-i18next";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { Helpers } from "../../../../utilities/helpers";
import { useOnMount } from "../../../../utilities/hooks";
import { useNavigator } from "../../../hooks/useNavigator";
import { AnalysisTypeId, analyzes } from "../../../models/docAnalysis";
import FileNameCell from "./fileNameCell/fileNameCell";

const getClassNames = classNamesFunction<IDetailListDocAnalysisPropsStyles, IDetailListDocAnalysisStyles>();

export const DetailListDocAnalysisBase = (props: IDetailListDocAnalysisProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [items, setItems] = useState<analyzes[]>([]);
    const { t } = useTranslation(['listDocAnalysis', 'docAnalysis', 'common']);
    const navigator = useNavigator();
    const renderStatus = (status: AnalyzesStatus) => {
        let textStatus = '';
        switch (status) {
            case 0: textStatus = t('inAnalysis'); break;
            //case 1: textStatus = t("ToBeValidated"); break;
            //case 2: textStatus = t('Validated'); break;
            case 1: textStatus = t('Completed'); break;
            case 2: textStatus = t('Completed'); break;
            case 3: textStatus = t("Error"); break;
            default: textStatus = ""; break;
        }
        return (<span>{textStatus}</span>)
    }

    useEffect(() => {
        setItems(props.analyzes);
    }, [props.analyzes]);

    useOnMount(() => {
        registerEvent();
    });

    const registerEvent = useCallback(() => {
        const listElm = document.querySelector('#docAnalysisDetailsListZone .ms-DetailsList-contentWrapper');
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 150) {
                const button = document.getElementById("click");
                button && button.click();
            }
        });
    }, []);
    const renderType = (type: AnalysisTypeId): string => {
        let textType = '';
        switch (type) {
            case 0: textType = t('docAnalysis:qualityCheck.title'); break;
            case 1: textType = t("docAnalysis:tableOfContent.title"); break;
            case 2: textType = t("docAnalysis:glossaryCheck.title"); break;
            case 3: textType = t("docAnalysis:searchAndCompare.title"); break;
            default: textType = ""; break;
        }
        return textType;
    }

    const getModifiedDate = (createdOn: string) => {
        return Helpers.formatToRelativeDate(createdOn);
    }

    const columns: IColumn[] = [
        {
            key: 'name',
            name: t('column.name'),
            ariaLabel: 'name',
            isResizable: true,
            fieldName: 'name',
            minWidth: 80,
            maxWidth: 150,
            isCollapsible: true,
            onRender: function getItemName(item: analyzes) {
                return <FileNameCell
                    name={item.name}
                    pointer={(item.statusId !== AnalyzesStatus.Validated && item.statusId !== AnalyzesStatus.ToBeValidated) || item.typeId === AnalysisTypeId.GlossaryCheck || !props.canUserViewResult}
                    className={classNames.analysisFieldName}
                    item={item}
                    onClick={(item.statusId === AnalyzesStatus.Validated || item.statusId === AnalyzesStatus.ToBeValidated) && (props.canUserViewResult) && item.typeId !== AnalysisTypeId.GlossaryCheck ? () => navigator.goToAnalysisPath(item.id, item.typeId)
                        : undefined}
                />
            }
        },
        {
            key: 'type',
            name: t('column.type'),
            ariaLabel: 'type',
            fieldName: 'TypeId',
            minWidth: 100,
            maxWidth: 150,
            isSorted: props.orderBy.fieldName === "TypeId",
            //isResizable: true,
            isCollapsible: true,
            isSortedDescending: !props.orderBy.ascending,
            styles: classNames.subComponentStyles.column,
            onColumnClick: (_, column) => props.sortByColumn(_, column),
            onRender: function getItemType(item: analyzes) {
                return <TooltipHost content={renderType(item.typeId)}><span>{renderType(item.typeId)}</span></TooltipHost>
            }
        },
        {
            key: 'IsCrossArchive?',
            name: t('multiple'),
            ariaLabel: 'IsCrossArchive',
            fieldName: 'IsCrossArchive',
            // isResizable: true,
            minWidth: 80,
            styles: classNames.subComponentStyles.column,
            isCollapsible: true,
            maxWidth: 80,
            onColumnClick: (_, column) => props.sortByColumn(_, column),
            onRender: function ismulti(item: analyzes) {
                if(item.isCrossArchive) return <Icon iconName="Accept" style={{ fontSize: '12px',display:'flex',justifyContent:'center'}} />
                
            }
        },
        {
            key: 'createdOn',
            name: t('column.createdOn'),
            ariaLabel: 'createdOn',
            fieldName: 'CreatedOn',
            minWidth: 130,
            maxWidth: 200,
            //isResizable:true,
            isCollapsible: true,
            isSorted: props.orderBy.fieldName === "CreatedOn",
            isSortedDescending: !props.orderBy.ascending,
            styles: classNames.subComponentStyles.column,
            onColumnClick: (_, column) => props.sortByColumn(_, column),
            onRender: function getItemDate(item: analyzes) {
                return <span>{getModifiedDate(item.createdOn)}</span>
            }
        },
        {
            key: 'startedBy',
            name: t('column.startedBy'),
            ariaLabel: 'startedBy',
            fieldName: 'startedBy',
            minWidth: 100,
            maxWidth: 210,
            //isResizable:true,
            isCollapsible: true,
            onRender: function getItemFrom(item: analyzes) {
                return <span>{item.startedBy}</span>
            }
        },
        {
            key: 'lastUpdatedOn',
            name: t('column.lastUpdatedOn'),
            ariaLabel: 'lastUpdatedOn',
            fieldName: 'lastUpdatedOn',
            minWidth: 130,
            maxWidth: 200,
            //isResizable:true,
            isCollapsible: true,
            onRender: function getItemUpdate(item: analyzes) {
                return <span>{getModifiedDate(item.lastUpdatedOn)}</span>
            }
        },
        // {
        //     key: 'validatedBy',
        //     name: t('column.validatedBy'),
        //     ariaLabel: 'validatedBy',
        //     fieldName: 'validatedBy',
        //     minWidth: 100,
        //     maxWidth: 210,
        //     //isResizable:true,
        //     isCollapsible: true,
        //     onRender: function getItemValidateFrom(item: analyzes) {
        //         return <span>{item.validatedBy}</span>
        //     }
        // },
        {
            key: 'statusId',
            name: t('column.status'),
            ariaLabel: 'statusId',
            fieldName: 'StatusId',
            isSorted: props.orderBy.fieldName === 'StatusId',
            isSortedDescending: props.orderBy.ascending,
            // isResizable: true,
            minWidth: 80,
            styles: classNames.subComponentStyles.column,
            isCollapsible: true,
            maxWidth: 100,
            onColumnClick: (_, column) => props.sortByColumn(_, column),
            onRender: (item: analyzes) => (
                renderStatus(item.statusId)
            ),
        },
        
    ];

    const emptyList = useCallback(() => {
        if (items.length !== 0)
            return null;

        return (
            <div className={classNames.emptyList}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyList")}
                />
            </div>
        )
    }, [items.length, t, classNames.emptyList]);

    return (
        <div className={classNames.detailListContainer} id={"docAnalysisDetailsListZone"}>
            <DetailsList
                items={items}
                columns={columns}
                selectionMode={SelectionMode.none}
                isHeaderVisible={true}
                getKey={((item: analyzes) => item.id.toString())}
                setKey="none"
                styles={classNames.subComponentStyles.detailsList}
                onRenderRow={props => props ? <DetailsRow {...props} /> : null}
                onRenderDetailsFooter={emptyList}
            />
            <div className={classNames.load}>
                <PrimaryButton id={props.noMore ? "noClick" : "click"} onClick={props.load} />
            </div>
        </div>
    );
}