import { styled } from "@fluentui/react";
import { getStyles } from "./customWidgetSelect.styles";
import { ICustomWidgetSelectPropsStyles, ICustomWidgetSelectStyles, ICustomWidgetSelectProps } from "./customWidgetSelect.types";
import { CustomWidgetSelectBase } from "./customWidgetSelect.base";

const CustomWidgetSelect = styled<ICustomWidgetSelectProps, ICustomWidgetSelectPropsStyles, ICustomWidgetSelectStyles>(
    CustomWidgetSelectBase,
    getStyles,
    undefined,
    { scope: 'CustomWidgetSelect' }
);

export default CustomWidgetSelect;