import { styled } from "@fluentui/react";
import { getStyles } from "./uploadFileModal.styles";
import { IUploadFileModalPropsStyles, IUploadFileModalStyles, IUploadFileModalProps } from "./uploadFileModal.types";
import { UploadFileModalBase } from "./uploadFileModal.base";

const UploadFileModal = styled<IUploadFileModalProps, IUploadFileModalPropsStyles, IUploadFileModalStyles>(
    UploadFileModalBase,
    getStyles,
    undefined,
    { scope: 'ModalUploadFile' }
);

export default UploadFileModal;