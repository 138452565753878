import { styled } from "@fluentui/react";
import { getStyles } from "./jsonForm.styles";
import { IJsonFormPropsStyles, IJsonFormStyles, IJsonFormProps } from "./jsonForm.types";
import { JsonFormBase } from "./jsonForm.base";

const JsonForm = styled<IJsonFormProps, IJsonFormPropsStyles, IJsonFormStyles>(
    JsonFormBase,
    getStyles,
    undefined,
    { scope: 'JsonForm' }
);

export default JsonForm;