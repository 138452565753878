import { IStyleFunction } from "@fluentui/react"
import { IFormalAspectsPropsStyles, IFormalAspectsStyles } from "./formalAspects.types"

export const FormalAspectsGlobalClassNames = {
    root: 'edi-formal-aspects-root'
}

export const getStyles: IStyleFunction<IFormalAspectsPropsStyles, IFormalAspectsStyles> = (props: IFormalAspectsPropsStyles): IFormalAspectsStyles => {
    const { palette } = props.theme!;//eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            FormalAspectsGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        error: {
            color: palette.redDark,
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        },
        container: {
            // position: 'relative',
            // height: '30vh',
            position:'absolute',
            width:'100%',
            height:'100%'
        },
        empty: {
            fontWeight: 'bold',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    };
}