/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from "react";
import { classNamesFunction, DetailsList, DetailsRow, FontIcon, IColumn, SelectionMode, Spinner, SpinnerSize, TooltipHost } from "@fluentui/react";
import { IKnowledgeLabFileUploaderPropsStyles, IKnowledgeLabFileUploaderStyles, IKnowledgeLabFileUploaderProps } from "./knowledgeLabFileUploader.types";
import { NavigableItem } from "../../models/navigableFile";
import _ from "lodash";
import FileIconCell from "../../../common/components/fileIconCell/fileIconCell";
import { UploadStatus } from "../common/fileUploader/fileUploader.types";
import { FileFormatExtension } from "../../../utilities/constants";
import { ErrorDetails } from "../../../modules/apiClient/apiClient";
import { useTranslation } from "react-i18next";
import { useKnowledgeLabSelector } from "../../knowledgeLabStore";

const getClassNames = classNamesFunction<IKnowledgeLabFileUploaderPropsStyles, IKnowledgeLabFileUploaderStyles>();

export const KnowledgeLabFileUploaderBase = (props: IKnowledgeLabFileUploaderProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const [items, setItems] = useState<NavigableItem[]>(props.files);
    const [loading, setLoading] = useState<boolean>(false);
    const [countFilesLoaded, setCountFilesLoaded] = useState<number>(0);
    const { selectedKnowledgeLab } = useKnowledgeLabSelector(state => state.knowledgeLabList);
    const { t } = useTranslation(["importKnowledgeLabDocument"]);

    useEffect(() => {
        scheduleUploadFiles();
    }, [items.length]); //eslint-disable-line react-hooks/exhaustive-deps

    const scheduleUploadFiles = async () => {
        setLoading(true);
        props.isUploading && props.isUploading(true);
        const itemsToUpload = _.filter(items, ['fileUploadStatus', UploadStatus.ToUpload]);
        for (const file of itemsToUpload) {
            if (file.isValid) {
                setCountFilesLoaded(countFilesLoaded => countFilesLoaded + 1)
                await uploadFile(file);
            }
        }
        setLoading(false);
        props.isUploading && props.isUploading(false);
    };

    const uploadFile = async (inputFile: NavigableItem) => {
        const fileExtension = inputFile.name.substring(inputFile.name.lastIndexOf('.'), inputFile.name.length);
        const fileExtensionType = FileFormatExtension.find(a => a.extension === fileExtension.toLowerCase())?.key;

        if ((items.length > props.maxNumFilesUploadable - 1))
            return;

        if (!selectedKnowledgeLab)
            return;

        const copyItems = items.slice()
        const index = _.findIndex(copyItems, ['id', inputFile.id]);
        inputFile.fileUploadStatus = UploadStatus.Uploading;
        copyItems[index] = inputFile;
        setItems(copyItems);

        if (!props.fileTypes.some(x => x === inputFile.extension || x === fileExtensionType)) {
            const index = _.findIndex(copyItems, ['id', inputFile.id]);
            inputFile.fileUploadStatus = UploadStatus.Error;
            inputFile.errorMessage = <span>{t('steps.uploadFiles.picker.fileTypeNotAllowed')}</span>;
            copyItems[index] = inputFile;
            setItems(copyItems);

            return;
        }

        const formData = new FormData();
        formData.append("File", inputFile.file as File);
        formData.append("FileId", inputFile.id);
        formData.append("FileName", inputFile.name);

        if (inputFile.metadata)
            formData.append("Metadata", inputFile.metadata);

        if (inputFile.filePath)
            formData.append("FilePath", inputFile.filePath);

        if (inputFile.driveId)
            formData.append("DriveId", inputFile.driveId);

        // eslint-disable-next-line
        formData.append("Id", selectedKnowledgeLab!.id.toString());


        try {
            if (props.sendFiles) {
                await props.sendFiles(formData);
            }

            const index = _.findIndex(copyItems, ['id', inputFile.id]);
            inputFile.fileUploadStatus = UploadStatus.Uploaded;
            copyItems[index] = inputFile;
            setItems(copyItems);
        }
        catch (error) {
            let messages: JSX.Element | JSX.Element[];

            if (error instanceof ErrorDetails && error.errorCodes) {
                const errorList = error.getAllValidationErrorCodes();
                messages = errorList.map((code, ind) => <span key={code}>{t(code)} {errorList.length > ind + 1 && " - "} </span>);
            }
            else if (error.code === 409) {
                messages = <span>{t('uploadConflictError')}</span>
            }
            else if (error.code === 422) {
                messages = <span>{t('uploadUnprocessableError')}</span>
            }
            else if (error.code === 413) {
                messages = <span>{t('uploadEntityTooLarge')}</span>
            }
            else {
                messages = <span>{t('uploadGenericError')}</span>
            }

            const index = _.findIndex(copyItems, ['id', inputFile.id]);
            inputFile.fileUploadStatus = UploadStatus.Error;
            inputFile.errorMessage = messages;
            copyItems[index] = inputFile;
            setItems(copyItems);
        }
    }

    const iconRender = (item: NavigableItem) => {
        switch (item.fileUploadStatus) {
            case UploadStatus.Uploading: return <Spinner size={SpinnerSize.small} />;
            case UploadStatus.Uploaded: return <FontIcon iconName="CompletedSolid" style={{ fontSize: 16, color: palette.green }} />;
            case UploadStatus.Error: return (
                <TooltipHost content={item.errorMessage} id={item.id} styles={{ root: { display: 'flex', alignItems: 'center' } }}>
                    <FontIcon iconName="StatusErrorFull" aria-describedby={item.id} style={{ fontSize: 16, color: palette.red, cursor: 'help' }} />
                </TooltipHost>
            );
            default: return <FontIcon iconName="ReminderTime" style={{ fontSize: 16 }} />;
        }
    }

    const getColumns = (): IColumn[] => {
        return [
            {
                key: 'column1',
                name: 'fileStatus',
                minWidth: 10,
                maxWidth: 10,
                onRender: function columnRender(item: NavigableItem) {
                    return iconRender(item)
                }
            },
            {
                key: 'column2',
                name: 'fileType',
                minWidth: 20,
                maxWidth: 20,
                onRender: function columnRender(item: NavigableItem) {
                    const ext = item.extension as string;
                    return <FileIconCell fileExtension={ext} />
                },
            },
            {
                key: 'column3',
                name: 'fileName',
                isResizable: true,
                minWidth: 170,
                maxWidth: 250,
                onRender: function columnRender(item: NavigableItem) {
                    return <span>{item.name}</span>
                },
            }
        ];
    };

    return (
        <>
            <span className={classNames.textParagraph}>
                {!loading && countFilesLoaded === 0 ? '' : !loading && countFilesLoaded > 0 ? `${_.filter(items, ['fileUploadStatus', UploadStatus.Uploaded]).length} ${t('steps.uploadFiles.uploadedText')}` : `${t('steps.uploadFiles.uploadingText')} ${countFilesLoaded} ${t('of')} ${_.filter(items, ['isValid', true]).length}`}
            </span>
            <DetailsList
                items={_.filter(props.files, ['isValid', true])}
                styles={{
                    contentWrapper: {
                        width: '100%',
                        maxHeight: '100%',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    },
                    root: {},
                    focusZone: {},
                    headerWrapper: {}
                }}
                columns={getColumns()}
                setKey="uploadFiles"
                isHeaderVisible={false}
                getKey={(item: NavigableItem) => item?.id}
                selectionMode={SelectionMode.none}
                onRenderRow={props => props ? <DetailsRow {...props} /> : null}
            />

        </>
    );
}