import { ImageName } from "../../common/components/teamsImage/teamsImage.types"

export enum ExcelType {
    ACPV = 0,
    DocsTree = 1
}

export enum ArchiveRoleId {
    Owner = 0,
    Contributor = 1,
    Reader = 2,
    Architect = 3,
    Professional = 4,
    Uploader = 5
}

export const ArchiveRoleIdWeight =
[
    { archiveRoleId: ArchiveRoleId.Owner, weight: 5 },
    { archiveRoleId: ArchiveRoleId.Contributor, weight: 3 },
    { archiveRoleId: ArchiveRoleId.Reader, weight: 0 },
    { archiveRoleId: ArchiveRoleId.Architect, weight: 4 },
    { archiveRoleId: ArchiveRoleId.Professional, weight: 2 },
    { archiveRoleId: ArchiveRoleId.Uploader, weight: 1 }
]

export enum ItemToCopy {
    DeepLink = 1,
    DocumentId = 2
}

export enum ArchiveTypeId {
    Personal = 0,
    Unit = 1,
    Activity = 2,
    Search = 3,
    SearchAndCompare = 4,
}

export enum ArchiveStatusFilesApproval {
    Disabled = 0,
    InProgress = 1,
    Enabled = 2
}

export enum AnalysisStatus {
    WaitingForIndexing = 0,
    ToBeReviewed = 1,
    Reviewed = 2
}

export const Constants = {
    MAX_LENGTH_ARCHIVE_ITEM_NAME: 255,
    MAX_LENGTH_ARCHIVE_NAME: 50,
    MAX_LENGTH_ARCHIVE_DESCRIPTION: 255,
    MAX_LENGHT_25: 25,
    PAGE_SIZE: 50,
    CAROUSEL_ITEMS: 4,
    LIST_ANALYSIS: 20,
    standardItemNameMaxLength: 200,
    tocNameMaxLenght: 50,
    MAX_FACETS_SHOWED: 5,
    //Parametro di livello di profondita' massimo in creazione di ToC.
    //In base al parametro inserito non si potranno creare figli oltre a quel livello.
    TOC_MAX_DEPTH: 4,

    PAGE_SIZE_START_ANALYSIS: 100,
    MAX_INTEGER: 2147483647
}

export const ConstantsDocLabSetting = {
    MAX_LENGTH_KEYWORD: 50,
    MIN_LENGTH_KEYWORD: 3,
    MAX_LENGTH_LIST_KEYWORD: 10
}

export enum DocAnalysis {
    QualityCheck = 0,
    TocVerification = 1,
    PartsOfTest = 2,
    TextVsGlossary = 3,
}

export enum AnalyzesStatus {
    InAnalysis = 0,
    ToBeValidated = 1,
    Validated = 2,
    Error = 3
}

export enum Labels {
    Negligible = 0,
    Small = 1,
    Medium = 2,
    Large = 3
}

export enum AnalysisDocWarning {
    WrongExtension = 1,
    TocNotFound = 2,
    InvalidToc = 3
}

export enum AnalysisDocError {
    Generic = 0,
    BlobNotFound = 1,
    Parsing = 2
}

export const FormalAspects =
[
    { key: 'TableCount', value: 0 },
    { key: 'ParagraphCount', value: 1 },
    { key: 'PageCount', value: 2 },
    { key: 'WordCount', value: 3 },
    { key: 'ImageCount', value: 4 }
]

export const FormalAspectsSettingsKeys =
[
    { key: 'WordCount', value: 0 },
    { key: 'TableCount', value: 1 },
    { key: 'PageCount', value: 2 },
    { key: 'ParagraphCount', value: 3 },
    { key: 'ImageCount', value: 4 }
]

export const SearchFiltersItems = {
    ApprovalStatus: "ApprovalStatus",
    Languages: "Languages",
    FormatExt: "FormatExt",
    DateCreated: "DateCreated",
    FormalAspects: "FormalAspects",
    Locations: "Locations",
    Organizations: "Organizations",
    Keyphrases: "Keyphrases",
    AlreadyApproved: "AlreadyApproved"
}

export const SearchFilters = {
    items: [
        SearchFiltersItems.ApprovalStatus,
        SearchFiltersItems.Languages,
        SearchFiltersItems.DateCreated,
        SearchFiltersItems.Keyphrases,
        SearchFiltersItems.Locations,
        SearchFiltersItems.Organizations,
        SearchFiltersItems.FormatExt,
        SearchFiltersItems.FormalAspects,
        SearchFiltersItems.AlreadyApproved
   ]
}

export enum ContentItemScope {
    All = 0,
    Folder = 1,
    File = 2
}

export enum ContentItemIndexingStatus {
    All = 0,
    Indexed = 1,
    ToBeIndexed = 2
}

export const GlossaryExcelDataValidationErrors = {
    MIN_LENGTH_CELL: 3,
    MAX_COUNT_COLUMNS: 2
}

export const GlossaryCheckTeamsImage =
[
    { key: 'glossaryCheckInfoIt', value: ImageName.GlossaryCheckInfoIt },
    { key: 'glossaryCheckInfoEn', value: ImageName.GlossaryCheckInfoEn }
]

export const SharePointDocumentPathSuffixes = {
    Edit: '&allowtyping=true&wddownloadbutton=false',
    ViewOnly: '&action=embedview&allowtyping=false&wddownloadbutton=false'
};

export const FileExtensions = {
    Pdf: ".pdf"
}

export enum SelectionProfile {
    Owner = 'Owner',
    Fathers = 'Fathers',
    Brothers = 'Brothers'
}

export enum DeletedType {
    ByFiles = 0,
    ByRecycleBin = 1
}

export enum UnivocityRulesUsageType {
    AllTheArchive = 0,
    OnlyCurrentFolder = 1
}