import { styled } from "@fluentui/react";
import { getStyles } from "./activityLabSettings.styles";
import { IActivityLabSettingsPropsStyles, IActivityLabSettingsStyles, IActivityLabSettingsProps } from "./activityLabSettings.types";
import { ActivityLabSettingsBase } from "./activityLabSettings.base";

const ActivityLabSettings = styled<IActivityLabSettingsProps, IActivityLabSettingsPropsStyles, IActivityLabSettingsStyles>(
    ActivityLabSettingsBase,
    getStyles,
    undefined,
    { scope: 'ActivityLabSettings' }
);

export default ActivityLabSettings;