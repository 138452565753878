import { styled } from "@fluentui/react";
import { getStyles } from "./createDatasetModal.styles";
import { ICreateDatasetModalPropsStyles, ICreateDatasetModalStyles, ICreateDatasetModalProps } from "./createDatasetModal.types";
import { CreateDatasetModalBase } from "./createDatasetModal.base";

const CreateDatasetModal = styled<ICreateDatasetModalProps, ICreateDatasetModalPropsStyles, ICreateDatasetModalStyles>(
    CreateDatasetModalBase,
    getStyles,
    undefined,
    { scope: 'CreateDatasetModal' }
);

export default CreateDatasetModal;