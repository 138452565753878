import { styled } from "@fluentui/react";
import { getStyles } from "./tableOfContentItem.styles";
import { TableOfContentItemBase } from "./tableOfContentItem.base";
import { ITableOfContentItemProps, ITableOfContentItemPropsStyles, ITableOfContentItemStyles } from "./tableOfContentItem.types";

const TableOfContentItem = styled<ITableOfContentItemProps, ITableOfContentItemPropsStyles, ITableOfContentItemStyles>(
    TableOfContentItemBase,
    getStyles,
    undefined,
    { scope: 'TableOfContent' }
);

export default TableOfContentItem;