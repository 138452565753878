/*eslint-disable sonarjs/cognitive-complexity */
import { Checkbox, classNamesFunction, Dropdown, DropdownMenuItemType, Icon, IDropdownOption, ISelectableOption, SearchBox, Stack } from "@fluentui/react";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAdvancedDropdownProps, IAdvancedDropdownPropsStyles, IAdvancedDropdownStyles} from "./advancedDropdown.types";

const getClassNames = classNamesFunction<IAdvancedDropdownPropsStyles, IAdvancedDropdownStyles>();

export const AdvancedDropdownBase: React.FC<IAdvancedDropdownProps> = (props: IAdvancedDropdownProps) => {

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className});
    const [searchText, setSearchText] = useState<string | undefined>('');
    const { t } = useTranslation('common');
    const [options, setOptions] = useState<IDropdownOption[]>([]);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [optionLenght, setOptionLenght] = useState<number>(options.length - 4);

    useEffect(() => {
        if (!props.selectedItems)
            return;

        if (props.selectedItems.length > 0) {
            setSelectedItems(props.selectedItems);

            if (props.multiselect && props.onChangeItems) {
                props.onChangeItems(props.selectedItems); 
            }                           
        }
    }, [props.selectedItems]);  //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let optionsTemp: IDropdownOption[] = [];

        if (props.options && !props.multiselect) {
            optionsTemp = [
                { key: 'FilterHeader', text: '-', itemType: DropdownMenuItemType.Header, hidden: true },
                { key: 'divider_filterHeader', text: '-', itemType: DropdownMenuItemType.Divider, hidden: true },
                ...props.options.map(op => !op.disabled && op.text.toLocaleLowerCase().indexOf(searchText ? searchText.toLocaleLowerCase() : '') > -1 ?
                op : {...op, hidden: true})
            ]
        }
        if(props.options && props.multiselect) {
            optionsTemp = [
                { key: 'FilterHeader', text: '-', itemType: DropdownMenuItemType.Header, hidden: true },
                { key: 'divider_filterHeader', text: '-', itemType: DropdownMenuItemType.Divider, hidden: true },
                { key: 'SelectAll', text: '-', itemType: DropdownMenuItemType.Header, hidden: true},
                { key: 'divider_filterHeader', text: '-', itemType: DropdownMenuItemType.Divider, hidden: true },
                ...props.options.map(op => !op.disabled && op.text.toLocaleLowerCase().indexOf(searchText ? searchText.toLocaleLowerCase() : '') > -1 ?
                op : {...op, hidden: true})
            ]
        }
        setOptions(optionsTemp);
        setOptionLenght(optionsTemp.length - 4)
    }, [props.options, searchText, props.multiselect]);  

    function renderOption(option?: ISelectableOption): JSX.Element {
        if (!option)
            return <></>
        
        if (option.itemType === DropdownMenuItemType.Header && option.key === "FilterHeader") 
            return <SearchBox 
                onChange={(ev, newValue) => setSearchText(newValue)} 
                underlined={true} 
                placeholder={t('search')}
                />        
        else if (option.itemType === DropdownMenuItemType.Header && option.key === "SelectAll")
            return <Checkbox
                        label={t('selectAll')}
                        checked={setChecked()}
                        className={classNames.checkboxItems}
                        onChange={(ev, checked) => handleSelectAll(checked)}
                    />                 
        else 
            return <div className={classNames.dropdownElement}>
                    {option.text}
                </div>
    }

    const setChecked = () => {
        if(selectedItems.length === options.filter(o => !o.hidden).length || selectedItems.length === optionLenght)
            return true

        return false
    }

    const handleSelectAll = (checked: boolean | undefined) => {
        if (!props.onChangeItems) 
            return;

        const keys = options.filter(o => !o.hidden).map(op => op.key) as number[];
        if (checked) {
            setSelectedItems(keys);
            props.onChangeItems(keys);                            
        }        
        else {
            setSelectedItems(selectedItems.filter(k => !keys.includes(k)));
            props.onChangeItems(selectedItems.filter(k => !keys.includes(k)));                                  
        }
    }

    function handleChangeItems(option: IDropdownOption) {
        if (!props.onChangeItems) 
          return;

        if (selectedItems.length === optionLenght && !option.selected) {
            const keys = selectedItems.filter(e => e !== option.key);
            setSelectedItems(keys);            
            props.onChangeItems(keys)
        }
        
        if (selectedItems.length !== optionLenght && option.selected){
            const keys = [...selectedItems, option.key as number];
            setSelectedItems(keys);
            props.onChangeItems(keys)
        }
        
        if (selectedItems.length !== optionLenght && !option.selected) {
            const keys = selectedItems.filter(e => e !== option.key);
            setSelectedItems(keys);
            props.onChangeItems(keys);
        }
    }

    return (
        <div className={props.multiselect ? classNames.dropdownItemMultiselect : classNames.dropdownItem}>
                <Dropdown
                    key={props.key}
                    label={props.label}
                    options={options}
                    onRenderOption={renderOption}
                    onDismiss={() => {
                        setSearchText('');
                        if(props?.onCloseDropdown)
                            props?.onCloseDropdown(selectedItems);
                        
                    }}
                    styles={classNames.subComponentStyles.dropdown}
                    multiSelect={props.multiselect}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    onChange={async (_, option) => {
                        if (!option)
                            return;
                        if(!props.multiselect && props.onChangeItem)
                            props.onChangeItem(option.key as number);
                        else
                            handleChangeItems(option);
                    }}
                    selectedKeys={props.multiselect ? selectedItems as number[] : undefined}
                    selectedKey={props.multiselect ? undefined : selectedItems}
                    onRenderCaretDown={() => 
                        <Stack horizontal verticalAlign="center">
                            {!isEmpty(props.selectedItems) && !props.multiselect && (
                                <Icon
                                iconName="Cancel"
                                className={classNames.cancelIcon}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if(props.onCancelSelection) props.onCancelSelection();
                                }}
                                />
                            )}
                            {!props.disabled &&
                                <Icon
                                    iconName={"ChevronDown"}
                                    className={classNames.chevronDownIcon}
                                />}
                        </Stack>
                    }                    
                />
            </div>
    );
}