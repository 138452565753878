import { IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react"
import { IFileMetadataOnUploadPropsStyles, IFileMetadataOnUploadStyles } from "./fileMetadataOnUpload.types"

export const getStyles: IStyleFunction<IFileMetadataOnUploadPropsStyles, IFileMetadataOnUploadStyles> = (props: IFileMetadataOnUploadPropsStyles): IFileMetadataOnUploadStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        body: {
            height: '100%'
        },
        leftContainer: {
            marginRight: 10,
            height: '100%'
        },
        dropzoneBorders: {
            border: '2px dashed ' + palette.neutralSecondary,
            borderRadius: '25px',
            padding: 10,
            height: '100%',
            boxSizing: 'border-box',
        },
        dropzoneContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            color: palette.neutralSecondary,
            '> :not(:first-child)': {
                marginTop: 10
            }
        },
        rightContainer: {
            '> :not(:first-child)': {
                marginTop: 20
            }
        },
        disclaimers: {
            '> :not(:first-child)': {
                marginTop: 5
            }
        },
        pickerErrors: {},
        fileList: {},
        detailsListContainer: {
            " .ms-DetailsRow-cell": {
                display: 'flex',
                alignItems: 'center'
            }
        },
        wellDoneImage: {
            flexBasis: '50%',
            flexShrink: 0
        },
        secondFooterContainer: {
            display: 'flex',
            alignItems: 'center',
            height: '100%'
        },
        subfirstFooterContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        firstFooterContainer: {
            paddingTop: '10px',
            textAlign: 'right'
        },
        footer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center'
        },
        spinner: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        progressIndicatorbody: {
            width: '30%'
        },
        progressIndicatoritemName: {
            textAlign: 'left'
        },
        progressIndicatoritemDescription: {
            textAlign: 'right'
        },
        emptyFolder: {
            height: '350px',
        },
        caption: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
        captionRow: {
            color: 'rgb(37, 36, 35)',
            fontWeight: 300
        },
        loadingErrorContainer: {
            height: 'calc(100% - 124px)', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center'
        },
        textOverflow: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        updateDependenciesContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '15px',
            marginBottom: '1px',
            height: 'auto'
        },
        saveMetadataContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '15px',
            marginTop: '1px',
            height: 'auto'
        },
        saveMetadataButtonOn: {
            color: palette.blueDark,
            backgroundColor: 'white',
            '&:hover': {
                color: palette.themePrimary,                
                backgroundColor: 'white'
            },
            '&:active': {             
                backgroundColor: 'white'
            }
        },
        saveMetadataButtonOff: {
            backgroundColor: 'white'
        },
        subComponentStyles: {
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                    },
                    cell: { alignItems: 'center', textOverflow: 'unset', display: 'flex', cursor: 'pointer' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
            detailsRowClicked: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        backgroundColor: palette.neutralTertiary
                    },
                    cell: { alignItems: 'center', textOverflow: 'unset', display: 'flex', cursor: 'pointer' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            }
        }
    };
}