import React, { useState } from "react";
import { classNamesFunction, ContextualMenu, Icon, IContextualMenuItem, INavLink, INavLinkGroup, Nav } from "@fluentui/react";
import { useOnMount } from "../../../utilities/hooks";
import { useTranslation } from "react-i18next";
import { IKnowledgeLabListProps, IKnowledgeLabListPropsStyles, IKnowledgeLabListStyles } from "./knowledgeLabList.types";
import TitleListSection from "../../../common/components/titleListSection/titleListSection";
/*eslint-disable sonarjs/cognitive-complexity */
import { loadKnowledgeLabList, useKnowledgeLabList } from "../../features/knowledgeLabList";
import { useKnowledgeLabDispatch, useKnowledgeLabState } from "../../knowledgeLabStore";
import { useNavigator } from "../../hooks/useNavigator";
import NavigationContainer from "../../../common/components/navigationContainer/navigationContainer";
import { KnowledgeLab, KnowledgeLabShort } from "../../models/knowledgeLab";
import { getKnowledgeActions } from "../../utilities/knowledgeLabActions";
import { KnowledgeLabRoleId } from "../../models/knowledgeLabRoleId";

const getClassNames = classNamesFunction<IKnowledgeLabListPropsStyles, IKnowledgeLabListStyles>();
export const KnowledgeLabListBase = (props: IKnowledgeLabListProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['knowledgeList', 'common']);

    const [openedMenuId, setOpenedMenuId] = useState<number>();
    const state = useKnowledgeLabState();

    const dispatch = useKnowledgeLabDispatch();
    const knowledgelab = useKnowledgeLabList();
    const navigator = useNavigator();
    useOnMount(() => {
        loadKnowledgeLabs();
    })

    //const [openedMenuId, setOpenedMenuId] = useState<number | undefined>(undefined);

    const loadKnowledgeLabs = (id?: number) => {
        dispatch(loadKnowledgeLabList(id)).then((result) => {
            const res = result.payload as { knowledges: KnowledgeLabShort[], knowledge: KnowledgeLab }

            if (res) {
                const knowledgeLabShorts = res.knowledges as KnowledgeLabShort[];
                if (!knowledgeLabShorts) return;

                let knowledgeLabToBeSelected = knowledgeLabShorts.find((knowledgeLabShort) => knowledgeLabShort.id === navigator.currentKnowledgeLabId);

                if (!knowledgeLabToBeSelected && knowledgeLabShorts.length > 0) {
                    knowledgeLabToBeSelected = knowledgeLabShorts[0];
                }

                if (knowledgeLabToBeSelected && navigator.isKnowledgeLabRoute)
                    navigator.changeKnowledgeLab(knowledgeLabToBeSelected, navigator.currentKnowledgeLabTab)
            } else
                return;

        });

    }

    const knowledgeLabNavLinkGroup: INavLinkGroup = {
        links:
            knowledgelab.knowledgeLabList.map(lab => {
                return {
                    key: lab.id.toString(),
                    name: lab.name,
                    url: "",
                }
            })
    };

    const changeKnowledgeLab = (key: number | undefined) => {
        const lab = knowledgelab.knowledgeLabList.find(i => i.id === key);
        lab && navigator.changeKnowledgeLab(lab);
    }

    const groups: INavLinkGroup[] = [knowledgeLabNavLinkGroup]
    const getContextualMenuItems = (labKey: string | undefined): IContextualMenuItem[] => {
        if (!labKey) return [];
        const labId = Number.parseInt(labKey);
        const lab = knowledgelab.knowledgeLabList.find(i => i.id === labId);
        if (!lab) return [];
        const actions = getKnowledgeActions(lab, knowledgelab.currentKnowledgeLabRoleId as KnowledgeLabRoleId);
        return actions.map(
            (action) => ({
                key: action.id,
                text: t(action.label as string),
                iconProps: { iconName: action.icon },
                onClick: () => action.onClick(state, navigator, dispatch)
            })
        );
    };

    const mapToLab = (link: INavLink) => {
        if (!link?.key) return {};
        return {
            id: Number.parseInt(link?.key),
            name: link.name
        }
    }
    const renderKnowledgeLabList = (groups: INavLinkGroup[]) => {
        return <>
            <Nav
                styles={classNames.subComponentStyles.nav}
                groups={groups}
                onLinkClick={(_, item) => {
                    if (!item) return;
                    const lab = mapToLab(item)
                    changeKnowledgeLab(lab.id)
                }}
                selectedKey={navigator.currentKnowledgeLabId?.toString()}
                onRenderLink={(link) => {
                    if (!link) return <></>;
                    return (
                        <>
                            <div className={classNames.renderLinkStyle}>{link?.name}</div>
                            <Icon
                                id={`more_${link?.id}`}
                                iconName="More"
                                className={classNames.moreButton}
                                styles={classNames.subComponentStyles.moreIcon}
                                style={openedMenuId === mapToLab(link).id ? { visibility: 'visible' } : undefined}
                                onClick={ev => {
                                    navigator.currentKnowledgeLabId === mapToLab(link).id && ev.stopPropagation();
                                    setOpenedMenuId(mapToLab(link).id);
                                }}
                            />
                            {openedMenuId === mapToLab(link).id &&
                                <ContextualMenu
                                    items={getContextualMenuItems(link?.key)}
                                    target={`#more_${link?.id}`}
                                    onDismiss={() => setOpenedMenuId(undefined)}
                                />
                            }
                        </>
                    )
                }}
            >
            </Nav>
        </>
    }
    return (
        <div className={classNames.root}>
            <TitleListSection
                title={t('title')}
                isReloading={knowledgelab.isLoading}
                refreshButtonHandler={() => dispatch(loadKnowledgeLabList(navigator.currentKnowledgeLabId))}
            />
            <NavigationContainer
                isError={knowledgelab.isError}
                isLoading={knowledgelab.isLoading}
                canUserCreateResource={true}
                createResourceText={t("createNew")}
                onClickFooterButton={() => { /* TO DO*/ }}
                iconName={"Archive"}
                showFooter={false}
            >
                <>
                    {knowledgelab.knowledgeLabList.length > 0 && knowledgeLabNavLinkGroup.links.length > 0 && renderKnowledgeLabList(groups)}
                </>
            </NavigationContainer>
        </div>
    );
}