import { ILabelStyles, IShimmeredDetailsListStyleProps, IShimmeredDetailsListStyles, IStyleFunction } from "@fluentui/react"
import { ISelectProfileMemberBasePropsStyles, ISelectProfileMemberBaseStyles } from "./selectProfileMember.types";


export const SelectProfileMemberGlobalClassNames = {
    root: 'edi-select-profile-viewer-root',
    peoplePickerSuggestions: 'edi-select-profile-viewer-peoplePickerSuggestions',
    peoplePickerSuggestionItem: 'edi-select-profile-viewer-peoplePickerSuggestionItem',
    iconTagLabel: 'edi-select-profile-view-icontaglabel',
    containerTag: 'edi-select-profile-containertag',
    profileContainer: 'edi-select-profile-profilecontainer', 
    templatesCountCircle: 'edi-select-profile-templatescountcircle',
    remainingIconTagContainer: 'edi-select-profile-remainingicontagcontainer'
}

export const getStyles: IStyleFunction<ISelectProfileMemberBasePropsStyles, ISelectProfileMemberBaseStyles> = (props: ISelectProfileMemberBasePropsStyles): ISelectProfileMemberBaseStyles => {
    const {semanticColors, palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            SelectProfileMemberGlobalClassNames.root,
            {
                
            }
        ],
        peoplePickerSuggestionItem: [
            SelectProfileMemberGlobalClassNames.peoplePickerSuggestionItem,
            {
                
            }
        ],
        peoplePickerSuggestions: [
            SelectProfileMemberGlobalClassNames.peoplePickerSuggestions,
            {
                
            }
        ],
        containerTag: {
            display: 'flex', 
            height: '100%', 
            alignItems: 'center',
            flexFlow: 'row wrap',
            gap: '5px 10px' 
        }, 
        iconTagLabel: {
            width: '130px',
            overflow: 'hidden',
            height: '25px',
            padding: '3px 0'
        },             
        profileContainer: {
            display: 'flex'            
        },
        remainingIconTagContainer: {
            padding: '0 5px',
            width: '100%',
            textAlign: 'left',
            justifyContent: 'start',
            color: palette.themePrimary
        },
        subComponentStyles: {
            templatesCountCircle: (): ILabelStyles => {
                return {
                    root: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        fontSize: '12px',
                        backgroundColor: semanticColors.variantBorder,
                        color: palette.themePrimary,            
                    }
                }
            },
            shimmeredDetailsList: (props: IShimmeredDetailsListStyleProps): IShimmeredDetailsListStyles => {
                return {
                    root: {
                        '::after': {
                            display: 'none'
                        },
                        position: 'relative'
                    }
                }
            },
        }
    }
}