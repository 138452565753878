import { IStyleFunction } from "@fluentui/react"
import { IFeedbackFiltersPropsStyles, IFeedbackFiltersStyles } from "./feedbackFilters.types"

export const FeedbackFiltersGlobalClassNames = {
    root: 'edi-feedback-filters-root',
    filterPanelItem: 'edi-feedback-filters-panel-item',
}

export const getStyles: IStyleFunction<IFeedbackFiltersPropsStyles, IFeedbackFiltersStyles> = (props: IFeedbackFiltersPropsStyles): IFeedbackFiltersStyles => {
    return {
        root: [
            FeedbackFiltersGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        filterPanelItem: [
            FeedbackFiltersGlobalClassNames.filterPanelItem,
            {
                marginBottom: '2em !important',
                '.ms-TextField-suffix': {
                    padding: 0,
                    background: 'transparent',
                }
                // CSS PROPS AND SELECTORS
            }
        ],
        scrollablePane: {
            ".ms-ScrollablePane--contentContainer": {
                paddingLeft: "20px",
                paddingRight: "20px",
            }
        }
    };
}