import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CancelToken } from "axios";
import { Helpers } from "../../utilities/helpers";
import { useActivityLabSelector } from "../activityLabStore";
import { Portfolio } from "../models/portfolio";
import { portfolioApi } from "../services/portfolio/portfolio.api";

export type PortfolioListState = {
  portfolioList: Record<number, Portfolio>;
  portfolioIds: number[];
  isLoading: boolean;
  isError: boolean;
  renamePortfolioModalShown: boolean;
};

const initialState: PortfolioListState = {
  portfolioList: {},
  portfolioIds: [],
  isLoading: false,
  isError: false,
  renamePortfolioModalShown: false,
};

export const loadPortfolioList = createAsyncThunk(
  "portfolioList/load-portfolio",
  async (cancelToken?: CancelToken) => await portfolioApi.getPortfolioList(cancelToken)
);

const portfolioListSlice = createSlice({
  name: "activityLab-portfolio-list",
  initialState,
  reducers: {
    showRenamePortfolioModal: (state: PortfolioListState, action: PayloadAction<boolean>) => {
      state.renamePortfolioModalShown = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadPortfolioList.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(loadPortfolioList.fulfilled, (state, action) => {
      state.portfolioList = Helpers.normalize(action.payload, (portfolio) => portfolio.id);
      state.portfolioIds = action.payload.map((portfolio) => portfolio.id);
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(loadPortfolioList.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.portfolioList = [];
      state.portfolioIds = [];
    });
  }
});

export const { showRenamePortfolioModal } = portfolioListSlice.actions;
export default portfolioListSlice.reducer;
export const usePortfolioList = () => useActivityLabSelector((state) => state.portfolioList);