import React, { useState } from "react";
import { classNamesFunction, DetailsList, IColumn, SelectionMode } from "@fluentui/react";
import { IFileToUpload } from "../uploadFileModal/uploadFileModal.types";
import { IDocumentTagsListProps, IDocumentTagsListPropsStyles, IDocumentTagsListStyles } from "./documentTagsList.types";
import { useTranslation } from "react-i18next";
import { useOnMount } from "../../../../utilities/hooks";
import JsonForm from "../../../../common/components/tagPanel/jsonForm/jsonForm";

const getClassNames = classNamesFunction<IDocumentTagsListPropsStyles, IDocumentTagsListStyles>();

export const DocumentTagsListBase = (props: IDocumentTagsListProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const { t } = useTranslation(['uploadFileModal', 'common']);
  const [errorForm, setErrorForm] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  useOnMount(()=>{
    if(props.shortJsonSchema === undefined || props.shortJsonSchema === null)
      setErrorForm(true);
  });
  
  const columns: IColumn[] = [
    {
      key: 'column0',
      name: t('detailsList.documentName'),
      ariaLabel: 'name',
      isResizable: false,
      fieldName: '',
      minWidth: 300,
      maxWidth: 300,
      isCollapsible: true,
      headerClassName: classNames.root,
      onRender: function columnRender(item: IFileToUpload) {
        return <span>{item.fileName}</span>
      }
    },
    {
      key: 'column1',
      name: t('detailsList.documentTag'),
      ariaLabel: 'tags',
      isResizable: true,
      fieldName: '',
      minWidth: 300,
      maxWidth: 300,
      isCollapsible: true,
      headerClassName: classNames.root,
      onRender: function columnRender(item: IFileToUpload) {
        return errorForm ? <span>{t('common:genericErrorApi')}</span> :
        <JsonForm
          searchText={searchText}
          formData={{}}
          schema={props.shortJsonSchema}
          uiSchema={{}}
          onChange={(changeEvent) => { item.oneMetadataProperty = changeEvent.formData; }}
          onChangeSearchText={(str: string) => setSearchText(str)}
          hasErrorFE={() => setErrorForm(true)}
          disabled={false}
          onSubmit={() => console.log("Submit")}
          onError={(err) => console.log("Errors: ", err)}
        />
      }
    }
  ];

  return (
    <DetailsList
      items={props.documents}
      columns={columns}
      styles={classNames.subComponentStyles.detailsList}
      setKey={"documentType"}
      getKey={(item: IFileToUpload) => item.id}
      selectionMode={SelectionMode.none}
      onShouldVirtualize={() => false}
    />
  );
}