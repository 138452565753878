import { styled } from "@fluentui/react";
import { IManageTopicsOfInterestProps, IManageTopicsOfInterestPropsStyles, IManageTopicsOfInterestStyles } from "./manageTopicsOfInterest.types";
import { getStyles } from "./manageTopicsOfInterest.styles";
import { ManageTopicsOfInterestBase } from "./manageTopicsOfInterest.base";

const ManageTopicsOfInterest = styled<IManageTopicsOfInterestProps, IManageTopicsOfInterestPropsStyles, IManageTopicsOfInterestStyles>(
    ManageTopicsOfInterestBase, 
    getStyles,
    undefined,
    { scope: 'ManageTopicsOfInterest'}
)

export default ManageTopicsOfInterest;