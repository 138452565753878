import { AnimationStyles, IStyleFunction } from "@fluentui/react"
import { ICarouselPropsStyles, ICarouselStyles, StepMutation } from "./carousel.types"
import { multiLineEllipsis } from '../../../utilities/constants';
export const CarouselGlobalClassNames = {
    root: 'edi-carousel-root',
    animation: 'edi-carousel-animation',
    cardDescription: 'edi-carousel-cardDescription',
    cardContainer: 'edi-carousel-cardContainer',
    cardTitleContainer: 'edi-carousel-cardTitleContainer',
    cardTitleArrow: 'edi-carousel-cardTitleArrow',
    cardTitle: 'edi-carousel-cardTitle',
    cardIconContainer: 'edi-carousel-cardIconContainer',
    cardIcon: 'edi-carousel-cardIcon',
    arrowContainer: 'edi-carousel-arrowContainer',
    arrowIcon: 'edi-carousel-arrowIcon',
}

export const getStyles: IStyleFunction<ICarouselPropsStyles, ICarouselStyles> = (props: ICarouselPropsStyles): ICarouselStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            CarouselGlobalClassNames.root,
            {
                margin: '20px auto',
                display: 'flex',
                justifyContent: 'space-evenly'
            }
        ],
        arrowContainer: [
            CarouselGlobalClassNames.arrowContainer,
            {
                userSelect: 'none',
                padding: '0 20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }],
        cardContainer: [
            CarouselGlobalClassNames.cardContainer, {
                padding: '10px',
                height: '190px',
                margin: '0 10px',
                boxShadow: 'rgb(136 136 136) 0px 1px 8px 0px',
                background: palette.white,
                flexGrow: 1,
                minWidth: '30px',
                maxWidth: '200px',
                flexShrink: 1,
                cursor:'pointer',
                flexBasis: '30px',
            }],
            disabledCardContainer:{
                padding: '10px',
                height: '190px',
                margin: '0 10px',
                boxShadow: 'rgb(136 136 136) 0px 1px 8px 0px',
                background: palette.neutralLight,
                flexGrow: 1,
                minWidth: '30px',
                maxWidth: '200px',
                cursor:'unset',
                pointerEvents: 'none',
                flexShrink: 1,
                flexBasis: '30px',
            },
        cardTitleContainer: [
            CarouselGlobalClassNames.cardTitleContainer,
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '10px'
            }],
        cardTitle: [
            CarouselGlobalClassNames.cardTitle,
            multiLineEllipsis(2),
            {
                fontWeight: 'bold'
            }],
        arrowIcon: [
            CarouselGlobalClassNames.arrowIcon,
            {
                fontSize: '60px',
                cursor: 'pointer'
            }],
        disabledIcon:
        {
            fontSize: '60px',
            cursor: 'none',
            pointerEvents: 'none',
            color: palette.neutralLighter
        },
        cardTitleArrow: [
            CarouselGlobalClassNames.cardTitleArrow, {
                cursor: 'pointer',
                userSelect: 'none'
            }],
        cardIconContainer: [
            CarouselGlobalClassNames.cardIconContainer, {
                padding: '10px 0'
            }],
        cardIcon: [
            CarouselGlobalClassNames.cardIcon, {
                fontSize: 30,
                color: palette.themePrimary
            }],
        animation: [
            CarouselGlobalClassNames.animation,
            props.stepMutation === StepMutation.exitPrevious && { ...AnimationStyles.slideLeftOut400, animationDuration: "100ms", animationTimingFunction: 'ease-in' },
            props.stepMutation === StepMutation.enterNext && { ...AnimationStyles.slideLeftIn400 },
            props.stepMutation === StepMutation.exitNext && { ...AnimationStyles.slideRightOut400 },
            props.stepMutation === StepMutation.enterPrevious && { ...AnimationStyles.slideRightIn400 },
            props.stepMutation === StepMutation.fadeOnLoad && { ...AnimationStyles.fadeIn200 },
            {
                height: '100%',
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center'
            }
        ],
        cardDescription: [
            CarouselGlobalClassNames.cardDescription,
            multiLineEllipsis(5)
        ]
    };
}