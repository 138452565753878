import { FontSizes, FontWeights, IStyleFunction, IDetailsListStyleProps, IDetailsListStyles, 
    IDetailsRowStyleProps, IDetailsRowStyles, ILabelStyles, IShimmeredDetailsListStyleProps, 
    IShimmeredDetailsListStyles } from "@fluentui/react"
import { IManageInterestsBasePropsStyles, IManageInterestsBaseStyles } from "./manageInterests.types";

export const manageInterestsGlobalClassNames = {
    root: 'edi-interests-management-root',
    title: 'edi-interests-management-title',
    titleIcon: 'edi-interests-management-titleIcon',
    reportDownload: 'edi-interests-management-report-download',
    headerRow: 'edi-interests-management-header-row',
    load: 'edi-interests-management-load',
    iconTagLabel: 'edi-interests-management-icontaglabel',
    containerTag: 'edi-interests-management-containertag',
    profileContainer: 'edi-interests-management-profilecontainer', 
    templatesCountCircle: 'edi-interests-management-templatescountcircle',
    remainingIconTagContainer: 'edi-interests-management-remainingicontagcontainer'
}

export const getStyles: IStyleFunction<IManageInterestsBasePropsStyles, IManageInterestsBaseStyles> = (props: IManageInterestsBasePropsStyles): IManageInterestsBaseStyles => {
    const { semanticColors, palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            manageInterestsGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px',
                '> .ms-Viewport': {
                    height: '100%'
                }
            }
        ],
        load: {
            display: 'none'
        },
        title: [
            manageInterestsGlobalClassNames.title,
            {
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingBottom: 10,
                paddingTop: 10
            }
        ],
        titleIcon: [
            manageInterestsGlobalClassNames.titleIcon,
            {
                padding: 7,
                border: '1.5px solid',
                borderRadius: '50%',
                fontSize: 26
            }
        ],
        reportDownload: [
            manageInterestsGlobalClassNames.reportDownload,
            {
                alignSelf:'flex-end',
                justifySelf:'right',
                fontSize: '12px',
                color: palette.themePrimary,
                display: 'flex',
                cursor: 'pointer'
            }
        ],
        headerRow: [
            manageInterestsGlobalClassNames.headerRow,
            {
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                }
            }
        ],
        containerTag: {
            display: 'flex', 
            height: '100%', 
            alignItems: 'center',
            flexFlow: 'row wrap',
            gap: '5px 10px' 
        }, 
        iconTagLabel: {
            width: '150px',
            overflow: 'hidden',
            height: '25px',
            padding: '3px 0'
        },             
        profileContainer: {
            display: 'flex'            
        },
        remainingIconTagContainer: {
            padding: '0 5px',
            width: '100%',
            textAlign: 'left',
            justifyContent: 'start',
            color: palette.themePrimary
        },
        subComponentStyles: {
            shimmeredDetailsList: (props: IShimmeredDetailsListStyleProps): IShimmeredDetailsListStyles => {
                return {
                    root: {
                        '::after': {
                            display: 'none'
                        },
                        position: 'relative'
                    }
                }
            },
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto',
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        flexBasis: 'calc(100vh - 315px)',
                        overflowY: 'overlay',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            },
            templatesCountCircle: (): ILabelStyles => {
                return {
                    root: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        fontSize: '12px',
                        backgroundColor: semanticColors.variantBorder,
                        color: palette.themePrimary,            
                    }
                }
            },
            row: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        backgroundColor: palette.white,
                        ':hover': {
                            backgroundColor: palette.white
                        }
                    },
                    cell: {},
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            }           
        },
    }
}