import { IWorkFlowListPropsStyles, IWorkFlowListStyles } from "./workFlowList.types"
import { DetailsRowGlobalClassNames, IChoiceGroupStyles, IDetailsColumnStyleProps, IDetailsColumnStyles, IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction, ITooltipHostStyles } from "@fluentui/react";

export const WorkFlowListGlobalClassNames = {
    root: 'edi-work-flow-list-root',
    emptyList: 'edi-work-flow-list-emptyList',
    detailListContainer: 'edi-work-flow-list-container',
    centeredContainer: 'edi-work-flow-list-centered-container',
    workflowListNameWrapper: 'edi-work-flow-list-workflow-list-name-wrapper',
    workflowName: 'edi-work-flow-list-workflow-name',
}

export const getStyles: IStyleFunction<IWorkFlowListPropsStyles, IWorkFlowListStyles> = (props: IWorkFlowListPropsStyles): IWorkFlowListStyles => {
    const { palette, semanticColors } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            WorkFlowListGlobalClassNames.root,
            {
                visibility: 'hidden'
            }
        ],
        load: {
            display: 'none'
        },
        detailListContainer: [
            WorkFlowListGlobalClassNames.detailListContainer,
            {
                marginRight: '30px',
                marginTop: '15px',
                marginBottom: '15px',
                height: '100%',
                '> .ms-Viewport': {
                    height: '100%'
                }
            }],
        emptyList: [
            WorkFlowListGlobalClassNames.emptyList,
            {
                position: 'absolute',
                top: '60px',
                width: '150px',
                left: 'calc(50% - 88px)'
            }],
        activityName: {
            padding: '0 2px',
            color: palette.black,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            selectors: {
                ':hover': {
                    textDecoration: 'underline'
                }
            }
        },
        centeredContainer: [
            WorkFlowListGlobalClassNames.centeredContainer,
            {
                height: '85vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        ],
        workflowListNameWrapper: [
            WorkFlowListGlobalClassNames.workflowListNameWrapper,
            {
                position: 'relative',
                paddingRight: 30,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        ],
        workflowName: [
            WorkFlowListGlobalClassNames.workflowName,
            {
                cursor: 'pointer',
                ':hover': {
                    textDecoration: 'underline'
                }
            }
        ],
        yellowPersonaStyle: {
            backgroundColor: palette.yellowLight,
            color: palette.yellowDark,
        },
        greenPersonaStyle: {
            backgroundColor: palette.greenLight,
            color: semanticColors.bodyText,
        },
        menuIconWrapper: {
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            color: 'inherit',
            visibility: 'hidden',
            [`.${DetailsRowGlobalClassNames.root}:hover &`]: {
                visibility: 'visible'
            }
        },
        deleteButtonContainer: {
            display: 'flex',
            alignItems: 'flex-end'
        },
        deleteFooterContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        deleteErrorMessageStyle: {
            color: palette.redDark,
            fontSize: '12px'
        },
        subComponentStyles: {
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto'
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        flexBasis: 'calc(100vh - 220px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            },
            column: (props: IDetailsColumnStyleProps): IDetailsColumnStyles => {
                return {
                    root: {},
                    gripperBarVerticalStyle: {},
                    cellTooltip: {},
                    cellTitle: {
                        cursor: 'pointer'
                    },
                    cellName: {},
                    iconClassName: {},
                    nearIcon: {},
                    accessibleLabel: {},
                    sortIcon: {},
                    filterChevron: {},
                    borderAfterDropping: {},
                    noBorderAfterDropping: {},
                    borderWhileDragging: {},
                    noBorderWhileDragging: {},
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                    },
                    cell: { alignSelf: 'center', textOverflow: 'unset' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
            tooltipStyle: (): ITooltipHostStyles => {
                return {
                    root: {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: palette.themePrimary,
                        cursor: 'context-menu'
                    }
                }
            },
            choiceGroupStyles: (): IChoiceGroupStyles => {
                return {
                    root: {
                    },
                    flexContainer: {
                        display: 'flex',
                        gap: '10px'
                    }
                }
            },
            iconButton: {
                root: {
                    height: '100%',
                    float: 'right'
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`
                },
                menuIcon: {
                    display: 'none !important'
                },
                rootExpanded: {
                    backgroundColor: 'inherit'
                },
                rootPressed: {
                    backgroundColor: 'inherit'
                },
                rootHovered: {
                    backgroundColor: 'inherit',
                }
            },
            deleteDialogFooterContainer: {
                actions: {
                    margin: '8px 0 0'
                }
            },
            deletePrimaryButtonDisabled: {
                root: {
                    selectors: {
                        ':disabled': {
                            backgroundColor: palette.neutralQuaternary,
                            color: palette.neutralTertiary,
                            pointerEvents: 'none'
                        },
                    }
                },
            }
        }
    };
}