import { FontSizes, FontWeights, IStyleFunction, ITooltipHostStyles } from "@fluentui/react"
import { IActivityCardPropsStyles, IActivityCardStyles } from "./activityCard.types"

export const ActivityCardGlobalClassNames = {
    root: 'edi-activity-card-root'
}

export const getStyles: IStyleFunction<IActivityCardPropsStyles, IActivityCardStyles> = (props: IActivityCardPropsStyles): IActivityCardStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            ActivityCardGlobalClassNames.root,
            {
                background: palette.white,
                width: 'auto',
                height: '150px',
                borderRadius: '4px',
                boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 4px -0.75px',
            }
        ],
        cardTitle:
        {
            fontSize: FontSizes.size16,
            fontWeight: FontWeights.semibold,
            paddingTop: 5,
            paddingBottom: 5
        },
        activityName: {
            padding: '0 2px',
            color: palette.black,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: FontSizes.size16,
            cursor: 'pointer',
            selectors: {
                ':hover': {
                    textDecoration: 'underline'
                }
            }
        },
        propertyName: {
            padding: '0 2px',
            color: palette.black,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        activityMemberPersonaStyle: {
            backgroundColor: palette.themePrimary,
            color: palette.white,
        },
        activityLeaderPersonaStyle: {
            backgroundColor: palette.themeTertiary,
            color: palette.themePrimary,
        },
        subComponentStyles: {
            tooltipStyle: (): ITooltipHostStyles => {
                return {
                    root: {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: palette.themePrimary,
                        cursor: 'context-menu'
                    }
                }
            }
        },
    };
}