/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react";
import { classNamesFunction, ConstrainMode, DetailsList, DetailsRow, DirectionalHint, IColumn, IconButton, IContextualMenuItem, IContextualMenuProps, SelectionMode, Spinner, SpinnerSize } from "@fluentui/react";
import { ISearchAndComparePropsStyles, ISearchAndCompareStyles, ISearchAndCompareProps } from "./searchAndCompareResults.types";
import { IResult, ISearchAndCompareData, ISearchAndCompareValue } from "../../../../models/docAnalysis";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { docAnalyzesApi } from "../../../../services/docAnalyzes/docAnalyzes.api";
import { Helpers } from "../../../../../utilities/helpers";
import Results from "../results";
import FileIconCell from "../../../../../common/components/fileIconCell/fileIconCell";
import { useDocLabDispatch, useDocLabState } from "../../../../docLabStore";
import { useNavigator } from "../../../../hooks/useNavigator";
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive";
import { Archive } from "../../../../models/archive";
import { getSearchActions } from "../../../../utilities/searchActions";
import { ArchiveTypeId } from "../../../../models/constants";
import { SelectionEntry, selectItems } from "../../../../features/archiveContent";
import { setCurrentAnalysisResults } from "../../../../features/docAnalysisContent";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
const getClassNames = classNamesFunction<ISearchAndComparePropsStyles, ISearchAndCompareStyles>();

export const SearchAndCompareBase = (props: ISearchAndCompareProps): JSX.Element => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [loading, setLoading] = useState(false);
    const [apiResult, setApiResult] = useState<IResult<ISearchAndCompareData>>();
    const [apiError, setApiError] = useState<number | undefined>(undefined);
    const [searchAndCompareResult, setSearchAndCompareResult] = useState<ISearchAndCompareValue[]>();
    const [analysisInfo, setAnalysisInfo] = useState<ISearchAndCompareData>();
    const location = useLocation();
    const splittedLocation = location.pathname.split('/');
    const id = splittedLocation.pop();
    const dispatch = useDocLabDispatch();
    const docLabState = useDocLabState();
    const navigator = useNavigator();
    const currentArchive = useCurrentArchive();
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const { t } = useTranslation(['common', 'analysisResults', 'docAnalysis']);
    const emptyMessage = `${t('analysisResults:searchAndCompareResult.emptySnippet')}`;

    useEffect(() => {
        if (!apiResult)
            return;
    }, [apiResult, t]);

    const getAnalysis = async () => {
        setLoading(true);
        setApiError(undefined);

        try {
            if (id) {
                const [APIresult] = await Promise.all([docAnalyzesApi.getAnalysisById(+id), Helpers.delay(500)]);
                return APIresult;
            }
        }
        catch (error: any) {
            setApiError(error.code);
        }
        finally {
            setLoading(false);
        }

        return null;
    };

    useEffect(() => {
        (async () => {
            const res = await getAnalysis();
            if (!res)
                return;

            try { setApiResult(JSON.parse(res.result ?? "")); } catch { setApiResult(undefined); }
            const apiResultParsed = JSON.parse(res.result ?? "")
            setAnalysisInfo({
                AnalysisName: apiResultParsed.AnalysisName,
                TextSection: apiResultParsed.TextSection,
                FileName: apiResultParsed.FileName,
                FileId: apiResultParsed.FileId,
                SearchResult: apiResultParsed.SearchResult,
            })
            setSearchAndCompareResult((apiResultParsed.SearchResult.Values as ISearchAndCompareValue[]).map(x => ({ ...x, More: false })))
            dispatch(setCurrentAnalysisResults((apiResultParsed.SearchResult.Values as ISearchAndCompareValue[]).map(x => ({ ...x, More: false }))))

        })()
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const renderDocumentAnalysis = () => {
        if (loading)
            return;
        if (apiError === undefined)
            return renderSearchAndCompareResult();
        if (apiError === 403)
            return <div><TeamsImage
                styles={{
                    img: {
                        width: '25%'
                    }
                }}
                imageName={ImageName.Forbidden}
                caption={t("docAnalysis:forbidden")}
            /></div>
        else
            return <div>{t('genericErrorApi')}</div>
    }

    const getContextualMenuItems = useCallback((archive: Archive, id: number): IContextualMenuItem[] => {
        return getSearchActions(ArchiveTypeId.SearchAndCompare, archive.currentUserRoleId).map(
            (action) => ({
                key: action.label,
                text: t(action.label),
                iconProps: { iconName: action.icon },
                onClick: () => action.onClick(docLabState, archive, navigator, dispatch, id),
            })
        );
    }, [navigator, t, dispatch, docLabState]);

    const menuProps = (id: number): IContextualMenuProps => {
        return ({
            items: currentArchive ? getContextualMenuItems(currentArchive, id) : [],
            directionalHint: DirectionalHint.rightTopEdge
        })
    }

    const onSelect = (fileId: number) => {
        const selectedItem: SelectionEntry[] = [];
        selectedItem.push({
            id: fileId,
            isFolder: false
        });
        dispatch(selectItems(selectedItem));
    }

    const setMoreAction = (item: ISearchAndCompareValue) => {
        if (searchAndCompareResult) {
            const newSearchAndCompare = searchAndCompareResult.slice()
            const index = newSearchAndCompare.findIndex(x => x.Highlights === item.Highlights)
            newSearchAndCompare[index].More = newSearchAndCompare[index].More ? !newSearchAndCompare[index].More : true

            setSearchAndCompareResult(newSearchAndCompare)
        }
    }

    const renderSearchAndCompareResult = () => {
        return (
            <>
                <div className={classNames.root} >
                    <div >
                        <span style={{ color: palette.neutralSecondary, marginRight: '5px' }}>{analysisInfo?.FileName ? `${t('analysisResults:searchAndCompareResult.subTitleFile')} ${analysisInfo?.FileName}:` : `${t('analysisResults:searchAndCompareResult.subTitle')}`}</span>

                        {analysisInfo?.TextSection}
                    </div>
                    <div >
                        <span style={{ color: palette.neutralSecondary, marginRight: '5px' }}>{searchAndCompareResult && searchAndCompareResult.length > 0 ? t('analysisResults:searchAndCompareResult.searchResults') : ''}</span>

                    </div>
                </div>
                <div>
                    {getAnalysisSection()}
                </div>

            </>
        )
    }

    const detailColumns: IColumn[] = [
        {
            key: '',
            name: '',
            fieldName: '',
            minWidth: 150,
            maxWidth: 300,
            headerClassName: classNames.headerRow,
            styles: {
                cellName: {
                    fontSize: '14px',
                    fontWeight: 'bold'
                },
                cellTitle: { justifyContent: 'center' }
            },
            onRender: function getItems(item: ISearchAndCompareValue) {
                return (
                    <>
                        <div className={classNames.itemHeader}>
                            {item.Document.Title !== null && <b style={{ color: palette.black }}>{item.Document.Title}</b>}
                            <IconButton
                                onMenuClick={() => { onSelect(item.Document.FileRef) }}
                                styles={classNames.subComponentStyles.iconButton()}
                                iconProps={{ iconName: 'More' }}
                                menuProps={menuProps(item.Document.FileRef)}
                            />
                        </div>
                        <div key={item.Document.FileRef} className={classNames.itemCell} style={{ maxHeight: (item.More || (item.Highlights["Content"] && item.Highlights["Content"].length === 1) ? 'inherit' : '100px') }} >
                            <FileIconCell
                                fileExtension={item.Document.Title.split('.').pop() || ''}
                                styles={{ fileIconWrapper: { selectors: { 'i': { width: '35px', height: '50px', marginLeft: '5px' } } } }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                                <div className={classNames.itemContent}>
                                    {(item.Highlights["Content"] && item.Highlights["Content"].length > 0) ? item.Highlights["Content"]?.map((h, i) =>
                                        <div key={i} className={classNames.itemName} dangerouslySetInnerHTML={{ __html: h }} />
                                    ) : <div className={classNames.itemName}> {t('analysisResults:searchAndCompareResult.emptySnippet')} </div>}
                                </div>
                                <div style={{ cursor: 'pointer', display: (item.Highlights["Content"] && item.Highlights["Content"].length > 1 ? '' : 'none'), fontWeight: 'bold', color: palette.themePrimary }} onClick={() => setMoreAction(item)}>
                                    {item.More === undefined || !item.More ? t('analysisResults:searchAndCompareResult.more') : t('analysisResults:searchAndCompareResult.less')}
                                </div>
                            </div>
                        </div>
                    </>
                );
            }
        }
    ];

    const getAnalysisSection = () => {
        if (searchAndCompareResult && searchAndCompareResult.length > 0) {
            return (
                <DetailsList
                    items={searchAndCompareResult}
                    columns={detailColumns}
                    selectionMode={SelectionMode.none}
                    isHeaderVisible={false}
                    constrainMode={ConstrainMode.unconstrained}
                    styles={classNames.subComponentStyles.detailsList}
                    onRenderRow={props => props ? <DetailsRow {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
                />
            )
        }
        else {
            return (<div style={{ marginLeft: '25px' }}>{emptyMessage}</div>)
        }
    }
    const searchAndCompareBody = () => {
        return (
            <>
                {loading ? <Spinner style={{ position: 'absolute', left: '50%', top: 'calc(50% - 18px)' }} size={SpinnerSize.large} /> :
                    renderDocumentAnalysis()}
            </>
        )
    }

    return (
        <>
            <Results
                content={searchAndCompareBody()}
                isLoading={loading}
                changeDocument={(document) => console.log(document)}
                iconName={"UpgradeAnalysis"}
                sectionTitle={analysisInfo?.AnalysisName ? `${t('analysisResults:searchAndCompareResult.title')} - ${analysisInfo?.AnalysisName}` : ''}
            />
        </>
    );
}