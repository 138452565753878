import { IStyleFunction } from "@fluentui/react"
import { IWorldMapChartPropsStyles, IWorldMapChartStyles } from "./worldMapChart.types"

export const WorldMapChartGlobalClassNames = {
    root: 'edi-world-map-chart-root'
}

export const getStyles: IStyleFunction<IWorldMapChartPropsStyles, IWorldMapChartStyles> = (props: IWorldMapChartPropsStyles): IWorldMapChartStyles => {
    const { palette } = props.theme!;//eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            WorldMapChartGlobalClassNames.root,
            {
                position: 'relative',
                width: '100%'
            }
        ],
        composableMap: {
            height: 490,
            width: '100%',
            cursor: 'grab',
            selectors: {
                ':active': {
                    cursor: 'grabbing'
                }
            }
        },
        controls: {
            position: "absolute",
            top: -47,
            right: 0
        },
        subComponentStyles: {
            iconButton: {
                root: {
                    color: palette.black,
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`,
                    color: palette.themePrimary
                },
                rootExpanded: {
                    backgroundColor: 'transparent',
                    color: palette.themePrimary,
                    textShadow: 'rgb(37 36 35) 0px 0px 1px'
                },
                rootPressed: {
                    backgroundColor: 'transparent',

                },
                rootHovered: {
                    backgroundColor: 'transparent',
                },
                rootDisabled: {
                    backgroundColor: 'transparent'
                }
            }
        }
    };
}