import { styled } from "@fluentui/react";
import { CreateProfileModalBase } from "./createProfileModal.base";
import { getStyles } from "./createProfileModal.style";
import { ICreateProfileModalBasePropsStyles, ICreateProfileModalBaseStyles, ICreateProfileFolderModalProps } from "./createProfileModal.types";

const CreateProfileModal = styled<ICreateProfileFolderModalProps, ICreateProfileModalBasePropsStyles, ICreateProfileModalBaseStyles>(
    CreateProfileModalBase, 
    getStyles,
    undefined,
    { scope: 'CreateProfileModal'}
)

export default CreateProfileModal;