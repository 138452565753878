import { IStyleFunction } from "@fluentui/react"
import { IMembersModalPropsStyles, IMembersModalStyles } from "./membersModal.types"

export const MembersModalGlobalClassNames = {
    root: 'edi-update-membership-modal-root'
}

export const getStyles: IStyleFunction<IMembersModalPropsStyles, IMembersModalStyles> = (props: IMembersModalPropsStyles): IMembersModalStyles => {
    return {
        root: [
            MembersModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}