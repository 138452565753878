import React from "react";
import { classNamesFunction, FocusZone, Icon } from "@fluentui/react";
import { IPaginationPropsStyles, IPaginationStyles, IPaginationProps, IPagination } from "./pagination.types";
import ReactPaginate from 'react-paginate';

const getClassNames = classNamesFunction<IPaginationPropsStyles, IPaginationStyles>();

export const PaginationBase = (props: IPaginationProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    const handlePageClick = ({ selected: selectedPage }: IPagination) => {
        props.onChangePage(selectedPage + 1);
    }

    return (
        <>
            <FocusZone className={classNames.root}>
                <ReactPaginate
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    previousLabel={<Icon className={classNames.iconTemp} iconName="ChevronLeft" />}
                    nextLabel={<Icon className={classNames.iconTemp} iconName="ChevronRight" />}
                    pageCount={props.pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={classNames.root}
                    pageClassName={classNames.temp}
                    activeClassName={classNames.active}
                    forcePage={props.currentPage - 1}
                />
            </FocusZone>

        </>
    );


}