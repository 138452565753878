import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDocLabSelector } from '../docLabStore';
import { Call } from '../models/callsApi';


export type NotificationStatus = {
    notification: Call;
    progress: boolean;
    operation: string;
    success?: boolean;
    message?: string;
}

export type CallNotificationState = {
    showPanel: boolean;
    notificationsQueue: string[];
    status: Record<string, NotificationStatus>;
    showCallout: boolean;
    calloutMessage: string;
}

const initialState: CallNotificationState = {
    notificationsQueue: [],
    status: {},
    showPanel: false,
    showCallout: false,
    calloutMessage:''
}


const callNotificationSlice = createSlice({
    name: 'docLab-call-notification',
    initialState,
    reducers: {
        insertCall: (state: CallNotificationState, action: PayloadAction<{ requestId: string, notification: Call }>) => {
            state.notificationsQueue.push(action.payload.requestId);
            state.status[action.payload.requestId] = {
                notification: action.payload.notification,
                success: undefined,
                operation: action.payload.notification.nameOperation,
                progress: true,
                message: action.payload.notification.nameOperation
            }
            state.showCallout= false;
            state.calloutMessage = '';

        },
        setCall: (state: CallNotificationState, action: PayloadAction<{ requestId: string, success: boolean, message: string }>) => {
            state.status[action.payload.requestId] = {
                ...state.status[action.payload.requestId], progress: false,
                message: action.payload.message, success: action.payload.success
            }
            if(!action.payload.success)
            {
                state.showCallout= true;
                state.calloutMessage = action.payload.message;
            }
        },
        removeCall: (state: CallNotificationState, action: PayloadAction<string>) => {
            const notId = state.notificationsQueue.indexOf(action.payload);
            if (notId > -1) {
                state.notificationsQueue.splice(notId, 1);
            }
            delete state.status[action.payload];
            state.showCallout = false;
            state.calloutMessage = '';
        },
        showPanelAction: (state: CallNotificationState, action: PayloadAction<boolean>) => {
            state.showPanel = action.payload;
        },
        shownCallout: (state: CallNotificationState, action: PayloadAction<boolean>) => {
            state.showCallout = action.payload;
        },
    }
})

export const { insertCall, setCall, removeCall, showPanelAction, shownCallout } = callNotificationSlice.actions;
export default callNotificationSlice.reducer;

export const useCallNotification = () => useDocLabSelector(state => state.callNotification);