import { styled } from "@fluentui/react";
import { getStyles } from "./feedback.styles";
import { IFeedbackPropsStyles, IFeedbackStyles, IFeedbackProps } from "./feedback.types";
import { FeedbackBase } from "./feedback.base";

const Feedback = styled<IFeedbackProps, IFeedbackPropsStyles, IFeedbackStyles>(
    FeedbackBase,
    getStyles,
    undefined,
    { scope: 'Feedback' }
);

export default Feedback;