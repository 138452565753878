import { styled } from "@fluentui/react";
import { getStyles } from "./calloutNotification.styles";
import { ICalloutNotificationPropsStyles, ICalloutNotificationStyles, ICalloutNotificationProps } from "./calloutNotification.types";
import { CalloutNotificationBase } from "./calloutNotification.base";

const CalloutNotification = styled<ICalloutNotificationProps, ICalloutNotificationPropsStyles, ICalloutNotificationStyles>(
    CalloutNotificationBase,
    getStyles,
    undefined,
    { scope: 'CalloutNotification' }
);

export default CalloutNotification;