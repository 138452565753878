import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useDocLabSelector } from '../docLabStore';
import { ArchiveRoleId } from '../models/constants';
import { archivesApi } from '../services/archives/archives.api';
import { ProfileMember } from '../services/archives/archives.contracts';

export type ProfileContentState = {
    profiles: ProfileMember[];
    profileViewIsLoading: boolean;
    avaiableRoles: ArchiveRoleId[];
    avaiableRolesIsLoading: boolean;
    changingId: number | undefined;
}

const initialState: ProfileContentState = {
    profileViewIsLoading: false,
    profiles: [],
    avaiableRolesIsLoading: false,
    avaiableRoles: [],
    changingId: undefined
}

export const getProfiles = createAsyncThunk('profiles/get-profiles', async (request :{archiveId: number, filter?: string}) => {
    return await archivesApi.getProfiles(request.archiveId, request.filter);
})

export const changeRole = createAsyncThunk('profiles/change-role', async (request :{profileId: number, newRoleId: ArchiveRoleId}) => {
    const payload = {
        newRoleId: request.newRoleId
    }
    return await archivesApi.changeProfileRole(request.profileId, payload);
})

export const modifyProfile = createAsyncThunk('profiles/modify-profile', async (request :{profileId: number, name: string, description?: string}) => {
    const payload = {
        name: request.name, 
        description: request.description
    }
    return await archivesApi.changeProfileRole(request.profileId, payload);
})

export const deleteProfile = createAsyncThunk('profiles/delete-profile', async (profileId: number) => {
    return await archivesApi.deleteProfile(profileId);
})

export const updateMemberProfile = createAsyncThunk('profiles/update-member-profile', async (request : {profileId: number, userIds: string[], archiveId: number}) => {
    await archivesApi.updateProfileMember(request.profileId, request.userIds);
    return await archivesApi.getProfiles(request.archiveId);
})

const profileContentSlice = createSlice({   
    name: 'profile-content',
    initialState,
    reducers: {
        cleanProfiles: (state: ProfileContentState) => {
            state.profiles = [];
        },
        // updateRoleId: (state: ProfileContentState, action: PayloadAction<{profileId: number, newRole: ArchiveRoleId}>) => {
        //     const index = state.profiles.findIndex(p => p.id === action.payload.profileId)
        //     const updateItem = state.profiles[index];
        //     updateItem.archiveRoleId = action.payload.newRole;
        //     state.profiles[index] = updateItem;
        // },
    },
    extraReducers: builder => {
        builder.addCase(getProfiles.pending, (state) => {
            state.profileViewIsLoading = true;
            state.profiles = [];
        }); 

        builder.addCase(getProfiles.fulfilled, (state, action) => {   
            state.profiles = action.payload;
            state.profileViewIsLoading = false;
        }); 

        builder.addCase(getProfiles.rejected, (state) => {    
            state.profileViewIsLoading = false;
        });

        builder.addCase(updateMemberProfile.pending, (state) => {
            state.profileViewIsLoading = true;
        }); 

        builder.addCase(updateMemberProfile.fulfilled, (state, action) => {   
            state.profiles = action.payload;
            state.profileViewIsLoading = false;
        }); 

        builder.addCase(updateMemberProfile.rejected, (state) => {    
            state.profileViewIsLoading = false;
        });

        builder.addCase(changeRole.pending, (state, action) => {   
            state.avaiableRolesIsLoading = true;
            state.changingId = action.meta.arg.profileId;
        });

        builder.addCase(changeRole.fulfilled, (state, action) => {   
            const index = state.profiles.findIndex(p => p.id === action.meta.arg.profileId)
            const updateItem = state.profiles[index];
            updateItem.archiveRoleId = action.meta.arg.newRoleId;
            state.avaiableRolesIsLoading = false;
            state.changingId = undefined;
            state.profiles[index] = updateItem;
        });

        builder.addCase(deleteProfile.pending, (state, action) => {
            state.profileViewIsLoading = true;
        });

        builder.addCase(deleteProfile.fulfilled, (state, action) => {
            state.profiles = action.payload;
            state.profileViewIsLoading = false;
        });
        
        builder.addCase(deleteProfile.rejected, (state, action) => {
            state.profileViewIsLoading = false;
        });

        builder.addCase(modifyProfile.pending, (state) => {
            state.profileViewIsLoading = true;
        }); 

        builder.addCase(modifyProfile.fulfilled, (state, action) => {   
            state.profiles = action.payload;
            state.profileViewIsLoading = false;
        }); 

        builder.addCase(modifyProfile.rejected, (state) => {    
            state.profileViewIsLoading = false;
        });
    }       
});

export const { cleanProfiles } = profileContentSlice.actions;
export default profileContentSlice.reducer;

export const useProfileContent = () => useDocLabSelector(state => state.profileContent);