import { generatePath } from "react-router-dom";
import { AppRoutes, AppTab } from "../../app/utilities/routes";

export enum KnowledgeTab {
    search = 'search',
    favorites = 'favorites',
    documents = 'documents',
}

export enum KnowledgeSettingsTab {
    users = 'users',
    profileManagement = 'profile-management'
}

class KnowledgeLabRoutesClass {
    public knowledgeLabBase = AppRoutes.generateRouteForTab(AppTab.knowledgeLab);
    public knowledgeLabs = `${this.knowledgeLabBase}/lab`;
    public knowledgeLab = `${this.knowledgeLabs}/:knowledgelabId(\\d+)`;
    public knowledgeLabOptionals = `${this.knowledgeLabBase}/:lab?/:knowledgelabId(\\d+)?/:tab(${KnowledgeTab.search}|${KnowledgeTab.favorites}|${KnowledgeTab.documents})?`;

    generateKnowledgeLabPath(labId: number) {
        return generatePath(`${this.knowledgeLabs}/:knowledgeLabId`, { knowledgeLabId: labId });
    }

    generateKnowledgeLabTabPath(labId: number, tab: KnowledgeTab) {
        return `${this.generateKnowledgeLabPath(labId)}/${tab}`;
    }

    generateKnowledgeLabSettingsTabPath(knowledgeLabId: number, tab: KnowledgeSettingsTab) {
        return `${this.generateKnowledgeLabPath(knowledgeLabId)}/settings/${tab}`;
    }
}

export const KnowledgeLabRoutes = new KnowledgeLabRoutesClass();