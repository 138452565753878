import { DefaultButton, IIconProps, ScrollablePane, Stack } from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useActivityLabDispatch } from "../../../../activityLabStore";
import { loadActivities } from "../../../../features/activityList";
import { useCurrentPortfolio } from "../../../../hooks/useCurrentPortfolio";
import { ActivityStatus, PortfolioRoleId } from "../../../../models/constants";
import CreateActivity from "../../createActivity/createActivity";
import ActivityBoard from "../activityBoard/activityBoard";
import { IActivitiesBoardsProps } from "./activitiesBoards.types";

// const getClassNames = classNamesFunction<IActivitiesBoardsPropsStyles, IActivitiesBoardsStyles>();

export const ActivitiesBoardsBase = (props: IActivitiesBoardsProps) => {
    // const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [planActivity, setPlanActivity] = useState<boolean>(false);
    const [activityToAdd, setActivityToAdd] = useState<string>();
    const currentPortfolio = useCurrentPortfolio();
    const addIcon: IIconProps = { iconName: 'Add', style: { height: 'auto' } };
    const { t } = useTranslation(['common']);
    const dispatch = useActivityLabDispatch();

    return (
        <><div style={{
            height: '100%',
            position: 'relative'
        }}>
            <ScrollablePane>
                {currentPortfolio?.portfolioRoleId === PortfolioRoleId.Owner ?
                    <div style={{ marginLeft: '30px', marginTop: '30px' }}>
                        <DefaultButton
                            text={t("planActivity")}
                            iconProps={addIcon}
                            onClick={() => setPlanActivity(true)}
                        />
                    </div>
                    : ''
                }
                <Stack horizontal style={{ marginLeft: '20px' }}>
                    <ActivityBoard activityToAdd={activityToAdd} status={ActivityStatus.Planned} pageSize={10}/>
                    <ActivityBoard status={ActivityStatus.OnGoing} pageSize={10} />
                    <ActivityBoard status={ActivityStatus.Blocked} pageSize={10} />
                    <ActivityBoard status={ActivityStatus.Closed} pageSize={10} />
                </Stack>
            </ScrollablePane>
        </div>
            {
                planActivity && <CreateActivity
                    portfolioId={currentPortfolio?.id ? currentPortfolio?.id : 0}
                    onCreated={(name: string) => { 
                        setActivityToAdd(name);
                        dispatch(loadActivities({
                            activityStatus: ActivityStatus.Planned,
                            forceRefresh: true,
                            portfolioId: currentPortfolio?.id ? currentPortfolio?.id : 0,
                            pageSize: 10
                        }));
                    }}
                    onClose={() => {
                        setPlanActivity(false);
                    }}
                />
            }
        </>
    );
}
