/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable sonarjs/no-duplicate-string */
import React, {} from "react";
import { classNamesFunction, Spinner, SpinnerSize } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { ISecondPageProps, ISecondPagePropsStyles, ISecondPageStyles } from "./secondPage.types";
import { useOnMount } from "../../../../../../../utilities/hooks";
import { archivesApi } from "../../../../../../services/archives/archives.api";

const getClassNames = classNamesFunction<ISecondPagePropsStyles, ISecondPageStyles>();

export const SecondPageBase = (props: ISecondPageProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    const { t } = useTranslation(['documentTranslation','common']);

    useOnMount(() => {
        translateDocument();
    });

    const translateDocument = async () => {
        let success = true;
        try{
            const response = await archivesApi.translateDocument(props.fileId, props.selectedLanguage);

            const data = new Blob([response], { type: 'application/octet-stream' });
            const objectUrl: string = window.URL.createObjectURL(data);
            const tempLink = document.createElement('a');
            tempLink.href = objectUrl;
            tempLink.setAttribute('download', props.selectedLanguage + "_" + props.fileName);
            document.body.appendChild(tempLink);
            tempLink.click();
        }catch{
            success = false;
            props.onComplete(false);
        }finally{
            if(success)
                props.onComplete(true);
        }
    }

    return (
        <div className={classNames.spinnerBody}>
            <div className={classNames.spinnerMessage}>
                <span>{t('sentenceOne')}</span>
                <span>{t('sentenceTwo')}</span>
                <span>{t('sentenceThree')}</span>
            </div>
            <Spinner size={SpinnerSize.large}></Spinner>
        </div>
    );
}