import { IStyleFunction, ITextFieldStyleProps, ITextFieldStyles, keyframes } from "@fluentui/react"
import { ICreateStandardModalPropsStyles, ICreateStandardModalStyles } from "./createStandardModal.types"

export const DocAnalysisCreateStandardGlobalClassNames = {
    root: 'edi-create-standard-modal-root'
}

export const getStyles: IStyleFunction<ICreateStandardModalPropsStyles, ICreateStandardModalStyles> = (props: ICreateStandardModalPropsStyles): ICreateStandardModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    
    return {
        root: [
            DocAnalysisCreateStandardGlobalClassNames.root,
            {
            }
        ],
        subComponentStyles: {
            name: (props: ITextFieldStyleProps & { icon: string }): ITextFieldStyles => {
                const { theme, icon } = props;

                return {
                    root: {},
                    fieldGroup: {
                        selectors:{
                            '::after':{
                                inset: '0px !important'
                            }
                        }
                    },
                    prefix: {},
                    field: {},
                    icon: {},
                    description: {},
                    wrapper: {},
                    revealButton: {},
                    revealIcon: {},
                    revealSpan: {},
                    errorMessage: {
                        position: 'absolute'
                    },
                    suffix: [
                        { background: 'transparent', },
                        icon === 'Error' && { color: theme.palette.redDark },
                        icon === 'Completed' && { color: theme.palette.greenDark },
                        icon === 'ProgressRingDots' && {
                            '> i': {
                                animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite`
                            }
                        }
                    ],
                    subComponentStyles: {
                        label: {}
                    }
                }
            }
        },
        requiredTextLeft: [{
            display:'flex', 
            alignItems:'center',
            gap: 5,
            justifyContent: "flex-start",
            bottom: 0,
            left:40,
            color: palette.redDark,
            background: palette.white,
            width: '30%'
        }]
    };
}