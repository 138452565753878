/*eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from "react";
import { classNamesFunction, MessageBar, MessageBarType, PrimaryButton, Stack, TooltipHost } from "@fluentui/react";
import { IRowReportBaseProps, IRowReportBasePropsStyles, IRowReportBaseStyles } from "./RowReport.types"
import { ExportStatus, ReportType } from "../../../../models/archive";
import FileIconCell from "../../../../../common/components/fileIconCell/fileIconCell";
import { useOnMount } from "../../../../../utilities/hooks";
import { useTranslation } from "react-i18next";

const  getClassNames = classNamesFunction<IRowReportBasePropsStyles, IRowReportBaseStyles>();

export const RowReportBase = (props: IRowReportBaseProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [renderDownload, setRenderDownload] = useState<boolean>();
    const { t } = useTranslation([props.translationName, 'common']);

    useOnMount(() => {
        setRenderDownload(
            props.item?.status === ExportStatus.completed || props.item?.status === ExportStatus.completedWithError ||
            (props.item?.previousCreatedOn !== null && (props.item?.status === ExportStatus.onGoing || props.item?.status === ExportStatus.inError))
        );       
    })

    const convertStatus = () : MessageBarType => {
        switch(props.item?.status){
            case ExportStatus.completed:
                return MessageBarType.success;
            case ExportStatus.completedWithError:
                return MessageBarType.warning;
            case ExportStatus.inError:
                return MessageBarType.error;
            case ExportStatus.onGoing:
                return MessageBarType.severeWarning;
            default:
                return MessageBarType.error;
        }
    }

    const getLabelStatus = ()  => {
        switch(props.item?.status){
            case ExportStatus.completed:
                return t('common:statusLabel.completed');
            case ExportStatus.completedWithError:
                return t('common:statusLabel.completedWithError');
            case ExportStatus.inError:
                return t('common:statusLabel.error');
            case ExportStatus.onGoing:
                return t('common:statusLabel.ongoing');
            default:
                return '';
        }
    }

    const getClassForStatus = () => {
        switch(props.item?.status){
            case ExportStatus.completed:
                return classNames.statusCompleted;
            case ExportStatus.completedWithError:
                return classNames.statusCompletedWithError;
            case ExportStatus.inError:
                return classNames.statusError;
            case ExportStatus.onGoing:
                return classNames.statusOnGoing;
            default:
                return '';
        }
    }

    const getButtonLabel = () => {
        switch(props.item?.type){
            case ReportType.Metadata:
                return t('generateReportAction');
            case ReportType.ACL:
                return t('generateReportAction');
            case ReportType.Keyphrases:
                return t('generateReportAction');
            case ReportType.ManagementReport:
                return t('generateReportAction');
            case ReportType.FilesMetadataChanges:
                return t('generateReportAction');
            case ReportType.ENITER:
                return t('generateReportAction');
            case ReportType.Import:
                return t('generateReportActionImport');
            case ReportType.Export:
                return t('generateReportActionExport');
            case ReportType.ImportACPV:
                return t('generateReportActionImport');
            case ReportType.ImportDocsTree:
                return t('generateReportActionImport');
            default:
                return '';
        }
    }

    return (
        <>
            <Stack>
                <div className={classNames.rowContainer}>
                    <div className={classNames.titleDescriptionContainer}>
                        <div className={classNames.rowTitle}>
                            {props.item?.title}
                        </div>
                        <div className={classNames.rowDescription}>
                            <TooltipHost content={props.item?.description}>
                                <span>{props.item?.description}</span>
                            </TooltipHost>
                        </div>
                    </div>
                    <div className={classNames.rowSubLevelContainer}>
                        {!(!renderDownload || !(props.item?.status !== ExportStatus.onGoing || props.item.previousCreatedOn)) && !props.noReport && props.item?.status !== ExportStatus.inError ? 
                            <>
                                <div className={(!renderDownload || !(props.item?.status !== ExportStatus.onGoing || props.item.previousCreatedOn))  ? classNames.invisibleItem : 'row-date'} style={{minWidth: '300px', maxWidth: '500px'}}>
                                    <div className={`linkfile`} style={{display: 'flex', alignItems: 'center', width: '100%'}} onClick={props.onFileClick}>                            
                                        <FileIconCell fileExtension={props.fileExtension}/>
                                        <div style={{textOverflow: 'ellipsis', width:'100%', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                            <TooltipHost content={t('common:commands.download')}><span className={classNames.tooltipStyle}>{props.item?.fileName}</span></TooltipHost>
                                        </div>
                                        
                                    </div>
                                    <div style={{ fontSize: '12px', minWidth: '300px', maxWidth: '350px'}}>
                                        {props.item?.createdOn && t('date') + props.item.createdOn}
                                    </div>
                                    {props.item?.status === ExportStatus.completedWithError &&
                                        <div className={classNames.containerErrorMessage}>
                                            {t('completedWithErrorMessage')}
                                        </div>
                                    }
                                </div>
                            </> :
                            <>
                                {(props.item?.type === ReportType.ImportACPV || props.item?.type === ReportType.ImportDocsTree) 
                                && props.item?.status === ExportStatus.inError ?
                                    <div style={{fontSize: '13px', fontStyle: 'italic'}}>{props.item?.errorMessage}</div> :
                                    <div style={{fontSize: '13px', fontStyle: 'italic'}}>{t('noReport')}</div>
                                }
                            </>
                        }
                        <div style={{minWidth: '300px', display: 'flex', justifyContent: 'space-between'}}>
                            <div className={!renderDownload ? classNames.invisibleItem : 'status'} >
                                <MessageBar className={getClassForStatus()}  messageBarType={convertStatus()} styles={{root: {
                                    background: 'none'
                                }}}>
                                    {getLabelStatus()}
                                </MessageBar>
                            </div>
                            <div>
                                <PrimaryButton
                                    text={getButtonLabel()}
                                    onClick={props.onStartNewReport}
                                    disabled={props.item?.status === ExportStatus.onGoing}
                                    styles={{
                                        icon: {
                                            fontSize: '20px',
                                            height: '30px',
                                            lineHeight: '30px'    
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </Stack>
        </>
    )
}