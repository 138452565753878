import { IStyleFunction } from "@fluentui/react"
import { IActivityExpiredPropsStyles, IActivityExpiredStyles } from "./activityExpired.types"

export const ActivityExpiredGlobalClassNames = {
    root: 'edi-activity-expired-root'
}

export const getStyles: IStyleFunction<IActivityExpiredPropsStyles, IActivityExpiredStyles> = (props: IActivityExpiredPropsStyles): IActivityExpiredStyles => {
    return {
        root: [
            ActivityExpiredGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}