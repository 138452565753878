import { classNamesFunction, DefaultButton, Icon, PrimaryButton, TextField } from "@fluentui/react";
import { nanoid } from "@reduxjs/toolkit";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../../../common/components/ediModal/ediModal";
import { Helpers } from "../../../../../../utilities/helpers";
import { useDocLabDispatch } from "../../../../../docLabStore";
import { insertCall, setCall } from "../../../../../features/callNotification";
import { useCurrentArchive } from "../../../../../hooks/useCurrentArchive";
import { Call, callsList } from "../../../../../models/callsApi";
import { Constants, Labels } from "../../../../../models/constants";
import { StandardLabel } from "../../../../../models/docAnalysis";
import { docAnalyzesApi } from "../../../../../services/docAnalyzes/docAnalyzes.api";
import { ICreateStandardModalProps, ICreateStandardModalPropsStyles, ICreateStandardModalStyles } from "./createStandardModal.types";
import LabelRange from "./subComponents/labelRange/labelRange";

export type CreateStandardData = {
    name?: string;
    isValid: boolean;
}

const minDelta = 0.01;
const maxDelta = Constants.MAX_INTEGER;
const getClassNames = classNamesFunction<ICreateStandardModalPropsStyles, ICreateStandardModalStyles>();

export const CreateStandardModalBase = (props: ICreateStandardModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['docAnalysis', 'common']);
    const archive = useCurrentArchive();
    const dispatch = useDocLabDispatch();
    const defaultLabels = [
        { labelId: Labels.Negligible, valueFrom: 0, valueTo: minDelta, enabled: true },
        { labelId: Labels.Small, valueFrom: minDelta, valueTo: minDelta * 2, enabled: true },
        { labelId: Labels.Medium, valueFrom: minDelta * 2, valueTo: minDelta * 3, enabled: true },
        { labelId: Labels.Large, valueFrom: minDelta * 3, enabled: true }
    ];
    const [validationIcon, setValidationIcon] = useState('Error');
    const [data, setData] = useState<CreateStandardData>();
    const [labels, setLabels] = useState<StandardLabel[]>(defaultLabels);

    const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
        setData({ ...data, isValid: !error, name: value ?? "" });
        setValidationIcon(error ? 'Error' : 'Completed');
    }, [data]);

    const updateValue = (label: Labels, newValue: number) => {
        const newLabels = [...labels];
        recursiveUpdateValue(newLabels, label, newValue);
        setLabels(newLabels);
        setData({ ...data, isValid: newLabels.every(l => l.valueFrom <= maxDelta || (l.valueTo && l.valueTo <= maxDelta)) });
    }
    const recursiveUpdateValue = (labels: StandardLabel[], label: Labels, newValue: number) => {
        const currentLabel = labels[label];
        currentLabel.valueTo = newValue;

        const next = getNextEnabledLabel(labels, label);
        if (next) {
            next.valueFrom = newValue;

            if (next.valueTo && next.valueTo < newValue + minDelta)
                recursiveUpdateValue(labels, next.labelId, +(newValue + minDelta).toFixed(2));
        }
    }

    const updateStatus = (label: Labels, enabled: boolean | undefined) => {
        const newLabels = [...labels];
        const currentLabel = newLabels[label];

        currentLabel.enabled = enabled ?? false;

        const next = getNextEnabledLabel(newLabels, label);
        const prev = getPrevEnabledLabel(newLabels, label);

        if (!enabled) {
            if (next)
                next.valueFrom = currentLabel.valueFrom;
            else if (prev)
                prev.valueTo = undefined;
        }
        else {
            if (prev)
                prev.valueTo = currentLabel.valueFrom;

            if (next)
                next.valueFrom = currentLabel.valueTo ?? 0;
        }

        setLabels(newLabels);
    }

    const getNextEnabledLabel = (labels: StandardLabel[], label: Labels) => {
        return labels.slice(label + 1).find(l => l.enabled);
    }

    const getPrevEnabledLabel = (labels: StandardLabel[], label: Labels) => {
        return labels.slice(0, label).reverse().find(l => l.enabled);
    }

    const getLabelRange = (label: Labels) => {
        const l = labels[label]
        if (!l)
            return null;
        return (
            <LabelRange
                label={label}
                from={l.valueFrom}
                to={l.valueTo}
                enabled={l.enabled}
                setLabelFrom={updateValue}
                updateStatus={updateStatus}
                disableToggle={labels.filter(x => !x.enabled).length >= 2}
                disableToInterval={!labels.slice(label + 1).some(l => l.enabled)}
                minDelta={minDelta}
                maxDelta={maxDelta}
            />
        )
    }

    const createStandard = async () => {
        if (archive) {
            const createStandardCall: Call = {
                type: callsList.createStandard,
                nameOperation: t('operations.addOperation.name'),
                errors: [
                    { code: 403, message: t("operations.addOperation.errors.errorCode403") },
                    { code: 404, message: t("operations.addOperation.errors.errorCode404") },
                    { code: 409, message: t("operations.addOperation.errors.errorCode409") },
                    { code: 500, message: t('common:genericErrorApi') }
                ]
            };

            props.onClose(false);

            const id = nanoid();
            const payload = { requestId: id, notification: createStandardCall }
            dispatch(insertCall(payload));

            try {
                await docAnalyzesApi.createStandard({
                    archiveId: archive.id,
                    name: data?.name ?? "",
                    description: 'description',
                    data: {
                        length: labels.filter(label => label.enabled)
                    }
                });
                const successPayload = { requestId: id, success: true, message: t('operations.addOperation.successMessage') }
                dispatch(setCall(successPayload));
                props.onClose(true);
            }
            catch (error) {
                let errorMessage = '';
                switch (error.code) {
                    case 403: errorMessage = t("operations.addOperation.errors.errorCode403"); break;
                    case 404: errorMessage = t("operations.addOperation.errors.errorCode404"); break;
                    case 409: errorMessage = t("operations.addOperation.errors.errorCode409"); break;
                    default: errorMessage = t("common:genericErrorApi"); break;
                }
                const failurePayload = { requestId: id, success: false, message: errorMessage }
                dispatch(setCall(failurePayload));
            }
        }
    }

    const validateStandardName = useCallback(async (value: string) => {
        if (!value && archive)
            return ''; //return `${t('common:fieldRequired')}`;

        setValidationIcon('ProgressRingDots');

        try {
            const [isValid] = await Promise.all([docAnalyzesApi.isConfigurationNameAvailable(archive?.id || 0, value), Helpers.delay(500)]);
            return isValid ? "" : t('operations.addOperation.errors.nameNotAvailable');
        }
        catch {
            return t('common:genericErrorCheckName');
        }
    }, [t, archive]);

    const footer = (
        <>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' ,textAlign: 'left', width: '100%' }}>
            <span className={classNames.requiredTextLeft}>* {t('common:fieldRequired')}</span>
            <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center', alignContent: 'center', width: '70%' }}>
                <DefaultButton
                    text={t("common:cancel")}
                    style={{ marginRight: '8px' }}
                    onClick={() => props.onClose(false)}
                />
                <PrimaryButton
                    text={t("common:save")}
                    onClick={() => { createStandard(); props.onClose(true); }}
                    disabled={(!data?.isValid || false) || labels.some(l => (l.valueTo !== undefined && l.valueTo <= l.valueFrom) && l.enabled)}
                />
            </div>
        </div>
        </>
    );

    return (
        <EdiModal
            width={800}
            height={500}
            isOpen={props.isOpen}
            showCloseIcon
            title={t('addStandard')}
            onCloseClick={() => props.onClose(false)}
            body={
                <>
                    <div style={{ marginBottom: 40 }}>
                        <TextField
                            label={t('common:name')}
                            maxLength={Constants.standardItemNameMaxLength}
                            placeholder={t('namePlaceholder')}
                            deferredValidationTime={250}
                            required
                            autoFocus
                            autoComplete={"off"}
                            onGetErrorMessage={validateStandardName}
                            onNotifyValidationResult={onNameValidationResult}
                            onChange={() => setData({ ...data, isValid: false })}
                            onRenderSuffix={() => <Icon iconName={validationIcon} />}
                            styles={classNames.subComponentStyles.name({ theme: props.theme!, icon: validationIcon })} //eslint-disable-line @typescript-eslint/no-non-null-assertion
                        />
                    </div>
                    {getLabelRange(Labels.Negligible)}
                    {getLabelRange(Labels.Small)}
                    {getLabelRange(Labels.Medium)}
                    {getLabelRange(Labels.Large)}
                </>
            }
            footer={footer}
        />
    );
}