import { IStyleFunction } from "@fluentui/react"
import { IDocumentTagPanelPropsStyles, IDocumentTagPanelStyles } from "./documentTagPanel.types"

export const DocumentTagPanelGlobalClassNames = {
    root: 'edi-document-tag-panel-root',
    pivot: 'edi-document-tag-panel-pivot'
}

export const getStyles: IStyleFunction<IDocumentTagPanelPropsStyles, IDocumentTagPanelStyles> = (props: IDocumentTagPanelPropsStyles): IDocumentTagPanelStyles => {
    return {
        root: [
            DocumentTagPanelGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
                width: "690px"
            }
        ],
        pivot: [
            DocumentTagPanelGlobalClassNames.pivot,
            {
                width: '100%',
                height: '100%'
                // CSS PROPS AND SELECTORS
            }
        ],
        loadingErrorContainer: {
            height: 'calc(100%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
    };
}