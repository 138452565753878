/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { classNamesFunction, ConstrainMode, Selection, IColumn, Persona, PersonaSize, SelectionMode, ShimmeredDetailsList, TooltipHost, PrimaryButton, IContextualMenuProps, DirectionalHint, IconButton, IContextualMenuStyles, IContextualMenuStyleProps, IContextualMenuItemStyleProps, IContextualMenuItemStyles } from "@fluentui/react";
import { IFeedbackPropsStyles, IFeedbackStyles, IFeedbackProps } from "./feedback.types";
import { dashboardApi } from "../../services/dashboard.api";
import { dashboardStatusText, Feedback, FeedbackType } from "../../models/feedback";
import { useDashboardDispatch } from "../../dashboardStore";
import { setBaseFeedbackFilters, setFilters, setNoMore, setRefresh, setSelectedItems, useFeedbackList } from "../../features/feedbackList";
import { Helpers } from "../../../utilities/helpers";
import { useTranslation } from "react-i18next";
import TeamsImage from "../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../common/components/teamsImage/teamsImage.types";
import { useOnMount } from "../../../utilities/hooks";
import { filePreviewSubPath, DashboardTextType } from "../../../utilities/constants";
import FeedbackModal from "./feedbackModal/feedbackModal";
import FeedbackNerPanel from "./feedbackNerPanel/feedbackNerPanel";

const getClassNames = classNamesFunction<IFeedbackPropsStyles, IFeedbackStyles>();

export const FeedbackBase = (props: IFeedbackProps) => {
    const { t } = useTranslation(['dashboardFeedback', 'dashboardToolbar', 'common']);
    const dispatch = useDashboardDispatch();

    const [feedbackId, setFeedbackId] = useState<number | undefined>();
    const [feedbackList, setFeedbackList] = useState<Feedback[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isNerOpen, setIsNerOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [modalContentType, setModalContentType] = useState<DashboardTextType>();
    const [mounted, setMounted] = useState<boolean>(false);
    const [scrollLoading, setScrollLoading] = useState<boolean>(false);

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, isEmptyList: (feedbackList.length === 0 && !loading) });

    const { filters, noMore, refresh, selectedItems } = useFeedbackList();

    const [error, setError] = useState(false);

    useOnMount(() => {
        registerEvent();
        if(!filters.datasetId)
            dispatch(setBaseFeedbackFilters());
        setMounted(true);
    });

    const selection = useMemo(() => new Selection({
        onSelectionChanged: () => {
            const selectionDetails = selection.getSelection();
            dispatch(setSelectedItems(selectionDetails as Feedback[]));
        }
    }), [dispatch]);

    useEffect(() => {
        if (mounted) {
            if (filters.pageNumber === 0) {
                setLoading(true);
                dashboardApi.getFeedbackList(filters)
                    .then((result) => {
                        setFeedbackList(result);
                    }).catch((err) => {
                        setError(true);
                    })
                    .finally(() => {
                        setLoading(false)
                        setScrollLoading(false)
                    })
            } else {
                dashboardApi.getFeedbackList(filters)
                    .then((result) => {
                        const actualList = [...feedbackList];
                        const newList = actualList.concat(result)

                        if (result.length < (filters?.pageSize ?? 20))
                            dispatch(setNoMore(true));

                        setFeedbackList(newList);
                    }).catch((err) => {
                        setError(true);
                    })
                    .finally(() => {
                        setLoading(false)
                        setScrollLoading(false)
                    })
            }

        }

    }, [filters, mounted]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (refresh) {
            dispatch(setNoMore(false));
            refreshData();
        }

    }, [refresh]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedItems.length === 0) {
            selection.setAllSelected(false);
        }
    }, [selectedItems, selection]);

    const loadMore = () => {
        if (loading || noMore || scrollLoading || filters?.pageNumber == null)
            return;

        setScrollLoading(true)
        dispatch(setFilters(
            {
                ...filters,
                pageNumber: filters.pageNumber + 1
            }))
    }

    const refreshData = () => {
        dispatch(setFilters({
            ...filters,
            pageNumber: 0
        }));
        dispatch(setRefresh(false));
    }

    const sortByColumn = useCallback((_: React.MouseEvent, column: IColumn) => {
        if (loading)
            return;

        dispatch(setFilters({
            ...filters,
            orderBy: column.fieldName,
            isAscending: column.isSortedDescending,
            pageNumber: 0
        }));

    }, [loading, dispatch]); //eslint-disable-line react-hooks/exhaustive-deps

    const registerEvent = useCallback(() => {
        const listElm = document.querySelector('#detailsListZone .ms-DetailsList-contentWrapper');
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50) {
                const button = document.getElementById("click");
                button && button.click();
            }
        });
    }, []);



    /*   const styles = {
           root: [
             {
               
               display: 'none',
               selectors: {
                 
                 '&.isExpanded': {
                   display: 'block'
                 },
                 ':hover .childElement': {
                   color: 'white'
                 }
               }
             }
           ]
         };
   */
    const stylesFunc = (props: IContextualMenuStyleProps): IContextualMenuStyles => {
        return {
            root: { /* styles */ },
            title: { /* styles */ },
            container: { /* styles */ },
            header: { /* styles */ },
            list: { /* styles */ },
            subComponentStyles: {
                callout: {},
                menuItem: (subProps: IContextualMenuItemStyleProps): IContextualMenuItemStyles => {
                    //const { theme,  } = props; // parent props are available in subComponents

                    // const { required, hasOtherBoolean } = subProps;
                    return ({
                        root: { color: subProps.disabled ? 'rgb(161, 159, 157)' : '' },
                    });
                }
            }
        };
    }

    const menuProps = (item: Feedback): IContextualMenuProps => {
        return {
            styles: stylesFunc,
            items: [
                {
                    key: 'view',
                    text: t('dashboardToolbar:actions.view'),
                    onClick: () => {
                        if (item.id)
                            Helpers.getKnowledgeLabPreviewFile(item.id, filePreviewSubPath.feedback);
                    },
                },
                {
                    key: 'details',
                    text: t('dashboardToolbar:actions.details'),
                    onClick: () => {
                        if (item.id) {
                            setFeedbackId(item.id)
                            setModalContentType(DashboardTextType.element)
                            setIsModalOpen(true)
                        }
                    },
                    disabled: (item.entityId === undefined || item.entityId === null)
                },
                {
                    key: 'documentText',
                    text: t('dashboardToolbar:actions.documentText'),
                    onClick: () => {
                        if (item.id) {
                            setFeedbackId(item.id)
                            setModalContentType(DashboardTextType.document)
                            setIsModalOpen(true)
                        }
                    },
                },
                {
                    key: 'retag',
                    text: t('dashboardToolbar:actions.retag'),
                    onClick: () => {
                        if (item.id) {
                            setFeedbackId(item.id);
                            setIsNerOpen(true);
                        }
                    },
                    disabled: (item.feedbackType === undefined || item.feedbackType !== FeedbackType.NER)
                }


            ],
            directionalHint: DirectionalHint.rightTopEdge
        }
    }

    const columns: IColumn[] = useMemo(() => {
        return [
            {
                key: 'ModuleInstanceName',
                name: t("columns.moduleInstanceName"),
                fieldName: 'ModuleInstanceName',
                minWidth: 200,
                maxWidth: 350,
                isSorted: filters.orderBy === 'ModuleInstanceName',
                isSortedDescending: !filters.isAscending,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: sortByColumn,
                onRender: function getCell(item: Feedback) {
                    return <div>
                        <span>{item.moduleInstanceName}</span>
                        <IconButton
                            styles={classNames.subComponentStyles.iconButton()}
                            iconProps={{ iconName: 'More' }}
                            menuProps={menuProps(item)}
                        />
                    </div>
                }
            },
            {
                key: 'FileName',
                name: t("columns.fileName"),
                fieldName: 'FileName',
                minWidth: 150,
                maxWidth: 350,
                isSorted: filters.orderBy === 'FileName',
                isSortedDescending: !filters.isAscending,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: sortByColumn,
                onRender: function getCell(item: Feedback) {
                    return <span>{item.fileName}</span>
                }
            },
            {
                key: 'SkillName',
                name: t("columns.skillName"),
                fieldName: 'SkillName',
                minWidth: 200,
                maxWidth: 350,
                isSorted: filters.orderBy === 'SkillName',
                isSortedDescending: !filters.isAscending,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: sortByColumn,

                onRender: function getCell(item: Feedback) {
                    return <span>{item.skillName}</span>
                }
            },
            {
                key: 'MetadataChanges',
                name: t("columns.metadataChanges"),
                fieldName: 'OldValue',
                minWidth: 150,
                maxWidth: 350,
                isSorted: filters.orderBy === 'OldValue',
                isSortedDescending: !filters.isAscending,
                onColumnClick: sortByColumn,
                isRowHeader: true,
                isResizable: true,
                onRender: function getCell(item: Feedback) {
                    if (item.feedbackType === FeedbackType.NER)
                        return <span>NER / NER</span>
                    else
                        return <span>{`${item.oldValue} / ${item.newValue}`}</span>
                }
            },
            {
                key: 'ExtraProperty',
                name: t("columns.extraProperty"),
                fieldName: 'ExtraProperty',
                minWidth: 150,
                maxWidth: 350,
                isSorted: filters.orderBy === 'ExtraProperty',
                isSortedDescending: !filters.isAscending,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: sortByColumn,
                onRender: function getCell(item: Feedback) {
                    return <span>{item.metadataExtraPropertyValue ?? ""}</span>
                }
            },
            {
                key: 'FeedbackStatus',
                name: t("columns.feedbackStatus"),
                fieldName: 'FeedbackStatus',
                minWidth: 90,
                maxWidth: 120,
                isResizable: true,
                isSorted: filters.orderBy === 'FeedbackStatus',
                isSortedDescending: !filters.isAscending,
                onColumnClick: sortByColumn,
                onRender: function getCreatedBy(item: Feedback) {
                    const status = dashboardStatusText.find(t => t.key === item.feedbackStatus)?.value ?? ""
                    return <span>{t(status)}</span>;
                },
            },
            {
                key: 'UserCreated',
                name: t("columns.createdBy"),
                fieldName: 'CreatorEmail',
                minWidth: 60,
                maxWidth: 120,
                isSorted: filters.orderBy === 'CreatorEmail',
                isSortedDescending: !filters.isAscending,
                onColumnClick: sortByColumn,
                isResizable: true,
                onRender: function getCell(item: Feedback) {
                    if (item.creatorEmail !== null) {
                        return <div className={classNames.personaCell}>
                            <TooltipHost
                                content={`${item.creatorName} (${item.creatorEmail})`}
                            >
                                <Persona text={item.creatorName} size={PersonaSize.size24} hidePersonaDetails={true} />
                            </TooltipHost>

                        </div>;
                    }
                    else {
                        return <div></div>
                    }
                }
            },
            {
                key: 'UserUpdated',
                name: t("columns.updatedBy"),
                fieldName: 'ApproverEmail',
                minWidth: 60,
                maxWidth: 120,
                isSorted: filters.orderBy === 'ApproverEmail',
                isSortedDescending: !filters.isAscending,
                onColumnClick: sortByColumn,
                isResizable: true,
                onRender: function getCell(item: Feedback) {
                    if (item.approverEmail !== null && item.approverEmail !== undefined && item.approverEmail !== "") {
                        return <div className={classNames.personaCell}>
                            <TooltipHost
                                content={item.approverName ? `${item.approverName} (${item.approverEmail})` : <div style={{ paddingLeft: 10, paddingRight: 10 }}>  -  </div>}
                            >
                                {item.approverName ? <Persona text={item.approverName} size={PersonaSize.size24} hidePersonaDetails={true} /> : " - "}
                            </TooltipHost>

                        </div>;
                    }
                    else {
                        return <div></div>
                    }
                }
            }];
    }, [sortByColumn, filters.isAscending, dispatch, filters.orderBy, t]); //eslint-disable-line react-hooks/exhaustive-deps

    const emptyFolder = useCallback(() => {
        if ((filters.pageNumber && filters.pageNumber > 0 && feedbackList.length === 0) || (filters.pageNumber && filters.pageNumber === 0 && loading))
            return null;

        return (
            <div className={classNames.emptyFolder}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyFolder")}
                />
            </div>
        )
    }, [classNames.emptyFolder, t, feedbackList.length, loading, filters.pageNumber, noMore]); //eslint-disable-line react-hooks/exhaustive-deps

    return (


        <>{error ?
            <TeamsImage
                imageName={ImageName.Error1}
                scale={0.3}
                fullContainer
                caption={t("common:genericErrorApi")}
            /> :
            <>

                <FeedbackModal
                    isOpen={isModalOpen}
                    documentId={feedbackId}
                    documentType={modalContentType}
                    title={modalContentType === DashboardTextType.document ? t("documentText") : t("elementText")}
                    onCloseClick={() => {
                        setIsModalOpen(false);
                        setFeedbackId(undefined);
                        setModalContentType(undefined);
                        dispatch(setRefresh(true));
                    }}
                />

                {isNerOpen &&
                    <FeedbackNerPanel
                        isOpen={isNerOpen}
                        feedbackId={feedbackId}
                        dismissPanel={() => {
                            setIsNerOpen(false);
                            setFeedbackId(undefined);
                            dispatch(setRefresh(true));
                        }}
                    />
                }

                <div id={"detailsListZone"} className={classNames.root}>
                    <ShimmeredDetailsList
                        items={feedbackList}
                        columns={columns}
                        getKey={(item: Feedback) => item && `${item.id}`}
                        selection={selection}
                        selectionMode={SelectionMode.multiple}
                        enableShimmer={filters.pageNumber === 0 && loading}
                        constrainMode={ConstrainMode.unconstrained}
                        styles={classNames.subComponentStyles.shimmeredDetailsList}
                        detailsListStyles={classNames.subComponentStyles.detailsList}
                        onRenderDetailsFooter={emptyFolder}
                    />
                    <div style={{ display: 'none' }}>
                        <PrimaryButton id={noMore ? "noClick" : "click"} onClick={loadMore} />
                    </div>

                </div>


            </>}
        </>
    );
}