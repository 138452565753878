import React from "react";
import { useSearchContent } from "../../../features/searchContent";
import { ContextualMenu, DirectionalHint, IComponentAsProps, IContextualMenuItem, IContextualMenuProps } from "@fluentui/react";
import { getSearchContentActionsByIds } from "../../../features/searchContentActions";
import { useTranslation } from "react-i18next";
import { useDocLabDispatch } from "../../../docLabStore";

export const ContextualMenuCustom = (props: React.PropsWithChildren<IComponentAsProps<IContextualMenuProps>>) => {
    const searchState = useSearchContent();
    const { selectedItemsActionIds, selectionEntryList } = useSearchContent();
    const { t } = useTranslation(['search', "archiveList", "common"]);
    const dispatch = useDocLabDispatch();

    const getContextualMenuItems = (): IContextualMenuItem[] => {
        if (selectionEntryList.length === 0)
            return [];

        const actions = getSearchContentActionsByIds(selectedItemsActionIds);
        const contextualMenuActions: IContextualMenuItem[] = [];
        actions.forEach(action => {
            contextualMenuActions.push({
                key: action.id,
                text: action.label ? t(`archiveContentActions:${action.label}`) : "",
                onClick: () => action.onClickForContextualMenu(searchState, dispatch)
            });
        });

        return contextualMenuActions;
    }

    return (
        <ContextualMenu
            items={getContextualMenuItems()}
            directionalHint={DirectionalHint.rightTopEdge}
            target={props.target}
            onDismiss={props.onDismiss}
        />
    );
}