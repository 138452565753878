import React from "react";
import { Callout, classNamesFunction } from "@fluentui/react";
import { ICalloutNotificationPropsStyles, ICalloutNotificationStyles, ICalloutNotificationProps } from "./calloutNotification.types";
const getClassNames = classNamesFunction<ICalloutNotificationPropsStyles, ICalloutNotificationStyles>();

export const CalloutNotificationBase = (props: ICalloutNotificationProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    return (
        <Callout
            gapSpace={props.gapSpace}
            target={`#${props.target}`}
            isBeakVisible={props.isBeakVisible}
            onDismiss={props.onDismiss}
            calloutMinWidth={300}
            calloutMaxWidth={450}
            className={classNames.root}
            directionalHint={props.directionalHint}
            styles={classNames.subComponentStyles.rootMain}
            hidden={props.hidden}
            preventDismissOnScroll
        >
            <div className={classNames.title}>
                {props.title}
            </div>
            <div className={classNames.message}>
                {props.message}
            </div>
        </Callout>
    );
}