import { ArchiveRoleId } from "./constants";

export type ArchiveUnivocityRule = {
    ruleId: number;
    archiveId: number;
    ruleName: string;
    isEnabled: boolean;
    metadataKeyName1: string;
    metadataValues1: string[];
    metadataKeyName2?: string;
    metadataValues2?: string[];
    metadataKeyName3?: string;
    metadataValues3?: string[];
}

export type ArchiveUnivocityRulesConfigs = {
    archiveId: number;
    involvedRolesBlocked: ArchiveRoleId[];
    rulesUsageType: RulesUsageType;
}

export enum RulesUsageType {
    AllTheArchive = 0,
    OnlyCurrentFolder = 1
}

export enum DocVerifySource {
    Upload = 0,
    MetadataPanel = 1
}

export type FileNameWithMetadata = {
    fileId?: number;
    fileName: string;
    metadata: string;
}

export type ResultDocumentMetadataUnivocity = {
    fileName: string;
    fileDuplicateName: string;
    metadataFileValue1: string;
    metadataFileValue2: string;
    metadataFileValue3: string;
    fileId? : number;

}

export type FileNameWithErrorMetadata = {
    fileName: string;
    errorOnMetadata: string;
}

export type ResultDocumentsMetadataUnivocityWithError = {
    notUnivocityDocumentList: ResultDocumentMetadataUnivocity[];
    errorOnFileMetadataList: FileNameWithErrorMetadata[];
    isUserBlocked: boolean;
}