/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable sonarjs/no-duplicate-string*/
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { ActionButton, Checkbox, ChoiceGroup, classNamesFunction, DefaultEffects, Dropdown, FocusZone, FocusZoneDirection, IChoiceGroupOption, IconButton, IDropdownOption, Label, List, ScrollablePane, Shimmer, Stack, StackItem, TextField, TooltipHost } from "@fluentui/react";
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import AdvancedDropdown from "../../../common/components/advancedDropdown/advancedDropdown";
import DatePicker from "../../../common/components/datePicker/datePicker";
import FileIconCell from "../../../common/components/fileIconCell/fileIconCell";
import Pagination from "../../../common/components/pagination/pagination";
import Tag from "../../../common/components/tag/tag";
import TeamsImage from "../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../common/components/teamsImage/teamsImage.types";
import { appInsightReactPlugin } from "../../../modules/appInsights/appInsights";
import { useAsyncApi, useOnMount } from "../../../utilities/hooks";
import { mapper } from "../../../utilities/mapper";
import { useDocLabDispatch, useDocLabState } from "../../docLabStore";
import { addSelectedFacet, getIndexField, removeFacetsByFamily, removeSelectedFacet, resetAllSearchResults, setCurrentResults, setGroupedFacets, setSearchParams, updateGroupedFacets, useSearchContent, SelectionEntryItemSearch, setSelectionEntryList, setMultipleArchiveChosen, setCurrentArchiveForSearch } from "../../features/searchContent";
import { setToolbarActions, ToolbarAction } from "../../features/toolbarActions";
import { useCurrentArchive } from "../../hooks/useCurrentArchive";
import { Archive } from "../../models/archive";
import { ArchiveStatusFilesApproval, ArchiveTypeId, Constants, FormalAspects, SearchFilters, SearchFiltersItems } from "../../models/constants";
import { archivesApi } from "../../services/archives/archives.api";
import { searchApi } from "../../services/search/search.api";
import { FacetType, FieldType, FlatFacet, IFacet, IFacetItem, ISkillResultsTagValue, MergedFacetItem, MergedFacets, SearchRequest, SearchResult, SearchValue } from "../../services/search/search.contracts";
import { ISearchProps, ISearchPropsStyles, ISearchStyles, SearchScope } from "./search.types";
import { ActionEntrySearch, getSearchContentActionsByIds } from "../../features/searchContentActions";
import { ToolbarDelegateType } from "../../hooks/useActionDelegate";
import SearchItemHeader from "./searchItemHeader/searchItemHeader";
import IconButtonSelectedAll from "./iconButtonSelectedAll/iconButtonSelectedAll";
import SearchFolderModal from "./searchFolderModal/searchFolderModal";
import { FolderProfileMaxNumber } from "../../../common/components/folderTreeViewer/folderManager/IManagerFolder";

const getClassNames = classNamesFunction<ISearchPropsStyles, ISearchStyles>();

const initFormalAspects = (): MergedFacetItem[] => {
    return FormalAspects.map(v => { return { field: v.key, type: FacetType.Range, count: 0, from: 0, to: 0 } });
}

export const SearchBase = (props: ISearchProps) => {
    const { searchResult, searchParams, groupedFacets, selectionEntryList, selectedItemsActionIds } = useSearchContent();
    const { palette, semanticColors } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [validKeyword, setValidKeyword] = useState(true);
    const [multiLanguageCheckBox, setMultiLanguageCheckBox] = useState(false);
    const [searchRequested, setSearchRequested] = useState(false);
    const { t } = useTranslation(['search', "archiveList", "common", "tagPanel"]);
    const dispatch = useDocLabDispatch();
    const docLabState = useDocLabState();
    const currentArchive = useCurrentArchive();
    const [keyword, setKeyword] = useState('');
    const [filterKeyword, setFilterKeyword] = useState('');
    const [searchFilterKeyword, setSearchFilterKeyword] = useState('');

    const [copyFacets, setCopyFacets] = useState<MergedFacets[]>([])
    const [searchFilterActived, setSearchFilterActived] = useState(false);
    const [formalAspects, setFormalAspects] = useState<MergedFacetItem[]>(initFormalAspects());
    const [searchScope, setSearchScope] = useState<SearchScope>(SearchScope.Full);
    const [searchScopeSelectedKey, setSearchScopeSelectedKey] = useState('0');
    const [firstSearch, setFirstSearch] = useState({ prevSearch: '', firstMultiLanguageSearch: true });

    const [crossArchiveSearchOption, setCrossArchiveSearchOption] = useState<IDropdownOption[]>([]);
    const [selectedArchives, setSelectedArchives] = useState<number[]>(searchParams.selectedArchives ?? []);
    const [loadArchives, setLoadArchives] = useState<boolean>(false);
    const [isSearchDone, setIsSearchDone] = useState<boolean>(false);
    const [allArchives, setAllArchives] = useState<Archive[]>([]);
    const [selectedArchivesInfo, setSelectedArchivesInfo] = useState<Archive[]>([currentArchive!]); //eslint-disable-line @typescript-eslint/no-non-null-assertion

    const { execute: search, error, loading, value: result } = useAsyncApi<SearchRequest, SearchResult>({
        func: searchApi.search,
        keepResultsWhileReloading: true
    });

    const optionsSearch: IChoiceGroupOption[] = [
        { key: SearchScope.Full.toString(), text: t('filters.standard.full') },
        { key: SearchScope.MetadataAndTags.toString(), text: t('filters.standard.metadata') },
        { key: SearchScope.Content.toString(), text: t('filters.standard.text') }
    ];

    const applicationInsightsFilters = useTrackEvent(appInsightReactPlugin, "addedSearchFilter", { searchFilters: {} });
    const applicationInsightsSearch = useTrackEvent(appInsightReactPlugin, "firstSearch", { searchInfo: {} });
    const applicationInsightsMultiLanguage = useTrackEvent(appInsightReactPlugin, "multiLanguageSearch", { multiLanguageInfo: {} });

    const [selectedFolders, setSelectedFolders] = useState<string>(searchParams.selectedFoldersString ?? t('filters.folderFilter.allFolderDefault'));
    const [selectedFoldersIds, setSelectedFoldersIds] = useState<number[] | undefined>(searchParams.selectedFolders ?? undefined);
    const [crossFolderModal, setCrossFolderModal] = useState<boolean>(false);
    const [crossArchiveSelected, setCrossArchiveSelected] = useState<boolean>(false);
    const [crossFolderSelected, setCrossFolderSelected] = useState<boolean>(false);
    const [isItemSelectedList, setIsItemSelectedList] = useState(false);
    const [totUserFolderCount, setTotUserFolderCount] = useState<number>(0);
    const [loadUserFolderCount, setloadUserFolderCount] = useState<boolean>(false);

    useOnMount(() => {
        dispatch(setCurrentArchiveForSearch(currentArchive));
        if (currentArchive?.isCrossArchiveSearchable)
            getArchives();

        dispatch(setToolbarActions([]));
        dispatch(getIndexField());
        setKeyword(searchParams.keyword || '');
        getTotalUserFolderCount();

        if (!currentArchive || currentArchive?.id !== searchParams.archiveId) {
            dispatch(resetAllSearchResults(currentArchive?.id));
            dispatch(setSelectionEntryList([]));
            setKeyword('');
            setSearchRequested(false);
        }
        else {
            if (searchResult) {
                checkDocLabSettingsChange();
                setSearchRequested(true);
            }
        }

        setIsItemSelectedList(selectionEntryList.length === searchResult?.values.length);
    });

    const getTotalUserFolderCount = async () => {
        if (!currentArchive)
            return;

        setloadUserFolderCount(true);
        try {
            const result = await archivesApi.getUserFolderCount(currentArchive?.id);
            setTotUserFolderCount(result);
        }
        finally {
            setloadUserFolderCount(false);
        }
    }

    const onFolderSelectionChange = (folders: string, ids: number[]) => {
        if (ids.length !== 0) {
            setSelectedFolders(folders);
            setSelectedFoldersIds(ids);
            setCrossFolderSelected(true);
        }
        else {
            setSelectedFolders(t('filters.folderFilter.allFolderDefault'));
            setSelectedFoldersIds(undefined);
            setCrossFolderSelected(false);
        }
    }

    useEffect(() => {
        if (currentArchive?.id && searchParams.selectedArchives?.length === 0) {
            setSelectedArchives([currentArchive.id]);
            dispatch(setSearchParams({ selectedArchives: [currentArchive.id] }));
        }
    }, [currentArchive]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //if you want re-search without filter on every changed of selected archives list
        //dispatch(setSearchParams({ selectedFacets: [] }));

        const newArchives = allArchives.filter(a => selectedArchives.find(s => s === a.id));
        if (newArchives.length > 0) {
            setSelectedArchivesInfo(newArchives);
            dispatch(setMultipleArchiveChosen(true));

            if (newArchives.length === 1) {
                dispatch(setCurrentArchiveForSearch(newArchives[0]));
                dispatch(setMultipleArchiveChosen(false));
            }
        }
        else {
            setSelectedArchivesInfo([currentArchive!]); //eslint-disable-line @typescript-eslint/no-non-null-assertion    
            dispatch(setMultipleArchiveChosen(false));
        }
    }, [selectedArchives]); //eslint-disable-line

    useEffect(() => {
        if (result && !_.isEqual(result, searchResult))
            dispatch(setCurrentResults(result));
    }, [result, dispatch]); //eslint-disable-line

    useEffect(() => {
        if (searchParams.selectedFacets && (searchParams.selectedFacets?.length ?? 0) > 0) {
            applicationInsightsFilters({ searchFilters: { filters: searchParams.selectedFacets, archiveId: currentArchive?.id ?? 0 } })
        }
    }, [searchParams.selectedFacets]); //eslint-disable-line

    const getAdditionalField = (field: string) => {
        for (const [key, value] of Object.entries(currentArchive?.metadataMapping)) {
            if (value === field)
                return key;
        }

        return undefined;
    }

    const getFacetByType = (disabledFacetFilters: string[], type: FacetType, values: IFacet<IFacetItem>[], facetsRegistered: MergedFacets[]) => {
        const facets: MergedFacets[] = new Array(SearchFilters.items.length);

        values.forEach(val => {
            if (!disabledFacetFilters?.some(x => x === val.field)) {
                const isAdditional = val.field.includes("EDI_Field");
                let additionalType: string | undefined;

                if (docLabState.searchContent.indexFields.find(i => i.name === val.field)) {
                    additionalType = docLabState.searchContent.indexFields?.find(i => i.name === val.field)?.type;
                }

                const typedAdditionalType = additionalType as keyof typeof FieldType;
                if (isAdditional && (typedAdditionalType === "Edm.DateTimeOffset" || typedAdditionalType === "Edm.Int32"))
                    return;

                const fieldToInsert = isAdditional ? (getAdditionalField(val.field) ?? '') : t(`tagPanel:keys.${val.field}`);
                const facet: MergedFacets = ({
                    title: currentArchive?.metadataJsonSchemaFields?.find(i => i.name === fieldToInsert)?.title,
                    type: type,
                    field: fieldToInsert,
                    values: val.values.map((v: IFacetItem) => { return { ...v, field: val.field, type: type } }),
                    showAll: type !== FacetType.Range ? false : undefined,
                    isAdditional: isAdditional,
                    additionalType: isAdditional ? FieldType[typedAdditionalType] : val.field.includes("AlreadyApproved") ? FieldType["Edm.Boolean"] : undefined,
                    additionalKey: val.field,
                    unRegistered: false
                });
                if (SearchFilters.items.indexOf(val.field) !== -1) {
                    facetsRegistered[SearchFilters.items.indexOf(val.field)] = facet;
                }
                else {
                    if (facet !== undefined)
                        facets.push(facet);
                }
            }
        })

        return facets;
    }

    // const addFacetByType = (disabledFacetFilters: string[], facets: MergedFacets[], type: FacetType, facetsUnregistered: MergedFacets[], val: IFacet<IFacetItem>) => {
    //     if (!disabledFacetFilters?.some(x => x === val.field)) {
    //         const isAdditional = val.field.includes("EDI_Field") ;
    //         let additionalType: string | undefined;

    //         if(docLabState.searchContent.indexFields.find(i => i.name === val.field)){
    //             additionalType = docLabState.searchContent.indexFields?.find(i => i.name === val.field)?.type;
    //         }

    //         const typedAdditionalType = additionalType as keyof typeof FieldType;
    //         if(isAdditional && (typedAdditionalType === "Edm.DateTimeOffset" || typedAdditionalType === "Edm.Int32"))
    //             return;

    //         const fieldToInsert = isAdditional ? (getAdditionalField(val.field) ?? '') : t(`tagPanel:keys.${val.field}`);
    //         const facet : MergedFacets = ({
    //             title: currentArchive?.metadataJsonSchemaFields?.find(i => i.name === fieldToInsert)?.title,
    //             type: type,
    //             field: fieldToInsert,
    //             values: val.values.map((v: IFacetItem) => { return { ...v, field: val.field, type: type } }),
    //             showAll: type !== FacetType.Range ? false : undefined,
    //             isAdditional: isAdditional,
    //             additionalType: isAdditional ? FieldType[typedAdditionalType] : undefined,
    //             additionalKey: val.field
    //         });
    //         if (SearchFilters.items.indexOf(val.field) !== -1)
    //             facets[SearchFilters.items.indexOf(val.field)] = facet;
    //         else
    //             facetsUnregistered.push(facet);
    //     }
    // }

    const getAdditionalFieldFilter = () => {
        const filterArchive: MergedFacets[] = [];
        if (!currentArchive?.metadataMapping)
            return;

        for (const [key, value] of Object.entries(currentArchive?.metadataMapping)) {
            const additionalType = docLabState.searchContent.indexFields?.find(i => i.name === value)?.type;
            const typedAdditionalType = additionalType as keyof typeof FieldType;

            if (typedAdditionalType !== "Edm.Int32" && typedAdditionalType !== "Edm.DateTimeOffset")
                continue;

            const item: MergedFacets = {
                title: currentArchive?.metadataJsonSchemaFields?.find(i => i.name === key)?.title,
                type: FacetType.Value,
                field: key,
                values: [
                    {
                        count: 0,
                        field: typeof value === 'string' ? value : '',
                        type: typedAdditionalType === "Edm.Int32" ? FacetType.Value : FacetType.DateRange
                    }
                ],
                showAll: false,
                isAdditional: true,
                additionalType: FieldType[typedAdditionalType],
                additionalKey: typeof value === 'string' ? value : ''
            }

            if (docLabState.searchContent.groupedFacets.some(i => i.field === key)) {
                const indexItem = groupedFacets.findIndex(i => i && i.field === key);
                const itemRestored = docLabState.searchContent.groupedFacets[indexItem];
                filterArchive.push(itemRestored);
            }
            else {
                filterArchive.push(item);
            }
        }
        return filterArchive;
    }

    //populateFacets
    useMemo(() => {
        if (searchResult) {
            const facetsRegistered: MergedFacets[] = new Array(SearchFilters.items.length);
            const facetsUnregistered: MergedFacets[] = [];
            const facets: MergedFacets[] = [];

            // Filtri provenienti dai metadati aggiuntivi
            const additionalFilters = getAdditionalFieldFilter();
            additionalFilters?.filter(val => val.additionalType !== 0).forEach(val => facetsUnregistered.push(val));

            const dateRangeFacets = getFacetByType(searchResult.disabledFacetFilters, FacetType.DateRange, searchResult?.facets.ranges, facetsRegistered);
            dateRangeFacets.forEach(f => {
                facetsUnregistered.push(f);
            });

            const valueFacets = getFacetByType(searchResult.disabledFacetFilters, FacetType.Value, searchResult?.facets.values, facetsRegistered);
            valueFacets.forEach(f => {
                facetsUnregistered.push(f);
            });

            //Aspetti formali (fitro, non recuperato dalla search)
            if (!searchResult?.disabledFacetFilters.some(x => x === "FormalAspects")) {
                facetsRegistered[SearchFilters.items.indexOf("FormalAspects")] =
                {
                    field: "FormalAspects",
                    type: FacetType.Range,
                    values: initFormalAspects(),
                    showAll: undefined
                };

                //popolamento Aspetti Formali
                const faUpdated = formalAspects.slice();
                if (searchParams.selectedFacets?.some(x => x.type === FacetType.Range)) {
                    searchParams.selectedFacets.forEach(val => {
                        const itemToUpdate = faUpdated.filter(x => x.field === val.key)[0];
                        if (itemToUpdate) {
                            itemToUpdate.from = val.from;
                            itemToUpdate.to = val.to;
                        }
                    })
                    setFormalAspects(faUpdated);
                }
            }

            if (selectedArchives.length === 1 && selectedArchives[0] === currentArchive?.id) {
                currentArchive?.metadataJsonSchemaFields?.forEach(m => {
                    const elementToAdd = facetsUnregistered.find(f => f.field && f.field === m.name);
                    if (elementToAdd) {
                        facets.push(elementToAdd);
                    }
                })
            }

            facetsRegistered.forEach(val => facets.push(val));

            // Order the facets filters list, if there is an order to respect in metadataOrderInSearch, only for a single archive search
            if (currentArchive?.metadataOrderInSearch !== undefined && currentArchive?.metadataOrderInSearch !== null && currentArchive?.metadataOrderInSearch.length !== 0 && !crossArchiveSelected) {
                facets.sort((a, b) => {
                    const indexA = currentArchive?.metadataOrderInSearch.indexOf(a.field || '');
                    const indexB = currentArchive?.metadataOrderInSearch.indexOf(b.field || '');

                    // If both filters are not present in metadataOrderInSearch, keep the original order
                    if (indexA === -1 && indexB === -1)
                        return 0;

                    // If only filter A is not present in metadataOrderInSearch, put filter A after filter B
                    if (indexA === -1)
                        return 1;

                    // If only filter B is not present in metadataOrderInSearch, put filter A before filter B
                    if (indexB === -1)
                        return -1;

                    // Otherwise, normally compares filters according to the order in metadataOrderInSearch
                    return indexA - indexB;
                });
            }

            dispatch(setGroupedFacets(facets));
            setCopyFacets(facets);
        }
    }, [searchResult]); //eslint-disable-line

    const checkDocLabSettingsChange = () => {
        if (searchResult) {
            //caso in cui dal "Impostazioni Doc Lab" si spegne una categoria i cui facet erano stati scelti
            const facetsToRemove = searchParams.selectedFacets?.filter(x =>
                searchResult?.disabledFacetFilters.some(val =>
                    val === x.key ||
                    (val === SearchFiltersItems.FormalAspects && FormalAspects.some(y => y.key === x.key))));
            if (facetsToRemove && facetsToRemove.length > 0) {
                dispatch(removeFacetsByFamily(facetsToRemove.map(x => x.key)));
            }

            //caso in cui dal "Impostazioni Doc Lab" si accende una categoria
            let familiesInSearchResult = searchResult.facets.ranges.map(x => x.field);
            familiesInSearchResult = familiesInSearchResult.concat(searchResult.facets.values.map(x => x.field))
            const missingFamilies = SearchFilters.items.filter(x => x !== SearchFiltersItems.FormatExt && x !== SearchFiltersItems.FormalAspects && !familiesInSearchResult.some(y => y === x)).filter(z => !searchResult.disabledFacetFilters.some(t => t === z));
            if (missingFamilies && missingFamilies.length > 0)
                dispatch(removeFacetsByFamily([]));
        }
    }

    const getArchives = async () => {
        if (currentArchive?.id && searchParams.selectedArchives?.length === 0) {
            setSelectedArchives([currentArchive.id]);
            dispatch(setSearchParams({ selectedArchives: [currentArchive.id] }));
        }

        setLoadArchives(true);
        try {
            const archives = await archivesApi.getArchives([ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity], undefined);
            const dropdownArchives = archives.map(a => mapArchiveToDropOpt(a));
            setCrossArchiveSearchOption(dropdownArchives);
            setAllArchives(archives);
        }
        finally {
            setLoadArchives(false);
        }
    }

    const mapArchiveToDropOpt = (archive: Archive): IDropdownOption => {
        return {
            ...mapper.mapArchiveToDropdownOption(archive)
        }
    }

    useEffect(() => {
        if (searchRequested) {
            const p = {
                ...searchParams,
                archiveId: currentArchive?.id
            };

            if (!p.keyword || p.keyword.length < 2 || !currentArchive) {
                setValidKeyword(false);
                return;
            }
            else
                setValidKeyword(true);

            trackOnApplicationInsight(p.keyword, multiLanguageCheckBox)

            search({
                value: p.keyword || '',
                orderedBy: p.orderBy,
                isAscending: p.isAsc ?? true,
                currentArchiveId: currentArchive?.id || 0,
                selectedArchivesId: selectedArchives || [],
                currentPage: p.pageNumber && p.pageNumber > 0 ? p.pageNumber : 1,
                pageSize: 10,
                maxHighlights: 3,
                filters: searchParams.selectedFacets,
                multiLanguageSearch: multiLanguageCheckBox,
                scope: searchScope,
                folderIds: selectedFoldersIds
            });

            if (searchParams.selectedFolders !== undefined)
                setCrossFolderSelected(true);
            setIsSearchDone(true);
        }
    }, [searchParams, currentArchive, searchRequested, searchScope]); //eslint-disable-line

    const trackOnApplicationInsight = (searchLabel: string, multilanguageSearch: boolean) => {
        let isNewSearch = false;
        if (searchLabel !== firstSearch.prevSearch) {
            setFirstSearch(
                {
                    firstMultiLanguageSearch: true,
                    prevSearch: searchLabel
                });
            isNewSearch = true;
            applicationInsightsSearch({ searchInfo: { searchedValue: searchLabel, archiveId: currentArchive?.id ?? 0 } });
        }

        if ((multilanguageSearch && firstSearch.firstMultiLanguageSearch) || (multilanguageSearch && isNewSearch)) {
            applicationInsightsMultiLanguage({ multiLanguageInfo: { searchedValue: searchLabel, multiLanguage: true, archiveId: currentArchive?.id ?? 0 } });
            setFirstSearch(
                {
                    firstMultiLanguageSearch: false,
                    prevSearch: isNewSearch ? searchLabel : firstSearch.prevSearch
                });
        }
    }

    const options: IDropdownOption[] = [
        { key: '', text: t("relevance") },
        { key: 'UserCreatedNameAsc', text: t("alphabetAsc") },
        { key: 'UserCreatedNameDesc', text: t("alphabetDesc") },
        { key: 'DateCreatedDesc', text: t("createdOnDesc") },
        { key: 'DateCreatedAsc', text: t("createdOnAsc") },
    ];

    const dropDownChange = (event: React.FormEvent<HTMLDivElement>, options?: IDropdownOption): void => {
        if (!options)
            return;
        switch (options.key) {
            case 'UserCreatedNameAsc': dispatch(setSearchParams({ orderBy: 'UserCreatedName', isAsc: true, pageNumber: 1 })); break;
            case 'UserCreatedNameDesc': dispatch(setSearchParams({ orderBy: 'UserCreatedName', isAsc: false, pageNumber: 1 })); break;
            case 'DateCreatedAsc': dispatch(setSearchParams({ orderBy: 'DateCreated', isAsc: true, pageNumber: 1 })); break;
            case 'DateCreatedDesc': dispatch(setSearchParams({ orderBy: 'DateCreated', isAsc: false, pageNumber: 1 })); break;
            default: dispatch(setSearchParams({ orderBy: undefined, isAsc: true, pageNumber: 1 })); break;
        }
    };

    const emptyResults = (withTags = false) => {
        return (!searchResult?.values || searchResult.values.length === 0) && (withTags ? searchParams.selectedFacets?.length === 0 : true);
    }

    const wrongResult = useMemo(() => {
        if (loading)
            return null;

        if (!(error || emptyResults()))
            return null;

        return (
            <TeamsImage
                imageName={error ? ImageName.Error3 : ImageName.EmptySearch}
                scale={0.3}
                fullContainer
                style={{ marginBottom: 30 }}
                caption={t(error ? "common:genericErrorApi" : "common:emptySearch")}
            />);
    }, [error, searchResult, t, loading, emptyResults]); //eslint-disable-line

    const mapToolbarActions = useCallback((actions: ActionEntrySearch[], t: TFunction<string[]>): ToolbarAction[] => {
        const toolbarActions: ToolbarAction[] = [];
        actions.forEach(action => {
            if (action.showInToolbar) {
                let subActions = undefined;
                if (action.subActionItems)
                    subActions = mapToolbarActions(action.subActionItems, t);

                toolbarActions.push({
                    id: action.id,
                    type: ToolbarDelegateType.search,
                    label: action.label ? t(`archiveContentActions:${action.label}`) : "",
                    icon: action.icon,
                    subActions: subActions
                });
            }
        });

        return toolbarActions;
    }, [selectionEntryList]); //eslint-disable-line

    useEffect(() => {
        const actions = mapToolbarActions(getSearchContentActionsByIds(selectedItemsActionIds), t);
        dispatch(setToolbarActions(actions));
    }, [selectedItemsActionIds, dispatch, t, mapToolbarActions]);

    const changedSelectedAll = (flag: boolean) => {
        setIsItemSelectedList(flag);
    }

    const onRenderCell = (item?: SearchValue, index?: number | undefined): JSX.Element => {
        if (!item)
            return <div></div>;

        const selectedFile: SelectionEntryItemSearch = {
            id: Number.parseInt(item.document.fileRef.toString()),
            name: item.document.title,
            isFolder: false,
            archiveRoleId: item.roleId,
            indexed: true,
            approvalStatus: item.document.approvalStatus
        }

        return (
            <>
                <div className={classNames.itemHeader} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <SearchItemHeader
                        selectedFile={selectedFile}
                        isItemSelectedList={isItemSelectedList}
                        changedSelectedAll={(flag) => changedSelectedAll(flag)}
                    />
                </div>

                <div key={index} className={classNames.itemCell} data-is-focusable={true}>
                    <FileIconCell
                        fileExtension={item.document.title.split('.').pop() || ''}
                        styles={{ fileIconWrapper: { selectors: { 'i': { width: '35px', height: '50px', marginLeft: '5px' } } } }}
                    />
                    <div className={classNames.itemContent}>
                        {item.highlights["Content"]?.map((h, i) =>
                            <div key={i} className={classNames.itemName} dangerouslySetInnerHTML={{ __html: h }} />
                        )}
                        <div style={{ marginBottom: 10 }}>
                            <span>
                                {item.document.userCreatedName !== null &&
                                    <>
                                        <b style={{ color: palette.black, marginRight: 3 }}>{t("createdBy")}:</b>
                                        <span>{item.document.userCreatedName}</span>
                                    </>
                                }
                            </span>
                            <span>
                                {item.document.dateCreated !== null &&
                                    <>
                                        <b style={{ color: palette.black, marginLeft: 20, marginRight: 3 }}>{t("createdOn")}:</b>
                                        {DateTime.fromISO(item.document.dateCreated, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}
                                    </>
                                }
                            </span>
                            <span>
                                <b style={{ color: palette.black, marginLeft: 20, marginRight: 3 }}>{t("documentArchive")}:</b>
                                <span>{item.archiveName}</span>
                            </span>
                            {selectedArchivesInfo.some(s => s.name === item.archiveName && s.filesApproval === ArchiveStatusFilesApproval.Enabled) &&
                                item.document.approvalStatus !== undefined &&
                                item.document.approvalStatus !== null &&
                                <span>
                                    <b style={{ color: palette.black, marginLeft: 20, marginRight: 3 }}>{t("approvalStatus")}:</b>
                                    <span>{t(`filters.approvalStatus.value_${item.document.approvalStatus}`)}</span>
                                    {item.rejectionNote &&
                                        <TooltipHost
                                            id={"tooltipRejectedNote"}
                                            content={item.rejectionNote}
                                            calloutProps={{ gapSpace: 0 }}
                                        >
                                            <IconButton
                                                iconProps={{ iconName: 'Info' }}
                                                styles={{ root: { cursor: 'help' } }}
                                            />
                                        </TooltipHost>}
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const isItemSelected = (val: MergedFacetItem) => {
        if (val.type === FacetType.Value) {
            return (searchParams.selectedFacets?.some(x => x.key === val.field && (x.values?.some(x => x === val.value) || x.value)));
        }
        if (val.type === FacetType.DateRange) {
            return (searchParams.selectedFacets?.some(x => x.key === val.field && x.from === val.from));
        }
    }

    const formatFacetVal = (val: MergedFacetItem, additionalType?: FieldType) => {
        return <>
            {val.type === FacetType.Range ?
                getRanges(formalAspects.some(x => x.field === val.field) ? formalAspects.filter(x => x.field === val.field)[0] : undefined, additionalType)
                :
                (
                    <span
                        style={{ cursor: 'pointer', fontWeight: ((isItemSelected(val)) ? 'bold' : undefined), color: ((isItemSelected(val)) ? palette.themePrimary : '') }}
                        onClick={() => {
                            if (val.count !== 0)
                                dispatch(addSelectedFacet({
                                    mergedFacedItem: val,
                                    AdditionalType: additionalType,
                                    AlreadyApproved: val.field === "AlreadyApproved" ? val.value === t('filters.alreadyApproved.true') : undefined
                                }));
                        }}
                    >
                        {val.value + ' (' + val.count + ')'}
                    </span>
                )
            }
        </>
    }

    const mapDataRange = (ranges: MergedFacetItem[]) => {
        const groupedRanges: MergedFacetItem[] = [];
        const reversedRanges = [...ranges].reverse();
        const today = reversedRanges[0].from ? new Date(reversedRanges[0].from || '').toISOString() : undefined;
        reversedRanges.forEach((val, index) => {
            if (index !== 0)
                groupedRanges.push({
                    field: val.field,
                    from: val.from ? new Date(val.from || '').toISOString() : undefined,
                    to: today,
                    type: FacetType.DateRange,
                    value: t(`filters.ranges.range_${index}`),
                    count: reversedRanges.slice(0, index + 1).reduce(function (prev, current) {
                        return prev + current.count
                    }, 0),
                })
        });
        return groupedRanges;
    }

    const mapApprovalStatusRange = (ranges: MergedFacetItem[]) => {
        if (!selectedArchivesInfo.every(a => a.filesApproval === ArchiveStatusFilesApproval.Enabled))
            return [];

        const groupedStatusRanges: MergedFacetItem[] = [];
        ranges.forEach((val, index) => {
            if (index !== 0 && val.from !== null)
                groupedStatusRanges.push({
                    field: val.field,
                    type: FacetType.DateRange,
                    from: val.from,
                    to: val.from,
                    value: t(`filters.approvalStatus.value_${val.from}`),
                    count: val.count
                });
        });

        return groupedStatusRanges;
    }

    const mapAlreadyApprovedValue = (values: MergedFacetItem[]) => {
        if (!selectedArchivesInfo.every(a => a.filesApproval === ArchiveStatusFilesApproval.Enabled))
            return [];

        const mergedFacetItems: MergedFacetItem[] = [];
        values.forEach((val) => {
            mergedFacetItems.push({
                field: val.field,
                type: FacetType.Value,
                value: t(`filters.alreadyApproved.${val.value}`),
                count: val.count
            });
        });

        return mergedFacetItems;
    }

    const getRanges = (val: MergedFacetItem | undefined, additionalType?: FieldType) => {
        const isSelected = val ? searchParams.selectedFacets?.some(x => x.key === val.field) : false;
        return (
            <>
                {(val) ?
                    (<>
                        <span style={{ fontWeight: 'bold', color: isSelected ? palette.themePrimary : '' }}>{t(`tagPanel:values.${val.field}`)}</span>
                        <Stack
                            horizontal={true}
                            horizontalAlign='stretch'
                            verticalAlign='center'
                            style={{ paddingTop: '5px', display: 'flex' }}
                            tokens={{ childrenGap: 10 }}
                        >
                            <Stack.Item>

                                <TextField
                                    type="number"
                                    label=""
                                    min="0"
                                    max={val.to}
                                    value={val.from?.toString()}
                                    onChange={(e) => {
                                        if (e.currentTarget.value && Number(e.currentTarget.value) >= 0 && Number(e.currentTarget.value) % 1 === 0 && Number(e.currentTarget.value) <= Number(val.to)) {
                                            const faUpdated = formalAspects.slice();
                                            faUpdated.filter(x => x.field === val.field)[0].from = e.currentTarget.value;
                                            setFormalAspects(faUpdated)
                                        }
                                    }}
                                    prefix={t('filters.formalAspects.from')}
                                    disabled={isSelected}
                                />
                            </Stack.Item>
                            <Stack.Item>

                                <TextField
                                    min={val.from}
                                    type="number"
                                    styles={classNames.subComponentStyles.rangeTextFieldStyle}
                                    inputMode='numeric'
                                    value={val.to?.toString()}
                                    onChange={(e) => {
                                        if (e.currentTarget.value && Number(e.currentTarget.value) % 1 === 0 && Number(e.currentTarget.value) >= Number((val.from || 0))) {
                                            const faUpdated = formalAspects.slice();
                                            faUpdated.filter(x => x.field === val.field)[0].to = e.currentTarget.value;
                                            setFormalAspects(faUpdated)
                                        }
                                    }}
                                    prefix={t('filters.formalAspects.to')}
                                    disabled={isSelected}
                                />
                            </Stack.Item>
                            {searchParams.selectedFacets?.some(x => x.key === val.field) ? '' :
                                <IconButton
                                    styles={{
                                        root: {
                                            selectors: {
                                                ':hover': {
                                                    background: 'transparent !important'
                                                },
                                                ':active': {
                                                    background: 'transparent !important'
                                                }
                                            },
                                            marginLeft: '0px !important'
                                        }
                                    }}
                                    iconProps={{ iconName: "Add", style: { fontSize: 'smaller' } }}
                                    onClick={() => dispatch(addSelectedFacet(
                                        {
                                            mergedFacedItem: {
                                                ...val,
                                                from: Number(val.from),
                                                to: Number(val.to),
                                                value: `${t(`tagPanel:values.${val.field}`)} [${val.from},${val.to}]`
                                            },
                                            AdditionalType: additionalType
                                        }))}
                                />
                            }
                        </Stack>
                    </>
                    ) : ''}
            </>
        )
    }

    const getTag = (facet: FlatFacet, value: string, facetToRemove: FlatFacet) => {
        const y: ISkillResultsTagValue = { value: value, title: '', isPinned: false, toDelete: false };
        return <StackItem key={`StackItem_${facet.key}_${y.value.toString().trim()}`}>
            <Tag
                onCancelSelected={() => dispatch(removeSelectedFacet(facetToRemove))}
                selectableTag
                small
                key={`${facet.key}_${y.value.toString().trim()}`}
                value={y}
                keyTag={`${facet.key}_${y.value.toString().trim()}`}
            />
        </StackItem>
    }

    const getTagByType = (x: FlatFacet) => {
        if (x.type === FacetType.Value && x.key === "AlreadyApproved")
            return getTag(x, t(`filters.alreadyApproved.${x.value}`), { ...x, text: t(`filters.alreadyApproved.${x.value}`) });
        if (x.type === FacetType.Value && x.value)
            return getTag(x, x.value.toString(), { ...x, text: x.value.toString() });
        if (x.type === FacetType.Value)
            return x.values?.map((val: string) => {
                return getTag(x, val, { ...x, text: val });
            });
        if (x.type === FacetType.Range || x.type === FacetType.DateRange)
            return getTag(x, x.text?.toString() || '', x);
    }

    const getFacetsToMap = (val: MergedFacets) => {
        if (val.additionalKey === 'ApprovalStatus')
            return mapApprovalStatusRange((val.values || []));
        if (val.additionalKey === 'AlreadyApproved')
            return mapAlreadyApprovedValue((val.values || []));

        switch (val.type) {
            case FacetType.Value:
            case FacetType.Range:
                return val?.values || [];
            case FacetType.DateRange:
                return mapDataRange((val.values || []));
            default:
                return [];
        }
    }

    const onShowHandler = (item: MergedFacets) => {
        const copy: MergedFacets[] = [];
        groupedFacets.forEach(gf => gf && copy.push({ ...gf }));
        const itemGroupedFacets = copy.findIndex(el => el && el.field === item.field);
        if (itemGroupedFacets >= 0) {
            copy[itemGroupedFacets].showAll = !item.showAll
        }
        dispatch(setGroupedFacets([...copy]));
    }

    const additionalItems = (item: MergedFacets) => {
        const indexItem = groupedFacets.findIndex(i => i && i.field === item.field);
        const valuesCopy = groupedFacets[indexItem].values?.slice() as MergedFacetItem[];
        const indexValue = valuesCopy.findIndex(i => i && i.field === item.additionalKey);
        const value = { ...valuesCopy[indexValue] }
        const itemsDisable = searchParams.selectedFacets?.some(x => x.key === value.field)

        return (
            item.isAdditional && item.additionalType === FieldType["Edm.DateTimeOffset"] ?
                <>
                    <Stack style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ marginRight: "5px" }}>
                            <DatePicker
                                placeholder="From"
                                disabled={itemsDisable}
                                inputValue={value.from?.toString() ? new Date(value.from?.toString()) : undefined}
                                dateCallback={(newDate) => {
                                    const indexItemToUpdate = groupedFacets.findIndex(i => i && i.field === item.field);
                                    const valuesCopy = groupedFacets[indexItemToUpdate].values?.slice() as MergedFacetItem[];
                                    const indexValueToCopy = valuesCopy.findIndex(i => i && i.field === item.additionalKey);
                                    const elementToUpdate = { ...valuesCopy[indexValueToCopy] }
                                    valuesCopy.splice(indexValueToCopy, 1);
                                    elementToUpdate.from = DateTime.fromJSDate(newDate).toString();
                                    valuesCopy.push(elementToUpdate);
                                    dispatch(updateGroupedFacets({
                                        fieldMergedFacet: indexItemToUpdate,
                                        newValue: valuesCopy
                                    }));
                                }
                                }
                            />
                        </div>
                        <DatePicker
                            placeholder="To"
                            disabled={itemsDisable}
                            inputValue={value.to?.toString() ? new Date(value.to?.toString()) : undefined}
                            minDate={value.from?.toString() ? new Date(value.from?.toString()) : undefined}
                            dateCallback={(newDate) => {
                                const indexItemToUpdate = groupedFacets.findIndex(i => i && i.field === item.field);
                                const valuesCopy = groupedFacets[indexItemToUpdate].values?.slice() as MergedFacetItem[];
                                const indexValueToCopy = valuesCopy.findIndex(i => i && i.field === item.additionalKey);
                                const elementToUpdate = { ...valuesCopy[indexValueToCopy] }
                                valuesCopy.splice(indexValueToCopy, 1);
                                elementToUpdate.to = DateTime.fromJSDate(newDate).toString();
                                valuesCopy.push(elementToUpdate);
                                dispatch(updateGroupedFacets({
                                    fieldMergedFacet: indexItemToUpdate,
                                    newValue: valuesCopy
                                }));
                            }
                            }
                        />
                        <IconButton
                            styles={
                                itemsDisable ? {
                                    root: {
                                        visibility: 'hidden'
                                    }
                                } : {
                                    root: {
                                        selectors: {
                                            ':hover': {
                                                background: 'transparent !important'
                                            },
                                            ':active': {
                                                background: 'transparent !important'
                                            }
                                        },
                                        marginLeft: '0px !important',

                                    }
                                }}
                            disabled={itemsDisable}
                            iconProps={{ iconName: "Add", style: { fontSize: 'smaller' } }}
                            onClick={() => {
                                const indexItemToUpdate = groupedFacets.findIndex(i => i && i.field === item.field);
                                const valuesCopy = groupedFacets[indexItemToUpdate].values?.slice() as MergedFacetItem[];
                                const indexValueToCopy = valuesCopy.findIndex(i => i && i.field === item.additionalKey);
                                const elementToUpdate = { ...valuesCopy[indexValueToCopy] }
                                dispatch(addSelectedFacet({
                                    mergedFacedItem: {
                                        ...elementToUpdate,
                                        from: elementToUpdate.from,
                                        to: elementToUpdate.to,
                                        value: `${item.title} [${elementToUpdate.from ? (t('filters.formalAspects.from') + ": " + onFormatDate(new Date(elementToUpdate.from?.toString() ?? ''))) : ''}${elementToUpdate.to ? (", " + t('filters.formalAspects.to') + ": " + onFormatDate(new Date(elementToUpdate.to?.toString() ?? ''))) : ''}]`
                                    },
                                    AdditionalType: item.additionalType
                                }))
                            }}
                        />
                    </Stack>
                </>
                : item.isAdditional && item.additionalType === FieldType["Edm.Int32"] ?
                    <>
                        {
                            item?.values?.map((val, ind) => {
                                return (<>
                                    <Stack
                                        horizontal={true}
                                        horizontalAlign='stretch'
                                        verticalAlign='center'
                                        style={{ paddingTop: '5px' }}
                                        tokens={{ childrenGap: 10 }}
                                    >
                                        <Stack.Item>
                                            <TextField
                                                styles={classNames.subComponentStyles.rangeTextFieldStyle}
                                                type="number"
                                                label=""
                                                min="0"
                                                max={val.to}
                                                value={val.from?.toString()}
                                                onChange={(e) => {
                                                    if (e.currentTarget.value && Number(e.currentTarget.value) >= 0 && Number(e.currentTarget.value) % 1 === 0 && Number(e.currentTarget.value) <= Number(val.to)) {
                                                        const indexItemToUpdate = groupedFacets.findIndex(i => i && i.field === item.field);
                                                        const valuesCopy = groupedFacets[indexItemToUpdate].values?.slice() as MergedFacetItem[];
                                                        const indexValueToCopy = valuesCopy.findIndex(i => i && i.field === val.field);
                                                        const elementToUpdate = { ...valuesCopy[indexValueToCopy] }
                                                        valuesCopy.splice(indexValueToCopy, 1);
                                                        elementToUpdate.from = e.currentTarget.value;
                                                        valuesCopy.push(elementToUpdate);
                                                        dispatch(updateGroupedFacets({
                                                            fieldMergedFacet: indexItemToUpdate,
                                                            newValue: valuesCopy
                                                        }));
                                                    }
                                                }}
                                                prefix={t('filters.formalAspects.from')}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <TextField
                                                min={val.from}
                                                type="number"
                                                styles={classNames.subComponentStyles.rangeTextFieldStyle}
                                                inputMode='numeric'
                                                value={val.to?.toString()}
                                                onChange={(e) => {
                                                    if (e.currentTarget.value && Number(e.currentTarget.value) >= 0 && Number(e.currentTarget.value) % 1 === 0 && Number(e.currentTarget.value) <= Number(val.to)) {
                                                        const indexItemToUpdate = groupedFacets.findIndex(i => i && i.field === item.field);
                                                        const valuesCopy = groupedFacets[indexItemToUpdate].values?.slice() as MergedFacetItem[];
                                                        const indexValueToCopy = valuesCopy.findIndex(i => i && i.field === val.field);
                                                        const elementToUpdate = { ...valuesCopy[indexValueToCopy] }
                                                        valuesCopy.splice(indexValueToCopy, 1);
                                                        elementToUpdate.to = e.currentTarget.value;
                                                        valuesCopy.push(elementToUpdate);
                                                        dispatch(updateGroupedFacets({
                                                            fieldMergedFacet: indexItemToUpdate,
                                                            newValue: valuesCopy
                                                        }));
                                                    }
                                                }}
                                                prefix={t('filters.formalAspects.to')}
                                            />
                                        </Stack.Item>
                                        {searchParams.selectedFacets?.some(x => x.key === val?.field) ? '' :
                                            <IconButton
                                                styles={{
                                                    root: {
                                                        selectors: {
                                                            ':hover': {
                                                                background: 'transparent !important'
                                                            },
                                                            ':active': {
                                                                background: 'transparent !important'
                                                            }
                                                        },
                                                        marginLeft: '0px !important'
                                                    }
                                                }}
                                                iconProps={{ iconName: "Add", style: { fontSize: 'smaller' } }}
                                                onClick={() => dispatch(addSelectedFacet({
                                                    mergedFacedItem: {
                                                        ...val,
                                                        from: Number(val.from),
                                                        to: Number(val.to),
                                                        value: `${t(`tagPanel:values.${item.additionalKey}`)} [${val.from},${val.to}]`
                                                    },
                                                    AdditionalType: item.additionalType
                                                }))}
                                            />
                                        }
                                    </Stack>
                                </>)
                            })
                        }
                    </> : ''
        );
    }

    const onFormatDate = (date?: Date): string => {
        if (date) {
            let day = '' + date?.getDate();
            let month = '' + (date?.getMonth() + 1);
            const year = date?.getFullYear();

            if (date?.getDate() < 10)
                day = '0' + date?.getDate();
            if ((date?.getMonth() + 1) < 10)
                month = '0' + (date?.getMonth() + 1);

            return !date ? '' : day + '/' + month + '/' + year;
        }
        return '';
    };

    const checkTitle = (val: MergedFacets) => {
        let res = false;
        getFacetsToMap(val).forEach((valueItem) => {
            if (valueItem.value?.toLowerCase().includes(filterKeyword.toLowerCase()))
                res = true;
        })
        return res;
    }

    const getFacets = () => {
        return (<div style={{ marginTop: '1em' }}>
            <ChoiceGroup
                defaultSelectedKey="0"
                selectedKey={searchScopeSelectedKey}
                options={optionsSearch}
                label="Search type"
                styles={{
                    label: {
                        fontWeight: 'bold'
                    },
                    flexContainer: {
                        '.ms-ChoiceField': {
                            margin: 0
                        },
                    },
                    root: {
                        marginBottom: '2em'
                    }
                }}
                onChange={(_, option) => {
                    setSearchScopeSelectedKey(option?.key ?? '0');
                    setSearchScope(parseInt(option?.key ?? '0'));
                }}
            />
            {(groupedFacets)?.map((val) => {
                return (<>
                    {
                        (val.additionalKey === 'AlreadyApproved' || val.additionalKey === 'ApprovalStatus') &&
                            !selectedArchivesInfo.every(a => a.filesApproval === ArchiveStatusFilesApproval.Enabled) ?
                            '' :
                            val.type === FacetType.DateRange && !val.values?.some(x => x.count !== 0) ?
                                '' :
                                (<Stack style={{ marginBottom: '2em' }}>
                                    {val.type === FacetType.DateRange && checkTitle(val) && <StackItem style={{ marginBottom: '5px' }}>
                                        <span style={{ fontWeight: 'bold' }}>{val.title ?? val.field}</span>
                                    </StackItem>}

                                    {val.type !== FacetType.DateRange && <StackItem style={{ marginBottom: '5px' }}>
                                        <span style={{ fontWeight: 'bold' }}>{val.title ?? val.field}</span>
                                    </StackItem>}

                                    {getFacetsToMap(val).map((valueItem, ind) => {
                                        return (
                                            !valueItem.value?.toString().toLowerCase().includes(filterKeyword.toLowerCase()) && valueItem.type === FacetType.DateRange ?
                                                additionalItems(val) :
                                                valueItem.count === 0 && valueItem.type === FacetType.DateRange ?
                                                    '' :
                                                    val.showAll || valueItem.type === FacetType.DateRange ?
                                                        <>
                                                            <Stack style={{ marginBottom: '5px' }}>
                                                                <StackItem>{formatFacetVal(valueItem, val.additionalType)}</StackItem>
                                                            </Stack>
                                                        </> : ind < Constants.MAX_FACETS_SHOWED &&
                                                        <>
                                                            <Stack style={{ marginBottom: '5px' }}>
                                                                <StackItem>{formatFacetVal(valueItem, val.additionalType)}</StackItem>
                                                            </Stack>
                                                        </>
                                        )
                                    })}

                                    {val.values && val.values?.length > Constants.MAX_FACETS_SHOWED && val.type !== FacetType.DateRange &&
                                        <Stack style={{ marginBottom: '5px' }}>
                                            <StackItem
                                                className={classNames.showAllButton}
                                                onClick={() => onShowHandler(val)}>
                                                {val.showAll === false ? t('filters.showMore') : t('filters.showLess')}
                                            </StackItem>
                                        </Stack>
                                    }
                                </Stack>)
                    }
                </>)
            })}
        </div>)
    }

    const refiners = () => {
        return <div style={{
            height: '100%',
            position: 'relative',
            maxHeight: 'inherit',
        }}>
            <ScrollablePane>
                <div>
                    <StackItem align="center" style={{ alignItems: 'center' }} >
                        <div className={classNames.searchContainer}>
                            {searchFilterActived ?
                                <TextField
                                    placeholder={t("filterMessage")}
                                    value={searchFilterKeyword}
                                    autoFocus={searchFilterActived}
                                    styles={classNames.subComponentStyles.filterSearch}
                                    onChange={(_, value) => {
                                        setSearchFilterKeyword(value || '');
                                        if (value?.length && value?.length > 1) {
                                            setFilterKeyword(value || '');
                                            setSearchFilterKeyword(value || '');
                                        }
                                    }}
                                    iconProps={{
                                        iconName: 'Clear',
                                        styles: {
                                            root: {
                                                color: palette.themePrimary,
                                                fontSize: '18px',
                                                paddingBottom: '4px',
                                                cursor: 'pointer',
                                                pointerEvents: 'auto'
                                            }
                                        },
                                        onClick: () => {
                                            setSearchFilterActived(false);
                                            setSearchFilterKeyword('');
                                            setFilterKeyword('');
                                        }
                                    }}
                                /> : <div className={classNames.containerSearchFilter}>
                                    <span style={{ fontSize: 16, fontWeight: 500 }}>{t('title')}</span>
                                    <IconButton
                                        styles={{
                                            menuIcon: {
                                                display: 'none !important'
                                            },
                                            rootExpanded: {
                                                backgroundColor: 'inherit',
                                                textShadow: 'rgb(37 36 35) 0px 0px 1px'
                                            },
                                            rootPressed: {
                                                backgroundColor: 'inherit'
                                            },
                                            rootHovered: {
                                                backgroundColor: 'inherit',
                                                textShadow: 'rgb(37 36 35) 0px 0px 1px'
                                            }
                                        }}
                                        onClick={() => setSearchFilterActived(true)}
                                        iconProps={{
                                            iconName: 'PageListFilter',
                                            styles: {
                                                root: {
                                                    color: palette.themePrimary,
                                                    fontSize: '20px',
                                                    cursor: 'pointer',
                                                    pointerEvents: 'auto',
                                                },

                                            },
                                        }} /></div>}
                        </div>
                    </StackItem>
                    <Stack horizontal style={{ marginTop: '15px' }} >
                        <StackItem align="center" style={{ alignItems: 'center', width: '100%' }} >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span style={{ fontWeight: 'bold', fontSize: '1em' }}>{t('filters.filter')}</span>
                                {isSearchDone && !loading &&
                                    <ActionButton
                                        style={{ textDecoration: 'underline', color: palette.themePrimary }}
                                        onClick={() => { clearSearch(); }}
                                    > {t("restartSearch")}
                                    </ActionButton>
                                }
                            </div>
                        </StackItem>

                        {searchParams.selectedFacets?.length !== 0 && <StackItem align="center">
                            <TooltipHost
                                content={t('filters.resetFilter')}
                                id={"resetFilters"}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                            >
                                <IconButton iconProps={{ iconName: 'ClearFilter' }} onClick={() => { dispatch(setSearchParams({ selectedFacets: [] })); }} />
                            </TooltipHost>
                        </StackItem>}

                    </Stack>
                    <Stack horizontal={true} wrap>
                        {searchParams.selectedFacets?.map((x: FlatFacet) => {
                            return getTagByType(x)
                        })}
                    </Stack>
                </div>
                {loading && getShimmer()}
                {!loading && !error && getFacets()}
            </ScrollablePane >
        </div >
    }

    const newSearch = (val: string) => {
        if (val && val.length < 2) {
            setValidKeyword(false)
            setIsSearchDone(false)
        }
        else {
            dispatch(resetAllSearchResults(currentArchive?.id));
            dispatch(setSearchParams({ keyword: val, selectedArchives: selectedArchives, selectedFolders: selectedFoldersIds, selectedFoldersString: selectedFolders }));
            setSearchRequested(true);
            setSearchFilterKeyword('');
            setFilterKeyword('');
            setSearchFilterActived(false);
            val.length > 0 ? setIsSearchDone(true) : setIsSearchDone(false);
            dispatch(setSelectionEntryList([]));
            setIsItemSelectedList(false);
        }
    }

    useEffect(() => {
        const copyGrouped = [...copyFacets];
        const newGrouped: MergedFacets[] = [];
        let filteredValues: MergedFacetItem[] = [];
        copyGrouped.forEach(group => {
            if (!group)
                return;

            if (group.type !== FacetType.Range && group.type !== FacetType.DateRange) {
                const groupValues = group.values;
                if (groupValues) {
                    filteredValues = groupValues.filter(v => (v.value?.toString().toLowerCase().includes(filterKeyword.toLowerCase())
                        || group.field?.toLowerCase().includes(filterKeyword.toLowerCase()))
                        && !(group.isAdditional && groupValues?.some(i => i.type === FacetType.DateRange || i.type === FacetType.Range)));
                }
                if (group.isAdditional && groupValues?.some(i => i.type === FacetType.DateRange || i.type === FacetType.Range)) {
                    newGrouped.push(group);
                }
                if (filteredValues.length > 0) {
                    const groupCopied = { ...group }
                    const newValues = [...filteredValues];
                    groupCopied.values = newValues;
                    newGrouped.push(groupCopied);
                }
            }
            else {
                newGrouped.push(group);
            }
        })

        dispatch(setGroupedFacets(newGrouped));
    }, [filterKeyword, copyFacets, dispatch]);

    const headerResultSection = useMemo(() => {
        return <>
            {!loading && searchResult?.values && searchResult.values.length > 0 &&
                <div className={classNames.dropDown} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <IconButtonSelectedAll
                        isItemSelectedList={isItemSelectedList}
                        changedSelectedAll={(flag) => changedSelectedAll(flag)}
                    />
                    <Dropdown
                        key={currentArchive?.id}
                        defaultSelectedKey={searchParams.orderBy ? searchParams.orderBy + (searchParams.isAsc ? "Asc" : "Desc") : ''}
                        options={options}
                        styles={{
                            dropdown: { width: 150, boxShadow: DefaultEffects.elevation8 },
                            title: { background: palette.white },
                            root: { marginTop: '0px !important', paddingLeft: 17 }
                        }}
                        onChange={dropDownChange}
                        disabled={!searchResult?.values || searchResult.values.length === 0}
                    />
                    <span style={{ paddingRight: 17, marginLeft: 'auto' }}>
                        <Pagination
                            key={currentArchive?.id}
                            pageCount={searchResult?.pageCount ?? 0}
                            currentPage={searchParams.pageNumber ?? 1}
                            onChangePage={page => { dispatch(setSearchParams({ pageNumber: page })) }}
                        />
                    </span>
                </div>}
        </>
    }, [searchResult, searchParams, loading, formalAspects, classNames, isItemSelectedList]) // eslint-disable-line

    const resultSection = useMemo(() => {
        return <>
            <Stack.Item className={classNames.resultsContent} grow>
                {!loading && searchResult?.values && searchResult.values.length > 0 &&
                    <>
                        <FocusZone direction={FocusZoneDirection.vertical}>
                            <div data-is-scrollable>
                                <List items={searchResult?.values}
                                    className={classNames.customList}
                                    onRenderCell={onRenderCell}
                                    aria-multiselectable={true}
                                    key={'selectionEntryList' + selectionEntryList.length}
                                />
                            </div>
                        </FocusZone>
                    </>
                }
            </Stack.Item>
        </>
    }, [searchResult, searchParams, loading, formalAspects, classNames]);  // eslint-disable-line

    const getShimmer = () => {
        return [...Array(5)].map((e, i) => <div key={i} style={{ marginTop: '2em' }}>
            <Shimmer width="50%" /><br />
            <Shimmer />
            <br /><br />
        </div>);
    }

    const onCheckBoxChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        checked ? setMultiLanguageCheckBox(true) : setMultiLanguageCheckBox(false);
    }

    const clearSearch = () => {
        if (currentArchive?.id) {
            setSelectedArchives([currentArchive.id]);
            dispatch(setSearchParams({ selectedArchives: [currentArchive.id] }));
        }

        if (isSearchDone)
            newSearch('')

        //clear search textfield
        setKeyword('');
        //clear scope
        setSearchScopeSelectedKey('0');
        setSearchScope(parseInt('0'));
        //clear cross archive search
        setCrossArchiveSelected(false);
        setCrossFolderSelected(false);
        //clear cross folder search
        setSelectedFolders(t('filters.folderFilter.allFolderDefault'));
        setSelectedFoldersIds(undefined);
        dispatch(setSearchParams({ selectedFolders: undefined, selectedFoldersString: undefined }));
        dispatch(setSelectionEntryList([]));
        dispatch(setToolbarActions([]));
        setIsItemSelectedList(false);
        setIsSearchDone(false);
    }

    return (
        <Stack key={0} style={{ height: '100%', display: 'flex', flexFlow: 'row', padding: '0 20px', justifyContent: emptyResults(true) ? 'center' : 'space-between' }}>
            {searchRequested && !emptyResults(true) ?
                <Stack horizontal={true} style={{ height: 'calc(100vh - 100px)', minWidth: '300px' }}>
                    {emptyResults(true) ? '' :
                        <StackItem style={{ width: '100%' }}>
                            {refiners()}
                        </StackItem>
                    }
                </Stack> : ''}

            <div className={classNames.root} >
                {!loading &&
                    <div className={classNames.searchContainer}>
                        <div className={crossFolderSelected || !currentArchive?.isCrossArchiveSearchable ? classNames.advancedDropdownHidden : classNames.advancedDropdown}>
                            {currentArchive?.isCrossArchiveSearchable && !crossFolderSelected &&
                                <div className={classNames.advancedDropdownContainer}>
                                    <Label className={classNames.advancedDropdownLabel}>
                                        {t('advancedDropdownLabel')}
                                    </Label>
                                    <AdvancedDropdown
                                        options={crossArchiveSearchOption}
                                        multiselect={true}
                                        placeholder={t('docLabMultiselectPlaceholder')}
                                        disabled={loadArchives}
                                        selectedItems={selectedArchives}
                                        className="AdvancedDropdownGlobalClassNames.root"
                                        onChangeItems={(keys) => {
                                            if (keys.length !== 0)
                                                setSelectedArchives(keys);
                                            else
                                                setSelectedArchives([currentArchive?.id]);

                                            if (keys.length === 1 && keys[0] === currentArchive.id)
                                                setCrossArchiveSelected(false);
                                            else
                                                setCrossArchiveSelected(true);
                                        }}
                                    />
                                </div>}
                            {(!isSearchDone || emptyResults(true)) &&
                                <ActionButton
                                    style={{ textDecoration: 'underline', color: palette.themePrimary }}
                                    onClick={clearSearch}
                                > {t("resetSearch")}
                                </ActionButton>}
                            {(isSearchDone && !emptyResults(true)) && (currentArchive?.isCrossFolderSearchable || currentArchive?.isCrossArchiveSearchable) &&
                                <ActionButton
                                    iconProps={{ iconName: 'Refresh', style: { color: palette.themePrimary } }}
                                    onClick={() => newSearch(keyword)}
                                >
                                    <span style={{ textDecoration: 'underline', color: palette.themePrimary }}>
                                        {t("refreshResults")}
                                    </span>
                                </ActionButton>}
                        </div>
                        <TextField
                            key={currentArchive?.id}
                            placeholder={t("searchBox")}
                            value={keyword}
                            errorMessage={(validKeyword) ? undefined : t("errorMessage")}
                            styles={classNames.subComponentStyles.textFieldStyle}
                            onChange={(_, value) => setKeyword(value || '')}
                            iconProps={{
                                iconName: 'search',
                                styles: {
                                    root: {
                                        color: (validKeyword) ? palette.themePrimary : semanticColors.errorIcon,
                                        fontSize: '25px',
                                        paddingBottom: '4px',
                                        cursor: 'pointer',
                                        pointerEvents: 'auto'
                                    }
                                },
                                onClick: () => newSearch(keyword)
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter')
                                    newSearch(e.currentTarget.value);
                            }
                            }
                        />
                        <div className={classNames.subMenuSearchContainer}>
                            <div className={classNames.selectionFolderContainer}>
                                {currentArchive?.isCrossFolderSearchable && !crossArchiveSelected && <>
                                    <div className={classNames.listFolderContainer}>
                                        <span style={{ whiteSpace: 'nowrap', marginRight: 3 }}>{t('filters.folderFilter.crossFolderSearch')}</span>
                                        <TooltipHost content={selectedFolders} className={classNames.tooltipHostStyle}>
                                            <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{selectedFolders}</span>
                                        </TooltipHost>
                                    </div>
                                    <ActionButton
                                        disabled={crossArchiveSelected}
                                        style={{ textDecoration: 'underline', color: palette.themePrimary }}
                                        onClick={() => { setCrossFolderModal(true) }}>
                                        {t('filters.folderFilter.folderModal')}
                                    </ActionButton>
                                </>}
                            </div>
                            <TooltipHost
                                content={t('multiLanguage.tooltipLabel')}
                                styles={classNames.subComponentStyles.checkBoxStyle}>
                                <Checkbox
                                    label={t('multiLanguage.labelCheckBox')}
                                    onChange={(ev, checked) => onCheckBoxChange(ev, checked)}
                                    checked={multiLanguageCheckBox}
                                />
                            </TooltipHost>
                        </div>
                    </div>
                }
                {loading &&
                    <div className={classNames.spinner}>
                        <TeamsSpinner />
                    </div>
                }
                {searchRequested && wrongResult}
                {!error && headerResultSection}
                {!error && resultSection}
            </div>

            {crossFolderModal && !loadUserFolderCount &&
                <SearchFolderModal
                    isOpen={crossFolderModal}
                    onClose={() => { setCrossFolderModal(false) }}
                    archiveId={currentArchive?.id ?? 0}
                    onComplete={() => { setCrossFolderModal(false) }}
                    onChange={onFolderSelectionChange}
                    selectedFolder={selectedFoldersIds}
                    translationName={'search:filters.folderFilter'}
                    isProfileFoldersBulk={totUserFolderCount > FolderProfileMaxNumber}
                />
            }
        </Stack>
    );
}