import { styled } from "@fluentui/react";
import { getStyles } from "./ediCallout.styles";
import { IEdiCalloutPropsStyles, IEdiCalloutStyles, IEdiCalloutProps } from "./ediCallout.types";
import { EdiCalloutBase } from "./ediCallout.base";

const EdiCallout = styled<IEdiCalloutProps, IEdiCalloutPropsStyles, IEdiCalloutStyles>(
    EdiCalloutBase,
    getStyles,
    undefined,
    { scope: 'EdiCallout' }
);

export default EdiCallout;