import { IDetailsListStyles, IDetailsRowStyles, IStyleFunction } from "@fluentui/react"
import { IManageAssociationTopicUsersPropsStyles, IManageAssociationTopicUsersStyles } from "./manageAssociationTopicUsers.types"

export const ManageAssociationTopicUsersGlobalClassNames = {
    root: 'edi-manage-association-topic-users-root',
    errorImage: 'edi-manage-association-topic-users-errorImage',
    stackContainer: 'edi-manage-association-topic-users-stackContainer',
    divContainer: 'edi-manage-association-topic-users-divContainer',
    iconButtonAccordion: 'edi-manage-association-topic-users-iconButtonAccordion',
    topicList: 'edi-manage-association-topic-users-topicList',
    labelBox: 'edi-manage-association-topic-users-labelBox',
    iconTagLabel: 'edi-manage-association-topic-users-iconTagLabel',
    remainingIconTagLabel: 'edi-manage-association-topic-users-remainingIconTagLabel',
    detailListContainer: 'edi-manage-association-topic-users-detailListContainer',
    containerEmptyList: 'edi-manage-association-topic-users-containerEmptyList',
    flexContainer: 'edi-manage-association-topic-users-flexContainer',
    countCircle: 'edi-manage-association-topic-users-countCircle',
    flexFooterContainer: 'edi-manage-association-topic-users-flexFooterContainer'
}

export const getStyles: IStyleFunction<IManageAssociationTopicUsersPropsStyles, IManageAssociationTopicUsersStyles> = (props: IManageAssociationTopicUsersPropsStyles): IManageAssociationTopicUsersStyles => {
    const { palette, semanticColors } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            ManageAssociationTopicUsersGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px',
                position: 'relative',
                selectors: {
                    ' .edi-profile-view-action': {
                        right: '15px',
                        bottom: '15px',
                    }
                }
            }
        ],
        errorImage: [
            ManageAssociationTopicUsersGlobalClassNames.errorImage,
            {
                height: '200px',
                width: '400px',
                alignSelf: 'center',
                marginTop: '50px'
            }
        ],
        stackContainer: [
            ManageAssociationTopicUsersGlobalClassNames.stackContainer,
            {
                marginBottom: '30px',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap'
            }
        ],
        divContainer: [
            ManageAssociationTopicUsersGlobalClassNames.divContainer,
            {
                height: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '50px'
            }
        ],
        iconButtonAccordion: [
            ManageAssociationTopicUsersGlobalClassNames.iconButtonAccordion,
            {
                width: '30px',
                height: '30px',
                fontSize: '20px',
                pointerEvents: 'auto'
            }
        ],
        topicList: [
            ManageAssociationTopicUsersGlobalClassNames.topicList,
            {
                display:'flex',
                flexDirection:'horizontal',
                alignItems: 'center',
                alignContent: 'center',
                selectors: {
                    ' .icontag-container': {
                        marginLeft: '10px'
                    },
                    ' .edi-icon-tag-root':{
                        padding: '0 13px'
                    }
                }
            }
        ],
        labelBox: [
            ManageAssociationTopicUsersGlobalClassNames.labelBox,
            {
                width: '100px',
                overflow: 'hidden',
                height: '25px',
                padding: '3px 0'
            }
        ],
        iconTagLabel: [
            ManageAssociationTopicUsersGlobalClassNames.iconTagLabel,
            {
                width: '150px',
                overflow: 'hidden',
                height: '25px'
            }
        ],
        remainingIconTagLabel: [
            ManageAssociationTopicUsersGlobalClassNames.remainingIconTagLabel,
            {
                padding: '0 5px',
                width: '100%',
                textAlign: 'left',
                color: palette.themePrimary
            }
        ],
        detailListContainer: [
            ManageAssociationTopicUsersGlobalClassNames.detailListContainer,
            {
                height: 'calc(100vh - 257px)',
                paddingRight: '20px',
                marginTop: '15px',
                marginBottom: '15px',
                selectors:{
                    ' .edi-accordion-root': {
                        position: 'relative'
                    },
                    ' .edi-accordion-header': {
                        position: 'relative'
                    }
                }
            }
        ],
        containerEmptyList: [
            ManageAssociationTopicUsersGlobalClassNames.containerEmptyList,
            {
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        ],
        flexContainer: [
            ManageAssociationTopicUsersGlobalClassNames.flexContainer,
            {
                display: 'flex',
                flexWrap: 'nowrap',
                flexDirection: 'row',
                alignContent: 'center'
            }
        ],
        countCircle: [
            ManageAssociationTopicUsersGlobalClassNames.countCircle,
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                fontSize: '12px',
                backgroundColor: semanticColors.variantBorder,
                color: palette.themePrimary
            }
        ],
        flexFooterContainer: [
            ManageAssociationTopicUsersGlobalClassNames.flexFooterContainer,
            {
                display: 'flex',
                flexWrap: 'nowrap',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'flex-end'
            }
        ],
        subComponentStyles: {
            list: (): IDetailsListStyles => {
                return {
                    root: {
                        selectors: {
                            '& [role=grid]': {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                maxHeight: '65vh'
                            }
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto',
                        '> .ms-DetailsHeader': {
                            paddingTop: 0,
                            borderBottomColor: 'transparent'
                        }
                    },
                    contentWrapper: {
                        flex: '1 1 auto',
                        overflowY: 'overlay',
                        overflowX: 'hidden',
                        width: '100%'
                    },
                    focusZone: {}
                }
            },
            row: (): IDetailsRowStyles => {
                return {
                    root: {
                        backgroundColor: palette.white,
                        ':hover': {
                            backgroundColor: palette.white
                        }
                    },
                    cell: {},
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            }
        }
    }
}