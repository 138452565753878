import { styled } from "@fluentui/react";
import { RootBase } from "./root.base";
import { getStyles } from "./root.styles";
import { IRootProps, IRootPropsStyles, IRootStyles } from "./root.types";

const KnowledgeDsRoot = styled<IRootProps, IRootPropsStyles, IRootStyles>(
    RootBase,
    getStyles,
    undefined,
    { scope: 'DocLab' }
);

export default KnowledgeDsRoot;