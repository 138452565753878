import { styled } from "@fluentui/react";
import { getStyles } from "./activityCard.styles";
import { IActivityCardPropsStyles, IActivityCardStyles, IActivityCardProps } from "./activityCard.types";
import { ActivityCardBase } from "./activityCard.base";

const ActivityCard = styled<IActivityCardProps, IActivityCardPropsStyles, IActivityCardStyles>(
    ActivityCardBase,
    getStyles,
    undefined,
    { scope: 'ActivityCard' }
);

export default ActivityCard;