import { IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IIconStyleProps, IIconStyles, IStyleFunction } from "@fluentui/react"
import { ITocResultPropsStyles, ITocResultStyles } from "./tocResult.types"

export const TocResultGlobalClassNames = {
    root: 'edi-toc-result-root',
    error: 'edi-toc-result-error',
    header: 'edi-toc-result-header',
    resultNok: 'edi-toc-result-resultKo',
    resultOk: 'edi-toc-result-resultOk',
    headerRow: 'edi-toc-result-headerRow',
}

export const getStyles: IStyleFunction<ITocResultPropsStyles, ITocResultStyles> = (props: ITocResultPropsStyles): ITocResultStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            TocResultGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        resultNok : [TocResultGlobalClassNames.resultNok,{
            fontWeight: 'bold',
            color: palette.red
        }],
        resultOk : [TocResultGlobalClassNames.resultOk,{
            fontWeight: 'bold',
            color: palette.green
        }],
        error: [
            TocResultGlobalClassNames.error, {
                color: palette.red
            }],
        header:[TocResultGlobalClassNames.header,{
            display: 'flex',
            alignItems: 'center',
            margin: '20px 0',
            color: palette.themeDark
            }],
            headerRow: [
                TocResultGlobalClassNames.headerRow,
                {
                    ':hover': {
                        backgroundColor: 'transparent'
                    },
                    ':active': {
                        backgroundColor: 'transparent'
                    }
                }
            ],
        subComponentStyles: {
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        },
                        overflow:'hidden'
                    },
                    headerWrapper: {
                        flex: '0 0 auto',
                        '> .ms-DetailsHeader': {
                            backgroundColor: palette.white,
                            border: 'none'
                        }
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        overflowY: 'auto',
                        overflowX:'auto',
                        '@media(max-width: 630px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            },
            icon: (props: IIconStyleProps & { ok: boolean }): IIconStyles => {
                return {
                    root: {
                        fontSize: '25px',
                        color: props.ok ? palette.green : palette.redDark
                    }
                }

            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        width: '100%',
                        background: palette.white + " !important",
                        pointerEvents: 'none !important'
                    },
                    cell: {
                        textAlign:'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems:'enter',
                        whiteSpace:'normal',
                        wordBreak: 'break-word'
                    },
                    cellAnimation: {},
                    cellUnpadded: {},
                    cellPadded: {},
                    checkCell: {},
                    isRowHeader: {},
                    isMultiline: {},
                    fields: {
                        backgroundColor: palette.white,
                    },
                    cellMeasurer: {},
                    checkCover: {},
                    check: {}
                }
            }
        }
    };
}