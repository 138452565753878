import { useCallback, useMemo } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { useDocLabDispatch } from "../docLabStore";
import { useArchiveListContent } from "../features/archiveContent";
import { loadArchive } from "../features/archiveList";
import { AnalysisTypeId } from "../models/docAnalysis";
import { ArchiveSettingsTab, ArchiveTab, DocLabRoutes } from "../utilities/routes";

export interface INavigator {
    isDocLabRoute: boolean;
    currentArchiveId?: number;
    currentArchiveTab?: ArchiveTab;
    changeArchive: (archiveId: number, tab?: ArchiveTab) => void
    changeArchiveTab: (tab: ArchiveTab) => void,
    goToArchiveSettingsTab: (tab: ArchiveSettingsTab, archiveId?: number) => void;
    goToArchivePath: (path: string) => void;
    goToAnalysisPath: (id: number, type: AnalysisTypeId) => void;
    goToPath: (path: string) => void;
    goBack: () => void;
    goToFirstArchive: () => void;
}

export const useNavigator = (): INavigator => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDocLabDispatch();
    const archiveListState = useArchiveListContent();
    const docLabRoutePath = useMemo(() =>
        matchPath<{ archiveId: string, tab: ArchiveTab }>(location.pathname, { path: DocLabRoutes.docLabOptionals }),
        [location.pathname]);

    const isDocLabRoute = useMemo(() => docLabRoutePath !== null, [docLabRoutePath]);
    
    const currentArchiveId = useMemo(() => docLabRoutePath?.params.archiveId ? +docLabRoutePath?.params.archiveId : undefined, [docLabRoutePath]);
    
    const currentArchiveTab = useMemo(() => docLabRoutePath?.params.tab, [docLabRoutePath]);
    
    const changeArchive = useCallback((archiveId: number, tab?: ArchiveTab) => {
        history.push(DocLabRoutes.generateArchiveTabPath(archiveId, tab ?? currentArchiveTab ?? ArchiveTab.files));
        dispatch(loadArchive(archiveId));
    }, [currentArchiveTab, history, dispatch]);

    const changeArchiveTab = useCallback((tab: ArchiveTab) => {
        if (!currentArchiveId)
            return;

            history.push(DocLabRoutes.generateArchiveTabPath(currentArchiveId, tab));
    }, [currentArchiveId, history]);

    const goToArchiveSettingsTab = useCallback((tab: ArchiveSettingsTab, archiveId?: number) => {
        if (!archiveId && !currentArchiveId)
            return;
        
        history.push(DocLabRoutes.generateArchiveSettingsTabPath(archiveId ?? currentArchiveId ?? 0, tab));
    }, [currentArchiveId, history])


    const goToArchivePath = useCallback((path: string) => {
        if (!currentArchiveId)
            return;

        history.push(`${DocLabRoutes.generateArchivePath(currentArchiveId)}/${path}`);
    }, [currentArchiveId, history])

    const goToAnalysisPath = useCallback((id: number, type: AnalysisTypeId) => {
        if (!currentArchiveId)
            return;

        history.push(`${DocLabRoutes.generateAnalysisPath(currentArchiveId, id, type)}`);
    }, [currentArchiveId, history])


    const goToPath = useCallback((path: string) => history.push(path), [history]);

    const goBack = useCallback(() => history.goBack(), [history]);

    const goToFirstArchive = useCallback(() => {
        if( archiveListState.archiveIds[0]){
            changeArchive(archiveListState.archiveIds[0])           
        }
    }, [archiveListState.archiveIds, changeArchive])

    return {
        isDocLabRoute: isDocLabRoute,
        currentArchiveId: currentArchiveId,
        currentArchiveTab: currentArchiveTab,
        changeArchive: changeArchive,
        changeArchiveTab: changeArchiveTab,
        goToArchiveSettingsTab: goToArchiveSettingsTab,
        goToArchivePath: goToArchivePath,
        goToAnalysisPath: goToAnalysisPath,
        goToPath: goToPath,
        goBack: goBack,
        goToFirstArchive: goToFirstArchive
    }
}