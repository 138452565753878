import { styled } from "@fluentui/react";
import { getStyles } from "./archiveContent.styles";
import { IArchiveContentPropsStyles, IArchiveContentStyles, IArchiveContentProps } from "./archiveContent.types";
import { ArchiveContentBase } from "./archiveContent.base";

const archiveContent = styled<IArchiveContentProps, IArchiveContentPropsStyles, IArchiveContentStyles>(
    ArchiveContentBase,
    getStyles,
    undefined,
    { scope: 'ArchiveContent' }
);

export default archiveContent;