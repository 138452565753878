import { DefaultEffects, FontWeights, IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IShimmeredDetailsListStyleProps, IShimmeredDetailsListStyles, IStyleFunction } from "@fluentui/react"
import { IMultiStepModalPropsStyles, IMultiStepModalStyles } from "../../../../common/components/multiStepModal/multiStepModal.types";
import { IDocAnalysisStartModalPropsStyles, IDocAnalysisStartModalStyles } from "./docAnalysisStartModal.types"

export const DocAnalysisStartModalGlobalClassNames = {
    root: 'edi-doc-analysis-start-modal-root',
    selected: 'edi-doc-analysis-start-modal-selected',
    selectedContainer: 'edi-doc-analysis-start-modal-selectedContainer',
    grid: 'edi-doc-analysis-start-modal-grid',
    loadContainer: 'edi-doc-analysis-start-modal-loadContainer',
    errorMessage: 'edi-doc-analysis-start-modal-errorMessage',
    maxNumError: 'edi-doc-analysis-start-modal-maxNumError',
    footerContainer: 'edi-doc-analysis-start-modal-footerContainer',
    footerButtons: 'edi-doc-analysis-start-modal-footerButtons'
}

export const getStyles: IStyleFunction<IDocAnalysisStartModalPropsStyles, IDocAnalysisStartModalStyles> = (props: IDocAnalysisStartModalPropsStyles): IDocAnalysisStartModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        itemContent: {
            boxShadow: DefaultEffects.elevation8,
            margin: 5,
            padding: 5,
            backgroundColor: palette.neutralLight
        },
        cellName: {
            cursor: 'pointer',
            ':hover': {
                textDecoration: 'underline'
            }
        },
        selectedContainer: [
            DocAnalysisStartModalGlobalClassNames.selectedContainer,
            {
                display: 'flex',
                justifyContent: 'space-between'
            }],
        loadContainer: [
            DocAnalysisStartModalGlobalClassNames.loadContainer,
            {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100% - 191px)'
            }],
        errorMessage: [DocAnalysisStartModalGlobalClassNames.errorMessage,
        {
            color: palette.redDark,
            display: 'flex',
            alignItems: 'center'
        }],
        itemName: [
            {
                overflow: 'hidden',
                boxSizing: 'border-box',
                flexGrow: 1,
                paddingBottom: 5,
                paddingLeft: 30,
                background: palette.white,
                display: 'flex',
                selectors: {
                    '&:hover': { background: palette.whiteTranslucent40 },
                },
            },
        ],
        grid: [
            DocAnalysisStartModalGlobalClassNames.grid,
            {
                display: 'grid',
                gridTemplateColumns: '100px 50px 50px',
                background: palette.white,
                boxShadow: DefaultEffects.elevation4,
                marginBottom: 2,
                maxWidth: 200,
                '> *': {
                    padding: '5px 10px',
                    color: palette.neutralPrimary
                },
                '> :first-child': {
                    backgroundColor: palette.neutralSecondary,
                    color: palette.white,
                    fontWeight: FontWeights.semibold
                },
            }
        ],
        subComponentStyles: {
            list: (): IDetailsListStyles => {
                return {
                    root: {},
                    contentWrapper: {
                    },
                    focusZone: {},
                    headerWrapper: {
                        '> .ms-DetailsHeader': {
                            paddingTop: 0,
                            borderBottomColor: 'transparent'
                        }
                    },
                }
            },
            row: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                const { palette } = props.theme;

                return {
                    root: {
                        backgroundColor: palette.white,
                        ':hover': {
                            backgroundColor: palette.white
                        }
                    },
                    cell: { alignSelf: 'center' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
            shimmeredDetailsList: (props: IShimmeredDetailsListStyleProps): IShimmeredDetailsListStyles => {
                return {
                    root: {
                        '::after': {
                            display: 'none'
                        },
                        position: 'relative'
                    }
                }
            },
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto'
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        flexBasis: 'calc(100vh - 475px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            },
            ediModal: (props: IMultiStepModalPropsStyles): IMultiStepModalStyles => {
                return {
                    // body: { marginTop: '0px !important' },
                    body: {},
                    bodyContent: {},
                    title: {},
                    closeIcon: {},
                    container: { width: '800px' },
                    content: {},
                    footer: {
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    },
                    header: {},
                    subComponentStyles: {
                        iconButton: {}
                    },
                    subTitle: { color: palette.black }
                }
            }
        },
        thirdFooter: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center'
        },
        emptyFolder: {
            height: '204px',
            paddingTop: '52px',
            paddingBottom: '174px'
        },
        searchAndCompareContent: {
            textDecoration: 'underline',
            color: palette.themePrimary,
            fontWeight: 'bold',
            cursor: 'pointer'
        },
        root: [
            DocAnalysisStartModalGlobalClassNames.root,
            {
                color: palette.black
            }
        ],
        selected: [
            DocAnalysisStartModalGlobalClassNames.selected,
            {
                color: props.selectedNumber === props.maxNumFiles ? palette.orangeLighter : props.selectedNumber && props.selectedNumber > 0 ? palette.green : palette.redDark
            }
        ],
        maxNumError: [
            DocAnalysisStartModalGlobalClassNames.maxNumError,
            {
                color: palette.red
            }
        ],
        footerContainer: [
            DocAnalysisStartModalGlobalClassNames.footerContainer,
            {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
            }
        ],
        footerButtons: [
            DocAnalysisStartModalGlobalClassNames.footerButtons,
            {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '5px'
            }
        ],
        requiredTextLeft:{
            display:'flex', 
            alignItems:'center',
            gap: 5,
            justifyContent: "flex-start",
            bottom: 0,
            left:40,
            color: palette.redDark,
            background: palette.white
        }
    };
}