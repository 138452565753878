/*eslint-disable @typescript-eslint/no-explicit-any*/
import { ArchiveRoleId, ArchiveStatusFilesApproval, ArchiveTypeId, UnivocityRulesUsageType } from "./constants";

export type Archive = {
    id: number;
    name: string;
    description: string;
    typeId: ArchiveTypeId;
    isCrossArchiveSearchable: boolean;
    isCrossFolderSearchable: boolean;
    isTranslationAllowed: boolean;
    isDefaultViewAdditionalMetadata: boolean;
    isReportDateFieldsInDateFormat: boolean;
    isLogChangesMetadata: boolean;
    currentUserRoleId: ArchiveRoleId;
    rootFolderId: number;
    isPermanent: boolean;
    metadataJsonSchema: any;
    metadataUISchema: any;
    metadataMapping: any;
    metadataRequired: boolean;
    metadataJsonSchemaFields: JsonSchemaField[];
    newUserHandling: boolean;
    managedByIag: boolean;
    metadataShortJsonSchema: any;
    filesApproval: ArchiveStatusFilesApproval;
    isRecycleBinActive: boolean;
    involvedRolesBlocked?: ArchiveRoleId[];
    rulesUsageType?: UnivocityRulesUsageType;
    displayedMetadataKey: string[];
    isTopicOfInterestActive: boolean;
    metadataOrderInSearch: string[];
}

export type JsonSchemaField = {
    title: string;
    name: string;
}

export type ArchiveCapacity = {
    id: number;
    capacity: number;
    usedCapacity: number;
}

export type ArchiveReport = {
    createdOn: Date | null;
    status: ExportStatus;
    archiveReportId: number | null;
    userId: string | null;
    previousReportCreatedOn?: Date;
    reportDescription: string;
    reportName: string;
    reportType: ReportType;
    configs: any;
}

export enum ExportStatus {
    notExisting = 0,
    onGoing = 1,
    completed = 2,
    inError = 3,
    completedWithError = 4
}

export enum ReportType {
    Metadata,
    ACL,
    Keyphrases,
    ENITER,
    Import,
    Export,
    ImportACPV,
    ImportDocsTree,
    ManagementReport,
    FilesMetadataChanges
}

export type OtherSettings = {
    isCrossArchiveSearchable: boolean | null;
    isCrossFolderSearchable: boolean | null;
    isTranslationAllowed: boolean | null;
    isReportDateFieldsInDateFormat: boolean | null;
    isDefaultViewAdditionalMetadata: boolean | null;
    isLogChangesMetadata: boolean|null;
}