import React from "react";
import { classNamesFunction, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import { IDeleteKnowledgeElementModalPropsStyles, IDeleteKnowledgeElementModalStyles, IDeleteKnowledgeElementModalProps } from "./deleteKnowledgeElementModal.types";
import { useTranslation } from "react-i18next";
import { useKnowledgeLabSelector } from "../../knowledgeLabStore";
import { KnowledgeHelpers } from "../../utilities/helpers";

const getClassNames = classNamesFunction<IDeleteKnowledgeElementModalPropsStyles, IDeleteKnowledgeElementModalStyles>();


export const DeleteKnowledgeElementModalBase = (props: IDeleteKnowledgeElementModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t, i18n } = useTranslation('knowledgeDocuments');
    const { selectedKnowledgeLab } = useKnowledgeLabSelector(state => state.knowledgeLabList);

    const currentLang = (i18n.language).split("-")[0];
    const currentTranslations = selectedKnowledgeLab?.configurations?.translations;
    const elementName = KnowledgeHelpers.getKnowledgeTranslation("elementPlaceHolder", currentTranslations, currentLang);

    const confirmDelete = () => {
        try {
            if (props.onComplete) props.onComplete();
        } catch {
            if (props.onError) props.onError();
        }
    }

    const dialogContentProps = {
        type: DialogType.normal,
        title: t('deleteElementModal.title') + ` ${elementName}`,
        closeButtonAriaLabel: t('deleteElementModal.close'),
        subText: t('deleteElementModal.text'),
    };

    return (
        <Dialog
            isOpen={props.isOpen}
            className={classNames.root}
            onDismiss={() => props.onClose()}
            dialogContentProps={dialogContentProps}

        >
            <DialogFooter>
                <DefaultButton onClick={props.onClose}>{t("deleteModal.cancel")}</DefaultButton>
                <PrimaryButton onClick={confirmDelete}>{t("deleteModal.confirm")}</PrimaryButton>
            </DialogFooter>
        </Dialog>
    );
}