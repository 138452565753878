import { styled } from "@fluentui/react";
import { getStyles } from "./datasetFilters.styles";
import { IDatasetFiltersPropsStyles, IDatasetFiltersStyles, IDatasetFiltersProps } from "./datasetFilters.types";
import { DatasetFiltersBase } from "./datasetFilters.base";

const DatasetFilters = styled<IDatasetFiltersProps, IDatasetFiltersPropsStyles, IDatasetFiltersStyles>(
    DatasetFiltersBase,
    getStyles,
    undefined,
    { scope: 'DatasetFilters' }
);

export default DatasetFilters;