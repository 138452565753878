import { IStyleFunction } from "@fluentui/react"
import { IWorkflowStartModalPropsStyles, IWorkflowStartModalStyles } from "./workflowStartModal.types"

export const WorkflowStartModalGlobalClassNames = {
    root: 'edi-workflow-start-modal-root',
    buttonsContainer: 'edi-workflow-start-modal-buttonsContainer',
}

export const getStyles: IStyleFunction<IWorkflowStartModalPropsStyles, IWorkflowStartModalStyles> = (props: IWorkflowStartModalPropsStyles): IWorkflowStartModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    
    return {
        root: [
            WorkflowStartModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        buttonsContainer:[WorkflowStartModalGlobalClassNames.buttonsContainer,{
            display:'flex',
            flexDirection:'row',
            gap: 5,
            justifyContent: 'flex-end'
        }],
        requiredTextLeft: [{
            display:'flex', 
            alignItems:'center',
            gap: 5,
            justifyContent: "flex-start",
            bottom: 0,
            left:40,
            color: palette.redDark,
            background: palette.white
        }]
    };
}