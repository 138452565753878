/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable sonarjs/no-duplicate-string */

import { IColumn, IconButton, keyframes, Spinner, SpinnerSize } from "@fluentui/react";
import { noop } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "../../../../common/components/carousel/carousel";
import { carouselItem } from "../../../../common/components/carousel/carousel.base";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { setPageCarousel } from "../../../../utilities/constants";
import { Helpers } from "../../../../utilities/helpers";
import { useOnMount } from "../../../../utilities/hooks";
import { useDocLabDispatch } from "../../../docLabStore";
import { showQCStartModal, useArchiveContent } from "../../../features/archiveContent";
import { showDocAnalysisDeleteModal, useDocAnalysisContent } from "../../../features/docAnalysisContent";
import { setToolbarActions } from "../../../features/toolbarActions";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { ArchiveRoleId, Constants } from "../../../models/constants";
import useDocAnalysis, { AnalysisType, AnalysisTypeId, analyzes } from "../../../models/docAnalysis";
import { docAnalyzesApi } from "../../../services/docAnalyzes/docAnalyzes.api";
import { GetAnalyzesRequest } from "../../../services/docAnalyzes/docAnalyzes.contracts";
import DetailListDocAnalysis from "../detailListDocAnalysis/detailListDocAnalysis";
import { IDashboardDocAnalysisProps } from "./dashboardDocAnalysis.types";
import DeleteAnalysisModal from "../deleteAnalysisModal/deleteAnalysisModal";
import { ErrorsType } from "../../../models/callsApi";
import DocAnalysisStartModal from "../docAnalysisStartModal/docAnalysisStartModal";

export const DashboardDocAnalysisBase = (props: IDashboardDocAnalysisProps) => {
    const callbacks = { onStepChange: noop, onStepEnter: noop, onStepExit: noop, ...props };
    const [carouselContents, setCarouselContents] = useState<carouselItem[][]>();
    const [activePage, setActivePage] = useState(0);
    const [listAnalysisPageNumber, setListAnalysisPageNumber] = useState(0);
    const [listAnalysis, setListAnalysis] = useState<analyzes[]>([])
    const [loading, setLoading] = useState(false);
    const [loadMoreCall, setLoadMoreCall] = useState(false);
    const [noMore, setNoMore] = useState(false);
    const [listLoading, setListLoading] = useState(false);
    const [orderByColumn, setOrderByColumn] = useState({ fieldName: "CreatedOn", ascending: false });
    const [errorMessage, setErrorMessage] = useState("");
    const [analysisTypes] = useState(useDocAnalysis());
    const [analysisTypeId, setAnalysisTypeId] = useState<AnalysisTypeId | undefined>(undefined)
    const currentArchive = useCurrentArchive();
    const dispatch = useDocLabDispatch();
    const { t } = useTranslation(['listDocAnalysis', 'common']);
    const { qCStartModalShown } = useArchiveContent();
    const { deleteDocAnalysisModalShown } = useDocAnalysisContent();

    useOnMount(() => { dispatch(setToolbarActions([])); });

    useEffect(() => {
        if (!currentArchive)
            return;

        getAvailableAnalyzes(currentArchive.id);
        getListAnalysis();
    }, [currentArchive]); //eslint-disable-line react-hooks/exhaustive-deps

    const getListAnalysis = async (loadMore?: boolean) => {
        if (!currentArchive)
            return;

        !loadMore && setListLoading(true);
        setLoadMoreCall(true);
        setErrorMessage("");

        try {
            const request: GetAnalyzesRequest = {
                archiveId: currentArchive.id,
                pageNumber: listAnalysisPageNumber,
                pageSize: Constants.LIST_ANALYSIS,
                orderBy: orderByColumn.fieldName,
                isAscending: orderByColumn.ascending
            }
            const [elements] = await Promise.all([docAnalyzesApi.getAnalyzes(request), Helpers.delay(500)]);
            if (elements.length < Constants.LIST_ANALYSIS) {
                setNoMore(true);
            }
            if (listAnalysisPageNumber === 0) {
                setNoMore(false);
                setListAnalysis(elements);
            }
            else {
                setListAnalysis(listAnalysis.concat(elements));
            }
        }
        catch (er) {
            const error: ErrorsType = er as ErrorsType;
            let errorDescription = t('common:genericErrorApi');
            if (error.code === 403) {
                errorDescription = t('notPermissions');
            }
            setErrorMessage(errorDescription);
        }
        finally {
            !loadMore && setListLoading(false);
            setLoadMoreCall(false);
        }
    }

    const getAvailableAnalyzes = async (archiveId: number) => {
        setLoading(true);
        setErrorMessage("");

        try {
            const allItems: carouselItem[] = [];
            const [elements] = await Promise.all([docAnalyzesApi.getAvailableAnalysisTypes(archiveId), Helpers.delay(500)]);

            elements.forEach((element: AnalysisType) => {
                const analysis = analysisTypes.find(el => el.type === element.typeId);
                if (analysis) {
                    const item: carouselItem = {
                        title: analysis.title,
                        description: analysis.description,
                        type: analysis.type,
                        iconName: analysis.iconName,
                        enabled: element.isActive && (currentArchive?.currentUserRoleId === ArchiveRoleId.Contributor || currentArchive?.currentUserRoleId === ArchiveRoleId.Owner || currentArchive?.currentUserRoleId === ArchiveRoleId.Architect)
                    }
                    allItems.push(item);
                }
            });

            setCarouselContents(setPageCarousel(allItems));
        }
        catch (er) {
            const error: ErrorsType = er as ErrorsType;
            switch (error.code) {
                case 403: setErrorMessage(t('notPermissions')); break;
                case 500: setErrorMessage(t('common:genericErrorApi')); break;
                default: setErrorMessage(t('common:genericErrorApi')); break;
            }
        }
        finally {
            setLoading(false);
        }
    };

    const loadMore = () => {
        const number = listAnalysisPageNumber + 1;
        if (listLoading || noMore || loadMoreCall)
            return;
        setListAnalysisPageNumber(number);
    }
    const onCarouselClick = (item: carouselItem) => {
        setAnalysisTypeId(item.type);
        dispatch(showQCStartModal(true))
    }

    const sortByColumn = (_: React.MouseEvent, column: IColumn) => {
        if (column.fieldName && column.isSorted !== undefined) {
            setListAnalysisPageNumber(0);
            setOrderByColumn({ fieldName: column.fieldName, ascending: !orderByColumn.ascending });
            const listElm = document.querySelector('#docAnalysisDetailsListZone .ms-DetailsList-contentWrapper');
            listElm && listElm.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        getListAnalysis(true);
    }, [listAnalysisPageNumber, orderByColumn]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {deleteDocAnalysisModalShown && <DeleteAnalysisModal onConfirm={() => getListAnalysis(false)} onClose={() => { dispatch(showDocAnalysisDeleteModal(false)) }} />}
            {qCStartModalShown && <DocAnalysisStartModal analysisType={analysisTypeId} onClose={(reload: boolean) => { dispatch(showQCStartModal(false)); setListAnalysisPageNumber(0); setAnalysisTypeId(undefined); reload && getListAnalysis(false) }} />}
            <div style={{ position: 'absolute', right: 0, margin: '15px 25px', fontSize: '18px', cursor: 'pointer' }}>
                <IconButton
                    disabled={listLoading}
                    styles={{ root: { background: 'transparent' } }}
                    style={listLoading ? { animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite` } : {}}
                    iconProps={{ iconName: "Refresh" }}
                    onClick={() => { setListAnalysisPageNumber(0); getListAnalysis() }}
                /></div>
            {loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '250px' }}>
                <Spinner size={SpinnerSize.large} />
            </div>
            }
            {!loading && errorMessage === "" && carouselContents && carouselContents.length > 0 &&

                <Carousel
                    activeStep={activePage}
                    onStepChange={callbacks.onStepChange}
                    onStepExit={callbacks.onStepExit}
                    onClickItem={(item) => onCarouselClick(item)}
                    pageCount={carouselContents.length - 1}
                    next={() => activePage !== carouselContents.length - 1 && setActivePage(activePage + 1)}
                    back={() => activePage !== 0 && setActivePage(activePage - 1)}
                    items={carouselContents[activePage]}
                />
            }
            {!loading && errorMessage &&
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '250px' }}>
                    <TeamsImage imageName={ImageName.Oops1} scale={0.5} fullContainer />
                </div>
            }
            {listLoading ? <div style={{ height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Spinner size={SpinnerSize.large} /></div> : errorMessage === "" && carouselContents !== undefined &&
            <DetailListDocAnalysis
                orderBy={orderByColumn}
                sortByColumn={(_, column) => sortByColumn(_, column)}
                noMore={noMore}
                load={loadMore}
                analyzes={listAnalysis}
                canUserViewResult={currentArchive?.currentUserRoleId === ArchiveRoleId.Contributor || currentArchive?.currentUserRoleId === ArchiveRoleId.Owner || currentArchive?.currentUserRoleId === ArchiveRoleId.Architect}
            />
            }
        </>
    );
}