import { IStyleFunction } from "@fluentui/react"
import { ICalloutNotificationPropsStyles, ICalloutNotificationStyles } from "./calloutNotification.types"

export const CalloutNotificationGlobalClassNames = {
    root: 'edi-callout-notification-root',
    title: 'edi-callout-notification-title',
    message: 'edi-callout-notification-message'
}

export const getStyles: IStyleFunction<ICalloutNotificationPropsStyles, ICalloutNotificationStyles> = (props: ICalloutNotificationPropsStyles): ICalloutNotificationStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            CalloutNotificationGlobalClassNames.root,
            {
                padding: '10px 20px', 
                backgroundColor: palette.neutralLighterAlt,
            }
        ],
        title: [CalloutNotificationGlobalClassNames.title, {
            fontWeight: 'bold',
            fontSize: '14px',
            marginBottom: '10px'
        }],
        message: [CalloutNotificationGlobalClassNames.message, {

        }],
        subComponentStyles: {
            rootMain: {
                calloutMain: {
                    backgroundColor: palette.neutralLighterAlt
                }, 
            }
        }
    };
}