/*eslint-disable sonarjs/cognitive-complexity */

import React, { useState } from "react";
import { classNamesFunction, DefaultButton, IconButton, PrimaryButton, TooltipHost } from "@fluentui/react";
import { ITableOfContentProps, ITableOfContentPropsStyles, ITableOfContentStyles } from "./tableOfContent.types";
import { useTranslation } from "react-i18next";
import TableOfContentItem from "../tableOfContentItem/tableOfContentItem";
import { useOnMount } from "../../../../../../../../utilities/hooks";
import { TocTemplate } from "../../../../../../files/createTemplateModal/createTemplateModal.types";
import { Constants } from "../../../../../../../models/constants";

const getClassNames = classNamesFunction<ITableOfContentPropsStyles, ITableOfContentStyles>();

export const TableOfContentBase = (props: ITableOfContentProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['createTemplate', 'common']);
    const [tree, setTree] = useState<TocTemplate[]>([]);
    const [key, setKey] = useState(0);
    let savedItem: TocTemplate | undefined = undefined;

    useOnMount(() => {
        if (props.retrievedTree) {
            setTree(props.retrievedTree)
        }
        else {
            const newTree: TocTemplate[] = [...tree]
            const firstItem: TocTemplate = {
                id: '1',
                opened: true,
                text: '',
                visible: true,
                children: []
            }
            newTree.push(firstItem);
            setTree(newTree)
        }
    })

    const changeVisibility = (id: string, treeItems?: TocTemplate[]) => {
        const newTree = treeItems ? treeItems : [...tree];

        const ids = id.split('.');
        const position = parseInt(ids[0]) - 1;
        if (ids.length === 1) {
            newTree[position].visible = !newTree[position].visible;
        }
        else {
            const newChild = newTree[position];
            ids.shift();
            const newId = ids.join('.');
            changeVisibility(newId, newChild.children);
        }
        setTree(newTree);
    }

    const changeStatus = (id: string, treeItems?: TocTemplate[]) => {
        const newTree = treeItems ? treeItems : [...tree];

        const ids = id.split('.');
        const position = parseInt(ids[0]) - 1;
        if (ids.length === 1) {
            newTree[position].opened = !newTree[position].opened;
        }
        else {
            const newChild = newTree[position];
            ids.shift();
            const newId = ids.join('.');
            changeStatus(newId, newChild.children);
        }
        setTree(newTree);
    }

    const changeText = (id: string, newText: string, treeItems?: TocTemplate[]) => {
        const newTree = treeItems ? treeItems : [...tree];
        const node = findNode(newTree, id);
        if (node)
            node.text = newText;
        setTree(newTree);
    }

    const addNewSection = () => {
        const newTree = [...tree];

        const reverseTree = [...tree].reverse();
        const lastId = reverseTree.find(item => !item.id.includes('.'))?.id;
        if (lastId) {
            newTree.push({
                id: (parseInt(lastId) + 1).toString(),
                text: '',
                opened: true,
                visible: true,
                children: []
            });
        }
        setTree(newTree);
    }

    let parent: TocTemplate | undefined = undefined;
    const findParent = (id: string, treeItems?: TocTemplate[]): TocTemplate | undefined => {
        const newTree = treeItems ? treeItems : [...tree];
        const ids = id.split('.');
        const position = parseInt(ids[0]) - 1;
        if (ids.length === 1) {
            return parent;
        }
        else {
            const newChild = newTree[position];
            parent = newChild;
            ids.shift();
            const newId = ids.join('.');
            parent = findParent(newId, newChild.children);
        }
        return parent;
    }

    let savedId = '';

    const addNewChild = (id: string, treeItems?: TocTemplate[]) => {
        const newTree = treeItems ? treeItems : [...tree];
        if (treeItems === undefined) {
            id.split('.').pop();
            savedId = id;
        }
        const ids = id.split('.');
        const position = parseInt(ids[0]) - 1;
        if (ids.length === 1) {
            if (newTree[position].children.length === 0) {
                const newTreeItem: TocTemplate = {
                    id: savedId.concat(".1"),
                    text: '',
                    opened: true,
                    visible: true,
                    children: []
                }
                newTree[position].children.push(newTreeItem);
            }
            else {
                const lastId = newTree[position].children[newTree[position].children.length - 1].id.split('.').reverse()[0];
                const newTreeItem: TocTemplate = {
                    id: savedId.concat(".", (parseInt(lastId) + 1).toString()),
                    text: '',
                    visible: true,
                    opened: true,
                    children: []
                }
                newTree[position].children.push(newTreeItem);
            }
        }
        else {
            const newChild = newTree[position];
            ids.shift();
            const newId = ids.join('.');
            addNewChild(newId, newChild.children);
        }
        savedId = '';
        setTree(newTree);
    }

    const changeId = (idToChange: string, toCToChange: TocTemplate): TocTemplate => {
        const newId = idToChange.split('.')
        const splitted = toCToChange.id.split('.');
        newId.forEach((item, ind) => splitted[ind] = item);
        toCToChange.id = splitted.join('.');

        if (toCToChange.children.length > 0) {
            toCToChange.children.map(x => changeId(idToChange, x))
        }
        return toCToChange;
    }

    const changeChildrenId = (tocToChange: TocTemplate, treeItems?: TocTemplate[]): TocTemplate => {

        const newTree = treeItems ? treeItems : tocToChange.children;

        newTree.forEach((item, ind) => {
            item.id = tocToChange.id + "." + (ind + 1);
            if (item.children.length > 0)
                changeChildrenId(item, item.children)
        })

        return tocToChange;

    }
    let father = "";
    const removeItem = (id: string, treeItems?: TocTemplate[]) => {
        let newTree = treeItems ? treeItems : [...tree]
        if (treeItems === undefined) {
            const arrayOfId = [...id.split('.')];
            arrayOfId.pop();
            father = arrayOfId.join('.');
        }
        const ids = id.split('.');
        const position = parseInt(ids[0]) - 1;
        if (ids.length === 1) {

            savedItem = newTree[position];
            const temp = newTree;
            temp.splice(position, 1);
            temp.forEach((item) => {
                if (father === "") {
                    if (savedItem && parseInt(item.id) > parseInt(savedItem.id)) {
                        item.id = (parseInt(item.id) - 1).toString();
                        item = changeChildrenId(item);
                    }
                }
                else {
                    if (savedItem && item.id > savedItem.id) {
                        const splittedParentId = item.id.split('.');
                        const lastNumber = splittedParentId[splittedParentId.length - 1];
                        splittedParentId.pop();
                        item.id = splittedParentId.join('.').concat('.', (parseInt(lastNumber) - 1).toString());
                        item = changeChildrenId(item);
                    }
                }
            });
            newTree = temp;
        }
        else {
            const newChild = newTree[position];
            ids.shift();
            const newId = ids.join('.');
            removeItem(newId, newChild.children);
        }
        savedItem=undefined;
        father = ""
        setTree(newTree);
        setKey(key + 1);
    }

    const findNode = (root: TocTemplate[], id: string): TocTemplate | undefined => {
        let node: TocTemplate | undefined = undefined;

        root.some(function (n) { //eslint-disable-line array-callback-return
            if (n.id === id) {
                return node = n;
            }
            if (n.children) {
                return node = findNode(n.children, id);
            }
        });
        return node || undefined;
    }


    const onMoveRight = (id: string) => {
        const newTree = [...tree];
        const ids = id.split('.');

        const parentNode = findParent(id);

        const rootParent = ids.length === 1 ? undefined : parentNode?.children;
        const subTree = getRightSubTree(id, rootParent, ids.length)

        if (parentNode) parentNode.children = subTree;

        if (ids.length !== 1) {
            const node = newTree.find(item => item.id === parentNode?.id);

            if (node) {
                const getRecursiveTree = (recursiveTree: TocTemplate[]) => {
                    const arr = recursiveTree ? recursiveTree : newTree;
                    arr.forEach(item => {
                        if (parentNode && item.id === parentNode.id) {
                            item = node;
                        }
                        else {
                            getRecursiveTree(item.children); //eslint-disable-line @typescript-eslint/no-unused-vars
                        }

                    })
                }
            }
            setTree(newTree);
        }
        else
            setTree(subTree)
        setKey(key + 1);

    }

      const getRightSubTree = (id: string, treeItems?: TocTemplate[], level?: number) => {
        let newTree = treeItems ? treeItems : [...tree];
        const currentNode = findNode(newTree, id);

        if (currentNode) {
            let brotherId = ""
            newTree = newTree.filter(item => item.id !== currentNode.id);
            if (level === 1) {
                brotherId = (parseInt(currentNode.id) - 1).toString();
            }
            else {
                const splittedCurrentId = currentNode.id.split('.');
                const lastNumber = splittedCurrentId[splittedCurrentId.length - 1];
                splittedCurrentId.pop();
                brotherId = splittedCurrentId.join('.').concat('.', (parseInt(lastNumber) - 1).toString())
            }
            let newFather = newTree.find(item => item.id === brotherId);

            if (newFather) {
                newFather.children.push(currentNode);
                newFather = changeChildrenId(newFather);
            }

            newTree.forEach((item) => {
                if (level === 1) {
                    if (newFather && parseInt(item.id) > parseInt(newFather.id)) {
                        item.id = (parseInt(item.id) - 1).toString();
                        item = changeChildrenId(item);
                    }
                }
                else {
                    if (newFather && item.id > newFather.id) {
                        const splittedParentId = item.id.split('.');
                        const lastNumber = splittedParentId[splittedParentId.length - 1];
                        splittedParentId.pop();
                        item.id = splittedParentId.join('.').concat('.', (parseInt(lastNumber) - 1).toString());
                        item = changeChildrenId(item);
                    }
                }
            });
        }
        return newTree;
    }

    //declassamento come era prima della modifica del 23/09
    // const getRightSubTree = (id: string, treeItems?: TocTemplate[], level?: number) => {
    //     let newTree = treeItems ? treeItems : [...tree];
    //     const currentNode = findNode(newTree, id);

    //     if (currentNode) {
    //         let brotherId = ""
    //         newTree = newTree.filter(item => item.id !== currentNode.id);
    //         if (level === 1) {
    //             brotherId = (parseInt(currentNode.id) + 1).toString();
    //         }
    //         else {
    //             const splittedCurrentId = currentNode.id.split('.');
    //             const lastNumber = splittedCurrentId[splittedCurrentId.length - 1];
    //             splittedCurrentId.pop();
    //             brotherId = splittedCurrentId.join('.').concat('.', (parseInt(lastNumber) + 1).toString())
    //         }
    //         let newFather = newTree.find(item => item.id === brotherId);

    //         if (newFather) {
    //             newFather.children.unshift(currentNode);
    //             newFather.id = currentNode.id;
    //             newFather = changeChildrenId(newFather);
    //         }

    //         newTree.forEach((item) => {
    //             if (level === 1) {
    //                 if (newFather && item.id > newFather.id) {
    //                     item.id = (parseInt(item.id) - 1).toString();
    //                     item = changeChildrenId(item);
    //                 }
    //             }
    //             else {
    //                 if (newFather && item.id > newFather.id) {
    //                     const splittedParentId = item.id.split('.');
    //                     const lastNumber = splittedParentId[splittedParentId.length - 1];
    //                     splittedParentId.pop();
    //                     item.id = splittedParentId.join('.').concat('.', (parseInt(lastNumber) - 1).toString());
    //                     item = changeChildrenId(item);
    //                 }
    //             }
    //         });
    //     }
    //     return newTree;
    // }
    const onMoveLeft = (id: string) => {
        const newTree = [...tree];

        const ids = id.split('.');

        const parentNode = findParent(id);
        let root: TocTemplate | undefined = undefined;

        if (parentNode)
            root = findParent(parentNode.id)
        if (!root) {
            root = parentNode
        }
        let rootParent: TocTemplate | undefined = undefined;
        const treeToEdit = root?.id === parent?.id && ids.length === 2 ? undefined : root?.children;
        const subTree = getSubTree(id, true, treeToEdit, ids.length);

        let breakCondition = false;
        do {
            let result = undefined;
            if (rootParent) {
                result = findParent(rootParent.id)
            }
            else if (root) {
                result = findParent(root.id)
            }

            if (result !== undefined && result !== rootParent) {
                rootParent = result;
                breakCondition = true;
            }
            else {
                breakCondition = false;
            }

        }
        while (breakCondition);

        if (ids.length !== 2) {
            const node = newTree.find(item => item.id === rootParent?.id);

            const getRecursiveTree = (recursiveTree: TocTemplate[]) => {
                const arr = recursiveTree ? recursiveTree : node ? node.children : [];
                arr.forEach(item => {
                    if (root && item.id === root.id) {
                        item.children = subTree;
                    }
                    else {
                        getRecursiveTree(item.children); //eslint-disable-line @typescript-eslint/no-unused-vars
                    }

                })
            }
            if (node) newTree[parseInt(node.id) - 1] = node;
            setTree(newTree);
        }
        else
            setTree(subTree);
    }

    let currentNode: TocTemplate | undefined = undefined;
    const getSubTree = (id: string, isLeft: boolean, treeItems?: TocTemplate[], level?: number) => {
        let newTree = treeItems ? treeItems : [...tree];
        currentNode = findNode(newTree, id);
        if (currentNode) {
            const parentNode = findParent(currentNode.id);
            removeItem(currentNode.id);

            if (level === 2) {
                currentNode.id = parentNode ? (parseInt(parentNode.id) + 1).toString() : '';
            }
            else {
                const splittedParentId = parentNode ? parentNode.id.split('.') : [];
                const lastNumber = splittedParentId[splittedParentId.length - 1];
                splittedParentId.pop();

                currentNode.id = splittedParentId.join('.').concat('.', (parseInt(lastNumber) + 1).toString());
            }

            currentNode = changeChildrenId(currentNode);

            const newObject: TocTemplate = {
                id: currentNode.id,
                visible: currentNode.visible,
                opened: currentNode.opened,
                text: currentNode.text,
                children: currentNode.children.length > 0 ? currentNode.children : []
            }
            const tempTree = newTree;
            tempTree.forEach((item) => {
                if (level === 2) {
                    if (parseInt(item.id) >= parseInt(newObject.id))
                        item.id = (parseInt(item.id) + 1).toString();
                    item = changeChildrenId(item);
                }
                else {
                    if (item.id >= newObject.id) {
                        const splittedParentId = item.id.split('.');
                        const lastNumber = splittedParentId[splittedParentId.length - 1];
                        splittedParentId.pop();
                        item.id = splittedParentId.join('.').concat('.', (parseInt(lastNumber) + 1).toString());
                        item = changeChildrenId(item);
                    }
                }
            })
            if (level === 2) {
                tempTree.splice(parseInt(newObject.id) - 1, 0, newObject);
            }
            else {
                const splittedParentId = newObject.id.split('.');
                const lastNumber = splittedParentId[splittedParentId.length - 1];
                tempTree.splice(parseInt(lastNumber) - 1, 0, newObject);
            }
            newTree = tempTree;
        }
        return newTree;
    }

    const onMoveUpDown = (id: string, isUp: boolean, treeItems?: TocTemplate[]) => {
        const newTree = treeItems ? treeItems : [...tree];
        const ids = id.split('.');
        const position = parseInt(ids[0]) - 1;
        if (ids.length === 1) {
            savedItem = newTree[position];
            const newId = savedItem.id;
            let toChange = { ...newTree[isUp ? position - 1 : position + 1] };
            savedItem = changeId(toChange.id, savedItem);
            toChange = changeId(newId, toChange);
            newTree[isUp ? position - 1 : position + 1] = savedItem;
            newTree[position] = toChange;
        }
        else {

            const newChild = newTree[position];
            ids.shift();
            const newId = ids.join('.');
            onMoveUpDown(newId, isUp, newChild.children);
        }
        savedItem = undefined;
        setKey(key + 1);
        setTree(newTree);
    }


    const upDisabled = (id: string): boolean => {
        return id.split('.')[id.split('.').length - 1] === "1"
    }

    const downDisabled = (id: string, treeItems?: TocTemplate[]): boolean => {
        const newTree = treeItems ? treeItems : [...tree];
        const ids = id.split('.');
        const position = parseInt(ids[0]) - 1;
        let result = false;
        if (ids.length === 1) {
            result = newTree[position].id === newTree[newTree.length - 1].id;
            return result
        }
        else {
            const newChild = newTree[position];
            ids.shift();
            const newId = ids.join('.');
            result = downDisabled(newId, newChild.children);
        }
        return result
    }

    const leftDisabled = (id: string) => {
        return id.split('.').length === 1;
    }

    let originalId = ''
    const deleteDisabled = (id: string, treeItems?: TocTemplate[]): boolean => {
        const newTree = treeItems ? treeItems : [...tree];
        if (!treeItems) {
            originalId = id;
        }
        const ids = id.split('.');
        const position = parseInt(ids[0]) - 1;
        let result = false;
        if (ids.length === 1) {
            result = newTree.length === 1 && originalId.split('.').length === 1;
            return result;
        }
        else {
            const newChild = newTree[position];
            ids.shift();
            const newId = ids.join('.');
            result = deleteDisabled(newId, newChild.children);
        }
        originalId = '';
        return result;
    }

    const rightDisabled = (id: string, treeItems?: TocTemplate[]): boolean => {
        return id.split('.')[id.split('.').length - 1] === "1" || id.split('.').length === Constants.TOC_MAX_DEPTH;
    }

    //prima della modifica del 23/09
    // const rightDisabled = (id: string, treeItems?: TocTemplate[]): boolean => {
    //     const newTree = treeItems ? treeItems : [...tree];
    //     const ids = id.split('.');
    //     const position = parseInt(ids[0]) - 1;
    //     let result = false;
    //     if (ids.length === 1) {
    //         result = newTree.length === 1 || newTree[position + 1] === undefined;
    //         return result
    //     }
    //     else {
    //         const newChild = newTree[position];
    //         ids.shift();
    //         const newId = ids.join('.');
    //         result = rightDisabled(newId, newChild.children);
    //     }
    //     return result
    // }
    const addDisabled = (id: string) => id.split('.').length === Constants.TOC_MAX_DEPTH;
    

    const getTree = (treeItems?: TocTemplate[]): JSX.Element => {
        const items = treeItems ? treeItems : [...tree];
        return (

            <div className={classNames.scrollableContainer}>

                {items.map((item) => {
                    return (
                        <div key={item.id + key}>
                            <TableOfContentItem
                                onChangeText={(id: string, newText: string) => changeText(id, newText)}
                                onAddClick={(id: string) => addNewChild(id)}
                                onMoveUpDown={(id: string, isUp: boolean) => onMoveUpDown(id, isUp)}
                                onMoveLeft={(id: string) => onMoveLeft(id)}
                                onMoveRight={(id: string) => onMoveRight(id)}
                                key={item.id}
                                id={item.id}
                                readonly={props.readonly}
                                changeVisibility={(id) => changeVisibility(id)}
                                visible={props.readonly ? false : item.visible}
                                changeOpen={(id) => changeStatus(id)}
                                onDeleteItem={(id) => removeItem(id)}
                                opened={item.opened}
                                hasChildren={item.children.length > 0}
                                disabled={[
                                    addDisabled(item.id),
                                    leftDisabled(item.id),
                                    rightDisabled(item.id),
                                    upDisabled(item.id),
                                    downDisabled(item.id),
                                    deleteDisabled(item.id)
                                ]}
                                text={item.text} />

                            <div id={'children_' + item.id}>
                                {item.children.length > 0 && item.opened && getTree(item.children)}
                            </div>
                        </div>
                    )
                }
                )}
            </div>

        )
    }

    const footer = (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' ,textAlign: 'left', width: '100%' }}>
            <span className={classNames.requiredTextLeft}>* {t('common:fieldRequired')}</span>
        
            <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center', alignContent: 'center', width: '70%' }}>
                <div style={{ marginTop: "20px", display: 'flex', justifyContent: 'flex-end' }}>
                    <DefaultButton
                        style={{ marginRight: 8 }}
                        text={t("common:leave")}
                        disabled={props.loadingState}
                        onClick={() => props.reject(undefined)}
                    />
                    <PrimaryButton
                        text={t('common:save')}
                        disabled={props.disableButton || props.readonly}
                        onClick={() => props.accept(true, tree)}
                    />
                </div>
            </div>
        </div>
    );

    return (<>
        <div className={classNames.root}>
            {getTree()}
            {!props.readonly && <div style={{ padding: '20px 0 0 20px' }}>
                <TooltipHost content={t('addSection')}>
                    <IconButton
                        iconProps={{ iconName: 'Add' }}
                        className={classNames.AddSection}
                        styles={{root: { 
                            borderRadius: '100%' 
                          }}}
                        onClick={() => addNewSection()}
                    />
                </TooltipHost>
            </div>}
        </div>
        {footer}
    </>);
}
