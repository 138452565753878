import { styled } from "@fluentui/react";
import { getStyles } from "./workFlowList.styles";
import { IWorkFlowListPropsStyles, IWorkFlowListStyles, IWorkFlowListProps } from "./workFlowList.types";
import { WorkFlowListBase } from "./workFlowList.base";

const WorkFlowList = styled<IWorkFlowListProps, IWorkFlowListPropsStyles, IWorkFlowListStyles>(
    WorkFlowListBase,
    getStyles,
    undefined,
    { scope: 'WorkFlowList' }
);

export default WorkFlowList;