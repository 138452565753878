import { styled } from "@fluentui/react";
import { IManageTopicViewProps, IManageTopicViewPropsStyles, IManageTopicViewStyles } from "./manageTopicView.types";
import { getStyles } from "./manageTopicView.styles";
import { ManageTopicViewBase } from "./manageTopicView.base";

const ManageTopicView = styled<IManageTopicViewProps, IManageTopicViewPropsStyles, IManageTopicViewStyles>(
    ManageTopicViewBase, 
    getStyles,
    undefined,
    { scope: 'ManageTopicView'}
)

export default ManageTopicView;