import { DocLabDispatch, DocLabState } from "../docLabStore";
import { showCopyLinkModal, showTagPanel } from "../features/archiveContent";
import { showDeleteModal } from "../features/archiveContent";
import { INavigator } from "../hooks/useNavigator";
import { Archive } from "../models/archive";
import { ArchiveRoleId, ArchiveTypeId, ItemToCopy } from "../models/constants";
import { SelectionScopes } from "./archiveContentActions";
import { Helpers } from "../../utilities/helpers";
import { startDownload } from "../features/downloadStatus";
import { nanoid } from "@reduxjs/toolkit";
import { archivesApi } from "../services/archives/archives.api";
import { Section } from "../../common/components/tagPanel/tagPanel.types";

export type SearchEntry = {
    id: string;
    scopes: SelectionScopes;
    archiveRoles: ArchiveRoleId[];
    archiveTypes: ArchiveTypeId[];
    label: string;
    icon?: string;
    subActionItems?: SearchEntry[];
    onClick: (state: DocLabState, archive: Archive, navigator: INavigator, dispatch: DocLabDispatch, id?: number) => void;
}

const actions: SearchEntry[] = [
    {
        id: "open-file",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Search, ArchiveTypeId.SearchAndCompare],
        label: 'commands.open',
        onClick: (_, __, ___, dispatch, ____) => {

            if (____ === undefined)
                return;
            Helpers.getPreviewFile(____);
        }
    },
    {
        id: "view-tag",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Search],
        label: 'commands.viewTag',
        onClick: (_, __, ___, dispatch) => dispatch(showTagPanel({isOpen: true, defaultSection: Section.tag}))
    },
    {
        id: "create-link",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Search, ArchiveTypeId.SearchAndCompare],
        label: 'commands.createLink',
        onClick: (_, __, ___, dispatch) => dispatch(showCopyLinkModal({showModal: true, type: ItemToCopy.DeepLink, generateBeLink: true}))
    },
    {
        id: "copy-id-document",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Search, ArchiveTypeId.SearchAndCompare],
        label: 'commands.copyDocumentId',
        onClick:  (_, __, ___, dispatch) => dispatch(showCopyLinkModal({showModal: true, type: ItemToCopy.DocumentId}))
    },
    {
        id: "download-file",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Search],
        label: 'commands.download',
        onClick: (state, _, __, dispatch, id) => {
            dispatch(showDeleteModal({show: true, type: 'DOWNLOAD_FILES'}));
        }
    },
    {
        id: "delete",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'commands.delete',
        onClick: (_, __, ___, dispatch) => dispatch(showDeleteModal({show:true, type: 'DELETE_ITEM'})),
    },
    {
        id: "download-analysis-file",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.SearchAndCompare],
        label: 'commands.download',
        onClick: (state, _, __, dispatch, id) => {
            const { selectedDocAnalysisResults } = state.docAnalysisContent;

            const file = selectedDocAnalysisResults?.find(item => item.Document.FileRef === id);
            if (file)
                dispatch(startDownload({ requestId: nanoid(), file: { id: file.Document.FileRef, name: file.Document.Title }, downloadFunction: archivesApi.downloadFile }));
        }
    }
];

export const getSearchActions = (archiveTypeId: ArchiveTypeId, archiveRoleId: ArchiveRoleId, isCrossArchiveSearch?: boolean) => {

    return actions.filter(entry =>
        entry.archiveTypes.includes(archiveTypeId) &&
        entry.archiveRoles.includes(archiveRoleId));

    // if(isCrossArchiveSearch){
    //     results = results.filter(r =>  r.id !== "view-tag")
    // } 
    // return results;
}