
import React, { useCallback, useState } from "react";
import { ActionButton, classNamesFunction, CommandBar, ICommandBarItemProps, IContextualMenuItem, Pivot, PivotItem } from "@fluentui/react";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { filePreviewSubPath } from "../../../../utilities/constants";
import { Helpers } from "../../../../utilities/helpers";
import { startDownload } from "../../../features/downloadStatus";
import { removeFromCompare, setCompareModalOpen, setDetailsModalOpen, setForceDocumentReload, setSelectedElement, useKnowledgeLabContent } from "../../../features/knowledgeLabContent";
import { useKnowledgeLabList } from "../../../features/knowledgeLabList";
import { useNavigator } from "../../../hooks/useNavigator";
import { useKnowledgeLabSelector } from "../../../knowledgeLabStore";
import { KnowledgeLabRoleId } from "../../../models/knowledgeLabRoleId";
import { KnowledgeTab } from "../../../routes/routes";
import { knowledgeLabApi } from "../../../services/knowledgeLab.api";
import { KnowledgeHelpers } from "../../../utilities/helpers";
import { IToolbarProps, IToolbarPropsStyles, IToolbarStyles } from "./toolbar.types";


import DeleteKnowledgeDocumentModal from "../../deleteKnowledgeDocumentModal/deleteKnowledgeDocumentModal";
import DocumentTagPanel from "../../documentTagPanel/documentTagPanel";
import KnowledgeLabImportDocumentModal from "../importDocumentModal/importDocumentModal";
import KnowledgeDetailsModal from "../knowledgeDetailsModal/knowledgeDetailsModal";
import CompareDocumentsModal from "../compareDocumentsModal/compareDocumentsModal";


const getClassNames = classNamesFunction<IToolbarPropsStyles, IToolbarStyles>();

export const ToolbarBase = (props: IToolbarProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t, i18n } = useTranslation('knowledgeToolbar');
    const { selectedKnowledgeLab } = useKnowledgeLabSelector(state => state.knowledgeLabList);
    const navigator = useNavigator();
    const knowledgelab = useKnowledgeLabList();
    const knowledgeLabContentState = useKnowledgeLabContent();
    const [viewDocumentId, setViewDocumentId] = useState<number | undefined>();
    const dispatch = useDispatch();
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [importDocumentIsOpen, setImportDocumentIsOpen] = useState(false);

    const currentLang = (i18n.language).split("-")[0];
    const currentTranslations = selectedKnowledgeLab?.configurations?.translations;
    const entitiesName = KnowledgeHelpers.getKnowledgeTranslation("elementsPlaceHolder", currentTranslations, currentLang);
    const [selection, setSelection] = React.useState<{ [key: string]: boolean }>({});

    const isCompareEnabled = selectedKnowledgeLab?.configurations?.compareElements?.enabled ?? false;

    const rightTabs = useCallback(() => {
        return (
            <div style={{ height: '40px' }}>
                <Pivot
                    headersOnly={true}
                    styles={classNames.subComponentStyles.pivot}
                    selectedKey={navigator.currentKnowledgeLabTab ?? null}
                    onLinkClick={item => item?.props.itemKey && navigator.currentKnowledgeLabTab !== item.props.itemKey &&
                        navigator.changeKnowledgeLabTab(item.props.itemKey as KnowledgeTab)
                    }
                    style={{ height: '100%' }}
                >
                    <PivotItem key={KnowledgeTab.search} itemKey={KnowledgeTab.search} headerText={t('search')} style={{ height: '40px' }} />
                    <PivotItem key={KnowledgeTab.favorites} itemKey={KnowledgeTab.favorites} headerText={t('favorites')} />
                    {(
                        knowledgelab.currentKnowledgeLabRoleId === KnowledgeLabRoleId.Owner ||
                        knowledgelab.currentKnowledgeLabRoleId === KnowledgeLabRoleId.Contributor
                    )
                        && <PivotItem key={KnowledgeTab.documents} itemKey={KnowledgeTab.documents} headerText={t('documents')} />}
                </Pivot >
            </div>
        );
    }, [classNames.subComponentStyles.pivot, t, navigator]); //eslint-disable-line react-hooks/exhaustive-deps

    const collapseAction: ICommandBarItemProps = {
        key: "menu",
        iconProps: { iconName: "CollapseMenu" },
        onClick: props.onMenuButtonClick
    }

    const onToggleSelect = React.useCallback(
        (ev?: React.MouseEvent<HTMLButtonElement>, item?: IContextualMenuItem): void => {
            ev && ev.preventDefault();

            if (item) {
                setSelection({ ...selection, [item.key]: selection[item.key] === undefined ? true : !selection[item.key] });
            }
        },
        [selection],
    );

    const compareText = `${t('actions.compareElements')} (${knowledgeLabContentState.elementsToCompares.length})`;

    const renderCompareHeader = React.useCallback((item: IContextualMenuItem): JSX.Element => {
        return (
            <div className={classNames.compareHeader}>
                <span>{item.text}</span>
                <ActionButton
                    onClick={() => {
                        dispatch(setCompareModalOpen(true));
                    }}

                    disabled={Object.keys(selection).length === 0 || (isCompareEnabled && Object.keys(selection).filter(k => selection[k]).length !== (selectedKnowledgeLab?.configurations?.compareElements?.elementsToCompare ?? -1))}
                >
                    <span style={{ textDecoration: 'underline' }}>{t('actions.compare')}</span>
                </ActionButton>
            </div>
        );
    }, [selection]) //eslint-disable-line react-hooks/exhaustive-deps

    const getCompareItems = () => {
        const items: IContextualMenuItem[] = [
            {
                key: "compare",
                text: compareText,
                onRender: renderCompareHeader,
                className: "compare-action-header"
            }
        ]

        knowledgeLabContentState.elementsToCompares.forEach((el) => (
            items.push({
                key: el.id.toString(),
                text: el.title,
                title: el.title,
                canCheck: true,
                isChecked: selection[el.id.toString()],
                onClick: onToggleSelect,
                iconProps: {
                    iconName: selection[el.id.toString()] ? 'Delete' : '',
                    onClick: (i) => {
                        dispatch(removeFromCompare(el));
                        setSelection({ ...selection, [el.id.toString()]: selection[el.id.toString()] === undefined ? true : !selection[el.id.toString()] });
                    }
                },
                className: classNames.compareDeleteIcon
            } as IContextualMenuItem)));

        return items;
    }

    const compareAction: ICommandBarItemProps = {
        key: "compare",
        text: compareText,
        iconProps: { iconName: "DocumentSearch" },
        disabled: !(isCompareEnabled && knowledgeLabContentState.elementsToCompares.length > 0),
        subMenuProps: {
            items: getCompareItems()
        }
    }

    const importFileAction: ICommandBarItemProps = {
        key: "addfile",
        text: t('actions.importDocument'),
        iconProps: { iconName: "Add" },
        onClick: () => setImportDocumentIsOpen(true)
    }

    const deleteAction: ICommandBarItemProps = {
        key: "delete",
        iconProps: { iconName: "Delete" },
        text: t('actions.delete'),
        onClick: () => setDeleteModalOpen(true)
    }
    const viewTagsAction: ICommandBarItemProps = {
        key: "viewTags",
        iconProps: { iconName: "View" },
        text: `${t('actions.viewTags')} & ${entitiesName}`,
        onClick: () => {
            if (knowledgelab.selectedDocuments) {
                const file = knowledgelab.selectedDocuments[0];
                setViewDocumentId(file.id)
            }
        }
    }

    const downloadAction: ICommandBarItemProps = {
        key: "download",
        iconProps: { iconName: "Download" },
        text: t('actions.download'),
        onClick: () => {
            if (knowledgelab.selectedDocuments) {
                const file = knowledgelab.selectedDocuments[0];
                dispatch(startDownload({ requestId: nanoid(), file: { id: file.id, name: file.name }, downloadFunction: knowledgeLabApi.downloadFile }));
            }
        }
    }

    const viewAction: ICommandBarItemProps = {
        key: "view",
        text: t('actions.view'),
        iconProps: { iconName: "Search" },
        onClick: () => {
            if (knowledgelab.selectedDocuments) {
                const file = knowledgelab.selectedDocuments[0];
                Helpers.getKnowledgeLabPreviewFile(file.id, filePreviewSubPath.knowledge);
            }
        }
    }

    const actions = () => {
        const acts = [] as ICommandBarItemProps[];

        acts.push(collapseAction);

        if (knowledgelab.currentKnowledgeLabRoleId === KnowledgeLabRoleId.Owner ||
            knowledgelab.currentKnowledgeLabRoleId === KnowledgeLabRoleId.Contributor)
            acts.push(importFileAction);

        if (isCompareEnabled && knowledgeLabContentState.elementsToCompares.length > 0 &&
            navigator.currentKnowledgeLabTab === KnowledgeTab.search)
            acts.push(compareAction);

        if (navigator.currentKnowledgeLabTab !== KnowledgeTab.documents)
            return acts;
        if (knowledgelab.selectedDocuments && knowledgelab.selectedDocuments.length === 1) {
            acts.push(viewAction);
            acts.push(downloadAction);
            acts.push(viewTagsAction);
        }

        if (knowledgelab.selectedDocuments && knowledgelab.selectedDocuments.length > 0)
            acts.push(deleteAction);

        return acts;
    }

    const infoTab = () => (

        //TODO
        // <>
        //     <Route path={`${KnowledgeLabRoutes.knowledgeLab}/${KnowledgeTab.documents}`}>
        //         <IconButton
        //             styles={classNames.subComponentStyles.reloadIconButton()}
        //             style={knowledgelab.isLoading ? { animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite` } : {}}
        //             disabled={knowledgelab.isLoading}
        //             iconProps={{ iconName: "Refresh" }}
        //             onClick={() => dispatch(setForceDocumentReload(true))}
        //         />
        //     </Route>
        // </>

        <></>

    )

    return (
        <div className={classNames.root}>
            <CommandBar
                className={classNames.wrapper}
                items={actions()}
                farItems={[{ key: "info", commandBarButtonAs: infoTab }, { key: "right-tabs", commandBarButtonAs: rightTabs }]}
            >
            </CommandBar>
            <KnowledgeDetailsModal
                title="Clausola"
                isOpen={knowledgeLabContentState.isDetailsModalOpen}
                onCloseClick={() => {
                    dispatch(setDetailsModalOpen(false));
                    dispatch(setSelectedElement(undefined));
                }} />

            {<DeleteKnowledgeDocumentModal
                isOpen={deleteModalOpen}
                documents={knowledgelab.selectedDocuments ?? []}
                onComplete={() => {
                    dispatch(setForceDocumentReload(true))
                }}
                onClose={() => {
                    setDeleteModalOpen(false);
                }}
            />}

            <CompareDocumentsModal
                isOpen={knowledgeLabContentState.isCompareModalOpen}
                documentsIds={Object.keys(selection).filter(k => selection[k])}
                onCloseClick={() => dispatch(setCompareModalOpen(false))}
            />

            {viewDocumentId && <DocumentTagPanel
                fileId={viewDocumentId ?? 0}
                dismissPanel={() => {
                    if (!knowledgeLabContentState.isDetailsModalOpen)
                        setViewDocumentId(undefined);
                }
                }
            />}
            {
                <KnowledgeLabImportDocumentModal onClose={() => setImportDocumentIsOpen(false)} isOpen={importDocumentIsOpen}></KnowledgeLabImportDocumentModal>
            }
        </div>
    );
}