import { styled } from "@fluentui/react";
import { IDocVerifyLoadingPageProps, IDocVerifyLoadingPagePropsStyles, IDocVerifyLoadingPageStyles } from "./docVerifyLoadingPage.types";
import { getStyles } from "./docVerifyLoadingPage.styles";
import { DocVerifyLoadingPageBase } from "./docVerifyLoadingPage.base";

const DocVerifyLoadingPage = styled<IDocVerifyLoadingPageProps, IDocVerifyLoadingPagePropsStyles, IDocVerifyLoadingPageStyles>(
    DocVerifyLoadingPageBase,
    getStyles,
    undefined,
    { scope: 'DocVerifyLoadingPage' }
);

export default DocVerifyLoadingPage;