import { styled } from "@fluentui/react";
import { getStyles } from "./dashboardMembersSettings.styles";
import { DashboardMembersSettingsBase } from "./dashboardMembersSettings.base";
import { IDashboardMembersSettingsProps, IDashboardMembersSettingsPropsStyles, IDashboardMembersSettingsStyles } from "./dashboardMembersSettings.types";

const DashboardMembersSettings = styled<IDashboardMembersSettingsProps, IDashboardMembersSettingsPropsStyles, IDashboardMembersSettingsStyles>(
    DashboardMembersSettingsBase,
    getStyles,
    undefined,
    { scope: 'DashboardMembersSettings' }
);

export default DashboardMembersSettings;