import React from "react";
import { classNamesFunction, Icon, IconButton, Pivot, PivotItem, PrimaryButton } from "@fluentui/react";
import { IResultsPropsStyles, IResultsStyles, IResultsProps } from "./results.types";
import { useTranslation } from "react-i18next";
import { Helpers } from "../../../../utilities/helpers";
import { useNavigator } from "../../../hooks/useNavigator";
import { ArchiveTab } from "../../../utilities/routes";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { useArchiveList } from "../../../features/archiveList";
/*eslint-disable sonarjs/cognitive-complexity */

const getClassNames = classNamesFunction<IResultsPropsStyles, IResultsStyles>();

export const ResultsBase = (props: IResultsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['common', 'docAnalysis', 'archiveContentActions', 'analysisResults']);
    const navigator = useNavigator();
    const currentArchive = useCurrentArchive();
    const { archiveIds} = useArchiveList();

    const changeDoc = (pivotItem?: PivotItem) => {
        if (pivotItem && pivotItem.props.itemKey) {
            props.changeDocument(+pivotItem.props.itemKey);
        }
    }

    const renderButtonAction = () => {
        return (
            <div className={classNames.buttonsContainer}>
                {/* <DefaultButton>{t('abort')}</DefaultButton>
                <DefaultButton style={{ margin: '0 10px' }}>{t('qualityCheckResult:saveReport')}</DefaultButton> */}
                <PrimaryButton onClick={() => currentArchive ? navigator.changeArchiveTab(navigator.currentArchiveTab as ArchiveTab) : navigator.changeArchive(archiveIds[0], ArchiveTab.files)} style={{ marginRight: 20 }}>{t('close')}</PrimaryButton>
            </div>
        )
    }

    const renderPivot = () => {
        if (!props.docs)
            return;

        return (
            props.isLoading ? <div style={{ height: '44px' }}></div> :
                <Pivot styles={classNames.subComponentStyles.pivotContainer} onLinkClick={changeDoc} >
                    {props.docs.map((item, index) => {
                        return (
                            <PivotItem
                                key={index}
                                itemKey={index.toString()}
                                headerButtonProps={{
                                    disabled: item.error?.code !== undefined || item.warning?.code !== undefined
                                }}
                                headerText={item.fileName}
                                onRenderItemLink={() =>
                                    <>
                                        <span style={{ marginRight: 2, cursor: item.error?.code === undefined && item.warning?.code === undefined ? 'pointer' : 'no-drop' }}>{item.fileName}</span>

                                        {item.fileId >= 0 && <IconButton
                                            style={{ padding: 0 }}
                                            menuProps={{
                                                items: [
                                                    {
                                                        key: 'openInNewWindow',
                                                        text: t('archiveContentActions:openInBrowser'),
                                                        iconProps: { iconName: 'openInNewWindow' },
                                                        onClick: () => Helpers.getPreviewFile(item.fileId)
                                                    }
                                                ],
                                            }}
                                        />}
                                    </>
                                }
                            />
                        )
                    })}
                </Pivot>)
    }

    return (
        <>
            <div className={classNames.root}>
                <>
                    <div className={classNames.descriptionContainer}>
                        <div className={classNames.titleContainer}>
                            <Icon iconName={props.iconName} className={classNames.titleIcon} />
                            <div>
                                <span className={classNames.title}>
                                    {props.sectionTitle}
                                </span>
                                {props.apiInput && props.apiInput.Name ? <span className={classNames.subtitle}>  {`${t('analysisResults:subtitle')}: ${props.apiInput.Name}`} </span> : <></>}
                            </div>
                        </div>
                        {renderButtonAction()}
                    </div>
                    {renderPivot()}
                    <div className={props.docs ? classNames.container : classNames.containerNoDocs}>{props.content}</div>
                </>
            </div>

        </>
    );
}