import { styled } from "@fluentui/react";
import { getStyles } from "./deleteKnowledgeDocumentModal.styles";
import { IDeleteKnowledgeDocumentModalPropsStyles, IDeleteKnowledgeDocumentModalStyles, IDeleteKnowledgeDocumentModalProps } from "./deleteKnowledgeDocumentModal.types";
import { DeleteKnowledgeDocumentModalBase } from "./deleteKnowledgeDocumentModal.base";

const DeleteKnowledgeDocumentModal = styled<IDeleteKnowledgeDocumentModalProps, IDeleteKnowledgeDocumentModalPropsStyles, IDeleteKnowledgeDocumentModalStyles>(
    DeleteKnowledgeDocumentModalBase,
    getStyles,
    undefined,
    { scope: 'DeleteKnowledgeDocumentModal' }
);

export default DeleteKnowledgeDocumentModal;