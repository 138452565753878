import { CancelToken } from "axios";
import qs from "qs";
import { apiClient } from "../../../modules/apiClient/apiClient";
import { svcPaths } from "../../../modules/apiClient/config";
import { PortfolioRoleId } from "../../models/constants";
import { Portfolio } from "../../models/portfolio";
import { PortfolioMembership } from "../../models/portfolioMembership";
import { User } from "../../models/user";
import { CreatePortfolioRequest, GetPortfolioAvailableMembersRequest, GetPortfolioMembersRequest, UpdateTemplateActivitiesForPortfolioMembersRequest } from "./portfolio.contracts";

class PortfolioApiClass {

    async getPortfolio(portfolioId: number, cancelToken?: CancelToken) {
        const response = await apiClient.get<Portfolio>(`${svcPaths.activities}/portfolios/${portfolioId}`, {
            cancelToken: cancelToken
        });
        return response.data
    }

    async getPortfolioList(cancelToken?: CancelToken) {
        const response = await apiClient.get<Portfolio[]>(`${svcPaths.activities}/portfolios`, { cancelToken: cancelToken });
        return response.data
    }

    async isPortfolioNameAvailable(name: string) {
        const payload = { name: name };
        const response = await apiClient.post<boolean>(`${svcPaths.activities}/portfolios/check-name`, payload);
        return response.data;
    }

    async createPortfolio(request: CreatePortfolioRequest) {
        const payload = { name: request.name };
        const response = await apiClient.post<Portfolio>(`${svcPaths.activities}/portfolios`, payload);
        return response.data;
    }

    async getPortfolioMembers(request: GetPortfolioMembersRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<User[]>(`${svcPaths.activities}/portfolios/${request.id}/members`, {
            params: {
                keyword: request.keyword
            },
            cancelToken: cancelToken
        });
        return response.data;
    }

    async updatePortfolioMemberRole(portfolioId: number, userId: string, newRole: PortfolioRoleId) {
        const payload = { newRoleId: newRole };
        const response = await apiClient.put(`${svcPaths.activities}/portfolios/${portfolioId}/members/${userId}`, payload);
        return response.data;
    }

    async deleteMember(portfolioId: number, userId: string) {
        const response = await apiClient.delete(`${svcPaths.activities}/portfolios/${portfolioId}/members/${userId}`);
        return response.data;
    }

    async getPortfolioAvailableMembers(request: GetPortfolioAvailableMembersRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<User[]>(`${svcPaths.activities}/portfolios/${request.portfolioId}/available-members`, {
            params: {
                keyword: request.keyword,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                excludeCurrentUser: true,
                userIds: request.userIds
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken
        });
        return response.data;
    }

    async addMembers(portfolioId: number, members: PortfolioMembership[]) {
        const payload = { id: portfolioId, members };
        const response = await apiClient.post(`${svcPaths.activities}/portfolios/${portfolioId}/members`, payload);
        return response.data;
    }

    async updateTemplateActivitiesForPortfolioMembers(request: UpdateTemplateActivitiesForPortfolioMembersRequest, cancelToken?: CancelToken) {
        const response = await apiClient.put(`${svcPaths.activities}/portfolios/${request.portfolioId}/members/${request.userId}/templateactivities`, {
            templateActivities: request.templateActivities,
            cancelToken: cancelToken
        });
        return response.data;
    }

    async renamePortfolio(portfolioId: number, name: string) {
        const payload = { name: name, Id: portfolioId };
        const response = await apiClient.put(`${svcPaths.activities}/portfolios/${portfolioId}/name`, payload);
        return response.data
    }
}

export const portfolioApi = new PortfolioApiClass();