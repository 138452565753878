/*eslint-disable sonarjs/cognitive-complexity */
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { classNamesFunction, Icon, StackItem, TextField } from "@fluentui/react";
import { nanoid } from "@reduxjs/toolkit";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../../../../../common/components/ediModal/ediModal";
import TeamsImage from "../../../../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../../../../common/components/teamsImage/teamsImage.types";
import { Helpers } from "../../../../../../../../utilities/helpers";
import { useOnMount } from "../../../../../../../../utilities/hooks";
import { useDocLabDispatch } from "../../../../../../../docLabStore";
import { insertCall, setCall } from "../../../../../../../features/callNotification";
import { useCurrentArchive } from "../../../../../../../hooks/useCurrentArchive";
import { Call, callsList } from "../../../../../../../models/callsApi";
import { Constants } from "../../../../../../../models/constants";
import { docAnalyzesApi } from "../../../../../../../services/docAnalyzes/docAnalyzes.api";
import { TocTemplate } from "../../../../../../files/createTemplateModal/createTemplateModal.types";
import TableOfContent from "../tableOfContent/tableOfContent";
import { ICreateTableOfContentModalProps, ICreateTableOfContentModalPropsStyles, ICreateTableOfContentModalStyles } from "./createTableOfContentModal.types";

export type CreateTocData = {
    name?: string;
    isValid: boolean;
}
const getClassNames = classNamesFunction<ICreateTableOfContentModalPropsStyles, ICreateTableOfContentModalStyles>();

export const CreateTableOfContentModalBase = (props: ICreateTableOfContentModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [tableContentName, setTableContentName] = useState('')
    const [tableContentDescription, setTableContentDescription] = useState('')
    const [saveLoading, setSaveLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [retrievedTree, setRetrieveTree] = useState<TocTemplate[] | undefined>(undefined)
    const { t } = useTranslation(['createTemplate', 'common', 'docAnalysis']);
    const currentArchive = useCurrentArchive();
    const genericErrorMessage = t('common:genericErrorApi');
    const [validationIcon, setValidationIcon] = useState('Error');
    const [data, setData] = useState<CreateTocData>();
    const dispatch = useDocLabDispatch();

    useOnMount(() => {
        async function fetch() {
            await getTreeItems();
        }
        fetch()
    })

    const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
        setData({ ...data, isValid: !error, name: value ?? "" });
        setValidationIcon(error ? 'Error' : 'Completed');
    }, [data]);

    const validateTocName = useCallback(async (value: string) => {
        if (!value && currentArchive)
            return ''; // return `${t('common:fieldRequired')}`;

        setValidationIcon('ProgressRingDots');

        try {
            const [isValid] = await Promise.all([docAnalyzesApi.isConfigurationTocNameAvailable(currentArchive?.id || 0, value), Helpers.delay(500)]);
            return isValid ? "" : t('docAnalysis:operations.addOperation.errors.nameNotAvailable');
        }
        catch {
            return t('common:genericErrorCheckName');
        }
    }, [t, currentArchive]);

    const getTreeItems = async () => {
        setIsLoading(true);
        try {
            if (props.idTemplate) {
                const treeItems = await docAnalyzesApi.getTocTemplate(props.idTemplate);
                if (treeItems) {
                    setRetrieveTree(treeItems.data.referenceToC)
                    setTableContentName(treeItems.name);
                    setTableContentDescription(treeItems.description);
                }
                const check = treeItems.data.referenceToC.find((item, ind) => item.id !== (ind + 1).toString());

                if (!check)
                    setError(false);
                else
                    setError(true);
            }
        }
        catch (e) { console.log(e); setError(true) }
        finally {
            setIsLoading(false);
        }
    }

    const createTemplate = async (tree: TocTemplate[]) => {
        setSaveLoading(true);
        const saveTemplateCall: Call = {
            type: callsList.saveTemplate,
            nameOperation: t('docAnalysisSettings:tableOfContent.saveActionName'),
            errors: [
                { code: 403, message: t("docAnalysisSettings:tableOfContent.saveAction403") },
                { code: 422, message: t("docAnalysisSettings:tableOfContent.saveAction422") },
                { code: 500, message: genericErrorMessage }
            ]
        };
        const id = nanoid();
        const payload = { requestId: id, notification: saveTemplateCall }
        dispatch(insertCall(payload));
        try {
            if (currentArchive) {
                await docAnalyzesApi.createTemplate({
                    archiveId: currentArchive?.id,
                    name: tableContentName,
                    description: tableContentDescription,
                    data: { referenceToC: tree }
                });
                props.onClose(true);
                const successPayload = { requestId: id, success: true, message: t('docAnalysisSettings:tableOfContent.saveSuccessMessage') }
                dispatch(setCall(successPayload));

            }
        }
        catch (error: any) { //eslint-disable-line @typescript-eslint/no-explicit-any
            let errorMessage = '';
            switch (error.code) {
                case (403):
                    {
                        errorMessage = t("docAnalysisSettings:tableOfContent.saveAction403");
                        break;
                    }
                case (422):
                    {
                        errorMessage = t("docAnalysisSettings:tableOfContent.saveAction422");
                        break;
                    }
                default: {
                    errorMessage = genericErrorMessage;
                    break;
                }
            }
            const failurePayload = { requestId: id, success: false, message: errorMessage }
            dispatch(setCall(failurePayload));
        }
        finally {
            props.onClose(undefined);
            setSaveLoading(false);
        }
    }

    const body = () => {

        return (<>
            {isLoading ? <div className={classNames.spinner}>
                <TeamsSpinner />
            </div> : error ? <div><TeamsImage
                imageName={ImageName.Error2}
                styles={{ img: { width: '80%', height: '80%' } }}
                caption={genericErrorMessage}
            /></div> :
                <>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '35px' }}>
                        <TextField
                            label={t('name')}
                            autoComplete={"off"}
                            required
                            autoFocus
                            disabled={retrievedTree !== undefined}
                            maxLength={Constants.tocNameMaxLenght}
                            onChange={(_, newValue) => setTableContentName(newValue || '')}
                            onRenderSuffix={() => <Icon iconName={validationIcon} />}
                            value={tableContentName}
                            onGetErrorMessage={retrievedTree !== undefined ? undefined : validateTocName}
                            onNotifyValidationResult={onNameValidationResult}
                            styles={classNames.subComponentStyles.textFieldStyle({ theme: props.theme!, icon: validationIcon })} //eslint-disable-line @typescript-eslint/no-non-null-assertion
                        />
                        <TextField
                            multiline={true}
                            label={t('description')}
                            autoComplete={"off"}
                            resizable={false}
                            disabled={retrievedTree !== undefined}
                            maxLength={Constants.MAX_LENGTH_ARCHIVE_DESCRIPTION}
                            onChange={(_, newValue) => setTableContentDescription(newValue || '')}
                            value={tableContentDescription}
                            styles={{ root: { width: '100%' }, errorMessage: { position: 'absolute' } }}
                        />
                    </div>
                    <div style={{ height: 'calc(100% - 90px)' }}>
                        <StackItem style={{ height: 'calc(100% - 90px)' }}>
                            <TableOfContent readonly={retrievedTree || isLoading ? true : false}
                                retrievedTree={retrievedTree ? retrievedTree : undefined}
                                disableButton={tableContentName.trim().length === 0 || saveLoading || (!data?.isValid || false)}
                                loadingState={saveLoading}
                                accept={(bool, treeItems) => createTemplate(treeItems)}
                                reject={() => props.onClose(undefined)} />
                        </StackItem>
                    </div>
                </>}
        </>)
    }


    return (
        <>
            <EdiModal
                title={isLoading ? '' : retrievedTree ? `${t('viewFirstPageTitle')}` : `${t('firstPageTitle')}`}
                width={800}
                height={800}
                isOpen={true}
                className={classNames.scrollableBody}
                styles={{ bodyContent: { overflow: 'hidden' }, container: { overflow: 'hidden' } }}
                onCloseClick={() => {
                    props.onClose(undefined);
                    setRetrieveTree(undefined)
                }}
                showCloseIcon={true}
                body={body()}
            />
        </>
    );
}