import { styled } from "@fluentui/react";
import { getStyles } from "./glossaryCheckContent.styles";
import { IGlossaryCheckContentPropsStyles, IGlossaryCheckContentStyles, IGlossaryCheckContentProps } from "./glossaryCheckContent.types";
import { GlossaryCheckContentBase } from "./glossaryCheckContent.base";

const GlossaryCheckContent = styled<IGlossaryCheckContentProps, IGlossaryCheckContentPropsStyles, IGlossaryCheckContentStyles>(
    GlossaryCheckContentBase,
    getStyles,
    undefined,
    { scope: 'GlossaryCheckContent' }
);

export default GlossaryCheckContent;