/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { classNamesFunction, DefaultButton, Icon, IStackTokens, PrimaryButton, Spinner, SpinnerSize, Stack, StackItem, TextField, TooltipHost } from "@fluentui/react";
import { IWorkflowApprovalStepPropsStyles, IWorkflowApprovalStepStyles, IWorkflowApprovalStepProps } from "./workflowApprovalStep.types";
import { useTranslation } from "react-i18next";
import { WorkFlowStepTypes } from "../../../../models/workflowStep";
import { ErrorsType } from "../../../../../docLab/models/callsApi";
import { workFlowStepApi } from "../../../../services/workflowstep/workflowstep.api";
import { ApproveWorkFlowStep } from "../../../../services/workflowstep/workflowstep.contracts";
import WorkflowApprovalModal from "../workflowApprovalModal/workflowApprovalModal";

const getClassNames = classNamesFunction<IWorkflowApprovalStepPropsStyles, IWorkflowApprovalStepStyles>();

export const WorkflowApprovalStepBase = (props: IWorkflowApprovalStepProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const { t } = useTranslation(['workflowApprovalStep', 'common']);
    const [description, setDescription] = useState('');
    const [showApprovalModal, setShowApprovalModal] = useState(false)
    const [errorMessageFooter, setErrorMessageFooter] = useState('');
    const [disableApproveButton, setDisableApproveButton] = useState(false)
    const [loading, setLoading] = useState(false);

    const propertiesGap: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    const approveStep = async (approve: boolean) => {
        setLoading(true);
        setDisableApproveButton(true)
        try {
            const request: ApproveWorkFlowStep = {
                id: props.stepId,
                approve: approve,
                comments: description

            }
            await workFlowStepApi.ApproveWorkFlowStep(request);
            setErrorMessageFooter('')
            props.onReload();
        }
        catch (er: any) {
            const error: ErrorsType = er as ErrorsType;
            switch (error.code) {
                case 403: setErrorMessageFooter(t('notPermissions')); break;
                case 404: setErrorMessageFooter(t('notFound')); break;
                case 422: setErrorMessageFooter(t('unprocessable')); break;
                default: setErrorMessageFooter(t('common:genericErrorApi')); break;
            }
        }
        finally {
            setDisableApproveButton(false)
            setLoading(false);
        }
    }

    return (
        <>
            <Stack verticalAlign="center" tokens={propertiesGap} className={classNames.root} >
                <StackItem>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <StackItem align="end" style={{ width: '100%' }}>
                            <StackItem align="end" style={{ width: '100%', fontSize: 18, marginBottom: 15, fontWeight: 600 }} >
                                {`${t('title')}: ${props.stepName}`}
                            </StackItem>
                            <StackItem align="end" style={{ width: '100%', marginBottom: 5 }} >
                                {props.stepType === WorkFlowStepTypes.OnlyOneSignedApprover ? t('onlyOneSignedApproverDesctioption') : props.stepType === WorkFlowStepTypes.OnlyOneApprover ? t('onlyOneApproverDesctioption') : t('everyoneMustApproveApproverDesctioption')}
                            </StackItem>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {t('secondDesctioption')}
                                <TooltipHost content={t('tooltipDescription')}>
                                    <Icon
                                        styles={{ root: { marginLeft: '5px', color: palette.black, paddingTop: 5, cursor: 'context-menu' } }}
                                        iconName="info"
                                    />
                                </TooltipHost>
                            </div>
                            <StackItem align="end" style={{ marginTop: 5, width: '100%' }} >
                                <TextField
                                    multiline
                                    placeholder={t('commentPlaceholder')}
                                    value={description}
                                    resizable={false}
                                    styles={{
                                        field: { color: palette.neutralPrimary, 'input': { '&::placeholder': { color: palette.neutralPrimary } } }
                                    }}
                                    style={{ minHeight: '50px', maxHeight: '50px' }}
                                    maxLength={250}
                                    onChange={(_, value) => { setDescription(value || '') }}
                                />
                            </StackItem>
                        </StackItem>
                    </Stack>
                </StackItem>
                <StackItem>
                    <Stack horizontal tokens={{ childrenGap: 10 }} >
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ fontSize: '14px', color: palette.red }}>
                                {errorMessageFooter}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                <PrimaryButton disabled={disableApproveButton} onClick={() => { props.stepType === WorkFlowStepTypes.OnlyOneSignedApprover ? setShowApprovalModal(true) : approveStep(true) }} style={{ margin: '0px 4px' }} >{props.stepType === WorkFlowStepTypes.OnlyOneSignedApprover ? t('signedApprove') : t('approve')}</PrimaryButton>
                                <TooltipHost content={t('rejectToolTip')}>
                                    <DefaultButton style={{ margin: '0px 4px' }} disabled={description.trim().length === 0 || disableApproveButton} onClick={() => approveStep(false)}>{t('reject')}</DefaultButton>
                                </TooltipHost>
                                {loading && <Spinner size={SpinnerSize.large} />}
                            </div>
                        </div>
                    </Stack>
                </StackItem>
            </Stack>

            {showApprovalModal && <WorkflowApprovalModal onReload={() => props.onReload()} description={description} closeModal={() => setShowApprovalModal(false)} stepId={props.stepId} />}
        </>
    )

}