/*eslint-disable sonarjs/cognitive-complexity */
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { classNamesFunction, DetailsList, DetailsRow, IDetailsRowProps, Persona, PersonaSize, PrimaryButton, ScrollablePane, SelectionMode, Stack, TextField } from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { useAsyncApi, useDebounced } from "../../../../utilities/hooks";
import { mapper } from "../../../../utilities/mapper";
import { useArchiveContent } from "../../../features/archiveContent";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { ArchiveRoleId, Constants } from "../../../models/constants";
import { User } from "../../../models/user";
import { archivesApi } from "../../../services/archives/archives.api";
import FolderMembersModal from "../../settings/archiveMembersModal/folderMembers/folderMembersModal";
import { RemoveMemberButton } from "../../settings/archiveMembersSettings/subComponents/removeMemberButton";
import { IFolderPermissionModalProps, IFolderPermissionModalPropsStyles, IFolderPermissionModalStyles } from "./folderPermissionModal.types";

const getClassNames = classNamesFunction<IFolderPermissionModalPropsStyles, IFolderPermissionModalStyles>();

export const FolderPermissionModalBase = (props: IFolderPermissionModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { managePermissionModalShown, selectedItems, items } = useArchiveContent();
    const { t } = useTranslation(['folderPermission', 'common']);
    const currentArchive = useCurrentArchive();
    const readonly = useMemo(() => currentArchive?.currentUserRoleId !== ArchiveRoleId.Owner, [currentArchive]);
    const [folderMembersCount, setfolderMembersCount] = useState<number>(0);
    const [filter, setFilter] = useState<string>("");
    const [isModalUserOpened, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const folderName = _.find(items, {'id': selectedItems[0].id, 'isFolder': true})?.name; 


    const { execute: loadMembers, value: members, loading } =
        useAsyncApi<void, User[]>({
            keepResultsWhileReloading: true,
            func: async () => {
                if (!selectedItems[0].isFolder)
                    return {} as User[];
                
                const members =  await archivesApi.getFolderMembers({
                    folderId: selectedItems[0].id,
                    pageNumber: 0,
                    pageSize: 0,
                    keyword: filter
                });

                if(filter === '')
                    setfolderMembersCount(members?.length ?? 0);

                return members;
            }
        });

    useEffect(() => {
        debouncedLoadMembers()
    }, [filter]) //eslint-disable-line react-hooks/exhaustive-deps

    const debouncedLoadMembers = useDebounced<void, void>(() => loadMembers(), 300);

    const columns = useMemo(() => {
        if (!currentArchive)
            return [];

        return [
            {
                key: 'persona',
                name: 'Name',
                minWidth: 100,
                headerClassName: classNames.headerRow,
                onRender: function renderMemberPersona(member: User) {
                    return (
                        <Persona {...mapper.mapUserToPersona(member)} size={PersonaSize.size40} coinSize={32} />
                    );
                }
            },
            {
                key: 'remove',
                name: '',
                minWidth: 24,
                headerClassName: classNames.headerRow,
                onRender: function renderMemberRemoveButton(member: User) {
                    return (
                        <RemoveMemberButton
                            archiveId={currentArchive.id}
                            buttonStyles={classNames.subComponentStyles.removeButton()}
                            member={member}
                            onRemoveMemberCompleted={() => {
                                if (filter!=="") {
                                    setFilter("");
                                } else {
                                    debouncedLoadMembers();
                                }
                            }}
                            disabled={readonly || member.roleId === ArchiveRoleId.Owner || folderMembersCount < 3 }
                            folderId={selectedItems[0].id}
                        />
                    );
                }
            }
        ];
    }, [classNames, filter, currentArchive, readonly, folderMembersCount, selectedItems, debouncedLoadMembers]);

    const membersForRole = () => {
        const singleRoleMembers = members
        if (!singleRoleMembers)
            return null;

        return (
            <DetailsList
                styles={classNames.subComponentStyles.list}
                items={singleRoleMembers}
                getKey={(item: User) => item.id}
                onRenderRow={(props?: IDetailsRowProps) =>
                    props ? <DetailsRow {...props} styles={classNames.subComponentStyles.row} /> : null}
                columns={columns}
                isHeaderVisible={false}
                selectionMode={SelectionMode.none}
            />
        );
    }

    
    const body = () => {
        return (
            <Stack className={classNames.root}>
                <Stack horizontal verticalAlign={'center'}>

                </Stack>
                <Stack horizontal className={classNames.upperSection} horizontalAlign={'end'}>
                    <TextField
                        key={currentArchive?.id}
                        maxLength={Constants.MAX_LENGTH_ARCHIVE_NAME}
                        placeholder={t("searchBox")}
                        styles={classNames.subComponentStyles.textFieldStyle}
                        onChange={(_, value) => { setFilter(value || '') }}
                        iconProps={{ iconName: 'search', }}
                        value={filter}
                    />
                    <PrimaryButton
                        iconProps={{ iconName: 'AddFriend' }}
                        styles={{ icon: { height: 'auto' } }}
                        text={t("addMembers")}
                        onClick={showModal}
                        disabled={readonly}
                    />
                </Stack>
                <Stack.Item verticalFill className={classNames.lowerSection}>
                    {loading ?
                        <div className={classNames.loaderContainer}>
                            <TeamsSpinner />
                        </div> :
                        <ScrollablePane styles={classNames.subComponentStyles.scrollablePane}>
                            {membersForRole()}
                        </ScrollablePane>
                    }
                </Stack.Item>
            </Stack>


        );
    }

    return (
        isModalUserOpened ?
         <FolderMembersModal
            isOpen={isModalUserOpened}
            operationEnded={(success: boolean) => {
                hideModal();
                if (success) {
                    if (filter!=="") {
                        setFilter("");
                    } else {
                        debouncedLoadMembers();
                    }
                }
            }}
        /> : 
        <EdiModal
            isOpen={managePermissionModalShown}
            showCloseIcon={true}
            onCloseClick={props.onClose}
            title={`${t('title')} - ${folderName ?? ''}`}
            body={body()}
            width={800}
            height={500}
        />
    );
}