import { classNamesFunction, DefaultButton, Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import MultiStepModal from "../../../../common/components/multiStepModal/multiStepModal";
import { ICreateArchiveModalProps, ICreateArchiveModalPropsStyles, ICreateArchiveModalStyles } from "./createArchiveModal.types";
import { CreatingArchivePage } from "./pages/creatingArchivePage";
import { CreateArchiveFirstPage, FirstPageData } from "./pages/firstPage";
import { AddMembersPage, MembersPageData } from "../../../../common/components/modalPages/addMembersPage";
import { User } from "../../../models/user";
import { archivesApi } from "../../../services/archives/archives.api";
import { AddMembersPageType } from "../../../../utilities/constants";
import { IUser } from "../../../../common/interfaces/IUser";

enum CreateArchiveStep {
    setNameAndDescription = 0,
    addMembers = 1,
    creatingArchive = 2
}

const getClassNames = classNamesFunction<ICreateArchiveModalPropsStyles, ICreateArchiveModalStyles>();

export const CreateArchiveModalBase = (props: ICreateArchiveModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [currentStep, setCurrentStep] = useState(CreateArchiveStep.setNameAndDescription);
    const [isMutating, setIsMutating] = useState(false);
    const [firstPageData, setFirstPageData] = useState<FirstPageData>({ name: "", description: "", isValid: false });
    const [secondPageData, setSecondPageData] = useState<MembersPageData>({ selectedPeople: [], isValid: false });
    const [showDialog, setShowDialog] = useState(false);
    const { t } = useTranslation(['createArchiveModal', 'common']);

    const firstPageFooter = useMemo(() => {
        return (
            <>
                <span className={classNames.requiredTextLeft}>* {t('common:requiredField')}</span> 
                <span></span>
                <PrimaryButton
                    text={t('common:nextButton')}
                    disabled={isMutating || !firstPageData.isValid}
                    onClick={() => setCurrentStep(CreateArchiveStep.addMembers)}
                />
            </>
        );
    }, [firstPageData.isValid, t, isMutating, classNames.requiredTextLeft]);

    const secondPageFooter = useMemo(() => (
        <>
            <label
                onClick={() => !isMutating && setCurrentStep(CreateArchiveStep.setNameAndDescription)}
                style={{ cursor: 'pointer' }}
            >
                {t('common:previousButton')}
            </label>
            <PrimaryButton
                text={t('common:nextButton')}
                disabled={isMutating || !secondPageData.isValid}
                onClick={() => { setCurrentStep(CreateArchiveStep.creatingArchive); }}
            />
        </>
    ), [secondPageData.isValid, t, isMutating]);

    const getUsers = async (keyword: string, currentUsers: IUser[]) => {
        if (keyword.length > 2) {
            return await archivesApi.getUsers({
                pageNumber: 0,
                pageSize: 20,
                keyword: keyword,
                userIds: currentUsers.map(x => x.id)
            });
        }
        return []
    }

    const steps = [
        {
            title: `${t('firstStepTitle')}`,
            body: <CreateArchiveFirstPage theme={props.theme} onArchiveDataChange={setFirstPageData} initialState={firstPageData} />,
            footer: firstPageFooter
        },
        {
            title: `${t('secondStepTitle')} "${firstPageData.name}"`,
            body: <AddMembersPage onDataChange={setSecondPageData} initialState={secondPageData} 
            getUsers={(keyword: string) => getUsers(keyword, secondPageData.selectedPeople)}
            translationNamespace="membersPageForCreateArchive" modalType={AddMembersPageType.Archive} enableAllowFolderToggle={false} enableRoleSelection={true} />,
            footer: secondPageFooter
            
        },
        {
            bodyOnly: true,
            body: <CreatingArchivePage onClose={props.onClose} initialState={
                { name: firstPageData.name, description: firstPageData.description, members: secondPageData.selectedPeople as User[] }
            } />
        },
    ]

    return (
        <>
            <MultiStepModal
                styles={classNames.subComponentStyles.ediModal}
                isOpen={true}
                width={600}
                height={600}
                showCloseIcon={currentStep !== CreateArchiveStep.creatingArchive}
                onCloseClick={() => currentStep === CreateArchiveStep.addMembers ? setShowDialog(true) : props.onClose && props.onClose()}
                activeStep={currentStep}
                steps={steps}
                onStepChange={setIsMutating}
                animateInitialStep
            />
            <Dialog
                hidden={!showDialog}
                dialogContentProps={{ title: `${t('stopCreating')}` }}
            >
                <DialogFooter>
                    <DefaultButton onClick={() => setShowDialog(false)} text={t('common:dialogButtonCancel')} />
                    <PrimaryButton onClick={props.onClose} text={t('common:dialogButtonConfirm')} />
                </DialogFooter>
            </Dialog>
        </>
    );
}