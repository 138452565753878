import { IStyleFunction } from "@fluentui/react"
import { IFeedbackConfirmModalPropsStyles, IFeedbackConfirmModalStyles } from "./feedbackConfirmModal.types";

export const FeedbackModalGlobalClassNames = {
    root: 'edi-feedback-modal-root'
}

export const getStyles: IStyleFunction<IFeedbackConfirmModalPropsStyles, IFeedbackConfirmModalStyles> = (props: IFeedbackConfirmModalPropsStyles): IFeedbackConfirmModalStyles => {
    return {
        root: [
            FeedbackModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        firstFooterContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }
}