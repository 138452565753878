import { FontWeights, IStyleFunction } from "@fluentui/react"
import { IEdiCalloutPropsStyles, IEdiCalloutStyles } from "./ediCallout.types"

export const EdiCalloutGlobalClassNames = {
    root: 'edi-callout-root',
    content: 'edi-callout--content',
    header: 'edi-callout--header',
    title: 'edi-callout--title',
    closeIcon: 'edi-callout--close-icon',
    body: 'edi-callout--body',
    bodyContent: 'edi-callout--body-content',
    footer: 'edi-callout--footer',
    subTitle: 'edi-callout--subtitle'
}

export const getStyles: IStyleFunction<IEdiCalloutPropsStyles, IEdiCalloutStyles> = (props: IEdiCalloutPropsStyles): IEdiCalloutStyles => {
    const { palette, fonts } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            EdiCalloutGlobalClassNames.root,
            {
                padding: '27px 32px',
                width: props.width,
                height: props.height,
                right: 34
            }
        ],
        content: [
            EdiCalloutGlobalClassNames.content,
            {
                '*::-webkit-scrollbar': {
                    width: 16,
                    height: 16
                },
                '*::-webkit-scrollbar-thumb': {
                    border: '5px solid transparent',
                    backgroundClip: 'padding-box',
                    backgroundColor: 'transparent',
                    borderRadius: 25
                },
                '*:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: palette.neutralSecondary// 'rgba(66, 66, 66, 0.3)'
                },
                '*::-webkit-scrollbar-thumb:hover': {
                    border: '2px solid transparent'
                },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                '> :not(:first-child)': {
                    marginTop: 20
                }

            }
        ],
        header: [
            EdiCalloutGlobalClassNames.header,
            fonts.large,
            {
                display: 'flex',
                justifyContent: 'space-between',
                // alignItems: 'flex-start',
                alignItems: 'center',
                fontWeight: FontWeights.bold,
                minHeight: 36
            }
        ],
        subTitle: [
            EdiCalloutGlobalClassNames.subTitle,
            fonts.medium,
            {
               fontWeight: FontWeights.semibold,
               color: palette.neutralPrimary
            }
        ],
        title: [
            EdiCalloutGlobalClassNames.title,
            {
                wordBreak: 'break-word',
                color: palette.black
            }
        ],
        closeIcon: [
            EdiCalloutGlobalClassNames.closeIcon,
            {
            }
        ],
        body: [
            EdiCalloutGlobalClassNames.body,
            {
                flexGrow: 1,
                overflowY: 'auto',                
                height: '100%'
            }
        ],
        bodyContent: [
            EdiCalloutGlobalClassNames.bodyContent,
            {
                height: '100%'
            }
        ],
        footer: [
            EdiCalloutGlobalClassNames.footer,
            {
                paddingTop: '10px',
                textAlign: 'right'
            }
        ],
        subComponentStyles: {
            iconButton: {
                root: {
                    color: palette.neutralDark,
                    marginLeft: 'auto',
                    marginTop: '4px',
                    marginRight: '2px',
                },
                rootHovered: {
                    color: palette.neutralPrimary,
                }
            }
        }
    };
}