import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDocLabSelector } from '../docLabStore';
import { searchApi } from '../services/search/search.api';
import { FacetType, FieldType, FlatFacet, MergedFacetItem, MergedFacets, SearchIndexField, SearchResult } from '../services/search/search.contracts';
import { ArchiveRoleId } from '../models/constants';
import { getSearchContentActionIds } from './searchContentActions';
import { FileApprovalStatus, FileSourceType } from '../models/archiveItem';
import { SelectionScopes } from '../utilities/archiveContentActions';
import { Archive } from '../models/archive';

export type SearchParams = {
  keyword?: string;
  orderBy?: string;
  isAsc?: boolean;
  pageNumber?: number;
  selectedFacets?: FlatFacet[];
  archiveId?: number;
  archiveType?:number;
  multiLanguageSearch?: boolean;
  selectedArchives?: number[];
  selectedFolders?: number[];
  selectedFoldersString?: string;
}

export type SelectionEntryItemSearch = {
  id: number;
  name?: string | undefined;
  isFolder: boolean;
  archiveRoleId?: ArchiveRoleId | undefined;
  indexed?: boolean,
  sourceType?: FileSourceType;
  approvalStatus?: FileApprovalStatus | undefined
}

export type SearchContentState = {
  archive?: Archive;
  searchResult: SearchResult | undefined,
  searchParams: SearchParams;
  groupedFacets: MergedFacets[];
  indexFields: SearchIndexField[];
  selectionEntryList: SelectionEntryItemSearch[];
  selectedItemsActionIds: string[];
  multipleArchiveChosen?: boolean;
}

export type UpdateGroupedFacets = {
  fieldMergedFacet: number;
  newValue: MergedFacetItem[];
}

const initialState: SearchContentState = {
  archive: undefined,
  searchResult: undefined,
  searchParams: {
    keyword: undefined,
    orderBy: undefined,
    isAsc: true,
    pageNumber: 1,
    selectedFacets: [],
    archiveId: 0,
    multiLanguageSearch: false,
    selectedArchives: [],
    selectedFolders: undefined,
    selectedFoldersString: undefined,
    archiveType:0,
  } as SearchParams,
  groupedFacets: [] as MergedFacets[],  
  indexFields: [],
  selectionEntryList: [],
  selectedItemsActionIds:[],
  multipleArchiveChosen: false
}

const addSelectionEntryItem = (state: SearchContentState, selectionEntryItem: SelectionEntryItemSearch) => {
  if (state.selectionEntryList)
    state.selectionEntryList =[
      ...state.selectionEntryList ,{
        id: selectionEntryItem.id,
        name:  selectionEntryItem.name,
        isFolder: selectionEntryItem.isFolder,
        archiveRoleId: selectionEntryItem.archiveRoleId,
        approvalStatus: selectionEntryItem.approvalStatus
      }
    ]
}

const removeSelectionEntryItem = (state: SearchContentState, selectionEntryItem: SelectionEntryItemSearch) => {
  if (state.selectionEntryList?.some(e => (e.id === selectionEntryItem.id && e.archiveRoleId === selectionEntryItem.archiveRoleId && e.name === selectionEntryItem.name)))
    state.selectionEntryList = state.selectionEntryList.filter(e => !(e.id === selectionEntryItem.id && e.archiveRoleId === selectionEntryItem.archiveRoleId && e.name === selectionEntryItem.name));
}

const addFacetRange = (state: SearchContentState, action: PayloadAction<{mergedFacedItem: MergedFacetItem, AdditionalType?: FieldType}>) => {
  state.searchParams.selectedFacets =
    [
      ...state.searchParams.selectedFacets as FlatFacet[],
      {
        key: action.payload.mergedFacedItem.field,
        from: action.payload.mergedFacedItem.from,
        to: action.payload.mergedFacedItem.to,
        type: action.payload.mergedFacedItem.type,
        text: action.payload.mergedFacedItem.value
      }
    ]
}

const removeFacet = (state: SearchContentState, facet: FlatFacet) => {
  if (facet.type === FacetType.Value && state.searchParams.selectedFacets && facet.key !== "AlreadyApproved") {
    state.searchParams.selectedFacets.filter(x => x.key === facet.key)[0].values = facet.values?.filter(x => x !== facet.text);
    if (state.searchParams.selectedFacets.filter(x => x.key === facet.key)[0].values?.length === 0 || facet.value)
      state.searchParams.selectedFacets = state.searchParams.selectedFacets?.filter(x => x.key !== facet.key);
  }
  else {
    state.searchParams.selectedFacets = state.searchParams.selectedFacets?.filter(x => x.key !== facet.key);
  }
}

const manageFacetDataRanges = (facet: FlatFacet | undefined, state: SearchContentState, action: PayloadAction<{mergedFacedItem: MergedFacetItem, AdditionalType?: FieldType}>) => {
  if (facet) {
    if (facet.text !== action.payload.mergedFacedItem.value) {
      removeFacet(state, facet);
      addFacetRange(state, action);
    }
  }
  else {
    addFacetRange(state, action);
  }
}

const manageFacetValues = (facet: FlatFacet | undefined, state: SearchContentState, action: PayloadAction<{mergedFacedItem: MergedFacetItem, AdditionalType?: FieldType, AlreadyApproved?: boolean}>) => {
  if (action.payload.mergedFacedItem.value)
    if (facet) {
      facet.values?.push(action.payload.mergedFacedItem.value);
    }
    else {
      if (action.payload.AdditionalType && (action.payload.AdditionalType === FieldType['Edm.String'] || action.payload.AdditionalType === FieldType['Edm.Boolean'])) {
        state.searchParams.selectedFacets =
        [
          ...state.searchParams.selectedFacets as FlatFacet[],
          {
            key: action.payload.mergedFacedItem.field,
            type: FacetType.Value,
            value: action.payload.AlreadyApproved !== undefined ? action.payload.AlreadyApproved as boolean : action.payload.mergedFacedItem.value
          }
        ]
      }
      else{
        state.searchParams.selectedFacets =
        [
          ...state.searchParams.selectedFacets as FlatFacet[],
          {
            key: action.payload.mergedFacedItem.field,
            values: [action.payload.mergedFacedItem.value],
            type: FacetType.Value
          }
        ]
      }
    }
}

export const getIndexField = createAsyncThunk('search/getIndexField', async () => {
  const indexFields: SearchIndexField[] = [];
  const response = await searchApi.getIndexField();
    response.fields.forEach(field => { if (field.name.toLowerCase().startsWith("edi_field")) indexFields.push(field) });
  return indexFields;
});

const getSelectionScope = (state: SearchContentState) => {
  let scope = SelectionScopes.None;

  state.selectionEntryList.forEach(entry => {
    switch (scope) {
        case SelectionScopes.None: scope = SelectionScopes.File; break;
        case SelectionScopes.File: scope = SelectionScopes.Files; break;
        case SelectionScopes.Folder: scope = SelectionScopes.FilesAndFolders; break;
        case SelectionScopes.Folders: scope = SelectionScopes.FilesAndFolders; break;
        case SelectionScopes.FilesAndFolders: return scope;
      }
    }
  );

  return scope;
}

const searchContentSlice = createSlice({
  name: 'search-content',
  initialState,
  reducers: {
    setCurrentArchiveForSearch: (state: SearchContentState, action: PayloadAction<Archive | undefined>) => {
      state.archive = action.payload;
    },
    resetAllSearchResults: (state: SearchContentState, action: PayloadAction<number | undefined>) => {
      state.searchParams = { ...initialState.searchParams, archiveId: action.payload };
      state.searchResult = initialState.searchResult;
      state.groupedFacets = initialState.groupedFacets;
    },
    resetAllParams: (state: SearchContentState, action: PayloadAction) => {
      state.searchParams = initialState.searchParams;
    },
    setCurrentResults: (state: SearchContentState, action: PayloadAction<SearchResult | undefined>) => {
      state.searchResult = action.payload;
      state.selectedItemsActionIds = getSearchContentActionIds(SelectionScopes.None, state.archive, state.searchParams?.archiveType, state.selectionEntryList, state.selectionEntryList[0]?.archiveRoleId, state.multipleArchiveChosen);
    },
    setSearchParams: (state: SearchContentState, action: PayloadAction<SearchParams>) => {
      state.searchParams = { ...state.searchParams, ...action.payload };
    },
    setGroupedFacets: (state: SearchContentState, action: PayloadAction<MergedFacets[]>) => {
      state.groupedFacets = action.payload;
    },
    updateGroupedFacets: (state: SearchContentState, action: PayloadAction<UpdateGroupedFacets>) => {
      state.groupedFacets[action.payload.fieldMergedFacet].values = action.payload.newValue;
    },
    addSelectedFacet: (state: SearchContentState, action: PayloadAction<{mergedFacedItem: MergedFacetItem, AdditionalType?: FieldType, AlreadyApproved?: boolean}>) => {
      const facet = state.searchParams.selectedFacets?.filter(x => x.key === action.payload.mergedFacedItem.field)[0];
      if (action.payload.mergedFacedItem.type === FacetType.Value 
        && !state.searchParams.selectedFacets?.some(x => x.key === action.payload.mergedFacedItem.field 
        && x.values?.some(e => e === action.payload.mergedFacedItem.value))) {
        manageFacetValues(facet, state, action);
        state.searchParams.pageNumber = 1;
      }
      if (action.payload.mergedFacedItem.type === FacetType.DateRange || action.payload.mergedFacedItem.type === FacetType.Range) {
        manageFacetDataRanges(facet, state, action);
        state.searchParams.pageNumber = 1;
      }
    },
    removeSelectedFacet: (state: SearchContentState, action: PayloadAction<FlatFacet>) => {
      removeFacet(state, action.payload);
      state.searchParams.pageNumber = 1;
    },
    removeFacetsByFamily: (state: SearchContentState, action: PayloadAction<string[]>) => {
      if (state.searchParams.selectedFacets) {
        state.searchParams.selectedFacets = state.searchParams.selectedFacets.filter(x => !action.payload.some(y => y === x.key));
        state.searchParams.pageNumber = 0;
      }
    },
    addSelectedItem: (state: SearchContentState, action: PayloadAction<SelectionEntryItemSearch>) => {
      addSelectionEntryItem(state,action.payload);
      state.selectedItemsActionIds = getSearchContentActionIds(getSelectionScope(state), state.archive, state.searchParams?.archiveType, state.selectionEntryList, state.selectionEntryList[0].archiveRoleId, state.multipleArchiveChosen);
    },
    removeSelectedItem: (state: SearchContentState, action: PayloadAction<SelectionEntryItemSearch>) => {
      removeSelectionEntryItem(state,action.payload);
      if (state.selectionEntryList.length === 0)
        state.selectedItemsActionIds = []
      else
        state.selectedItemsActionIds = getSearchContentActionIds(getSelectionScope(state), state.archive, state.searchParams?.archiveType, state.selectionEntryList, state.selectionEntryList[0].archiveRoleId, state.multipleArchiveChosen);
    },
    addSelectedItems: (state: SearchContentState, action: PayloadAction<SelectionEntryItemSearch[]>) => {
      state.selectionEntryList = action.payload;
      state.selectedItemsActionIds = getSearchContentActionIds(getSelectionScope(state), state.archive, state.searchParams?.archiveType, state.selectionEntryList, state.selectionEntryList[0].archiveRoleId, state.multipleArchiveChosen);
    },
    setSelectionEntryList: (state: SearchContentState, action: PayloadAction<SelectionEntryItemSearch[]>) => {
      state.selectionEntryList = action.payload;
      if (action.payload.length === 0)
        state.selectedItemsActionIds = [];
      else
        state.selectedItemsActionIds = getSearchContentActionIds(getSelectionScope(state), state.archive, state.searchParams?.archiveType, state.selectionEntryList, state.selectionEntryList[0].archiveRoleId, state.multipleArchiveChosen);
    },
    setMultipleArchiveChosen: (state: SearchContentState, action: PayloadAction<boolean>) => {
      state.multipleArchiveChosen = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getIndexField.pending, (state, action) => {
        state.indexFields = [];
    });
    builder.addCase(getIndexField.fulfilled, (state, action) => {
        state.indexFields = action.payload;
    });
  }
})

export const { setCurrentResults, setSearchParams, setGroupedFacets, setCurrentArchiveForSearch, resetAllSearchResults, addSelectedFacet, removeSelectedFacet, resetAllParams, removeFacetsByFamily, updateGroupedFacets, addSelectedItem, removeSelectedItem, addSelectedItems, setSelectionEntryList, setMultipleArchiveChosen } = searchContentSlice.actions;
export default searchContentSlice.reducer;

export const useSearchContent = () => useDocLabSelector(state => state.searchContent);