import { styled } from "@fluentui/react";
import { getStyles } from "./activitySettingsTemplate.styles";
import { IActivitySettingsTemplatePropsStyles, IActivitySettingsTemplateStyles, IActivitySettingsTemplateProps } from "./activitySettingsTemplate.types";
import { ActivitySettingsTemplateBase } from "./activitySettingsTemplate.base";

const ActivitySettingsTemplate = styled<IActivitySettingsTemplateProps, IActivitySettingsTemplatePropsStyles, IActivitySettingsTemplateStyles>(
    ActivitySettingsTemplateBase,
    getStyles,
    undefined,
    { scope: 'ActivitySettingsTemplate' }
);

export default ActivitySettingsTemplate;