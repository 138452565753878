import { styled } from "@fluentui/react";
import { IDeleteTopicModalProps, IDeleteTopicModalPropsStyles, IDeleteTopicModalStyles } from "./deleteTopicModal.types";
import { getStyles } from "./deleteTopicModal.styles";
import { DeleteTopicModalBase } from "./deleteTopicModal.base";

const DeleteTopicModal = styled<IDeleteTopicModalProps, IDeleteTopicModalPropsStyles, IDeleteTopicModalStyles>(
    DeleteTopicModalBase, 
    getStyles,
    undefined,
    { scope: 'DeleteTopicModal'}
)

export default DeleteTopicModal;