/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { classNamesFunction, IconButton, Label, PrimaryButton, ScrollablePane, SearchBox, Spinner, SpinnerSize, Stack, StackItem} from "@fluentui/react";
import {  useOnMount } from "../../../../../utilities/hooks";
import Accordion from "../../../../../common/components/accordion/accordion";
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { useTranslation } from "react-i18next";
import { archivesApi } from '../../../../services/archives/archives.api';
import { IManageTopicViewProps, IManageTopicViewPropsStyles, IManageTopicViewStyles } from "./manageTopicView.types";
import CreateTopicModal from "./createTopicModal/createTopicModal";
import { Topic } from "../topicModel/topic";
import DeleteTopicModal from "./deleteTopicModal/deleteTopicModal";

const getClassNames = classNamesFunction<IManageTopicViewPropsStyles, IManageTopicViewStyles>();

export const ManageTopicViewBase = (props: IManageTopicViewProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['topicOfInterest', 'common']);
    const currentArchive = useCurrentArchive();
    const [createTopicModalIsOpen, setCreateTopicModalIsOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [topics, setTopics] = useState<Topic[]>([]);
    const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState<boolean>(false);
    const [selectedTopic, setSelectedTopic] = useState<Topic>();
    const [deleteTopicErrorMessage, setDeleteTopicErrorMessage] = useState<string>();
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

    useOnMount(() => {
        getTopics();
    })

    const getTopics = async (value?: any) => { //eslint-disable-line @typescript-eslint/no-explicit-any 
        if(currentArchive?.id === undefined || currentArchive?.id === null)
            return

        setLoading(true);

        try{
            const result = await archivesApi.getTopics(currentArchive?.id, value ? value : null);
            setTopics(result);
        }finally{
            setLoading(false);
        }
    }

    const actionForProfile = (topic: Topic) => {
        return <div style={{marginLeft: 'auto'}}>
            <IconButton 
                iconProps={{ iconName: "Edit" }} 
                style={{ width: '30px', height: '30px', fontSize: '20px' }}  
                onClick={(e) => {
                    setCreateTopicModalIsOpen(true);
                    setSelectedTopic(topic);
                    e.stopPropagation();
                }}
            />
            <IconButton 
                iconProps={{ iconName: "Delete" }} 
                style={{ width: '30px', height: '30px', fontSize: '20px' }}  
                disabled={topic.userTopics.length > 0 ? true : false}
                onClick={(e) => {
                    setIsDeleteConfirmModalOpen(true);
                    setSelectedTopic(topic);
                    e.stopPropagation(); 
                }}
            />
        </div>
    }

    const onCreateModalClose = () => {
        setCreateTopicModalIsOpen(false);
        setSelectedTopic(undefined);
    }

    const onDeleteModalClose = () => {
        setIsDeleteConfirmModalOpen(false);
        setDeleteTopicErrorMessage(undefined);
        getTopics();
    }

    const deleteTopic = async () => {
        setDeleteTopicErrorMessage(undefined);
        setLoadingDelete(true);

        try{
            await archivesApi.deleteTopic(selectedTopic?.id ?? 0, currentArchive?.id ?? 0);            
            onDeleteModalClose();
        }catch (ex: any) {
            if(ex.subCode === 40001)
                setDeleteTopicErrorMessage(t('deleteErrorMessage'));
            else
                setDeleteTopicErrorMessage(t('common:genericErrorApi'));
        }finally{
            setLoadingDelete(false);
        }
    }

    const getAccordion = () => {
        return topics.map((topic, i) => {
            const key = Object.keys(topic.metadata)[0];
            const resultObject = {
                key: key,
                value: (topic.metadata[key] as string[]).map((item: string) => item.trim()).join(', ')
              };

            return (
                <Accordion title={`${topic.name}`} 
                    key={i}
                    customHeader={actionForProfile(topic)}
                >  
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        <div className={classNames.accordionDetailcontainer}> 
                            <Label className={classNames.accordionDetailKey}>{resultObject.key}</Label>
                            <Label className={classNames.accordionDetailValue}>{resultObject.value}</Label>
                        </div>
                        {topic.keyPhrases &&
                            <div className={classNames.accordionDetailcontainer} style={{marginTop: 5}}>
                                <Label className={classNames.accordionDetailKey}>{t('KeyPhraseLab')}</Label>
                                <Label className={classNames.accordionDetailValue}>{topic.keyPhrases}</Label>
                            </div>  
                        }            
                    </div>
                </Accordion>
            )            
        })
    }

    return (
        <>
            <Stack className={classNames.root}>
                <StackItem style={{display: 'flex', marginBottom: '30px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <SearchBox
                            styles={{ root: { width: 300, marginRight: '15px'} }}
                            placeholder={t('topicSearch')}
                            onSearch={(newValue) => {
                                getTopics(newValue);
                            }}
                            onClear={() => {
                                getTopics();
                            }}
                        />
                        <PrimaryButton
                            iconProps={{ iconName: 'Add' }}
                            styles={{ icon: { height: 'auto' } }}
                            onClick={() => setCreateTopicModalIsOpen(true)}
                            text={t('createNewTopic')}
                        />
                    </div>
                </StackItem>
                {loading ?  
                    <div style={{ height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}>
                        <Spinner size={SpinnerSize.large} />
                    </div> : topics.length > 0 ?
                    <StackItem style={{
                        maxHeight: 'inherit',
                        height: 'calc(100vh - 340px)',
                        position: 'relative'
                    }}>
                        <ScrollablePane>
                            { getAccordion() }
                        </ScrollablePane>                
                    </StackItem> : 
                    <TeamsImage
                        imageName={ImageName.SurprisedWoman}
                        style={{
                            height: '200px',
                            width: '400px',
                            alignSelf: 'center',
                            marginTop: '50px'
                        }}
                        caption={t('noTopic')}
                    />
                }  
            </Stack> 
            {createTopicModalIsOpen &&
                <CreateTopicModal
                    isOpen={createTopicModalIsOpen}
                    onSave={() => getTopics()}
                    onClose={() => onCreateModalClose()}
                    archiveId={currentArchive?.id ? currentArchive.id : 0}
                    topic={selectedTopic}
                />
            }
            {isDeleteConfirmModalOpen && selectedTopic &&
                <DeleteTopicModal
                    title={t("deleteTopicTitle")}
                    subtitle={t("deleteTopicMessage")}
                    isOpen={isDeleteConfirmModalOpen}
                    onClose={() => onDeleteModalClose()}
                    onConfirm={() => deleteTopic()}
                    errorMessage={deleteTopicErrorMessage}
                    loading={loadingDelete}
                />
            }            
        </>
    )
}