import { styled } from "@fluentui/react";
import { getStyles } from "./customWidgetDate.styles";
import { ICustomWidgetDatePropsStyles, ICustomWidgetDateStyles, ICustomWidgetDateProps } from "./customWidgetDate.types";
import { CustomWidgetDateBase } from "./customWidgetDate.base";

const CustomWidgetDate = styled<ICustomWidgetDateProps, ICustomWidgetDatePropsStyles, ICustomWidgetDateStyles>(
    CustomWidgetDateBase,
    getStyles,
    undefined,
    { scope: 'CustomWidgetDate' }
);

export default CustomWidgetDate;