import { CurrentUserInfo } from '../../docLab/services/users/user.contracts';
import { usersApi } from '../../docLab/services/users/users.api';


class CurrentUser {
    private currentUserProfile?: CurrentUserInfo;
    public currentUserId?: string;

   constructor() {
        this.currentUserProfile = undefined;
    }

    public get getUserRoles(){
        return this.currentUserProfile?.roles
    }

    async getUserProfile(currentUserId: string) {
        const response = await usersApi.getCurrentUserInfo(currentUserId);
        this.currentUserProfile = response;
        this.currentUserId = currentUserId;
    }
    //commento
}

export default new CurrentUser();