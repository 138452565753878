/*eslint-disable sonarjs/cognitive-complexity*/
import { IRecycleBinProps, IRecycleBinPropsStyles, IRecycleBinStyles } from "./recycleBin.types"
import { classNamesFunction, Icon, IconButton, Spinner, SpinnerSize, Stack, IColumn, ShimmeredDetailsList, PrimaryButton, SelectionMode } from "@fluentui/react";
import { ConstrainMode, Selection } from '@fluentui/react/lib/DetailsList';
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { TFunction, useTranslation } from "react-i18next";
import { loadRecycleBin, setIsDeeplink, useArchiveContent, selectItemsOnDelete, setArchivesHasUnivocityRole } from "../../../features/archiveContent";
import { ArchiveItem, IndexingStatus } from "../../../models/archiveItem";
import FileIconCell from "../../../../common/components/fileIconCell/fileIconCell";
import FileNameCell from "../../files/archiveContent/fileNameCell/fileNameCell";
import { useDocLabDispatch } from "../../../docLabStore";
import { Helpers } from "../../../../utilities/helpers";
import { DateTime } from 'luxon';
import { useOnMount } from "../../../../utilities/hooks";
import { ToolbarAction, setToolbarActions } from "../../../features/toolbarActions";
import { ArchiveRoleId } from "../../../models/constants";
import { ActionEntry, getArchiveContentActionsByIds } from "../../../utilities/archiveContentActions";
import { ToolbarDelegateType } from "../../../hooks/useActionDelegate";
import { setSelectedItems } from "../../../../knowledgeDS/features/feedbackList";
import { archivesApi } from "../../../services/archives/archives.api";

const getClassNames = classNamesFunction<IRecycleBinPropsStyles, IRecycleBinStyles>();

const getModifiedDate = (item: ArchiveItem) => {
    return Helpers.formatToRelativeDate(item?.deletedOn ? item.deletedOn : "");
}

export const RecycleBinBase = (props: IRecycleBinProps) => {
    const dispatch = useDocLabDispatch();
    const { isLoading, orderBy, isAscending, archive, selectedItemsActionIds, selectedItems, deletedFiles, currentPageNumber, noMore } = useArchiveContent();
    const currentArchive = archive;
    const { t } = useTranslation(['recycleBinMetadata']);
    const [deferredLoading, setDeferredLoading] = useState(false);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, isEmptyList: deletedFiles?.length === 0 });
    const [loading, setloading] = useState(true);

    useOnMount(() => {
        dispatch(setSelectedItems([]));
        const action = dispatch(loadRecycleBin({
            folderId: currentArchive?.rootFolderId ?? 0,
            orderBy: "Name",
            isAscending: true,
            resetPage: true,
            deeplink: true,
        }));
        Helpers.deferred(action, setDeferredLoading, 200, 250);
        registerEvent();
        setloading(false);
    });

    const mapToolbarActions = useCallback((actions: ActionEntry[], t: TFunction<string[]>): ToolbarAction[] => {
        const filteredToolbarActions = actions.filter(action => (action.toBeIndexedCheck && selectedItems[0].indexed) || !action.toBeIndexedCheck);
        return filteredToolbarActions.map(action => {
            let subActions = undefined;
            if (action.subActionItems)
                subActions = mapToolbarActions(action.subActionItems, t);

            return {
                id: action.id,
                type: ToolbarDelegateType.files,
                label: action.label ? t(`archiveContentActions:${action.label}`) : "",
                icon: action.icon,
                subActions: subActions
            }
        });
    }, [selectedItems]);

    useEffect(() => {
        const actions = mapToolbarActions(getArchiveContentActionsByIds(selectedItemsActionIds), t);
        dispatch(setToolbarActions(actions));
    }, [selectedItemsActionIds, dispatch, t, mapToolbarActions]);

    const recycleBinContent = () => {
        try {
            if (!currentArchive)
                return;
            const action = dispatch(loadRecycleBin({ resetPage: true }));
            Helpers.deferred(action, setDeferredLoading, 200, 250);
        }
        catch (error) {
            console.error(error)
        }
    }

    const registerEvent = useCallback(() => {
        const listElm = document.querySelector('#detailsListZone .ms-DetailsList-contentWrapper');
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50) {
                const button = document.getElementById("click");
                button && button.click();
            }
        });
    }, []);

    useEffect(() => {
        if (!isLoading) registerEvent()
    }, [isLoading]);//eslint-disable-line react-hooks/exhaustive-deps

    const sortByColumn = useCallback((_: React.MouseEvent, column: IColumn) => {
        if (isLoading)
            return;

        const action = dispatch(loadRecycleBin({
            orderBy: column.fieldName,
            isAscending: column.isSortedDescending,
            resetPage: true
        }));
        dispatch(setIsDeeplink(false));
        Helpers.deferred(action, setDeferredLoading, 200, 250);
    }, [isLoading, dispatch]);

    const emptyFolder = useCallback(() => {
        if (deletedFiles?.length !== 0)
            return null;

        return (
            <div className={classNames.emptyFolder}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyFolder")}
                />
            </div>
        );
    }, [classNames.emptyFolder, deletedFiles?.length, t]);

    const loadMore = useCallback(() => {
        if (isLoading || noMore)
            return;
        const action = dispatch(loadRecycleBin({ resetPage: false }));
        Helpers.deferred(action, setDeferredLoading, 200, 250);
    }, [dispatch, isLoading, noMore]);

    const columns: IColumn[] = [
        {
            key: 'file-type',
            name: 'File Type',
            iconName: 'Page',
            isIconOnly: true,
            fieldName: '',
            minWidth: 20,
            maxWidth: 20,
            styles: {
                cellTitle: {
                    placeContent: 'center'
                }
            },
            onRender: function getItemCell(item: ArchiveItem) {
                const extIndex = item.name.lastIndexOf('.');
                const ext = extIndex !== -1 ? item.name.substring(extIndex) : "";
                return <FileIconCell fileExtension={ext} isFolder={item.isFolder} />
            }
        },
        {
            key: 'Name',
            name: t("columns.name"),
            fieldName: 'Name',
            minWidth: 190,
            maxWidth: 300,
            isSorted: orderBy === 'Name',
            isSortedDescending: !isAscending,
            isRowHeader: true,
            isResizable: true,
            onColumnClick: sortByColumn,
            onRender: function getItemName(item: ArchiveItem) {
                const totalHours = DateTime.fromISO(item.deletedOn ? item.deletedOn : '', { zone: 'utc' })
                    .diffNow().negate().as('hours');
                return <FileNameCell
                    name={item.name}
                    isNewItem={totalHours < 24}
                />
            }
        },
        {
            key: 'DeleteddOn',
            name: t("columns.deletedon"),
            fieldName: 'DeletedOn',
            minWidth: 130,
            maxWidth: 130,
            isResizable: true,
            isSorted: orderBy === 'DeletedOn',
            isSortedDescending: !isAscending,
            onColumnClick: sortByColumn,
            onRender: getModifiedDate,
        },
        {
            key: 'DeletedBy',
            name: t("columns.deletedby"),
            fieldName: 'DeletedBy',
            minWidth: 90,
            maxWidth: 120,
            isResizable: true,
            isCollapsible: true,
            isSorted: orderBy === 'DeletedBy',
            isSortedDescending: !isAscending,
            onColumnClick: sortByColumn,
            onRender: function getDeletedBy(item: ArchiveItem) {
                return <span>{item.deletedBy}</span>;
            }
        }
    ]

    useOnMount(() => {
        if (currentArchive?.id)
            setUserArchiveUnivocityRules(currentArchive.id);
    }); //eslint-disable-line react-hooks/exhaustive-deps

    const setUserArchiveUnivocityRules = async (id: number) => {
        try {
            const numerOfRules = await archivesApi.getArchiveUnivocityRules(id);
            dispatch(setArchivesHasUnivocityRole({ archivesHasUnivocityRole: numerOfRules.length > 0 }));
        }
        catch (error) {
            console.log("ERROR: ", error);
        }
    }

    const selection = useMemo(() => new Selection({
        onSelectionChanged: () => {
            const selectionDetails = selection.getSelection();
            dispatch(selectItemsOnDelete(selectionDetails.map(i => {
                const item = i as ArchiveItem;
                return {
                    /*eslint-disable @typescript-eslint/no-non-null-assertion*/
                    id: item.fileId!,
                    name: item.name,
                    isFolder: item.isFolder,
                    indexed: !item.isFolder && item.indexingStatus === IndexingStatus.Deleted,
                    deleted: item.indexingStatus === IndexingStatus.Deleted,
                    archiveRoleId: item.archiveRoleId,
                    sourceType: item.sourceType,
                    approvalStatus: item.approvalStatus,
                    folderId: item.folderId,
                    metadata: item.metadata
                }
            })));
        }
    }), [dispatch]);

    return (
        <div className={classNames.root} id={"detailsListZone"}>
            <Stack className={classNames.title} horizontal verticalAlign={'center'} styles={{ root: { marginBottom: '15px' } }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingRight: '40px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Icon iconName={"RecycleBin"} className={classNames.iconTitle} />
                        <span style={{ paddingLeft: 10 }}>{t('title')}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <IconButton
                            iconProps={{ iconName: "Refresh" }}
                            onClick={() => { recycleBinContent() }}
                        />
                    </div>
                </div>
            </Stack>
            {loading ?
                <Spinner size={SpinnerSize.large} /> :
                <ShimmeredDetailsList
                    items={deletedFiles?.length ? deletedFiles : []}
                    columns={columns}
                    getKey={(item: ArchiveItem) => item && `${item.isFolder ? 'f' : ''}${item.id}`}
                    selection={selection}
                    selectionMode={ArchiveRoleId.Professional === archive?.currentUserRoleId ? SelectionMode.single : SelectionMode.multiple}
                    enableShimmer={currentPageNumber === 0 && (deferredLoading || isLoading)}
                    constrainMode={ConstrainMode.unconstrained}
                    styles={classNames.subComponentStyles.shimmeredDetailsList}
                    detailsListStyles={classNames.subComponentStyles.detailsList}
                    onRenderDetailsFooter={emptyFolder}
                />
            }
            <div className={classNames.load}>
                <PrimaryButton id={noMore ? "noClick" : "click"} onClick={loadMore} />
            </div>
        </div>
    )
}