import { IStyleFunction } from "@fluentui/react"
import { IPhaseFormItemPropsStyles, IPhaseFormItemStyles } from "./phaseFormItem.types";

export const PhaseFormItemGlobalClassNames = {
    phaseContainer: 'edi-activityLab-phaseForm-phaseContainer',
    upperSection: 'edi-activityLab-phaseForm-upperSection',
    lowerSection: 'edi-activityLab-phaseForm-lowerSection',
    upperSectionItem: 'edi-activityLab-phaseForm-upperSectionItem',
    descriptionContainer: 'edi-activityLab-phaseForm-descriptionContainer',
    buttonContainer: 'edi-activityLab-phaseForm-buttonContainer',
    arrowStyle: 'edi-activityLab-phaseForm-arrowStyle',
    arrowsContainer: 'edi-activityLab-phaseForm-arrowsContainer'
}

export const getStyles: IStyleFunction<IPhaseFormItemPropsStyles, IPhaseFormItemStyles> = (props: IPhaseFormItemPropsStyles): IPhaseFormItemStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        phaseContainer:[PhaseFormItemGlobalClassNames.phaseContainer,{
            display:'flex',
            flexFlow: 'column wrap',
            marginBottom: 30,
            borderTop: '1px solid '+palette.themePrimary,
            paddingTop: 20
        }],
        upperSection: [PhaseFormItemGlobalClassNames.upperSection,{
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            columnGap: '30px'
        }],
        upperSectionItem: [PhaseFormItemGlobalClassNames.upperSectionItem,{
            flexGrow: 1,
            flexBasis: '20%',
            '@media(max-width: 887px)': {  
              flexBasis: "40%"
            },
        }],
        lowerSection: [PhaseFormItemGlobalClassNames.lowerSection,{
            display:'flex',
            flexFlow: 'row wrap',
            gap: '100px',
            alignItems: 'center'
        }],
        descriptionContainer: [PhaseFormItemGlobalClassNames.descriptionContainer,{
            flexGrow: 3
        }],
        buttonContainer: [PhaseFormItemGlobalClassNames.buttonContainer,{
            marginTop: 20, //perchè la label dell'input text di fianco è alta 28px
            marginRight: 50
        }],
        arrowStyle: [PhaseFormItemGlobalClassNames.arrowStyle,{
            fontSize: '20px !important',
            cursor:'pointer',
            color: palette.themePrimary,
            userSelect:'none'
        }],
        arrowsContainer:[PhaseFormItemGlobalClassNames.arrowsContainer,{
            display:'flex',
            marginTop: 15,
            justifyContent:'space-around'
        }]
    };
}