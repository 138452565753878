/*eslint-disable sonarjs/cognitive-complexity */
import { classNamesFunction, DefaultButton, PrimaryButton } from "@fluentui/react";
import React, { useState } from "react";
import { FileFormatExtension } from "../../../utilities/constants";
import { useOnMount } from "../../../utilities/hooks";
import { IUploadButtonProps, IUploadButtonPropsStyles, IUploadButtonStyles } from "./uploadButton.types";

const getClassNames = classNamesFunction<IUploadButtonPropsStyles, IUploadButtonStyles>();

export const UploadButtonBase = (props: IUploadButtonProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const [acceptedExtensions, setAcceptedExtensions] = useState<string>();

  useOnMount(() => {
    let extensionList: string[] = [];
    props.acceptedFile.forEach(val => {
      let valueToAdd;
      
      if(props.checkExtension) //serve per evitare di inserire estensioni vietate che hanno lo stesso mimetype di estensioni consentite
        valueToAdd = FileFormatExtension.filter(a => a.extension === val).map(a => a.extension);
      else
        valueToAdd = FileFormatExtension.filter(a => a.key === val).map(a => a.extension);

      if(valueToAdd.length > 1){
        valueToAdd.forEach(v =>{
          if(!extensionList.includes(v))
          extensionList = [...extensionList, v];
        })
      }

      if (valueToAdd.length === 1 ){
        extensionList = [...extensionList, ...valueToAdd];
      }
    });

    setAcceptedExtensions(extensionList.toString())
  });

  return (
    <>
      {props.isDefaultButton ?
        <DefaultButton
          className={classNames.uploadButton}
          disabled={props.isDisabled}>
          {props.label}
          <input
            id='upload'
            className={classNames.inputFile}
            type='file'
            title=''
            onChange={ev => { (ev.target.files && props.uploadFile(Array.from(ev.target.files))); (ev.target as HTMLInputElement).value = "" }}
            disabled={props.isDisabled}
            accept={acceptedExtensions}
            multiple={props.multiple}
          />
        </DefaultButton>
        :

        <PrimaryButton
          className={classNames.uploadButton}
          disabled={props.isDisabled}>
          {props.label}
          <input
            id='upload'
            className={classNames.inputFile}
            type='file'
            title=''
            onChange={ev => { (ev.target.files && props.uploadFile(Array.from(ev.target.files))); (ev.target as HTMLInputElement).value = "" }}
            disabled={props.isDisabled}
            accept={acceptedExtensions}
            multiple={props.multiple}
          />
        </PrimaryButton>
      }

    </>

  );
}