import { IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react"
import { multiLineEllipsis } from "../../../../../utilities/constants";
import { IActivitySettingsTemplatePropsStyles, IActivitySettingsTemplateStyles } from "./activitySettingsTemplate.types"

export const ActivitySettingsTemplateGlobalClassNames = {
    root: 'edi-activity-settings-template-root',
    ellipseRow: 'edi-activity-settings-template-ellipseRow',
    nameAndDescriptionContainer: 'edi-activity-settings-template-nameAndDescriptionContainer',
    headerRow: 'edi-activity-settings-template-headerRow',
    wrapRow: 'edi-activity-settings-template-wrapRow',
    nameStyle: 'edi-activity-settings-template-nameStyle',
    descriptionStyle: 'edi-activity-settings-template-descriptionStyle'
}

export const getStyles: IStyleFunction<IActivitySettingsTemplatePropsStyles, IActivitySettingsTemplateStyles> = (props: IActivitySettingsTemplatePropsStyles): IActivitySettingsTemplateStyles => {
    return {
        root: [
            ActivitySettingsTemplateGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        nameStyle: [ActivitySettingsTemplateGlobalClassNames.nameStyle,
        multiLineEllipsis(3),
        {
            margin: '5px 0 10px',
            fontWeight: 'bold'
        }],
        descriptionStyle: [ActivitySettingsTemplateGlobalClassNames.descriptionStyle,
        multiLineEllipsis(5),
        {
            margin: '5px 0'
        }],
        nameAndDescriptionContainer: [
            ActivitySettingsTemplateGlobalClassNames.nameAndDescriptionContainer,
            {
                whiteSpace: 'normal'
            }],
        ellipseRow: [ActivitySettingsTemplateGlobalClassNames.ellipseRow,
        {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }],
        wrapRow: [ActivitySettingsTemplateGlobalClassNames.wrapRow, {
            wordBreak: ' break-word',
            whiteSpace: 'normal'
        }],
        headerRow: [
            ActivitySettingsTemplateGlobalClassNames.headerRow,
            {
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                }
            }
        ],
        subComponentStyles: {
            detailsList: (): IDetailsListStyles => {
                return {
                    root: {},
                    contentWrapper: {},
                    focusZone: {},
                    headerWrapper: {
                        '> .ms-DetailsHeader': {
                            paddingTop: 0,
                            borderBottomColor: 'transparent'
                        }
                    },
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                    },
                    cell: { alignSelf: 'center', textOverflow: 'unset' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
        }
    };
}