import { styled } from "@fluentui/react";
import { getStyles } from "./folderProfilePermissionModal.styles";
import { IFolderProfilePermissionModalPropsStyles, IFolderProfilePermissionModalStyles, IFolderProfilePermissionModalProps } from "./folderProfilePermissionModal.types";
import { FolderProfilePermissionModalBase } from "./folderProfilePermissionModal.base";

const FolderProfilePermissionModal = styled<IFolderProfilePermissionModalProps, IFolderProfilePermissionModalPropsStyles, IFolderProfilePermissionModalStyles>(
    FolderProfilePermissionModalBase,
    getStyles,
    undefined,
    { scope: 'FolderProfilePermissionModal' }
);

export default FolderProfilePermissionModal;