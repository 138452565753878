import { IDetailsColumnStyleProps, IDetailsColumnStyles, IDetailsListStyleProps, IDetailsListStyles, IStyleFunction } from "@fluentui/react"
import { IDetailListDocAnalysisPropsStyles, IDetailListDocAnalysisStyles } from "./detailListDocAnalysis.types"

export const DetailListDocAnalysisGlobalClassNames = {
    root: 'edi-detail-list-doc-analysis-root',
    emptyList: 'edi-detail-list-doc-analysis-emptyList',
    detailListContainer: 'edi-detail-list-doc-analysis-container',
    analysisFieldName: 'edi-detail-list-doc-analysis-analysisFieldName'
}

export const getStyles: IStyleFunction<IDetailListDocAnalysisPropsStyles, IDetailListDocAnalysisStyles> = (props: IDetailListDocAnalysisPropsStyles): IDetailListDocAnalysisStyles => {
    return {
        root: [
            DetailListDocAnalysisGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        load:{
            display: 'none'
        },
        analysisFieldName:[
        DetailListDocAnalysisGlobalClassNames.analysisFieldName,
        {
            cursor:'pointer',
            selectors:{
                ':hover':{
                    textDecoration:'underline'
                }
            }
        }],
        detailListContainer:[
            DetailListDocAnalysisGlobalClassNames.detailListContainer,
        {
            padding: '0 10px'
        }],
        emptyList:[
            DetailListDocAnalysisGlobalClassNames.emptyList,
            {
            position: 'absolute',
            top: '60px',
            width: '150px',
            left: 'calc(50% - 88px)'
        }],
        subComponentStyles:{
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto'
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        flexBasis: 'calc(100vh - 400px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            },
            column: (props: IDetailsColumnStyleProps): IDetailsColumnStyles => {
              return{
                root:{},
                gripperBarVerticalStyle: {},
                cellTooltip: {},
                cellTitle: {
                    cursor:'pointer'
                },
                cellName: {},
                iconClassName: {},
                nearIcon: {},
                accessibleLabel: {},
                sortIcon: {},
                filterChevron: {},
                borderAfterDropping: {},
                noBorderAfterDropping: {},
                borderWhileDragging: {},
                noBorderWhileDragging: {},
            }
        }
        }
    };
}