import { DefaultButton, Dialog, DialogFooter, IButtonStyles, PrimaryButton } from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export interface IRemoveAllButtonProps {
    buttonStyles?: IButtonStyles;
    disabled?: boolean;
    removeItem: () => void;
    message : string;
    buttonText :string;
}

export const RemoveAllButton = (props: IRemoveAllButtonProps) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const { t } = useTranslation(['common']);
    
    return (
        <>
            <PrimaryButton
                    disabled={props.disabled }
                    onClick={() => setShowConfirmDialog(true)}
                    text={props.buttonText}
            />
            <Dialog
                hidden={!showConfirmDialog}
                dialogContentProps={{ title : props.message}}
            >
                <DialogFooter>
                    <DefaultButton  onClick={() => setShowConfirmDialog(false)} text={t('abort')} />
                    <PrimaryButton  onClick={() => {props.removeItem();setShowConfirmDialog(false)}} text={t('continue')} />
                </DialogFooter>
            </Dialog>
        </>
    )
}