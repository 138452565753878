import { styled } from "@fluentui/react";
import { getStyles } from "./keywordList.styles";
import { KeywordListBase } from "./keywordList.base";
import { IKeywordListProps, IKeywordListPropsStyles, IKeywordListStyles } from "./keywordList.types";

const KeywordList = styled<IKeywordListProps, IKeywordListPropsStyles, IKeywordListStyles>(
    KeywordListBase,
    getStyles,
    undefined,
    { scope: 'KeywordList' }
);

export default KeywordList;