import { styled } from "@fluentui/react";
import { DownloadWfDetailsModalBase } from "./downloadWfDetailsModal.base";
import { getStyles } from "./downloadWfDetailsModal.styles";
import { IDownloadWfDetailsModalProps, IDownloadWfDetailsModalPropsStyles, IDownloadWfDetailsModalStyles } from "./downloadWfDetailsModal.types";

const DownloadWfDetailsModal = styled<IDownloadWfDetailsModalProps, IDownloadWfDetailsModalPropsStyles, IDownloadWfDetailsModalStyles>(
    DownloadWfDetailsModalBase,
    getStyles,
    undefined,
    { scope: 'DownloadWfDetailsModal' }
);

export default DownloadWfDetailsModal;