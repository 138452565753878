import { IStyleFunction } from "@fluentui/react"
import { ISearchItemHeaderPropsStyles, ISearchItemHeaderStyles } from "./searchItemHeader.types";

export const SearchItemHeaderGlobalClassNames = {
    root: 'edi-SearchItemHeader-root'
}

export const getStyles: IStyleFunction<ISearchItemHeaderPropsStyles, ISearchItemHeaderStyles> = (props: ISearchItemHeaderPropsStyles): ISearchItemHeaderStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            SearchItemHeaderGlobalClassNames.root,
            {
            }
        ],
        subComponentStyles: {
            iconButton: {
                root: {
                    height: 19
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`
                },
                menuIcon: {
                    display: 'none !important'
                },
                rootExpanded: {
                    backgroundColor: 'inherit'
                },
                rootPressed: {
                    backgroundColor: 'inherit'
                },
                rootHovered: {
                    backgroundColor: 'inherit',
                }
            }
        }
    };
}