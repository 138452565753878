/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable sonarjs/no-duplicate-string*/
import React, { useEffect, useMemo, useState } from "react";
import axios, { CancelToken, CancelTokenSource } from "axios";
import { classNamesFunction, DefaultButton, PrimaryButton, ProgressIndicator } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import FileUploader from "../../../../common/components/fileUploader/fileUploader";
import MultiStepModal from "../../../../common/components/multiStepModal/multiStepModal";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { Helpers } from "../../../../utilities/helpers";
import { useOnMount } from "../../../../utilities/hooks";
import { useArchiveContent } from "../../../features/archiveContent";
import { ArchiveCapacity } from "../../../models/archive";
import { archivesApi } from "../../../services/archives/archives.api";
import DocumentTagsList from "../documentTagsList/documentTagsList";
import FileScheduler from "../fileScheduler/fileScheduler";
import { FileStatus, IFileToUpload, IUploadFileModalProps, IUploadFileModalPropsStyles, IUploadFileModalStyles } from "./uploadFileModal.types";
import FileMetadataOnUpload from "../fileMetadataOnUpload/fileMetadataOnUpload";
import { useDocLabState } from "../../../docLabStore";
import { MimeTypeDictionary } from "../../../services/archives/archives.contracts";
import { ArchiveUnivocityRule, DocVerifySource, FileNameWithMetadata, ResultDocumentsMetadataUnivocityWithError } from "../../../models/archiveUnivocityRule";
import DocVerifyLoadingPage from "../../../../common/components/docVerifyLoadingPage/docVerifyLoadingPage";
import DocVerifyModalFooter from "../../../../common/components/docVerifyModalFooter/docVerifyModalFooter";
import DocVerifyModalBody from "../../../../common/components/docVerifyModalBody/docVerifyModalBody";

export type InputFile = {
  name: string;
  type: string;
  lastModified: string;
  size: string;
}

const getClassNames = classNamesFunction<IUploadFileModalPropsStyles, IUploadFileModalStyles>();

enum UploadFileSteps {
  loading = 0,
  upload = 1,
  end = 2,
  error = 3
}

enum ScheduleFileSteps {
  loading = 0,
  chooseFiles = 1,
  chooseTags = 2,
  schedule = 3,
  end = 4,
  error = 5,
  docVerifyLoading = 6,
  docVerify = 7
}

export const UploadFileModalBase = (props: IUploadFileModalProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const [currentStep, setCurrentStep] = useState(UploadFileSteps.loading);
  const [currentScheduleStep, setCurrentScheduleStep] = useState(ScheduleFileSteps.loading);
  const [itemsToUpload, setItems] = useState<IFileToUpload[]>([]);
  const [fileTypes, setFileTypes] = useState<string[]>([]);
  const [extensionFileTypes, setExtensionFileTypes] = useState<MimeTypeDictionary>();
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [errorCapacityMessage, setErrorCapacityMessage] = useState<string>("");
  const [archiveCapacity, setArchiveCapacity] = useState<ArchiveCapacity>();
  const { archive, currentFolderId, uploadFilesModalShown } = useArchiveContent();
  const { t } = useTranslation(['uploadFileModal', 'common']);
  const [freeArchive, setFreeArchive] = useState<string>("");
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const docLabState = useDocLabState();
  const [archiveUnivocityRules, setArchiveUnivocityRules] = useState<ArchiveUnivocityRule[]>([]);
  const [documentUnivocityError, setDocumentUnivocityError] = useState<ResultDocumentsMetadataUnivocityWithError>();
  const [loadingDoc, setLoadingDoc] = useState<boolean>(true);
  const [filesNameWithMetadata, setFilesNameWithMetadata] = useState<FileNameWithMetadata[]>([]);
  const [filteredItems, setFilteredItems] = useState<IFileToUpload[]>([]);
  const [isUserBlocked, setIsUserBlocked] = useState<boolean>(false);

  useOnMount(() => {
    const cancelToken = axios.CancelToken.source();
    setCancelToken(cancelToken);
    return () => {
      cancelToken.cancel();
      setCancelToken(cancelToken);
    }
  });

  useEffect(() => {
    if (!archive)
      return;

    archivesApi.getAllowedFileTypes(archive.id)
      .then(types => {
        setFileTypes([...new Set(Object.values(types))]);
        setExtensionFileTypes(types);
      });

    getArchiveCapacity();
  }, [archive]); //eslint-disable-line react-hooks/exhaustive-deps

  const numberOfPropertiesShortSchema = useMemo(() => {
    if (archive?.metadataShortJsonSchema !== null && archive?.metadataShortJsonSchema !== undefined && archive?.metadataShortJsonSchema.properties) {
      const keys = Object.keys(archive?.metadataShortJsonSchema.properties);
      if (archive?.metadataShortJsonSchema.dependencies)
        keys.push("dependencies");
      return keys.length;
    }

    return 0;
  }, [archive?.metadataShortJsonSchema]);

  const getArchiveCapacity = async () => {
    if (!archive)
      return;

    try {
      const capacity: ArchiveCapacity = await archivesApi.getArchiveCapacity(archive.id);
      const numerOfRules = await archivesApi.getArchiveUnivocityRules(archive.id);
      setArchiveUnivocityRules(numerOfRules);
      if (!capacity || (capacity && capacity.usedCapacity >= capacity.capacity)) {
        const message = t('uploadUnprocessableError') + t('capacity.used') + (capacity.usedCapacity * (1 / (1024 * 1024 * 1024))).toFixed(2) + "/" + (capacity.capacity * (1 / (1024 * 1024 * 1024))).toFixed(2) + " GB";
        if (archive?.metadataShortJsonSchema === undefined || archive?.metadataShortJsonSchema === null)
          setCurrentStep(UploadFileSteps.error);
        else
          setCurrentScheduleStep(ScheduleFileSteps.error);
        setErrorCapacityMessage(message);
      }
      else {
        const userBlocked = await archivesApi.getUserInhibitionPermission(currentFolderId);
        setIsUserBlocked(userBlocked);

        if (userBlocked) {
          setErrorCapacityMessage(t('uploadInhibitionError'));
          if (archive?.metadataShortJsonSchema === undefined || archive?.metadataShortJsonSchema === null)
            setCurrentStep(UploadFileSteps.error);
          else
            setCurrentScheduleStep(ScheduleFileSteps.error);
        }
        else {
          setArchiveCapacity(capacity);
          setFreeArchive(Helpers.formatSize(capacity.capacity - capacity.usedCapacity));
          if (archive?.metadataShortJsonSchema === undefined || archive?.metadataShortJsonSchema === null)
            setCurrentStep(UploadFileSteps.upload);
          else if (currentScheduleStep === ScheduleFileSteps.loading)
            setCurrentScheduleStep(ScheduleFileSteps.chooseFiles);
        }
      }
    }
    catch (error) {
      const message = t('uploadGenericError')
      if (archive?.metadataShortJsonSchema === undefined || archive?.metadataShortJsonSchema === null)
        setCurrentStep(UploadFileSteps.error);
      else
        setCurrentScheduleStep(ScheduleFileSteps.error);
      setErrorCapacityMessage(message);
    }
  }

  const onFileUpdated = (files: IFileToUpload[]) => {
    setItems(files);
    if (archive?.metadataShortJsonSchema === undefined || archive?.metadataShortJsonSchema === null)
      getArchiveCapacity();
  }

  const onUploadEvent = (result: boolean) => {
    setUploadLoading(result);
  }

  const hasDuplicateFileNames = (files: IFileToUpload[]) => {
    if (files.length > 0) {
      const fileNamesSet = new Set();
      for (const file of files) {
        if (fileNamesSet.has(file.fileName))
          return true; //already exist a file with the same fileName

        fileNamesSet.add(file.fileName);
      }
    }
    return false; //not exist a file with the same fileName
  }

  const sendFiles = async (formData: FormData, token?: CancelToken) => {
    await archivesApi.uploadFiles(formData, token);
  }

  const firstPageBodyModal = () => {
    return (
      <FileUploader
        multiUpload
        showMessages
        showMessageError={false}
        isFileDraggable
        fileTypes={fileTypes}
        showFileNameOnly={false}
        maxNumFilesUploadable={10000}
        archiveId={archive?.id || 0}
        onFileUpdated={onFileUpdated}
        currentFolderId={currentFolderId}
        sendFiles={(formData) => sendFiles(formData, cancelToken?.token)}
        multiple={true}
        isUploading={onUploadEvent}
        fileOnInit={archive?.metadataShortJsonSchema === undefined || archive?.metadataShortJsonSchema === null ? undefined : itemsToUpload.length === 0 ? undefined : itemsToUpload}
        blockUpload={archive?.metadataShortJsonSchema === undefined || archive?.metadataShortJsonSchema === null ? false : true}
        extensionFileTypes={extensionFileTypes}
      />
    );
  }

  const secondPageBodyModal = () => {
    return (
      <div className={classNames.secondFooterContainer}>
        <TeamsImage imageName={ImageName.Sandglass} className={classNames.wellDoneImage} fullContainer scale={0.8} />
        <div className={classNames.footer}>
          {t('endMessage')}
          <div>
            <PrimaryButton text={t('footer.end')}
              allowDisabledFocus
              onClick={props.onClose}
              styles={{ root: { padding: '0px 80px', marginTop: '20px' } }}
            />
          </div>
        </div>
      </div>
    )
  }

  const onNextClick = () => {
    if (documentUnivocityError?.errorOnFileMetadataList.length !== 0 || documentUnivocityError?.notUnivocityDocumentList.length !== 0)
      setCurrentScheduleStep(ScheduleFileSteps.docVerify);
    else
      setCurrentScheduleStep(ScheduleFileSteps.schedule);
  }

  useEffect(() => {
    if (documentUnivocityError !== undefined)
      setLoadingDoc(false);
  }, [documentUnivocityError]);

  const docVerifyLoadingPage = () => {
    return (
      <DocVerifyLoadingPage
        folderId={[currentFolderId]}
        files={filesNameWithMetadata}
        onVerifyResult={(item) => setDocumentUnivocityError(item)}
        onNext={onNextClick}
        onClose={props.onClose}
        loadingData={loadingDoc}
      />
    );
  }

  const errorPageBodyModal = () => {
    return (
      <div className={classNames.secondFooterContainer}>
        <TeamsImage
          imageName={ImageName.Error1}
          className={classNames.wellDoneImage}
          fullContainer scale={0.8}
          caption={errorCapacityMessage}
        />
      </div>
    )
  }

  const loadingPageBodyModal = () => {
    return (
      <div className={classNames.spinner}>
        <TeamsSpinner />
      </div>
    )
  }

  const footer = useMemo(() => {
    return (
      <div className={classNames.subfirstFooterContainer}>
        <div className={classNames.progressIndicatorbody}>
          <ProgressIndicator
            label={t('capacity.free') + freeArchive}
            description={t('capacity.total') + ((archiveCapacity === undefined) ? 0 : archiveCapacity.capacity * (1 / (1024 * 1024 * 1024))).toFixed(2) + " GB"}
            percentComplete={(archiveCapacity) ? archiveCapacity.usedCapacity / archiveCapacity?.capacity : 0}
            styles={{
              itemName: classNames.progressIndicatoritemName,
              itemDescription: classNames.progressIndicatoritemDescription,
            }}
          />
        </div>
        <div className={classNames.firstFooterContainer}>
          <DefaultButton text={t('footer.abort')}
            style={{ marginRight: '2px' }}
            allowDisabledFocus
            onClick={props.onClose}
          />
          <PrimaryButton text={t('footer.confirm')}
            allowDisabledFocus
            onClick={() => { setCurrentStep(UploadFileSteps.end); }}
            disabled={itemsToUpload.length < 1 || uploadLoading}
          />
        </div>
      </div>
    )
  }, [classNames, props.onClose, itemsToUpload, t, archiveCapacity, freeArchive, uploadLoading]);

  const errorfooter = useMemo(() => {
    return (
      <div className={classNames.footer}>
        <PrimaryButton
          text={t('common:close')}
          onClick={props.onClose}
        />
      </div>
    );
  }, [props.onClose, t, classNames.footer]);

  const advancedSecondPageBodyModal = () => {
    return (
      <DocumentTagsList
        shortJsonSchema={archive?.metadataShortJsonSchema}
        documents={itemsToUpload}
      />
    );
  }

  const metadataCompilationSecondPageBodyModal = () => {
    return (
      <FileMetadataOnUpload
        jsonSchema={archive?.metadataShortJsonSchema}
        files={itemsToUpload}
        onFileUpdated={onFileUpdated}
      />
    );
  }

  const advancedThirdPageBodyModal = () => {
    return (
      <FileScheduler
        multiUpload={true}
        showMessages={true}
        showMessageError={false}
        showFileNameOnly={false}
        maxNumFilesUploadable={10000}
        fileTypes={fileTypes}
        archiveId={archive?.id || 0}
        currentFolderId={currentFolderId}
        onFileUpdated={onFileUpdated}
        isUploading={onUploadEvent}
        filesOnInit={filteredItems.length === 0 ? itemsToUpload : filteredItems}
        isMetadataPopulation={numberOfPropertiesShortSchema === 1 ? false : true}
        extensionFileTypes={extensionFileTypes}
      />
    );
  }

  const closeVerifyPage = () => {
    if (documentUnivocityError === undefined)
      return;

    if (documentUnivocityError.isUserBlocked) {
      const itemsNotInError = itemsToUpload.filter(item => {
        return !documentUnivocityError.notUnivocityDocumentList.some(thirdItem => thirdItem.fileName === item.fileName);
      });

      if (documentUnivocityError.errorOnFileMetadataList.length > 0 || itemsNotInError.length === 0)
        backOnMetadataChangePage();
      else {
        setFilteredItems(itemsNotInError);
        setCurrentScheduleStep(ScheduleFileSteps.schedule);
      }
    }
    else {
      if (documentUnivocityError.errorOnFileMetadataList.length > 0)
        backOnMetadataChangePage();
      else
        setCurrentScheduleStep(ScheduleFileSteps.schedule);
    }
  }

  const backOnMetadataChangePage = () => {
    setLoadingDoc(true);
    setDocumentUnivocityError(undefined);
    setCurrentScheduleStep(ScheduleFileSteps.chooseTags);
  }

  const docVerifyBody = () => {
    const emptyError: ResultDocumentsMetadataUnivocityWithError = { errorOnFileMetadataList: [], isUserBlocked: true, notUnivocityDocumentList: [] }
    return (
      <DocVerifyModalBody
        item={documentUnivocityError ? documentUnivocityError : emptyError}
        requestSource={DocVerifySource.Upload}
      />
    );
  }

  const docVerifyFooter = () => {
    if (documentUnivocityError?.isUserBlocked !== undefined) {
      return (
        <DocVerifyModalFooter
          isUserBlocked={documentUnivocityError.isUserBlocked}
          documentUnivocityError={documentUnivocityError}
          onBack={backOnMetadataChangePage}
          onClose={() => closeVerifyPage()}
          onNext={() => setCurrentScheduleStep(ScheduleFileSteps.schedule)}
        />
      );
    }
  }

  const advancedFooter = useMemo(() => {
    return (
      <div className={classNames.advancedFooterContainer}>
        <span className={classNames.errorMessage}>{hasDuplicateFileNames(itemsToUpload) && t('uploadConflictError')}</span>
        <div>
          <DefaultButton text={t('footer.abort')}
            style={{ marginRight: '5px' }}
            allowDisabledFocus
            onClick={props.onClose}
          />
          <PrimaryButton text={t('footer.confirm')}
            allowDisabledFocus
            onClick={() => {
              switch (currentScheduleStep) {
                case ScheduleFileSteps.chooseFiles:
                  setCurrentScheduleStep(ScheduleFileSteps.chooseTags);
                  break;
                case ScheduleFileSteps.chooseTags:
                  setCurrentScheduleStep(ScheduleFileSteps.schedule);
                  break;
                case ScheduleFileSteps.schedule:
                  setCurrentScheduleStep(ScheduleFileSteps.end);
                  break;
                default:
                  setCurrentScheduleStep(ScheduleFileSteps.error);
                  break;
              }
            }}
            disabled={itemsToUpload.length < 1 || uploadLoading || hasDuplicateFileNames(itemsToUpload)}
          />
        </div>
      </div>
    )
  }, [classNames, currentScheduleStep, props.onClose, t, itemsToUpload, uploadLoading]);

  const buildFilesNameWithMetadata = () => {
    const filesNameWithMetadataTemp: FileNameWithMetadata[] = [];

    itemsToUpload.forEach(file => {
      const documentMetdata = docLabState.archiveContent.documentUploadMetadata.find(m => m.documentId === file.id);
      if (documentMetdata !== null) {
        const fileNameWithMetadataTemp: FileNameWithMetadata = {
          fileId: undefined,
          fileName: file.fileName,
          metadata: documentMetdata?.documentMetadata,
        }

        filesNameWithMetadataTemp.push(fileNameWithMetadataTemp);
      }
    });
    setFilesNameWithMetadata(filesNameWithMetadataTemp);
    setCurrentScheduleStep(ScheduleFileSteps.docVerifyLoading);
  }

  const advancedFooterBack = useMemo(() => {
    return (
      <div className={classNames.advancedFooterContainerBack}>
        <div style={{ color: 'rgb(196, 49, 75)' }}>{t('requiredField')}</div>
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignContent: 'center' }}>
          <DefaultButton text={t('footer.back')}
            style={{ marginRight: '5px' }}
            allowDisabledFocus
            onClick={() => {
              if (currentScheduleStep === ScheduleFileSteps.chooseTags)
                setCurrentScheduleStep(ScheduleFileSteps.chooseFiles);
              else
                setCurrentScheduleStep(ScheduleFileSteps.error);
            }}
          />
          <PrimaryButton text={t('footer.confirm')}
            allowDisabledFocus
            onClick={() => {
              switch (currentScheduleStep) {
                case ScheduleFileSteps.chooseFiles:
                  setCurrentScheduleStep(ScheduleFileSteps.chooseTags);
                  break;
                case ScheduleFileSteps.chooseTags:
                  if (archiveUnivocityRules.length > 0)
                    buildFilesNameWithMetadata();
                  else
                    setCurrentScheduleStep(ScheduleFileSteps.schedule);
                  break;
                case ScheduleFileSteps.schedule:
                  setCurrentScheduleStep(ScheduleFileSteps.end);
                  break;
                default:
                  setCurrentScheduleStep(ScheduleFileSteps.error);
                  break;
              }
            }}
            disabled={
              itemsToUpload.length < 1 ||
              uploadLoading ||
              !itemsToUpload.every(item => item.fileStatus !== FileStatus.FileMetadataRequired && item.fileStatus !== undefined) ||
              !docLabState.archiveContent.documentUploadMetadata.every(item => item.documentErrorMetadata.length === 0)
            }
          />
        </div>
      </div>
    )
  }, [classNames.advancedFooterContainerBack, currentScheduleStep, t, itemsToUpload.length, uploadLoading, docLabState.archiveContent.documentUploadMetadata]); //eslint-disable-line react-hooks/exhaustive-deps

  const steps = [
    {
      bodyOnly: true,
      body: loadingPageBodyModal()
    },
    {
      title: t('title'),
      body: firstPageBodyModal(),
      footer: footer
    },
    {
      bodyOnly: true,
      body: secondPageBodyModal()
    },
    {
      title: t('common:error'),
      body: errorPageBodyModal(),
      footer: errorfooter
    }
  ];

  const scheduleSteps = [
    {
      bodyOnly: true,
      body: loadingPageBodyModal()
    },
    {
      title: t('title'),
      body: firstPageBodyModal(),
      footer: advancedFooter
    },
    {
      title: numberOfPropertiesShortSchema === 1 ? t('titleTag') : t('titleMetadata'),
      body: numberOfPropertiesShortSchema === 1 ? advancedSecondPageBodyModal() : metadataCompilationSecondPageBodyModal(),
      footer: numberOfPropertiesShortSchema === 1 ? advancedFooter : advancedFooterBack
    },
    {
      title: t('title'),
      body: advancedThirdPageBodyModal(),
      footer: advancedFooter
    },
    {
      bodyOnly: true,
      body: secondPageBodyModal()
    },
    {
      title: t('common:error'),
      body: errorPageBodyModal(),
      footer: errorfooter
    },
    {
      bodyOnly: true,
      body: docVerifyLoadingPage()
    },
    {
      title: t('verificationResultTitle'),
      body: docVerifyBody(),
      footer: docVerifyFooter()
    }
  ];

  return (
    <>
      {!fileTypes && !archiveCapacity && <TeamsSpinner />}
      {fileTypes &&
        <MultiStepModal
          subtitle={currentScheduleStep !== ScheduleFileSteps.chooseTags && currentScheduleStep !== ScheduleFileSteps.docVerify && currentScheduleStep !== ScheduleFileSteps.docVerifyLoading && !isUserBlocked ? t('subtitle') : ''}
          isOpen={uploadFilesModalShown}
          showCloseIcon={(currentStep !== UploadFileSteps.error || currentScheduleStep !== ScheduleFileSteps.error) && currentScheduleStep !== ScheduleFileSteps.docVerify}
          onCloseClick={props.onClose}
          steps={archive?.metadataShortJsonSchema === undefined || archive?.metadataShortJsonSchema === null ? steps : scheduleSteps}
          activeStep={archive?.metadataShortJsonSchema === undefined || archive?.metadataShortJsonSchema === null ? currentStep : currentScheduleStep}
          height={currentScheduleStep === ScheduleFileSteps.chooseTags && numberOfPropertiesShortSchema > 1 ? '100%' : 520}
          width={755}
          animateInitialStep
        />
      }
    </>
  );
}