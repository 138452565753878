import { IStyleFunction } from "@fluentui/react"
import { IKnowledgeLabListPropsStyles, IKnowledgeLabListStyles } from "./knowledgeLabList.types";

export const KnowledgeLabListGlobalClassNames = {
    root: 'edi-knowledgelab-list-root',
    renderLinkStyle: 'edi-knowledgelab-list-renderLinkStyle',
    moreButton: 'edi-knowledgelabs-list-moreButton',
    emptyFolderContainer: 'edi-knowledgelabs-list-emptyFolderContainer'

}

export const getStyles: IStyleFunction<IKnowledgeLabListPropsStyles, IKnowledgeLabListStyles> = (props: IKnowledgeLabListPropsStyles): IKnowledgeLabListStyles => {
    const { palette, semanticColors } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            KnowledgeLabListGlobalClassNames.root,
            {
                height: '100%',
                position: 'relative',
                selectors: {
                    '::before': {
                        background: `linear-gradient(to right,transparent,${semanticColors.menuDivider})`,
                        content: "''",
                        opacity: '0.2',
                        pointerEvents: 'none',
                        position: 'absolute',
                        right: 0,
                        width: '0.8rem',
                        height: '100%',
                        zIndex: '2'
                    }
                }
            }
        ],
        renderLinkStyle: [KnowledgeLabListGlobalClassNames.renderLinkStyle, {
            marginLeft: '10px',
            marginRight: 'auto',
            height: 28,
            lineHeight: 28,
            width: 'calc(100% - 40px)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left'
        }],
        moreButton: [KnowledgeLabListGlobalClassNames.moreButton, {
            right: 12,
            position: 'absolute',
            height: 28,
            width: 32,
        }],
        emptyFolderContainer: [KnowledgeLabListGlobalClassNames.emptyFolderContainer, {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }],
        subComponentStyles: {
            nav: {
                navItem: {
                    ':hover *': {
                        visibility: 'visible'
                    }
                },
            },
            moreIcon: {
                root: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    visibility: 'hidden',
                    ':hover': {
                        textShadow: `0 0 1px ${palette.black}`,
                    },
                }
            }
        }
    };
}