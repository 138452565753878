import React, { useState } from "react";
import { classNamesFunction, TooltipDelay, TooltipHost } from "@fluentui/react";
import {
  IWorldMapChartPropsStyles,
  IWorldMapChartStyles,
  IWorldMapChartProps,
} from "./worldMapChart.types";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from "react-simple-maps";
import { IconButton } from "@fluentui/react";
import ReactTooltip from "react-tooltip";
import { Helpers } from "../../../../utilities/helpers";
import { useTranslation } from "react-i18next";
import geoUrl from "./assets/worldMapDataset.json";

const getClassNames = classNamesFunction<
  IWorldMapChartPropsStyles,
  IWorldMapChartStyles
>();

export const WorldMapChartBase = (props: IWorldMapChartProps) => {
  const classNames = getClassNames(props.styles, {
    theme: props.theme,
    className: props.className,
  });
  const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
  const [tooltipContent, setTooltipContent] = useState("");
  const { t } = useTranslation(["nations", "common"]);

  type MyPosition = {
    coordinates: number[];
    zoom: number;
  };
  const initialPosition = { coordinates: [0, 15], zoom: 1.5 };

  const [position, setPosition] = useState(initialPosition);

  function colorScale(countryIso: string): string {
    const countryDocument =
      props.data && props.data.find((c) => c.location === countryIso);
    if (countryDocument && countryDocument !== null) {
      const documentsPercentage =
        countryDocument.count / props.maxLocationCount;

      if (documentsPercentage > 0.75) return palette.themePrimary;
      if (documentsPercentage > 0.5)
        return Helpers.getColorWithOpacity(palette.themePrimary, 0.75);
      if (documentsPercentage > 0.25)
        return Helpers.getColorWithOpacity(palette.themePrimary, 0.5);
      return Helpers.getColorWithOpacity(palette.themePrimary, 0.25);
    }
    return palette.neutralLight;
  }

  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  function handleMoveEnd(position: MyPosition) {
    setPosition(position);
  }

  function handleResetZoom() {
    setPosition(initialPosition);
  }
  return (
    <>
      {props.data && (
        <div className={classNames.root}>
          <ComposableMap
            onFocus={(e) => e.preventDefault()}
            className={classNames.composableMap}
          >
            <ZoomableGroup
              zoom={position.zoom}
              center={[position.coordinates[0], position.coordinates[1]]}
              onMoveEnd={handleMoveEnd}
              data-tip={tooltipContent}
            >
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={colorScale(geo.properties.ISO_A3)}
                      stroke={palette.neutralLighter}
                      strokeWidth={0.3}
                      style={{
                        default: { outline: "none" },
                        hover: { outline: "none" },
                        pressed: { outline: "none" },
                      }}
                      onMouseEnter={() => {
                        setTooltipContent(() => {
                          const count =
                            props.data &&
                            props.data.find(
                              (c) => c.location === geo.properties.ISO_A3
                            )?.count;
                          if (count) {
                            return t(geo.properties.ISO_A3) + " - " + count;
                          }
                          return t(geo.properties.ISO_A3);
                        });
                      }}
                      onMouseLeave={() => {
                        setTooltipContent("");
                      }}
                    />
                  ))
                }
              </Geographies>
            </ZoomableGroup>
          </ComposableMap>
          <ReactTooltip>{tooltipContent}</ReactTooltip>
          <div className={classNames.controls}>
            <TooltipHost content={t("common:zoomIn")} delay={TooltipDelay.zero}>
              <IconButton
                styles={classNames.subComponentStyles.iconButton()}
                iconProps={{ iconName: "ZoomIn" }}
                onClick={handleZoomIn}
              />
            </TooltipHost>
            <TooltipHost
              content={t("common:zoomOut")}
              delay={TooltipDelay.zero}
            >
              <IconButton
                styles={classNames.subComponentStyles.iconButton()}
                iconProps={{ iconName: "ZoomOut" }}
                onClick={handleZoomOut}
              />
            </TooltipHost>
            <TooltipHost content={t("common:reset")} delay={TooltipDelay.zero}>
              <IconButton
                styles={classNames.subComponentStyles.iconButton()}
                iconProps={{ iconName: "ZoomToFit" }}
                onClick={handleResetZoom}
              />
            </TooltipHost>
          </div>
        </div>
      )}
    </>
  );
};
