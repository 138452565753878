/*eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useMemo, useState } from "react";
import { classNamesFunction, DetailsRow, IColumn, Icon, IDetailsRowProps, Label, SearchBox, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";
import { RemoveButton } from "./subComponents/removeButton";
import { RemoveAllButton } from "./subComponents/removeAllButton";
import { IKeywordListProps, IKeywordListPropsStyles, IKeywordListStyles } from "./keywordList.types";
import { useTranslation } from "react-i18next";
import { ConstantsDocLabSetting } from "../../../../models/constants";

const getClassNames = classNamesFunction<IKeywordListPropsStyles, IKeywordListStyles>();

export const KeywordListBase = (props: IKeywordListProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [item, setItem] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const { t } = useTranslation(['archiveSettings']);

    function addItemList(): void {
        if (!item.trim()) {
            setErrorMessage(t('EmptyItem'));
        }
        else if (props.maxLenght && props.items.length >= ConstantsDocLabSetting.MAX_LENGTH_LIST_KEYWORD) {
            setErrorMessage(t('maxLenghtList'));
        }
        else if (item.trim().length < ConstantsDocLabSetting.MIN_LENGTH_KEYWORD || item.trim().length > ConstantsDocLabSetting.MAX_LENGTH_KEYWORD) {
            setErrorMessage(t('maxLenghtKeyword'));
        }
        else if (props.items.map((a) => { return a.toLowerCase() }).includes(item.trim().toLowerCase())) {
            setErrorMessage(t('keywordAlreadyExist'));
        }
        else if (props.NotInList && props.NotInList.map((a) => { return a.toLowerCase() }).includes(item.trim().toLowerCase())) {
            setErrorMessage(t('keywordAlreadyExistBlacklist') + props.NotInListTitle);
        } else {
            setErrorMessage('');
            props.addItem(item.trim());
            setItem("");
        }
    }

    function removeItem(item: string): void {
        if (item) {
            props.removeItem(item);
        }
    }

    function removeAllItems(): void {
        props.removeAllItems();
    }

    const columns: IColumn[] = useMemo(() => [
        {
            key: 'Name',
            name: 'Name',
            minWidth: 100,
            columnActionsMode: 0,
            headerClassName: classNames.headerRow,
            onRender: function getItemName(item: string) {
                return (
                    <div className={classNames.item}>
                        {props.pinnedIcon && <Icon className={classNames.center} iconName={'Pinned'}></Icon>}
                        <div className={classNames.itemName}>{item}</div>
                    </div>
                );
            }
        },
        {
            key: 'remove',
            name: '',
            minWidth: 120,
            headerClassName: classNames.buttonHeader,
            styles: { cellTitle: { padding: 0, justifyContent: 'flex-end' } },
            onRender: function renderRemoveButton(item: string) {
                return (
                    <div className={classNames.center}>
                        <RemoveButton item={item} removeItem={removeItem} message={t('removeButton')}></RemoveButton>
                    </div>
                );
            },
            onRenderHeader: function rendereRemove() {
                return (
                    <div className={classNames.item}>
                        <RemoveAllButton disabled={props.loading || props.items.length === 0} removeItem={removeAllItems} buttonText={t('clearAllButton')} message={t('removeAllButton')}></RemoveAllButton>
                    </div>
                );
            }
        }], [classNames, props.loading, props.items]);//eslint-disable-line react-hooks/exhaustive-deps

    const emptyFolder = useCallback(() => {
        if (props.items.length !== 0)
            return null;

        return (
            <Label className={classNames.center}>
                {t('NoItem')}
            </Label>
        )
    }, [props.items, t, classNames.center]);

    return (
        <>
            <Label className={classNames.commonLabel}>{props.commonDescription}</Label>
            <Label className={classNames.descriptionLabel}>{props.description}</Label>
            <div className={classNames.container}>
                <SearchBox
                    value={item}
                    styles={{
                        iconContainer: {
                            display: 'none'
                        }
                    }}
                    placeholder={t('placeHolderSearch')}
                    onChange={(e) => { setItem(e?.target.value || ""); setErrorMessage('') }}
                    onClear={() => { setItem(""); setErrorMessage(''); }}
                    onSearch={() => addItemList()}
                    className={classNames.search}
                    disabled={props.loading}
                />
            </div>
            {errorMessage && <Label className={classNames.errorMessage}>{errorMessage}</Label>}
            <div
                className={classNames.list}>
                <ShimmeredDetailsList
                    items={props.items}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    isHeaderVisible={true}
                    enableShimmer={props.loading}
                    shimmerLines={5}
                    onRenderDetailsFooter={emptyFolder}
                    onRenderRow={(props?: IDetailsRowProps) => props ? <DetailsRow {...props} styles={{ cellUnpadded: { paddingRight: 0 } }} ></DetailsRow> : null}
                />
            </div>
        </>
    );
}