/*eslint-disable @typescript-eslint/no-non-null-assertion*/
import { Helpers } from "../../utilities/helpers";
import { SearchContentState, SelectionEntryItemSearch } from "./searchContent";
import { IActionDelegate } from "../hooks/useActionDelegate";
import { ArchiveRoleId, ArchiveStatusFilesApproval, ArchiveTypeId, ItemToCopy } from "../models/constants";
import { showFilesApprovalDocumentModal, showDeleteModal, selectItems, showTagPanel, showCopyLinkModal } from "./archiveContent";
import { Archive } from "../models/archive";
import { SelectionScopes } from "../utilities/archiveContentActions";
import { FileApprovalStatus } from "../models/archiveItem";
import { Section } from "../../common/components/tagPanel/tagPanel.types";
import { DocLabDispatch } from "../docLabStore";

export type ActionEntrySearch = {
    id: string;
    scopes: SelectionScopes;
    archiveRoles: ArchiveRoleId[];
    archiveTypes: ArchiveTypeId[];
    archiveRolesDest?: ArchiveRoleId[];
    label?: string;
    icon?: string;
    showInToolbar: boolean;
    subActionItems?: ActionEntrySearch[];
    onClick?: IActionDelegate;
    onClickForContextualMenu: (state: SearchContentState, dispatch: DocLabDispatch) => void;
    condition?: (archive: Archive) => boolean;
    conditionFile?: (file: SelectionEntryItemSearch) => boolean;
    conditionFiles?: (files: SelectionEntryItemSearch[]) => boolean;
    toBeIndexedCheck?: boolean;
}

const actions: ActionEntrySearch[] = [
    {
        id: "open-file",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'open',
        showInToolbar: false,
        onClickForContextualMenu: (state, dispatch) => Helpers.getPreviewFile(state.selectionEntryList[0].id)
    },
    {
        id: "view-tag",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'viewTag',
        icon: 'Tag',
        toBeIndexedCheck: true,
        showInToolbar: false,
        onClickForContextualMenu: (state, dispatch) => dispatch(showTagPanel({isOpen: true, defaultSection: Section.tag}))
    },
    {
        id: "create-link",
        scopes: SelectionScopes.File | SelectionScopes.Folder,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'createLink',
        icon: 'Link12',
        showInToolbar: false,
        onClickForContextualMenu: (state, dispatch) => dispatch(showCopyLinkModal({showModal: true, type: ItemToCopy.DeepLink}))
    },
    {
        id: "copy-id-document",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity, ArchiveTypeId.Search],
        label: 'copyDocumentId',
        icon: 'Source',
        showInToolbar: false,
        onClickForContextualMenu: (state, dispatch) => dispatch(showCopyLinkModal({showModal: true, type: ItemToCopy.DocumentId}))
    },
    {
        id: "download-file",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'download',
        icon: 'Download',
        showInToolbar: true,
        onClick: async (state, __, dispatch) => {
            const { selectionEntryList } = state.searchContent;
            if (selectionEntryList.length !== 1)
                return;
            
            await dispatch(selectItems([]));
            dispatch(showDeleteModal({show: true, type: 'DOWNLOAD_FILES'}));
        },
        onClickForContextualMenu: (state, dispatch) => {
            const { selectionEntryList } = state;
            if (selectionEntryList.length !== 1)
                return;
            
            dispatch(selectItems([]));
            dispatch(showDeleteModal({show: true, type: 'DOWNLOAD_FILES'}));
        }
    },
    {
        id: "download-files",
        scopes: SelectionScopes.Files,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'download',
        icon: 'Download',
        showInToolbar: true,
        onClick: async (_, __, dispatch) => {                
            await dispatch(selectItems([]));
            dispatch(showDeleteModal({show: true, type: 'DOWNLOAD_FILES'}));
        },
        onClickForContextualMenu: (state, dispatch) => {                
            dispatch(selectItems([]));
            dispatch(showDeleteModal({show: true, type: 'DOWNLOAD_FILES'}));
        }
    },
    {
        id: "approve",
        scopes: SelectionScopes.File | SelectionScopes.Files,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'approve',
        icon: 'DocumentApproval',
        toBeIndexedCheck: true,
        showInToolbar: true,
        condition: (archive) => {return archive.filesApproval === ArchiveStatusFilesApproval.Enabled},
        conditionFile: (file) => {return file.approvalStatus === FileApprovalStatus.ToBeApproved},
        conditionFiles: (files) => {return files.every(f => f.approvalStatus === FileApprovalStatus.ToBeApproved)},
        onClick: (_, __, dispatch) => {dispatch(showFilesApprovalDocumentModal({show: true, updateStatus: FileApprovalStatus.Approved}))},
        onClickForContextualMenu: (state, dispatch) => {dispatch(showFilesApprovalDocumentModal({show: true, updateStatus: FileApprovalStatus.Approved}))}
    },
    {
        id: "reject",
        scopes: SelectionScopes.File | SelectionScopes.Files,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'reject',
        icon: 'PageRemove',
        toBeIndexedCheck: true,
        showInToolbar: true,
        condition: (archive) => {return archive.filesApproval === ArchiveStatusFilesApproval.Enabled},
        conditionFile: (file) => {return file.approvalStatus === FileApprovalStatus.ToBeApproved},
        conditionFiles: (files) => {return files.every(f => f.approvalStatus === FileApprovalStatus.ToBeApproved)},
        onClick: (_, __, dispatch) => {dispatch(showFilesApprovalDocumentModal({show: true, updateStatus: FileApprovalStatus.Rejected}))},
        onClickForContextualMenu: (state, dispatch) => {dispatch(showFilesApprovalDocumentModal({show: true, updateStatus: FileApprovalStatus.Rejected}))}
    },
    {
        id: "resubmit-approval",
        scopes: SelectionScopes.File | SelectionScopes.Files,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'resubmitApproval',
        icon: 'PageArrowRight',
        toBeIndexedCheck: true,
        showInToolbar: true,
        condition: (archive) => {return archive.filesApproval === ArchiveStatusFilesApproval.Enabled},
        conditionFile: (file) => {return file.approvalStatus !== FileApprovalStatus.ToBeApproved},
        conditionFiles: (files) => {return files.every(f => f.approvalStatus !== FileApprovalStatus.ToBeApproved && f.approvalStatus === files[0].approvalStatus)},
        onClick: (_, __, dispatch) => {dispatch(showFilesApprovalDocumentModal({show: true, updateStatus: FileApprovalStatus.ToBeApproved}))},
        onClickForContextualMenu: (state, dispatch) => {dispatch(showFilesApprovalDocumentModal({show: true, updateStatus: FileApprovalStatus.ToBeApproved}))}
    }
];

const normalizedActions = Helpers.normalize(actions, action => action.id);

export const getSearchContentActionIds = (scopes: SelectionScopes
    , archive?: Archive
    , archiveType?: ArchiveTypeId
    , selectedItems?: SelectionEntryItemSearch[]
    , archiveRoleId?: ArchiveRoleId    
    , multipleArchiveChosen?: boolean) => 
{
    if (!archive || archiveType === undefined || archiveRoleId === undefined)
        return [];

    const currentUserRoleId = 
        !selectedItems || selectedItems?.length === 0
            ? archiveRoleId 
            : Helpers.retrieveMinArchiveRoleId(selectedItems);

    return actions.filter(action => (action.scopes & scopes) !== 0
        && action.archiveRoles.includes(currentUserRoleId)
        && action.archiveTypes.includes(archiveType)
        && (action.condition === undefined || (action.condition(archive) === true && multipleArchiveChosen === false))
        && (action.conditionFile === undefined || action.conditionFile(selectedItems![0]) === true)
        && (action.conditionFiles === undefined || action.conditionFiles(selectedItems!) === true)
    ).map(action => action.id);
}

export const getSearchContentActionsByIds = (actionIds: string[]) => {
    return actionIds.map(id => normalizedActions[id]);
}

export const searchContentActions = actions;