/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useEffect, useState } from "react";
import { classNamesFunction, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IDocVerifyLoadingPageProps, IDocVerifyLoadingPagePropsStyles, IDocVerifyLoadingPageStyles } from "./docVerifyLoadingPage.types";
import TeamsImage from "../teamsImage/teamsImage";
import { ImageName } from "../teamsImage/teamsImage.types";
import { useOnMount } from "../../../utilities/hooks";
import { archivesApi } from "../../../docLab/services/archives/archives.api";
import { ResultDocumentsMetadataUnivocityWithError } from "../../../docLab/models/archiveUnivocityRule";
import { selectItemsOnDelete, useArchiveContent } from "../../../docLab/features/archiveContent";
import { useActivityLabDispatch } from "../../../activityLab/activityLabStore";
import { nanoid } from "@reduxjs/toolkit";
import { setCall } from "../../../docLab/features/callNotification";
import { ErrorsType } from "../../../docLab/models/callsApi";

const getClassNames = classNamesFunction<IDocVerifyLoadingPagePropsStyles, IDocVerifyLoadingPageStyles>();

export const DocVerifyLoadingPageBase = (props: IDocVerifyLoadingPageProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['docVerifyPages']);
    const [loading, setLoading] = useState<boolean>(false);
    const [documentUnivocityError, setDocumentUnivocityError] = useState<ResultDocumentsMetadataUnivocityWithError>();
    const [error, setError] = useState(false);
    const { deleteModalShown, selectedItems } = useArchiveContent();
    const dispatch = useActivityLabDispatch();

    useOnMount(() => {
        verifyDocumentsMetadataUnivocity();
    });

    const verifyDocumentsMetadataUnivocity = async () => {
        setLoading(true);

        try {
            const result = deleteModalShown ? await archivesApi.verifyDocumentsMetadataUnivocityBulk(props.files, props.folderId) : await archivesApi.verifyDocumentsMetadataUnivocity(props.files, props.folderId[0]);
            setDocumentUnivocityError(result);
            //rimuovere dalla lista gli elementi errati per continuare con il salvataggio
            if (deleteModalShown && result.isUserBlocked) {
                dispatch(selectItemsOnDelete(selectedItems.filter((s) => {
                    return !result.notUnivocityDocumentList.some((item) => item.fileId === s.id);
                })));
            }
        }
        catch (e: any) { //eslint-disable-line @typescript-eslint/no-explicit-any
            const error: ErrorsType = e;
            console.log("ERROR: ", error.message);
            setError(true);
            setLoading(false);

            const id = nanoid();
            if (deleteModalShown) {
                const failurePayload = { requestId: id, success: false, message: error.message }
                dispatch(setCall(failurePayload));
            }
        }
    }

    useEffect(() => {
        if (props.onVerifyResult && documentUnivocityError)
            props.onVerifyResult(documentUnivocityError);
    }, [documentUnivocityError]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.loadingData !== undefined)
            setLoading(props.loadingData);
    }, [props.loadingData]);

    return (
        <>
            <div className={classNames.bodyContainer}>
                <TeamsImage imageName={ImageName.Sandglass} className={classNames.wellDoneImage} fullContainer scale={0.8} />
                <div className={classNames.footer}>
                    {loading ? t('messageLoading') : error ? t('errorMessage') : t('message')}
                    {loading &&
                        <Spinner style={{ marginTop: '10px' }} size={SpinnerSize.large} />
                    }
                    {error ?
                        <div>
                            <PrimaryButton
                                text={t('close')}
                                disabled={loading}
                                onClick={() => { if (props.onClose) props.onClose() }}
                                styles={{ root: { padding: '0px 80px', marginTop: '10px' } }}
                            />
                        </div>
                        :
                        <div>
                            <PrimaryButton
                                text={t('nextButton')}
                                disabled={loading}
                                onClick={() => props.onNext()}
                                styles={{ root: { padding: '0px 80px', marginTop: '10px' } }}
                            />
                        </div>
                    }
                </div>
            </div>
        </>
    )
}