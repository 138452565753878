import { IStyleFunction } from "@fluentui/react"
import { IFileNavigatorPropsStyles, IFileNavigatorStyles } from "./fileNavigator.types"

export const FileNavigatorGlobalClassNames = {
    root: 'edi-file-navigator-root',
    navigator: 'edi-file-navigator-navigator',
    navigableFolder: 'edi-file-navigator-navigableFolder',
    checkBox: 'edi-file-navigator-checkBox'
}

export const getStyles: IStyleFunction<IFileNavigatorPropsStyles, IFileNavigatorStyles> = (props: IFileNavigatorPropsStyles): IFileNavigatorStyles => {
    return {
        root: [
            FileNavigatorGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
                
            }
        ],
        navigator: [
            FileNavigatorGlobalClassNames.navigator,
            {
                overflow: 'auto',
                selectors:{
                    '.ms-DetailsHeader': {
                        paddingTop: '0px !important'
                    }
                }
            }
        ],
        checkBox: [
            FileNavigatorGlobalClassNames.checkBox,
            {
                selectors:{
                    ' .ms-Checkbox-checkbox': {
                        borderRadius: '50%'
                    }
                }
            }
        ],
        navigableFolder: [
            FileNavigatorGlobalClassNames.navigableFolder,
            {
                selectors: {
                    ':hover': {
                        textDecoration: 'underline',
                        cursor:'pointer'
                    }
                }
            }
        ],
        emptyFolder: {
            height: '204px',
            paddingTop: '52px',
            paddingBottom: '174px'
        },
    };
}