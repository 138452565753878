import { IStyleFunction } from "@fluentui/react";
import { IArchiveListPropsStyles, IArchiveListStyles } from "./archiveList.types";

export const ArchiveListGlobalClassNames = {
    root: 'edi-navigation-root'
}

export const getStyles: IStyleFunction<IArchiveListPropsStyles, IArchiveListStyles> = (props: IArchiveListPropsStyles): IArchiveListStyles => {
    const { palette, semanticColors } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            ArchiveListGlobalClassNames.root,
            {
                height: '100%',
                position: 'relative',
                selectors: {
                    '::before': {
                        background: `linear-gradient(to right,transparent,${semanticColors.menuDivider})`,
                        content: "''",
                        opacity: '0.2',
                        pointerEvents: 'none',
                        position: 'absolute',
                        right: 0,
                        width: '0.8rem',
                        height: '100%',
                        zIndex: '2'
                    }
                }
            }
        ],
        renderLinkStyle: {
            marginLeft: '10px',
            marginRight: 'auto',
            height: 28,
            lineHeight: 28,
            width: 'calc(100% - 40px)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left'
        },
        moreButton: {
            right: 12,
            position: 'absolute',
            height: 28,
            width: 32,
        },
        subComponentStyles: {
            nav: {
                navItem: {
                    ':hover *': {
                        visibility: 'visible'
                    }
                },
            },
            iconFlick: {
                root: {
                    transform: "rotate(270deg)",
                    paddingTop: "10px",
                    fontSize: "10px"
                }
            },
            moreIcon: {
                root: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    visibility: 'hidden',
                    ':hover': {
                        textShadow: `0 0 1px ${palette.black}`,
                    },
                }
            }
        }
    };
}