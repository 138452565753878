import { styled } from "@fluentui/react";
import { getStyles } from "./documents.styles";
import { IDocumentsPropsStyles, IDocumentsStyles, IDocumentsProps } from "./documents.types";
import { DocumentsBase } from "./documents.base";

const Documents = styled<IDocumentsProps, IDocumentsPropsStyles, IDocumentsStyles>(
    DocumentsBase,
    getStyles,
    undefined,
    { scope: 'Documents' }
);

export default Documents;