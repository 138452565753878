import { styled } from "@fluentui/react";
import { getStyles } from "./pagination.styles";
import { IPaginationPropsStyles, IPaginationStyles, IPaginationProps } from "./pagination.types";
import { PaginationBase } from "./pagination.base";

const Pagination = styled<IPaginationProps, IPaginationPropsStyles, IPaginationStyles>(
    PaginationBase,
    getStyles,
    undefined,
    { scope: 'Pagination' }
);

export default Pagination;