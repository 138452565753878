import React, { useState } from "react";
import { classNamesFunction, Icon, Stack, StackItem } from "@fluentui/react";
import { IGlossaryCheckContentPropsStyles, IGlossaryCheckContentStyles, IGlossaryCheckContentProps } from "./glossaryCheckContent.types";
import FileUploader from "../../../../../../common/components/fileUploader/fileUploader";
import TeamsImage from "../../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../../common/components/teamsImage/teamsImage.types";
import { IFileToUpload } from "../../../../files/uploadFileModal/uploadFileModal.types";
import { useTranslation } from "react-i18next";
import { docAnalyzesApi } from "../../../../../services/docAnalyzes/docAnalyzes.api";
import { GlossaryCheckValidationResult } from "../../../../../models/docAnalysis";
import { GlossaryCheckTeamsImage } from "../../../../../models/constants";

const getClassNames = classNamesFunction<IGlossaryCheckContentPropsStyles, IGlossaryCheckContentStyles>();

export const GlossaryCheckContentBase = (props: IGlossaryCheckContentProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['docAnalysisStartModal', 'common']);
    const [fileAccepted, setFileAccepted] = useState(false);

    const onFileUpdated = (files: IFileToUpload[], result: GlossaryCheckValidationResult) => {
        props.contentValidationChanged(files && files.length > 0, result);
        setFileAccepted(false)
    }

    const isFileAccepted = (result?: boolean) => {
        if (result) setFileAccepted(result)
        else setFileAccepted(true)
    }

    const onFileUploadMetadata = (files: File[]) => {
        props.fileUploadedCallback(files);
    }

    return (
        <div className={classNames.contentContainer}>
            <FileUploader
                archiveId={1}
                sendFiles={docAnalyzesApi.uploadFiles}
                onFileUpdated={onFileUpdated}
                showFileNameOnly={true}
                removeBackground={true}
                currentFolderId={2}
                multiUpload={false}
                showMessages={false}
                showMessageError={fileAccepted}
                isFileAccepted={isFileAccepted}
                fileTypes={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                isFileDraggable={false}
                maxNumFilesUploadable={2}
                labelToPrint={t('glossaryCheck.buttonForUploadFile')}
                fileOnInit={props.fileOnInit}
                onFileUploadMetadata={onFileUploadMetadata}
                isDisable={false}
                multiple={false}
            >
            </FileUploader>
            <Stack>
                <StackItem>
                    <div style={{ marginTop: 15 }}>
                        <span>{t('glossaryCheck.excelLimit')}</span>
                    </div>
                </StackItem>                   
            </Stack>
            <Stack horizontal>
                <StackItem> <Icon iconName={'info'} className={classNames.iconResized} /></StackItem>
                <StackItem className={classNames.root}>
                    <Stack>
                        <StackItem>
                            <div style={{ marginTop: 15 }}>
                                <span>{t('glossaryCheck.firstPhrase')}</span>
                            </div>
                        </StackItem>
                        <StackItem>
                            <div style={{ marginTop: 5 }}>
                                <span>{t('glossaryCheck.secondPhrase')}</span>
                            </div>
                        </StackItem>
                        <StackItem>
                            <div style={{ marginTop: 5 }}>
                                <span>{t('glossaryCheck.thirdPhrase')}</span>
                            </div>
                        </StackItem>
                        <StackItem>
                            <TeamsImage
                                imageName={GlossaryCheckTeamsImage.find(x => x.key === t('glossaryCheck.teamsImage'))?.value || ImageName.GlossaryCheckInfoEn}
                                scale={0.5}
                                fullContainer
                                style={{
                                    marginTop: "10px",
                                    alignItems: "flex-start"
                                }}
                            />
                        </StackItem>
                    </Stack>
                </StackItem>
            </Stack>
        </div>
    );
}