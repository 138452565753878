/* eslint-disable sonarjs/cognitive-complexity */
import { classNamesFunction, Stack, TextField, Toggle } from "@fluentui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Constants } from "../../../../../../../models/constants";
import { ILabelRangeProps, ILabelRangePropsStyles, ILabelRangeStyles } from "./labelRange.types";

const getClassNames = classNamesFunction<ILabelRangePropsStyles, ILabelRangeStyles>();

export const LabelRangeBase = (props: ILabelRangeProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation('docAnalysis');
    const [toValue, setToValue] = React.useState<number | undefined>(0);
    const [fromValue, setFromValue] = React.useState<number | undefined>(0);
    const [enableValue, setEnableValue] = React.useState<boolean | undefined>(true);
    const maxDelta = Constants.MAX_INTEGER;
    const onToChanged = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            const newVal = Number(Number(newValue).toFixed(2));
            props.setLabelFrom(props.label, newVal);
        },
        [props],
    );

    const enabledChanged = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        setEnableValue(checked);
        props.updateStatus(props.label, checked);
    }

    useMemo(() => {
        setFromValue(props.from);
        setToValue(props.to);
        setEnableValue(props.enabled);
    }, [props.from, props.to, props.enabled]);

    return (
        <Stack
            horizontal={true}
            horizontalAlign='stretch'
            verticalAlign='center'
            tokens={{ childrenGap: 60 }}
            style={{ paddingBottom: '1em' }}
        >
            {/* <Stack.Item grow={3}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    style={{ width: '10em' }}
                    tokens={{ childrenGap: 4, maxWidth: 300 }}
                >
                    <Icon iconName="Tag" />
                    <span>{t(`labels.types.${props.label}`)}</span>
                </Stack>
            </Stack.Item> */}
            <TextField
                readOnly
                styles={classNames.subComponentStyles.range}
                type='number'
                label=""
                prefix={t('labels.from')}
                value={props.enabled ? fromValue?.toString() : ''}
                disabled={!props.enabled}
                //Va in errore se From è maggiore di To o se il valore di From supera il limite massimo dell'Intero
                className={(props.to && props.from > props.to) || (fromValue && fromValue > (props.maxDelta || maxDelta))
                            ? props.enabled ? classNames.errorPrefix : ''
                            : ''
                        }
                errorMessage={(props.to && props.from > props.to) || (fromValue && fromValue > (props.maxDelta || maxDelta))
                            ? props.enabled ? ' ' : ''
                            : ''
                        }
            />
            <Stack.Item style={{ visibility: (props.disableToInterval ? 'hidden' : 'visible') }}>
                <TextField
                    styles={classNames.subComponentStyles.range}
                    type='number'
                    inputMode='numeric'
                    prefix={t('labels.to')}
                    value={props.enabled ? toValue?.toString() : ''}
                    onChange={onToChanged}
                    step={props.minDelta}
                    min={props.minDelta + props.from}
                    disabled={!props.enabled}
                    //Va in errore se From è maggiore di TO o se il valore di To supera il limite massimo dell'Intero
                    className={(props.to && props.from > props.to) || (toValue && toValue > (props.maxDelta || maxDelta))
                                ? props.enabled ? classNames.errorPrefix : ''
                                : ''
                            }
                    errorMessage={(props.to && props.from > props.to) || (toValue && toValue > (props.maxDelta || maxDelta))
                                ? props.enabled ? ' ' : ''
                                : ''
                            }
                />
            </Stack.Item>
            <Toggle
                disabled={props.disableToggle && enableValue}
                checked={enableValue}
                onText="On"
                offText="Off"
                onChange={enabledChanged}
                styles={{ root: { marginBottom: 0 } }}
            />
        </Stack>
    );
}