import { IStyleFunction } from "@fluentui/react"
import { IRenamePortfolioModalPropsStyles, IRenamePortfolioModalStyles } from "./renamePortfolioModal.types";

export const RenamePortfolioModalGlobalClassNames = {
    root: 'edi-rename-portfolio-modal-root',
    innerContent: 'edi-rename-portfolio-modal-innerContent',
    firstFooterContainer: 'edi-rename-portfolio-modal-firstFooterContainer',
    errorMessage: 'edi-rename-portfolio-modal-errorMessage',
}

export const getStyles: IStyleFunction<IRenamePortfolioModalPropsStyles, IRenamePortfolioModalStyles> = (props: IRenamePortfolioModalPropsStyles): IRenamePortfolioModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            RenamePortfolioModalGlobalClassNames.root,
            {
            }
        ],
        innerContent: [
            RenamePortfolioModalGlobalClassNames.innerContent, {
                display: 'flex',
                alignItems: 'baseline'
            }
        ],
        firstFooterContainer: [
            RenamePortfolioModalGlobalClassNames.firstFooterContainer, {
                display: 'flex',
                justifyContent: 'flex-end',
            }
        ],
        errorMessage: [
            RenamePortfolioModalGlobalClassNames.errorMessage, {
                color: palette.redDark,
                fontSize: '12px'
            }
        ],
        subComponentStyles: {
            textField: {
                errorMessage: {
                    position: 'absolute'
                },
                root: {
                    minHeight: '55px',
                    overflow: 'hidden',
                    width: '100%'
                }
            }
        }
    }
}