import { AnimationClassNames, DefaultEffects, FontSizes, FontWeights, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react";
import { IQualityCheckSettingsPropsStyles, IQualityCheckSettingsStyles } from "./qualityCheckSettings.types";

export const QualityCheckSettingsGlobalClassNames = {
    root: 'edi-quality-check-settings-root',
    grid: 'edi-quality-check-settings-grid',
    spinner: 'edi-quality-check-settings-spinner',
    rowTitle: 'edi-quality-check-settings-row-title',
    paragraphUoM: 'edi-quality-check-settings-paragraph-uom',
    headerRow: 'edi-quality-check-settings-header-row',
}

export const getStyles: IStyleFunction<IQualityCheckSettingsPropsStyles, IQualityCheckSettingsStyles> = (props: IQualityCheckSettingsPropsStyles): IQualityCheckSettingsStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            QualityCheckSettingsGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        grid: [
            QualityCheckSettingsGlobalClassNames.grid,
            {
                display: 'grid',
                gridTemplateColumns: '100px 50px 50px',
                background: palette.white,
                boxShadow: DefaultEffects.elevation4,
                marginBottom: 2,
                maxWidth: 200,
                '> *': {
                    padding: '5px 10px',
                    color: palette.black
                },
                '> :first-child': {
                    backgroundColor: palette.neutralSecondary,
                    color: palette.white,
                    fontWeight: FontWeights.semibold
                },
            }
        ],
        spinner: [
            QualityCheckSettingsGlobalClassNames.spinner,
            {
                width: '100%',
                height: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        ],
        rowTitle: [
            QualityCheckSettingsGlobalClassNames.rowTitle,
            AnimationClassNames.fadeIn400,
            {
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 11,
                fontSize: FontSizes.size14,
                color: palette.black,
            }
        ],
        paragraphUoM: [
            QualityCheckSettingsGlobalClassNames.paragraphUoM,
            {
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 11,
                fontSize: FontSizes.size12,
                color: palette.black,
            }
        ],
        headerRow: [
            QualityCheckSettingsGlobalClassNames.headerRow,
            {
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                }
            }
        ],
        subComponentStyles: {
            detailsList: (): IDetailsListStyles => {
                return {
                    root: {},
                    contentWrapper: {},
                    focusZone: {},
                    headerWrapper: {
                        '> .ms-DetailsHeader': {
                            paddingTop: 0,
                            borderBottomColor: 'transparent'
                        }
                    },
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                    },
                    cell: { alignSelf: 'center' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
        }
    };
}