/*eslint-disable sonarjs/cognitive-complexity*/
import { Checkbox, DetailsList, DetailsRow, Dropdown, IBasePickerStyles, IBasePickerSuggestionsProps, IColumn, IconButton, IDropdownOption, IPersonaProps, mergeStyleSets, NormalPeoplePicker, Persona, PersonaSize, PrimaryButton, SelectionMode, useTheme } from "@fluentui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PortfolioRoleId } from "../../../activityLab/models/constants";
import { ArchiveRoleId } from "../../../docLab/models/constants";
import { AddMembersPageType } from "../../../utilities/constants";
import { mapper } from "../../../utilities/mapper";
import { IUser } from "../../interfaces/IUser";
import AdvancedDropdown from "../advancedDropdown/advancedDropdown";
import { GetModuleLibrary } from "../../../dashboard/models/dashboardMember";

export interface IExtPersonaProps extends IPersonaProps {
    userData: IUser;
}

export type MembersPageData = {
    isValid: boolean;
    selectedPeople: IUser[];
}

export interface IAddMembersPageProps {
    initialState: MembersPageData;
    onDataChange: (input: MembersPageData) => void;
    translationNamespace: string;
    getUsers: (keyword: string, currentUsers: IUser[]) => Promise<IUser[]>;
    modalType: AddMembersPageType;
    enableAllowFolderToggle: boolean;
    toggleAlwaysTrue?: boolean;
    enableRoleSelection: boolean;
    enableSelectionLibraryModule?: boolean;
    moduleLibrary?: GetModuleLibrary[];
}


const mapUserToPersona = (user: IUser): IExtPersonaProps => {
    return {
        ...mapper.mapUserToPersona(user),
        userData: user
    }
}

const mapPersonToUser = (person: IPersonaProps): IUser => {
    const converted: IExtPersonaProps | undefined = person as IExtPersonaProps;
    return {
        ...mapper.mapPersonaToUser(converted)
    }
}

export const AddMembersPage = (props: IAddMembersPageProps) => {
    const [data, setData] = useState<MembersPageData>(props.initialState);
    const [pickerBuffer, setPickerbuffer] = useState<IExtPersonaProps[]>([]);
    const [showMinLengthAlert, setShowMinLengthAlert] = useState(true);
    const theme = useTheme();
    const { t } = useTranslation([props.translationNamespace, 'common']);

    const availableRoles: IDropdownOption[] = props.modalType === AddMembersPageType.Archive
        ? [
            { key: ArchiveRoleId.Owner, text: "Owner" },
            { key: ArchiveRoleId.Architect, text: "Architect" },
            { key: ArchiveRoleId.Contributor, text: "Contributor" },
            { key: ArchiveRoleId.Professional, text: "Professional" },
            { key: ArchiveRoleId.Uploader, text: "Uploader" },
            { key: ArchiveRoleId.Reader, text: "Reader", selected: true }
        ] : (props.modalType === AddMembersPageType.KnowledgeLab ? [
            { key: ArchiveRoleId.Owner, text: "Owner" },
            { key: ArchiveRoleId.Contributor, text: "Contributor" },
            { key: ArchiveRoleId.Reader, text: "Reader", selected: true }
        ] : [
            { key: PortfolioRoleId.Owner, text: "Owner" },
            { key: PortfolioRoleId.Viewer, text: "Viewer", selected: true }
        ]);

    useEffect(() => { props.onDataChange(data) }, [props, data]);

    const searchForAvailableMembers = async (filter: string, selectedItems?: IPersonaProps[]) => {

        const selectedUsers = selectedItems ? selectedItems.map(x => mapPersonToUser(x)) : [];
        let filteredUser: IUser[] = []
        try {
            await props.getUsers(filter, selectedUsers)?.then(response => {
                const pickerBufferSelectedUserIds = pickerBuffer.map(user => user.userData.id)
                filteredUser = response.filter(user => !pickerBufferSelectedUserIds.includes(user.id))
            })
            if (filter.length > 2 && filteredUser.length === 0) {
                setShowMinLengthAlert(false)
            }
            else { setShowMinLengthAlert(true) }
            return filteredUser.map(user => mapUserToPersona(user));
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }

    const getColumns = () => {
        let tempColumns = availableColumns;
        if (!props.enableAllowFolderToggle)
            tempColumns = tempColumns.filter(p => p.key !== 'allChilds');
        if (!props.enableRoleSelection)
            tempColumns = tempColumns.filter(p => p.key !== 'role')
        if(!props.enableSelectionLibraryModule)
            tempColumns = tempColumns.filter(p => p.key !== 'selectLibraryModule')
        return tempColumns;
    }

    const movePickerBufferToMembers = () => {
        const newState = { ...data };
        newState.selectedPeople = newState.selectedPeople.concat(pickerBuffer.map(user => ({ ...user.userData, allowSubFolders: true })));
        newState.isValid = newState.selectedPeople.length > 0;

        setData(newState);
        setPickerbuffer([]);
    }

    const addPeopleToPickerBuffer = (people?: IPersonaProps[]) => {
        const converted: IExtPersonaProps[] | undefined = people?.map(p => p as IExtPersonaProps);
        setPickerbuffer(converted ?? []);
    }

    const removeMember = (member: IExtPersonaProps) => {
        const newState = { ...data };
        newState.selectedPeople = data.selectedPeople.filter(p => p.id !== member.id);
        newState.isValid = newState.selectedPeople.length > 0;

        setData(newState);
    }

    const changeMemberRole = (member: IUser, selectedRole?: IDropdownOption) => {
        const newState = { ...data };
        const person = newState.selectedPeople.find(p => p.id === member.id);
        if (!person)
            return;

        person.roleId = selectedRole?.key as ArchiveRoleId;
        if (person.roleId === ArchiveRoleId.Owner) {
            person.allowSubFolders = true;
        }

        setData(newState);
    }

    const toggleAllowSubfolder = (member: IUser) => {
        const newState = { ...data };
        const person = newState.selectedPeople.find(p => p.id === member.id);
        if (!person)
            return;

        person.allowSubFolders = !person.allowSubFolders;
        setData(newState);
    }


    const renderMemberPersona = (item: IUser) => {
        return <Persona {...mapUserToPersona(item)} size={PersonaSize.size40} coinSize={32} />
    };

    const renderMemberRoles = (member: IUser) => {
        return <Dropdown
            defaultSelectedKey={member.roleId}
            options={availableRoles}
            onChange={(_, option) => changeMemberRole(member, option)}
        />
    }

    const renderAllowChildFolders = (member: IUser) => {
        return <>
            {props.modalType !== AddMembersPageType.KnowledgeLab && <Checkbox
                label={t('allowChildFolders')}
                checked={member.allowSubFolders}
                className={classNames.checkboxAlign}
                disabled={props.toggleAlwaysTrue || (member.roleId === ArchiveRoleId.Owner)}
                onChange={() => toggleAllowSubfolder(member)}
            />}
        </>
    }

    const renderMemberRemoveButton = (member: IExtPersonaProps) => {
        return <IconButton iconProps={{ iconName: "Cancel" }} onClick={() => removeMember(member)} />
    }

    const renderModuleLibrary = (member: IUser) => {
        return <>
            {props.enableSelectionLibraryModule && props.modalType === AddMembersPageType.Dashboard &&
                <AdvancedDropdown
                    placeholder=""
                    disabled={false}
                    label=""
                    multiselect
                    options={ props.moduleLibrary ?             
                        props.moduleLibrary?.map(m => {
                        return {
                            key: m.id,
                            text: m.moduleInstanceFullName,
                            selected: true
                        } as IDropdownOption;
                    }): []}
                    onChangeItems={(keySelected) => {
                        const newState = { ...data };
                        const person = newState.selectedPeople.find(p => p.id === member.id);
                        if (!person)
                            return;

                        person.selectedModuleLibrary = keySelected;
                        setData(newState);                                                      
                    }}
                    selectedItems={member.selectedModuleLibrary}
                />
            }    
        </>
        
    }

    const availableColumns: IColumn[] = [
        { key: 'persona', name: 'persona', minWidth: 100, onRender: renderMemberPersona },
        { key: 'role', name: 'role', minWidth: 120, onRender: renderMemberRoles },
        { key: 'allChilds', name: 'Child Folders', minWidth: 160, onRender: renderAllowChildFolders },
        { key: 'selectLibraryModule', name: 'selectLibraryModule', minWidth: 300, onRender: renderModuleLibrary },
        { key: 'remove', name: 'remove', minWidth: 24, onRender: renderMemberRemoveButton }
    ];

    const classNames = mergeStyleSets({
        disclaimerZone: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 30
        },
        peoplePickerZone: {
            display: 'flex',
            marginBottom: '20px'
        },
        peoplePickerSuggestions: {
            padding: '8px 0'
        },
        peoplePickerSuggestionItem: {
            '::after': {
                display: 'none',
            },
            ".is-suggested": {
                background: theme.palette.neutralQuaternary
            }
        },
        detailsListRow: {
            background: 'transparent !important',
        },
        checkboxAlign: {
            height: '100%',
            alignItems: 'center',
            ".ms-Checkbox-label": {
                alignItems: 'center',
                height: '32px'
            },
            ".ms-Checkbox-text": {
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
                fontSize: '13px',
                lineHeight: '12px'
            },
        }
    })

    const suggestionOptions: IBasePickerSuggestionsProps = {
        showRemoveButtons: false,
        suggestionsClassName: classNames.peoplePickerSuggestions,
        suggestionsItemClassName: classNames.peoplePickerSuggestionItem,
        loadingText: t('common:loading'),
        noResultsFoundText: showMinLengthAlert ? t('minLengthAlert') : t('noResults'),
    };

    const peoplePickerStyles = useMemo((): IBasePickerStyles => ({
        input: {
            backgroundColor: 'transparent'
        },
        text: {
            border: '1px solid rgb(240, 240, 240) !important',
            borderRadius: 2,
            backgroundColor: 'rgb(240, 240, 240)',
        },
        itemsWrapper: {
            ".ms-PickerPersona-container": {
                background: 'rgb(245, 245, 245)',
                border: "1px solid rgb(245, 245, 245)",
                ':hover': {
                    background: 'rgba(98, 100, 167, 0.2)',
                    border: "1px solid rgba(98, 100, 167, 0.2)",
                }
            },
        },
        root: {},
        screenReaderText: {}
    }), []);



    return (
        <>
            <div className={classNames.disclaimerZone}>
                <span>{t('description1')}</span>
                <span>{t('description2')}</span>
            </div>
            <div className={classNames.peoplePickerZone}>
                <NormalPeoplePicker
                    styles={peoplePickerStyles}
                    onResolveSuggestions={searchForAvailableMembers}
                    resolveDelay={500}
                    pickerSuggestionsProps={suggestionOptions}
                    selectedItems={pickerBuffer}
                    onChange={addPeopleToPickerBuffer}
                />
                <PrimaryButton text={t("common:add")} onClick={movePickerBufferToMembers} disabled={pickerBuffer.length === 0} />
            </div>
            <div>
                <DetailsList
                    items={data.selectedPeople}
                    columns={getColumns()}
                    selectionMode={SelectionMode.none}
                    isHeaderVisible={false}
                    onRenderRow={props => props ? <DetailsRow {...props} className={classNames.detailsListRow} /> : null}
                />
            </div>
        </>
    )
}