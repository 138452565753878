import { IStyleFunction } from "@fluentui/react"
import { IDatasetFiltersPropsStyles, IDatasetFiltersStyles } from "./datasetFilters.types"

export const DatasetFiltersGlobalClassNames = {
    root: 'edi-dataset-filters-root',
    filterPanelItem: 'edi-dataset-filters-panel',
}

export const getStyles: IStyleFunction<IDatasetFiltersPropsStyles, IDatasetFiltersStyles> = (props: IDatasetFiltersPropsStyles): IDatasetFiltersStyles => {
    return {
        root: [
            DatasetFiltersGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        filterPanelItem: [
            DatasetFiltersGlobalClassNames.filterPanelItem,
            {
                marginBottom: '2em !important',
                '.ms-TextField-suffix': {
                    padding: 0,
                    background: 'transparent',
                }
                // CSS PROPS AND SELECTORS
            }
        ],
        scrollablePane: {
            ".ms-ScrollablePane--contentContainer": {
                paddingLeft: "20px",
                paddingRight: "20px",
            }
        }
    };
}