import { IStyleFunction } from "@fluentui/react"
import { ICreateDatasetModalPropsStyles, ICreateDatasetModalStyles } from "./createDatasetModal.types"

export const CreateDatasetModalGlobalClassNames = {
    root: 'edi-create-dataset-modal-root'
}

export const getStyles: IStyleFunction<ICreateDatasetModalPropsStyles, ICreateDatasetModalStyles> = (props: ICreateDatasetModalPropsStyles): ICreateDatasetModalStyles => {
    return {
        root: [
            CreateDatasetModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
                width: "100%",
                minHeight: "150px"
            }
        ]
    };
}