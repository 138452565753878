/*eslint-disable sonarjs/no-duplicate-string */
/*eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect } from 'react';
import { IEditProfileFolderModalBasePropsStyles, IEditProfileFolderModalBaseStyles, IEditProfileFolderModalProps } from './editProfileFolderModal.types';
import { DefaultButton, Icon, Label, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { classNamesFunction } from "@fluentui/react";
import FolderTreeViewer from '../../../../../../common/components/folderTreeViewer/folderTreeViewer';
import { useOnMount } from '../../../../../../utilities/hooks';
import { archivesApi } from '../../../../../services/archives/archives.api';
import { Folder } from '../createProfileModal/createProfileModal.types';
import { IFolder } from '../../../../../models/folder';
import { IManagerFolder } from '../../../../../../common/components/folderTreeViewer/folderManager/IManagerFolder';
import EdiModal from '../../../../../../common/components/ediModal/ediModal';
import _ from 'lodash';
import { ErrorDetails } from '../../../../../../modules/apiClient/apiClient';
import TeamsImage from '../../../../../../common/components/teamsImage/teamsImage';
import { ImageName } from '../../../../../../common/components/teamsImage/teamsImage.types';

const getClassNames = classNamesFunction<IEditProfileFolderModalBasePropsStyles, IEditProfileFolderModalBaseStyles>();

export const EditProfileFolderModalBase = (props: IEditProfileFolderModalProps) => {

    const { t } = useTranslation([props.translationName, 'common']);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [folders, setFolders] = useState<Folder[]>([]);
    const [selectedFolders, setSelectedFolders] = useState<IManagerFolder[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [canSave, setCanSave] = useState<boolean>(false);
    const [totalFolders, setTotalFolders] = useState<number | undefined>(undefined);
    const [loadingFolders, setLoadingFolders] = useState<boolean>(false);
    const [foldersWithState, setFoldersWithState] = useState<FolderWithState[]>([]);
    const [errorMessageFooter, setErrorMessageFooter] = useState('');

    type FolderWithState = {
        folderId: number;
        state: boolean | undefined;
    }

    useOnMount(() => {
        getFolders().then(() => {
            setIsLoading(false);
        })
    })

    useEffect(() => {
        if (props.isProfileFoldersBulk !== undefined && props.isProfileFoldersBulk)
            setSelectedFolders(folders);
    }, [folders]); //eslint-disable-line react-hooks/exhaustive-deps

    const convertToManagerFolders = (folders: IFolder[]) => {
        const fs = folders.map(f => {
            const tf = new Folder();
            tf.id = f.id;
            tf.name = f.name;
            tf.parentId = f.parentId;
            tf.createdBy = f.createdBy;
            tf.createdOn = f.createdOn;
            tf.depth = f.depth;
            tf.fullPathName = f.fullPathName;
            return tf;
        });

        const result: Folder[] = [];
        const ids = fs.map(object => {
            return object.id
        });
        let maxId = Math.max(...ids);
        fs.forEach(f => {
            result.push(f);
            const documentOf = new Folder();
            documentOf.name = 'Documents in ' + f.name;
            maxId = maxId + 1;
            documentOf.id = maxId;
            documentOf.depth = f.depth + 1;
            documentOf.documentOfId = f.id;
            documentOf.parentId = f.id;
            documentOf.canExpand = false;
            documentOf.canSelect = true;
            documentOf.fullPathName = f.fullPathName === ""
                ? "Documents in " + f.name
                : f.fullPathName + "\\Documents in " + f.name

            result.push(documentOf);
        });
        return result;
    }

    const convertToManagerFoldersBulk = (folders: IManagerFolder[]) => {
        const fs = folders.map(f => {
            const tf = new Folder();
            tf.id = f.id;
            tf.name = f.name;
            tf.parentId = f.parentId;
            tf.createdBy = f.createdBy;
            tf.createdOn = f.createdOn;
            tf.depth = f.depth;
            tf.fullPathName = f.fullPathName;
            tf.selected = f.parentId === null ? f.selected : f.selectedAllChildren;
            tf.collapsed = f.collapsed;
            tf.hidden = f.hidden;
            tf.selectedAllChildren = f.selectedAllChildren;
            return tf;
        });

        const result: Folder[] = [];
        const ids = fs.map(object => {
            return object.id
        });
        let maxId = Math.max(...ids);
        fs.forEach(f => {
            result.push(f);
            const documentOf = new Folder();
            documentOf.name = 'Documents in ' + f.name;
            maxId = maxId + 1;
            documentOf.id = maxId;
            documentOf.depth = f.depth + 1;
            documentOf.documentOfId = f.id;
            documentOf.parentId = f.id;
            documentOf.canExpand = false;
            documentOf.canSelect = true;
            documentOf.selected = f.selected;
            documentOf.hidden = f.collapsed ? true : false;
            documentOf.fullPathName = f.fullPathName === ""
                ? "Documents in " + f.name
                : f.fullPathName + "\\Documents in " + f.name

            result.push(documentOf);
        });
        return result;
    }

    const onChangeSelectedFolders = (changedFolders: IManagerFolder[]) => {
        const temp = _.cloneDeep(changedFolders)
        temp
            .filter(f => f.documentOfId !== undefined && f.documentOfId !== null)
            .forEach(f => {
                const container = temp.find(i => i.id === f.documentOfId && f.selected);
                if (container !== undefined)
                    container.selected = true;
            });

        const selected = temp.filter(f => (f.documentOfId === undefined || f.documentOfId === null) && f.selected);

        if (props.isProfileFoldersBulk !== undefined && props.isProfileFoldersBulk)
            setSelectedFolders(changedFolders);
        else
            setSelectedFolders(selected);

        if (selected.length === totalFolders && props.profileId === undefined)
            setSelectedFolders([]); //only for cross folders filter in search component, if you select all the folders, you don't have to filter for any folder

        if (selected.length !== 0 || props.profileId !== undefined)
            setCanSave(true);
        else
            setCanSave(false);
    }

    const getFolders = async () => {
        setIsLoading(true);
        let archiveFolders: Folder[] = [];

        if (props.profileId !== undefined) {
            if (!props.isProfileFoldersBulk) {
                await archivesApi.getAllFolders(props.archiveId).then((result) => {
                    archiveFolders = convertToManagerFolders(result);
                });

                let profileFolders: IFolder[] = [];
                await archivesApi.getProfileFolders(props.profileId).then((result) => {
                    profileFolders = result;
                });
                const fs: Folder[] = [];
                archiveFolders.forEach(f => {
                    const fselected = profileFolders.find(i => i.id === f.documentOfId);

                    if (fselected) {
                        f.selected = true;
                    }
                    fs.push(f);
                });
                setFolders(fs);
            }
            else {
                try {
                    const childFolders = await archivesApi.getNextFolderLevel(0, props.profileId, undefined);
                    const foldersBulk = convertToManagerFoldersBulk(childFolders);
                    setFolders(foldersBulk);
                }
                catch (er) {
                    const error: ErrorDetails = er as ErrorDetails;
                    switch (error.code) {
                        case 400:
                            if (error.subCode === 1)
                                setErrorMessageFooter(t(props.translationName + '.errorProfileNotFound'));
                            if (error.subCode === 2)
                                setErrorMessageFooter(t(props.translationName + '.errorParentFolderNotFound'));
                            break;
                        case 422:
                            setErrorMessageFooter(t('common:genericErrorApi'));
                            break;
                        default:
                            setErrorMessageFooter(t('common:genericErrorApi'));
                            break;
                    }
                }
            }
        }
        else {
            await archivesApi.getFoldersWithPermission(props.archiveId).then((result) => {
                archiveFolders = convertToManagerFolders(result);
                setTotalFolders(result.length);
            });
            setFolders(archiveFolders);

            if (props.selectedFolder !== undefined) {
                const fs: Folder[] = [];
                archiveFolders.forEach(f => {
                    if (props.selectedFolder!.find(idF => idF === f.documentOfId)) //eslint-disable-line @typescript-eslint/no-non-null-assertion
                        f.selected = true;
                    fs.push(f);
                });
                setFolders(fs);
            }
        }
    }

    const getNextFolders = async (folder: IManagerFolder, folderSelected: boolean | undefined) => {
        if (!props.profileId)
            return;

        setLoadingFolders(true);
        let status = folderSelected;

        const parent = foldersWithState.find(f => f.folderId === folder.parentId);
        if (parent) {
            switch (parent.state) {
                case true:
                    status = true;
                    break;
                case false:
                    status = false;
                    break;
                case undefined:
                    status = folderSelected
                    break;
                default:
                    status = folderSelected
                    break;
            }
        }

        if (!foldersWithState.find(f => f.folderId === folder.id)) {
            const folderWithState: FolderWithState = { folderId: folder.id, state: status }
            setFoldersWithState(prevState => [...prevState, folderWithState]);
        }

        try {
            const childFolders = await archivesApi.getNextFolderLevel(folder.id, props.profileId, status);
            if (childFolders.length > 0) {
                setFolders([]);
                const folderList = folders.filter(f => f.documentOfId === undefined || f.documentOfId === null);
                const updatedFolderList = folderList.map((folder) => ({
                    ...folder,
                    selectedAllChildren: folder.selected,
                }));
                const index = updatedFolderList.findIndex(f => f.id === folder.id);
                if (index !== -1) {
                    updatedFolderList.splice(index + 1, 0, ...childFolders);
                }
                const foldersBulk = convertToManagerFoldersBulk(updatedFolderList);
                setFolders(foldersBulk);
            }
        }
        catch (er) {
            const error: ErrorDetails = er as ErrorDetails;
            switch (error.code) {
                case 400:
                    if (error.subCode === 1)
                        setErrorMessageFooter(t(props.translationName + '.errorProfileNotFound'));
                    if (error.subCode === 2)
                        setErrorMessageFooter(t(props.translationName + '.errorParentFolderNotFound'));
                    break;
                case 422:
                    setErrorMessageFooter(t('common:genericErrorApi'));
                    break;
                default:
                    setErrorMessageFooter(t('common:genericErrorApi'));
                    break;
            }
        }
        finally {
            setLoadingFolders(false);
        }
    }

    const bodyModal = () => {
        return <>
            {isLoading && <Spinner style={{ height: 'inherit' }} size={SpinnerSize.large} />}
            {!isLoading &&
                <div style={{ height: '100%' }}>
                    <div style={{ height: '90px' }}>
                        <Label>{t(props.translationName + '.secondaryTitle')}</Label>
                        <p>{t(props.translationName + '.description')}</p>
                    </div>
                    <div style={{ height: 'calc(100% - 90px - 35px)' }}>
                        {errorMessageFooter !== undefined && errorMessageFooter !== '' ?
                            <TeamsImage
                                styles={{
                                    img: {
                                        width: '25%',
                                        height: '25%'
                                    }
                                }}
                                imageName={ImageName.EmptyFolderDrop}
                                caption={t("common:emptyList")}
                            />
                            :
                            folders &&
                            <FolderTreeViewer
                                key={'folder-tree-viewer'}
                                folders={folders}
                                onChange={onChangeSelectedFolders}
                                isFolderBulk={props.isProfileFoldersBulk !== undefined && props.isProfileFoldersBulk ? true : false}
                                loading={props.isProfileFoldersBulk !== undefined && props.isProfileFoldersBulk ? loadingFolders : false}
                                onGetNextFolders={(folder, selected) => getNextFolders(folder, selected)}
                            />
                        }
                    </div>
                    {props.profileId === undefined &&
                        <div className={classNames.warningContainer}>
                            <Icon style={{ padding: '3px', color: 'rgb(169, 90, 76)', fontSize: 'x-large' }} iconName="Warning"></Icon>
                            <Label style={{ color: 'rgb(169, 90, 76)' }}>{t(props.translationName + '.warningMessage')}</Label>
                        </div>
                    }
                </div>
            }
        </>
    }

    const footer = () => {
        return <div className={classNames.footerContainer}>
            <div style={{ fontSize: '14px', color: 'rgb(164, 38, 44)' }}>
                {errorMessageFooter}
            </div>
            <div>
                <DefaultButton onClick={props.onClose}>{t(props.translationName + '.cancel')}</DefaultButton>
                <PrimaryButton disabled={!canSave} style={{ marginLeft: '10px' }} onClick={save}>{t(props.translationName + '.save')}</PrimaryButton>
            </div>
        </div>
    }

    const save = async () => {
        setCanSave(false);
        setIsLoading(true);

        try {
            const selectedFoldTempWithoutDoc = selectedFolders.filter(f => (f.selected || selectedFolders.some(doc => doc.documentOfId === f.id && doc.selected)) && (f.documentOfId === undefined || f.documentOfId === null));

            if (props.isProfileFoldersBulk !== undefined && props.isProfileFoldersBulk){
                const treeFolderList = selectedFolders.map(f => ({
                    folderId: f.id,
                    retrieveFolderChildren: (props.isProfileFoldersBulk !== undefined && props.isProfileFoldersBulk) ? f.selected : false,
                    selected: (selectedFoldTempWithoutDoc.findIndex(b => b.id === f.id) !== -1 ? true : f.selected)
                }));
                await archivesApi.updateProfileFolderBulk(props.profileId ?? 0, treeFolderList, true);
            }
            else {

                const treeFolderList = selectedFoldTempWithoutDoc.map(f => ({
                    folderId: f.id,
                    retrieveFolderChildren: (props.isProfileFoldersBulk !== undefined && props.isProfileFoldersBulk) ? f.selected : false,
                }));

                await archivesApi.updateProfileFolder(props.profileId ?? 0, treeFolderList);
            }    

            if (props.onComplete)
                props.onComplete();
        }
        catch (er) {
            const error: ErrorDetails = er as ErrorDetails;
            if (error.code === 400)
                setErrorMessageFooter(t(props.translationName + '.errorProfileNotFound'));
            else
                setErrorMessageFooter(t('common:genericErrorApi'));
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <EdiModal
            styles={classNames.subComponentStyles.ediModal}
            isOpen={props.isOpen}
            title={t(props.translationName + '.primaryTitle')}
            footer={footer()}
            body={bodyModal()}
            height={700}
            width={900}
            showCloseIcon={true}
            onCloseClick={props.onClose}
        />
    );
}