/*eslint-disable @typescript-eslint/no-non-null-assertion*/
/*eslint-disable @typescript-eslint/no-explicit-any*/
import { classNamesFunction, DefaultButton, PrimaryButton, TextField } from "@fluentui/react";
import { nanoid } from "@reduxjs/toolkit";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { Helpers } from "../../../../utilities/helpers";
import { useDocLabDispatch } from "../../../docLabStore";
import { loadFolderContent, selectItems, useArchiveContent } from "../../../features/archiveContent";
import { insertCall, setCall } from "../../../features/callNotification";
import { ArchiveItem } from "../../../models/archiveItem";
import { Call, callsList } from "../../../models/callsApi";
import { Constants } from "../../../models/constants";
import { archivesApi } from "../../../services/archives/archives.api";
import { IRenameItemModalProps, IRenameItemModalPropsStyles, IRenameItemModalStyles } from "./renameItemModal.types";

const getClassNames = classNamesFunction<IRenameItemModalPropsStyles, IRenameItemModalStyles>();

export const RenameItemModalBase = (props: IRenameItemModalProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const { palette } = props.theme!;
  const { items, selectedItems, renameModalShown } = useArchiveContent();
  const [item, setItem] = useState<ArchiveItem>();
  const [pickerErrorMessage, setPickerErrorMessage] = useState("");
  const [newItemName, setNewItemName] = useState("");
  const { t } = useTranslation(['renameItemModal', 'common']);
  const dispatch = useDocLabDispatch();

  useEffect(() => {
    if (selectedItems.length === 0 || items.length === 0)
      return;

    const found = items.find(item => item.id === selectedItems[0].id && item.isFolder === selectedItems[0].isFolder);
    setItem(found);

    if (found) {
      if (!found.isFolder)
        setNewItemName(Helpers.getFilename(found.name ?? ""));
      else
        setNewItemName(found.name ?? "");
    }
  }, [selectedItems, items]);

  const itemExtension = useMemo(() => {
    if (!item?.isFolder)
      return Helpers.getFileExtension(item?.name ?? "");
    else
      return "";
  }, [item]);

  const itemName = useMemo(() => {
    if (!item?.isFolder)
      return Helpers.getFilename(item?.name ?? "");
    else
      return item?.name;
  }, [item]);

  const rename = async () => {
    if (!item)
      return;
    const renameItemCall: Call = {
      type: callsList.renameFolder,
      nameOperation: t('operation'),
      errors: [
        { code: 403, message: t("errorCode403") },
        { code: 404, message: t("errorCode404") },
        { code: 409, message: t("errorCode409") },
        { code: 500, message: t('common:genericErrorApi') }
      ]
    };
    const id = nanoid();
    const payload = { requestId: id, notification: renameItemCall }
    dispatch(insertCall(payload));
    props.onClose && props.onClose();

    try {
      selectedItems[0].isFolder ?
        await archivesApi.renameFolder(item.id, newItemName) :
        await archivesApi.renameFile(item.id, `${newItemName}${itemExtension}`);
      const successPayload = { requestId: id, success: true, message: t('successMessage') }
      dispatch(setCall(successPayload));
      dispatch(loadFolderContent({ resetPage: true }));
      dispatch(selectItems([]));

    }
    catch (error: any) {
      let errorMessage = '';
      switch (error.code) {
        case 403: errorMessage = t("errorCode403"); break;
        case 404: errorMessage = t("errorCode404"); break;
        case 409: errorMessage = t("errorCode409"); break;
        default: errorMessage = t("common:genericErrorApi"); break;
      }
      const failurePayload = { requestId: id, success: false, message: errorMessage }
      dispatch(setCall(failurePayload));
    }
  }

  const body = (
    <div className={classNames.innerContent}>
      <TextField
        label={""}
        autoComplete={"off"}
        maxLength={Constants.MAX_LENGTH_ARCHIVE_ITEM_NAME}
        rows={1}
        autoFocus
        styles={classNames.subComponentStyles.textField}
        defaultValue={newItemName}
        placeholder={t("placeholder")}
        errorMessage={pickerErrorMessage}
        onChange={(_, newValue) => {
          setNewItemName(newValue ?? "");
          setPickerErrorMessage("");
        }}
      />
      <span style={{ color: palette.themePrimary, marginLeft: '4px' }}>{itemExtension}</span>
    </div>
  );

  const footer = (
    <div className={classNames.firstFooterContainer}>
      <DefaultButton
        style={{ margin: '0px 4px' }}
        text={t("cancel")}
        onClick={props.onClose}
      />
      <PrimaryButton
        style={{ margin: '0px 4px' }}
        text={t("save")}
        onClick={() => rename()}
        disabled={itemName === newItemName || newItemName.length === 0}
      />
    </div>
  );

  return (
    <EdiModal
      isOpen={renameModalShown && !!item}
      showCloseIcon={false}
      onCloseClick={props.onClose}
      title={t("rename")}
      body={body}
      footer={footer}
      width={400}
    />
  );
}