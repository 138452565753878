/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable sonarjs/no-identical-functions */
/*eslint-disable no-loop-func */
import { IManagerFolder } from "./IManagerFolder";

export class FolderTreeManager {
    defaultFolders: IManagerFolder[];
    singleFolderSelection?: boolean;
    showRootFolder?: boolean;
    constructor(defaultItems: IManagerFolder[], singleFolderSelection?: boolean, showRootFolder?: boolean){
        this.defaultFolders = defaultItems;
        this.singleFolderSelection = singleFolderSelection;
        this.showRootFolder = showRootFolder;
        this.checkSelected();
    }

    private checkSelected(){
        if(!this.singleFolderSelection)
            this.defaultFolders.forEach(f => {
                this.autoSelectOrUnselectParent(f.parentId);
            })
        this.defaultFolders.filter(f => f.documentOfId !== undefined).forEach(f => {
            if(f.selected)
                this.selectDocumentInFolder(f, true);
        })
    }

    tryCollapseOrExpand(folderId: number){
        const folder = this.getFolderByKey(folderId);
        if(folder === undefined) return;
        if(folder.collapsed){
            this.expandFolder(folder);
        }
        else{
            this.collapseFolder(folder);
        }
    }

    getRoot(){
        return this.defaultFolders.find(i => i.parentId === null || i.parentId === undefined)
    }

    collapseAll(){
        const root = this.getRoot();
        if (root === undefined) return;
        const rootChildren = this.getChildren(root);
        
        if(this.showRootFolder){
            root.collapsed = true;
            rootChildren.forEach(c => {
                this.hide(c);
                this.collapseFolder(c)
            });
        }
        else
            rootChildren.forEach(c => this.collapseFolder(c));
        
    }

    expandAll(){
        this.defaultFolders.forEach(f => {
            this.expandFolder(f);
        })
    }

    expandSelected(folders?: IManagerFolder[]){

        if(!folders){
            this.defaultFolders.forEach(f => {
                const children = this.getChildren(f);
                if(children.length > 0){
                    children.forEach(c => {
                        if(c.selected){
                            this.expandFolder(f);
                            this.expandSelected(children)
                        }
                    })
                }
                
            })
        }
        else{
            folders.forEach(f => {
                const children = this.getChildren(f);
                if(children.length > 0){
                    children.forEach(c => {
                        if(c.selected){
                            this.expandFolder(f);
                            this.expandSelected(children)
                        }
                    })
                }
                
            })
        }
    }

    expandSingleSelected(){
        this.defaultFolders.forEach(f => {
           if(f.selected){
            const parents = this.getParents(f);
            parents.forEach(p => {
                this.expandFolder(p);
            })
           }
            
        })
    }

    getFolderByKey(key: number){
        return this.defaultFolders.find(f => f.id === key);
    }
    //hide all children
    collapseFolder(folder: IManagerFolder){
        const children = this.getChildren(folder);
        folder.collapsed = true;
        if(children.length > 0){
            
            //hide all children
            children.forEach(child => {
                this.hide(child);

                //hide children of child
                this.collapseFolder(child);
            });
        }
    }

    expandFolder(folder: IManagerFolder){
        folder.collapsed = false;
        const children = this.getChildren(folder);
        if(children.length > 0){
            //hide all children
            children.forEach(child => {
                this.show(child);
            });
        }
    }
    
    getChildren(folder: IManagerFolder){
        return this.defaultFolders.filter((f) => f.parentId === folder.id);
    }

    getParents(folder: IManagerFolder){
        const folderList: IManagerFolder[] = [];
        let parent = this.defaultFolders.find((f) => f.id === folder.parentId);

        while(parent) { 
            folderList.push(parent);
            parent = this.defaultFolders.find((f) => f.id === parent?.parentId);
        } 
    
        return folderList;
    }

    getChildrenById(folderId: number){
        const folder = this.defaultFolders.find(f => f.id === folderId);
        if (folder === undefined) return [];
        return this.defaultFolders.filter((f) => f.parentId === folder.id);
    }

    show(folder: IManagerFolder){
        folder.hidden = false;
    }

    hide(folder: IManagerFolder){
        folder.hidden = true;
    }

    trySelectOrUnselect(folderId: number){
        const folder = this.defaultFolders.find(f => f.id === folderId);
        if(folder === undefined)
            return;

        if(!folder.selected){
            this.selectFolder(folder, false);
        }
        else{
            this.unSelectFolder(folder, false);

            if(folder?.parentId !== undefined && !this.singleFolderSelection){
                this.unselectParent(folder.parentId);
            }
        }

        if(!this.singleFolderSelection)
            this.autoSelectOrUnselectParent(folder.parentId) //check if parent must be checked
    }

    autoSelectOrUnselectParent(parentId : number | undefined){
        if(parentId === undefined) return;
        
        const parent = this.getFolderByKey(parentId);
        if(parent === undefined) return;

        if(this.verifyCheckOnParent(parentId)){
            parent.selected = true;
        }
        else{
            parent.selected = false;
        }
        this.autoSelectOrUnselectParent(parent.parentId);
    }

    verifyCheckOnParent(parentFolderId: number | undefined) {
        if(parentFolderId === undefined) return false;
        const folder = this.getFolderByKey(parentFolderId);
        if(folder === undefined) return false;
        const children = this.getChildren(folder);
        return children.every(c => c.selected);
    }

    unSelectFolder(folder: IManagerFolder, unSelectAll: boolean){
        if(folder?.documentOfId !== undefined && folder?.documentOfId !== null){
            this.unSelectDocumentInFolder(folder);
            return;
        }

        folder.selected = false;

        if(unSelectAll || !this.singleFolderSelection){
            const children = this.getChildren(folder);        
            children.forEach(f => {
                this.unSelectFolder(f, true);
            });
        }
    }

    selectFolder(folder: IManagerFolder, selectAll : boolean){
        if(folder?.documentOfId !== undefined){
            this.selectDocumentInFolder(folder);
            return;
        }

        folder.selected = true;

        if(selectAll || !this.singleFolderSelection){
            const children = this.getChildren(folder);        
            children.forEach(f => {
                this.selectFolder(f, true);
            });
        }
    }

    unselectParent(parentId: number){
        const folder = this.defaultFolders.find(f => f.id === parentId);
        if(folder !== undefined && folder!== null){
            folder.selected = false;
            if(folder.parentId !== undefined && folder.parentId !== null){
                this.unselectParent(folder.parentId);
            }
        }
        
    }

    allSelected(){
        return this.defaultFolders.every(f => f.selected);
    }

    trySelectAllOrUnselect(){
        const root = this.defaultFolders.find(f => f.parentId === undefined || f.parentId === null);
        if(root === undefined) return;
        if(this.allSelected()){
            this.unSelectFolder(root, true);
        }
        else{
            this.selectFolder(root, true);
        }
    }

    selectDocumentInFolder(documents: IManagerFolder, canSelect = true){
        documents.selected = true;
        documents.canSelect = canSelect;
        if(documents.parentId !== undefined && documents.parentId !== null){
            const parent = this.getFolderByKey(documents.parentId);
            if(parent?.parentId !== undefined){
                const parentDocuments = this.getDocumentsOfFolder(parent?.parentId);
                if(parentDocuments !== undefined){
                    this.selectDocumentInFolder(parentDocuments, false);
                }
                    
            }    
        }
    }

    unSelectDocumentInFolder(documents: IManagerFolder){
        documents.canSelect = true;
        documents.selected = false;
        if(documents.parentId === undefined) return;
        const parent = this.getFolderByKey(documents.parentId);
        if(parent !== undefined && parent?.parentId !== undefined){
            const grandParent = this.getFolderByKey(parent.parentId);
            if(!this.parentFolderHasOthersDocuments(documents) && grandParent !== undefined){
                const granParentDocuments = this.getDocumentsOfFolder(grandParent.id)
                if(granParentDocuments !== undefined)
                    granParentDocuments.canSelect = true;
            }
        }
    }

    getDocumentsOfFolder(parentId: number){
        return this.defaultFolders.find(f => f.parentId === parentId && f.documentOfId !== undefined)
    }


    parentFolderHasOthersDocuments(currentDocuments: IManagerFolder){
        if(currentDocuments.parentId !== undefined && currentDocuments.parentId !== null){
            const parent = this.getFolderByKey(currentDocuments.parentId);
            if(parent?.parentId !== undefined && parent?.parentId !== null){
                const brothers = this.getChildrenById(parent?.parentId);
                return brothers.some(b => b.id !== currentDocuments.parentId && this.getDocumentsOfFolder(b.id)?.selected === true);
            }    
        }
        return false;
    }

  }
  
