import { styled } from "@fluentui/react";
import { getStyles } from "./folderNavigation.styles";
import { IFolderNavigationPropsStyles, IFolderNavigationStyles, IFolderNavigationProps } from "./folderNavigation.types";
import { FolderNavigationBase } from "./folderNavigation.base";

const FolderNavigation = styled<IFolderNavigationProps, IFolderNavigationPropsStyles, IFolderNavigationStyles>(
    FolderNavigationBase,
    getStyles,
    undefined,
    { scope: 'FolderNavigation' }
);

export default FolderNavigation;