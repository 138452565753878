import { IStyleFunction } from "@fluentui/react" 
import { IWorkflowApprovalStepPropsStyles, IWorkflowApprovalStepStyles } from "./workflowApprovalStep.types"

export const WorkflowApprovalStepGlobalClassNames = {
    root: 'edi-workflow-approval-step-root'
}

export const getStyles: IStyleFunction<IWorkflowApprovalStepPropsStyles, IWorkflowApprovalStepStyles> = (props: IWorkflowApprovalStepPropsStyles): IWorkflowApprovalStepStyles => {
    
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            WorkflowApprovalStepGlobalClassNames.root, 
                {
                    background: palette.white,
                    width: 'auto', 
                    height: '210px',
                    borderRadius: '4px',  
                    boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 4px -0.75px', 
                } 
        ],  
    };
}