/*eslint-disable @typescript-eslint/no-explicit-any */
/*eslint-disable sonarjs/no-identical-functions*/
import { nanoid } from "@reduxjs/toolkit";
import { Helpers } from "../../utilities/helpers";
import { startDownload } from "../features/downloadStatus";
import { knowledgeLabApi } from "../services/knowledgeLab.api";
import { KnowledgeLabRoleId } from "../models/knowledgeLabRoleId";
import { IActionDelegate } from "../hooks/useActionDelegate";
import { filePreviewSubPath } from "../../utilities/constants";
import { KnowledgeLabShort } from "../models/knowledgeLab";
import { KnowledgeSettingsTab } from "../routes/routes";
import { KnowledgeElement } from "../models/knowledgeElement";
import { setRefreshFavourites, setSelectedElement, setSubParagraphModalOpen } from "../features/knowledgeLabContent";

export type ActionEntry = {
    id: string;
    knowledgeRole: KnowledgeLabRoleId[];
    label?: string;
    icon?: string;
    showInToolbar: boolean;
    showInElementDetailModal: boolean;
    showInKnowledgeContextualMenu: boolean;
    hideIfElementIsFavourite?: boolean;
    hideIfElementIsNotFavourite?: boolean;
    subActionItems?: ActionEntry[];
    onClick: IActionDelegate;
    maxDepth?: number;
    toBeIndexedCheck?: boolean;
}


const actions: ActionEntry[] = [
    {
        id: "add-to-favourites",
        knowledgeRole: [KnowledgeLabRoleId.Owner, KnowledgeLabRoleId.Contributor, KnowledgeLabRoleId.Reader],
        label: 'commands.addToFavourites',
        icon: 'Heart',
        showInToolbar: true,
        showInElementDetailModal: true,
        showInKnowledgeContextualMenu: false,
        hideIfElementIsFavourite: true,
        onClick: (state, __, dispatch) => {

            const element = state.knowledgeLabContent.selectedElement;

            if (element)
                knowledgeLabApi.addElementToFavourites(parseInt(element.id)).then((res: KnowledgeElement) => {
                    if (res) {
                        const additionalData = { ...element.additionalData };
                        if (additionalData)
                            additionalData["isFavourite"] = String(res.isFavourite)
                        dispatch(setSelectedElement({
                            ...element,
                            additionalData: additionalData,
                            increaseViews: false
                        }))
                    }
                });
        }
    },
    {
        id: "remove-to-favourites",
        knowledgeRole: [KnowledgeLabRoleId.Owner, KnowledgeLabRoleId.Contributor, KnowledgeLabRoleId.Reader],
        label: 'commands.removeToFavourites',
        icon: 'HeartFill',
        showInToolbar: true,
        showInElementDetailModal: true,
        showInKnowledgeContextualMenu: false,
        hideIfElementIsNotFavourite: true,
        onClick: (state, __, dispatch) => {

            const element = state.knowledgeLabContent.selectedElement;

            if (element)
                knowledgeLabApi.removeElementFromFavourites(parseInt(element.id)).then((res: KnowledgeElement) => {
                    if (res) {
                        const additionalData = { ...element.additionalData };
                        if (additionalData)
                            additionalData["isFavourite"] = String(res.isFavourite)
                        dispatch(setSelectedElement({
                            ...element,
                            additionalData: additionalData,
                            increaseViews: false
                        }))
                        dispatch(setRefreshFavourites(true))
                    }
                });
        }
    },
    {
        id: "download-file",
        knowledgeRole: [KnowledgeLabRoleId.Owner, KnowledgeLabRoleId.Contributor],
        label: 'commands.downloadFile',
        icon: 'CloudDownload',
        showInToolbar: true,
        showInElementDetailModal: true,
        showInKnowledgeContextualMenu: false,
        onClick: (state, __, dispatch) => {
            const { selectedElement } = state.knowledgeLabContent;
            if (selectedElement) //eslint-disable @typescript-eslint/no-explicit-any
                knowledgeLabApi.getFilesById([selectedElement.fileRef]).then((files: any[]) => {
                    const file = files[0];
                    dispatch(startDownload({ requestId: nanoid(), file: { id: selectedElement.fileRef, name: file.name }, downloadFunction: knowledgeLabApi.downloadFile }));
                });
        }
    },
    {
        id: "view-file",
        knowledgeRole: [KnowledgeLabRoleId.Owner, KnowledgeLabRoleId.Contributor],
        label: 'commands.viewFile',
        icon: 'RedEye',
        showInToolbar: true,
        showInElementDetailModal: true,
        showInKnowledgeContextualMenu: false,
        onClick: (state, __, ___) => {
            const { selectedElement } = state.knowledgeLabContent;
            if (selectedElement)
                Helpers.getKnowledgeLabPreviewFile(parseInt(selectedElement.id), filePreviewSubPath.element);
        }
    },
    {
        id: "manage-users",
        knowledgeRole: [KnowledgeLabRoleId.Owner],
        label: 'common:manageUsers',
        icon: 'PlayerSettings',
        showInToolbar: false,
        showInElementDetailModal: false,
        showInKnowledgeContextualMenu: true,
        onClick: (state, navigator, _) => {
            const { selectedKnowledgeLab } = state.knowledgeLabList;
            if (selectedKnowledgeLab)
                navigator.goToKnowledgeSettingsTab(KnowledgeSettingsTab.users, selectedKnowledgeLab.id)
        }
    },
    {
        id: "view-sub-paragraph",
        knowledgeRole: [KnowledgeLabRoleId.Owner, KnowledgeLabRoleId.Contributor],
        label: 'commands.viewSubParagraph',
        icon: 'DocumentSearch',
        showInToolbar: true,
        showInElementDetailModal: true,
        showInKnowledgeContextualMenu: false,
        onClick: (_, __, dispatch) => {
            dispatch(setSubParagraphModalOpen(true));
        }
    }
];

const normalizedActions = Helpers.normalize(actions, action => action.id);


export const getKnowledgeLabContentActionsByIds = (actionIds: string[]) => {
    return actionIds.map(id => normalizedActions[id]);
}


export const knowledgeLabContentActions = actions;

export const getKnowledgeActions = (lab: KnowledgeLabShort, roleId: KnowledgeLabRoleId) => {

    return actions.filter(entry =>
        entry.showInKnowledgeContextualMenu &&
        entry.knowledgeRole.includes(roleId));
}

export const getKnowledgeModalActions = (lab: KnowledgeLabShort, roleId: KnowledgeLabRoleId, enableSecondContent = false) => {

    let filteredActions = actions;

    if (!enableSecondContent) {
        filteredActions = filteredActions.filter(a => a.id !== "view-sub-paragraph");
    }

    return filteredActions.filter(entry =>
        entry.showInElementDetailModal &&
        entry.knowledgeRole.includes(roleId));
}
