import { IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";

export interface IImportDocumentModalProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<IImportDocumentModalPropsStyles, IImportDocumentModalStyles>;
    className?: string;
    isOpen: boolean;
    onClose: () => void;
}

export interface IImportDocumentModalPropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<IImportDocumentModalPropsStyles, IImportDocumentModalStyles>;
    className?: string;
}

export interface IImportDocumentModalStyles {
    root: IStyle;
    completeStepContainer?: IStyle;
    wellDoneImage?: IStyle;
    footer?: IStyle;
}

export enum EdiItemType {
    folder,
    archive,
    file,
    documentLibrary
}