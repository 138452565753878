import { IDropdownOption, IPersonaProps } from "@fluentui/react"
import { PortfolioRoleId } from "../activityLab/models/constants"
import { WorkFlowApprover, WorkFlowApproverStatus } from "../activityLab/models/workflowApprover"
import { IExtPersonaProps } from "../common/components/modalPages/addMembersPage"
import { IUser } from "../common/interfaces/IUser"
import { KeyValueLanguagesType } from "../docLab/components/files/archiveContent/documentTranslationModal/documentTranslationModal.types"
import { Archive } from "../docLab/models/archive"
import { UserDashboardShort } from "../dashboard/models/dashboardMember"

export const mapper = {
    mapUserToPersona: (user: IUser | undefined): IPersonaProps => {
        return {
            text: user ? `${user.firstName} ${user.lastName}` : '',
            secondaryText: user ? user.email : ''
        }
    },
    mapUserShortToPersone: (item: UserDashboardShort): IPersonaProps => {
        if(!item)
        return {

        }

        return {
            text: `${item.firstName} ${item.lastName}`,
            secondaryText: item.email
        }
    },
    mapPersonaToUser: (persona: IExtPersonaProps): IUser => {
        return {
            id: persona.userData.id ? persona.userData.id : '',
            firstName: persona.userData.firstName ? persona.userData.firstName : '',
            lastName: persona.userData.lastName ? persona.userData.lastName : '',
            email: persona.userData.email ? persona.userData.email : '',
            roleId: persona.userData.roleId || undefined, 
        }
    },
    mapPersonaToApprover: (persona: IExtPersonaProps) : WorkFlowApprover => {
        return {
            status: WorkFlowApproverStatus.ToBeApproved,
            workFlowStepId: 0,
            user: {
                id: persona.userData.id ? persona.userData.id : '',
                firstName: persona.userData.firstName ? persona.userData.firstName : '',
                lastName: persona.userData.lastName ? persona.userData.lastName : '',
                email: persona.userData.email ? persona.userData.email : '',
                hasActivities: false,
                roleId: PortfolioRoleId.Viewer,
                templateActivities: []
            }
        }
    },  

    // Archive --> IDropdownOption
    mapArchiveToDropdownOption: (archive: Archive | undefined): IDropdownOption =>{
        return{
            text: archive? archive.name : '',
            key: archive? archive.id: 0
        }
    },

    mapLanguagesToDropdownOption: (languages: KeyValueLanguagesType): IDropdownOption => {
        return{
            text: languages.value,
            key: languages.key
        }
    },

    mapExtraPropertyToDropdownOption: (exstraProperty: string, index: number): IDropdownOption =>{
        return{
            text: exstraProperty,
            key: index
        }
    },
}