import { styled } from "@fluentui/react";
import { DocLabSettingsBase } from "./docLabSettings.base";
import { getStyles } from "./docLabSettings.styles";
import { IDocLabSettingsProps, IDocLabSettingsPropsStyles, IDocLabSettingsStyles } from "./docLabSettings.types";

const DocLabSettings = styled<IDocLabSettingsProps, IDocLabSettingsPropsStyles, IDocLabSettingsStyles>(
    DocLabSettingsBase,
    getStyles,
    undefined,
    { scope: 'DocLabSettings' }
);

export default DocLabSettings;