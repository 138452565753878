import { IStyleFunction } from "@fluentui/react"
import { IFolderTreeViewerBasePropsStyles, IFolderTreeViewerBaseStyles } from "./folderTreeViewer.types";


export const FolderTreeViewerGlobalClassNames = {
    root: 'edi-folder-tree-viewer-root',
    row: 'edi-folder-tree-viewer-row',
    column: 'edi-folder-tree-viewer-column',
    longColumn: 'edi-folder-tree-viewer-longColumn',
    folderIcon: 'edi-folder-tree-viewer-felderIcon',
    chevron: 'edi-folder-tree-viewer-chevron',
    expandChevron: 'edi-folder-tree-viewer-hide-expand',
    customCheckbox: 'edi-folder-tree-viewer-hide-customCheckbox',
    customCheckboxHide: 'edi-folder-tree-viewer-hide-customCheckbox-hide',
    treeViewerSelector: 'edi-folder-tree-viewer-selector'
}

export const getStyles: IStyleFunction<IFolderTreeViewerBasePropsStyles, IFolderTreeViewerBaseStyles> = (props: IFolderTreeViewerBasePropsStyles): IFolderTreeViewerBaseStyles => {
    return {
        root: [
            FolderTreeViewerGlobalClassNames.root,
            {
                
            }
        ],
        row:[
            FolderTreeViewerGlobalClassNames.row,
            {
                display: 'flex',
                flexDirection: 'row',
                padding: '3px',
                borderBottom: '1px solid rgb(243, 242, 241);',
                //borderTop: '1px solid rgb(243, 242, 241);',
                alignItems: 'center',

                selectors: {
                    '> *': {
                        paddingLeft: '10px'
                    }
                }
            }
        ],
        column:[
            FolderTreeViewerGlobalClassNames.column,
            {
                width: '192px !important',
                fontSize: '13px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                fontWeight: 'normal'
            }
        ],
        longColumn:[
            FolderTreeViewerGlobalClassNames.longColumn,
            {
                width: '285px !important',
                fontSize: '13px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                fontWeight: 'normal'
            }
        ],
        folderIcon:[
            FolderTreeViewerGlobalClassNames.folderIcon,
            {
                width:'40px'
            }
        ],
        chevron: [
            FolderTreeViewerGlobalClassNames.chevron,
            {
                selectors: {
                    '> *': {
                        color: 'rgb(96, 94, 92);'
                    }
                }
            }
        ],
        expandChevron:[
            FolderTreeViewerGlobalClassNames.expandChevron,
            {
                visibility: 'hidden'
            }
        ],
        customCheckbox:[
            FolderTreeViewerGlobalClassNames.customCheckbox,
            {
                selectors: {
                    ' .ms-Checkbox-checkbox': {
                        borderRadius: '50%'
                    }
                }
                
            }
        ],
        customCheckboxHide:[
            FolderTreeViewerGlobalClassNames.customCheckboxHide,
            {
                visibility: 'none'
            }
        ],
        treeViewerSelector:[
            FolderTreeViewerGlobalClassNames.treeViewerSelector,
            {
                height: 'calc(100% - 80px)',
                overflow: 'auto',
            }
        ]
    }
}