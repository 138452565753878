/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable sonarjs/no-identical-functions */
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { classNamesFunction, DetailsList, DetailsRow, Icon, IDetailsRowProps, Persona, PersonaSize, PrimaryButton, ScrollablePane, SearchBox, SelectionMode, Stack } from "@fluentui/react";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useBoolean } from '@fluentui/react-hooks';
import { useTranslation } from "react-i18next";
import Accordion from "../../../../common/components/accordion/accordion";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import { mapper } from "../../../../utilities/mapper";
// import { useActivityLabDispatch } from "../../../activityLabStore";
import { useCurrentPortfolio } from "../../../hooks/useCurrentPortfolio";
import { PortfolioRoleId } from "../../../models/constants";
import { User } from "../../../models/user";
import { portfolioApi } from "../../../services/portfolio/portfolio.api";
import { MemberActivityTemplates } from "./subComponents/memberActivityTemplates";
import { MemberRoleDropdown } from "./subComponents/memberRoleDropdown";
import { RemoveMemberButton } from "./subComponents/removeMemberButton";
import { IPortfolioMembersSettingsProps, IPortfolioMembersSettingsPropsStyles, IPortfolioMembersSettingsStyles } from "./portfolioMembersSettings.types";
import PortfolioMembersModal from "../portfolioMembersModal/portfolioMembersModal";
import { Portfolio } from "../../../models/portfolio";
import { useNavigator } from "../../../hooks/useNavigator";

const getClassNames = classNamesFunction<IPortfolioMembersSettingsPropsStyles, IPortfolioMembersSettingsStyles>();

const getTotalMembersCount = (members?: Record<PortfolioRoleId, User[]>) => {
  if (!members || Object.keys(members).length === 0)
    return 0;
  return _.values(members)
    .map(list => list?.length ?? 0)
    .reduce((prev, curr) => prev + curr);
}

export const PortfolioMembersSettingsBase = (props: IPortfolioMembersSettingsProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const { t } = useTranslation(["activityLabUsersSettings", "common"]);
  const currentPortfolioId = useNavigator().currentPortfolioId!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
  const [memberSearchKeyword, setMemberSearchKeyword] = useState('');
  const [currentPortfolio, setCurrentPortfolio] = useState<Portfolio>(useCurrentPortfolio()!); //eslint-disable-line @typescript-eslint/no-non-null-assertion
  const [isUsersModalOpen, { setTrue: showUsersModal, setFalse: hideUsersModal }] = useBoolean(false);
  const [viewerPanelOpened, setViewerPanelOpened] = useState(false);

  const { execute: loadMembers, value: members, loading } =
    useAsyncApi<string, Record<PortfolioRoleId, User[]>>({
      keepResultsWhileReloading: true,
      func: async (keyword: string) => {
        if (!currentPortfolio) return {} as Record<PortfolioRoleId, User[]>;

        const portfolioMembers = await portfolioApi.getPortfolioMembers({
          id: currentPortfolioId,
          keyword: keyword
        });

        return _.groupBy(portfolioMembers, (member) => member.roleId) as Record<PortfolioRoleId, User[]>;
      },
    });

  const loadPortfolio = async () => {
    setCurrentPortfolio(await portfolioApi.getPortfolio(currentPortfolioId));
  }

  const totalMembersCount = useMemo(() => getTotalMembersCount(members), [members]);

  useOnMount(() => {
    loadPortfolio();
    loadMembers(memberSearchKeyword);
  });

  const ownerColumns = useMemo(() => {
    if (!currentPortfolioId) return [];

    return [
      {
        key: "persona",
        name: t('common:name'),
        minWidth: 100,
        headerClassName: classNames.headerRow,
        onRender: function renderMemberPersona(member: User) {
          return (
            <Persona {...mapper.mapUserToPersona(member)} size={PersonaSize.size40} coinSize={32} />
          );
        }
      },
      {
        key: "role",
        name: t('common:role'),
        minWidth: 120,
        maxWidth: 200,
        headerClassName: classNames.headerRow,
        onRender: function renderMemberRoles(member: User) {
          return (
            <MemberRoleDropdown
              portfolioId={currentPortfolioId}
              dropdownStyles={classNames.subComponentStyles.rolesDropdown}
              member={member}
              totalCount={totalMembersCount}
              onRoleChangeCompleted={async () => await loadMembers(memberSearchKeyword)}
            />
          );
        },
      },
      {
        key: "remove",
        name: "",
        minWidth: 40,
        headerClassName: classNames.headerRow,
        onRender: function renderMemberRemoveButton(member: User) {
          return (
            <RemoveMemberButton
              portfolioId={currentPortfolioId}
              buttonStyles={classNames.subComponentStyles.iconButton()}
              member={member}
              onRemoveMemberCompleted={async () => await loadMembers(memberSearchKeyword)}
            />
          );
        },
      }
    ];
  }, [classNames, currentPortfolioId, loadMembers, totalMembersCount, memberSearchKeyword, t]);

  const viewerColumns = useMemo(() => {
    if (!currentPortfolioId) return [];

    return [
      {
        key: "persona",
        name: t('common:name'),
        minWidth: 100,
        maxWidth: 350,
        headerClassName: classNames.headerRow,
        onRender: function renderMemberPersona(member: User) {
          return (
            <Persona {...mapper.mapUserToPersona(member)} size={PersonaSize.size40} coinSize={32} />
          );
        }
      },
      {
        key: "template",
        name: t('common:activityVisibility'),
        minWidth: 150,
        headerClassName: classNames.headerRow,
        onRender: function renderMemberTemplates(member: User, index?: number) {
          return (
            <MemberActivityTemplates
              portfolio={currentPortfolio}
              itemIndex={index}
              member={member}
              loaderContainerStyle={classNames.loaderContainer}
              templatesCountCircleStyle={classNames.subComponentStyles.templatesCountCircle}
              iconButtonStyles={classNames.subComponentStyles.iconButton()}
              onActivitiesSaved={async () => await loadMembers(memberSearchKeyword)}
            />
          );
        },
      },
      {
        key: "role",
        name: t('common:role'),
        minWidth: 120,
        maxWidth: 200,
        headerClassName: classNames.headerRow,
        onRender: function renderMemberRoles(member: User) {
          return (
            <MemberRoleDropdown
              portfolioId={currentPortfolioId}
              dropdownStyles={classNames.subComponentStyles.rolesDropdown}
              member={member}
              totalCount={totalMembersCount}
              onRoleChangeCompleted={async () => await loadMembers(memberSearchKeyword)}
            />
          );
        },
      },
      {
        key: "remove",
        name: "",
        minWidth: 40,
        headerClassName: classNames.headerRow,
        onRender: function renderMemberRemoveButton(member: User) {
          return (
            <RemoveMemberButton
              portfolioId={currentPortfolioId}
              buttonStyles={classNames.subComponentStyles.iconButton()}
              member={member}
              onRemoveMemberCompleted={async () => await loadMembers(memberSearchKeyword)}
            />
          );
        },
      }
    ];
  }, [classNames, currentPortfolioId, currentPortfolio, loadMembers, totalMembersCount, memberSearchKeyword, t]);

  const membersForRole = (role: PortfolioRoleId) => {
    const singleRoleMembers = members && members[role];
    if (!singleRoleMembers) return null;

    return (
      <Accordion title={`${PortfolioRoleId[role]} (${singleRoleMembers.length})`} opened={role === PortfolioRoleId.Owner || (role === PortfolioRoleId.Viewer && viewerPanelOpened)}
        additionalOnAccordionClick={() => { if (!viewerPanelOpened) setViewerPanelOpened(true); }} stickyHeader
      >
        <DetailsList styles={classNames.subComponentStyles.list} items={singleRoleMembers}
          getKey={(item: User) => item.id}
          onRenderRow={(props?: IDetailsRowProps) =>
            props
              ? (
                <DetailsRow {...props} styles={classNames.subComponentStyles.row} />
              ) : null
          }
          columns={role === PortfolioRoleId.Owner ? ownerColumns : viewerColumns}
          selectionMode={SelectionMode.none} />
      </Accordion>
    );
  };

  const onSearchBoxClear = () => { setMemberSearchKeyword(''); loadMembers(''); }

  return (
    <React.Fragment>
      <Stack className={classNames.root}>
        <Stack className={classNames.title} horizontal verticalAlign={"center"}>
          <Icon iconName={"PlayerSettings"} className={classNames.titleIcon} />
          <span className={classNames.titleContent}>{t("common:manageUsers")}</span>
        </Stack>
        <Stack horizontal className={classNames.upperSection} horizontalAlign={"space-between"}>
          <SearchBox
            key={currentPortfolio?.id}
            placeholder={t("searchBox")}
            className={classNames.searchBox}
            onChange={(_, value) => setMemberSearchKeyword(value || '')}
            onSearch={() => loadMembers(memberSearchKeyword)}
            onClear={onSearchBoxClear}
            value={memberSearchKeyword}
          />
          <PrimaryButton
            iconProps={{ iconName: "AddFriend" }}
            styles={classNames.subComponentStyles.addMembersButtonStyle()}
            text={t("addMembers")}
            onClick={showUsersModal}
          />
        </Stack>
        <Stack.Item verticalFill className={classNames.lowerSection}>
          {loading && totalMembersCount === 0
            ? (
              <div className={classNames.loaderContainer}>
                <TeamsSpinner />
              </div>
            ) : (!loading && totalMembersCount === 0)
              ? (
                <div className={classNames.loaderContainer}>
                  <TeamsImage imageName={ImageName.EmptySearch} caption={t('common:emptySearch')} />
                </div>
              ) : (
                <ScrollablePane styles={classNames.subComponentStyles.scrollablePane}>
                  {membersForRole(PortfolioRoleId.Owner)}
                  {membersForRole(PortfolioRoleId.Viewer)}
                </ScrollablePane>
              )
          }
        </Stack.Item>
      </Stack>
      {isUsersModalOpen && <PortfolioMembersModal
        isOpen={isUsersModalOpen}
        operationEnded={(success: boolean) => {
          hideUsersModal();

          if (success)
            loadMembers(memberSearchKeyword);
        }}
      />}
    </React.Fragment>
  );
};
