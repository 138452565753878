import { IStyleFunction } from "@fluentui/react";
import { IExportFolderZipModalPropsStyles, IExportFolderZipModalStyles } from "./exportFolderZipModal.types";

export const ExportFolderZipModalGlobalClassNames = {
    root: 'edi-export-folderZip-modal-root',
    buttonContainer: 'edi-export-folderZip-modal-buttonContainer',
    centerContentContainer: 'edi-export-folderZip-modal-centerContentContainer',
    secondFooterContainer: 'edi-export-folderZip-modal-secondFooterContainer',
    sandglassImage: 'edi-export-folderZip-modal-sandglassImage',
    exportingFooter: 'edi-export-folderZip-modal-exportingFooter',
}

export const getStyles: IStyleFunction<IExportFolderZipModalPropsStyles, IExportFolderZipModalStyles> = (props: IExportFolderZipModalPropsStyles): IExportFolderZipModalStyles => {
    return {
        root: [
            ExportFolderZipModalGlobalClassNames.root,
            {
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center'
            }
        ],
        buttonContainer: [
            ExportFolderZipModalGlobalClassNames.buttonContainer,
            {
                display: 'flex',
                justifyContent: 'flex-end',
                alignContent: 'center'
            }
        ],
        centerContentContainer: [
            ExportFolderZipModalGlobalClassNames.centerContentContainer,
            {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        ],
        secondFooterContainer: [
            ExportFolderZipModalGlobalClassNames.secondFooterContainer,
            {
                display: 'flex',
                alignItems: 'center',
                height: '100%'
            }
        ],
        sandglassImage: [
            ExportFolderZipModalGlobalClassNames.sandglassImage,
            {
                flexBasis: '50%',
                flexShrink: 0
            }
        ],
        exportingFooter: [
            ExportFolderZipModalGlobalClassNames.exportingFooter,
            {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center'
            }
        ]
    };
}