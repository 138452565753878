/*eslint-disable sonarjs/cognitive-complexity */
import React, { useState,useMemo } from "react";
import { classNamesFunction, DefaultButton, Dialog, DetailsList, DialogFooter, PrimaryButton, Spinner, SpinnerSize, DetailsRow, DetailsHeader, TooltipHost, TooltipOverflowMode, IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useDocLabDispatch, useDocLabState } from "../../../../docLabStore";
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive";
import { IModalStepData } from "../../../../../common/components/multiStepModal/multiStepModal.types";
import MultiStepModal from "../../../../../common/components/multiStepModal/multiStepModal";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { useAsyncApi, useOnMount } from "../../../../../utilities/hooks";
import { archivesApi } from "../../../../services/archives/archives.api";
import { ErrorsType } from "../../../../models/callsApi";
import FileUploader from "../../../../../common/components/fileUploader/fileUploader";
import { IFileToUpload } from "../../../files/uploadFileModal/uploadFileModal.types";
import { FileFormatExtension } from "../../../../../utilities/constants";
import { IImportACPVModalProps, IImportACPVModalPropsStyles, IImportACPVModalStyles } from "./importACPVModal.types";
import { showImportACPVModal } from "../../../../features/archiveContent";
import { ExcelType } from "../../../../models/constants";
import { IObjectWithKey, ISelection, Selection } from '@fluentui/react/lib/DetailsList';
import { MetadataSelect } from "../../../../models/metadataDependency";

const getClassNames = classNamesFunction<IImportACPVModalPropsStyles, IImportACPVModalStyles>();
enum SelectionMode {
    none = 0,
    single = 1,
    multiple = 2
}

enum ImportSteps {
    chooseExcelFile = 0,
    confirmupload = 1,
    selectMetadataToUpdate=2,
    completedImport = 3,    
}

export const ImportACPVModalBase = (props: IImportACPVModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['importMetadataModal', 'common']);
    const dispatch = useDocLabDispatch();
    const currentArchive = useCurrentArchive();
    const docLabState = useDocLabState();
    const [showDialog, setShowDialog] = useState(false);
    const [importFile, setImportFile] = useState<File | undefined>(undefined);
    const [currentStep, setCurrentStep] = useState<ImportSteps>(ImportSteps.chooseExcelFile);
    const [metadataList, setMetadataList] = useState<MetadataSelect[]>([]);
    const [selectedList, setSelectedList] = useState<MetadataSelect[]>([]);

    useOnMount(()=>{
        if (currentArchive){
        try {
            archivesApi.getSecondaryPropertiesList(currentArchive.id, ExcelType.ACPV)
            .then((response) => {
              setMetadataList(response);
            });

        }
        catch (er: any) { //eslint-disable-line @typescript-eslint/no-explicit-any
            const error: ErrorsType = er as ErrorsType;
            console.log("Error: ", error);
        }
    }
        });

    const { execute: importMetadataFileExcel, loading, error } = useAsyncApi<void, void>({
        func: async () => {
            if (!currentArchive)
                return;
            try {
                const formDataRequest = new FormData();
                const metadataSelectedList= selectedList.map((item)=>item.metadata).join(',');
                formDataRequest.append("ArchiveReportId", docLabState.archiveContent.selectedReportIdExportShown.toString() as string);
                formDataRequest.append("File", importFile as File);
                formDataRequest.append("MetadataSelected",metadataSelectedList);

                await archivesApi.importMetadataFromExcel(currentArchive.id, formDataRequest);
            }
            catch (er: any) { //eslint-disable-line @typescript-eslint/no-explicit-any
                const error: ErrorsType = er as ErrorsType;
                console.log("Error: ", error);
            }
        }
    });

    const onFileUpload = (files: IFileToUpload[]) => {
        if(files[0] !== undefined && files[0] !== null) {
            if(files[0].fileType === FileFormatExtension.find(a => a.extension === ".xlsx")?.key)
                setImportFile(files[0].file);
            else
                setImportFile(undefined);
        }
        else
            setImportFile(undefined);
    }

    const bodyChooseFile = () => {
        return (
            <div style={{height: '100%'}}>
                <FileUploader
                    multiUpload={false}
                    multiple={false}
                    showMessages={true}
                    showMessageError={false}
                    isFileDraggable={true}
                    fileTypes={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                    extensionFileTypes={{".xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}}
                    showFileNameOnly={false}
                    currentFolderId={0}
                    maxNumFilesUploadable={1}
                    archiveId={currentArchive?.id ?? 0}
                    onFileUpdated={onFileUpload}
                    blockUpload={true}
                />
            </div>
        )
    }

    const bodyConfirmUpload = () => {
        return(            
            <div style={{height: '100%'}}>
                    <TeamsImage
                        imageName={ImageName.EmptyFavourites}
                        className={classNames.sandglassImage}
                        fullContainer
                        scale={0.6}
                    />
            </div>
        )
    }

    const bodyImportCompleted = () => {
        return (
            <div className={classNames.secondFooterContainer}>
                {loading ?
                    <div className={classNames.centerContentContainer}>
                        <Spinner size={SpinnerSize.large} />
                    </div> : error ?
                        renderError() :
                    <div className={classNames.centerContentContainer}>
                        <TeamsImage
                            imageName={ImageName.Sandglass}
                            className={classNames.sandglassImage}
                            fullContainer
                            scale={0.7}
                        />
                        <div className={classNames.importingFooter}>
                            {t('operationInProgress')}
                            <PrimaryButton
                                text={t('common:leave')}
                                allowDisabledFocus
                                onClick={() => dispatch(showImportACPVModal({show: false, reportId: 0}))}
                                styles={{ root: { padding: '0px 80px', marginTop: '20px' } }}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }

    const footer = () => {
        return (
            <div className={classNames.buttonContainer}>
                {currentStep === ImportSteps.chooseExcelFile || currentStep === ImportSteps.selectMetadataToUpdate ?
                <DefaultButton
                    style={{marginRight: '10px'}}
                    onClick={() => {
                        if(currentStep === ImportSteps.selectMetadataToUpdate)
                            setCurrentStep(ImportSteps.confirmupload);
                        else
                            dispatch(showImportACPVModal({show: false, reportId: 0}));
                    }}
                    text={t('common:previousButton')}
                />
                :
                <DefaultButton
                    style={{marginRight: '10px'}}
                    onClick={() => {                    
                        importMetadataFileExcel();
                        setCurrentStep(ImportSteps.completedImport);
                    }}
                    text={t('newImport')}
                />
                }
                <PrimaryButton
                    onClick={() => {                        
                        switch(currentStep){
                            case ImportSteps.chooseExcelFile: {
                                if (metadataList.length>0) 
                                    setCurrentStep(ImportSteps.confirmupload);
                                else{
                                    setCurrentStep(ImportSteps.completedImport);
                                    importMetadataFileExcel();
                                }
                                break;
                            }
                            case ImportSteps.confirmupload: {
                                setCurrentStep(ImportSteps.selectMetadataToUpdate);
                                break;
                            }
                            case ImportSteps.selectMetadataToUpdate: {
                                setCurrentStep(ImportSteps.completedImport);
                                importMetadataFileExcel();
                                break;
                            }
                            case ImportSteps.completedImport:
                                dispatch(showImportACPVModal({show: false, reportId: 0}));
                                break;

                            default:
                                dispatch(showImportACPVModal({show: false, reportId: 0}));
                                break;
                        }
                    }}
                    text={t('common:nextButton')}
                    disabled={importFile === undefined}
                />
            </div>
        )
    }
    const stepColumn: IColumn[] = [
        {
            key: 'title',
            name: t('fieldTitle'),
            ariaLabel: 'title',
            fieldName: 'title',
            minWidth: 130,
            maxWidth: 250,
            styles: classNames.subComponentStyles.columnHeader,
            isResizable: false,
            onRender: function getMetadata(item: MetadataSelect) {
                return <span>
                    <TooltipHost
                        overflowMode={TooltipOverflowMode.Parent}
                        content={item.metadata}>{item.title}
                    </TooltipHost>
                </span>
            }
        },
        {
            key: 'metadata',
            name: t('fieldName'),
            ariaLabel: 'metadata',
            fieldName: 'metadata',
            minWidth: 130,
            maxWidth: 250,
            styles: classNames.subComponentStyles.columnHeader,
            isResizable: false,
            onRender: function getMetadata(item: MetadataSelect) {
                return <span>
                    <TooltipHost
                        overflowMode={TooltipOverflowMode.Parent}
                        content={item.metadata}>{item.metadata}
                    </TooltipHost>
                </span>
            }
        }
    ];
    const getSelectedItems = useMemo((): ISelection => {
        const selection: Selection = new Selection({
            onSelectionChanged: () => {
                const selectionDetails = selection.getSelection();
                const res: MetadataSelect[] = [];
                selectionDetails.forEach(i => {
                    const item = i as MetadataSelect;
                    res.push(item)
                })
                setSelectedList(res);
            }
        });
        const items = [...selectedList];
    
        selection.setItems(items as IObjectWithKey[]);
        items.forEach((selectedItem,ind) => { 
             selection.setKeySelected(ind.toString(), true, false);
        });
        return selection;
    },[dispatch, currentStep])  //eslint-disable-line react-hooks/exhaustive-deps

    const metadataWorkContent = () => {        
        return <div>
            <DetailsList
                items={metadataList}
                getKey={(key: MetadataSelect) => key.metadata}
                columns={stepColumn}
                styles={classNames.subComponentStyles.detailsList}
                isHeaderVisible={true}
                onRenderDetailsHeader={props => props ? <DetailsHeader styles={classNames.subComponentStyles.detailsHeader} {...props} /> : null}
                setKey="none"
                selection={getSelectedItems}
                selectionMode={SelectionMode.multiple}
                onShouldVirtualize={() => false}
                onRenderRow={props => props ? <DetailsRow {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
            />
        </div>
    }

    const renderError = () => {
        return <div className={classNames.centerContentContainer}>
            <TeamsImage
                imageName={ImageName.Error1}
                className={classNames.sandglassImage}
                fullContainer
                scale={0.7}
            />
            <div className={classNames.importingFooter}>
                {t('operationFailed')}
                <div className={classNames.buttonContainer} style={{marginTop: '20px'}}>
                    <DefaultButton
                        style={{marginRight: '10px'}}
                        text={t('common:close')}
                        allowDisabledFocus
                        onClick={() => dispatch(showImportACPVModal({show: false, reportId: 0}))}
                    />
                    <PrimaryButton
                        text={t('common:retry')}
                        allowDisabledFocus
                        onClick={() => importMetadataFileExcel()}
                    />
                </div>
            </div>
        </div>
    }
    
    const steps: IModalStepData[] = [
        {
            title: t('titleModalACPV'),
            body: bodyChooseFile(),
            footer: footer()
        },
        {
            title: t('confirmUpload'),
            subtitle: t('subConfirmUpload'),
            body: bodyConfirmUpload(),
            footer: footer()
        },        
        {
            title: t('titleMetadataWork'),
            body: metadataWorkContent(),
            footer: footer()
        },
        {
            bodyOnly: true,
            body: bodyImportCompleted()
        }
    ]

    return (
        <>
            <MultiStepModal
                isOpen
                showCloseIcon
                width={715}
                height={600}
                hideScrollbarBody
                onCloseClick={currentStep === ImportSteps.completedImport ? () => dispatch(showImportACPVModal({show: false, reportId: 0})) : () => setShowDialog(true)}
                activeStep={currentStep}
                steps={steps}
                animateInitialStep
            />
            <Dialog
                hidden={!showDialog}
                dialogContentProps={{ title: `${t('stopCreating')}` }}
            >
                <DialogFooter>
                    <DefaultButton
                        style={{marginRight: '10px'}}
                        onClick={() => setShowDialog(false)}
                        text={t('common:dialogButtonCancel')}
                    />
                    <PrimaryButton
                        onClick={() => dispatch(showImportACPVModal({show: false, reportId: 0}))}
                        text={t('common:dialogButtonConfirm')}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
}