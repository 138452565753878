import { IStyleFunction } from "@fluentui/react"
import { ICreateTopicModalPropsStyles, ICreateTopicModalStyles } from "./createTopicModal.types"

export const CreateTopicModalGlobalClassNames = {
    root: 'edi-profile-view-root'
}

export const getStyles: IStyleFunction<ICreateTopicModalPropsStyles, ICreateTopicModalStyles> = (props: ICreateTopicModalPropsStyles): ICreateTopicModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [ CreateTopicModalGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px',
                selectors: {
                    ' .edi-profile-view-action': {
                        right: '15px',
                        bottom: '15px',
                    }
                }
            }            
        ],
        footerButtons:
        {
            'margin-left': '10px'
        },
        metadataContainer:
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start'
        },
        keyPhraseContainer:{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'end'
        },
        errorMessage: {
            color: palette.red
        }
    }
}