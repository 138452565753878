import React from "react";
import { Stack, TooltipDelay, TooltipHost, Label, classNamesFunction, IPersonaSharedProps, Persona } from "@fluentui/react";
import { IMemberListProps, IMemberListPropsStyles, IMemberListStyles } from "./memberList.types";
import { PersonaDetails } from "../../../../models/user";

const getClassNames = classNamesFunction<IMemberListPropsStyles, IMemberListStyles>();

export const MemberListBase = (props: IMemberListProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    const membersList = props.members.slice(0, props.sliceLength ?? 5);
    const remainingMembersList = props.members.slice(props.sliceLength ?? 5);

    const remainingMembersTooltip = (): JSX.Element => {
        return (
            <Stack horizontalAlign={'start'} tokens={{ childrenGap: 2 }}>
                {remainingMembersList.map(x => <Label key={x.firstName + x.lastName} className={classNames.remainingIconTagContainer}>{`${x.firstName} ${x.lastName}`}</Label>)}
            </Stack>);
    }

    const mapUserToPersona = (user: PersonaDetails): IPersonaSharedProps | undefined => {
        return {
            text: `${user.firstName} ${user.lastName}`,
            secondaryText: user.email,
            imageInitials: `${user.firstName[0].toUpperCase() || ""}${user.lastName[0].toUpperCase() || ""}`
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {membersList.map(x => {
                    return <div key={x.firstName + x.lastName} style={{ marginRight: '5px', width: '30px', height: '30px' }}>
                        <Persona
                            {...mapUserToPersona(x)}
                            hidePersonaDetails
                            onRenderInitials={(props) => <TooltipHost content={props?.text}><div style={{ cursor: 'pointer' }}>{props?.imageInitials}</div></TooltipHost>}
                            coinProps={{
                                styles: {
                                    initials: {
                                        borderRadius: props.memberRounded ? '50%' : 0
                                    }
                                }
                            }}
                            coinSize={30}
                        />
                    </div>
                })}
                {remainingMembersList.length > 0 ?
                    <div>
                        <TooltipHost
                            id={'remainingMemberTooltips'}
                            content={remainingMembersTooltip()}
                            delay={TooltipDelay.zero}>
                            <Label
                                styles={classNames.subComponentStyles.templatesCountCircle}>+{remainingMembersList.length}
                            </Label>
                        </TooltipHost>
                    </div>
                    : ''}
            </div>
        </div>
    );
}