import React, { useState } from "react";
import { DetailsList, DetailsRow, IBasePickerSuggestionsProps, IColumn, IPersonaProps, IconButton, NormalPeoplePicker, Persona, PersonaSize, PrimaryButton, SelectionMode, classNamesFunction } from "@fluentui/react";
import { IAddUsersToTopicModalProps, IAddUsersToTopicModalPropsStyles, IAddUsersToTopicModalStyles } from "./addUsersToTopicModal.types";
import { IExtPersonaProps } from "../../../../../../common/components/modalPages/addMembersPage";
import { useTranslation } from "react-i18next";
import { IUser } from "../../../../../../common/interfaces/IUser";
import { mapper } from "../../../../../../utilities/mapper";
import { peoplePickerStyles } from "./addUsersToTopicModal.styles";

const getClassNames = classNamesFunction<IAddUsersToTopicModalPropsStyles, IAddUsersToTopicModalStyles>();

export const AddUsersToTopicModalBase = (props: IAddUsersToTopicModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [dataUser, setDataUser] = useState<IUser[]>([]);
    const [pickerBuffer, setPickerbuffer] = useState<IExtPersonaProps[]>([]);
    const [showMinLengthAlert, setShowMinLengthAlert] = useState(true);
    const { t } = useTranslation(['topicOfInterest', 'common']);

    const mapUserToPersona = (user: IUser): IExtPersonaProps => {
        return {
            ...mapper.mapUserToPersona(user),
            userData: user
        }
    }

    const mapPersonaToUser = (persona: IPersonaProps): IUser => {
        const converted: IExtPersonaProps | undefined = persona as IExtPersonaProps;
        return {
            ...mapper.mapPersonaToUser(converted)
        }
    }

    const searchForAvailableUsers = async (filter: string, selectedItems?: IPersonaProps[]) => {
        const selectedUsers = selectedItems ? selectedItems.map(x => mapPersonaToUser(x)) : [];
        const selectedUsersIds = selectedUsers.map(s => s.id);
        const addedUserIds = dataUser.map(s => s.id);
        const totalUserToExclude = selectedUsersIds.concat(addedUserIds);

        let filteredUser: IUser[] = [];
        await props.getAvailableUsers(filter, totalUserToExclude).then(response => {
            const pickerBufferSelectedUserIds = pickerBuffer.map(user => user.userData.id);
            filteredUser = response.filter(user => !pickerBufferSelectedUserIds.includes(user.id));
        });

        if (filter.length > 2 && filteredUser.length === 0) {
            setShowMinLengthAlert(false);
        }
        else {
            setShowMinLengthAlert(true);
        }

        return filteredUser.map(user => mapUserToPersona(user));
    }

    const movePickerBufferToDataUser = () => {
        const updateDataUser = dataUser.concat(pickerBuffer.map(user => user.userData));
        setDataUser(updateDataUser);
        props.setUserIdsToAdd(updateDataUser.map(d => d.id));        
        setPickerbuffer([]);
    }

    const addPersonaToPickerBuffer = (people?: IPersonaProps[]) => {
        const converted: IExtPersonaProps[] | undefined = people?.map(p => p as IExtPersonaProps);
        setPickerbuffer(converted ?? []);
    }

    const removeUser = (persona: IExtPersonaProps) => {
        const newState = dataUser.filter(p => p.id !== persona.id);
        setDataUser(newState);
        props.setUserIdsToAdd(newState.map(n => n.id));
    }

    const renderUserPersona = (user: IUser) => {
        return <Persona
            {...mapUserToPersona(user)}
            size={PersonaSize.size40}
            coinSize={32}
        />
    }

    const renderUserRemoveButton = (persona: IExtPersonaProps) => {
        return <IconButton
            iconProps={{ iconName: "Cancel" }}
            onClick={() => removeUser(persona)}
        />
    }

    const columns: IColumn[] = [
        {
            key: 'persona',
            name: '',
            minWidth: 100,
            onRender: renderUserPersona
        },
        {
            key: 'remove',
            name: '',
            minWidth: 25,
            onRender: renderUserRemoveButton
        }
    ]

    const suggestionOptions: IBasePickerSuggestionsProps = {
        showRemoveButtons: false,
        suggestionsClassName: classNames.peoplePickerSuggestions,
        suggestionsItemClassName: classNames.peoplePickerSuggestionItem,
        loadingText: t('common:loading'),
        noResultsFoundText: showMinLengthAlert ? t('minLengthAlert') : t('noResults')
    }

    return (
        <>
            <div className={classNames.disclaimerZone}>
                <span>{t('description1AddUsers')}</span>
                <span>{t('description2AddUsers')}</span>
            </div>
            <div className={classNames.peoplePickerZone}>
                <NormalPeoplePicker
                    styles={peoplePickerStyles}
                    resolveDelay={500}
                    onResolveSuggestions={searchForAvailableUsers}
                    pickerSuggestionsProps={suggestionOptions}
                    selectedItems={pickerBuffer}
                    onChange={addPersonaToPickerBuffer}
                />
                <PrimaryButton
                    text={t("common:add")}
                    onClick={movePickerBufferToDataUser}
                    disabled={pickerBuffer.length === 0}
                />
            </div>
            <div>
                <DetailsList
                    items={dataUser}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    isHeaderVisible={false}
                    onRenderRow={props => props ?
                        <DetailsRow {...props} className={classNames.detailsListRow}/> : null
                    }
                />
            </div>
        </>
    )
}