import { styled } from "@fluentui/react";
import { getStyles } from "./worldMapChart.styles";
import { IWorldMapChartPropsStyles, IWorldMapChartStyles, IWorldMapChartProps } from "./worldMapChart.types";
import { WorldMapChartBase } from "./worldMapChart.base";

const WorldMapChart = styled<IWorldMapChartProps, IWorldMapChartPropsStyles, IWorldMapChartStyles>(
    WorldMapChartBase,
    getStyles,
    undefined,
    { scope: 'WorldMapChart' }
);

export default WorldMapChart;