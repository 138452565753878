import { apiClient } from "../../../modules/apiClient/apiClient";
import { svcPaths } from "../../../modules/apiClient/config";
import { TemplateActivity } from "../../models/templateActivity";
import { CreateTemplateRequest } from "./template.contracts";

class TemplateApiClass {
    async getTemplateList(portfolioId: number) {
        const response = await apiClient.get<TemplateActivity[]>(`${svcPaths.activities}/templateactivities?portfolioId=${portfolioId}`);
        return response.data;
    }

    async getTemplateActivityById(templateActivityId: number) {
        const response = await apiClient.get<TemplateActivity>(`${svcPaths.activities}/templateactivities/${templateActivityId}`);
        return response.data;           
    }

    async updateTemplateActivity(request: CreateTemplateRequest, activityId: number) {
        const payload = { 
            id: activityId,
            name: request.name,
            description: request.description, 
            templatePhases: request.templatePhases   
        };
        const response = await apiClient.put(`${svcPaths.activities}/templateactivities/${activityId}`, payload);
        return response.data;           
    }

    async getTemplateListForUser(portfolioId: number) {
        const response = await apiClient.get<TemplateActivity[]>(`${svcPaths.activities}/templateactivities/user-template?portfolioId=${portfolioId}`);
        return response.data;
    }
    
    async isTemplateNameAvailable(portfolioId: number, name: string) {
        const payload = { portfolioId:portfolioId, name: name };
        const response = await apiClient.post<boolean>(`${svcPaths.activities}/templateactivities/check-name`, payload);
        return response.data;
    }

    async createTemplate(request: CreateTemplateRequest) {
        const response = await apiClient.post(`${svcPaths.activities}/templateactivities`, request);
        return response.data;
    }

    async enableTemplate(idTemplate: number){
        const request = {id: idTemplate};
        const response = await apiClient.put(`${svcPaths.activities}/templateactivities/${idTemplate}/status`, request);
        return response.data;
    }
}

export const templateApi = new TemplateApiClass();