/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { classNamesFunction, ConstrainMode, PrimaryButton, SelectionMode, ShimmeredDetailsList, IColumn, IconButton, IContextualMenuProps, DirectionalHint, TooltipHost, PersonaSize, Persona } from "@fluentui/react";
import { IDatasetsPropsStyles, IDatasetsStyles, IDatasetsProps } from "./datasets.types";
import { KnowledgeDataset } from "../../models/dataset";
import { useKnowledgeDsDispatch } from "../../knowledgeDsStore";
import { resetDatasetFilters, setFilters, setNoMore, setRefresh, useDatasetList } from "../../features/datasetList";
import { useTranslation } from "react-i18next";
import { useOnMount } from "../../../utilities/hooks";
import { Helpers } from "../../../utilities/helpers";
import { ImageName } from "../../../common/components/teamsImage/teamsImage.types";
import TeamsImage from "../../../common/components/teamsImage/teamsImage";
import { knowledgeDsApi } from "../../services/knowledgeDS.api";

const getClassNames = classNamesFunction<IDatasetsPropsStyles, IDatasetsStyles>();

export const DatasetsBase = (props: IDatasetsProps) => {
    const dispatch = useKnowledgeDsDispatch();

    const { t } = useTranslation(['knowledgeDsDatasets', 'common', 'knowledgeDsToolbar']);

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false);
    const [datasetItems, setDatasetItems] = useState<KnowledgeDataset[]>([])
    const [scrollLoading, setScrollLoading] = useState(false)

    const { filters, noMore, refresh } = useDatasetList()

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, isEmptyList: (datasetItems.length === 0 && !loading) })

    useOnMount(() => {
        registerEvent();
        dispatch(resetDatasetFilters())
    });

    const loadMore = () => {
        if (loading || noMore || scrollLoading)
            return;

        setScrollLoading(true)
        dispatch(setFilters(
            {
                ...filters,
                pageNumber: filters?.pageNumber ?? 0 + 1
            }))
    }

    useEffect(() => {
        if (filters.pageNumber === 0) {
            setLoading(true);
            knowledgeDsApi.getDatasets(filters)
                .then((result) => {
                    setDatasetItems(result);
                }).catch((err) => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false)
                    setScrollLoading(false)
                })
        } else {
            knowledgeDsApi.getDatasets(filters)
                .then((result) => {
                    const actualList = [...datasetItems];
                    const newList = actualList.concat(result)

                    if (result.length < (filters?.pageSize ?? 20))
                        dispatch(setNoMore(true))

                    setDatasetItems(newList);
                }).catch((err) => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false)
                    setScrollLoading(false)
                })
        }
    }, [filters]); //eslint-disable-line react-hooks/exhaustive-deps

    const refreshData = () => {
        dispatch(setFilters({
            ...filters,
            pageNumber: 0
        }));
        dispatch(setRefresh(false));
    }

    useEffect(() => {
        if (refresh) {
            dispatch(setNoMore(false))
            refreshData();
        }

    }, [refresh]); //eslint-disable-line react-hooks/exhaustive-deps

    const registerEvent = useCallback(() => {
        const listElm = document.querySelector('#detailsListZone .ms-DetailsList-contentWrapper');
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50) {
                const button = document.getElementById("click");
                button && button.click();
            }
        });
    }, []);

    const menuProps = (item: KnowledgeDataset): IContextualMenuProps => {
        return {
            items: [
                {
                    key: 'train',
                    text: t('knowledgeDsToolbar:actions.train'),
                    onClick: () => {
                        setLoading(true);
                        knowledgeDsApi.executeTraining(item.id).then((r) => {
                            dispatch(setNoMore(false))
                            refreshData();
                            setLoading(false);
                        }).catch((e) => {
                            setLoading(false);
                        })
                    },
                }
            ],
            directionalHint: DirectionalHint.rightTopEdge
        }
    }
    const getFormattedDate = (dateString: string) => {
        return Helpers.formatToRelativeDate(dateString);
    }

    const sortByColumn = useCallback((_: React.MouseEvent, column: IColumn) => {
        if (loading)
            return;

        dispatch(setFilters({
            ...filters,
            orderBy: column.fieldName,
            isAscending: column.isSortedDescending,
            pageNumber: 0
        }));

    }, [loading, dispatch]); //eslint-disable-line react-hooks/exhaustive-deps


    const columns: IColumn[] = useMemo(() => {
        return [
            {
                key: 'SkillName',
                name: t("columns.skillName"),
                fieldName: 'SkillName',
                minWidth: 150,
                maxWidth: 350,
                isSorted: filters.orderBy === 'SkillName',
                isSortedDescending: !filters.isAscending,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: sortByColumn,
                onRender: function getCell(item: KnowledgeDataset) {
                    return <span>{item.skillName}</span>
                }
            },
            {
                key: 'DatasetName',
                name: t("columns.name"),
                fieldName: 'Name',
                minWidth: 200,
                maxWidth: 350,
                isSorted: filters.orderBy === 'Name',
                isSortedDescending: !filters.isAscending,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: sortByColumn,
                onRender: function getCell(item: KnowledgeDataset) {
                    return <div>
                        <span>{item.name}</span>
                        <IconButton
                            styles={classNames.subComponentStyles.iconButton()}
                            iconProps={{ iconName: 'More' }}
                            menuProps={menuProps(item)}
                        />
                    </div>
                }
            },
            {
                key: 'CreatedOn',
                name: t("columns.createdOn"),
                fieldName: 'CreatedOn',
                minWidth: 130,
                maxWidth: 130,
                isResizable: true,
                isSorted: filters.orderBy === 'CreatedOn',
                isSortedDescending: !filters.isAscending,
                onColumnClick: sortByColumn,
                onRender: (item: KnowledgeDataset) => getFormattedDate(item.createdOn)
            },
            {
                key: 'UpdatedOn',
                name: t("columns.updatedOn"),
                fieldName: 'UpdatedOn',
                minWidth: 130,
                maxWidth: 130,
                isResizable: true,
                isSorted: filters.orderBy === 'UpdatedOn',
                isSortedDescending: !filters.isAscending,
                onColumnClick: sortByColumn,
                onRender: (item: KnowledgeDataset) => item.updatedOn ? getFormattedDate(item.updatedOn) : " - "
            },
            {
                key: 'UserUpdated',
                name: t("columns.updatedBy"),
                fieldName: 'TrainerEmail',
                minWidth: 60,
                maxWidth: 120,
                isSorted: filters.orderBy === 'TrainerEmail',
                isSortedDescending: !filters.isAscending,
                onColumnClick: sortByColumn,
                isResizable: true,
                onRender: function getCell(item: KnowledgeDataset) {
                    if(item.trainerEmail !== null){
                    return <div className={classNames.personaCell}>
                        <TooltipHost
                            content={item.trainerName ? `${item.trainerName} (${item.trainerEmail})` : <div style={{ paddingLeft: 10, paddingRight: 10 }}>  -  </div>}
                        >
                            {item.trainerName ? <Persona text={item.trainerName} size={PersonaSize.size24} hidePersonaDetails={true} /> : " - "}
                        </TooltipHost>

                    </div>;
                    }
                    else{
                        return <div></div>
                    }
                }
            }];
    }, [sortByColumn, filters.isAscending, dispatch, filters.orderBy, t]); //eslint-disable-line react-hooks/exhaustive-deps

    const emptyFolder = useCallback(() => {
        if (filters.pageNumber && filters.pageNumber > 0 && datasetItems.length === 0)
            return null;

        return (
            <div className={classNames.emptyFolder}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyFolder")}
                />
            </div>
        )
    }, [classNames.emptyFolder, t, datasetItems.length, loading, filters.pageNumber, noMore]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>{error ? <>
            <TeamsImage
                imageName={ImageName.Error1}
                scale={0.3}
                fullContainer
                caption={t("common:genericErrorApi")}
            />
        </> :
            <>

                <div id={"detailsListZone"} className={classNames.root}>
                    <ShimmeredDetailsList
                        items={datasetItems}
                        columns={columns}
                        getKey={(item: KnowledgeDataset) => item && `${item.id}`}
                        selectionMode={SelectionMode.none}
                        enableShimmer={filters.pageNumber === 0 && loading}
                        constrainMode={ConstrainMode.unconstrained}
                        styles={classNames.subComponentStyles.shimmeredDetailsList}
                        detailsListStyles={classNames.subComponentStyles.detailsList}
                        onRenderDetailsFooter={emptyFolder}
                    />
                    <div style={{ display: 'none' }}>
                        <PrimaryButton id={noMore ? "noClick" : "click"} onClick={loadMore} />
                    </div>

                </div>

            </>}
        </>
    );
}