import { IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";

export enum UploadStatus {
    Uploading = 0,
    Uploaded = 1,
    Error = 2, 
    ToUpload = 3,
}

export enum UploadErrors {
    FileNull = "E1001",
    LengthOutOfRange = "E1002",
    NameInvalid = "E1003",
    MimeTypeNotAllowed = "E0050",
    InvalidFileSignature = "E0051",
    TooLarge = "E0052"
}

export interface IFileToUpload {
    fileName: string;
    fileType: string;
    fileSize: number;
    fileStatus?: FileStatus;
    fileUploadStatus: UploadStatus; 
    errorMessage?: JSX.Element | JSX.Element[];
    file?: File;
    isValid: boolean; 
    id: string; 
    oneMetadataProperty?: string;
}

export interface IUploadFileModalProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<IUploadFileModalPropsStyles, IUploadFileModalStyles>;
    className?: string;
    onClose?: () => void;
}

export interface IUploadFileModalPropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<IUploadFileModalPropsStyles, IUploadFileModalStyles>;
    className?: string;
}

export interface IUploadFileModalStyles {
    disclaimers: IStyle;
    pickerErrors: IStyle;
    rightContainer: IStyle;
    leftContainer: IStyle;
    dropzoneBorders: IStyle;
    dropzoneContent: IStyle;
    fileList: IStyle;
    wellDoneImage: IStyle;
    body?: IStyle;
    spinner?: IStyle;
    firstFooterContainer?: IStyle;
    advancedFooterContainer?: IStyle;
    advancedFooterContainerBack?: IStyle;
    subfirstFooterContainer?: IStyle;
    progressIndicatorbody?: IStyle;
    progressIndicatoritemName?: IStyle;
    progressIndicatoritemDescription?: IStyle;
    detailsListContainer?: IStyle;
    footer?: IStyle;
    secondFooterContainer?: IStyle;
    errorMessage?: IStyle;
}

export enum FileStatus {
    FileMetadataRequired = 0,
    FileMetadataSaved = 1
}