import { IDetailsColumnStyleProps, IDetailsColumnStyles, IDetailsListStyleProps, IDetailsListStyles, IStyleFunction } from "@fluentui/react"
import { IDocVerifyModalBodyPropsStyles, IDocVerifyModalBodyStyles } from "./docVerifyModalBody.types";

export const DocVerifyLoadingPageGlobalClassNames = {
    root: 'edi-file-docVerifyModalBody-root'
}

export const getStyles: IStyleFunction<IDocVerifyModalBodyPropsStyles, IDocVerifyModalBodyStyles> = (props: IDocVerifyModalBodyPropsStyles): IDocVerifyModalBodyStyles => {
    return {
        root: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignContent: 'center',
            '> .ms-Viewport': {
                overflow: 'hidden',
                height: '100%',
                '> .ms-DetailsList.is-horizontalConstrained': {
                    height: '100%'
                }
            }            
        },
        errorStyle: {
            fontWeight: 600,
            color: 'red',
            marginBottom: 5
        },
        emptyList: {
            position: 'absolute',
            top: '60px',
            width: '150px',
            left: 'calc(50% - 88px)'
        },
        tableTitle: {
            marginBottom: 10,
            marginTop: 15,
            fontWeight: 500,
            alignSelf: 'center'
        },
        cellDocName: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            textOverflow: 'ellipsis',
            '> .edi-file-icon-cell-wrapper':{                
                marginRight: '4px'
            }
        },
        title: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        subComponentStyles:{
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    },
                    headerWrapper: {
                        '& [role="row"]': {
                            padding: 0
                        },
                        flex: '0 0 auto'
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        height: '100%',
                        //flexBasis: 'calc(100vh - 700px)',
                        // overflowY: 'auto',
                        // overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            },
            column: (props: IDetailsColumnStyleProps): IDetailsColumnStyles => {
                return{
                    root:{},
                    gripperBarVerticalStyle: {},
                    cellTooltip: {},
                    cellTitle: {
                        cursor:'pointer'
                    },
                    cellName: {},
                    iconClassName: {},
                    nearIcon: {},
                    accessibleLabel: {},
                    sortIcon: {},
                    filterChevron: {},
                    borderAfterDropping: {},
                    noBorderAfterDropping: {},
                    borderWhileDragging: {},
                    noBorderWhileDragging: {},
                }
            }
        }
    };
}