import React, { useCallback, useEffect, useState } from "react";
import { classNamesFunction, DefaultButton, Icon, PrimaryButton, TextField } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { Helpers } from "../../../../utilities/helpers";
import { Constants } from "../../../models/constants";
import { useAsyncApi } from "../../../../utilities/hooks";
import { IRenamePortfolioData, IRenamePortfolioModalProps, IRenamePortfolioModalPropsStyles, IRenamePortfolioModalStyles } from "./renamePortfolioModal.types";
import { loadPortfolioList, usePortfolioList } from "../../../features/portfolioList";
import { useCurrentPortfolio } from "../../../hooks/useCurrentPortfolio";
import { useActivityLabDispatch } from "../../../activityLabStore";
import { portfolioApi } from "../../../services/portfolio/portfolio.api";
import { ErrorsType } from "../../../models/callsApi";

const getClassNames = classNamesFunction<IRenamePortfolioModalPropsStyles, IRenamePortfolioModalStyles>();

export const RenamePortfolioModalBase = (props: IRenamePortfolioModalProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const { renamePortfolioModalShown } = usePortfolioList();
  const portfolio = useCurrentPortfolio();
  const { t } = useTranslation(['renamePortfolioModal', 'common']);
  const dispatch = useActivityLabDispatch();
  const [validationIcon, setValidationIcon] = useState<string>('Error');
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [portfolioName, setPortfolioName] = useState<string | undefined>(portfolio?.name);
  const [data, setData] = useState<IRenamePortfolioData>({ name: portfolio?.name, isValid: false });

  const validatePortfolioName = useCallback(async (value: string) => {
    if (!value)
      return `${t('common:fieldRequired')}`;

    setValidationIcon('ProgressRingDots');

    try {
      const [isValid] = await Promise.all([portfolioApi.isPortfolioNameAvailable(value), Helpers.delay(500)]);
      return isValid ? "" : t('nameNotAvailable');
    }
    catch {
      return t('common:genericErrorCheckName');
    }
  }, [t]);

  const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
    setData({ ...data, isValid: !error, name: value ?? "" });
    setValidationIcon(error ? 'Error' : 'Completed');
  }, [data]);

  const { execute: renamePortfolio, loading, error: err } = useAsyncApi<void, void>({
    func: async () => {
      if (!data.isValid || !portfolio?.id || !data.name)
        return;
      setErrorMessage("");
      await portfolioApi.renamePortfolio(portfolio.id, data.name)
      await dispatch(loadPortfolioList());
    }
  });

  useEffect(() => {
    if (portfolioName !== portfolio?.name) {
      setPortfolioName(portfolio?.name);
      props.onClose && props.onClose();
    }
  }, [portfolio?.name]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!err) {
      setErrorMessage("");
      return;
    }
    const error: ErrorsType = (err as unknown) as ErrorsType;
    switch (error.code) {
      case 403: setErrorMessage(t("errorCode403")); break;
      case 404: setErrorMessage(t("errorCode404")); break;
      case 409: setErrorMessage(t("errorCode409")); break;
      default: setErrorMessage(t("common:genericErrorApi"));
    }
  }, [err]);  //eslint-disable-line react-hooks/exhaustive-deps

  const body = (
    <div className={classNames.innerContent}>
      <TextField
        label={t('label')}
        maxLength={Constants.MAX_LENGTH_PORTFOLIO_NAME}
        placeholder={t('placeholder')}
        deferredValidationTime={250}
        onGetErrorMessage={validatePortfolioName}
        required
        defaultValue={data.name}
        autoFocus
        autoComplete={"off"}
        styles={classNames.subComponentStyles.textField}
        onNotifyValidationResult={onNameValidationResult}
        onChange={() => setData({ ...data, isValid: false })}
        onRenderSuffix={() => <Icon iconName={validationIcon} />}
      />
    </div>
  );

  const footer = (
    <>
      <div className={classNames.firstFooterContainer}>
        <DefaultButton
          style={{ margin: '0px 4px' }}
          text={t("cancel")}
          onClick={props.onClose}
          disabled={loading}
        />
        <PrimaryButton
          style={{ margin: '0px 4px' }}
          text={t("save")}
          onClick={() => renamePortfolio()}
          disabled={!data.isValid || loading}
        />
      </div>
      {err && <span className={classNames.errorMessage}>{errorMessage}</span>}
    </>
  );

  return (
    <EdiModal
      isOpen={renamePortfolioModalShown}
      showCloseIcon={true}
      onCloseClick={props.onClose}
      title={t("title")}
      body={body}
      footer={footer}
      width={400}
    />
  );
}