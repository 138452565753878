import { IStyleFunction } from "@fluentui/react"
import { IGraphWorkflowStatusPropsStyles, IGraphWorkflowStatusStyles } from "./graphWorkflowStatus.types"

export const GraphWorkflowStatusGlobalClassNames = {
    root: 'edi-graph-workflow-status-root',
    emptyGraph: 'edi-graph-workflow-status-stack-empty-graph',
    stackLeft: 'edi-graph-workflow-status-stack-left',
    stackRight: 'edi-graph-workflow-status-stack-right',
    doughnutTotal: 'edi-graph-workflow-status-doughnut-total',
    legendContainer: 'edi-graph-workflow-status-legend-container',
    legendItem: 'edi-graph-workflow-status-legend-item',
    legendCheck: 'edi-graph-workflow-status-legend-check',
    filterContainer: 'edi-graph-workflow-status-filter-container',
}

export const getStyles: IStyleFunction<IGraphWorkflowStatusPropsStyles, IGraphWorkflowStatusStyles> = (props: IGraphWorkflowStatusPropsStyles): IGraphWorkflowStatusStyles => {
    return {
        root: [
            GraphWorkflowStatusGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        emptyGraph: [
            GraphWorkflowStatusGlobalClassNames.emptyGraph,
            {
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold'
            }
        ],
        stackLeft: [
            GraphWorkflowStatusGlobalClassNames.stackLeft,
            {
                width: '25%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }
        ],
        stackRight: [
            GraphWorkflowStatusGlobalClassNames.stackRight,
            {
                width: '25%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }
        ],
        doughnutTotal: [
            GraphWorkflowStatusGlobalClassNames.doughnutTotal,
            {
                position: 'absolute',
                userSelect: 'none',
                top: '51%',
                fontSize: '18px',
                fontWeight: 'bold',
                left: '49%'
            }
        ],
        legendContainer: [
            GraphWorkflowStatusGlobalClassNames.legendContainer,
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                flexWrap: 'wrap',
                '@media(max-width: 1410px)': {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    alignContent: 'center',
                    rowGap: 10
                }

            }
        ],
        legendItem: [
            GraphWorkflowStatusGlobalClassNames.legendItem,
            {
                display: 'flex',
                alignItems: 'center',
                columnGap: '7px',
                marginLeft: 10,             
                fontSize: 13,   
                '@media(max-width: 1410px)': { 
                    margin: 0
                }
            }
        ],
        legendCheck: [
            GraphWorkflowStatusGlobalClassNames.legendCheck,
            {
                height: 12,
                maxHeight: 12,
                width: 40,
                maxWidth: 40,
            }
        ],
        filterContainer: [
            GraphWorkflowStatusGlobalClassNames.filterContainer,
            {
                position: 'absolute',
                top: -37,
                right: '10%',
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                minWidth: 175
            }
        ],
    };
}