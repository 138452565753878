import React from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { WorkFlowRelativeStatus } from "../../../models/workflow";
import { IGraphWorkflowUsersProps } from "./graphWorkflowUsers.types";

export const GraphWorkflowUsersBase = (props: IGraphWorkflowUsersProps) => {
    const { palette, semanticColors } = props.theme!;//eslint-disable-line @typescript-eslint/no-non-null-assertion    
    const { workflowUsers } = props;
    const { t } = useTranslation('workflow');

    const workflowUsersBarOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                title: {
                    color: semanticColors.bodyText
                },
                labels: {
                    color: semanticColors.bodyText
                }
            },
            tooltip: {
                callbacks: {
                    title: (context: any) => { //eslint-disable-line @typescript-eslint/no-explicit-any    
                        const titleArray = context[0].label.split(' ');
                        titleArray.pop();
                        return titleArray.join(' ');
                    },
                    afterLabel: (context: any) => { //eslint-disable-line @typescript-eslint/no-explicit-any                    
                        if (!workflowUsers) return;
                        const datasetIndex: number = context.datasetIndex;
                        const userWF = workflowUsers[context.dataIndex];
                        let count = 0;
                        if (datasetIndex === 0)
                            count = userWF.assignedToMe;
                        if (datasetIndex === 1)
                            count = userWF.approved;
                        if (datasetIndex === 2)
                            count = userWF.refused;
                        if (datasetIndex === 3)
                            count = userWF.ongoing;
                        return `${(count / (userWF.assignedToMe + userWF.approved + userWF.refused + userWF.ongoing) * 100).toFixed(2)}%`;
                    }
                }
            }
        },
        scales: {
            y: {
                max: workflowUsers
                    && (Math.max(...workflowUsers.map(w => w.assignedToMe),
                        ...workflowUsers.map(w => w.approved),
                        ...workflowUsers.map(w => w.refused),
                        ...workflowUsers.map(w => w.ongoing))
                    ),
                ticks: {
                    beginAtZero: true,
                    color: semanticColors.bodyText
                },
                grid: {
                    color: palette.neutralTertiaryAlt
                }
            },
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    color: semanticColors.bodyText
                },
            }
        }
    };
    const workflowUsersData = {
        labels: workflowUsers.map(w => `${w.user?.firstName} ${w.user?.lastName} (${w.assignedToMe + w.approved + w.refused + w.ongoing})`),
        datasets: [
            {
                label: t(`status.${WorkFlowRelativeStatus.ToMe}`),
                data: workflowUsers.map(w => w.assignedToMe),
                backgroundColor: palette.themePrimary,
            },
            {
                label: t(`status.${WorkFlowRelativeStatus.Approved}`),
                data: workflowUsers.map(w => w.approved),
                backgroundColor: palette.green,
            },
            {
                label: t(`status.${WorkFlowRelativeStatus.Refused}`),
                data: workflowUsers.map(w => w.refused),
                backgroundColor: palette.redDark,
            },
            {
                label: t(`status.${WorkFlowRelativeStatus.Ongoing}`),
                data: workflowUsers.map(w => w.ongoing),
                backgroundColor: palette.yellow,
            },
        ],
    };

    return <Bar data={workflowUsersData} options={workflowUsersBarOptions} />;
}