import { IStyleFunction } from "@fluentui/react"
import { IPhasePropsStyles, IPhaseStyles } from "./phase.types"

export const PhaseGlobalClassNames = {
    cardContainer: 'edi-phase-cardContainer',
    nameContainer: 'edi-phase-nameContainer',
    descriptionContainer: 'edi-phase-descriptionContainer'
}

export const getStyles: IStyleFunction<IPhasePropsStyles, IPhaseStyles> = (props: IPhasePropsStyles): IPhaseStyles => {
    const { palette } = props.theme!;  //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        cardContainer: [
            PhaseGlobalClassNames.cardContainer,
            {
                display:'flex',
                flexFlow:'column',
                rowGap:'15px',
                width:'100%',
                padding: '5px 0'
            }
        ],
        nameContainer: [
            PhaseGlobalClassNames.nameContainer,
            {
                background: palette.themePrimary,
                padding: 5,
                borderRadius: 5,
                color: palette.white,
                whiteSpace:'normal',
                wordBreak: 'break-word'
            }
        ],
        descriptionContainer: [
            PhaseGlobalClassNames.descriptionContainer,
            {
                background: palette.themeSecondary,
                padding: 5,
                borderRadius: 5,
                color: palette.white,
                fontSize: 12,
                whiteSpace:'normal',
                wordBreak: 'break-word',
            }
        ]
    };
}