import { styled } from "@fluentui/react";
import { getStyles } from "./subParagraphModal.styles";
import { ISubParagraphModalPropsStyles, ISubParagraphModalStyles, ISubParagraphModalProps } from "./subParagraphModal.types";
import { SubParagraphModalBase } from "./subParagraphModal.base";

const SubParagraphModal = styled<ISubParagraphModalProps, ISubParagraphModalPropsStyles, ISubParagraphModalStyles>(
    SubParagraphModalBase,
    getStyles,
    undefined,
    { scope: 'SubParagraphModal' }
);

export default SubParagraphModal;