import { IStyleFunction } from "@fluentui/react"
import { IFeedbackNerPanelPropsStyles, IFeedbackNerPanelStyles } from "./feedbackNerPanel.types";

export const FeedbackNerPanelClassNames = {    
    personaCell: 'edi-feedbackNerPanel-persona',
}
export const getStyles: IStyleFunction<IFeedbackNerPanelPropsStyles, IFeedbackNerPanelStyles> = (props: IFeedbackNerPanelPropsStyles): IFeedbackNerPanelStyles => {

    return {
        root: [
            {
                // CSS PROPS AND SELECTORS
                width: "690px"
            }
        ],
        pivot: [
            {
                width: '100%',
                height: '100%'
                // CSS PROPS AND SELECTORS
            }
        ],
        loadingErrorContainer: {
            height: 'calc(100%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        content: {
            border: '1px solid gray',
            borderRadius: '1%',
            height: '100%',
            margin: '10px',
            padding: '10px'
        },
        personaCell: [
            FeedbackNerPanelClassNames.personaCell,
            {
                ".ms-Persona": {
                    justifyContent: "center!important"
                    
                }
            }
        ]
    };
}