import React from "react";
import { classNamesFunction, IconButton, IIconProps, Modal, ResponsiveMode, Stack } from "@fluentui/react";
import { ISubParagraphModalPropsStyles, ISubParagraphModalStyles, ISubParagraphModalProps } from "./subParagraphModal.types";
import CopyActionCommand from "../copyActionButton/copyActionCommand";
import { useTranslation } from "react-i18next";

const getClassNames = classNamesFunction<ISubParagraphModalPropsStyles, ISubParagraphModalStyles>();

const initialIconProps: IIconProps = {
    iconName: 'Cancel'
}

export const SubParagraphModalBase = (props: ISubParagraphModalProps) => {

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, width: '40%', height: '60%' });

    const { t } = useTranslation('subParagraphModal');
    const modalTitle = t('title');

    const modalBody =
        <>
            <Stack style={{ margin: '0 10px', padding: '20px' }}>

                <Stack.Item style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <span style={{ color: '#868EBE' }}><strong>{props.title}</strong></span>
                </Stack.Item>

                <Stack.Item>
                    <div className={classNames.copyButtonDiv}>
                        <CopyActionCommand
                            content={props.content}
                            textPlaceHolder={t('subParagraph')}
                        />
                    </div>
                </Stack.Item>

                <Stack.Item style={{ overflowY: 'scroll' }}>
                    <p>{props.content}</p>
                </Stack.Item>
            </Stack>
        </>

    return (
        <Modal
            isOpen={props.isOpen}
            className={props.className}
            containerClassName={classNames.container}
            responsiveMode={ResponsiveMode.large}
            scrollableContentClassName={classNames.content}
            onDismissed={props.onCloseClick}
            isBlocking={true}
        >
            <div className={classNames.upperContent}>
                <div>
                    <div className={classNames.header}>
                        <div className={classNames.title}>{modalTitle}</div>
                        <div className={classNames.closeIcon}>
                            <IconButton
                                styles={classNames.subComponentStyles.iconButton()}
                                iconProps={{ ...initialIconProps }}
                                onClick={props.onCloseClick}
                            />
                        </div>
                    </div>
                </div>
                <div className={classNames.body}>
                    <div className={classNames.bodyContent}>
                        {modalBody}
                    </div>
                </div>
            </div>
            <div className={classNames.footer}></div>
        </Modal>
    );
}