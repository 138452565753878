import { styled } from "@fluentui/react";
import { ExportReportSettingsBase } from "./exportReportSettings.base";
import { getStyles } from "./exportReportSettings.styles";
import { IExportReportSettingsBaseProps, IExportReportSettingsBasePropsStyles, IExportReportSettingsBaseStyles } from "./exportReportSettings.types";

const ExportReportSettings = styled<IExportReportSettingsBaseProps, IExportReportSettingsBasePropsStyles, IExportReportSettingsBaseStyles>(
    ExportReportSettingsBase,
    getStyles,
    undefined,
    { scope: 'ExportReportSettings'}
)

export default ExportReportSettings;