/*eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Icon } from "@fluentui/react"
import { ActivityStatus } from "../../../models/constants";

//TODO FARE COMPONENTE
export const getLabel = (name: string, palette: any) => {
    return <div style={{ backgroundColor: palette.themePrimary, color: 'white', padding: '2px' }}>
        <Icon iconName='tag' />
        <span style={{ marginLeft: '5px' }}>{name}</span>
    </div>
}

export function isActivityExpired(status: ActivityStatus, dueDate: Date) {
    const today = new Date();
    today.setHours(0,0,0,0);

    switch (status) {
        case ActivityStatus.OnGoing: return new Date(dueDate) < today;
        case ActivityStatus.Blocked: return new Date(dueDate) < today;
        case ActivityStatus.Closed: return false;
        default: return false;
    }
}

export function isActivityNotStarted(status: ActivityStatus, startDate: Date) {
    const today = new Date();
    today.setHours(0,0,0,0);

    if (status === ActivityStatus.Planned) {
        return new Date(startDate) < today;
    }
    return false;
}