import { IStyleFunction } from "@fluentui/react"
import { IManageTopicViewPropsStyles, IManageTopicViewStyles } from "./manageTopicView.types"


export const ManageTopicViewGlobalClassNames = {
    root: 'edi-profile-view-root'
}

export const getStyles: IStyleFunction<IManageTopicViewPropsStyles, IManageTopicViewStyles> = (props: IManageTopicViewPropsStyles): IManageTopicViewStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [ManageTopicViewGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px',
                selectors: {
                    ' .edi-profile-view-action': {
                        right: '15px',
                        bottom: '15px',
                    }
                }
            }
        ],        
        accordionDetailKey:{
            marginRight: 15, 
            backgroundColor: palette.neutralLight,
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '25px',
            minWidth: 250
        },
        accordionDetailValue:{
            marginRight: 15, 
            backgroundColor: palette.neutralLight,
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            marginLeft: '25px',
            minWidth: 250
        },
        accordionDetailcontainer:{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%'
        }
    }
}