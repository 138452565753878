import { IStyleFunction } from "@fluentui/react"
import { IPaginationPropsStyles, IPaginationStyles } from "./pagination.types"

export const PaginationGlobalClassNames = {
    root: 'edi-pagination-root'
}

export const getStyles: IStyleFunction<IPaginationPropsStyles, IPaginationStyles> = (props: IPaginationPropsStyles): IPaginationStyles => {
    return {
        pagination:{      
        color: 'black',
        float: 'left',
       // padding: '8px 16px',
       padding: '4px 8px', 
       cursor:'pointer',
        textDecoration: 'none',
            ':hover':{
                backgroundColor: 'rgba(37, 36, 35, 0.05)'
            }
        },
        temp:{
            cursor:'pointer',
            marginLeft:'4px',
            marginRight:'4px',

        },
        active:{
            color:  props.theme?.palette.themePrimary,
            borderBottom: '1px solid '+ props.theme?.palette.themePrimary,
            cursor:'pointer',
            marginLeft:'4px',
            marginRight:'4px',
        },
        iconTemp:{
            color:  props.theme?.palette.themePrimary,
            fontSize: '10px',
            cursor:'pointer'
        },
        icon:{
            color:  props.theme?.palette.themePrimary,

        },
        activePagination:{
            float: 'left',
            padding: '4px 8px',
            textDecoration: 'none',
            backgroundColor: props.theme?.palette.themePrimary,
            color: 'white',
            cursor:'pointer',
            ':hover':{
                backgroundColor:  props.theme?.palette.themePrimary,
            }
        },
    
        root: [
            PaginationGlobalClassNames.root,
            {
                display:'flex',
                listStyle:'none',     

            }
        ]
    };
}