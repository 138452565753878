import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useKnowledgeLabSelector } from "../knowledgeLabStore";
import { ElementShort } from "../models/element";
import { Document } from "../services/search/search.contracts";

export type KnowledgeLabContentState = {
  elementsToCompares: ElementShort[];
  forceDocumentReload: boolean;
  isCompareModalOpen: boolean;
  isDeleteItem: boolean;
  isDetailsModalOpen: boolean;
  isSubParagraphModalOpen: boolean;
  refreshFavourites: boolean;
  selectedElement?: Document;
  selectedElementId?: string;
};

const initialState: KnowledgeLabContentState = {
  elementsToCompares: [],
  forceDocumentReload: false,
  isCompareModalOpen: false,
  isDeleteItem: false,
  isDetailsModalOpen: false,
  isSubParagraphModalOpen: false,
  refreshFavourites: false,
  selectedElement: undefined,
  selectedElementId: undefined,
};


const knowledgeLabContentSlice = createSlice({
  name: "knowledgeLab-knowledge-content",
  initialState,
  reducers: {
    addToCompare: (state: KnowledgeLabContentState, action: PayloadAction<ElementShort>) => {
      const check = state.elementsToCompares.filter(e => e.id === action.payload.id)
      if (check.length === 0)
        state.elementsToCompares.push(action.payload);
    },
    clearCompare: (state: KnowledgeLabContentState) => {
      state.elementsToCompares = [];
    },
    removeFromCompare: (state: KnowledgeLabContentState, action: PayloadAction<ElementShort>) => {
      state.elementsToCompares = state.elementsToCompares.filter(i => i.id !== action.payload.id);
    },
    setCompareModalOpen: (state: KnowledgeLabContentState, action: PayloadAction<boolean>) => {
      state.isCompareModalOpen = action.payload;
    },
    setDetailsModalOpen: (state: KnowledgeLabContentState, action: PayloadAction<boolean>) => {
      state.isDetailsModalOpen = action.payload;
    },
    setForceDocumentReload: (state: KnowledgeLabContentState, action: PayloadAction<boolean>) => {
      state.forceDocumentReload = action.payload;
    },
    setIsDeleteItem: (state: KnowledgeLabContentState, action: PayloadAction<boolean>) => {
      state.isDeleteItem = action.payload;
    },
    setRefreshFavourites: (state: KnowledgeLabContentState, action: PayloadAction<boolean>) => {
      state.refreshFavourites = action.payload;
    },
    setSelectedElement: (state: KnowledgeLabContentState, action: PayloadAction<Document | undefined>) => {
      state.selectedElement = action.payload;
    },
    setSelectedElementId: (state: KnowledgeLabContentState, action: PayloadAction<string>) => {
      state.selectedElementId = action.payload;
    },
    setSubParagraphModalOpen: (state: KnowledgeLabContentState, action: PayloadAction<boolean>) => {
      state.isSubParagraphModalOpen = action.payload;
    },
  },
});

export default knowledgeLabContentSlice.reducer;
export const {
  addToCompare,
  clearCompare,
  removeFromCompare,
  setCompareModalOpen,
  setDetailsModalOpen,
  setForceDocumentReload,
  setIsDeleteItem,
  setRefreshFavourites,
  setSelectedElement,
  setSelectedElementId,
  setSubParagraphModalOpen
} = knowledgeLabContentSlice.actions;
export const useKnowledgeLabContent = () => useKnowledgeLabSelector((state) => state.knowledgeLabContent);