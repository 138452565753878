import React from "react";
import { classNamesFunction, Pivot, PivotItem, Stack } from "@fluentui/react";
import { useOnMount } from "../../../../utilities/hooks";
import { useDocLabDispatch } from "../../../docLabStore";
import { setToolbarActions } from "../../../features/toolbarActions";
import { useTranslation } from "react-i18next";
import { IManageTopicsOfInterestProps, IManageTopicsOfInterestPropsStyles, IManageTopicsOfInterestStyles } from "./manageTopicsOfInterest.types";
import ManageTopicView from "./manageTopicsView/manageTopicView";
import ManageAssociationTopicUsers from "./manageAssociationTopicUsers/manageAssociationTopicUsers";

const getClassNames = classNamesFunction<IManageTopicsOfInterestPropsStyles, IManageTopicsOfInterestStyles>();

export const ManageTopicsOfInterestBase = (props: IManageTopicsOfInterestProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const dispatch = useDocLabDispatch();
    const { t } = useTranslation(['topicOfInterest']);

    useOnMount(() => {
        dispatch(setToolbarActions([]));
    })

    return (
        <Stack className={classNames.root}>

            <Stack horizontal horizontalAlign="space-between" verticalAlign={'center'}>
                <Stack.Item className={classNames.title}>
                    <span style={{ paddingLeft: 10 }}>{t('manageTopics')}</span>
                </Stack.Item>
            </Stack>

            <Pivot>
                <PivotItem headerText={t('viewTopics')}>
                    <ManageTopicView/>
                </PivotItem>
                <PivotItem headerText={t('associationTopicUsers')}>
                    <ManageAssociationTopicUsers/>
                </PivotItem>
            </Pivot>

        </Stack> 
    )
}