import React, { useEffect, useState } from "react";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { IGraphWorkflowStatusCustomFilterModalProps } from "./graphWorkflowStatusCustomFilterModal.types";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import DatePicker from "../../../../common/components/datePicker/datePicker";
import { useOnMount } from "../../../../utilities/hooks";


export const GraphWorkflowStatusCustomFilterModalBase = (props: IGraphWorkflowStatusCustomFilterModalProps) => {
    const { t } = useTranslation(['workflow', 'common']);
    const [fromDate, setFromDate] = useState<Date | undefined>();
    const [toDate, setToDate] = useState<Date | undefined>();

    const today = new Date(Date.now());
    //Tomorrow
    const [minToDate, setMinToDate] = useState<Date>(new Date(today.setDate(today.getDate() + 1)));


    useOnMount(() => {
        setFromDate(undefined);
        setToDate(undefined);
    });

    useEffect(() => {
        if (fromDate) {
            const temp = new Date(fromDate);
            temp.setDate(fromDate.getDate() + 1);
            setMinToDate(temp);
        }
    }, [fromDate]);

    const footer = <React.Fragment>
        <DefaultButton
            text={t("common:cancel")}
            style={{ marginRight: "2px" }}
            onClick={() => props.onDismiss()} />
        <PrimaryButton
            text={t("common:continue")}
            onClick={() => props.onFilter(fromDate, toDate)}
            disabled={!fromDate && !toDate} />
    </React.Fragment>;

    const body = <React.Fragment>
        <DatePicker
            key={"startDate"}
            dateCallback={(newValue) => {
                if (toDate && newValue > toDate) {
                    setToDate(undefined);
                    setFromDate(newValue);
                }
                else
                    setFromDate(newValue);
            }}
            placeholder={t('filters.selectDay')}
            label={t('filters.startDate')}
        />
        <DatePicker
            key={"endDate"}
            dateCallback={(newValue) => {
                setToDate(newValue);
            }}
            minDate={minToDate}
            placeholder={t('filters.selectDay')}
            label={t('filters.endDate')}
            inputValue={toDate}
        />
    </React.Fragment>;

    return <EdiModal
        title={t("filters.filterBy")}
        width={300}
        height={320}
        isOpen={props.isOpen}
        showCloseIcon={true}
        onCloseClick={() => props.onDismiss()}
        body={body}
        footer={footer}
    />;

    // return <EdiCallout
    //     title={t("filters.filterBy")}
    //     width={300}
    //     height={320}
    //     target={props.target}
    //     targetType={'#'}        
    //     showCloseIcon={true}
    //     onCloseClick={() => props.onDismiss()}
    //     body={body}
    //     footer={footer}
    // />;
}