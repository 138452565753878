import { IStyleFunction } from "@fluentui/react"
import { IEditTopicsOfInterestModalBasePropsStyles, IEditTopicsOfInterestModalBaseStyles } from "./editTopicsOfInterestModal.types";


export const EditTopicsOfInterestModalGlobalClassNames = {
    root: 'edi-profile-view-root',
    title: 'edi-profile-view-title',
    action: 'edi-profile-view-action'
}

export const getStyles: IStyleFunction<IEditTopicsOfInterestModalBasePropsStyles, IEditTopicsOfInterestModalBaseStyles> = (props: IEditTopicsOfInterestModalBasePropsStyles): IEditTopicsOfInterestModalBaseStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            EditTopicsOfInterestModalGlobalClassNames.root,
            {
                background: palette.white,
                width: 'auto',
                height: '150px',
                borderRadius: '4px',
                boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 4px -0.75px',
                padding: '15px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'     
            }
        ],
        title: [
            EditTopicsOfInterestModalGlobalClassNames.title,
            {
                color: palette.black,
                fontWeight: 'bold'
            }
        ],
        action: [
            EditTopicsOfInterestModalGlobalClassNames.action,
            { 
                alignSelf:'flex-end',
                justifySelf:'right',
                fontSize: '12px',
                color: palette.themePrimary,
                cursor: 'pointer'
            }
        ],
        accordionDetailKey:{
            marginRight: 15, 
            backgroundColor: palette.neutralLight,
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '25px',
            minWidth: 250
        },
        accordionDetailValue:{
            marginRight: 15, 
            backgroundColor: palette.neutralLight,
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            marginLeft: '25px',
            minWidth: 250
        },
        accordionDetailContainer:{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
            padding: '0 10px 0 10px'
        },
        subComponentStyles: {
            textField: {
                root: {
                    textOverflow: 'inherit',
                    width: '95%'
                }
            }
        }
    }
}