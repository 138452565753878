import { styled } from "@fluentui/react";
import { getStyles } from "./copyActionCommand.styles";
import { ICopyActionCommandPropsStyles, ICopyActionCommandStyles, ICopyActionCommandProps } from "./copyActionCommand.types";
import { CopyActionCommandBase } from "./copyActionCommand.base";

const CopyActionCommand = styled<ICopyActionCommandProps, ICopyActionCommandPropsStyles, ICopyActionCommandStyles>(
    CopyActionCommandBase,
    getStyles,
    undefined,
    { scope: 'CopyActionCommand' }
);

export default CopyActionCommand;