/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useEffect, useState } from "react";
import { classNamesFunction, DefaultButton, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IDocVerifyModalFooterProps, IDocVerifyModalFooterPropsStyles, IDocVerifyModalFooterStyles } from "./docVerifyModalFooter.types";

const getClassNames = classNamesFunction<IDocVerifyModalFooterPropsStyles, IDocVerifyModalFooterStyles>();

export const DocVerifyModalFooterBase = (props: IDocVerifyModalFooterProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['docVerifyPages']);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (props.loading !== undefined)
            setLoading(props.loading);
    }, [props.loading]);

    return (
        <div className={classNames.advancedFooterContainer}>
            {props.isUserBlocked || props.documentUnivocityError.errorOnFileMetadataList.length > 0 ?
                <div>
                    {loading &&
                        <Spinner style={{ marginTop: '10px' }} size={SpinnerSize.large} />
                    }
                    <PrimaryButton text={t('close')}
                        onClick={props.onClose}
                        disabled={loading}
                    />
                </div>
                :
                <div className={classNames.disableElement}>
                    {loading &&
                        <Spinner size={SpinnerSize.large} />
                    }
                    <DefaultButton text={t('backButton')}
                        allowDisabledFocus
                        disabled={loading}
                        onClick={props.onBack}
                    />
                    <PrimaryButton text={t('nextButton')}
                        onClick={props.onNext}
                        disabled={loading}
                    />
                </div>
            }
        </div>
    )
}