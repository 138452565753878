import { styled } from "@fluentui/react";
import { SelectProfileMemberBase } from "./selectProfileMember.base";
import { getStyles } from "./selectProfileMember.styles";
import { ISelectProfileMemberBaseProps, ISelectProfileMemberBasePropsStyles, ISelectProfileMemberBaseStyles } from "./selectProfileMember.types";

const SelectProfileMember = styled<ISelectProfileMemberBaseProps, ISelectProfileMemberBasePropsStyles, ISelectProfileMemberBaseStyles>(
    SelectProfileMemberBase, 
    getStyles,
    undefined,
    { scope: 'SelectProfileMember'}
)

export default SelectProfileMember;