import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dashboardFilter } from "../components/feedback/feedback.types";
import { useDashboardSelector } from "../dashboardStore";
import { FeedbackStatus, Feedback } from "../models/feedback";
import { DashboardRoleId } from "../models/dashboardRoleId";
import { User } from "../models/user";
import { dashboardApi } from "../services/dashboard.api";

export type FeedbackListState = {
    currentUser: User | undefined;
    currentDashboardRoleId: DashboardRoleId | undefined;
    filters: dashboardFilter;
    selectedItems: Feedback[];
    noMore: boolean;
    refresh: boolean;
}

const emptyFilters = {
    pageNumber: 0,
    pageSize: 100,
    orderBy: "CreatedOn",
    isAscending: false,
    datasetName: undefined,
    fileName: undefined,
    skillId: undefined,
    moduleInstanceId: undefined,
    feedbackStatus: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    datasetId: undefined,
    extraProperty: undefined,
    datasetStatus: undefined
}

const initialState: FeedbackListState = {
    currentDashboardRoleId: undefined,
    filters: { ...emptyFilters, feedbackStatus: FeedbackStatus.Pending },
    selectedItems: [],
    noMore: false,
    refresh: false,
    currentUser: undefined
}

export const loadUserDetails = createAsyncThunk(
    'dashboard/load-user-details',
    async () => await dashboardApi.getUserDetails()
);

const feedbackListSlice = createSlice({
    name: 'dashboard-feedback',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(loadUserDetails.fulfilled, (state, action) => {
            state.currentUser = action.payload;
            state.currentDashboardRoleId = action.payload?.roleId
        });
    },
    reducers: {
        setCurrentDashboardRoleId: (state: FeedbackListState, action: PayloadAction<DashboardRoleId | undefined>) => {
            state.currentDashboardRoleId = action.payload;
        },
        setFilters: (state: FeedbackListState, action: PayloadAction<dashboardFilter>) => {
            state.filters = action.payload;
        },
        resetFeedbackFilters: (state: FeedbackListState) => {
            state.filters = emptyFilters;
            state.noMore = false;
        },
        setBaseFeedbackFilters: (state: FeedbackListState) => {
            state.filters = { ...emptyFilters, feedbackStatus: FeedbackStatus.Pending };
            state.noMore = false;
        },
        setSelectedItems: (state: FeedbackListState, action: PayloadAction<Feedback[]>) => {
            state.selectedItems = action.payload;
        },
        setNoMore: (state: FeedbackListState, action: PayloadAction<boolean>) => {
            state.noMore = action.payload;
        },
        clearSelectedItems: (state: FeedbackListState) => {
            state.selectedItems = [];
        },
        setRefresh: (state: FeedbackListState, action: PayloadAction<boolean>) => {
            state.refresh = action.payload;
        }
    }
})

export const { setCurrentDashboardRoleId, setFilters, resetFeedbackFilters, setBaseFeedbackFilters, setSelectedItems, clearSelectedItems, setNoMore, setRefresh } = feedbackListSlice.actions;
export default feedbackListSlice.reducer;

export const useFeedbackList = () => useDashboardSelector(state => state.feedbackList);