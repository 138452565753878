import { IStyleFunction } from "@fluentui/react"
import { ISingleStepPropsStyles, ISingleStepStyles } from "./singleStep.types"

export const SingleStepGlobalClassNames = {
    root: 'edi-single-step-root',
    firstContainer: 'edi-single-step-firstContainer',
    stepSingleItem: 'edi-single-step-stepSingleItem',
    iconBarContainer: 'edi-single-step-iconBarContainer'
}

export const getStyles: IStyleFunction<ISingleStepPropsStyles, ISingleStepStyles> = (props: ISingleStepPropsStyles): ISingleStepStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            SingleStepGlobalClassNames.root,
            {
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 'rgb(136 136 136) 0px 1px 8px 0px',
                padding: '20px 10px',
                margin: 10
            }
        ],
        firstContainer: [SingleStepGlobalClassNames.firstContainer, {
            display: 'flex',
            marginBottom: 10,
            gap: 20,
            justifyContent: 'space-between'
        }],
        stepSingleItem: [SingleStepGlobalClassNames.stepSingleItem, {
            flexGrow: 1
        }],
        iconBarContainer: [SingleStepGlobalClassNames.iconBarContainer,{
            marginTop: 10,
            display:'flex',
            justifyContent: 'center',
            gap: 10
        }],
        peoplePickerStyles:[
            {             
            flex: '1 1 0',           
            input: {
                backgroundColor: 'transparent',
            },
            text: {
                border: '1px solid rgb(240, 240, 240) !important',
                borderRadius: 2,
                backgroundColor: 'rgb(240, 240, 240)',
            },
            itemsWrapper: {
                ".ms-PickerPersona-container": {
                    background: 'rgb(245, 245, 245)',
                    border: "1px solid rgb(245, 245, 245)",
                    ':hover': {
                        background: 'rgba(98, 100, 167, 0.2)',
                        border: "1px solid rgba(98, 100, 167, 0.2)",
                    }
                },
            },
            root: 
            {
                float: 'right',
                marginRight: '0 !important',
            },
            screenReaderText: {}
            }
        ],
        peoplePickerSuggestions: {
            padding: '8px 0'
        },
        peoplePickerSuggestionItem: {
            '::after': {
                display: 'none'
            },
            ".is-suggested": {
                background: palette.neutralQuaternary
            }
        },
    };
}