import { styled } from "@fluentui/react";
import { getStyles } from "./renameArchiveModal.styles";
import { IRenameArchiveModalPropsStyles, IRenameArchiveModalStyles, IRenameArchiveModalProps } from "./renameArchiveModal.types";
import { RenameArchiveModalBase } from "./renameArchiveModal.base";

const RenameArchiveModal = styled<IRenameArchiveModalProps, IRenameArchiveModalPropsStyles, IRenameArchiveModalStyles>(
    RenameArchiveModalBase,
    getStyles,
    undefined,
    { scope: 'RenameArchiveModal' }
);

export default RenameArchiveModal;