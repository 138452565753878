import { styled } from "@fluentui/react";
import { SecondPageBase } from "./secondPage.base";
import { getStyles } from "./secondPage.styles";
import { ISecondPageProps, ISecondPagePropsStyles, ISecondPageStyles } from "./secondPage.types";

const SecondPage = styled<ISecondPageProps, ISecondPagePropsStyles, ISecondPageStyles>(
    SecondPageBase,
    getStyles,
    undefined,
    { scope: 'SecondPage' }
);

export default SecondPage;