import { styled } from "@fluentui/react";
import { getStyles } from "./knowledgeLabMembersSettings.styles";
import { IKnowledgeLabMembersSettingsPropsStyles, IKnowledgeLabMembersSettingsStyles, IKnowledgeLabMembersSettingsProps } from "./knowledgeLabMembersSettings.types";
import { KnowledgeLabMembersSettingsBase } from "./knowledgeLabMembersSettings.base";

const KnowledgeLabMembersSettings = styled<IKnowledgeLabMembersSettingsProps, IKnowledgeLabMembersSettingsPropsStyles, IKnowledgeLabMembersSettingsStyles>(
    KnowledgeLabMembersSettingsBase,
    getStyles,
    undefined,
    { scope: 'ArchiveMembersSettings' }
);

export default KnowledgeLabMembersSettings;