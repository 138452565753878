import { IStyleFunction } from "@fluentui/react"
import { IDocumentsPropsStyles, IDocumentsStyles } from "./documents.types"

export const DocumentsGlobalClassNames = {
    root: 'edi-documents-root',
    filterPanel: 'edi-documents-filter-panel',
    filterPanelItem: 'edi-documents-filter-panel-item',
    spinner: 'edi-documents-spinner',
    listContent: 'edi-documents-listContent',
    menuIconWrapper: 'edi-documents-menuIconWrapper',
    workflowListNameWrapper: 'edi-documents-workflowListNameWrapper',
}

export const getStyles: IStyleFunction<IDocumentsPropsStyles, IDocumentsStyles> = (props: IDocumentsPropsStyles): IDocumentsStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            DocumentsGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        filterPanel: [
            DocumentsGlobalClassNames.filterPanel,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        filterPanelItem: [
            DocumentsGlobalClassNames.filterPanelItem,
            {
                marginBottom: '2em !important',
                '.ms-TextField-suffix': {
                    padding: 0,
                    background: 'transparent',
                }
                // CSS PROPS AND SELECTORS
            }
        ],
        spinner: [DocumentsGlobalClassNames.spinner,
        {
            width: '100%',
            height: '70%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }],
        listContent: [DocumentsGlobalClassNames.listContent,
        {
            position: 'relative',
            selectors: {
                ' > .ms-Viewport': {},
                ' .ms-DetailsList': { overflow: 'unset' },
                ' .ms-DetailsList .div[role=grid]': { height: '100%' },
                ' .ms-DetailsList-headerWrapper': { position: 'sticky', top: 0, zIndex: '100' },
                ' .ms-DetailsRow ': {
                    visibility: 'visibile'
                }
            },
        }],
        menuIconWrapper: {
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            color: 'inherit',
            visibility: 'hidden',
            [`.${'ms-DetailsRow'}:hover &`]: {
                visibility: 'visible'
            }
        },
        workflowListNameWrapper: [
            DocumentsGlobalClassNames.workflowListNameWrapper,
            {
                position: 'relative',
                paddingRight: 30,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        ],
        subComponentStyles: {
            iconButton: {
                root: {
                    height: '100%',
                    float: 'right'
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`
                },
                menuIcon: {
                    display: 'none !important'
                },
                rootExpanded: {
                    backgroundColor: 'inherit'
                },
                rootPressed: {
                    backgroundColor: 'inherit'
                },
                rootHovered: {
                    backgroundColor: 'inherit',
                }
            },
        }
    };
}