import { IButtonStyles, IconButton } from "@fluentui/react";
import React from "react";
import authService from "../../../../../modules/authentication/teamsAuthService";
import { useAsyncApi } from "../../../../../utilities/hooks";
import { KnowledgeLabRoleId } from "../../../../models/knowledgeLabRoleId";
import { User } from "../../../../models/user";
import { knowledgeLabApi } from "../../../../services/knowledgeLab.api";

export interface IRemoveMemberButtonProps {
    knowledgeLabId: number;
    member: User;
    buttonStyles: IButtonStyles;
    onRemoveMemberCompleted: () => void;
    disabled?: boolean;
}

export const RemoveMemberButton = (props: IRemoveMemberButtonProps) => {
    const { knowledgeLabId, member, buttonStyles, onRemoveMemberCompleted } = props;

    const removeMember = useAsyncApi({
        func: async (member: User) => {
            await knowledgeLabApi.deleteMember(knowledgeLabId, member.id);
            onRemoveMemberCompleted();
        }
    });

    if (member.roleId === KnowledgeLabRoleId.Owner)
        return null;

    const disabled = member.id === authService.currentUserId;

    return (
        <IconButton
            disabled={props.disabled || disabled || removeMember.loading}
            styles={buttonStyles}
            iconProps={{ iconName: "Cancel" }}
            onClick={() => removeMember.execute(member)}
        />);
}