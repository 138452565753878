/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable sonarjs/no-duplicate-string*/
import React, { useEffect, useState } from "react";
import { classNamesFunction, Icon, IconButton, List, ScrollablePane, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { IImportExportMetadataProps, IImportExportMetadataPropsStyles, IImportExportMetadataStyles } from "./importExport.types";
import { ExportStatus, ReportType } from "../../../models/archive";
import RowReport from "../exportReportSettings/RowReport/RowReport";
import { Report } from "../exportReportSettings/exportReportSettings.base";
import { nanoid } from "@reduxjs/toolkit";
import { archivesApi } from "../../../services/archives/archives.api";
import { startDownload } from "../../../features/downloadStatus";
import { useDocLabDispatch, useDocLabState } from "../../../docLabStore";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { DateTime } from "luxon";
import { setToolbarActions } from "../../../features/toolbarActions";
import { showExportMetadataModal, showImportACPVModal, showImportDocsTreeModal } from "../../../features/archiveContent";
import ExportMetadataModal from "./exportMetadataModal/exportMetadataModal";
import { showImportMetadataModal } from "../../../features/archiveContent";
import ImportMetadataModal from "./importMetadataModal/importMetadataModal";
import ImportACPVModal from "./importACPVModal/importACPVModal";
import ImportDocsTreeModal from "./importDocsTreeModal/importDocsTreeModal";

const  getClassNames = classNamesFunction<IImportExportMetadataPropsStyles, IImportExportMetadataStyles>();

export const ImportExportMetadataBase = (props: IImportExportMetadataProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState<Report[]>([]);
    const [importAutoFillItems, setImportAutoFillItems] = useState<Report[]>([]);
    const dispatch = useDocLabDispatch();
    const docLabState = useDocLabState();
    const currentArchive = useCurrentArchive();
    const { t } = useTranslation(['importExportMetadata']);

    useEffect(() => {
        dispatch(setToolbarActions([]));
        getMetadata();
    }, [currentArchive?.id]) //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(!docLabState.archiveContent.exportMetadataModalShown
        && !docLabState.archiveContent.importMetadataModalShown
        && !docLabState.archiveContent.importACPVModalShown
        && !docLabState.archiveContent.importDocsTreeModalShown) {
            getMetadata();
        }
    }, [docLabState.archiveContent.exportMetadataModalShown, docLabState.archiveContent.importMetadataModalShown, docLabState.archiveContent.importACPVModalShown, docLabState.archiveContent.importDocsTreeModalShown]) //eslint-disable-line react-hooks/exhaustive-deps

    async function getMetadata() {
        const report : Report[] = [];
        const importAutoFill : Report[] = [];

        try {
            setLoading(true);
            const result = await archivesApi.getReportMetadata(currentArchive?.id ?? 0, true);
            
            result.forEach(element => {
                const createdOn = new Date(element?.createdOn ?? '');
                const dateFormat = "dd/MM/yyyy HH:mm";
                const zoneLocal = DateTime.now();
                let stringDateDownloadLink = "";
    
                if(element?.status === ExportStatus.onGoing || element?.status === ExportStatus.inError){
                    const previousCreatedOn = new Date(element?.previousReportCreatedOn ?? '');
                    stringDateDownloadLink = DateTime.fromJSDate(previousCreatedOn ?? new Date(), {zone: zoneLocal.zone}).toFormat(dateFormat);
                }
        
                const stringDateFile = DateTime.fromJSDate(createdOn ?? new Date(), {zone: zoneLocal.zone}).toFormat(dateFormat);
                if(stringDateDownloadLink === '')
                    stringDateDownloadLink = stringDateFile;
                
                const fileName = t('fileName.' + element.reportName).trim() + '_' + currentArchive?.name + '_' + stringDateDownloadLink + '.xlsx';
                
                const isFieldSelection = getIsFieldSelection(element.configs) ?? true;

                const newItem : Report = 
                {
                    createdOn: stringDateDownloadLink,
                    status: element.status,
                    title: (!element.reportName || element.reportName === '') ? element.archiveReportId?.toString() ?? '' : t('reportName.'+ element.reportName),
                    description: (!element.reportDescription || element.reportDescription === '') ? '' : t(element.reportDescription),
                    fileName: fileName,
                    previousCreatedOn: element.previousReportCreatedOn?.toString(),
                    noReport: element.status === ExportStatus.notExisting || (element.status === ExportStatus.inError && element.previousReportCreatedOn === null),
                    reportId: element.archiveReportId,
                    type: element.reportType,
                    isFieldSelection: isFieldSelection,
                    position: element.reportType === ReportType.Export ? 0 : 1
                }
                
                if(newItem.type === ReportType.Export || newItem.type === ReportType.Import)
                {
                    report.push(newItem);
                }

                if(newItem.type === ReportType.ImportACPV || newItem.type === ReportType.ImportDocsTree)
                {
                    if(newItem.status === ExportStatus.inError)
                        newItem.errorMessage = getErrorMessageForImport(element.configs)
                    
                    newItem.type === ReportType.ImportACPV ? newItem.position = 0 : newItem.position = 1
                    importAutoFill.push(newItem);
                }
            });
        }
        catch(error) {
            console.error(error)
        }
        finally {
            report.sort((obj1, obj2) => {
                return obj1.position - obj2.position;                
            });
            setItems(report);

            importAutoFill.sort((obj1, obj2) => {
                return obj1.position - obj2.position;                
            });
            setImportAutoFillItems(importAutoFill);
            setLoading(false);
        }
    }

    const getErrorMessageForImport = (jsonItem: any): string | undefined => { //eslint-disable-line @typescript-eslint/no-explicit-any
        if(jsonItem && jsonItem != null){
            for (const [key, value] of Object.entries(jsonItem)) {
                if(key === "ErrorMessage"){
                    return value as string;
                }
            }
        }
    }

    const onRenderCell = (item?: Report): JSX.Element => {
        return (
            <RowReport
                item={item}
                noReport={item?.noReport}
                onStartNewReport={() => {
                    if(item?.type === ReportType.Export && item.isFieldSelection) {                        
                        dispatch(showExportMetadataModal({show: true, reportId: item.reportId ?? 0}));
                    }
                    
                    if(item?.type === ReportType.Import) {                        
                        dispatch(showImportMetadataModal({show: true, reportId: item?.reportId ?? 0}));
                    }

                    if(item?.type === ReportType.ImportACPV) {                        
                        dispatch(showImportACPVModal({show: true, reportId: item?.reportId ?? 0}));
                    }

                    if(item?.type === ReportType.ImportDocsTree) {                        
                        dispatch(showImportDocsTreeModal({show: true, reportId: item?.reportId ?? 0}));
                    }
                }}
                onFileClick={() =>{
                    dispatch(startDownload(
                        { requestId: nanoid(), file: { id: item?.reportId ?? 0, name: item?.fileName ?? ''}, downloadFunction: archivesApi.downloadReport }));
                }}
                fileExtension={'xlsx'}
                translationName={t('importExportMetadata')}
            />
        );
    };

    const getIsFieldSelection = (jsonItem: any): boolean | undefined => { //eslint-disable-line @typescript-eslint/no-explicit-any
        if(jsonItem && jsonItem != null){
            for (const [key, value] of Object.entries(jsonItem)) {
                if(key === "IsFieldSelectionAllowed"){
                    return value === true;
                }
            }
        }
    }

    return (
        <ScrollablePane style={{position: 'relative', overflow: 'scroll', height: '100%'}}>
            <div className={classNames.root}>
                <Stack className={classNames.title} horizontal verticalAlign={'center'} styles={{root: {marginBottom: '15px'}}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingRight: '40px'}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Icon iconName={"Switch"} className={classNames.iconTitle} />
                            <span style={{ paddingLeft: 10 }}>{t('title')}</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <IconButton
                                iconProps={{ iconName: "Refresh" }}
                                onClick={() => getMetadata()}
                            />
                        </div>
                    </div>
                </Stack>
                <Stack>
                    {
                        items.length === 0 && !loading ? <>
                            <TeamsImage
                                imageName={ImageName.SurprisedWoman}
                                style={{
                                    height: '250px',
                                    width: '400px',
                                    alignSelf: 'center'
                                }}
                                caption={t('noReportAvaiable')}
                            />
                        </> : <></>
                    }
                    {loading ? 
                        <Spinner size={SpinnerSize.large} /> : 
                        <List style={{width: '100%'}} items={items} onRenderCell={onRenderCell} /> 
                    }            
                </Stack>
                {importAutoFillItems.length !== 0 && <Stack>
                    <Stack className={classNames.title} horizontal verticalAlign={'center'} styles={{root: {marginBottom: '15px'}}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '15px'}}>
                                <Icon iconName={"Import"} className={classNames.iconTitle} />
                                <span style={{ paddingLeft: 10 }}>{t('titleImportAutoFill')}</span>
                            </div>
                        </div>
                    </Stack>
                    <Stack>
                        {loading ? 
                            <Spinner size={SpinnerSize.large} /> :
                            <List style={{width: '100%'}} items={importAutoFillItems} onRenderCell={onRenderCell} /> 
                        }
                    </Stack>
                </Stack>}
                {docLabState.archiveContent.exportMetadataModalShown && <ExportMetadataModal/>}
                {docLabState.archiveContent.importMetadataModalShown && <ImportMetadataModal />}
                {docLabState.archiveContent.importACPVModalShown && <ImportACPVModal />}
                {docLabState.archiveContent.importDocsTreeModalShown && <ImportDocsTreeModal />}
            </div>
        </ScrollablePane>
    )
}