export const authority = "https://login.microsoftonline.com/c16e514b-893e-4a01-9a30-b8fef514a650";
export const tenantId = "c16e514b-893e-4a01-9a30-b8fef514a650";

export type ConfigEntry = {
    clientId: string;
    redirectUri: string;
    scopes: string[];
}

export const environment: Record<string, ConfigEntry> = {
    'localhost:3000': {
        clientId: "ac98d506-d573-471b-a983-da5a89f7dc70",
        redirectUri: "https://localhost:3000",
        scopes: ["http://aap-aad-ictx-EDI_BE/Access.All"]
    },
    'sd-edi-app.eni.com': {
        clientId: "cfb24fd1-a2cd-4fa4-8695-f608812eb9ab",
        redirectUri: "https://sd-edi-app.eni.com",
        scopes: ["http://aap-aad-ictd-EDI_BE/Access.All"]
    },
    'st-edi-app.eni.com': {
        clientId: "893dc1c3-4bbf-4627-876b-ddbaa8e32b67",
        redirectUri: "https://st-edi-app.eni.com",
        scopes: ["http://aap-aad-ictt-EDI_BE/Access.All"]
    },
    'pp-edi-app.eni.com': {
        clientId: "ffcc7c85-056a-48ed-8109-4f40168ad544",
        redirectUri: "https://pp-edi-app.eni.com",
        scopes: ["http://aap-aad-ictq-EDI_BE/Access.All"]
    },
    'edi-app.eni.com': {
        clientId: "f87f37f7-e244-4c44-8aed-c8c0e7467d3a",
        redirectUri: "https://edi-app.eni.com",
        scopes: ["http://aap-aad-ictp-EDI_BE/Access.All"]
    }
}