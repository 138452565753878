import { classNamesFunction, DirectionalHint, Stack } from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import CalloutNotification from "../../../../common/components/calloutNotification/calloutNotification";
import NotificationPanel from "../../../../common/components/notificationPanel/notificationPanel";
import Page from "../../../../common/components/page/Page";
import TagPanel from "../../../../common/components/tagPanel/tagPanel";
import { useDocLabDispatch } from "../../../docLabStore";
import { loadFolderContent, showCopyLinkModal, showCreateFolderModal, showDeleteModal, showManagePermissionModal, showManageProfilePermissionModal, showNavigationModal, showRenameArchiveModal, showRenameModal, showTranslationModal, showWorkflowDetailsModal, showDownloadWfDetailsModal, showUploadFilesModal, useArchiveContent, setDocumentUploadMetadata, showFilesApprovalDocumentModal } from "../../../features/archiveContent";
import { shownCallout, useCallNotification } from "../../../features/callNotification";
import { ArchiveSettingsTab, ArchiveTab, DocLabRoutes } from "../../../utilities/routes";
import DashboardDocAnalysis from "../../docAnalysis/dashboardDocAnalysis/dashboardDocAnalysis";
import QualityCheckResults from "../../docAnalysis/results/qualityCheckResult/qualityCheckResult";
import ArchiveContent from "../../files/archiveContent/archiveContent";
import CreateFolderModal from "../../files/createFolderModal/createFolderModal";
import DeleteModal from "../deleteModal/deleteModal";
import DownloadNotification from "../../files/downloadNotification/downloadNotification";
import FolderPermissionModal from "../../files/folderPermissionModal/folderPermissionModal";
import RenameItemModal from "../../files/renameItemModal/renameItemModal";
import UploadFileModal from "../../files/uploadFileModal/uploadFileModal";
import Search from "../../search/search";
import ArchiveMembersSettings from "../../settings/archiveMembersSettings/archiveMembersSettings";
import DocAnalysisSettings from "../../settings/docAnalysisSettings/docAnalysisSettings";
import ArchiveList from "../archiveList/archiveList";
import Toolbar from "../toolbar/toolbar";
import { IRootProps, IRootPropsStyles, IRootStyles } from "./root.types";
import DashboardContainer from "../../dashboard/dashboardContainer/dashboardContainer";
import DocLabSettings from "../../settings/docLabSettings/docLabSettings";
import TocResult from "../../docAnalysis/results/tocResult/tocResult";
import { AnalysisTypeId } from "../../../models/docAnalysis";
import CopyLinkModal from "../../files/copyLinkModal/copyLinkModal";
import SearchAndCompareResults from "../../docAnalysis/results/searchAndCompareResults/searchAndCompareResults";
import ExportArchiveModal from "../archiveList/exportArchiveModal/exportArchiveModal";
import ExportReportSettings from "../../settings/exportReportSettings/exportReportSettings";
import RenameArchiveModal from "../../files/renameArchiveModal/renameArchiveModal";
import NavigationItemModal from "../../files/navigationItemModal/navigationItemModal";
import ProfileManagement from "../../settings/profileManagement/profileManagement";
import FolderProfilePermissionModal from "../../files/folderProfilePermissionModal/folderProfilePermissionModal";
import WorkflowDetailsModal from "../../files/workflowDetailsModal/workflowDetailsModal";
import DocumentTranslationModal from "../../files/archiveContent/documentTranslationModal/documentTranslationModal";
import ImportExportMetadata from "../../settings/importExportMetadata/importExport";
import DownloadWfDetailsModal from "../../files/downloadWfDetailsModal/downloadWfDetailsModal";
import FilesApprovalDocumentModal from "../../files/filesApprovalDocumentModal/filesApprovalDocumentModal";
import RecycleBin from "../../settings/recycleBin/recycleBin";
import ManageTopicsOfInterest from "../../settings/manageTopicsOfInterest/manageTopicsOfInterest";
import ManageInterests from "../../settings/manageInterests/manageInterests";
/*eslint-disable sonarjs/cognitive-complexity*/

const getClassNames = classNamesFunction<IRootPropsStyles, IRootStyles>();

const collapseStyle = {
    flexBasis: 0,
    opacity: 0,
}

export const RootBase = (props: IRootProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const dispatch = useDocLabDispatch();
    const { t } = useTranslation('common');
    const { showCallout, calloutMessage } = useCallNotification();
    const { managePermissionModalShown, isDeeplink, folderModalShown, uploadFilesModalShown, renameModalShown, renameArchiveModalShown, deleteModalShown, manageProfilePermissionModalShown, navigationModalShown, copyLinkModalShown, translationModalShown, tagPanelShown, archiveExportShown, workflowDetailsModalShown, downloadWfDetailsModalShown, filesApprovalDocumentModalShown } = useArchiveContent();

    const getFileId = (param: string): number | undefined => {
        let result: number | undefined = undefined;
        if (props.ctx?.subEntityId && isDeeplink) {
            const splitted = atob(props.ctx.subEntityId).split(param)[1];
            result = parseInt(splitted);
        }
        return result;
    }

    const isFolder = (param: string): boolean | undefined => {
        let result: boolean | undefined = undefined;
        if (props.ctx?.subEntityId) {
            const isFolder = atob(props.ctx.subEntityId).split(param)[1];
            result = isFolder === "true";
        }
        return result;
    }

    const isAnalysisDeepLink = (): boolean =>{
        if (props.ctx?.subEntityId) {
            return (atob(props.ctx?.subEntityId).includes('doc-analysis')
                        || atob(props.ctx?.subEntityId).includes('export-report')
                        || atob(props.ctx?.subEntityId).includes('import-export-metadata'))
        }

        return false;
    }

    return (
        <>
            <Stack className={classNames.root} horizontal verticalAlign={"stretch"}>
                <Stack.Item className={classNames.leftNav} disableShrink style={sidebarCollapsed ? collapseStyle : {}}>
                    <ArchiveList isDeepLinkDocAnaysis={isAnalysisDeepLink()}/>
                </Stack.Item>
                <Stack.Item className={classNames.rightContent} grow>
                    <Stack style={{ height: '100%' }} horizontal={false} verticalAlign={"stretch"}>
                        <Stack.Item disableShrink>
                            <Toolbar onMenuButtonClick={() => setSidebarCollapsed(!sidebarCollapsed)} />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Route path={`${DocLabRoutes.archive}/${ArchiveTab.files}`}>
                                <Page title="DocLab - Files">
                                    <ArchiveContent fileId={getFileId("/fileid=")} isDeepLinkfolder={isFolder("/isfolder=")} />
                                </Page>
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/settings/${ArchiveSettingsTab.users}`}>
                                <Page title="DocLab - Settings - Members" content={ArchiveMembersSettings} />
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/settings/${ArchiveSettingsTab.exportReport}`}>
                                <Page title="DocLab - Settings - Members" content={ExportReportSettings} />
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/settings/${ArchiveSettingsTab.importExportMetadta}`}>
                                <Page title="DocLab - Settings - Import/Export Metadata" content={ImportExportMetadata} />
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/settings/${ArchiveSettingsTab.recycleBin}`}>
                                <Page title="DocLab - Settings - Recycle Bin" content={RecycleBin} />
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/settings/${ArchiveSettingsTab.topicsOfInterestManagement}`}>
                                <Page title="DocLab - Mange Topics of Interest" content={ManageTopicsOfInterest} />
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/settings/${ArchiveSettingsTab.docAnalysis}`}>
                                <Page title="DocLab - Settings - DocAnalysis" content={DocAnalysisSettings} />
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/settings/${ArchiveSettingsTab.interestsManagement}`}>
                                <Page title="DocLab - Settings - Manage Your Interests" content={ManageInterests} />
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/${ArchiveTab.dashboard}`}>
                                <Page title="DocLab - Dashboard" content={DashboardContainer} />
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/${ArchiveTab.search}`}>
                                <Page title="DocLab - Search" content={Search} />
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/${ArchiveTab.docAnalysis}`} exact>
                                <Page title="DocLab - DocAnalysis" content={DashboardDocAnalysis} />
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/${ArchiveTab.docAnalysis}/${AnalysisTypeId.QualityCheck}/:analysisId`}>
                                <Page title="DocLab - DocAnalysis - Quality Check" content={QualityCheckResults} />
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/${ArchiveTab.docAnalysis}/${AnalysisTypeId.TableOfContent}/:analysisId`}>
                                <Page title="DocLab - DocAnalysis - Table Of Content" content={TocResult} />
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/${ArchiveTab.docAnalysis}/${AnalysisTypeId.SearchAndCompare}/:analysisId`}>
                                <Page title="DocLab - DocAnalysis - SearchAndCompare" content={SearchAndCompareResults} />
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/settings/${ArchiveSettingsTab.main}`}>
                                <Page title="DocLab - Settings" content={DocLabSettings} />
                            </Route>
                            <Route path={`${DocLabRoutes.archive}/settings/${ArchiveSettingsTab.profileManagement}`}>
                                <Page title="DocLab - Profile Management" content={ProfileManagement} />
                            </Route>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>

            {folderModalShown && <CreateFolderModal onClose={() => { dispatch(showCreateFolderModal(false)); dispatch(loadFolderContent({ resetPage: true })) }} />}
            {uploadFilesModalShown && <UploadFileModal onClose={() => { dispatch(setDocumentUploadMetadata([])); dispatch(showUploadFilesModal(false)); dispatch(loadFolderContent({ resetPage: true })) }} />}
            {renameModalShown && <RenameItemModal onClose={() => { dispatch(showRenameModal(false)) }} />}
            {renameArchiveModalShown && <RenameArchiveModal onClose={() => { dispatch(showRenameArchiveModal(false)) }} />}
            {managePermissionModalShown && <FolderPermissionModal onClose={() => { dispatch(showManagePermissionModal(false)); dispatch(loadFolderContent({ resetPage: true })); }} />}
            {deleteModalShown && <DeleteModal onClose={() => { dispatch(showDeleteModal({show: false})) }} />}
            {manageProfilePermissionModalShown && <FolderProfilePermissionModal onClose={() => { dispatch(showManageProfilePermissionModal({show: false}))}} />}
            {navigationModalShown && <NavigationItemModal onClose={() => { dispatch(showNavigationModal({show: false})) }} />}
            {copyLinkModalShown && <CopyLinkModal entityId={props.ctx?.entityId || ""} onClose={() => { dispatch(showCopyLinkModal({...copyLinkModalShown, showModal: false})) }} />}
            {workflowDetailsModalShown && <WorkflowDetailsModal onClose={() => { dispatch(showWorkflowDetailsModal(false)); }} />}
            {downloadWfDetailsModalShown && <DownloadWfDetailsModal onClose={() => { dispatch(showDownloadWfDetailsModal(false)); }} />}
            {filesApprovalDocumentModalShown && <FilesApprovalDocumentModal onClose={() => { dispatch(showFilesApprovalDocumentModal({show: false, updateStatus: undefined})); }} />}
            {translationModalShown && <DocumentTranslationModal onSuccess={() => dispatch(showTranslationModal(false))} onCloseClick={() => dispatch(showTranslationModal(false))} />}
            {archiveExportShown && <ExportArchiveModal/>}
            <DownloadNotification />
            <NotificationPanel />
            {tagPanelShown.isOpen && <TagPanel default={tagPanelShown.defaultSection}/>}
            {showCallout &&
                <CalloutNotification
                    title={t('operationFailed')}
                    message={calloutMessage}
                    isBeakVisible={false}
                    target={'panelIcon'}
                    hidden={!showCallout}
                    directionalHint={DirectionalHint.bottomCenter}
                    onDismiss={() => dispatch(shownCallout(false))}
                />}
        </>
    );
}