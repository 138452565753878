
import { classNamesFunction, CommandBar, ICommandBarItemProps, Pivot, PivotItem } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { resetDatasetFilters } from "../../../features/datasetList";
import { clearSelectedItems, setBaseFeedbackFilters, setRefresh, useFeedbackList } from "../../../features/feedbackList";
import { useNavigator } from "../../../hooks/useNavigator";
import { useKnowledgeDsDispatch } from "../../../knowledgeDsStore";
import { FeedbackStatus } from "../../../models/feedback";
import { KnowledgeDsTab } from "../../../routes/routes";
import { knowledgeDsApi } from "../../../services/knowledgeDS.api";
import CreateDatasetModal from "../../feedback/createDatasetModal/createDatasetModal";

import { IToolbarProps, IToolbarPropsStyles, IToolbarStyles } from "./toolbar.types";

const getClassNames = classNamesFunction<IToolbarPropsStyles, IToolbarStyles>();

export const ToolbarBase = (props: IToolbarProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation('knowledgeDsToolbar');
    const navigator = useNavigator();
    const dispatch = useKnowledgeDsDispatch();
    const feedbackList = useFeedbackList();

    const [isOpen, setIsOpen] = useState(false);

    const onClick = (item: PivotItem | undefined) => {
        if (item?.props.itemKey && navigator.currentTab !== item.props.itemKey) {
            navigator.changeTab(item.props.itemKey as KnowledgeDsTab);
            dispatch(setBaseFeedbackFilters())
            dispatch(resetDatasetFilters())
        }
    }

    const rightTabs = useCallback(() => {
        return (
            <div style={{ height: '40px' }}>
                <Pivot
                    headersOnly={true}
                    styles={classNames.subComponentStyles.pivot}
                    selectedKey={navigator.currentTab ?? null}
                    onLinkClick={item => onClick(item)
                    }
                    style={{ height: '100%' }}
                >
                    <PivotItem key={KnowledgeDsTab.feedback} itemKey={KnowledgeDsTab.feedback} headerText={t('feedback')} style={{ height: '40px' }} />
                    <PivotItem key={KnowledgeDsTab.datasets} itemKey={KnowledgeDsTab.datasets} headerText={t('datasets')} />
                </Pivot >
            </div>
        );
    }, [classNames.subComponentStyles.pivot, t, navigator]); //eslint-disable-line react-hooks/exhaustive-deps

    const collapseAction: ICommandBarItemProps = {
        key: "menu",
        iconProps: { iconName: "CollapseMenu" },
        onClick: props.onMenuButtonClick
    }

    const approveFeedbackAction: ICommandBarItemProps = {
        key: "approveFeedback",
        text: t('actions.approveFeedback'),
        iconProps: { iconName: "StatusCircleCheckmark", style: { fontSize: 20 } },
        onClick: () => {
            updateFeedbackStatus(FeedbackStatus.Approved)
        }
    }

    const updateFeedbackStatus = (status: FeedbackStatus) => {
        knowledgeDsApi.updateFeedback(feedbackList.selectedItems.map(f => f.id), status)
            .then(() => {
                dispatch(clearSelectedItems())
                dispatch(setRefresh(true));
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const rejectFeedbackAction: ICommandBarItemProps = {
        key: "rejectFeedback",
        text: t('actions.rejectFeedback'),
        iconProps: { iconName: "StatusCircleErrorX", style: { fontSize: 20 } },
        onClick: () => {
            updateFeedbackStatus(FeedbackStatus.Rejected)
        }
    }

    const createDatasetAction: ICommandBarItemProps = {
        key: "createDataset",
        text: t('actions.createDataset'),
        iconProps: { iconName: "AppIconDefaultAdd" },
        onClick: () => {
            setIsOpen(true);
        },
        disabled: !(feedbackList.selectedItems.every(i => i.feedbackStatus === FeedbackStatus.Approved || i.feedbackStatus === FeedbackStatus.DatasetCreated)
            && feedbackList.selectedItems.every(i => i.skillName === feedbackList.selectedItems[0].skillName))
    }

    const actions = () => {
        const acts = [] as ICommandBarItemProps[];

        acts.push(collapseAction);

        if (navigator.currentTab === KnowledgeDsTab.feedback && feedbackList.selectedItems && feedbackList.selectedItems.length > 0) {

            if (feedbackList.selectedItems.every(i => i.feedbackStatus !== FeedbackStatus.DatasetCreated)) {
                acts.push(approveFeedbackAction)
                acts.push(rejectFeedbackAction)
            }
            acts.push(createDatasetAction)
        }

        return acts;
    }

    const infoTab = () => (

        //TODO
        // <>
        //     <Route path={`${KnowledgeLabRoutes.knowledgeLab}/${KnowledgeTab.documents}`}>
        //         <IconButton
        //             styles={classNames.subComponentStyles.reloadIconButton()}
        //             style={knowledgelab.isLoading ? { animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite` } : {}}
        //             disabled={knowledgelab.isLoading}
        //             iconProps={{ iconName: "Refresh" }}
        //             onClick={() => dispatch(setForceDocumentReload(true))}
        //         />
        //     </Route>
        // </>

        <></>

    )



    return (
        <div className={classNames.root}>
            <CommandBar
                className={classNames.wrapper}
                items={actions()}
                farItems={[{ key: "info", commandBarButtonAs: infoTab }, { key: "right-tabs", commandBarButtonAs: rightTabs }]}
            >
            </CommandBar>

            <CreateDatasetModal isOpen={isOpen} onClose={() => {
                setIsOpen(false)
                dispatch(setRefresh(true))
            }} />


        </div>
    );
}