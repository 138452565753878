import React, { useState } from "react";
import { classNamesFunction, Icon, ScrollablePane, Stack } from "@fluentui/react";
import { IActivityLabSettingsPropsStyles, IActivityLabSettingsStyles, IActivityLabSettingsProps } from "./activityLabSettings.types";
import { useTranslation } from "react-i18next";
import { useNavigator } from "../../../hooks/useNavigator";
import { useOnMount } from "../../../../utilities/hooks";
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import ActivitySettingsTemplate from "./activitySettingsTemplate/activitySettingsTemplate";
import { TemplateActivity } from "../../../models/templateActivity";
import CreateTemplateModal from "./createTemplateModal/createTemplateModal";
import { templateApi } from "../../../services/template/template.api";

const getClassNames = classNamesFunction<IActivityLabSettingsPropsStyles, IActivityLabSettingsStyles>();

export const ActivityLabSettingsBase = (props: IActivityLabSettingsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['templateActivity', 'common']);
    const { currentPortfolioId } = useNavigator();
    const [loading, setLoading] = useState(false);
    const [loadingTemplate, setLoadingTemplate] = useState(false);
    const [error, setError] = useState(false);
    const [templateList, setTemplateList] = useState<TemplateActivity[]>([]);
    const [showModal, setShowModal] = useState(false);

    useOnMount(() => {
        async function fetch() {
            await getTemplateList();
        }
        fetch();
    })

    const getTemplateList = async (withoutLoading?: boolean) => {
        if (currentPortfolioId === undefined)
            return;
        !withoutLoading && setLoading(true);
        setError(false);
        try {
            const list = await templateApi.getTemplateList(currentPortfolioId);
            setTemplateList(list);
        }
        catch (ex) {
            setError(true)
        }
        finally {
            !withoutLoading && setLoading(false);
        }
    }
    return (
        <>
            {loading ?
                <div className={classNames.loaderContainer}>
                    <TeamsSpinner />
                </div> :
                error ? <TeamsImage imageName={ImageName.Oops1} scale={0.5} fullContainer /> :
                    <Stack className={classNames.root}>
                        <Stack className={classNames.title} horizontal verticalAlign={'center'}>
                            <Icon iconName={"AccountActivity"} className={classNames.titleIcon} />
                            <span style={{ paddingLeft: 10 }}>{t('title')}</span>
                        </Stack>
                        <Stack.Item verticalFill className={classNames.lowerSection}>
                            <ScrollablePane styles={{ contentContainer: { overflowX: 'hidden' } }}>
                                <ActivitySettingsTemplate reloadItems={async () => { setLoadingTemplate(true); await getTemplateList(true).then(() => setLoadingTemplate(false)) }} openModal={() => setShowModal(true)} listLoading={loadingTemplate} listTemplate={templateList} />
                            </ScrollablePane>
                        </Stack.Item>
                    </Stack>}
            {showModal && <CreateTemplateModal onClose={async (reload) => { setShowModal(false); reload && await getTemplateList(true) }} />}
        </>
    );
}