/*eslint-disable sonarjs/cognitive-complexity*/
import React from "react";
import { IDeleteLayoutProps, IDeleteModalPropsStyles, IDeleteModalStyles } from "./deleteModal.types";
import { classNamesFunction, DefaultButton, Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";

const getClassNames = classNamesFunction<IDeleteModalPropsStyles, IDeleteModalStyles>();

export const DeleteModalLayoutBase = (props: IDeleteLayoutProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    return (
        <Dialog
            isOpen={props.IsOpen}
            dialogContentProps={{
                title: props.Title,
                showCloseButton: props.DefaultButtonText !== "",
                onDismiss: props.onDismiss,
                subText: props.SubText
            }}
        >
            <div className={classNames.footerContainer}>
                <div className={classNames.buttonContainer}>
                    <DialogFooter styles={classNames.subComponentStyles.dialogFooterContainer} >
                        {props.DefaultButtonText && <DefaultButton onClick={props.onDismiss} text={props.DefaultButtonText} disabled={props.Disabled} />}
                        {props.PrimaryButtonText && <PrimaryButton styles={classNames.subComponentStyles.primaryButtonDisabled()} onClick={props.onClick} text={props.PrimaryButtonText} disabled={props.Disabled} />}
                    </DialogFooter>
                </div>
            </div>
            {props.ErrorMessage && <span className={classNames.errorMessageStyle}>{props.ErrorMessage}</span>}
        </Dialog>
    )
}