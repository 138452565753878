import { styled } from "@fluentui/react";
import { getStyles } from "./feedbackFilters.styles";
import { IFeedbackFiltersPropsStyles, IFeedbackFiltersStyles, IFeedbackFiltersProps } from "./feedbackFilters.types";
import { FeedbackFiltersBase } from "./feedbackFilters.base";

const FeedbackFilters = styled<IFeedbackFiltersProps, IFeedbackFiltersPropsStyles, IFeedbackFiltersStyles>(
    FeedbackFiltersBase,
    getStyles,
    undefined,
    { scope: 'FeedbackFilters' }
);

export default FeedbackFilters;