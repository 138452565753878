import { FontSizes, FontWeights, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IDropdownStyles, IScrollablePaneStyles, IStyleFunction } from "@fluentui/react";
import { IKnowledgeLabMembersSettingsPropsStyles, IKnowledgeLabMembersSettingsStyles } from "./knowledgeLabMembersSettings.types";

export const knowledgeMembersSettingsGlobalClassNames = {
    root: 'edi-update-knowledge-members-root',
    upperSection: 'edi-update-knowledge-members-upper-section',
    title: 'edi-update-knowledge-members-title',
    titleIcon: 'edi-update-knowledge-members-title-icon',
    lowerSection: 'edi-update-knowledge-members-lower-section',
    headerRow: 'edi-update-knowledge-members-header-row',
    loaderContainer: 'edi-update-knowledge-members-loader-container',
    pageContainer: 'edi-knowledge-members-container',
}

export const getStyles: IStyleFunction<IKnowledgeLabMembersSettingsPropsStyles, IKnowledgeLabMembersSettingsStyles> = (props: IKnowledgeLabMembersSettingsPropsStyles): IKnowledgeLabMembersSettingsStyles => {
    const { semanticColors } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            knowledgeMembersSettingsGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px'
            }
        ],
        title: [
            knowledgeMembersSettingsGlobalClassNames.title,
            {
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingBottom: 10
            }
        ],
        titleIcon: [
            knowledgeMembersSettingsGlobalClassNames.titleIcon,
            {
                padding: 7,
                border: '1.5px solid',
                borderRadius: '50%',
                fontSize: 26
            }
        ],
        upperSection: [
            knowledgeMembersSettingsGlobalClassNames.upperSection,
            {
                paddingBottom: 10
            }
        ],
        lowerSection: [
            knowledgeMembersSettingsGlobalClassNames.lowerSection,
            {
                position: 'relative'
            }
        ],
        headerRow: [
            knowledgeMembersSettingsGlobalClassNames.headerRow,
            {
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                }
            }
        ],
        loaderContainer: [
            knowledgeMembersSettingsGlobalClassNames.loaderContainer,
            {
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        ],
        pageContainer: [
            knowledgeMembersSettingsGlobalClassNames.pageContainer,
            {
                maxHeight: 'calc(100vh - 100px)',
                overflowY: 'scroll',
                position: 'inherit',
                ".ms-ScrollablePane": {
                    position: 'inherit !important'
                },
                ".ms-ScrollablePane--contentContainer": {
                    position: 'inherit !important',
                }
            }
        ],
        subComponentStyles: {
            list: (): IDetailsListStyles => {
                return {
                    root: {},
                    contentWrapper: {
                    },
                    focusZone: {},
                    headerWrapper: {
                        '> .ms-DetailsHeader': {
                            paddingTop: 0,
                            borderBottomColor: 'transparent'
                        }
                    },
                }
            },
            row: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                const { palette } = props.theme;

                return {
                    root: {
                        backgroundColor: palette.white,
                        ':hover': {
                            backgroundColor: palette.white
                        }
                    },
                    cell: {},
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
            scrollablePane: (): IScrollablePaneStyles => {
                return {
                    root: {},
                    contentContainer: {
                        width: '100%',
                        paddingRight: 17,
                        overflowY: 'overlay'
                    },
                    stickyAbove: {},
                    stickyBelow: {},
                    stickyBelowItems: {}
                }
            },
            rolesDropdown: (): IDropdownStyles => {
                return {
                    root: {},
                    label: {},
                    dropdown: {
                        '::after': {
                            display: 'none'
                        }
                    },
                    title: {
                        backgroundColor: 'transparent'
                    },
                    caretDownWrapper: {},
                    caretDown: {},
                    errorMessage: {},
                    dropdownItemsWrapper: {},
                    dropdownItems: {},
                    dropdownItem: {},
                    dropdownItemSelected: {},
                    dropdownItemDisabled: {},
                    dropdownItemSelectedAndDisabled: {},
                    dropdownItemHidden: {},
                    dropdownOptionText: {},
                    dropdownDivider: {},
                    dropdownItemHeader: {},
                    panel: {},
                    callout: {},
                    subComponentStyles: {
                        label: {},
                        multiSelectItem: {},
                        panel: {}
                    }
                }
            },
            removeButton: {
                root: {
                    paddingRight: 10
                },
                icon: {
                    color: semanticColors.bodyText
                },
                rootHovered: {
                    background: 'transparent'
                },
                rootPressed: {
                    background: 'transparent'
                },
                rootDisabled: {
                    background: 'transparent'
                }
            }
        }
    };
}