/*eslint-disable sonarjs/no-duplicate-string */
import { ActivityPriority, ActivityStatus, Constants as DocLabConstants } from "../../../activityLab/models/constants";
import { WorkFlowRelativeStatus } from "../../../activityLab/models/workflow";
import { WorkFlowApproverStatus } from "../../../activityLab/models/workflowApprover";
import { WorkFlowStepRelativeStatus, WorkFlowStepTypes } from "../../../activityLab/models/workflowStep";
import { UploadErrors } from "../../../docLab/components/files/uploadFileModal/uploadFileModal.types";
import { AnalysisDocError, AnalysisDocWarning, Constants, FormalAspects, GlossaryExcelDataValidationErrors, ItemToCopy, Labels } from "../../../docLab/models/constants";
import { AnalysisTypeId } from "../../../docLab/models/docAnalysis";

export const en = {
    authentication: {
        errorMessage1: 'Something went wrong.',
        errorMessage2: 'Check browser permissions in order to allow popups and retry.',
        accessDenied: 'Access not allowed. Contact the administrator to be enabled'
    },

    folderPermission: {
        title: 'Manage Permission',
        searchBox: 'Search member',
        peoplePicker: 'Add member from the ENI organization',
        minLengthAlert: "Type at least three characters",
        noResults: "No people match the specified keyword",
        addMembers: "Add members"

    },

    folderProfilePermission: {
        newTitle: 'Create new folder',
        editTitle: 'Manage permissions',
        editSubtitle: 'Manage the access of newly added Profiles to all subfolders already created',
        inputFolder: 'Folder name',
        selectProfileGroup: 'Select the profile group',
        manageVisibilityCoin: 'Manage visibility permissions',
        viewProfiles: 'View profiles with chosen visibility permission',
        searchProfiles: 'Search profiles',
        searchMembers: 'Search members',
        viewUsers: 'View the list of associated users',
        dropdownOwner: 'Only owner',
        dropdownBrothers: 'All profiles that have visibility to all folders of the same level',
        dropdownFathers: 'All profiles that have visibility to the parent folder',
        dropdownCustomValue: 'Personalized',
        subtitleMemberInvolved: 'View the list of members with chosen visibility permission',
        enableAllSubfolders: 'Enable all Profiles to all subfolders',

        ofFolder: "the folder",
        alreadyExists: "Folder name already exists",
        notFound: "Parent folder not found",
        notPermissions: "Access denied for user",
        excessiveDepth: "Folder tree greater than 4",
        messageCreate: 'Folder creation "',
        successMessage: 'Completed successfully',
        messageEdit: 'Update of profiles related to the folder "',

    },

    createTemplate: {
        firstPageTitle: 'Add ToC',
        viewFirstPageTitle: 'View ToC',
        name: 'ToC Name',
        description: 'ToC Description',
        secondPageHeader: '',
        hideToolBar: 'Hide buttons',
        showToolBar: 'Show buttons',
        createChild: 'Create child node',
        promoteNode: 'Promot node',
        downgradesNode: 'Downgrades node',
        upNode: 'Move up',
        deleteNode: 'Delete node',
        downNode: 'Move down',
        addSection: 'Add section',
        emptyField: 'Field not filled in'
    },

    search: {
        searchBox: 'Edi Search',
        searchBoxKnowledge: 'Search',
        createdBy: 'Created By',
        createdOn: 'Created On',
        relevance: 'Relevance',
        alphabetAsc: 'Created By (A-Z)',
        alphabetDesc: 'Created By (Z-A)',
        createdOnAsc: 'Least recent',
        createdOnDesc: 'Most recent',
        errorMessage: 'Type at least two characters',
        filterMessage: 'Type at least two characters',
        title: 'Search filters',
        knowledgeTitle: 'Search filters and entities',
        docLabMultiselectPlaceholder: 'Select Archives',
        advancedDropdownLabel: 'Select the archives to search',
        resetSearch: 'Reset search',
        restartSearch: 'Restart search',
        refreshResults: 'Refresh results',
        documentArchive: 'Document Archive',
        approvalStatus: 'Approval Status',
        additionalData: {
            contractSignDate: 'Sign date',
            legalSystem: 'Legal system',
            contractType: 'Contract type',
            views: 'Views',
        },
        commands: {
            open: 'Open',
            openInBrowser: 'Open in browser',
            translateDocument: 'Translate document',
            createLink: 'Create link',
            copyDocumentId: 'Copy ID Document',
            viewTag: "View Tag",
            download: 'Download',
            delete: 'Delete',
            move: 'Move',
            copy: 'Copy',
            addToFavourites: 'Add to favourites',
            removeFromFavourites: 'Remove from favourites',
            downloadFile: 'Download document',
            viewFile: 'View document',
            details: 'Open detail',
            addToCompare: 'Add to compare'
        },
        filters: {
            standard: {
                full: 'Both',
                metadata: 'Search in the metadata',
                text: 'Search in the document'
            },
            ranges: {
                range_1: 'Last 24 hours',
                range_2: 'Last Week',
                range_3: 'Last Month',
                range_4: 'Last 6 Months',
                range_5: 'Last Year',
                range_6: 'Other',
            },
            approvalStatus: {
                value_0: 'NA',
                value_1: 'To Be Approved',
                value_2: 'Approved',
                value_3: 'Rejected',
                value_4: 'Migrated'
            },
            alreadyApproved: {
                true: "Yes",
                false: "No"
            },
            formalAspects: {
                from: 'From',
                to: 'To',
                [FormalAspects[0].key]: 'Tables',
                [FormalAspects[1].key]: 'Paragraphs',
                [FormalAspects[2].key]: 'Pages',
                [FormalAspects[3].key]: 'Words',
                [FormalAspects[4].key]: 'Images',
            },
            folderFilter: {
                allFolderDefault: 'All folders',
                crossFolderSearch: 'Search the results on:',
                folderModal: 'Select',
                primaryTitle: 'Select the folders where you want to search',
                secondaryTitle: 'Folder selection',
                description: 'Click the folder where you want to view the search results',
                save: 'Select',
                cancel: 'Cancel',
                warningMessage: "Remember that by selecting folders it won't be possible to search in multiple archives"
            },
            filter: "FILTER RESULTS",
            resetFilter: "Filters Reset",
            showMore: "Show more",
            showLess: "Show less"
        },
        multiLanguage: {
            labelCheckBox: "Show results in other languages",
            tooltipLabel: "Search for documents in English, Italian, Spanish and French"
        }
    },

    workflowApprovalStep: {
        title: 'Step',
        everyoneMustApproveApproverDesctioption: "Everyone's approval is required for this step",
        onlyOneApproverDesctioption: 'At least one approval is required for this step',
        onlyOneSignedApproverDesctioption: 'A digital signature is required for this step',
        secondDesctioption: 'Text a comment',
        tooltipDescription: 'The comment will be visible to all approvers of the workflow. In case of refusal, the comment is mandatory',
        approve: 'Approve',
        signedApprove: 'Approve with signature',
        reject: 'Reject',
        rejectToolTip: 'Text a comment to reject',
        commentPlaceholder: 'Text a comment',
        notPermissions: "You don't have approval permissions",
        notFound: 'Step not found',
        unprocessable: 'Request is unprocessable'
    },

    workflowApprovalModal: {
        firsStepTitle: 'Sign the document',
        userIdToolTipDescrioption: 'User ID',
        pinToolTipDescrioption: 'User ID',
        certificate: 'Select a certificate',
        otpRequest: 'Request OTP',
        insertOtp: 'Insert OTP',
        signatureInProgress: 'Signature in progress',
        signatureDescription: 'We are signing the document.',
        secondSignatureDescription: "Please wait a few seconds.",
        signatureSuccess: 'Successfully signed',
        firsStepDescription: 'There is no signing certificate associated with your user',
        firsStepInfoDescription: 'For more information',
        digitalSignature: 'digital signature',
        insertPin: 'Insert Pin',
        insertUser: 'Insert User ID',
        notPermissions: "Access denied for user",
        notFound: 'Step not found',
        unprocessable: 'Request is unprocessable',
        signatureTooltip: 'Insert the OTP code that was sent to you via email',
        pinTooltip: 'Insert a pin with 8 numbers',
        wrongRequest: 'Pin or certificate are not valid',
        certificateNotFound: 'Certificate not found',
        editText: 'Changes will not be applied to the projects already entered'
    },

    activity: {
        status: {
            [ActivityStatus.Planned]: 'Planned',
            [ActivityStatus.OnGoing]: 'On Going',
            [ActivityStatus.Blocked]: 'Blocked',
            [ActivityStatus.Closed]: 'Closed',
        },
        priority: {
            [ActivityPriority.P1]: 'Low priority',
            [ActivityPriority.P2]: 'Medium priority',
            [ActivityPriority.P3]: 'High priority',
        },
        activitiesBoard: {
            startDate: 'Start Date:',
            closeDate: 'Close Date:',
            activityExpired: "Project is not completed on time",
            activityNotStarted: 'Project not started on time',
            dueDate: 'Due Date:'
        },
        activitiesList: {
            column: {
                name: "Project Title",
                template: "Project Tipology",
                createdOn: "Creation Date",
                members: "Members",
                status: "Container"
            },
        },
    },

    workflow: {
        status: {
            [WorkFlowRelativeStatus.Ongoing]: 'Ongoing',
            [WorkFlowRelativeStatus.Approved]: 'Approved',
            [WorkFlowRelativeStatus.Refused]: 'Refused',
            [WorkFlowRelativeStatus.ToMe]: 'Assigned to the user',
        },
        sentWorkflowRequests: 'Sent Workflow requests',
        receivedWorkflowRequests: 'Received Workflow requests',
        filters: {
            filterBy: 'Filter by:',
            all: 'All',
            lastMonth: 'Last month',
            lastWeek: 'Last week',
            custom: 'Custom',
            startDate: 'From',
            endDate: 'To',
            selectDay: 'Select a day'
        }
    },

    portfoliosList: {
        title: 'Your Portfolios',
        createNew: 'Create portfolio',
        commands: {
            settingsPortfolio: 'Project Lab settings',
            settingsUsers: 'Manage Users',
            viewUsers: "View Users",
            renamePortfolio: "Rename"
        }
    },

    archiveList: {
        title: 'Your Doc Labs',
        createNew: 'Create a Doc Lab',
        commands: {
            archiveSettings: "Doc Lab Settings",
            deleteArchive: "Delete",
            exportArchive: "Report",
            importExport: "Import/Export",
            renameArchive: "Rename",
            docAnalysisSettings: "Doc Analysis Settings",
            recycleBin: "Recycle Bin",
            search: "Search",
            viewTag: "View Tags",
            copyLink: "Copy Link",
            download: "Download",
            delete: "Delete",
            manageTopicsOfInterest: "Manage Topics of interest",
            manageInterests: "Manage your interests"
        },
    },

    topicOfInterest: {
        manageTopics: 'Manage topics of interest',
        manageInterests: 'Manage your interests',
        topics: 'Topics',
        updateTopicsOfInterest: 'Update topics of interest',
        searchTopic: 'Search topic',
        deleteTopicsTitle: 'Delete Topics',
        deleteTopicsMessage: 'Are you sure you want to delete all the topics associated to you?',
        selectTopics: 'Select the topics for which you want to receive notification',
        leave: 'Leave',
        viewTopics: 'Topics view',
        viewTopicUser: 'User Topic association',
        topicSearch: 'Search topics',
        topicInfo: 'Topic Info',
        createNewTopic: 'Create new topic',
        noTopic: 'No topics found',   
        KeyPhraseLab: 'KeyPhrases of interest', 
        deleteTopicTitle: 'Delete Topic',
        deleteTopicMessage: 'Are you sure you want to delete the topic?',
        deleteErrorMessage: 'User-Topic association found',
        associationTopicUsers: 'Association topic - Users',
        nameLabel: 'Name',
        topicsLabel: 'Topics',
        titleAddUsersModal: 'Assign user to the topic',
        description1AddUsers: 'Start typing a name to associate with the topic of interest, so that it can be notified',
        description2AddUsers: 'You can add users who are enabled to the archive',
        minLengthAlert: 'Type at least three characters',
        noResults: 'No people match the specified keyword',
        deleteUserFromTopicTitle: 'Delete User',
        deleteUserFromTopicSubtitle: 'Are you sure you want to delete this user associate to the topic?',
        deleteUsersFromTopicTitle: 'Delete Users',
        deleteUsersFromTopicSubtitle: 'Are you sure you want to delete all the users associate to the topic?',
        newTopic: {
            title:'Create a new Topic',
            updateTitle: 'Modify Topic',
            subtitle: 'Set detail of your new Topic',
            subtitleMetadata: 'If there are more than one values for the metadata, enter all values separated by commas',
            nameLabel: 'Name',
            insertTopicName: 'Insert new Topic Name',
            descriptionLabel: 'Description',
            cancel: 'Cancel',
            next: 'Next',
            back: 'Back',
            save: 'Save',
            metadataLabel: 'Metadata',
            metadataValues: 'Values',
            KeyPhraseLabel: 'KeyPhrase'
        }
    },
    
    knowledgeList: {
        title: 'Your Knowledge Labs',
        createNew: 'Create Knowledge Lab',
        commands: {
            archiveSettings: "Doc Lab Settings",
            deleteArchive: "Delete",
            exportArchive: "Report",
            importExport: "Import/Export",
            renameArchive: "Rename",
            docAnalysisSettings: "Doc Analysis Settings",
            recycleBin: "Recycle Bin",
            search: "Search",
            viewTag: "View Tags",
            copyLink: "Copy Link",
            download: "Download",
            delete: "Delete"
        },
    },

    choiceInputSource: {
        sharepointSubtitle: 'Upload documents from sharepoint.',
        localSubtitle: 'Upload documents from your local computer.',
        ediSubtitle: 'Upload documents from Edi.'
    },

    knowledgeToolbar: {
        search: "Search",
        favorites: "Favorites",
        documents: "Documents",
        actions: {
            importDocument: 'Import documents',
            delete: 'Delete',
            view: 'View',
            download: 'Download',
            viewTags: 'View Tags',
            compareElements: 'Compare elements',
            compare: 'Compare',
        }
    },

    knowledgeDsToolbar: {
        feedback: "Feedback",
        datasets: "Datasets",
        users: "Users",
        actions: {
            approveFeedback: "Approve",
            rejectFeedback: "Reject",
            createDataset: "Create Dataset",
            view: "View document",
            details: "View element text",
            documentText: "View document text",
            train: "Retrain"
        },
    },

    knowledgeDsFeedback: {
        columns: {
            knowledgeLabName: "Knowledge Lab Name",
            fileName: "File name",
            skillName: "Skill",
            metadataChanges: "Metadata History",
            createdBy: "Created by",
            updatedBy: "Updated by",
            feedbackStatus: "Status",
            createdOn: "Created On",
            updatedOn: "Updated On",

        },
        status: {
            pending: "Pending",
            approved: "Approved",
            rejected: "Rejected",
            datasetCreated: "Dataset Created"
        },
        filters: {
            filter: "Applied Filters",
            fileName: "File Name",
            knowledgeLabName: "Knowledge Lab",
            skillName: "Skill",
            feedbackStatus: "Feedback Status",
            creationDate: "Creation date",
            resetFilters: "Restart search",
            from: "From",
            to: "To"
        },
        elementText: "Element Text",
        documentText: "Document Text"

    },

    feedbackConfirmModal: {
        cancel: "Cancel",
        approve: "Approve",
        reject: "Reject",
        approveFeedback: "Approve Feedback",
        rejectFeedback: "Reject Feedback",
        comment: "comment/note"
    },

    dashboardToolbar: {
        feedback: "Feedback",
        datasets: "Datasets",
        users: "Users",
        actions: {
            approveFeedback: "Approve",
            approveFeedbackForDataset: "Approve for dataset",
            rejectFeedback: "Reject",
            createDataset: "Create Dataset",
            view: "View document",
            details: "View element text",
            documentText: "View document text",
            retag: "View feedback details and retagging",
            train: "Retrain"
        },
        metadataFromUser: "Metadata indicated by user",
        previousMetadata: "Metadata precedent",
        metadataModifiedBy: "Metadata edited by",
        dateEditMetadata: "Metadata modification date",
        statusFeedback: "Feedback status",
        lastUserEditFeedback: "User who made the last change to the feedback",
        dateLastFeedbackEdit: "Last modified date feedback",
        noteFeedback: "Note/Comment",
        OriginalAiExtractedMetadata: "Metadata Originally Extracted by AI"
    },

    dashboardManageUser: {
        addMembers: "Add",
        modalAddUser: "Add user",
        description1: "Start typing a name to add.",
        description2: "You can add Eni people (*@eni.com or *@external.eni.com) who already have EDI on their Teams.",
        minLengthAlert: "Type at least three characters",
        noResults: "No people match the specified keyword",
        ownerBiz: "Owner Biz Reference",
        moduleLibrary: "Modules/Libraries",
        noUser: "No users"
    },

    dashboardFeedback: {
        columns: {
            moduleInstanceName: "Module/Library",
            fileName: "File name",
            skillName: "Skill AI",
            metadataChanges: "Metadata previous / new",
            createdBy: "Created by",
            updatedBy: "Updated by",
            extraProperty: "Extra Property",
            feedbackStatus: "Status",
            createdOn: "Created On",
            updatedOn: "Updated On",
            
        },
        status: {
            pending: "Pending",
            approved: "Approved",
            rejected: "Rejected",
            readyForDataset: "Ready for Dataset",
            datasetCreated: "Dataset Created"
        },
        filters: {
            filter: "Applied Filters",
            datasetName: "Dataset Name",
            fileName: "File Name",
            property: "Attribute/Property",
            extraPropertyMultiselectPlaceholder: 'Select Extra Property',
            moduleInstanceName: "Module/Library",
            skillName: "AI Skills",
            feedbackStatus: "Feedback Status",
            creationDate: "Creation date",
            resetFilters: "Restart search",
            attributeProperty: "Attribute/Property",
            from: "From",
            to: "To",
            noDescription: "No description found"
        },
        ner: {
            retagging: "Retagging",
            infoFeedback: "Info Feedback",
            dettaglioFeedback: "Feedback details",
            updatedOn: "Updated on",
            updatedBy: "Updated by",
            notChanged: "Not changed metadata",
            changed: "Changed metadata",
            fileName: "File Name",
            skill: "Skill Name",
            metadata: "Metadata",
            datonuovo: "New",
            datoold: "Previous"
        },
        elementText: "Element Text",
        documentText: "Document Text",
        search : "Search",
        findResult : "Results"
    },

    createDatasetModal: {
        confirmText: "Do you want to create a Dataset?",
        createdText: "created",
        continue: "Create",
        close: "Close"
    },

    knowledgeDsDatasets: {
        columns: {
            name: "Dataset Name",
            skillName: "Skill",
            updatedBy: "Trained by",
            createdOn: "Creation Date",
            updatedOn: "Last Retraining Date",
        }
    },

    dashboardDatasets: {
        deleteTitle: "Confirm retraining",
        deleteSubTitle: "Are you sure to submit this dataset for retraining?",
        deleteConfirm: "Confirm",
        deleteUndo: "Undo",
        columns: {
            name: "Dataset Name",
            skillName: "Skill",
            datasetStatus: "Status",
            updatedBy: "Trained by",
            createdOn: "Creation Date",
            updatedOn: "Last Retraining Date",
        },
        status: {
            ready: "Ready",
            sent: "Sent",
            created: "Created",
            training: "Training",
            trained: "Trained",
            deployed: "Deployed",
            error: "Error"
        },
        filters: {
            filter: "Applied Filters",
            resetFilters: "Restart search",
            skillName: "AI Skills",
            datasetName: "Dataset Name",
            retrainingStatus: "Retraining Status",
        }
    },

    knowledgeFavourites: {
        defaultTitle: "My favourites",
        searchBox: "Search...",
        card: {
            views: "Views",
            actions: {
                remove: "Remove from favourites",
                view: "View"
            }
        }
    },

    knowledgeDocuments: {
        title: 'Documents',
        emptyDocumentList: 'No documents',
        filters: {
            resetFilters: 'Restart Search',
            user: 'User',
            fileName: 'File Name',
            documentStatus: 'Document Status',
            indexed: 'Indexed',
            notIndexed: 'Not indexed',
            loadingDate: 'Loading Date',
            from: 'From',
            to: 'To'
        },
        deleteModal: {
            title: 'Delete Documents',
            singleText: 'Are you sure you want to delete the file?',
            pluralText: 'Are you sure you want to delete the selected items?',
            confirm: 'Yes, delete',
            cancel: 'No, go back',
        },
        actions: {
            delete: 'Delete',
            download: 'Download',
            view: 'View',
            viewTags: 'View Tags',
            addToFavourites: 'Add to favourites',
            removeFromFavourites: 'Remove from favourites',
        },
        delete: {
            error: 'An error occurred while eliminating the document.',
            success: 'Document eliminated with success.'
        },
        tagsPanel: {
            tagHeader: 'Tag',
            elementHeader: 'Clauses',
            nothingToShow: 'No items extracted'
        },
        columns: {
            fileName: 'Name',
            dataImport: 'Created at',
            importedBy: 'Created by',
            indexingStatus: 'Status'
        },
        deleteElementModal: {
            title: 'Delete',
            text: 'Are you sure you want delete?',
            confirm: 'Yes, delete',
            cancel: 'No, go back',
        }

    },
    docAnalysisStartModal: {
        analysisName: 'Analysis name',
        name: 'Name',
        createdBy: 'Created by',
        createdOn: 'Created on',
        secondPageHeader: 'Select the files (8 max) from which you want to extract the formal aspects.',
        secondPageHeaderFirstPart: 'Select the files (',
        secondPageHeaderSecondPart: ' max) from which you want to extract the formal aspects.',
        secondPageHeaderSecondPartToc: ' max) of your doc lab that you want to compare with the reference ToC',
        secondPageHeaderSecondPartS_C: ' max) you want to submit for Search and Compare analysis',
        selectedNumber: 'Selected: ',
        thirdPageAnalisys: 'We are analyzing your documents, the operation will take a few minutes',
        end: 'OK',
        errorLoading: 'An error occurred while retrieving the resource',
        noConfigs: 'No models',
        notPermissions: "Access denied for user",
        errorAnalysis: "An error occurred while starting the analysis. Wait a few minutes and try again",
        secondPageTitle: 'Select one or more files to compare',
        qualityCheckModal: {
            firstPageTitle: 'Select a model for Quality Check',
            firstPageSubHeader: 'Paragraph length measured as \'number of pages\', net of images and tables',
        },
        tocStartModal: {
            firstPageTitle: 'Select a model for ToC verification',
        },
        glossaryCheck: {
            firstPageTitle: 'Glossary Check',
            firstPageSubHeader: 'Upload the reference glossary',
            secondPageSubHeader: 'Select the documents on which you want to check the glossary',
            buttonForUploadFile: 'Upload',
            firstPhrase: 'Instructions for a correct compilation of the file',
            secondPhrase: 'The file must be Excel format',
            thirdPhrase: 'The structure must have the terms in the first column, the related terms separated by commas in the second column.',
            searchDocument: 'Search for document',
            teamsImage: 'glossaryCheckInfoEn',
            excelLimit: 'Due to the rows limit in Excel, the number of results for each term, depends on the the rows number of the glossary and the files selected.',
            maxNumError1: 'You selected more than ',
            maxNumError2: ' files.'
        },
        searchAndCompare: {
            firstPageTitle: 'Search and Compare',
            fileTemplate: 'Select the file to use as a search reference',
            pastedTextPlaceholder: 'Paste the text to execute the search',
            or: 'Or',
            noSelectAndNext: 'Continue without select any document',
            text4SearchAndCompare: 'Enter the text you want to search and continue to select the documents on which you want to search and compare'
        }
    },

    formalAspectTresholds: {
        labelFrom: 'From',
        labelTo: 'To',
        fieldRequired: 'Thresholds is required',
        columnTresholds: 'Thresholds',
        tresholds: 'Error during compiling thresholds',
        addLabel: 'Add Label',
        duplicateLabel: 'Duplicate Labels'
    },

    // MODALS
    uploadFileModal: {
        title: 'Upload',
        titleTag: 'Tag the files',
        titleMetadata: 'Tag the documents',
        verificationResultTitle: 'Outcome of metadata verification',
        updateDependencies: 'Update Dependencies',
        saveMetadata: 'Save Metadata',
        noFilesInTheList: "There aren't files in the list",
        endMessage: 'We are analyzing your documents. You will soon find out the result!',
        subtitle: 'The upload of so many files can take a long time. We suggest to open the EDI application in a new window to prevent a data loss.',
        [UploadErrors.LengthOutOfRange]: 'Minimum file size not allowed',
        [UploadErrors.NameInvalid]: 'Invalid file name',
        [UploadErrors.MimeTypeNotAllowed]: 'File type not allowed',
        [UploadErrors.InvalidFileSignature]: 'File type check failed',
        [UploadErrors.TooLarge]: "File size exceeds limit (100MB)",
        uploadGenericError: 'Error while uploading file',
        uploadConflictError: 'File already exists',
        uploadUnprocessableError: 'Archive capacity limit reached.',
        uploadInhibitionError: "You are uploading files to a folder that is not at the last level",
        uploadUnauthorizedError: "User can't upload file",
        invalidExcel: "The file is not formatted correctly. Follow instructions above and try again.",
        uploadingText: 'Uploading file',        
        requiredField: '* Required field',
        of: 'of',
        uploadedText: 'processed files',
        picker: {
            fileAlreadyUploaded: "File already uploaded",
            fileTypeNotAllowed: "File type not allowed",
            info: "File types allowed: ",
            disclaimer: 'If you close this window, the upload process may be interrupted.'
        },
        dropzone: {
            msg1: "Drop a file here",
            msg2: "or",
            button: 'Explore'
        },
        footer: {
            abort: 'Abort',
            confirm: 'Continue',
            end: 'Got it',
            back: 'Back'
        },
        capacity: {
            used: 'Used Space : ',
            total: 'Total Space : ',
            free: 'Free Space : ',
        },
        detailsList: {
            documentType: 'Select the document type',
            documentName: 'Document name',
            documentTag: 'Document Tag',
        },
        columns: {
            date: 'Date',
            source: 'Source',
            country: 'Country'
        }
    },

    docVerifyPages: {
        messageLoading: 'A metadata verification operation is underway. The result will be available soon.',
        message: 'The verification ended. Press Next to proceed.',
        errorMessage: 'Something went wrong, close the page and try again.',
        nextButton: 'Next',
        backButton: 'Back',
        close: 'Close',
        error: 'Error: ',
        tableTitle1: 'The following files will not be loaded in the archive because a document with the same attributes already exists. To access the documents that you not authorized, refer to the archive owner. Click Close to complete the operation.',
        tableTitle2: 'A document with the same attributes already exists. Metadata changes will not be applied. If you are not authorized to access, please contact the archive owner.',
        tableTitle3: 'For the following files there is already a document with the same attributes.',
        tableTitle4: 'For the following files there is already a document with the same attributes. Click next if you still want to proceed with the upload.',
        tableTitle5: 'A document with the same attributes already exists.',
        tableTitle6: 'A document with the same attributes already exists. Click next if you still want to proceed with the change.',
        columns: {
            nameDoc: 'Document Name',
            NamePrevDoc: 'Document already in the archive',
            Attribute1: 'Attribute 1',
            Attribute2: 'Attribute 2',
            Attribute3: 'Attribute 3'
        }
    },

    createPortfolioModal: {
        pageTitle: "Create a portfolio",
        namePlaceholder: "Type here your portfolio's name",
        nameNotAvailable: "Name not available",
        creatingPortfolio: "Creating portfolio..."
    },

    createArchiveModal: {
        stopCreating: "Do you want stop creating this Doc Lab?",
        firstStepTitle: "Create a Doc Lab",
        secondStepTitle: "Add members to ",
        namePlaceholder: "Type here your Doc Lab's name",
        descriptionPlaceholder: "Type here your Doc Lab's description",
        nameNotAvailable: "Name not available",
        creatingArchive: "Creating archive...",
        ofArchive: "the archive"
    },

    createWorkFlowModal: {
        creatingWorkflow: "Creating workflow..."
    },

    membersPageForCreateArchive: {
        description1: "Start typing a name to add to your Doc Lab.",
        description2: "You can add Eni people (*@eni.com or *@external.eni.com) who already have EDI on their Teams.",
        minLengthAlert: "Type at least three characters",
        noResults: "No people match the specified keyword"
    },

    membersPageForAddArchiveMembers: {
        description1: "Start typing a name to add to your Doc Lab.",
        description2: "You can add Eni people (*@eni.com or *@external.eni.com) who already have EDI on their Teams.",
        minLengthAlert: "Type at least three characters",
        noResults: "No people match the specified keyword",
        allowChildFolders: "Enable to subfolders"
    },

    membersPageForAddKnowledgeLabMembers: {
        description1: "Start typing a name to add to your Knowledge Lab.",
        description2: "You can add Eni people (*@eni.com or *@external.eni.com) who already have EDI on their Teams.",
        minLengthAlert: "Type at least three characters",
        noResults: "No people match the specified keyword",
    },

    membersPageForAddFolderMembers: {
        description1: "Start typing a name to add to your Doc Lab.",
        description2: "You can add Eni people (*@eni.com or *@external.eni.com) who already have access on this archive.",
        minLengthAlert: "Type at least three characters",
        noResults: "No people match the specified keyword",
        allowChildFolders: "Enable to subfolders"
    },

    membersPageForAddPortfolioMembers: {
        description1: "Start typing a name to add to your Portfolio.",
        description2: "You can add Eni people (*@eni.com or *@external.eni.com) who already have EDI on their Teams.",
        minLengthAlert: "Type at least three characters",
        noResults: "No people match the specified keyword"
    },

    //recuperati da teams, TODO: change with deleteItemModal
    deleteModal: {
        title: 'Delete?',
        messageFile: 'Are you sure you want to delete the file?',
        messageFolder: 'Are you sure you want to delete the folder?',
        messageItems: 'Are you sure you want to delete the selected items?',
        ofFolder: 'of the folder',
        ofFile: 'of the file',
        ofItems: 'of the items',
        notPermissions: "Access denied for user",
        operationFolder: "Folder delete operation",
        operationFile: "File delete operation",
        operationMultiItems: "Items delete operation",
        successMessageFolder: "Folder successfully deleted",
        successMessageFile: "File successfully deleted",
        successMessageItems: "Items successfully deleted"
    },

    navigationModal: {
        copyTitle: 'Copy to',
        moveTitle: 'Move to',
        copyMessage: 'Click on the folder where you would like to copy the selected items or select an other archive. Only documents you have access to will be copied, together with tags and added metadata. If the destination archive is different from the source archive, files will be re-indexed.',
        moveMessage: 'Click on the folder where you would like to move the selected items or select an other archive. Only documents you have access to will be moved, together with tags and added metadata. If the destination archive is different from the source archive, files will be re-indexed.',
        search: 'Search folders',
        secondaryAction: 'Cancel',
        primaryCopyAction: 'Copy here',
        primaryMoveAction: 'Move here',
        copyOperation: "Copy items operation",
        moveOperation: "Move items operation",
        successMoveOperation: "Move items successfully completed",
        successCopyOperation: "Copy items successfully completed",
        conflictError: "Items with the same name already exist in the destionation folder",
        searchFolders: 'Search for folders',
        searchDoclab: 'Search for DocLab',
        depthExceedError: 'Maximum element depth limit (5) exceeded',
        sizeArchiveExceedError: 'Archive size exceeds target quota',
        moveFolderInSubfolderError: 'The destination folder is a subfolder of the source folder',
        errorMessage: 'The destination folder is not at the last level',
        folderError: 'Folder not found',
        columns: {
            name: "Name",
            createdBy: "Created by",
            createdOn: "Created on",
        },
    },

    deleteArchiveModal: {
        operationName: 'DeleteArchive',
        title: 'Delete Archive?',
        subtext: 'Are you sure you want to delete this archive? All files contained and associated data will be deleted',
        buttonConfirm: 'Yes',
        buttonCancel: 'No',
        notPermissions: "Access denied for user",
        successOperation: "Archive successfully deleted",
        errorCode403: 'Insufficient priviledges',
        errorCode404: 'Archive not found',
        
    },

    downloadFilesModal: {
        operationName: 'DownloadFiles',
        title: 'Download selected items?',
        titleMonoDownload:'Download selected item?',
        subtext: 'Download of multiple files and/or folders can take a long time. It\'s suggested to open EDI application in a new window to prevent data loss',
        subtextMultiploWarning: 'Download of multiple files and/or folders can take a long time. It\'s suggested to open EDI application in a new window to prevent data loss.\n Downloading one or multiple files with an approval status of To Approve or Rejected',
        subTextDownloadMono: ' Downloading an unapproved file',
        buttonConfirm: 'Continue',
        buttonCancel: 'Cancel',
        downloading: 'Downloading...',
        longTime: 'This could take a while. Don\'t close this window.'
    },

    moveToRecycleBinModal: {
        title: 'Move to Recycle Bin?',
        messageFile: 'Are you sure you want to move the file to the recycle bin?',
        messageItems: 'Are you sure you want to move the selected items to the recycle bin?',
        ofFile: 'of the file',
        ofItems: 'of the items',
        notPermissions: "Access denied for user",
        operationFile: "File move to recycle bin operation",
        operationMultiItems: "Items move to recycle bin operation",
        successMessageFile: "File successfully moved to recycle bin",
        successMessageItems: "Items successfully moved to recycle bin: "
    },

    restoreFromRecycleBinModal: {
        title: 'Restore From The Recycle Bin?',
        messageFile: 'Are you sure you want to restore the file from the recycle bin?',
        messageItems: 'Are you sure you want to restore the selected items from the recycle bin?',
        ofFile: 'of the file',
        ofItems: 'of the items',
        notPermissions: "Access denied for user",
        operationFile: "File move restore from the recycle bin operation",
        operationMultiItems: "Items restore from the recycle bin operation",
        successMessageFile: "File successfully restore from the recycle bin",
        successMessageItems: "Items successfully restore from the recycle bin: ",
        fileAlreadyExists: "An item with the same name already exists in the restore path",
        filesAlreadyExists: "One of the selected items already exists in the restore path with the same name",
        verificationResultTitle: 'The following files will not be restored',
        verificationResultTitleWithErrorRules: 'The following files do not comply with the uniqueness rules but will still be restored.',
        errorMultipleFiles: "Items with the same name already exist in the destionation folder"
    },

    deleteDocAnalysisModal: {
        title: 'Delete?',
        message: 'Are you sure you want to delete the analysis?',
        notPermissions: "Access denied for user",
        operation: "Analysis delete operation",
        successMessage: "Analysis successfully deleted",
        notFound: "Analysis Not Found",
        Unprocessable: "Analysis status is Running"
    },

    createFolderModal: {
        namePlaceholder: "Enter your folder name",
        creatingFolder: "Creating folder...",
        createNewFolder: "Create new folder",
        ofFolder: "the folder",
        alreadyExists: "Folder name already exists",
        notFound: "Parent folder not found",
        notPermissions: "Access denied for user",
        excessiveDepth: "Folder tree greater than 4",
        message: 'Folder creation "',
        successMessage: 'Completed successfully',
    },

    listDocAnalysis: {
        inAnalysis: "Analyzing",
        ToBeValidated: "To be validated",
        Validated: "Validated",
        Completed: "Completed",
        Error: "Error",
        notPermissions: "You don't have permission to access the resource",
        column: {
            name: 'Analysis name',
            type: 'Type',
            createdOn: 'Creation date',
            startedBy: 'Started by',
            lastUpdatedOn: 'Last update date',
            validatedBy: 'Validated by',
            status: 'Status'
        },
        multiple: "Cross archives",
    },

    renameItemModal: {
        placeholder: 'Insert a new name',
        rename: 'Rename',
        cancel: 'Cancel',
        save: 'Save',
        errorCode403: 'Insufficient priviledges',
        errorCode404: 'Item not found',
        errorCode409: 'Name already taken',
        operation: 'Renaming operation',
        successMessage: 'Completed successfully'
    },

    renameArchiveModal: {
        placeholder: 'Insert a new name',
        title: 'Rename Archive',
        label: "Name",
        cancel: 'Cancel',
        save: 'Save',
        nameNotAvailable: "Name already in use",
        errorCode403: 'Insufficient priviledges',
        errorCode404: 'Item not found',
        errorCode409: 'Name already taken',
    },

    renamePortfolioModal: {
        placeholder: 'Insert a new name',
        title: 'Rename Portfolio',
        label: "Name",
        cancel: 'Cancel',
        save: 'Save',
        nameNotAvailable: "Name already in use",
        errorCode403: 'Insufficient priviledges',
        errorCode404: 'Item not found',
        errorCode409: 'Name already taken',
    },

    copyLinkModal: {
        title: {
            [ItemToCopy.DeepLink]: "Get link",
            [ItemToCopy.DocumentId]: "Get ID Document"
        },
        action: {
            [ItemToCopy.DeepLink]: "Copy link",
            [ItemToCopy.DocumentId]: "Copy"
        }
    },
    documentTranslation: {
        title: 'Document Translation',
        subtitle: 'Please, select the language for the document translation.',
        selectLanguages: 'Select a language',
        English: 'English',
        Italian: 'Italian',
        French: 'French',
        Spanish: 'Spanish',
        Portuguese: 'Portuguese',
        German: 'German',
        Greek: 'Greek',
        Chinese: 'Chinese',
        Russian: 'Russian',
        Polish: 'Polish',
        Romanian: 'Romanian',
        Czech: 'Czech',
        Slovak: 'Slovak',
        Hungarian: 'Hungarian',
        Turkish: 'Turkish',
        Swedish: 'Swedish',
        Vietnamese: 'Vietnamese',
        sentenceOne: 'Please wait.',
        sentenceTwo: 'We are translating your document.',
        sentenceThree: 'The download will start in a while.',
    },
    addMembersToArchive: {
        updateMembership: "Update membership of ",
    },

    addMembersToFolders: {
        title: 'Manage Permission',
        addMembers: 'Add members'
    },

    downloadNotification: {
        downloading: 'Downloading...',
        downloaded: 'Downloaded',
        error: 'Couldn\'t download'
    },
    // END MODALS


    // TABS
    archiveMembersSettings: {
        searchForMembers: "Search for members",
        addMembers: "Add Members"
    },

    tagPanel: {
        dialogMessage: "The changes will not be saved. Proceed with closing?",
        changeTabMessage: "The changes will not be saved. Do you want to continue?",
        verificationResultTitle: 'Outcome of metadata verification',
        keys: {
            Languages: "Languages",
            Keyphrases: "Keyphrases",
            FormalAspects: "Formal aspects",
            Organizations: "Organizations",
            Locations: "Locations",
            DateCreated: "Creation Date",
            ApprovalStatus: "Approval Status",
            AlreadyApproved: "Already Approved",
        },
        tooltipText: {
            Languages: "EDI is able to detect up to 3 most relevant languages in your doc.",
            Keyphrases: 'EDI is able to retrieve the 15 most important phrases or words within your document, to help you in its search and understanding.',
            FormalAspects: 'EDI is able to spot within your document how many pages, paragraphs, words, table and images are present. The AI algorithm is based upon assumptions (e.g.: well-formatted tables and images; paragraphs defines as "header" in word or as "bookmark" in pdf"), that may be once overcome, leading to feature improvements within the evolution of EDI product.',
            Organizations: 'EDI is able to spot "organization" entities within your document, showing the 5 most relevant ones.',
            Locations: 'EDI is able to spot "location" entities within your document, showing the 5 most relevant ones.'
        },
        values: {
            TableCount: 'Tables',
            PageCount: 'Pages',
            ParagraphCount: 'Paragraphs',
            WordCount: 'Words',
            ImageCount: 'Images',
            FileFormatExtension: 'File format extension'
        },
        title: 'Tags',
        forbiddenError: "You don't have permission to access the resource",
        inProgressError: "Still no related tags: we are analyzing your documents, you will soon find out the results!",
        metadata: {
            requiredField: "Required field",
            pivotTag: "Tag",
            info: "Info",
            pivotMetadata: "Additional metadata",
            success: "Save successful",
            confirmSave: "Are you sure you want to save the metadata?",
            placeHolderSearch: "Enter keyword",
            errorSaving: "Error during the save operation",
            tryAgain: "Try again?",
            other: "Other ",
            items: " items found",
            noItems: "There are no other elements",
            moreChar: "Type at least two characters ",
            errorApi: "Error while retrieving additional metadata",
            userNotFound: "Error: User not found",
            fileNotFound: "Error: File not found",
            noFileId: "File Id not found: ",
            noUserAccess: "You don't have access to this files: ",
            fileJsonSchemaError: "This file does not have a suitable JsonSchema: ",
            errorMainProperty: "The following main properties are not valid: ",
            sameFile: "It isn't possible to reference the same file: "
        },
        formalAspectWithoutExtension: 'We cannot calculate this parameter for this format file',
        updateDependencies: 'Update Dependencies'
    },

    archiveContent: {
        errorLoading: 'Error while loading folder content',

        columns: {
            name: "Name",
            createdby: "Created by",
            createdon: "Created on",
            indexStatus: "Status",
            approvalStatus: "Approval Status",
            alreadyApproved: "Already Approved"
        },
        indexStatus: {
            ToBeIndexed: "To Be Indexed",
            Indexed: "Indexed",
            undefined: ""
        },
        approvalStatus:{
            NA: "NA",
            ToBeApproved: "To Be Approved",
            Approved: "Approved",
            Rejected: "Rejected",
            Migrated: "Migrated"
        },
        metadataWarning: "Additional metadata required"
    },


    docAnalysis: {
        [AnalysisTypeId[0]]: 'Quality Check',
        [AnalysisTypeId[1]]: 'Table Of Content',
        [AnalysisTypeId[2]]: 'Glossary Check',
        commands: {
            delete: 'Delete',
            downloadReport: 'Download Report',
            createLink: 'Create link'
        },
        qualityCheck: {
            title: 'Quality Check',
            description: 'Check the quality of your docs, based on a reference model you previously set.'
        },
        tableOfContent: {
            title: 'Table of content',
            description: 'This is the description of the doc analysis called "Table of content". Click on this card to carry out the desired analysis',
            addTemplate: 'Add Toc'
        },
        glossaryCheck: {
            title: 'Glossary check',
            description: 'Check the glossary of your documents by entering the reference glossary'
        },
        searchAndCompare: {
            title: 'Search and Compare',
            description: 'This is the description of the doc analysis called "Search and Compare". Click on this card to carry out the desired analysis',
        },
        tocVerification: {
            title: 'Toc Verification',
            description: 'This is the description of the doc analysis called "Toc Verification". Click on this card to carry out the desired analysis'
        },
        partsOfTest: {
            title: 'Comparison between parts of text',
            description: 'This is the description of the doc analysis called "Comparison between parts of text". Click on this card to carry out the desired analysis'
        },
        textVsGlossary: {
            title: 'Text vs glossary comparison',
            description: 'This is the description of the doc analysis called "Text vs glossary comparison". Click on this card to carry out the desired analysis'
        },
        addStandard: 'Add model',
        namePlaceholder: `Add model (max ${Constants.standardItemNameMaxLength} characters)`,
        label: 'Label',
        labels: {
            types: {
                [Labels.Negligible]: 'Negligible',
                [Labels.Small]: 'Small',
                [Labels.Medium]: 'Medium',
                [Labels.Large]: 'Large'
            },
            from: 'From',
            to: 'To',
        },
        operations: {
            addOperation: {
                name: 'Add model',
                errors: {
                    errorCode403: '',
                    errorCode404: '',
                    errorCode409: '',
                    nameNotAvailable: 'Name already exists'
                },
                successMessage: 'Model created successfully'
            },
            deleteOperation: {
                name: 'Model cancellation',
                errors: {
                    errorCode403: 'Operation not allowed'
                },
                successMessage: 'Model successfully canceled'
            }
        },
        forbidden: "You do not have permission to view the analysis results",

    },

    archiveSettings: {
        title: 'Doc Lab Settings',
        tagging: 'Tagging preferences',
        whitelistTitle: 'WhiteList keywords',
        commonDescription: 'Help EDI extract the most relevant words for your DocLab.',
        whitelistDescription: 'The relevant keywords are those that will be presented as first in the search results and in the document tag',
        blacklistTitle: 'BlackList keywords',
        blacklistDescription: 'The hidden keywords are those that will not be presented in the document tag',
        maxLenghtKeyword: 'Keyword must be between 3 and 50 characters',
        maxLenghtList: 'Maximum number of keywords reached',
        keywordAlreadyExist: 'Keyword already exists',
        keywordAlreadyExistBlacklist: 'Keyword already exist in ',
        errorCode404: "Configuration for the Archive not found",
        removeButton: 'Are you sure you want to delete the keyword ?',
        removeAllButton: 'Are you sure you want to delete all keywords ?',
        placeHolderSearch: 'Enter keyword',
        clearAllButton: 'Clear All',
        Name: 'Name',
        NoItem: 'No keywords found',
        EmptyItem: 'Cannot add empty string',
        taggingDescription: 'Set up families of interest to tell your archive members to tag files in just a few clicks',
        status: 'Status',
        family: 'Family',
        wordNumber: 'Word Count',
        tableNumber: 'Table Count',
        pageNumber: 'Page Count',
        paragraphNumber: 'Paragraph Count',
        imageNumber: 'Image Count',
        formalAspect: 'Formal Aspects',
        date: 'Date',
        location: 'Location',
        organization: 'Organization',
        language: 'Language',
        keyphrases: 'Keyphrases',
        enabled: 'has been enabled',
        disabled: 'has been disabled',
        externalLov: 'Added metadata: editable List of Values',
        otherSettings: 'Other settings',
        logChangeMetadata: 'Metadata changes log',
        crossArchiveSearch: 'Cross archive search',
        crossFolderSearch: 'Cross folder search',
        documentTranslator: 'Document translation',
        defaultAdditionalMetadata: 'View "Additional Metadata" by Default in the Tags panel',
        reportFieldsWithDateFormat: 'Set the date fields of the "Export archive" and "Export tag" reports with the date format'
    },

    docAnalysisSettings: {
        title: 'Doc Analysis Settings',
        preferences: 'Preferences',

        qualityCheck: {
            addStandard: 'Add Model',
            name: 'Name',
            paragraphUoM: 'Paragraph length measured as \'number of pages\', net of images and tables',
            createdOn: 'Created on',
            createdBy: 'Created by',

            deleteModal: {
                title: 'Are you sure you want to delete the model?',
                description: ''
            }
        },
        tableOfContent: {
            addTemplate: 'Add ToC',
            name: 'Name',
            createdOn: 'Created on',
            createdBy: 'Created by',
            deleteActionName: 'ToC cancellation',
            deleteSuccessMessage: 'ToC successfully canceled',
            saveActionName: 'ToC creation',
            saveSuccessMessage: 'ToC successfully created',
            saveAction403: "You don't have permission to create a valid ToC template",
            saveAction422: "Reached the maximum depth level for element (max. 4)",
            deleteAction403: 'You do not have the permissions to perform the operation',
            deleteModal: {
                title: 'Are you sure you want to delete the template?',
                description: ''
            }
        }
    },
    workflows: {
        addNew: 'New Workflow',
        column: {
            name: 'Workflow Name',
            status: 'Status',
            activity: 'Project',
            documentName: 'Document Name',
            createdOn: 'Created on',
            createdBy: 'Created by',
            assignedTo: 'Assigned to',
            members: 'Involved users',
        },
        confirmEliminate: 'Yes, delete',
        undoEliminate: 'No, go back'
    },
    workflowDetails: {
        info: {
            title: 'Info',
            status: {
                [WorkFlowRelativeStatus.Ongoing]: 'Workflow in progress and I have been involved in the steps started up to now, but I don\'t have to do any operations or Workflow in progress and I am the workflow requestor',
                [WorkFlowRelativeStatus.Approved]: 'Workflow completed successfully',
                [WorkFlowRelativeStatus.Refused]: 'Workflow ended with a refusal',
                [WorkFlowRelativeStatus.ToMe]: 'Workflow with request for action in charge of me',
            },
            secondLineStatus: {
                [WorkFlowRelativeStatus.Ongoing]: 'Click on the attached document to view content and/or make changes',
                [WorkFlowRelativeStatus.Approved]: 'Click on the attached document to view content',
                [WorkFlowRelativeStatus.Refused]: 'Click on the attached document to view content',
                [WorkFlowRelativeStatus.ToMe]: 'Click on the attached document to view content and/or make changes',
            },
            activityName: 'Project: ',
            document: 'Attached document: ',
            createdBy: 'Applicant: '
        },
        action: {
            title: 'Action requested'
        },
        iter: {
            title: 'Approval process',
            column: {
                step: 'Step',
                status: 'Status',
                rules: 'Rules',
                actionHistory: 'Actions History',
                comments: 'Comments',
            },
            userActionWithDate: 'on',
            userCommented: ' commented: '
        }
    },
    workflowList: {
        column: {
            name: 'Workflow name',
            status: 'Status',
            activity: 'Project',
            documentName: 'Document name',
            createdOn: 'Creation date',
            createdBy: 'Requested by',
            assignedTo: 'Assigned to',
            members: 'Members'
        },
        deleteTitle: 'Delete?',
        deleteMessage: 'Are you sure you want to delete the workflow?',
        status: {
            [WorkFlowRelativeStatus.Ongoing]: 'Ongoing',
            [WorkFlowRelativeStatus.Approved]: 'Approved',
            [WorkFlowRelativeStatus.Refused]: 'Refused',
            [WorkFlowRelativeStatus.ToMe]: 'Assigned to me',
        },
        receivedRequests: 'Received workflows',
        sentRequests: 'Created workflows',
    },

    stepStatus: {
        title: 'STEP #{{order}}:',
        userAction: '',
        status: {
            [WorkFlowStepRelativeStatus.Idle]: '',
            [WorkFlowStepRelativeStatus.ToBeApproved]: 'Pending',
            [WorkFlowStepRelativeStatus.Approved]: 'Approved',
            [WorkFlowStepRelativeStatus.Refused]: 'Refused',
            [WorkFlowStepRelativeStatus.ToMe]: 'Pending',
        },
        approverStatus: {
            [WorkFlowApproverStatus.ToBeApproved]: 'Pending',
            [WorkFlowApproverStatus.Approved]: 'Approved',
            [WorkFlowApproverStatus.Refused]: 'Refused',
        },
        type: {
            [WorkFlowStepTypes.OnlyOneApprover]: 'Only one approver',
            [WorkFlowStepTypes.OnlyOneSignedApprover]: 'Only one approver with signature',
            [WorkFlowStepTypes.EveryoneMustApprove]: 'Everyone must approve',
        }
    },

    activityLabUsersSettings: {
        searchBox: 'Search member',
        addMembers: 'Add members',
        errorRemoveMembers: 'Member cannot be removed as it is present in at least one portfolio project.',
        updateMembership: "Update membership of ",
        createAtLeastOneTemplate: 'Create at least one template for this portfolio',
        templateSettings: {
            title: 'Make the following types of projects visible:'
        }
    },

    templateActivity: {
        columns: {
            name: "Project template",
            status: "Status",
            createdBy: "Created by",
            createdOn: "Creation date"
        },
        addTemplate: "Add project template",
        title: "Project Lab Settings",
        type: "Type of project"
    },
    // END TABS

    // ACTIONS
    toolbar: {
        doclab: {
            docAnalysis: 'Doc Analysis',
            dashboard: 'Dashboard',
            files: 'Files',
            search: 'Search',
            selected: 'selected'
        },
        activityLab: {
            board: 'Board',
            list: 'List',
            workflow: 'Workflow',
            graphs: 'Graphs'
        }
    },

    dashboard: {
        progressBar: {
            title: "Available space",
            description: " free of "
        },
        tagCloudChart: {
            title: "Keyphrases Wordcloud",
        },
        worldMapChart: {
            title: "Documents Locations",
        },
        fileformats: {
            title: "Documents Formats",
        },
        languages: {
            title: "Documents languages",
            countMessage: "The sum of the values does not coincide with the total number of documents visible in the archive"
        },
        formalAspects: {
            title: "Formal Aspects",
        },
        fileCount: {
            description: "There are ",
            descriptionEnd: " documents"
        },
        fileCountNull: {
            description: "There aren't documents",
        },
        fileCountOne: {
            description: "There is ",
            descriptionEnd: " document"
        },
        formalAspectsError: "Not Applicable",
        portfolio: {
            activitiesStatus: {
                title: 'Project Status',
            },
            activitiesPriorities: {
                title: 'Project Priorities',
            },
            activitiesLeaders: {
                title: 'Projects for Project Leaders',
            },
            workflowStatus: {
                title: 'Workflow Status',
            },
            workflowUsers: {
                title: 'Workflow for Users',
            }
        },
        errors: {
            errorCode403: "You don’t have necessary permissions to see this dashboard",
            errorCode422: "Disabled in settings"
        },
        empty: "There is no data"
    },


    notificationPanel: {
        failure: 'Operation failed',
        emptyPlaceHolder: 'No notifications',
        title: 'Notifications'
    },

    exportArchiveModal: {
        noReport: 'No report available',
        inError: 'In error ',
        exporting: "A report is already being generated",
        stopCreating: "Do you want to stop the operation?",
        titleHomepage: "Export Archive",
        date: "Last Report Created On: ",
        note: "Note: Reports older than one month will be deleted",
        generateExport: "Generate Export Archive",
        fieldName: "Field Name",
        fieldTitle: "Title",
        selectAll: "Select / Deselect all",
        operationInProgress: "Operation in progress. The report will be ready in a few minutes.",
        operationFailed: "Error while creating the report.",
        noReportAvaiable: "No reports have been configured for this archive",
        generateReportAction: "Generate report",
        titleMetadataWork: "Metadata selection",
        generateReportActionImport: "Upload",
        generateReportActionExport: "Export",
        managementReportCentralFileName: "Institutional report",
        createScadenzaReport: {
            title: "Generate report",
            text: "Are you sure you want to generate a new report?"
        },
        matadataChanges:{
            subtitleDate:"Choose Data Range To Filter Metadata",
            from: "From",
            to: "To"
        },
        reportName: {
            Scadenze: 'Contracts expiration',
            Metadata: 'Export archive',
            Keyphrases: 'Export tag',
            ENITER: 'Export ENITER',
            Import: 'Import metadata',
            Export: 'Export metadata',
            ManagementReport: 'Directional report',
            FilesMetadataChanges: 'Metadata Changes report'
        },
        folderZipModal: {
            titleExport: 'Select a folder'
        },
        report_export_description: 'Extraction of a list of documents the user has access to in the archive, with details of all additional metadata',
        report_expire_description: 'Extraction of three separate lists containing respectively: i) a list of documents expiring within 2 months and 1 day, ii) a list of documents for which the expiration date is not available in date format (dd/mm/yy), and iii) a list of expired contracts with Active status',
        report_keyphrases_description: 'Tags list of the documents, the user has access to.',
        report_eniter_description: 'Zip file that contains the files selected and the excel with their metadata',
        report_import_metadata_description: 'Import new metadata properties',
        report_export_metadata_description: 'Export custom metadata properties',
        report_export_management_description: 'Generate the directional report',
        report_files_metadata_changes_description: 'Export files metadata changes'
    },

    recycleBinMetadata:{
        title:'Recycle Bin',
        columns:{
            name:"Name",
            deletedon: "Deleted On",
            deletedby: "Deleted By"
        }
    },
    
    importExportMetadata: {
        title: 'Import/Export Metadata',
        titleImportAutoFill: 'Import values automatically populate',
        noReport: 'No file available',
        inError: 'In error ',
        exporting: "A file is already being generated",
        stopCreating: "Do you want to stop the operation?",
        date: "Last File Created On: ",
        note: "Note: Files older than one month will be deleted",
        fieldName: "Field Name",
        fieldTitle: "Title",
        selectAll: "Select / Deselect all",
        operationInProgress: "Operation in progress. The file will be ready in a few minutes.",
        operationFailed: "Error while doing the operation.",
        noReportAvaiable: "No json schema have been configured for this archive",
        generateReportActionImport: "Upload",
        generateReportActionExport: "Export",
        reportName: {
            Import: 'Import metadata',
            Export: 'Export metadata',
            ImportACPV: 'Import ACPV registry',
            ImportDocsTree: 'Import documents tree structure'
        },
        fileName: {
            Import: 'ResultImportMetadata',
            Export: 'ExportMetadata',
            ImportACPV: 'ResultImportACPVRegistry',
            ImportDocsTree: 'ResultImportDocumentsTreeStructure'
        },
        report_import_metadata_description: 'Import new metadata properties',
        report_export_metadata_description: 'Export custom metadata properties',
        report_import_ACPV_description: 'Import ACPV registry',
        report_import_docstree_description: 'Import documents tree structure',
        completedWithErrorMessage: 'Import completed, See the Excel Log tab for the errors.'
    },

    importMetadataModal: {
        titleModal: "Import Metadata",
        titleModalACPV: "Import ACPV",
        titleModalDocsTree: "Import documents tree structure",
        operationInProgress: "Operation in progress. The import will be done in a few minutes.",
        operationFailed: "Error while uploading the file Excel for the import.",
        stopCreating: "Do you want to stop the operation?",
        titleMetadataWork: "Select the metadata you wish to update for the files",
        dialogMetadataToChange:"Some metadata already exists, do you want to proceed with the selection?",
        fieldName: "Field Name",
        confirmUpload: "Confirm Upload",
        subConfirmUpload: "Select 'New Import' to start a new import ACPV registry or select 'Next' to start a new import ACPV registry and choose the fields to override in the documents that are already in the archive",
        newImport: "New Import",
        fieldTitle: "Title"
        },

    archiveContentActions: {
        newFolder: 'New Folder',
        uploadFile: 'Upload here',
        open: 'Open',
        openInBrowser: 'Open in browser',
        translateDocument: 'Translate document',
        createLink: 'Create Link',
        copyDocumentId: 'Copy ID Document',
        viewTag: "View Tags",
        managePermissions: 'Modify Permissions',
        exportArchive: "Export Archive",
        download: 'Download',
        delete: 'Delete',
        rename: 'Rename',
        move: 'Move',
        copy: 'Copy',
        viewWfDetails: 'View WF details',
        downloadWfDetails: 'Download WF details',
        approve: 'Approve',
        reject: 'Reject',
        resubmitApproval: 'Resubmit approval',
        restore: 'Restore',
        moveToRecyclebin: 'Move to Recycle Bin'
    },

    analysisResults: {
        subtitle: 'Reference model',
        saveReport: 'Save report',
        empty: 'Analysis is not present',
        error: 'Error while processing the analysis',
        tooltipText: 'Paragraph length measured as \'number of pages\', net of images and tables',
        qualityCheckResult: {
            length: 'Length',
            words: 'Words',
            images: 'Images',
            tables: 'Tables',
            title: 'Quality Check results'
        },
        tocResult: {
            title: 'ToC results',
            summaryAnalysis: "Summary analysis",
            chapterName: "ToC template chapter name",
            chapterNameFromDocument: "Chapter name from document",
            percentage: "Percentage",
            status: "Status",
            motivation: "Motivation",
            matchElements: "Elements identified",
            noMatchElements: "Unallocated items",
            notAllocated: "Not allocated",
            missing: "Missing",
            warnings: {
                [AnalysisDocWarning.WrongExtension]: "File extension not supported",
                [AnalysisDocWarning.TocNotFound]: "ToC not present in the comparison document",
                [AnalysisDocWarning.InvalidToc]: "Reference toC invalid",
            },
            errors: {
                [AnalysisDocError.Generic]: "An error has occurred",
                [AnalysisDocError.BlobNotFound]: "File not found",
                [AnalysisDocError.Parsing]: "Error while parsing the document",
            }
        },
        searchAndCompareResult: {
            title: 'Search and Compare results',
            analysisName: 'Analysis Name:',
            subTitle: 'You search this section:',
            subTitleFile: 'You search this section of the file',
            more: 'More',
            less: 'Less',
            emptySnippet: 'Empty results',
            searchResults: 'Search Results:'
        }
    },

    createActivity: {
        low: "Low",
        medium: "Medium",
        high: "High",
        title: 'Plan projects',
        activityName: 'Project name',
        activityNamePlaceholder: 'Type a new project name',
        description: 'Description',
        startDate: "Start date",
        selectDay: 'Select a day',
        endDate: 'Due date',
        selectBudget: 'Assign a budget',
        priority: 'Priority',
        toActivityLeader: 'Assign to a Project Leader',
        activityTemplate: 'Project template',
        messageForActivity: "The project leader will receive a notification for the assignment and automatic creation of a Teams",
        analysisAlreadyExist: 'Project name already exist',
        endMessage: 'The project has been created, the team is being created',
        end: 'Ok',
        error: 'An error occurred while creating the project. Wait a few minutes and try again',
        dataPickerStrings: {
            "closeButtonAriaLabel": "Close date picker",
            "dayMarkedAriaLabel": "Checked",
            "days": ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            "goToToday": "Go to today",
            "invalidInputErrorMessage": "Invalid date format",
            "isRequiredErrorMessage": "Required field",
            "monthPickerHeaderAriaLabel": "{0}, change year",
            "shortMonths": ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            "months": ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            "shortDays": ['S', 'M', 'T', 'W', 'T', 'F', 'S']
        },
        leadersPlaceholder: 'Start typing a name',
        minLengthAlert: "Type at least three characters",
        noResults: "No people match the specified keyword",
        creatingActivity: 'Create project...'
    },
    // END ACTIONS

    createActivityTemplate: {
        title: "Set project template",
        editTitle: "Update project template",
        subEditTitle: "Changes will not be applied to the projects already entered",
        stopCreating: "Do you want to stop creating this template?",
        creatingTemplate: "Creating template...",
        updateTemplate: 'Updating template...',
        ofTemplate: " of the creation of the project template",
        namePlaceholder: "Type here your project template's name",
        descriptionPlaceholder: "Type here a description of your project template",
        addPhase: "Add",
        text: "Set the sections of your business and decide whether to automatically create Channels in Teams. Sections allow you to define the phases or activity streams of your project",
        namePhasePlaceholder: "Section name",
        descriptionPhasePlaceholder: "Section description",
        maxLength: 'You have reached the maximum number of ' + DocLabConstants.MAX_PHASES_NUMBER + ' Sections',
        noDescription: 'Empty description',
        phaseNameError: 'Name already exists',
        toogleTooltip: "Use this toggle to set up a channel in Teams named as the corresponding Section",
        columns: {
            settedPhases: "Set sections",
            channelTeams: "Teams Channel"
        },
        nameNotAvailable: "Name not available",
        addNewSection: "- Add new section",
        createTemplate: "Create template",
        createTemplateSuccess: "The template has been created",
        modifyTemplate: "Edit template",
        modifyTemplateSuccess: "The template has been edited"
    },

    activityPhaseStatus: {
        'notStarted': 'Non iniziata',
        'inProgress': 'In corso',
        'done': 'Terminata'
    },

    infoAndStatus: {
        low: "Low",
        medium: "Medium",
        high: "High",
        phases: "STEPS",
        noPhases: "No steps",
        getActivityError: "Error retrieving project data",
        tabInfo: "Information",
        descriptionLabel: "Description",
        descriptionPlaceholder: "Enter a description...",
        typeLabel: "Type of project",
        typePlaceHolder: "Enter project type...",
        priorityLabel: "Priority",
        priorityPlaceHolder: "Enter a priority...",
        startDateLabel: "Start date",
        startDatePlaceholder: "Enter a start date...",
        dueDateLabel: "Due date",
        dueDatePlaceholder: "Enter a due date...",
        budgetPlaceholder: "Enter budget...",
        activityStatusLabel: "Project status",
        activityStatusPlaceholder: "Enter project status...",
        addPhases: "Add steps",
        warning: "The steps on EDI will not be changed!",
        savingError: "Error while saving. Please try again.",
        insertDatePlaceholder: "Select a date ...",
        syncUsers: "Go Teams",
        phaseLabel: "Step",
        status: {
            [ActivityStatus.Planned]: 'Planned',
            [ActivityStatus.OnGoing]: 'On going',
            [ActivityStatus.Blocked]: 'Blocked',
            [ActivityStatus.Closed]: 'Closed',
        }
    },
    importKnowledgeLabDocument: {
        title: 'Import a document',
        confirmButton: 'Confirm',
        cancelButton: 'Cancel',
        selectFiles: 'Select files',
        import: 'Import',
        search: 'Search...',
        overviewDisclaimer: 'If you close this window, the upload process may be interrupted.',
        allowedFiles: 'File types allowed: .pdf.',
        searchBox: 'Search for Terms/Contents',
        uploadFileAlreadyExists: "A file with same name already exists",
        uploadGenericError: 'Error while uploading file',
        uploadConflictError: 'File already exists',
        uploadEntityTooLarge: 'File too large. ',
        source: {
            sharepoint: 'Sharepoint',
            ediArchive: 'Edi Doc Lab',
            local: 'Local Computer'
        },
        choiceInputSource: {
            sharepointSubtitle: 'Select files from Sharepoint',
            localSubtitle: 'Select files from Local Computer',
            ediSubtitle: 'Select files from Edi Doc Lab'
        },
        importTitle: "Import files",
        steps: {
            overviewSharepoint: {
                title: 'Sharepoint',
                import: 'Import',
                selectMessage: 'Select Sharepoint files',
            },
            overviewEdi: {
                title: 'EDI DocLab',
                import: 'Import',
                selectMessage: 'Select Sharepoint files',
            },
            sharepointFileSelector: {
                title: 'Choose File',
                selectMessage: 'Select the document to import'
            },
            ediFileSelector: {
                title: 'Choose File',
                selectMessage: 'Select the document to import'
            },
            localFileSelector: {
                title: 'Local File',
                selectMessage: 'Select the document to import'
            },
            uploadFiles: {
                title: 'Documents upload',
                stopButton: 'Abort',
                picker: {
                    fileTypeNotAllowed: 'File type not allowed'
                },
                uploadedText: 'uploaded files',
                uploadingText: 'Uploading file'
            },
            completed: {
                content: 'We are analyzing your documents. You will soon find out the result!',
                closeButton: 'Got it'
            }
        }
    },
    fileImporterOverview: {

    },

    createWorkflow: {
        checkDeleteWorflow: {
            title: "Do you want to cancel the Workflow request?",
            message: "If you close the window without completing workflow creation, the details you entered will be lost. Do you want to cancel this workflow creation?",
            confirm: "Yes, cancel",
            undo: "No, go back"
        },
        setProperties: {
            title: "Workflow request",
            nameLabel: "Request name",
            namePlaceholder: "Type name...",
            descriptionLabel: "Description",
            descriptionPlaceholder: "Type description...",
            activityLabel: "Select project",
            activityPlaceholder: "Select project..."
        },
        addFile: {
            title: "Approval ",
            selectDoc: "Select the document to workflow",
            tooltipText: 'Warning! Selecting a file with a format other than PDF does not allow you to choose the "Approval with digital signature" rule'
        },
        addSteps: {
            title: "Definition of Workflow ",
            namePlaceholder: "Enter name...",
            nameLabel: "Step name",
            dropdownLabel: "Select the approval rule",
            dropdownPlaceholder: "Select...",
            peoplePlaceholder: 'Start typing a name',
            approvers: "Step approvers",
            noResult: "No result",
            addStep: "Add step",
            minLengthAlert: "Type at least three characters",
            maxOneApprover: "Max one approver",
            errorType: "The selected rule can only be used for pdf files"

        },
        summary: {
            title: 'Summary'
        },
        creating: {
            title: 'Creating workflow'
        },
        errorRetrievingActivities: "Error while retrieving projects.",
        noActivities: "There are no projects associated with the current portfolio",
        workflowTypes: {
            onlyOneApprover: "At least one must approve",
            everyoneMustApprove: "Everyone must approve",
            onlyOneSignedApprover: "Approve with digital sign"
        },
        columns: {
            name: "Name",
            createdOn: "Created on",
            createdBy: "Created by"
        }
    },

    sharepointLink: {
        propertyName: 'Property name',
        link: 'Link',
        accessToList: 'Access to the list',
        noProperties: 'No properties found',
        buttonLabel: 'Refresh lov from external sources'
    },

    folderTreeViewer: {
        searchFolders: 'Search folders',
        name: 'Name',
        createdOn: 'Created On',
        createdBy: 'Created By',
        path: 'Path',
        expandAll: 'Expand All',
        expandAllBulk: 'For large amounts of data "Expand all" is disabled',
        collapseAll: 'Collapse All',
        search: 'Search'
    },

    profileManagement: {
        manageUser: 'Manage users',
        viewMember: 'Member view',
        viewProfile: 'Profile view',
        reportDonwload: 'Download user management report',
        search: 'Search members',
        addMember: 'Add member',
        add: 'Add',
        view: 'View members',
        viewAndEdit: 'View and edit members',

        memberProfileView: {
            'profiles': 'User\'s profiles',
            'search': 'Search members'
        },

        profileView: {
            members: 'Members',
            view: 'View',
            viewAndModify: 'View and edit',
            role: 'Role',
            visibility: 'Doc Lab folders visibility',
            profileSearch: 'Search profiles',
            createNewProfile: 'Create new profile',
            duplicateProfile: 'Duplicate profile',
            downloadReportProfile: 'Download report User Management',
            duplicateProfileAlreadyExistError: 'A copy of this profile already exists.',
            duplicateProfileError: 'An error occurred while duplicating the profile.',
            duplicateProfileTimeout: 'Timeout while duplicating the profile.',
            noProfile: 'No profile found',
            successMessage: 'Profile succesfully created!',
            cancelModalTitle: 'Delete profile',
            cancelModalText: "You are deleting the profile. You will no longer be able to associate archive members with that profile, and the profile's association with all currently associated members will be removed. Would you like to continue?",
            editModal: {
                descriptionPlaceholder: 'Description',
                descriptionTitle: 'Description (Optional)',
                nameTitle: 'Profile Name',
                namePlaceholder: 'Name',
                title: 'Edit Profile',
                nameNotAvailable: 'Name not available'
            },
            newProfile: {
                insertProfileName: 'Insert the profile name you want to create',
                selectProfileRole: 'Select Role',
                createNewProfile: 'Create new Profile',
                folderVisibility: 'Folder Visibility',
                folderVisibilityDescription: 'Select folder you want make visibile to the users linked to this profile, instead click on the radio button to select the entire folder tree.',
                userVisibility: 'Assign member to profile',
                userVisibilityDescription: 'Insert a name to add a user as member enabled to view the Doc Lab',
                assignRole: 'Assign new role to the profile',
                nameLabel: 'Name',
                descriptionLabel: 'Description',
                next: 'Next',
                previous: 'Previous',
                cancel: 'Cancel',
                save: 'Save',
                confirm: 'Confirm'
            },
            editFolderModal: {
                primaryTitle: 'View and edit Folders',
                cancel: 'Cancel',
                save: 'Save',
                secondaryTitle: 'Folder visibility',
                description: 'Select the individual folders that you want to make visible to the users associated with this Profile, or click on the radio button to select the entire contents of each individual folder.',
                errorProfileNotFound: 'Profile not found',
                errorParentFolderNotFound: 'Parent folder not found'
            }
        }
    },

    subParagraphModal: {
        subParagraph: 'subparagraph',
        title: "Subparagraph"
    },

    downloadWfDetailsModal: {
        downloadWfDetails: 'Download WF details',
        title: 'Do you want to download the datails of this WorkFlow?',
        getwfError: 'Error: Workflow data could not be retrieved',
        downloadError: 'Error: The requested file could not be generated'
    },

    filesApprovalDocumentModal: {
        titleApproveDocument: 'Approve Document',
        titleApproveDocuments: 'Approve Documents',
        titleRejectDocument: 'Reject Document',
        titleRejectDocuments: 'Reject Documents',
        titleResubmitApprovalDocument: 'Resubmit approval Document',
        titleResubmitApprovalDocuments: 'Resubmit approval Documents',
        rejectionNoteTitle: 'Rejection note',
        emailsRecipientsTitle: 'Emails recipients',
        bodyTextResubmitApprovalDocument: 'Are you sure you want to resubmit approval of this document?',
        bodyTextResubmitApprovalDocuments: 'Are you sure you want to resubmit approval of these documents?',
        bodyTextApproveDocument: 'Are you sure you want to approve this document?',
        bodyTextApproveDocuments: 'Are you sure you want to approve these documents?',
        bodyTextRejectDocument: 'Are you sure you want to reject this document?',
        bodyTextRejectDocuments: 'Are you sure you want to reject these documents?',
        errorLabel: 'Something went wrong, retry',
        placeholderEmailsRecipients: 'email1@eni.com, email2@eni.com'
    },

    workflowDetailsModal: {
        viewWfDetails: 'View WF details',
        title: 'Approval process',
        yes: 'Yes',
        no: 'No',
        wfLabName: 'WorkFlow Lab Name: ',
        wfName: 'WorkFlow Name: ',
        attachedDocuments: 'Attached Documents: ',
        column: {
            step: 'Step',
            iter: 'Iter',
            status: 'Status',
            rules: 'Rules',
            actionHistory: 'Action History',
            comments: 'Comments',
            approvers: 'Approvers',
            attachedDocuments: 'Attached Documents'
        },
        userActionWithDate: 'on',
        userCommented: ' commented: ',
        stepStatus: {
            title: 'STEP #{{order}}:',
            userAction: '',
            status: {
                [WorkFlowStepRelativeStatus.Idle]: '',
                [WorkFlowStepRelativeStatus.ToBeApproved]: 'Pending',
                [WorkFlowStepRelativeStatus.Approved]: 'Approved',
                [WorkFlowStepRelativeStatus.Refused]: 'Refused',
                [WorkFlowStepRelativeStatus.ToMe]: 'Pending',
            },
            approverStatus: {
                [WorkFlowApproverStatus.ToBeApproved]: 'Pending',
                [WorkFlowApproverStatus.Approved]: 'Approved',
                [WorkFlowApproverStatus.Refused]: 'Refused',
            },
            type: {
                [WorkFlowStepTypes.OnlyOneApprover]: 'Only one approver',
                [WorkFlowStepTypes.OnlyOneSignedApprover]: 'Only one approver with signature',
                [WorkFlowStepTypes.EveryoneMustApprove]: 'Everyone must approve',
            }
        },
        error: {
            notFound: 'Workflow information not found',
            notPermissions: "You haven't the permissions for this Workflow",
        }
    },

    // END ACTIONS
    common: {
        yes:"Yes",
        startNewSearchToSeeResults: "Select a filter or insert text to see results here.",
        nameNotAvailable: "Name not available",
        planActivity: 'Plan project',
        columns: {
            date: 'Data-Periodo del report',
            source: 'Fonte del report',
            country: 'Paesi'
        },
        archiveLinkForbiddenOperation: "Link",
        archiveLinkForbiddenMessage: "You don't have permission to access the resource",
        retryFolder: 'Go back to the main folder',
        archiveForbidden: "You don't have access to the archive",
        genericErrorApi: "Something went wrong",
        notFound: "Could not find what you're looking for... May has been deleted or you have no access to it",
        fieldRequired: 'Required field',
        nextButton: 'Next',
        previousButton: 'Back',
        leave: 'Leave',
        close: 'Close',
        abort: 'Cancel',
        save: 'Save',
        done: 'Done',
        dialogButtonCancel: 'No, go back',
        dialogButtonConfirm: 'Yes, cancel',
        dialogButtonConfirmClose: 'Yes, close',
        dialogButtonConfirmContinue: 'Yes, continue',
        confirm: 'Confirm',
        confirmSave: 'Are you sure you want to save?',
        dialogButtonConfirmDeleting: 'Yes, delete',
        name: 'Name',
        description: 'Description',
        add: 'Add',
        field: 'Field',
        loading: 'Loading...',
        niceWork: 'Nice work!',
        tryAgain: 'Try again',
        create: 'Create',
        continue: 'Continue',
        cancel: 'Cancel',
        update: 'Update',
        authErrorTitle: 'Authentication error',
        operationFailed: 'Operation failed',
        notifications: 'Notifications',
        authErrorMessage: "Please allow pop-ups from your browser settings and try again.",
        retry: 'Retry',
        preposition: ' of ',
        genericErrorCheckName: "Error during checking name",
        emptyList: 'The list is empty',
        emptyFolder: 'The folder is empty',
        noTags: 'The Tag section is not aviable for files with extension: ',
        noPreview: 'The Preview is not aviable for files with extension: ',
        noMetadata: 'This archive does not have any metadata',
        excelCel: 'Excel cell',
        isInvalid: 'is invalid:',
        excelFormatError: 'Invalid Excel file format:',
        EmptyFile: 'excel sheet is empty',
        ColumnsPosition: 'only the first two columns must be filled',
        ColumnsCount: `found a count of columns greater than the max of columns accepted (${GlossaryExcelDataValidationErrors.MAX_COUNT_COLUMNS} columns)`,
        CellRequired: 'cell value is required',
        RegexNotMatched: 'wrong format',
        InvalidMinLenght: `value must have at least ${GlossaryExcelDataValidationErrors.MIN_LENGTH_CELL} chars`,
        fileNotFound: 'File not found',
        maxFilesSelected: `It's impossible to select all files. The list contains more than ${Constants.PAGE_SIZE_START_ANALYSIS} files.`,
        commands: {
            open: 'Open',
            openInBrowser: 'Open in browser',
            translateDocument: 'Translate document',
            createLink: 'Create link',
            copyDocumentId: 'Copy ID Document',
            viewTag: "View Tag",
            download: 'Download',
            downloadFile: 'Download document',
            addToFavourites: 'Add to favourites',
            removeToFavourites: 'Remove from favourites',
            viewFile: 'View document',
            delete: 'Delete',
            edit: 'Edit',
            move: 'Move',
            copy: 'Copy',
            copied: 'Copied',
            addToCompare: 'Add to compare',
            viewSubParagraph: 'View sub paragraph'
        },
        statusLabel: {
            ongoing: "Ongoing",
            completed: "Completed",
            error: "Error",
            completedWithError: "Completed with Error"
        },
        zoomIn: 'Zoom in',
        zoomOut: 'Zoom out',
        reset: 'Reset',
        manageUsers: "Manage Users",
        viewUsers: "View Users",
        emptySearch: 'No results found',
        emptyFavourites: "No elements added to your favorites yet.",
        role: 'Role',
        activityVisibility: 'Project visibility',
        error: 'Error',
        apply: 'Apply',
        search: 'Search',
        selectAll: 'Select All',
        deselectAll: 'Deselect All',
        warning: 'Warning',
        savingError: "Error while saving. Please try again.",
        noResults: 'No result',
        gotIt: 'Got it',
        compare: 'Compare',
        subParagraph: 'Subparagraph',
        requiredField: "Required field",
        remove: 'Remove'
    }
}