import { PartialTheme } from '@fluentui/react';
import { pivotStyles, navStyles, peoplePickerStyles, suggestionsItemStyles, suggestionsPeoplePickerStyles, breadcrumbStyles, detailsColumnStyles, detailsHeaderStyles, detailsRowStyles, dropdownStyles, textFieldStyles, tooltipStyles , searchBoxStyles, checkBoxStyles  } from './hc.components';

export const themeHighContrast: PartialTheme = {
  components: {
    NormalPeoplePicker: { styles: peoplePickerStyles },
    Suggestions: { styles: suggestionsPeoplePickerStyles },
    SuggestionItem: { styles: suggestionsItemStyles },
    Nav: { styles: navStyles },
    //ModalUploadFile :{styles: modalUploadFile},
    Breadcrumb: { styles: breadcrumbStyles },
    DetailsRow: { styles: detailsRowStyles },
    DetailsList: {},
    DetailsColumn: { styles: detailsColumnStyles },
    DetailsHeader: { styles: detailsHeaderStyles },
    Pivot: { styles: pivotStyles },
    Tooltip: {styles: tooltipStyles},
    Dropdown: { styles: dropdownStyles },
    TextField: { styles: textFieldStyles },
    SearchBox : {styles : searchBoxStyles},
    Checkbox : {styles : checkBoxStyles}
  },
  palette: {
    // themeDarker: "#000",
     themeDark: "#ffff00",
    // themeDarkAlt: "#ffff00",
    // themePrimary: "#00ebff",
    // themeSecondary: "#ffff00",
    // themeTertiary: "#ffff00",
    // themeLight: "#000",
    // themeLighter: "#000",
    // themeLighterAlt: "#000",
    // black: "#fff",
    // "blackTranslucent40": "rgba(0,0,0,.4)",
    // neutralDark: "#fff",
    // neutralPrimary: "#000",
    // neutralPrimaryAlt: "#000",
    // neutralSecondary: "#fff",
    // neutralSecondaryAlt: "#8a8886",
    // neutralTertiary: "#000",
    // neutralTertiaryAlt: "#fff",
    // neutralQuaternary: "#000",
    // neutralQuaternaryAlt: "#000",
    // neutralLight: "#000",
    // neutralLighter: "#fff",
    // neutralLighterAlt: "#000",
    // accent: "#00ebff",
    // white: "#000",
    // "whiteTranslucent40": "rgba(255,255,255,.4)",
    // yellowDark: "#d29200",
    // yellow: "#ffb900",
    // yellowLight: "#fff100",
    // orange: "#d83b01",
    // orangeLight: "#ea4300",
    // orangeLighter: "#ff8c00",
    // redDark: "#fff",
    // red: "#e81123",
    // magentaDark: "#5c005c",
    // magenta: "#b4009e",
    // magentaLight: "#e3008c",
    // purpleDark: "#32145a",
    // purple: "#5c2d91",
    // purpleLight: "#b4a0ff",
    // blueDark: "#002050",
    // blueMid: "#00188f",
    // blue: "#0078d4",
    // blueLight: "#00bcf2",
    // tealDark: "#004b50",
    // teal: "#008272",
    // tealLight: "#00b294",
    // greenDark: "#004b1c",
    // green: "#107c10",
    // greenLight: "#bad80a"
  },
  semanticColors: {
    // bodyBackground: "#1f1f1f",
    // listHeaderBackgroundHovered: "#fff100",
    // listItemBackgroundHovered: "#fff100",
    // listItemBackgroundChecked: "rgb(0, 235, 255)",
    // menuDivider:"#000",
    // variantBorder : '#fff',
    // bodyText: DefaultPalette.yellowLight,
    // bodyDivider : '#fff'
  }
};