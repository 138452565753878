import { IStyleFunction } from "@fluentui/react"
import { ICustomWidgetSelectPropsStyles, ICustomWidgetSelectStyles } from "./customWidgetSelect.types"

export const CustomWidgetSelectGlobalClassNames = {
    root: 'edi-custom-widget-select-root'
}

export const getStyles: IStyleFunction<ICustomWidgetSelectPropsStyles, ICustomWidgetSelectStyles> = (props: ICustomWidgetSelectPropsStyles): ICustomWidgetSelectStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            CustomWidgetSelectGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        label:{
            padding:'5px 0'
        },
        errorLabel:{
            color: palette.redDark
        },
        required:{
            color: palette.redDark,
            paddingLeft: '5px'
        },
        otherItems:{
            textAlign:"center !important",
            textDecoration:'italic'
        }
    };
}