import { IStyleFunction } from "@fluentui/merge-styles";
import { IInputTextTypedPropsStyles, IInputTextTypedStyles } from "./inputTextTyped.types";

export const InputTextTypedGlobalClassNames = {
    root: 'input-text-typed-root', 
}

export const getStyles: IStyleFunction<IInputTextTypedPropsStyles, IInputTextTypedStyles> = (props: IInputTextTypedPropsStyles): IInputTextTypedStyles => {
    return {
        root: [
            InputTextTypedGlobalClassNames.root,
            {

            }
        ],
        container: [],
        iconWrapper:[],
        wrapper: []
    };
}