export const localEnvironment: Record<string, string> = {
    //'edi-be-svc-users': 'https://localhost:44310/',
    //'edi-be-svc-archives': 'https://localhost:44311/',
    //'edi-be-svc-search': 'https://localhost:44312/',
    //'edi-be-svc-docanalyzes': 'https://localhost:44313/',
    //'edi-be-svc-activities': 'https://localhost:44314/',
    //'edi-be-svc-analytics': 'https://localhost:44315/',
    //'edi-be-svc-knowledges': 'https://localhost:44316/'
    //'edi-be-svc-feedback': 'https://localhost:44316/'
}

export const environment: Record<string, string> = {
    'localhost:3000': 'https://apim-wwe-ictx-edi.azure-api.net/local',
    'sd-edi-app.eni.com': 'https://api-app.eni.com/sd-edi/',
    'st-edi-app.eni.com': 'https://api-app.eni.com/st-edi/',
    'pp-edi-app.eni.com': 'https://api-app.eni.com/pp-edi/',
    'edi-app.eni.com': 'https://api-app.eni.com/pr-edi/'
}

export const svcPaths: Record<string, string> = {
    users: 'edi-be-svc-users',
    archives: 'edi-be-svc-archives',
    search: 'edi-be-svc-search',
    docAnalyzes: 'edi-be-svc-docanalyzes',
    activities: 'edi-be-svc-activities',
    analytics: 'edi-be-svc-analytics',
    knowledges: 'edi-be-svc-knowledges',
    dashboard: 'edi-be-svc-feedback',
    notifications: 'edi-be-svc-notifications'
}

export const isInDebug = (baseURL: string | undefined, url: string | undefined) => {
    if (!url)
        return baseURL;

    if (window.location.host !== 'localhost:3000')
        return baseURL;

    const svcPath = url.split('/')[0];

    return localEnvironment[svcPath] ?? baseURL;
}