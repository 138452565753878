import { styled } from "@fluentui/react";
import { ProfileManagementBase } from "./profileManagement.base";
import { getStyles } from "./profileManagement.styles";
import { IProfileManagementBaseProps, IProfileManagementBasePropsStyles, IProfileManagementBaseStyles } from "./profileManagement.types";

const ProfileManagement = styled<IProfileManagementBaseProps, IProfileManagementBasePropsStyles, IProfileManagementBaseStyles>(
    ProfileManagementBase, 
    getStyles,
    undefined,
    { scope: 'ProfileManagement'}
)

export default ProfileManagement;