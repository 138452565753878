/*eslint-disable sonarjs/cognitive-complexity */

import React, { useCallback } from "react";
import { classNamesFunction, Icon, IconButton, TextField, TooltipHost } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { ITableOfContentItemProps, ITableOfContentItemPropsStyles, ITableOfContentItemStyles } from "./tableOfContentItem.types";


const getClassNames = classNamesFunction<ITableOfContentItemPropsStyles, ITableOfContentItemStyles>();

export const TableOfContentItemBase = (props: ITableOfContentItemProps) => {
    const { t } = useTranslation(['createTemplate', 'common']);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    const viewIcon = (
        <div>
            <TooltipHost content={props.visible ? t('hideToolBar') : t('showToolBar')}>
                <IconButton
                    style={{ cursor: 'pointer' }}
                    styles={{
                        root: {
                            selectors: {
                                ':hover': {
                                    background: 'transparent !important'
                                },
                                ':active': {
                                    background: 'transparent !important'
                                }
                            }
                        }
                    }}
                    disabled={props.readonly}
                    iconProps={{ iconName: props.visible ? 'Hide3' : 'View' }}
                    onClick={() => props.changeVisibility(props.id)}
                />
            </TooltipHost>
        </div>
    )
    const iconBar = (
        // <div style={{ visibility: !showIconBar ? 'visible' : 'hidden' }}>
        <div style={{ paddingLeft: '15px', whiteSpace: 'nowrap' }}>
            <TooltipHost content={t('createChild')}>
                <IconButton
                    disabled={props.disabled[0] || props.readonly}
                    iconProps={{ iconName: 'CirclePlus' }}
                    onClick={() => props.onAddClick(props.id)}
                />
            </TooltipHost>
            <TooltipHost content={t('promoteNode')}>
                <IconButton
                    styles={{ root: { transform: 'rotate(90deg)' } }}
                    iconProps={{ iconName: 'DrillDownSolid' }}
                    disabled={props.disabled[1] || props.readonly}
                    onClick={() => props.onMoveLeft(props.id)}

                />
            </TooltipHost>
            <TooltipHost content={t('downgradesNode')}>
                <IconButton
                    styles={{ root: { transform: 'rotate(270deg)' } }}
                    iconProps={{ iconName: 'DrillDownSolid' }}
                    disabled={props.disabled[2] || props.readonly}
                    onClick={() => props.onMoveRight(props.id)}
                />
            </TooltipHost>
            <TooltipHost content={t('upNode')}>
                <IconButton

                    styles={{ root: { transform: 'rotate(180deg)' } }}
                    iconProps={{ iconName: 'DrillDownSolid' }}
                    onClick={() => props.onMoveUpDown(props.id, true)}
                    disabled={props.disabled[3] || props.readonly}

                />
            </TooltipHost>
            <TooltipHost content={t('downNode')}>
                <IconButton
                    iconProps={{ iconName: 'DrillDownSolid' }}
                    onClick={() => props.onMoveUpDown(props.id, false)}
                    disabled={props.disabled[4] || props.readonly}

                />
            </TooltipHost>
            <TooltipHost content={t('deleteNode')}>
                <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    disabled={props.disabled[5] || props.readonly}
                    onClick={() => props.onDeleteItem(props.id)}
                />
            </TooltipHost>
        </div>
    )


    const emptyField = useCallback((value: string) => {
        if(props.readonly)
            return "";
        if (!value || value.trim() === "")
            return `${t('emptyField')}`;
        return "";
    }, [t, props.readonly])

    return (
        <div style={{ display: 'flex', alignItems: 'center', position: 'relative', paddingTop: props.id.split('.').length === 1 ? '15px' : '5px', marginBottom: '20px', marginLeft: props.id.split('.').length * 20 }}>
            {props.hasChildren && <Icon
                iconName={props.opened ? "ChevronDown" : "ChevronRight"}
                onClick={() => { props.changeOpen && props.changeOpen(props.id) }}
                style={{ position: 'absolute', userSelect: 'none', fontWeight: 'bold', left: '-20px', cursor: 'pointer' }} />}
            <TooltipHost content={props.text}>
                <TextField
                    autoComplete={"off"}
                    prefix={props.id}
                    disabled={props.readonly}
                    defaultValue={props.text}
                    onGetErrorMessage={emptyField}
                    onChange={(_, newValue) => props.onChangeText(props.id, newValue || "")}
                    styles={classNames.subComponentStyles.textFieldStyle}
                />
            </TooltipHost>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {!props.readonly && viewIcon}
                {props.visible && iconBar}
            </div>
        </div>);
}
