import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { classNamesFunction, Icon, ScrollablePane, Stack } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Accordion from "../../../../common/components/accordion/accordion";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import { useNavigator } from "../../../hooks/useNavigator";
import { AnalysisTypeId } from "../../../models/docAnalysis";
import { docAnalyzesApi } from "../../../services/docAnalyzes/docAnalyzes.api";
import QualityCheckSettings from "./qualityCheck/qualityCheckSettings/qualityCheckSettings";
import { IDocAnalysisSettingsProps, IDocAnalysisSettingsPropsStyles, IDocAnalysisSettingsStyles } from "./docAnalysisSettings.types";
import TocSettings from "./toc/tocSettings/tocSettings";
import { useDocLabDispatch } from "../../../docLabStore";
import { setToolbarActions } from "../../../features/toolbarActions";

const getClassNames = classNamesFunction<IDocAnalysisSettingsPropsStyles, IDocAnalysisSettingsStyles>();

export const DocAnalysisSettingsBase = (props: IDocAnalysisSettingsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['docAnalysisSettings', 'docAnalysis']);
    const { currentArchiveId } = useNavigator();
    const dispatch = useDocLabDispatch();

    const { execute: loadConfigs, loading, value: configs, error } = useAsyncApi<void, AnalysisTypeId[]>({
        keepResultsWhileReloading: true,
        func: async () => {
            if (!currentArchiveId)
                return [];

            const result = await docAnalyzesApi.getAvailableAnalysisTypes(currentArchiveId);
            return result.map(r => r.typeId);
        }
    });

    useOnMount(() => {
        loadConfigs();
        dispatch(setToolbarActions([]));
    });

    const configurationsByAnalysisType = (typeId: AnalysisTypeId) => {
        if (!currentArchiveId)
            return null;

        let content = null;

        switch (typeId) { //eslint-disable-line sonarjs/no-small-switch
            case AnalysisTypeId.QualityCheck:
                content = <QualityCheckSettings />;
                break;
            case AnalysisTypeId.TableOfContent:
                content = <TocSettings />;
                break;
            default:
                return content;
        }

        return (
            <Accordion key={typeId} title={`${t('preferences')} ${t(`docAnalysis:${AnalysisTypeId[typeId]}`)}`} opened={true} stickyHeader>
                {content}
            </Accordion>
        );
    }

    return (
        <Stack className={classNames.root}>
            <Stack className={classNames.title} horizontal verticalAlign={'center'}>
                <Icon iconName={"DocumentManagement"} className={classNames.titleIcon} />
                <span style={{ paddingLeft: 10 }}>{t('title')}</span>
            </Stack>
            <Stack.Item verticalFill className={classNames.lowerSection}>
                {configs &&
                    <ScrollablePane>
                        {configs.map(key => configurationsByAnalysisType(key))}
                    </ScrollablePane>
                }
                {loading &&
                    <div className={classNames.loaderContainer}>
                        <TeamsSpinner />
                    </div>
                }
                {error && <TeamsImage imageName={ImageName.Oops1} scale={0.5} fullContainer />}
            </Stack.Item>
        </Stack>
    );
}