import React, { useEffect, useState } from "react";
import { Dropdown, IDropdownOption, TextField } from "@fluentui/react";
import { IInputTextTypedProps } from "./inputTextTyped.types";

export const InputTextTypedBase = (props: IInputTextTypedProps) => {
    const [dropdownValue, setDropdownValue] = useState<IDropdownOption>();
    const [selectedKey, setSelectedKey] = useState<string | null>();
    const [textValue, setTextValue] = useState('0');
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    useEffect(() => {
        setTextValue(props.inputValue)
    }, [props.inputValue]);

    useEffect(() => {
        if(dropdownValue === undefined){
            setSelectedKey(null);
        }
        else{
            setSelectedKey(dropdownValue?.key + '');
        }
        
    }, [dropdownValue]);

    const handleChange = (e: Event) => {
        e.preventDefault();
    };

    return (
        <div style= {{ display: 'flex', width: '100%'}}>
            <div style= {{ width: '75%'}}>
                <TextField
                    resizable= {false}
                    maxLength= {200}
                    value={textValue}
                    disabled={props.disabled}
                    onChange={(e) => {
                        if(!e.currentTarget.value){
                            setTextValue('0');
                            setDropdownValue(undefined);
                            props.textValueCallback('0');
                            props.dropdownValueCallback(undefined);
                        }else{
                            if (Number(e.currentTarget.value) % 1 === 0) {
                                if(!dropdownValue){
                                    props.dropdownValueCallback(props.dropdownOptions[0]);
                                    setDropdownValue(props.dropdownOptions[0]);
                                }
                                setTextValue(props.inputValue)
                                props.textValueCallback(e.currentTarget.value)   
                            }
                        }
                    }}
                    onPaste={(input) => handleChange(input.nativeEvent)}
                    defaultValue="0"
                />
            </div>
            <Dropdown
                disabled={props.disabled}
                options= {props.dropdownOptions}
                onChange= {(_, newValue) => {
                    setDropdownValue(newValue);
                    props.dropdownValueCallback(newValue)
                }} 
                styles= {{
                    root: {
                        width: '25%'
                    },
                    title: {
                        backgroundColor: palette.neutralTertiaryAlt
                    }
                }}
                selectedKey={props.selectedDropodown || selectedKey}
                //defaultSelectedKey={props.dropdownOptions && props.dropdownOptions.length > 0 ? props.selectedDropodown : props.dropdownPlaceholder}
                placeholder={props.dropdownPlaceholder}
            />
        </div>
    )
}