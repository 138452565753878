import { styled } from "@fluentui/react";
import { getStyles } from "./knowledgeLabFileEnrichment.styles";
import { IKnowledgeLabFileEnrichmentPropsStyles, IKnowledgeLabFileEnrichmentStyles, IKnowledgeLabFileEnrichmentProps } from "./knowledgeLabFileEnrichment.types";
import { KnowledgeLabFileEnrichmentBase } from "./knowledgeLabFileEnrichment.base";

const KnowledgeLabFileEnrichment = styled<IKnowledgeLabFileEnrichmentProps, IKnowledgeLabFileEnrichmentPropsStyles, IKnowledgeLabFileEnrichmentStyles>(
    KnowledgeLabFileEnrichmentBase,
    getStyles,
    undefined,
    { scope: 'KnowledgeLabFileEnrichment' }
);

export default KnowledgeLabFileEnrichment;