import { DateTime } from "luxon";
import { AnalysisDocError, AnalysisDocWarning, Labels } from "./constants";
import { useTranslation } from "react-i18next";
import { AnalyzesStatus } from "./constants";
import { TocTemplate } from "../components/files/createTemplateModal/createTemplateModal.types";
import { Facets, SearchResult } from "../services/search/search.contracts";

export enum AnalysisTypeId {
  QualityCheck = 0,
  TableOfContent = 1,
  GlossaryCheck = 2,
  SearchAndCompare = 3
}

export type AnalysisConfigTyped<T extends QualityCheckConfig | TableOfContentSetting> = AnalysisConfig & {
  data: T;
}

export type AnalysisConfig = {
  id: number;
  name: string;
  description: string;
  createdOn: string;
  lastUpdatedOn: DateTime;
  userId: string;
  user: string;
  typeId: AnalysisTypeId;
  archiveId: number;
  isActive: boolean;
}

export type QualityCheckConfig = {
  length: StandardLabel[];
}

export type StandardLabel = {
  labelId: Labels;
  valueFrom: number;
  valueTo?: number;
  enabled: boolean;
}

export type TableOfContentSetting = {
  referenceToC: TocTemplate[];
}

export type ObjDocAnalysis = {
  type: AnalysisTypeId,
  title: string,
  description: string,
  iconName?: string
}

export type AnalysisType = {
  typeId: AnalysisTypeId;
  isActive: boolean;
}

export type analyzes = {
  id: number,
  name: string,
  typeId: AnalysisTypeId,
  startedBy: string,
  validatedBy: string,
  statusId: AnalyzesStatus,
  createdOn: string, // "2021-07-29T08:36:24.738Z",
  lastUpdatedOn: string,
  fileId: number,
  fileName: string,
  isCrossArchive: boolean
}

export type IAnalyzesData = {
  name: string,
  level: number,
  properties: IAnalyzesProperties[],
  children: IAnalyzesData[]
}

export type ITocAnalyzesData = {
  matches: IToCMatchesData[],
  notMatches: IToCMatchesData[]
}

export type IToCMatchesData = {
  result: string,
  ref: string | undefined,
  comp: string | undefined,
  confidence: number | undefined
}

export type IAnalyzesProperties = {
  Name: string,
  Value: number,
  LabelId: number | undefined
}

export type IResult<T extends IAnalyzesData[] | ITocAnalyzesData | ISearchAndCompareData> = {
  fileId: number;
  fileName: string;
  path: string;
  error?: { code?: AnalysisDocError, message?: string };
  warning?: { code?: AnalysisDocWarning, message?: string };
  data: T;
}

export type resultAnalyzes = {
  // result: IResult[]
  input?: string;
  result?: string;
}


export type ExcelDataValidationErrors = {
  errorCode: string;
  relatedCell?: string;
}

export type GlossaryCheckValidationResult = {
  isValid: boolean;
  items: GlossaryCheckItem[],
  errors: ExcelDataValidationErrors[],
  messageError: string
}

export type GlossaryCheckItem = {
  mainTerm: string;
  relatedTerms: string[]
}

export type ISearchAndCompareData = {
  AnalysisName: string;
  TextSection: string; 
  FileName: string;
  FileId: number,
  SearchResult: SearchResult;
}

export type ISearchAndCompareResult = {
  TotalCount: number;
  CurrentPage: number;
  Values: ISearchAndCompareValue[];
  Facets: Facets;
  PageCount: number;
  DisabledFacetFilters: string[];
}

export type ISearchAndCompareValue = {
  Score: number;
  Document: IDocument;
  Highlights: Record<string, string[]>;
  More: boolean;
}

export type IDocument = {
  FileRef: number;
  Title: string;
}

const useDocAnalysis = () => {
  const { t } = useTranslation('docAnalysis');
  return [{
    type: AnalysisTypeId.QualityCheck,
    title: t('qualityCheck.title'),
    description: t('qualityCheck.description'),
    iconName: 'AnalyticsReport',
  },
  {
    type: AnalysisTypeId.TableOfContent,
    title: t('tableOfContent.title'),
    description: t('tableOfContent.description'),
    iconName: 'UpgradeAnalysis'
  },
  {
    type: AnalysisTypeId.GlossaryCheck,
    title: t('glossaryCheck.title'),
    description: t('glossaryCheck.description'),
    iconName: 'ActivateOrders'
  },
  {
    type: AnalysisTypeId.SearchAndCompare,
    title: t('searchAndCompare.title'),
    description: t('searchAndCompare.description'),
    iconName: 'InsertTextBox'
  }]
}
export default useDocAnalysis;