import { generatePath } from "react-router-dom";
import { AppRoutes, AppTab } from "../../app/utilities/routes";

export enum ActivityTab {
    board = 'board',
    list = 'list',
    workflow = 'workflow',
    graphs = 'graphs',
}

export enum ActivityTabSettings {
    settings= 'settings',
    users = 'users'
}

class ActivityLabRoutesClass {
    public activityLab = AppRoutes.generateRouteForTab(AppTab.activityLab);
    public portfolios = `${this.activityLab}/portfolios`;
    public portfolio = `${this.portfolios}/:portfolioId(\\d+)`;
    public portfolioTab = `${this.portfolio}/:tab(${ActivityTab.board}|${ActivityTab.list}|${ActivityTab.workflow}|${ActivityTab.graphs})`;
    public activityLabOptionals = `${this.activityLab}/:portfolios(portfolios)?/:portfolioId(\\d+)?/:tab(${ActivityTab.board}|${ActivityTab.list}|${ActivityTab.workflow}|${ActivityTab.graphs})?`;

    
    generatePortfolioPath(portfolioId: number) {
        return generatePath(`${this.portfolios}/:portfolioId`, { portfolioId: portfolioId });
    }
    generateActivityLabTabPath(portfolioId: number, tab: ActivityTab) {
        return `${this.generatePortfolioPath(portfolioId)}/${tab}`;
    }
    generateActivitySettingsTabPath(portfolioId: number, tab: ActivityTabSettings) {
        return `${this.generatePortfolioPath(portfolioId)}/settings/${tab}`;
    }
}

export const ActivityLabRoutes = new ActivityLabRoutesClass();