import { styled } from "@fluentui/react";
import { getStyles } from "./createStandardModal.styles";
import { ICreateStandardModalPropsStyles, ICreateStandardModalStyles, ICreateStandardModalProps } from "./createStandardModal.types";
import { CreateStandardModalBase } from "./createStandardModal.base";

const CreateStandardModal = styled<ICreateStandardModalProps, ICreateStandardModalPropsStyles, ICreateStandardModalStyles>(
    CreateStandardModalBase,
    getStyles,
    undefined,
    { scope: 'CreateStandardModal' }
);

export default CreateStandardModal;