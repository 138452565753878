import { IStyleFunction } from "@fluentui/react"
import { ITagPanelPropsStyles, ITagPanelStyles } from "./tagPanel.types"
/*eslint-disable sonarjs/no-duplicate-string */
export const TagPanelGlobalClassNames = {
    root: 'edi-tag-panel-root',
    cardContainer: 'edi-tag-panel-cardContainer',
    footer: 'edi-tag-panel-footer',
    errorTitle: 'edi-tag-panel-errorTitle',
    errorMessage: 'edi-tag-panel-errorMessage',
    successTitle: 'edi-tag-panel-successTitle',
    successMessage: 'edi-tag-panel-successMessage',
    titleAccordion: 'edi-tag-panel-titleAccordion',
    iconStyle: 'edi-tag-panel-iconStyle',
    emptyFolder:'edi-tag-panel-emptyFolder',
    caption: 'edi-tag-panel-caption',
    captionRow: 'edi-tag-panel-captionRow',
    pivotHeaderContainer: 'edi-tag-panel-pivotHeaderContainer'
}

export const getStyles: IStyleFunction<ITagPanelPropsStyles, ITagPanelStyles> = (props: ITagPanelPropsStyles): ITagPanelStyles => {
     const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [TagPanelGlobalClassNames.root,
        {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '15px',
            paddingBottom: '15px',
            //borderBottom: '1px solid ' + palette.neutralPrimary
        }],
        pivotItemsDisabled:{
            root:{
                selectors:{
                    ':disabled': {
                        color: palette.neutralTertiary,
                        pointerEvents: 'none'
                    }
                }
            }
        },
        emptyFolder: {
            height: '350px',
        },
        caption: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
        captionRow: {
            color: 'rgb(37, 36, 35)',
            fontWeight: 300
        },
        pivotHeaderContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        footer: [TagPanelGlobalClassNames.footer,
        {
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            padding: "0 24px 15px 0px",
            position:'fixed',
            bottom: 0,
            right: 40,
            width: "calc(100% - 40px)",
            background: palette.neutralLighterAlt+"f0"
        }
        ],
        iconStyle: [TagPanelGlobalClassNames.iconStyle,
        {
            fontSize: 20,
            paddingLeft: '10px'
        }],
        titleAccordion: [
            TagPanelGlobalClassNames.titleAccordion,
            {
                display: 'flex',
                alignItems: 'center'
            }],
        tagAccordionContainer: ["", {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-start'
        }],
        required:{
            color: palette.redDark,
            paddingLeft: 5
        },
        childrenContainer:{
            ".ms-Label":{
                color:palette.black,
                fontWeight: 400
            },
            ".ms-TextField-errorMessage":{
                display:"none !important"
            },
            ".ms-TextField-fieldGroup": {
                '::after': {
                        borderBottom:"1px solid "+palette.themePrimary
                    }
            }
        },
        label:{
            padding:'5px 0'
        },
        errorLabel:{
            color: palette.redDark
        },
        loadingErrorContainer:{
            height: 'calc(100% - 124px)', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center'
        },
        footerContainer:{
            padding:  !props.readonlyForm ? "10px 0px 20px 0" : "0",
            position:'fixed',
            bottom: 0,
            right: 40,
            width: "calc(100% - 40px)",
            background: palette.neutralLighterAlt+"f0"
        },
        buttonFormContainer:{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        buttonFormContainerLeft:{
            marginLeft: '20px',
            fontWeight: 400,
            color: palette.redDark
        },
        buttonFormContainerRight:{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '5px'
        },
        arrayFieldItem: {
            display: 'flex',
            gap: 30,
            alignItems: 'flex-start',
            ':not(:last-child)': {
                borderBottom: '0.5px dotted ' + palette.themePrimary
            },
            paddingTop: 10,
            ".ms-TextField-fieldGroup": {
                '::after': {
                        borderBottom:"1px solid "+palette.themePrimary
                    }
            }
        },
        arrayFieldItemWithError: {
            display: 'flex',
            gap: 30,
            alignItems: 'flex-start',
            ':not(:last-child)': {
                borderBottom: '0.5px dotted ' + palette.themePrimary
            },
            paddingTop: 10,
            ".ms-TextField-fieldGroup": {
                '::after': {
                        borderBottom:"1px solid "+palette.redDark
                    }
            }
        },
        childrenContainerWithError:{
            ".ms-Label":{
                color:palette.redDark,
                fontWeight: 400
            },
            ".ms-TextField-errorMessage":{
                display:"none !important"
            },
            ".ms-TextField-fieldGroup": {
                '::after': {
                        borderBottom:"1px solid "+palette.redDark
                    }
            }
        },
        titleSection:{
            fontSize: 20,
            fontWeight:"bold",
            margin: "15px 0"
        },
        errorForm:{
            color: palette.redDark,
            fontSize: 12
        },
        subComponentStyles: {
            panelStyle: {
                overlay: {
                    backgroundColor: 'transparent'
                },
                main:{
                    overflow:'hidden'
                },
                contentInner: {
                    height:'calc(100% - 20px)'
                },
                scrollableContent:{
                    height:'100%'
                },
                content:{
                    paddingBottom: 60
                }
                
            },
            panelStyleNoItems: {
                overlay: {
                    backgroundColor: 'transparent'
                },
                main:{
                    overflow:'hidden'
                },
                contentInner: {
                    height:'calc(100% - 20px)'
                },
                scrollableContent:{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height:'100%'
                },
                content:{
                    paddingBottom: 60
                }
                
            },
            dropdownStyle:{
                dropdown:{
                    '::before': {
                        display: 'none'
                    }
                }
            },
            iconButton: {
                root: {
                    selectors: {
                        ':disabled': {
                            backgroundColor: palette.neutralQuaternary,
                            color: palette.neutralTertiary,
                            pointerEvents: 'none'
                        },
                    }
                },
                iconHovered: {},
                menuIcon: {},
                rootExpanded: {},
                rootPressed: {},
                rootHovered: {}
            },
        }
    };
}