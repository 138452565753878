import { IStyleFunction } from "@fluentui/react"
import { ISearchFolderModalBaseStyles, ISearchFolderModalBasePropsStyles } from "./searchFolderModal.types";
import { IEdiModalPropsStyles, IEdiModalStyles } from "../../../../common/components/ediModal/ediModal.types";


export const SearchFolderModalGlobalClassNames = {
    root: 'edi-create-profile-modal-root',
    footerButtons: 'edi-create-profile-footer',
    footerContainer: 'edi-create-profile-folder-footer-container',
    warningContainer: 'edi-edit-profile-folder-warning-container'
}

export const getStyles: IStyleFunction<ISearchFolderModalBasePropsStyles, ISearchFolderModalBaseStyles> = (props: ISearchFolderModalBasePropsStyles): ISearchFolderModalBaseStyles => {
    return {
        root: [
            SearchFolderModalGlobalClassNames.root,
            {
            }
        ],
        footerButtons:[
            SearchFolderModalGlobalClassNames.footerButtons,
            {
                'margin-left': '10px'
            }
        ],
        footerContainer: [
            SearchFolderModalGlobalClassNames.footerContainer,
            {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }
        ],
        warningContainer: [
            SearchFolderModalGlobalClassNames.warningContainer,
            {
                height: '30px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: 'rgb(255, 241, 186)',
                border: '1px solid rgb(240, 138, 97)',
                borderRadius: 5
            }
        ],
        subComponentStyles: {
            ediModal: (props: IEdiModalPropsStyles): IEdiModalStyles => {
                return {
                    body: { marginTop: '10px !important' },
                    bodyContent: {},
                    title: {},
                    closeIcon: {},
                    container: { width: '800px', overflow: 'hidden' },
                    content: {},
                    footer: {
                    },
                    header: {},
                    subComponentStyles: {
                        iconButton: {}
                    },
                    subTitle: {}
                }
            }
        }
    }
}