import { IStyleFunction } from "@fluentui/react"
import { ITableOfContentItemPropsStyles, ITableOfContentItemStyles } from "./tableOfContentItem.types";

export const TableOfContentGlobalClassNames = {
  root: 'edi-table-of-content-root'
}

export const getStyles: IStyleFunction<ITableOfContentItemPropsStyles, ITableOfContentItemStyles> = (props: ITableOfContentItemPropsStyles): ITableOfContentItemStyles => {
  const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
  return {
    root: [
      TableOfContentGlobalClassNames.root,
      {
        // CSS PROPS AND SELECTORS
      }
    ],
    subComponentStyles: {
      textFieldStyle: {
        fieldGroup:{
          selectors:{
            '::after':{
              borderColor: palette.themePrimary
            }
          }
        },
        errorMessage:{
          color: palette.orangeLighter,
          position: 'absolute'
        },
        field:{
          width:'200px'
        }
      }
    }
  }
}
