import { styled } from "@fluentui/react";
import { getStyles } from "./activityType.styles";
import { IActivityTypePropsStyles, IActivityTypeStyles, IActivityTypeProps } from "./activityType.types";
import { ActivityTypeBase } from "./activityType.base";

const ActivityType = styled<IActivityTypeProps, IActivityTypePropsStyles, IActivityTypeStyles>(
    ActivityTypeBase,
    getStyles,
    undefined,
    { scope: 'ActivityType' }
);

export default ActivityType;