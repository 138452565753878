import { DefaultEffects, IStyleFunction } from "@fluentui/react"
import { IFavouriteCardPropsStyles, IFavouriteCardStyles } from "./favouriteCard.types"

export const FavouriteCardGlobalClassNames = {
    root: 'edi-favourite-card-root',
    titleSection: 'edi-titleSection-root',
    contentSection: 'edi-contentSection-root',
    viewsSection: 'edi-viewsSection-root',
    additionalDataSection: 'edi-additionalDataSection-root',
}

export const getStyles: IStyleFunction<IFavouriteCardPropsStyles, IFavouriteCardStyles> = (props: IFavouriteCardPropsStyles): IFavouriteCardStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            FavouriteCardGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
                backgroundColor: palette.white,
                margin: '3px',
                padding: '15px',
                boxShadow: DefaultEffects.elevation8,
                '::placeholder': {
                    fontSize: 20
                },
                width: "calc(33.33% - 36px)"
            }
        ],
        titleSection: [
            FavouriteCardGlobalClassNames.titleSection, {

                span: {
                    color: palette.black,
                    cursor: 'pointer',
                    fontWeight: 'bold'
                }

            }
        ],
        contentSection: [
            FavouriteCardGlobalClassNames.contentSection, {
                height: "150px",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }
        ],
        viewsSection: [
            FavouriteCardGlobalClassNames.viewsSection, {
                marginBottom: 5,
                marginTop: 5
            }
        ],
        additionalDataSection: [
            FavouriteCardGlobalClassNames.additionalDataSection, {
                marginBottom: 5,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                div: {
                    backgroundColor: palette.neutralTertiary,
                    color: palette.black,
                    borderRadius: "10px",
                    padding: 5,
                    width: "28%",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflowX: "hidden",
                }
            }
        ],
        subComponentStyles: {
            iconButton: {
                root: {
                    height: 19
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`
                },
                menuIcon: {
                    display: 'none !important'
                },
                rootExpanded: {
                    backgroundColor: 'inherit'
                },
                rootPressed: {
                    backgroundColor: 'inherit'
                },
                rootHovered: {
                    backgroundColor: 'inherit',
                }
            }
        }
    };
}