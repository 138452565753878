import { FontSizes, FontWeights, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react"
import { IActivityLabSettingsPropsStyles, IActivityLabSettingsStyles } from "./activityLabSettings.types"

export const ActivityLabSettingsGlobalClassNames = {
    root: 'edi-activity-lab-settings-root',
    title: 'edi-activity-lab-settings-title',
    titleIcon:'edi-activity-lab-settings-titleIcon',
    upperSection: 'edi-activity-lab-settings-upperSection',
    lowerSection: 'edi-activity-lab-settings-lowerSection',
    headerRow: 'edi-activity-lab-settings-headerRow',
    loaderContainer: 'edi-activity-lab-settings-loaderContainer'
}

export const getStyles: IStyleFunction<IActivityLabSettingsPropsStyles, IActivityLabSettingsStyles> = (props: IActivityLabSettingsPropsStyles): IActivityLabSettingsStyles => {
    const { semanticColors } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            ActivityLabSettingsGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px'
            }
        ],
        title: [
            ActivityLabSettingsGlobalClassNames.title,
            {
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingBottom: 10,
                userSelect: 'none'
            }
        ],
        titleIcon: [
            ActivityLabSettingsGlobalClassNames.titleIcon,
            {
                padding: 7,
                border: '1.5px solid',
                borderRadius: '50%',
                fontSize: 26
            }
        ],
        upperSection: [
            ActivityLabSettingsGlobalClassNames.upperSection,
            {
                paddingBottom: 10
            }
        ],
        lowerSection: [
            ActivityLabSettingsGlobalClassNames.lowerSection,
            {
                position: 'relative'
            }
        ],
        headerRow: [
            ActivityLabSettingsGlobalClassNames.headerRow,
            {
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                }
            }
        ],
        loaderContainer: [
            ActivityLabSettingsGlobalClassNames.loaderContainer,
            {
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        ],
        subComponentStyles: {
            list: (): IDetailsListStyles => {
                return {
                    root: {},
                    contentWrapper: {
                    },
                    focusZone: {},
                    headerWrapper: {
                        '> .ms-DetailsHeader': {
                            paddingTop: 0,
                            borderBottomColor: 'transparent'
                        }
                    },
                }
            },
            row: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                const { palette } = props.theme;

                return {
                    root: {
                        backgroundColor: palette.white,
                        ':hover': {
                            backgroundColor: palette.white
                        }
                    },
                    cell: { alignSelf: 'center' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
            removeButton: {
                root: {
                    paddingRight: 10
                },
                icon: {
                    color: semanticColors.bodyText
                },
                rootHovered: {
                    background: 'transparent'
                },
                rootPressed: {
                    background: 'transparent'
                },
                rootDisabled: {
                    background: 'transparent'
                }
            }
        },
    };
}