/* eslint-disable sonarjs/cognitive-complexity */
import { DefaultButton, PrimaryButton, classNamesFunction } from "@fluentui/react";
import { nanoid } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SubCodeConstants } from "../../../../activityLab/models/constants";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { archivesApi } from "../../../../docLab/services/archives/archives.api";
import { ErrorDetails } from "../../../../modules/apiClient/apiClient";
import { useDocLabDispatch } from "../../../docLabStore";
import { loadFolderContent, selectItems, showNavigationModal, useArchiveContent } from "../../../features/archiveContent";
import { insertCall, removeCall, setCall } from "../../../features/callNotification";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { Archive } from "../../../models/archive";
import { Call, callsList } from "../../../models/callsApi";
import { ContentItemIndexingStatus } from "../../../models/constants";
import ArchiveNavigation from "./archiveNavigation/archiveNavigation";
import FolderNavigation from "./folderNavigation/folderNavigation";
import { INavigationItemProps, INavigationItemPropsStyles, INavigationItemStyles } from "./navigationItemModal.types";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
const getClassNames = classNamesFunction<INavigationItemPropsStyles, INavigationItemStyles>();

export const NavigationItemModalBase = (props: INavigationItemProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['navigationModal', 'common']);
    const [folderId, setFolderId] = useState(0);
    const { navigationModalShown, navigationModalType, selectedItems } = useArchiveContent();
    const dispatch = useDocLabDispatch();
    const genericErrorApi = t('common:genericErrorApi'); 
    const [currentArchive, setCurrentArchive] = useState<Archive | undefined>(useCurrentArchive());
    const [showArchives, setShowArchives] = useState(false);
    const [isUserBlocked, setIsUserBlocked] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const copyItems = async () => {
      const copyItemsCall: Call = {
          type: callsList.copyItems,
          nameOperation:  t('copyOperation'),
          errors: [
              { code: 403, message: t('notPermissions') },
              { code: 500, message: genericErrorApi }
          ]
      }
      const id = nanoid();
      const payload = { requestId: id, notification: copyItemsCall }
      dispatch(insertCall(payload));   
      
      setLoading(true);

      try {
          await archivesApi.copyItems(selectedItems, folderId);
          const successPayload = { requestId: id, success: true, message: t('successCopyOperation') }
          dispatch(setCall(successPayload));
          dispatch(selectItems([]));
          props.onClose && props.onClose();
      }
      catch (er) {
        const error: ErrorDetails = er as ErrorDetails;
        let errorMessage = '';
        
        switch (error.code) {
          case 400:
            errorMessage = t('folderError')
          break;
          case 401:
            setIsUserBlocked(true);
            errorMessage = t('errorMessage')
          break;

          case 422: 
            switch (error.subCode) {
              case SubCodeConstants.SUBCODE_DEPTH_LIMIT_EXCEED: 
              errorMessage = t('depthExceedError');
              break; 
              case SubCodeConstants.SUBCODE_ARCHIVE_SIZE_EXCEED: 
              errorMessage = t('sizeArchiveExceedError');
              break; 
            }
          break; 

          case 409:
            errorMessage = t('conflictError');
            break;

          case 403: 
            errorMessage = t('notPermissions');
            break; 

          default: 
            errorMessage = genericErrorApi; 
        }        

        if(error.code === 401){        
          dispatch(removeCall(id));  
        }        
        else{
          const failurePayload = { requestId: id, success: false, message: errorMessage }
          dispatch(setCall(failurePayload));
          props.onClose && props.onClose();
        }        
        setLoading(false);
      }finally{
        setLoading(false);
      }
    }

    const moveItems = async () => {
      const moveItemsCall: Call = {
          type: callsList.moveItems,
          nameOperation:  t('moveOperation'),
          errors: [
              { code: 403, message: t('notPermissions') },
              { code: 500, message: genericErrorApi }
          ]
      }
      const id = nanoid();
      const payload = { requestId: id, notification: moveItemsCall }
      dispatch(insertCall(payload));

      setLoading(true);

      try {
          if (navigationModalType === 'movefolders')
            await archivesApi.moveFolders(selectedItems, folderId);        
          else 
            await archivesApi.moveFiles(selectedItems, folderId);        
          
          const successPayload = { requestId: id, success: true, message: t('successMoveOperation') }
          dispatch(setCall(successPayload));
          await dispatch(loadFolderContent({ resetPage: true }));
          dispatch(selectItems([]));
          props.onClose && props.onClose();
      }
      catch (er) {
        const error: ErrorDetails = er as ErrorDetails;
        let errorMessage = '';
        switch (error.code) {
          case 400:
            errorMessage = t('folderError')
          break;
          case 401:
            setIsUserBlocked(true);
            errorMessage = t('errorMessage')
          break;

          case 422: 
            switch (error.subCode) {
              case SubCodeConstants.SUBCODE_DEPTH_LIMIT_EXCEED: 
              errorMessage = t('depthExceedError');
              break; 
              case SubCodeConstants.SUBCODE_ARCHIVE_SIZE_EXCEED: 
              errorMessage = t('sizeArchiveExceedError');
              break; 
              case SubCodeConstants.SUBCODE_MOVEFOLDER_IN_SUBFOLDER: 
              errorMessage = t('moveFolderInSubfolderError');
              break;                 
            }
          break; 

          case 409:
            errorMessage = t('conflictError');
            break;

          case 403: 
            errorMessage = t('notPermissions');
            break; 

          default: 
            errorMessage = genericErrorApi; 
        }

        if(error.code === 401){        
          dispatch(removeCall(id));  
        }        
        else{
          const failurePayload = { requestId: id, success: false, message: errorMessage }
          dispatch(setCall(failurePayload));
          props.onClose && props.onClose();
        }        
        setLoading(false);

      }finally{
        setLoading(false);
      }
    }

    const onLevelUp = () => {
        setFolderId(0);
        setShowArchives(true); 
    }

    const onLevelDown = (archive: Archive) => {
        setShowArchives(false); 
        setCurrentArchive(archive); 
    }

    const closeModal = () => {
      dispatch(showNavigationModal({show: false}))
    }
       
    const footer = (
        <React.Fragment>
          <DefaultButton
            text={t("secondaryAction")}
            style={{ marginRight: "2px" }}
            onClick={() => {dispatch(showNavigationModal({show: false}))}}
            disabled={loading}
          />
          <PrimaryButton
            text={navigationModalType === 'copy' || navigationModalType === 'copyfiles' ? t("primaryCopyAction") : t("primaryMoveAction")}
            onClick={navigationModalType === 'copy' || navigationModalType === 'copyfiles' ? copyItems : moveItems}
            disabled={!folderId || loading}
          />
        </React.Fragment>
      );
    
    const body = (
      showArchives ?
        <ArchiveNavigation
            onArchiveSelected={onLevelDown}
        /> : 
        <FolderNavigation
            contentIndexingStatusConfiguration={ContentItemIndexingStatus.All}
            onUpdateFolder={setFolderId}
            selectedArchive={currentArchive}
            onLevelUp={onLevelUp}
        />
    );

    const errorPageBodyModal = () => {
      return (
        <div className={classNames.secondFooterContainer}>
          <TeamsImage
            imageName={ImageName.Error1}
            className={classNames.wellDoneImage}
            fullContainer scale={0.8}
            caption={t('errorMessage')}
          />
        </div>
      )
    }

    const errorfooter = () => {
      return (
        <div className={classNames.footer}>
          <PrimaryButton
            text={t('common:close')}
            onClick={() => closeModal()}
          />
        </div>
      );
    }

    const setSubtitle = () => {
      if(isUserBlocked)
      return '';

      if(navigationModalType === 'copy' || navigationModalType === 'copyfiles')
        return t("copyMessage");

      return t("moveMessage");
    }

    return (
        <EdiModal
        title={navigationModalType === 'copy' || navigationModalType === 'copyfiles' ? t("copyTitle") : t("moveTitle")}
        subtitle={setSubtitle()}
        width={800}
        height={500}
        isOpen={navigationModalShown}
        showCloseIcon={true}
        onCloseClick={() => closeModal()}
        body={isUserBlocked ? errorPageBodyModal() : body}
        footer={isUserBlocked ? errorfooter() : footer}
        />
    );
}