import { useArchiveList } from "../features/archiveList";
import { Archive } from "../models/archive";
import { useNavigator } from "./useNavigator";

export const useCurrentArchive = (): Archive | undefined => {
    const { currentArchiveId } = useNavigator();
    const { archives } = useArchiveList();

    if (!currentArchiveId)
        return;

    return archives[currentArchiveId];
}