import { FontSizes, FontWeights, IStyleFunction } from "@fluentui/react"
import { IManageTopicsOfInterestPropsStyles, IManageTopicsOfInterestStyles } from "./manageTopicsOfInterest.types";


export const ManageTopicGlobalClassNames = {
    root: 'edi-profile-management-root',
    title: 'edi-profile-management-title'
}

export const getStyles: IStyleFunction<IManageTopicsOfInterestPropsStyles, IManageTopicsOfInterestStyles> = (props: IManageTopicsOfInterestPropsStyles): IManageTopicsOfInterestStyles => {    
    return {
        root: [
            ManageTopicGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px'
            }
        ],
        title: [
            ManageTopicGlobalClassNames.title,
            {
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingBottom: 10,
                paddingTop: 10
            }
        ]
    }
}