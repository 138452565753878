/*eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from "react";
import { DefaultButton, PrimaryButton, SearchBox, Spinner, SpinnerSize, StackItem } from "@fluentui/react";
import { IEditViewProfileMemberBaseProps } from "./editViewProfileMember.types";
import EdiModal from "../../../../../../common/components/ediModal/ediModal";
import { useTranslation } from "react-i18next";
import { useDocLabDispatch } from "../../../../../docLabStore";
import { useOnMount } from "../../../../../../utilities/hooks";
import { archivesApi } from "../../../../../services/archives/archives.api";
import SelectProfileMember from "../../../../../../common/components/selectProfileMember/selectProfileMember";
import { UserProfile } from "../../../../../models/user";
import { useCurrentArchive } from "../../../../../hooks/useCurrentArchive";
import { updateMemberProfile } from "../../../../../features/profileContent";

export const EditViewProfileMemberBase = (props: IEditViewProfileMemberBaseProps) => {
    const { t } = useTranslation(['profileManagement']);
    const dispatch = useDocLabDispatch();
    const [profileMembers, setProfileMembers] = useState<UserProfile[]>([]);
    const [tempNewMembers, setTempNewMembers] = useState<UserProfile[]>([]);
    const [loading, setLoading] = useState(true);
    const [addNewUserMode, setAddNewUserMode] = useState(false);
    const [userBackup, setUserBackup] = useState<UserProfile[]>([])
    const currentArchive = useCurrentArchive();

    async function fetch() {
        const result = await archivesApi.getMemberProfile(props.profile?.id ?? 0);
        setUserBackup(result)
        setProfileMembers(result);
        setLoading(false);
    }

    useOnMount(() => {
        fetch();
    });

    const body = (
        <>
            {
                addNewUserMode &&
                <SelectProfileMember 
                    archiveId={currentArchive?.id ?? 0} 
                    readonly={false}
                    visualizeMode={false}
                    selectedUser={(users) => setTempNewMembers(users)}
                    notAvaiableUserIds={profileMembers.map(p => p.id)}
                />
            }
            {   
                (!addNewUserMode) &&
                <>
                    {
                        !loading ? 
                        <SelectProfileMember 
                            userToRender={profileMembers} 
                            archiveId={currentArchive?.id ?? 0} 
                            readonly={props.readonly}
                            visualizeMode={true}
                            selectedUser={(users) => {
                                setProfileMembers(users)
                            }}
                            removedUser={(idUser) => {
                                setUserBackup(userBackup.filter(u => u.id !== idUser))
                            }}
                        /> : 
                        <StackItem><Spinner size={SpinnerSize.large} /></StackItem>
                    }
                </>
            }            
        </>
    )

    const footer = (
        <div>
            <DefaultButton 
                onClick={ () => { 
                    if(addNewUserMode){
                        setAddNewUserMode(false)
                        setTempNewMembers([]);
                    }
                    else {
                        props.onClose()
                    } 
                }} 
                style={{ margin: '0px 4px' }}
            >
                {t('common:leave')}
            </DefaultButton>
            {!props.readonly && 
                <PrimaryButton
                    onClick={ () => {
                        if(addNewUserMode){
                            setAddNewUserMode(false);
                            setUserBackup(tempNewMembers.concat(profileMembers))
                            setProfileMembers(tempNewMembers.concat(profileMembers));
                        }
                        else{
                            props.onClose();
                            dispatch(updateMemberProfile({
                                archiveId: currentArchive?.id ?? 0,
                                profileId: props.profile?.id ?? 0,
                                userIds: userBackup.map(p => p.id)
                            }))
                        }                        
                    }}
                    style={{ margin: '0px 4px' }}>
                    {addNewUserMode ? t("add") : t('common:save') }
                </PrimaryButton>
            }
        </div>  
    )

    const customHeader = () => 
    { 
        return (
            addNewUserMode ? <></> :
                <>
                    {
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: '15px'}}>
                            <SearchBox
                                    styles={{ root: { width: 300, marginRight: '15px'} }}
                                    placeholder={t('search')}
                                    onSearch={(newValue) => {
                                        if(newValue !== ''){
                                            setProfileMembers(
                                                userBackup.filter(p => p.firstName.toLocaleLowerCase().includes(newValue.toLocaleLowerCase()) || 
                                                    p.lastName.toLocaleLowerCase().includes(newValue.toLocaleLowerCase())
                                            ));
                                            return;
                                        }
                                        setProfileMembers(userBackup);                                        
                                    }}
                                    onClear={() => {
                                        setProfileMembers(userBackup);
                                    }}
                            />
                            {!props.readonly && <PrimaryButton onClick={() => setAddNewUserMode(true)} text={t("addMember")} /> }
                        </div>
                    }
                </>
        ) 
    }

    return (
        <EdiModal 
            title={props.readonly ? t('view') : t('viewAndEdit') }
            body={body}
            isOpen={props.isOpen}
            height={700}
            width={800}
            footer={footer}
            onCloseClick={props.onClose}
            showCloseIcon={true}
            headerCustom={customHeader()}
        />
    )   
}