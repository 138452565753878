/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable sonarjs/no-identical-functions */
import { Checkbox, mergeStyleSets, Stack, TextField, useTheme } from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useOnMount } from "../../../utilities/hooks";

export interface CheckListDataBase {
    id: number;
    name: string;
}

export type CheckListPageData<T extends CheckListDataBase> = {
    checked: boolean;
    item: T;
}

export interface ICheckListPageProps<T extends CheckListDataBase> {
    initialState: CheckListPageData<T>[];
    checklistData: T[];
    onDataChange: (input: number[]) => void;
    maxHeight?: number;
    hasSelectAll?: boolean;
    hasTextField?: boolean;
}

export const CheckListPage = <T extends CheckListDataBase>(props: ICheckListPageProps<T>) => {
    const [data, setData] = useState<CheckListPageData<T>[]>();
    const [checkedItems, setCheckedItems] = useState<number[]>([]);
    const [allSelected, setAllSelected] = useState<boolean>();
    const { t } = useTranslation('common');
    const [keyword, setKeyword] = useState<string>('');
    const theme = useTheme();

    useOnMount(() => {
        setData(props.initialState);
        const checked = props.initialState.filter(s => s.checked).map(m => m.item.id);
        setCheckedItems(checked);
        setAllSelected(checked.length > 0 && checked.length === props.initialState.length);

    });

    const classNames = mergeStyleSets({
        textField: {
            field: {
                color: theme.palette.neutralPrimary,
                'input': {
                    '&::placeholder': { color: theme.palette.neutralPrimary }
                }
            }
        },
        checkListContainer: {
            height: props.maxHeight ?? '100%',
            maxHeight: props.maxHeight ?? 'initial',
            overflow: 'hidden overlay',
            marginTop: 20
        },
        checkListSelectAllContainer: {
            marginTop: 20,
            width: '100%'
        },
        checkListSelectAll: {
            label: {
                fontWeight: 'bold'
            }
        },
        checkListItemRow: {
            width: '100%'
        },
        checkListItem: {
            root: {
                marginTop: 10,
                width: '100%'
            },
            label: {
                alignItems: 'center',
                width: '95%'
            }
        }
    });

    //COMPONENTS INTERACTION
    const onTextFieldChange = (keyword: string) => {
        setKeyword(keyword);
        setData(props.checklistData?.filter(item => item.name.toLowerCase().includes(keyword.trim().toLowerCase())).map((item: T) => {
            return {
                checked: checkedItems.some(i => i === item.id),
                item: item
            } as CheckListPageData<T>
        }));
    };
    const onCheckBoxChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (!data) return;

        let newCheckedItems: number[] = checkedItems;
        const itemId: number = ev?.currentTarget.id ? +ev.currentTarget.id : -1;
        if (checked) {
            newCheckedItems = [...newCheckedItems, itemId];
        } else {
            const index = checkedItems.indexOf(itemId);
            if (index > -1)
                newCheckedItems.splice(index, 1);
        }
        setCheckedItems(newCheckedItems);

        if (newCheckedItems.length === data.length)
            setAllSelected(newCheckedItems.length > 0);
        else
            setAllSelected(false);

        setData(data.map(item => {
            return {
                checked: newCheckedItems.some(i => i === item.item.id),
                item: item.item
            } as CheckListPageData<T>;
        }));

        props.onDataChange(newCheckedItems);
    }
    const onSelectAllClick = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (!data) return;
        let newCheckedItems: number[] = [];

        if (checked) {
            newCheckedItems = data.map(item => item.item.id);
            setAllSelected(true);
        } else {
            newCheckedItems = [];
            setAllSelected(false);
        }

        setCheckedItems(newCheckedItems);

        setData(data?.map(item => {
            return {
                checked: newCheckedItems.some(i => i === item.item.id),
                item: item.item
            } as CheckListPageData<T>;
        }));

        props.onDataChange(newCheckedItems);
    }


    return (
        <React.Fragment>
            {props.hasTextField && <TextField placeholder={t('search')}
                value={keyword}
                onChange={(_, value) => onTextFieldChange(value || '')}
                className={classNames.textField}
            />}
            {props.hasSelectAll && <div className={classNames.checkListSelectAllContainer}>
                <Checkbox
                    label={t(allSelected ? 'deselectAll' : 'selectAll')}
                    checked={allSelected ? true : false}
                    className={classNames.checkListSelectAll}
                    onChange={(ev, checked) => onSelectAllClick(ev, checked)}
                />
            </div>}
            <Stack horizontalAlign='start' className={classNames.checkListContainer} tokens={{ childrenGap: 15 }}>
                {data?.map((item: CheckListPageData<T>) => {
                    return (<Stack.Item key={item.item.id} className={classNames.checkListItemRow}>
                        <Checkbox
                            id={item.item.id.toString()}
                            label={item.item.name}
                            checked={item.checked}
                            className={classNames.checkListItem}
                            onChange={(ev, checked) => onCheckBoxChange(ev, checked)}
                        />
                    </Stack.Item>);
                })}
            </Stack>

        </React.Fragment>
    )
}