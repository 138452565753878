import { IStyleFunction } from "@fluentui/react"
import { IImportDocumentModalPropsStyles, IImportDocumentModalStyles } from "./importDocumentModal.types"

export const ImportDocumentModalGlobalClassNames = {
    root: 'edi-doclab-ImportDocumentModal'
}

export const getStyles: IStyleFunction<IImportDocumentModalPropsStyles, IImportDocumentModalStyles> = (props: IImportDocumentModalPropsStyles): IImportDocumentModalStyles => {
    return {
        root: [
            ImportDocumentModalGlobalClassNames.root,
            {

            }
        ],
        completeStepContainer: {
            display: 'flex',
            alignItems: 'center',
            height: '100%'
        },
        wellDoneImage: {
            flexBasis: '50%',
            flexShrink: 0
        },
        footer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center'
        },
    };
}