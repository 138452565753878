/*eslint-disable @typescript-eslint/no-explicit-any*/
import { nanoid } from "@reduxjs/toolkit";
import { DocLabDispatch } from "../docLabStore";
import { showCopyLinkModal } from "../features/archiveContent";
import { showDocAnalysisDeleteModal } from "../features/docAnalysisContent";
import { startDownload } from "../features/downloadStatus";
import { INavigator } from "../hooks/useNavigator";
import { ArchiveRoleId, ArchiveTypeId, ItemToCopy } from "../models/constants";
import { AnalysisTypeId } from "../models/docAnalysis";
import { docAnalyzesApi } from "../services/docAnalyzes/docAnalyzes.api";

export type DocAnalysisEntry = {
    id: string;
    archiveRoles: ArchiveRoleId[];
    archiveTypes: ArchiveTypeId[];
    label: string;
    icon?: string;
    docAnalysisType: AnalysisTypeId[];
    subActionItems?: DocAnalysisEntry[];
    onClick: (analysis: any , navigator: INavigator, dispatch: DocLabDispatch) => void;
}

const actions: DocAnalysisEntry[] = [
    {
        id: "delete",
        archiveRoles: [ArchiveRoleId.Owner],
        docAnalysisType:[AnalysisTypeId.QualityCheck, AnalysisTypeId.TableOfContent, AnalysisTypeId.GlossaryCheck, AnalysisTypeId.SearchAndCompare],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'commands.delete',
        onClick: (_, __, dispatch) => dispatch(showDocAnalysisDeleteModal(true)),
    },
    {
        id: "downloadReport",
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        docAnalysisType:[AnalysisTypeId.GlossaryCheck],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'commands.downloadReport',
        onClick: ((analysis,__, dispatch) => dispatch(startDownload({ requestId: nanoid(), file: { id: analysis.id, name: `${analysis.name}.xlsx` }, downloadFunction: docAnalyzesApi.downloadFile})))
    },
    {
        id: "create-link",
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader],
        archiveTypes: [ArchiveTypeId.Search, ArchiveTypeId.SearchAndCompare],
        label: 'commands.createLink',
        docAnalysisType: [AnalysisTypeId.QualityCheck, AnalysisTypeId.TableOfContent, AnalysisTypeId.GlossaryCheck ,AnalysisTypeId.SearchAndCompare],
        onClick: (__, ___, dispatch) => dispatch(showCopyLinkModal({showModal:true, type: ItemToCopy.DeepLink}))
    },
];

export const getDocAnalysisActions = (roleId: ArchiveRoleId,docAnalysisType: AnalysisTypeId ) => {
    return actions.filter(action => action.archiveRoles.includes(roleId) && action.docAnalysisType.includes(docAnalysisType));
}