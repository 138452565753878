import { styled } from "@fluentui/react";
import { getStyles } from "./fileImporterOverview.styles";
import { IFileImporterOverviewPropsStyles, IFileImporterOverviewStyles, IFileImporterOverviewProps } from "./fileImporterOverview.types";
import { FileImporterOverviewBase } from "./fileImporterOverview.base";

const FileImporterOverview = styled<IFileImporterOverviewProps, IFileImporterOverviewPropsStyles, IFileImporterOverviewStyles>(
    FileImporterOverviewBase,
    getStyles,
    undefined,
    { scope: 'FileImporterOverview' }
);

export default FileImporterOverview;