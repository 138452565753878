import { apiClient } from '../../../modules/apiClient/apiClient';
import { svcPaths } from '../../../modules/apiClient/config';
import { capacity, fileFormat, formalAspect,  keyphrase, language,location } from './analytics.contracts';

class AnalyticsApiClass {

  async getkeyphrases(archiveId: number) {
    const response = await apiClient.get<keyphrase[]>(`${svcPaths.analytics}/dashboard/${archiveId}/keyphrases`);
    return response.data;
  }
  
  async getfileformats(archiveId: number) {
    const response = await apiClient.get<fileFormat[]>(`${svcPaths.analytics}/dashboard/${archiveId}/fileformats`);
    return response.data
  }

  async getlanguages(archiveId: number) {
    const response = await apiClient.get<language[]>(`${svcPaths.analytics}/dashboard/${archiveId}/languages`);
    return response.data
  }

  async getusagestats(archiveId: number) {
    const response = await apiClient.get<capacity>(`${svcPaths.analytics}/dashboard/${archiveId}/usagestats`);
    return response.data
  }
  
  async GetFormalAspects(archiveId: number) {
    const response = await apiClient.get<formalAspect[]>(`${svcPaths.analytics}/dashboard/${archiveId}/formalaspects`);
    return response.data
  }

  async getlocations(archiveId: number) {
    const response = await apiClient.get<location[]>(`${svcPaths.analytics}/dashboard/${archiveId}/locations`);
    return response.data
  }

}

export const analyticsApi = new AnalyticsApiClass();