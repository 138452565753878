import { styled } from "@fluentui/react";
import { IDeleteTopicsModalProps, IDeleteTopicsModalPropsStyles, IDeleteTopicsModalStyles } from "./deleteTopicsModal.types";
import { getStyles } from "./deleteTopicsModal.styles";
import { DeleteTopicsModalBase } from "./deleteTopicsModal.base";

const DeleteTopicsModal = styled<IDeleteTopicsModalProps, IDeleteTopicsModalPropsStyles, IDeleteTopicsModalStyles>(
    DeleteTopicsModalBase, 
    getStyles,
    undefined,
    { scope: 'DeleteTopicsModal'}
)

export default DeleteTopicsModal;