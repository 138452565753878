import React, { useEffect, useState } from "react";
import { classNamesFunction, DefaultButton, DialogFooter, Modal, PrimaryButton, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { ICreateDatasetModalPropsStyles, ICreateDatasetModalStyles, ICreateDatasetModalProps } from "./createDatasetModal.types";
import { useTranslation } from "react-i18next";
import { useFeedbackList } from "../../../features/feedbackList";
import { knowledgeDsApi } from "../../../services/knowledgeDS.api";

const getClassNames = classNamesFunction<ICreateDatasetModalPropsStyles, ICreateDatasetModalStyles>();

export const CreateDatasetModalBase = (props: ICreateDatasetModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [confirmStep, setConfirmStep] = useState(true)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [datasetName, setDatasetName] = useState("")


    const feedbackList = useFeedbackList();

    const { t } = useTranslation("createDatasetModal");

    useEffect(() => {
        if (props.isOpen) {
            setError(false)
            setConfirmStep(true)
            setDatasetName("")
            setLoading(false)
        }

    }, [props.isOpen]);

    const createDataset = () => {
        const ids = feedbackList.selectedItems.map(i => i.id);
        const skillName = feedbackList.selectedItems[0].skillName;
        setLoading(true)
        knowledgeDsApi.createDataset(ids, skillName).then((name) => {
            setDatasetName(name)
            setConfirmStep(false)
            setLoading(false)
        })
            .catch((e) => {
                setError(true);
            });
    }

    const modalBody = <>
        <div className={classNames.root}>
            {loading ? <div style={{ height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Spinner size={SpinnerSize.large} /> </div> : (
                confirmStep ?
                    <Stack style={{ height: "100%", minHeight: "150px" }} verticalAlign="space-between">
                        <Stack.Item>
                            <div style={{ minHeight: "80px", display: "flex", alignItems: "center", padding: 20 }}>
                                <span>{t('confirmText')}</span>
                            </div>

                        </Stack.Item>
                        <Stack.Item>
                            <div style={{ padding: 5 }}>
                                <DialogFooter>
                                    <DefaultButton onClick={props.onClose} text={t('close')} />
                                    <PrimaryButton
                                        onClick={() => createDataset()} text={t('common:save')} />
                                </DialogFooter>
                            </div>
                        </Stack.Item>
                    </Stack> :
                    /*final step*/
                    <Stack style={{ height: "100%", minHeight: "150px" }} verticalAlign="space-between">
                        <Stack.Item>
                            <div style={{ minHeight: "80px", display: "flex", alignItems: "center", padding: 20 }}>
                                <span>{`Dataset "${datasetName}" ${t('createdText')}.`}</span>
                            </div>
                        </Stack.Item>
                        <div style={{ padding: 5 }}>
                            <DialogFooter>
                                <DefaultButton onClick={props.onClose} text={t('close')} />
                            </DialogFooter>
                        </div>
                    </Stack>
            )}
        </div>
    </>

    return (

        <Modal isOpen={props.isOpen} isBlocking={true}>
            {error ? <>
                <Stack style={{ height: "100%", minHeight: "150px" }} verticalAlign="space-between">
                    <Stack.Item>
                        <div style={{ minHeight: "80px", display: "flex", alignItems: "center", padding: 20 }}>
                            <span>{t('error')}</span>
                        </div>
                    </Stack.Item>
                    <div style={{ padding: 5 }}>
                        <DialogFooter>
                            <DefaultButton onClick={props.onClose} text={t('close')} />
                        </DialogFooter>
                    </div>
                </Stack>
            </> : modalBody}
        </Modal>
    );
}