import { styled } from "@fluentui/react";
import { getStyles } from "./portfolioMembersModal.styles";
import { IPortfolioMembersModalPropsStyles, IPortfolioMembersModalStyles, IPortfolioMembersModalProps } from "./portfolioMembersModal.types";
import { PortfolioMembersModalBase } from "./portfolioMembersModal.base";

const PortfolioMembersModal = styled<IPortfolioMembersModalProps, IPortfolioMembersModalPropsStyles, IPortfolioMembersModalStyles>(
    PortfolioMembersModalBase,
    getStyles,
    undefined,
    { scope: 'PortfolioMembersModal' }
);

export default PortfolioMembersModal;