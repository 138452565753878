import React from "react";
import { classNamesFunction, ProgressIndicator } from "@fluentui/react";
import { IProgressBarPropsStyles, IProgressBarStyles, IProgressBarProps } from "./progressBar.types";
import { useTranslation } from "react-i18next";

const getClassNames = classNamesFunction<IProgressBarPropsStyles, IProgressBarStyles>();

export const ProgressBarBase = (props: IProgressBarProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

  const { t } = useTranslation(['dashboard']);

  return (
    <>
      <ProgressIndicator
        description={(props.data?.formattedFreeSpace) + t('progressBar.description') + props.data?.formattedTotalCapacity}
        percentComplete={props.data && props.data?.usedCapacityPercentage / 100}
        styles={{
          itemName: classNames.progressIndicatoritemName,
          itemDescription: classNames.progressIndicatoritemDescription,
          progressBar: (props.data && props.data.usedCapacityPercentage / 100 >= 0.9) ? classNames.progressIndicatorRed : classNames.progressIndicator,
          progressTrack: classNames.progressTrack,
        }}
      />
    </>
  );
}