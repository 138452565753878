import { styled } from "@fluentui/react";
import { getStyles } from "./archiveMembersSettings.styles";
import { IArchiveMembersSettingsPropsStyles, IArchiveMembersSettingsStyles, IArchiveMembersSettingsProps } from "./archiveMembersSettings.types";
import { ArchiveMembersSettingsBase } from "./archiveMembersSettings.base";

const ArchiveMembersSettings = styled<IArchiveMembersSettingsProps, IArchiveMembersSettingsPropsStyles, IArchiveMembersSettingsStyles>(
    ArchiveMembersSettingsBase,
    getStyles,
    undefined,
    { scope: 'ArchiveMembersSettings' }
);

export default ArchiveMembersSettings;