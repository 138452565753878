import { styled } from "@fluentui/react";
import { getStyles } from "./tocResult.styles";
import { ITocResultPropsStyles, ITocResultStyles, ITocResultProps } from "./tocResult.types";
import { TocResultBase } from "./tocResult.base";

const TocResult = styled<ITocResultProps, ITocResultPropsStyles, ITocResultStyles>(
    TocResultBase,
    getStyles,
    undefined,
    { scope: 'TocResult' }
);

export default TocResult;