import { IStyleFunction, ITextFieldStyleProps, ITextFieldStyles, keyframes } from "@fluentui/react"
import { ICreateTableOfContentModalPropsStyles, ICreateTableOfContentModalStyles } from "./createTableOfContentModal.types"

export const CreateTableOfContentModalGlobalClassNames = {
  root: 'edi-create-table-of-content-modal-root',
  spinner: 'edi-create-table-of-content-modal-spinner',
}

export const getStyles: IStyleFunction<ICreateTableOfContentModalPropsStyles, ICreateTableOfContentModalStyles> = (props: ICreateTableOfContentModalPropsStyles): ICreateTableOfContentModalStyles => {
  const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
  return {
    root: [
      CreateTableOfContentModalGlobalClassNames.root,
      {
        // CSS PROPS AND SELECTORS
      }
    ],
    scrollableBody:{
      
      '*::-webkit-scrollbar': {
        width: 16,
        height: 16
      },
      '*::-webkit-scrollbar-thumb': {
        border: '5px solid transparent',
        backgroundClip: 'padding-box',
        backgroundColor: 'transparent',
        borderRadius: 25
      },
      '*:hover::-webkit-scrollbar-thumb': {
        backgroundColor: palette.neutralSecondary// 'rgba(66, 66, 66, 0.3)'
      },
      '*::-webkit-scrollbar-thumb:hover': {
        border: '2px solid transparent'
      }
    },
    spinner: [
      CreateTableOfContentModalGlobalClassNames.spinner,
      {
        width: '100%',
        height: 600,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    ],
    subComponentStyles: {
      textFieldStyle: (props: ITextFieldStyleProps & { icon: string }): ITextFieldStyles => {
        const { theme, icon } = props;

        return {
          root: {
            paddingBottom: 20,
            paddingRight: '80px',
            flex: '1 1 0'
  
          },
          fieldGroup: {
            selectors:{
                '::after':{
                    inset: '0px !important'
                }
            }
        },
        prefix: {},
        field: {},
        icon: {},
        description: {},
        wrapper: {width: '250px'},
        revealButton: {},
        revealIcon: {},
        revealSpan: {},
        errorMessage: {
            position: 'absolute'
        },
        suffix: [
            { background: 'transparent', },
            icon === 'Error' && { color: theme.palette.redDark },
            icon === 'Completed' && { color: theme.palette.greenDark },
            icon === 'ProgressRingDots' && {
                '> i': {
                    animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite`
                }
            }
        ],
        subComponentStyles: {
            label: {}
        }
        }
    } 
    }
  };
}