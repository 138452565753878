import { IStyleFunction } from "@fluentui/react"
import { IDeleteTopicModalPropsStyles, IDeleteTopicModalStyles } from "./deleteTopicModal.types";

export const DeleteTopicModalGlobalClassNames = {
    root: 'edi-profile-view-root'
}

export const getStyles: IStyleFunction<IDeleteTopicModalPropsStyles, IDeleteTopicModalStyles> = (props: IDeleteTopicModalPropsStyles): IDeleteTopicModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [ DeleteTopicModalGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px',
                selectors: {
                    ' .edi-profile-view-action': {
                        right: '15px',
                        bottom: '15px',
                    }
                }
            }            
        ],
        footerContainer:{
            overflow: 'hidden',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        },
        footerButtons:
        {
            'margin-left': '10px',
            marginBottom: '2px'
        },
        errorMessage: {
            color: palette.red
        }
    }
}