import { Icon, IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, ITheme, keyframes, mergeStyleSets, TextField } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helpers } from "../../../../../utilities/helpers";
import { Constants } from "../../../../models/constants";
import { portfolioApi } from "../../../../services/portfolio/portfolio.api";


export type FirstPageData = {
    name: string;
    isValid: boolean;
}

export interface ICreatePortfolioFirstPageProps {
    theme?: ITheme;
    initialState: FirstPageData;
    onPortfolioChange: (input: FirstPageData) => void;
}

const classNames = mergeStyleSets({
    formItem: [
        'edi-create-portfolio-modal-form-item',
        {
            minHeight: '100px',
            ':not(:first-child)': {
                marginTop: 30
            }
        }
    ]
})

export const CreatePortfolio = (props: ICreatePortfolioFirstPageProps) => {
    const [data, setData] = useState<FirstPageData>(props.initialState);
    const [validationIcon, setValidationIcon] = useState('Error');
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const { t } = useTranslation(['createPortfolioModal', 'common']);
    useEffect(() => props.onPortfolioChange(data), [data, props]);

    const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
        setData({ ...data, isValid: !error, name: value ?? "" });
        setValidationIcon(error ? 'Error' : 'Completed');
    }, [data]);


    const validatePortfolioName = useCallback(async (value: string) => {
        //if (!value)
        //    return `${t('common:fieldRequired')}`;

        setValidationIcon('ProgressRingDots');

        try {
            const [isValid] = await Promise.all([portfolioApi.isPortfolioNameAvailable(value), Helpers.delay(500)]);
            return isValid ? "" : t('nameNotAvailable');
        }
        catch {
            return t('common:genericErrorCheckName');
        }
    }, [t]);

    const styles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = () => {
        return {
            errorMessage: {
                position: 'absolute'
            },
            suffix: [
                { background: 'transparent', },
                validationIcon === 'Error' && { color: palette.redDark },
                validationIcon === 'Completed' && { color: palette.greenDark },
                validationIcon === 'ProgressRingDots' && {
                    '> i': {
                        animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite`
                    }
                }
            ]
        }
    }

    return (
        <>
            <div className={classNames.formItem}>
                <TextField
                    label={t('common:name')}
                    maxLength={Constants.MAX_LENGTH_PORTFOLIO_NAME}
                    placeholder={t('namePlaceholder')}
                    deferredValidationTime={250}
                    onGetErrorMessage={validatePortfolioName}
                    required
                    defaultValue={data.name}
                    autoFocus
                    autoComplete={"off"}
                    styles={styles}
                    onNotifyValidationResult={onNameValidationResult}
                    onChange={() => setData({ ...data, isValid: false })}
                    onRenderSuffix={() => <Icon iconName={validationIcon} />}
                />
            </div>
        </>
    )
}