import { styled } from "@fluentui/react";
import { ExportModalFilesMetadataChangesReportBase } from "./ExportModalFilesMetadataChangesReport.base";
import { getStyles } from "./ExportModalFilesMetadataChangesReport.styles";
import { IExportModalFilesMetadataChangesReportBaseProps, IExportModalFilesMetadataChangesReportBasePropsStyles, IExportModalFilesMetadataChangesReportBaseStyles } from "./ExportModalFilesMetadataChangesReport.types";

const ExportModalFilesMetadataChangesReport = styled<IExportModalFilesMetadataChangesReportBaseProps, IExportModalFilesMetadataChangesReportBasePropsStyles, IExportModalFilesMetadataChangesReportBaseStyles>(
    ExportModalFilesMetadataChangesReportBase,
    getStyles,
    undefined,
    { scope: 'ExportModalFilesMetadataChangesReport'}
)

export default ExportModalFilesMetadataChangesReport;