import React, { useState } from "react";
import { classNamesFunction, PrimaryButton, TextField } from "@fluentui/react";
import { ICreateFolderModalPropsStyles, ICreateFolderModalStyles, ICreateFolderModalProps } from "./createFolderModal.types";
import { loadFolderContent, useArchiveContent } from "../../../features/archiveContent";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { Constants } from "../../../models/constants";
import { useDocLabDispatch } from "../../../docLabStore";
import { insertCall, setCall } from "../../../features/callNotification";
import { Call, callsList } from "../../../models/callsApi";
import { nanoid } from "@reduxjs/toolkit";
import { archivesApi } from "../../../services/archives/archives.api";
import { Helpers } from "../../../../utilities/helpers";
const getClassNames = classNamesFunction<ICreateFolderModalPropsStyles, ICreateFolderModalStyles>();

export const CreateFolderModalBase = (props: ICreateFolderModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { folderModalShown } = useArchiveContent();
    const [data, setData] = useState({ name: "", isValid: true });
    const { currentFolderId } = useArchiveContent();
    const { t } = useTranslation(['createFolderModal', 'common']);
    const dispatch = useDocLabDispatch();

    const onDismissed = () => {
        setData({ name: "", isValid: true });
    }

    const createFolder = async () => {
        const createFolderCall: Call = {
            type: callsList.createFolder,
            nameOperation: t('message').concat(data.name).concat('"'),
            errors: [
                { code: 403, message: t('notPermissions') },
                { code: 404, message: t("notFound") },
                { code: 409, message: t('alreadyExists') },
                { code: 422, message: t("excessiveDepth") },
                { code: 500, message: t('common:createGenericErrorApi').concat(t('ofFolder')) }
            ]
        };
        const id = nanoid();
        const payload = { requestId: id, notification: createFolderCall }
        dispatch(insertCall(payload));
        props.onClose && props.onClose();

        try {
            await Promise.all([archivesApi.createFolder(currentFolderId, data.name), Helpers.delay(500)]);
            const successPayload = { requestId: id, success: true, message: t('successMessage') }
            dispatch(setCall(successPayload));
            dispatch(loadFolderContent({ resetPage: true }));
        }
        catch (error) {
            let errorMessage = '';
            switch (error.code) {
                case 403: errorMessage = t('notPermissions'); break;
                case 404: errorMessage = t("notFound"); break;
                case 409: errorMessage = t('alreadyExists'); break;
                case 422: errorMessage = t("excessiveDepth"); break;
                default: errorMessage = t('common:genericErrorApi'); break;
            }
            const failurePayload = { requestId: id, success: false, message: errorMessage }
            dispatch(setCall(failurePayload));
        }
    }

    const body = () => {
        return (
            <div className={classNames.bodyModal}>
                <TextField
                    maxLength={Constants.MAX_LENGTH_ARCHIVE_ITEM_NAME}
                    placeholder={t('namePlaceholder')}
                    required
                    defaultValue={data.name}
                    autoFocus
                    rows={1}
                    autoComplete={"off"}
                    styles={!data.isValid ? classNames.subComponentStyles.textFieldStyleWithError : classNames.subComponentStyles.textFieldStyle}
                    onChange={(_, value) => { setData({ name: value ?? "", isValid: true }) }}
                />
            </div>
        )
    }
    const footer = () => {
        return (<div className={classNames.createButtonContainer}>
            <PrimaryButton disabled={data.name === ""} onClick={createFolder} >{t('common:create')}</PrimaryButton>
        </div>);
    }

    return (
        <EdiModal
            isOpen={folderModalShown}
            showCloseIcon={true}
            onCloseClick={props.onClose}
            title={t('createNewFolder')}
            body={body()}
            footer={footer()}
            onDismissed={onDismissed}
        />
    );
}