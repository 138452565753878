import React, { useState } from "react";
import { classNamesFunction, Icon, Stack } from "@fluentui/react";
import { IKnowledgeEdiFileSelectorPropsStyles, IKnowledgeEdiFileSelectorStyles, IKnowledgeEdiFileSelectorProps, EdiItem } from "./knowledgeEdiFileSelector.types";
import FileNavigator from "../common/fileNavigator/fileNavigator";
import { archivesApi } from "../../../docLab/services/archives/archives.api";
import { ArchiveTypeId } from "../../../docLab/models/constants";
import { useOnMount } from "../../../utilities/hooks";
import { Archive } from "../../../docLab/models/archive";
import FileIconCell from "../../../common/components/fileIconCell/fileIconCell";
import { ArchiveItem } from "../../../docLab/models/archiveItem";
import { NavigableItem } from "../../models/navigableFile";
import { EdiItemType } from "../common/importDocumentModal/importDocumentModal.types";
import { Helpers } from "../../../utilities/helpers";
import { UploadStatus } from "../common/fileUploader/fileUploader.types";
import { useTranslation } from "react-i18next";

const getClassNames = classNamesFunction<IKnowledgeEdiFileSelectorPropsStyles, IKnowledgeEdiFileSelectorStyles>();

export const KnowledgeEdiFileSelectorBase = (props: IKnowledgeEdiFileSelectorProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [currentLevel, setCurrentLevel] = useState<EdiItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation('importKnowledgeLabDocument')

    useOnMount(() => {
        loadArchives();
    })
    const loadArchives = async () => {
        setIsLoading(true);
        const archives = await archivesApi.getArchives([ArchiveTypeId.Personal, ArchiveTypeId.Unit]);
        setCurrentLevel(archives.map(a => mapArchiveToNavigatorItem(a)));
        setIsLoading(false);
    }
    const getFolderContent = async (folder: NavigableItem) => {
        const ediItem = folder as EdiItem;
        const id = ediItem.type === EdiItemType.archive ? ediItem.rootId : parseInt(ediItem.id);
        if (!id) return;
        setIsLoading(true);
        const request = {
            id: id,
            orderBy: 'Name',
            isAscending: true,
            pageNumber: 0,
            pageSize: 1000
        }
        const result = await archivesApi.getFolderContent(request);
        setCurrentLevel(result.contentItems.map(a => mapToNavigatorItem(folder, a)));
        setIsLoading(false);
    }

    const mapArchiveToNavigatorItem = (archive: Archive): EdiItem => {
        return {
            id: archive.id.toString(),
            name: archive.name,
            type: EdiItemType.archive,
            createdBy: '',
            createdOn: '',
            extension: '',
            isNavigable: true,
            rootId: archive.rootFolderId,
            fileUploadStatus: UploadStatus.ToUpload,
            isValid: true,
        }
    }
    const mapToNavigatorItem = (current: NavigableItem, item: ArchiveItem): EdiItem => {
        return {
            id: item.id.toString(),
            name: item.name,
            type: item.isFolder ? EdiItemType.folder : EdiItemType.file,
            createdBy: item.createdBy,
            createdOn: item.createdOn,
            extension: !item.isFolder ? item.name.split('.')[item.name.split('.').length - 1] : '',
            isNavigable: item.isFolder,
            rootId: undefined,
            parent: current,
            size: item.size !== undefined ? Helpers.formatSize(item.size) : undefined,
            fileUploadStatus: UploadStatus.ToUpload,
            isValid: true,
            filePath: getBlobFilePath(current, item.id.toString()),
        }
    }

    const getBlobFilePath = (current: NavigableItem, id?: string): string => {
        let path = ``;

        if (current.parent)
            path = `${getBlobFilePath(current.parent)}`;
        else
            path = `archive_${current.id}`;

        if (id)
            path = `${path}/${id}`;

        return path;
    }


    const renderIcon = (item: NavigableItem) => {
        const ediItem = item as EdiItem;
        if (ediItem.type === EdiItemType.folder) return <FileIconCell isFolder={true} fileExtension="" />
        else if (ediItem.type === EdiItemType.archive)
            return <Icon iconName="Archive" />
        else if (ediItem.type === EdiItemType.file) return <FileIconCell isFolder={false} fileExtension={item.extension ? item.extension : ''} />
        return <></>
    }

    const onChangeSelection = (items: NavigableItem[]) => {
        const selected = items.map(i => i as EdiItem);
        props.onChange && props.onChange(selected);
    }

    return (
        <div className={classNames.root}>

            <Stack>
                <Stack.Item>
                    <label>{t("steps.ediFileSelector.selectMessage")}</label>
                </Stack.Item>
                <Stack.Item>
                    <FileNavigator
                        height={368}
                        defaultSelected={props.defaultSelected || []}
                        currentLevel={currentLevel}
                        onRenderIcon={renderIcon}
                        acceptedExtensions={props.allowedFileTypes}
                        //onRenderName={renderName}
                        onGoingInto={(folder) => {
                            if (!folder) loadArchives();
                            else {
                                const f = folder as EdiItem;
                                (f.type === EdiItemType.archive && f.rootId) && getFolderContent(f);
                                (f.type === EdiItemType.folder) && getFolderContent(f);
                            }
                        }}
                        isLoading={isLoading}
                        onChangeSelection={onChangeSelection}
                    />
                </Stack.Item>
            </Stack>

        </div>
    );
}