import { styled } from "@fluentui/react";
import { IDocVerifyModalBodyProps, IDocVerifyModalBodyPropsStyles, IDocVerifyModalBodyStyles } from "./docVerifyModalBody.types";
import { getStyles } from "./docVerifyModalBody.styles";
import { DocVerifyModalBodyBase } from "./docVerifyModalBody.base";

const DocVerifyModalBody = styled<IDocVerifyModalBodyProps, IDocVerifyModalBodyPropsStyles, IDocVerifyModalBodyStyles>(
    DocVerifyModalBodyBase,
    getStyles,
    undefined,
    { scope: 'DocVerifyModalBody' }
);

export default DocVerifyModalBody;