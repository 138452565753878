import { styled } from "@fluentui/react";
import { RecycleBinBase } from "./recycleBin.base";
import { getStyles } from "./recycleBin.styles";
import { IRecycleBinProps, IRecycleBinPropsStyles, IRecycleBinStyles } from "./recycleBin.types";

const RecycleBin = styled<IRecycleBinProps, IRecycleBinPropsStyles, IRecycleBinStyles>(
    RecycleBinBase,
    getStyles,
    undefined,
    { scope: 'RecycleBin'}
);

export default RecycleBin;