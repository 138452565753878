import React from "react";
import { classNamesFunction, Icon, IDatePickerStyles, TooltipHost } from "@fluentui/react";
import { ICustomWidgetDatePropsStyles, ICustomWidgetDateStyles, ICustomWidgetDateProps } from "./customWidgetDate.types";
import DatePicker from "../../../datePicker/datePicker";
import { DateTime } from "luxon";

const getClassNames = classNamesFunction<ICustomWidgetDatePropsStyles, ICustomWidgetDateStyles>();

export const CustomWidgetDateBase = (props: ICustomWidgetDateProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    const datePickerStyle = (): IDatePickerStyles => {
        return {
            root: {
                paddingTop: 5,
                height: 37
            },
            textField: {},
            callout: {},
            icon: {}
        }
    }
    const customDateWidget = () => {
        try{
            return (
                <div>
                    <label className={(props.dateProps.rawErrors && props.dateProps.rawErrors.length > 0)
                        && (!props.dateProps.value || props.dateProps.value.length === 0) && !props.disabled ?
                        classNames.errorLabel :
                        classNames.label}>{props.dateProps.label}
                        {props.dateProps.required && <span className={classNames.required}>*</span>}
                        {props.dateProps.options.description && 
                        <TooltipHost styles={{root:{marginLeft:5}}} content={props.dateProps.options.description}>
                        <Icon
                            styles={{ root: { fontSize: 13, cursor: 'pointer' } }}
                            iconName="Info" />
                        </TooltipHost>
                        }
                    </label>
                    <DatePicker
                        resetIconStyle={classNames.resetIconStyle}
                        styles={datePickerStyle}
                        disabled={props.dateProps.disabled}
                        resetButton={!props.dateProps.disabled}
                        onReset={() => props.dateProps.onChange(undefined)}
                        disableAutoFocus={false}
                        inputValue={props.dateProps.value && new Date(props.dateProps.value)}
                        dateCallback={(date) => {
                            const newDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
                            props.dateProps.onChange(newDate.toString())
                        }} />
                </div>)
        }
        catch(ex){
            props.hasError();
            return <div>ERROR</div>
        }
        
    }

    return (
        <>{customDateWidget()}</>
    );
}