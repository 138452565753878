import { styled } from "@fluentui/react";
import { getStyles } from "./qualityCheckResult.styles";
import { IQualityCheckResultsPropsStyles, IQualityCheckResultsStyles, IQualityCheckResultsProps } from "./qualityCheckResult.types";
import { QualityCheckResultsBase } from "./qualityCheckResult.base";

const QualityCheckResults = styled<IQualityCheckResultsProps, IQualityCheckResultsPropsStyles, IQualityCheckResultsStyles>(
    QualityCheckResultsBase,
    getStyles,
    undefined,
    { scope: 'QualityCheckResults' }
);

export default QualityCheckResults;