import { styled } from "@fluentui/react";
import { IImportDocsTreeModalProps, IImportDocsTreeModalPropsStyles, IImportDocsTreeModalStyles } from "./importDocsTreeModal.types";
import { getStyles } from "./importDocsTreeModal.styles";
import { ImportDocsTreeModalBase } from "./importDocsTreeModal.base";

const ImportDocsTreeModal = styled<IImportDocsTreeModalProps, IImportDocsTreeModalPropsStyles, IImportDocsTreeModalStyles>(
    ImportDocsTreeModalBase,
    getStyles,
    undefined,
    { scope: 'ImportDocsTreeModal' }
);

export default ImportDocsTreeModal;