/*eslint-disable sonarjs/cognitive-complexity */
import { ChoiceGroup, classNamesFunction, DefaultButton, DetailsList, DetailsRow, Dialog, DialogFooter, DirectionalHint, IChoiceGroupOption, IColumn, IconButton, IContextualMenuProps, IIconProps, PrimaryButton, SelectionMode, Spinner, SpinnerSize } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { ErrorsType } from "../../../../../docLab/models/callsApi";
import { Helpers } from "../../../../../utilities/helpers";
import { useOnMount } from "../../../../../utilities/hooks";
import { useCurrentPortfolio } from "../../../../hooks/useCurrentPortfolio";
import { useNavigator } from "../../../../hooks/useNavigator";
import { PersonaDetails, UserShort } from "../../../../models/user";
import { WorkFlowShort } from "../../../../models/workflow";
import { workflowApi } from "../../../../services/workflow/workflow.api";
import { GetWorkFlowsRequest } from "../../../../services/workflow/workflow.contracts";
import WorkflowStartModal from "../../../workflow/workflowStartModal/workflowStartModal";
import MemberList from "../../common/memberList/memberList";
import { IWorkFlowListProps, IWorkFlowListPropsStyles, IWorkFlowListStyles } from "./workFlowList.types";

const getClassNames = classNamesFunction<IWorkFlowListPropsStyles, IWorkFlowListStyles>();

const pageSize = 20;

const initialFilters: GetWorkFlowsRequest = {
    pageNumber: 0,
    pageSize: pageSize,
    orderBy: "CreatedOn",
    isAscending: false,
    portfolioId: undefined,
    isCreator: undefined
}

export const WorkFlowListBase = (props: IWorkFlowListProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['workflowList', 'workflows', 'common']);
    const [workFlows, setWorkFlows] = useState<WorkFlowShort[]>([]);
    const currentPortfolio = useCurrentPortfolio();
    const [loadMoreCall, setLoadMoreCall] = useState(false);
    const [noMore, setNoMore] = useState(false);
    const [reload, setReload] = useState(false);
    const [deleteModalShown, setDeleteModalShown] = useState(false);
    const [listLoading, setListLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [selectedWorkFlow, setSelectedWorkFlow] = useState(0);
    const [createWorkflow, setCreateWorkflow] = useState<boolean>(false); //eslint-disable-line @typescript-eslint/no-unused-vars
    const addIcon: IIconProps = { iconName: 'Add', style: { height: 'auto' } };
    const [filters, setFilters] = useState<GetWorkFlowsRequest>(initialFilters);
    const [choiceGroupSelected, setChoiceGroupSelected] = useState<string>('received');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const location = useLocation();
    const navigator = useNavigator();

    useOnMount(() => {
        registerEvent();
    });

    const registerEvent = () => {
        const listElm = document.querySelector('#workFlowsListZone .ms-DetailsList-contentWrapper');
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50) {
                const button = document.getElementById("click");
                button && button.click();
            }
        });
    }

    const loadMore = () => {
        if (listLoading || noMore || loadMoreCall)
            return;
        setFilters({ ...filters, pageNumber: workFlows.length < (filters.pageSize ?? 0) ? (filters.pageNumber ?? 0) : (filters.pageNumber ?? 0) + 1 })
    }

    const getWorkFlowList = async (loadMore?: boolean) => {
        if (!currentPortfolio)
            return;

        !loadMore && setListLoading(true);
        setLoadMoreCall(true);
        setErrorMessage('');

        try {
            const [elements] =
                await Promise.all([workflowApi.getWorkFlowList({ ...filters, portfolioId: currentPortfolio.id, pageNumber: filters.pageNumber ?? 0, isCreator: choiceGroupSelected !== 'received' }),
                Helpers.delay(500)]);

            if (elements.length < pageSize) {
                setNoMore(true);
            }
            if (filters.pageNumber === 0) {
                setNoMore(false);
                setWorkFlows(elements);
            }
            else {
                setWorkFlows(workFlows.concat(elements));
            }
        }
        catch (er) {
            const error: ErrorsType = er as ErrorsType;
            if (error.code === 403) { setErrorMessage(t('notPermissions')); }
            else { setErrorMessage(t('common:genericErrorApi')); }
        }
        finally {
            !loadMore && setListLoading(false);
            setLoadMoreCall(false);
        }
    }

    const deleteItem = async () => {
        try {
            setDeleting(true);
            await workflowApi.delete(selectedWorkFlow);
            setDeleting(false);
            setDeleteModalShown(false);
            reloadList();
        }
        catch (error) {
            let errorMessage = '';
            if (error.code === 403) {
                errorMessage = t('notPermissions');
            }
            else {
                errorMessage = t('common:genericErrorApi');
            }
            setErrorMessage(errorMessage)
        }
        finally {
            setDeleting(false);
        }
    }

    // const sortByColumn = (_: React.MouseEvent, column: IColumn) => {
    //     if (column.fieldName && column.isSorted !== undefined) {
    //         setFilters({ ...filters, orderBy: column.fieldName, isAscending: !filters.isAscending, pageNumber: 0 });
    //         const listElm = document.querySelector('#workFlowsListZone .ms-DetailsList-contentWrapper');
    //         listElm && listElm.scrollTo(0, 0);
    //     }
    // };
    const mapToPersonaDetails = (users: UserShort[]): PersonaDetails[] => {
        return users.map(a => {
            return { firstName: a.firstName, lastName: a.lastName, email: '' } as PersonaDetails;
        });
    }
    

    useEffect(() => {
        getWorkFlowList();
    }, [filters, currentPortfolio, choiceGroupSelected, reload]); //eslint-disable-line react-hooks/exhaustive-deps


    const menuProps = (workFlowId: number): IContextualMenuProps => {
        return {
            items: [{
                key: 'delete',
                text: t('common:commands.delete'),
                onClick: () => { setDeleteModalShown(true); setSelectedWorkFlow(workFlowId) },
            }],
            directionalHint: DirectionalHint.rightTopEdge
        }
    }

    const columns: IColumn[] = [
        {
            key: 'name',
            name: t('column.name'),
            ariaLabel: 'name',
            isResizable: true,
            fieldName: 'Name',
            minWidth: 120,
            maxWidth: 150,
            isCollapsible: true,
            // isSorted: filters.orderBy === 'Name',
            // isSortedDescending: !filters.isAscending,
            // onColumnClick: (_, column) => sortByColumn(_, column),
            onRender: function getItemName(item: WorkFlowShort) {
                return <div className={classNames.workflowListNameWrapper}>
                    <span
                        data-selection-disabled
                        className={classNames.workflowName}
                        title={item.name}
                        onClick={() => navigator.goToPath(`${location.pathname}/${item.id}`)}
                    >
                        {item.name}
                    </span>
                    {choiceGroupSelected !== 'received' ?
                        <div className={classNames.menuIconWrapper}>
                            <IconButton
                                styles={classNames.subComponentStyles.iconButton()}
                                iconProps={{ iconName: 'More' }}
                                menuProps={menuProps(item.id)}
                            />
                        </div> : ''}
                </div>
            }
        },
        {
            key: 'status',
            name: t('column.status'),
            ariaLabel: 'status',
            fieldName: 'status',
            minWidth: 120,
            maxWidth: 180,
            isCollapsible: true,
            isResizable: true,
            onRender: function getItemType(item: WorkFlowShort) {
                return <div className={classNames.workflowListNameWrapper}>
                    <span title={t(`status.${item.relativeStatus}`)}>{t(`status.${item.relativeStatus}`)}</span>
                </div>
            }
        },
        {
            key: 'activity',
            name: t('column.activity'),
            ariaLabel: 'activity',
            fieldName: 'activity',
            minWidth: 120,
            maxWidth: 180,
            isCollapsible: true,
            isResizable: true,
            // isSorted: filters.orderBy === 'activity',
            // isSortedDescending: !filters.isAscending,
            // onColumnClick: (_, column) => sortByColumn(_, column),
            onRender: function getItemType(item: WorkFlowShort) {
                return <div className={classNames.workflowListNameWrapper}>
                    <span title={item.activityName}>{item.activityName}</span>
                </div>
            }
        },
        {
            key: 'documentName',
            name: t('column.documentName'),
            ariaLabel: 'documentName',
            fieldName: 'documentName',
            minWidth: 120,
            maxWidth: 230,
            isResizable: true,
            isCollapsible: true,
            onRender: function getItemDate(item: WorkFlowShort) {
                return <div className={classNames.workflowListNameWrapper}>
                    <span title={item.documentName}>{item.documentName}</span>
                </div>
            }
        },
        {
            key: 'createdOn',
            name: t("column.createdOn"),
            ariaLabel: 'createdOn',
            fieldName: 'createdOn',
            minWidth: 95,
            maxWidth: 190,
            isResizable: true,
            isCollapsible: true,
            // isSorted: filters.orderBy === 'createdOn',
            // isSortedDescending: !filters.isAscending,
            // onColumnClick: (_, column) => sortByColumn(_, column),
            onRender: function getItemType(item: WorkFlowShort) {
                if (item.createdOn)
                    return <span>{Helpers.getShortDate(item.createdOn)}</span>
            }
        },
        {
            key: 'createdBy',
            name: t("column.createdBy"),
            ariaLabel: 'createdBy',
            fieldName: 'createdBy',
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isCollapsible: true,
            // isSorted: filters.orderBy === 'createdBy',
            // isSortedDescending: !filters.isAscending,
            // onColumnClick: (_, column) => sortByColumn(_, column),
            onRender: function getItemDate(item: WorkFlowShort) {
                if (item.creator)
                    return <span><MemberList members={[{firstName: item.creator.firstName, lastName: item.creator.lastName, email: ""}]} /></span>
            }
        },
        {
            key: 'assignedTo',
            name: t("column.assignedTo"),
            ariaLabel: 'assignedTo',
            fieldName: 'assignedTo',
            minWidth: 100,
            maxWidth: 210,
            isResizable: true,
            isCollapsible: true,
            // isSorted: filters.orderBy === 'assignedTo',
            // isSortedDescending: !filters.isAscending,
            // onColumnClick: (_, column) => sortByColumn(_, column),
            onRender: function getItemFrom(item: WorkFlowShort) {
                return <MemberList members={mapToPersonaDetails(item.assignedTo)} sliceLength={1} />;
            }
        },
        {
            key: 'members',
            name: t("column.members"),
            ariaLabel: 'members',
            fieldName: 'members',
            minWidth: 100,
            maxWidth: 210,
            isResizable: true,
            isCollapsible: true,
            // isSorted: filters.orderBy === 'members',
            // isSortedDescending: !filters.isAscending,
            // onColumnClick: (_, column) => sortByColumn(_, column),
            onRender: function getItemFrom(item: WorkFlowShort) {
                return <MemberList members={mapToPersonaDetails(item.involvedPeople)} sliceLength={1} />;
            }
        },
    ];

    const reloadList = () => {
        setWorkFlows([]);
        setFilters(initialFilters);
        setReload(!reload);
    }

    useEffect(() => {
        if (!listLoading) registerEvent()
    }, [listLoading]);

    const emptyList = useCallback(() => {
        if (workFlows.length !== 0)
            return null;

        return (
            <div className={classNames.emptyList}>
                <span>{t("common:emptyList")}</span>
            </div>
        )
    }, [workFlows.length, t, classNames.emptyList]);

    const choiceGroupOptions: IChoiceGroupOption[] = [
        { key: 'received', text: t('receivedRequests') },
        { key: 'sent', text: t('sentRequests') }
    ];

    const onChoiceGroupSelect = (option: IChoiceGroupOption | undefined): void => {
        if (option?.key !== undefined) setChoiceGroupSelected(option?.key);
        setWorkFlows([]);
        setFilters(initialFilters);
    }

    return (
        <>
            {
                createWorkflow &&
                <WorkflowStartModal
                    onSuccess={() => { setCreateWorkflow(false); reloadList() }}
                    onClose={() => setCreateWorkflow(false)}
                />
            }
            <div style={{ marginLeft: '30px', marginTop: '30px' }}>
                <DefaultButton
                    text={t("workflows:addNew")}
                    iconProps={addIcon}
                    onClick={() => setCreateWorkflow(true)}
                />
                <div style={{ marginTop: '20px' }}>
                    <ChoiceGroup
                        defaultSelectedKey={choiceGroupSelected}
                        selectedKey={choiceGroupSelected}
                        options={choiceGroupOptions}
                        disabled={listLoading || errorMessage !== ''}
                        onChange={(_, option) => onChoiceGroupSelect(option)}
                        styles={classNames.subComponentStyles.choiceGroupStyles}
                    />
                    {listLoading && workFlows.length === 0 ?
                        <div className={classNames.centeredContainer}>
                            <Spinner size={SpinnerSize.large} />
                        </div>
                        : errorMessage !== '' ?
                            <div className={classNames.centeredContainer}>
                                <TeamsImage
                                    imageName={ImageName.Error1}
                                    caption={errorMessage}
                                />
                            </div>
                            :
                            <div className={classNames.detailListContainer} id={"workFlowsListZone"}>
                                <>
                                    <div style={{ height: 'calc(100% - 10px)', overflow: 'overlay' }}>
                                        <DetailsList
                                            items={workFlows}
                                            columns={columns}
                                            selectionMode={SelectionMode.none}
                                            isHeaderVisible={true}
                                            getKey={((item: WorkFlowShort) => item.id.toString())}
                                            setKey="none"
                                            styles={classNames.subComponentStyles.detailsList}
                                            onRenderRow={props => props ? <DetailsRow {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
                                            onRenderDetailsFooter={emptyList}
                                        />
                                    </div>
                                    <div className={classNames.load}>
                                        <PrimaryButton id={noMore ? "noClick" : "click"} onClick={loadMore} />
                                    </div>
                                </>
                            </div>
                    }
                </div>
            </div>

            {deleteModalShown && <Dialog
                isOpen={deleteModalShown}
                dialogContentProps={{
                    title: t('deleteTitle'),
                    showCloseButton: true,
                    onDismiss: () => { setDeleteModalShown(false); setSelectedWorkFlow(0) },
                    subText: t('deleteMessage'),
                }}
            >
                {errorMessage && <span style={{ marginLeft: '55%' }}>{errorMessage}</span>}
                <div className={classNames.deleteFooterContainer}>
                    <div className={classNames.deleteButtonContainer}>
                        {!errorMessage && deleting && <Spinner size={SpinnerSize.large}></Spinner>}
                        <DialogFooter styles={classNames.subComponentStyles.deleteDialogFooterContainer}>
                            <DefaultButton disabled={deleting} onClick={() => { setDeleteModalShown(false); setSelectedWorkFlow(0) }} text={t('workflows:undoEliminate')} />
                            <PrimaryButton disabled={deleting} styles={classNames.subComponentStyles.deletePrimaryButtonDisabled()} onClick={deleteItem} text={t('workflows:confirmEliminate')} />
                        </DialogFooter>
                    </div>
                </div>
            </Dialog>}
        </>
    );
}