import { IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, ILabelStyles, IShimmeredDetailsListStyleProps, IShimmeredDetailsListStyles, IStyleFunction } from "@fluentui/react";
import { IMemberViewBasePropsStyles, IMemberViewBaseStyles } from "./memberView.types";


export const MemberViewGlobalClassNames = {
    root: 'edi-member-view-root', 
    headerRow: 'edi-member-view-header-row',
    load: 'edi-member-view-load',
    iconTagLabel: 'edi-member-view-icontaglabel',
    containerTag: 'edi-member-view-containertag',
    profileContainer: 'edi-member-view-profilecontainer', 
    templatesCountCircle: 'edi-member-view-templatescountcircle',
    remainingIconTagContainer: 'edi-member-view-remainingicontagcontainer'
}

export const getStyles: IStyleFunction<IMemberViewBasePropsStyles, IMemberViewBaseStyles> = (props: IMemberViewBasePropsStyles): IMemberViewBaseStyles => {
    const {semanticColors, palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            MemberViewGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px', 
                '> .ms-Viewport': {
                    height: '100%'
                }
            }
        ],         
        load: {
            display: 'none'
        },
        headerRow: [
            MemberViewGlobalClassNames.headerRow,
            {
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                }
            }
        ],
        containerTag: {
            display: 'flex', 
            height: '100%', 
            alignItems: 'center',
            flexFlow: 'row wrap',
            gap: '5px 10px' 
        }, 
        iconTagLabel: {
            width: '150px',
            overflow: 'hidden',
            height: '25px',
            padding: '3px 0'
        },             
        profileContainer: {
            display: 'flex'            
        },
        remainingIconTagContainer: {
            padding: '0 5px',
            width: '100%',
            textAlign: 'left',
            justifyContent: 'start',
            color: palette.themePrimary
        },
        subComponentStyles: {
            shimmeredDetailsList: (props: IShimmeredDetailsListStyleProps): IShimmeredDetailsListStyles => {
                return {
                    root: {
                        '::after': {
                            display: 'none'
                        },
                        position: 'relative'
                    }
                }
            },
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto',
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        flexBasis: 'calc(100vh - 315px)',
                        overflowY: 'overlay',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            },
            templatesCountCircle: (): ILabelStyles => {
                return {
                    root: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        fontSize: '12px',
                        backgroundColor: semanticColors.variantBorder,
                        color: palette.themePrimary,            
                    }
                }
            },
            row: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        backgroundColor: palette.white,
                        ':hover': {
                            backgroundColor: palette.white
                        }
                    },
                    cell: {},
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            }           
        },
    }
}