/*eslint-disable sonarjs/cognitive-complexity */
import React, { useMemo, useState } from "react";
import { classNamesFunction, DefaultButton, Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";
import { ICreateTemplateModalPropsStyles, ICreateTemplateModalStyles, ICreateTemplateModalProps } from "./createTemplateModal.types";
import { CreateTemplateFirstPage, FirstPageData } from "./pages/firstPage";
import MultiStepModal from "../../../../../common/components/multiStepModal/multiStepModal";
import { useTranslation } from "react-i18next";
import { CreatingTemplatePage } from "./pages/creatingTemplatePage";
import { CreateTemplateSecondPage, SecondPageData } from "./pages/secondPage";

const getClassNames = classNamesFunction<ICreateTemplateModalPropsStyles, ICreateTemplateModalStyles>();
enum CreateTemplateStep {
    setNameAndDescription = 0,
    manageTemplate = 1,
    creatingTemplate = 2
}

export const CreateTemplateModalBase = (props: ICreateTemplateModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [currentStep, setCurrentStep] = useState(CreateTemplateStep.setNameAndDescription);
    const [isMutating, setIsMutating] = useState(false);
    const [firstPageData, setFirstPageData] = useState<FirstPageData>({
        name: props.template ? props.template.name : "",
        description: props.template ? props.template.description : "",
        isValid: false
    });
    const [secondPageData, setSecondPageData] = useState<SecondPageData>({ phases: [] });
    const [showDialog, setShowDialog] = useState(false);
    const { t } = useTranslation(['createActivityTemplate', 'common']);

    const firstPageFooter = useMemo(() => {
        return (
            <>
                <div style={{ textAlign: 'left', width: '70%' }}>
                    <div>{props.edit && t('subEditTitle')}</div>
                    <span className={classNames.requiredTextLeft}>* {t('common:fieldRequired')}</span> 
                </div>
                <div style={{ textAlign: 'right', width: '30%' }}>
                    <PrimaryButton
                        text={t('common:nextButton')}
                        disabled={isMutating || !firstPageData.isValid}
                        onClick={() => setCurrentStep(CreateTemplateStep.manageTemplate)}
                    />
                </div>
            </>            
        );
    }, [firstPageData.isValid, t, isMutating]); //eslint-disable-line react-hooks/exhaustive-deps

    const secondPageFooter = useMemo(() => (
        <>
            <span className={classNames.requiredTextLeft}>* {t('common:fieldRequired')}</span> 
            <div style={{ fontSize: '14px', display: 'flex', gap: '5px' }}>
            <PrimaryButton
                text={ t('common:previousButton') }
                disabled={isMutating}
                onClick={() => !isMutating && setCurrentStep(CreateTemplateStep.setNameAndDescription)}
            />
            <PrimaryButton
                text={props.edit ? t('modifyTemplate') : t('createTemplate')}
                disabled={isMutating}
                onClick={() => { setCurrentStep(CreateTemplateStep.creatingTemplate); }}
            />
            </div>
        </>
    ), [t, isMutating]); //eslint-disable-line react-hooks/exhaustive-deps

    const steps = [
        {
            title: props.edit ? `${t('editTitle')}` : `${t('title')}`,
            body: <CreateTemplateFirstPage edit={props.edit} templateName={props.template?.name} theme={props.theme} onTemplateDataChange={setFirstPageData} initialState={firstPageData} />,
            footer: firstPageFooter
        },
        {
            title: `${props.edit ? t('editTitle') : t('title')} "${firstPageData.name}" ${t('addNewSection')}`,
            body: <CreateTemplateSecondPage edit={props.edit} templateActivityId={props.template?.id} onTemplateDataChange={setSecondPageData} initialState={secondPageData} />,
            footer: secondPageFooter
        },
        {
            bodyOnly: true,
            body: <CreatingTemplatePage edit={props.edit} templateActivityId={props.template?.id} onClose={() => props.onClose(true)} initialState={
                { name: firstPageData.name, description: firstPageData.description, phases: secondPageData.phases }
            } />
        },
    ]

    return (
        <>
            <MultiStepModal
                styles={classNames.subComponentStyles.ediModal}
                isOpen={true}
                width={600}
                height={currentStep === CreateTemplateStep.manageTemplate ? 700 : 600}
                bodyClassname={currentStep === CreateTemplateStep.manageTemplate || props.edit ? classNames.bodyModal : undefined}
                showCloseIcon={currentStep !== CreateTemplateStep.creatingTemplate}
                onCloseClick={() => currentStep === CreateTemplateStep.manageTemplate ? setShowDialog(true) : props.onClose && props.onClose(false)}
                activeStep={currentStep}
                steps={steps}
                onStepChange={setIsMutating}
                animateInitialStep
            />
            <Dialog
                hidden={!showDialog}
                dialogContentProps={{ title: `${t('stopCreating')}` }}
            >
                <DialogFooter>
                    <DefaultButton onClick={() => setShowDialog(false)} text={t('common:dialogButtonCancel')} />
                    <PrimaryButton onClick={() => props.onClose(false)} text={t('common:dialogButtonConfirm')} />
                </DialogFooter>
            </Dialog>
        </>
    );
}