import { Dropdown, IDropdownOption, IDropdownStyleProps, IDropdownStyles, IStyleFunctionOrObject } from "@fluentui/react";
import React from "react";
import { useMemo } from "react";
import currentUser from "../../../../../modules/authentication/currentUser";
import { useAsyncApi } from "../../../../../utilities/hooks";
import { KnowledgeLabRoleId } from "../../../../models/knowledgeLabRoleId";
import { User } from "../../../../models/user";
import { knowledgeLabApi } from "../../../../services/knowledgeLab.api";

export interface IMemberRoleDropdownProps {
    knowledgeLabId: number;
    member: User;
    totalCount: number;
    dropdownStyles: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
    onRoleChangeCompleted: () => void;
    disabled?: boolean;
}

export const AvailableRoles: IDropdownOption[] = [
    { key: KnowledgeLabRoleId.Owner, text: "Owner" },
    { key: KnowledgeLabRoleId.Contributor, text: "Contributor" },
    { key: KnowledgeLabRoleId.Reader, text: "Reader", selected: true }
]

export const MemberRoleDropdown = (props: IMemberRoleDropdownProps) => {
    const { member, dropdownStyles, knowledgeLabId, onRoleChangeCompleted } = props;
    const disabled = useMemo(() => member.id === currentUser.currentUserId, [member.id]);

    const updateMemberRole = useAsyncApi<{ member: User, newRoledId: KnowledgeLabRoleId }, void>({
        func: async (request) => {
            await knowledgeLabApi.updateMemberRole(knowledgeLabId, request.member.id, request.newRoledId);
        }
    });

    return (
        <Dropdown
            disabled={props.disabled || disabled || updateMemberRole.loading}
            styles={dropdownStyles}
            defaultSelectedKey={member.roleId}
            options={AvailableRoles}
            onChange={async (_, option) => {
                if (!option)
                    return;

                await updateMemberRole.execute({ member, newRoledId: (option.key as KnowledgeLabRoleId) });

                if (!updateMemberRole.error)
                    onRoleChangeCompleted();
            }}
        />);
}