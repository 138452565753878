import { IButtonStyles, IDropdownStyleProps, IDropdownStyles, IPanelStyles, IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";
import { IPanelPropsStyles } from "../panel/panel.types";

export enum Section {
    tag,
    metadata
}
export interface ITagPanelProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ITagPanelPropsStyles, ITagPanelStyles>;
    className?: string;
    default?: Section;
}

export interface ITagPanelPropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ITagPanelPropsStyles, ITagPanelStyles>;
    className?: string;
    noTags: boolean;
    readonlyForm: boolean;
}

export interface ITagPanelStyles {
    root: IStyle; 
    titleAccordion: IStyle;
    iconStyle: IStyle;
    childrenContainer:IStyle;
    childrenContainerWithError:IStyle;
    errorForm: IStyle;
    titleSection: IStyle;
    errorLabel: IStyle;
    arrayFieldItem: IStyle;
    arrayFieldItemWithError: IStyle;
    required: IStyle;
    loadingErrorContainer: IStyle;
    label: IStyle;
    footer: IStyle;
    pivotItemsDisabled: IButtonStyles;
    footerContainer: IStyle;
    buttonFormContainer: IStyle;
    buttonFormContainerLeft: IStyle;
    buttonFormContainerRight: IStyle;
    tagAccordionContainer: IStyle;
    emptyFolder: IStyle;
    caption: IStyle;
    captionRow: IStyle;
    pivotHeaderContainer: IStyle;
    subComponentStyles: {
        dropdownStyle: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
        panelStyle: IStyleFunctionOrObject<IPanelPropsStyles, IPanelStyles>;
        panelStyleNoItems: IStyleFunctionOrObject<IPanelPropsStyles, IPanelStyles>;
        iconButton: IButtonStyles;
    }
}