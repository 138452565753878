import { User, UserShort } from "./user";
import { WorkFlowApprover } from "./workflowApprover";
import { WorkFlowStep, WorkFlowStepTypes } from "./workflowStep";

export enum WorkFlowTypes {
    Approval = 0,
    //Verify = 0 non fa differenze per il momento, utilizzeremo solo approval 
}

export enum WorkFlowStatus {
    Approved = 0,
    Refused = 1,
    Ongoing = 2,
    NotStarted = 3
}

export enum WorkFlowRelativeStatus {
    Approved = 0,
    Refused = 1,
    Ongoing = 2,
    ToMe = 3
}

export type WorkFlow = {
    id: number;
    activityId?: number;
    activityName?: string;
    name: string;
    description?: string;
    type: WorkFlowTypes;
    status?: WorkFlowStatus;
    relativeStatus?: WorkFlowRelativeStatus;
    createdOn?: Date;
    lastUpdatedOn?: Date;
    creator?: User;
    documentName?: string;
    documentUrl?: string;
    steps: WorkFlowStep[];
    involvedPeople: WorkFlowApprover[];
    currentStepId?: number;
    workflowLab?: WorkflowLab;
}

export type WorkflowLab = {
    id: number;
    name: string;
}

export type WorkFlowSplitByStatus = {
    status: WorkFlowRelativeStatus;
    count: number;
}

export type WorkFlowForUser = {
    user: UserShort;
    approved: number;
    refused: number;
    ongoing: number;
    assignedToMe: number;
}

export type CreateWorkFlowPayload = {
    Name: string;
    Description?: string;
    FileId?: string;
    ActivityId?: number;
    Steps: WorkFlowStepRequest[];
}

export type WorkFlowStepRequest = {
    name: string;
    type: WorkFlowStepTypes ;
    order: number;
    approvers: string[]
}

export type WorkFlowShort = {
    id: number;
    activityId: number;
    activityName: string;
    name: string;
    relativeStatus: WorkFlowRelativeStatus;
    createdOn: Date;
    creator: UserShort;
    documentName: string;
    assignedTo: UserShort[];
    involvedPeople: UserShort[];
}