/*eslint-disable @typescript-eslint/no-explicit-any*/
import { AxiosRequestConfig, CancelToken } from 'axios';
import qs from 'qs';
import { apiClient, ErrorDetails } from '../../../modules/apiClient/apiClient';
import { svcPaths } from '../../../modules/apiClient/config';
import { SelectionEntry } from '../../features/archiveContent';
import { Archive, ArchiveCapacity, ArchiveReport, OtherSettings } from '../../models/archive';
import { ArchiveMembership } from '../../models/archiveMembership';
import { ArchiveRoleId, ArchiveTypeId, DeletedType } from '../../models/constants';
import { DeleteBulkResponse } from '../../models/deleteBulkResponse';
import { User, UserProfile } from '../../models/user';
import _ from "lodash";
import { AddMetadataRequest, ArchiveSettings, CreateArchiveRequest, CreateReportMetadataRequest, FilePathGet, FilePaths, FilePathsRequest, FileTeamsUrl, GetArchiveAvailableMembersRequest, GetArchiveMembersRequest, GetArchiveMembersWithProfilesRequest, GetFolderAvailableMembersNoNestedRequest, GetFolderAvailableMembersRequest, GetFolderContentRequest, GetFolderMembersRequest, GetUsersRequest, MimeTypeDictionary, previewResponse, ProfileMember, ProfileSelectedFolder, SelectionScopeProfiles, SharepointLinkItem, UpdateApprovalStatusRequest, UpdateFormDataRequest } from './archives.contracts';
import { ArchiveRoleWithContentItem } from '../../models/archiveRoleWithContentItem';
import { IFolder } from '../../models/folder';
import { UserWithProfiles } from '../../models/userWithProfiles';
import { WorkFlow } from '../../../activityLab/models/workflow';
import { ArchiveRoleWithFileDeleted } from '../../models/archiveRoleWithFileDeleted';
import { ArchiveUnivocityRule, FileNameWithMetadata, ResultDocumentsMetadataUnivocityWithError } from '../../models/archiveUnivocityRule';
import { DisplayedFileMetadata } from '../../../activityLab/models/fileShort';
import { Topic, TopicWithUsers } from '../../components/settings/manageTopicsOfInterest/topicModel/topic';
import { NonOwnerTopics, TopicShort } from '../../components/settings/manageInterests/topicModel/topic';
import { Folder, TreeFolderIdView, TreeFolderIdViewBulk } from '../../components/settings/profileManagement/profileView/createProfileModal/createProfileModal.types';
import { SearchResultSelectedFolder } from '../../../common/components/folderTreeViewer/folderManager/IManagerFolder';

class ArchivesApiClass {
    async getArchives(types: ArchiveTypeId[], keyword?: string, cancelToken?: CancelToken) {

        const response = await apiClient.get<Archive[]>(`${svcPaths.archives}/archives`, {
            params: { typeId: types, keyword: keyword },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken
        });
        return response.data
    }

    async getArchive(archiveId: number, cancelToken?: CancelToken) {
        const response = await apiClient.get<Archive>(`${svcPaths.archives}/archives/${archiveId}`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    async getAllFolders(archiveId: number) {
        const response = await apiClient.get<IFolder[]>(`${svcPaths.archives}/archives/${archiveId}/getallfolders`);
        return response.data;
    }

    async getFoldersWithPermission(archiveId: number) {
        const response = await apiClient.get<IFolder[]>(`${svcPaths.archives}/archives/${archiveId}/getfolderswithpermission`);
        return response.data;
    }

    async getProfileFolders(profileId: number) {
        const response = await apiClient.get<IFolder[]>(`${svcPaths.archives}/profile/${profileId}/getprofilefolders`);
        return response.data;
    }

    async getUserFolderCount(archiveId: number) {
        const response = await apiClient.get<number>(`${svcPaths.archives}/folders/${archiveId}/get-total-archive-folders`);
        return response.data;
    }

    async getNextFolderLevel(folderId: number, profileId: number, folderSelected: boolean | undefined) {
        const response = await apiClient.get<Folder[]>(`${svcPaths.archives}/profile/get-child-folders-profile-folder`, {
            params: {
                profileId: profileId,
                folderId: folderId,
                folderSelected: folderSelected,
            }
        });
        return response.data;
    }

    async getNextFolderLevelWithFolderPermission(folderId: number, archiveId: number, folderSelected: boolean | undefined) {
        const response = await apiClient.get<Folder[]>(`${svcPaths.archives}/folders/${archiveId}/get-children-folders-permission`, {
            params: {
                folderId: folderId,
                folderSelected: folderSelected,
            }
        });
        return response.data;
    }

    async getAllFolderIdsTree(archiveId: number, folders: TreeFolderIdView[]) {
        const payload = { folderIds: folders };

        const response =  await apiClient.put<SearchResultSelectedFolder>(`${svcPaths.archives}/folders/${archiveId}/get-all-folder-ids-tree`, payload);
        return response.data;
    }

    //Reports
    async createReportMetadata(request: CreateReportMetadataRequest) {
        const response = await apiClient.post(`${svcPaths.archives}/reports`, request);
        return response.data;
    }

    async getReportMetadata(archiveId: number, isImportExportType: boolean) {
        const response = await apiClient.get<ArchiveReport[]>(`${svcPaths.archives}/reports/archives/${archiveId}`, {
            params: {
                isImportExportType: isImportExportType
            }
        });
        return response.data;
    }

    async downloadReport(reportId: number, onDownloadProgress?: (progress: number) => void) {
        const config: AxiosRequestConfig = {
            responseType: 'arraybuffer',
            onDownloadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onDownloadProgress && onDownloadProgress(percentCompleted);
            }
        }

        const response = await apiClient.get(`${svcPaths.archives}/reports/${reportId}/download`, config);
        return response.data;
    }
    //End Reports

    async importMetadataFromExcel(archiveId: number, formData: FormData) {
        const response = await apiClient.post(`${svcPaths.archives}/archives/${archiveId}/importMetadata`, formData);
        return response.data;
    }

    async getDisplayedFilesMetadata(archiveId: number, displayedMetadata: string[], cancelToken?: CancelToken) {
        const response = await apiClient.get<DisplayedFileMetadata[]>(`${svcPaths.archives}/archives/${archiveId}/displayedFilesMetadata`, {params: {displayedMetadata: displayedMetadata},
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        cancelToken: cancelToken
        });
        return response.data;
    }

    async getSecondaryPropertiesList(archiveId: number, excelType: number) {
        const resposnse= await apiClient.get(`${svcPaths.archives}/archives/${archiveId}/get-secondary-props-archive?ExcelType=${excelType}`)
        return resposnse.data;
    }
    
    async getMainPropertiesList(archiveId: number) {
        const response = await apiClient.get(`${svcPaths.archives}/archives/${archiveId}/main-properties-list-archive`);
        return response.data;
    }

    async uploadFiles(formData: FormData, cancelToken?: CancelToken) {
        const url = `${svcPaths.archives}/folders/${formData.get("Id")}/files`;

        const response = await apiClient.post(url, formData, { cancelToken: cancelToken });
        return response.data;
    }

    async preview(fileId: number) {
        const response = await apiClient.get<previewResponse>(`${svcPaths.archives}/files/${fileId}/preview`);
        return response.data
    }

    async createFolder(id: number, name: string, profiles?: ProfileMember[]) {
        const idParent = id;
        const payload = { name: name, profiles: profiles };
        const response = await apiClient.post(`${svcPaths.archives}/folders/${idParent}/folders`, payload);
        return response.data;
    }

    async deleteItem(selection: SelectionEntry) {
        const selectedItem = selection;
        const itemType = selectedItem.isFolder ? 'folders' : 'files';
        const response = await apiClient.delete(`${svcPaths.archives}/${itemType}/${selectedItem.id}`);
        return response.data;
    }

    // async DeleteFilesBulk(selection: SelectionEntry[]){
    //     const files = _.map(_.filter(selection, ['isFolder', false]), 'id');
    //     const payload = { 
    //         Ids: files, 
    //     };
    //     await apiClient.delete(`${svcPaths.archives}/archives/delete-files-bulk`, { data: payload });
    // }
    async deleteItems(selection: SelectionEntry[],deletedType: DeletedType) {
        const files = _.map(_.filter(selection, ['isFolder', false]), 'id');
        const folders = _.map(_.filter(selection, ['isFolder', true]), 'id');
        const payload = { 
            fileIds: files, 
            folderIds: folders, 
            deletedType: deletedType
        };
        const operation = 'deletebulk';
        const response = await apiClient.delete(`${svcPaths.archives}/archives/${operation}`, { data: payload });
        return response.data;
    }

    async recycleBinDeleteItems(selection: SelectionEntry[]) {
        const files = _.map(_.filter(selection, ['isFolder', false]), 'id');
        const payload = { fileIds: files };

        const response = await apiClient.delete<DeleteBulkResponse>(`${svcPaths.archives}/archives/move-to-recyclebin`, { data: payload });
        return response.data;
    }

    async deleteFromRecycleBin(selection: SelectionEntry[]) {
        const files = _.map(_.filter(selection, ['isFolder', false]), 'id');
        const payload = { fileIds: files };

        const response = await apiClient.delete(`${svcPaths.archives}/archives/delete-from-recyclebin`, { data: payload });
        return response.data;
    }

    async recycleBinRestore(selection: SelectionEntry[]) {
        const files = _.map(_.filter(selection, ['isFolder', false]), 'id');
        const payload = { fileIds: files };

        const response =  await apiClient.put<number>(`${svcPaths.archives}/archives/restore-from-recyclebin`, payload);
        return response.data;
    }

    async addFileMetadata(fileId: number, metadata: any) {
        const payload: AddMetadataRequest = { metadata: metadata };
        const response = await apiClient.put(`${svcPaths.archives}/files/${fileId}/metadata`, payload);
        return response.data;
    }

    async getFileMetadata(fileId: number) {
        const response = await apiClient.get<any>(`${svcPaths.archives}/files/${fileId}/metadata`);
        return response.data;
    }

    async getArchiveSettings(archiveId: number) {
        const response = await apiClient.get<ArchiveSettings>(`${svcPaths.archives}/archives/${archiveId}/settings`);
        return response.data;
    }

    async renameArchive(archiveId: number, name: string) {
        const payload = { name: name, Id: archiveId };
        const response = await apiClient.put(`${svcPaths.archives}/archives/${archiveId}/name`, payload);
        return response.data
    }

    async updateArchiveOtherSettings(archiveId: number, otherSettings: OtherSettings) {
        const payload = {
            id: archiveId,
            isCrossArchiveSearchable: otherSettings.isCrossArchiveSearchable,
            isCrossFolderSearchable: otherSettings.isCrossFolderSearchable,
            isTranslationAllowed: otherSettings.isTranslationAllowed,
            isDefaultViewAdditionalMetadata: otherSettings.isDefaultViewAdditionalMetadata,
            isReportDateFieldsInDateFormat: otherSettings.isReportDateFieldsInDateFormat,
            isLogChangesMetadata: otherSettings.isLogChangesMetadata
        };

        const response = await apiClient.put(`${svcPaths.archives}/archives/othersettings`, payload);
        return response.data;
    }

    async renameFolder(folderId: number, name: string) {
        const payload = { name: name };
        const response = await apiClient.put(`${svcPaths.archives}/folders/${folderId}/name`, payload);
        return response.data
    }

    async renameFile(fileId: number, name: string) {
        const payload = { name: name };
        const response = await apiClient.put(`${svcPaths.archives}/files/${fileId}/name`, payload);
        return response.data;
    }

    async downloadFile(fileId: number) {
        const config: AxiosRequestConfig = {
            responseType: 'arraybuffer'
        }

        const response = await apiClient.get(`${svcPaths.archives}/files/${fileId}`, config);
        return response.data;
    }
/*
    async downloadGenericFile(uri: string, type: string, filename: string, onDownloadProgress?: (progress: number) => void) {
        const config: AxiosRequestConfig = {
            responseType: 'arraybuffer',
            onDownloadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onDownloadProgress && onDownloadProgress(percentCompleted);
            }
        }

        const response = await apiClient.get(uri, config);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: type }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }
    */
    async downloadGenericFile(
        uri: string,
        type: string,
        filename: string,
        onDownloadProgress?: (progress: number) => void
    ) {
        try {
            const config: AxiosRequestConfig = {
                responseType: 'arraybuffer',
                timeout: 3000000, // Timeout di 3000 secondi
                onDownloadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        if (onDownloadProgress) {
                            onDownloadProgress(percentCompleted);
                        }
                    }
                }
            };
    
            const response = await apiClient.get(uri, config);
    
            const blob = new Blob([response.data], { type });
            const url = window.URL.createObjectURL(blob);
    
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
    
            // Clean up the link after the download
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }

    async multipleDownload(fileIds: number[], folderIds: number[]) {
        const config: AxiosRequestConfig = {
            responseType: 'arraybuffer',
            params: {
                fileIds: fileIds,
                folderIds: folderIds
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
        }
        const response = await apiClient.get(`${svcPaths.archives}/archives/download`, config);
        return response.data;
    }

    async getAllowedFileTypes(archiveId: number) {
        const response = await apiClient.get<MimeTypeDictionary>(`${svcPaths.archives}/archives/${archiveId}/content-types-accepted`);
        return response.data;
    }

    async isArchiveNameAvailable(name: string) {
        const payload = { name: name };
        const response = await apiClient.post<boolean>(`${svcPaths.archives}/archives/check-name`, payload);
        return response.data;
    }

    async isFolderNameAvailable(name: string, parentFolderId: number) {
        const payload = {
            name: name,
            parentFolderId: parentFolderId
        };

        const response = await apiClient.post<boolean>(`${svcPaths.archives}/folders/${parentFolderId}/check-name`, payload);
        return response.data;
    }

    async createArchive(newArchive: CreateArchiveRequest) {
        const payload = { name: newArchive.name, description: newArchive.description, typeId: newArchive.typeId, members: newArchive.members };
        const response = await apiClient.post<Archive>(`${svcPaths.archives}/archives`, payload);
        return response.data;
    }

    async getBreadcrumbFromDeeplink(fileId: number, isFolder: boolean) {
        const filesToRetrievePaths: FilePathGet[] = [{
            id: fileId,
            isFolder: isFolder,
            includeFileName: false
        }];
        const payload: FilePathsRequest = {
            filesToRetrievePaths: filesToRetrievePaths
        }
        const response = await apiClient.post<FilePaths[]>(`${svcPaths.archives}/folders/${fileId}/get-files-paths`, payload);
        return response.data[0].filePath;
    }

    async getFilesDeleted(folderRequest: GetFolderContentRequest) {
        const response = await apiClient.get<ArchiveRoleWithFileDeleted>(`${svcPaths.archives}/folders/${folderRequest.id}/files-deleted`, {
            params: {
                pageNumber: folderRequest.pageNumber,
                pageSize: folderRequest.pageSize,
                orderBy: folderRequest.orderBy,
                isAscending: folderRequest.isAscending,
                name: folderRequest.name,
                scope: folderRequest.scope,
                filterForIndexingStatus: folderRequest.indexingStatus
            }
        });
        return response.data;
    }

    async getFolderContent(folderRequest: GetFolderContentRequest) {
        const response = await apiClient.get<ArchiveRoleWithContentItem>(`${svcPaths.archives}/folders/${folderRequest.id}/items`, {
            params: {
                pageNumber: folderRequest.pageNumber,
                pageSize: folderRequest.pageSize,
                orderBy: folderRequest.orderBy,
                isAscending: folderRequest.isAscending,
                name: folderRequest.name,
                scope: folderRequest.scope,
                filterForIndexingStatus: folderRequest.indexingStatus
            }
        });
        return response.data;
    }

    async getUsers(request: GetUsersRequest): Promise<User[]> {
        const response = await apiClient.get<User[]>(`${svcPaths.archives}/users`, {
            params: {
                keyword: request.keyword,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                excludeCurrentUser: true,
                userIds: request.userIds
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        });
        return response.data;
    }

    async getArchiveMembers(request: GetArchiveMembersRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<User[]>(`${svcPaths.archives}/archives/${request.archiveId}/members`, {
            params: {
                keyword: request.keyword,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                excludeCurrentUser: false,
            },
            cancelToken: cancelToken
        })
        return response.data;
    }

    async getArchiveAvailableMembers(request: GetArchiveAvailableMembersRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<User[]>(`${svcPaths.archives}/archives/${request.archiveId}/available-members`, {
            params: {
                keyword: request.keyword,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                excludeCurrentUser: true,
                userIds: request.userIds
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken
        });
        return response.data;
    }

    async addMembers(archiveId: number, members: ArchiveMembership[]) {
        const payload = { id: archiveId, members };
        const response = await apiClient.post(`${svcPaths.archives}/archives/${archiveId}/members`, payload);
        return response.data
    }

    async getNewAvailableProfileMember(archiveId: number, text: string, pageSize: number, pageNumber: number, userIds: string[]) {
        const payload =
        {
            archiveId: archiveId,
            keyword: text,
            pageSize: 0,
            pageNumber: pageNumber,
            userIds: userIds
        };
        const response = await apiClient.post<UserProfile[]>(`${svcPaths.archives}/profile/getnewavailableprofilemember`, payload);
        return response.data;
    }

    async getFolderMembers(request: GetFolderMembersRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<User[]>(`${svcPaths.archives}/folders/${request.folderId}/members`, {
            params: {
                keyword: request.keyword,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                excludeCurrentUser: false,
            },
            cancelToken: cancelToken
        })
        return response.data;
    }

    async getFolderAvailableMembers(request: GetFolderAvailableMembersRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<User[]>(`${svcPaths.archives}/folders/${request.folderId}/available-members`, {
            params: {
                keyword: request.keyword,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                excludeCurrentUser: true,
                userIds: request.userIds
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken
        });
        return response.data;
    }

    async getFolderAvailableMembersNoNested(request: GetFolderAvailableMembersNoNestedRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<User[]>(`${svcPaths.archives}/folders/${request.folderId}/availableMemberNoNestedFolder`, {
            params: {
                keyword: request.keyword,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                excludeCurrentUser: true,
                userIds: request.userIds
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken
        });
        return response.data;
    }

    async addFolderMembers(folderId: number, members: ArchiveMembership[]) {
        const payload = { folderId: folderId, members };
        const response = await apiClient.post(`${svcPaths.archives}/folders/${folderId}/member`, payload);
        return response.data
    }

    async deleteFolderMember(folderId: number, userId: string) {
        const response = await apiClient.delete(`${svcPaths.archives}/folders/${folderId}/member/${userId}`);
        return response.data
    }

    async updateMemberRole(archiveId: number, userId: string, newRole: ArchiveRoleId) {
        const payload = { newRoleId: newRole };
        const response = await apiClient.put(`${svcPaths.archives}/archives/${archiveId}/members/${userId}`, payload);
        return response.data
    }

    async deleteMember(archiveId: number, userId: string) {
        const response = await apiClient.delete(`${svcPaths.archives}/archives/${archiveId}/members/${userId}`);
        return response.data
    }

    async getArchiveCapacity(archiveId: number) {
        const response = await apiClient.get<ArchiveCapacity>(`${svcPaths.archives}/archives/${archiveId}/available-space`);
        return response.data
    }

    async deleteArchive(archiveId: number) {
        const response = await apiClient.delete(`${svcPaths.archives}/archives/${archiveId}`);
        return response.data
    }

    async copyItems(selection: SelectionEntry[], destinationFolderId: number) {
        const files = _.map(_.filter(selection, ['isFolder', false]), 'id');
        const folders = _.map(_.filter(selection, ['isFolder', true]), 'id');
        const payload = { fileIds: files, folderIds: folders, destinationFolderId: destinationFolderId };
        const operation = 'copybulk';
        const response = await apiClient.put(`${svcPaths.archives}/archives/${operation}`, payload);
        return response.data;
    }

    async moveFiles(selection: SelectionEntry[], destinationFolderId: number) {
        const files = _.map(_.filter(selection, ['isFolder', false]), 'id');
        const payload = { fileIds: files, destinationFolderId: destinationFolderId };
        const operation = 'movefiles';
        const response = await apiClient.put(`${svcPaths.archives}/archives/${operation}`, payload);
        return response.data;
    }

    async moveFolders(selection: SelectionEntry[], destinationFolderId: number) {
        const folders = _.map(_.filter(selection, ['isFolder', true]), 'id');
        const payload = { folderIds: folders, destinationFolderId: destinationFolderId };
        const operation = 'movefolders';
        const response = await apiClient.put(`${svcPaths.archives}/archives/${operation}`, payload);
        return response.data;
    }

    async refreshJsonSchema(archiveId: number) {
        try {
            const response = await apiClient.put<SharepointLinkItem[]>(`${svcPaths.archives}/archives/${archiveId}/jsonschemaproperty`, {});
            return response.data
        } catch (e) {
            const err = e as ErrorDetails;
            throw (err.detail);
        }
    }

    async getJsonSchemaProperty(archiveId: number) {
        const response = await apiClient.get<SharepointLinkItem[]>(`${svcPaths.archives}/archives/${archiveId}/jsonschemaproperty`);
        return response.data;
    }

    async getForbiddenFormatById(archiveId: number){
        const response = await apiClient.get<string[]>(`${svcPaths.archives}/archives/${archiveId}/forbiddenFormat`);
        return response.data;
      }

    // PROFILES
    async getProfiles(archiveId: number, filter?: string) {
        const response = await apiClient.get<ProfileMember[]>(`${svcPaths.archives}/profile/${archiveId}`, {
            params: {
                filter: filter ?? ''
            }
        });
        return response.data;
    }

    async getArchiveMembersWithProfiles(request: GetArchiveMembersWithProfilesRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<UserWithProfiles[]>(`${svcPaths.archives}/archives/${request.archiveId}/memberswithprofiles`, {
            params: {
                keyword: request.keyword,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                isAscending: request.isAscending,
            },
            cancelToken: cancelToken
        })
        return response.data;
    }

    async changeProfileRole(profileId: number, payload: any) {
        const response = await apiClient.put(`${svcPaths.archives}/profile/${profileId}`, payload);
        return response.data;
    }

    async deleteProfile(profileId: number) {
        const response = await apiClient.delete<ProfileMember[]>(`${svcPaths.archives}/profile/${profileId}`);
        return response.data;
    }

    async isProfileNameAvailable(archiveId: number, name: string) {
        const payload = { name: name };
        const response = await apiClient.post<boolean>(`${svcPaths.archives}/profile/${archiveId}/check-name`, payload);
        return response.data;
    }

    async createProfile(archiveId: number, payload: any) {
        const response = await apiClient.post(`${svcPaths.archives}/profile/${archiveId}/createprofile`, payload);
        return response.data;
    }

    async updateProfileFolder(profileId: number, folders: TreeFolderIdView[], foldersChildrenToRetrieve?: boolean) {
        const payload = {
            treeFolderIdViewList: folders,
            foldersChildrenToRetrieve: foldersChildrenToRetrieve
        }
        const response = await apiClient.post(`${svcPaths.archives}/profile/${profileId}/updateprofilefolders`, payload);
        return response.data;
    }

    async updateProfileFolderBulk(profileId: number, folders: TreeFolderIdViewBulk[], foldersChildrenToRetrieve?: boolean) {
        const payload = {
            treeFolderIdViewList: folders,
            foldersChildrenToRetrieve: foldersChildrenToRetrieve
        }
        const response = await apiClient.post(`${svcPaths.archives}/profile/${profileId}/updateprofilefoldersbulk`, payload);
        return response.data;
    }

    async getMemberProfile(profileId: number, filter?: string) {
        const response = await apiClient.get<UserProfile[]>(`${svcPaths.archives}/profile/${profileId}/profile-member`);
        return response.data;
    }

    async duplicateProfile(profileId: number) {
        const config: AxiosRequestConfig = {
           timeout: 3000000 // Timeout di 3000 secondi
        };

        const response = await apiClient.post(`${svcPaths.archives}/profile/${profileId}/duplicate`, config);
        return response.data;
    }

    async updateProfileMember(profileId: number, userIds: string[]) {
        const payload = {
            profileId: profileId,
            userIds: userIds
        };

        const response = await apiClient.put(`${svcPaths.archives}/profile/profile-member`, payload);
        return response.data;
    }

    async getProfilesRelatedTo(folderId: number) {
        const response = await apiClient.get<SelectionScopeProfiles[]>(`${svcPaths.archives}/folders/${folderId}/getProfilesRelatedTo`);
        return response.data;
    }

    async getProfilesWithFolderVisiblity(parentFolderId: number, folderId?: number) {
        const response = await apiClient.get<ProfileSelectedFolder[]>(`${svcPaths.archives}/profile/getProfilesWithFolderVisiblity`, {
            params: { parentFolderId: parentFolderId, folderId: folderId }
        });
        return response.data;
    }

    async getFlatMembersInProfiles(archiveId: number, profileIds: number[]) {
        const payload = {
            archiveId: archiveId,
            profileIds: profileIds
        };

        const response = await apiClient.post<UserProfile[]>(`${svcPaths.archives}/profile/getFlatMembersInProfiles`, payload);
        return response.data;
    }

    async updateFolderProfilePermission(folderId: number, profiles: ProfileSelectedFolder[]) {
        const payload = {
            id: folderId,
            profiles: profiles
        };
        const response = await apiClient.put(`${svcPaths.archives}/folders/${folderId}/updateFolderProfilePermission`, payload);

        return response.data;
    }

    async getKeypharasesLimit(archiveId: number) {
        const response = await apiClient.get(`${svcPaths.archives}/archives/${archiveId}/keypharases-limit`);
        return response.data;
    }

    async updateKeypharasesLimit(archiveId: number, newKeypharasesLimit: number) {
        const payload =
        {
            archiveId: archiveId,
            newKeyphrasesLimit: newKeypharasesLimit
        }
        const response = await apiClient.put(`${svcPaths.archives}/archives/keypharases-limit`, payload);
        return response.data;
    }

    async translateDocument(fileId: number, targetLanguage: string) {
        const config: AxiosRequestConfig = {
            responseType: 'arraybuffer'
        }
        const response = await apiClient.get(`${svcPaths.archives}/files/${fileId}/translate?targetLanguage=${targetLanguage}`, config);
        return response.data;
    }

    async getWorkFlowInfo(fileId: number) {
        const response = await apiClient.get<WorkFlow>(`${svcPaths.archives}/files/${fileId}/getWorkFlow`);
        return response.data;
    }

    async getFileTeamsUrl(fileId: number, openPreview: boolean) {
        const response = await apiClient.get<FileTeamsUrl>(`${svcPaths.archives}/files/${fileId}/getfileteamsurl?openPreview=${openPreview}`);
        return response.data;
    }

    async getArchiveFromFile(fileId: number, cancelToken?: CancelToken) {
        const response = await apiClient.get<Archive>(`${svcPaths.archives}/files/${fileId}/archive`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    async updateFormDataDependencies(archiveId: number, formData: any) {
        const payload: UpdateFormDataRequest = { formData: formData };
        const response = await apiClient.post<any>(`${svcPaths.archives}/archives/${archiveId}/update-formdata-dependencies`, payload);
        return response.data;
    }

    async updateApprovalStatus(updateApprovalStatusInput: UpdateApprovalStatusRequest) {
        const payload =
        {
            id: updateApprovalStatusInput.archiveId,
            fileIds: updateApprovalStatusInput.fileIds,
            approvalStatus: updateApprovalStatusInput.approvalStatus,
            rejectionNote: updateApprovalStatusInput.rejectionNote,
            emailsRecipients: updateApprovalStatusInput.emailsRecipients
        }
        const response = await apiClient.put<any>(`${svcPaths.archives}/files/${updateApprovalStatusInput.archiveId}/update-approval-status`, payload);
        return response.data;
    }

    async exportWorkflowInfo(fileId: number, type: string, onDownloadProgress?: (progress: number) => void) {
        const config: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        }
        const response = await apiClient.get(`${svcPaths.archives}/files/${fileId}/exportWorkflowInfo`, config);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: type }));
        const contentDisposition = response.headers["content-disposition"].match(/filename\s*=\s*"(.+)"/i);
        const filename = contentDisposition[1];
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }

    async getFileIdsFromFolders(FolderIds: number[]) {
        const payload = {
            FolderIds: FolderIds
        };

        const response = await apiClient.post(`${svcPaths.archives}/archives/get-file-ids-from-folders`, payload);
        return response.data;
    }

    async getArchiveUnivocityRules(archiveId: number){
        const response = await apiClient.get<ArchiveUnivocityRule[]>(`${svcPaths.archives}/univocityrules/${archiveId}/rules?OnlyEnabledRules=true`);
        return response.data;
    }

    async verifyDocumentsMetadataUnivocity(filesNameWithMetadata: FileNameWithMetadata[], folderId:number){
        const payload = {
            FolderId: folderId,
            FileMetadataList: filesNameWithMetadata
        };

        const response = await apiClient.post<ResultDocumentsMetadataUnivocityWithError>(`${svcPaths.archives}/univocityrules/verify-documents-metadata-univocity`, payload);
        return response.data;
    }

    
    async verifyDocumentsMetadataUnivocityBulk(filesNameWithMetadata: FileNameWithMetadata[], folderId: number[]){
        const payload = {
            FolderId: folderId,
            FileMetadataList: filesNameWithMetadata
        };

        const response = await apiClient.post<ResultDocumentsMetadataUnivocityWithError>(`${svcPaths.archives}/univocityrules/verify-documents-metadata-univocity-bulk`, payload);
        return response.data;
    }


    async getUserInhibitionPermission(folderId: number){
        const response = await apiClient.get<boolean>(`${svcPaths.archives}/archives/${folderId}/user-inhibition-permission`);
        return response.data;
    }

    //TOPICS
    async createTopic(payload: any) {
        const response = await apiClient.post(`${svcPaths.archives}/topic`, payload);
        return response.data;
    }

    async updateTopic(payload: any) {
        const response = await apiClient.put(`${svcPaths.archives}/topic`, payload);
        return response.data;
    }

    async isTopicNameAvailable(archiveId: number, name: string) {
        const payload = { name: name };
        const response = await apiClient.post<boolean>(`${svcPaths.archives}/topic/${archiveId}/check-name`, payload);
        return response.data;
    }

    async getTopics(archiveId: number, filter?: string) {
        const response = await apiClient.get<Topic[]>(`${svcPaths.archives}/topic/${archiveId}`, {
            params: {
                filter: filter ?? ''
            }
        });
        return response.data;
    }

    async getNonOwnerTopics(archiveId: number, cancelToken?: CancelToken, ) {
        const response = await apiClient.get<NonOwnerTopics>(`${svcPaths.archives}/topic/associated-topics-non-owner-users`, {
            params: { 
                archiveId: archiveId
            },
            cancelToken: cancelToken
        });
        return response.data;
    }

    async getTopicsNonOwner(archiveId: number, filter?: string) {
        const response = await apiClient.get<TopicShort[]>(`${svcPaths.archives}/topic/topics-non-owner-user`, {
            params: {
                archiveId: archiveId,
                filter: filter ?? ''
            }
        });
        return response.data;
    }

    async updateNonOwnerTopics(currentUserId: string | undefined, topicsModified: number[]) {
        const payload =
        {
            userId: currentUserId,
            topicIds: topicsModified
        }
        const response = await apiClient.put(`${svcPaths.archives}/topic/update-user-topics`, payload);
        return response.data;
    }

    async deleteUserTopics(currentUserId: string | undefined) {
        const payload = { 
            userId: currentUserId
        };
        const response = await apiClient.delete(`${svcPaths.archives}/topic/delete-user-topics`, { data: payload });
        return response.data;
    }

    async getTopicsWithUsers(archiveId: number, pageSize: number, pageNumber: number, searchBy: string | undefined) {
        const response = await apiClient.get<TopicWithUsers[]>(`${svcPaths.archives}/topic/topics-with-users`, {
            params: {
                archiveId: archiveId,
                pageSize: pageSize,
                pageNumber: pageNumber,
                searchBy: searchBy
            }
        });
        return response.data;
    }

    async deleteUsersTopic(topicId: number, userIds: string[]) {
        const payload = { 
            topicId: topicId,
            userIds: userIds
        };
        const response = await apiClient.delete(`${svcPaths.archives}/topic/users-topic`, { data: payload });
        return response.data;
    }

    async deleteTopic(topicId: number, archiveId: number) {
        const response = await apiClient.delete(`${svcPaths.archives}/topic/${topicId}/archive/${archiveId}`);
        return response.data
    }

    async addUsersToTopic(topicId: number, userIds: string[]) {
        const payload =
        {
            topicId: topicId,
            userIds: userIds
        };
        const response = await apiClient.put(`${svcPaths.archives}/topic/users-topic`, payload);
        return response.data
    }

    async getAvailableUsersNotAssociateToTopic(topicId: number, keyword: string, userIdsToExclude: string[]) {
        const response = await apiClient.get<User[]>(`${svcPaths.archives}/topic/available-users-topic`, {
            params: {
                topicId: topicId,
                keyword: keyword,
                userIdsToExclude: userIdsToExclude
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
        });
        return response.data;
    }
}

export const archivesApi = new ArchivesApiClass();