import { styled } from "@fluentui/react";
import { WorkflowDetailsModalBase } from "./workflowDetailsModal.base";
import { getStyles } from "./workflowDetailsModal.styles";
import { IWorkflowDetailsModalProps, IWorkflowDetailsModalPropsStyles, IWorkflowDetailsModalStyles } from "./workflowDetailsModal.types";

const WorkflowDetailsModal = styled<IWorkflowDetailsModalProps, IWorkflowDetailsModalPropsStyles, IWorkflowDetailsModalStyles>(
    WorkflowDetailsModalBase,
    getStyles,
    undefined,
    { scope: 'WorkflowDetailsModal' }
);

export default WorkflowDetailsModal;