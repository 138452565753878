import { IStyleFunction } from "@fluentui/react"
import { IMultiStepModalPropsStyles, IMultiStepModalStyles } from "../../../../../common/components/multiStepModal/multiStepModal.types";
import { ICreateTemplateModalPropsStyles, ICreateTemplateModalStyles } from "./createTemplateModal.types"

export const CreateTemplateModalGlobalClassNames = {
    root: 'edi-create-template-modal-root',
    bodyModal: 'edi-create-template-modal-bodyModal'
}

export const getStyles: IStyleFunction<ICreateTemplateModalPropsStyles, ICreateTemplateModalStyles> = (props: ICreateTemplateModalPropsStyles): ICreateTemplateModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    
    return {
        root: [
            CreateTemplateModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        bodyModal: [CreateTemplateModalGlobalClassNames.bodyModal,
        {
            marginTop: "10px !important",
            flexGrow: 1,
            overflowY: 'auto'
        }],
        subComponentStyles: {
            ediModal: (props: IMultiStepModalPropsStyles): IMultiStepModalStyles => {
                return {
                    body: {},
                    bodyContent: {},
                    title: {},
                    closeIcon: {},
                    container: {},
                    content: {},
                    footer: {
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    },
                    header: {},
                    subComponentStyles: {
                        iconButton: {}
                    },
                    subTitle: {}
                }
            }
        },
        requiredTextLeft: [{
            display:'flex', 
            alignItems:'center',
            gap: 5,
            justifyContent: "flex-start",
            bottom: 0,
            left:40,
            color: palette.redDark,
            background: palette.white
        }]
    };
}