import { classNamesFunction, Stack } from "@fluentui/react";
import React, { useState } from "react";
import { Route } from "react-router-dom";
import Page from "../../../../common/components/page/Page";
import { KnowledgeDsRoutes, KnowledgeDsTab } from "../../../routes/routes";
import Feedback from "../../feedback/feedback";
import Dataset from "../../datasets/datasets";
import KnowledgeToolbar from "../toolbar/toolbar";
import { IRootProps, IRootPropsStyles, IRootStyles } from "./root.types";
import { useNavigator } from "../../../hooks/useNavigator";
import { useOnMount } from "../../../../utilities/hooks";
import FeedbackFilters from "../../feedback/feedbackFilters/feedbackFilters";
import DatasetFilters from "../../datasets/datasetFilters/datasetFilters";


const getClassNames = classNamesFunction<IRootPropsStyles, IRootStyles>();

const collapseStyle = {
    flexBasis: 0,
    opacity: 0,
}



export const RootBase = (props: IRootProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    const navigator = useNavigator();

    const currentTab = navigator.currentTab;

    useOnMount(() => {
        navigator.changeTab(currentTab)
    })

    return (
        <>
            <Stack className={classNames.root} horizontal verticalAlign={"stretch"}>
                <Stack.Item className={classNames.leftNav} disableShrink style={sidebarCollapsed ? collapseStyle : {}}>
                    {/*filters*/}
                    <Route path={`${KnowledgeDsRoutes.knowledgeDsBase}/${KnowledgeDsTab.feedback}`}>
                        <FeedbackFilters />
                    </Route>
                    <Route path={`${KnowledgeDsRoutes.knowledgeDsBase}/${KnowledgeDsTab.datasets}`}>
                        <DatasetFilters />
                    </Route>
                </Stack.Item>
                <Stack.Item className={classNames.rightContent} grow>
                    <Stack.Item>
                        <Stack.Item>
                            <KnowledgeToolbar onMenuButtonClick={() => setSidebarCollapsed(!sidebarCollapsed)}></KnowledgeToolbar>
                        </Stack.Item>

                    </Stack.Item>

                    <Stack.Item style={{ height: '100%' }}>
                        <Route path={`${KnowledgeDsRoutes.knowledgeDsBase}/${KnowledgeDsTab.feedback}`}>
                            <Page title="Knowledge DS - Feedback" content={Feedback} ></Page>
                        </Route>
                        <Route path={`${KnowledgeDsRoutes.knowledgeDsBase}/${KnowledgeDsTab.datasets}`}>
                            <Page title="Knowledge DS - Datasets" content={Dataset} />
                        </Route>
                    </Stack.Item>

                </Stack.Item>
            </Stack>
        </>
    );
}