import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import datasetList from './features/datasetList';
import feedbackList from './features/feedbackList';
const knowledgeDsStore = configureStore({
    reducer: {
        feedbackList: feedbackList,
        datasetList: datasetList
    }

});
export type KnowledgeDsState = ReturnType<typeof knowledgeDsStore.getState>;
export type KnowledgeDsDispatch = typeof knowledgeDsStore.dispatch;
export default knowledgeDsStore;
export const useKnowledgeDsState = (): KnowledgeDsState => knowledgeDsStore.getState();
export const useKnowledgeDsDispatch = (): KnowledgeDsDispatch => useDispatch<KnowledgeDsDispatch>();
export const useKnowledgeDsSelector: TypedUseSelectorHook<KnowledgeDsState> = useSelector;