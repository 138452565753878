import React, { useState } from "react";
import { ActionButton, classNamesFunction, IconButton, Label, ScrollablePane, Stack, TextField, TooltipHost } from "@fluentui/react";
import { IDatasetFiltersPropsStyles, IDatasetFiltersStyles, IDatasetFiltersProps } from "./datasetFilters.types";
import { useTranslation } from "react-i18next";
import { resetDatasetFilters, setFilters, useDatasetList } from "../../../features/datasetList";
import { useDashboardDispatch } from "../../../dashboardStore";
import { FlatFacet, ISkillResultsTagValue } from "../../../../knowledgeLab/services/search/search.contracts";
import { useOnMount } from "../../../../utilities/hooks";
import { dashboardApi } from "../../../services/dashboard.api";
import { FacetType } from "../../../../docLab/services/search/search.contracts";
import Tag from "../../../../common/components/tag/tag";
import { Skill } from "../../../models/skill";
import { dashboardDatasetStatusText, DatasetStatus } from "../../../models/dataset";
import {setFilters as setFeedbackFilters} from "../../../features/feedbackList"
import { useNavigator } from "../../../hooks/useNavigator";

const getClassNames = classNamesFunction<IDatasetFiltersPropsStyles, IDatasetFiltersStyles>();

export const DatasetFiltersBase = (props: IDatasetFiltersProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['dashboardDatasets', 'common', 'dashboardToolbar']);
    const { filters, allDatasetItems, } = useDatasetList();
    const dispatch = useDashboardDispatch();
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    const [datasetName, setDatasetName] = useState<string>();
    const [selectedFacet, setSelectedFacet] = useState<FlatFacet[]>([]);
    const [skills, setSkills] = useState<Skill[]>([]);
    const navigator = useNavigator();

    const generateDatasetBaseFacet = (datasetStatus: DatasetStatus | undefined) => {
        return {
            key: "datasetStatus",
            value: t(dashboardDatasetStatusText.find(t => t.key === datasetStatus)?.value ?? ""),
            type: FacetType.Value,
            text: t(dashboardDatasetStatusText.find(t => t.key === datasetStatus)?.value ?? ""),
        } as FlatFacet
    }

    useOnMount(() => {
        dashboardApi.getFeedbackFilters().then((r) => {
            setSkills(r.skills);
        })
        if(filters.datasetId && filters.datasetName)
            setSelectedFacet([{
                key: "datasetId",
                value: filters.datasetName,
                type: FacetType.Value,
                text: filters.datasetName
            }])
        else
            setSelectedFacet([generateDatasetBaseFacet(filters.datasetStatus)])
    });

    const onRenderSuffix = () =>
        <IconButton
            aria-label="Search"
            iconProps={{ iconName: 'search' }}
            onClick={() => {
                if(allDatasetItems?.find(item => item.name === datasetName)){
                    dispatch(setFeedbackFilters(
                        {
                            ...filters,
                            datasetId: allDatasetItems?.find(item => item.name === datasetName)?.id,
                            datasetName: datasetName
                        }));
                    navigator.changeTab();
                }
                else{
                    dispatch(setFeedbackFilters(
                        {
                            ...filters,
                            datasetId: 1
                        }));
                    navigator.changeTab();
                }
            }}
        />

    const getTagByType = (x: FlatFacet) => {
        if (x.type === FacetType.Value && x.value)
            return getTag(x, x.value.toString(), { ...x, text: x.value.toString() })
        if (x.type === FacetType.Value)
            return x.values?.map((val: string) => {
                return getTag(x, val, { ...x, text: val });
            })
        if (x.type === FacetType.Range || x.type === FacetType.DateRange)
            return getTag(x, x.text || '', x);
    }

    const removeSelectedFacet = (f: FlatFacet) => {
        if (selectedFacet) {
            const newFacets = selectedFacet.filter(i => i.key !== f.key);
            setSelectedFacet(newFacets);
            const currentFilters = { ...filters };
            const prop = f.key as keyof typeof filters;
            currentFilters[prop] = undefined;
            dispatch(setFilters(currentFilters))
        }
    }

    const resetFilters = () => {
        setSelectedFacet([]);
        setDatasetName('');
        dispatch(resetDatasetFilters());
    }

    const getTag = (facet: FlatFacet, value: string, facetToRemove: FlatFacet) => {
        const y: ISkillResultsTagValue = { value: value, title: '', isPinned: false, toDelete: false }; //TODO generalizare tipo in input al componente Tag
        return <Stack.Item key={`StackItem_${facet.key}_${y.value.trim()}`}>
            <Tag
                onCancelSelected={() => removeSelectedFacet(facetToRemove)}
                selectableTag
                small
                key={`${facet.key}_${y.value.trim()}`}
                value={y}
                keyTag={`${facet.key}_${y.value.trim()}`} 
            />
        </Stack.Item>
    }

    const getDatasetStatusFilter = () => {
        const values = Object.values(DatasetStatus) as DatasetStatus[];
        return values.map(v =>
            <Stack.Item key={v}>
                <span
                    style={{ cursor: 'pointer', fontWeight: ((filters.datasetStatus === v) ? 'bold' : undefined), color: ((filters.datasetStatus === v) ? palette.themePrimary : '') }}
                    onClick={() => {
                        dispatch(setFilters({ ...filters, datasetStatus: v, pageNumber: 0 }));
                        if (selectedFacet) {
                            const currentFacets = [...selectedFacet];
                            const facet = currentFacets.find(f => f.key === "datasetStatus");
                            const value = t(dashboardDatasetStatusText.find(t => t.key === v)?.value ?? "");
                            if (facet) {
                                const index = currentFacets.indexOf(facet);
                                currentFacets[index] = { ...currentFacets[index], value: value }

                            } else {
                                const newFacet: FlatFacet = {
                                    key: "datasetStatus",
                                    value: value,
                                    type: FacetType.Value,
                                    text: value
                                }

                                currentFacets.push(newFacet)
                            }
                            setSelectedFacet(currentFacets);
                        }}}
                    >
                    {t(dashboardDatasetStatusText.find(t => t.key === v)?.value ?? "")}
                </span>
            </Stack.Item>
        );
    }

    return (
        <div style={{
            height: '100%',
            position: 'relative',
            maxHeight: 'inherit',
            paddingLeft: 20
        }}>

            <Stack>
                <ScrollablePane className={classNames.scrollablePane}>
                    <Stack horizontal horizontalAlign={'end'}>
                        <Stack.Item align="end" style={{ marginBottom: "10px" }}>
                            <ActionButton
                                style={{ position: "relative", right: 0 }}
                                onClick={() => resetFilters()}>
                                <span style={{ color: palette.themePrimary, textDecoration: 'underline' }}>{t('filters.resetFilters')}</span>
                            </ActionButton>
                        </Stack.Item>
                    </Stack>

                    <Stack horizontal style={{ marginTop: '15px' }} className={classNames.filterPanelItem}>
                        <Stack.Item align="center" style={{ alignItems: 'center' }} >
                            <span style={{ fontWeight: 'bold', fontSize: '1em' }}>{t('filters.filter')}</span>
                        </Stack.Item>

                        {selectedFacet?.length !== 0 && <Stack.Item align="center">
                            <TooltipHost
                                content={t('filters.resetFilter')}
                                id={"resetFilters"}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                            >
                                <IconButton
                                    iconProps={{ iconName: 'ClearFilter' }}
                                    onClick={() => resetFilters()}
                                />
                            </TooltipHost>
                        </Stack.Item>}

                    </Stack>
                    <Stack horizontal={true} wrap>
                        {selectedFacet?.map((x: FlatFacet) => {
                            return getTagByType(x)
                        })}
                    </Stack>

                    <Stack.Item className={classNames.filterPanelItem} >
                        <Label>{t('filters.datasetName')}</Label>
                        <TextField
                            value={datasetName}
                            onRenderSuffix={onRenderSuffix}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter'){
                                    if(allDatasetItems?.find(item => item.name === datasetName)){
                                        dispatch(setFeedbackFilters(
                                            {
                                                ...filters,
                                                datasetId: allDatasetItems?.find(item => item.name === datasetName)?.id,
                                                datasetName: datasetName
                                            }));
                                        navigator.changeTab();
                                    }
                                    else{
                                        dispatch(setFeedbackFilters(
                                            {
                                                ...filters,
                                                datasetId: 1
                                            }));
                                        navigator.changeTab();
                                    }
                                }
                            }}
                            onChange={(e, n) => setDatasetName(n)}
                        />
                    </Stack.Item>

                    <Stack.Item className={classNames.filterPanelItem}>
                        <Label>{t('filters.retrainingStatus')}</Label>
                        <Stack.Item style={{ margin: '0 0 8px 0' }}>
                            {getDatasetStatusFilter()}
                        </Stack.Item>
                    </Stack.Item>

                    {skills.length > 0 &&
                        <Stack.Item className={classNames.filterPanelItem}>
                            <Label>{t('filters.skillName')}</Label>
                            <Stack.Item style={{ margin: '0 0 8px 0' }}>
                                {
                                    skills.map(s =>
                                        <Stack.Item key={s.name}>
                                            <span style={{ cursor: 'pointer', fontWeight: ((filters.skillName === s.name) ? 'bold' : undefined), color: ((filters.skillName === s.name) ? palette.themePrimary : '') }} onClick={() => {
                                                dispatch(setFilters({ ...filters, skillName: s.name, pageNumber: 0 }));
                                                if (selectedFacet) {
                                                    const currentFacets = [...selectedFacet];
                                                    const facet = currentFacets.find(f => f.key === "skillName");
                                                    if (facet) {
                                                        const index = currentFacets.indexOf(facet);
                                                        currentFacets[index] = { ...currentFacets[index], value: s.name }

                                                    } else {
                                                        const newFacet: FlatFacet = {
                                                            key: "skillName",
                                                            value: s.name,
                                                            type: FacetType.Value,
                                                            text: s.name
                                                        }

                                                        currentFacets.push(newFacet)
                                                    }
                                                    setSelectedFacet(currentFacets);
                                                }

                                            }}>{s.name}</span>
                                        </Stack.Item>
                                    )
                                }
                            </Stack.Item>
                        </Stack.Item>
                    }
                </ScrollablePane>
            </Stack>


        </div>
    );
}