import { IFormalAspectSettings } from "../../../common/components/formalAspectTresholds/formalAspectTresholds.types"
import { SearchScope } from "../../components/search/search.types";
import { FileApprovalStatus } from "../../models/archiveItem";
import { ArchiveRoleId } from "../../models/constants";

/*eslint-disable @typescript-eslint/no-explicit-any */
export type SearchRequest = {
    value: string;
    orderedBy?: string;
    isAscending: boolean;
    currentArchiveId: number;
    selectedArchivesId?: number[];
    currentPage: number;
    pageSize: number;
    maxHighlights?: number;
    filters?: FlatFacet[],
    multiLanguageSearch: boolean;
    scope: SearchScope;
    folderIds?: number[]
}

export type SearchResult = {
    values: SearchValue[];
    facets: Facets;
    pageCount: number;
    disabledFacetFilters: string[];
}

export type Facets = {
    ranges: FacetsRange[];
    values: FacetsValue[];
}

export type FlatFacet = {
    key: string;
    type?: FacetType;
    from?: string | number;
    to?: string | number;
    values?: string[];
    value?: number | string | boolean;
    text?: string
}

export type JSONType = {
    [x: string]: string
}

export type MergedFacets = {
    title?: string;
    field?: string;
    type?: FacetType;
    additionalType?: FieldType;
    values?: MergedFacetItem[];
    showAll?: boolean;
    isAdditional?: boolean;
    additionalKey?: string;
    unRegistered?: boolean;
}

export type MergedFacetItem = {
    type: FacetType;
    field: string;
    value?: string;
    from?: string | number;
    to?: string | number;
    count: number;
}

export enum FieldType {
    "Edm.Int32",
    "Edm.DateTimeOffset",
    "Collection(Edm.String)",
    "Edm.String",
    "Edm.Boolean"
}

export enum FacetType {
    Value = 1,
    DateRange = 2,
    Range = 3
}

export interface IFacet<IFacetItem> {
    field: string;
    values: Array<IFacetItem>;
}

export interface IFacetItem {
    count: number;
}

export type FacetsRange = {
    field: string;
    values: FacetsRangeValue[]
}

export type FacetsValue = {
    field: string;
    values: FacetsValueCount[]
}

export class FacetsRangeValue implements IFacetItem {
    public from: string;
    public to: string;
    public count: number;

    constructor(from: string, to: string, count: number) {
        this.from = from;
        this.to = to;
        this.count = count;
    }
}

export class FacetsValueCount implements IFacetItem {
    public value: string;
    public count: number;

    constructor(value: string, count: number) {
        this.value = value;
        this.count = count;
    }
}

export type SearchValue = {
    archiveName: string;
    roleId: ArchiveRoleId;
    score: number;
    document: Document;
    highlights: Record<string, string[]>;
    rejectionNote?: string;
}

export type Document = {
    id: string;
    fileRef: number;
    title: string;
    userCreatedId: string;
    userCreatedName: string;
    dateCreated: string;
    fileFormatExtension: string;
    pageCount: number;
    wordCount: number;
    imageCount: number;
    tableCount: number;
    paragraphCount: number;
    languages: string[];
    keyphrases: string[];
    locations: string[];
    organizations: string[];
    approvalStatus?: FileApprovalStatus;
}

export interface ITag {
    tags: ITagValue[]
}

export interface ITagValue {
    key: string,
    values: ISkillResultsTagValue[],
    settings: IFormalAspectSettings[]
}

export interface ISkillResultsTagValue {
    title: string,
    value: any,
    isPinned: boolean,
    toDelete: boolean
}

export type SkillConfigurations = {
    skill: number,
    archiveId: string,
    configurationProperties: configurationProperties
}

export type configurationProperties = {
    isSkillActive: boolean,
    whitelist: string[],
    blacklist: string[],
    numKeyphrases: number
}

export interface SearchIndexField {
    name: string;
    type: string;
}

export interface SearchIndex  {
    name: string;
    fields: SearchIndexField[];
}

// export type SkillResults = {
//     tags: Tags[]
// }

// export type Tags = {
//     key: string,
//     values: SkillResultsTagValue[]
// }

// export type SkillResultsTagValue = {
//     title: string,
//     value: string,
//     isPinned: boolean
// }
