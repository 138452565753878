/*eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useState } from "react";
import { classNamesFunction, Dropdown, IBasePickerSuggestionsProps, IconButton, IDropdownOption, IPersonaProps, TextField } from "@fluentui/react";
import { ISingleStepPropsStyles, ISingleStepStyles, ISingleStepProps } from "./singleStep.types";
import { IUser } from "../../../../../../common/interfaces/IUser";
import { mapper } from "../../../../../../utilities/mapper";
import { useTranslation } from "react-i18next";
import { IExtPersonaProps } from "../../../../../../common/components/modalPages/addMembersPage";
import { WorkFlowStepTypes } from "../../../../../models/workflowStep";
import PeoplePicker from "../../../../../../common/components/peoplePicker/peoplePicker";
import { activitiesApi } from "../../../../../services/activities/activities.api";
import { useCurrentPortfolio } from "../../../../../hooks/useCurrentPortfolio";
import { WorkFlowApprover } from "../../../../../models/workflowApprover";
import { Constants } from "../../../../../models/constants";

const getClassNames = classNamesFunction<ISingleStepPropsStyles, ISingleStepStyles>();


export const SingleStepBase = (props: ISingleStepProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['createWorkflow', 'common']);
    const [showMinLengthAlert, setShowMinLengthAlert] = useState(true);

    const options: IDropdownOption[] = [
        { key: WorkFlowStepTypes.OnlyOneApprover, text: t('workflowTypes.onlyOneApprover') },
        { key: WorkFlowStepTypes.EveryoneMustApprove, text: t('workflowTypes.everyoneMustApprove') },
        { key: WorkFlowStepTypes.OnlyOneSignedApprover, text: t('workflowTypes.onlyOneSignedApprover'), disabled: !props.digitalSignEnabled }
    ];

    const currentPortfolio = useCurrentPortfolio();
    const mapPersonToUser = (person: IPersonaProps): IUser => {
        const converted: IExtPersonaProps | undefined = person as IExtPersonaProps;
        return {
            ...mapper.mapPersonaToUser(converted)
        }
    }

    const mapPersonsToApprovers = (persons: IPersonaProps[]): WorkFlowApprover[] => {
        const result: WorkFlowApprover[] = [];
        persons.forEach(person => {
            const user = mapPersonToApprover(person);
            result.push(user);
        });
        return result;
    }

    const mapPersonToApprover = (user: IPersonaProps): WorkFlowApprover => {
        const converted: IExtPersonaProps | undefined = user as IExtPersonaProps;
        return {
            ...mapper.mapPersonaToApprover(converted)
        }
    }

    const mapUserToPersona = (user: IUser): IExtPersonaProps => {
        return {
            ...mapper.mapUserToPersona(user),
            userData: user
        }
    }

    const getUsers = async (keyword: string, currentUsers: IUser[]) => {
        if (keyword.length > 2) {
            return await activitiesApi.getActivityAvaiableLeaders({
                portfolioId: currentPortfolio?.id || -1,
                pageNumber: 0,
                pageSize: 20,
                keyword: keyword,
                userIds: currentUsers.map(x => x.id),
                excludeUser: false
            });
        }
        return []
    }

    const searchForAvailableMembers = async (filter: string, selectedItems?: IPersonaProps[]) => {
        const selectedUsers = selectedItems ? selectedItems.map(x => mapPersonToUser(x)) : [];
        let filteredUser: IUser[] = [];
        try {
            await getUsers(filter, selectedUsers)?.then(response => {
                const pickerBufferSelectedUserIds = props.step.approvers.map(a => a.user.id);
                filteredUser = response.filter(user => !pickerBufferSelectedUserIds.includes(user.id))
            })
                .catch(error => {
                    console.log(error)
                    filteredUser = [];
                })
            if (filter.length > 2 && filteredUser.length === 0) {
                setShowMinLengthAlert(false)
            }
            else { setShowMinLengthAlert(true) }
            return filteredUser.map(user => mapUserToPersona(user));
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }

    const addPeopleToPickerBuffer = (selectedItems?: IPersonaProps[]) => {
        if (selectedItems)
            props.updateApprovers(mapPersonsToApprovers(selectedItems) || []);
    }

    const suggestionOptions: IBasePickerSuggestionsProps = {
        showRemoveButtons: false,
        suggestionsClassName: classNames.peoplePickerSuggestions,
        suggestionsItemClassName: classNames.peoplePickerSuggestionItem,
        loadingText: t('common:loading'),
        noResultsFoundText: showMinLengthAlert ? t('addSteps.minLengthAlert') : t('addSteps.noResult'),
    };

    const iconBar = (
        <>
            <IconButton
                styles={{ root: { transform: 'rotate(180deg)' } }}
                iconProps={{ iconName: 'DrillDownSolid' }}
                disabled={props.disabled[0]}
                onClick={() => props.moveItem(props.step, true)}
            />
            <IconButton
                iconProps={{ iconName: 'DrillDownSolid' }}
                disabled={props.disabled[1]}
                onClick={() => props.moveItem(props.step, false)}
            />
            <IconButton
                disabled={props.disabled[2]}
                iconProps={{ iconName: 'Delete' }}
                onClick={() => props.deleteItem(props.step)}
            />
        </>
    )

    const validateStepName = useCallback((value: string) => {
        if (!value || value.trim() === "")
            return `${t('common:fieldRequired')}`;
    }, [t]);

    return (
        <div className={classNames.root}>
            <div className={classNames.firstContainer}>
                <TextField
                    className={classNames.stepSingleItem}
                    label={t('addSteps.nameLabel')}
                    maxLength={Constants.MAX_WORKFLOWSTEP_NAME}
                    placeholder={t('addSteps.namePlaceholder')}
                    required
                    onGetErrorMessage={validateStepName}
                    defaultValue={props.step.name.trim()}
                    onChange={(_, newValue) => props.onChangeName(newValue || "")}
                />
                <Dropdown
                    className={classNames.stepSingleItem}
                    selectedKey={props.step.type}
                    label={t('addSteps.dropdownLabel')}
                    options={options}
                    onChange={(_, newValue) => {
                        if (!newValue)
                            return;
                        props.onChangeType(+newValue.key)
                    }}
                    required
                    styles={{
                        errorMessage: {
                            position: 'absolute'
                        }
                    }}
                    errorMessage={props.step.type === WorkFlowStepTypes.OnlyOneSignedApprover && !props.isPdf ? t('addSteps.errorType') : ""}
                    // styles={classNames.subComponentStyles.dropdown}
                    placeholder={t('addSteps.dropdownPlaceholder')}
                />
            </div>
            <div>
                <PeoplePicker
                    onResolveSuggestion={searchForAvailableMembers}
                    pickerSuggestionsProps={suggestionOptions}
                    className={classNames.peoplePickerStyles}
                    //stylePeoplePicker={peoplePickerStyles}
                    resolveDelay={500}
                    selectedItems={props.step.approvers.map(a => mapUserToPersona(a.user))}
                    onChange={addPeopleToPickerBuffer}
                    placeholder={t("addSteps.peoplePlaceholder")}
                    isRequired
                    errorMessage={t('common:fieldRequired')}
                    title={t('addSteps.approvers')}
                    customError={(props.step.type === WorkFlowStepTypes.OnlyOneSignedApprover && props.step.approvers.length > 1) ? `${t('addSteps.maxOneApprover')}` : ""}
                />
            </div>
            <div className={classNames.iconBarContainer} >
                {iconBar}
            </div>
        </div>
    );
}