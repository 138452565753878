import { IStyleFunction } from "@fluentui/react"
import { IKnowledgeEdiFileSelectorPropsStyles, IKnowledgeEdiFileSelectorStyles } from "./knowledgeEdiFileSelector.types"

export const KnowledgeEdiFileSelectorGlobalClassNames = {
    root: 'edi-knowledge-edi-file-selector-root'
}

export const getStyles: IStyleFunction<IKnowledgeEdiFileSelectorPropsStyles, IKnowledgeEdiFileSelectorStyles> = (props: IKnowledgeEdiFileSelectorPropsStyles): IKnowledgeEdiFileSelectorStyles => {
    return {
        root: [
            KnowledgeEdiFileSelectorGlobalClassNames.root,
            {
                maxHeight: '100%',
                overflow: 'hidden'
            }
        ]
    };
}