import { styled } from "@fluentui/react";
import { getStyles } from "./fileNavigator.styles";
import { IFileNavigatorPropsStyles, IFileNavigatorStyles, IFileNavigatorProps } from "./fileNavigator.types";
import { FileNavigatorBase } from "./fileNavigator.base";

const FileNavigator = styled<IFileNavigatorProps, IFileNavigatorPropsStyles, IFileNavigatorStyles>(
    FileNavigatorBase,
    getStyles,
    undefined,
    { scope: 'FileNavigator' }
);

export default FileNavigator;