import { styled } from "@fluentui/react";
import { ICreateTopicModalProps, ICreateTopicModalPropsStyles, ICreateTopicModalStyles } from "./createTopicModal.types";
import { getStyles } from "./createTopicModal.styles";
import { CreateTopicModalBase } from "./createTopicModal.base";

const CreateTopicModal = styled<ICreateTopicModalProps, ICreateTopicModalPropsStyles, ICreateTopicModalStyles>(
    CreateTopicModalBase, 
    getStyles,
    undefined,
    { scope: 'CreateTopicModal'}
)

export default CreateTopicModal;