/*eslint-disable sonarjs/cognitive-complexity */
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner"
import { DefaultButton, mergeStyleSets, PrimaryButton } from "@fluentui/react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import TeamsImage from "../../../../../../common/components/teamsImage/teamsImage"
import { ImageName } from "../../../../../../common/components/teamsImage/teamsImage.types"
import { Helpers } from "../../../../../../utilities/helpers"
import { useOnMount } from "../../../../../../utilities/hooks"
import { useCurrentPortfolio } from "../../../../../hooks/useCurrentPortfolio"
import { TemplatePhase } from "../../../../../models/templatePhase"
import { templateApi } from "../../../../../services/template/template.api"
import { CreateTemplateRequest } from "../../../../../services/template/template.contracts"


export type CreatingTemplatePageData = {
    name?: string;
    description?: string;
    phases?: TemplatePhase[];
}

export interface ICreateTemplateFirstPageProps {
    initialState: CreatingTemplatePageData;
    edit?: boolean;
    templateActivityId?: number;
    onClose?: (reload: boolean) => void;
}

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '> :not(:last-child)': {
            marginBottom: 20
        }
    },
    label: {
        fontSize: 24,
        fontWeight: 600,
        textAlign: 'center'
    },
    errorButtons: {
        display: 'flex',
        justifyContent: 'space-around',
        alignSelf: 'stretch'
    }
})

export const CreatingTemplatePage = (props: ICreateTemplateFirstPageProps) => {
    const [success, setSuccess] = useState<boolean | undefined>(undefined);
    const [creating, setCreating] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const currentPortfolio = useCurrentPortfolio();
    const { t } = useTranslation(['createActivityTemplate', 'common']);

    useOnMount(() => void createTemplate());

    const createTemplate = async () => {
        if (!currentPortfolio)
            return
        setCreating(true);
        setSuccess(undefined);
        const data = props.initialState;
        try {
            const request: CreateTemplateRequest = {
                portfolioId: currentPortfolio.id,
                description: data.description || "",
                name: data.name || "",
                templatePhases: data.phases || []
            }
            if (props.edit && props.templateActivityId) {
                await Promise.all([templateApi.updateTemplateActivity(request, props.templateActivityId), Helpers.delay(500)]);
            }
            else {
                await Promise.all([templateApi.createTemplate(request), Helpers.delay(500)]);
            }
            setSuccess(true);
            setErrorCount(0);
        }
        catch (ex) {
            setErrorCount(errorCount + 1);
            setSuccess(false);
        }
        finally {
            setCreating(false);
        }
    }

    return (
        <div className={classNames.container}>
            {creating && !success &&
                <>
                    <TeamsSpinner />
                    <span className={classNames.label}>{props.edit ? t('updateTemplate') : t('creatingTemplate')}</span>
                </>
            }
            {!creating && success &&
                <>
                    <TeamsImage imageName={ImageName.WellDone} style={{ width: '70%' }} />
                    <span className={classNames.label}>{t('common:niceWork')}</span>
                    <span className={classNames.label}>{props.edit ? t('modifyTemplateSuccess') : t('createTemplateSuccess')}</span>
                    <PrimaryButton text={t('common:leave')} onClick={() => props.onClose && props.onClose(true)} style={{ flex: '0 0 auto' }} />
                </>
            }
            {!creating && success === false &&
                <>
                    <div className={classNames.label}>{t('common:genericErrorApi')}{t('ofTemplate')}</div>
                    <div className={classNames.errorButtons}>
                        {errorCount < 3 && <DefaultButton text={t('common:tryAgain')} onClick={createTemplate} />}
                        <PrimaryButton text={t('common:leave')} onClick={() => props.onClose && props.onClose(false)} />
                    </div>
                </>
            }
        </div>
    )
}