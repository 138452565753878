/* eslint-disable sonarjs/cognitive-complexity */
import { Check, classNamesFunction, ConstrainMode, FontIcon, IColumn, PrimaryButton, SelectionMode, ShimmeredDetailsList, TooltipHost } from "@fluentui/react";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import FileIconCell from "../../../../../common/components/fileIconCell/fileIconCell";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { UploadErrors } from "../../../../../docLab/components/files/uploadFileModal/uploadFileModal.types";
import { ErrorsType } from "../../../../../docLab/models/callsApi";
import { Helpers } from "../../../../../utilities/helpers";
import { useOnMount } from "../../../../../utilities/hooks";
import { Constants } from "../../../../models/constants";
import { FileShort, ListFile } from "../../../../models/fileShort";
import { workflowApi } from "../../../../services/workflow/workflow.api";
import { SPOFolderBreadcrumb } from "./SPOfolderContent.breadcrumb";
import { ISPOFolderContentProps, ISPOFolderContentPropsStyles, ISPOFolderContentStyles } from "./SPOfolderContent.types";

const getClassNames = classNamesFunction<ISPOFolderContentPropsStyles, ISPOFolderContentStyles>();

type folderBreadcrumb = {
    uniqueId: string | undefined,
    name: string
}

export const SPOFolderContentBase = (props: ISPOFolderContentProps) => {
    const [selectedFolder, setSelectedFolder] = useState<folderBreadcrumb>({ uniqueId: "", name: "Root" })
    const { t } = useTranslation(['createWorkflow', 'common']);
    const [loadingPage, setLoadingPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [SPOFiles, setSPOFiles] = useState<ListFile>({ files: [], skipToken: undefined });
    const [error, setError] = useState(false);
    const [selectedAllEnabled] = useState(false);
    const [selectedItems, setSelectedItems] = useState<FileShort[]>(props.selection || []);
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, isEmptyList: !SPOFiles || SPOFiles.files.length === 0 });

    useOnMount(() => {
        fetchFiles(true);
        registerEvent()
    });

    const loadMoreItems = async (skipToken: string) => {
        if (!skipToken || loadingPage)
            return
        setLoadingPage(true)
        try {
            const result = await workflowApi.getPagedListFromSPO(
                props.activityId,
                selectedFolder.uniqueId,
                Constants.MAX_NUMBER_FILES_PAGE,
                skipToken);
            setSPOFiles({ files: [...SPOFiles.files, ...result.files], skipToken: result.skipToken })
        }
        catch (ex) {
            const error: ErrorsType = ex as ErrorsType;
            console.log("Error while retrieving files from SPO: ", error.message);
            setError(true)
        }
        finally {
            setLoadingPage(false)
        }
    }

    const fetchFiles = async (shimmer?: boolean, folderId?: string, folderName?: string) => {
        if (shimmer) {
            setLoading(true)
        }
        setError(false)
        try {
            let folderUniqueId = "";

            if (folderId !== undefined) {
                folderUniqueId = folderId;
                setSelectedFolder({ uniqueId: folderId, name: folderName || "" })
            }

            const [result] = await Promise.all([workflowApi.getPagedListFromSPO(
                props.activityId,
                folderUniqueId,
                Constants.MAX_NUMBER_FILES_PAGE,
                ""), Helpers.delay(500)]);

            if (shimmer)
                setSPOFiles({ files: [...result.files], skipToken: result.skipToken })
            else
                setSPOFiles({ files: [...SPOFiles.files, ...result.files], skipToken: result.skipToken })
        }
        catch (ex) {
            const error: ErrorsType = ex as ErrorsType;
            console.log("Error while retrieving files from SPO: ", error.message);
            setError(true)
        }
        finally {
            shimmer && setLoading(false)
            const listElm = document.querySelector('#SPOfolderContentDetailsListZone');
            listElm && listElm.scrollTo(0, 0);
        }
    }

    const registerEvent = useCallback(() => {
        const listElm = document.querySelector('#SPOfolderContentDetailsListZone'); //#modalListZone .ms-DetailsList-contentWrapper
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50) {
                const button = document.getElementById("click_SPOFolderContent");
                button && button.click();
            }
        });
    }, []);

    // const sortByColumn = (_: React.MouseEvent, column: IColumn) => {
    //     if (loading)
    //         return;

    //     const action = getFolderContent({
    //         orderBy: column.fieldName,
    //         isAsc: column.fieldName === data.orderBy ? !data.isAsc : true,
    //         resetPage: true,
    //         name: keyword, contentScope: setContentScope(keyword)
    //     });

    //     Helpers.deferred(action, setDeferredLoading, 200, 250);
    // };

    const loadMore = async () => {
        if (loading || !SPOFiles.skipToken ) return;
        await loadMoreItems(SPOFiles.skipToken);
    }

    const emptyFolder = useCallback(() => {
        if (SPOFiles?.files.length !== 0)
            return null;

        return (
            <div className={classNames.emptyFolder}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyFolder")}
                />
            </div>
        )
    }, [classNames.emptyFolder, SPOFiles, t]);
 
    const onRenderHeader = () => {
        return props.selectAllEnabled && SPOFiles && SPOFiles.files.length > 0 ? <div data-checked={true} className={classNames.checkboxHeader}>
            <Check checked={selectedAllEnabled} />
        </div> : <></>
    }

    const isFileSelectable = (file: FileShort) => {
        if (!file.isFolder && (!props.enabledFileTypes 
            || !props.enabledFileTypes?.some(type => type.toLowerCase() === Helpers.getFileExtension(file.name).toLowerCase()) )) {
            return UploadErrors.MimeTypeNotAllowed;
        }

        if (!file.isFolder && (!props.enabledFileTypes 
            || (file.size && Helpers.convertToMB(file.size) > 100))) {
            return UploadErrors.TooLarge;
        }

        return undefined;
    }

    const columns: IColumn[] = [{
        key: 'selection',
        name: 'selection',
        isIconOnly: true,
        onRenderHeader: onRenderHeader,
        minWidth: 20,
        maxWidth: 20,
        onRender: function getItemCell(item: FileShort) {
            if (item.isFolder && props.disableFolderSelection)
                return null;

            if (!item.isFolder && (!props.enabledFileTypes ||
                !props.enabledFileTypes?.some(type => type.toLowerCase() === Helpers.getFileExtension(item.name).toLowerCase()))) {
                return null;
            }

            const fileSelectable = isFileSelectable(item);
            if (fileSelectable){
                return (
                    <TooltipHost content={t(`uploadFileModal:${fileSelectable}`)} styles={{ root: { display: 'flex', alignItems: 'center' } }}>
                        <FontIcon iconName="StatusErrorFull" style={{ fontSize: 16, color: palette.red, cursor: 'help' }} />
                    </TooltipHost>
                )
            }

            const checked = selectedItems.some(i => i.uniqueId === item.uniqueId);

            const toggleSelected = () => {
                let newItems;

                if (props.maxNumFiles === 1) {
                    newItems = checked ? [] : [item];
                }
                else {
                    if ((selectedItems.length > (props.maxNumFiles - 1) && !checked))
                        return;
                    if (checked)
                        newItems = _.remove([...selectedItems], i => i.uniqueId !== item.uniqueId);
                    else
                        newItems = [...selectedItems, item];
                }

                setSelectedItems(newItems);
                props.onSelectionChange([...newItems]);
            }

            return (
                <div data-checked={checked} onClick={() => toggleSelected()} className={classNames.checkbox}>
                    <Check checked={checked} />
                </div>
            )
        }
    }, {
        key: 'file-type',
        name: 'File Type',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: '',
        minWidth: 20,
        maxWidth: 20,
        onRender: function getItemCell(item: FileShort) {
            const extIndex = item.name.lastIndexOf('.');
            const ext = extIndex !== -1 ? item.name.substring(extIndex) : "";
            return <FileIconCell fileExtension={ext} isFolder={item.isFolder} />
        }
    }, {
        key: 'Name',
        name: t("columns.name"),
        fieldName: 'Name',
        minWidth: 100,
        maxWidth: 350,
        // isSorted: data.orderBy === 'Name',
        // isSortedDescending: !data.isAsc,
        isRowHeader: true,
        isResizable: true,
        //onColumnClick: sortByColumn,
        onRender: function getItemName(item: FileShort) {
            return (
                <span
                    onClick={async () => { item.isFolder && await fetchFiles(true, item.uniqueId, item.name)}}
                    style={{ cursor: item.isFolder || props.canShowPreview ? 'pointer' : 'default' }}
                >
                    {item.name}
                </span>
            )
        }
    }, {
        key: 'CreatedOn',
        name: t("columns.createdOn"),
        fieldName: 'CreatedOn',
        minWidth: 130,
        maxWidth: 130,
        isResizable: true,
        // isSorted: data.orderBy === 'CreatedOn',
        // isSortedDescending: !data.isAsc,
        //onColumnClick: sortByColumn,
        onRender: function render(item: FileShort) {
            return new Date(item.createdOn).toLocaleString();
        }
    }, {
        key: 'CreatedBy',
        name: t("columns.createdBy"),
        fieldName: 'CreatedBy',
        minWidth: 130,
        maxWidth: 250,
        isResizable: true,
        isCollapsible: true,
        // isSorted: data.orderBy === 'CreatedBy',
        // isSortedDescending: !data.isAsc,
        // onColumnClick: sortByColumn,
        onRender: function getCreatedBy(item: FileShort) {
            return <span>{item.createdBy}</span>;
        },
    }];

    // const setContentScope = (name: string) => {
    //     return name ? ContentItemScope.File : ContentItemScope.All
    // }

    return (
        <>
            <div className={classNames.root}>

                <div>
                   {!error && <SPOFolderBreadcrumb
                        folderId={selectedFolder.uniqueId || ""}
                        folderName={selectedFolder.name}
                        activityId={props.activityId}
                        onClick={() => fetchFiles(true, selectedFolder.uniqueId, selectedFolder.name)}
                    />}
                </div>

                <div id={"SPOfolderContentDetailsListZone"} style={{ height: 'calc(100vh - 300px)', overflow: 'overlay' }}>
                    {error ? <div style={{ display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center', justifyContent: 'center' }}>{t('common:genericErrorApi')}</div> :
                    <ShimmeredDetailsList
                        items={SPOFiles.files || []}
                        columns={columns}
                        enableShimmer={loading}
                        getKey={(item: FileShort) => item && `${item.isFolder ? 'f' : ''}${item.uniqueId}`}
                        selectionMode={SelectionMode.none}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsFooter={emptyFolder}
                    />}
                </div>


                <div className={classNames.load}>
                    <PrimaryButton id={"click_SPOFolderContent"} onClick={async () => await loadMore()} />
                </div>
            </div>
        </>
    );
}