import { styled } from "@fluentui/react";
import { getStyles } from "./copyLinkModal.styles";
import { ICopyLinkModalPropsStyles, ICopyLinkModalStyles, ICopyLinkModalProps } from "./copyLinkModal.types";
import { CopyLinkModalBase } from "./copyLinkModal.base";

const CopyLinkModal = styled<ICopyLinkModalProps, ICopyLinkModalPropsStyles, ICopyLinkModalStyles>(
    CopyLinkModalBase,
    getStyles,
    undefined,
    { scope: 'CopyLinkModal' }
);

export default CopyLinkModal;