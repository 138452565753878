import React, { useState } from "react";
import { ActionButton, classNamesFunction, IconButton, Label, ScrollablePane, Stack, TooltipHost } from "@fluentui/react";
import { IDatasetFiltersPropsStyles, IDatasetFiltersStyles, IDatasetFiltersProps } from "./datasetFilters.types";
import { useTranslation } from "react-i18next";
import { resetDatasetFilters, setFilters, useDatasetList } from "../../../features/datasetList";
import { useKnowledgeDsDispatch } from "../../../knowledgeDsStore";
import { FlatFacet, ISkillResultsTagValue } from "../../../../knowledgeLab/services/search/search.contracts";
import { useOnMount } from "../../../../utilities/hooks";
import { knowledgeDsApi } from "../../../services/knowledgeDS.api";
import { FacetType } from "../../../../docLab/services/search/search.contracts";
import Tag from "../../../../common/components/tag/tag";

const getClassNames = classNamesFunction<IDatasetFiltersPropsStyles, IDatasetFiltersStyles>();

export const DatasetFiltersBase = (props: IDatasetFiltersProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['knowledgeDsFeedback', 'common', 'knowledgeDsToolbar']);
    const { filters } = useDatasetList();
    const dispatch = useKnowledgeDsDispatch();
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    const [selectedFacet, setSelectedFacet] = useState<FlatFacet[]>([]);
    const [skills, setSkills] = useState<string[]>([]);

    useOnMount(() => {
        knowledgeDsApi.getFeedbackFilters().then((r) => {
            setSkills(r.skills);
        })
    })

    const getTagByType = (x: FlatFacet) => {
        if (x.type === FacetType.Value && x.value)
            return getTag(x, x.value.toString(), { ...x, text: x.value.toString() })
        if (x.type === FacetType.Value)
            return x.values?.map((val: string) => {
                return getTag(x, val, { ...x, text: val });
            })
        if (x.type === FacetType.Range || x.type === FacetType.DateRange)
            return getTag(x, x.text || '', x);
    }

    const removeSelectedFacet = (f: FlatFacet) => {
        if (selectedFacet) {
            const newFacets = selectedFacet.filter(i => i.key !== f.key);
            setSelectedFacet(newFacets);
            const currentFilters = { ...filters };
            const prop = f.key as keyof typeof filters;
            currentFilters[prop] = undefined;
            dispatch(setFilters(currentFilters))
        }

    }

    const getTag = (facet: FlatFacet, value: string, facetToRemove: FlatFacet) => {
        const y: ISkillResultsTagValue = { value: value, title: '', isPinned: false, toDelete: false }; //TODO generalizare tipo in input al componente Tag
        return <Stack.Item key={`StackItem_${facet.key}_${y.value.trim()}`}>
            <Tag
                onCancelSelected={() => removeSelectedFacet(facetToRemove)}
                selectableTag
                small
                key={`${facet.key}_${y.value.trim()}`}
                value={y}
                keyTag={`${facet.key}_${y.value.trim()}`} />
        </Stack.Item>
    }

    return (
        <div style={{
            height: '100%',
            position: 'relative',
            maxHeight: 'inherit',
            paddingLeft: 20
        }}>

            <Stack>
                <ScrollablePane className={classNames.scrollablePane}>
                    <Stack horizontal horizontalAlign={'end'}>
                        <Stack.Item align="end" style={{ marginBottom: "10px" }}>
                            <ActionButton
                                style={{ position: "relative", right: 0 }}
                                onClick={() => {
                                    setSelectedFacet([]);
                                    dispatch(resetDatasetFilters());
                                }}>
                                <span style={{ color: palette.themePrimary, textDecoration: 'underline' }}>{t('filters.resetFilters')}</span>
                            </ActionButton>
                        </Stack.Item>
                    </Stack>

                    <Stack horizontal style={{ marginTop: '15px' }} className={classNames.filterPanelItem}>
                        <Stack.Item align="center" style={{ alignItems: 'center' }} >
                            <span style={{ fontWeight: 'bold', fontSize: '1em' }}>{t('filters.filter')}</span>
                        </Stack.Item>

                        {selectedFacet?.length !== 0 && <Stack.Item align="center">
                            <TooltipHost
                                content={t('filters.resetFilter')}
                                id={"resetFilters"}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                            >
                                <IconButton iconProps={{ iconName: 'ClearFilter' }} onClick={() => {
                                    setSelectedFacet([]);
                                    dispatch(resetDatasetFilters());
                                }} />
                            </TooltipHost>
                        </Stack.Item>}

                    </Stack>
                    <Stack horizontal={true} wrap>
                        {selectedFacet?.map((x: FlatFacet) => {
                            return getTagByType(x)
                        })}
                    </Stack>

                    {skills.length > 0 &&
                        <Stack.Item className={classNames.filterPanelItem}>
                            <Label>{t('filters.skillName')}</Label>
                            <Stack.Item style={{ margin: '0 0 8px 0' }}>
                                {
                                    skills.map(s =>
                                        <Stack.Item key={s}>
                                            <span style={{ cursor: 'pointer', fontWeight: ((filters.skillName === s) ? 'bold' : undefined), color: ((filters.skillName === s) ? palette.themePrimary : '') }} onClick={() => {
                                                dispatch(setFilters({ ...filters, skillName: s, pageNumber: 0 }));
                                                if (selectedFacet) {
                                                    const currentFacets = [...selectedFacet];
                                                    const facet = currentFacets.find(f => f.key === "skillName");
                                                    if (facet) {
                                                        const index = currentFacets.indexOf(facet);
                                                        currentFacets[index] = { ...currentFacets[index], value: s }

                                                    } else {
                                                        const newFacet: FlatFacet = {
                                                            key: "skillName",
                                                            value: s,
                                                            type: FacetType.Value,
                                                            text: s
                                                        }

                                                        currentFacets.push(newFacet)
                                                    }
                                                    setSelectedFacet(currentFacets);
                                                }

                                            }}>{s}</span>
                                        </Stack.Item>
                                    )
                                }
                            </Stack.Item>
                        </Stack.Item>
                    }
                </ScrollablePane>
            </Stack>


        </div>
    );
}