/*eslint-disable sonarjs/no-duplicate-string */

import { classNamesFunction, DetailsList, DetailsRow, IDetailsRowProps, Label, Link, PrimaryButton, SelectionMode, Spinner, SpinnerSize } from "@fluentui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDocLabState, useDocLabDispatch } from "../../../../docLabStore";
import { refreshJsonSchema } from "../../../../features/archiveContent";
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive";
import { SharepointLinkItem } from "../../../../services/archives/archives.contracts";
import { ISharepointLinkProps, ISharepointLinkPropsStyles, ISharepointLinkStyles } from "./sharepointLink.types";

const getClassNames = classNamesFunction<ISharepointLinkPropsStyles, ISharepointLinkStyles>();

export const SharepointLinkBase = (props: ISharepointLinkProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['sharepointLink']);
    const docLabState = useDocLabState();
    const dispatch = useDocLabDispatch();
    const currentArchive = useCurrentArchive();

    const columns = useMemo(() => {
        return [
            {
                key: 'property',
                name: t('propertyName'),
                minWidth: 100,
                maxWidth: 300,
                headerClassName: classNames.headerRow,
                isResizable: true,
                isCollapsible: true,
                onRender: function renderMemberPersona(member: SharepointLinkItem) {
                    return (
                        <div>{member.propertyName}</div>
                    );
                }
            },
            {
                key: 'link',
                name: t('link'),
                minWidth: 130,
                maxWidth: 200,
                headerClassName: classNames.headerRow,
                isResizable: true,
                isCollapsible: true,
                onRender: function renderMemberPersona(member: SharepointLinkItem) {
                    return (
                        <Link target="_blank" href={member.link} underline>{t('accessToList')}</Link>
                    );
                }
            }
        ];
    }, [classNames, t]);

    const errorsRender = () => {
        return <>{docLabState.archiveContent.archiveJsonSchemaLink.errors.map((e, i) => { return <span key={i} style={{ color: "red" }}>{e};&nbsp;</span> })}</>
    }

    return (
        docLabState.archiveContent.archiveJsonSchemaLink.isLoading ? 
        <>
           <div style={{ height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
               <Spinner size={SpinnerSize.large} />
           </div>
        </> :
        <>
           <div className={classNames.container}>
               <div>
                       <DetailsList
                           items={docLabState.archiveContent.archiveJsonSchemaLink.archiveLinks}
                           onRenderRow={(props?: IDetailsRowProps) =>
                               props ? <DetailsRow {...props} styles={classNames.subComponentStyles.row} /> : null}
                           columns={columns}
                           selectionMode={SelectionMode.none}
                       />
                        {
                            docLabState.archiveContent.archiveJsonSchemaLink.archiveLinks.length === 0 ? <Label>{t('noProperties')}</Label> : <></>   
                        } 
               </div>
               <div className={classNames.button}>
                    <div className={classNames.errorLabels}>
                        {errorsRender()}
                    </div>
                    <PrimaryButton 
                        onClick={() => dispatch(refreshJsonSchema(currentArchive?.id ?? 0))}
                        disabled={docLabState.archiveContent.archiveJsonSchemaLink.isLoading} 
                        text={t('buttonLabel')}
                    />
               </div>
           </div> 
        </>
    )
}