/*eslint-disable sonarjs/cognitive-complexity */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActivityLabState, useActivityLabSelector } from "../activityLabStore";
import { ActivityShort } from "../models/activity";
import { ActivityStatus } from "../models/constants";
import { activityApi } from "../services/activity/activity.api";
import { GetActivitiesRequest } from "../services/activity/activity.contracts";


export type ActivityListState = {
  activities: ActivityGeneral;
  plannedActivities: ActivityGeneral;
  onGoingActivities: ActivityGeneral;
  blockedActivities: ActivityGeneral;
  closedActivities: ActivityGeneral;
  currentPortfolioId: number;
};

export type ActivityGeneral = {
  activityList: ActivityShort[],
  pageNumber: number,
  pageSize: number,
  isLoading: boolean,
  lenghtLastCall: number,
  orderBy: string,
  isAsc: boolean
}

export type LoadActivitiesRequest = {
  portfolioId: number | undefined,
  activityStatus: ActivityStatus | null,
  forceRefresh: boolean,
  pageSize?: number,
  orderBy?: string,
  isAscending?: boolean
}

const initialState: ActivityListState = {
  activities: {
    activityList: [],
    pageNumber: 0,
    pageSize: 10,
    isLoading: false,
    lenghtLastCall: 0,
    orderBy: "CreatedOn",
    isAsc: false
  },
  plannedActivities: {
    activityList: [],
    pageNumber: 0,
    pageSize: 10,
    isLoading: false,
    lenghtLastCall: 0,
    orderBy: "",
    isAsc: false
  },
  onGoingActivities: {
    activityList: [],
    pageNumber: 0,
    pageSize: 10,
    isLoading: false,
    lenghtLastCall: 0,
    orderBy: "",
    isAsc: false
  },
  blockedActivities: {
    activityList: [],
    pageNumber: 0,
    pageSize: 10,
    isLoading: false,
    lenghtLastCall: 0,
    orderBy: "",
    isAsc: false
  },
  closedActivities: {
    activityList: [],
    pageNumber: 0,
    pageSize: 10,
    isLoading: false,
    lenghtLastCall: 0,
    orderBy: "",
    isAsc: false
  },
  currentPortfolioId: 0,
};

const initialFilters: GetActivitiesRequest = {
  pageNumber: 0,
  pageSize: 10,
  orderBy: undefined,
  isAscending: false,
  status: undefined,
  priorities: [],
  activityStatus: [],
  phaseStatus: [],
  templateActivities: [],
  createdOn: undefined,
  dueDate: undefined,
  portfolioId: 7
}

export const loadActivities = createAsyncThunk(
  "activities/get-activity",
  async (request: LoadActivitiesRequest, {getState, dispatch}) => {

    const state = (getState() as ActivityLabState).activityList;
    const pageSize = request.pageSize ?? 10;
    let pageNumber = 0;

    if(request.activityStatus == null){

      if(request.forceRefresh){
        dispatch(updatePageNumber({pageNumber: 0, status: null}));
        dispatch(updateOrderBy({
          status: request.activityStatus,
          isAsc: request.isAscending,
          orderBy: request.orderBy
        }));
        return await activityApi.getActivityList({...initialFilters, portfolioId: request.portfolioId, pageNumber: 0, pageSize: pageSize, orderBy: request.orderBy, isAscending: request.isAscending});
      } 

      pageNumber = state.activities.pageNumber + 1;
      dispatch(updateOrderBy({
        isAsc: request.isAscending,
        status: request.activityStatus,
        orderBy: request.orderBy
      }));
      dispatch(updatePageNumber({pageNumber: pageNumber, status: null}));

      return await activityApi.getActivityList({...initialFilters, portfolioId: request.portfolioId, pageNumber: pageNumber, pageSize: pageSize, orderBy: request.orderBy, isAscending: request.isAscending});
    }

    //se il portfolio id cambia, devo di conseguenza resettare il pagenumber.
    if(request.forceRefresh){
      dispatch(updatePageNumber({pageNumber: 0, status: request.activityStatus}));
      return await activityApi.getActivityList({...initialFilters, status: request.activityStatus, portfolioId: request.portfolioId, pageNumber: 0});
    } 

    //Recupero il corretto pagenumber in base allo stato
    switch(request.activityStatus){
      case ActivityStatus.Planned:
        pageNumber = state.plannedActivities.pageNumber + 1;
      break;

      case ActivityStatus.OnGoing:
        pageNumber = state.onGoingActivities.pageNumber + 1;
      break;

      case ActivityStatus.Blocked:
        pageNumber = state.blockedActivities.pageNumber + 1;
      break;

      case ActivityStatus.Closed:
        pageNumber = state.closedActivities.pageNumber + 1;
      break;
    }

    dispatch(updatePageNumber({pageNumber: pageNumber, status: request.activityStatus}));
    return await activityApi.getActivityList({...initialFilters, status: request.activityStatus, portfolioId: request.portfolioId, pageNumber: pageNumber});
  } 
);

const getActivitiesSlice = createSlice({
  name: "activityLab-activities-get",
  initialState,
  reducers: {
    updateCurrentPortfolioId: (state: ActivityListState, action: PayloadAction<number>) => {
      state.currentPortfolioId = action.payload;
    },

    updatePageNumber: (state: ActivityListState, action: PayloadAction<{
      pageNumber: number,
      status: ActivityStatus | null
    }>) => {
      switch(action.payload.status){
        case ActivityStatus.Planned:
          state.plannedActivities.pageNumber = action.payload.pageNumber;
        break;

        case ActivityStatus.OnGoing:
          state.onGoingActivities.pageNumber = action.payload.pageNumber;
        break;

        case ActivityStatus.Blocked:
          state.blockedActivities.pageNumber = action.payload.pageNumber;
        break;

        case ActivityStatus.Closed:
          state.closedActivities.pageNumber = action.payload.pageNumber;
        break;

        default:
          state.activities.pageNumber = action.payload.pageNumber;
      }

    },

    updatePageSize: (state: ActivityListState, action: PayloadAction<{
      pageSize: number,
      status: ActivityStatus
    }>) => {
      switch(action.payload.status){
        case ActivityStatus.Planned:
          state.plannedActivities.pageSize = action.payload.pageSize;
        break;

        case ActivityStatus.OnGoing:
          state.onGoingActivities.pageSize = action.payload.pageSize;
        break;

        case ActivityStatus.Blocked:
          state.blockedActivities.pageSize = action.payload.pageSize;
        break;

        case ActivityStatus.Closed:
          state.closedActivities.pageSize = action.payload.pageSize;
        break;
      }
    },

    removeAllActivities: (state: ActivityListState, action: PayloadAction) => {
      state.plannedActivities.activityList = [];
      state.onGoingActivities.activityList = [];
      state.blockedActivities.activityList = [];
      state.closedActivities.activityList = [];
      state.activities.activityList = [];
    },

    updateOrderBy: (state: ActivityListState, action: PayloadAction<{
      orderBy?: string,
      isAsc?: boolean,
      status: ActivityStatus | null
    }>) => {
      switch(action.payload.status){
        case ActivityStatus.Planned:
          state.plannedActivities.orderBy = action.payload.orderBy ?? state.plannedActivities.orderBy;
          state.plannedActivities.isAsc = action.payload.isAsc ?? state.plannedActivities.isAsc;
        break;

        case ActivityStatus.OnGoing:
          state.onGoingActivities.orderBy = action.payload.orderBy ?? state.plannedActivities.orderBy;
          state.onGoingActivities.isAsc = action.payload.isAsc ?? state.plannedActivities.isAsc;
          
        break;

        case ActivityStatus.Blocked:
          state.blockedActivities.orderBy = action.payload.orderBy ?? state.plannedActivities.orderBy;
          state.blockedActivities.isAsc = action.payload.isAsc ?? state.plannedActivities.isAsc;
        break;

        case ActivityStatus.Closed:
          state.closedActivities.orderBy = action.payload.orderBy ?? state.plannedActivities.orderBy;
          state.closedActivities.isAsc = action.payload.isAsc ?? state.plannedActivities.isAsc;
        break;

        default:          
          state.activities.orderBy = action.payload.orderBy ?? state.plannedActivities.orderBy;
          state.activities.isAsc = action.payload.isAsc ?? state.plannedActivities.isAsc;
        break;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadActivities.pending, (state, action) => {
      const request = action.meta.arg;
      
      switch(request.activityStatus){
        case ActivityStatus.Planned:
          state.plannedActivities.isLoading = true;
          state.plannedActivities.pageSize = request.pageSize ?? 10;
          if(request.forceRefresh)
            state.plannedActivities.activityList = [];
        break;

        case ActivityStatus.OnGoing:
          state.onGoingActivities.isLoading = true;
          state.onGoingActivities.pageSize = request.pageSize ?? 10;
          if(request.forceRefresh)
            state.onGoingActivities.activityList = [];
        break;

        case ActivityStatus.Blocked:
          state.blockedActivities.isLoading = true;
          state.blockedActivities.pageSize = request.pageSize ?? 10;
          if(request.forceRefresh)
            state.blockedActivities.activityList = [];
        break;

        case ActivityStatus.Closed:
          state.closedActivities.isLoading = true;
          state.closedActivities.pageSize = request.pageSize ?? 10;
          if(request.forceRefresh)
            state.closedActivities.activityList = [];
        break;

        default:
          state.activities.isLoading = true;
          state.activities.pageSize = request.pageSize ?? 10;
          if(request.forceRefresh)
            state.activities.activityList = [];
        break;
      }
    });

    builder.addCase(loadActivities.fulfilled, (state, action) => {
      const request = action.meta.arg;
      if(request.portfolioId !== state.currentPortfolioId)
        return;
        

      switch(request.activityStatus){
        case ActivityStatus.Planned:
          state.plannedActivities.isLoading = false;
          state.plannedActivities.lenghtLastCall = action.payload.length;

          if(state.plannedActivities.pageNumber === 0){
            state.plannedActivities.activityList = action.payload;
          }
          if(state.plannedActivities.pageNumber > 0){
            state.plannedActivities.activityList = state.plannedActivities.activityList.concat(action.payload);
          }
        break;

        case ActivityStatus.OnGoing:
          state.onGoingActivities.isLoading = false;
          state.onGoingActivities.lenghtLastCall = action.payload.length;


          if(state.onGoingActivities.pageNumber === 0){
            state.onGoingActivities.activityList = action.payload;
          }
          if(state.onGoingActivities.pageNumber > 0){
            state.onGoingActivities.activityList = state.onGoingActivities.activityList.concat(action.payload);
          }
        break;

        case ActivityStatus.Blocked:
          state.blockedActivities.isLoading = false;
          state.blockedActivities.lenghtLastCall = action.payload.length;


          if(state.blockedActivities.pageNumber === 0){
            state.blockedActivities.activityList = action.payload;
          }
          if(state.blockedActivities.pageNumber > 0){
            state.blockedActivities.activityList = state.blockedActivities.activityList.concat(action.payload);
          }
        break;

        case ActivityStatus.Closed:
          state.closedActivities.isLoading = false;
          state.closedActivities.lenghtLastCall = action.payload.length;

          if(state.closedActivities.pageNumber === 0){
            state.closedActivities.activityList = action.payload;
          }
          if(state.closedActivities.pageNumber > 0){
            state.closedActivities.activityList = state.closedActivities.activityList.concat(action.payload);
          }
        break;

        default:
          state.activities.isLoading = false;
          state.activities.lenghtLastCall = action.payload.length;

          if(state.activities.pageNumber === 0){
            state.activities.activityList = action.payload;
          }
          if(state.activities.pageNumber > 0){
            state.activities.activityList = state.activities.activityList.concat(action.payload);
          }
        break;
      }
    });

    builder.addCase(loadActivities.rejected, (state, action) => {
      const request = action.meta.arg;

      switch(request.activityStatus){
        case ActivityStatus.Planned:
          state.plannedActivities.isLoading = false;
        break;

        case ActivityStatus.OnGoing:
          state.onGoingActivities.isLoading = false;
        break;

        case ActivityStatus.Blocked:
          state.blockedActivities.isLoading = false;
        break;

        case ActivityStatus.Closed:
          state.closedActivities.isLoading = false;
        break;
        
        default:
          state.activities.isLoading = false;
        break;
      }
    });
  }
});


export const { updateCurrentPortfolioId, updatePageNumber, updatePageSize, removeAllActivities, updateOrderBy } = getActivitiesSlice.actions;
export default getActivitiesSlice.reducer;
export const useActivityList = () => useActivityLabSelector((state) => state.activityList);