import { IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react"
import { ISharepointLinkStyles, ISharepointLinkPropsStyles } from "./sharepointLink.types";

export const SharepointLinkGlobalClassNames = {
    root: 'edi-sharepointlink-root',
    container: 'edi-sharepointlink-container',
    button: 'edi-sharepointlink-button',
    headerRow: 'edi-sharepointlink-headerrow',
    labelEmpty: 'edi-sharepointlink-label-empty',
    errorLabels: 'edi-sharepointlink-error-labels',
}
export const getStyles: IStyleFunction<ISharepointLinkPropsStyles, ISharepointLinkStyles> = (props: ISharepointLinkPropsStyles): ISharepointLinkStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    
    return {
        root: [
            SharepointLinkGlobalClassNames.root,
            {

            }
        ],
        container: [
            SharepointLinkGlobalClassNames.container,
            {
                display: 'flex',
                flexDirection: 'column'

            }            
        ],
        button: [
            SharepointLinkGlobalClassNames.button,
            {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }
        ],
        headerRow: [
            SharepointLinkGlobalClassNames.headerRow,
            {
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                }
            }
        ],
        errorLabels: [
            SharepointLinkGlobalClassNames.errorLabels,
            {
                display: 'flex',
                flexDirection: 'column'
            }
        ],
        emptyLabel: [
            SharepointLinkGlobalClassNames.labelEmpty,
            {
                display: 'flex'
            }
        ],
        subComponentStyles: {
            list: (): IDetailsListStyles => {
                return {
                    root: {
                        selectors: {
                            '& [role=grid]': {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                maxHeight: '65vh'
                            }
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto',
                        '> .ms-DetailsHeader': {
                            paddingTop: 0,
                            borderBottomColor: 'transparent'
                        }
                    },
                    contentWrapper: {
                        flex: '1 1 auto',
                        overflowY: 'overlay',
                        overflowX: 'hidden',
                        width: '100%'
                    },
                    focusZone: {}
                }
            },
            row: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        backgroundColor: palette.white,
                        ':hover': {
                            backgroundColor: palette.neutralLight
                        },
                        paddingRight: '15px',
                        paddingLeft: '15px'
                    },
                    cell: {},
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            }
        }
    };
}