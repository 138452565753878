/*eslint-disable sonarjs/cognitive-complexity */

import { classNamesFunction, DefaultButton, DetailsList, DetailsRow, IColumn, IIconProps, PrimaryButton, SelectionMode, Spinner, SpinnerSize, TooltipHost } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helpers } from "../../../../../utilities/helpers";
import { useActivityLabDispatch } from "../../../../activityLabStore";
import { loadActivities, useActivityList } from "../../../../features/activityList";
import { useCurrentPortfolio } from "../../../../hooks/useCurrentPortfolio";
import { ActivityShort } from "../../../../models/activity";
import { PortfolioRoleId } from "../../../../models/constants";
import { PersonaDetails } from "../../../../models/user";
import { defaultGetActivitiesRequest, GetActivitiesRequest } from "../../../../services/activity/activity.contracts";
import ActivityExpired from "../../common/activityExpired/activityExpired";
import ActivityType from "../../common/activityType/activityType";
import MemberList from "../../common/memberList/memberList";
import CreateActivity from "../../createActivity/createActivity";
import InfoStatus from "../../infoStatus/infoStatus";
import { IActivitiesListProps, IActivitiesListPropsStyles, IActivitiesListStyles } from "./activitiesList.types";

const getClassNames = classNamesFunction<IActivitiesListPropsStyles, IActivitiesListStyles>();

export const ActivitiesListBase = (props: IActivitiesListProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    const { t } = useTranslation(['activity', 'common']);
    
    const [planActivity, setPlanActivity] = useState<boolean>(false);
    const addIcon: IIconProps = { iconName: 'Add', style: { height: 'auto' } };
    const [filters, setFilters] = useState<GetActivitiesRequest>(defaultGetActivitiesRequest);
    const [showInfo, setShowInfo] = useState<number>();

    const currentPortfolio = useCurrentPortfolio();
    const dispatch = useActivityLabDispatch();
    const { activities, currentPortfolioId } = useActivityList();

    const registerEvent = () => {
        const listElm = document.querySelector('#activitiesListZone .ms-DetailsList-contentWrapper');
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50) {
                const button = document.getElementById("click");
                button && button.click();
            }
        });
    }

    useEffect(() => {
        if (!activities.isLoading) registerEvent()
    }, [activities.isLoading]);

    useEffect(() => {
        if(currentPortfolioId && !activities.isLoading && activities.activityList.length === 0){
            dispatch(loadActivities({
                portfolioId: currentPortfolio?.id,
                activityStatus: null,
                forceRefresh: true,
                pageSize: 20,
                orderBy: filters.orderBy,
                isAscending: filters.isAscending
            }));
        }
    }, [currentPortfolioId]); //eslint-disable-line react-hooks/exhaustive-deps

    const loadMore = () => {
        if (activities.isLoading || (activities.lenghtLastCall < 20) )
            return;
        dispatch(loadActivities({
            portfolioId: currentPortfolio?.id,
            activityStatus: null,
            forceRefresh: false,
            pageSize: 20,
            orderBy: activities.orderBy,
            isAscending: activities.isAsc
        }));
    }

    const sortByColumn = (_: React.MouseEvent, column: IColumn) => {
        if (column.fieldName && column.isSorted !== undefined) {
            setFilters({ ...filters, orderBy: column.fieldName, isAscending: !filters.isAscending, pageNumber: 0 });
            dispatch(loadActivities({
                portfolioId: currentPortfolio?.id,
                activityStatus: null,
                forceRefresh: true,
                pageSize: 20,
                orderBy: column.fieldName,
                isAscending: !activities.isAsc
            }));
        }
    };

    const columns: IColumn[] = [
        {
            key: 'Activty Expired',
            name: '',
            ariaLabel: 'name',
            isResizable: true,
            fieldName: '',
            minWidth: 20,
            maxWidth: 20,
            isCollapsible: true,
            headerClassName: classNames.root,
            onRender: function getItemName(item: ActivityShort) {
                return <ActivityExpired activity={item}></ActivityExpired>
            }
        },
        {
            key: 'name',
            name: t('activitiesList.column.name'),
            ariaLabel: 'name',
            isResizable: true,
            fieldName: 'Name',
            minWidth: 150,
            maxWidth: 200,
            isCollapsible: true,
            isSorted: activities.orderBy === 'Name',
            isSortedDescending: !activities.isAsc && activities.orderBy === 'Name',
            onColumnClick: (_, column) => sortByColumn(_, column),
            onRender: function getItemName(item: ActivityShort) {
                return (<>
                    <TooltipHost styles={classNames.subComponentStyles.tooltipStyle} content={item.name}>
                        <div
                            onClick={() => setShowInfo(item.id)}
                            className={classNames.activityName} >
                            {item.name}
                        </div>
                    </TooltipHost>
                </>)
            }
        },
        {
            key: 'template',
            name: t('activitiesList.column.template'),
            ariaLabel: 'template',
            fieldName: 'templateId',
            minWidth: 170,
            maxWidth: 200,
            isCollapsible: true,
            isResizable: true,
            onRender: function getItemType(item: ActivityShort) {
                return <div style={{ width: 'min-content' }}><ActivityType activity={item}></ActivityType></div>
            }
        },
        {
            key: 'createdOn',
            name: t('activitiesList.column.createdOn'),
            ariaLabel: 'createdOn',
            fieldName: 'CreatedOn',
            minWidth: 150,
            maxWidth: 200,
            isCollapsible: true,
            isResizable: true,
            isSorted: activities.orderBy === 'CreatedOn',
            isSortedDescending: !activities.isAsc && activities.orderBy === 'CreatedOn',
            onColumnClick: (_, column) => sortByColumn(_, column),
            onRender: function getItemType(item: ActivityShort) {
                return <span>{Helpers.getShortDate(item.createdOn)}</span>
            }
        },
        {
            key: 'members',
            name: t('activitiesList.column.members'),
            ariaLabel: 'members',
            fieldName: 'Members',
            minWidth: 200,
            maxWidth: 250,
            isResizable: true,
            isCollapsible: true,
            onRender: function getItemDate(item: ActivityShort) {
                return <MemberList members={item.members as PersonaDetails[]} />
            }
        },
        {
            key: 'status',
            name: t("activitiesList.column.status"),
            ariaLabel: 'status',
            fieldName: 'Status',
            minWidth: 100,
            maxWidth: 210,
            isResizable: true,
            isCollapsible: true,
            isSorted: filters.orderBy === 'Status',
            isSortedDescending: !filters.isAscending,
            onColumnClick: (_, column) => sortByColumn(_, column),
            onRender: function getItemFrom(item: ActivityShort) {
                return <span>{t(`status.${item.status}`)}</span>
            }
        }
    ];

    const emptyList = useCallback(() => {
        if (activities.activityList.length !== 0)
            return null;

        return (
            <div className={classNames.emptyList}>
                <span>{t("common:emptyList")}</span>
            </div>
        )
    }, [activities.activityList.length, t, classNames.emptyList]);

    return (
        <>
            {showInfo && <InfoStatus closeModal={() => setShowInfo(undefined)} activityId={showInfo} />}
            {currentPortfolio?.portfolioRoleId === PortfolioRoleId.Owner ?
                <div style={{ marginLeft: '30px', marginTop: '30px' }}>
                    <DefaultButton
                        text={t("common:planActivity")}
                        iconProps={addIcon}
                        onClick={() => setPlanActivity(true)}
                    />
                </div>
                : ''
            }
            <div className={classNames.detailListContainer} id={"activitiesListZone"}>
                
                { activities.isLoading && activities.activityList.length === 0 ?
                    <div style={{ height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Spinner size={SpinnerSize.large} />
                    </div>
                    :
                        <>
                        <div style={{ height: 'calc(100% - 10px)', overflow: 'overlay' }}>                        
                            <DetailsList
                                items={activities.activityList}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                isHeaderVisible={true}
                                getKey={((item: ActivityShort) => item.id.toString())}
                                setKey="none"
                                styles={classNames.subComponentStyles.detailsList}
                                onRenderRow={props => props ? <DetailsRow {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
                                onRenderDetailsFooter={emptyList}
                            />                        
                        </div>
                        <div className={classNames.load}>
                            {/* className={classNames.load} */}
                            <PrimaryButton id={`click`} onClick={loadMore} />
                        </div>
                        </>
                    }
                {
                    planActivity && 
                        <CreateActivity
                            portfolioId={currentPortfolio?.id ? currentPortfolio?.id : 0}
                            onClose={() => setPlanActivity(false)}
                        />
                }
            </div>
        </>
    );
}