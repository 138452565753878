import { IDetailsColumnStyleProps, IDetailsColumnStyles, IDetailsHeaderStyleProps, IDetailsHeaderStyles, IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react";
import { IImportACPVModalPropsStyles, IImportACPVModalStyles } from "./importACPVModal.types";

export const ImportACPVModalGlobalClassNames = {
    root: 'edi-importACPVModal-root',
    buttonContainer: 'edi-importACPVModal-buttonContainer',
    centerContentContainer: 'edi-importACPVModal-centerContentContainer',
    secondFooterContainer: 'edi-importACPVModal-secondFooterContainer',
    sandglassImage: 'edi-importACPVModal-sandglassImage',
    importingFooter: 'edi-importACPVModal-exportingFooter',
}

export const getStyles: IStyleFunction<IImportACPVModalPropsStyles, IImportACPVModalStyles> = (props: IImportACPVModalPropsStyles): IImportACPVModalStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            ImportACPVModalGlobalClassNames.root,
            {
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center'
            }
        ],
        buttonContainer: [
            ImportACPVModalGlobalClassNames.buttonContainer,
            {
                display: 'flex',
                justifyContent: 'flex-end',
                alignContent: 'center'
            }
        ],
        centerContentContainer: [
            ImportACPVModalGlobalClassNames.centerContentContainer,
            {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        ],
        secondFooterContainer: [
            ImportACPVModalGlobalClassNames.secondFooterContainer,
            {
                display: 'flex',
                alignItems: 'center',
                height: '100%'
            }
        ],
        sandglassImage: [
            ImportACPVModalGlobalClassNames.sandglassImage,
            {
                flexBasis: '50%',
                flexShrink: 0
            }
        ],
        importingFooter: [
            ImportACPVModalGlobalClassNames.importingFooter,
            {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center'
            }
        ],
        subComponentStyles: {
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                            height: '400px'
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto',
                        '& [role="checkbox"]': {
                            opacity: 'unset',
                        }
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        overflowY: 'overlay',
                        overflowX: 'hidden',
                        height: 'calc(100vh - 285px)',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {
                    }
                }
            },
            columnHeader: (props: IDetailsColumnStyleProps): IDetailsColumnStyles => {
                return {
                    root: {},
                    gripperBarVerticalStyle: {},
                    cellTooltip: {},
                    cellTitle: {},
                    cellName: {
                        fontWeight: 'bold',
                        fontSize: 14
                    },
                    iconClassName: {},
                    nearIcon: {},
                    accessibleLabel: {},
                    sortIcon: {},
                    filterChevron: {},
                    borderAfterDropping: {},
                    noBorderAfterDropping: {},
                    borderWhileDragging: {},
                    noBorderWhileDragging: {},
                }
            },
            detailsHeader: (props: IDetailsHeaderStyleProps): IDetailsHeaderStyles => {
                return {
                    root: {
                        borderBottom: '0px',
                        paddingTop: 0
                    },
                    cellIsCheck: {},
                    check: {},
                    cellWrapperPadded: {},
                    cellIsActionable: {},
                    cellIsEmpty: {},
                    cellSizer: {},
                    cellSizerStart: {},
                    cellSizerEnd: {},
                    cellIsResizing: {},
                    cellIsGroupExpander: {},
                    collapseButton: {},
                    checkTooltip: {},
                    sizingOverlay: {},
                    dropHintCircleStyle: {},
                    dropHintCaretStyle: {},
                    dropHintLineStyle: {},
                    dropHintStyle: {},
                    accessibleLabel: {},
                }
            },
            reloadIconButton: {
                root: {
                    color: palette.black,
                    transform: 'rotate(90deg)'
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`,
                    color: palette.themePrimary
                },
                rootExpanded: {
                    backgroundColor: 'transparent',
                    color: palette.themePrimary,
                    textShadow: 'rgb(37 36 35) 0px 0px 1px'
                },
                rootPressed: {
                    backgroundColor: 'transparent',

                },
                rootHovered: {
                    backgroundColor: 'transparent',
                },
                rootDisabled: {
                    backgroundColor: 'transparent'
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                const important = " !important"
                return {
                    root: {
                        background: palette.white + important,
                        borderBottom: '0px',
                        selectors: {
                            ':hover': { 
                                backgroundColor: palette.neutralLighterAlt + important
                            }
                        },
                        '&.is-selected':{
                            backgroundColor: palette.neutralLighterAlt + important,
                            borderBottom: '0px' + important
                        }
                    },
                    cell: {
                        display: 'flex',
                        alignItems: 'center',
                        paddingBottom: '0px',
                        paddingTop: '0px !important',
                        height: '100%',
                        'span': {
                            minWidth: 0,
                            whiteSpace: 'no-wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }
                    },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            }
        }
        
    };
}