import React from "react";
import { classNamesFunction, DefaultButton, DirectionalHint, Icon, IconButton, TextField, TooltipHost } from "@fluentui/react";
import { IJsonFormPropsStyles, IJsonFormStyles, IJsonFormProps } from "./jsonForm.types";
import Form from "@rjsf/fluent-ui";
import { useTranslation } from "react-i18next";
import CustomWidgetSelect from "./customWidgetSelect/customWidgetSelect";
import CustomWidgetDate from "./customWidgetDate/customWidgetDate";
import { DateTime } from "luxon";
import { isNumber } from "lodash";
import Ajv2020 from 'ajv/dist/2020.js';
import { customizeValidator } from "@rjsf/validator-ajv8";

const getClassNames = classNamesFunction<IJsonFormPropsStyles, IJsonFormStyles>();
/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable @typescript-eslint/no-explicit-any*/
export const JsonFormBase = (JsonFormprops: IJsonFormProps) => {
    const classNames = getClassNames(JsonFormprops.styles, { theme: JsonFormprops.theme, className: JsonFormprops.className, disabled: JsonFormprops.disabled });
    const { t } = useTranslation(['tagPanel', 'common']);

    const AJV8_2020 = customizeValidator({ AjvClass: Ajv2020 });


    const CustomFieldTemplate = (props: any) => {
        const { children, schema, rawErrors, required, formData, id, label, rawHelp, rawDescription } = props;
        const e: boolean = ((!formData || formData.length === 0) && required && rawErrors) ||
            (props.schema.enum && formData && !props.schema.enum.includes(formData)) ||
            (formData && formData.length > 0 && props.schema.items && props.schema.items.enum && !props.schema.items.enum.includes(formData[0])) ||
            (props.schema.type === "number" && formData && (!isNumber(formData) || (props.schema.multipleOf && formData % props.schema.multipleOf !== 0))) ||
            (props.schema.type === "integer" && formData && !Number.isInteger(formData)) ||
            (rawErrors !== undefined);

        const getChildren = () => {
            if (props.schema.type === "array" && formData && formData.length === 0 && props.disabled) {
                return <TextField disabled={true}></TextField>
            }
            return children;
        }

        return (
            <div style={{ marginBottom: 10 }}>
                <div style={{ flexGrow: 1 }} className={e && !JsonFormprops.disabled ?
                    classNames.childrenContainerWithError : props.readonly && !JsonFormprops.disabled ? classNames.childrenContainerDisabled : classNames.childrenContainer}>
                    {(props.schema.type === "string" || props.schema.type === "integer" || props.schema.type === "number" || (props.schema.type === "array" && formData && formData.length === 0 && props.disabled)) && !props.hidden && !props.schema.enum && !props.schema.format && label &&
                        <div className="labelField">{label}
                            {required && <span className={classNames.required}>*</span>}
                            {rawDescription && <TooltipHost styles={{ root: { marginLeft: 5 } }} content={rawDescription}><Icon
                                styles={{ root: { fontSize: 13, cursor: 'pointer' } }}
                                iconName="Info" />
                            </TooltipHost>}
                        </div>}
                    {(formData && JsonFormprops.disabled
                        && (!rawErrors || rawErrors.length === 0)
                        && id !== "root") ?
                        <TooltipHost directionalHint={DirectionalHint.bottomCenter} key={id + "_" + formData} content={renderFormData(formData, props.schema.format)}>
                            {(props.schema.type === "array" && classNames.arrayFormStyle ? <div className={`${classNames.arrayFormStyle} ${props.disabled && classNames.arrayFormDisabledStyle}`}>
                                {getChildren()}
                            </div> : getChildren())}
                        </TooltipHost> : (props.schema.type === "array" && classNames.arrayFormStyle ? <div className={`${classNames.arrayFormStyle} ${props.disabled && classNames.arrayFormDisabledStyle}`}>{getChildren()}</div> : getChildren())
                    }
                    {/* {e && !JsonFormprops.disabled && <span className={classNames.errorForm}> {t('metadata.requiredField')}</span>} */}
                    {rawHelp && (schema.type !== "array" || (schema.type === "array" && schema.uniqueItems)) && <div className={classNames.help}>{rawHelp}</div>}
                </div>
            </div>
        );
    }

    const renderFormData = (data: any, format: string): string => {
        try {
            let str = "";
            if (format === "date") {
                str = DateTime.fromISO(data, { zone: 'utc' }).toFormat('dd/MM/yyyy')
            }
            else if (typeof (data) === 'string')
                return data;
            else if (data.length !== undefined) {
                data.forEach((d: any, i: number) => {
                    str += d;
                    if (data.length - 1 > i)
                        str += "; "
                });
            }
            return str
        }
        catch (ex) {
            console.log(ex);
            JsonFormprops.hasErrorFE();
            return ''
        }
    }

    const CustomDescriptionField = (props: any) => { return <div style={{ fontStyle: 'italic', marginBottom: '20px' }} id={props.id}>{props.description}</div>; };

    // const descriptionsFields = { DescriptionField: CustomDescriptionField };

    const CustomArrayFieldTemplate = (props: any) => {
        const { canAdd, items, onAddClick, title, required, uiSchema } = props;
        const notError: boolean = props.formData && props.formData.every((value: any) => value !== undefined);
        return (
            <div style={{ marginBottom: 10 }}>
                <div className={classNames.titleSection}>{title}
                    {required && <span className={classNames.required}>*</span>}
                    {title && uiSchema['ui:description'] && <TooltipHost styles={{ root: { marginLeft: 5 } }} content={uiSchema['ui:description']}><Icon
                        styles={{ root: { fontSize: 13, cursor: 'pointer' } }}
                        iconName="Info" />
                    </TooltipHost>}
                    {title && uiSchema['ui:help'] && <div className={classNames.help}>{uiSchema['ui:help']}</div>}
                </div>
                <>
                    {items.map((element: any) =>
                        <div
                            style={element.children.props.schema.properties ? { alignItems: 'center' } : {}}
                            className={(!notError || items.length === 0) && required && !JsonFormprops.disabled ? classNames.arrayFieldItemWithError : classNames.arrayFieldItem}
                            key={element.key}>
                            <div style={{ flexGrow: 1, maxWidth: 490 }}>
                                <>{element.children}</>
                            </div>
                            {!JsonFormprops.disabled && <div>
                                <IconButton
                                    styles={classNames.subComponentStyles.iconButton()}
                                    iconProps={{ iconName: "Up" }}
                                    onClick={element.onReorderClick(element.index, element.index - 1)}
                                    disabled={!element.hasMoveUp}
                                />
                                <IconButton
                                    styles={classNames.subComponentStyles.iconButton()}
                                    iconProps={{ iconName: "Down" }}
                                    onClick={element.onReorderClick(element.index, element.index + 1)}
                                    disabled={!element.hasMoveDown}
                                />
                                <IconButton
                                    styles={classNames.subComponentStyles.iconButton()}
                                    iconProps={{ iconName: "Delete" }}
                                    onClick={element.onDropIndexClick(element.index)}
                                    disabled={!element.hasRemove}
                                />
                            </div>}
                        </div>
                    )}
                    {canAdd && !JsonFormprops.disabled &&
                        <DefaultButton styles={{ root: { margin: "5px 0 0 0" } }} onClick={onAddClick}>
                            <Icon styles={{ root: { marginRight: 4 } }} iconName="Add" />{t('common:add')}
                        </DefaultButton>}
                    {/* {(!notError || items.length === 0) && required &&
                        <div style={{ marginTop: 5 }} className={classNames.errorForm}>{t('metadata.requiredField')}</div>
                    } */}
                </>
            </div>
        );
    }

    const customSelect = (selectProps: any) => {
        return (<CustomWidgetSelect hasError={JsonFormprops.hasErrorFE} selectProps={selectProps} disabled={JsonFormprops.disabled} />)
    }

    const customDate = (dateProps: any) => {
        return (<CustomWidgetDate hasError={JsonFormprops.hasErrorFE} dateProps={dateProps} disabled={JsonFormprops.disabled} />)
    }


    const widgets = {
        SelectWidget: customSelect,
        DateWidget: customDate
    };
    
        
    return (
        <>
            <Form
                schema={JsonFormprops.schema}
                liveOmit
                omitExtraData={false}
                disabled={JsonFormprops.disabled}
                formData={JsonFormprops.formData || {}}
                onError={(e) => JsonFormprops.onError(e)}
                onSubmit={(ev) => JsonFormprops.onSubmit()}
                showErrorList={false}
                uiSchema={JsonFormprops.uiSchema}
                noHtml5Validate
                widgets={widgets}
                readonly={JsonFormprops.disabled}
                liveValidate={true}
                templates={{
                    ArrayFieldTemplate: CustomArrayFieldTemplate,
                    FieldTemplate: CustomFieldTemplate,
                    DescriptionFieldTemplate: CustomDescriptionField
                }}
                onChange={(ev) => { JsonFormprops.onChange(ev) }}
                transformErrors={(err) => {
                    if(JsonFormprops.errorsOnChangeFormData)
                        JsonFormprops.errorsOnChangeFormData(err);
                    return err;
                }}
                validator={AJV8_2020}
            >
                <> {/* {Putting empty diamonds in order to hide submit button} */}
                </>
            </Form>
        </>
    );
}