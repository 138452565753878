import { DefaultEffects, IStyleFunction } from "@fluentui/react"
import { ITableOfContentPropsStyles, ITableOfContentStyles } from "./tableOfContent.types"

export const TableOfContentGlobalClassNames = {
  root: 'edi-table-of-content-root'
}

export const getStyles: IStyleFunction<ITableOfContentPropsStyles, ITableOfContentStyles> = (props: ITableOfContentPropsStyles): ITableOfContentStyles => {
  const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
  return {
    root: [
      TableOfContentGlobalClassNames.root,
      {
        overflow: 'auto',
        height: '100%'
        // CSS PROPS AND SELECTORS
      }
    ],
    scrollableContainer:{
      width:'100%'
    },
    AddSection:{
      icon: { 
        color: palette.white 
      },
      root: {  
        background: palette.themePrimary 
      }
    },
    subComponentStyles: {
      textFieldStyle: {
        root: {
          paddingBottom: 20,
          paddingRight: '80px',
          flex: '1 1 0'
        },
        wrapper: {
          width: '250px',
          borderBottom: '2px solid',
          borderBottomColor: palette.themePrimary
        },
        field: {
          fontSize: 14,
          color: palette.black,
          boxShadow: DefaultEffects.elevation8,
          '::placeholder': {
            fontSize: 14
          },
        },
        fieldGroup: {
          height: 30,
          background: palette.white + ' !important'
        },
        errorMessage: {
          position: 'absolute'
        }
      }
    },
    requiredTextLeft: [{
      display:'flex', 
      alignItems:'center',
      gap: 5,
      justifyContent: "flex-start",
      bottom: 0,
      left:40,
      color: palette.redDark,
      background: palette.white,
      width: '30%'
  }]
  };
}