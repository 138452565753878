import { IRawStyle } from "@fluentui/react"
import { carouselItem } from "../common/components/carousel/carousel.base";
import { Constants } from "../docLab/models/constants";

export interface IHistory {
    push: (url: string) => void
}

export interface ILocation {
    pathname: string;
    search: string;
}

export enum FormItemType {
    Text = 0,
    MultiText = 1,
    Dropdown = 2,
    DatePicker = 3,
    Numeric = 4,
    TextCurrency = 5
}

export enum EdiUserRoleId {
    User = 0,
    DocLabAdmin = 1,
    ActivityLabAdmin = 2,
    PlatformAdmin = 3,
    KnowledgeDS = 4
}

export enum AddMembersPageType {
    Archive = 0,
    Portfolio = 1,
    Folder = 2,
    KnowledgeLab = 3,
    Dashboard = 4
}

export interface IGroup {
    count: number;
    key: string;
    name: string;
    startIndex: number;
    level?: number;
    isCollapsed?: boolean;
    children?: IGroup[];
}

export const multiLineEllipsis = (num: number): IRawStyle => {
    return {
        display: '-webkit-box',
        '-webkit-line-clamp': `${num}`,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden'
    }
}

export const setPageCarousel = (allItems: carouselItem[]): carouselItem[][] => {
    const result: carouselItem[][] = [];
    let pageContent: carouselItem[] = [];
    allItems.forEach((item, ind) => {
        if (ind % Constants.CAROUSEL_ITEMS !== 0 || ind === 0) {
            pageContent.push(item);
            if (ind === allItems.length - 1) {
                result.push(pageContent);
            }
        }
        else {
            result.push(pageContent);
            pageContent = [];
        }
    });
    return result;
}

export const FileFormatExtension = [
    { extension: ".doc", key: "application/msword" },
    { extension: ".docx", key: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
    { extension: ".dotx", key: "application/vnd.openxmlformats-officedocument.wordprocessingml.template" },
    { extension: ".docm", key: "application/vnd.ms-word.document.macroEnabled.12" },
    { extension: ".dotm", key: "application/vnd.ms-word.template.macroEnabled.12" },
    { extension: ".xlsx", key: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    { extension: ".xltx", key: "application/vnd.openxmlformats-officedocument.spreadsheetml.template" },
    { extension: ".xlsm", key: "application/vnd.ms-excel.sheet.macroEnabled.12" },
    { extension: ".xltm", key: "application/vnd.ms-excel.template.macroEnabled.12" },
    { extension: ".xlsb", key: "application/vnd.ms-excel.sheet.binary.macroEnabled.12" },
    { extension: ".pptx", key: "application/vnd.openxmlformats-officedocument.presentationml.presentation" },
    { extension: ".ppsx", key: "application/vnd.openxmlformats-officedocument.presentationml.slideshow" },
    { extension: ".potx", key: "application/vnd.openxmlformats-officedocument.presentationml.template" },
    { extension: ".pptm", key: "application/vnd.ms-powerpoint.presentation.macroEnabled.12" },
    { extension: ".ppsm", key: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12" },
    { extension: ".potm", key: "application/vnd.ms-powerpoint.template.macroEnabled.12" },
    { extension: ".ppt", key: "application/vnd.ms-powerpoint" },
    { extension: ".pdf", key: "application/pdf" },
    { extension: ".msg", key: "application/octet-stream" },
    { extension: ".csv", key: "text/csv" },
    { extension: ".xls", key: "application/vnd.ms-excel" },
    { extension: ".jpg", key: "image/jpeg" },
    { extension: ".jpeg", key: "image/jpeg" },
    { extension: ".dwg", key: "application/octet-stream" },
    { extension: ".p7m", key: "application/pkcs7-mime" },
    { extension: ".zip", key: "application/x-zip-compressed" },
    { extension: ".rar", key: "application/x-rar-compressed" },
    { extension: ".tif", key: "image/tiff" },
    { extension: ".dwf", key: "drawing/x-dwf" },
    { extension: ".eml", key: "message/rfc822" }
];

export enum FileType {
    Doc = 0,
    Docx = 1,
    Dotx = 2,
    Docm = 3,
    Dotm = 4,
    Xlsx = 5,
    Xltx = 6,
    Xlsm = 7,
    Xltm = 8,
    Xlsb = 9,
    Pptx = 10,
    Ppsx = 11,
    Potx = 12,
    Pptm = 13,
    Ppsm = 14,
    Potm = 15,
    Ppt = 16,
    Pdf = 17,
    Msg = 18,
    Csv = 19,
    Xls = 20,
    Jpg = 21,
    Jpeg = 22,
    Dwg = 23,
    P7m = 24,
    Zip = 25,
    Rar = 26,
    Tif = 27,
    Dwf = 28,
    Eml = 29
}

export const filePreviewSubPath = {
    element: "element",
    knowledge: "knowledge",
    feedback: "feedback"
}

export enum KnowledgeDsTextType {
    element = 0,
    document = 1
}

export enum DashboardTextType {
    element = 0,
    document = 1
}