import { styled } from "@fluentui/react";
import { getStyles } from "./graphActivityForLeader.styles";
import { IGraphActivityForLeaderPropsStyles, IGraphActivityForLeaderStyles, IGraphActivityForLeaderProps } from "./graphActivityForLeader.types";
import { GraphActivityForLeaderBase } from "./graphActivityForLeader.base";

const GraphActivityForLeader = styled<IGraphActivityForLeaderProps, IGraphActivityForLeaderPropsStyles, IGraphActivityForLeaderStyles>(
    GraphActivityForLeaderBase,
    getStyles,
    undefined,
    { scope: 'GraphActivityForLeader' }
);

export default GraphActivityForLeader;