import { IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";
import { NavigableItem } from "../../models/navigableFile";

export interface IKnowledgeSharepointFileSelectorProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<IKnowledgeSharepointFileSelectorPropsStyles, IKnowledgeSharepointFileSelectorStyles>;
    className?: string;
    onChange?: (files: SharepointNavItem[]) => void;
    defaultSelected: NavigableItem[];
    allowedFileTypes: string[];
    // ADD COMPONENT PROPS HERE
}

export interface IKnowledgeSharepointFileSelectorPropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<IKnowledgeSharepointFileSelectorPropsStyles, IKnowledgeSharepointFileSelectorStyles>;
    className?: string;
    // ADD STYLE PROPS HERE FOR CONDITIONAL CSS GENERATION
}

export interface IKnowledgeSharepointFileSelectorStyles {
    root: IStyle; // EXAMPLE CUSTOMIZABLE PROP
    img: IStyle;
}

export interface SharepointNavItem extends NavigableItem {
    type: SharepointItemType
    size?: string
    rootId?: number
}

export enum SharepointItemType {
    folder,
    drive,
    sharepointSite,
    file,
    documentLibrary
}

export type GetDriveContentRequest = {
    id: string;
    driveId: string;
    orderBy: string;
    isAscending: boolean;
    pageNumber: number;
    pageSize: number;
    name?: string;
}