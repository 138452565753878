export const FormalAspects =
    [{ key: 'TableCount', value: 0 },
    { key: 'ParagraphCount', value: 1 },
    { key: 'PageCount', value: 2 },
    { key: 'WordCount', value: 3 },
    { key: 'ImageCount', value: 4 }
    ]

export const FormalAspectsSettingsKeys =
    [{ key: 'WordCount', value: 0 },
    { key: 'TableCount', value: 1 },
    { key: 'PageCount', value: 2 },
    { key: 'ParagraphCount', value: 3 },
    { key: 'ImageCount', value: 4 }
    ]

export const SearchFiltersItems = {
    Languages: "Languages",
    FormatExt: "FormatExt",
    DateCreated: "DateCreated",
    FormalAspects: "FormalAspects",
    Locations: "Locations",
    Organizations: "Organizations",
    Keyphrases: "Keyphrases"
}

export const SearchFilters = {
    items: [
        SearchFiltersItems.Languages,
        SearchFiltersItems.DateCreated,
        SearchFiltersItems.Keyphrases,
        SearchFiltersItems.Locations,
        SearchFiltersItems.Organizations,
        SearchFiltersItems.FormatExt,
        SearchFiltersItems.FormalAspects,
    ]
}

export const Constants = {
    MAX_LENGTH_ARCHIVE_ITEM_NAME: 255,
    MAX_LENGTH_ARCHIVE_NAME: 50,
    MAX_LENGTH_ARCHIVE_DESCRIPTION: 255,
    MAX_LENGHT_25: 25,
    PAGE_SIZE: 50,
    CAROUSEL_ITEMS: 4,
    LIST_ANALYSIS: 20,
    standardItemNameMaxLength: 200,
    tocNameMaxLenght: 50,
    MAX_FACETS_SHOWED: 5,
    //Parametro di livello di profondita' massimo in creazione di ToC.
    //In base al parametro inserito non si potranno creare figli oltre a quel livello.
    TOC_MAX_DEPTH: 4,

    PAGE_SIZE_START_ANALYSIS: 100,
    MAX_INTEGER: 2147483647
}