import { classNamesFunction, Stack } from "@fluentui/react";
import React, { useState } from "react";
import { Route } from "react-router-dom";
import Page from "../../../../common/components/page/Page";
import { ActivityLabRoutes, ActivityTab, ActivityTabSettings } from "../../../utilities/routes";
import ActivitiesBoards from "../../activities/boards/activitiesBoards/activitiesBoards";
import ActivitiesList from "../../activities/list/activitiesList/activitiesList";
import DashboardContainer from "../../dashboard/dashboardContainer/dashboardContainer";
import WorkFlowDetailsPage from "../../activities/workflow/workFlowDetailsPage/workFlowDetailsPage";
import WorkFlowList from "../../activities/workflow/workFlowList/workFlowList";
import PortfoliosList from "../../portfolios/portfoliosList/portfoliosList";
import ActivityLabSettings from "../../settings/activityLabSettings/activityLabSettings";
import PortfolioMembersSettings from "../../settings/portfolioMembersSettings/portfolioMembersSettings";
import Toolbar from "../toolbar/toolbar";
import { IRootProps, IRootPropsStyles, IRootStyles } from "./root.types";
import { showRenamePortfolioModal, usePortfolioList } from "../../../features/portfolioList";
import RenamePortfolioModal from "../../portfolios/renamePortfolioModal/renamePortfolioModal";
import { useActivityLabDispatch } from "../../../activityLabStore";


const getClassNames = classNamesFunction<IRootPropsStyles, IRootStyles>();

const collapseStyle = {
    flexBasis: 0,
    opacity: 0,
}

export const RootBase = (props: IRootProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const { renamePortfolioModalShown } = usePortfolioList();
    const dispatch = useActivityLabDispatch();

    const isWorkflowDeepLink = (): boolean => {
        if (props.ctx?.subEntityId) {
            return atob(props.ctx?.subEntityId).includes(ActivityTab.workflow);
        }
        return false;
    }

    return (
        <>
            <Stack className={classNames.root} horizontal verticalAlign={"stretch"}>
                <Stack.Item className={classNames.leftNav} disableShrink style={sidebarCollapsed ? collapseStyle : {}}>
                    <PortfoliosList isWorkflowDeepLink={isWorkflowDeepLink()} />
                </Stack.Item>
                <Stack.Item className={classNames.rightContent} grow>
                    <Stack style={{ height: '100%' }} horizontal={false} verticalAlign={"stretch"}>
                        <Stack.Item disableShrink>
                            <Toolbar onMenuButtonClick={() => setSidebarCollapsed(!sidebarCollapsed)} />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Route path={`${ActivityLabRoutes.portfolio}/${ActivityTab.board}`}>
                                <Page title="Activity Lab - Activities Boards" content={ActivitiesBoards}></Page>
                            </Route>
                            <Route path={`${ActivityLabRoutes.portfolio}/${ActivityTab.list}`}>
                                <Page title="Activity Lab - Activities List" content={ActivitiesList}></Page>
                            </Route>
                            <Route path={`${ActivityLabRoutes.portfolio}/${ActivityTab.graphs}`}>
                                <Page title="Activity Lab - Graphs" content={DashboardContainer}></Page>
                            </Route>
                            <Route exact path={`${ActivityLabRoutes.portfolio}/${ActivityTab.workflow}`}>
                                <Page title="Activity Lab - Activities Workflow" content={WorkFlowList}></Page>
                            </Route>
                            <Route path={`${ActivityLabRoutes.portfolio}/${ActivityTab.workflow}/:workFlowId`}>
                                <Page title="Activity Lab - Activities Workflow - Details" content={WorkFlowDetailsPage} />
                            </Route>
                            <Route path={`${ActivityLabRoutes.portfolio}/settings/${ActivityTabSettings.settings}`}>
                                <Page title="Activity Lab- Settings" content={ActivityLabSettings} />
                            </Route>
                            <Route path={`${ActivityLabRoutes.portfolio}/settings/${ActivityTabSettings.users}`}>
                                <Page title="Activity Lab- Settings - Members" content={PortfolioMembersSettings} />
                            </Route>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>

            {renamePortfolioModalShown && <RenamePortfolioModal onClose={() => { dispatch(showRenamePortfolioModal(false)) }} />}

        </>
    );
}