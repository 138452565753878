import { styled } from "@fluentui/react";
import { ExportFolderZipModalBase } from "./exportFolderZipModal.base";
import { getStyles } from "./exportFolderZipModal.styles";
import { IExportFolderZipModalProps, IExportFolderZipModalPropsStyles, IExportFolderZipModalStyles } from "./exportFolderZipModal.types";

const ExportFolderZipModal = styled<IExportFolderZipModalProps, IExportFolderZipModalPropsStyles, IExportFolderZipModalStyles>(
    ExportFolderZipModalBase,
    getStyles,
    undefined,
    { scope: 'ExportFolderZipModal' }
);

export default ExportFolderZipModal;