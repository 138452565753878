
import { classNamesFunction, CommandBar, DefaultButton, ICommandBarItemProps, IconButton, keyframes, Pivot, PivotItem } from "@fluentui/react";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import Information from "../../../../common/components/information/information";
import { useDocLabDispatch } from "../../../docLabStore";
import { loadFolderContent, selectItems, setIsDeeplink, useArchiveContent } from "../../../features/archiveContent";
import { ToolbarAction, useToolbarActions } from "../../../features/toolbarActions";
import { useActionDelegate } from "../../../hooks/useActionDelegate";
import { useNavigator } from "../../../hooks/useNavigator";
import { ArchiveTab, DocLabRoutes } from "../../../utilities/routes";
import { IToolbarProps, IToolbarPropsStyles, IToolbarStyles } from "./toolbar.types";

const getClassNames = classNamesFunction<IToolbarPropsStyles, IToolbarStyles>();

const mapActions = (actions: ToolbarAction[], delegate: (action: ToolbarAction) => void): ICommandBarItemProps[] => {
    return actions.map(action => {
        let subActions = undefined;

        if (action.subActions && action.subActions.length > 0) {
            subActions = { items: mapActions(action.subActions, delegate) };
        }

        return {
            key: action.id.toString(),
            text: action.label,
            iconOnly: !action.label,
            iconProps: { iconName: action.icon },
            subMenuProps: subActions,
            onClick: (ev) => { delegate(action) },
            disabled: false
        }
    });
}

export const ToolbarBase = (props: IToolbarProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const navigator = useNavigator();
    const toolbarActions = useToolbarActions();
    const dispatch = useDocLabDispatch();
    const delegate = useActionDelegate();
    const { t } = useTranslation('toolbar');
    const { isLoading, selectedItems } = useArchiveContent();

    const rightTabs = useCallback(() => {
        return (
            <>
                <Pivot
                    headersOnly={true}
                    styles={classNames.subComponentStyles.pivot}
                    selectedKey={navigator.currentArchiveTab ?? null}
                    onLinkClick={item => item?.props.itemKey && navigator.currentArchiveTab !== item.props.itemKey &&
                        dispatch(selectItems([])) && 
                        dispatch(setIsDeeplink(false)) && 
                        navigator.changeArchiveTab(item.props.itemKey as ArchiveTab)
                    }
                >
                    <PivotItem key={ArchiveTab.dashboard} itemKey={ArchiveTab.dashboard} headerText={t('doclab.dashboard')} />
                    <PivotItem key={ArchiveTab.files} itemKey={ArchiveTab.files} headerText={t('doclab.files')} />
                    <PivotItem key={ArchiveTab.search} itemKey={ArchiveTab.search} headerText={t('doclab.search')} />
                    <PivotItem key={ArchiveTab.docAnalysis} itemKey={ArchiveTab.docAnalysis} headerText={t('doclab.docAnalysis')} />
                </Pivot >
            </>
        );
    }, [classNames.subComponentStyles.pivot, navigator, dispatch, t]);

    const menuAction: ICommandBarItemProps = {
        key: "menu",
        iconProps: { iconName: "CollapseMenu" },
        onClick: props.onMenuButtonClick
    }

    const infoTab = () => (
        <>
            <Route path={`${DocLabRoutes.archive}/${ArchiveTab.files}`}>
                {selectedItems && selectedItems.length > 0 && 
                    <DefaultButton
                        styles={classNames.subComponentStyles.unselectButton()}
                        disabled={isLoading}
                        iconProps={{ iconName: "Cancel" }}
                        onClick={() => dispatch(selectItems([]))}
                        text={selectedItems.length + " " + t("doclab.selected")}
                    />
                }                
                <IconButton
                    styles={classNames.subComponentStyles.reloadIconButton()}
                    style={isLoading ? { animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite` } : {}}
                    disabled={isLoading}
                    iconProps={{ iconName: "Refresh" }}
                    onClick={() => dispatch(loadFolderContent({ resetPage: true }))}
                />
            </Route>
            <Information />
        </>

    )
    
    const actions = useMemo(() => mapActions(toolbarActions.actions, delegate),
        [toolbarActions.actions, delegate]);

    return (
        <div className={classNames.root}>
            <CommandBar
                className={classNames.wrapper}
                items={[menuAction, ...actions]}
                farItems={[{ key: "info", commandBarButtonAs: infoTab }, { key: "right-tabs", commandBarButtonAs: rightTabs }]}
            />
        </div>
    );
}