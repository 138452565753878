import React, { useEffect, useState } from "react";
import { classNamesFunction, IconButton, IIconProps, Modal, ResponsiveMode, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { ICompareDocumentsModalPropsStyles, ICompareDocumentsModalStyles, ICompareDocumentsModalProps } from "./compareDocumentsModal.types";
import { KnowledgeElement } from "../../../models/knowledgeElement";
import { knowledgeLabApi } from "../../../services/knowledgeLab.api";
import { useTranslation } from "react-i18next";
import { useKnowledgeLabSelector } from "../../../knowledgeLabStore";
import { KnowledgeHelpers } from "../../../utilities/helpers";
import CopyActionCommand from "../copyActionButton/copyActionCommand";
import { useCurrentKnowledge } from "../../../hooks/useCurrentKnowledge";

const getClassNames = classNamesFunction<ICompareDocumentsModalPropsStyles, ICompareDocumentsModalStyles>();

const initialIconProps: IIconProps = {
    iconName: 'Cancel'
}

export const CompareDocumentsModalBase = (props: ICompareDocumentsModalProps) => {
    const currentKnowledge = useCurrentKnowledge();

    const getTitleFromJsonSchema = (s: string) => {
        return currentKnowledge?.jsonSchemaFields.find(f => f.name === s)?.title;
    };
    const getAdditionalData = (d: KnowledgeElement) => {
        if (currentKnowledge?.searchProperties) {
            return currentKnowledge?.searchProperties.map((el) => {
                const value = d?.metadata ? (d.metadata[el] ?? "-") : "-";
               // return <span style={{ marginRight: "5", width: '250px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} key={el}><b>{getTitleFromJsonSchema(el)}</b>: <TooltipHost content={value}>{value}</TooltipHost></span>
                return <Stack.Item key={el}>
                      <strong>{getTitleFromJsonSchema(el)}: </strong >
                      <span>{value}</span>
                     </Stack.Item>
            });
        } else
            return <></>
    }
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, width: '70%', height: "90%" });

    const [comparedDocuments, setComparedDocuments] = useState<KnowledgeElement[]>([]);
    const [loading, setLoading] = useState(true);

    const { selectedKnowledgeLab } = useKnowledgeLabSelector(state => state.knowledgeLabList);
    const { t, i18n } = useTranslation('common');

    const currentLang = (i18n.language).split("-")[0];
    const currentTranslations = selectedKnowledgeLab?.configurations?.translations;
    const elementPlaceHolder = KnowledgeHelpers.getKnowledgeTranslation("elementPlaceHolder", currentTranslations, currentLang);
    const modalTitle = `${t('compare')} ${elementPlaceHolder}`;
    const loadData = async (id: string, increaseViews: boolean) => {
        const document = await knowledgeLabApi.getElementDetails(id, increaseViews);
        setComparedDocuments(comparedDocuments => [...comparedDocuments, document]);
    }

    useEffect(() => {
        if (comparedDocuments.length === props.documentsIds.length)
            setLoading(false);
    }, [comparedDocuments.length, props.documentsIds.length])

    useEffect(() => {

        setComparedDocuments([]);

        if (props.isOpen) {
            setLoading(true);

            props.documentsIds.forEach(id => {
                loadData(id, false);
            });
        }

    }, [props.documentsIds, props.isOpen]) //eslint-disable-line react-hooks/exhaustive-deps

    const modalBody =
        <>
            <Stack style={{ padding: '20px' }}>
                {loading ? <div className={classNames.loadingErrorContainer}>
                    <Spinner size={SpinnerSize.large} />
                </div> :
                    <>

                        <Stack horizontal style={{ maxHeight: '100%' }}>

                            {comparedDocuments.map(document =>
                                <Stack key={document.id} style={{ margin: '0 10px', width: '50%' }}>
                                    <Stack.Item style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                        <span style={{ color: '#868EBE' }}><strong>{document?.metadata["elementTitle"]}</strong></span>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Stack horizontal horizontalAlign="space-between">
                                         {getAdditionalData(document)}
                                        </Stack>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <div className={classNames.copyButtonDiv}>
                                            <CopyActionCommand
                                                content={document?.metadata["content"]}
                                                textPlaceHolder={elementPlaceHolder}
                                            />
                                        </div>
                                    </Stack.Item>
                                    <Stack.Item style={{ overflowY: 'scroll' }}>
                                        <p>{document?.metadata["content"]}</p>
                                    </Stack.Item>
                                </Stack>
                            )}

                        </Stack>
                    </>
                }

            </Stack>
        </>

    return (
        <Modal
            isOpen={props.isOpen}
            className={props.className}
            containerClassName={classNames.container}
            responsiveMode={ResponsiveMode.large}
            scrollableContentClassName={classNames.content}
            onDismissed={props.onCloseClick}
            isBlocking={true}
        >
            <div className={classNames.upperContent}>
                <div>
                    <div className={classNames.header}>
                        <div className={classNames.title}>{modalTitle}</div>
                        <div className={classNames.closeIcon}>
                            <IconButton
                                styles={classNames.subComponentStyles.iconButton()}
                                iconProps={{ ...initialIconProps }}
                                onClick={props.onCloseClick}
                            />
                        </div>
                    </div>
                </div>
                <div className={classNames.body}>
                    <div className={classNames.bodyContent}>
                        {modalBody}
                    </div>
                </div>
            </div>
            <div className={classNames.footer}></div>
        </Modal>
    );
}