import * as msal from '@azure/msal-browser';
import { RedirectRequest } from '@azure/msal-browser';
import * as microsoftTeams from "@microsoft/teams-js";
import * as Config from './config';

class TeamsAuthService {
    private request: RedirectRequest;
    private msalClient: msal.PublicClientApplication;
    private teamsContext?: microsoftTeams.Context;
    private account?: msal.AccountInfo;

    public get currentUserName() {
        return this.account?.name;
    }

    public get currentUserId() {
        return this.teamsContext?.userObjectId;
    }

    public get currentUserPrincipalName() {
        return this.teamsContext?.userPrincipalName;
    }

    constructor() {
        const config = Config.environment[window.location.host];

        const msalConfig: msal.Configuration = {
            auth: {
                clientId: config.clientId,
                authority: Config.authority,
                redirectUri: config.redirectUri
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false,
            }
        };

        this.request = {
            scopes: config.scopes
        }

        this.msalClient = new msal.PublicClientApplication(msalConfig);
    }

    async init(teamsContext: microsoftTeams.Context) {
        this.teamsContext = teamsContext;

        const response = await this.msalClient.handleRedirectPromise();

        if (response?.account && this.isValidAccount(response.account)) {
            return true;
        }

        return this.isLoggedIn();
    }

    isValidAccount(account: msal.AccountInfo) {
        const result = account.tenantId === this.teamsContext?.tid &&
        account.localAccountId === this.teamsContext?.userObjectId

        if (result) {
            this.account = account;
        }
        return result;
    }

    isLoggedIn() {
        if (!this.teamsContext?.userObjectId) {
            return false;
        }

        const account = this.msalClient.getAccountByLocalId(this.teamsContext.userObjectId);
 
        return account !== null && this.isValidAccount(account);
    }

    login() {
        try { this.msalClient.loginRedirect(this.request); }
        catch (err) { console.log(err); }
    }

    async getAccessToken(bypassRedirect?: boolean) {
        this.request.account = this.msalClient.getAccountByLocalId(this.teamsContext?.userObjectId ?? "") ?? undefined;

        try {
            const resp = await this.msalClient.acquireTokenSilent(this.request);

            if (resp?.accessToken)
                return resp.accessToken;

            await this.msalClient.acquireTokenRedirect(this.request);
        }
        catch (error) {
            if (bypassRedirect)
                return null;

            if (error instanceof msal.InteractionRequiredAuthError) {
                console.warn("Silent token acquisition failed; acquiring token using redirect");
                this.msalClient.acquireTokenRedirect(this.request);
            }
            else {
                throw (error);
            }
        }
    }

    getAccessTokenWithTeamsPopup() {
        return new Promise<string>((resolve, reject) => {
            microsoftTeams.authentication.authenticate({
                url: window.location.origin + "/login",
                width: 600,
                height: 535,
                successCallback: (response?: string) => {
                    if (!response) {
                        reject("Unexpected error - missing response");
                        return;
                    }

                    resolve(response);
                },
                failureCallback: (reason?: string) => {
                    reject(reason);
                }
            });
        });
    }

    async tryGetAccessToken() {
        let token = await this.getAccessToken(true);
        if (token)
            return [true, token];

        try {
            token = await this.getAccessTokenWithTeamsPopup();
            if (token) {
                return [true, token];
            }
        }
        catch (error) {
            return [false, error];
        }

        return [false, ""];
    }
}

export default new TeamsAuthService();