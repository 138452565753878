import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useKnowledgeLabSelector } from '../knowledgeLabStore';
import { searchApi } from '../services/search/search.api';
import { FacetType, FieldType, FlatFacet, MergedFacetItem, MergedFacets, SearchIndexField, SearchResult } from '../services/search/search.contracts';

export type SearchParams = {
  keyword?: string;
  orderBy?: string;
  isAsc?: boolean;
  pageNumber?: number;
  selectedFacets?: FlatFacet[];
  knowledgelabId?: number;
  multiLanguageSearch?: boolean;
}

export type SearchContentState = {
  searchResult: SearchResult | undefined,
  searchParams: SearchParams;
  groupedFacets: MergedFacets[];
  indexFields: SearchIndexField[];
}

export type UpdateGroupedFacets = {
  fieldMergedFacet: number;
  newValue: MergedFacetItem[];
}

const initialState: SearchContentState = {
  searchResult: undefined,
  searchParams: {
    keyword: undefined,
    orderBy: undefined,
    isAsc: true,
    pageNumber: 1,
    selectedFacets: [],
    knowledgelabId: 0,
    multiLanguageSearch: false,
  } as SearchParams,
  groupedFacets: [] as MergedFacets[],
  indexFields: []
}

const addFacetRange = (state: SearchContentState, action: PayloadAction<{ mergedFacedItem: MergedFacetItem, AdditionalType?: FieldType }>) => {
  state.searchParams.selectedFacets =
    [
      ...state.searchParams.selectedFacets as FlatFacet[],
      {
        key: action.payload.mergedFacedItem.field,
        from: action.payload.mergedFacedItem.from,
        to: action.payload.mergedFacedItem.to,
        type: action.payload.mergedFacedItem.type,
        text: action.payload.mergedFacedItem.value
      }
    ]
}

const removeFacet = (state: SearchContentState, facet: FlatFacet) => {
  if (facet.type === FacetType.Value && state.searchParams.selectedFacets) {
    state.searchParams.selectedFacets.filter(x => x.key === facet.key)[0].values = facet.values?.filter(x => x !== facet.text);
    if (state.searchParams.selectedFacets.filter(x => x.key === facet.key)[0].values?.length === 0 || facet.value)
      state.searchParams.selectedFacets = state.searchParams.selectedFacets?.filter(x => x.key !== facet.key);
  }
  else {
    state.searchParams.selectedFacets = state.searchParams.selectedFacets?.filter(x => x.key !== facet.key);
  }
}

const manageFacetDataRanges = (facet: FlatFacet | undefined, state: SearchContentState, action: PayloadAction<{ mergedFacedItem: MergedFacetItem, AdditionalType?: FieldType }>) => {
  if (facet) {
    if (facet.text !== action.payload.mergedFacedItem.value) {
      removeFacet(state, facet);
      addFacetRange(state, action);
    }
  }
  else {
    addFacetRange(state, action);
  }
}

const manageFacetValues = (facet: FlatFacet | undefined, state: SearchContentState, action: PayloadAction<{ mergedFacedItem: MergedFacetItem, AdditionalType?: FieldType }>) => {
  if (action.payload.mergedFacedItem.value)
    if (facet) {
      facet.values?.push(action.payload.mergedFacedItem.value);
    }
    else {
      if (action.payload.AdditionalType && action.payload.AdditionalType === FieldType['Edm.String']) {
        state.searchParams.selectedFacets =
          [
            ...state.searchParams.selectedFacets as FlatFacet[],
            {
              key: action.payload.mergedFacedItem.field,
              type: FacetType.Value,
              value: action.payload.mergedFacedItem.value
            }
          ]
      }
      else {
        state.searchParams.selectedFacets =
          [
            ...state.searchParams.selectedFacets as FlatFacet[],
            {
              key: action.payload.mergedFacedItem.field,
              values: [action.payload.mergedFacedItem.value],
              type: FacetType.Value
            }
          ]
      }
    }
}

export const getIndexField = createAsyncThunk('search/getIndexField', async () => {
  const indexFields: SearchIndexField[] = [];
  const response = await searchApi.getIndexField();
  response.fields.forEach(field => { if (field.name.toLowerCase().startsWith("edi_field")) indexFields.push(field) });
  return indexFields;
});

const searchContentSlice = createSlice({
  name: 'search-content',
  initialState,
  reducers: {
    resetAllSearchResults: (state: SearchContentState, action: PayloadAction<number | undefined>) => {
      state.searchParams = { ...initialState.searchParams, knowledgelabId: action.payload };
      state.searchResult = initialState.searchResult;
      state.groupedFacets = initialState.groupedFacets;
    },
    resetAllParams: (state: SearchContentState, action: PayloadAction) => {
      state.searchParams = initialState.searchParams;
    },
    setCurrentResults: (state: SearchContentState, action: PayloadAction<SearchResult | undefined>) => {
      state.searchResult = action.payload;
    },
    setSearchParams: (state: SearchContentState, action: PayloadAction<SearchParams>) => {
      state.searchParams = { ...state.searchParams, ...action.payload };
    },
    setGroupedFacets: (state: SearchContentState, action: PayloadAction<MergedFacets[]>) => {
      state.groupedFacets = action.payload;
    },
    updateGroupedFacets: (state: SearchContentState, action: PayloadAction<UpdateGroupedFacets>) => {
      state.groupedFacets[action.payload.fieldMergedFacet].values = action.payload.newValue;
    },
    addSelectedFacet: (state: SearchContentState, action: PayloadAction<{ mergedFacedItem: MergedFacetItem, AdditionalType?: FieldType }>) => {
      const facet = state.searchParams.selectedFacets?.filter(x => x.key === action.payload.mergedFacedItem.field)[0];
      if (action.payload.mergedFacedItem.type === FacetType.Value
        && !state.searchParams.selectedFacets?.some(x => x.key === action.payload.mergedFacedItem.field
          && x.values?.some(e => e === action.payload.mergedFacedItem.value))) {
        manageFacetValues(facet, state, action);
        state.searchParams.pageNumber = 1;
      }
      if (action.payload.mergedFacedItem.type === FacetType.DateRange || action.payload.mergedFacedItem.type === FacetType.Range) {
        manageFacetDataRanges(facet, state, action);
        state.searchParams.pageNumber = 1;
      }
    },
    removeSelectedFacet: (state: SearchContentState, action: PayloadAction<FlatFacet>) => {
      removeFacet(state, action.payload);
      state.searchParams.pageNumber = 1;
    },
    removeFacetsByFamily: (state: SearchContentState, action: PayloadAction<string[]>) => {
      if (state.searchParams.selectedFacets) {
        state.searchParams.selectedFacets = state.searchParams.selectedFacets.filter(x => !action.payload.some(y => y === x.key));
        state.searchParams.pageNumber = 0;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getIndexField.pending, (state, action) => {
      state.indexFields = [];
    });

    builder.addCase(getIndexField.fulfilled, (state, action) => {
      state.indexFields = action.payload;
    });
  }
})

export const { setCurrentResults, setSearchParams, setGroupedFacets, resetAllSearchResults, addSelectedFacet, removeSelectedFacet, resetAllParams, removeFacetsByFamily, updateGroupedFacets } = searchContentSlice.actions;
export default searchContentSlice.reducer;

export const useSearchContent = () => useKnowledgeLabSelector(state => state.searchContent);