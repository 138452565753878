import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { classNamesFunction, DefaultButton, DetailsList, DetailsListLayoutMode, DetailsRow, Dialog, DialogFooter, IconButton, IDetailsRowProps, PrimaryButton, SelectionMode, Toggle } from "@fluentui/react";
import { nanoid } from "@reduxjs/toolkit";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import TeamsImage from "../../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../../common/components/teamsImage/teamsImage.types";
import { Helpers } from "../../../../../../utilities/helpers";
import { useAsyncApi, useOnMount } from "../../../../../../utilities/hooks";
import { useDocLabDispatch } from "../../../../../docLabStore";
import { insertCall, setCall } from "../../../../../features/callNotification";
import { useNavigator } from "../../../../../hooks/useNavigator";
import { Call, callsList } from "../../../../../models/callsApi";
import { AnalysisConfig, AnalysisConfigTyped } from "../../../../../models/docAnalysis";
import { docAnalyzesApi } from "../../../../../services/docAnalyzes/docAnalyzes.api";
import { QualityCheckSetting } from "../../../../../services/docAnalyzes/docAnalyzes.contracts";
import CreateStandardModal from "../createStandardModal/createStandardModal";
import { IQualityCheckSettingsProps, IQualityCheckSettingsPropsStyles, IQualityCheckSettingsStyles } from "./qualityCheckSettings.types";

const getClassNames = classNamesFunction<IQualityCheckSettingsPropsStyles, IQualityCheckSettingsStyles>();

export const QualityCheckSettingsBase = (props: IQualityCheckSettingsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['docAnalysisSettings', 'common', 'docAnalysis']);
    const { currentArchiveId } = useNavigator();
    const [actionInProgress, setActionInProgress] = useState(false);
    const [createStandardModalOpened, setCreateStandardModalOpened] = useState(false);
    const [deleteStandardModalOpened, setDeleteStandardModalOpened] = useState(0);
    const dispatch = useDocLabDispatch();
    
    const { execute, error, loading, value: configs } = useAsyncApi<void, AnalysisConfigTyped<QualityCheckSetting>[]>({
        keepResultsWhileReloading: true,
        func: async () => {
            if (!currentArchiveId) return [];
            return await docAnalyzesApi.getQualityCheckConfigs({ archiveId: currentArchiveId });
        }
    });

    useOnMount(() => void execute());

    const updateStandardStatus = async (configId: number) => {
        setActionInProgress(true);
        try {
            await docAnalyzesApi.updateStandardStatus(configId);
            await execute();
        }
        catch { /*Error*/ }
        finally {
            setActionInProgress(false);
        }
    }
    /*eslint-disable sonarjs/no-duplicate-string */
    const deleteStandard = async (configId: number) => {
        setActionInProgress(true);
        const deleteStandardCall: Call = {
            type: callsList.deleteStandard,
            nameOperation: t('docAnalysis:operations.deleteOperation.name'),
            errors: [
                { code: 403, message: t("docAnalysis:operations.deleteOperation.errors.errorCode403") },
                { code: 500, message: t('common:genericErrorApi') }
            ]
        };
        setDeleteStandardModalOpened(0);
        const id = nanoid();
        const payload = { requestId: id, notification: deleteStandardCall }
        dispatch(insertCall(payload));
        try {
            await docAnalyzesApi.deleteStandard(configId);
            const successPayload = { requestId: id, success: true, message: t('docAnalysis:operations.deleteOperation.successMessage') }
            dispatch(setCall(successPayload));
            await execute();
        }
        catch (error) {
            let errorMessage = '';
            if (error.code === 403) {
                errorMessage = t("docAnalysis:operations.deleteOperation.errors.errorCode403")
            }
            else {
                errorMessage = t('common:genericErrorApi')
            }
            const failurePayload = { requestId: id, success: false, message: errorMessage }
            dispatch(setCall(failurePayload));
        }
        finally{
            setActionInProgress(false);
        }
    }

    const columns = [{
        key: 'name',
        name: t('qualityCheck.name'),
        minWidth: 200,
        maxWidth: 250,
        headerClassName: classNames.headerRow,
        onRender: function renderStandardDetails(config: AnalysisConfigTyped<QualityCheckSetting>) {
            return (
                <>
                    {config.data.length.map((val) => (
                        <div key={val.labelId} className={classNames.grid}>
                            <div>{t(`docAnalysis:labels.types.${val.labelId}`)}</div>
                            <div style={{ justifySelf: 'right' }}>{val.valueFrom}</div>
                            <div style={{ justifySelf: 'right' }}>{val.valueTo ? val.valueTo : '∞'}</div>
                        </div>
                    ))}
                </>
            );
        }
    },
    {
        key: 'status',
        name: 'status',
        isIconOnly: true,
        isResizable: false,
        minWidth: 40,
        maxWidth: 80,
        headerClassName: classNames.headerRow,
        onRender: function render(config: AnalysisConfig) {
            return (
                <Toggle
                    disabled={actionInProgress}
                    checked={config.isActive}
                    styles={{ root: { marginBottom: 0 } }}
                    onChange={() => updateStandardStatus(config.id)}
                />
            );
        }
    },
    {
        key: 'createdBy',
        name: t('qualityCheck.createdBy'),
        minWidth: 100,
        maxWidth: 250,
        headerClassName: classNames.headerRow,
        onRender: function render(config: AnalysisConfig) {
            return config.user;
        }
    },
    {
        key: 'createdOn',
        name: t('qualityCheck.createdOn'),
        minWidth: 100,
        maxWidth: 250,
        targetWidthProportion: 3,
        headerClassName: classNames.headerRow,
        onRender: function render(config: AnalysisConfig) {
            return Helpers.formatToRelativeDate(config.createdOn);
        }
    },
    {
        key: 'remove',
        name: 'remove',
        isIconOnly: true,
        isResizable: false,
        minWidth: 30,
        maxWidth: 80,
        headerClassName: classNames.headerRow,
        onRender: function render(config: AnalysisConfig) {
            return (
                <IconButton
                    disabled={actionInProgress}
                    iconProps={{ iconName: "Delete" }}
                    onClick={() => setDeleteStandardModalOpened(config.id)}
                />
            );
        }
    }];

    const emptyList = useCallback(() => {
        if (loading || (configs && configs.length > 0))
            return null;

        return (
            <div>
                <TeamsImage
                    styles={{
                        img: {
                            width: '25%',
                            height: '25%'
                        }
                    }}
                    imageName={ImageName.EmptyFolderDrop}
                    caption={t("common:emptyList")}
                />
            </div>
        )
    }, [loading, configs, t]);

    return (
        <>
            <div style={{ paddingLeft: 30, paddingTop: 15 }}>
                <PrimaryButton
                    iconProps={{ iconName: 'Add' }}
                    styles={{ icon: { height: 'auto' } }}
                    text={t("qualityCheck.addStandard")}
                    onClick={() => setCreateStandardModalOpened(true)}
                />
                {configs &&
                    <DetailsList
                        layoutMode={DetailsListLayoutMode.justified}
                        styles={classNames.subComponentStyles.detailsList}
                        items={configs || []}
                        getKey={(item: AnalysisConfigTyped<QualityCheckSetting>) => item.id.toString()}
                        onRenderRow={(props?: IDetailsRowProps) => {
                            if (!props) return null;
                            return (
                                <>
                                    <div className={classNames.rowTitle}>{props.item.name}</div>
                                    <div className={classNames.paragraphUoM}>{t("qualityCheck.paragraphUoM")}</div>
                                    <DetailsRow {...props} styles={classNames.subComponentStyles.detailsRow} />
                                </>
                            )
                        }}
                        columns={columns}
                        onRenderDetailsFooter={emptyList}
                        selectionMode={SelectionMode.none}
                    />
                }
                {error && <TeamsImage styles={{
                    img: {
                        width: '50%',
                        height: '50%'
                    }
                }} imageName={ImageName.Error2} caption={t("common:genericErrorApi")} />}
                {loading && configs === undefined &&
                    <div className={classNames.spinner}>
                        <TeamsSpinner />
                    </div>
                }
            </div>
            {createStandardModalOpened && <CreateStandardModal isOpen={createStandardModalOpened} onClose={reload => {
                    setCreateStandardModalOpened(false);
                    if (reload)
                        execute();
                }} />}
            <Dialog hidden={deleteStandardModalOpened === 0} dialogContentProps={{
                title: t('qualityCheck.deleteModal.title'),
                subText: t('qualityCheck.deleteModal.description')
            }}>
                <DialogFooter>
                    <DefaultButton text={t('common:dialogButtonCancel')} onClick={() => setDeleteStandardModalOpened(0)} />
                    <PrimaryButton text={t('common:dialogButtonConfirmDeleting')} onClick={async () => {
                        await deleteStandard(deleteStandardModalOpened);
                    }} />
                </DialogFooter>
            </Dialog>
        </>
    )
}