import { styled } from "@fluentui/react";
import { IAddUsersToTopicModalProps, IAddUsersToTopicModalPropsStyles, IAddUsersToTopicModalStyles } from "./addUsersToTopicModal.types";
import { getStyles } from "./addUsersToTopicModal.styles";
import { AddUsersToTopicModalBase } from "./addUsersToTopicModal.base";

const AddUsersToTopicModal = styled<IAddUsersToTopicModalProps, IAddUsersToTopicModalPropsStyles, IAddUsersToTopicModalStyles>(
    AddUsersToTopicModalBase, 
    getStyles,
    undefined,
    { scope: 'AddUsersToTopicModal'}
)

export default AddUsersToTopicModal;