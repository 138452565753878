import { classNamesFunction, Icon, Stack, StackItem, Sticky } from "@fluentui/react";
import React, { useState } from "react";
import { IAccordionAnalysisProps, IAccordionAnalysisPropsStyles, IAccordionAnalysisStyles } from "./accordionAnalysis.types";

const getClassNames = classNamesFunction<IAccordionAnalysisPropsStyles, IAccordionAnalysisStyles>();

export const AccordionAnalysisBase = (props: IAccordionAnalysisProps) => {
    const [opened, setOpened] = useState(props.opened ?? false);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, opened: opened });

    const header = (
        <Stack horizontal className={classNames.header}>
            <Icon onClick={() => setOpened(!opened)} styles={classNames.subComponentStyles.icon} iconName={opened ? 'ChevronDownMed' : 'ChevronRightMed'} />
            <div onClick={() => setOpened(!opened)} className={classNames.title}>{props.title}</div>
        </Stack>
    );

    return (
        <Stack className={classNames.root}>
            {props.stickyHeader ? <Sticky>{header}</Sticky> : header}
            {opened && <StackItem>
                {props.children}
            </StackItem>}
        </Stack>
    );
}