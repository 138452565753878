import { classNamesFunction, DirectionalHint, Icon, IconButton, IContextualMenuItem, IContextualMenuProps } from "@fluentui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDocLabDispatch } from "../../../../docLabStore";
import { setCurrentAnalysis, useDocAnalysisContent } from "../../../../features/docAnalysisContent";
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive";
import { useNavigator } from "../../../../hooks/useNavigator";
import { AnalyzesStatus } from "../../../../models/constants";
import { getDocAnalysisActions } from "../../../../utilities/docAnalysisActions";
import { IFileNameCellProps, IFileNameCellPropsStyles, IFileNameCellStyles } from "./fileNameCell.types";

const getClassNames = classNamesFunction<IFileNameCellPropsStyles, IFileNameCellStyles>();

export const FileNameCellBase = (props: IFileNameCellProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(["docAnalysis"]);
    const archive = useCurrentArchive();
    const { isLoadingDeleteAnalysis } = useDocAnalysisContent();

    const navigator = useNavigator();
    const dispatch = useDocLabDispatch();

    const menuItems = useCallback((): IContextualMenuItem[] => {
        return archive ? getDocAnalysisActions(archive.currentUserRoleId, props.item.typeId).map(
            (action) => ({
                key: action.label,
                text: t(action.label),
                disabled: isLoadingDeleteAnalysis || props.item.statusId === AnalyzesStatus.InAnalysis || props.item.statusId === AnalyzesStatus.Error,
                iconProps: { iconName: action.icon },
                onClick: () => {
                    dispatch(setCurrentAnalysis(props.item));
                    action.onClick(props.item, navigator, dispatch);
                },
            })
        ) : [];
    }, [navigator, t, dispatch, props.item, archive, isLoadingDeleteAnalysis]);

    const menuProps: IContextualMenuProps = {
        items: menuItems() || [],
        styles: isLoadingDeleteAnalysis || props.item.statusId === AnalyzesStatus.InAnalysis || props.item.statusId === AnalyzesStatus.Error ? classNames.subComponentStyles.disabledContextualMenuOptions : undefined,
        directionalHint: DirectionalHint.rightTopEdge
    }


    return (
        <>
            <div className={classNames.textWrapper}>
                <span
                    data-selection-disabled
                    className={classNames.text}
                    title={props.title || props.name}
                    style={props.pointer ? { pointerEvents: 'none' } : {}}
                    onClick={props.onClick}>
                    {props.name}
                </span>
            </div>
            {props.isNewItem && <Icon iconName="Glimmer" className={classNames.newItemIcon} />}
            {menuItems().length > 0 &&
                <div className={classNames.menuIconWrapper}>
                    <IconButton
                        styles={classNames.subComponentStyles.iconButton()}
                        iconProps={{ iconName: 'More' }}
                        menuProps={menuProps}
                        disabled={isLoadingDeleteAnalysis}
                    />
                </div>
            }
        </>
    );
}