import { styled } from "@fluentui/react";
import { getStyles } from "./createPortfolioModal.styles";
import { ICreatePortfolioModalPropsStyles, ICreatePortfolioModalStyles, ICreatePortfolioModalProps } from "./createPortfolioModal.types";
import { CreatePortfolioModalBase } from "./createPortfolioModal.base";

const CreatePortfolioModal = styled<ICreatePortfolioModalProps, ICreatePortfolioModalPropsStyles, ICreatePortfolioModalStyles>(
    CreatePortfolioModalBase,
    getStyles,
    undefined,
    { scope: 'CreatePortfolioModal' }
);

export default CreatePortfolioModal;