/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable sonarjs/no-duplicate-string */
import React from "react";
import { Label, Pivot, PivotItem, classNamesFunction } from "@fluentui/react";
import { IFormalAspectsProps, IFormalAspectsPropsStyles, IFormalAspectsStyles } from "./formalAspects.types";
import { useTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";
import { Helpers } from "../../../../utilities/helpers";


const getClassNames = classNamesFunction<IFormalAspectsPropsStyles, IFormalAspectsStyles>();

export const FormalAspectsBase = (props: IFormalAspectsProps) => {
  const { palette } = props.theme!;//eslint-disable-line @typescript-eslint/no-non-null-assertion
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const { t } = useTranslation(['tagPanel', 'dashboard']);

  const pageCount = {
    labels: props.pageCount && props.pageCount.map(t => t.label),
    datasets: [
      {
        data: props.pageCount && props.pageCount.map(t => t.count),
        backgroundColor: [palette.themePrimary],
        borderColor: palette.themeSecondary,
      },
    ],
  };

  const wordCount = {
    labels: props.wordCount && props.wordCount.map(t => t.label),
    datasets: [
      {
        data: props.wordCount && props.wordCount.map(t => t.count),
        backgroundColor: [palette.themePrimary],
        borderColor: palette.themeSecondary,
      },
    ],
  };

  const paragraphCount = {
    labels: props.paragraphCount && props.paragraphCount.map(t => t.label),
    datasets: [
      {
        data: props.paragraphCount && props.paragraphCount.map(t => t.count),
        backgroundColor: [palette.themePrimary],
        borderColor: palette.themeSecondary,
      },
    ],
  };

  const tableCount = {
    labels: props.tableCount && props.tableCount.map(t => t.label),
    datasets: [
      {
        data: props.tableCount && props.tableCount.map(t => t.count),
        backgroundColor: [palette.themePrimary],
        borderColor: palette.themeSecondary,
      },
    ],
  };

  const imageCount = {
    labels: props.imageCount && props.imageCount.map(t => t.label),
    datasets: [
      {
        data: props.imageCount && props.imageCount.map(t => t.count),
        backgroundColor: [palette.themePrimary],
        borderColor: palette.themeSecondary,
      },
    ],
  };

  const pageCountOptions = {
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        max: props.pageCount && Helpers.getMaxScaleForDashBoard(props.pageCount.map(o => o.count)),
        ticks: {
          beginAtZero: true
        }
      }
    },
  };

  const wordCountOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        max: props.wordCount && Helpers.getMaxScaleForDashBoard(props.wordCount.map(o => o.count)),
        ticks: {
          beginAtZero: true
        }
      }
    },
  };

  const paragraphCountOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        max: props.paragraphCount && Helpers.getMaxScaleForDashBoard(props.paragraphCount.map(o => o.count),),
        ticks: {
          beginAtZero: true
        }
      }
    },
  };

  const tableCountOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        max: props.tableCount && Helpers.getMaxScaleForDashBoard(props.tableCount.map(o => o.count)),
        ticks: {
          beginAtZero: true
        }
      }
    },
  };

  const imageCountOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    height: '400px',
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        max: props.imageCount && Helpers.getMaxScaleForDashBoard(props.imageCount.map(o => o.count)),
        ticks: {
          beginAtZero: true
        }
      }
    },
  };

  return (
    <>
      {(!props.pageCount || props.pageCount.length === 0) &&
        (!props.paragraphCount || props.paragraphCount.length === 0) &&
        (!props.tableCount || props.tableCount.length === 0) &&
        (!props.wordCount || props.wordCount.length === 0) &&
        (!props.imageCount || props.imageCount.length === 0)
        ?
        <Label className={classNames.empty}>{t('dashboard:empty')}</Label>
        :
        <Pivot style={{ height: '100%' }} styles={{ itemContainer: { width: '100%', position: 'relative', height: 'calc(100% - 44px)' } }}>
          <PivotItem headerText={t('values.PageCount')} headerButtonProps={{ 'data-order': 1 }}>
            <div className={classNames.container}>
              {props.pageCount && props.pageCount.length !== 0 && <Bar data={pageCount} options={pageCountOptions} />}
              {(!props.pageCount || props.pageCount.length === 0) && <Label className={classNames.error}>{t('dashboard:formalAspectsError')}</Label>}
            </div>
          </PivotItem>

          <PivotItem headerText={t('values.WordCount')}>
            <div className={classNames.container}>
              {props.wordCount && props.wordCount.length !== 0 && <Bar data={wordCount} options={wordCountOptions} />}
              {(!props.wordCount || props.wordCount.length === 0) && <Label className={classNames.error}>{t('dashboard:formalAspectsError')}</Label>}
            </div>
          </PivotItem>

          <PivotItem headerText={t('values.ParagraphCount')}>
            <div className={classNames.container}>
              {props.paragraphCount && props.paragraphCount.length !== 0 && <Bar data={paragraphCount} options={paragraphCountOptions} />}
              {(!props.paragraphCount || props.paragraphCount.length === 0) && <Label className={classNames.error}>{t('dashboard:formalAspectsError')}</Label>}
            </div>
          </PivotItem>

          <PivotItem headerText={t('values.TableCount')}>
            <div className={classNames.container}>
              {props.tableCount && props.tableCount.length !== 0 && <Bar data={tableCount} options={tableCountOptions} />}
              {(!props.tableCount || props.tableCount.length === 0) && <Label className={classNames.error}>{t('dashboard:formalAspectsError')}</Label>}
            </div>
          </PivotItem>

          <PivotItem headerText={t('values.ImageCount')}>
            <div className={classNames.container}>
              {props.imageCount && props.imageCount.length !== 0 && <Bar data={imageCount} options={imageCountOptions} />}
              {(!props.imageCount || props.imageCount.length === 0) && <Label className={classNames.error}>{t('dashboard:formalAspectsError')}</Label>}
            </div>
          </PivotItem>

        </Pivot>
      }
    </>
  );
}