/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useEffect, useState } from "react";
import { classNamesFunction, IconButton, IIconProps, Modal, ResponsiveMode, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { IFeedbackModalPropsStyles, IFeedbackModalStyles, IFeedbackModalProps } from "./feedbackModal.types";
import { KnowledgeDsTextType } from "../../../../utilities/constants";
import { knowledgeDsApi } from "../../../services/knowledgeDS.api";
import CopyActionCommand from "../../../../knowledgeLab/components/common/copyActionButton/copyActionCommand";

const getClassNames = classNamesFunction<IFeedbackModalPropsStyles, IFeedbackModalStyles>();

export const FeedbackModalBase = (props: IFeedbackModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, width: "60%", height: "80%" });

    const [loading, setLoading] = useState(true);
    const [modalContent, setModalContent] = useState<string>();
    const [error, setError] = useState(false);


    useEffect(() => {
        if (props.documentType !== undefined && props.documentId !== undefined) {
            setError(false)
            setLoading(true)
            if ((props.documentType as KnowledgeDsTextType) === KnowledgeDsTextType.element) {
                knowledgeDsApi.getElementDetails(props.documentId)
                    .then((content) => {
                        setModalContent(content);
                    })
                    .catch((e) => {
                        setError(true);
                    }).finally(() => {
                        setLoading(false)
                    })
            } else {

                knowledgeDsApi.getDocument(props.documentId)
                    .then((document) => {
                        setModalContent(document.content);
                    })
                    .catch((e) => {
                        setError(true);
                    }).finally(() => {
                        setLoading(false)
                    })
            }
        }
    }, [props.documentId, props.documentType]);

    const modalBody =
        <>
            <Stack>
                {loading ? <div className={classNames.loadingErrorContainer}>
                    <Spinner size={SpinnerSize.large} />
                </div> : (error ? <span>{' something went wrong :( '}</span> :
                    <Stack.Item style={{ height: 'calc(100% - 10px)', overflowY: 'scroll' }}>
                        <Stack.Item >
                            <div className={classNames.copyButtonDiv}>
                                <CopyActionCommand
                                    content={modalContent ?? ""}
                                    textPlaceHolder=""
                                />
                            </div>
                            <p>{modalContent}</p>
                        </Stack.Item>
                    </Stack.Item>
                )

                }

            </Stack>
        </>

    const initialIconProps: IIconProps = {
        iconName: 'Cancel'
    }

    return (
        <Modal
            isOpen={props.isOpen}
            className={props.className}
            containerClassName={classNames.container}
            responsiveMode={ResponsiveMode.large}
            scrollableContentClassName={classNames.content}
            onDismissed={props.onCloseClick}
            isBlocking={true}
        >
            <div className={classNames.upperContent}>
                <div>
                    <div className={classNames.header}>
                        <div className={classNames.title}>{props.title}</div>
                        <div className={classNames.closeIcon}>
                            <IconButton
                                styles={classNames.subComponentStyles.iconButton()}
                                iconProps={{ ...initialIconProps }}
                                onClick={props.onCloseClick}
                            />
                        </div>
                    </div>
                </div>
                <div className={classNames.body}>
                    <div className={classNames.bodyContent}>
                        {modalBody}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

