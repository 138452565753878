import { classNamesFunction, DirectionalHint, Icon, IconButton, IContextualMenuItem, IContextualMenuProps } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDocLabDispatch, useDocLabState } from "../../../../docLabStore";
import { useArchiveContent } from "../../../../features/archiveContent";
import { useNavigator } from "../../../../hooks/useNavigator";
import { ActionEntry, getArchiveContentActionsByIds } from "../../../../utilities/archiveContentActions";
import { IFileNameCellProps, IFileNameCellPropsStyles, IFileNameCellStyles } from "./fileNameCell.types";

const getClassNames = classNamesFunction<IFileNameCellPropsStyles, IFileNameCellStyles>();

export const FileNameCellBase = (props: IFileNameCellProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { selectedItemsActionIds, selectedItems } = useArchiveContent();
    const { t } = useTranslation('archiveContentActions');

    const navigator = useNavigator();
    const dispatch = useDocLabDispatch();
    const state = useDocLabState();

    const mapContextualMenuActions = (actions: ActionEntry[]): IContextualMenuItem[] => {
        const filteredActions = actions.filter(action => (action.toBeIndexedCheck && selectedItems[0].indexed) || !action.toBeIndexedCheck);

        return filteredActions.map(action => {
            let subActions = undefined;
            if (action.subActionItems) {
                subActions = mapContextualMenuActions(action.subActionItems);
            }

            return {
                key: action.id,
                text: action.label ? t(action.label) : "",
                items: subActions,
                onClick: () => action.onClick && action.onClick(state, navigator, dispatch),
            }
        });
    }

    const menuItems = mapContextualMenuActions(getArchiveContentActionsByIds(selectedItemsActionIds));

    const menuProps: IContextualMenuProps = {
        items: menuItems || [],
        directionalHint: DirectionalHint.rightTopEdge
    }

    return (
        <>
            <div className={classNames.textWrapper}>
                <span data-selection-disabled className={classNames.text} title={props.title || props.name} onClick={props.onClick}>
                    {props.name}
                </span>
            </div>
            {props.isNewItem && <Icon iconName="Glimmer" className={classNames.newItemIcon} />}
            {menuItems &&
                <div className={classNames.menuIconWrapper}>
                    <IconButton
                        styles={classNames.subComponentStyles.iconButton()}
                        iconProps={{ iconName: 'More' }}
                        menuProps={menuProps}
                    />
                </div>
            }
        </>
    );
}