import { styled } from "@fluentui/react";
import { getStyles } from "./fileMetadataOnUpload.styles";
import { IFileMetadataOnUploadPropsStyles, IFileMetadataOnUploadStyles, IFileMetadataOnUploadProps } from "./fileMetadataOnUpload.types";
import { FileMetadataOnUploadBase } from "./fileMetadataOnUpload.base";

const FileMetadataOnUpload = styled<IFileMetadataOnUploadProps, IFileMetadataOnUploadPropsStyles, IFileMetadataOnUploadStyles>(
    FileMetadataOnUploadBase,
    getStyles,
    undefined,
    { scope: 'FileMetadataOnUpload' }
);

export default FileMetadataOnUpload;