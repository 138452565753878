import React from "react";
import { classNamesFunction, Icon, Label, Stack, TooltipDelay, TooltipHost } from "@fluentui/react";
import { IIconTagPropsStyles, IIconTagStyles, IIconTagProps } from "./iconTag.types";

const getClassNames = classNamesFunction<IIconTagPropsStyles, IIconTagStyles>();

export const IconTagBase = (props: IIconTagProps) => {
  const classNames = getClassNames(props.styles, {
    theme: props.theme,
    className: props.className,
    noBackground: props.noBackground
  });

  const renderTag = () => {
    return (
      <Stack
        horizontal verticalAlign='center' horizontalAlign='center'
        className={props.className ? `${classNames.root} ${props.className}` : classNames.root}
      >
        <Icon
          iconName={props.iconName || "Tag"}
          className={props.iconClassName} />
        <Label className={props.labelClassName} style={{ color: 'inherit',textOverflow: 'ellipsis',  whiteSpace: 'nowrap' }}>{props.label}</Label>
      </Stack>
    );
  };

  return (
    <>
      {props.tooltip ? (
        <TooltipHost id={'iconTag'} content={props.label} delay={TooltipDelay.zero}
          style={{ display: props.tooltip ? "initial" : "none" }}>
          {renderTag()}
        </TooltipHost>
      ) : (<>{renderTag()}</>)}
    </>
  );
};
