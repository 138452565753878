import { User } from "./user"

export enum PortfolioRoleId {
    Owner = 0,
    Viewer = 1
}

export enum ActivityRoleId {
    Leader = 0,
    Member = 1
}

export enum PhaseStatus {
    NotStarted = 0,
    InProgress = 1,
    Completed = 2
}

export enum ActivityPriority {
    P1 = 0,
    P2 = 1,
    P3 = 2
}

export enum ActivityStatus {
    Planned = 0,
    OnGoing = 1,
    Blocked = 2,
    Closed = 3
}

export type ActivityMembership = {
    activityRoleId: ActivityRoleId;
    user: User;
}

export const Constants = {
    MAX_LENGTH_PORTFOLIO_NAME: 50,
    MAX_LENGTH_TEMPLATE_NAME: 50,
    MAX_LENGTH_TEMPLATE_DESCRIPTION: 200,
    MAX_PHASES_NUMBER: 15,
    MAX_WORKFLOW_NAME: 25,
    MAX_WORKFLOW_DESCRIPTION: 150,
    MAX_WORKFLOWSTEP_NAME: 25,
    MAX_NUMBER_FILES_PAGE: 20
}

export const SubCodeConstants = {
    SUBCODE_DEPTH_LIMIT_EXCEED: 1, 
    SUBCODE_ARCHIVE_SIZE_EXCEED: 2,
    SUBCODE_MOVEFOLDER_IN_SUBFOLDER: 3
}

export enum WorkflowType {
    Approval = 0,
    Verify = 1
}

export enum WorkflowStatus {
    Approved = 0,
    Refused = 1,
    OnGoing = 2
}

export enum WorkflowStepType {
    OnlyOneApprover = 0,
    OnlyOneSignedApprover = 1,
    EveryoneMustApprove = 2
}

export enum WorkflowStepStatus {
    ToBeApproved = 0,
    Approved = 1,
    Refused = 2
}