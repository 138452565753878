import { IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";

export interface IChoiceInputSourceProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<IChoiceInputSourcePropsStyles, IChoiceInputSourceStyles>;
    className?: string;
    onElementChoice: (type: FileSource) => void;
}

export interface IChoiceInputSourcePropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<IChoiceInputSourcePropsStyles, IChoiceInputSourceStyles>;
    className?: string;
    // ADD STYLE PROPS HERE FOR CONDITIONAL CSS GENERATION
}

export interface IChoiceInputSourceStyles {
    root: IStyle;
}

export enum FileSource {
    local = 0,
    edi = 1,
    sharepoint = 2
}