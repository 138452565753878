import { IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";
import { CSSProperties } from "react";

export enum ImageName {
    WellDone = 0,
    EmptyFolderDrop = 1,
    Oops1 = 2,
    StillThere = 3,
    Error1 = 4,
    Error2 = 5,
    Error3 = 6,
    Oops2 = 7,
    EmptySearch = 8,
    SearchNoResult = 9,
    SurprisedWoman = 10,
    Forbidden = 11,
    Sandglass = 12,
    EdiAppLogo = 13,
    InProgress = 14,
    GlossaryCheckInfoIt = 15,
    GlossaryCheckInfoEn = 16,
    Sharepoint = 17,
    EmptyFavourites = 18,
    NoResults = 19
}

export interface ITeamsImageProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ITeamsImagePropsStyles, ITeamsImageStyles>;
    style?: CSSProperties;
    className?: string;
    imageName: ImageName;
    scale?: number;
    fullContainer?: boolean;
    caption?: string;
}

export interface ITeamsImagePropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ITeamsImagePropsStyles, ITeamsImageStyles>;
    className?: string;
    scale: number;
    fullContainer: boolean;
}

export interface ITeamsImageStyles {
    root: IStyle;
    img: IStyle;
    caption: IStyle;
}