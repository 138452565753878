import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";

export const useQueryString = () => {
    const location = useLocation();
    return new URLSearchParams(location.search);
}

export const useOnMount = (callback: React.EffectCallback) => {
    return useEffect(callback, []); //eslint-disable-line react-hooks/exhaustive-deps
}

export type AsyncApiParameters<TRequest, TResponse> = {
    func: (request: TRequest) => Promise<TResponse>;
    initialValue?: TResponse;
    keepResultsWhileReloading?: boolean;
}

export const useAsyncApi = <TRequest, TResponse>(props: AsyncApiParameters<TRequest, TResponse>) => {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(props.initialValue);
    const [error, setError] = useState(null);

    const { func, keepResultsWhileReloading } = props;

    const execute = useCallback(async (request: TRequest) => {
        setLoading(true);
        setError(null);

        if (!keepResultsWhileReloading)
            setValue(undefined);

        try {
            const response = await func(request);
            setValue(response);
        } catch (error) {
            setError(error);
        }
        finally {
            setLoading(false);
        }
    }, [func, keepResultsWhileReloading]);

    return { execute, loading, value, error };
};

export const usePrevious = <T>(value: T) => {
    const ref = useRef<T>();
    useEffect(() => { ref.current = value });
    return ref.current;
}

export const useDebounced = <TRequest, TResponse>(func: (request: TRequest) => Promise<TResponse>, timeout: number) => {
    const [lastCall, setLastCall] = useState<NodeJS.Timeout>();

    return useCallback(async (request: TRequest) => {
            if (lastCall)
                clearTimeout(lastCall);
    
            setLastCall(setTimeout(async () => {
                await func(request);
            }, timeout))
    
        }, [timeout, func]); //eslint-disable-line react-hooks/exhaustive-deps
}
