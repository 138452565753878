/*eslint-disable sonarjs/cognitive-complexity*/
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    classNamesFunction,
    ConstrainMode,
    DefaultButton,
    Dialog,
    DialogFooter,
    DirectionalHint,
    IColumn,
    IconButton,
    IContextualMenuProps,
    Persona,
    PersonaSize,
    PrimaryButton,
    SelectionMode,
    ShimmeredDetailsList,
    TooltipHost
} from "@fluentui/react";
import {IDatasetsProps, IDatasetsPropsStyles, IDatasetsStyles} from "./datasets.types";
import {Dataset, dashboardDatasetStatusText} from "../../models/dataset";
import {useDashboardDispatch} from "../../dashboardStore";
import {resetDatasetFilters, setAllDatasetItems, setFilters, setNoMore, setRefresh, useDatasetList} from "../../features/datasetList";
import {setFilters as setFiltersFeedback} from "../../features/feedbackList"
import {useTranslation} from "react-i18next";
import {useOnMount} from "../../../utilities/hooks";
import {Helpers} from "../../../utilities/helpers";
import {ImageName} from "../../../common/components/teamsImage/teamsImage.types";
import TeamsImage from "../../../common/components/teamsImage/teamsImage";
import {dashboardApi} from "../../services/dashboard.api";
import {useNavigator} from "../../hooks/useNavigator";

const getClassNames = classNamesFunction<IDatasetsPropsStyles, IDatasetsStyles>();

export const DatasetsBase = (props: IDatasetsProps) => {

    const [datasetItems, setDatasetItems] = useState<Dataset[]>([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mounted, setMounted] = useState<boolean>(false);
    const [retrainDialog, setRetrainDialog] = useState<number | undefined>();
    const [scrollLoading, setScrollLoading] = useState(false);

    const navigator = useNavigator();
    const dispatch = useDashboardDispatch();
    const { filters, noMore, refresh } = useDatasetList()
    const { t } = useTranslation(['dashboardDatasets', 'dashboardToolbar', 'common']);

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, isEmptyList: (datasetItems.length === 0 && !loading) })

    useOnMount(() => {
        registerEvent();
        dispatch(resetDatasetFilters());
        setMounted(true);
    });

    useEffect(() => {
        if (mounted) {
            if (filters.pageNumber === 0) {
                setLoading(true);
                dashboardApi.getDatasets(filters)
                    .then((result) => {
                        const totalDatasetItems = result;
                        dispatch(setAllDatasetItems(totalDatasetItems));
                        setDatasetItems(result);
                    }).catch((err) => {
                        setError(true);
                    })
                    .finally(() => {
                        setLoading(false)
                        setScrollLoading(false)
                    })
            } else {
                dashboardApi.getDatasets(filters)
                    .then((result) => {
                        const totalDatasetItems = result;
                        dispatch(setAllDatasetItems(totalDatasetItems));
                        const actualList = [...datasetItems];
                        const newList = actualList.concat(result)

                        if (result.length < (filters?.pageSize ?? 20))
                            dispatch(setNoMore(true));

                        setDatasetItems(newList);
                    }).catch((err) => {
                        setError(true);
                    })
                    .finally(() => {
                        setLoading(false)
                        setScrollLoading(false)
                    })
            }
        }
    }, [filters, mounted]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (refresh) {
            dispatch(setNoMore(false));
            refreshData();
        }
    }, [refresh]); //eslint-disable-line react-hooks/exhaustive-deps


    const loadMore = () => {
        if (loading || noMore || scrollLoading || filters?.pageNumber == null)
            return;

        setScrollLoading(true);

        dispatch(setFilters(
            {
                ...filters,
                pageNumber: filters.pageNumber + 1
            }))
    }

    const refreshData = () => {
        dispatch(setFilters({
            ...filters,
            pageNumber: 0
        }));
        dispatch(setRefresh(false));
    }

    const registerEvent = useCallback(() => {
        const listElm = document.querySelector('#detailsListZone .ms-DetailsList-contentWrapper');
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50) {
                const button = document.getElementById("click");
                button && button.click();
            }
        });
    }, []);

    const menuProps = (item: Dataset): IContextualMenuProps => {
        return {
            items: [
                {
                    key: 'train',
                    text: t('dashboardToolbar:actions.train'),
                    onClick: () => {
                        setRetrainDialog(item.id);
                    }
                }
            ],
            directionalHint: DirectionalHint.rightTopEdge
        }
    }
    const getFormattedDate = (dateString: string) => {
        return Helpers.formatToRelativeDate(dateString);
    }

    const sortByColumn = useCallback((_: React.MouseEvent, column: IColumn) => {
        if (loading)
            return;

        dispatch(setFilters({
            ...filters,
            orderBy: column.fieldName,
            isAscending: column.isSortedDescending,
            pageNumber: 0
        }));

    }, [loading, dispatch]); //eslint-disable-line react-hooks/exhaustive-deps


    const columns: IColumn[] = useMemo(() => {
        return [
            {
                key: 'SkillName',
                name: t("columns.skillName"),
                fieldName: 'SkillName',
                minWidth: 150,
                maxWidth: 350,
                isSorted: filters.orderBy === 'SkillName',
                isSortedDescending: !filters.isAscending,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: sortByColumn,
                onRender: function getCell(item: Dataset) {
                    return <span>{item.skillName}</span>
                }
            },
            {
                key: 'DatasetName',
                name: t("columns.name"),
                fieldName: 'Name',
                minWidth: 200,
                maxWidth: 350,
                isSorted: filters.orderBy === 'Name',
                isSortedDescending: !filters.isAscending,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: sortByColumn,
                onRender: function getCell(item: Dataset) {
                    return <div onClick={() => {
                        dispatch(setFiltersFeedback(
                            {
                                ...filters,
                                datasetId: item.id,
                                datasetName: item.name
                            }));
                        navigator.changeTab();}}
                    >
                        <span className={classNames.datasetName}>{item.name}</span>
                        <IconButton
                            styles={classNames.subComponentStyles.iconButton()}
                            iconProps={{ iconName: 'More' }}
                            menuProps={menuProps(item)}
                        />
                    </div>
                }
            },
            {
                key: 'DatasetStatus',
                name: t("columns.datasetStatus"),
                fieldName: 'DatasetStatus',
                minWidth: 150,
                maxWidth: 350,
                isSorted: filters.orderBy === 'DatasetStatus',
                isSortedDescending: !filters.isAscending,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: sortByColumn,
                onRender: function getCell(item: Dataset) {
                    const status = dashboardDatasetStatusText.find(t => t.key === item.datasetStatus)?.value ?? ""
                    return <span>{t(status)}</span>;
                }
            },
            {
                key: 'CreatedOn',
                name: t("columns.createdOn"),
                fieldName: 'CreatedOn',
                minWidth: 130,
                maxWidth: 130,
                isResizable: true,
                isSorted: filters.orderBy === 'CreatedOn',
                isSortedDescending: !filters.isAscending,
                onColumnClick: sortByColumn,
                onRender: (item: Dataset) => getFormattedDate(item.createdOn)
            },
            {
                key: 'UpdatedOn',
                name: t("columns.updatedOn"),
                fieldName: 'UpdatedOn',
                minWidth: 130,
                maxWidth: 130,
                isResizable: true,
                isSorted: filters.orderBy === 'UpdatedOn',
                isSortedDescending: !filters.isAscending,
                onColumnClick: sortByColumn,
                onRender: (item: Dataset) => item.updatedOn ? getFormattedDate(item.updatedOn) : " - "
            },
            {
                key: 'UserUpdated',
                name: t("columns.updatedBy"),
                fieldName: 'TrainerEmail',
                minWidth: 60,
                maxWidth: 120,
                isSorted: filters.orderBy === 'TrainerEmail',
                isSortedDescending: !filters.isAscending,
                onColumnClick: sortByColumn,
                isResizable: true,
                onRender: function getCell(item: Dataset) {
                    if (item.trainerEmail !== null && item.trainerEmail !== undefined && item.trainerEmail !== "") {
                        return <div className={classNames.personaCell}>
                            <TooltipHost
                                content={item.trainerName ? `${item.trainerName} (${item.trainerEmail})` : <div style={{ paddingLeft: 10, paddingRight: 10 }}>  -  </div>}
                            >
                                {item.trainerName ? <Persona text={item.trainerName} size={PersonaSize.size24} hidePersonaDetails={true} /> : " - "}
                            </TooltipHost>

                        </div>;
                    }
                    else {
                        return <div></div>
                    }
                }
            }];
    }, [sortByColumn, filters.isAscending, dispatch, filters.orderBy, t]); //eslint-disable-line react-hooks/exhaustive-deps

    const emptyFolder = useCallback(() => {
        if ((filters.pageNumber && filters.pageNumber > 0 && datasetItems.length === 0) || (filters.pageNumber && filters.pageNumber === 0 && loading))
            return null;

        return (
            <div className={classNames.emptyFolder}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyFolder")}
                />
            </div>
        )
    }, [classNames.emptyFolder, t, datasetItems.length, loading, filters.pageNumber, noMore]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Dialog
                isOpen={retrainDialog !== undefined}
                dialogContentProps={{
                    title: t('deleteTitle'),
                    onDismiss: () => setRetrainDialog(undefined),
                    subText: t('deleteSubTitle')
                }}>
                <div className={classNames.footerContainer}>
                    <div className={classNames.buttonContainer}>
                        <DialogFooter styles={classNames.subComponentStyles.dialogFooterContainer} >
                            <DefaultButton
                                onClick={() => setRetrainDialog(undefined)}
                                text={t('deleteUndo')}
                            />
                            <PrimaryButton
                                styles={classNames.subComponentStyles.primaryButtonDisabled()}
                                onClick={() => {
                                    setLoading(true);
                                    setRetrainDialog(undefined);
                                    dashboardApi.executeTraining(retrainDialog ?? 0).then((r) => {
                                        dispatch(setNoMore(false))
                                        refreshData();
                                        setLoading(false);
                                    }).catch((e) => {
                                        setLoading(false);
                                    })
                                }}
                                text={t('deleteConfirm')}
                            />
                        </DialogFooter>
                    </div>
                </div>
            </Dialog>
            {error ? <>
                <TeamsImage
                    imageName={ImageName.Error1}
                    scale={0.3}
                    fullContainer
                    caption={t("common:genericErrorApi")}
                />
            </> :
                <>

                    <div id={"detailsListZone"} className={classNames.root}>
                        <ShimmeredDetailsList
                            items={datasetItems}
                            columns={columns}
                            getKey={(item: Dataset) => item && `${item.id}`}
                            selectionMode={SelectionMode.none}
                            enableShimmer={filters.pageNumber === 0 && loading}
                            constrainMode={ConstrainMode.unconstrained}
                            styles={classNames.subComponentStyles.shimmeredDetailsList}
                            detailsListStyles={classNames.subComponentStyles.detailsList}
                            onRenderDetailsFooter={emptyFolder}
                        />
                        <div style={{ display: 'none' }}>
                            <PrimaryButton id={noMore ? "noClick" : "click"} onClick={loadMore} />
                        </div>

                    </div>

                </>}
        </>
    );
}