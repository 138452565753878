import { IStyleFunction, ITooltipHostStyles } from "@fluentui/react"
import { ITagPropsStyles, ITagStyles } from "./tag.types"

export const TagGlobalClassNames = {
    root: 'edi-tag-root',
    tagsContainer: 'edi-tag-tagsContainer',
    tagsContainerDisabled: 'edi-tag-tagsContainerDisabled',
    propertyContainer: 'edi-tag-propertyContainer',
    propertyName: 'edi-tag-propertyName',
    formalAspectsValue: 'edi-tag-formalAspectsValue',
    pinIcon: 'edi-tag-pinIcon',
    formalAspectsPinIcon: 'edi-tag-formalAspectsPinIcon'
}

export const getStyles: IStyleFunction<ITagPropsStyles, ITagStyles> = (props: ITagPropsStyles): ITagStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            TagGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        tagsContainer: [
            TagGlobalClassNames.tagsContainer,
            {
                height: 32,
                boxShadow: 'rgb(136 136 136) 0px 1px 8px 0px',
                display: 'flex',
                background: palette.themePrimary,
                alignItems: 'center',
                borderRadius: '10px',
                margin: '10px 15px',
                flexBasis: '28%',
                minWidth: '80px'
            }],
        tagsContainerDisabled: [
            TagGlobalClassNames.tagsContainerDisabled,
            {
                height: 32,
                boxShadow: 'rgb(136 136 136) 0px 1px 8px 0px',
                display: 'flex',
                background: palette.themeTertiary,
                alignItems: 'center',
                borderRadius: '10px',
                margin: '10px 15px',
                flexBasis: '28%',
                minWidth: '80px'
            }],
        pinIcon: [
            TagGlobalClassNames.pinIcon,
            {
                padding: '0 10px',
                fontSize: '18px',
                color: palette.white
            }],
        formalAspectsPinIcon: [
            TagGlobalClassNames.formalAspectsPinIcon,
            {
                flexBasis: '20%',
                display: 'flex',
                justifyContent: 'flex-end'
            }],
        formalAspectsValue: [
            TagGlobalClassNames.formalAspectsValue,
            {
                flexBasis: '30%',
                overflow: 'hidden',
                height: '100%',
                textOverflow: 'ellipsis',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                background: palette.neutralLight
            }],
        subComponentStyles: {
            tooltipStyle: (): ITooltipHostStyles => {
                return {
                    root: {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: props.formalAspects ? palette.themePrimary : palette.white,
                        cursor: 'context-menu'
                    }
                }
            }
        },
        propertyName: [
            TagGlobalClassNames.propertyName,
            {
                padding: '0 5px',
                color: palette.white,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
            }],
        propertyContainer: [
            TagGlobalClassNames.propertyContainer,
            {
                padding: '0 5px',
                color: palette.white
            }],
    };
}