import { styled } from "@fluentui/react";
import { getStyles } from "./deleteModal.styles";
import { IDeleteModalPropsStyles, IDeleteModalStyles, IDeleteModalProps } from "./deleteModal.types";
import { DeleteModalBase } from "./deleteModal.base";

const DeleteModal = styled<IDeleteModalProps, IDeleteModalPropsStyles, IDeleteModalStyles>(
    DeleteModalBase,
    getStyles,
    undefined,
    { scope: 'DeleteModal' }
);

export default DeleteModal;