/*eslint-disable @typescript-eslint/no-non-null-assertion*/
import { Section } from "../../common/components/tagPanel/tagPanel.types";
import { Helpers } from "../../utilities/helpers";
import { SelectionEntry, showCopyLinkModal, showCreateFolderModal, showDeleteModal, showManagePermissionModal, showManageProfilePermissionModal, showNavigationModal, showRenameModal, showTagPanel, showTranslationModal, showUploadFilesModal, showWorkflowDetailsModal, showDownloadWfDetailsModal, showFilesApprovalDocumentModal } from "../features/archiveContent";
import { IActionDelegate } from "../hooks/useActionDelegate";
import { Archive } from "../models/archive";
import { FileApprovalStatus, FileSourceType } from "../models/archiveItem";
import { ArchiveRoleId, ArchiveStatusFilesApproval, ArchiveTypeId, DeletedType, ItemToCopy } from "../models/constants";

export type ActionEntry = {
    id: string;
    scopes: SelectionScopes;
    archiveRoles: ArchiveRoleId[];
    archiveTypes: ArchiveTypeId[];
    archiveRolesDest?: ArchiveRoleId[];
    label?: string;
    icon?: string;
    showInToolbar: boolean;
    subActionItems?: ActionEntry[];
    onClick?: IActionDelegate;
    maxDepth?: number;
    condition?: (archive: Archive) => boolean;
    conditionFile?: (file: SelectionEntry) => boolean;
    conditionFiles?: (file: SelectionEntry[], archive?:Archive) => boolean;
    toBeIndexedCheck?: boolean;
}

export enum SelectionScopes {
    None = 0x1,
    File = 0x2,
    Files = 0x4,
    Folder = 0x8,
    Folders = 0x10,
    FilesAndFolders = 0x20,
    Dashboard = 0x40,
    FileDeleted = 0x80,
    AllSelections = File | Files | Folder | Folders | FilesAndFolders,
    All = None | AllSelections
}

const actions: ActionEntry[] = [
    {
        id: "new-folder",
        scopes: SelectionScopes.None,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'newFolder',
        icon: 'Folder',
        showInToolbar: true,
        maxDepth: 5,
        condition: (archive) => !archive.newUserHandling,        
        onClick: (_, __, dispatch) => dispatch(showCreateFolderModal(true)),
    },
    {
        id: "new-folderProfile",
        scopes: SelectionScopes.None,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'newFolder',
        icon: 'Folder',
        showInToolbar: true,
        maxDepth: 5,
        condition: (archive) => archive.newUserHandling,
        onClick: (_, __, dispatch) => dispatch(showManageProfilePermissionModal({show: true, type: 'NEW'})),
    },
    {
        id: "upload-file",
        scopes: SelectionScopes.None,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'uploadFile',
        icon: 'Upload',
        showInToolbar: true,
        onClick: (_, __, dispatch) => dispatch(showUploadFilesModal(true)),
    },
    {
        id: "open-file",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'open',
        showInToolbar: true,
        subActionItems: [{
            id: "open-file-browser",
            scopes: SelectionScopes.File,
            archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
            archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
            label: 'openInBrowser',
            icon: 'OpenInNewWindow',
            showInToolbar: true,
            onClick: (state, __, dispatch) => {
                Helpers.getPreviewFile(state.archiveContent.selectedItems[0].id);
            }
        }],
    },
    {
        id: "view-tag",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'viewTag',
        icon: 'Tag',
        toBeIndexedCheck: true,
        showInToolbar: true,
        onClick: (_, __, dispatch) => dispatch(showTagPanel({isOpen: true, defaultSection: Section.tag}))
    },
    {
        id: "create-link",
        scopes: SelectionScopes.File | SelectionScopes.Folder,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'createLink',
        icon: 'Link12',
        showInToolbar: true,
        onClick:  (_, __, dispatch) => dispatch(showCopyLinkModal({showModal: true, type: ItemToCopy.DeepLink}))
    },
    {
        id: "translate-document",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Unit],
        label: 'translateDocument',
        icon: 'Translate',
        showInToolbar: true,
        condition: (archive) => archive.isTranslationAllowed,  
        onClick:  (_, __, dispatch) => dispatch(showTranslationModal(true))
    },
    {
        id: "copy-id-document",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'copyDocumentId',
        icon: 'Source',
        showInToolbar: true,
        onClick:  (_, __, dispatch) => dispatch(showCopyLinkModal({showModal: true, type: ItemToCopy.DocumentId}))
    },
    {
        id: "rename",
        scopes: SelectionScopes.File | SelectionScopes.Folder,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect],
        archiveTypes: [ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'rename',
        icon: 'Rename',
        showInToolbar: true,
        onClick: (_, __, dispatch) => dispatch(showRenameModal(true)),
    },
    {
        id: "user-permissions",
        scopes: SelectionScopes.Folder,
        archiveRoles: [ArchiveRoleId.Owner],
        archiveTypes: [ArchiveTypeId.Unit],
        label: 'managePermissions',
        icon: 'PlayerSettings',
        showInToolbar: true,
        condition: (archive) => !archive.newUserHandling,
        onClick: (_, __, dispatch) => dispatch(showManagePermissionModal(true)),
    },
    {
        id: "profile-permissions",
        scopes: SelectionScopes.Folder,
        archiveRoles: [ArchiveRoleId.Owner],
        archiveTypes: [ArchiveTypeId.Unit],
        label: 'managePermissions',
        icon: 'PlayerSettings',
        showInToolbar: true,
        condition: (archive) => archive.newUserHandling,
        onClick: (_, __, dispatch) => dispatch(showManageProfilePermissionModal({show: true, type: 'EDIT'})),
    },
    {
        id: "download-file",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'download',
        icon: 'Download',
        showInToolbar: true,
        onClick: (state, __, dispatch) => {
            const { selectedItems } = state.archiveContent;
            if (selectedItems.length !== 1 || selectedItems[0].isFolder)
                return;

            dispatch(showDeleteModal({show: true, type: 'DOWNLOAD_FILES'}))
        }
    },
    {
        id: "download-files",
        scopes: SelectionScopes.Files | SelectionScopes.Folder | SelectionScopes.Folders | SelectionScopes.FilesAndFolders,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'download',
        icon: 'Download',
        showInToolbar: true,
        onClick: (_, __, dispatch) => dispatch(showDeleteModal({show: true, type: 'DOWNLOAD_FILES'}))
    },
    {
        id: "delete",
        scopes: SelectionScopes.AllSelections,
        archiveRoles: [ArchiveRoleId.Owner],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'delete',
        icon: 'Delete',
        showInToolbar: true,
        condition: (archive) => !archive.isRecycleBinActive,
        onClick: (_, __, dispatch) => dispatch(showDeleteModal({show: true, type: 'DELETE_ITEM', deletedType: DeletedType.ByFiles}))
    },
    {
        id: "delete-recyclebin",
        scopes:  SelectionScopes.FileDeleted,
        archiveRoles: [ArchiveRoleId.Owner],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'delete',
        icon: 'Delete',
        showInToolbar: true,
        onClick: (_, __, dispatch) => dispatch(showDeleteModal({show: true, type: 'DELETE_ITEM', deletedType: DeletedType.ByRecycleBin}))
    },
    {
        id: "movefiles",
        scopes: SelectionScopes.File | SelectionScopes.Files,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor],    
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'move',
        icon: 'movetofolder', 
        showInToolbar: true,
        onClick: (_, __, dispatch) => dispatch(showNavigationModal({
            show: true, 
            roles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor],
            archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit],
            type: "movefiles"}))
    },
    {
        id: "movefolders",
        scopes: SelectionScopes.Folder | SelectionScopes.Folders,
        archiveRoles: [ArchiveRoleId.Owner],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'move',
        icon: 'movetofolder', 
        showInToolbar: true,
        onClick: (_, __, dispatch) => dispatch(showNavigationModal({
            show: true, 
            roles: [ArchiveRoleId.Owner],
            archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit],
            type: "movefolders"}))
    },
    {
        id: "copy",
        scopes: SelectionScopes.Folder | SelectionScopes.Folders | SelectionScopes.FilesAndFolders,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'copy',
        icon: 'Copy',
        showInToolbar: true,
        onClick: (_, __, dispatch) => dispatch(showNavigationModal({
            show: true, 
            roles :[ArchiveRoleId.Owner, ArchiveRoleId.Architect],
            archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit],
            type: "copy"}))
    }, 
    {
        id: "copyfiles",
        scopes: SelectionScopes.File | SelectionScopes.Files,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'copy',
        icon: 'Copy',
        showInToolbar: true,
        onClick: (_, __, dispatch) => dispatch(showNavigationModal({
            show: true, 
            roles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor], 
            archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit],
            type: "copyfiles"}))
    },
    {
        id: "view-wf-details",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'viewWfDetails',
        icon: 'view',
        showInToolbar: true,
        conditionFile: (file) => {return file.sourceType === FileSourceType.EWE},
        onClick: (_, __, dispatch) => {dispatch(showWorkflowDetailsModal(true))}
    },
    {
        id: "download-wf-details",
        scopes: SelectionScopes.File,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional, ArchiveRoleId.Uploader, ArchiveRoleId.Reader],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'downloadWfDetails',
        icon: 'Download',
        showInToolbar: true,
        conditionFile: (file) => {return file.sourceType === FileSourceType.EWE},
        onClick: (_, __, dispatch) => {dispatch(showDownloadWfDetailsModal(true))}
    },
    {
        id: "move-to-recyclebin",
        scopes: SelectionScopes.File  | SelectionScopes.Files,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'moveToRecyclebin',
        icon: 'EmptyRecycleBin',
        toBeIndexedCheck: true,
        showInToolbar: true,
        condition: (archive) => archive.isRecycleBinActive,
        conditionFiles: (files) => {return files.every(f => f.indexed === true)},
        onClick: (_, __, dispatch) => dispatch(showDeleteModal({show: true, type: 'MOVE_TO_RECYCLEBIN'}))
    },
    {
        id: "move-to-recyclebin-Professional",
        scopes: SelectionScopes.File | SelectionScopes.Files,
        archiveRoles: [ArchiveRoleId.Professional],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'moveToRecyclebin',
        icon: 'EmptyRecycleBin',
        toBeIndexedCheck: true,
        showInToolbar: true,
        condition: (archive) => archive.isRecycleBinActive,
        conditionFiles: (files, archive) => {
            return files.every(f => 
                f.indexed === true &&
                (
                    archive!.filesApproval === ArchiveStatusFilesApproval.Disabled 
                    ||
                    (archive!.filesApproval === ArchiveStatusFilesApproval.Enabled && !f.alreadyApproved)
                )
            )
        },
        onClick: (_, __, dispatch) => dispatch(showDeleteModal({show: true, type: 'MOVE_TO_RECYCLEBIN'}))
    },
    {
        id: "approve",
        scopes: SelectionScopes.File | SelectionScopes.Files,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'approve',
        icon: 'DocumentApproval',
        toBeIndexedCheck: true,
        showInToolbar: true,
        condition: (archive) => {return archive.filesApproval === ArchiveStatusFilesApproval.Enabled},
        conditionFile: (file) => {return file.approvalStatus === FileApprovalStatus.ToBeApproved},
        conditionFiles: (files) => {return files.every(f => f.approvalStatus === FileApprovalStatus.ToBeApproved && f.indexed === true)},
        onClick: (_, __, dispatch) => {dispatch(showFilesApprovalDocumentModal({show: true, updateStatus: FileApprovalStatus.Approved}))}
    },
    {
        id: "reject",
        scopes: SelectionScopes.File | SelectionScopes.Files,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'reject',
        icon: 'PageRemove',
        toBeIndexedCheck: true,
        showInToolbar: true,
        condition: (archive) => {return archive.filesApproval === ArchiveStatusFilesApproval.Enabled},
        conditionFile: (file) => {return file.approvalStatus === FileApprovalStatus.ToBeApproved},
        conditionFiles: (files) => {return files.every(f => f.approvalStatus === FileApprovalStatus.ToBeApproved && f.indexed === true)},
        onClick: (_, __, dispatch) => {dispatch(showFilesApprovalDocumentModal({show: true, updateStatus: FileApprovalStatus.Rejected}))}
    },
    {
        id: "resubmit-approval",
        scopes: SelectionScopes.File | SelectionScopes.Files,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'resubmitApproval',
        icon: 'PageArrowRight',
        toBeIndexedCheck: true,
        showInToolbar: true,
        condition: (archive) => {return archive.filesApproval === ArchiveStatusFilesApproval.Enabled},
        conditionFile: (file) => {return file.approvalStatus !== FileApprovalStatus.ToBeApproved},
        conditionFiles: (files) => {return files.every(f => f.approvalStatus !== FileApprovalStatus.ToBeApproved && f.approvalStatus === files[0].approvalStatus && f.indexed === true)},
        onClick: (_, __, dispatch) => {dispatch(showFilesApprovalDocumentModal({show: true, updateStatus: FileApprovalStatus.ToBeApproved}))}
    },
    {
        id: "restore",
        scopes: SelectionScopes.FileDeleted,
        archiveRoles: [ArchiveRoleId.Owner, ArchiveRoleId.Architect, ArchiveRoleId.Contributor, ArchiveRoleId.Professional],
        archiveTypes: [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity],
        label: 'restore',
        icon: 'Rotate90CounterClockwise',
        showInToolbar: true,
        condition: (archive) => archive.isRecycleBinActive,
        onClick: (_, __, dispatch) => dispatch(showDeleteModal({show: true, type: 'RESTORE_FROM_RECYCLEBIN'}))
    }
];

const normalizedActions = Helpers.normalize(actions, action => action.id);

export const getArchiveContentActionIds = (scopes: SelectionScopes
    , archive?: Archive
    , selectedItems?: SelectionEntry[]
    , archiveRoleId?: ArchiveRoleId
    , navigationDepth = 0) =>
{
    if (!archive || archiveRoleId === undefined)
        return [];

    const currentUserRoleId = 
        !selectedItems || selectedItems?.length === 0
            ? archiveRoleId 
            : Helpers.retrieveMinArchiveRoleId(selectedItems);

    return actions.filter(action => (action.scopes & scopes) !== 0
        && action.archiveRoles.includes(currentUserRoleId)
        && action.archiveTypes.includes(archive.typeId)
        && (action.maxDepth === undefined || action.maxDepth > navigationDepth)
        && (action.condition === undefined || action.condition(archive) === true)
        && (action.conditionFile === undefined || action.conditionFile(selectedItems![0]) === true)
        && (action.conditionFiles === undefined || action.conditionFiles(selectedItems!, archive) === true)
    ).map(action => action.id);
}

export const getArchiveContentActionsByIds = (actionIds: string[]) => {
    return actionIds.map(id => normalizedActions[id]);
}

export const archiveContentActions = actions;