import { IStyleFunction } from "@fluentui/react"
import { IMultiStepModalPropsStyles, IMultiStepModalStyles } from "../../../../common/components/multiStepModal/multiStepModal.types"
import { ICreatePortfolioModalPropsStyles, ICreatePortfolioModalStyles } from "./createPortfolioModal.types"

export const CreatePortfolioModalGlobalClassNames = {
    root: 'edi-create-portfolio-modal-root'
}

export const getStyles: IStyleFunction<ICreatePortfolioModalPropsStyles, ICreatePortfolioModalStyles> = (props: ICreatePortfolioModalPropsStyles): ICreatePortfolioModalStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        buttonContainer: {
            width:'100%',
            display: 'flex',
            justifyContent:'flex-end'
        },
        subComponentStyles: {
            ediModal: (props: IMultiStepModalPropsStyles): IMultiStepModalStyles => {
                return {
                    body: {},
                    bodyContent: {},
                    title: {},
                    closeIcon: {},
                    container: {},
                    content: {},
                    footer: {
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    },
                    header: {},
                    subComponentStyles: {
                        iconButton: {}
                    },
                    subTitle: {}
                }
            }
        },
        requiredTextLeft:{
            display:'flex', 
            alignItems:'center',
            gap: 5,
            justifyContent: "flex-start",
            bottom: 0,
            left:40,
            color: palette.redDark,
            background: palette.white,
            width: '100%'
        }
    }
}