import React, { classNamesFunction, IDropdownOption } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { FormItemType } from "../../../../../utilities/constants";
import { PhaseStatus } from "../../../../models/constants";
import FormItem from "../formItem/formItem";
import { PhaseFieldForm } from "../formItem/formItem.base";
import { IPhaseFormItemProps, IPhaseFormItemPropsStyles, IPhaseFormItemStyles } from "./phaseFormItem.types";

const getClassNames = classNamesFunction<IPhaseFormItemPropsStyles, IPhaseFormItemStyles>();

export const PhaseFormItemBase = (props: IPhaseFormItemProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const { t } = useTranslation(["infoAndStatus", "activityPhaseStatus", "common"])

  const statusOptions: IDropdownOption[] = [
    { key: PhaseStatus.NotStarted, text: t('activityPhaseStatus:notStarted') },
    { key: PhaseStatus.InProgress, text: t('activityPhaseStatus:inProgress') },
    { key: PhaseStatus.Completed, text: t('activityPhaseStatus:done') }]

  return (
    <div className={classNames.phaseContainer}>
      <div className={classNames.upperSection}>
        <FormItem className={classNames.upperSectionItem} label={t('phaseLabel')} type={FormItemType.Text} defaultValue={props.phase.name} disabled />
        <FormItem phase={props.phase} fieldForm={PhaseFieldForm.StartDate} disabled={true} className={classNames.upperSectionItem} defaultData={new Date(props.phase.startDate)} label={t('startDateLabel')} type={FormItemType.DatePicker} placeHolder={t('startDatePlaceholder')} />
        <FormItem phase={props.phase} fieldForm={PhaseFieldForm.DueDate} disabled={true} className={classNames.upperSectionItem} defaultData={new Date(props.phase.dueDate)} label={t('dueDateLabel')} type={FormItemType.DatePicker} placeHolder={t('dueDatePlaceholder')} />
        <FormItem phase={props.phase} disabled={true} fieldForm={PhaseFieldForm.Status} className={classNames.upperSectionItem} dropdownSelected={props.phase.status} label={t('activityStatusLabel')} type={FormItemType.Dropdown} options={statusOptions} placeHolder={t('activityStatusPlaceholder')} />
      </div>
      <div className={classNames.lowerSection}>
        <FormItem phase={props.phase} fieldForm={PhaseFieldForm.Description} disabled={true} className={classNames.descriptionContainer} label={t('descriptionLabel')} type={FormItemType.MultiText} defaultValue={props.phase.description} placeHolder={t('descriptionPlaceholder')} />
      </div>
    </div>
  );
}