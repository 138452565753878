import { styled } from "@fluentui/react";
import { FormItemBase } from "./formItem.base";
import { getStyles } from "./formItem.styles";
import { IFormItemProps, IFormItemPropsStyles, IFormItemStyles } from "./formItem.types";


const FormItem = styled<IFormItemProps, IFormItemPropsStyles, IFormItemStyles>(
    FormItemBase,
    getStyles,
    undefined,
    { scope: 'FormItemBase' }
);

export default FormItem;