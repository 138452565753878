/*eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/cognitive-complexity */
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import React, { classNamesFunction, IDropdownOption, IPersonaSharedProps, Persona, PrimaryButton, Stack, StackItem, TooltipHost } from "@fluentui/react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "../../../../common/components/accordion/accordion";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { FormItemType } from "../../../../utilities/constants";
import { Helpers } from "../../../../utilities/helpers";
import { useOnMount } from "../../../../utilities/hooks";
import { Activity } from "../../../models/activity";
import { ActivityPriority, ActivityStatus } from "../../../models/constants";
import { activityApi } from "../../../services/activity/activity.api";
import FormItem from "../common/formItem/formItem";
import { FieldForm } from "../common/formItem/formItem.base";
import PhaseFormItem from "../common/phaseFormItem/phaseFormItem";
import { IInfoStatusProps, IInfoStatusPropsStyles, IInfoStatusStyles } from "./infoStatus.types";
import * as microsoftTeams from "@microsoft/teams-js";

const getClassNames = classNamesFunction<IInfoStatusPropsStyles, IInfoStatusStyles>();

export const InfoStatusBase = (props: IInfoStatusProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const [activityLeaders, setActivityLeaders] = useState<IPersonaSharedProps[]>([]);
  const [typeOptionsDropDown, setTypeOptionsDropDown] = useState<IDropdownOption[]>([]);
  const [activity, setActivity] = useState<Activity | undefined>(undefined)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation(["infoAndStatus", "common"]);
  const translationKey = "status";


  const priorityOptionsDropDown: IDropdownOption[] = [
    { key: ActivityPriority.P1, text: t('low') },
    { key: ActivityPriority.P2, text: t('medium') },
    { key: ActivityPriority.P3, text: t('high') }
  ]

  const statusOptionsDropDown: IDropdownOption[] = [
    { key: ActivityStatus.Blocked, text: t(translationKey + "." + ActivityStatus.Blocked) },
    { key: ActivityStatus.Closed, text: t(translationKey + "." + ActivityStatus.Closed) },
    { key: ActivityStatus.OnGoing, text: t(translationKey + "." + ActivityStatus.OnGoing) },
    { key: ActivityStatus.Planned, text: t(translationKey + "." + ActivityStatus.Planned) },
  ]

  const currencyOptions: IDropdownOption[] = [
    { key: "EUR", text: "€" },
    { key: "USD", text: "$" }
  ]

  useOnMount(() => {
    fetchData();
  })


  const fetchData = async () => {

    setLoading(true);
    setError(false)
    try {
      const result = await activityApi.getActivityById(props.activityId);
      const types = await activityApi.getUserTemplates(result.portfolioId);
      const res: IDropdownOption[] = [];
      const valid: boolean = result.templateActivity !== null && types.findIndex(element => element.id === result.templateActivity.id) >= 0;
      types.forEach(type => {
        type.active && !type.deleted &&
          res.push({
            key: type.id,
            text: type.name
          })
      });
      if (!valid && result.templateActivity !== null) {
        res.push({
          key: result.templateActivity.id,
          text: result.templateActivity.name
        });
      }
      setTypeOptionsDropDown([...res]);
      const tempPersona: IPersonaSharedProps[] = [];
      result.leaders?.forEach(item => {
        const temp = Helpers.mapStringToPersona(item);
        temp && tempPersona.push(temp)
      });
      setActivityLeaders(tempPersona);
      setActivity(result)
    }
    catch (error: any) {
      console.log("error: ", error.message)
      setError(true)
    }
    finally {
      setLoading(false);
    }
  }

  const titlePhases = (<div className={classNames.phasesTitle}>{t('phases')}</div>)

  const onRenderPersonaDetails = () => {
    return <div className={classNames.leadersContainer}>
      <label>Project Leaders</label>
      <div className={classNames.personaFormSection}>
        {activityLeaders.map((leader, ind) => {
          return <Persona
            {...leader}
            key={ind}
            hidePersonaDetails
            onRenderInitials={(props) => <TooltipHost content={props?.text}><div style={{ cursor: 'pointer' }}>{props?.imageInitials}</div></TooltipHost>}
            coinSize={35}
          />
        })}
      </div>
    </div>
  }


  const loadPhases = useCallback(() => {
    if (!activity)
      return
    if (activity.phases.length === 0)
      return <div style={{ display: 'flex', minHeight: 100, justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>{t('noPhases')}</div>
    return activity.phases.map((item, index) => {
      return <PhaseFormItem
        readonly={true}
        phase={item} key={item.name + "_" + index} />
    })
  }, [t, activity])

  const body = () => {

    return loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}><TeamsSpinner /></div> : error ? <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'enter' }}>{t('common:genericErrorApi')}</div> : <div className={classNames.root}>
      <>
        <div className={classNames.title}>
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: '20', }}>
            <StackItem style={{ float: 'right' }} align="center"><PrimaryButton text={t('syncUsers')} disabled={!activity || activity.teamsAppUrl === null || activity.teamsAppUrl === ""} onClick={() => microsoftTeams.executeDeepLink(activity?.teamsAppUrl || "")} /></StackItem>
          </Stack>

        </div>


        <Accordion opened={true} title={t('tabInfo')}>
          <div className={classNames.accordionContainer}>
            <div className={classNames.formSection}>
              <FormItem disabled={true} fieldForm={FieldForm.Description} className={classNames.leftSectionForm} required label={t('descriptionLabel')} type={FormItemType.MultiText} defaultValue={activity?.description} placeHolder={t('descriptionPlaceholder')} />
              <div className={classNames.rightSectionForm}>
                <FormItem showWarning fieldForm={FieldForm.ActivityType} disabled={true} label={t('typeLabel')} type={FormItemType.Dropdown} options={typeOptionsDropDown} dropdownSelected={activity?.templateActivity && activity.templateActivity.id} placeHolder={t('typePlaceHolder')} />
                <FormItem fieldForm={FieldForm.ActivityPiority} disabled={true} label={t('priorityLabel')} type={FormItemType.Dropdown} options={priorityOptionsDropDown} dropdownSelected={activity?.priority} placeHolder={t('priorityPlaceHolder')} />
              </div>
            </div>

            <div className={classNames.formSection} style={{ marginTop: 10 }}>
              <div className={classNames.leftThreeAlignment}>
                <FormItem fieldForm={FieldForm.ActivityStartDate} disabled={true} className={classNames.singleItemThreeAlignment} defaultData={new Date(activity?.startDate || new Date())} label={t('startDateLabel')} type={FormItemType.DatePicker} placeHolder={t('startDatePlaceholder')} />
                <FormItem fieldForm={FieldForm.ActivityDueDate} disabled={true} className={classNames.singleItemThreeAlignment} defaultData={new Date(activity?.dueDate || new Date())} label={t('dueDateLabel')} type={FormItemType.DatePicker} placeHolder={t('dueDatePlaceholder')} />
                <FormItem fieldForm={FieldForm.ActivityBudget} disabled={true} className={classNames.singleItemThreeAlignment} dropdownSelected={activity?.currency} defaultValue={activity?.budget.toString()} label="Budget" type={FormItemType.TextCurrency} options={currencyOptions} placeHolder={t('budgetPlaceholder')} />
              </div>
            </div>
            {onRenderPersonaDetails()}
          </div>
        </Accordion>

        <Accordion opened={true} title={"Status"}>
          <div className={classNames.accordionContainer}>
            <div className={classNames.formSection}>
              <FormItem fieldForm={FieldForm.ActivityStatus} disabled={true} alignHorizontal label={t('activityStatusLabel')} type={FormItemType.Dropdown} options={statusOptionsDropDown} dropdownSelected={activity?.status} placeHolder={t('activityStatusPlaceholder')} />
            </div>
            <div className={classNames.phaseTitleContainer}>
              {titlePhases}
            </div>
            {loadPhases()}
          </div>
        </Accordion>
      </>
    </div>
  }
  return (
    <EdiModal
      height={700}
      width={1000}
      body={body()}
      title={activity?.name}
      isOpen={true}
      showCloseIcon={true}
      onCloseClick={props.closeModal}
    />
  );
}