import React from "react";
import { SelectionEntryItemSearch, addSelectedItem, removeSelectedItem, useSearchContent } from "../../../features/searchContent";
import { IContextualMenuProps, IconButton, classNamesFunction } from "@fluentui/react";
import { useDocLabDispatch } from "../../../docLabStore";
import { ContextualMenuCustom } from "./contextualMenuCustom";
import { ISearchItemHeaderProps, ISearchItemHeaderPropsStyles, ISearchItemHeaderStyles } from "./searchItemHeader.types";

const getClassNames = classNamesFunction<ISearchItemHeaderPropsStyles, ISearchItemHeaderStyles>();

export const SearchItemHeaderBase = (props: ISearchItemHeaderProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { searchResult, selectionEntryList } = useSearchContent();
    const dispatch = useDocLabDispatch();
    const emptyMenuProps: IContextualMenuProps = { items: [] };
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    const updateElementSelectionList = (selectedFile: SelectionEntryItemSearch) => {
        if (!selectionEntryList.some(x => x.id === selectedFile.id)) {
            dispatch(addSelectedItem(selectedFile));
            props.changedSelectedAll(selectionEntryList.length + 1 === searchResult?.values.length);
        }
        else {
            dispatch(removeSelectedItem(selectedFile));
            props.changedSelectedAll(false);
        }
    }

    const onSelect = () => {
        if(selectionEntryList.length >= 1){
            if(!selectionEntryList.some(s => s.id === props.selectedFile.id)){
                selectionEntryList.forEach((file) => {
                    dispatch(removeSelectedItem(file));
                })

                dispatch(addSelectedItem(props.selectedFile));
            }
        }else{
            if(!selectionEntryList.some(s => s.id === props.selectedFile.id)){
                dispatch(addSelectedItem(props.selectedFile));
            }
        }
    }

    return (
        <div>
            <IconButton
                key={selectionEntryList.length}
                iconProps={{
                    iconName: selectionEntryList.some(x => x.id === props.selectedFile.id) ? 'SkypeCircleCheck' : 'CircleRing'
                }}
                title="Seleziona"
                ariaLabel="Seleziona"
                onClick={() => updateElementSelectionList(props.selectedFile)}
            />
            {props.selectedFile.name !== null && <b style={{ color: palette.black}}>{props.selectedFile.name}</b>}
            <IconButton
                onMenuClick={() => {onSelect()}}
                styles={classNames.subComponentStyles.iconButton()}
                iconProps={{ iconName: 'More' }}
                menuProps={emptyMenuProps}
                menuAs={props => ContextualMenuCustom(props)}
            />
        </div>
    );
}