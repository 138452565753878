import { IStyleFunction, keyframes } from "@fluentui/react"
import { ISetPropertiesPropsStyles, ISetPropertiesStyles } from "./setProperties.types"

export const SetPropertiesGlobalClassNames = {
    root: 'edi-set-properties-root',
    formItem: 'edi-set-properties-formItem',
    dropdownItem: 'edi-set-properties-dropdownItem',
    fileContainer: 'edi-set-properties-fileContainer',
    errorLoadingContainer: 'edi-set-properties-errorLoadingContainer'
}

export const getStyles: IStyleFunction<ISetPropertiesPropsStyles, ISetPropertiesStyles> = (props: ISetPropertiesPropsStyles): ISetPropertiesStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            SetPropertiesGlobalClassNames.root,
            {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 20,
                alignItems: 'center'
            }
        ],
        formItem: [SetPropertiesGlobalClassNames.formItem, {
            marginBottom: 20
        }],
        dropdownItem: [SetPropertiesGlobalClassNames.dropdownItem, {
            width: '50%'
        }],
        errorLoadingContainer: [SetPropertiesGlobalClassNames.errorLoadingContainer, {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }],
        fileContainer: [SetPropertiesGlobalClassNames.fileContainer, {
            overflow: 'hidden',
            width: '50%',
            marginTop: 20,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }],
        subComponentStyles: {
            textField: {
                errorMessage: {
                    position: 'absolute'
                },
                suffix: [
                    { background: 'transparent', },
                    props.validationIcon === 'Error' && { color: palette.redDark },
                    props.validationIcon === 'Completed' && { color: palette.greenDark },
                    props.validationIcon === 'ProgressRingDots' && {
                        '> i': {
                            animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite`
                        }
                    }
                ]
            },
            dropdown: {
                root: {
                    padding: '5px 0',
                },
                label: {
                    color: palette.black,
                    fontWeight: 400
                },
                title: {},
                caretDownWrapper: {},
                caretDown: {},
                errorMessage: {},
                dropdownItemsWrapper: {},
                dropdownItems: {},
                dropdownItem: {},
                dropdownItemSelected: {},
                dropdownItemDisabled: {},
                dropdownItemSelectedAndDisabled: {},
                dropdownItemHidden: {},
                dropdownOptionText: {},
                dropdownDivider: {},
                dropdownItemHeader: {},
                panel: {},
                callout: {
                    '*::-webkit-scrollbar': {
                        width: 16,
                        height: 16
                    },
                    '*::-webkit-scrollbar-thumb': {
                        border: '5px solid transparent',
                        backgroundClip: 'padding-box',
                        backgroundColor: 'transparent',
                        borderRadius: 25
                    },
                    '*:hover::-webkit-scrollbar-thumb': {
                        backgroundColor: palette.neutralSecondary// 'rgba(66, 66, 66, 0.3)'
                    },
                    '*::-webkit-scrollbar-thumb:hover': {
                        border: '2px solid transparent'
                    }
                },
                subComponentStyles: {
                    label: {},
                    multiSelectItem: {},
                    panel: {}
                }
            },
            icon: {
                root: {
                    marginRight: 5,
                    color: palette.green
                }
            }
        }
    };
}