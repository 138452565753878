import { styled } from "@fluentui/react";
import { getStyles } from "./progressBar.styles";
import { IProgressBarPropsStyles, IProgressBarStyles, IProgressBarProps } from "./progressBar.types";
import { ProgressBarBase } from "./progressBar.base";

const ProgressBar = styled<IProgressBarProps, IProgressBarPropsStyles, IProgressBarStyles>(
    ProgressBarBase,
    getStyles,
    undefined,
    { scope: 'ProgressBar' }
);

export default ProgressBar;