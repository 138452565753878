import { styled } from "@fluentui/react";
import { getStyles } from "./tagCloudChart.styles";
import { ITagCloudChartPropsStyles, ITagCloudChartStyles, ITagCloudChartProps } from "./tagCloudChart.types";
import { TagCloudChartBase } from "./tagCloudChart.base";

const TagCloudChart = styled<ITagCloudChartProps, ITagCloudChartPropsStyles, ITagCloudChartStyles>(
    TagCloudChartBase,
    getStyles,
    undefined,
    { scope: 'TagCloudChart' }
);

export default TagCloudChart;