import { IStyleFunction } from "@fluentui/react"
import { IAddFilePropsStyles, IAddFileStyles } from "./addFile.types"

export const AddFileGlobalClassNames = {
    root: 'edi-add-file-root'
}

export const getStyles: IStyleFunction<IAddFilePropsStyles, IAddFileStyles> = (props: IAddFilePropsStyles): IAddFileStyles => {
    return {
        root: [
            AddFileGlobalClassNames.root,
            {
               // marginBottom: 20
            }
        ]
    };
}