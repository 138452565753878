import { FontSizes, FontWeights, IStyleFunction } from "@fluentui/react"
import { IExportReportSettingsBasePropsStyles, IExportReportSettingsBaseStyles } from "./exportReportSettings.types"

export const exportReportSettingsGlobalClassNames = {
    root: 'edi-export-report-settings-root',
    title: 'edi-export-report-title',
    iconTitle: 'edi-export-report-icon-title'
}

export const getStyles: IStyleFunction<IExportReportSettingsBasePropsStyles, IExportReportSettingsBaseStyles> = (props: IExportReportSettingsBasePropsStyles): IExportReportSettingsBaseStyles => {
    return {
        root: [
            exportReportSettingsGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px 0px 25px'
            }
        ],
        iconTitle: [
            exportReportSettingsGlobalClassNames.iconTitle,
            {
                padding: 7,
                border: '1.5px solid',
                borderRadius: '50%',
                fontSize: 26
            }
        ],
        title: [
            exportReportSettingsGlobalClassNames.title,
            {
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingBottom: 10
            }
        ]

    }
}