import { AxiosRequestConfig, CancelToken } from "axios";
import { previewResponse } from "../../docLab/services/archives/archives.contracts";
import { apiClient } from "../../modules/apiClient/apiClient";
import { svcPaths } from "../../modules/apiClient/config";
import { KnowledgeDocumentFilter } from "../components/documents/documents.types";
import { GetDriveContentRequest } from "../components/knowledgeSharepointFileSelector/knowledgeSharepointFileSelector.types";
import { KnowledgeFile } from "../models/file";
import { KnowledgeElement } from "../models/knowledgeElement";
import { KnowledgeLab, KnowledgeLabShort } from "../models/knowledgeLab";
import { SharepointItem } from "../models/sharepointItem";
import { ElementShort } from "../models/element";
import { GetKnowledgeLabAvailableMembersRequest, GetKnowledgeLabMembersRequest } from "./knowledgeLab.contracts";
import { User } from "../../docLab/models/user";
import { KnowledgeLabRoleId } from "../models/knowledgeLabRoleId";
import { KnowledgeLabMembership } from "../models/knowledgeLabMembership";
import qs from "qs";

class KnowledgeLabApiClass {

    async uploadFiles(formData: FormData, cancelToken?: CancelToken) {
        const response = await apiClient
            .post(`${svcPaths.knowledges}/file/${formData.get("Id")}/files`
                , formData
                , { cancelToken: cancelToken });
        return response.data;
    }

    async copyFilesFromBlob(formData: FormData, cancelToken?: CancelToken) {
        const response = await apiClient
            .post(`${svcPaths.knowledges}/file/${formData.get("Id")}/copyfiles`
                , formData
                , { cancelToken: cancelToken });
        return response.data;
    }

    async copyFilesFromSharepoint(formData: FormData, cancelToken?: CancelToken) {
        const response = await apiClient
            .post(`${svcPaths.knowledges}/file/${formData.get("Id")}/copysharepointfiles`
                , formData
                , { cancelToken: cancelToken });
        return response.data;
    }

    async getKnowledgeLabList(cancelToken?: CancelToken) {
        const response = await apiClient.get<KnowledgeLabShort[]>(`${svcPaths.knowledges}/knowledgelabs`, { cancelToken: cancelToken });
        return response.data
    }

    async getKnowledgeLab(id: number, cancelToken?: CancelToken) {

        const response = await apiClient.get<KnowledgeLab>(`${svcPaths.knowledges}/knowledgelabs/${id}`, { cancelToken: cancelToken });
        return response.data
    }

    async getSharepointArchives() {
        const response = await apiClient.get<SharepointItem[]>(`${svcPaths.knowledges}/file/getsharepointsites`);
        if (response.data)
            return response.data;
        else
            return [] as SharepointItem[];
    }

    // async getKnowledgeSettings(labId: number) {
    //     const response = await apiClient.get<KnowledgeLabSetting>(`${svcPaths.knowledges}/knowledgelabs/${labId}/settings`);
    //     return response.data;
    // }

    async getFolderContent(folderRequest: GetDriveContentRequest) {
        const response = await apiClient.get<SharepointItem[]>(`${svcPaths.knowledges}/file/getsharepointdrivefiles/${folderRequest.driveId}/${folderRequest.id === folderRequest.driveId ? 'root' : folderRequest.id}`);
        return response.data;
    }

    async getSharepointSiteContent(siteId: string) {
        const response = await apiClient.get<SharepointItem[]>(`${svcPaths.knowledges}/file/getsharepointsitedrives/${siteId}`);
        if (response.data)
            return response.data;
        else
            return [] as SharepointItem[];
    }

    async getElementDetails(elementId: string, increaseViews = false) {
        const response = await apiClient.get<KnowledgeElement>(`${svcPaths.knowledges}/element/${elementId}${increaseViews ? "?increaseViews=true" : ""}`)
        return response.data;
    }

    async downloadFile(fileId: number) {
        const config: AxiosRequestConfig = {
            responseType: 'arraybuffer'
        }

        const response = await apiClient.get(`${svcPaths.knowledges}/file/${fileId}`, config);
        return response.data;
    }

    async getFilesById(fileIds: number[], cancelToken?: CancelToken) {
        const response = await apiClient
            .post(`${svcPaths.knowledges}/file/getFilesById`
                , { "FileIds": fileIds }
                , { cancelToken: cancelToken });
        return response.data;
    }

    async preview(fileId: number) {
        const response = await apiClient.get<previewResponse>(`${svcPaths.knowledges}/file/${fileId}/preview`);
        return response.data
    }

    async previewFromElement(elementId: number) {
        const response = await apiClient.get<previewResponse>(`${svcPaths.knowledges}/element/${elementId}/preview`);
        return response.data
    }

    async addElementToFavourites(elementId: number) {
        const payload = { id: elementId };
        const response = await apiClient.put(`${svcPaths.knowledges}/element/${elementId}/addtofavourites`, payload);
        return response.data
    }

    async removeElementFromFavourites(elementId: number) {
        const payload = { id: elementId };
        const response = await apiClient.put(`${svcPaths.knowledges}/element/${elementId}/removefromfavourites`, payload);
        return response.data
    }

    async searchFavourites(keyword: string, knowledgeLabId: number) {
        const payload = { keyword: keyword, knowledgeLabId: knowledgeLabId }
        const response = await apiClient
            .post(`${svcPaths.knowledges}/element/searchFavourites`
                , payload);
        return response.data;
    }

    /*eslint-disable @typescript-eslint/no-explicit-any*/
    async updateElementMetadata(id: string, metadata: any, cancelToken?: CancelToken) {
        const response = await apiClient
            .post(`${svcPaths.knowledges}/element`
                , { "id": id, "metadata": metadata }
                , { cancelToken: cancelToken });
        return response.data;
    }

    async updateDocumentMetadata(id: string, metadata: any, cancelToken?: CancelToken) {
        const response = await apiClient
            .post(`${svcPaths.knowledges}/file`
                , { "fileId": id, "metadata": metadata }
                , { cancelToken: cancelToken });
        return response.data;
    }

    async getDocuments(knowledgeLabId: number, filters: KnowledgeDocumentFilter) {
        const response = await apiClient.post<KnowledgeFile[]>(`${svcPaths.knowledges}/file/getfiles`, {
            knowledgeLabId: knowledgeLabId,
            ...filters
        })
        return response.data;
    }

    async getFileElements(fileId: number) {
        const response = await apiClient.get<ElementShort[]>(`${svcPaths.knowledges}/element/file/${fileId}`)
        return response.data;
    }

    async getFileDetails(fileId: number) {
        const response = await apiClient.get<ElementShort[]>(`${svcPaths.knowledges}/file/${fileId}/details`)
        return response.data;
    }

    async getFileMetadata(fileId: number) {
        const response = await apiClient.get<any>(`${svcPaths.knowledges}/file/${fileId}/metadata`)
        return response.data;
    }

    async deleteFile(fileIds: number[]) {
        const response = await apiClient.post(`${svcPaths.knowledges}/file/delete`, {
            fileIds: fileIds
        })
        return response.data;
    }

    async deleteElement(elementId: number) {
        const response = await apiClient.post(`${svcPaths.knowledges}/element/delete`, {
            elementId: elementId
        })
        return response.data;
    }

    async getKnowledgeLabMembers(request: GetKnowledgeLabMembersRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<User[]>(`${svcPaths.knowledges}/knowledgelabs/${request.knowledgeLabId}/members`, {
            params: {
                keyword: request.keyword,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                excludeCurrentUser: false,
            },
            cancelToken: cancelToken
        })
        return response.data;
    }

    async updateMemberRole(knowledgeLabId: number, userId: string, newRole: KnowledgeLabRoleId) {
        const payload = { newRoleId: newRole };
        const response = await apiClient.put(`${svcPaths.knowledges}/knowledgelabs/${knowledgeLabId}/members/${userId}`, payload);
        return response.data
    }

    async deleteMember(knowledgeLabId: number, userId: string) {
        const response = await apiClient.delete(`${svcPaths.knowledges}/knowledgelabs/${knowledgeLabId}/members/${userId}`);
        return response.data
    }

    async addMembers(knowledgeLabId: number, members: KnowledgeLabMembership[]) {
        const payload = { id: knowledgeLabId, members };
        const response = await apiClient.post(`${svcPaths.knowledges}/knowledgelabs/${knowledgeLabId}/members`, payload);
        return response.data
    }

    async getKnowledgeLabAvailableMembers(request: GetKnowledgeLabAvailableMembersRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<User[]>(`${svcPaths.knowledges}/knowledgelabs/${request.knowledgeLabId}/available-members`, {
            params: {
                keyword: request.keyword,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                excludeCurrentUser: true,
                userIds: request.userIds
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken
        });
        return response.data;
    }

}

export const knowledgeLabApi = new KnowledgeLabApiClass();