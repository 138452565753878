import { IStyleFunction } from "@fluentui/react"
import { IQualityCheckResultsPropsStyles, IQualityCheckResultsStyles } from "./qualityCheckResult.types"

export const QualityCheckResultsGlobalClassNames = {
    checkContainer: 'edi-quality-check-results-checkContainer',
    renderCheckContainer: 'edi-quality-check-results-renderCheckContainer',
    resultCheck: 'edi-quality-check-results-resultCheck',
    labelResultChek: 'edi-quality-check-results-labelResultChek',
    renderChildrenNodeContainer: 'edi-quality-check-results-renderChildrenNodeContainer',
    value: 'edi-quality-check-results-value',
}

export const getStyles: IStyleFunction<IQualityCheckResultsPropsStyles, IQualityCheckResultsStyles> = (props: IQualityCheckResultsPropsStyles): IQualityCheckResultsStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        renderChildrenNodeContainer: [
            QualityCheckResultsGlobalClassNames.renderChildrenNodeContainer,
            {
                paddingLeft: '20px'
            }],
        renderCheckContainer: [
            QualityCheckResultsGlobalClassNames.renderCheckContainer,
            {
                display: 'flex',
                flexGrow: 1,
                flexShrink: 1,
                flexWrap: 'wrap',
                minHeight: '60px',
                overflowX: 'hidden',
                overflowY: 'auto',
                justifyContent: 'flex-start'
            }],
        resultCheck: [
            QualityCheckResultsGlobalClassNames.renderCheckContainer,
            {
                padding: '0 20px',
                color: palette.white
            }],
        labelResultChek: [
            QualityCheckResultsGlobalClassNames.labelResultChek,
            {
                padding: '0 20px',
                color: palette.white,
                //borderLeft: '1px solid ' + palette.white
            }],
        value: [
            QualityCheckResultsGlobalClassNames.value,
            {
                display: 'flex',
                padding: '0 10px',
                height: 32,
                alignItems: 'center',
                background: palette.neutralLight
            }],
        checkContainer: [QualityCheckResultsGlobalClassNames.checkContainer,
        {
            height: 32,
            boxShadow: 'rgb(136 136 136) 0px 1px 8px 0px',
            display: 'flex',
            background: palette.themePrimary,
            alignItems: 'center',
            borderRadius: '10px',
            margin: '10px 15px'
        }]
    };
}