import { styled } from "@fluentui/react";
import { getStyles } from "./users.styles";
import { IUsersPropsStyles, IUsersStyles, IUsersProps } from "./users.types";
import { UsersBase } from "./users.base";

const Users = styled<IUsersProps, IUsersPropsStyles, IUsersStyles>(
    UsersBase,
    getStyles,
    undefined,
    { scope: 'Users' }
);

export default Users;