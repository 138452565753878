import { IStyleFunction } from "@fluentui/react"
import { IAuthorizeRolePropsStyles, IAuthorizeRoleStyles } from "./authorizeRole.types"

export const AuthorizeRoleGlobalClassNames = {
    root: 'edi-authorize-role-root'
}

export const getStyles: IStyleFunction<IAuthorizeRolePropsStyles, IAuthorizeRoleStyles> = (props: IAuthorizeRolePropsStyles): IAuthorizeRoleStyles => {
    return {
        root: [
            AuthorizeRoleGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}