import { styled } from "@fluentui/react";
import { IconButtonSelectedAllBase } from "./iconButtonSelectedAll.base";
import { getStyles } from "./iconButtonSelectedAll.styles";
import { IIconButtonSelectedAllProps, IIconButtonSelectedAllPropsStyles, IIconButtonSelectedAllStyles } from "./iconButtonSelectedAll.types";

const IconButtonSelectedAll = styled<IIconButtonSelectedAllProps, IIconButtonSelectedAllPropsStyles, IIconButtonSelectedAllStyles>(
    IconButtonSelectedAllBase,
    getStyles,
    undefined,
    { scope: 'IconButtonSelectedAll' }
);

export default IconButtonSelectedAll;