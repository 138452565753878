import { FontSizes, FontWeights, IStyleFunction } from "@fluentui/react"
import { IProfileManagementBasePropsStyles, IProfileManagementBaseStyles } from "./profileManagement.types";


export const profileManagementGlobalClassNames = {
    root: 'edi-profile-management-root',
    title: 'edi-profile-management-title',
    reportDownload: 'edi-profile-management-report-download'
}

export const getStyles: IStyleFunction<IProfileManagementBasePropsStyles, IProfileManagementBaseStyles> = (props: IProfileManagementBasePropsStyles): IProfileManagementBaseStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    
    return {
        root: [
            profileManagementGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px'
            }
        ],
        title: [
            profileManagementGlobalClassNames.title,
            {
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingBottom: 10,
                paddingTop: 10
            }
        ],
        reportDownload: [
            profileManagementGlobalClassNames.reportDownload,
            {
                alignSelf:'flex-end',
                justifySelf:'right',
                fontSize: '12px',
                color: palette.themePrimary,
                display: 'flex',
                cursor: 'pointer'
            }
        ]
    }
}