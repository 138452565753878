import React, { useState } from "react";

import Dataset from "../../datasets/datasets";
import DatasetFilters from "../../datasets/datasetFilters/datasetFilters";
import Feedback from "../../feedback/feedback";
import FeedbackFilters from "../../feedback/feedbackFilters/feedbackFilters";
import DashboardToolbar from "../toolbar/toolbar";
import Page from "../../../../common/components/page/Page";
import Users from "../../users/users";

import { IRootProps, IRootPropsStyles, IRootStyles } from "./root.types";
import { Route } from "react-router-dom";
import { classNamesFunction, Stack } from "@fluentui/react";
import { dashboardRoutes, dashboardTab } from "../../../routes/routes";
import { loadUserDetails } from "../../../features/feedbackList";
import { useDashboardDispatch } from "../../../dashboardStore";
import { useNavigator } from "../../../hooks/useNavigator";
import { useOnMount } from "../../../../utilities/hooks";


const getClassNames = classNamesFunction<IRootPropsStyles, IRootStyles>();

const collapseStyle = {
    flexBasis: 0,
    opacity: 0,
}

export const RootBase = (props: IRootProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    const dispatch = useDashboardDispatch();
    const navigator = useNavigator();

    const currentTab = navigator.currentTab;

    useOnMount(() => {
        dispatch(loadUserDetails());
        navigator.changeTab(currentTab)
    })

    return (
        <>
            <Stack className={classNames.root} horizontal verticalAlign={"stretch"}>

                <Stack.Item className={classNames.leftNav} disableShrink style={sidebarCollapsed ? collapseStyle : {}}>
                    {/*filters*/}
                    <Route path={`${dashboardRoutes.dashboardBase}/${dashboardTab.feedback}`}>
                        <FeedbackFilters />
                    </Route>
                    <Route path={`${dashboardRoutes.dashboardBase}/${dashboardTab.datasets}`}>
                        <DatasetFilters />
                    </Route>
                </Stack.Item>

                <Stack.Item className={classNames.rightContent} grow>

                    <Stack.Item>
                        <Stack.Item>
                            <DashboardToolbar onMenuButtonClick={() => setSidebarCollapsed(!sidebarCollapsed)}></DashboardToolbar>
                        </Stack.Item>

                    </Stack.Item>

                    <Stack.Item style={{ height: '100%' }}>
                        <Route path={`${dashboardRoutes.dashboardBase}/${dashboardTab.feedback}`}>
                            <Page title="Dashboard retraining - Feedback" content={Feedback} />
                        </Route>
                        <Route path={`${dashboardRoutes.dashboardBase}/${dashboardTab.datasets}`}>
                            <Page title="Dashboard retraining - Datasets" content={Dataset} />
                        </Route>
                        <Route path={`${dashboardRoutes.dashboardBase}/${dashboardTab.users}`}>
                            <Page title="Dashboard retraining - Users" content={Users} />
                        </Route>
                    </Stack.Item>

                </Stack.Item>
            </Stack>
        </>
    );
}