import { IStyleFunction } from "@fluentui/react"
import { IExportModalManagementReportBasePropsStyles, IExportModalManagementReportBaseStyles } from "./ExportModalManagementReport.types"

export const ExportModalManagementReportGlobalClassNames = {
    root: 'edi-export-report-settings-root',
    secondFooterContainer: 'edi-export-scadenza-second-footer-container',
    exportingFooter: 'edi-export-scadenza-exporting-footer-container',
    sandglassImage: 'edi-export-scadenza-sandglass-image',
    centerContentContainer: 'edi-export-center-content-container',
    buttonContainer: 'edi-export-button-container',
    buttonFooter: 'edi-export-button-footer'
}

export const getStyles: IStyleFunction<IExportModalManagementReportBasePropsStyles, IExportModalManagementReportBaseStyles> = (props: IExportModalManagementReportBasePropsStyles): IExportModalManagementReportBaseStyles => {

    return {
        root: [
            ExportModalManagementReportGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px'
            }
        ],
        centerContentContainer: [
            ExportModalManagementReportGlobalClassNames.centerContentContainer,
            {
                display: 'flex',
                alignItems: 'center',
                height: '100%'
            }
        ],
        exportingFooter: [
            ExportModalManagementReportGlobalClassNames.exportingFooter,
            {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center'
            }
        ],
        sandglassImage: [
            ExportModalManagementReportGlobalClassNames.sandglassImage,
            {
                flexBasis: '50%',
                flexShrink: 0
            }
        ],
        secondFooterContainer: [
            ExportModalManagementReportGlobalClassNames.secondFooterContainer,
            {
                display: 'flex',
                alignItems: 'center',
                height: '100%'
            }
        ],
        buttonContainer: [
            ExportModalManagementReportGlobalClassNames.buttonContainer,
            {
                display: 'flex',
                flexDirection: 'initial',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '5px'
            }
        ],
        buttonFooter:[
            ExportModalManagementReportGlobalClassNames.buttonFooter,
            {
                display:"flex",
                flexDirection:"row", 
                alignItems:"center"
            }
        ]
    }
}