import { styled } from "@fluentui/react";
import { getStyles } from "./dashboardContainer.styles";
import { IDashboardContainerPropsStyles, IDashboardContainerStyles, IDashboardContainerProps } from "./dashboardContainer.types";
import { DashboardContainerBase } from "./dashboardContainer.base";

const DashboardContainer = styled<IDashboardContainerProps, IDashboardContainerPropsStyles, IDashboardContainerStyles>(
    DashboardContainerBase,
    getStyles,
    undefined,
    { scope: 'DashboardContainer' }
);

export default DashboardContainer;