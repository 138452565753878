import React, { useState } from "react";
import { classNamesFunction, Stack } from "@fluentui/react";
import { IKnowledgeSharepointFileSelectorPropsStyles, IKnowledgeSharepointFileSelectorStyles, IKnowledgeSharepointFileSelectorProps, SharepointItemType, SharepointNavItem } from "./knowledgeSharepointFileSelector.types";
import { knowledgeLabApi } from "../../services/knowledgeLab.api";
import { useOnMount } from "../../../utilities/hooks";
import { NavigableItem } from "../../models/navigableFile";
import FileNavigator from "../common/fileNavigator/fileNavigator";
import FileIconCell from "../../../common/components/fileIconCell/fileIconCell";
import { SharepointItem } from "../../models/sharepointItem";
import SharepointSvg from "../../../common/components/teamsImage/images/sharepoint.svg";
import { useTranslation } from "react-i18next";
import { Helpers } from "../../../utilities/helpers";
import { UploadStatus } from "../common/fileUploader/fileUploader.types";


const getClassNames = classNamesFunction<IKnowledgeSharepointFileSelectorPropsStyles, IKnowledgeSharepointFileSelectorStyles>();

export const KnowledgeSharepointFileSelectorBase = (props: IKnowledgeSharepointFileSelectorProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [currentLevel, setCurrentLevel] = useState<SharepointNavItem[]>([]);
    const [currentDrive, setCurrentDrive] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation('importKnowledgeLabDocument')
    useOnMount(() => {
        loadSharepointItems();
    })
    const loadSharepointItems = async () => {
        setIsLoading(true);

        knowledgeLabApi.getSharepointArchives().catch((e) => {
            setIsLoading(false);
        }).then((sharepointArchives) => {
            if (sharepointArchives)
                setCurrentLevel(sharepointArchives.map(a => mapSharepointItemToNavigatorItem(a)));
            setIsLoading(false);
        })
    }

    const getFolderContent = async (folder: NavigableItem, driveId: string) => {
        const sharepointNavItem = folder as SharepointNavItem;
        const id = sharepointNavItem.id;
        if (!id) return;
        setIsLoading(true);
        const request = {
            id: id,
            driveId: driveId,
            orderBy: 'Name',
            isAscending: true,
            pageNumber: 0,
            pageSize: 1000
        }
        const result = await knowledgeLabApi.getFolderContent(request);
        setCurrentLevel(result.map(a => mapToNavigatorItem(folder, a, driveId)));
        setIsLoading(false);
    }

    const getSharepointDriveContent = async (folder: NavigableItem) => {
        setCurrentDrive(folder.id);
        await getFolderContent(folder, folder.id);
    };

    const getSharepointSiteContent = async (folder: NavigableItem) => {
        const sharepointNavItem = folder as SharepointNavItem;
        const id = sharepointNavItem.id;

        if (!id) return;
        setIsLoading(true);
        const result = await knowledgeLabApi.getSharepointSiteContent(id);
        setCurrentLevel(result.map(a => mapToNavigatorItem(folder, a)));
        setIsLoading(false);
    }

    const mapSharepointItemToNavigatorItem = (item: SharepointItem): SharepointNavItem => {
        return {
            id: item.uniqueId,
            name: item.name,
            type: item.isFolder ? SharepointItemType.folder : (item.isSite ? SharepointItemType.sharepointSite : (item.mimeType != null || item.mimeType !== "" ? SharepointItemType.file : SharepointItemType.documentLibrary)),
            createdBy: item.createdBy,
            createdOn: item.createdOn?.toString(),
            extension: !item.isFolder && !item.isSite ? item.name.split('.')[item.name.split('.').length - 1] : '',
            isNavigable: item.isFolder || item.isSite || item.isDrive,
            driveId: currentDrive,
            fileUploadStatus: UploadStatus.ToUpload,
            isValid: true,
        }
    }

    const mapToNavigatorItem = (current: NavigableItem, item: SharepointItem, driveId?: string): SharepointNavItem => {
        return {
            id: item.uniqueId,
            name: item.name,
            type: item.isFolder ? SharepointItemType.folder : (!item.isFolder && !item.isSite && !item.mimeType ? SharepointItemType.drive : SharepointItemType.file),
            createdBy: item.createdBy,
            createdOn: item.createdOn?.toString(),
            extension: !item.isFolder && !item.isSite ? item.name.split('.')[item.name.split('.').length - 1] : '',
            isNavigable: item.isFolder || item.isSite || item.isDrive,
            rootId: undefined,
            parent: current,
            driveId: driveId,
            size: (item.size !== undefined && item.size !== null) ? Helpers.formatSize(item.size) : undefined,
            fileUploadStatus: UploadStatus.ToUpload,
            isValid: true
        }
    }

    const renderIcon = (item: NavigableItem) => {
        const sharepointItem = item as SharepointNavItem;
        if (sharepointItem.type === SharepointItemType.folder || sharepointItem.type === SharepointItemType.drive) return <FileIconCell isFolder={true} fileExtension="" />
        else if (sharepointItem.type === SharepointItemType.sharepointSite)
            //return <Icon iconName="SharepointAppIcon16"/>
            return <img alt={""} src={SharepointSvg} className={classNames.img} />
        else if (sharepointItem.type === SharepointItemType.file) return <FileIconCell isFolder={false} fileExtension={item.extension ? item.extension : ''} />
        return <></>
    }

    const folder = (folder: SharepointNavItem) => {
        return <>{folder.name}</>
    }

    const renderName = (item: NavigableItem) => {
        const ediItem = item as SharepointNavItem;
        if (ediItem.type === SharepointItemType.folder)
            return folder(ediItem)
        else if (ediItem.type === SharepointItemType.sharepointSite)
            return folder(ediItem);
        return <>{item.name}</>
    }

    const onChangeSelection = (items: NavigableItem[]) => {
        const selected = items.map(i => i as SharepointNavItem);
        props.onChange && props.onChange(selected);
    }

    return (
        <div className={classNames.root}>
            <Stack>
                <Stack.Item>
                    <label>{t("steps.sharepointFileSelector.selectMessage")}</label>
                </Stack.Item>
                <Stack.Item>
                    <FileNavigator
                        height={368}
                        defaultSelected={props.defaultSelected}
                        currentLevel={currentLevel}
                        onRenderIcon={renderIcon}
                        onRenderName={renderName}
                        acceptedExtensions={props.allowedFileTypes}
                        onGoingInto={(folder) => {
                            if (!folder) loadSharepointItems();
                            else {
                                const f = folder as SharepointNavItem;
                                (f.type === SharepointItemType.folder) && getFolderContent(f, currentDrive);
                                (f.type === SharepointItemType.sharepointSite) && getSharepointSiteContent(f);
                                (f.type === SharepointItemType.drive) && getSharepointDriveContent(f);
                            }
                        }}
                        isLoading={isLoading}
                        onChangeSelection={onChangeSelection}
                    />
                </Stack.Item>
            </Stack>
        </div>
    );
}