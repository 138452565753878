import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { KnowledgeDsFilter } from "../components/feedback/feedback.types";
import { useKnowledgeDsSelector } from "../knowledgeDsStore";
import { FeedbackStatus, KnowledgeFeedback } from "../models/feedback";

export type FeedbackListState = {
    filters: KnowledgeDsFilter;
    selectedItems: KnowledgeFeedback[];
    noMore: boolean;
    refresh: boolean;
}

const emptyFilters = {
    pageNumber: 0,
    pageSize: 100,
    orderBy: "CreatedOn",
    isAscending: false,
    fileName: undefined,
    skillName: undefined,
    knowledgeName: undefined,
    feedbackStatus: undefined,
    dateFrom: undefined,
    dateTo: undefined
}

const initialState: FeedbackListState = {
    filters: { ...emptyFilters, feedbackStatus: FeedbackStatus.Pending },
    selectedItems: [],
    noMore: false,
    refresh: false,
}

const feedbackListSlice = createSlice({
    name: 'knowledgeDS-feedback',
    initialState,
    reducers: {
        setFilters: (state: FeedbackListState, action: PayloadAction<KnowledgeDsFilter>) => {
            state.filters = action.payload;
        },
        resetFeedbackFilters: (state: FeedbackListState) => {
            state.filters = emptyFilters;
            state.noMore = false;
        },
        setBaseFeedbackFilters: (state: FeedbackListState) => {
            state.filters = { ...emptyFilters, feedbackStatus: FeedbackStatus.Pending };
            state.noMore = false;
        },
        setSelectedItems: (state: FeedbackListState, action: PayloadAction<KnowledgeFeedback[]>) => {
            state.selectedItems = action.payload;
        },
        setNoMore: (state: FeedbackListState, action: PayloadAction<boolean>) => {
            state.noMore = action.payload;
        },
        clearSelectedItems: (state: FeedbackListState) => {
            state.selectedItems = [];
        },
        setRefresh: (state: FeedbackListState, action: PayloadAction<boolean>) => {
            state.refresh = action.payload;
        }

    }
})

export const { setFilters, resetFeedbackFilters, setBaseFeedbackFilters, setSelectedItems, clearSelectedItems, setNoMore, setRefresh } = feedbackListSlice.actions;
export default feedbackListSlice.reducer;

export const useFeedbackList = () => useKnowledgeDsSelector(state => state.feedbackList);