import React, { useEffect, useState } from "react";
import { classNamesFunction, CollapseAllVisibility, ConstrainMode, DetailsList,  DetailsRow, IColumn, Icon, IGroup, IGroupHeaderProps, SelectionMode, Spinner, SpinnerSize } from "@fluentui/react";
import { ITocResultPropsStyles, ITocResultStyles, ITocResultProps } from "./tocResult.types";
import { IResult, ITocAnalyzesData, IToCMatchesData } from "../../../../models/docAnalysis";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { docAnalyzesApi } from "../../../../services/docAnalyzes/docAnalyzes.api";
import { Helpers } from "../../../../../utilities/helpers";
import Results from "../results";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable @typescript-eslint/no-non-null-assertion */
const getClassNames = classNamesFunction<ITocResultPropsStyles, ITocResultStyles>();

export const TocResultBase = (props: ITocResultProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [loading, setLoading] = useState(false);
    const [docs, setDocs] = useState<IResult<ITocAnalyzesData>[]>();
    const [apiInput, setApiInput] = useState<{ Name: string, Description: string }>();
    const [apiResult, setApiResult] = useState<IResult<ITocAnalyzesData>[]>([]);
    const [documentIndex, setDocumentIndex] = useState(0);
    const [apiError, setApiError] = useState<number|undefined>(undefined)
    const location = useLocation();
    const splittedLocation = location.pathname.split('/');
    const id = splittedLocation.pop();

    const { t } = useTranslation(['common', 'analysisResults','docAnalysis']);
    const emptyMessage = `${t('analysisResults:empty')}`;


    useEffect(() => {
        if (!apiResult)
            return;

        const finalResult: IResult<ITocAnalyzesData>[] = [];

        const synthesisResult: IResult<ITocAnalyzesData> = {
            fileId: -1,
            path: '',
            fileName: t('analysisResults:tocResult.summaryAnalysis'),
            data: { matches: [], notMatches: [] }
        }

        finalResult.push(synthesisResult);

        for (let i = 0; i < apiResult.length; i++) {
            finalResult.push({
                fileId: apiResult[i].fileId,
                fileName: apiResult[i].fileName,
                path: apiResult[i].path,
                error: apiResult[i].error,
                warning: apiResult[i].warning,
                data: { matches: apiResult[i].data ? apiResult[i].data.matches : [], notMatches: apiResult[i].data ? apiResult[i].data.notMatches : [] }
            })
        }
        setDocs(finalResult);
    }, [apiResult, t]);

    const getAnalysis = async () => {
        setLoading(true);
        setApiError(undefined);
        try {
            if (id) {
                const [APIresult] = await Promise.all([docAnalyzesApi.getAnalysisById(+id), Helpers.delay(500)]);
                return APIresult;
            }
        }
        catch (error) {
            console.log("ERROR: ", error);
            setApiError(error.code);
        }
        finally {
            setLoading(false);
        }

        return null;
    };

    useEffect(() => {
        (async () => {
            const res = await getAnalysis();
            if (!res)
                return;

            try { setApiResult(JSON.parse(res.result ?? "")); } catch { setApiResult([]); }
            try { setApiInput(JSON.parse(res.input ?? "")); } catch { setApiInput({ Name: "", Description: "" }); }
        })()
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const renderDocumentAnalysis = () => {
        if (loading)
            return;
        if (apiError === 403)
            return <div><TeamsImage
            styles={{
                img: {
                    width: '25%'
                }
            }}
            imageName={ImageName.Forbidden}
            caption={t("docAnalysis:forbidden")}
        /></div>
        if (apiError !== undefined)
            return <div>{t('genericErrorApi')}</div>
        if (docs && docs[documentIndex] && docs[documentIndex].error)
            return <div>{docs[documentIndex].error}</div>
        if (docs && docs[documentIndex] && docs[documentIndex].fileId === -1)
            return <div>{renderSynthesis()}</div>
        return docs && docs[documentIndex] && renderTocAnalysisResult()
    }

    const columns: IColumn[] = [
        {
            key: 'name',
            name: '',
            ariaLabel: 'name',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 300,
            headerClassName: classNames.headerRow,
            onRender: function getItemName(item: IResult<ITocAnalyzesData>) {
                return <div
                    style={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                    }}>
                    {item.fileName}
                </div>
            }
        },
        {
            key: 'ok',
            name: "",
            ariaLabel: 'ok',
            fieldName: 'ok',
            minWidth: 100,
            maxWidth: 100,
            headerClassName: classNames.headerRow,
            styles: {
                cellTitle: {
                    justifyContent: 'center !important'
                }
            },
            onRenderHeader: function okHeader() {
                return <div style={{ textAlign: 'center' }}>
                    <Icon
                        styles={classNames.subComponentStyles.icon({ ok: true, isImage: false, isPlaceholder: false })}
                        iconName="SkypeCircleCheck"
                    /></div>
            },
            onRender: function getItemType(item: IResult<ITocAnalyzesData>) {
                const successResult = item.data.matches.filter(success => success.result.toLocaleLowerCase() === "ok");
                return <div>{successResult.length} OK</div>
            }
        },
        {
            key: 'ko',
            name: "",
            ariaLabel: 'ko',
            fieldName: 'ko',
            minWidth: 100,
            maxWidth: 100,
            headerClassName: classNames.headerRow,
            styles: {
                cellTitle: {
                    justifyContent: 'center'
                }
            },
            onRenderHeader: function koHeader() {
                return <div> <Icon
                    styles={classNames.subComponentStyles.icon({ ok: false, isImage: false, isPlaceholder: false })}
                    iconName="StatusErrorFull"
                /></div>
            },
            onRender: function getItemDate(item: IResult<ITocAnalyzesData>) {
                let failedResult = item.data.matches.filter(fail => fail.result.toLowerCase() === "ko").length;
                failedResult += item.data.notMatches.filter(fail => fail.result.toLowerCase() === "ko").length
                return <span>{failedResult} KO</span>
            }
        },
        {
            key: 'error',
            name: "",
            ariaLabel: 'error',
            fieldName: 'error',
            minWidth: 100,
            maxWidth: 300,
            headerClassName: classNames.headerRow,
            onRender: function getItemFrom(item: IResult<ITocAnalyzesData>) {
                let errorMessage = "";
                if(item.error)
                {
                    errorMessage = t('analysisResults:tocResult.errors.'+item.error.code);
                }
                else if (item.warning)
                {
                    errorMessage = t('analysisResults:tocResult.warnings.'+item.warning.code);
                }
                return <span className={classNames.error}>{errorMessage}</span>
            }
        }
    ];

    const renderSynthesis = () => {
        if (!docs)
            return;
        const elements = [...docs];
        elements.shift()
        return (
            <div>
                <DetailsList
                    items={elements || []}
                    columns={columns}
                    constrainMode={ConstrainMode.unconstrained}
                    onShouldVirtualize={ () => false }
                    selectionMode={SelectionMode.none}
                    getKey={((item: IResult<ITocAnalyzesData>) => item.fileId.toString())}
                    setKey="none"
                    styles={classNames.subComponentStyles.detailsList}
                    onRenderRow={props => props ? <DetailsRow
                        styles={classNames.subComponentStyles.detailsRow} {...props} /> : null}

                />
            </div>)
    }



    const renderTocAnalysisResult = () => {
        if (!docs)
            return <></>
        const detailColumns: IColumn[] = [
            {
                key: 'name',
                name: t('analysisResults:tocResult.chapterName'),
                ariaLabel: 'name',
                fieldName: 'name',
                minWidth: 150,
                maxWidth: 300,
                headerClassName: classNames.headerRow,
                styles: {
                    cellName: {
                        fontSize: '14px',
                        fontWeight: 'bold'
                    },
                    cellTitle: { justifyContent: 'center' }
                },
                onRender: function getItemName(item: IToCMatchesData) {
                    return <div
                        style={{
                            whiteSpace: 'normal'
                        }}>
                        {item.ref ? item.ref : '-'}
                    </div>
                }
            },
            {
                key: 'nameFromDocument',
                name: t('analysisResults:tocResult.chapterNameFromDocument'),
                ariaLabel: 'nameFromDocument',
                fieldName: 'nameFromDocument',
                minWidth: 150,
                maxWidth: 300,
                headerClassName: classNames.headerRow,
                styles: {
                    cellName: {
                        fontSize: '14px',
                        fontWeight: 'bold'
                    },
                    cellTitle: { justifyContent: 'center' }
                },
                onRender: function getItemType(item: IToCMatchesData) {
                    return <div style={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                    }}>{item.comp ? item.comp : '-'}</div>
                }
            },
            {
                key: 'percentage',
                name: t('analysisResults:tocResult.percentage'),
                ariaLabel: 'percentage',
                fieldName: 'percentage',
                minWidth: 150,
                maxWidth: 150,
                headerClassName: classNames.headerRow,
                styles: {
                    cellName: {
                        fontSize: '14px',
                        fontWeight: 'bold'
                    },
                    cellTitle: { justifyContent: 'center' }
                },
                onRender: function getItemDate(item: IToCMatchesData) {
                    return <span>{item.confidence ? Math.floor(item.confidence * 100).toString().concat('%') : '-'}</span>
                }
            },
            {
                key: 'status',
                name: t('analysisResults:tocResult.status'),
                ariaLabel: 'status',
                fieldName: 'status',
                minWidth: 50,
                maxWidth: 50,
                headerClassName: classNames.headerRow,
                styles: {
                    cellName: {
                        fontSize: '14px',
                        fontWeight: 'bold'
                    },
                    cellTitle: { justifyContent: 'center' }
                },
                onRender: function getItemFrom(item: IToCMatchesData) {
                    return <span className={item.result.toLowerCase() === "ok" ? classNames.resultOk : classNames.resultNok}>{item.result}</span>
                }
            },
            {
                key: 'motivation',
                name: t('analysisResults:tocResult.motivation'),
                ariaLabel: 'motivation',
                fieldName: 'motivation',
                minWidth: 150,
                maxWidth: 150,
                headerClassName: classNames.headerRow,
                styles: {
                    cellName: {
                        fontSize: '14px',
                        fontWeight: 'bold'
                    },
                    cellTitle: { justifyContent: 'center' }
                },
                onRender: function getItemFrom(item: IToCMatchesData) {
                    const condition = item.result.toLowerCase() !== "ok";
                    return <>{condition && !item.ref ? <span className={classNames.error}>{t('analysisResults:tocResult.notAllocated')}</span> :
                        condition && !item.confidence ? <span className={classNames.error}>{t('analysisResults:tocResult.missing')}</span> : <span>{"-"}</span>
                    }</>
                }
            }
        ];

        const elements: ITocAnalyzesData = {
            matches: docs[documentIndex].data.matches,
            notMatches: docs[documentIndex].data.notMatches
        }
        const groups: IGroup[] = [];

        groups.push({
            key: "0",
            name: t('analysisResults:tocResult.matchElements'),
            startIndex: 0,
            count: elements.matches.length || 0,
            level: 0,
        },
            {
                key: "1",
                name: t('analysisResults:tocResult.noMatchElements'),
                startIndex: elements.matches.length || 0,
                count: elements.notMatches.length || 0,
                level: 0
            }
        )
        const detailsListItems = elements.matches.concat(elements.notMatches);
        return (<div>{
            <DetailsList
                items={detailsListItems || []}
                columns={detailColumns}
                groups={groups}
                selectionMode={SelectionMode.none}
                isHeaderVisible={true}
                constrainMode={ConstrainMode.unconstrained}
                onShouldVirtualize={() => false}
                groupProps={{
                    onRenderHeader: onRenderHeader,
                    showEmptyGroups: true,
                    isAllGroupsCollapsed: true,
                    collapseAllVisibility: CollapseAllVisibility.hidden,
                    
                }}
                styles={classNames.subComponentStyles.detailsList}
                onRenderRow={props => props ? <DetailsRow
                    styles={classNames.subComponentStyles.detailsRow}
                    {...props} /> : null}
            />}</div>)
    }

    const toggleCollapse = (props: IGroupHeaderProps): void => {
        props.onToggleCollapse!(props.group!);
    };

    const onRenderHeader = (props?: IGroupHeaderProps): JSX.Element | null => {
        if (props && props.group) {
            const gr = props.group;
            return ( 
                <div className={classNames.header}>
                    <Icon onClick={()=>toggleCollapse(props)} style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '12px', userSelect: 'none', marginRight: '10px' }} iconName={gr!.isCollapsed ? "ChevronRight" : "ChevronDown"} />
                    <span onClick={()=>toggleCollapse(props)} style={{ cursor: 'pointer', fontWeight: 'bold', fontStyle: 'italic', fontSize: '16px', userSelect: 'none' }}>{gr!.name} ({gr!.count})</span>
                </div>
            );
        }
        return null;
    };

    const tocBody = () => {
        return (
            <>
                {loading ? <Spinner style={{ position: 'absolute', left: '50%', top: 'calc(50% - 18px)' }} size={SpinnerSize.large} /> :
                    docs ? renderDocumentAnalysis() : <div>{emptyMessage}</div>}
            </>
        )
    }

    return (

        <>
            <Results
                apiInput={apiInput}
                content={tocBody()}
                docs={docs}
                isLoading={loading}
                changeDocument={(document) => setDocumentIndex(document)}
                iconName={"UpgradeAnalysis"}
                sectionTitle={`${t('analysisResults:tocResult.title')}`}
            />

        </>
    );
}