import { IBasePickerStyles, IStyleFunction } from "@fluentui/react"
import { IAddUsersToTopicModalPropsStyles, IAddUsersToTopicModalStyles } from "./addUsersToTopicModal.types";

export const AddUsersToTopicModalGlobalClassNames = {
    root: 'edi-add-users-topic-modal-root',
    disclaimerZone: 'edi-add-users-topic-modal-disclaimerZone',
    peoplePickerZone: 'edi-add-users-topic-modal-peoplePickerZone',
    peoplePickerSuggestions: 'edi-add-users-topic-modal-peoplePickerSuggestions',
    peoplePickerSuggestionItem: 'edi-add-users-topic-modal-peoplePickerSuggestionItem',
    detailsListRow: 'edi-add-users-topic-modal-detailsListRow',
    checkboxAlign: 'edi-add-users-topic-modal-checkboxAlign'
}

export const getStyles: IStyleFunction<IAddUsersToTopicModalPropsStyles, IAddUsersToTopicModalStyles> = (props: IAddUsersToTopicModalPropsStyles): IAddUsersToTopicModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            AddUsersToTopicModalGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px',
                selectors: {
                    ' .edi-profile-view-action': {
                        right: '15px',
                        bottom: '15px',
                    }
                }
            }            
        ],
        disclaimerZone: [
            AddUsersToTopicModalGlobalClassNames.disclaimerZone,
            {
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 30
            }
        ],
        peoplePickerZone: [
            AddUsersToTopicModalGlobalClassNames.peoplePickerZone,
            {
                display: 'flex',
                marginBottom: '20px'
            }
        ],
        peoplePickerSuggestions: [
            AddUsersToTopicModalGlobalClassNames.peoplePickerSuggestions,
            {
                padding: '8px 0'
            }    
        ],
        peoplePickerSuggestionItem: [
            AddUsersToTopicModalGlobalClassNames.peoplePickerSuggestionItem,
            {
                '::after': {
                    display: 'none',
                },
                ".is-suggested": {
                    background: palette.neutralQuaternary
                }
            }
        ],
        detailsListRow: [
            AddUsersToTopicModalGlobalClassNames.detailsListRow,
            {
                background: 'transparent !important',
            }
        ],
        checkboxAlign: [
            AddUsersToTopicModalGlobalClassNames.checkboxAlign,
            {
                height: '100%',
                alignItems: 'center',
                ".ms-Checkbox-label": {
                    alignItems: 'center',
                    height: '32px'
                },
                ".ms-Checkbox-text": {
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap',
                    fontSize: '13px',
                    lineHeight: '12px'
                }
            }
        ]
    }
}

export const peoplePickerStyles = (): IBasePickerStyles => ({
    input: {
        backgroundColor: 'transparent'
    },
    text: {
        border: '1px solid rgb(240, 240, 240) !important',
        borderRadius: 2,
        backgroundColor: 'rgb(240, 240, 240)'
    },
    itemsWrapper: {
        ".ms-PickerPersona-container": {
            background: 'rgb(245, 245, 245)',
            border: "1px solid rgb(245, 245, 245)",
            ':hover': {
                background: 'rgba(98, 100, 167, 0.2)',
                border: "1px solid rgba(98, 100, 167, 0.2)"
            }
        }
    },
    root: {},
    screenReaderText: {}
})