/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable sonarjs/no-duplicate-string */
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { ActionButton, Checkbox, classNamesFunction, DefaultEffects, DirectionalHint, Dropdown, FocusZone, FocusZoneDirection, IconButton, IContextualMenuItem, IContextualMenuProps, IDropdownOption, List, ScrollablePane, Shimmer, Stack, StackItem, TextField, TooltipHost } from "@fluentui/react";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../common/components/datePicker/datePicker";
import Pagination from "../../../common/components/pagination/pagination";
import Tag from "../../../common/components/tag/tag";
import TeamsImage from "../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../common/components/teamsImage/teamsImage.types";
import { useAsyncApi, useOnMount } from "../../../utilities/hooks";
import { useKnowledgeLabDispatch, useKnowledgeLabState } from "../../knowledgeLabStore";
import { addSelectedFacet, getIndexField, removeSelectedFacet, resetAllSearchResults, setCurrentResults, setGroupedFacets, setSearchParams, updateGroupedFacets, useSearchContent } from "../../features/searchContent";
import { Constants, SearchFilters } from "../../models/constants";
import { searchApi } from "../../services/search/search.api";
import { Document, FacetType, FieldType, FlatFacet, IFacet, IFacetItem, ISkillResultsTagValue, MergedFacetItem, MergedFacets, SearchRequest, SearchValue } from "../../services/search/search.contracts";
import { getSearchActions } from "../../utilities/searchActions";
import { ISearchProps, ISearchPropsStyles, ISearchStyles, SearchScope } from "./search.types";
import { useCurrentKnowledge } from "../../hooks/useCurrentKnowledge";
import { KnowledgeLabShort } from "../../models/knowledgeLab";
import { useNavigator } from "../../hooks/useNavigator";
import { setDetailsModalOpen, setSelectedElement } from "../../features/knowledgeLabContent"
import { useKnowledgeLabList } from "../../features/knowledgeLabList";

const getClassNames = classNamesFunction<ISearchPropsStyles, ISearchStyles>();

export const SearchBase = (props: ISearchProps) => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [multiLanguageCheckBox, setMultiLanguageCheckBox] = useState(false);
    const { t, i18n } = useTranslation(['search', "common"]);
    const dispatch = useKnowledgeLabDispatch();
    const knowledgeLabState = useKnowledgeLabState();
    const knowledgelab = useKnowledgeLabList();
    const navigator = useNavigator();
    const currentKnowledge = useCurrentKnowledge();
    const { searchResult, searchParams, groupedFacets } = useSearchContent();
    const [keyword, setKeyword] = useState('');
    const [filterKeyword, setFilterKeyword] = useState('');
    const [searchFilterKeyword, setSearchFilterKeyword] = useState('');
    const [copyFacets, setCopyFacets] = useState<MergedFacets[]>([])
    const [searchFilterActived, setSearchFilterActived] = useState(false);
    const [IsFirstSearch, setIsFirstSearch] = useState(true);
    const [showNoResult, setShowNoResult] = useState(true);

    const isCompareEnabled = currentKnowledge?.configurations?.compareElements?.enabled ?? false;

    const { execute: search, error, loading } = useAsyncApi<SearchRequest, void>({
        func: async (request) => {
            const result = await searchApi.search(request);

            if (result && !_.isEqual(result, searchResult)) {
                if (IsFirstSearch)
                    result.values = [];

                dispatch(setCurrentResults(result));
            }
        },
        keepResultsWhileReloading: true
    });

    const searchElements = async () => {
        if (currentKnowledge?.id) {
            if (IsFirstSearch)
                setShowNoResult(true);
            else
                setShowNoResult(false);

            await search({
                value: keyword,
                orderedBy: searchParams.orderBy,
                isAscending: searchParams.isAsc ?? true,
                currentKnowledgeId: currentKnowledge?.id,
                currentPage: searchParams.pageNumber && searchParams.pageNumber > 0 ? searchParams.pageNumber : 1,
                pageSize: 10,
                maxHighlights: 1,
                filters: searchParams.selectedFacets,
                multiLanguageSearch: keyword !== '' ? multiLanguageCheckBox : false,
                scope: SearchScope.Full
            });
        }
    };

    const options: IDropdownOption[] = [
        { key: '', text: t("relevance") },
        { key: 'DateCreatedDesc', text: t("createdOnDesc") },
        { key: 'DateCreatedAsc', text: t("createdOnAsc") },
    ];

    const dropDownChange = (event: React.FormEvent<HTMLDivElement>, options?: IDropdownOption): void => {
        if (!options)
            return;
        switch (options.key) {
            case 'DateCreatedAsc': dispatch(setSearchParams({ orderBy: 'DateCreated', isAsc: true, pageNumber: 1 })); break;
            case 'DateCreatedDesc': dispatch(setSearchParams({ orderBy: 'DateCreated', isAsc: false, pageNumber: 1 })); break;
            default: dispatch(setSearchParams({ orderBy: undefined, isAsc: true, pageNumber: 1 })); break;
        }
    };

    const getElementTitle = (d: Document): string => {

        const currentLang = i18n.language;
        const currentTranslations = currentKnowledge?.configurations?.translations.find(t => t.lang === currentLang) ?? undefined;
        let title = currentTranslations?.values["defaultTitle"] ?? d.id;

        const titleName = currentKnowledge?.titleFieldName;
        if (d.additionalData && titleName && d.additionalData[titleName]) {
            title = d.additionalData[titleName];
        }

        return title;
    }

    const getTitleFromJsonSchema = (s: string) => {
        return currentKnowledge?.jsonSchemaFields.find(f => f.name === s)?.title;
    };

    const getAdditionalData = (d: Document) => {
        if (currentKnowledge?.searchProperties) {
            return currentKnowledge?.searchProperties.map((el) => {
                const value = d.additionalData ? (d.additionalData[el] ?? "-") : "-";
                return <span style={{ marginRight: "5", width: '250px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} key={el}><b>{getTitleFromJsonSchema(el)}</b>: <TooltipHost content={value}>{value}</TooltipHost></span>
            });
        } else
            return <></>
    }

    const onRenderCell = (item?: SearchValue, index?: number | undefined): JSX.Element => {
        if (!item)
            return <div></div>;

        return (
            <>
                <div className={classNames.itemHeader}>
                    <div>
                        {item.document.id !== null &&
                            <span style={{ color: palette.themePrimary, cursor: 'pointer', fontWeight: 'bold' }}
                                onClick={() => {
                                    dispatch(setSelectedElement(item.document));
                                    dispatch(setDetailsModalOpen(true));
                                }}
                            >{getElementTitle(item.document)}
                            </span>}
                        <IconButton
                            styles={classNames.subComponentStyles.iconButton()}
                            iconProps={{ iconName: 'More' }}
                            menuProps={menuProps(item.document)}
                        />
                    </div>
                    <div>
                        <span style={{ color: palette.themePrimary, marginTop: 5, marginBottom: 5 }}>{t("additionalData.views")}: <b>{item.document.additionalData ? (item.document.additionalData["views"] ?? "-") : "-"}</b></span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap', marginTop: 5, marginBottom: 5 }}>
                        {getAdditionalData(item.document)}
                    </div>
                </div>
                <div key={index} className={classNames.itemCell} data-is-focusable={true}>
                    <div className={classNames.itemContent}>
                        {item.highlights["Content"]?.map((h, i) =>
                            <div key={i} className={classNames.itemName} dangerouslySetInnerHTML={{ __html: h }} />
                        )}
                    </div>
                </div>
            </>
        );
    }

    const resultSection = useMemo(() => {
        return <>
            <Stack.Item className={classNames.resultsContent} grow>
                {!loading && searchResult?.values && searchResult.values.length > 0 &&
                    <> <div className={classNames.dropDown}>
                        <Dropdown
                            key={currentKnowledge?.id}
                            defaultSelectedKey={searchParams.orderBy ? searchParams.orderBy + (searchParams.isAsc ? "Asc" : "Desc") : ''}
                            options={options}
                            styles={{
                                dropdown: { width: 150, boxShadow: DefaultEffects.elevation8 },
                                title: { background: palette.white },
                                root: { marginTop: '0px !important', paddingLeft: 17 }
                            }}
                            onChange={dropDownChange}
                            disabled={!searchResult?.values || searchResult.values.length === 0}
                        />
                        <span style={{ paddingRight: 17 }}>
                            <Pagination
                                key={currentKnowledge?.id}
                                pageCount={searchResult?.pageCount ?? 0}
                                currentPage={searchParams.pageNumber ?? 1}
                                onChangePage={page => { dispatch(setSearchParams({ pageNumber: page })) }}
                            />
                        </span>
                    </div>
                        <FocusZone direction={FocusZoneDirection.vertical}>
                            <div data-is-scrollable>
                                <List items={searchResult?.values}
                                    className={classNames.customList}
                                    onRenderCell={onRenderCell}
                                />
                            </div>
                        </FocusZone>
                    </>
                }
            </Stack.Item>
        </>
        // eslint-disable-next-line
    }, [searchResult, searchParams, loading, classNames]);

    const getContextualMenuItems = useCallback((knowledge: KnowledgeLabShort, document: Document): IContextualMenuItem[] => {

        let isFavourite = false;

        if (document?.additionalData)
            isFavourite = document.additionalData["isFavourite"] !== undefined ? (document.additionalData["isFavourite"].toLocaleLowerCase() === 'true') : false;

        const itemTitle = getElementTitle(document);

        return getSearchActions(knowledge.roleId, isFavourite, isCompareEnabled).map(
            (action) => ({
                key: action.label,
                text: t(action.label),
                iconProps: { iconName: action.icon },
                onClick: () => action.onClick(knowledgeLabState, knowledge, navigator, dispatch, document, searchResult, itemTitle),
            })
        );
    }, [navigator, t, dispatch, knowledgeLabState, searchResult, isCompareEnabled]); //eslint-disable-line react-hooks/exhaustive-deps

    const menuProps = (document: Document): IContextualMenuProps => {
        return ({
            items: currentKnowledge ? getContextualMenuItems(currentKnowledge, document) : [],
            directionalHint: DirectionalHint.rightTopEdge
        })
    }

    const emptyResults = (withTags = false) => {
        return (!searchResult?.values || searchResult.values.length === 0) && (withTags ? searchParams.selectedFacets?.length === 0 : true);
    }

    const wrongResult = useMemo(() => {
        if (loading)
            return null;

        if (!(error || emptyResults()))
            return null;

        if (!error && showNoResult)
            return <TeamsImage
                imageName={ImageName.InProgress}
                scale={0.3}
                fullContainer
                style={{ marginBottom: 30 }}
                caption={t("common:startNewSearchToSeeResults")}
            />

        return (
            <TeamsImage
                imageName={error ? ImageName.Error3 : ImageName.NoResults}
                scale={0.3}
                fullContainer
                style={{ marginBottom: 30 }}
                caption={t(error ? "common:genericErrorApi" : "common:emptySearch")}
            />);
        // eslint-disable-next-line
    }, [error, searchResult, t, loading, emptyResults, showNoResult]);


    const isItemSelected = (val: MergedFacetItem) => {
        if (val.type === FacetType.Value) {
            return (searchParams.selectedFacets?.some(x => x.key === val.field && (x.values?.some(x => x === val.value) || x.value)));
        }
        if (val.type === FacetType.DateRange) {
            return (searchParams.selectedFacets?.some(x => x.key === val.field && x.from === val.from));
        }
    }

    const formatFacetVal = (val: MergedFacetItem, additionalType?: FieldType) => {
        return <>
            {val.type !== FacetType.Range &&
                <span style={{ cursor: 'pointer', fontWeight: ((isItemSelected(val)) ? 'bold' : undefined), color: ((isItemSelected(val)) ? palette.themePrimary : '') }} onClick={() => {
                    if (val.count !== 0)
                        dispatch(addSelectedFacet({ mergedFacedItem: val, AdditionalType: additionalType }));
                }}>{val.value + ' (' + val.count + ')'}</span>
            }
        </>
    }

    const mapDataRange = (ranges: MergedFacetItem[]) => {
        const groupedRanges: MergedFacetItem[] = [];
        const reversedRanges = [...ranges].reverse();
        const today = reversedRanges[0].from ? new Date(reversedRanges[0].from || '').toISOString() : undefined;
        reversedRanges.forEach((val, index) => {
            if (index !== 0)
                groupedRanges.push({
                    field: val.field,
                    from: val.from ? new Date(val.from || '').toISOString() : undefined,
                    to: today,
                    type: FacetType.DateRange,
                    value: t(`filters.ranges.range_${index}`),
                    count: reversedRanges.slice(0, index + 1).reduce(function (prev, current) {
                        return prev + current.count
                    }, 0),
                })
        });
        return groupedRanges;
    }

    const getTag = (facet: FlatFacet, value: string, facetToRemove: FlatFacet) => {
        const y: ISkillResultsTagValue = { value: value, title: '', isPinned: false, toDelete: false }; //TODO generalizare tipo in input al componente Tag
        return <StackItem key={`StackItem_${facet.key}_${y.value.trim()}`}>
            <Tag
                onCancelSelected={() => dispatch(removeSelectedFacet(facetToRemove))}
                selectableTag
                small
                key={`${facet.key}_${y.value.trim()}`}
                value={y}
                keyTag={`${facet.key}_${y.value.trim()}`} />
        </StackItem>
    }

    const getTagByType = (x: FlatFacet) => {
        if (x.type === FacetType.Value && x.value)
            return getTag(x, x.value.toString(), { ...x, text: x.value.toString() })
        if (x.type === FacetType.Value)
            return x.values?.map((val: string) => {
                return getTag(x, val, { ...x, text: val });
            })
        if (x.type === FacetType.Range || x.type === FacetType.DateRange)
            return getTag(x, x.text || '', x);
    }

    const getFacetsToMap = (val: MergedFacets) => {
        switch (val.type) {
            case FacetType.Value:
            case FacetType.Range:
                return val?.values || [];
            case FacetType.DateRange:
                return mapDataRange((val.values || []));
            default:
                return [];
        }
    }

    const onShowHandler = (item: MergedFacets) => {
        const copy: MergedFacets[] = [];
        groupedFacets.forEach(gf => gf && copy.push({ ...gf }));
        const itemGroupedFacets = copy.findIndex(el => el && el.field === item.field);
        if (itemGroupedFacets >= 0) {
            copy[itemGroupedFacets].showAll = !item.showAll
        }
        dispatch(setGroupedFacets([...copy]));
    }

    const additionalItems = (item: MergedFacets) => {
        const indexItem = groupedFacets.findIndex(i => i && i.field === item.field);
        const valuesCopy = groupedFacets[indexItem].values?.slice() as MergedFacetItem[];
        const indexValue = valuesCopy.findIndex(i => i && i.field === item.additionalKey);
        const value = { ...valuesCopy[indexValue] }
        const itemsDisable = searchParams.selectedFacets?.some(x => x.key === value.field)

        return (
            item.isAdditional && item.additionalType === FieldType["Edm.DateTimeOffset"] ?
                <>
                    <Stack style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ marginRight: "5px" }}>
                            <DatePicker
                                placeholder="From"
                                disabled={itemsDisable}
                                inputValue={value.from?.toString() ? new Date(value.from?.toString()) : undefined}
                                dateCallback={(newDate) => {
                                    const indexItemToUpdate = groupedFacets.findIndex(i => i && i.field === item.field);
                                    const valuesCopy = groupedFacets[indexItemToUpdate].values?.slice() as MergedFacetItem[];
                                    const indexValueToCopy = valuesCopy.findIndex(i => i && i.field === item.additionalKey);
                                    const elementToUpdate = { ...valuesCopy[indexValueToCopy] }
                                    valuesCopy.splice(indexValueToCopy, 1);
                                    elementToUpdate.from = DateTime.fromJSDate(newDate).toString();
                                    valuesCopy.push(elementToUpdate);
                                    dispatch(updateGroupedFacets({
                                        fieldMergedFacet: indexItemToUpdate,
                                        newValue: valuesCopy
                                    }));
                                }
                                }
                            />
                        </div>
                        <DatePicker
                            placeholder="To"
                            disabled={itemsDisable}
                            inputValue={value.to?.toString() ? new Date(value.to?.toString()) : undefined}
                            minDate={value.from?.toString() ? new Date(value.from?.toString()) : undefined}
                            dateCallback={(newDate) => {
                                const indexItemToUpdate = groupedFacets.findIndex(i => i && i.field === item.field);
                                const valuesCopy = groupedFacets[indexItemToUpdate].values?.slice() as MergedFacetItem[];
                                const indexValueToCopy = valuesCopy.findIndex(i => i && i.field === item.additionalKey);
                                const elementToUpdate = { ...valuesCopy[indexValueToCopy] }
                                valuesCopy.splice(indexValueToCopy, 1);
                                elementToUpdate.to = DateTime.fromJSDate(newDate).toString();
                                valuesCopy.push(elementToUpdate);
                                dispatch(updateGroupedFacets({
                                    fieldMergedFacet: indexItemToUpdate,
                                    newValue: valuesCopy
                                }));
                            }
                            }
                        />
                        <IconButton
                            styles={
                                itemsDisable ? {
                                    root: {
                                        visibility: 'hidden'
                                    }
                                } : {
                                    root: {
                                        selectors: {
                                            ':hover': {
                                                background: 'transparent !important'
                                            },
                                            ':active': {
                                                background: 'transparent !important'
                                            }
                                        },
                                        marginLeft: '0px !important',

                                    }
                                }}
                            disabled={!(value.from !== undefined || value.to !== undefined)}
                            iconProps={{ iconName: "Add", style: { fontSize: 'smaller' } }}
                            onClick={() => {
                                const indexItemToUpdate = groupedFacets.findIndex(i => i && i.field === item.field);
                                const valuesCopy = groupedFacets[indexItemToUpdate].values?.slice() as MergedFacetItem[];
                                const indexValueToCopy = valuesCopy.findIndex(i => i && i.field === item.additionalKey);
                                const elementToUpdate = { ...valuesCopy[indexValueToCopy] }
                                dispatch(addSelectedFacet({
                                    mergedFacedItem: {
                                        ...elementToUpdate,
                                        from: elementToUpdate.from,
                                        to: elementToUpdate.to,
                                        value: `${item.title} [${elementToUpdate.from ? (t('filters.formalAspects.from') + ": " + onFormatDate(new Date(elementToUpdate.from?.toString() ?? ''))) : ''}${elementToUpdate.to ? (", " + t('filters.formalAspects.to') + ": " + onFormatDate(new Date(elementToUpdate.to?.toString() ?? ''))) : ''}]`
                                    },
                                    AdditionalType: item.additionalType
                                }))
                            }}
                        />
                    </Stack>
                </>
                : item.isAdditional && item.additionalType === FieldType["Edm.Int32"] ?
                    <>
                        {
                            item?.values?.map((val, ind) => {
                                return (
                                    <Stack
                                        key={ind}
                                        horizontal={true}
                                        horizontalAlign='stretch'
                                        verticalAlign='center'
                                        style={{ paddingTop: '5px' }}
                                        tokens={{ childrenGap: 10 }}
                                    >
                                        <Stack.Item>
                                            <TextField
                                                styles={classNames.subComponentStyles.rangeTextFieldStyle}
                                                type="number"
                                                label=""
                                                min="0"
                                                max={val.to}
                                                value={val.from?.toString()}
                                                onChange={(e) => {
                                                    if (e.currentTarget.value && Number(e.currentTarget.value) >= 0 && Number(e.currentTarget.value) % 1 === 0 && Number(e.currentTarget.value) <= Number(val.to)) {
                                                        const indexItemToUpdate = groupedFacets.findIndex(i => i && i.field === item.field);
                                                        const valuesCopy = groupedFacets[indexItemToUpdate].values?.slice() as MergedFacetItem[];
                                                        const indexValueToCopy = valuesCopy.findIndex(i => i && i.field === val.field);
                                                        const elementToUpdate = { ...valuesCopy[indexValueToCopy] }
                                                        valuesCopy.splice(indexValueToCopy, 1);
                                                        elementToUpdate.from = e.currentTarget.value;
                                                        valuesCopy.push(elementToUpdate);
                                                        dispatch(updateGroupedFacets({
                                                            fieldMergedFacet: indexItemToUpdate,
                                                            newValue: valuesCopy
                                                        }));
                                                    }
                                                }}
                                                prefix={t('filters.formalAspects.from')}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <TextField
                                                min={val.from}
                                                type="number"
                                                styles={classNames.subComponentStyles.rangeTextFieldStyle}
                                                inputMode='numeric'
                                                value={val.to?.toString()}
                                                onChange={(e) => {
                                                    if (e.currentTarget.value && Number(e.currentTarget.value) >= 0 && Number(e.currentTarget.value) % 1 === 0 && Number(e.currentTarget.value) <= Number(val.to)) {
                                                        const indexItemToUpdate = groupedFacets.findIndex(i => i && i.field === item.field);
                                                        const valuesCopy = groupedFacets[indexItemToUpdate].values?.slice() as MergedFacetItem[];
                                                        const indexValueToCopy = valuesCopy.findIndex(i => i && i.field === val.field);
                                                        const elementToUpdate = { ...valuesCopy[indexValueToCopy] }
                                                        valuesCopy.splice(indexValueToCopy, 1);
                                                        elementToUpdate.to = e.currentTarget.value;
                                                        valuesCopy.push(elementToUpdate);
                                                        dispatch(updateGroupedFacets({
                                                            fieldMergedFacet: indexItemToUpdate,
                                                            newValue: valuesCopy
                                                        }));
                                                    }
                                                }}
                                                prefix={t('filters.formalAspects.to')}
                                            />
                                        </Stack.Item>
                                        {searchParams.selectedFacets?.some(x => x.key === val?.field) ? '' :
                                            <IconButton
                                                styles={{
                                                    root: {
                                                        selectors: {
                                                            ':hover': {
                                                                background: 'transparent !important'
                                                            },
                                                            ':active': {
                                                                background: 'transparent !important'
                                                            }
                                                        },
                                                        marginLeft: '0px !important'
                                                    }
                                                }}
                                                iconProps={{ iconName: "Add", style: { fontSize: 'smaller' } }}
                                                onClick={() => dispatch(addSelectedFacet({
                                                    mergedFacedItem: {
                                                        ...val,
                                                        from: Number(val.from),
                                                        to: Number(val.to),
                                                        value: `${t(`tagPanel:values.${item.additionalKey}`)} [${val.from},${val.to}]`
                                                    },
                                                    AdditionalType: item.additionalType
                                                }))}
                                            />
                                        }
                                    </Stack>
                                )
                            })
                        }
                    </> : ''
        );
    }

    const onFormatDate = (date?: Date): string => {
        if (date) {
            let day = '' + date?.getDate();
            let month = '' + (date?.getMonth() + 1);
            const year = date?.getFullYear();

            if (date?.getDate() < 10)
                day = '0' + date?.getDate();
            if ((date?.getMonth() + 1) < 10)
                month = '0' + (date?.getMonth() + 1);

            return !date ? '' : day + '/' + month + '/' + year;
        }
        return '';
    };

    const checkTitle = (val: MergedFacets) => {
        let res = false;
        getFacetsToMap(val).forEach((valueItem) => {
            if (valueItem.value?.toLowerCase().includes(filterKeyword.toLowerCase()))
                res = true;
        })
        return res
    }

    const getFacets = () => {
        return (<div style={{ marginTop: '1em' }}>
            {(groupedFacets)?.map((val) => {
                return (<>
                    {
                        val.type === FacetType.DateRange && !val.values?.some(x => x.count !== 0) ? '' :
                            (<Stack style={{ marginBottom: '2em' }}>
                                {val.type === FacetType.DateRange && checkTitle(val) && <StackItem style={{ marginBottom: '5px' }}>
                                    <span style={{ fontWeight: 'bold' }}>{val.title ?? val.field}</span>
                                </StackItem>}

                                {val.type !== FacetType.DateRange && <StackItem style={{ marginBottom: '5px' }}>
                                    <span style={{ fontWeight: 'bold' }}>{val.title ?? val.field}</span>
                                </StackItem>}
                                {getFacetsToMap(val).map((valueItem, ind) => {
                                    return (
                                        !valueItem.value?.toLowerCase().includes(filterKeyword.toLowerCase()) &&
                                            valueItem.type === FacetType.DateRange ? additionalItems(val) :
                                            valueItem.count === 0 && valueItem.type === FacetType.DateRange ? '' :
                                                val.showAll || valueItem.type === FacetType.DateRange ? <>
                                                    <Stack style={{ marginBottom: '5px' }}>
                                                        <StackItem>{formatFacetVal(valueItem, val.additionalType)}</StackItem>
                                                    </Stack>
                                                </> : ind < Constants.MAX_FACETS_SHOWED &&
                                                <>
                                                    <Stack style={{ marginBottom: '5px' }}>
                                                        <StackItem>{formatFacetVal(valueItem, val.additionalType)}</StackItem>
                                                    </Stack>
                                                </>)
                                })}
                                {val.values && val.values?.length > Constants.MAX_FACETS_SHOWED && val.type !== FacetType.DateRange &&
                                    <Stack style={{ marginBottom: '5px' }}>
                                        <StackItem
                                            className={classNames.showAllButton}
                                            onClick={() => onShowHandler(val)}>
                                            {val.showAll === false ? t('filters.showMore') : t('filters.showLess')}
                                        </StackItem>
                                    </Stack>}
                            </Stack>)
                    }
                </>)
            })}
        </div>)
    }

    const restAllFilters = () => {
        setSearchFilterKeyword('');
        setFilterKeyword('');
        setKeyword('');
        setSearchFilterActived(false);
        setMultiLanguageCheckBox(false);
        setIsFirstSearch(true);
        dispatch(resetAllSearchResults(currentKnowledge?.id));
    }

    const filtersPanel = () => {
        return <div className={classNames.filtersPanel}>
            <ScrollablePane>
                <div>
                    <StackItem >
                        <div style={{ display: 'flex', justifyContent: 'end', marginTop: 10 }}>
                            <ActionButton onClick={() => {
                                restAllFilters();
                            }}>
                                <span style={{ color: palette.themePrimary, textDecoration: 'underline' }}>{t('restartSearch')}</span>
                            </ActionButton>
                        </div>
                    </StackItem>
                    <StackItem align="center" style={{ alignItems: 'center' }} >
                        <div className={classNames.searchContainer}>
                            {searchFilterActived ?
                                <TextField
                                    placeholder={t("filterMessage")}
                                    value={searchFilterKeyword}
                                    autoFocus={searchFilterActived}
                                    styles={classNames.subComponentStyles.filterSearch}
                                    onChange={(_, value) => {
                                        setSearchFilterKeyword(value || '');
                                        if (value?.length && value?.length > 1) {
                                            setFilterKeyword(value || '');
                                            setSearchFilterKeyword(value || '');
                                        }
                                    }}
                                    iconProps={{
                                        iconName: 'Clear',
                                        styles: {
                                            root: {
                                                color: palette.themePrimary,
                                                fontSize: '18px',
                                                paddingBottom: '4px',
                                                cursor: 'pointer',
                                                pointerEvents: 'auto'
                                            }
                                        },
                                        onClick: () => {
                                            setSearchFilterActived(false);
                                            setSearchFilterKeyword('');
                                            setFilterKeyword('');
                                        }
                                    }}
                                /> : <div className={classNames.containerSearchFilter} style={{ borderBottom: '1px solid ' + palette.neutralLight }}>
                                    <span style={{ fontSize: 16, fontWeight: 500 }}>{t('knowledgeTitle')}</span>
                                    <IconButton
                                        styles={{
                                            menuIcon: {
                                                display: 'none !important'
                                            },
                                            rootExpanded: {
                                                backgroundColor: 'inherit',
                                                textShadow: 'rgb(37 36 35) 0px 0px 1px'
                                            },
                                            rootPressed: {
                                                backgroundColor: 'inherit'
                                            },
                                            rootHovered: {
                                                backgroundColor: 'inherit',
                                                textShadow: 'rgb(37 36 35) 0px 0px 1px'
                                            }
                                        }}
                                        onClick={() => setSearchFilterActived(true)}
                                        iconProps={{
                                            iconName: 'SearchIssue',
                                            styles: {
                                                root: {
                                                    color: palette.themePrimary,
                                                    fontSize: '20px',
                                                    cursor: 'pointer',
                                                    pointerEvents: 'auto',
                                                },

                                            },
                                        }} /></div>}
                        </div>
                    </StackItem>
                    <Stack horizontal style={{ marginTop: '15px' }} >
                        <StackItem align="center" style={{ alignItems: 'center' }} >
                            <span style={{ fontWeight: 'bold', fontSize: '1em' }}>{t('filters.filter')}</span>
                        </StackItem>

                        {searchParams.selectedFacets?.length !== 0 && <StackItem align="center">
                            <TooltipHost
                                content={t('filters.resetFilter')}
                                id={"resetFilters"}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                            >
                                <IconButton iconProps={{ iconName: 'ClearFilter' }} onClick={() => { dispatch(setSearchParams({ selectedFacets: [] })); }} />
                            </TooltipHost>
                        </StackItem>}

                    </Stack>
                    <Stack horizontal={true} wrap>
                        {searchParams.selectedFacets?.map((x: FlatFacet) => {
                            return getTagByType(x)
                        })}
                    </Stack>
                </div>
                {loading && getShimmer()}
                {!loading && !error && getFacets()}
            </ScrollablePane >
        </div >
    }

    const getShimmer = () => {
        return [...Array(5)].map((e, i) => <div key={i} style={{ marginTop: '2em' }}>
            <Shimmer width="50%" /><br />
            <Shimmer />
            <br /><br />
        </div>);
    }

    const onCheckBoxChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        checked ? setMultiLanguageCheckBox(true) : setMultiLanguageCheckBox(false);
    }

    const getAdditionalField = (field: string) => {
        for (const [key, value] of Object.entries(currentKnowledge?.metadataMapping)) {
            if (value === field)
                return key;
        }
        return undefined;
    }

    const getFacetByType = (disabledFacetFilters: string[], type: FacetType, values: IFacet<IFacetItem>[], facetsRegistered: MergedFacets[]) => {
        const facets: MergedFacets[] = new Array(SearchFilters.items.length);

        values.forEach(val => {
            if (!disabledFacetFilters?.some(x => x === val.field)) {
                const isAdditional = val.field.includes("EDI_Field");
                let additionalType: string | undefined;

                if (knowledgeLabState.searchContent.indexFields.find(i => i.name === val.field)) {
                    additionalType = knowledgeLabState.searchContent.indexFields?.find(i => i.name === val.field)?.type;
                }

                const typedAdditionalType = additionalType as keyof typeof FieldType;
                if (isAdditional && (typedAdditionalType === "Edm.DateTimeOffset" || typedAdditionalType === "Edm.Int32"))
                    return;

                const fieldToInsert = isAdditional ? (getAdditionalField(val.field) ?? '') : t(`tagPanel:keys.${val.field}`);
                const facet: MergedFacets = ({
                    title: currentKnowledge?.jsonSchemaFields?.find(i => i.name === fieldToInsert)?.title,
                    type: type,
                    field: fieldToInsert,
                    values: val.values.map((v: IFacetItem) => { return { ...v, field: val.field, type: type } }),
                    showAll: type !== FacetType.Range ? false : undefined,
                    isAdditional: isAdditional,
                    additionalType: isAdditional ? FieldType[typedAdditionalType] : undefined,
                    additionalKey: val.field,
                    unRegistered: false
                });
                if (SearchFilters.items.indexOf(val.field) !== -1) {
                    facetsRegistered[SearchFilters.items.indexOf(val.field)] = facet;
                }
                else {
                    if (facet !== undefined)
                        facets.push(facet);
                }
            }
        })

        return facets;
    }

    const getAdditionalFieldFilter = () => {
        const filterArchive: MergedFacets[] = [];
        if (!currentKnowledge?.metadataMapping)
            return;

        for (const [key, value] of Object.entries(currentKnowledge?.metadataMapping)) {
            const additionalType = knowledgeLabState.searchContent.indexFields?.find(i => i.name === value)?.type;
            const typedAdditionalType = additionalType as keyof typeof FieldType;


            if (typedAdditionalType !== "Edm.Int32" && typedAdditionalType !== "Edm.DateTimeOffset")
                continue;

            const item: MergedFacets = {
                title: currentKnowledge?.jsonSchemaFields?.find(i => i.name === key)?.title,
                type: FacetType.Value,
                field: key,
                values: [
                    {
                        count: 0,
                        field: typeof value === 'string' ? value : '',
                        type: typedAdditionalType === "Edm.Int32" ? FacetType.Value : FacetType.DateRange
                    }
                ],
                showAll: false,
                isAdditional: true,
                additionalType: FieldType[typedAdditionalType],
                additionalKey: typeof value === 'string' ? value : ''
            }

            if (knowledgeLabState.searchContent.groupedFacets.some(i => i.field === key)) {
                const indexItem = groupedFacets.findIndex(i => i && i.field === key);
                const itemRestored = knowledgeLabState.searchContent.groupedFacets[indexItem];
                filterArchive.push(itemRestored);
            }
            else {
                filterArchive.push(item);
            }
        }
        return filterArchive;
    }

    useOnMount(() => {
        dispatch(getIndexField());
    });

    useEffect(() => {
        if (!IsFirstSearch) {
            restAllFilters();
        }
    }, [currentKnowledge?.id, dispatch]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        searchElements();
        setIsFirstSearch(false);
    }, [searchParams]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const copyGrouped = [...copyFacets];
        const newGrouped: MergedFacets[] = [];
        let filteredValues: MergedFacetItem[] = [];
        copyGrouped.forEach(group => {
            if (!group)
                return;

            if (group.type !== FacetType.Range && group.type !== FacetType.DateRange) {

                const groupValues = group.values;
                if (groupValues) {
                    filteredValues = groupValues.filter(v => (v.value?.toLowerCase().includes(filterKeyword.toLowerCase())
                        || group.field?.toLowerCase().includes(filterKeyword.toLowerCase()))
                        && !(group.isAdditional && groupValues?.some(i => i.type === FacetType.DateRange || i.type === FacetType.Range)));
                }
                if (group.isAdditional && groupValues?.some(i => i.type === FacetType.DateRange || i.type === FacetType.Range)) {
                    newGrouped.push(group);
                }
                if (filteredValues.length > 0) {
                    const groupCopied = { ...group }
                    const newValues = [...filteredValues];
                    groupCopied.values = newValues;
                    newGrouped.push(groupCopied);
                }
            }
            else {
                newGrouped.push(group);
            }
        })

        dispatch(setGroupedFacets(newGrouped))
    }, [filterKeyword, copyFacets, dispatch]);

    //populateFacets
    useEffect(() => {
        if (searchResult) {
            const facetsRegistered: MergedFacets[] = new Array(SearchFilters.items.length);
            const facetsUnregistered: MergedFacets[] = [];
            const facets: MergedFacets[] = [];

            // Filtri provenienti dai metadati aggiuntivi
            const additionalFilters = getAdditionalFieldFilter();
            additionalFilters?.filter(val => val.additionalType !== 0).forEach(val => facetsUnregistered.push(val));

            const dateRangeFacets = getFacetByType(searchResult.disabledFacetFilters, FacetType.DateRange, searchResult?.facets.ranges, facetsRegistered);
            dateRangeFacets.forEach(f => {
                facetsUnregistered.push(f);
            });

            const valueFacets = getFacetByType(searchResult.disabledFacetFilters, FacetType.Value, searchResult?.facets.values, facetsRegistered);
            valueFacets.forEach(f => {
                facetsUnregistered.push(f);
            });

            currentKnowledge?.jsonSchemaFields?.forEach(m => {
                const elementToAdd = facetsUnregistered.find(f => f.field && f.field === m.name);
                if (elementToAdd) {
                    facets.push(elementToAdd);
                }
            })

            facetsRegistered.forEach(val => facets.push(val));

            dispatch(setGroupedFacets(facets));
            setCopyFacets(facets);
        }
        // eslint-disable-next-line
    }, [searchResult]);


    return (
        <>
            {knowledgelab.isLoading ? <div className={classNames.spinner}>
                <TeamsSpinner />
            </div> :
                <Stack style={{ height: '100%', display: 'flex', flexFlow: 'row', padding: '0 20px', justifyContent: emptyResults(true) ? 'center' : 'space-between' }}>

                    <Stack horizontal={true} style={{ height: 'calc(100vh - 100px)', minWidth: '300px' }}>
                        <StackItem style={{ width: '100%' }}>
                            {filtersPanel()}
                        </StackItem>
                    </Stack>

                    <div className={classNames.root} >
                        <div className={classNames.searchContainer}>
                            <TextField
                                disabled={loading || !currentKnowledge?.id}
                                key={currentKnowledge?.id}
                                placeholder={t("searchBoxKnowledge")}
                                value={keyword}
                                styles={classNames.subComponentStyles.textFieldStyle}
                                onChange={(_, value) => setKeyword(value || '')}
                                iconProps={{
                                    iconName: 'search',
                                    styles: {
                                        root: {
                                            color: palette.themePrimary,
                                            fontSize: '25px',
                                            paddingBottom: '4px',
                                            cursor: 'pointer',
                                            pointerEvents: 'auto'
                                        }
                                    },
                                    onClick: () => searchElements()
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter')
                                        searchElements();
                                }
                                }
                            />
                            <TooltipHost
                                content={t('multiLanguage.tooltipLabel')}
                                styles={classNames.subComponentStyles.checkBoxStyle}>
                                <Checkbox
                                    label={t('multiLanguage.labelCheckBox')}
                                    onChange={(ev, checked) => onCheckBoxChange(ev, checked)}
                                    checked={multiLanguageCheckBox}
                                />
                            </TooltipHost>
                        </div>
                        {loading &&
                            <div className={classNames.spinner}>
                                <TeamsSpinner />
                            </div>
                        }
                        {wrongResult}
                        {!error && resultSection}
                    </div>
                </Stack>}

        </>

    );
}