import React, { useEffect, useMemo, useState } from "react";
import { classNamesFunction, CommandBar, DefaultButton, DialogFooter, ICommandBarItemProps, IconButton, IIconProps, IStyleFunctionOrObject, Modal, PrimaryButton, ResponsiveMode, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { IKnowledgeDetailsModalPropsStyles, IKnowledgeDetailsModalStyles, IKnowledgeDetailsModalProps } from "./knowledgeDetailsModal.types";
import { setSubParagraphModalOpen, useKnowledgeLabContent } from "../../../features/knowledgeLabContent";
import { knowledgeLabApi } from "../../../services/knowledgeLab.api";
import { KnowledgeElement } from "../../../models/knowledgeElement";
import { useKnowledgeLabSelector } from "../../../knowledgeLabStore";
import { useTranslation } from "react-i18next";
import { ActionEntry, getKnowledgeModalActions } from "../../../utilities/knowledgeLabActions";
import { useActionDelegate } from "../../../hooks/useActionDelegate";
import JsonForm from "../../../../common/components/tagPanel/jsonForm/jsonForm";
import { KnowledgeLabRoleId } from "../../../models/knowledgeLabRoleId";
import { KnowledgeHelpers } from "../../../utilities/helpers";
import { IJsonFormPropsStyles, IJsonFormStyles } from "../../../../common/components/tagPanel/jsonForm/jsonForm.types";
import CopyActionCommand from "../copyActionButton/copyActionCommand";
import SubParagraphModal from "../subParagraphModal/subParagraphModal";
import { useDispatch } from "react-redux";
/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable @typescript-eslint/no-explicit-any */


const getClassNames = classNamesFunction<IKnowledgeDetailsModalPropsStyles, IKnowledgeDetailsModalStyles>();

const initialIconProps: IIconProps = {
    iconName: 'Cancel'
}

export const KnowledgeDetailsModalBase = (props: IKnowledgeDetailsModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, width: "60%", height: "80%" });

    const state = useKnowledgeLabContent();
    const { selectedKnowledgeLab } = useKnowledgeLabSelector(state => state.knowledgeLabList);
    const { t, i18n } = useTranslation('common');
    const dispatch = useDispatch();
    const delegate = useActionDelegate();
    const currentLang = (i18n.language).split("-")[0];
    const currentTranslations = selectedKnowledgeLab?.configurations?.translations;
    const modalTitle = KnowledgeHelpers.getKnowledgeTranslation("elementPlaceHolder", currentTranslations, currentLang);
    const defaultElementTitle = KnowledgeHelpers.getKnowledgeTranslation("defaultTitle", currentTranslations, currentLang);
    const fieldSecondContent = selectedKnowledgeLab?.configurations?.fieldSecondContent ?? "";

    const [userActions, setUserActions] = useState<ActionEntry[]>([]);
    const [apiErrorForm, setApiErroForm] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [initializationFormData, setInitializationFormData] = useState<any | undefined>(undefined);
    const [formData, setFormData] = useState<any | undefined>(undefined);
    const [formErrorsList, setFormErrorsList] = useState<any | undefined>(undefined);
    const [elementData, setElementData] = useState<KnowledgeElement>();
    const [loading, setLoading] = useState(false);
    const [metadataloading, setMetadataLoading] = useState(false);

    const readonly = useMemo(() => selectedKnowledgeLab?.roleId === KnowledgeLabRoleId.Reader, [selectedKnowledgeLab?.roleId]);

    useEffect(() => {
        if (selectedKnowledgeLab?.roleId !== undefined) {

            let actions = getKnowledgeModalActions(selectedKnowledgeLab, selectedKnowledgeLab.roleId, selectedKnowledgeLab.configurations.enableSecondContent);

            if (elementData?.isFavourite ?? false)
                actions = actions.filter(a => !a.hideIfElementIsFavourite)
            else
                actions = actions.filter(a => !a.hideIfElementIsNotFavourite)

            setUserActions(actions);
        }
    }, [selectedKnowledgeLab, selectedKnowledgeLab?.roleId, elementData?.isFavourite]);

    const loadData = (id: string, increaseViews: boolean) => {
        setLoading(true);
        knowledgeLabApi.getElementDetails(id, increaseViews).then((data) => {
            setElementData(data);
            setInitializationFormData(data.metadata);
            setLoading(false);
        });
    }

    useEffect(() => {

        if (state.selectedElement?.id && props.isOpen) {
            loadData(state.selectedElement?.id, state.selectedElement.increaseViews ?? true)
        }

    }, [state.selectedElement]); //eslint-disable-line react-hooks/exhaustive-deps


    const mapActions = (actions: ActionEntry[], delegate: (action: ActionEntry) => void): ICommandBarItemProps[] => {
        return actions.map(action => {
            return {
                key: action.id.toString(),
                text: t(action.label ?? ""),
                iconOnly: !action.label,
                iconProps: { iconName: action.icon },
                onClick: (ev) => { delegate(action) },
                disabled: false
            }
        });
    }

    const saveAndCloseModal = () => {
        setMetadataLoading(true);
        knowledgeLabApi.updateElementMetadata(state.selectedElement?.id as string, formData).then((res) => {
            if (props.onCloseClick && !res)
                props.onCloseClick();
            setMetadataLoading(false);
        }).catch((e) => {
            setMetadataLoading(false);
            setApiErroForm(true)
        });
    }

    const jsonFomrStyle: IStyleFunctionOrObject<IJsonFormPropsStyles, IJsonFormStyles> = {
        arrayFormStyle: {
            "div[class*= 'titleSection']": {
                fontSize: "inherit",
                fontWeight: "normal",
                margin: 0,
            }
        },
        arrayFormDisabledStyle: {
            "div[class*= 'arrayFieldItem']": {
                gap: 0,
                paddingTop: 0,
                "div": {
                    maxWidth: "inherit!important"
                }
            },
            ".ms-Button": {
                display: "none"
            },
            ".ms-TextField-fieldGroup::before": {
                display: "none"
            }
        }
    }

    const modalBodyMetadataFooter = () => {
        return !readonly &&
            <DialogFooter>
                <div style={{ display: "flex", justifyContent: "left", gap: 10 }}>
                    <DefaultButton onClick={props.onCloseClick} text={t('common:cancel')} />
                    <PrimaryButton
                        disabled={!formErrorsList || (formErrorsList && formErrorsList.length > 0) || loading || !formData}
                        onClick={() => saveAndCloseModal()} text={t('common:save')} />
                </div>
            </DialogFooter>
    }

    const renderJsonForm = useMemo(() => {
        return apiErrorForm ? <div className={classNames.loadingErrorContainer}>
            <span>{t('genericErrorApi')}</span>
            <IconButton onClick={() => {
                if (state.selectedElement?.id)
                    loadData(state.selectedElement.id, true)
                setApiErroForm(false);
            }} iconProps={{ iconName: 'Refresh' }}></IconButton>
        </div> :
            <>
                {metadataloading ? <div className={classNames.loadingErrorContainer}>
                    <Spinner size={SpinnerSize.large} />
                </div> :
                    <div style={{ paddingBottom: 100 }}>
                        <JsonForm
                            hasErrorFE={() => setApiErroForm(true)}
                            styles={jsonFomrStyle}
                            onChangeSearchText={(str) => setSearchText(str)}
                            searchText={searchText}
                            disabled={readonly}
                            onError={(errors) => console.log("ERRORI: ", errors)}
                            onSubmit={() => console.log("submit")}
                            formData={initializationFormData}
                            schema={{ ...selectedKnowledgeLab?.jsonSchema }}
                            uiSchema={selectedKnowledgeLab?.uiSchema}
                            onChange={(ev) => {
                                setFormData({ ...ev.formData });
                                setFormErrorsList([...ev.errors])
                            }}
                        />

                    </div>
                }
            </>
    },
        [metadataloading, apiErrorForm, readonly, searchText, loading]) //eslint-disable-line react-hooks/exhaustive-deps


    const modalBody =
        <>
            <Stack>
                {loading ? <div className={classNames.loadingErrorContainer}>
                    <Spinner size={SpinnerSize.large} />
                </div> :
                    <>
                        <Stack.Item style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                            <span>{elementData?.metadata[elementData.titleFieldName] ? elementData.metadata[elementData.titleFieldName] : defaultElementTitle}</span>
                        </Stack.Item>
                        <Stack.Item style={{ height: 'calc(100% - 80px)' }}>
                            <Stack horizontal style={{ height: '100%' }}>

                                <Stack style={{ width: '40%' }}>

                                    <Stack.Item style={{ height: 'calc(100% - 80px)', overflowY: 'scroll' }} className={classNames.jsonform}>
                                        {renderJsonForm}
                                    </Stack.Item>

                                    <Stack.Item>{modalBodyMetadataFooter()}</Stack.Item>
                                </Stack>

                                <Stack style={{ width: '60%' }}>
                                    <Stack.Item>
                                        <div className={classNames.copyButtonDiv}>
                                            <CopyActionCommand
                                                content={elementData?.metadata["content"]}
                                                textPlaceHolder={modalTitle}
                                            />
                                        </div>
                                    </Stack.Item>
                                    <Stack.Item style={{ overflowY: 'scroll' }}>
                                        <p>{elementData?.metadata["content"]}</p>
                                    </Stack.Item>
                                </Stack>

                            </Stack>
                        </Stack.Item>
                    </>

                }

            </Stack>
        </>

    const actions: ICommandBarItemProps[] = mapActions(userActions, delegate);

    const modalFooter =
        <div>
            <CommandBar items={actions}></CommandBar>
        </div>


    return (

        <>
            <Modal
                isOpen={props.isOpen}
                className={props.className}
                containerClassName={classNames.container}
                responsiveMode={ResponsiveMode.large}
                scrollableContentClassName={classNames.content}
                onDismissed={props.onCloseClick}
                isBlocking={true}
            >
                <div className={classNames.upperContent}>
                    <div>
                        <div className={classNames.header}>
                            <div className={classNames.title}>{modalTitle ? modalTitle : props.title}</div>
                            <div className={classNames.closeIcon}>
                                <IconButton
                                    styles={classNames.subComponentStyles.iconButton()}
                                    iconProps={{ ...initialIconProps }}
                                    onClick={props.onCloseClick}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classNames.body}>
                        <div className={classNames.bodyContent}>
                            {modalBody}
                        </div>
                    </div>
                </div>
                <div className={classNames.footer}>{modalFooter}</div>
            </Modal>
            <SubParagraphModal
                content={elementData?.metadata[fieldSecondContent]}
                title={elementData?.metadata[elementData.titleFieldName] ? elementData.metadata[elementData.titleFieldName] : defaultElementTitle}
                isOpen={state.isSubParagraphModalOpen}
                onCloseClick={() => dispatch(setSubParagraphModalOpen(false))}
            />
        </>


    );
}