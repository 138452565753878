import { IStyleFunction } from "@fluentui/react"
import { IGraphActivityForLeaderPropsStyles, IGraphActivityForLeaderStyles } from "./graphActivityForLeader.types"

export const GraphActivityForLeaderGlobalClassNames = {
    root: 'edi-graph-activity-for-leader-root'
}

export const getStyles: IStyleFunction<IGraphActivityForLeaderPropsStyles, IGraphActivityForLeaderStyles> = (props: IGraphActivityForLeaderPropsStyles): IGraphActivityForLeaderStyles => {
    return {
        root: [
            GraphActivityForLeaderGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}