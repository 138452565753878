import { styled } from "@fluentui/react";
import { getStyles } from "./compareDocumentsModal.styles";
import { ICompareDocumentsModalPropsStyles, ICompareDocumentsModalStyles, ICompareDocumentsModalProps } from "./compareDocumentsModal.types";
import { CompareDocumentsModalBase } from "./compareDocumentsModal.base";

const CompareDocumentsModal = styled<ICompareDocumentsModalProps, ICompareDocumentsModalPropsStyles, ICompareDocumentsModalStyles>(
    CompareDocumentsModalBase,
    getStyles,
    undefined,
    { scope: 'CompareDocumentsModal' }
);

export default CompareDocumentsModal;