import React, { useEffect, useState } from "react";
import { DefaultButton, DefaultPalette, Icon, IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, keyframes, PrimaryButton, TextField } from "@fluentui/react";
import { IEditProfileModalBaseProps } from "./editProfileModal.types";
import EdiModal from "../../../../../../common/components/ediModal/ediModal";
import { useTranslation } from "react-i18next";
import { useDocLabDispatch } from "../../../../../docLabStore";
import { modifyProfile } from "../../../../../features/profileContent";
import { archivesApi } from "../../../../../services/archives/archives.api";

export const EditProfileModalBase = (props: IEditProfileModalBaseProps) => {
    const { t } = useTranslation(['profileManagement']);
    const [validationIcon, setValidationIcon] = useState('Error');
    const dispatch = useDocLabDispatch();
    const [name, setName] = useState(props.profile?.name);
    const [description, setDescription] = useState(props.profile?.description);
    const [continueEnable, setContinueEnable] = useState(false);
    const [nameValid, setNameValid] =  useState(false);

    useEffect(() => {
        if(!name ||
            !nameValid ||
            (props.profile?.description === description && props.profile?.name === name)
            ) {
            setContinueEnable(false);
        }
        else{
            setContinueEnable(true);
        }        
    }, [description, nameValid, props.profile?.description, props.profile?.name, name])

    const validateArchiveName = async (value: string) => {
        setName(value);

        if (!value){
            setValidationIcon('Error')
            setNameValid(false);
            return `${t('common:fieldRequired')}`;
        }
        setValidationIcon('ProgressRingDots');

        try {
            if(props.profile?.name === value) {
                setValidationIcon('Completed');
                setNameValid(true);
                return "";
            }

            const [isValid] = await Promise.all([archivesApi.isProfileNameAvailable(props.archiveId, value)]);
            if(isValid){
                setValidationIcon('Completed');
                setNameValid(true);
                return "";
            }

            setValidationIcon('Error');
            setNameValid(false);
            return t('profileView.editModal.nameNotAvailable');
        }
        catch {
            return t('common:genericErrorCheckName');
        }
    }

    const styles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = () => {
        return {
            errorMessage: {
                position: 'absolute'
            },
            suffix: [
                { background: 'transparent', },
                validationIcon === 'Error' && { color: DefaultPalette.redDark },
                validationIcon === 'Completed' && { color: DefaultPalette.greenDark },
                validationIcon === 'ProgressRingDots' && {
                    '> i': {
                        animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite`
                    }
                }
            ],
            root: {
                paddingBottom: '25px'
            }
        }
    }

    const body = (
        <>
            <div>
                <TextField
                    label={t('profileView.editModal.nameTitle')}
                    placeholder={t('profileView.editModal.namePlaceholder')}
                    deferredValidationTime={250}
                    onGetErrorMessage={validateArchiveName}
                    required
                    defaultValue={props.profile?.name}
                    autoFocus
                    autoComplete={"off"}
                    styles={styles}
                    onRenderSuffix={() => <Icon iconName={validationIcon} />}
                />
            </div>
            <div>
                <TextField
                    label={t('profileView.editModal.descriptionTitle')}
                    resizable={false}
                    rows={4}
                    multiline
                    defaultValue={props.profile?.description}
                    placeholder={t('profileView.editModal.descriptionPlaceholder')}
                    deferredValidationTime={250}
                    onChange={(_, newValue) => setDescription(newValue)}
                />
            </div>
        </>
    )

    const footer = (<div>
        <DefaultButton 
            onClick={ props.onClose } 
            style={{ margin: '0px 4px' }}
        >
            {t('common:leave')}
        </DefaultButton>
        <PrimaryButton
            onClick={() => {
                dispatch(modifyProfile(
                {
                    description: description,
                    name: name ?? '',
                    profileId: props.profile?.id ?? 0
                }));
                props.onClose();
            }}
            style={{ margin: '0px 4px' }}
            disabled={!continueEnable}
        >
            {t('common:save')}
        </PrimaryButton>
    </div>)

    return (
        <EdiModal 
            title={t('profileView.editModal.title')}
            body={body}
            isOpen={props.isOpen}
            height={600}
            width={600}
            footer={footer}
            onCloseClick={props.onClose}
            showCloseIcon={true}
            styles={{body: {overflow: 'unset'}}}
        />
    )   
}