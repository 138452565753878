import { IStyleFunction } from "@fluentui/react"
import { IUploadFileModalPropsStyles, IUploadFileModalStyles } from "./uploadFileModal.types"

export const getStyles: IStyleFunction<IUploadFileModalPropsStyles, IUploadFileModalStyles> = (props: IUploadFileModalPropsStyles): IUploadFileModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        body: {
            height: '100%'
        },
        leftContainer: {
            marginRight: 10,
            height: '100%'
        },
        dropzoneBorders: {
            border: '2px dashed ' + palette.neutralSecondary,
            borderRadius: '25px',
            padding: 10,
            height: '100%',
            boxSizing: 'border-box',
        },
        dropzoneContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            color: palette.neutralSecondary,
            '> :not(:first-child)': {
                marginTop: 10
            }
        },
        rightContainer: {
            '> :not(:first-child)': {
                marginTop: 20
            }
        },
        disclaimers: {
            '> :not(:first-child)': {
                marginTop: 5
            }
        },
        pickerErrors: {},
        fileList: {},
        detailsListContainer: {
            " .ms-DetailsRow-cell": {
                display: 'flex',
                alignItems: 'center'
            }
        },
        wellDoneImage: {
            flexBasis: '50%',
            flexShrink: 0
        },
        secondFooterContainer: {
            display: 'flex',
            alignItems: 'center',
            height: '100%'
        },
        subfirstFooterContainer: {
            display: 'flex',
            justifyContent: 'space-between' //eslint-disable-line sonarjs/no-duplicate-string
        },
        firstFooterContainer: {
            paddingTop: '10px',
            textAlign: 'right'
        },
        advancedFooterContainer: {
            paddingTop: '0px',
            marginTop: '0px',
            textAlign: 'right',
            marginRight: '15px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        advancedFooterContainerBack: {
            paddingTop: '0px',
            marginTop: '0px',
            textAlign: 'right',
            marginRight: '15px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        footer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center'
        },
        spinner: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        progressIndicatorbody: {
            width: '30%'
        },
        progressIndicatoritemName: {
            textAlign: 'left'
        },
        progressIndicatoritemDescription: {
            textAlign: 'right'
        },
        errorMessage: {
            color: palette.redDark,
            display: 'flex',
            alignItems: 'center'
        }
    };
}