import { styled } from "@fluentui/react";
import { RowReportBase } from "./RowReport.base";
import { getStyles } from "./RowReport.styles";
import { IRowReportBaseProps, IRowReportBasePropsStyles, IRowReportBaseStyles } from "./RowReport.types";

const RowReport = styled<IRowReportBaseProps, IRowReportBasePropsStyles, IRowReportBaseStyles>(
    RowReportBase,
    getStyles,
    undefined,
    { scope: 'RowReport'}
)

export default RowReport;