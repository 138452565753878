import React, { useEffect,  useMemo,  useState } from "react";
import { classNamesFunction, DefaultButton, DialogFooter, IconButton, IStyleFunctionOrObject, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { IDocumentTagPanelPropsStyles, IDocumentTagPanelStyles, IDocumentTagPanelProps } from "./documentTagPanel.types";
import { useTranslation } from "react-i18next";
import { useOnMount } from "../../../utilities/hooks";
import { ElementShort } from "../../models/element";
import ShortElementViewer from "./shortElementViewer/shortElementViewer";
import { knowledgeLabApi } from "../../services/knowledgeLab.api";
import JsonForm from "../../../common/components/tagPanel/jsonForm/jsonForm";
import { useKnowledgeLabSelector } from "../../knowledgeLabStore";
import { KnowledgeLabRoleId } from "../../models/knowledgeLabRoleId";
import { KnowledgeHelpers } from "../../utilities/helpers";
import TeamsImage from "../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../common/components/teamsImage/teamsImage.types";
import { IJsonFormPropsStyles, IJsonFormStyles } from "../../../common/components/tagPanel/jsonForm/jsonForm.types";

/*eslint-disable @typescript-eslint/no-explicit-any */
/*eslint-disable sonarjs/cognitive-complexity */

const getClassNames = classNamesFunction<IDocumentTagPanelPropsStyles, IDocumentTagPanelStyles>();

export const DocumentTagPanelBase = (props: IDocumentTagPanelProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t, i18n } = useTranslation(['knowledgeDocuments', 'common']);
    const [elements, setElements] = useState<ElementShort[]>([]);
    const { selectedKnowledgeLab } = useKnowledgeLabSelector(state => state.knowledgeLabList);

    const [apiErrorForm, setApiErroForm] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [formData, setFormData] = useState<any | undefined>(undefined);
    const [initializationFormData, setInitializationFormData] = useState<any | undefined>(undefined);
    const [formErrorsList, setFormErrorsList] = useState<any | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [filteredJsonSchema, setFilteredJsonSchema] = useState<any>();
    const [metadataloading, setMetadataLoading] = useState(false);
    const [elementPivotTitle, setElementPivotTitle] = useState("");

    const currentLang = (i18n.language).split("-")[0];
    const currentTranslations = selectedKnowledgeLab?.configurations?.translations;
    const entitiesName = KnowledgeHelpers.getKnowledgeTranslation("elementsPlaceHolder", currentTranslations, currentLang);
    const readonly = useMemo(() => selectedKnowledgeLab?.roleId === KnowledgeLabRoleId.Reader, [selectedKnowledgeLab?.roleId]);
    useOnMount(() => {
        if (props.fileId)
            knowledgeLabApi.getFileElements(props.fileId)
                .then((result) => {
                    setElements(result);
                })
    });

    const loadData = (id: number) => {
        setLoading(true);
        knowledgeLabApi.getFileMetadata(id)
            .then((data) => {
                setInitializationFormData(data)
                setLoading(false);
            })
            .catch((err) => {
                setFormData(null)
                setLoading(false);
            });
    }

   
   

    useEffect(() => {

        if (props.fileId) {
            loadData(props.fileId)
        }

    }, [props.fileId]);

    useEffect(() => {
        if (selectedKnowledgeLab?.commonProperties) {
            const jsonSchema: any = { properties: {} };
            selectedKnowledgeLab?.commonProperties.forEach((p) => {
                jsonSchema["properties"][p] = selectedKnowledgeLab.jsonSchema["properties"][p];
            });
            setFilteredJsonSchema(jsonSchema);
        }
    }, [selectedKnowledgeLab?.id]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setElementPivotTitle(`${entitiesName} (${elements.length})`)
    }, [elements, entitiesName]);

    const saveAndCloseModal = () => {
        setMetadataLoading(true);
        knowledgeLabApi.updateDocumentMetadata(props.fileId!.toString(), formData).then((res) => { //eslint-disable-line @typescript-eslint/no-non-null-assertion
            if (props.dismissPanel && !res)
                props.dismissPanel();
            setMetadataLoading(false);
        }).catch((e) => {
            setMetadataLoading(false);
            setApiErroForm(true)
        });
    }

    
    const updateElementInElements = (element: ElementShort) => {
        const elementsUpdated = elements.map(e => {
            if (e.id === element.id)
                return { ...e, isFavourite: element.isFavourite }
            else
                return e
        });
        setElements(elementsUpdated)
    }
    const removeElement = (element: ElementShort) => {
        const elementsUpdated = [...elements];
        const i = elementsUpdated.findIndex(e => e.id === element.id)
        elementsUpdated.splice(i, 1);
        if (elementsUpdated.length === 0)
            setFormData(null)
        setElements(elementsUpdated)
    }

    const jsonFomrStyle: IStyleFunctionOrObject<IJsonFormPropsStyles, IJsonFormStyles> = {
        arrayFormStyle: {
            "div[class*= 'titleSection']": {
                fontSize: "inherit",
                fontWeight: "normal",
                margin: 0,
            },
        },
        arrayFormDisabledStyle: {
            "div[class*= 'arrayFieldItem']": {
                gap: 0,
                paddingTop: 0,
                "div": {
                    maxWidth: "inherit!important"
                }
            },
            ".ms-Button": {
                display: "none"
            },
            ".ms-TextField-fieldGroup::before": {
                display: "none"
            }
        }
    }

    const metadataFooter = () => {
        return !readonly && <DialogFooter>
                <DefaultButton                   
                    onClick={props.dismissPanel}
                    text={t('common:cancel')}
                />
                <PrimaryButton
                    disabled={!formErrorsList || (formErrorsList && formErrorsList.length > 0) || loading || !formData}
                    onClick={() => saveAndCloseModal()}>
                    {t('common:save')}
                </PrimaryButton>
        </DialogFooter>
    }


    const settingsContent = useMemo(() => {        
        return  apiErrorForm ? <div className={classNames.loadingErrorContainer}>
                                <span>{t('common:genericErrorApi')}</span>
                                <IconButton onClick={() => {
                                    if (props.fileId)
                                        loadData(props.fileId)
                                    setApiErroForm(false);
                                }} iconProps={{ iconName: 'Refresh' }}></IconButton>
                            </div> :
                                 <div style={{ marginTop: "20px" }}>
                                    {metadataloading ? <div className={classNames.loadingErrorContainer}>
                                        <Spinner size={SpinnerSize.large} />
                                    </div> :
                                        <>
                                            <JsonForm
                                                hasErrorFE={() => setApiErroForm(true)}
                                                onChangeSearchText={(str) => setSearchText(str)}
                                                searchText={searchText}
                                                disabled={readonly}
                                                onError={(errors) => console.log("ERRORI: ", errors)}
                                                onSubmit={() => console.log("submit")}
                                                formData={{...initializationFormData}}
                                                schema={{ ...filteredJsonSchema }}
                                                uiSchema={selectedKnowledgeLab?.uiSchema}
                                                styles={jsonFomrStyle}
                                                onChange={(ev) => {
                                                    setFormData({ ...ev.formData });
                                                    setFormErrorsList([...ev.errors])
                                                   
                                                }}
                                            />                                                                
                                        </>
                                    }
                                      
                                </div>

    }, [metadataloading, apiErrorForm,  readonly, searchText, loading]); //eslint-disable-line react-hooks/exhaustive-deps





    return (
        <Panel
            isOpen={true}
            onDismiss={props.dismissPanel}
            type={PanelType.medium}
            // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
            closeButtonAriaLabel="Close"
            customWidth="690px"
            headerText={`Tag & ${entitiesName}`}
        >
            {loading ? <div className={classNames.loadingErrorContainer}>
                <Spinner size={SpinnerSize.large} />
            </div>
                :
                initializationFormData ?
                    <Pivot className={classNames.pivot}
                    >
                        <PivotItem headerText={t('knowledgeDocuments:tagsPanel.tagHeader')}>

                        {settingsContent}
                        {metadataFooter()}

                        </PivotItem>
                        <PivotItem headerText={elementPivotTitle}>
                            <ShortElementViewer elements={elements} onElementsUpdate={updateElementInElements} onRemoveElement={removeElement} />
                        </PivotItem>
                    </Pivot>
                    :
                    <TeamsImage
                        imageName={ImageName.NoResults}
                        scale={0.3}
                        fullContainer
                        style={{ marginBottom: 30 }}
                        caption={t("knowledgeDocuments:tagsPanel.nothingToShow")}
                    />
            }


        </Panel>
    );
}