import { useKnowledgeLabState, KnowledgeLabDispatch, KnowledgeLabState, useKnowledgeLabDispatch } from "../knowledgeLabStore";
//import { ToolbarAction } from "../features/toolbarActions";
import { ActionEntry, knowledgeLabContentActions } from "../utilities/knowledgeLabActions";
import { INavigator, useNavigator } from "./useNavigator";

export type IActionDelegate = (state: KnowledgeLabState, navigator: INavigator, dispatch: KnowledgeLabDispatch) => void;

export enum ToolbarDelegateType {
    files = 'files'
}

const mapActionsToDelegates = (actions: ActionEntry[]): Record<string, IActionDelegate | undefined> => {
    let mapped: Record<string, IActionDelegate | undefined> = {};
    actions.forEach(action => {
        mapped[action.id] = action.onClick;

        if (action.subActionItems)
            mapped = { ...mapped, ...mapActionsToDelegates(action.subActionItems) };
    });

    return mapped;
}

const toolbarDelegates = {
    [ToolbarDelegateType.files]: mapActionsToDelegates(knowledgeLabContentActions)
}

export const useActionDelegate = () => {
    const navigator = useNavigator();
    const dispatch = useKnowledgeLabDispatch();
    const state = useKnowledgeLabState();

    return (action: ActionEntry) => {
        const delegate = toolbarDelegates[ToolbarDelegateType.files][action.id];

        if (delegate)
            delegate(state, navigator, dispatch);
    }
}