import { IDetailsListStyleProps, IDetailsListStyles, IShimmeredDetailsListStyleProps, IShimmeredDetailsListStyles, IStyleFunction } from "@fluentui/react";
import { IEdiModalPropsStyles, IEdiModalStyles } from "../../../../../common/components/ediModal/ediModal.types";      
import { IFolderNavigationPropsStyles, IFolderNavigationStyles } from "./folderNavigation.types";

export const FolderNavigationGlobalClassNames = {
    root: 'edi-folder-navigation-root',
    breadcrumbContainer: 'edi-folder-breadcrumb-container',
}

export const getStyles: IStyleFunction<IFolderNavigationPropsStyles, IFolderNavigationStyles> = (props: IFolderNavigationPropsStyles): IFolderNavigationStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            FolderNavigationGlobalClassNames.root,
            {
                height: '100%',
                '> .ms-Viewport': {
                    height: '100%'
                },
                // '> .ms-Breadcrumb.root': {
                //     paddingLeft: '8px'
                // }    
            },
            props.isEmptyList && {
                '> .ms-Viewport .ms-DetailsList-contentWrapper': {
                    flexBasis: '0 !important'
                }
            }, 
        ],
        emptyFolder: {
            height: '100px',
        },
        load: {
            display: 'none'
        },
        file: {
            opacity: '0.3', 
            'div:first-child': {
                opacity: '0.3', 
            }
        },
        breadcrumbContainer: {
            display: 'flex'
        },
        iconContainer: {
            display: 'flex', 
            alignItems: 'center', 
            margin: '8px 0 0',
            paddingLeft: '8px'
        },         
        searchContainer: {
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center', 
            background: palette.neutralLight
        },
        subComponentStyles: {
            ediModal: (props: IEdiModalPropsStyles): IEdiModalStyles => {
                return {
                    bodyContent: {
                        overflow: 'unset'
                    },
                    body: {

                    },
                    closeIcon: {

                    },
                    container: {

                    },
                    content: {

                    },
                    footer: {

                    },
                    header: {

                    },
                    subComponentStyles: {
                        iconButton: {

                        }
                    },
                    subTitle: {

                    },
                    title: {

                    }
                }
            },
            shimmeredDetailsList: (props: IShimmeredDetailsListStyleProps): IShimmeredDetailsListStyles => {
                return {
                    root: {
                        '::after': {
                            display: 'none'
                        },
                        position: 'relative'
                    }
                }
            },
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                const { palette } = props.theme;
                return {
                    root: {
                        height: '100%',
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%'
                        },
                        ' .ms-DetailsHeader': {
                            background: palette.white
                        },
                        ' .ms-DetailsRow': {
                            background: palette.white
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto'
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        flexBasis: '100%',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            }
        }
    };
}