import { styled } from "@fluentui/react";
import { CardAccordionBase } from "./cardAccordion.base";
import { getStyles } from "./cardAccordion.styles";
import { ICardAccordionBaseProps, ICardAccordionBasePropsStyles, ICardAccordionBaseStyles } from "./cardAccordion.types";

const CardAccordion = styled<ICardAccordionBaseProps, ICardAccordionBasePropsStyles, ICardAccordionBaseStyles>(
    CardAccordionBase, 
    getStyles,
    undefined,
    { scope: 'CardAccordion'}
)

export default CardAccordion;