import { previewResponse } from "../../docLab/services/archives/archives.contracts";
import { searchDocument } from "../../knowledgeLab/models/searchDocument";
import { apiClient } from "../../modules/apiClient/apiClient";
import { svcPaths } from "../../modules/apiClient/config";
import { KnowledgeDsFilter } from "../components/feedback/feedback.types";
import { KnowledgeDataset } from "../models/dataset";
import { FeedbackStatus, KnowledgeFeedback } from "../models/feedback";
import { FeedbackFilters } from "../models/feedbackFilters";

class KnowledgeDsApiClass {
    async getFeedbackList(filters: KnowledgeDsFilter) {
        const response = await apiClient.post<KnowledgeFeedback[]>(`${svcPaths.knowledges}/knowledgelog/getfeedback`, {
            ...filters
        })
        return response.data;
    }

    async getDatasets(filters: KnowledgeDsFilter) {
        const response = await apiClient.post<KnowledgeDataset[]>(`${svcPaths.knowledges}/dataset/getdatasets`, {
            ...filters
        })
        return response.data;
    }

    async updateFeedback(feedbackIds: number[], feedbackStatus: FeedbackStatus) {
        const response = await apiClient.post<KnowledgeFeedback[]>(`${svcPaths.knowledges}/knowledgelog/updatefeedback`, {
            feedbackIds,
            feedbackStatus
        })
        return response.data;
    }

    async previewFromFeedback(fileId: number) {
        const response = await apiClient.get<previewResponse>(`${svcPaths.knowledges}/knowledgelog/${fileId}/preview`);
        return response.data
    }

    async getElementDetails(elementId: number) {
        const response = await apiClient.get<string>(`${svcPaths.knowledges}/knowledgelog/element/${elementId}`)
        return response.data;
    }

    async getDocument(documentId: number) {
        const response = await apiClient.get<searchDocument>(`${svcPaths.search}/searchknowledge/document/${documentId}`)
        return response.data;
    }

    async createDataset(feedbackIds: number[], skillName: string, name?: string) {
        const response = await apiClient.post(`${svcPaths.knowledges}/dataset/createdataset`, {
            FeedbackIds: feedbackIds,
            Name: name,
            SkillName: skillName
        })
        return response.data;
    }

    async getFeedbackFilters() {
        const response = await apiClient.get<FeedbackFilters>(`${svcPaths.knowledges}/knowledgelog/filters`)
        return response.data;
    }
    async executeTraining(datasetId: number) {
        const response = await apiClient.get<string>(`${svcPaths.knowledges}/dataset/${datasetId}/executeTraining`)
        return response.data;
    }
}


export const knowledgeDsApi = new KnowledgeDsApiClass();