import { styled } from "@fluentui/react";
import { getStyles } from "./workflowStartModal.styles";
import { IWorkflowStartModalPropsStyles, IWorkflowStartModalStyles, IWorkflowStartModalProps } from "./workflowStartModal.types";
import { WorkflowStartModalBase } from "./workflowStartModal.base";

const WorkflowStartModal = styled<IWorkflowStartModalProps, IWorkflowStartModalPropsStyles, IWorkflowStartModalStyles>(
    WorkflowStartModalBase,
    getStyles,
    undefined,
    { scope: 'WorkflowStartModal' }
);

export default WorkflowStartModal;