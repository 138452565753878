import { IDetailsListStyleProps, IDetailsListStyles, IIconStyleProps, IIconStyles, IShimmeredDetailsListStyleProps, IShimmeredDetailsListStyles, IStyleFunction } from "@fluentui/react"
import { IArchiveContentPropsStyles, IArchiveContentStyles } from "./archiveContent.types"

export const archiveContentGlobalClassNames = {
    root: 'edi-archive-content-root'
}

export const getStyles: IStyleFunction<IArchiveContentPropsStyles, IArchiveContentStyles> = (props: IArchiveContentPropsStyles): IArchiveContentStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            archiveContentGlobalClassNames.root,
            {
                height: '100%',
                //width: '100%'
                // '> .ms-Viewport': {
                //     height: '100%'
                // }
            },
            props.isEmptyList && {
                '> .ms-Viewport .ms-DetailsList-contentWrapper': {
                    flexBasis: '0 !important',
                }
            }
        ],
        emptyFolder: {
            height: '204px',
            paddingTop: '52px',
            paddingBottom: '174px'
        },
        load:{
            display: 'none'
        },
        subComponentStyles: {
            shimmeredDetailsList: (props: IShimmeredDetailsListStyleProps): IShimmeredDetailsListStyles => {
                return {
                    root: {
                        '::after': {
                            display: 'none'
                        },
                        position: 'relative'
                    }
                }
            },
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto'
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        // flexBasis: 'calc(100vh - 153px)',
                        flexBasis: 'calc(100vh - 201px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {}
                }
            },
            metadataRequiredIcon: (props: IIconStyleProps): IIconStyles => {
                return{
                    root:{
                        cursor:'pointer',
                        color: palette.redDark
                    }
                }
            }
        }
    };
}