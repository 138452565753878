import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useDocLabSelector } from '../docLabStore';

/*eslint-disable @typescript-eslint/no-explicit-any */
export interface DownloadableItem {
    id: number;
    name: string;
}

export type DownloadStatus = {
    file: DownloadableItem;
    downloadFile: (id: number, fn: (progress: number) => void) => Promise<any>;
    progress: number;
    started: boolean;
    success?: boolean;
}

export type DownloadStatusState = {
    downloadQueue: string[];
    status: Record<string, DownloadStatus>;
}

const initialState: DownloadStatusState = {
    downloadQueue: [],
    status: {} as Record<string, DownloadStatus>
}

const DownloadStatusSlice = createSlice({
    name: 'docLab-download-status',
    initialState,
    reducers: {
        startDownload: (state: DownloadStatusState, action: PayloadAction<{ requestId: string, file: DownloadableItem, downloadFunction: (id: number, fn: (progress: number) => void) => Promise<any> }>) => {
            state.downloadQueue.push(action.payload.requestId);
            state.status[action.payload.requestId] = {
                file: action.payload.file,
                progress: -1,
                started: false,
                downloadFile: action.payload.downloadFunction
            }
        },
        setDownloadProgress: (state: DownloadStatusState, action: PayloadAction<{ requestId: string, progress: number }>) => {
            if (!state.downloadQueue.find(requestId => requestId === action.payload.requestId))
                return;

            const status = state.status[action.payload.requestId];
            if (status.progress === 100 || status.progress === action.payload.progress)
                return;

            status.started = true;
            status.progress = action.payload.progress;
        },
        endDownload: (state: DownloadStatusState, action: PayloadAction<{ requestId: string, success: boolean }>) => {
            if (!state.downloadQueue.find(requestId => requestId === action.payload.requestId))
                return;

            const status = state.status[action.payload.requestId];
            status.progress = 100;
            status.success = action.payload.success;
        },
        removeDownload: (state: DownloadStatusState, action: PayloadAction<string>) => {
            _.remove(state.downloadQueue, requestId => requestId === action.payload);
            delete state.status[action.payload];
        }
    }
})

export default DownloadStatusSlice.reducer;
export const { startDownload, setDownloadProgress, endDownload, removeDownload } = DownloadStatusSlice.actions;
export const useDownloadStatus = () => useDocLabSelector(state => state.downloadStatus);