import React, {useEffect, useState} from "react";
import {ActionButton, classNamesFunction, IconButton, IDropdownOption, Label, ScrollablePane, Stack, TextField, TooltipHost} from "@fluentui/react";
import {IFeedbackFiltersProps, IFeedbackFiltersPropsStyles, IFeedbackFiltersStyles} from "./feedbackFilters.types";
import DatePicker from "../../../../common/components/datePicker/datePicker";
import {useTranslation} from "react-i18next";
import {resetFeedbackFilters, setBaseFeedbackFilters, setFilters, useFeedbackList} from "../../../features/feedbackList";
import {useDashboardDispatch} from "../../../dashboardStore";
import {dashboardStatusText, FeedbackStatus} from "../../../models/feedback";
import {Skill} from "../../../models/skill";
import {ModuleInstance} from "../../../models/moduleInstance";
import {FacetType, FlatFacet, ISkillResultsTagValue} from "../../../../knowledgeLab/services/search/search.contracts";
import Tag from "../../../../common/components/tag/tag";
import {useOnMount} from "../../../../utilities/hooks";
import {dashboardApi} from "../../../services/dashboard.api";
import AdvancedDropdown from "../../../../common/components/advancedDropdown/advancedDropdown";
import { mapper } from "../../../../utilities/mapper";

const getClassNames = classNamesFunction<IFeedbackFiltersPropsStyles, IFeedbackFiltersStyles>();

export const FeedbackFiltersBase = (props: IFeedbackFiltersProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['dashboardFeedback', 'common', 'dashboardToolbar']);
    const { filters } = useFeedbackList();
    const dispatch = useDashboardDispatch();
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [selectedFacet, setSelectedFacet] = useState<FlatFacet[]>([]);
    const [moduleInstances, setModuleInstances] = useState<ModuleInstance[]>([]);
    const [skills, setSkills] = useState<Skill[]>([]);
    const [extraPropertyList, setExtraPropertyList] = useState<IDropdownOption[]>([]);
    const [selectedExtraProperty, setSelectedExtraProperty] = useState<number[]>([]);
    const [extraPropertyLoading, setExtraPropertyLoading] = useState<boolean>(false);
    const [key, setKey] = useState<number>(0);

    const generateFeedbackBaseFacet = (feedbackStatus: FeedbackStatus) => {
        return {
            key: "feedbackStatus",
            value: t(dashboardStatusText.find(t => t.key === feedbackStatus)?.value ?? ""),
            type: FacetType.Value,
            text: t(dashboardStatusText.find(t => t.key === feedbackStatus)?.value ?? ""),
        } as FlatFacet
    }

    useOnMount(() => {
        dashboardApi.getFeedbackFilters().then((r) => {
            setModuleInstances(r.moduleInstances);
            setSkills(r.skills);

            if(filters.datasetId && filters.datasetName)
                setSelectedFacet([{
                    key: "datasetId",
                    value: filters.datasetName,
                    type: FacetType.Value,
                    text: filters.datasetName
                }])
            else
                setSelectedFacet([generateFeedbackBaseFacet(filters.feedbackStatus ?? FeedbackStatus.Pending)])

        })
        getExtraPropertyList();
    });

    const getSelectedExtraPropertyValue = () => {
         //recupero i valori dei filtri extraproperty selezionati
        return extraPropertyList
        .map((a, index) => { 
            if (selectedExtraProperty.includes(index)) 
                return a.text
            else return undefined
        }).filter((value): value is string => value !== undefined);
    }

    useEffect(() => {
        setKey(key + 1)        
        const selectedList = getSelectedExtraPropertyValue();
        dispatch(setFilters({ ...filters, extraProperty: selectedList, pageNumber: 0 }));
    }, [selectedExtraProperty]); //eslint-disable-line react-hooks/exhaustive-deps

    const getExtraPropertyList = async () =>{        
        try{
            setExtraPropertyLoading(true);
            let extraproperty = await dashboardApi.getAllFeedbackExtraPropery();
            extraproperty = extraproperty.filter(e => e !== "" && e !== null);
            const extrapropertyListOpt = extraproperty.map((a, index) => mapExtraPropertyToDropOpt(a, index));
            setExtraPropertyList(extrapropertyListOpt);
        }finally{
            setExtraPropertyLoading(false);
        }
    }

    const mapExtraPropertyToDropOpt = (extraProperty: string, index: number): IDropdownOption => {
        return {
            ...mapper.mapExtraPropertyToDropdownOption(extraProperty, index)
        }
    }

    const onRenderSuffix = () =>
        <IconButton
            aria-label="Search"
            iconProps={{ iconName: 'search' }}
            onClick={() => dispatch(setFilters({ ...filters, fileName: fileName, pageNumber: 0 }))}
        />

    const getStatusFilter = () => {
        const values = Object.values(FeedbackStatus) as FeedbackStatus[];
        return values.map(v =>
            <Stack.Item key={v}>
                <span
                    style={{ cursor: 'pointer', fontWeight: ((filters.feedbackStatus === v) ? 'bold' : undefined), color: ((filters.feedbackStatus === v) ? palette.themePrimary : '') }}
                    onClick={() => {
                        dispatch(setFilters({ ...filters, feedbackStatus: v, pageNumber: 0 }));
                        if (selectedFacet) {
                            const currentFacets = [...selectedFacet];
                            const facet = currentFacets.find(f => f.key === "feedbackStatus");
                            const value = t(dashboardStatusText.find(t => t.key === v)?.value ?? "");
                            if (facet) {
                                const index = currentFacets.indexOf(facet);
                                currentFacets[index] = { ...currentFacets[index], value: value }

                            } else {
                                const newFacet: FlatFacet = {
                                    key: "feedbackStatus",
                                    value: value,
                                    type: FacetType.Value,
                                    text: value
                                }

                                currentFacets.push(newFacet)
                            }
                            setSelectedFacet(currentFacets);
                        }}}
                    >
                    {t(dashboardStatusText.find(t => t.key === v)?.value ?? "")}
                </span>
            </Stack.Item>
        );
    }

    const getTagByType = (x: FlatFacet) => {
        if (x.type === FacetType.Value && x.value)
            return getTag(x, x.value.toString(), { ...x, text: x.value.toString() })
        if (x.type === FacetType.Value)
            return x.values?.map((val: string) => {
                return getTag(x, val, { ...x, text: val });
            })
        if (x.type === FacetType.Range || x.type === FacetType.DateRange)
            return getTag(x, x.text || '', x);
    }

    const removeSelectedFacet = (f: FlatFacet) => {
        if (selectedFacet) {
            const newFacets = selectedFacet.filter(i => i.key !== f.key);
            setSelectedFacet(newFacets);
            const currentFilters = { ...filters };
            const prop = f.key as keyof typeof filters;
            currentFilters[prop] = undefined;
            if(!f.key.includes('extraproperty'))
                dispatch(setFilters(currentFilters))
        }

        if(f.key.includes('extraproperty')){
            const targetKey = extraPropertyList.find(e => e.text === f.text)?.key;
            const temp = selectedExtraProperty.filter(e => e !== targetKey);
            setSelectedExtraProperty(temp);
        }
    }

    const getTag = (facet: FlatFacet, value: string, facetToRemove: FlatFacet) => {
        const y: ISkillResultsTagValue = { value: value, title: '', isPinned: false, toDelete: false }; //TODO generalizare tipo in input al componente Tag
        return <Stack.Item key={`StackItem_${facet.key}_${y.value.trim()}`}>
            <Tag
                onCancelSelected={() => removeSelectedFacet(facetToRemove)}
                selectableTag
                small
                key={`${facet.key}_${y.value.trim()}`}
                value={y}
                keyTag={`${facet.key}_${y.value.trim()}`} />
        </Stack.Item>
    }

    const onChangeExtraProperty = (keys: number[]) => {
        const currentFacets = [...selectedFacet];
        //se stiamo togliendo un elemento dal filtro extra property
        if(selectedExtraProperty.length > keys.length){
            const deletedElement = selectedExtraProperty.filter(e => !keys.includes(e));
            let finalFacetList = selectedFacet;
            deletedElement.forEach(d => {
                const facet = currentFacets.find(f => f.key === `extraproperty${d}`);
                if (facet) {
                    finalFacetList = finalFacetList.filter(f => f !== facet);
                }
            })
            setSelectedFacet(finalFacetList);
        }
        else{
            keys.forEach(k => {
                const facet = currentFacets.find(f => f.key === `extraproperty${k}`);
                if (facet) {
                    const index = currentFacets.indexOf(facet);
                    currentFacets[index] = { ...currentFacets[index], value: extraPropertyList[k].text }
                } else {
                    const newFacet: FlatFacet = {
                        key: `extraproperty${k}`,
                        value: extraPropertyList[k].text,
                        type: FacetType.Value,
                    }    
                    currentFacets.push(newFacet)
                }
            });  
            setSelectedFacet(currentFacets);
        }        
        setSelectedExtraProperty(keys);
    }

    return (
        <div style={{
            height: '100%',
            position: 'relative',
            maxHeight: 'inherit',
            paddingLeft: 20
        }}>
            <Stack>
                <ScrollablePane className={classNames.scrollablePane}>
                    <Stack horizontal horizontalAlign={'end'}>
                        <Stack.Item align="end" style={{ marginBottom: "10px" }}>
                            <ActionButton
                                style={{ position: "relative", right: 0 }}
                                onClick={() => {
                                    setSelectedFacet([generateFeedbackBaseFacet(FeedbackStatus.Pending)]);
                                    setFileName('');
                                    setSelectedExtraProperty([]);
                                    dispatch(setBaseFeedbackFilters());
                                }}>
                                <span style={{ color: palette.themePrimary, textDecoration: 'underline' }}>{t('filters.resetFilters')}</span>
                            </ActionButton>
                        </Stack.Item>
                    </Stack>

                    <Stack horizontal style={{ marginTop: '15px' }} className={classNames.filterPanelItem}>
                        <Stack.Item align="center" style={{ alignItems: 'center' }} >
                            <span style={{ fontWeight: 'bold', fontSize: '1em' }}>{t('filters.filter')}</span>
                        </Stack.Item>

                        {selectedFacet?.length !== 0 && <Stack.Item align="center">
                            <TooltipHost
                                content={t('filters.resetFilters')}
                                id={"resetFilters"}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                            >
                                <IconButton iconProps={{ iconName: 'ClearFilter' }} onClick={() => {
                                    setSelectedFacet([]);
                                    setFileName('');
                                    setSelectedExtraProperty([]);
                                    dispatch(resetFeedbackFilters());
                                }} />
                            </TooltipHost>
                        </Stack.Item>}

                    </Stack>
                    <Stack horizontal={true} wrap>
                        {selectedFacet?.map((x: FlatFacet) => {
                            return getTagByType(x)
                        })}
                    </Stack>
                    
                    <Stack.Item className={classNames.filterPanelItem} >
                        <Label>{t('filters.fileName')}</Label>
                        <TextField
                            value={fileName}
                            onRenderSuffix={onRenderSuffix}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter')
                                    dispatch(setFilters({ ...filters, fileName: fileName, pageNumber: 0 }));
                            }}
                            onChange={(e, n) => setFileName(n)} />
                    </Stack.Item>
                    <Stack.Item className={classNames.filterPanelItem} >
                        <Label>{t('filters.property')}</Label>
                        <AdvancedDropdown
                            // key={key.toString()}
                            options={extraPropertyList}
                            multiselect={true}
                            placeholder={t('filters.extraPropertyMultiselectPlaceholder')}
                            disabled={extraPropertyLoading}
                            selectedItems={selectedExtraProperty}
                            className="AdvancedDropdownGlobalClassNames.root"
                            onChangeItems={(keys) => onChangeExtraProperty(keys)}
                        />
                    </Stack.Item>
                    <Stack.Item className={classNames.filterPanelItem}>
                        <Label>{t('filters.feedbackStatus')}</Label>
                        <Stack.Item style={{ margin: '0 0 8px 0' }}>
                            {getStatusFilter()}
                        </Stack.Item>
                    </Stack.Item>

                    {moduleInstances.length > 0 &&
                        <Stack.Item className={classNames.filterPanelItem}>
                            <Label>{t('filters.moduleInstanceName')}</Label>
                            <Stack.Item style={{ margin: '0 0 8px 0' }}>
                                {
                                    moduleInstances.map(l =>
                                        <Stack.Item key={l.id}>
                                            <span style={{ cursor: 'pointer', fontWeight: ((filters.moduleInstanceId === l.id) ? 'bold' : undefined), color: ((filters.moduleInstanceId === l.id) ? palette.themePrimary : '') }} onClick={() => {
                                                dispatch(setFilters({ ...filters, moduleInstanceId: l.id, pageNumber: 0 }));
                                                if (selectedFacet) {
                                                    const currentFacets = [...selectedFacet];
                                                    const facet = currentFacets.find(f => f.key === "moduleInstanceId");
                                                    if (facet) {
                                                        const index = currentFacets.indexOf(facet);
                                                        currentFacets[index] = { ...currentFacets[index], value: l.moduleInstanceName }

                                                    } else {
                                                        const newFacet: FlatFacet = {
                                                            key: "moduleInstanceId",
                                                            value: l.moduleInstanceName,
                                                            type: FacetType.Value,
                                                            text: l.moduleInstanceName
                                                        }

                                                        currentFacets.push(newFacet)
                                                    }
                                                    setSelectedFacet(currentFacets);
                                                }
                                            }}>
                                                {l.moduleInstanceFullName}
                                            </span>
                                        </Stack.Item>
                                    )
                                }
                            </Stack.Item>
                        </Stack.Item>
                    }

                    {skills.length > 0 &&
                        <Stack.Item className={classNames.filterPanelItem}>
                            <Label>{t('filters.skillName')}</Label>
                            <Stack.Item style={{ margin: '0 0 8px 0' }}>
                                {
                                    skills.map(s =>
                                        <Stack.Item key={s.id}>
                                            <span style={{ cursor: 'pointer', fontWeight: ((filters.skillId === s.id) ? 'bold' : undefined), color: ((filters.skillId === s.id) ? palette.themePrimary : '') }}
                                                title={s.description ? s.description : t('filters.noDescription')}
                                                onClick={() => {
                                                    dispatch(setFilters({ ...filters, skillId: s.id, pageNumber: 0 }));
                                                    if (selectedFacet) {
                                                        const currentFacets = [...selectedFacet];
                                                        const facet = currentFacets.find(f => f.key === "skillId");
                                                        if (facet) {
                                                            const index = currentFacets.indexOf(facet);
                                                            currentFacets[index] = { ...currentFacets[index], value: s.name }

                                                        } else {
                                                            const newFacet: FlatFacet = {
                                                                key: "skillId",
                                                                value: s.name,
                                                                type: FacetType.Value,
                                                                text: s.name
                                                            }

                                                            currentFacets.push(newFacet)
                                                        }
                                                        setSelectedFacet(currentFacets);
                                                    }

                                                }}
                                            >
                                                {s.name}
                                            </span>
                                        </Stack.Item>
                                    )
                                }
                            </Stack.Item>
                        </Stack.Item>
                    }

                    {/* <Stack.Item className={classNames.filterPanelItem}>
                        <Label>{t('filters.attributeProperty')}</Label>
                    </Stack.Item> */}

                    <Stack.Item className={classNames.filterPanelItem}>
                        <Label>{t('filters.creationDate')}</Label>
                        <Stack horizontal>
                            <Stack.Item style={{ width: '246px' }}>
                                <DatePicker
                                    placeholder={t('filters.from')}
                                    inputValue={filters.dateFrom}
                                    maxDate={filters.dateTo}
                                    dateCallback={e => {
                                        if (e) {
                                            const c = new Date(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate()));
                                            dispatch(setFilters({ ...filters, dateFrom: c, pageNumber: 0 }));
                                        }
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                {
                                    filters.dateFrom &&
                                    <ActionButton
                                        style={{ height: '35px' }}
                                        iconProps={{ iconName: 'cancel' }}
                                        onClick={() => {
                                            dispatch(setFilters({ ...filters, dateFrom: undefined, pageNumber: 0 }));
                                        }}>
                                    </ActionButton>
                                }
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                </ScrollablePane>
            </Stack>

        </div>
    );
}
