import { IStyleFunction } from "@fluentui/react"
import { ICreateFolderModalPropsStyles, ICreateFolderModalStyles } from "./createFolderModal.types"

export const CreateFolderModalGlobalClassNames = {
    root: 'edi-create-folder-modal-root',
    bodyModal: 'edi-create-folder-modal-body',
    buttonContainer: 'edi-create-folder-modal-buttonContainer',
    spinner: 'edi-create-folder-modal-spinner',
    labelMessage: 'edi-create-folder-modal-label',
    actionContainer: 'edi-create-folder-modal-actionContainer'
}

export const getStyles: IStyleFunction<ICreateFolderModalPropsStyles, ICreateFolderModalStyles> = (props: ICreateFolderModalPropsStyles): ICreateFolderModalStyles => {

    return {
        root: [
            CreateFolderModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        bodyModal: [
            CreateFolderModalGlobalClassNames.bodyModal,
            {
                overflow: 'hidden',
                minHeight: '55px'
            }
        ],
        createButtonContainer: [
            CreateFolderModalGlobalClassNames.buttonContainer,
            {
                display: 'flex',
                justifyContent: 'flex-end'
            }],
        subComponentStyles: {
            textFieldStyleWithError: {
                errorMessage: {
                    position: 'absolute'
                }
            },
            textFieldStyle: {
                errorMessage: {
                    position: 'absolute'
                }
            }
        },
        label: [
            CreateFolderModalGlobalClassNames.bodyModal,
            {
                fontSize: 24,
                fontWeight: 600,
                textAlign: 'center',
                margin: '20px 0'
            }],
        actionContainer: [
            CreateFolderModalGlobalClassNames.actionContainer,
            {
                padding: '20px 50px',
                display: 'flex',
                flexDirection: 'column'
            }],
        spinner: [
            CreateFolderModalGlobalClassNames.spinner,
            {
                margin: 'auto'
            }],
        errorButtons:
            [
                CreateFolderModalGlobalClassNames.bodyModal, {
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignSelf: 'stretch'
                }]
    };
}