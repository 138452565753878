import { styled } from "@fluentui/react";
import { getStyles } from "./graphWorkflowUsers.styles";
import { IGraphWorkflowUsersPropsStyles, IGraphWorkflowUsersStyles, IGraphWorkflowUsersProps } from "./graphWorkflowUsers.types";
import { GraphWorkflowUsersBase } from "./graphWorkflowUsers.base";

const GraphWorkflowUsers = styled<IGraphWorkflowUsersProps, IGraphWorkflowUsersPropsStyles, IGraphWorkflowUsersStyles>(
    GraphWorkflowUsersBase,
    getStyles,
    undefined,
    { scope: 'GraphWorkflowUsers' }
);

export default GraphWorkflowUsers;