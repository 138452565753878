/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useEffect, useState } from "react";
import { showDeleteModal, useArchiveContent, useArchiveListContent } from "../../../features/archiveContent";
import { useDocLabDispatch } from "../../../docLabStore";
import { useTranslation } from "react-i18next";
import { IDeleteModalProps } from "../../common/deleteModal/deleteModal.types";
import { DeleteModalLayoutBase } from "../../common/deleteModal/deleteModalLayout";
import { archivesApi } from "../../../services/archives/archives.api";
import { loadArchives } from "../../../features/archiveList";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { ErrorsType } from "../../../models/callsApi";
import { useAsyncApi } from "../../../../utilities/hooks";
import { useNavigator } from "../../../hooks/useNavigator";

export const DeleteArchiveModalBase = (props: IDeleteModalProps) => {
  const { deleteModalShown } = useArchiveContent();
  const dispatch = useDocLabDispatch();
  const { t } = useTranslation(['deleteArchiveModal', 'common']);
  const archive = useCurrentArchive();
  const archivesList = useArchiveListContent();
  const [errorMessage, setErrorMessage] = useState("");
  const navigator = useNavigator();

  const { execute: deleteArchive, loading, error: err } = useAsyncApi<void, void>({
    func: async () => {
      if (archive === undefined)
        return;
      setErrorMessage("");
      await archivesApi.deleteArchive(archive.id);
      await dispatch(loadArchives());
      if (archivesList.archiveIds[0] && archive.id !== archivesList.archiveIds[0]) {
        navigator.changeArchive(archivesList.archiveIds[0])
      }
      else {
        if (archivesList.archiveIds[0] && archivesList.archiveIds[1] && archive.id === archivesList.archiveIds[0]) {
          navigator.changeArchive(archivesList.archiveIds[1])
        }
      }
      props.onClose && props.onClose();
    }
  });

  useEffect(() => {
    if (!err) {
      setErrorMessage("");
      return;
    }
    const error: ErrorsType = (err as unknown) as ErrorsType;
    switch (error.code) {
      case 403: setErrorMessage(t("errorCode403")); break;
      case 404: setErrorMessage(t("errorCode404")); break;
      default: setErrorMessage(t("common:genericErrorApi"));
    }
  }, [err]);  //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DeleteModalLayoutBase
      {...props}
      Title={t('title')}
      onDismiss={() => { dispatch(showDeleteModal({ show: false })) }}
      SubText={t('subtext')}
      onClick={deleteArchive}
      PrimaryButtonText={t('buttonConfirm')}
      DefaultButtonText={t('buttonCancel')}
      IsOpen={deleteModalShown}
      ErrorMessage={errorMessage}
      Disabled={loading}
    />
  )
}