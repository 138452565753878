import React from "react";
import { IGraphActivityForLeaderProps } from "./graphActivityForLeader.types";
import { ActivityStatus } from "../../../models/constants";
import { useTranslation } from "react-i18next";
import { Helpers } from "../../../../utilities/helpers";
import _ from "lodash";
import { Bar } from "react-chartjs-2";

export const GraphActivityForLeaderBase = (props: IGraphActivityForLeaderProps) => {
    const { palette, semanticColors } = props.theme!;//eslint-disable-line @typescript-eslint/no-non-null-assertion    
    const { activitiesForLeader } = props;
    const { t } = useTranslation('activity');

    const activitiesForLeaderBarOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                title: {
                    color: semanticColors.bodyText
                },
                labels: {
                    color: semanticColors.bodyText
                }
            },
            tooltip: {
                callbacks: {
                    title: (context: any) => { //eslint-disable-line @typescript-eslint/no-explicit-any    
                        const titleArray = context[0].label.split(' ');
                        titleArray.pop();
                        return titleArray.join(' ');
                    },
                    afterLabel: (context: any) => { //eslint-disable-line @typescript-eslint/no-explicit-any                    
                        const activityStatus = context.datasetIndex as ActivityStatus;
                        const userActivities = activitiesForLeader[context.dataIndex].activities;
                        return `${((userActivities.filter(a => a.status === activityStatus)[0].count / _.sum(userActivities.map(ua => ua.count))) * 100).toFixed(2)}%`;
                    }
                }
            }
        },
        scales: {
            y: {
                max: activitiesForLeader && Helpers.getMaxScaleForDashBoard(_.flattenDeep(activitiesForLeader.map(a => a.activities.map(ac => ac.count)))),
                ticks: {
                    beginAtZero: true,
                    color: semanticColors.bodyText
                },
                grid: {
                    color: palette.neutralTertiaryAlt
                }
            },
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    color: semanticColors.bodyText
                },
            }
        }
    };
    const activitiesForLeadersData = {
        labels: activitiesForLeader.map(a => `${a.leader?.firstName} ${a.leader?.lastName} (${_.sum(a.activities.map(ac => ac.count))})`),
        datasets: [
            {
                label: t(`status.${ActivityStatus.Planned}`),
                data: activitiesForLeader.map(a => a.activities.filter(ac => ac.status === ActivityStatus.Planned)[0]?.count),
                backgroundColor: semanticColors.bodyText,
            },
            {
                label: t(`status.${ActivityStatus.OnGoing}`),
                data: activitiesForLeader.map(a => a.activities.filter(ac => ac.status === ActivityStatus.OnGoing)[0]?.count),
                backgroundColor: palette.green,
            },
            {
                label: t(`status.${ActivityStatus.Blocked}`),
                data: activitiesForLeader.map(a => a.activities.filter(ac => ac.status === ActivityStatus.Blocked)[0]?.count),
                backgroundColor: palette.redDark,
            },
            {
                label: t(`status.${ActivityStatus.Closed}`),
                data: activitiesForLeader.map(a => a.activities.filter(ac => ac.status === ActivityStatus.Closed)[0]?.count),
                backgroundColor: palette.yellow,
            },
        ],
    };

    return <Bar data={activitiesForLeadersData} options={activitiesForLeaderBarOptions} />;
}