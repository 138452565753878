import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  useDocLabSelector } from '../docLabStore';
import { analyzes, ISearchAndCompareValue } from '../models/docAnalysis';

export type DocAnalysisContentState = {
    selectedDocAnalysis? : analyzes,
    selectedDocAnalysisResults? : ISearchAndCompareValue[],
    deleteDocAnalysisModalShown: boolean;
    isLoadingDeleteAnalysis: boolean;
}

const initialState: DocAnalysisContentState = {
    deleteDocAnalysisModalShown: false,
    isLoadingDeleteAnalysis: false
}


const docAnalysisContentSlice = createSlice({
    name: 'docLab-docAnalysis-content',
    initialState,
    reducers: {        
        setCurrentAnalysis: (state: DocAnalysisContentState, action: PayloadAction<analyzes>) => {
            state.selectedDocAnalysis = action.payload;
        },
        resetCurrentAnalysis: (state: DocAnalysisContentState) => {
            state.selectedDocAnalysis = undefined;
        },
        showDocAnalysisDeleteModal: (state: DocAnalysisContentState, action: PayloadAction<boolean>) => {
            state.deleteDocAnalysisModalShown = action.payload;
        },
        setIsLoadingDeleteAnalysis: (state: DocAnalysisContentState, action: PayloadAction<boolean>) => {
            state.isLoadingDeleteAnalysis = action.payload;
        },
        setCurrentAnalysisResults: (state: DocAnalysisContentState, action: PayloadAction<ISearchAndCompareValue[]>) => {
            state.selectedDocAnalysisResults = action.payload;
        },
    }
})

export const { showDocAnalysisDeleteModal , setCurrentAnalysis, setCurrentAnalysisResults, resetCurrentAnalysis, setIsLoadingDeleteAnalysis } = docAnalysisContentSlice.actions;
export default docAnalysisContentSlice.reducer;

export const useDocAnalysisContent = () => useDocLabSelector(state => state.docAnalysisContent);