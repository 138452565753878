import { styled } from "@fluentui/react";
import { getStyles } from "./activitiesBoards.styles";
import { IActivitiesBoardsPropsStyles, IActivitiesBoardsStyles, IActivitiesBoardsProps } from "./activitiesBoards.types";
import { ActivitiesBoardsBase } from "./activitiesBoards.base";

const ActivitiesBoards = styled<IActivitiesBoardsProps, IActivitiesBoardsPropsStyles, IActivitiesBoardsStyles>(
    ActivitiesBoardsBase,
    getStyles,
    undefined,
    { scope: 'ActivitiesBoards' }
);

export default ActivitiesBoards;