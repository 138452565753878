/*eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from "react";
import { classNamesFunction, DefaultButton, Dropdown, IconButton, IDropdownOption, PrimaryButton, ScrollablePane, SearchBox, Spinner, SpinnerSize, Stack, StackItem, TooltipHost } from "@fluentui/react";
import { IProfileViewBaseProps, IProfileViewBasePropsStyles, IProfileViewBaseStyles } from "./profileView.types";
import { useOnMount } from "../../../../../utilities/hooks";
import { ArchiveRoleId } from "../../../../models/constants";
import Accordion from "../../../../../common/components/accordion/accordion";
import CardAccordion from "./cardAccordion/cardAccordion";
import MemberList from "../../../../../activityLab/components/activities/common/memberList/memberList";
import { PersonaDetails } from "../../../../../activityLab/models/user";
import { useDocLabDispatch } from "../../../../docLabStore";
import { changeRole, cleanProfiles, deleteProfile, getProfiles, useProfileContent } from "../../../../features/profileContent";
import { ProfileMember } from "../../../../services/archives/archives.contracts";
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../../common/components/ediModal/ediModal";
import EditProfileModal from "./editProfileModal/editProfileModal";
import CreateProfileModal from "./createProfileModal/createProfileModal";
import EditProfileFolderModal from "./editProfileFolderModal/editProfileFolderModal";
import EditViewProfileMember from "./editViewProfileMember/editViewProfileMember";
import { archivesApi } from '../../../../services/archives/archives.api';
import { insertCall, setCall } from "../../../../features/callNotification";
import { nanoid } from "@reduxjs/toolkit";
import { Call, callsList } from "../../../../models/callsApi";
import { ErrorDetails } from "../../../../../modules/apiClient/apiClient";
import { FolderProfileMaxNumber } from "../../../../../common/components/folderTreeViewer/folderManager/IManagerFolder";

const getClassNames = classNamesFunction<IProfileViewBasePropsStyles, IProfileViewBaseStyles>();

export const AvailableRoles: IDropdownOption[] = [
    { key: ArchiveRoleId.Architect, text: "Architect" },
    { key: ArchiveRoleId.Contributor, text: "Contributor" },
    { key: ArchiveRoleId.Professional, text: "Professional" },
    { key: ArchiveRoleId.Uploader, text: "Uploader" },
    { key: ArchiveRoleId.Reader, text: "Reader" }
]

export const ProfileViewBase = (props: IProfileViewBaseProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['profileManagement', 'common']);
    const dispatch = useDocLabDispatch();
    const profileState = useProfileContent();
    const currentArchive = useCurrentArchive();
    const [deleteModal, setDeleteModal] = useState({ opened: false, profileId: 0 });
    const [isDuplicateProfile, setIsDuplicateProfile] = useState<boolean>(false);
    const [createProfileModalIsOpen, setCreateProfileModalIsOpen] = useState(false);
    const [editProfileFolderModalIsOpen, setEditProfileFolderModalIsOpen] = useState(false);
    const [profileToEdit, setprofileToEdit] = useState<number>(0);
    const [editProfileModal, setEditProfileModal] = useState<{ opened: boolean, profile?: ProfileMember }>();
    const [editViewProfileMemberModal, setEditViewProfileMemberModal] = useState<{ opened: boolean, readonly: boolean, profile?: ProfileMember }>();
    const [forceOpenedProfile, setForceOpenedProfile] = useState<string[]>([]);

    useOnMount(() => {
        dispatch(getProfiles({ archiveId: currentArchive?.id ?? 0 }));
    })

    // This will be invoked when component is unmount
    useEffect(() => {
        return () => {
            dispatch(cleanProfiles());
        };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const actionForProfile = (profile: ProfileMember) => {
        return (profile.archiveRoleId === ArchiveRoleId.Owner ? <></> :
            <div style={{ marginLeft: 'auto' }}>
                {profile.description &&
                    <TooltipHost content={profile.description}>
                        <IconButton iconProps={{ iconName: "Info" }}
                            style={{ width: '30px', height: '30px', fontSize: '20px', pointerEvents: 'auto' }}
                            onClick={(e) => { e.stopPropagation(); }}
                        />
                    </TooltipHost>
                }
                <IconButton
                    iconProps={{ iconName: "Edit" }}
                    style={{ width: '30px', height: '30px', fontSize: '20px' }}
                    onClick={(e) => {
                        setEditProfileModal({ opened: true, profile: profile })
                        e.stopPropagation();
                    }}
                />
                {<IconButton
                    iconProps={{ iconName: "Copy" }}
                    style={{ width: '30px', height: '30px', fontSize: '20px' }}
                    disabled={isDuplicateProfile}
                    onClick={async (e) => {
                        e.stopPropagation();
                        duplicateProfile(profile);
                    }}
                />}
                <IconButton
                    iconProps={{ iconName: "Delete" }}
                    style={{ width: '30px', height: '30px', fontSize: '20px' }}
                    onClick={(e) => {
                        setDeleteModal({ opened: true, profileId: profile.id });
                        e.stopPropagation();
                    }}
                />
            </div>
        )
    }

    const duplicateProfile = async (profile: ProfileMember) => {
        const duplicateProfileCall: Call = {
            type: callsList.duplicateProfile,
            nameOperation: t('profileView.duplicateProfile'),
            errors: [
                { code: 409, message: t('profileView.duplicateProfileAlreadyExistError') },
                { code: 500, message: t('profileView.duplicateProfileError') },
                { code: 504, message: t('profileView.duplicateProfileTimeout')}
            ]
        }
        const id = nanoid();
        const payload = { requestId: id, notification: duplicateProfileCall }
        dispatch(insertCall(payload));

        try {
            await archivesApi.duplicateProfile(profile.id)
            setForceOpenedProfile([`${profile.name}_copia`, `${profile.name}_copy`]);
            const successPayload = { requestId: id, success: true, message: t('profileView.successMessage') }
            dispatch(setCall(successPayload));
            setIsDuplicateProfile(true);
            dispatch(getProfiles({ archiveId: currentArchive?.id ?? 0 }));
            setIsDuplicateProfile(false);
        }
        catch (er) {
            const error: ErrorDetails = er as ErrorDetails;
            let errorMessage = '';

            if (error.code === 409)
                errorMessage = t('profileView.duplicateProfileAlreadyExistError');
            else if (error.code === 504)
                errorMessage = t('profileView.duplicateProfileTimeout');
            else
                errorMessage = t('profileView.duplicateProfileError');

            const failurePayload = { requestId: id, success: false, message: errorMessage }
            dispatch(setCall(failurePayload));
        }
    }

    const getRoleComponent = (profile: ProfileMember) => {
        return (profile.archiveRoleId === ArchiveRoleId.Owner ?
            <div>Owner</div> :
            <Dropdown
                options={AvailableRoles}
                onChange={(_, option) => {
                    dispatch(changeRole({ profileId: profile.id, newRoleId: option?.key as ArchiveRoleId }))
                }}
                disabled={profileState.avaiableRolesIsLoading && profileState.changingId === profile.id}
                defaultSelectedKey={profile.archiveRoleId}
            />
        )
    }

    const openUserModal = (profile: ProfileMember) => {
        const iagUsed = currentArchive?.managedByIag;
        if (iagUsed) {
            setEditViewProfileMemberModal({ opened: true, readonly: true, profile: profile })
        }
        else {
            setEditViewProfileMemberModal({ opened: true, readonly: false, profile: profile })
        }
    }

    const getAccordion = () => {
        return profileState.profiles.map((profile, i) => {
            return (
                <Accordion title={`${profile.name} (${profile.members.length})`}
                    key={i}
                    opened={(forceOpenedProfile.includes(profile.name) || profile.archiveRoleId === ArchiveRoleId.Owner) ? true : false}
                    customHeader={actionForProfile(profile)}
                    styles={{ root: { marginTop: '25px', maxWidth: '760px' } }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', overflowX: 'auto' }}>
                        <CardAccordion
                            content={
                                <MemberList
                                    members={profile.members as PersonaDetails[]}
                                    sliceLength={4}
                                    memberRounded
                                />
                            }
                            title={t('profileView.members')}
                            action={() => openUserModal(profile)}
                            actionText={currentArchive?.managedByIag ? t('profileView.view') : t('profileView.viewAndModify')}
                            styles={{ root: { marginRight: '35px' } }}
                        />
                        <CardAccordion
                            content={getRoleComponent(profile)}
                            title={t('profileView.role')}
                            styles={{ root: { marginRight: '35px' } }}
                        />
                        <CardAccordion
                            content={<h2 style={{ margin: '10px 0' }}>{profile.folderCount}</h2>}
                            title={t('profileView.visibility')}
                            styles={{ root: { marginRight: '35px', position: 'relative' } }}
                            actionText={profile.archiveRoleId !== ArchiveRoleId.Owner ? 'Edit' : ''}
                            action={() => {
                                if (profile.archiveRoleId !== ArchiveRoleId.Owner) {
                                    setprofileToEdit(profile.id);
                                    setEditProfileFolderModalIsOpen(true);
                                }
                            }}
                        />
                    </div>
                </Accordion>
            )
        })
    }

    const footer = (
        <div>
            <DefaultButton onClick={() => { setDeleteModal({ opened: false, profileId: deleteModal.profileId }) }} style={{ margin: '0px 4px' }}>
                {t('common:leave')}
            </DefaultButton>
            <PrimaryButton
                onClick={() => {
                    dispatch(deleteProfile(deleteModal.profileId));
                    setDeleteModal({ opened: false, profileId: deleteModal.profileId });
                }}
                style={{ margin: '0px 4px' }}>
                {t('common:commands.delete')}
            </PrimaryButton>
        </div>
    )

    const retriveProfileFolders = (profileId?: number) => {
        let foldersNumber: number | undefined;
        if (profileId !== undefined)
            foldersNumber = profileState.profiles.find(p => p.id === profileId)?.allArchiveFolder;
        else
            foldersNumber = profileState.profiles.find(p => p.archiveRoleId === ArchiveRoleId.Owner)?.allArchiveFolder;

        return foldersNumber !== undefined && foldersNumber >= FolderProfileMaxNumber;
    }

    return (
        <>
            <Stack className={classNames.root}>
                <StackItem style={{ display: 'flex', marginBottom: '30px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <SearchBox
                            styles={{ root: { width: 300, marginRight: '15px' } }}
                            placeholder={t('profileView.profileSearch')}
                            onSearch={(newValue) => {
                                dispatch(getProfiles({ archiveId: currentArchive?.id ?? 0, filter: newValue }));
                            }}
                            onClear={() => {
                                dispatch(getProfiles({ archiveId: currentArchive?.id ?? 0 }));
                            }}
                        />
                        <PrimaryButton
                            iconProps={{ iconName: 'Add' }}
                            styles={{ icon: { height: 'auto' } }}
                            onClick={() => setCreateProfileModalIsOpen(true)}
                            text={t('profileView.createNewProfile')}
                        />
                    </div>
                </StackItem>
                {profileState.profileViewIsLoading ?
                    <div style={{ height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}>
                        <Spinner size={SpinnerSize.large} />
                    </div> : profileState.profiles.length > 0 ?
                        <StackItem style={{
                            maxHeight: 'inherit',
                            height: 'calc(100vh - 340px)',
                            position: 'relative'
                        }}>
                            <ScrollablePane>
                                {getAccordion()}
                            </ScrollablePane>
                        </StackItem> :
                        <TeamsImage
                            imageName={ImageName.SurprisedWoman}
                            style={{
                                height: '200px',
                                width: '400px',
                                alignSelf: 'center',
                                marginTop: '50px'
                            }}
                            caption={t('profileView.noProfile')}
                        />
                }
            </Stack>
            <EdiModal
                body={<p>{t('profileView.cancelModalText')}</p>}
                height={280}
                width={600}
                title={t('profileView.cancelModalTitle')}
                onCloseClick={() => { setDeleteModal({ opened: false, profileId: deleteModal.profileId }) }}
                isOpen={deleteModal.opened}
                showCloseIcon={true}
                footer={footer}
                styles={{ body: { overflow: 'unset' } }}
            />
            <EditProfileModal
                isOpen={editProfileModal?.opened ?? false}
                profile={editProfileModal?.profile}
                onClose={() => setEditProfileModal({ opened: false, profile: undefined })}
                archiveId={currentArchive?.id ?? 0}
            />
            {editViewProfileMemberModal?.opened &&
                <EditViewProfileMember
                    isOpen={editViewProfileMemberModal?.opened ?? false}
                    profile={editViewProfileMemberModal?.profile}
                    readonly={editViewProfileMemberModal?.readonly ?? false}
                    onClose={() => setEditViewProfileMemberModal({ opened: false, readonly: true, profile: undefined })}
                    archiveId={currentArchive?.id ?? 0}
                />
            }
            {createProfileModalIsOpen &&
                <CreateProfileModal
                    onClose={() => { setCreateProfileModalIsOpen(false) }}
                    onComplete={() => {
                        dispatch(getProfiles({ archiveId: currentArchive?.id ?? 0 }));
                        setCreateProfileModalIsOpen(false);
                    }}
                    isOpen={createProfileModalIsOpen}
                    archiveId={currentArchive?.id ?? 0}
                    profileIdOwner={profileState.profiles.find(p => p.archiveRoleId === ArchiveRoleId.Owner)?.id ?? 0}
                    isProfileFoldersBulk={retriveProfileFolders()}
                />
            }
            {editProfileFolderModalIsOpen &&
                <EditProfileFolderModal
                    isOpen={editProfileFolderModalIsOpen}
                    onClose={() => { setEditProfileFolderModalIsOpen(false) }}
                    profileId={profileToEdit}
                    archiveId={currentArchive?.id ?? 0}
                    onComplete={() => {
                        dispatch(getProfiles({ archiveId: currentArchive?.id ?? 0 }));
                        setEditProfileFolderModalIsOpen(false);
                    }}
                    translationName={'profileManagement:profileView.editFolderModal'}
                    isProfileFoldersBulk={retriveProfileFolders(profileToEdit)}
                />
            }
        </>
    )
}