import { styled } from "@fluentui/react";
import { getStyles } from "./archiveList.styles";
import { IArchiveListPropsStyles, IArchiveListStyles, IArchiveListProps } from "./archiveList.types";
import { ArchiveListBase } from "./archiveList.base";

const ArchiveList = styled<IArchiveListProps, IArchiveListPropsStyles, IArchiveListStyles>(
    ArchiveListBase,
    getStyles,
    undefined,
    { scope: 'Navigation' }
);

export default ArchiveList;