/*eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from "react";
import { classNamesFunction, DefaultButton, Dialog, DialogFooter, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useDocLabDispatch, useDocLabState } from "../../../../docLabStore";
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive";
import { IModalStepData } from "../../../../../common/components/multiStepModal/multiStepModal.types";
import MultiStepModal from "../../../../../common/components/multiStepModal/multiStepModal";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { useAsyncApi } from "../../../../../utilities/hooks";
import { archivesApi } from "../../../../services/archives/archives.api";
import { ErrorsType } from "../../../../models/callsApi";
import FileUploader from "../../../../../common/components/fileUploader/fileUploader";
import { IFileToUpload } from "../../../files/uploadFileModal/uploadFileModal.types";
import { FileFormatExtension } from "../../../../../utilities/constants";
import { IImportDocsTreeModalProps, IImportDocsTreeModalPropsStyles, IImportDocsTreeModalStyles } from "./importDocsTreeModal.types";
import { showImportDocsTreeModal } from "../../../../features/archiveContent";

const getClassNames = classNamesFunction<IImportDocsTreeModalPropsStyles, IImportDocsTreeModalStyles>();

enum ImportSteps {
    chooseExcelFile = 0,
    completedImport = 1
}

export const ImportDocsTreeModalBase = (props: IImportDocsTreeModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['importMetadataModal', 'common']);
    const dispatch = useDocLabDispatch();
    const currentArchive = useCurrentArchive();
    const docLabState = useDocLabState();
    const [showDialog, setShowDialog] = useState(false);
    const [importFile, setImportFile] = useState<File | undefined>(undefined);
    const [currentStep, setCurrentStep] = useState<ImportSteps>(ImportSteps.chooseExcelFile);

    const { execute: importMetadataFileExcel, loading, error } = useAsyncApi<void, void>({
        func: async () => {
            if (!currentArchive)
                return;
            try {
                const formDataRequest = new FormData();
                formDataRequest.append("ArchiveReportId", docLabState.archiveContent.selectedReportIdExportShown.toString() as string);
                formDataRequest.append("File", importFile as File);

                await archivesApi.importMetadataFromExcel(currentArchive.id, formDataRequest);
            }
            catch (er: any) { //eslint-disable-line @typescript-eslint/no-explicit-any
                const error: ErrorsType = er as ErrorsType;
                console.log("Error: ", error);
            }
        }
    });

    const onFileUpload = (files: IFileToUpload[]) => {
        if(files[0] !== undefined && files[0] !== null) {
            if(files[0].fileType === FileFormatExtension.find(a => a.extension === ".xlsx")?.key)
                setImportFile(files[0].file);
            else
                setImportFile(undefined);
        }
        else
            setImportFile(undefined);
    }

    const bodyChooseFile = () => {
        return (
            <div style={{height: '100%'}}>
                <FileUploader
                    multiUpload={false}
                    multiple={false}
                    showMessages={true}
                    showMessageError={false}
                    isFileDraggable={true}
                    fileTypes={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                    extensionFileTypes={{".xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}}
                    showFileNameOnly={false}
                    currentFolderId={0}
                    maxNumFilesUploadable={1}
                    archiveId={currentArchive?.id ?? 0}
                    onFileUpdated={onFileUpload}
                    blockUpload={true}
                />
            </div>
        )
    }

    const bodyImportCompleted = () => {
        return (
            <div className={classNames.secondFooterContainer}>
                {loading ?
                    <div className={classNames.centerContentContainer}>
                        <Spinner size={SpinnerSize.large} />
                    </div> : error ?
                        renderError() :
                    <div className={classNames.centerContentContainer}>
                        <TeamsImage
                            imageName={ImageName.Sandglass}
                            className={classNames.sandglassImage}
                            fullContainer
                            scale={0.7}
                        />
                        <div className={classNames.importingFooter}>
                            {t('operationInProgress')}
                            <PrimaryButton
                                text={t('common:leave')}
                                allowDisabledFocus
                                onClick={() => dispatch(showImportDocsTreeModal({show: false, reportId: 0}))}
                                styles={{ root: { padding: '0px 80px', marginTop: '20px' } }}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }

    const footer = () => {
        return (
            <div className={classNames.buttonContainer}>
                <DefaultButton
                    style={{marginRight: '10px'}}
                    onClick={() => {                    
                        dispatch(showImportDocsTreeModal({show: false, reportId: 0}));
                    }}
                    text={t('common:previousButton')}
                />
                <PrimaryButton
                    onClick={() => {
                        switch(currentStep){
                            case ImportSteps.chooseExcelFile: {
                                setCurrentStep(ImportSteps.completedImport);
                                importMetadataFileExcel();
                                break;
                            }
                            case ImportSteps.completedImport:
                                dispatch(showImportDocsTreeModal({show: false, reportId: 0}));
                                break;
                            default:
                                dispatch(showImportDocsTreeModal({show: false, reportId: 0}));
                                break;
                        }
                    }}
                    text={t('common:nextButton')}
                    disabled={importFile === undefined}
                />
            </div>
        )
    }

    const renderError = () => {
        return <div className={classNames.centerContentContainer}>
            <TeamsImage
                imageName={ImageName.Error1}
                className={classNames.sandglassImage}
                fullContainer
                scale={0.7}
            />
            <div className={classNames.importingFooter}>
                {t('operationFailed')}
                <div className={classNames.buttonContainer} style={{marginTop: '20px'}}>
                    <DefaultButton
                        style={{marginRight: '10px'}}
                        text={t('common:close')}
                        allowDisabledFocus
                        onClick={() => dispatch(showImportDocsTreeModal({show: false, reportId: 0}))}
                    />
                    <PrimaryButton
                        text={t('common:retry')}
                        allowDisabledFocus
                        onClick={() => importMetadataFileExcel()}
                    />
                </div>
            </div>
        </div>
    }
    
    const steps: IModalStepData[] = [
        {
            title: t('titleModalDocsTree'),
            body: bodyChooseFile(),
            footer: footer()
        },
        {
            bodyOnly: true,
            body: bodyImportCompleted()
        }
    ]

    return (
        <>
            <MultiStepModal
                isOpen
                showCloseIcon
                width={750}
                height={600}
                hideScrollbarBody
                onCloseClick={currentStep === ImportSteps.completedImport ? () => dispatch(showImportDocsTreeModal({show: false, reportId: 0})) : () => setShowDialog(true)}
                activeStep={currentStep}
                steps={steps}
                animateInitialStep
            />
            <Dialog
                hidden={!showDialog}
                dialogContentProps={{ title: `${t('stopCreating')}` }}
            >
                <DialogFooter>
                    <DefaultButton
                        style={{marginRight: '10px'}}
                        onClick={() => setShowDialog(false)}
                        text={t('common:dialogButtonCancel')}
                    />
                    <PrimaryButton
                        onClick={() => dispatch(showImportDocsTreeModal({show: false, reportId: 0}))}
                        text={t('common:dialogButtonConfirm')}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
}