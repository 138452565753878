import { IStyleFunction } from "@fluentui/react"
import { ITagCloudChartPropsStyles, ITagCloudChartStyles } from "./tagCloudChart.types"

export const TagCloudChartGlobalClassNames = {
    root: 'edi-tag-cloud-chart-root'
}

export const getStyles: IStyleFunction<ITagCloudChartPropsStyles, ITagCloudChartStyles> = (props: ITagCloudChartPropsStyles): ITagCloudChartStyles => {
    return {
        root: [
            TagCloudChartGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}