import React, { useEffect, useState } from "react";
import { ActionButton } from "@fluentui/react";
import { ICopyActionCommandProps } from "./copyActionCommand.types";
import { useTranslation } from "react-i18next";

export const CopyActionCommandBase = (props: ICopyActionCommandProps) => {

    const { t } = useTranslation('common');

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        let timerId: NodeJS.Timeout;
        if (copied) {
            timerId = setTimeout(() => {
                setCopied(false);
            }, 3000);
        }
        return () => clearTimeout(timerId);

    }, [copied])


    const copyContent = async (content: string) => {
        //Clipboard API
        //navigator.clipboard.writeText(content);

        // workaround
        const tempInput = document.createElement("input");
        tempInput.value = content;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    }


    return (
        <ActionButton iconProps={{ iconName: 'Copy' }}
            disabled={copied}
            onClick={() => {
                copyContent(props.content);
                setCopied(true);
            }}>
            {copied ? t("common:commands.copied") : `${t("common:commands.copy")} ${props.textPlaceHolder}`}
        </ActionButton>
    );
}