import { CancelToken } from "axios";
import { apiClient } from "../../../modules/apiClient/apiClient";
import { svcPaths } from "../../../modules/apiClient/config";
import { Activity, ActivityForActivityLeader, ActivityShort, ActivitySplitByPriority, ActivitySplitByStatus } from "../../models/activity";
import { TemplateActivity } from "../../models/templateActivity";
import { GetActivitiesRequest } from "./activity.contracts";

class ActivityApiClass {
    async getActivityList(activitiesRequest: GetActivitiesRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<ActivityShort[]>(`${svcPaths.activities}/activities`, {
            params: {
                pageNumber: activitiesRequest.pageNumber,
                pageSize: activitiesRequest.pageSize,
                orderBy: activitiesRequest.orderBy,
                isAscending: activitiesRequest.isAscending,
                status: activitiesRequest.status,
                priorities: activitiesRequest.priorities,
                activityStatus: activitiesRequest.activityStatus,
                phaseStatus: activitiesRequest.phaseStatus,
                templateActivities: activitiesRequest.templateActivities,
                createdOn: activitiesRequest.createdOn,
                dueDate: activitiesRequest.dueDate,
                portfolioId: activitiesRequest.portfolioId
            },
            cancelToken: cancelToken
        });
        return response.data
    }

    async getActivityById(id: number) {
        const response = await apiClient.get<Activity>(`${svcPaths.activities}/activities/${id}`);
        return response.data;
    }
    async getUserTemplates(portfolioId: number) {
        const response = await apiClient.get<TemplateActivity[]>(`${svcPaths.activities}/templateactivities/user-template?portfolioId=${portfolioId}`);
        return response.data;
    }

    async getActivitiesForActivityLeader(portfolioId: number) {
        const response = await apiClient.get<Array<ActivityForActivityLeader>>(`${svcPaths.activities}/activities/activities-for-leaders?portfolioId=${portfolioId}`);
        return response.data;
    }

    async getActivitiesSplitByStatus(portfolioId: number) {
        const response = await apiClient.get<Array<ActivitySplitByStatus>>(`${svcPaths.activities}/activities/activities-status-graph?portfolioId=${portfolioId}`);
        return response.data;
    }

    async getActivitiesSplitByPriority(portfolioId: number) {
        const response = await apiClient.get<Array<ActivitySplitByPriority>>(`${svcPaths.activities}/activities/activities-priority-graph?portfolioId=${portfolioId}`);
        return response.data;
    }
}

export const activityApi = new ActivityApiClass();