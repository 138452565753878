
import { classNamesFunction, CommandBar, ICommandBarItemProps, IconButton, Pivot, PivotItem } from "@fluentui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import { useActivityLabDispatch } from "../../../activityLabStore";
import { loadActivities } from "../../../features/activityList";
import { useNavigator } from "../../../hooks/useNavigator";
import { ActivityStatus } from "../../../models/constants";
import { ActivityLabRoutes, ActivityTab } from "../../../utilities/routes";
import { IToolbarProps, IToolbarPropsStyles, IToolbarStyles } from "./toolbar.types";

const getClassNames = classNamesFunction<IToolbarPropsStyles, IToolbarStyles>();

export const ToolbarBase = (props: IToolbarProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation('toolbar');
    const dispatch = useActivityLabDispatch();
    const navigator = useNavigator();

    const rightTabs = useCallback(() => {
        return (
            <>
                <Pivot
                    headersOnly={true}
                    styles={classNames.subComponentStyles.pivot}
                    selectedKey={navigator.currentActivityLabTab ?? null}
                    onLinkClick={item => item?.props.itemKey && navigator.currentActivityLabTab !== item.props.itemKey &&
                        /*TODO dispatch(selectItems([])) && dispatch(setIsDeeplink(false)) && */ navigator.changeActivityLabTab(item.props.itemKey as ActivityTab)
                    } >
                    <PivotItem key={ActivityTab.board} itemKey={ActivityTab.board} headerText={t('activityLab.board')} />
                    <PivotItem key={ActivityTab.list} itemKey={ActivityTab.list} headerText={t('activityLab.list')} />
                    <PivotItem key={ActivityTab.workflow} itemKey={ActivityTab.workflow} headerText={t('activityLab.workflow')} />
                    <PivotItem key={ActivityTab.graphs} itemKey={ActivityTab.graphs} headerText={t('activityLab.graphs')} />
                </Pivot >
            </>
        );
    }, [classNames.subComponentStyles.pivot, t, navigator]);

    const menuAction: ICommandBarItemProps = {
        key: "menu",
        iconProps: { iconName: "CollapseMenu" },
        onClick: props.onMenuButtonClick
    }

    const infoTab = () => (
        <>
            <Route path={`${ActivityLabRoutes.portfolio}/${ActivityTab.board}`}>
                <IconButton
                    styles={classNames.subComponentStyles.reloadIconButton()}
                    iconProps={{ iconName: "Refresh" }}
                    onClick={() => {
                        getActivities(false);    
                    }}
                />
            </Route>
            <Route path={`${ActivityLabRoutes.portfolio}/${ActivityTab.list}`}>
                <IconButton
                    styles={classNames.subComponentStyles.reloadIconButton()}
                    iconProps={{ iconName: "Refresh" }}
                    onClick={() => {
                        getActivities(true);   
                    }}
                />
            </Route>
        </>

    )

    function getActivities(isBoard: boolean){
        if(isBoard){
            dispatch(loadActivities({
                portfolioId: navigator.currentPortfolioId,
                activityStatus: null,
                forceRefresh: true,
                pageSize: 20
            }));
            return;
        }

        for (const status in ActivityStatus) {
            if (!isNaN(+status)) {
                dispatch(loadActivities({
                    portfolioId: navigator.currentPortfolioId,
                    activityStatus: +status,
                    forceRefresh: true,
                    pageSize: undefined
                }));
            }
        }      
    }

    return (
        <div className={classNames.root}>
            <CommandBar
                className={classNames.wrapper}
                items={[menuAction]}
                farItems={[{ key: "info", commandBarButtonAs: infoTab }, { key: "right-tabs", commandBarButtonAs: rightTabs }]}
            />
        </div>
    );
}