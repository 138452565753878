import { DefaultEffects, IStyleFunction } from "@fluentui/react"
import { IFavouritesPropsStyles, IFavouritesStyles } from "./favourites.types";

export const KnowledgeLabContentGlobalClassNames = {
    root: 'edi-knowledgelab-content-root',
    buttonImport: 'edi-knowledgelab-buttonImport',
    searchContainer: 'edi-knowledgelab-search-container',
    cardsContainer: 'edi-knowledgelab-cards-container',
    scrollablePane: 'edi-knowledgelab-scrollablePane',
}

export const getStyles: IStyleFunction<IFavouritesPropsStyles, IFavouritesStyles> = (props: IFavouritesPropsStyles): IFavouritesStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            KnowledgeLabContentGlobalClassNames.root,
            {
                height: 'calc(100% - 60px)',
                position: 'relative',
                padding: '15px 15px 0 15px',
                overflowY: 'scroll'
            }
        ],
        searchContainer: [
            KnowledgeLabContentGlobalClassNames.searchContainer,
            {
                display: 'flex',
                flexDirection: 'column'
            }
        ],
        cardsContainer: [
            KnowledgeLabContentGlobalClassNames.cardsContainer,
            {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-start'
            }
        ],
        scrollablePane: [
            KnowledgeLabContentGlobalClassNames.scrollablePane,
            {
                position: 'inherit',
                ".ms-ScrollablePane--contentContainer": {
                    position: 'inherit !important',
                }

            }
        ],
        subComponentStyles: {
            textFieldStyle: {
                root: {
                    padding: '15px 17px'
                },
                field: {
                    fontSize: 20,
                    color: palette.black,
                    boxShadow: DefaultEffects.elevation8,
                    '::placeholder': {
                        fontSize: 20
                    },
                },
                fieldGroup: {
                    height: 40,
                    background: palette.white + ' !important'
                },
                errorMessage: {
                    position: 'absolute'
                },
                wrapper: {
                    height: 'unset'
                }
            },
        }
    };
}