import { IDetailsColumnStyleProps, IDetailsColumnStyles, IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction, ITooltipHostStyles } from "@fluentui/react";
import { IActivitiesListPropsStyles, IActivitiesListStyles } from "./activitiesList.types";

export const ActivitiesListGlobalClassNames = {
    root: 'edi-activities-list-root',
    emptyList: 'edi-activities-list-emptyList',
    detailListContainer: 'edi-activities-list-container'
}
export const getStyles: IStyleFunction<IActivitiesListPropsStyles, IActivitiesListStyles> = (props: IActivitiesListPropsStyles): IActivitiesListStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            ActivitiesListGlobalClassNames.root,
            {
                visibility: 'hidden'
            }
        ],
        load: {
            display: 'none'
        },
        detailListContainer: [
            ActivitiesListGlobalClassNames.detailListContainer,
            {
                margin: '30px',
                marginTop: '15px',
                marginBottom: '15px',
                height: '100%',
                '> .ms-Viewport': {
                    height: '100%'
                }
            }],
        emptyList: [
            ActivitiesListGlobalClassNames.emptyList,
            {
                position: 'absolute',
                top: '60px',
                width: '150px',
                left: 'calc(50% - 88px)'
            }],
        activityName: {
            padding: '0 2px',
            color: palette.black,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            selectors: {
                ':hover': {
                    textDecoration: 'underline'
                }
            }
        },
        activityMemberPersonaStyle: {
            backgroundColor: palette.themePrimary,
            color: palette.white,
        },
        activityLeaderPersonaStyle: {
            backgroundColor: palette.themeTertiary,
            color: palette.themePrimary,
        },
        subComponentStyles: {
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto'
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        flexBasis: 'calc(100vh - 220px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            },
            column: (props: IDetailsColumnStyleProps): IDetailsColumnStyles => {
                return {
                    root: {},
                    gripperBarVerticalStyle: {},
                    cellTooltip: {},
                    cellTitle: {
                        cursor: 'pointer'
                    },
                    cellName: {},
                    iconClassName: {},
                    nearIcon: {},
                    accessibleLabel: {},
                    sortIcon: {},
                    filterChevron: {},
                    borderAfterDropping: {},
                    noBorderAfterDropping: {},
                    borderWhileDragging: {},
                    noBorderWhileDragging: {},
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                    },
                    cell: { alignSelf: 'center', textOverflow: 'unset' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
            tooltipStyle: (): ITooltipHostStyles => {
                return {
                    root: {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: palette.themePrimary,
                        cursor: 'context-menu'
                    }
                }
            }
        }
    };
}