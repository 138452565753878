import { IDetailsListStyleProps, IDetailsListStyles, IShimmeredDetailsListStyleProps, IShimmeredDetailsListStyles, IStyleFunction } from "@fluentui/react";
import { IEdiModalPropsStyles, IEdiModalStyles } from "../ediModal/ediModal.types";
import { IFolderContentPropsStyles, IFolderContentStyles } from "./folderContent.types";

export const FolderContentGlobalClassNames = {
    root: 'edi-folder-content-root',
    checkbox: 'edi-folder-content-checkbox',
    breadcrumbContainer: 'edi-folder-breadcrumb-container',
}

export const getStyles: IStyleFunction<IFolderContentPropsStyles, IFolderContentStyles> = (props: IFolderContentPropsStyles): IFolderContentStyles => {
    return {
        root: [
            FolderContentGlobalClassNames.root,
            {
                height: '100%',
                '> .ms-Viewport': {
                    height: '100%'
                }
            },
            props.isEmptyList && {
                '> .ms-Viewport .ms-DetailsList-contentWrapper': {
                    flexBasis: '0 !important'
                }
            }
        ],
        emptyFolder: {
            height: '170px',
        },
        load: {
            display: 'none'
        },
        checkboxHeader: {
            ':not([data-checked="true"])': {
                opacity: 0
            },
            '.ms-DetailsRow:hover &': {
                opacity: 1
            },
            marginTop: '0.5em'
        },
        checkbox: {
            ':not([data-checked="true"])': {
                opacity: 0
            },
            '.ms-DetailsRow:hover &': {
                opacity: 1
            },
        },
        breadcrumbContainer: {
            display: 'flex',
        },
        iconContainer: {
            display: 'flex', 
            alignItems: 'center', 
            margin: '8px 0 0',
            paddingLeft: '8px',
        },
        subComponentStyles: {
            ediModal: (props: IEdiModalPropsStyles): IEdiModalStyles => {
                return {
                    bodyContent: {
                        overflow: 'unset'
                    },
                    body: {

                    },
                    closeIcon: {

                    },
                    container: {

                    },
                    content: {

                    },
                    footer: {

                    },
                    header: {

                    },
                    subComponentStyles: {
                        iconButton: {

                        }
                    },
                    subTitle: {

                    },
                    title: {

                    }
                }
            },
            shimmeredDetailsList: (props: IShimmeredDetailsListStyleProps): IShimmeredDetailsListStyles => {
                return {
                    root: {
                        '::after': {
                            display: 'none'
                        },
                        position: 'relative'
                    }
                }
            },
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                const { palette } = props.theme;
                return {
                    root: {
                        height: '100%',
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%'
                        },
                        ' .ms-DetailsHeader': {
                            background: palette.white
                        },
                        ' .ms-DetailsRow': {
                            background: palette.white
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto'
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        flexBasis: '100%',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            }
        }
    };
}