import {useDocLabState, DocLabDispatch, DocLabState, useDocLabDispatch } from "../docLabStore";
import { ToolbarAction } from "../features/toolbarActions";
import { ActionEntry, archiveContentActions } from "../utilities/archiveContentActions";
import {  searchContentActions } from "../features/searchContentActions";
import { INavigator, useNavigator } from "./useNavigator";

export type IActionDelegate = (state: DocLabState, navigator: INavigator, dispatch: DocLabDispatch) => void;

export enum ToolbarDelegateType {
    files = 'files',
    search = 'search'
}

const mapActionsToDelegates = (actions: ActionEntry[]): Record<string, IActionDelegate | undefined> => {
    let mapped: Record<string, IActionDelegate | undefined> = {};
    actions.forEach(action => {
        mapped[action.id] = action.onClick;

        if (action.subActionItems)
            mapped = { ...mapped, ...mapActionsToDelegates(action.subActionItems) };
    });

    return mapped;
}

const toolbarDelegates = {
    [ToolbarDelegateType.files]: mapActionsToDelegates(archiveContentActions),
    [ToolbarDelegateType.search]: mapActionsToDelegates(searchContentActions)
}

export const useActionDelegate = () => {
    const navigator = useNavigator();
    const dispatch = useDocLabDispatch();
    const state = useDocLabState();

    return (action: ToolbarAction) => {
        const delegate = toolbarDelegates[action.type][action.id];

        if (delegate)
            delegate(state, navigator, dispatch);
    }
}