import { DefaultPalette, IStyleFunction } from "@fluentui/react"
import { IWorkflowDetailsModalPropsStyles, IWorkflowDetailsModalStyles } from "./workflowDetailsModal.types";

export const WorkflowDetailsModalGlobalClassNames = {
    root: 'edi-workflow-details-modal-root',
    accordionContent: 'edi-workflow-details-modal-accordionContent',
    emptyList: 'edi-workflow-details-modal-emptyList',
    step: 'edi-workflow-details-modal-step',
    tooltipContent: 'edi-workflow-details-modal-tooltipContent',
    centerContainer: 'edi-workflow-details-modal-centerContainer',
    errorLabel: 'edi-workflow-details-modal-errorLabel'
}

export const getStyles: IStyleFunction<IWorkflowDetailsModalPropsStyles, IWorkflowDetailsModalStyles> = (props: IWorkflowDetailsModalPropsStyles): IWorkflowDetailsModalStyles => {

    return {
        root: [
            WorkflowDetailsModalGlobalClassNames.root,
            {
                //CSS PROPS AND SELECTORS
            }
        ],
        accordionContent: [
            WorkflowDetailsModalGlobalClassNames.accordionContent,
            {
                //height: '100%',
                //padding: '0px 15px 17px 17px'
            }
        ],
        emptyList: [
            WorkflowDetailsModalGlobalClassNames.emptyList,
            {
                position: 'absolute',
                top: '60px',
                width: '150px',
                left: 'calc(50% - 88px)'
            }
        ],
        step: [
            WorkflowDetailsModalGlobalClassNames.step,
            {
                paddingBottom: '0px',
                paddingTop: '0px !important',
                height: '100%'
            }
        ],
        tooltipContent: [
            WorkflowDetailsModalGlobalClassNames.tooltipContent,
            {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                cursor: 'pointer',
            }
        ],
        centerContainer: [
            WorkflowDetailsModalGlobalClassNames.centerContainer,
            {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',                
                height: '100%'
            }
        ],
        errorLabel: [
            WorkflowDetailsModalGlobalClassNames.errorLabel,
            {
                color: DefaultPalette.redDark,
                fontSize: 'medium'
            }
        ]
    };
}