import { styled } from "@fluentui/react";
import { FileSchedulerBase } from "./fileScheduler.base";
import { getStyles } from "./fileScheduler.styles";
import { IFileSchedulerProps, IFileSchedulerPropsStyles, IFileSchedulerStyles } from "./fileScheduler.types";

const FileScheduler = styled<IFileSchedulerProps, IFileSchedulerPropsStyles, IFileSchedulerStyles>(
    FileSchedulerBase,
    getStyles,
    undefined,
    { scope: 'FileScheduler' }
);

export default FileScheduler;