import { Breadcrumb, IBreadcrumbItem } from "@fluentui/react";
import React, { useEffect, useState } from "react";

export interface IFolderBreadcrumbProps {
    folderId: number;
    folderName: string;
    onClick: (folderId: number) => void;
}

export const FolderBreadcrumb = (props: IFolderBreadcrumbProps) => {
    const [breadcrumb, setBreadcrumb] = useState<IBreadcrumbItem[]>([]);

    useEffect(() => {
        if (!props.folderId)
            return;

        const index = breadcrumb.findIndex(b => b.key === props.folderId.toString());
        if (index !== -1) {
            setBreadcrumb(breadcrumb.slice(0, index + 1));
            return;
        }

        setBreadcrumb([
            ...breadcrumb,
            {
                key: props.folderId.toString(),
                text: props.folderName,
                onClick: () => props.onClick(props.folderId)
            }
        ]);
    }, [props]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{width: '100%'}}>
                <Breadcrumb style={{paddingLeft: '4px'}} items={breadcrumb} overflowIndex={breadcrumb.length > 1 ? 1 : 0}  />
            </div>
    );
}