import { generatePath, matchPath } from "react-router-dom";

export enum AppChannel {
    teams = "teams"
}

export enum AppTab {
    docLab = "doclab",
    activityLab = "activitylab",
    knowledgeLab = "knowledgelab",
    knowledgeDS = "knowledgeds",
    dashboard = "dashboard"
}

class RoutesClass {
    public home = `/:channel(${AppChannel.teams})`;
    public tab = `${this.home}/:tab(${AppTab.docLab}|${AppTab.activityLab}|${AppTab.knowledgeLab}|${AppTab.knowledgeDS}|${AppTab.dashboard})`;

    public generateRouteForTab(tab: AppTab) {
        return generatePath(this.tab, { channel: AppChannel.teams, tab: tab });
    }

    public getSelectedTab(path: string) {
        return matchPath<{ tab: AppTab }>(path, { path: this.tab })?.params.tab;
    }

    public isHome(path: string) {
        return matchPath(path, { path: this.home, exact: true }) !== null;
    }
}

export const AppRoutes = new RoutesClass();