import { FontSizes, FontWeights, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react";
import { IDocLabSettingsPropsStyles, IDocLabSettingsStyles } from "./docLabSettings.types";

export const DocLabSettingsGlobalClassNames = {
    root: 'edi-doc-lab-settings-root',
    title: 'edi-doc-lab-settings-title',
    titleIcon: 'edi-doc-lab-settings-titleIcon',
    upperSection: 'edi-doc-lab-settings-upperSection',
    lowerSection: 'edi-doc-lab-settings-lowerSection',
    headerRow: 'edi-doc-lab-settings-headerRow',
    loaderContainer: 'edi-doc-lab-settings-loaderContainer',
    iconStyle: 'edi-doc-lab-settings-iconStyle',
    taggingDescription: 'edi-doc-lab-settings-taggingDescription',
    taggingSection: 'edi-doc-lab-settings-taggingSection',
    errorKeyphrases: 'edi-doc-lab-settings-keyphrases-errorPrefix',
    standardKeyphrases: 'edi-doc-lab-settings-keyphrases-standard'
}

export const getStyles: IStyleFunction<IDocLabSettingsPropsStyles, IDocLabSettingsStyles> = (props: IDocLabSettingsPropsStyles): IDocLabSettingsStyles => {
    const { palette, semanticColors } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            DocLabSettingsGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px'
            }
        ],
        descriptionLabel: {
            paddingTop: '0px',
            paddingBottom: '8px',
            fontSize: '12px'
        },
        iconStyle: [
            DocLabSettingsGlobalClassNames.iconStyle,
            {
                fontSize: 14,
                marginLeft: '5px',
                marginRight: '10px',
            }],
        taggingDescription: [
            DocLabSettingsGlobalClassNames.taggingDescription,
            {
                color: palette.black,
                fontSize: 12,
                marginBottom: '10px '
            }],
        title: [
            DocLabSettingsGlobalClassNames.title,
            {
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingBottom: 10
            }
        ],
        titleIcon: [
            DocLabSettingsGlobalClassNames.titleIcon,
            {
                padding: 7,
                border: '1.5px solid',
                borderRadius: '50%',
                fontSize: 26
            }
        ],
        upperSection: [
            DocLabSettingsGlobalClassNames.upperSection,
            {
                paddingBottom: 10
            }
        ],
        lowerSection: [
            DocLabSettingsGlobalClassNames.lowerSection,
            {
                position: 'relative'
            }
        ],
        headerRow: [
            DocLabSettingsGlobalClassNames.headerRow,
            {
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                }
            }
        ],
        loaderContainer: [
            DocLabSettingsGlobalClassNames.loaderContainer,
            {
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        ],
        taggingSection: [
            DocLabSettingsGlobalClassNames.taggingSection,
            {
                marginLeft: '7px',
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingTop: 5,
                paddingBottom: 5
            }
        ],
        subComponentStyles: {
            list: (): IDetailsListStyles => {
                return {
                    root: {},
                    contentWrapper: {
                    },
                    focusZone: {},
                    headerWrapper: {
                        '> .ms-DetailsHeader': {
                            paddingTop: 0,
                            borderBottomColor: 'transparent'
                        }
                    },
                }
            },
            row: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                const { palette } = props.theme;

                return {
                    root: {
                        backgroundColor: palette.white,
                        ':hover': {
                            backgroundColor: palette.white
                        }
                    },
                    cell: { alignSelf: 'center' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
            removeButton: {
                root: {
                    paddingRight: 10
                },
                icon: {
                    color: semanticColors.bodyText
                },
                rootHovered: {
                    background: 'transparent'
                },
                rootPressed: {
                    background: 'transparent'
                },
                rootDisabled: {
                    background: 'transparent'
                }
            }
        },
        errorKeyphrases: [
            DocLabSettingsGlobalClassNames.errorKeyphrases,
            {
                ' .ms-TextField-prefix': {
                    backgroundColor: semanticColors.errorText,
                    color: palette.white,
                }
            }
        ],
        standardKeyphrases: [            
            DocLabSettingsGlobalClassNames.standardKeyphrases,
            {
                ' .ms-TextField-prefix': {
                    backgroundColor: palette.themePrimary,
                    color: palette.white
                }
            },
        ]
    };
}