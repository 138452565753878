/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable sonarjs/no-duplicate-string */
import React, { useCallback, useMemo, useState } from "react";
import { classNamesFunction, DefaultButton, Dropdown, IDropdownOption, PrimaryButton} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { DocumentTranslationEnum, IDocumentTranslationModalProps, IDocumentTranslationModalPropsStyles, IDocumentTranslationModalStyles, KeyValueLanguagesType } from "./documentTranslationModal.types";
import { IModalStepData } from "../../../../../common/components/multiStepModal/multiStepModal.types";
import MultiStepModal from "../../../../../common/components/multiStepModal/multiStepModal";
import { useDocLabDispatch } from "../../../../docLabStore";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { useOnMount } from "../../../../../utilities/hooks";
import { mapper } from "../../../../../utilities/mapper";
import SecondPage from "./modalPage/secondPage/secondPage";
import { ArchiveItem } from "../../../../models/archiveItem";
import {showTranslationModal, useArchiveContent } from "../../../../features/archiveContent";

const getClassNames = classNamesFunction<IDocumentTranslationModalPropsStyles, IDocumentTranslationModalStyles>();

export const DocumentTranslationModalBase = (props: IDocumentTranslationModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    const { t } = useTranslation(['documentTranslation','common']);
    
    const TranslationLanguages: KeyValueLanguagesType[] = [
        {key: 'en', value: t('English')},
        {key: 'it', value: t('Italian')},
        {key: 'fr', value: t('French')},
        {key: 'es', value: t('Spanish')},
        {key: 'pt-pt', value: t('Portuguese')},
        {key: 'de', value: t('German')},
        {key: 'el', value: t('Greek')},
        {key: 'zh-Hans', value: t('Chinese')},
        {key: 'ru', value: t('Russian')},
        {key: 'pl', value: t('Polish')},
        {key: 'ro', value: t('Romanian')},
        {key: 'cs', value: t('Czech')},
        {key: 'sk', value: t('Slovak')},
        {key: 'hu', value: t('Hungarian')},
        {key: 'tr', value: t('Turkish')},
        {key: 'sv', value: t('Swedish')},
        {key: 'vi', value: t('Vietnamese')},
    ]
    const dispatch = useDocLabDispatch();
    const [activeStep, setActiveStep] = useState<DocumentTranslationEnum>(DocumentTranslationEnum.languageChoicePage);
    const [languages, setLanguages] = useState<IDropdownOption[]>([]);
    const [selectedKey, setSelectedKey] = useState<IDropdownOption>();
    const [file, setFile] = useState<ArchiveItem>();
    const [translationComplete, setTranslationComplete] = useState<boolean>();
    const { selectedItems, items } = useArchiveContent();

    useOnMount(() => {
        setFile(items.find(item => item.id === selectedItems[0].id));

        const sortedLanguages = [...TranslationLanguages].sort((a, b) => a.value.localeCompare(b.value));
        const dropdownLanguages = sortedLanguages.map(l => mapLanguagesToDropOpt(l));

       setLanguages(dropdownLanguages)
       setSelectedKey(dropdownLanguages.find(l => l.key === 'en'))
    });

    const mapLanguagesToDropOpt = (languages: KeyValueLanguagesType): IDropdownOption => {
        return {
            ...mapper.mapLanguagesToDropdownOption(languages)
        }
    }

    // Method for manage next action for modal
    const next = useCallback(() => {
        switch (activeStep) {
            case DocumentTranslationEnum.languageChoicePage:
                return setActiveStep(DocumentTranslationEnum.translationPage);

            case DocumentTranslationEnum.translationPage:
                return setActiveStep(DocumentTranslationEnum.downloadPage);

            default: return () => undefined;
        }
    }, [activeStep])

    const footer = useMemo(() => {
        return (
            <div>
                {activeStep === DocumentTranslationEnum.downloadPage ? 
                    <div className={classNames.leaveButton}>
                        <PrimaryButton
                            text={t('common:leave')}
                            onClick={() => dispatch(showTranslationModal(false))} 
                        />
                    </div>
                    :
                    <div className={classNames.buttonsContainer}>
                        <DefaultButton
                            disabled={false}
                            text={t("common:close")}
                            onClick={() => dispatch(showTranslationModal(false))}
                        />
                        <PrimaryButton
                            text={t("common:nextButton")}
                            onClick={(e) => {
                                e.stopPropagation()
                                next();
                            }}
                        />
                    </div>
                }
            </div>
        );
    }, [classNames.buttonsContainer, activeStep, next, dispatch, t]); //eslint-disable-line react-hooks/exhaustive-deps

    const getMultiStepModal: IModalStepData[] = [
        {
            body:   <Dropdown
                        styles={classNames.subComponentStyles.dropdown}
                        options={languages}
                        defaultSelectedKey={'en'}
                        calloutProps={{calloutMaxHeight: 185}}
                        placeholder={t('selectLanguages')}
                        onChange={(_, option) => {option && setSelectedKey(option);}}
                    />,
            footer: footer
        },        
        {
            body:   <SecondPage
                        fileName={file? file.name : ''}
                        selectedLanguage={selectedKey?.key as string}
                        fileId={selectedItems[0].id}
                        onComplete={(complete) => {
                            next();
                            setTranslationComplete(complete);
                        }}    
                    />
        },
        {
            body:   <div className={classNames.downloadPage}>
                        {translationComplete ? 
                            <>
                                <TeamsImage imageName={ImageName.WellDone} style={{ width: '31%', marginBottom: 2}} />
                                <span >{t('common:niceWork')}</span>
                            </>
                            :
                            <>
                                <TeamsImage imageName={ImageName.Error3} style={{ width: '31%', marginBottom: 3}} />
                                <span >{t('common:genericErrorApi')}</span>
                            </>
                        }
                    </div>,
            footer: footer
        }      
    ];

    return (
        <>
            <MultiStepModal
                activeStep={activeStep}
                steps={getMultiStepModal}
                isOpen={true}
                width={600}
                height={400}
                showCloseIcon={activeStep !== DocumentTranslationEnum.translationPage }
                onCloseClick={() =>  dispatch(showTranslationModal(false))}
                title={t('title')}
                subtitle={activeStep === DocumentTranslationEnum.languageChoicePage ? t('subtitle') : ''}
            />                   
        </>
    );
}