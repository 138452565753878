import { IStyleFunction } from "@fluentui/react"
import { IIconTagPropsStyles, IIconTagStyles } from "./iconTag.types"

export const IconTagGlobalClassNames = {
    root: 'edi-icon-tag-root'
}

export const getStyles: IStyleFunction<IIconTagPropsStyles, IIconTagStyles> = (props: IIconTagPropsStyles): IIconTagStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            IconTagGlobalClassNames.root,
            {
                columnGap: '8px',
                borderRadius: '4px',
                backgroundColor: props.noBackground ? 'transparent' : palette.themeSecondary,
                color: props.noBackground ? palette.themeSecondary : palette.white,
                padding: '1.5px 4px',
                height: 'min-content',
                width: '100%'
            }
        ]
    };
}