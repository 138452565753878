export type Feedback = {
    id: number,
    moduleInstanceId: number,
    moduleInstanceName: string,
    fileId?: number,
    fileName: string,
    entityId?: number,
    creatorName: string,
    creatorEmail: string,
    approverName: string,
    approverEmail: string,
    skillName: string,
    metadataName: string,
    note?: string,
    oldValue: string,
    newValue: string,
    elementContent: string,
    fileContent: string,
    createdOn: string,
    updatedOn?: string,
    feedbackStatus: FeedbackStatus,
    feedbackType?: FeedbackType,
    nerTaggedData?: string,
    nerTaggedDataMappingDisplayName?: string,
    metadataExtraPropertyValue?: string
    originalAiExtractedMetadataValue?: string
}


export type InfoFeedbackNer = {
    metadataModified : Feedback[],
    metadataNotModified : Feedback[]
}

export enum FeedbackStatus {
    Pending = 0,
    Approved = 1,
    Rejected = 2,
    ReadyForDataset = 3,// bottone approva per dataset
    DatasetCreated = 4,
    // Merged = 5 - status "tecnico" utilizzato solo backend side
}

export enum FeedbackType {
    OTHER = 0,
    NER = 1,
    CLASSIFICATION = 2
 
}


export const dashboardStatusText = [
    { key: FeedbackStatus.Pending, value: "status.pending" },
    { key: FeedbackStatus.Approved, value: "status.approved" },
    { key: FeedbackStatus.Rejected, value: "status.rejected" },
    { key: FeedbackStatus.ReadyForDataset, value: "status.readyForDataset" },
    { key: FeedbackStatus.DatasetCreated, value: "status.datasetCreated" }
]

