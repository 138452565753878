import { IStyleFunction } from "@fluentui/react"
import { IEditProfileModalBasePropsStyles, IEditProfileModalBaseStyles } from "./editProfileModal.types";


export const EditProfileModalGlobalClassNames = {
    root: 'edi-profile-view-root',
    title: 'edi-profile-view-title',
    action: 'edi-profile-view-action'
}

export const getStyles: IStyleFunction<IEditProfileModalBasePropsStyles, IEditProfileModalBaseStyles> = (props: IEditProfileModalBasePropsStyles): IEditProfileModalBaseStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            EditProfileModalGlobalClassNames.root,
            {
                background: palette.white,
                width: 'auto',
                height: '150px',
                borderRadius: '4px',
                boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 4px -0.75px',
                padding: '15px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'     
            }
        ],
        title: [
            EditProfileModalGlobalClassNames.title,
            {
                color: palette.black,
                fontWeight: 'bold'
            }
        ],
        action: [
            EditProfileModalGlobalClassNames.action,
            { 
                alignSelf:'flex-end',
                justifySelf:'right',
                fontSize: '12px',
                color: palette.themePrimary,
                cursor: 'pointer'
            }
        ]
    }
}