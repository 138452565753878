/*eslint-disable sonarjs/cognitive-complexity */
import { classNamesFunction, IStackTokens, PrimaryButton, ScrollablePane, Spinner, SpinnerSize, Stack, StackItem } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useOnMount } from "../../../../../utilities/hooks";
import { useActivityLabDispatch } from "../../../../activityLabStore";
import { loadActivities, useActivityList } from "../../../../features/activityList";
import { useCurrentPortfolio } from "../../../../hooks/useCurrentPortfolio";
import { ActivityStatus, PortfolioRoleId } from "../../../../models/constants";
import ActivityCard from "../activityCard/activityCard";
import { IActivityBoardProps, IActivityBoardPropsStyles, IActivityBoardStyles } from "./activityBoard.types";

const getClassNames = classNamesFunction<IActivityBoardPropsStyles, IActivityBoardStyles>();

export const ActivityBoardBase = (props: IActivityBoardProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const currentPortfolio = useCurrentPortfolio();
    const dispatch = useActivityLabDispatch();
 
    const { t } = useTranslation(['activity', 'common']);
    const { plannedActivities, onGoingActivities, blockedActivities, closedActivities, activities } = useActivityList();


    const boardGap: IStackTokens = {
        childrenGap: 20,
        padding: 5,
    };

    const cardsGap: IStackTokens = {
        childrenGap: 10,
        padding: 5,
    };

    const getStatus = () => {
        return <div style={{ fontWeight: 'bold' }}>
            <span>{t(`status.${props.status}`)}</span>
        </div>
    }

    useOnMount(() => {
        registerEvent();
    });

    const loadMore = () => {
        if (isLoading() || (getLenghtLastCall() < 10) )
            return;

        dispatch(loadActivities({
            portfolioId: currentPortfolio?.id ?? 0,
            activityStatus: props.status,
            forceRefresh: false,
            pageSize: props.pageSize
        }));
    }

    const registerEvent = () => {
        const listElm = document.querySelector(`#activityCardListZone_${props.status} .ms-ScrollablePane--contentContainer`);
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50 ) {
                const button = document.getElementById(`click_${props.status}`);
                button && button.click();
            }
        });
    };

    const emptyFolder = () => {
        return (
            <div className={classNames.emptyBoard}>
                <span>{t("common:emptyList")}</span>
            </div>
        )
    };

    const renderList = () => {
        switch(props.status){
            case ActivityStatus.Planned:
                return plannedActivities.activityList.length > 0 ? plannedActivities.activityList.map(x => <ActivityCard key={x.id} activity={x} />) : emptyFolder();
            case ActivityStatus.OnGoing:
                return onGoingActivities.activityList.length > 0 ? onGoingActivities.activityList.map(x => <ActivityCard key={x.id} activity={x} />) : emptyFolder();
            case ActivityStatus.Blocked:
                return blockedActivities.activityList.length > 0 ? blockedActivities.activityList.map(x => <ActivityCard key={x.id} activity={x} />) : emptyFolder();
            case ActivityStatus.Closed:
                return closedActivities.activityList.length > 0 ? closedActivities.activityList.map(x => <ActivityCard key={x.id} activity={x} />) : emptyFolder();  
            default:
                return emptyFolder();
        }    
    }

    const isListNotEmpty = () => {
        return elementsLenght() > 0;
    }

    const elementsLenght = () => {
        switch(props.status){
            case ActivityStatus.Planned:
                return plannedActivities.activityList.length;
            case ActivityStatus.OnGoing:
                return onGoingActivities.activityList.length;
            case ActivityStatus.Blocked:
                return blockedActivities.activityList.length;
            case ActivityStatus.Closed:
                return closedActivities.activityList.length;  
            default:
                return 0;
        }
    }

    const isLoading = () => {
        switch(props.status){
            case ActivityStatus.Planned:
                return plannedActivities.isLoading;
            case ActivityStatus.OnGoing:
                return onGoingActivities.isLoading;
            case ActivityStatus.Blocked:
                return blockedActivities.isLoading;
            case ActivityStatus.Closed:
                return closedActivities.isLoading;  
            default:
                return activities.isLoading;
        }
    }

    const getLenghtLastCall = () => {
        switch(props.status){
            case ActivityStatus.Planned:
                return plannedActivities.lenghtLastCall;
            case ActivityStatus.OnGoing:
                return onGoingActivities.lenghtLastCall;
            case ActivityStatus.Blocked:
                return blockedActivities.lenghtLastCall;
            case ActivityStatus.Closed:
                return closedActivities.lenghtLastCall;  
            default:
                return 0;
        }
    }

    return (
        <Stack verticalAlign="center" tokens={boardGap} style={{
            marginTop: '15px',
            borderRight: '1px solid rgb(225, 223, 221)'
        }}>
            <StackItem style={{ marginLeft: '5px' }}>{getStatus()}</StackItem>
            <StackItem align="center" style={{ height: currentPortfolio?.portfolioRoleId === PortfolioRoleId.Owner ? 'calc(100vh - 277px)' : 'calc(100vh - 215px)', width: '350px' }}>
                <div id={`activityCardListZone_${props.status}`} style={{
                    height: '100%',
                    position: 'relative',
                    maxHeight: 'inherit'
                }}> <ScrollablePane styles={classNames.subComponentStyles.scrollablePane}>
                        {(isLoading() && !isListNotEmpty()) ? <Spinner style={{ height: '-webkit-fill-available' }} size={SpinnerSize.large} /> :
                            <Stack tokens={cardsGap} >
                                {/* {isLoading() &&
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '250px' }}>
                                        <TeamsImage imageName={ImageName.Oops1} scale={0.5} fullContainer />
                                    </div>
                                } */}
                                {renderList()}
                                {isLoading() ? <StackItem><Spinner size={SpinnerSize.large} /></StackItem> : ''}

                                <div style={{ display: 'none' }}>
                                    <PrimaryButton id={`click_${props.status}`} onClick={loadMore} />
                                </div>
                            </Stack>
                        }
                    </ScrollablePane>
                </div>
            </StackItem>
        </Stack>
    );
}