import { FontWeights, IStyleFunction } from "@fluentui/react"
import { IFeedbackModalPropsStyles, IFeedbackModalStyles } from "./feedbackModal.types"

export const FeedbackModalGlobalClassNames = {
    root: 'edi-feedback-modal-root'
}

export const getStyles: IStyleFunction<IFeedbackModalPropsStyles, IFeedbackModalStyles> = (props: IFeedbackModalPropsStyles): IFeedbackModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            FeedbackModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        modalMetadata: {
            height: 'calc(100% - 60px)',
            width: '40%',
            overflowY: 'scroll'
        },
        modalParagraph: {
            height: 'calc(100% - 10px)',
            width: '60%',
        },
        loadingErrorContainer: {
            height: 'calc(100%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        copyButtonDiv: {
            width: '100%',
            textAlign: 'end'
        },
        upperContent: {
            padding: '0px 32px 0px 32px',
            height: '100%',
        },
        body: {
            flexGrow: 1,
            height: 'calc(100% - 36px)'
        },
        bodyContent: {
            height: '100%',
            '> :first-child': {
                height: '100%',
            },
        },
        closeIcon: {},
        container: {
            padding: '27px 0px 0px 0px',
            width: props.width,
            height: props.height,
            right: 34,
            overflow: "hidden"
        },
        content: {
            '*::-webkit-scrollbar': {
                width: 16,
                height: 16
            },
            '*::-webkit-scrollbar-thumb': {
                border: '5px solid transparent',
                backgroundClip: 'padding-box',
                backgroundColor: 'transparent',
                borderRadius: 25
            },
            '*:hover::-webkit-scrollbar-thumb': {
                backgroundColor: palette.neutralSecondary// 'rgba(66, 66, 66, 0.3)'
            },
            '*::-webkit-scrollbar-thumb:hover': {
                border: '2px solid transparent'
            },
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            '> :not(:first-child)': {
                marginTop: 27
            },
            overflow: "hidden"
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            // alignItems: 'flex-start',
            alignItems: 'center',
            fontWeight: FontWeights.bold,
            height: 36
        },
        footer: {
            paddingTop: '10px',
            textAlign: 'left',
            position: 'sticky',
            bottom: '0px'
        },
        title: {
            wordBreak: 'break-word'
        },
        subComponentStyles: {
            iconButton: {
                root: {
                    color: palette.neutralDark,
                    marginLeft: 'auto',
                    marginTop: '4px',
                    marginRight: '2px',
                },
                rootHovered: {
                    color: palette.neutralPrimary,
                }
            }
        },
    };
}