import { styled } from "@fluentui/react";
import { FolderMembersModalBase } from "./folderMembersModal.base";
import { getStyles } from "../membersModal.styles";
import { IMembersModalPropsStyles, IMembersModalStyles, IMembersModalProps } from "../membersModal.types";

const FolderMembersModal = styled<IMembersModalProps, IMembersModalPropsStyles, IMembersModalStyles>(
    FolderMembersModalBase,
    getStyles,
    undefined,
    { scope: 'FolderMembersModal' }
);

export default FolderMembersModal;