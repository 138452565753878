import React, { useEffect} from "react";
import { classNamesFunction, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import { IDeleteKnowledgeDocumentModalPropsStyles, IDeleteKnowledgeDocumentModalStyles, IDeleteKnowledgeDocumentModalProps } from "./deleteKnowledgeDocumentModal.types";
import { useTranslation } from "react-i18next";
import { knowledgeLabApi } from "../../services/knowledgeLab.api";
import { useDispatch } from "react-redux";
import { setIsDeleteItem } from "../../features/knowledgeLabContent";
import { useKnowledgeLabContent, setForceDocumentReload } from "../../features/knowledgeLabContent";

const getClassNames = classNamesFunction<IDeleteKnowledgeDocumentModalPropsStyles, IDeleteKnowledgeDocumentModalStyles>();


export const DeleteKnowledgeDocumentModalBase = (props: IDeleteKnowledgeDocumentModalProps) => {
    
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation('knowledgeDocuments');
    const dispatch = useDispatch();
    const knowledgeLabContent = useKnowledgeLabContent();
        
    useEffect(() => {
        if (!knowledgeLabContent.forceDocumentReload) return;
               dispatch(setForceDocumentReload(false))

    }, [knowledgeLabContent.forceDocumentReload]) //eslint-disable-line react-hooks/exhaustive-deps

    const confirmDelete = () => {
        dispatch(setIsDeleteItem(true));
        const idToDelete = props.documents.map(i => i.id);
        props.onClose();
        knowledgeLabApi.deleteFile(idToDelete)
            .then(() => {
                if (props.onComplete) props.onComplete();
            })
            .catch(() => {
                if (props.onError) props.onError();
            })
            .finally(() => {
                dispatch(setIsDeleteItem(false));
                dispatch(setForceDocumentReload(true));
            });
    }

    const dialogContentProps = {
        type: DialogType.normal,
        title: t('deleteModal.title'),
        closeButtonAriaLabel: t('deleteModal.close'),
        subText: props.documents.length > 1 ? t('deleteModal.pluralText') : t('deleteModal.singleText')
    };

    return (
        <Dialog
            isOpen={props.isOpen}
            className={classNames.root}
            onDismiss={() => props.onClose()}
            dialogContentProps={dialogContentProps}

        >
            <DialogFooter>
                <DefaultButton onClick={props.onClose}>{t("deleteModal.cancel")}</DefaultButton>
                <PrimaryButton onClick={confirmDelete}>{t("deleteModal.confirm")}</PrimaryButton>
            </DialogFooter>
        </Dialog>
    );
}