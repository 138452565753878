import { IStyleFunction } from "@fluentui/react";
import { IImportDocsTreeModalPropsStyles, IImportDocsTreeModalStyles } from "./importDocsTreeModal.types";

export const ImportDocsTreeModalGlobalClassNames = {
    root: 'edi-importDocsTreeModal-root',
    buttonContainer: 'edi-importDocsTreeModal-buttonContainer',
    centerContentContainer: 'edi-importDocsTreeModal-centerContentContainer',
    secondFooterContainer: 'edi-importDocsTreeModal-secondFooterContainer',
    sandglassImage: 'edi-importDocsTreeModal-sandglassImage',
    importingFooter: 'edi-importDocsTreeModal-exportingFooter',
}

export const getStyles: IStyleFunction<IImportDocsTreeModalPropsStyles, IImportDocsTreeModalStyles> = (props: IImportDocsTreeModalPropsStyles): IImportDocsTreeModalStyles => {
    return {
        root: [
            ImportDocsTreeModalGlobalClassNames.root,
            {
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center'
            }
        ],
        buttonContainer: [
            ImportDocsTreeModalGlobalClassNames.buttonContainer,
            {
                display: 'flex',
                justifyContent: 'flex-end',
                alignContent: 'center'
            }
        ],
        centerContentContainer: [
            ImportDocsTreeModalGlobalClassNames.centerContentContainer,
            {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        ],
        secondFooterContainer: [
            ImportDocsTreeModalGlobalClassNames.secondFooterContainer,
            {
                display: 'flex',
                alignItems: 'center',
                height: '100%'
            }
        ],
        sandglassImage: [
            ImportDocsTreeModalGlobalClassNames.sandglassImage,
            {
                flexBasis: '50%',
                flexShrink: 0
            }
        ],
        importingFooter: [
            ImportDocsTreeModalGlobalClassNames.importingFooter,
            {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center'
            }
        ]
    };
}