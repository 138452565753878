import React from "react";
import { classNamesFunction, Icon, TooltipHost } from "@fluentui/react";
import { IActivityTypePropsStyles, IActivityTypeStyles, IActivityTypeProps } from "./activityType.types";

const getClassNames = classNamesFunction<IActivityTypePropsStyles, IActivityTypeStyles>();

export const ActivityTypeBase = (props: IActivityTypeProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    return (
        props.activity.templateActivityName ?
            <TooltipHost content={props.activity.templateActivityName}>
                <div className={classNames.propertyName}>
                    <span className={classNames.iconContainer}> <Icon iconName='tag' /></span>
                    <span style={{ marginLeft: '20px' }}>{props.activity.templateActivityName}</span>
                </div>
            </TooltipHost> : <></>
    );
}