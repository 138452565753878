import { FontWeights, IStyleFunction } from "@fluentui/react"
import { IInfoStatusPropsStyles, IInfoStatusStyles } from "./infoStatus.types";


export const InfoStatusGlobalClassNames = {
    root: 'edi-activitylab-infoStatus-root',
    title: 'edi-activitylab-infoStatus-title',
    accordionContainer: 'edi-activitylab-infoStatus-accordionContainer',
    formSection: 'edi-activitylab-infoStatus-formSection',
    leftSectionForm: 'edi-activitylab-infoStatus-leftSectionForm',
    rightSectionForm: 'edi-activitylab-infoStatus-rightSectionForm',
    leftThreeAlignment: 'edi-activitylab-infoStatus-leftThreeAlignment',
    singleItemThreeAlignment: 'edi-activitylab-infoStatus-singleItemThreeAlignment',
    personaFormSection: 'edi-activitylab-infoStatus-personaFormSection',
    leadersContainer: 'edi-activitylab-infoStatus-leadersContainer',
    phasesTitle: 'edi-activitylab-infoStatus-phasesTitle',
    phaseTitleContainer: 'edi-activitylab-infoStatus-phaseTitleContainer',
    breakLine: 'edi-activityLab-phaseForm-breakLine',
    infoContainerStyle: 'edi-activityLab-phaseForm-infoContainerStyle',
}

export const getStyles: IStyleFunction<IInfoStatusPropsStyles, IInfoStatusStyles> = (props: IInfoStatusPropsStyles): IInfoStatusStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            InfoStatusGlobalClassNames.root,
            {
                height: "calc(100vh - 90px)",
                padding: '0 27px 0 10px'
            }
        ],
        infoContainerStyle: [InfoStatusGlobalClassNames.infoContainerStyle, {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            transform: 'translate(0, -45px)'
        }],
        title: [
            InfoStatusGlobalClassNames.title,
            {
                fontSize: 18,
                fontWeight: 'bold',
                paddingTop: 10,
                paddingLeft: 27,
                marginBottom: 10,
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
            }
        ],
        singleItemThreeAlignment: [InfoStatusGlobalClassNames.singleItemThreeAlignment, {
            flexBasis: '50%'
        }],
        leftThreeAlignment: [InfoStatusGlobalClassNames.leftThreeAlignment,
        {
            flexGrow: 3,
            display: 'flex',
            flexFlow: 'row',
            gap: 74 //perchè bisogna considerare il padding lasciato dal suffix del text field sopra
        }],
        accordionContainer: [InfoStatusGlobalClassNames.accordionContainer, {
            paddingLeft: 27,
            marginBottom: 15
        }],
        formSection: [InfoStatusGlobalClassNames.formSection, {
            display: 'flex',
            justifyContent: 'space-between',
            gap: 50,
            alignItems: 'baseline'
        }],
        leftSectionForm: [InfoStatusGlobalClassNames.leftSectionForm, {
            flexGrow: 3,
            width: '65%'
        }],
        rightSectionForm: [InfoStatusGlobalClassNames.rightSectionForm, {
            flexGrow: 1,
            width: '30%'
        }],
        personaFormSection: [InfoStatusGlobalClassNames.personaFormSection, {
            width: '100%',
            display: 'flex',
            flexFlow: 'row wrap',
            padding: '5px 0',
            gap: 10
        }],
        leadersContainer: [InfoStatusGlobalClassNames.leadersContainer, {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 5,
            gap: 5
        }],
        phasesTitle: [InfoStatusGlobalClassNames.phasesTitle, {
            fontWeight: FontWeights.semibold,
        }],
        phaseTitleContainer: [InfoStatusGlobalClassNames.phaseTitleContainer, {
            display: 'flex',
            flexDirection: 'row',
            gap: 20,
            alignItems: 'center',
            margin: '20px 0'
        }],
        breakLine: [InfoStatusGlobalClassNames.breakLine, {
            height: 1,
            margin: '25px 400px',
            background: palette.neutralSecondary
        }]
    };
}