import { IStyleFunction } from "@fluentui/react"
import { IShortElementViewerPropsStyles, IShortElementViewerStyles } from "./shortElementViewer.types"

export const ShortElementViewerGlobalClassNames = {
    root: 'edi-short-element-viewer-root'
    ,itemCell: 'edi-short-element-viewer-itemCell'
    ,itemContent: 'edi-short-element-viewer-itemContent'
    ,itemHeader: 'edi-short-element-viewer-itemHeader'
    ,subComponentStyles: 'edi-short-element-viewer-subComponentStyles'
}

export const getStyles: IStyleFunction<IShortElementViewerPropsStyles, IShortElementViewerStyles> = (props: IShortElementViewerPropsStyles): IShortElementViewerStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            ShortElementViewerGlobalClassNames.root,
            {
                marginTop: '10px',
                boxShadow: 'rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px'
            }
        ]
        ,itemCell: [
            ShortElementViewerGlobalClassNames.itemCell,
            {
                padding: 10,
                minHeight: 54,
                background: palette.white,
                boxSizing: 'border-box',
                borderBottom: `1px solid ` + palette.themeSecondary,
                display: 'flex',
            }
        ]
        ,itemContent: [
            ShortElementViewerGlobalClassNames.itemContent,
            {
                textAlign: 'left',
                display: '-webkit-box',
                '-webkit-line-clamp': '4',
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        ]
        ,itemHeader: [
            ShortElementViewerGlobalClassNames.itemHeader,
            {
                display: 'flex',
                paddingLeft: 15,
                paddingTop: 15,
                color: palette.black,
                background: palette.white,
            }
        ],
        subComponentStyles:{
            iconButton: {
                root: {
                    height: '100%',
                    float: 'right'
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`
                },
                menuIcon: {
                    display: 'none !important'
                },
                rootExpanded: {
                    backgroundColor: 'inherit'
                },
                rootPressed: {
                    backgroundColor: 'inherit'
                },
                rootHovered: {
                    backgroundColor: 'inherit',
                }
            },
        }
    };
}