import { IDetailsHeaderStyleProps, IDetailsHeaderStyles, IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react"
import { DocumentTagsListGlobalClassNames } from "../../../docLab/components/files/documentTagsList/documentTagsList.styles";
import { IKnowledgeLabFileEnrichmentPropsStyles, IKnowledgeLabFileEnrichmentStyles } from "./knowledgeLabFileEnrichment.types"

export const KnowledgeLabFileEnrichmentGlobalClassNames = {
    root: 'edi-knowledge-lab-file-uploader-root'
}

export const getStyles: IStyleFunction<IKnowledgeLabFileEnrichmentPropsStyles, IKnowledgeLabFileEnrichmentStyles> = (props: IKnowledgeLabFileEnrichmentPropsStyles): IKnowledgeLabFileEnrichmentStyles => {
    return {
        root: [
            DocumentTagsListGlobalClassNames.root,
            {
                height: '100%',
                overflow: 'hidden'
            }
        ],
        emptyList: [
            DocumentTagsListGlobalClassNames.emptyList,
            {
                position: 'absolute',
                top: '60px',
                width: '150px',
                left: 'calc(50% - 88px)'
            }
        ],
        subComponentStyles: {
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        
                    },
                    headerWrapper: {
                        selectors: {
                            '.ms-FocusZone.css-138.ms-DetailsHeader.root-412': {
                                paddingTop: '0px'
                            }
                        }
                    },
                    contentWrapper: {},
                    focusZone: {}
                }
            },
            detailsHeader: (props: IDetailsHeaderStyleProps): IDetailsHeaderStyles => {
                return {
                    root: {
                        borderBottom: '0px'
                    },
                    cellIsCheck: {},
                    check: {},
                    cellWrapperPadded: {},
                    cellIsActionable: {},
                    cellIsEmpty: {},
                    cellSizer: {},
                    cellSizerStart: {},
                    cellSizerEnd: {},
                    cellIsResizing: {},
                    cellIsGroupExpander: {},
                    collapseButton: {},
                    checkTooltip: {},
                    sizingOverlay: {},
                    dropHintCircleStyle: {},
                    dropHintCaretStyle: {},
                    dropHintLineStyle: {},
                    dropHintStyle: {},
                    accessibleLabel: {}
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        borderBottom: '0px'
                    },
                    cell: {
                        alignSelf: 'center',
                        textOverflow: 'unset',
                        paddingBottom: '0px',
                        paddingTop: '10px !important',
                        height: '100%',
                        width: '100%',
                        display: 'inline-grid'
                    },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            }
        }
    };
}