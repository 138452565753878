import React from "react";
import { luminTest } from "./utils/utils";
import { MarkedSpan } from "../types/annotate-types";

export interface MarkProps<T> {
  key: string;
  content: string;
  start: number;
  end: number;
  onClick: (arg: T) => void;
  tag?: string;
  color?: string;
  className?: string;
  index?: number;
  search?:string;
  startCounter: number;
  selectedElement:number;
}

function replaceSpecialCharacters(searchString: string | undefined) {
  if (searchString === undefined || searchString === null)
    return searchString

  return searchString.replaceAll(
    /[.+*?[\](){}|^$\\]/g,
    (x: string) => `\\${x}`
  );
}

const marker =  (content: string, search: string, counter : number,selectedElement:number) =>{  
   
    
  const regex = new RegExp(replaceSpecialCharacters(search)||"","gmi");  
  const len = search?.length ?? 0;
  const index = content.search(regex);
  if (index === -1 || search === "" || search === undefined){  
    return <> {content}</>
  }
  else
  {
    counter++;
    const idMatch = "idxMatch"+counter;
    let tmp  = <>{content.substring(0, index)} <mark style={{
      backgroundColor: selectedElement === counter ? '#FF7F50' : '#FFFF66'
    }} id={idMatch}>{content.substring(index,index+len)}</mark></>;
    tmp =  <>{tmp}{marker( content.substring(index+len), search, counter,selectedElement )}</>;
   return tmp;
  }

}


const Mark = <T extends MarkedSpan>({
  color,
  className,
  end,
  start,
  onClick,
  content,
  tag,
  search,
  startCounter,
  selectedElement
}: MarkProps<T>) => {



  const lumin = color ? luminTest(color) : false;
  return (
    <mark
      className={className}
      style={{
        backgroundColor: color || "#84d2ff",
        padding: "0 4px",
        ...(lumin && { color: "white" }),
      }}
      data-start={start}
      data-end={end}
      onMouseUp={() => onClick({ start: start, end: end } as T)}
    >
      {marker(content,search||"",startCounter,selectedElement)}
      {tag && (
        <span style={{ fontSize: "0.7em", fontWeight: 500, marginLeft: 6 }}>
          {tag}
        </span>
      )}
    </mark>
  );
};

export default Mark;
