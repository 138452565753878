import { Breadcrumb } from "@fluentui/react";
import React, { useMemo } from "react";
import { useOnMount } from "../../../../utilities/hooks";
import { useDocLabDispatch } from "../../../docLabStore";
import { calculateBreadcrumbFromDeeplink, loadFolderContent, setIsDeeplink, useArchiveContent } from "../../../features/archiveContent";
import { IArchiveContentProps } from "./archiveContent.types";

export const FilesBreadcrumb = (props: IArchiveContentProps) => {
    const { archive, breadcrumb } = useArchiveContent();
    const dispatch = useDocLabDispatch();

    useOnMount(() => {
        if (props.fileId && props.isDeepLinkfolder !== undefined)
                dispatch(calculateBreadcrumbFromDeeplink({ fileId: props.fileId, isFolder: props.isDeepLinkfolder }));
    })

    const items = useMemo(() => {
        if (!archive)
            return [];
        return breadcrumb.map((item, ind) => ({
            key: item.id.toString(),
            text: item.label,
            onClick: () => {
                if (ind < breadcrumb.length - 1)
                    dispatch(loadFolderContent({
                        resetPage: true,
                        folderId: item.id
                    }));
                dispatch(setIsDeeplink(false));
            }
        }));
    }, [archive, breadcrumb, dispatch]);

    return (
        <Breadcrumb items={items} overflowIndex={items.length > 1 ? 1 : 0} />
    )
}