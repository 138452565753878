import { styled } from "@fluentui/react";
import { getStyles } from "./toolbar.styles";
import { IToolbarPropsStyles, IToolbarStyles, IToolbarProps } from "./toolbar.types";
import { ToolbarBase } from "./toolbar.base";

const DashboardToolbar = styled<IToolbarProps, IToolbarPropsStyles, IToolbarStyles>(
    ToolbarBase,
    getStyles,
    undefined,
    { scope: 'ToolBar' }
);

export default DashboardToolbar;