import { IButtonStyles, IconButton, Stack, TooltipDelay, TooltipHost } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorsType } from "../../../../../docLab/models/callsApi";
import authService from "../../../../../modules/authentication/teamsAuthService";
import { useAsyncApi } from "../../../../../utilities/hooks";
import { PortfolioRoleId } from "../../../../models/constants";
import { User } from "../../../../models/user";
import { portfolioApi } from "../../../../services/portfolio/portfolio.api";

export interface IRemoveMemberButtonProps {
    portfolioId: number;
    member: User;
    buttonStyles: IButtonStyles;
    onRemoveMemberCompleted: () => void;
}

export const RemoveMemberButton = (props: IRemoveMemberButtonProps) => {
    const { portfolioId, member, buttonStyles, onRemoveMemberCompleted } = props;
    const { t } = useTranslation('activityLabUsersSettings');
    const removeMember = useAsyncApi({
        func: async (member: User) => {
            let errorMessage = '';
            try {
                await portfolioApi.deleteMember(portfolioId, member.id);
            } catch (er) {
                const error: ErrorsType = er as ErrorsType;
                if (error.code === 422) {
                    errorMessage = t('errorRemoveMembers');
                }
            }
            if (errorMessage === '')
                onRemoveMemberCompleted();
        }
    });

    if (member.roleId === PortfolioRoleId.Owner)
        return null;

    const disabled = member.id === authService.currentUserId;

    return (
        <Stack horizontal verticalAlign={'baseline'}>
            {member.hasActivities
                ? <TooltipHost content={t('errorRemoveMembers')} delay={TooltipDelay.zero}>
                    <IconButton
                        disabled
                        styles={buttonStyles}
                        iconProps={{ iconName: "Cancel" }}
                    />
                </TooltipHost>

                : <IconButton
                    disabled={disabled || removeMember.loading}
                    styles={buttonStyles}
                    iconProps={{ iconName: "Cancel" }}
                    onClick={() => removeMember.execute(member)}
                />}
        </Stack>
    );
}