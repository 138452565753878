import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { themeDark, themeHighContrast, themeLight } from "@edi/fe-common/dist/themes";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { FontSizes, loadTheme, mergeStyleSets, mergeThemes, PrimaryButton, ThemeProvider, useTheme } from '@fluentui/react';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import * as microsoftTeams from "@microsoft/teams-js";
import { Settings } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Router, useHistory} from "react-router-dom";
import TeamsImage from "../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../common/components/teamsImage/teamsImage.types";
import { PreviewWrapper } from "../../docLab/components/files/filePreview/filePreview.base";
import { appInsights, browserHistory } from "../../modules/appInsights/appInsights";
import teamsAuthService from "../../modules/authentication/teamsAuthService";
import currentUser from "../../modules/authentication/currentUser";
import webAuthService from "../../modules/authentication/webAuthService";
import { initI18Next } from "../../modules/internationalization/config";
import { themeDark as appThemeDark } from "../../themes/dark.base";
import { themeHighContrast as appThemeHightContrast } from "../../themes/hc.base";
import { themeLight as appThemeLight } from "../../themes/light.base";
import { useOnMount } from "../../utilities/hooks";
import LoginPopup from "./loginPopup/loginPopup";
import Teams from "./teams/teams";

const classNames = mergeStyleSets({
  spinnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: FontSizes.large,
  },
  root: {
    height: "100%"
  },
  accessDeniedContainer: {
    display:'flex',
    flexDirection:'column',
    maxWidth: '30%',
    textAlign: 'center',
    alignItems: 'center'
  },
  accessDenied: {
    flexDirection:'row', 
    justifyContent:'center',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: FontSizes.large
  }
});

initI18Next();

const App = () => {
  const [theme, setTheme] = useState<string | undefined>("Light");
  const [authInitialized, setAuthInitialized] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);
  const [context, setContext] = useState<microsoftTeams.Context|undefined>(undefined);
  const startTheme = useTheme();
  const { t } = useTranslation(['authentication', 'common']);
  const [base, app] = useMemo(() => getPartialThemes(theme), [theme]);
  const merged = useMemo(() => mergeThemes(mergeThemes(startTheme, base), app), [base, app, startTheme]);

  // FIX FluentUI bug for custom components imported by fe-common
  useEffect(() => void loadTheme(merged), [theme]); //eslint-disable-line react-hooks/exhaustive-deps

  useOnMount(() => {
    initializeFileTypeIcons();
    initializeIcons();

    if (window.self !== window.top || window.location.pathname.startsWith('/login'))
      teamsInit();
    else
      webInit();
    
  });

  const webInit = useCallback(async () => {
    await webAuthService.init();
    setAuthInitialized(true);

    initI18Next('en');
    Settings.defaultLocale = 'en';

    const [success] = await webAuthService.tryGetAccessToken();

    if (success) {
      appInsights.setAuthenticatedUserContext(webAuthService.currentUserPrincipalName ?? '', webAuthService.currentUserId);
      try {
        await currentUser.getUserProfile(webAuthService.currentUserId ?? '');
        setAuthSuccess(true);
        setAccessDenied(false);
      }
      catch {
        setAuthError(true);
        setAccessDenied(true);
      }
    }
    else
      setAuthError(true);
  }, []);


  const teamsInit = useCallback(() => {
    microsoftTeams.initialize(() => {
      microsoftTeams.registerOnThemeChangeHandler(setTheme);
      microsoftTeams.getContext(async (ctx: microsoftTeams.Context) => {
        setContext(ctx);
        setTheme(ctx.theme);
        await teamsAuthService.init(ctx);
        setAuthInitialized(true);
        initI18Next(ctx.locale);
        Settings.defaultLocale = ctx.locale;

        if (window.parent === window.self)
          return;

        const [success] = await teamsAuthService.tryGetAccessToken();
        if (success) {
          appInsights.setAuthenticatedUserContext(teamsAuthService.currentUserPrincipalName ?? '', teamsAuthService.currentUserId);
          try {
            await currentUser.getUserProfile(teamsAuthService.currentUserId ?? '');
            setAuthSuccess(true);
            setAccessDenied(false);
          }
          catch {
            setAuthError(true);
            setAccessDenied(true);
          }
        }
        else
          setAuthError(true);
         
      });
    });
    
  }, []);

  return (
    <ThemeProvider theme={merged} className={classNames.root}>
      {!authSuccess && !authError &&
        <div className={classNames.spinnerContainer}>
          <TeamsSpinner />
        </div>
      }
      {authError && !accessDenied &&
        <div className={classNames.spinnerContainer}>
          <TeamsImage imageName={ImageName.Oops2} style={{ marginBottom: 10 }} />
          <span>{t('errorMessage1')}</span>
          <span>{t('errorMessage2')}</span>
          <PrimaryButton text={t('common:retry')} onClick={() => window.location.reload()} style={{ marginTop: 10 }} />
        </div>
      }
      {authError && accessDenied &&
        <div className={classNames.accessDenied} >
          <TeamsImage imageName={ImageName.Forbidden} styles={{ img: { width:'80%', height: '80%'}}} style={{ marginBottom: 10 }} />
          <div className={classNames.accessDeniedContainer}>
            <div style={{fontWeight:'bold', marginBottom:'40px', }}>{t('accessDenied')}</div>
            <PrimaryButton text={t('common:retry')} onClick={() => window.location.reload()} styles={{root:{maxWidth:'100px'}}} style={{ marginTop: 10 }} />
          </div>
        </div>
      }
      {authInitialized && !authError &&
        <Router history={browserHistory}>
          {!authSuccess &&
            <Route exact path="/login" component={LoginPopup} />
          }
          {authSuccess &&
            <>
              <Route path="/teams" render={()=><Teams teamsContext={context}></Teams>}/>
              <Route path="/tab" component={Teams} />
              <Route path="/preview" component={PreviewWrapper} />
              <Route exact path="/" component={() => <Home />}/>
              {/* <Route path="/pdf" component={PdfPreview} /> */}
            </>
          }
        </Router>
      }
    </ThemeProvider>
  );
}

const getPartialThemes = (theme: string | undefined) => {
  switch (theme) {
    case "dark": return [themeDark, appThemeDark];
    case "contrast": return [themeHighContrast, appThemeHightContrast];
    default: return [themeLight, appThemeLight];
  }
};

export default App;
//export default withAITracking(reactPlugin, App, undefined, classNames.root);

const Home = () => {
  const history = useHistory();

  useOnMount(() => {
    history.push("/teams");
  })

  return <></>
}