import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner"
import { DefaultButton, mergeStyleSets, PrimaryButton } from "@fluentui/react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage"
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types"
import { Helpers } from "../../../../../utilities/helpers"
import { useOnMount } from "../../../../../utilities/hooks"
import { useDocLabDispatch } from "../../../../docLabStore"
import { loadArchives } from "../../../../features/archiveList"
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive"
import { ArchiveRoleId, ArchiveTypeId } from "../../../../models/constants"
import { User } from "../../../../models/user"
import { archivesApi } from "../../../../services/archives/archives.api"
import { CreateArchiveRequest } from "../../../../services/archives/archives.contracts"

export type CreatingArchivePageData = {
    name?: string;
    description?: string;
    members: User[];
}

export interface ICreateArchiveFirstPageProps {
    initialState: CreatingArchivePageData;
    onClose?: () => void;
}

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '> :not(:last-child)': {
            marginBottom: 20
        }
    },
    label: {
        fontSize: 24,
        fontWeight: 600,
        textAlign: 'center'
    },
    errorButtons: {
        display: 'flex',
        justifyContent: 'space-around',
        alignSelf: 'stretch'
    }
})

export const CreatingArchivePage = (props: ICreateArchiveFirstPageProps) => {
    const [success, setSuccess] = useState<boolean | undefined>(undefined);
    const [creating, setCreating] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const dispatch = useDocLabDispatch();
    const archive = useCurrentArchive();
    const { t } = useTranslation(['createArchiveModal', 'common']);

    useOnMount(() => void createArchive());
    useEffect(() => { success && dispatch(loadArchives(archive?.id ?? 0)) }, [dispatch, success, archive]);

    const createArchive = async () => {
        if (success)
            return;

        setCreating(true);
        setSuccess(undefined);

        const data = props.initialState;

        const request: CreateArchiveRequest = {
            name: data.name ?? "",
            description: data.description ?? "",
            typeId: ArchiveTypeId.Unit,
            members: data.members.map(member => ({
                userId: member.id,
                archiveRoleId: member.roleId ?? ArchiveRoleId.Reader
            }))
        }

        try { await Promise.all([archivesApi.createArchive(request), Helpers.delay(500)]); setSuccess(true); }
        catch { setErrorCount(errorCount + 1); setSuccess(false); }
        finally { setCreating(false); }
    }

    return (
        <div className={classNames.container}>
            {creating && !success &&
                <>
                    <TeamsSpinner />
                    <span className={classNames.label}>{t('creatingArchive')}</span>
                </>
            }
            {!creating && success &&
                <>
                    <TeamsImage imageName={ImageName.WellDone} style={{ width: '80%' }} />
                    <span className={classNames.label}>{t('common:niceWork')}</span>
                    <PrimaryButton text={t('common:leave')} onClick={props.onClose} style={{ flex: '0 0 auto' }} />
                </>
            }
            {!creating && success === false &&
                <>
                    <div className={classNames.label}>{t('common:genericErrorApi')}{t('ofArchive')}</div>
                    <div className={classNames.errorButtons}>
                        {errorCount < 3 && <DefaultButton text={t('common:tryAgain')} onClick={createArchive} />}
                        <PrimaryButton text={t('common:leave')} onClick={props.onClose} />
                    </div>
                </>
            }
        </div>
    )
}