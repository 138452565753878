import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import activityList from './features/activityList';
import portfolioList from './features/portfolioList';


const activityLabStore = configureStore({
    reducer: {
        portfolioList: portfolioList,
        activityList: activityList
    }
});

export type ActivityLabState = ReturnType<typeof activityLabStore.getState>;
export type ActivityLabDispatch = typeof activityLabStore.dispatch;
export default activityLabStore;

export const useActivityLabState = (): ActivityLabState => activityLabStore.getState();
export const useActivityLabDispatch = (): ActivityLabDispatch => useDispatch<ActivityLabDispatch>();
export const useActivityLabSelector: TypedUseSelectorHook<ActivityLabState> = useSelector;