import { FontSizes, IStyleFunction } from "@fluentui/react"
import { IKnowledgeLabFileUploaderPropsStyles, IKnowledgeLabFileUploaderStyles } from "./knowledgeLabFileUploader.types"

export const KnowledgeLabFileUploaderGlobalClassNames = {
    root: 'edi-knowledge-lab-file-uploader-root'
}

export const getStyles: IStyleFunction<IKnowledgeLabFileUploaderPropsStyles, IKnowledgeLabFileUploaderStyles> = (props: IKnowledgeLabFileUploaderPropsStyles): IKnowledgeLabFileUploaderStyles => {
    return {
        textParagraph: {
            fontSize: FontSizes.size12,
            fontWeight: 'bold',
        }
    };
}