import { classNamesFunction, Stack } from "@fluentui/react";
import React, { useState } from "react";
import { Route } from "react-router-dom";
import Page from "../../../../common/components/page/Page";
import { KnowledgeLabRoutes, KnowledgeSettingsTab, KnowledgeTab } from "../../../routes/routes";
import Documents from "../../documents/documents";
import Favourites from "../../favourites/favourites";
import DownloadNotification from "../../files/downloadNotification/downloadNotification";
import KnowledgeLabList from "../../knowledgeLabList/knowledgeLabList";
import Search from "../../search/search";
import KnowledgeLabMembersSettings from "../../settings/knowledgeLabMembersSettings/knowledgeLabMembersSettings";
import KnowledgeToolbar from "../toolbar/toolbar";
import { IRootProps, IRootPropsStyles, IRootStyles } from "./root.types";


const getClassNames = classNamesFunction<IRootPropsStyles, IRootStyles>();

const collapseStyle = {
    flexBasis: 0,
    opacity: 0,
}

export const RootBase = (props: IRootProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    return (
        <>
            <DownloadNotification />
            <Stack className={classNames.root} horizontal verticalAlign={"stretch"}>
                <Stack.Item className={classNames.leftNav} disableShrink style={sidebarCollapsed ? collapseStyle : {}}>
                    <KnowledgeLabList></KnowledgeLabList>
                </Stack.Item>
                <Stack.Item className={classNames.rightContent} grow>
                    <Stack.Item>
                        <Stack.Item>
                            <KnowledgeToolbar onMenuButtonClick={() => setSidebarCollapsed(!sidebarCollapsed)}></KnowledgeToolbar>
                        </Stack.Item>

                    </Stack.Item>

                    <Stack.Item style={{ height: '100%' }}>
                        <Route path={`${KnowledgeLabRoutes.knowledgeLab}/${KnowledgeTab.favorites}`}>
                            <Page title="Knowledge Lab - Favourites" content={Favourites} ></Page>
                        </Route>
                        <Route path={`${KnowledgeLabRoutes.knowledgeLab}/${KnowledgeTab.search}`}>
                            <Page title="Knowledge Lab - Search" content={Search} />
                        </Route>
                        <Route path={`${KnowledgeLabRoutes.knowledgeLab}/${KnowledgeTab.documents}`}>
                            <Page title="Knowledge Lab - Documents" content={Documents} />
                        </Route>
                        <Route path={`${KnowledgeLabRoutes.knowledgeLab}/settings/${KnowledgeSettingsTab.users}`}>
                            <Page title="Knowledge Lab - Settings - Members" content={KnowledgeLabMembersSettings} />
                        </Route>
                    </Stack.Item>

                </Stack.Item>
            </Stack>
        </>
    );
}