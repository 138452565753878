import { IStyleFunction } from "@fluentui/react"
import { IProgressBarPropsStyles, IProgressBarStyles } from "./progressBar.types"

export const ProgressBarGlobalClassNames = {
    root: 'edi-progress-bar-root'
}

export const getStyles: IStyleFunction<IProgressBarPropsStyles, IProgressBarStyles> = (props: IProgressBarPropsStyles): IProgressBarStyles => {
    return {
        root: [
            ProgressBarGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        progressIndicatoritemName: {
            fontWeight: 'bold',
            fontSize: '21px'
        },
        progressIndicatoritemDescription: {
            textAlign: 'left'
        },
        progressIndicatorRed: {
            height: '20px',
            backgroundColor: 'red'
        },
        progressIndicator: {
            height: '20px',
            backgroundColor: 'orange',
        },
        progressTrack: {
            backgroundColor: 'lightgray',
            height: '20px',
        }
    };
}