import { FontSizes, FontWeights, IStyleFunction } from "@fluentui/react"
import { IImportExportMetadataPropsStyles, IImportExportMetadataStyles } from "./importExport.types"

export const exportReportSettingsGlobalClassNames = {
    root: 'edi-export-report-settings-root',
    title: 'edi-export-report-title',
    iconTitle: 'edi-export-report-icon-title',
    rowContainer: 'edi-ImportExport-row-container',
    rowTitle: 'edi-row-title',
    rowDescription: 'edi-row-description',
    invisibleItem: 'edi-row-item-invisible',
    rowTitleContainer: 'edi-row-title-container',
    tooltipStyle: 'edi-row-tooltip',
}

export const getStyles: IStyleFunction<IImportExportMetadataPropsStyles, IImportExportMetadataStyles> = (props: IImportExportMetadataPropsStyles): IImportExportMetadataStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    
    return {
        root: [
            exportReportSettingsGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px 0px 25px'
            }
        ],
        iconTitle: [
            exportReportSettingsGlobalClassNames.iconTitle,
            {
                padding: 7,
                border: '1.5px solid',
                borderRadius: '50%',
                fontSize: 26
            }
        ],
        title: [
            exportReportSettingsGlobalClassNames.title,
            {
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingBottom: 10
            }
        ],        
        rowTitleContainer: [
            exportReportSettingsGlobalClassNames.rowTitleContainer,
            {
                display: 'flex',
                marginBottom: '5px',
                marginTop: '5px',
                padding: '10px 40px 10px 53px',
                flexDirection: 'column',
                width: '100%'
            }
        ],
        rowTitle: [
            exportReportSettingsGlobalClassNames.rowTitle,
            {
                width: '100%',
                maxWidth: '370px',
                fontSize: FontSizes.size20,
                fontWeight: FontWeights.semibold,
                paddingBottom: 2
            }
        ],
        rowDescription: [
            exportReportSettingsGlobalClassNames.rowDescription,
            {
                textOverflow: 'ellipsis',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }
        ],
        rowContainer: [
            exportReportSettingsGlobalClassNames.rowContainer,
            {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '15px',
                height: 'auto',
                width: '100%',
                backgroundColor: palette.white,
            }
        ],
        invisibleItem: [
            exportReportSettingsGlobalClassNames.invisibleItem,
            {
                visibility: 'hidden'
            }
        ],
        tooltipStyle: [
            exportReportSettingsGlobalClassNames.tooltipStyle,
            {
                cursor:'pointer',
                justifyContent:'flex-start',
                alignItems: 'center',
                color: 'rgb(98, 100, 167)',
                fontWeight: '600',
                gap: 5,
                marginLeft: '5px',
                textOverflow: 'ellipsis',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }
        ],
    }
}