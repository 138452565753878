import { styled } from "@fluentui/react";
import { getStyles } from "./archiveNavigation.styles";
import { IArchiveNavigationPropsStyles, IArchiveNavigationStyles, IArchiveNavigationProps } from "./archiveNavigation.types";
import { ArchiveNavigationBase } from "./archiveNavigation.base";

const ArchiveNavigation = styled<IArchiveNavigationProps, IArchiveNavigationPropsStyles, IArchiveNavigationStyles>(
    ArchiveNavigationBase,
    getStyles,
    undefined,
    { scope: 'ArchiveNavigation' }
);

export default ArchiveNavigation;