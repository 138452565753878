import { styled } from "@fluentui/react";
import { getStyles } from "./activityBoard.styles";
import { IActivityBoardPropsStyles, IActivityBoardStyles, IActivityBoardProps } from "./activityBoard.types";
import { ActivityBoardBase } from "./activityBoard.base";

const ActivityBoard = styled<IActivityBoardProps, IActivityBoardPropsStyles, IActivityBoardStyles>(
    ActivityBoardBase,
    getStyles,
    undefined,
    { scope: 'ActivityBoard' }
);

export default ActivityBoard;