/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useState } from "react";
import { DefaultButton, Label, PrimaryButton, Spinner, SpinnerSize, TextField, classNamesFunction } from "@fluentui/react";
import { IFilesApprovalDocumentModalProps, IFilesApprovalDocumentModalPropsStyles, IFilesApprovalDocumentModalStyles } from "./filesApprovalDocumentModal.types";
import { useDocLabDispatch } from "../../../docLabStore";
import { loadFolderContent, showFilesApprovalDocumentModal, useArchiveContent } from "../../../features/archiveContent";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { useOnMount } from "../../../../utilities/hooks";
import { setSearchParams, setSelectionEntryList, useSearchContent } from "../../../features/searchContent";
import { FileApprovalStatus } from "../../../models/archiveItem";
import { archivesApi } from "../../../services/archives/archives.api";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { setToolbarActions } from "../../../features/toolbarActions";

const getClassNames = classNamesFunction<IFilesApprovalDocumentModalPropsStyles, IFilesApprovalDocumentModalStyles>();

export const FilesApprovalDocumentModalBase = (props: IFilesApprovalDocumentModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const dispatch = useDocLabDispatch();
    const currentArchive = useCurrentArchive();
    const { filesApprovalDocumentModalShown, approvalStatusToUpdate, selectedItems } = useArchiveContent();
    const { selectionEntryList } = useSearchContent();
    const selectedItemsList = selectedItems.length > 0 ? selectedItems : selectionEntryList;
    const { t } = useTranslation(['filesApprovalDocumentModal', 'common']);

    const [loading, setLoading] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [bodyText, setBodyText] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [rejectionNote, setRejectionNote] = useState<string | undefined>(undefined);
    const [emailsRecipients, setEmailsRecipients] = useState<string | undefined>(undefined);

    useOnMount(() => {
        setTitleAndBodyText();
    });

    const setTitleAndBodyText = () => {
        switch(approvalStatusToUpdate)
        {
            case FileApprovalStatus.ToBeApproved:
                if(selectedItemsList.length === 1) {
                    setModalTitle(t('titleResubmitApprovalDocument'));
                    setBodyText(t('bodyTextResubmitApprovalDocument'));
                }
                else {
                    setModalTitle(t('titleResubmitApprovalDocuments'));
                    setBodyText(t('bodyTextResubmitApprovalDocuments'));
                }
                break;
            case FileApprovalStatus.Approved:
                if(selectedItemsList.length === 1) {
                    setModalTitle(t('titleApproveDocument'));
                    setBodyText(t('bodyTextApproveDocument'));
                }
                else {
                    setModalTitle(t('titleApproveDocuments'));
                    setBodyText(t('bodyTextApproveDocuments'));
                }
                break;
            case FileApprovalStatus.Rejected:
                if(selectedItemsList.length === 1) {
                    setModalTitle(t('titleRejectDocument'));
                    setBodyText(t('bodyTextRejectDocument'));
                }
                else {
                    setModalTitle(t('titleRejectDocuments'));
                    setBodyText(t('bodyTextRejectDocuments'));
                }
                break;
            default:
                setModalTitle(t('errorLabel'));
                setErrorMessage(t('errorLabel'));
                break;
        }
    }

    const body = () => {
        return (<>
            {loading ?
            <div className={classNames.centerContainer}>
                <Spinner size={SpinnerSize.large} />
            </div>
            :
            <div className={classNames.mainContainer}>
                <Label>{bodyText}</Label>
                {approvalStatusToUpdate === FileApprovalStatus.Rejected &&
                <div className={classNames.rejectContainer}>
                    <TextField
                        label={t('emailsRecipientsTitle')}
                        value={emailsRecipients}
                        width={'100%'}
                        rows={5}
                        resizable={false}
                        multiline
                        placeholder={t('placeholderEmailsRecipients')}
                        styles={{
                            field: { color: palette.neutralPrimary, 'input': { '&::placeholder': { color: palette.blackTranslucent40 } } }
                        }}
                        onChange={(_, text) => { setEmailsRecipients(text); }}
                    />
                    
                    <TextField
                        label={t('rejectionNoteTitle')}
                        value={rejectionNote}
                        width={'100%'}
                        onChange={(_, text) => { setRejectionNote(text); }}
                    />
                </div>}
            </div>}
        </>);
    }

    const footer = () => {
        return (
            <div className={classNames.mainFooterContainer}>
                <div className={classNames.errorContainer}>
                    {!loading && errorMessage !== undefined && errorMessage}
                </div>
                <div className={classNames.footerContainer}>
                    <DefaultButton
                        disabled={loading}
                        style={{marginRight: '5px'}}
                        text={t('common:close')}
                        onClick={() => dispatch(showFilesApprovalDocumentModal({show: false, updateStatus: undefined}))}
                    />
                    <PrimaryButton
                        disabled={loading}
                        text={t('common:confirm')}
                        onClick={async () => {
                            try {
                                setLoading(true);
                                setErrorMessage(undefined);
                                await archivesApi.updateApprovalStatus(
                                    {
                                        archiveId: currentArchive?.id ?? 0,
                                        fileIds: selectedItemsList.map(s => s.id),
                                        approvalStatus: approvalStatusToUpdate,
                                        rejectionNote: rejectionNote,
                                        emailsRecipients: emailsRecipients
                                    }
                                );
                                dispatch(showFilesApprovalDocumentModal({show: false, updateStatus: undefined}));
                                
                                if (selectedItems.length > 0)
                                    dispatch(loadFolderContent({ resetPage: true }));
                                else {
                                    dispatch(setSearchParams({ selectedFacets: [] }));
                                    dispatch(setSelectionEntryList([]));
                                    dispatch(setToolbarActions([]));
                                }
                            }
                            catch {
                                setErrorMessage(t('errorLabel'));
                            }
                            finally {
                                setLoading(false);
                            }
                        }}
                    />
                </div>
            </div>
        );
    }

    return (
        <EdiModal
            title={modalTitle}
            width={approvalStatusToUpdate === FileApprovalStatus.Rejected ? 550 : 350}
            height={approvalStatusToUpdate === FileApprovalStatus.Rejected ? 450 : 270}
            body={body()}
            footer={footer()}
            isOpen={filesApprovalDocumentModalShown}
            showCloseIcon={!loading}
            onCloseClick={() => dispatch(showFilesApprovalDocumentModal({show: false, updateStatus: undefined}))}
        />
    );
}