/* eslint-disable sonarjs/cognitive-complexity */
import { classNamesFunction, ConstrainMode, IColumn, Icon, IconButton, SelectionMode, ShimmeredDetailsList, Stack, StackItem, TextField } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { useAsyncApi, useOnMount } from "../../../../../utilities/hooks";
import { Archive } from "../../../../models/archive";
import { Constants } from "../../../../models/constants";
import { archivesApi } from "../../../../services/archives/archives.api";
import { IArchiveNavigationProps, IArchiveNavigationPropsStyles, IArchiveNavigationStyles } from "./archiveNavigation.types";
import webAuthService from "../../../../../modules/authentication/webAuthService";
import { GetArchivesParams } from "../../../../services/archives/archives.contracts";
import teamsAuthService from "../../../../../modules/authentication/teamsAuthService"; 
import { useArchiveContent } from "../../../../features/archiveContent";
import _ from "lodash";

const getClassNames = classNamesFunction<IArchiveNavigationPropsStyles, IArchiveNavigationStyles>();

export const ArchiveNavigationBase = (props: IArchiveNavigationProps) => {
    const { t } = useTranslation(['navigationModal', 'common']);
    const [archiveList, setArchiveList] = useState<Archive[]>([]);
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const [keyword, setKeyword] = useState("");
    const { archiveRolesDest, archiveTypes } = useArchiveContent();

    const { loading, error, execute: getArchives, value: items } = useAsyncApi<GetArchivesParams, Archive[]>({
        func: async (input: GetArchivesParams): Promise<Archive[]> => { 

            const response = await archivesApi.getArchives(input.types, input.keyword); 
            setArchiveList(response);
             
            return response;
        }
    });
    const classNames = getClassNames(props.styles, { 
        theme: props.theme, 
        className: props.className, 
        isEmptyList: !items || items.length === 0 
    });

    useOnMount(() => {
        getArchives({types: archiveTypes ?? [], keyword: keyword});
    });

    const emptyArchiveList = useCallback(() => {
        if (loading || (items?.length !== 0))
            return null;

        return (
            <div className={classNames.emptyFolder}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyFolder")}
                />
            </div>
        )
    }, [classNames.emptyFolder, items, t, loading]);

    const columns : IColumn[] = [   
    {
        key: 'file-type',
        name: 'File Type',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: '',
        minWidth: 20,
        maxWidth: 20,
        onRender: function getItemCell(item: Archive) {
            return <span className={ _.includes(archiveRolesDest, item.currentUserRoleId) ? "" : classNames.disabled}> <Icon iconName="archive" /> </span>
        }
    }, 
    {
        key: 'Name',
        name: t("name"),
        fieldName: 'Name',
        minWidth: 100,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        onRender: function getItemName(item: Archive) {        
            const canAccess = _.includes(archiveRolesDest, item.currentUserRoleId);
            return (
                <span
                    onClick={() => {canAccess && props.onArchiveSelected(item)}}
                    style={{ cursor: canAccess ?  'pointer' : 'default'  }}
                    className={ canAccess ?  '' : classNames.disabled}
                >
                    {item.name}
                </span>
            )
        }
    }];

    return (
        <div className={classNames.root} id={"modalListZone"}>
            {error &&
                <TeamsImage
                    imageName={ImageName.Error1}
                    scale={0.3}
                    fullContainer
                    caption={t("errorLoading")}
                />
            }
            {!error &&
                <>
                    <Stack horizontal style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <StackItem grow={2}> 
                        <div className={classNames.breadcrumbContainer}>
                            <Icon styles={{ root: { fontSize: '18px' } }} iconName="home" />
                            <div className={classNames.nameContainer}> 
                            {window.self !== window.top ? teamsAuthService.currentUserName  : webAuthService.currentUserName}                            </div>                           
                        </div>                
                        </StackItem>
                        <StackItem align="end">
                            <div className={classNames.searchContainer}>
                                <TextField placeholder={t("searchDoclab")}
                                    value={keyword}
                                    onChange={(_, value) => setKeyword(value || '')}
                                    styles={{
                                        field: { color: palette.neutralPrimary, 'input': { '&::placeholder': { color: palette.neutralPrimary } } }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && e.currentTarget.value) {
                                            getArchives({types: archiveTypes ?? [], keyword: e.currentTarget.value})
                                        }

                                    }}
                                    maxLength={props.searchFieldMaxLenght || Constants.MAX_LENGHT_25}
                                />
                                <IconButton
                                    iconProps={{ iconName: "Cancel" }}
                                    styles={{
                                        rootHovered: { background: palette.neutralLight }
                                    }}
                                    style={{ height: 20, color: palette.black }}
                                    onClick={() => {
                                            setKeyword(''); 
                                            getArchives({types: archiveTypes ?? [], keyword: ''})
                                    }}
                                />
                            </div>
                        </StackItem>
                    </Stack>


                    <div id={"folderContentDetailsListZone"} style={{ height: 'calc(100% - 55px)', overflow: 'overlay' }}>
                        <ShimmeredDetailsList
                            enableShimmer={loading}
                            items={archiveList ?? []}
                            columns={columns}
                            getKey={(item: Archive) => item && `${item.id}`}
                            selectionMode={SelectionMode.none}
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderDetailsFooter={emptyArchiveList}
                            onShouldVirtualize={() => false}
                            compact={true}
                            isHeaderVisible={false}
                            styles={classNames.subComponentStyles.shimmeredDetailsList}
                        />
                    </div>
                </>
            }
        </div>
    );
}