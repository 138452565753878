import { styled } from "@fluentui/react";
import { getStyles } from "./feedbackModal.styles";
import { IFeedbackModalPropsStyles, IFeedbackModalStyles, IFeedbackModalProps } from "./feedbackModal.types";
import { FeedbackModalBase } from "./feedbackModal.base";

const FeedbackModal = styled<IFeedbackModalProps, IFeedbackModalPropsStyles, IFeedbackModalStyles>(
    FeedbackModalBase,
    getStyles,
    undefined,
    { scope: 'FeedbackModal' }
);

export default FeedbackModal;