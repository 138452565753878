import React, { useCallback, useEffect, useState } from "react";
import { ActionButton, Checkbox, classNamesFunction, DetailsListLayoutMode, IColumn, SelectionMode, ShimmeredDetailsList, Stack, TextField } from "@fluentui/react";
import { IFileNavigatorPropsStyles, IFileNavigatorStyles, IFileNavigatorProps } from "./fileNavigator.types";
import { NavigableItem } from "../../../models/navigableFile";
import { Helpers } from "../../../../utilities/helpers";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { useTranslation } from "react-i18next";

/*eslint-disable sonarjs/cognitive-complexity*/

const getClassNames = classNamesFunction<IFileNavigatorPropsStyles, IFileNavigatorStyles>();

export const FileNavigatorBase = (props: IFileNavigatorProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    //const [currentLevel, setCurrentLevel] = useState<EdiFile[]>(props.default || []);
    const [selectedFiles, setSelectedFiles] = useState<NavigableItem[]>(props.defaultSelected);
    const [parentFolder, setParentFolder] = useState<NavigableItem | undefined>(undefined);
    const [items, setItems] = useState<NavigableItem[]>([]);
    const [filter, setFilter] = useState<string>();
    const { t } = useTranslation('importKnowledgeLabDocument');


    useEffect(() => {
        setItems(props.currentLevel);
        setFilter("");
    }, [props.currentLevel]);

    const _columns = [
        { key: 'type', name: '', fieldName: 'type', minWidth: 50, maxWidth: 50, isResizable: false },
        { key: 'name', name: 'Name', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'createdBy', name: 'Created By', fieldName: 'createdBy', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'createdOn', name: 'Created On', fieldName: 'createdOn', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'size', name: 'Size', fieldName: 'size', minWidth: 100, maxWidth: 200, isResizable: true },
    ];

    const _renderItemColumn = (item: NavigableItem, index: number | undefined, column: IColumn | undefined) => {
        if (!column) return <></>

        const element = renderCell(item, column);
        if (column.key === 'type') {
            return <Stack horizontal>
                <Stack.Item style={{ minWidth: '30px' }}>
                    {
                        !item.isNavigable &&
                        <Checkbox
                            className={classNames.checkBox}
                            checked={selectedFiles.filter(i => i.id === item.id).length > 0}
                            onChange={(e, value) => onChangeSelection(item, value ? value : false)}
                            disabled={props.acceptedExtensions && props.acceptedExtensions.filter(i => i === item.extension?.toLowerCase()).length === 0}
                        />
                    }
                </Stack.Item>
                <Stack.Item>
                    {element}
                </Stack.Item>
            </Stack>
        }
        return element;
    }

    const addSelectedFile = (file: NavigableItem): NavigableItem[] => {
        const selected = Object.assign([], selectedFiles);
        selected.push(file);
        setSelectedFiles(selected);
        return selected;
    }
    const removeSelectedFile = (file: NavigableItem): NavigableItem[] => {
        const selected = Object.assign([], selectedFiles);
        const remains = selected.filter(i => i !== file);
        setSelectedFiles(remains);
        return remains;
    }

    const onChangeSelection = (file: NavigableItem, selection: boolean) => {
        const selectedFile = selectedFiles.find(i => i.id === file.id);
        let newSelection = [] as NavigableItem[];
        if (!selection && selectedFile)
            newSelection = removeSelectedFile(selectedFile);
        else if (selection)
            newSelection = addSelectedFile(file);

        props.onChangeSelection && props.onChangeSelection(newSelection);
    }

    const navigateTo = (folder: NavigableItem | undefined) => {
        setParentFolder(folder)
        props.onGoingInto && props.onGoingInto(folder)
    }

    const renderCell = (item: NavigableItem, column: IColumn) => {

        const fieldContent = item[column.fieldName as keyof NavigableItem] as string;
        if (column.key === 'type') {
            return props.onRenderIcon && props.onRenderIcon(item);
        }
        else if (column.key === 'createdOn') {
            return <span>{Helpers.formatToRelativeDate(fieldContent)}</span>
        }
        else if (column.key === 'name') {
            const content = props.onRenderName ? props.onRenderName(item) : item.name;
            return (
                <span
                    className={item.isNavigable ? classNames.navigableFolder : ''}
                    onClick={() => {
                        item.isNavigable && navigateTo(item);
                    }}>
                    {content}
                </span>
            )
        }
        return <span>{fieldContent}</span>;
    }

    const emptyFolder = useCallback(() => {
        if ((props.currentLevel.length > 0 || props.isLoading)) {
            return null;
        }

        return (
            <div className={classNames.emptyFolder}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyFolder")}
                />
            </div>
        )
    }, [classNames.emptyFolder, props.currentLevel.length, t, props.isLoading]);


    const _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
        if (newValue) {
            setFilter(newValue);
            setItems(props.currentLevel.filter(i => i.name.toLowerCase().indexOf(newValue.toLowerCase()) > -1));
        } else {
            setFilter("");
            setItems(props.currentLevel);
        }
    };

    return (
        <>
            {/* <div style={{ display: "flex", justifyContent: "flex-end", position: "sticky", top: 0, zIndex: 2 }}>
                <div style={{ width: '100px', marginBottom: 5 }}>
                    <TextField placeholder={t("search")} onChange={_onChangeText} value={filter} disabled={props.isLoading} />
                </div>
            </div> */}
            <div className={classNames.root} style={{ ...props.style, height: props.height + 'px' }}>
                <Stack horizontal style={{ minHeight: '40px' }} horizontalAlign="space-between">
                    {(parentFolder) ? <ActionButton iconProps={{ iconName: 'Up' }} onClick={() => navigateTo(parentFolder.parent)}>{parentFolder.name}</ActionButton> : <div></div>}
                    <div style={{ width: '100px', marginBottom: 5 }}>
                        <TextField placeholder={t("search")} onChange={_onChangeText} value={filter} disabled={props.isLoading} />
                    </div>
                </Stack>
                <div className={classNames.navigator} style={{ maxHeight: 'calc(100% - 40px)' }} data-is-scrollable="true">

                    <ShimmeredDetailsList
                        styles={{}}
                        items={items}
                        columns={_columns}
                        setKey="set"
                        enableShimmer={props.isLoading}
                        layoutMode={DetailsListLayoutMode.justified}
                        onRenderItemColumn={_renderItemColumn}
                        selectionPreservedOnEmptyClick={false}
                        selectionMode={SelectionMode.none}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="select row"
                        onItemInvoked={(e) => { console.log(e) }}
                        onRenderDetailsFooter={emptyFolder}
                        data-is-scrollable="true"
                    />
                </div>
            </div>
        </>
    );



}