import React from "react";
import { useOnMount } from "../../../utilities/hooks";

export interface IPageProps {
    title: string;
    children?: JSX.Element;
    content?: React.ComponentType;
}

const Page = (props: IPageProps) => {
    useOnMount(() => { document.title = `EDI - ${props.title ?? ""}` });

    if (props.content)
        return React.createElement(props.content);

    return props.children ?? null;
};

export default Page;