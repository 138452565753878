/*eslint-disable sonarjs/cognitive-complexity */

import React, { useEffect, useMemo, useState } from "react";
import { classNamesFunction, DatePicker, DefaultButton, DetailsHeader, DetailsList, DetailsRow, Dialog, DialogFooter, IColumn, IDatePickerStyles, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack, StackItem, TooltipHost, TooltipOverflowMode } from "@fluentui/react";
import { IExportModalFilesMetadataChangesReportBaseProps, IExportModalFilesMetadataChangesReportBasePropsStyles, IExportModalFilesMetadataChangesReportBaseStyles } from "./ExportModalFilesMetadataChangesReport.types"
import MultiStepModal from "../../../../../common/components/multiStepModal/multiStepModal";
import { IModalStepData } from "../../../../../common/components/multiStepModal/multiStepModal.types";
import { useTranslation } from "react-i18next";
import { setArchiveFilesMetadataChangesReportShow } from "../../../../features/archiveContent";
import { useDocLabDispatch, useDocLabState } from "../../../../docLabStore";
import { CreateReportMetadataRequest } from "../../../../services/archives/archives.contracts";
import { archivesApi } from "../../../../services/archives/archives.api";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { JsonSchemaField, ReportType } from "../../../../models/archive";
import { MetadataSelect } from "../../../../models/metadataDependency";
import { useOnMount } from "../../../../../utilities/hooks";
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive";
import { IObjectWithKey, ISelection, Selection } from '@fluentui/react/lib/DetailsList';



const  getClassNames = classNamesFunction<IExportModalFilesMetadataChangesReportBasePropsStyles, IExportModalFilesMetadataChangesReportBaseStyles>();

export enum ExportMetadataChangesStep {
    homepage = 4,
    dateRange = 0,
    metadataWork = 1,
    exporting = 2,
}

export const ExportModalFilesMetadataChangesReportBase = (props: IExportModalFilesMetadataChangesReportBaseProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [currentStep, setCurrentStep] = useState<ExportMetadataChangesStep>(ExportMetadataChangesStep.dateRange);
    const [loading, setLoading] = useState<boolean>(false);
    // eslint-disable-next-line
    const [labelError, setLabelError] = useState<boolean>(false);  
    const [metadataList, setMetadataList] = useState<MetadataSelect[]>([]);
    const [selectedList, setSelectedList] = useState<MetadataSelect[]>([]);
    const [showDialog, setShowDialog] = useState(false);
    const [selectedDateTo, setSelectedToDate] = React.useState<Date | undefined>(new Date());
    const [selectedDateFrom, setSelectedFromDate] = React.useState<Date | undefined>(new Date());
    const currentArchive = useCurrentArchive();
    const { t } = useTranslation(['exportArchiveModal', 'common']);
    const dispatch = useDocLabDispatch();
    const docLabState = useDocLabState();
    const datePickerStyles: Partial<IDatePickerStyles> = { root: { maxWidth: 300, marginTop: 15 } };

    useEffect(() => {
        const today = new Date();
        const twoWeeksAgoDate = new Date(today);
        twoWeeksAgoDate.setDate(today.getDate() - 14);
        setSelectedFromDate(twoWeeksAgoDate);
      }, []);
 
    const getSelectedItems = useMemo((): ISelection => {
        const selection: Selection = new Selection({
            onSelectionChanged: () => {
                const selectionDetails = selection.getSelection();
                const res: MetadataSelect[] = [];
                selectionDetails.forEach(i => {
                    const item = i as MetadataSelect;
                    res.push(item)
                })
                setSelectedList(res);
            }
        });
        const items = [...selectedList];
    
        selection.setItems(items as IObjectWithKey[]);
        items.forEach((selectedItem,ind) => { 
             selection.setKeySelected(ind.toString(), true, false);
        });
        return selection;
    },[dispatch, currentStep])  //eslint-disable-line react-hooks/exhaustive-deps
    
    useOnMount(() => {
        if (!currentArchive)
            return;
        const list: MetadataSelect[] = [];
        const keysMetadata = currentArchive.metadataJsonSchemaFields;
        keysMetadata.forEach((field: JsonSchemaField) => {
            list.push({
                metadata: field.name,
                title: field.title
            })
        });
        setMetadataList([...list]);
    })

    const stepColumn: IColumn[] = [
        {
            key: 'title',
            name: t('fieldTitle'),
            ariaLabel: 'title',
            fieldName: 'title',
            minWidth: 130,
            maxWidth: 250,
            styles: classNames.subComponentStyles.columnHeader,
            isResizable: false,
            onRender: function getMetadata(item: MetadataSelect) {
                return <span>
                    <TooltipHost
                        overflowMode={TooltipOverflowMode.Parent}
                        content={item.metadata}>{item.title}
                    </TooltipHost>
                </span>
            }
        },
        {
            key: 'metadata',
            name: t('fieldName'),
            ariaLabel: 'metadata',
            fieldName: 'metadata',
            minWidth: 130,
            maxWidth: 250,
            styles: classNames.subComponentStyles.columnHeader,
            isResizable: false,
            onRender: function getMetadata(item: MetadataSelect) {
                return <span>
                    <TooltipHost
                        overflowMode={TooltipOverflowMode.Parent}
                        content={item.metadata}>{item.metadata}
                    </TooltipHost>
                </span>
            }
        }
    ];
    const choiceContent = () => {
        return <div style={{ height: 'calc(100% - 10px)', overflow: 'hidden' }}>
            <DetailsList
                items={metadataList}
                getKey={(key: MetadataSelect) => key.metadata}
                columns={stepColumn}
                styles={classNames.subComponentStyles.detailsList}
                isHeaderVisible={true}
                onRenderDetailsHeader={props => props ? <DetailsHeader styles={classNames.subComponentStyles.detailsHeader} {...props} /> : null}
                setKey="none"
                selection={getSelectedItems}
                selectionMode={SelectionMode.multiple}
                onShouldVirtualize={() => false}
                onRenderRow={props => props ? <DetailsRow {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
        /></div>
    }

    const choiceFooter= () => {
        return <Stack>
        <StackItem className={classNames.buttonContainer} >
            <DefaultButton onClick={() => {
                dispatch(setArchiveFilesMetadataChangesReportShow({show: false, reportId: 0}));
                setShowDialog(false)
            }}>{t('common:previousButton')}</DefaultButton>
            <PrimaryButton
                onClick={async () => { await createExportMetadata();
                    }}
                disabled={selectedList.length < 1}>{t('common:nextButton')}</PrimaryButton>
        </StackItem>
        </Stack>
    }


    const choiceDateContent = () => {
        return (
            <div>
            <div>
              <DatePicker
                styles={datePickerStyles}
                placeholder="Select a date..."
                ariaLabel="Select a date"
                label={t('matadataChanges.from')}
                value = {selectedDateFrom}
                allowTextInput
                onSelectDate={setSelectedFromDate as (date: Date | null | undefined) => void}

              />
            </div>
            <div>
              <DatePicker
                styles={datePickerStyles}
                placeholder="Select a date..."
                ariaLabel="Select a date"
                allowTextInput
                label={t('matadataChanges.to')}
                value = {selectedDateTo}
                onSelectDate={setSelectedToDate as (date: Date | null | undefined) => void}
              />
            </div>
          </div>
          );
    }
    
    const choiceDateFooter = () =>{
        return <Stack>
        <StackItem className={classNames.buttonContainer} >
            <DefaultButton onClick={() => {
                setSelectedFromDate( undefined);
                setSelectedToDate (undefined);
                dispatch(setArchiveFilesMetadataChangesReportShow({show: false, reportId: 0}));
                setShowDialog(false)
            }}>{t('common:previousButton')}</DefaultButton>
            <PrimaryButton
                onClick={async () => {setCurrentStep(ExportMetadataChangesStep.metadataWork); }}
                disabled={selectedDateFrom === undefined || selectedDateTo  === undefined || selectedDateFrom>selectedDateTo}>{t('common:nextButton')}</PrimaryButton>
        </StackItem>
        </Stack>
    }
    
    const createExportMetadata = async () => {
        setLoading(true);
        const list = [...selectedList];
        const request: CreateReportMetadataRequest = {
            archiveReportId: docLabState.archiveContent.selectedReportIdExportShown,
            type: docLabState.archiveContent.selectedReportType ?? ReportType.Metadata,
            additionalInput:{
                OptionalColumns: list.map(l=>l.metadata),
                DateFrom: selectedDateFrom,
                DateTo: selectedDateTo
            } 
        }
        try{
            await archivesApi.createReportMetadata(request);
            setLoading(false);
            setCurrentStep(ExportMetadataChangesStep.exporting); 
        }
        catch{
            setLabelError(true)
            setLoading(false);
            setCurrentStep(ExportMetadataChangesStep.dateRange);
        }       
    }
    const exportingContent = () => {
        return  <div className={classNames.secondFooterContainer}>
                {loading ?
                    <div className={classNames.centerContentContainer}><Spinner size={SpinnerSize.large} /></div> :
                        <>
                            <TeamsImage imageName={ImageName.Sandglass} className={classNames.sandglassImage} fullContainer scale={0.8} />
                            <div className={classNames.exportingFooter}>
                                {t('operationInProgress')}
                                <div>
                                    <PrimaryButton text={t('common:leave')}
                                        allowDisabledFocus
                                        onClick={() => dispatch(setArchiveFilesMetadataChangesReportShow({reportId: 0, show: false}))}
                                        styles={{ root: { padding: '0px 80px', marginTop: '20px' } }}
                                    />
                                </div>
                            </div>
                        </>
                }
            </div>
    }

    const steps: IModalStepData[] = [
        {
            title: t('createScadenzaReport.title'),
            body: choiceDateContent(),
            footer: choiceDateFooter()
        },
        {
            title: t('createScadenzaReport.title'),
            body: choiceContent(),
            footer: choiceFooter()
        },
        {
            bodyOnly: true,
            body: exportingContent()
        }
    ]

    return (
        <>
            <MultiStepModal
                isOpen
                showCloseIcon
                width={ currentStep === ExportMetadataChangesStep.dateRange? 500: 600}
                height={currentStep === ExportMetadataChangesStep.dateRange ? 350 :
                    currentStep === ExportMetadataChangesStep.metadataWork ? 600 : 450}
                styles={{
                    footer: {
                        marginTop: "0px !important"
                    },
                    content:{
                        minHeight: 190,
                        minWidth: 400
                    }
                }}
                hideScrollbarBody
                onCloseClick={currentStep === ExportMetadataChangesStep.homepage ? () => dispatch(setArchiveFilesMetadataChangesReportShow({show: false, reportId: 0})) : () => setShowDialog(true)}
                activeStep={currentStep}
                steps={steps}
                animateInitialStep
                subtitle={ currentStep === ExportMetadataChangesStep.dateRange? t('matadataChanges.subtitleDate') : ""}
            />
            <Dialog
                hidden={!showDialog}
                dialogContentProps={{ title: `${t('stopCreating')}` }}
            >
                <DialogFooter>
                    <DefaultButton onClick={() => setShowDialog(false)} text={t('common:dialogButtonCancel')} />
                    <PrimaryButton onClick={() => dispatch(setArchiveFilesMetadataChangesReportShow({show: false, reportId: 0}))} text={t('common:dialogButtonConfirm')} />
                </DialogFooter>
            </Dialog>
        </>
    );
}