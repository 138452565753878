import React from "react";
import { SelectionEntryItemSearch, addSelectedItem, removeSelectedItem, useSearchContent } from "../../../features/searchContent";
import { IconButton,  } from "@fluentui/react";
import { useDocLabDispatch } from "../../../docLabStore";
import { IIconButtonSelectedAllProps} from "./iconButtonSelectedAll.types";

export const IconButtonSelectedAllBase = (props: IIconButtonSelectedAllProps) => {
    const { searchResult, selectionEntryList } = useSearchContent();
    const dispatch = useDocLabDispatch();   
    
    const updateElementSelectionListAll = () => {
        searchResult?.values.forEach((file) => {
            const element =
            {
                id: Number.parseInt(file.document.fileRef.toString()),
                name: file.document.title,
                isFolder: false,
                archiveRoleId: file.roleId,
                indexed: true,
                approvalStatus: file.document.approvalStatus
            } as SelectionEntryItemSearch;

            if (!props.isItemSelectedList)
                !selectionEntryList.some(x => x.id === element.id) && dispatch(addSelectedItem(element));
            else
                dispatch(removeSelectedItem(element));
        });
        props.changedSelectedAll(!props.isItemSelectedList);
    }

    return (
        <div style={{ paddingLeft: 17 }}>
            <IconButton
                iconProps={{
                    iconName: props.isItemSelectedList ? 'SkypeCircleCheck' : 'CircleRing'
                }}
                title="Seleziona"
                ariaLabel="Seleziona"
                onClick={() => updateElementSelectionListAll()}
            />
        </div>
    );
}