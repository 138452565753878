import { previewResponse } from "../../docLab/services/archives/archives.contracts";
import { apiClient } from "../../modules/apiClient/apiClient";
import { svcPaths } from "../../modules/apiClient/config";
import { dashboardFilter } from "../components/feedback/feedback.types";
import { FeedbackStatus, Feedback, InfoFeedbackNer } from "../models/feedback";
import { Dataset } from "../models/dataset";
import { User } from "../models/user";
import { FeedbackFilters } from "../models/feedbackFilters";
import { AddNewMembershipRequest, DashboardMember, GetModuleLibrary } from "../models/dashboardMember";
import { IUser } from "../../common/interfaces/IUser";

class dashboardApiClass {

    async getUserDetails() {
        const response = await apiClient.get<User>(`${svcPaths.dashboard}/user/me`);
        return response.data;
    }

    async getFeedbackList(filters: dashboardFilter) {
        const queryString = Object.keys(filters)
            .filter(key => filters[key] != null)
            .map(key => `${key}=${filters[key] instanceof Date ? (filters[key] as Date).toISOString() : filters[key]}`)
            .join('&');
        const response = await apiClient.get<Feedback[]>(`${svcPaths.dashboard}/feedback?${queryString}`)
        return response.data;
    }

    async getAllFeedbackExtraPropery() {
        const response = await apiClient.get<string[]>(`${svcPaths.dashboard}/feedback/extraproperty`)
        return response.data;
    }

    async getFeedbackFilters() {
        const response = await apiClient.get<FeedbackFilters>(`${svcPaths.dashboard}/feedback/filters`)
        return response.data;
    }

    async updateFeedback(feedbackIds: number[], feedbackStatus: FeedbackStatus, nerTaggedData?: string, note?: string) {
        const response = await apiClient.post<Feedback[]>(`${svcPaths.dashboard}/feedback/update-status`, {
            feedbackIds,
            feedbackStatus,
            nerTaggedData,
            note
        })
        return response.data;
    }

    async getElementDetails(elementId: number) {
        const response = await apiClient.get<Feedback>(`${svcPaths.dashboard}/feedback/${elementId}`)
        return response.data;
    }

    async getInfoFeedbackNer(feedbackId: number) {
        const response = await apiClient.get<InfoFeedbackNer>(`${svcPaths.dashboard}/feedback/${feedbackId}/info-feedback-ner`);
        return response.data;
    }

    async previewFromFeedback(feedbackId: number) {
        const response = await apiClient.get<previewResponse>(`${svcPaths.dashboard}/feedback/${feedbackId}/file-preview`);
        return response.data
    }

    async getDatasets(filters: dashboardFilter) {
        const response = await apiClient.post<Dataset[]>(`${svcPaths.dashboard}/dataset/getdatasets`, {
            ...filters
        })
        return response.data;
    }

    async createDataset(feedbackIds: number[], skillName: string, name?: string) {
        const response = await apiClient.post(`${svcPaths.dashboard}/dataset/createdataset`, {
            FeedbackIds: feedbackIds,
            Name: name,
            SkillName: skillName
        })
        return response.data;
    }

    async executeTraining(datasetId: number) {
        const response = await apiClient.get<string>(`${svcPaths.dashboard}/dataset/${datasetId}/executeTraining`)
        return response.data;
    }

    async getUsers() {
        const response = await apiClient.get<DashboardMember[]>(`${svcPaths.dashboard}/user/users`)
        return response.data;
    }

    async getLibraryModule() {
        const response = await apiClient.get<GetModuleLibrary[]>(`${svcPaths.dashboard}/user/usermodules`)
        return response.data;
    }

    async getUserByKeyword(keyword: string) {
        const response = await apiClient.get<IUser[]>(`${svcPaths.dashboard}/user?keyword=${keyword}`)
        return response.data;
    }

    async addUserMembership(request: AddNewMembershipRequest){
        const response = await apiClient.post(`${svcPaths.dashboard}/user`, request);
        return response.data;
    }

    async removeMembership(userId: string){
        const response = await apiClient.delete(`${svcPaths.dashboard}/user/${userId}/removeUser`);
        return response.data;
    }

    async editUser(newLibraryModules: number[], userId: string){
        const response = await apiClient.post(`${svcPaths.dashboard}/user/edit-users-modules`, {
            userId: userId,
            moduleInstancesId: newLibraryModules
        });
        return response.data;
    }
}


export const dashboardApi = new dashboardApiClass();