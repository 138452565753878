/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from "react";
import { classNamesFunction, DetailsList, DetailsRow, FontIcon, IColumn, Icon, IconButton, MessageBar, MessageBarType, SelectionMode, Spinner, SpinnerSize, Stack, StackItem, TooltipHost } from "@fluentui/react";
import { IFileUploaderPropsStyles, IFileUploaderStyles, IFileUploaderProps } from "./fileUploader.types";
import Dropzone, { IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import UploadButton from "../uploadButton/uploadButton";
import { IFileToUpload, UploadErrors, UploadStatus } from "../../../docLab/components/files/uploadFileModal/uploadFileModal.types";
import FileIconCell from "../fileIconCell/fileIconCell";
import { Helpers } from "../../../utilities/helpers";
import { useTranslation } from "react-i18next";
import { ErrorDetails } from "../../../modules/apiClient/apiClient";
import { useOnMount } from "../../../utilities/hooks";
import { FileFormatExtension } from "../../../utilities/constants";
import _ from "lodash";
import { appInsightReactPlugin } from "../../../modules/appInsights/appInsights";
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useDocLabDispatch, useDocLabState } from "../../../docLab/docLabStore";
import { setDocumentUploadMetadata } from "../../../docLab/features/archiveContent";

const getClassNames = classNamesFunction<IFileUploaderPropsStyles, IFileUploaderStyles>();

export const FileUploaderBase = (props: IFileUploaderProps) => {

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [pickerErrorMessage, setPickerErrorMessage] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [items, setItems] = useState<IFileToUpload[]>([]);
    const [tempItems, setTempItems] = useState<IFileToUpload[]>([]);
    const [countFilesLoaded, setCountFilesLoaded] = useState<number>(0);
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const { t } = useTranslation(['uploadFileModal', 'common']);
    const labelToPrint = props.labelToPrint ? props.labelToPrint : t('dropzone.button');
    const applicationInsightsUploadFile = useTrackEvent(appInsightReactPlugin, "uploadedFile", { fileInfo: {} });
    const dispatch = useDocLabDispatch();
    const docLabState = useDocLabState();
    const [archivesAllowedFileTypesString, setArchivesAllowedFileTypesString] = useState<string>("");
    const [allowedFileTypes, setAllowedFileTypes] = useState<string[]>([]);

    const mapToUploadFile = (inputFile: File): IFileToUpload => {
        return {
            fileName: inputFile.name,
            fileType: inputFile.type,
            fileSize: inputFile.size,
            fileUploadStatus: UploadStatus.ToUpload,
            file: inputFile,
            isValid: true,
            id: _.uniqueId(),
        };
    }

    const removeFile = (item: IFileToUpload) => {
        const copyItems = items.slice()
        const index = _.findIndex(copyItems, ['id', item.id]);
        if (index !== -1) {
            item.isValid = false;
            copyItems[index] = item;
            setItems(copyItems);
            if (item.fileUploadStatus === UploadStatus.Error) {
                setCountFilesLoaded(countFilesLoaded => countFilesLoaded - 1);
            }
        }
        else {
            const copyTempItems = tempItems.slice()
            const index = _.findIndex(copyTempItems, ['id', item.id]);
            item.isValid = false;
            copyTempItems[index] = item;
            setTempItems(copyTempItems);
        }

        if (props.blockUpload && props.onFileUpdated) {
            setItems(items.filter(x => x.id !== item.id));

            //remove from the redux state the document deleted
            const completeDocumentMetadataArray = docLabState.archiveContent.documentUploadMetadata;
            if (completeDocumentMetadataArray.length > 0) {
                const newDocArray = completeDocumentMetadataArray.filter(i => i.documentId !== item.id);
                dispatch(setDocumentUploadMetadata(newDocArray));
            }
        }

        if (props.onFileUpdated)
            props.onFileUpdated(items.filter(x => x.id !== item.id));
    }

    const addFiles = (inputFiles: File[]) => {
        if (!props.multiple) {
            setItems([]);
            setTimeout(() => {
                const fileToUpload = mapToUploadFile(inputFiles[0]);
                setItems([fileToUpload]);
            }, 10);
        }
        else {
            if (loading) {
                const newTempItems = tempItems.slice()
                _.forEach(inputFiles, function (file, index) {
                    newTempItems.push(
                        mapToUploadFile(file));
                });

                setTempItems(newTempItems);
            }
            else {
                const newItems = items.slice()
                _.forEach(inputFiles, function (file) {
                    newItems.push(
                        mapToUploadFile(file));
                });

                setItems(newItems);
            }
        }
    }

    const scheduleUploadFiles = async () => {
        setLoading(true);
        props.isUploading && props.isUploading(true);
        const itemsToUpload = _.filter(items, ['fileUploadStatus', UploadStatus.ToUpload]);
        for (const file of itemsToUpload) {
            if (file.isValid) {
                setCountFilesLoaded(countFilesLoaded => countFilesLoaded + 1)
                if (!props.blockUpload)
                    await uploadFile(file);
                else if (props.onFileUpdated)
                    props.onFileUpdated(props.multiUpload ? items : [file]);
            }
        }
        setLoading(false);
        props.isUploading && props.isUploading(false);
    };

    useEffect(() => {
        scheduleUploadFiles();
    }, [items.length]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (loading === false && tempItems.length > 0) {
            const newItems = items.slice()
            setItems(newItems.concat(tempItems));
            setTempItems([]);
        }
    }, [loading]); //eslint-disable-line react-hooks/exhaustive-deps

    const uploadFile = async (inputFile: IFileToUpload) => {
        const fileExtension = inputFile.fileName.substring(inputFile.fileName.lastIndexOf('.'), inputFile.fileName.length);
        const fileExtensionType = FileFormatExtension.find(a => a.extension === fileExtension)?.key;

        if ((props.isDisable || items.length > props.maxNumFilesUploadable - 1))
            return;

        if (!props.archiveId)
            return;

        if (fileExtensionType && (fileExtension === ".rar" || fileExtension === ".dwf")) {
            inputFile.fileType = fileExtensionType;
            if (inputFile.file) {
                const newFile = new File([inputFile.file], inputFile.file.name, { type: fileExtensionType });
                inputFile.file = newFile;
            }
        }

        const copyItems = items.slice()
        const index = _.findIndex(copyItems, ['id', inputFile.id]);
        inputFile.fileUploadStatus = UploadStatus.Uploading;
        copyItems[index] = inputFile;
        setItems(copyItems);
        if (!props.fileTypes.find(x => x === inputFile.fileType || x === fileExtensionType)) {
            setPickerErrorMessage("picker.fileTypeNotAllowed");
            if (props.isFileAccepted)
                props.isFileAccepted(false)

            const index = _.findIndex(copyItems, ['id', inputFile.id]);
            inputFile.fileUploadStatus = UploadStatus.Error;
            inputFile.errorMessage = <span>{t('picker.fileTypeNotAllowed')}</span>;
            copyItems[index] = inputFile;
            setItems(copyItems);

            return;
        }

        const formData = new FormData();
        formData.append("File", inputFile.file as File);
        formData.append("FileName", inputFile.fileName)
        formData.append("Id", props.currentFolderId.toString());

        setPickerErrorMessage("");

        try {
            let result = {};
            if (props.sendFiles)
                result = await props.sendFiles(formData);

            applicationInsightsUploadFile(
                {
                    fileInfo: {
                        filename: inputFile.fileName,
                        archiveId: props.archiveId
                    }
                }
            );

            const index = _.findIndex(copyItems, ['id', inputFile.id]);
            inputFile.fileUploadStatus = UploadStatus.Uploaded;
            copyItems[index] = inputFile;
            setItems(copyItems);

            if (props.onFileUpdated)
                props.onFileUpdated(props.multiUpload ? items : [inputFile], { ...result, messageError: [t('invalidExcel')] });

            // Carico a livello di callback il documento che è stato inizialmente scelto
            if (props.onFileUploadMetadata)
                props.onFileUploadMetadata([inputFile.file as File]);
        }
        catch (error: any) { //eslint-disable-line @typescript-eslint/no-explicit-any
            let messages: JSX.Element | JSX.Element[];

            if (error instanceof ErrorDetails && error.errorCodes) {
                const errorList = error.getAllValidationErrorCodes();
                messages = errorList.map((code, ind) => <span key={code}>{t(code)} {errorList.length > ind + 1 && " - "} </span>);
            }
            else if (error.code === 409) {
                messages = <span>{t('uploadConflictError')}</span>
                if (!props.fileTypes.includes(inputFile.fileType) || error.subCode === 410)
                    messages = <span>{t(`${UploadErrors.MimeTypeNotAllowed}`)}</span>
            }
            else if (error.code === 422) {
                messages = <span>{t('uploadUnprocessableError')}</span>
            }
            else if (error.code === 401) {
                messages = <span>{t('uploadUnauthorizedError')}</span>
            }
            else {
                messages = <span>{t('uploadGenericError')}</span>
            }

            if (props.onFileUpdated)
                props.onFileUpdated(props.multiUpload ? items : [], { isValid: false, errors: [t('uploadGenericError')] });

            const index = _.findIndex(copyItems, ['id', inputFile.id]);
            inputFile.fileUploadStatus = UploadStatus.Error;
            inputFile.errorMessage = messages;
            copyItems[index] = inputFile;
            setItems(copyItems);
        }
    }

    const iconRender = (item: IFileToUpload) => {
        switch (item.fileUploadStatus) {
            case UploadStatus.Uploading: return <Spinner size={SpinnerSize.small} />;
            case UploadStatus.Uploaded: return <FontIcon iconName="CompletedSolid" style={{ fontSize: 16, color: palette.green }} />;
            case UploadStatus.Error: return (
                <TooltipHost content={item.errorMessage} id={item.id} styles={{ root: { display: 'flex', alignItems: 'center' } }}>
                    <FontIcon iconName="StatusErrorFull" aria-describedby={item.id} style={{ fontSize: 16, color: palette.red, cursor: 'help' }} />
                </TooltipHost>
            );
            default: return <FontIcon iconName="ReminderTime" style={{ fontSize: 16 }} />;
        }
    }

    const getColumns = (): IColumn[] => {
        let valueToReturn: IColumn[] =
            [
                {
                    key: 'column1',
                    name: 'fileStatus',
                    minWidth: 10,
                    maxWidth: 10,
                    onRender: function columnRender(item: IFileToUpload) {
                        if (!props.blockUpload)
                            return iconRender(item)
                    }
                },
                {
                    key: 'column2',
                    name: 'fileType',
                    minWidth: 20,
                    maxWidth: 20,
                    onRender: function columnRender(item: IFileToUpload) {
                        const extIndex = item.fileName.lastIndexOf('.');
                        const ext = extIndex !== -1 ? item.fileName.substring(extIndex) : "";
                        return <FileIconCell fileExtension={ext} />
                    },
                },
                {
                    key: 'column3',
                    name: 'fileName',
                    isResizable: true,
                    minWidth: 170,
                    maxWidth: 250,
                    onRender: function columnRender(item: IFileToUpload) {
                        return <span>{item.fileName}</span>
                    },
                }
            ];

        if (!props.showFileNameOnly)
            valueToReturn = valueToReturn.concat(
                [
                    {
                        key: 'column4',
                        name: 'fileSize',
                        minWidth: 60,
                        maxWidth: 70,
                        onRender: function columnRender(item: IFileToUpload) {
                            return <span>{Helpers.formatSize(item.fileSize)}</span>
                        },
                    },
                    {
                        key: 'column0',
                        name: 'deleteFile',
                        minWidth: 10,
                        maxWidth: 10,
                        onRender: function columnRender(item: IFileToUpload) {
                            return (
                                (item.fileUploadStatus === UploadStatus.Error || item.fileUploadStatus === UploadStatus.ToUpload) &&
                                <div style={{ textAlign: 'center' }}>
                                    <IconButton
                                        iconProps={{ iconName: "Cancel" }}
                                        style={{ height: 18, color: palette.black }}
                                        onClick={() => removeFile(item)}
                                    />
                                </div>
                            )
                        },
                    }
                ]
            );

        return valueToReturn;
    };

    const dropzoneContent = (suffix: string) => (
        <div key={`content_${suffix}`} className={classNames.dropzoneContent}>
            <Icon iconName="CloudUpload" style={{ fontSize: 80 }} />
            <span>{t('dropzone.msg1')}</span>
            <span>{t('dropzone.msg2')}</span>
            <UploadButton
                key={allowedFileTypes.join(', ')}
                acceptedFile={allowedFileTypes}
                isDisabled={props.isDisable || items.length > props.maxNumFilesUploadable - 1}
                uploadFile={(files: File[]) => addFiles(files)}
                label={labelToPrint}
                multiple={props.multiple}
                checkExtension={true}
            />
        </div>
    );

    useOnMount(() => {
        // Per caricare i documenti al rendering del componente
        const selectedItem = props.fileOnInit;
        if (selectedItem) {
            addFiles([selectedItem]);
            if (props.blockUpload) //if FileUploader it's called from scheduledSteps in uploadFileModal
                setItems(selectedItem);
        }
        setAllowedFilesString();
    });

    const handleChangeStatus = (meta: IFileWithMeta, status: StatusValue, files: IFileWithMeta[]) => {
        if (status === 'done' && (!props.isDisable || items.length < props.maxNumFilesUploadable - 1)) {
            addFiles([meta.file]);
            _.remove(files, function (file) {
                return file.file.name === meta.file.name //Clean files saved in dropzone state
            });
        }
    }

    const setAllowedFilesString = async () => {
        const allowedFileTypes: string[] = props.extensionFileTypes ? Object.keys(props.extensionFileTypes) : []

        const filteredFileTypesString = allowedFileTypes.map(type => type.replace(/^\./, '')).join(', ');

        setArchivesAllowedFileTypesString(filteredFileTypesString);
        setAllowedFileTypes(allowedFileTypes);
    }

    return (
        <>
            <Stack horizontal className={props.isFileDraggable ? classNames.body : ''} style={{ alignItems: "center" }}>
                <StackItem className={classNames.leftContainer} shrink={false} grow={false}>
                    {props.isFileDraggable ? <Dropzone
                        classNames={{ dropzone: classNames.dropzoneBorders }}
                        onChangeStatus={handleChangeStatus}
                        inputContent={dropzoneContent("noData")}
                        inputWithFilesContent={dropzoneContent("withContent")}
                        styles={{
                            input: { display: 'none' },
                            preview: { display: 'none' }
                        }}
                        multiple={props.multiple}
                    /> : <UploadButton
                        key={allowedFileTypes.join(', ')}
                        acceptedFile={allowedFileTypes}
                        isDisabled={props.isDisable || items.length > props.maxNumFilesUploadable - 1}
                        uploadFile={(files: File[]) => addFiles(files)}
                        label={labelToPrint}
                        multiple={props.multiple}
                        checkExtension={true}
                    />}
                </StackItem>

                <StackItem align="start" className={classNames.rightContainer} grow>
                    {props.showMessages ?
                        <div className={classNames.disclaimers}>
                            <MessageBar messageBarType={MessageBarType.warning} messageBarIconProps={{ iconName: 'Warning' }}>
                                {t('picker.disclaimer')}
                            </MessageBar>
                            {!props.multiple ?
                                <MessageBar messageBarType={pickerErrorMessage ? MessageBarType.error : MessageBarType.info}>
                                    {t(pickerErrorMessage || "picker.info") + `${archivesAllowedFileTypesString}.`}
                                </MessageBar>
                                :
                                <MessageBar messageBarType={MessageBarType.info}>
                                    {t("picker.info") + `${archivesAllowedFileTypesString}.`}
                                </MessageBar>
                            }
                        </div> : null
                    }
                    {props.showMessageError ?
                        <MessageBar messageBarType={MessageBarType.error} styles={{ root: { display: 'flex', alignItems: 'center' } }} >
                            {t(pickerErrorMessage)}
                        </MessageBar>
                        :
                        <div className={classNames.fileList}>
                            {props.multiple && !props.blockUpload &&
                                <span style={!loading ? { padding: 12 } : {}} className={classNames.textParagraph}>
                                    {!loading && countFilesLoaded === 0 ? '' : !loading && countFilesLoaded > 0 ? `${countFilesLoaded} ${t('uploadedText')}` : `${t('uploadingText')} ${countFilesLoaded} ${t('of')} ${_.filter(items, ['isValid', true]).concat(_.filter(tempItems, ['isValid', true])).length}`}
                                </span>
                            }
                            <DetailsList
                                items={_.filter(items, ['isValid', true]).concat(_.filter(tempItems, ['isValid', true]))}
                                styles={{
                                    contentWrapper: {
                                        width: '100%',
                                        maxHeight: '100%',
                                        overflowY: 'auto',
                                        overflowX: 'hidden'
                                    },
                                    root: {},
                                    focusZone: {},
                                    headerWrapper: {}
                                }}
                                columns={getColumns()}
                                setKey="uploadFiles"
                                isHeaderVisible={false}
                                getKey={(item: IFileToUpload) => item?.id}
                                selectionMode={SelectionMode.none}
                                onShouldVirtualize={() => false}
                                onRenderRow={props => props ? <DetailsRow {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
                            />
                        </div>
                    }
                </StackItem>
            </Stack>
        </>
    );
}