/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable @typescript-eslint/no-explicit-any*/
import { Checkbox, classNamesFunction, DefaultButton, FontIcon, PrimaryButton, Spinner, SpinnerSize, Stack, StackItem, TextField, TooltipHost } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FolderContent from "../../../../common/components/folderContent/folderContent";
import MultiStepModal from "../../../../common/components/multiStepModal/multiStepModal";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { Helpers } from "../../../../utilities/helpers";
import { useOnMount } from "../../../../utilities/hooks";
import { useDocLabDispatch } from "../../../docLabStore";
import { setArchiveInfo } from "../../../features/archiveContent";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { Archive } from "../../../models/archive";
import { ArchiveItem } from "../../../models/archiveItem";
import { ArchiveRoleId, ArchiveTypeId, ContentItemIndexingStatus } from "../../../models/constants";
import { AnalysisConfig, AnalysisConfigTyped, AnalysisTypeId, GlossaryCheckValidationResult, QualityCheckConfig } from "../../../models/docAnalysis";
import { docAnalyzesApi } from "../../../services/docAnalyzes/docAnalyzes.api";
import ArchiveNavigation from "../../files/navigationItemModal/archiveNavigation/archiveNavigation";
import { IDocAnalysisStartModalProps, IDocAnalysisStartModalPropsStyles, IDocAnalysisStartModalStyles } from "./docAnalysisStartModal.types";
import GlossaryCheckContent from "./glossaryCheck/glossaryCheckContent/glossaryCheckContent";
import { archivesApi } from "../../../services/archives/archives.api";

const getClassNames = classNamesFunction<IDocAnalysisStartModalPropsStyles, IDocAnalysisStartModalStyles>();

export const DocAnalysisStartModalBase = (props: IDocAnalysisStartModalProps) => {
    const [currentStep, setCurrentStep] = useState(1);
    const { t } = useTranslation(['docAnalysisStartModal', 'common']);
    const currentArchive = useCurrentArchive();
    const [displayedArchive, setDisplayedArchive] = useState<Archive | undefined>(currentArchive);
    const [selectedStandard, setSelectedStandard] = useState(0);
    const [loadingStandard, setLoadingStandard] = useState(false);
    const [canGoTo2Step, setCanGoTo2Step] = useState(false);
    const [creatingAnalysis, setCreatingAnalysis] = useState(false);
    const [name, setName] = useState('');
    const [text2Search, setText2Search] = useState('');
    const [error, setError] = useState('');
    const [tryAgainCount, setTryAgainCount] = useState(0);
    const [folderContentError, setFolderContentError] = useState(false);
    const [validationResult, setValidationResult] = useState<GlossaryCheckValidationResult>();
    const [configs, setConfigs] = useState<AnalysisConfig[]>([]);
    const [selectedItems, setSelectedItems] = useState<ArchiveItem[]>([]);
    const [selectedFilePreview, setSelectedFilePreview] = useState<ArchiveItem[]>([]);
    const [showArchives, setShowArchives] = useState(false);
    const dispatch = useDocLabDispatch();
    const [payload, setPayload] = useState<any>();
    const [fileUploaded, setFileUploaded] = useState<any>();

    const getEnabledFileTypes = () => { return props.analysisType === AnalysisTypeId.GlossaryCheck || props.analysisType === AnalysisTypeId.SearchAndCompare ? ['.doc', '.docx', '.pdf', '.xlsx', '.xls', '.xlsm', '.csv', '.pptx', '.ppt', '.msg'] : ['.docx', '.pdf'] }
    const getNumMaxFileSelectable = () => { return props.analysisType === AnalysisTypeId.GlossaryCheck ? 1000 : (props.analysisType === AnalysisTypeId.SearchAndCompare ? 10 : 8) }
    const [isMoreThanMaxFileSelectable, setIsMoreThanMaxFileSelectable] = useState(false);
    const [loading, setLoading] = useState(false);

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, selectedNumber: selectedItems.length, maxNumFiles: getNumMaxFileSelectable() });

    const previousButtonText = t('common:previousButton');
    const nextButtonText = t('common:nextButton');

    useOnMount(() => {
        if (!currentArchive)
            return;
        dispatch(setArchiveInfo({
            types: props.analysisType === AnalysisTypeId.GlossaryCheck
                ? [ArchiveTypeId.Personal, ArchiveTypeId.Unit, ArchiveTypeId.Activity]
                : [],
            roles: props.analysisType === AnalysisTypeId.GlossaryCheck
                ? [ArchiveRoleId.Architect, ArchiveRoleId.Owner, ArchiveRoleId.Contributor]
                : []
        }));
        loadStandards();
    });

    const onLevelUp = () => {
        setShowArchives(true);
    }

    const onLevelDown = (archive: Archive) => {
        setShowArchives(false);
        setDisplayedArchive(archive);
    }

    const loadStandards = async () => {
        if (!currentArchive || props.analysisType === undefined)
            return;
        setLoadingStandard(true);
        try {
            const configs = await docAnalyzesApi.getQualityCheckActiveConfigs(currentArchive.id, props.analysisType);
            setConfigs(configs);

        }
        catch (error) {
            console.log(error)
        }
        finally {
            setLoadingStandard(false);
        }
    }

    const onCheckBoxChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (!checked) {
            setSelectedStandard(0);
            return;
        }
        if (ev?.currentTarget.id && checked) {
            setSelectedStandard(+ev.currentTarget.id);
        }
    }

    const bodyPageQualityCheck = () => {
        return configs.map((config: AnalysisConfig, ind) => {
            const typed = config as AnalysisConfigTyped<QualityCheckConfig>;

            return (
                <div key={ind} className={classNames.itemContent}>
                    <Checkbox
                        id={config.id.toString()}
                        label={config.name}
                        checked={selectedStandard === config.id}
                        onChange={(ev, checked) => onCheckBoxChange(ev, checked)}
                    />
                    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {typed.data.length.map((val) => (
                            <div key={val.labelId} className={classNames.grid}>
                                <div>{t(`docAnalysis:labels.types.${val.labelId}`)}</div>
                                <div style={{ justifySelf: 'right' }}>{val.valueFrom}</div>
                                <div style={{ justifySelf: 'right' }}>{val.valueTo ? val.valueTo : '∞'}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )
        })
    }

    const bodyPageToc = () => {
        return configs.map((config: AnalysisConfig, ind) => {
            return (
                <div key={ind} style={{ marginBottom: '10px' }} className={classNames.itemContent}>
                    <Checkbox
                        id={config.id.toString()}
                        label={config.name}
                        checked={selectedStandard === config.id}
                        onChange={(ev, checked) => onCheckBoxChange(ev, checked)}
                    />
                    <div style={{ margin: '20px 0 10px' }}>
                        {config.description}
                    </div>
                </div>
            )
        })
    }

    const bodyPageGlossary = () => {
        return (
            //Utilizzo il selectedStantard dandogli il valore "1", per controllare se effettivamente è stato caricato un file
            <GlossaryCheckContent contentValidationChanged={(isValid: boolean, result: GlossaryCheckValidationResult) => {
                if (isValid && result.isValid) {
                    setSelectedStandard(1);
                    setPayload(JSON.stringify(result.items));
                }
                setValidationResult(result);
            }}
                fileUploadedCallback={(files: File[]) => {
                    setFileUploaded(files[0]);
                }}
                fileOnInit={fileUploaded}>
            </GlossaryCheckContent>
        );
    }

    const bodyPagePasteText = () => {
        return (
            <>
                <span className={classNames.root}>{`${t('searchAndCompare.text4SearchAndCompare')}`}</span>
                <div style={{ marginTop: '10px' }}>
                    <TextField
                        multiline
                        placeholder={t('searchAndCompare.pastedTextPlaceholder')}
                        onChange={(_, newValue) => {
                            setText2Search(newValue ?? '');
                            setPayload(JSON.stringify({ fileId: isFile4PreviewSelected() ? selectedFilePreview[0].id : null, textSection: newValue }))
                        }}
                        value={text2Search}
                        style={{ minHeight: '400px' }}
                        maxLength={2000}
                        errorMessage={name.length === 0 || name.trim() === '' ? t('common:fieldRequired') : undefined}
                    />
                </div>
            </>
        )
    }

    const file4ComparisonPage = (finalStep: boolean, isSearchOrCompare?: boolean) => {
        return <>
            <div className={classNames.selectedContainer}>
                {((props.analysisType === AnalysisTypeId.QualityCheck || props.analysisType === AnalysisTypeId.GlossaryCheck) && finalStep) && <span className={classNames.root}>{`${t('secondPageHeaderFirstPart')}${getNumMaxFileSelectable()}${t('secondPageHeaderSecondPart')}`}</span>}
                {props.analysisType === AnalysisTypeId.TableOfContent && <span className={classNames.root}>{`${t('secondPageHeaderFirstPart')}${getNumMaxFileSelectable()}${t('secondPageHeaderSecondPartToc')}`}</span>}
                {props.analysisType === AnalysisTypeId.SearchAndCompare && finalStep && <span className={classNames.root}>{`${t('secondPageHeaderFirstPart')}${getNumMaxFileSelectable()}${t('secondPageHeaderSecondPartS_C')}`}</span>}
                {props.analysisType === AnalysisTypeId.SearchAndCompare && !finalStep && <span className={classNames.root}>{`${t('searchAndCompare.fileTemplate')}`}</span>}
                {/* {finalStep && <span style={{ fontWeight: 'bold' }} className={classNames.selected}>{t('selectedNumber')}{selectedItems.length}{t('common:preposition')}{getNumMaxFileSelectable()}</span>} */}
            </div>
            {showArchives ?
                <ArchiveNavigation
                    onArchiveSelected={onLevelDown}
                /> :
                <FolderContent
                    // styles={{ root: { height: 'calc(100% - 16px)' } }}
                    onSelectionChange={items => { finalStep ? setSelectedItems(items) : setSelectedFilePreview(items); onButtonReset() }}
                    disableFolderSelection={props.analysisType === AnalysisTypeId.GlossaryCheck ? false : true}
                    enabledFileTypes={getEnabledFileTypes()}
                    selection={finalStep ? selectedItems : selectedFilePreview}
                    creatingAnalysis={creatingAnalysis}
                    isError={isError => setFolderContentError(isError)}
                    maxNumFiles={finalStep ? getNumMaxFileSelectable() : 1}
                    selectAllEnabled={props.analysisType === AnalysisTypeId.GlossaryCheck}
                    contentIndexingStatusConfiguration={ContentItemIndexingStatus.Indexed}
                    isSearchOrCompareAnalysis={isSearchOrCompare}
                    onLevelUp={props.analysisType === AnalysisTypeId.GlossaryCheck ? () => onLevelUp() : undefined}
                    archive={displayedArchive}
                    isGlossaryCheck={props.analysisType === AnalysisTypeId.GlossaryCheck ? true : false}
                />
            }
        </>
    }

    const bodyPageSearchAndContent = () => {
        return <>
            {getAnalysisNameSection()}
            <div style={{ marginTop: '30px', height: '65%', marginBottom: '20px' }}>
                {file4ComparisonPage(false, true)}
            </div>
        </>
    }

    const isAnalysisNameValid = () => {
        return name && name.trim() !== '' && name.length > 0;
    }

    const getAnalysisNameSection = () => {
        return <TextField
            label={t('analysisName')}
            onChange={(_, newValue) => {
                setName(newValue ?? '');
            }}
            styles={{ errorMessage: { position: 'absolute' } }}
            value={name}
            maxLength={200}
            //errorMessage={name.length === 0 || name.trim() === '' ? t('common:fieldRequired') : undefined}
            errorMessage={ undefined }
            required
        />
    }

    const getContent = () => {
        switch (props.analysisType) {
            case AnalysisTypeId.QualityCheck:
                return bodyPageQualityCheck();
            case AnalysisTypeId.TableOfContent:
                return bodyPageToc();
            case AnalysisTypeId.GlossaryCheck:
                return bodyPageGlossary();
            case AnalysisTypeId.SearchAndCompare:
                return bodyPageSearchAndContent();
            default:
                break;
        }
    }

    const isAnalysisWithConfig = () => {
        return props.analysisType === AnalysisTypeId.QualityCheck || props.analysisType === AnalysisTypeId.TableOfContent;
    }

    const configOrPayloagBodyPage = (
        <>
            {getAnalysisNameSection()}

            {isAnalysisWithConfig() && loadingStandard ? <div className={classNames.loadContainer}><Spinner size={SpinnerSize.large} /></div>
                : configs.length < 1 && isAnalysisWithConfig() ? <div className={classNames.loadContainer}>{t('noConfigs')}</div> :
                    <>
                        <div style={{
                            marginTop: 30,
                            height: 'calc(100% - 92px)',
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            wordBreak: 'break-word'
                        }}>{getContent()}</div>
                    </>
            }
        </>
    )

    const getGlossaryValidateError = () => {
        if (validationResult?.errors) {
            return validationResult.errors.map(x => <div key={x.errorCode}>
                {x.relatedCell ? `${t('common:excelCel')} ${x.relatedCell} ${t('common:isInvalid')}` : t('common:excelFormatError')} {x.errorCode ? t(`common:${x.errorCode}`) : ''}
            </div>)
        }

    }

    const onClickNextFooter = () => {
        setTryAgainCount(0);
        setCurrentStep(currentStep + 1)
        if (props.analysisType === AnalysisTypeId.SearchAndCompare && isFile4PreviewSelected() && currentStep === 1)
            Helpers.getPreviewFile(selectedFilePreview[0].id)
    }

    const isSearchAndCompareFooter = () => {
        return <>
            {props.analysisType === AnalysisTypeId.SearchAndCompare ?
                <>
                    <StackItem style={{
                        alignSelf: 'center'
                    }} align="start">
                        <span>{`${t('searchAndCompare.or')}`}</span>
                        <span style={{ color: !isAnalysisNameValid() ? 'gray' : '', cursor: !isAnalysisNameValid() ? 'not-allowed' : '', marginLeft: '5px' }} onClick={() => {
                            if (isAnalysisNameValid())
                                setCurrentStep(currentStep + 1)
                        }} className={classNames.searchAndCompareContent}>{`${t('searchAndCompare.noSelectAndNext')}`}</span>
                    </StackItem></> : ''
            }
        </>
    }

    const standardFooter = () => {
        return <>
            <Stack styles={{ root: { width: '100%', display: 'flex', gap: '5px' } }} horizontal>
                <StackItem style={{ marginBottom: 10 }}>
                    {(validationResult && !validationResult.isValid) && <span className={classNames.errorMessage}>{validationResult.errors.length > 0 ?
                        <>
                            <TooltipHost content={getGlossaryValidateError()} styles={{ root: { display: 'inline-block', marginRight: 5 } }} >
                                <div style={{ display: 'flex' }}><FontIcon iconName="StatusErrorFull" style={{ fontSize: 14, cursor: 'help' }} /></div>
                            </TooltipHost>
                            {validationResult.messageError ? validationResult.messageError : validationResult.errors}
                        </>
                        : t('common:genericErrorApi')}</span>}
                </StackItem>
                <StackItem styles={{ root: { width: '100%', display: 'flex', gap: '5px' } }}>
                    <span className={classNames.requiredTextLeft}>* {t('common:requiredField')}</span>
                    {isSearchAndCompareFooter()}
                    <div style={{ marginLeft: 'auto' }}>
                        <DefaultButton
                            style={{ marginRight: 8 }}
                            text={t("common:cancel")}
                            onClick={() =>
                                currentStep === 1 ? props.onClose(false) : setCurrentStep(currentStep - 1)
                            }
                        />
                        <PrimaryButton
                            text={nextButtonText}
                            disabled={!canGoTo2Step}
                            onClick={onClickNextFooter}
                        />
                    </div>
                </StackItem>
            </Stack>
        </>
    }

    const footerPagePasterFooter = () => {
        return <>
            <Stack>
                <StackItem style={{ marginBottom: 10 }}>
                    {(validationResult && !validationResult.isValid) && <span className={classNames.errorMessage}>{validationResult.errors.length > 0 ?
                        <>
                            <TooltipHost content={getGlossaryValidateError()} styles={{ root: { display: 'inline-block', marginRight: 5 } }} >
                                <div style={{ display: 'flex' }}><FontIcon iconName="StatusErrorFull" style={{ fontSize: 14, cursor: 'help' }} /></div>
                            </TooltipHost>
                            {validationResult.messageError ? validationResult.messageError : validationResult.errors}
                        </>
                        : t('common:genericErrorApi')}</span>}</StackItem>
                <StackItem>
                    <DefaultButton
                        style={{ marginRight: 8 }}
                        text={previousButtonText}
                        onClick={() =>
                            currentStep === 1 ? props.onClose(false) : setCurrentStep(currentStep - 1)
                        }
                    />
                    <PrimaryButton
                        text={nextButtonText}
                        disabled={!canGoTo2Step}
                        onClick={onClickNextFooter}
                    />
                </StackItem>
            </Stack>
        </>
    }

    const isFile4PreviewSelected = () => {
        return selectedFilePreview && selectedFilePreview.length > 0;
    }

    const getAllFileIds = async (item: ArchiveItem[]) => {
        const fileIds = item.filter(item => !item.isFolder).map(item => item.id) ?? [];
        const folderIds = item.filter(item => item.isFolder).map(item => item.id) ?? [];
        const fileIdsFromFolderIds = await archivesApi.getFileIdsFromFolders(folderIds)
        const totalFileIds = fileIds.concat(fileIdsFromFolderIds);
        const uniqueTotalFileIds = Array.from(new Set(totalFileIds));

        if (props.analysisType === AnalysisTypeId.GlossaryCheck &&
            uniqueTotalFileIds.length > getNumMaxFileSelectable()) {
            setIsMoreThanMaxFileSelectable(true);
            return [];
        }
        else {
            setIsMoreThanMaxFileSelectable(false);
            return uniqueTotalFileIds;
        }
    }

    const onButtonReset = () => {
        setIsMoreThanMaxFileSelectable(false);
    }

    const startAnalysis = async () => {
        setLoading(true);
        let allFileIds: number[] = [];
        if (props.analysisType === AnalysisTypeId.GlossaryCheck) {
            allFileIds = await getAllFileIds(selectedItems);
        }
        else {
            allFileIds = selectedItems.map(i => i.id);
        }

        if (allFileIds.length > 0) {
            setError('');
            setCreatingAnalysis(true);
            if (!currentArchive)
                return;
            try {
                await docAnalyzesApi.startAnalysis({
                    archiveId: currentArchive.id,
                    configurationId: selectedStandard,
                    filesIdList: allFileIds,
                    name: name,
                    payload: payload,
                    typeId: props.analysisType !== undefined ? props.analysisType : -1
                });
                setError('');
                setCurrentStep(currentStep + 1);
            }
            catch (ex: any) { //eslint-disable-line @typescript-eslint/no-explicit-any
                console.log("Error while starting analysis: ", ex.message);
                if (ex.code === 403) {
                    setError(t('notPermissions'));
                }
                else {
                    setError(t('errorAnalysis'));
                }
                setCurrentStep(0);
            }
            finally {
                setCreatingAnalysis(false);
            }
        }
        setLoading(false);
    }

    const file4ComparisonFooter = () => {
        return <div className={classNames.footerContainer}>
            <div>
                {isMoreThanMaxFileSelectable &&
                    <span className={classNames.maxNumError}>
                        {`${t('glossaryCheck.maxNumError1')}${getNumMaxFileSelectable()}${t('glossaryCheck.maxNumError2')}`}
                    </span>
                }
            </div>
            <div className={classNames.footerButtons}>
                {loading && <Spinner size={SpinnerSize.medium} />}
                <DefaultButton
                    style={{ marginRight: 8 }}
                    text={previousButtonText}
                    disabled={creatingAnalysis}
                    onClick={() => { currentStep === 1 ? props.onClose(true) : setCurrentStep(currentStep - 1) }}
                />
                <PrimaryButton
                    disabled={selectedItems.length === 0 || creatingAnalysis || folderContentError || isMoreThanMaxFileSelectable}
                    text={nextButtonText}
                    onClick={async () => { await startAnalysis() }}
                />
            </div>
        </div>
    }

    const finalMessagePageBody = () => {
        return (
            <>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <TeamsImage imageName={ImageName.StillThere} style={{ flexBasis: '50%', flexShrink: 0 }}
                        fullContainer scale={0.8} />
                    <div className={classNames.thirdFooter}>
                        {t('thirdPageAnalisys')}
                        <div>
                            <PrimaryButton
                                text={t('end')}
                                allowDisabledFocus
                                disabled={creatingAnalysis}
                                onClick={() => props.onClose(true)}
                                styles={{ root: { padding: '0px 80px', marginTop: '20px' } }}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const errorPageFooter = (
        <div>
            <DefaultButton onClick={() => { setTryAgainCount(0); setCurrentStep(getStepsByAnalisys().length - 2) }} style={{ marginRight: '8px' }}>{previousButtonText}</DefaultButton>
            {tryAgainCount < 3 && <PrimaryButton onClick={async () => { setTryAgainCount(tryAgainCount + 1); await startAnalysis() }}>{t('common:tryAgain')}</PrimaryButton>}
        </div>
    )

    const errorStep = (
        <>
            {error !== "" &&
                <div>
                    <TeamsImage
                        imageName={ImageName.Error3}
                        scale={0.3}
                        fullContainer
                        caption={error}
                    />
                </div>
            }
        </>
    )

    const configOrPayloagPageTitle = (type?: AnalysisTypeId) => {
        if (type === undefined)
            return;
        if (type === AnalysisTypeId.QualityCheck)
            return t('qualityCheckModal.firstPageTitle')
        if (type === AnalysisTypeId.GlossaryCheck)
            return t('glossaryCheck.firstPageTitle')
        if (type === AnalysisTypeId.SearchAndCompare)
            return t('searchAndCompare.firstPageTitle')
        else
            return t('tocStartModal.firstPageTitle')
    }

    const file4ComparisonSubTitle = (type?: AnalysisTypeId) => {
        if (type === undefined)
            return;
        if (type === AnalysisTypeId.GlossaryCheck)
            return t('glossaryCheck.secondPageSubHeader')
    }

    const configOrPayloagPageSubTitle = (type?: AnalysisTypeId) => {
        if (type === undefined)
            return;
        if (type === AnalysisTypeId.GlossaryCheck)
            return t('glossaryCheck.firstPageSubHeader')
        if (type === AnalysisTypeId.QualityCheck)
            return t('qualityCheckModal.firstPageSubHeader')
    }

    const getErrorStep = () => {
        return {
            body: errorStep,
            footer: errorPageFooter
        }
    }

    const getFilesChoiseStep = () => {
        return {
            title: t('secondPageTitle'),
            body: file4ComparisonPage(true),
            footer: file4ComparisonFooter(),
            subtitle: file4ComparisonSubTitle(props.analysisType)
        }
    }

    const getMexAnalyzingStep = () => {
        return {
            bodyOnly: true,
            body: finalMessagePageBody()
        }
    }

    const getStepsByAnalisys = () => {
        if (props.analysisType === AnalysisTypeId.GlossaryCheck || props.analysisType === AnalysisTypeId.TableOfContent || props.analysisType === AnalysisTypeId.QualityCheck) {
            return [
                getErrorStep(),
                {
                    title: configOrPayloagPageTitle(props.analysisType),
                    body: configOrPayloagBodyPage,
                    footer: standardFooter(),
                    subtitle: configOrPayloagPageSubTitle(props.analysisType)
                },
                getFilesChoiseStep(),
                getMexAnalyzingStep()
            ];
        }
        else if (props.analysisType === AnalysisTypeId.SearchAndCompare) {
            return [
                getErrorStep(),
                {
                    title: configOrPayloagPageTitle(props.analysisType),
                    body: bodyPageSearchAndContent(),
                    footer: standardFooter(),
                    subtitle: configOrPayloagPageSubTitle(props.analysisType)
                },
                {
                    title: configOrPayloagPageTitle(props.analysisType),
                    body: bodyPagePasteText(),
                    footer: footerPagePasterFooter(),
                    subtitle: configOrPayloagPageSubTitle(props.analysisType)
                },
                getFilesChoiseStep(),
                getMexAnalyzingStep()
            ];
        }
        else {
            return []
        }
    }

    useEffect(() => {
        if ((currentStep === 1 && name.trim() !== '' && name.length > 0
            && (selectedStandard !== 0 || (selectedFilePreview?.length))
            && (!validationResult?.errors || validationResult?.errors.length === 0))
            || (currentStep === 2 && text2Search.trim() !== '' && text2Search.length > 0)) {
            setCanGoTo2Step(true);
            return;
        }
        setCanGoTo2Step(false);
    }, [selectedStandard, name, selectedFilePreview, text2Search, currentStep, validationResult?.errors]);

    return (
        <MultiStepModal
            styles={classNames.subComponentStyles.ediModal}
            isOpen={true}
            width={600}
            height={600}
            showCloseIcon={currentStep !== 0}
            onCloseClick={() => props.onClose(false)}
            activeStep={currentStep}
            steps={getStepsByAnalisys()}
            animateInitialStep
            hideScrollbarBody
        />
    );
}