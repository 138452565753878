import React, { useCallback, useEffect, useState } from "react";
import { classNamesFunction, DefaultButton, Icon,  PrimaryButton, TextField } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { Helpers } from "../../../../utilities/helpers";
import { useDocLabDispatch } from "../../../docLabStore";
import { calculateBreadcrumbFromDeeplink, loadFolderContent, setCurrentArchive, useArchiveContent } from "../../../features/archiveContent";
import { Constants } from "../../../models/constants";
import { archivesApi } from "../../../services/archives/archives.api";
import { IRenameArchiveModalProps, IRenameArchiveModalPropsStyles, IRenameArchiveModalStyles, IRenameArchiveData } from "./renameArchiveModal.types";
import { loadArchives } from "../../../features/archiveList";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { ErrorsType } from "../../../models/callsApi";
import { useAsyncApi } from "../../../../utilities/hooks";

const getClassNames = classNamesFunction<IRenameArchiveModalPropsStyles, IRenameArchiveModalStyles>();

export const RenameArchiveModalBase = (props: IRenameArchiveModalProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const { renameArchiveModalShown, currentFolderId } = useArchiveContent();
  const archive = useCurrentArchive();
  const { t } = useTranslation(['renameArchiveModal', 'common']); 
  const dispatch = useDocLabDispatch();
  const [validationIcon, setValidationIcon] = useState('Error');
  const [errorMessage, setErrorMessage] = useState(""); 
  const [archiveName, setArchiveName] = useState(archive?.name);
  const [data, setData] = useState<IRenameArchiveData>({name: archive?.name, isValid: false });

  const validateArchiveName = useCallback(async (value: string) => {
    if (!value)
        return `${t('common:fieldRequired')}`;

    setValidationIcon('ProgressRingDots');

    try {
        const [isValid] = await Promise.all([archivesApi.isArchiveNameAvailable(value), Helpers.delay(500)]);
        return isValid ? "" : t('nameNotAvailable');
    }
    catch {
        return t('common:genericErrorCheckName');
    }
  }, [t]);

  const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
    setData({ ...data, isValid: !error, name: value ?? "" });
    setValidationIcon(error ? 'Error' : 'Completed');
  }, [data]);

  const {execute: renameArchive, loading, error: err} = useAsyncApi<void, void>({
    func: async () => {
      if (!data.isValid || !archive?.id || !data.name)
        return;
      setErrorMessage(""); 
      await archivesApi.renameArchive(archive.id, data.name)
      await dispatch(loadArchives(archive?.id));
    }
  });

  useEffect(() => {
    if(archiveName !== archive?.name){
      setArchiveName(archive?.name);
      dispatch(calculateBreadcrumbFromDeeplink({ fileId: currentFolderId, isFolder: true }));
      dispatch(setCurrentArchive({archive, avoidOverwrite: true}));
      dispatch(loadFolderContent({ resetPage: false, folderId: currentFolderId}));
      props.onClose && props.onClose();
    }
  }, [archive?.name]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect( () => {
    if (!err) {
      setErrorMessage(""); 
      return;
    }
    const error: ErrorsType = (err as unknown) as ErrorsType;
    switch (error.code) {
      case 403: setErrorMessage(t("errorCode403")); break; 
      case 404: setErrorMessage(t("errorCode404")); break; 
      case 409: setErrorMessage(t("errorCode409")); break;  
      default: setErrorMessage(t("common:genericErrorApi")); 
    }
  }, [err]);  //eslint-disable-line react-hooks/exhaustive-deps


  const body = (
    <div className={classNames.innerContent}>
      <TextField
        label={t('label')}
        maxLength={Constants.MAX_LENGTH_ARCHIVE_NAME}
        placeholder={t('placeholder')}
        deferredValidationTime={250}
        onGetErrorMessage={validateArchiveName}
        required
        defaultValue={data.name}
        autoFocus
        autoComplete={"off"}
        styles={classNames.subComponentStyles.textField}
        onNotifyValidationResult={onNameValidationResult}
        onChange={() => setData({ ...data, isValid: false })}
        onRenderSuffix={() => <Icon iconName={validationIcon} />}
      />     
    </div>
  );

  const footer = (
    <>
    <div className={classNames.firstFooterContainer}>
      <DefaultButton
        style={{ margin: '0px 4px' }}
        text={t("cancel")}
        onClick={props.onClose}
        disabled={loading}
      />
      <PrimaryButton
        style={{ margin: '0px 4px' }}
        text={t("save")}
        onClick={() => renameArchive()}
        disabled={!data.isValid || loading}
      />      
    </div>
    {err && <span className={classNames.errorMessage}>{errorMessage}</span>}
    </>
  );

  return (
    <EdiModal
      isOpen={renameArchiveModalShown}
      showCloseIcon={true}
      onCloseClick={props.onClose}
      title={t("title")}
      body={body}
      footer={footer}
      width={400}
    />
  );
}