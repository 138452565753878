import { IStyleFunction } from "@fluentui/react"
import { IProfileViewBasePropsStyles, IProfileViewBaseStyles } from "./profileView.types";


export const ProfileViewGlobalClassNames = {
    root: 'edi-profile-view-root'
}

export const getStyles: IStyleFunction<IProfileViewBasePropsStyles, IProfileViewBaseStyles> = (props: IProfileViewBasePropsStyles): IProfileViewBaseStyles => {
    return {
        root: [
            ProfileViewGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px',
                selectors: {
                    ' .edi-profile-view-action': {
                        right: '15px',
                        bottom: '15px',
                    }
                }
            }
        ]
    }
}