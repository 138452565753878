/*eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Accordion from "../../../../common/components/accordion/accordion";
import { classNamesFunction, DefaultButton, DetailsList, DetailsRow, Icon, IconButton, IDetailsRowProps, IDropdownOption, IIconProps, Persona, PersonaSize, PrimaryButton, ScrollablePane, SelectionMode, Stack, TooltipHost } from "@fluentui/react";
import { DashboardRoleId } from "../../../models/dashboardRoleId";
import { AddNewMembershipRequest, DashboardMember, EditUserModulesLibrary, GetModuleLibrary, NewUserDashboard } from "../../../models/dashboardMember";
import { User } from "../../../models/user";
import { dashboardApi } from "../../../services/dashboard.api";
import { mapper } from "../../../../utilities/mapper";
import { useTranslation } from "react-i18next";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import AdvancedDropdown from "../../../../common/components/advancedDropdown/advancedDropdown";
import _ from "lodash";
import { IDashboardMembersSettingsProps, IDashboardMembersSettingsPropsStyles, IDashboardMembersSettingsStyles } from "./dashboardMembersSettings.types";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { AddMembersPage, MembersPageData } from "../../../../common/components/modalPages/addMembersPage";
import { AddMembersPageType } from "../../../../utilities/constants";
import { ErrorDetails } from "../../../../modules/apiClient/apiClient";
import { useFeedbackList } from "../../../features/feedbackList";

const getClassNames = classNamesFunction<IDashboardMembersSettingsPropsStyles, IDashboardMembersSettingsStyles>();

export const DashboardMembersSettingsBase = (props: IDashboardMembersSettingsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(["dashboardManageUser", "common"]);
    const [users, setUsers] = useState<DashboardMember[]>([]);
    const [moduleLibrary, setModuleLibrary] = useState<GetModuleLibrary[]>([]);
    const [roleNewUser, setRoleNewUser] = useState<DashboardRoleId | undefined>(undefined);
    const feedbackList = useFeedbackList();
    const [requestAddUser, setRequestAddUser] = useState<MembersPageData>({
        isValid: false,
        selectedPeople: [],
    });
    const [errorAddUser, setErrorAddUser] = useState("");
    const [firstRender, setFirstRender] = useState(true);

    const toDisplay = useMemo(() => 
        _.groupBy(users, (item) => item.roleId) as Record<DashboardRoleId, DashboardMember[]>, [users]);

    const permissionOwnerAdmin = useCallback((memberRole: DashboardRoleId) => {
        return ((feedbackList.currentDashboardRoleId === DashboardRoleId.Owner) && (memberRole === DashboardRoleId.Owner)) 
            || ((feedbackList.currentDashboardRoleId === DashboardRoleId.AdminDashboard) && (memberRole === DashboardRoleId.BusinessTech))
            || ((feedbackList.currentDashboardRoleId === DashboardRoleId.Owner) && (memberRole === DashboardRoleId.DataScientist)) 
    }, [feedbackList.currentDashboardRoleId])
        
    useOnMount(() => {
        getUsers();
    })

    const { execute: getUsers, loading, error: err } = useAsyncApi<void, DashboardMember[]>({
        func: async () => {
            const resultLibrary = await dashboardApi.getLibraryModule();
            setModuleLibrary(resultLibrary);
            const result = await dashboardApi.getUsers();
            setUsers(result);
            setFirstRender(false)
            return result as DashboardMember[];
        }
    });

    const { execute: addUser, loading: loadingAddUser, error: ex } = useAsyncApi<AddNewMembershipRequest, void>({
        func: async (request: AddNewMembershipRequest) => {
            const result = await dashboardApi.addUserMembership(request);
            setRoleNewUser(undefined);
            getUsers();
            return result;
        }
    });

    const { execute: deleteUser, loading: loadingDeleteUser } = useAsyncApi<string, void>({
        func: async (userId: string) => {
            await dashboardApi.removeMembership(userId);
            getUsers();
        }
    });

    const { execute: editUsers, loading: loadingEditUser } = useAsyncApi<EditUserModulesLibrary, void>({
        func: async (request: EditUserModulesLibrary) => {
            await dashboardApi.editUser(request.moduleLibrariesId, request.userId);
            getUsers();
        }
    });

    const columns = useMemo(() => {
        const removeIcon: IIconProps = { iconName: 'ChromeClose' };

        return [
            {
                key: 'persona',
                name: t('common:name'),
                minWidth: 200,
                headerClassName: classNames.headerRow,
                onRender: function renderMemberPersona(member: DashboardMember) {
                    return (
                        <Persona {...mapper.mapUserShortToPersone(member)} size={PersonaSize.size40} coinSize={32} />
                    );
                }
            },
            {
                key: 'referenceOwnerBiz',
                name: t('ownerBiz'),
                minWidth: 200,
                headerClassName: classNames.headerRow,
                onRender: function renderMemberPersona(member: DashboardMember) {
                    if(member.ownerBiz && member.roleId === DashboardRoleId.BusinessTech)
                        return <Persona {...mapper.mapUserShortToPersone(member.ownerBiz)} size={PersonaSize.size40} coinSize={32} />
                    
                    return <></>
                }
            },
            {
                key: 'moduleLibrary',
                name: t('moduleLibrary'),
                minWidth: 300,
                headerClassName: classNames.headerRow,
                onRender: function renderMemberRoles(member: DashboardMember) {
                    if(member.roleId === DashboardRoleId.Owner ||
                        member.roleId === DashboardRoleId.BusinessTech)
                    return (
                        <AdvancedDropdown
                            placeholder=""
                            disabled={permissionOwnerAdmin(member.roleId) || loadingEditUser}
                            label=""
                            multiselect
                            options={moduleLibrary.map(m => {
                                return {
                                    key: m.id,
                                    text: m.moduleInstanceFullName,
                                    selected: true
                                } as IDropdownOption;
                            })}           
                            onChangeItems={() => {return}}             
                            selectedItems={member.moduleInstances?.map(a => a.id)}
                            onCloseDropdown={(selected) => {
                                const initialIds = member.moduleInstances?.map(a => a.id) ?? [];
                                const intersection = selected.filter(x => initialIds.includes(x));

                                if(intersection.length === initialIds.length && initialIds.length === selected.length)
                                    return;

                                editUsers({moduleLibrariesId:selected, userId: member.id})
                            }}
                        />
                    );
                    
                }
            },
            {
                key: 'remove',
                name: '',
                minWidth: 24,
                headerClassName: classNames.headerRow,
                onRender: function renderMemberRemoveButton(member: DashboardMember) {
                    return (
                        <IconButton 
                            iconProps={removeIcon} 
                            title="Remove" 
                            ariaLabel="Remove"
                            disabled={loadingDeleteUser || loading || users.findIndex(u => u.ownerBiz?.id === member.id) !== -1 }
                            onClick={() => deleteUser(member.id)}
                        />                          
                    );
                }
            }
        ];
    }, [classNames, t, moduleLibrary, permissionOwnerAdmin, deleteUser, loadingDeleteUser, editUsers, loadingEditUser, loading, users]);

    const membersForRole = useCallback((role: DashboardRoleId) => {
        const singleRoleMembers = toDisplay && toDisplay[role];
        
        if(feedbackList.currentDashboardRoleId === DashboardRoleId.Owner && role !== DashboardRoleId.BusinessTech)
            return;

        return (
            <>
                <Stack horizontal className={classNames.upperSection} horizontalAlign={'end'} style={{marginTop: '15px'}}>
                    <PrimaryButton
                        iconProps={{ iconName: 'AddFriend' }}
                        styles={{ 
                            icon: { height: 'auto' }, 
                            root: { 
                                display: permissionOwnerAdmin(role) ? 'none' : 'initial'
                            } 
                        }}
                        text={`${t("addMembers")} ${DashboardRoleId[role]}`}
                        onClick={() => setRoleNewUser(role)}
                    />
                </Stack>
                <Accordion key={role} title={`${DashboardRoleId[role]}`} opened={true} stickyHeader>
                    {singleRoleMembers ? <DetailsList
                        styles={classNames.subComponentStyles.list}
                        items={singleRoleMembers ?? []}
                        getKey={(item: User) => item.id}
                        onRenderRow={(props?: IDetailsRowProps) =>
                            props ? <DetailsRow {...props} styles={classNames.subComponentStyles.row} /> : null}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                    /> : <span style={{marginLeft: '15px'}}>{t('noUser')}</span>}
                </Accordion>
            </>
        );
    }, [classNames.subComponentStyles, classNames.upperSection, columns, t, toDisplay, permissionOwnerAdmin, feedbackList.currentDashboardRoleId])

    useEffect(() => {
        if (!ex) {
          setErrorAddUser("");
          return;
        }
        const error: ErrorDetails = (ex as unknown) as ErrorDetails;
        if(error.code === 409)
            setErrorAddUser(error.detail ?? '');
        else
            setErrorAddUser('Something went wrong');
    }, [ex]); 

    const footer = (
        <div>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ fontSize: '14px', color: 'rgb(164, 38, 44)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <TooltipHost content={errorAddUser}>
                        {errorAddUser}
                    </TooltipHost>
                </div>
                <div style={{ minWidth: '230px' }}>
                    <DefaultButton 
                        onClick={() => setRoleNewUser(undefined)} 
                        style={{ margin: '0px 4px' }}
                        disabled={loadingAddUser}
                    > {t('common:cancel')}
                    </DefaultButton>
                    <PrimaryButton
                        onClick={() => addUser({
                            newUsers: requestAddUser.selectedPeople.map(s => {
                                return {
                                    moduleIstances: s.selectedModuleLibrary,
                                    userId: s.id
                                } as NewUserDashboard
                            }),
                            role: roleNewUser ?? DashboardRoleId.Owner
                        })}
                        style={{ margin: '0px 4px' }} 
                        disabled={loadingAddUser || requestAddUser.selectedPeople.length === 0}
                        >{t('common:create')}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );

    const modalAddUser = () => 
        <EdiModal
            title={`${t("modalAddUser")} `}
            width={800}
            height={600}
            isOpen={roleNewUser !== undefined}
            showCloseIcon={true}
            onCloseClick={() => setRoleNewUser(undefined)}
            body={
                <AddMembersPage
                    initialState={{ selectedPeople: [], isValid: true }}
                    translationNamespace="dashboardManageUser"
                    onDataChange={setRequestAddUser}
                    getUsers={(keyword: string) => getUsersByKeyword(keyword)}
                    modalType={AddMembersPageType.Dashboard}
                    enableAllowFolderToggle={false}
                    enableRoleSelection={false}
                    enableSelectionLibraryModule={roleNewUser === DashboardRoleId.Owner || 
                        roleNewUser === DashboardRoleId.BusinessTech}
                    moduleLibrary={moduleLibrary} 
                />
            }
            footer={footer}
        >
        </EdiModal>
    

    const getUsersByKeyword = async (keyword: string) => {
        if (keyword.length > 2) {
          return await dashboardApi.getUserByKeyword(keyword);
        }
        return [];
    };
    
    return (
        <>
            {modalAddUser()}
            <Stack className={classNames.root}>
                <Stack className={classNames.title} horizontal verticalAlign={'center'}>
                    <Icon iconName={"PlayerSettings"} className={classNames.titleIcon} />
                    <span style={{ paddingLeft: 10 }}>{t('common:manageUsers')}</span>
                </Stack>
                { loading && firstRender ?
                    <div className={classNames.loaderContainer}>
                        <TeamsSpinner />
                    </div> :
                    err ? <span>Something went wrong...</span> :
                    <Stack.Item verticalFill className={classNames.lowerSection}>
                        <ScrollablePane styles={classNames.subComponentStyles.scrollablePane}>
                            {membersForRole(DashboardRoleId.Owner)}
                            {membersForRole(DashboardRoleId.BusinessTech)}
                            {membersForRole(DashboardRoleId.DataScientist)}
                        </ScrollablePane>
                    </Stack.Item>                        
                }
            </Stack>
        </>
    );
}