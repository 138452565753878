import { IStyleFunction } from "@fluentui/react"
import { IDownloadNotificationPropsStyles, IDownloadNotificationStyles } from "./downloadNotification.types"

export const DownloadNotificationGlobalClassNames = {
    root: 'edi-download-notification-root',
    progressBar: 'edi-download-notification-progress-bar',
    downloadEntry: 'edi-download-notification-download-entry',
    downloadText: 'edi-download-notification-download-text'
}

export const getStyles: IStyleFunction<IDownloadNotificationPropsStyles, IDownloadNotificationStyles> = (props: IDownloadNotificationPropsStyles): IDownloadNotificationStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            DownloadNotificationGlobalClassNames.root,
            {
                position: 'fixed',
                backgroundColor: palette.themePrimary,
                color: palette.white,
                border: '1px solid rgb(189, 189, 189)',
                borderRadius: 3,
                boxShadow: '0 0 0.2rem 0 rgb(37 36 35 / 30%)',
                width: 302,
                bottom: 12,
                right: 12,
            },
            props.activeDownloadCount === 0 && {
                display: 'none'
            }
        ],
        progressBar: [
            DownloadNotificationGlobalClassNames.progressBar,
            {
                position: 'absolute',
                bottom: -8,
                left: 0,
                width: '100%'
            }
        ],
        downloadEntry: [
            DownloadNotificationGlobalClassNames.downloadEntry,
            {
                position: 'relative',
                borderBottom: '1px solid rgb(200, 198, 196)',
                // ':nth-child(1n+5)': {
                //     display: 'none'
                // }
            }
        ],
        downloadText: [
            DownloadNotificationGlobalClassNames.downloadText,
            {
                padding: 16,
                display: 'flex',
                justifyContent: 'space-between',
                '> :not(:last-child)': {
                    paddingRight: 5
                }
            }
        ],
        subComponentStyles: {
            progressBar: {
                progressBar: {
                    backgroundColor: palette.themeSecondary
                }
            }
        }
    };
}