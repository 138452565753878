import { AxiosRequestConfig } from 'axios';
import { apiClient } from '../../../modules/apiClient/apiClient';
import { svcPaths } from '../../../modules/apiClient/config';
import { AnalysisConfig, AnalysisConfigTyped, AnalysisType, AnalysisTypeId, analyzes, QualityCheckConfig, resultAnalyzes, TableOfContentSetting, } from '../../models/docAnalysis';
import { CreateTemplate, GetAnalyzesRequest, GetConfigurationsRequest } from './docAnalyzes.contracts';
import { StartAnalysisRequest, CreateStandard, GetConfigurationsByAnalysis } from './docAnalyzes.contracts';

const analysisPath = {
  qualityCheck: 'qualitycheck',
  toc: 'tableofcontent'
}

class DocAnalyzesApiClass {

  async getConfigurationsByAnalysis(getConfig: GetConfigurationsByAnalysis) {
    const response = await apiClient.get<AnalysisConfig>(`${svcPaths.docAnalyzes}/configurations-by-analysis`, {
      params: { getConfig }
    });
    return response.data;
  }

  async getAvailableAnalysisTypes(archiveId: number) {
    const response = await apiClient.get<AnalysisType[]>(`${svcPaths.docAnalyzes}/analyzes/archives/${archiveId}/available`);
    return response.data;
  }

  async getAnalysisById(id: number) {
    const response = await apiClient.get<resultAnalyzes>(`${svcPaths.docAnalyzes}/analyzes/get-analysis/${id}`);
    return response.data;
  }

  async getAnalyzes(request: GetAnalyzesRequest) {
    const archiveId = request.archiveId;
    const pageNumber = request.pageNumber;
    const pageSize = request.pageSize;
    const orderBy = request.orderBy;
    const isAscending = request.isAscending;
    const response = await apiClient.get<analyzes[]>(`${svcPaths.docAnalyzes}/analyzes/archives/${archiveId}?pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&isAscending=${isAscending}`);
    return response.data;
  }

  async startAnalysis(request: StartAnalysisRequest) {
    const payload = { ...request };
    const response = await apiClient.post(`${svcPaths.docAnalyzes}/analyzes`, payload);
    return response.data;
  }

  async deleteAnalysis(analysisId: number) {
    const response = await apiClient.delete(`${svcPaths.docAnalyzes}/analyzes/${analysisId}`);
    return response.data;
  }

  async uploadFiles(formData: FormData) {
    const response = await apiClient.post(`${svcPaths.docAnalyzes}/glossarycheck/validate`, formData);
    return response.data
  }

  async downloadFile(fileId: number, onDownloadProgress?: (progress: number) => void) {
    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer',
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onDownloadProgress && onDownloadProgress(percentCompleted);
      }
    }

    const response = await apiClient.get(`${svcPaths.docAnalyzes}/analyzes/${fileId}/download`, config);
    return response.data;
  }

  //QUALITY CHECK ---- START
  async getQualityCheckConfigs(input: GetConfigurationsRequest) {
    const response = await apiClient.get<AnalysisConfigTyped<QualityCheckConfig>[]>(`${svcPaths.docAnalyzes}/configurations/${analysisPath.qualityCheck}/archives/${input.archiveId}`);
    return response.data;
  }

  async getQualityCheckActiveConfigs(archiveId: number, type: AnalysisTypeId) {
    const path = type === AnalysisTypeId.QualityCheck ? analysisPath.qualityCheck : analysisPath.toc; //con i prossimi tipi di analisi diventerà uno switch
    const response = await apiClient.get<AnalysisConfig[]>(`${svcPaths.docAnalyzes}/configurations/${path}/archives/${archiveId}/active`);
    return response.data
  }

  async isConfigurationNameAvailable(archiveId: number, name: string) {
    const payload = { name: name, archiveId: archiveId };
    const response = await apiClient.post<boolean>(`${svcPaths.docAnalyzes}/configurations/${analysisPath.qualityCheck}/check-name`, payload);
    return response.data;
  }

  async isConfigurationTocNameAvailable(archiveId: number, name: string) {
    const payload = { name: name, archiveId: archiveId };
    const response = await apiClient.post<boolean>(`${svcPaths.docAnalyzes}/configurations/${analysisPath.toc}/check-name`, payload);
    return response.data;
  }

  async createStandard(createStandard: CreateStandard) {
    const payload = { ...createStandard };
    const response = await apiClient.post(`${svcPaths.docAnalyzes}/configurations/${analysisPath.qualityCheck}`, payload);
    return response.data;
  }

  async deleteStandard(standardId: number) {
    const response = await apiClient.delete(`${svcPaths.docAnalyzes}/configurations/${analysisPath.qualityCheck}/${standardId}`);
    return response.data;
  }

  async updateStandardStatus(standardId: number) {
    const response = await apiClient.put(`${svcPaths.docAnalyzes}/configurations/${analysisPath.qualityCheck}/${standardId}`);
    return response.data;
  }
  //QUALITY CHECK ---- END


  //TOC ---- START
  async getTocConfigs(input: GetConfigurationsRequest) {
    const response = await apiClient.get<AnalysisConfigTyped<TableOfContentSetting>[]>(`${svcPaths.docAnalyzes}/configurations/${analysisPath.toc}/archives/${input.archiveId}`);
    return response.data;
  }

  async getTocTemplate(idTemplate: number) {
    const response = await apiClient.get<AnalysisConfigTyped<TableOfContentSetting>>(`${svcPaths.docAnalyzes}/configurations/${analysisPath.toc}/${idTemplate}`);
    return response.data;
  }

  async createTemplate(createTemplate: CreateTemplate) {
    const payload = { ...createTemplate };
    const response = await apiClient.post(`${svcPaths.docAnalyzes}/configurations/${analysisPath.toc}`, payload);
    return response.data;
  }

  async deleteTemplate(templateId: number) {
    const response = await apiClient.delete(`${svcPaths.docAnalyzes}/configurations/${analysisPath.toc}/${templateId}`);
    return response.data;
  }

  async updateTemplateStatus(templateId: number) {
    const response = await apiClient.put(`${svcPaths.docAnalyzes}/configurations/${analysisPath.toc}/${templateId}`);
    return response.data;
  }
  //TOC ---- END

}


export const docAnalyzesApi = new DocAnalyzesApiClass();