import { styled } from "@fluentui/react";
import { getStyles } from "./deleteAnalysisModal.styles";
import { IDeleteAnalysisModalPropsStyles, IDeleteAnalysisModalStyles, IDeleteAnalysisModalProps } from "./deleteAnalysisModal.types";
import { DeleteAnalysisModalBase } from "./deleteAnalysisModal.base";

const DeleteAnlysisModal = styled<IDeleteAnalysisModalProps, IDeleteAnalysisModalPropsStyles, IDeleteAnalysisModalStyles>(
    DeleteAnalysisModalBase,
    getStyles,
    undefined,
    { scope: 'DeleteAnlysisModal' }
);

export default DeleteAnlysisModal;