import { IStyleFunction } from "@fluentui/react";
import { IFolderProfilePermissionModalPropsStyles, IFolderProfilePermissionModalStyles } from "./folderProfilePermissionModal.types";

export const FolderProfilePermissionModalGlobalClassNames = {
    root: 'edi-folder-profile-permission-modal-root',
    tagPanel: 'edi-folder-profile-permission-modal-tagPanel',
    containerTag: 'edi-folder-profile-permission-modal-containerTag',
    containerProfileCheckboxTag: 'edi-folder-profile-permission-modal-containerProfileCheckboxTag',
    iconTagLabel: 'edi-folder-profile-permission-modal-iconTagLabel',
    checkboxTag: 'edi-folder-profile-permission-modal-checkboxTag',
    checkboxTagElement: 'edi-folder-profile-permission-modal-checkboxTagElement',
    iconTagLabelProfile: 'edi-folder-profile-permission-modal-iconTagLabelProfile'
}

export const getStyles: IStyleFunction<IFolderProfilePermissionModalPropsStyles, IFolderProfilePermissionModalStyles> = (props: IFolderProfilePermissionModalPropsStyles): IFolderProfilePermissionModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            FolderProfilePermissionModalGlobalClassNames.root,
            {
                height: '100%',
                ' .edi-modal-body':{
                    marginTop: '10px !important'
                }
            }
        ], 
        tagPanel: [
            FolderProfilePermissionModalGlobalClassNames.tagPanel,
            {
                height: '100%'
            }
        ], 
        containerTag: {
            display: 'flex', 
            alignContent: 'flex-start',
            flexFlow: 'row wrap',
            gap: '15px',
            marginTop: '20px',
            height: '265px', 
            overflowX: 'hidden',
            overflowY: 'auto',
            width: 'calc(100% - 20px)',
            padding: '10px',
            backgroundColor: palette.neutralLight
        },
        iconTagLabel: {
            width: '128px',
            overflow: 'hidden',
            height: '25px',
            padding: '3px 0', 
            cursor: 'inherit'
        },
        iconTagLabelProfile: {
            width: '150px',
            overflow: 'hidden',
            height: '25px',
            padding: '3px 0', 
            cursor: 'inherit'
        },
        containerProfileCheckboxTag: {
            display: 'flex', 
            alignContent: 'flex-start',
            flexFlow: 'row wrap',
            rowGap: '25px',
            columnGap: '15px',
            marginTop: '20px',
            height: '370px', 
            overflowX: 'hidden',
            overflowY: 'auto',
            width: 'calc(100% - 20px)',
            padding: '10px',
            backgroundColor: palette.neutralLight,
        }, 
        checkboxTag: {
            alignItems: 'center',
        },
        checkboxTagElement: {
            display: 'flex',
            alignItems: 'center'
        }             
    };
}