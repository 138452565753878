import { generatePath } from "react-router-dom";
import { AppRoutes, AppTab } from "../../app/utilities/routes";

export enum dashboardTab {
    feedback = 'feedback',
    datasets = 'datasets',
    users = 'users'
}

class dashboardRoutesClass {
    public dashboardBase = AppRoutes.generateRouteForTab(AppTab.dashboard);
    public dashboardOptionals = `${this.dashboardBase}/:tab(${dashboardTab.feedback}|${dashboardTab.datasets}|${dashboardTab.users})?`;

    generatedashboardPath() {
        return generatePath(`${this.dashboardBase}`);
    }

    generatedashboardTabPath(tab: dashboardTab) {
        return `${this.generatedashboardPath()}/${tab}`;
    }
}

export const dashboardRoutes = new dashboardRoutesClass();