import { styled } from "@fluentui/react";
import { DocAnalysisSettingsBase } from "./docAnalysisSettings.base";
import { getStyles } from "./docAnalysisSettings.styles";
import { IDocAnalysisSettingsProps, IDocAnalysisSettingsPropsStyles, IDocAnalysisSettingsStyles } from "./docAnalysisSettings.types";

const DocAnalysisSettings = styled<IDocAnalysisSettingsProps, IDocAnalysisSettingsPropsStyles, IDocAnalysisSettingsStyles>(
    DocAnalysisSettingsBase,
    getStyles,
    undefined,
    { scope: 'DocAnalysisSettings' }
);

export default DocAnalysisSettings;