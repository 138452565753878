import { styled } from "@fluentui/react";
import { getStyles } from "./documentTagPanel.styles";
import { IDocumentTagPanelPropsStyles, IDocumentTagPanelStyles, IDocumentTagPanelProps } from "./documentTagPanel.types";
import { DocumentTagPanelBase } from "./documentTagPanel.base";

const DocumentTagPanel = styled<IDocumentTagPanelProps, IDocumentTagPanelPropsStyles, IDocumentTagPanelStyles>(
    DocumentTagPanelBase,
    getStyles,
    undefined,
    { scope: 'DocumentTagPanel' }
);

export default DocumentTagPanel;