import { IButtonStyles, IScrollablePaneStyleProps, IScrollablePaneStyles, IStyle, IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, ITheme, ITooltipHostStyleProps, ITooltipHostStyles } from "@fluentui/react";

export interface ISearchProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ISearchPropsStyles, ISearchStyles>;
    className?: string;
}

export interface ISearchPropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ISearchPropsStyles, ISearchStyles>;
    className?: string;
}

export interface ISearchStyles {
    root: IStyle;
    itemIcon: IStyle;
    itemCell: IStyle;
    itemContent: IStyle;
    itemName: IStyle;
    spinner: IStyle;
    itemHeader: IStyle;
    dropDown: IStyle;
    errorMessage: IStyle;
    customList: IStyle;
    filtersContent: IStyle;
    showAllButton: IStyle;
    resultsContent: IStyle;
    searchContainer: IStyle;
    containerSearchFilter: IStyle;
    advancedDropdown: IStyle;
    advancedDropdownHidden: IStyle;
    subMenuSearchContainer: IStyle;
    selectionFolderContainer: IStyle;
    listFolderContainer: IStyle;
    tooltipHostStyle: IStyle;
    subComponentStyles: {
        iconButton: IButtonStyles,
        filterSearch: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
        textFieldStyle: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
        rangeTextFieldStyle: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
        scrollablePane: IStyleFunctionOrObject<IScrollablePaneStyleProps, IScrollablePaneStyles>;
        checkBoxStyle: IStyleFunctionOrObject<ITooltipHostStyleProps, ITooltipHostStyles>;
    }
    advancedDropdownLabel: IStyle;
    advancedDropdownContainer: IStyle;
}

export enum SearchScope
{
    Full,
    MetadataAndTags,
    Content
}