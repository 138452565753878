import React from "react";
import {  Stack } from "@fluentui/react";
import { FileSource, IChoiceInputSourceProps } from "./choiceInputSource.types";
import { useTranslation } from "react-i18next";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import TeamsButton from "../../../../common/components/teamsButton/teamsButton";
/*eslint-disable sonarjs/cognitive-complexity */

// const getClassNames = classNamesFunction<IChoiceInputSourcePropsStyles, IChoiceInputSourceStyles>();

export const ChoiceInputSourceBase = (props: IChoiceInputSourceProps) => {
    const { t } = useTranslation(['importKnowledgeLabDocument', 'common']);

    return (
        <Stack>
            <TeamsButton
                imageName={ImageName.Sharepoint} 
                styles={{root: {marginBottom: '25px'}}} 
                title={t("source.sharepoint")} 
                subTitle={t("choiceInputSource.sharepointSubtitle")} 
                onClick={() => props.onElementChoice(FileSource.sharepoint)} />
            <TeamsButton 
                imageName={ImageName.EmptyFolderDrop} 
                styles={{root: {marginBottom: '25px'}}} 
                title={t("source.local")} 
                subTitle={t("choiceInputSource.localSubtitle")} 
                onClick={() => props.onElementChoice(FileSource.local)} />
            <TeamsButton 
                imageName={ImageName.EdiAppLogo} 
                title={t("source.ediArchive")} 
                subTitle={t("choiceInputSource.ediSubtitle")} 
                onClick={() => props.onElementChoice(FileSource.edi)} />
        </Stack>
    )
}