import { styled } from "@fluentui/react";
import { getStyles } from "./formalAspects.styles";
import { IFormalAspectsPropsStyles, IFormalAspectsStyles, IFormalAspectsProps } from "./formalAspects.types";
import { FormalAspectsBase } from "./formalAspects.base";

const FormalAspects = styled<IFormalAspectsProps, IFormalAspectsPropsStyles, IFormalAspectsStyles>(
    FormalAspectsBase,
    getStyles,
    undefined,
    { scope: 'FormalAspects' }
);

export default FormalAspects;