import { styled } from "@fluentui/react";
import { FavouritesBase } from "./favourites.base";
import { getStyles } from "./favourites.styles";
import { IFavouritesProps, IFavouritesPropsStyles, IFavouritesStyles } from "./favourites.types";


const Favourites = styled<IFavouritesProps, IFavouritesPropsStyles, IFavouritesStyles>(
    FavouritesBase,
    getStyles,
    undefined,
    { scope: 'Favourites' }
);

export default Favourites;