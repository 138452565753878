/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable @typescript-eslint/no-unused-vars*/
import React, { useCallback, useEffect, useState } from "react";
import { classNamesFunction, DetailsHeader, DetailsList, DetailsRow, IColumn, PrimaryButton, SelectionMode, TooltipHost } from "@fluentui/react";
import { showWorkflowDetailsModal, useArchiveContent } from "../../../features/archiveContent";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { archivesApi } from "../../../services/archives/archives.api";
import { IWorkflowDetailsModalProps, IWorkflowDetailsModalPropsStyles, IWorkflowDetailsModalStyles } from "./workflowDetailsModal.types";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import { WorkFlowDetails, WorkFlowStepDetails } from "../../../../activityLab/components/activities/workflow/common/common";
import { WorkFlow, WorkFlowStatus } from "../../../../activityLab/models/workflow";
import { WorkFlowStep, WorkFlowStepStatus } from "../../../../activityLab/models/workflowStep";
import StepStatus from "../../../../activityLab/components/activities/workflow/common/stepStatus/stepStatus";
import MemberList from "../../../../activityLab/components/activities/common/memberList/memberList";
import { Helpers } from "../../../../utilities/helpers";
import { PersonaDetails } from "../../../../activityLab/models/user";
import { useDocLabDispatch } from "../../../docLabStore";
import { ErrorsType } from "../../../models/callsApi";
import { User } from "../../../models/user";
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";

const getClassNames = classNamesFunction<IWorkflowDetailsModalPropsStyles, IWorkflowDetailsModalStyles>();

export const WorkflowDetailsModalBase = (props: IWorkflowDetailsModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const dispatch = useDocLabDispatch();
    const { workflowDetailsModalShown, selectedItems } = useArchiveContent();
    const [workFlow, setWorkFlow] = useState<WorkFlowDetails<WorkFlow>>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const { t } = useTranslation(['workflowDetailsModal', 'common']);
    const [attachedDocuments, setAttachedDocuments] = useState<string>(t('no'));

    useOnMount(() => {
        viewWfDetails();
    });

    const { execute: viewWfDetails, loading, error } = useAsyncApi<void, void>({
        func: async () => {
            const workflow = await archivesApi.getWorkFlowInfo(selectedItems[0].id);
            setWorkFlow({
                workFlow: workflow,
                stepsDetails: workflow.steps.sort((a, b) => a.order > b.order ? 1 : -1).map((x, i) => {
                    return {
                        step: { ...x },
                        prevStep: (x.order !== 0) ? workflow?.steps.filter(s => s.order === (x.order - 1))[0] : undefined,
                        isLastStep: x.order === ((workflow?.steps.length || 1) - 1),
                        isUnusedStep: workflow?.status === WorkFlowStatus.Refused && x.status === WorkFlowStepStatus.Idle
                    }
                })
            });
        }
    });

    useEffect(() => {
        if (!error) {
            setErrorMessage(undefined); 
            return;
        }
        const er: ErrorsType = (error as unknown) as ErrorsType;
        switch (er.code) {
            case 400: setErrorMessage(t('error.notPermissions')); break;
            case 403: setErrorMessage(t('error.notPermissions')); break;
            case 404: setErrorMessage(t('error.notFound')); break;
            default: setErrorMessage(t('common:genericErrorApi')); break;
        }
    }, [error]);  //eslint-disable-line react-hooks/exhaustive-deps
    
    const mapToPersonaDetails = (users: User[] | undefined): PersonaDetails[] => {
        const filteredUser = users === undefined ? [] : users.filter(u => u !== null);
        return filteredUser.map(a => {
            return { firstName: a.firstName, lastName: a.lastName, email: '' } as PersonaDetails;
        });
    }

    const getHistorySection = (item: WorkFlowStepDetails<WorkFlowStep>) => {
        return item.step.status !== WorkFlowStepStatus.Idle && item.step.approvers.map(x => {
            const content = `${x.user.firstName} ${x.user.lastName} ${t('stepStatus.userAction')} ${t(`stepStatus.approverStatus.${x.status}`)} ${t('userActionWithDate')} ${x.updatedOn ? Helpers.getShortDate(x.updatedOn) : ''}`
            return <div style={{ whiteSpace: 'pre-wrap' }} key={x.user.id}>
                    <span key={x.user.id}>
                        <TooltipHost content={content}>
                            <div className={classNames.tooltipContent}>
                                {content}
                            </div>
                        </TooltipHost>
                    </span>
                </div>
        });
    }

    const getCommentsSection = (item: WorkFlowStepDetails<WorkFlowStep>) => {
        return item.step.approvers.map(x => {
            const content = `${x.user.firstName} ${x.user.lastName} ${t('userCommented')} "${x.comment}"`
            return x.comment && item.step.status !== WorkFlowStepStatus.Idle ?
                <div key={x.user.id} style={{ whiteSpace: 'pre-wrap' }}>
                    <TooltipHost content={content}>
                        <div className={classNames.tooltipContent} >
                            <span>{x.user.firstName} {x.user.lastName}</span>
                            <span>{t('userCommented')}</span>
                            <span style={{ fontStyle: 'italic' }}>{`"${x.comment}"`}</span>
                        </div>
                    </TooltipHost>
                </div> : ''
        });
    }

    const getAttachedDocumentSection = (item: WorkFlowStepDetails<WorkFlowStep>) => {
        let attachedDocuments = t('no');
        if(item.step.documents?.length !== 0){
            attachedDocuments = t('yes');
            setAttachedDocuments(t('yes'));
        }
        return <span>{attachedDocuments}</span>
    }

    const workflowDetailsColumns: IColumn[] = [
        {
            key: 'step',
            name: t('column.step'),
            ariaLabel: 'step',
            fieldName: '',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            className: classNames.step,
            onRender: function getValue(item: WorkFlowStepDetails<WorkFlowStep>) {
                return <>
                    <StepStatus item={item}></StepStatus>
                </>
            }
        },
        {
            key: 'status',
            name: t('column.status'),
            ariaLabel: 'status',
            fieldName: '',
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            onRender: function getValue(item: WorkFlowStepDetails<WorkFlowStep>) {
                return <span>{t(`stepStatus.status.${item.step.status}`)}</span>
            }
        },
        {
            key: 'assignedTo',
            name: t('column.approvers'),
            ariaLabel: 'approvers',
            fieldName: '',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: function getItemFrom(item: WorkFlowStepDetails<WorkFlowStep>) {
                return (
                    <div style={{ display: 'flex' }}>
                        <MemberList
                            members={mapToPersonaDetails(item.step.involvedPeople)}
                            sliceLength={2}
                            memberRounded={true}
                        />
                    </div>
                );
            }
        },
        {
            key: 'rules',
            name: t('column.rules'),
            ariaLabel: 'rules',
            fieldName: '',
            minWidth: 130,
            maxWidth: 130,
            isResizable: true,
            onRender: function getValue(item: WorkFlowStepDetails<WorkFlowStep>) {
                return <span style={{ whiteSpace: 'normal' }}>{t(`stepStatus.type.${item.step.type}`)}</span>
            }
        },
        {
            key: 'actionHistory',
            name: t('column.actionHistory'),
            ariaLabel: 'actionHistory',
            fieldName: '',
            minWidth: 200,
            maxWidth: 250,
            isResizable: true,
            onRender: getHistorySection
        },
        {
            key: 'comments',
            name: t('column.comments'),
            ariaLabel: 'comments',
            isResizable: true,
            fieldName: '',
            minWidth: 150,
            maxWidth: 250,
            onRender: getCommentsSection
        },
        {
            key: 'attacheddocuments',
            name: t('column.attachedDocuments'),
            ariaLabel: 'attachedDocuments',
            isResizable: true,
            fieldName: '',
            minWidth: 115,
            maxWidth: 115,
            onRender: getAttachedDocumentSection
        }
    ];

    const emptyList = useCallback(() => {
        if (workFlow?.stepsDetails?.length !== 0)
            return null;

        return (
            <div className={classNames.emptyList}>
                <span>{t('common:emptyList')}</span>
            </div>
        );
    }, [workFlow?.stepsDetails?.length, t, classNames.emptyList]);

    const body = () => {
        return ( loading ?
            <div className={classNames.centerContainer}>
                <TeamsSpinner/>
            </div> : errorMessage === undefined ?
            <div style={{overflowY: 'hidden'}}>
                <p>
                    <span style={{fontWeight: 'bold', fontSize: 'large'}}>{t('wfLabName')}</span>
                    <span style={{fontSize: '16px'}}>{workFlow?.workFlow.workflowLab?.name}</span>
                </p>
                <p>
                    <span style={{fontWeight: 'bold', fontSize: 'large'}}>{t('wfName')}</span>
                    <span style={{fontSize: '16px'}}>{workFlow?.workFlow.name}</span>
                </p>
                <p>
                    <span style={{fontWeight: 'bold', fontSize: 'large'}}>{t('attachedDocuments')}</span>
                    <span style={{fontSize: '16px'}}>{attachedDocuments}</span>
                </p>
                <div className={classNames.accordionContent}>
                    <DetailsList
                        items={workFlow?.stepsDetails || []}
                        columns={workflowDetailsColumns}
                        selectionMode={SelectionMode.none}
                        isHeaderVisible={true}
                        getKey={((item: WorkFlowStepDetails<WorkFlowStep>) => item.step.id.toString())}
                        setKey="none"
                        onRenderRow={props => props ? <DetailsRow {...props} /> : null}
                        onRenderDetailsHeader={props => props ? <DetailsHeader {...props} /> : null}
                        onRenderDetailsFooter={emptyList}
                    />
                </div>
            </div> :
            <div className={classNames.centerContainer}>
                <TeamsImage imageName={ImageName.Error3} caption={errorMessage} scale={0.5} styles={{root: {width: '100%', height: '100%'} }} />
            </div>
        );
    }

    const footer = () => {
        return (
            <div>
                <PrimaryButton onClick={() => { dispatch(showWorkflowDetailsModal(false)); }}>{t('common:close')}</PrimaryButton>
            </div>
        );
    }

    return (
        <EdiModal
            isOpen={workflowDetailsModalShown}
            showCloseIcon={true}
            onCloseClick={props.onClose}
            title={t('viewWfDetails')}
            subtitle={t('title')}
            width={'70%'}
            height={700}
            body={body()}
            footer={footer()}            
        />
    );
}