import React from "react";
import { classNamesFunction } from "@fluentui/react";
import { IPhasePropsStyles, IPhaseStyles, IPhaseProps } from "./phase.types";
import { useTranslation } from "react-i18next";

const getClassNames = classNamesFunction<IPhasePropsStyles, IPhaseStyles>();

export const PhaseBase = (props: IPhaseProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation('createActivityTemplate')
    return (
        <div className={classNames.cardContainer}>
            <div className={classNames.nameContainer}>{props.name}</div>
            <div className={classNames.descriptionContainer}>{props.description === "" ? <span style={{ fontStyle:'italic' }}>{t('noDescription')}</span> :props.description}</div>
        </div>
    );
}