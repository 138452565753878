/*eslint-disable sonarjs/cognitive-complexity */
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { classNamesFunction, Dialog, DialogFooter, Icon, Label, PrimaryButton, ScrollablePane, Stack, TextField, Toggle, TooltipHost } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "../../../../common/components/accordion/accordion";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import { useNavigator } from "../../../hooks/useNavigator";
import { IArchiveTagsSetting, IDocLabSettingsProps, IDocLabSettingsPropsStyles, IDocLabSettingsStyles } from "./docLabSettings.types";
import { useDocLabDispatch } from "../../../docLabStore";
import { setToolbarActions } from "../../../features/toolbarActions";
import KeywordList from "./keywordList/keywordList";
import { searchApi } from "../../../services/search/search.api";
import { SkillConfigurations } from "../../../services/search/search.contracts";
import FormalAspectTresholds from "../../../../common/components/formalAspectTresholds/formalAspectTresholds";
import { IFormalAspectSettings, IFormalAspectTresholds } from "../../../../common/components/formalAspectTresholds/formalAspectTresholds.types";
import { ErrorsType } from "../../../models/callsApi";
import { insertCall, setCall } from "../../../features/callNotification";
import { nanoid } from "@reduxjs/toolkit";
import { Call, callsList } from "../../../models/callsApi";
import SharepointLink from "./sharepointLink/sharepointLink";
import { getJsonSchemaProperty } from "../../../features/archiveContent";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { archivesApi } from "../../../services/archives/archives.api";
import { OtherSettings } from "../../../models/archive";
import { setArchive } from "../../../features/archiveList";

enum ToggleType {
    Archive = 0,
    Folder = 1,
    Document = 2,
    Metadata = 3,
    Report = 4,
    MetadataChanges = 5
}

const getClassNames = classNamesFunction<IDocLabSettingsPropsStyles, IDocLabSettingsStyles>();

export const DocLabSettingsBase = (props: IDocLabSettingsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['archiveSettings', 'tagPanel', 'common']);
    const { currentArchiveId } = useNavigator();
    const dispatch = useDocLabDispatch();
    const currentArchive = useCurrentArchive();    

    const [whiteList, setWhiteList] = useState<string[]>([]);
    const [blackList, setBlackList] = useState<string[]>([]);
    const [precList, setPrecList] = useState<string[]>([]);
    const [prec, setPrec] = useState<string>("");
    const [update, setupdate] = useState<boolean>(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [keyphrasesLimit, setKeyphrasesLimit] = useState<{loading: boolean, limit: number, error: boolean}>();
    const [firstRenderLoading, setFirstRenderLoading] = useState(true);
    const [otherSettingLoading, setOtherSettingLoading] = useState<boolean>(false);
    const genericErrorApiTraslate = t('common:genericErrorApi');
    const [displayToggleMetadata, setDisplayToggleMetadata] = useState(false);
    
    const [tagsSetting, setTagsSetting] = useState<IArchiveTagsSetting[]>([])
    const archiveTags: Record<number, string> = { 0: t('archiveSettings:keyphrases'), 1: t('archiveSettings:language'), 2: t('archiveSettings:organization'), 3: t('archiveSettings:location'), 4: t('archiveSettings:formalAspect') }
    const { execute: Update, loading: loadingupdate } = useAsyncApi<void, void>({
        func: async () => {
            if (!currentArchiveId)
                return;

            try {
                await searchApi.putKeyphrasesExtractor(currentArchiveId, {
                    whitelist: whiteList, blacklist: blackList,
                    isSkillActive: configs?.configurationProperties.isSkillActive || false,
                    numKeyphrases: configs?.configurationProperties.numKeyphrases || 0
                });
            }
            catch (er) {
                const error: ErrorsType = er as ErrorsType;
                if (prec === 'whitelist') {
                    setWhiteList(precList);
                } else if (prec === 'blacklist') {
                    setBlackList(precList);
                }
                let errorMessage = '';
                if (error.code === 404) {
                    errorMessage = t("archiveSettings:errorCode404");
                } else {
                    errorMessage = genericErrorApiTraslate;
                }
                setErrorMessage(errorMessage);
                setShowErrorDialog(true);
            }
        }
    });

    const getArchiveTagsSetting = async () => {
        if (!currentArchiveId)
            return {} as IArchiveTagsSetting[];
        try {
            const result = await searchApi.getArchiveTagsSettings(currentArchiveId);

            setTagsSetting(result.sort((a, b) => a.tagSection - b.tagSection).filter(x => x.tagSection > 1));
            return result;
        }
        catch (error) {
            console.log(error)
            return {} as IArchiveTagsSetting[];
        }
    }

    useEffect(() => {
        if (keyphrasesLimit?.limit) {
            setFirstRenderLoading(false);
        }
    }, [keyphrasesLimit])

    const getKeyphrasesLimit = async () => {
        try {
            return await archivesApi.getKeypharasesLimit(currentArchiveId ?? 0).then(r => {
                setKeyphrasesLimit({ limit: r.keyphrasesLimitSet, loading: false, error: false})
            });
        }
        catch (error) {
            setKeyphrasesLimit({ limit: 20, loading: false, error: true})
            console.log(error)
        }
    }

    const putKeyphrasesLimit = async (newKeyPhrasesLimit: number) => {
        try {
            const response = await archivesApi.updateKeypharasesLimit(currentArchiveId ?? 0, newKeyPhrasesLimit);
            setKeyphrasesLimit({ limit: newKeyPhrasesLimit, loading: false, error: false})
            return response;
        }
        catch (error) {
            setKeyphrasesLimit({limit: keyphrasesLimit?.limit ?? 20, loading: false, error: true})
            console.log(error)
        }
    }

    const { execute: GetKeyphrasesExtractor, loading, value: configs } = useAsyncApi<void, SkillConfigurations>({
        func: async () => {
            if (!currentArchiveId)
                return {} as SkillConfigurations;
            try {
                const result = await searchApi.getKeyphrasesExtractor(currentArchiveId);

                setWhiteList(result.configurationProperties.whitelist);
                setBlackList(result.configurationProperties.blacklist);
                return result;
            }
            catch (er) {
                const error: ErrorsType = er as ErrorsType;
                let errorMessage = '';
                if (error.code === 404) {
                    errorMessage = t("archiveSettings:errorCode404");
                } else {
                    errorMessage = genericErrorApiTraslate;
                }
                setErrorMessage(errorMessage);
                setShowErrorDialog(true);
                return {} as SkillConfigurations;
            }
        }
    });

    function addItemWhitelist(item: string): void {
        setPrec('whitelist');
        setPrecList(whiteList);
        setWhiteList(o => [...o, item])
        setupdate(true);
    }

    function removeItemWhitelist(item: string): void {
        setPrec('whitelist');
        setPrecList(whiteList);
        setWhiteList(whiteList.filter(i => i !== item));
        setupdate(true)
    }

    function removeAllItemsWhitelist(): void {
        setPrec('whitelist');
        setPrecList(whiteList);
        setWhiteList([]);
        setupdate(true)
    }

    function addItemblacklist(item: string): void {
        setPrec('blacklist');
        setPrecList(blackList);
        setBlackList(o => [...o, item])
        setupdate(true);
    }

    function removeItemBlacklist(item: string): void {
        setPrec('blacklist');
        setPrecList(blackList);
        setBlackList(blackList.filter(i => i !== item));
        setupdate(true)
    }

    function removeAllItemsBlacklist(): void {
        setPrec('blacklist');
        setPrecList(blackList);
        setBlackList([]);
        setupdate(true)
    }

    useEffect(() => {
        if (update) {
            Update()
            setupdate(false)
        }
    }, [update]);//eslint-disable-line react-hooks/exhaustive-deps

    useOnMount(() => {
        getKeyphrasesLimit();
        GetKeyphrasesExtractor();
        dispatch(setToolbarActions([]));
        getArchiveTagsSetting();
        dispatch(getJsonSchemaProperty(currentArchiveId ?? 0));

        if (currentArchive?.metadataJsonSchema !== undefined && currentArchive.metadataJsonSchema !== null)
            setDisplayToggleMetadata(true);
    });

    const saveFormalAspectSettings = async (archiveTagsSetting: IArchiveTagsSetting, formalAspectslabels: IFormalAspectTresholds[], formalAspectSection: IFormalAspectSettings) => {
        let settings = (archiveTagsSetting.settings as IFormalAspectSettings[]);
        const index = (settings).indexOf(formalAspectSection);
        settings[index].Labels = formalAspectslabels;
        settings = settings.map(setting => {
            setting.Labels = setting.Labels.filter(l => l.text !== '');
            return setting;
        });
        if (currentArchiveId && settings[index].Labels.every(s => s.isValid)) {
            await searchApi.upsertArchiveTagsSetting(currentArchiveId, {
                tagSection: archiveTagsSetting.tagSection,
                enabled: archiveTagsSetting.enabled,
                settings: settings,
            });
        }
    }

    const labelIsDuplicated = (archiveTagsSetting: IArchiveTagsSetting, label: IFormalAspectTresholds) => {
        return (archiveTagsSetting.settings as IFormalAspectSettings[])
            .some(x => x.Labels.filter(lb => lb !== label)
                .some(l => l.text?.toUpperCase() === label.text?.toUpperCase()
                    || (l.text === label.text && l.labelFrom === label.labelFrom && l.labelTo === label.labelTo)));
    }

    const renderFormalAspectSettings = (archiveTagsSetting: IArchiveTagsSetting) => {
        return (archiveTagsSetting.settings && (archiveTagsSetting.settings as IFormalAspectSettings[]).map((sett, ind) => {
            return (
                <FormalAspectTresholds
                    onSave={(labels: IFormalAspectTresholds[]) => saveFormalAspectSettings(archiveTagsSetting, labels, sett)}
                    key={sett.FormalAspects + "_" + ind} labels={sett.Labels || []}
                    formalAspects={sett.FormalAspects}
                    labelIsDuplicated={(label: IFormalAspectTresholds) => labelIsDuplicated(archiveTagsSetting, label)}
                    readOnly={!archiveTagsSetting.enabled} >
                </FormalAspectTresholds>)
        }))
    }

    const enabledChanged = async (item: IArchiveTagsSetting, checked: boolean) => {
        const enabledChangedCall: Call = {
            type: callsList.toogleChange,
            nameOperation: `${t('archiveSettings:family')}: ${archiveTags[item.tagSection]}`,
            errors: [
                { code: 403, message: t('notPermissions') },
                { code: 500, message: genericErrorApiTraslate }
            ]
        }

        const id = nanoid();
        const payload = { requestId: id, notification: enabledChangedCall }
        dispatch(insertCall(payload));
        const newApiResult = tagsSetting.slice()
        const selectedToggle = tagsSetting.find(x => x.tagSection === item.tagSection)
        try {
            if (selectedToggle)
                newApiResult[tagsSetting.indexOf(selectedToggle)].enabled = checked;

            if (currentArchiveId) {
                const successPayload = { requestId: id, success: true, message: checked ? t('archiveSettings:enabled') : t('archiveSettings:disabled') }
                await searchApi.upsertArchiveTagsSetting(currentArchiveId, {
                    tagSection: item.tagSection,
                    enabled: checked,
                    settings: item.tagSection !== 4
                        ? item.settings
                        : (item.settings as IFormalAspectSettings[]).map(setting => {
                            setting.Labels = setting.Labels.filter(l => l.text !== '');
                            return setting;
                        })
                });
                setTagsSetting(newApiResult);
                dispatch(setCall(successPayload));
            }
        }
        catch (er) {
            const error: ErrorsType = er as ErrorsType;
            let errorMessage = '';
            if (error.code === 403) {
                errorMessage = t('notPermissions');
            }
            else {
                errorMessage = genericErrorApiTraslate;
            }
            const failurePayload = { requestId: id, success: false, message: errorMessage }
            dispatch(setCall(failurePayload));
        }
    }

    const updateOtherSettings = async (toggleType: ToggleType, checked: boolean | undefined) =>{
        if (checked === undefined)
            return;

        setOtherSettingLoading(true);
        const otherSettings : OtherSettings = {
            isCrossArchiveSearchable: toggleType === ToggleType.Archive ? checked : null,
            isCrossFolderSearchable: toggleType === ToggleType.Folder ? checked : null,
            isTranslationAllowed : toggleType === ToggleType.Document ? checked : null,
            isDefaultViewAdditionalMetadata: toggleType === ToggleType.Metadata ? checked : null,
            isReportDateFieldsInDateFormat: toggleType === ToggleType.Report ? checked : null,
            isLogChangesMetadata: toggleType === ToggleType.MetadataChanges ? checked : null
        }
        try {
            if (!currentArchiveId)
                return;
                
            await archivesApi.updateArchiveOtherSettings(currentArchiveId, otherSettings);
            const updatedArchive = await archivesApi.getArchive(currentArchiveId);
            dispatch(setArchive(updatedArchive));
        }
        finally {
            setOtherSettingLoading(false);
        }
    }

    const taggingSection = (item: IArchiveTagsSetting, key: number) => {
        return (
            <div key={key}>
                <Accordion title={
                    <Stack horizontal disableShrink >
                        <Stack styles={{ root: { width: '150px', marginLeft: (!item.settings ? '7px' : '0px') } }}>
                            <Stack >
                                <span>
                                    {archiveTags[item.tagSection]}
                                    <TooltipHost content={archiveTags[item.tagSection]}>
                                        <Icon
                                            className={classNames.iconStyle}
                                            iconName={'info'} />
                                    </TooltipHost>
                                </span>
                            </Stack>
                        </Stack>
                        <Stack>
                            <Stack >
                                <Toggle
                                    checked={item.enabled}
                                    onText="On"
                                    offText="Off"
                                    onChange={(_, checked) => enabledChanged(item, checked || false)}
                                    styles={{ root: { marginBottom: 0 } }}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                }
                    opened={true}
                    isIconHidden={!item.settings}
                    clickOnlyIconEnabled
                >
                    {item.settings && item.tagSection === 4 ? renderFormalAspectSettings(item) : undefined}
                </Accordion>
            </div>
        )
    }

    return (
        <>
            <Stack className={classNames.root}>
                <Stack className={classNames.title} horizontal verticalAlign={'center'}>
                    <Icon iconName={"Settings"} className={classNames.titleIcon} />
                    <span style={{ paddingLeft: 10 }}>{t('archiveSettings:title')}</span>
                </Stack>
                <Stack.Item verticalFill className={classNames.lowerSection}>
                    {configs &&
                        <ScrollablePane>
                            <Accordion title={t('archiveSettings:tagging')} opened={true} stickyHeader>
                                <div className={classNames.root}>
                                    <Label className={classNames.descriptionLabel}>{t('archiveSettings:taggingDescription')}</Label>
                                    <div style={{ marginLeft: '20px' }}>
                                        <Stack horizontal disableShrink className={classNames.taggingSection}>
                                            <Stack styles={{ root: { marginRight: '82px' } }}>
                                                <span>{t('archiveSettings:family')}</span>
                                            </Stack>
                                            <Stack >
                                                <span>{t('archiveSettings:status')}</span>
                                            </Stack>
                                        </Stack>
                                    </div>
                                    <>{
                                        tagsSetting.map((x, ind) => {
                                            return (taggingSection(x, ind))
                                        })}
                                    </>
                                </div>
                            </Accordion>
                            <Accordion title={t('archiveSettings:whitelistTitle')} opened={true} stickyHeader >
                                <div className={classNames.root}>
                                    <KeywordList commonDescription={t('archiveSettings:commonDescription')} loading={loading || loadingupdate} description={t('archiveSettings:whitelistDescription')} NotInListTitle={'BlackList'} items={whiteList} NotInList={blackList} addItem={addItemWhitelist} removeAllItems={removeAllItemsWhitelist} removeItem={removeItemWhitelist} pinnedIcon={true} maxLenght={true} />
                                </div>
                            </Accordion>
                            <Accordion title={t('archiveSettings:blacklistTitle')} opened={true} stickyHeader>
                                <div className={classNames.root}>
                                    <KeywordList commonDescription={t('archiveSettings:commonDescription')} loading={loading || loadingupdate} description={t('archiveSettings:blacklistDescription')} NotInListTitle={'WhiteList'} items={blackList} NotInList={whiteList} addItem={addItemblacklist} removeAllItems={removeAllItemsBlacklist} removeItem={removeItemBlacklist} pinnedIcon={false} maxLenght={false} />
                                </div>
                            </Accordion>
                            <Accordion title={"KeyPhrases"} opened={true} stickyHeader>
                                <div className={classNames.root}>
                                    {!firstRenderLoading ? <TextField
                                        type='number'
                                        inputMode='numeric'
                                        className={keyphrasesLimit?.error ? classNames.errorKeyphrases : classNames.standardKeyphrases}
                                        defaultValue={keyphrasesLimit?.limit.toString()}
                                        prefix={"KeyPhrases max items"}
                                        onNotifyValidationResult={(_, newValue) => {
                                            if(newValue !== '' && parseInt(newValue ?? '20') !== keyphrasesLimit?.limit){
                                                setKeyphrasesLimit({limit: keyphrasesLimit?.limit ?? 20, loading: true, error: false})
                                                putKeyphrasesLimit(parseInt(newValue ?? '20'))
                                            }
                                            if(newValue === ''){
                                                setKeyphrasesLimit({limit: keyphrasesLimit?.limit ?? 20, loading: false, error: false})
                                            }
                                        }}
                                        onGetErrorMessage={(newValue) => {
                                            if(parseInt(newValue ?? '20') < 0 || parseInt(newValue ?? '20') > 200)
                                                return "Limit must be in range 1 - 200"
                                        }}
                                        styles={{
                                            root:{
                                                width: '60%'
                                            }
                                        }}
                                        step={1}
                                        min={1}
                                        max={200}         
                                        disabled={keyphrasesLimit?.loading}             
                                    /> : 
                                    <div className={classNames.loaderContainer}>
                                        <TeamsSpinner />
                                    </div>
                                }
                                </div>
                            </Accordion>
                            <Accordion title={t('archiveSettings:otherSettings')} opened={true} stickyHeader>
                                <div className={classNames.root}>
                                    <Toggle
                                        label={t('archiveSettings:crossArchiveSearch')}
                                        inlineLabel
                                        disabled={otherSettingLoading}
                                        onText='On'
                                        offText='Off'
                                        checked={currentArchive?.isCrossArchiveSearchable}
                                        onChange={(_, checked) => updateOtherSettings(ToggleType.Archive, checked)}
                                    />
                                    <Toggle
                                        label={t('archiveSettings:crossFolderSearch')}
                                        inlineLabel
                                        disabled={otherSettingLoading}
                                        onText='On'
                                        offText='Off'
                                        checked={currentArchive?.isCrossFolderSearchable}
                                        onChange={(_, checked) => updateOtherSettings(ToggleType.Folder, checked)}
                                    />
                                    <Toggle
                                        label={t('archiveSettings:documentTranslator')}
                                        inlineLabel
                                        disabled={otherSettingLoading}
                                        onText='On'
                                        offText='Off'
                                        checked={currentArchive?.isTranslationAllowed}
                                        onChange={(_, checked) => updateOtherSettings(ToggleType.Document, checked)}
                                    />
                                    {displayToggleMetadata && <Toggle
                                        label={t('archiveSettings:defaultAdditionalMetadata')}
                                        inlineLabel
                                        disabled={otherSettingLoading}
                                        onText='On'
                                        offText='Off'
                                        checked={currentArchive?.isDefaultViewAdditionalMetadata}
                                        onChange={(_, checked) => updateOtherSettings(ToggleType.Metadata, checked)}
                                    />}
                                    <Toggle
                                        label={t('archiveSettings:reportFieldsWithDateFormat')}
                                        inlineLabel
                                        disabled={otherSettingLoading}
                                        onText='On'
                                        offText='Off'
                                        checked={currentArchive?.isReportDateFieldsInDateFormat}
                                        onChange={(_, checked) => updateOtherSettings(ToggleType.Report, checked)}
                                    />
                                    {displayToggleMetadata && <Toggle
                                        label={t('archiveSettings:logChangeMetadata')}
                                        inlineLabel
                                        disabled={otherSettingLoading}
                                        onText='On'
                                        offText='Off'
                                        checked={currentArchive?.isLogChangesMetadata}
                                        onChange={(_, checked) => updateOtherSettings(ToggleType.MetadataChanges, checked)}
                                    />}
                                </div>
                            </Accordion>
                            {currentArchive?.metadataJsonSchema !== null &&
                                <Accordion title={t('archiveSettings:externalLov')} opened={true} stickyHeader>
                                <div className={classNames.root}>
                                    <SharepointLink />
                                </div>
                                </Accordion>
                            }
                        </ScrollablePane>
                    }
                    {loading &&
                        <div className={classNames.loaderContainer}>
                            <TeamsSpinner />
                        </div>
                    }
                </Stack.Item>
            </Stack>
            <Dialog
                hidden={!showErrorDialog}
                dialogContentProps={{
                    title: t('common:error'),
                    subText: errorMessage,
                }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={() => setShowErrorDialog(false)} text={t('common:close')} />
                </DialogFooter>
            </Dialog>
        </>
    );
}