import { classNamesFunction, Pivot, PivotItem, Stack } from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import TeamsImage from "../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../common/components/teamsImage/teamsImage.types";
import { useOnMount } from "../../../utilities/hooks";
import { AppRoutes, AppTab } from "../../utilities/routes";
import { IHeaderProps, IHeaderPropsStyles, IHeaderStyles } from "./header.types";
const getClassNames = classNamesFunction<IHeaderPropsStyles, IHeaderStyles>();

export const HeaderBase = (props: IHeaderProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const location = useLocation();
    const history = useHistory();
    const [lastRoutes, setLastRoutes] = useState({
        [AppTab.docLab]: AppRoutes.generateRouteForTab(AppTab.docLab),
        [AppTab.activityLab]: AppRoutes.generateRouteForTab(AppTab.activityLab),
        [AppTab.knowledgeLab]: AppRoutes.generateRouteForTab(AppTab.knowledgeLab),
        [AppTab.knowledgeDS]: AppRoutes.generateRouteForTab(AppTab.knowledgeDS),
        [AppTab.dashboard]: AppRoutes.generateRouteForTab(AppTab.dashboard)
    });

    const canSeeKnowledgeDS = false; // currentUser.getUserRoles?.includes(EdiUserRoleId.KnowledgeDS) ?? false

    const selectedTab = useMemo(() => AppRoutes.getSelectedTab(location.pathname), [location.pathname]);

    useOnMount(() => {
        AppRoutes.isHome(location.pathname) && history.replace(AppRoutes.generateRouteForTab(AppTab.docLab))
    });

    useEffect(() => {
        if (!selectedTab || lastRoutes[selectedTab] === location.pathname)
            return;
        setLastRoutes({ ...lastRoutes, [selectedTab]: location.pathname })
    }, [location.pathname, selectedTab, lastRoutes]);

    const goTo = useCallback((item?: PivotItem) => {
        if (!item || !selectedTab || selectedTab === item?.props.itemKey || !lastRoutes[selectedTab])
            return;

        history.push(lastRoutes[item.props.itemKey as AppTab]);
    }, [selectedTab, history, lastRoutes]);

    return (
        <Stack horizontal className={classNames.root}>
            <Stack.Item className={classNames.title} disableShrink>
                <TeamsImage imageName={ImageName.EdiAppLogo} styles={{ img: { borderRadius: 4 } }} />
                <h2>EDI</h2>
            </Stack.Item>
            <Stack.Item disableShrink>
                <Pivot
                    key={selectedTab}
                    styles={classNames.subComponentStyles.pivot}
                    selectedKey={selectedTab}
                    onLinkClick={(item) => { goTo(item); }}
                    headersOnly={true}
                >
                    <PivotItem key={AppTab.docLab} itemKey={AppTab.docLab} headerText="Doc Lab" />
                    <PivotItem key={AppTab.activityLab} itemKey={AppTab.activityLab} headerText="Project Lab" />
                    <PivotItem key={AppTab.knowledgeLab} itemKey={AppTab.knowledgeLab} headerText="Knowledge Lab" />
                    {canSeeKnowledgeDS && <PivotItem key={AppTab.knowledgeDS} itemKey={AppTab.knowledgeDS} headerText="Knowledge DS" />}
                    <PivotItem key={AppTab.dashboard} itemKey={AppTab.dashboard} headerText="Dashboard retraining" />

                </Pivot>
            </Stack.Item>
        </Stack>
    );
}