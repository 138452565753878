/*eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useState } from "react";
import { classNamesFunction, DefaultButton, Icon, ITextField, keyframes, PrimaryButton, Shimmer, TextField } from "@fluentui/react";
import { ICopyLinkModalPropsStyles, ICopyLinkModalStyles, ICopyLinkModalProps } from "./copyLinkModal.types";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { useTranslation } from "react-i18next";
import { useArchiveContent } from "../../../features/archiveContent";
import appEntity from "../../../../modules/appEntity/appEntity";
import { useDocAnalysisContent } from "../../../features/docAnalysisContent";
import { AnalysisTypeId } from "../../../models/docAnalysis";
import { ItemToCopy } from "../../../models/constants";
import { useAsyncApi } from "../../../../utilities/hooks";
import { archivesApi } from "../../../services/archives/archives.api";
import { useSearchContent } from "../../../features/searchContent";

const getClassNames = classNamesFunction<ICopyLinkModalPropsStyles, ICopyLinkModalStyles>();

export const CopyLinkModalBase = (props: ICopyLinkModalProps) => {
    const [copied, setCopied] = useState(false);
    const [link, setLink] = useState('');
    const [title, setTitle] = useState('');
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, copied: copied });
    const { copyLinkModalShown, selectedItems } = useArchiveContent();
    const { selectionEntryList } = useSearchContent();
    const selectedList = selectedItems.length > 0 ? selectedItems : selectionEntryList;

    const { t } = useTranslation(['copyLinkModal', 'common']);
    const { selectedDocAnalysis } = useDocAnalysisContent();
    let inputRef: ITextField | null = null;

    const buildIdDocument = useCallback(() => {
        if (selectedList[0])
            setLink(selectedList[0].id.toString())
    }, [selectedList]);

    const { execute: generateFileLink, loading: loadingGenerateFileLink } = useAsyncApi<number, void>({
        func: async (fileUrl: number) => { 
            const result = await archivesApi.getFileTeamsUrl(fileUrl, true);
            setLink(result.teamsUrl);
        }
    });
    
    const buildLink = useCallback(() => {
        if (selectedList.length > 0) {
            const entityId = props.entityId && props.entityId !== "" ? props.entityId : appEntity.getEntityId;
            const selected = selectedList[0];
            const pathName = window.location.pathname + "/";
            const subEntity = `fileid=${selected.id}/isfolder=${selected.isFolder}`;
            const btoaPrint = btoa(setArchiveContentContext(pathName) + subEntity);
            const encodedContext = encodeURI('{"subEntityId": "' + btoaPrint + '"}');
            const taskItemUrl = `https://teams.microsoft.com/l/entity/${appEntity.getAppId}/${entityId}?context=${encodedContext}`
            setLink(taskItemUrl);
        }
        else {
            const entityId = props.entityId && props.entityId !== "" ? props.entityId : appEntity.getEntityId;
            const pathName = window.location.pathname + "/";
            const subEntity = `${selectedDocAnalysis?.typeId}/${selectedDocAnalysis?.id}`; //tipo analisi, id dell'analisi
            let base64Path = btoa(pathName + subEntity);

            if (selectedDocAnalysis?.typeId === AnalysisTypeId.GlossaryCheck)
                base64Path = btoa(pathName);

            const encodedContext = encodeURI('{"subEntityId": "' + base64Path + '"}');

            const taskItemUrl = `https://teams.microsoft.com/l/entity/${appEntity.getAppId}/${entityId}?context=${encodedContext}`
            
            setLink(taskItemUrl);
            // da implementare qualora volessimo creare deeplink per altre sezioni del sito (non su file cartelle o search)
        }
    }, [selectedList, props.entityId, selectedDocAnalysis?.id, selectedDocAnalysis?.typeId])

    useEffect(() => {
        setTitle(t("title." + copyLinkModalShown.type));
        switch (copyLinkModalShown.type) {
            case ItemToCopy.DeepLink:
                if (selectedList.length > 0 && copyLinkModalShown.generateBeLink){
                    const selected = selectedList[0];
                    generateFileLink(selected.id)
                }
                else{
                    buildLink();
                }
                break;

            case ItemToCopy.DocumentId:
                buildIdDocument();
                break;
            default:
                break;
        }
    }, [copyLinkModalShown, t, buildIdDocument, buildLink, selectedList]);  //eslint-disable-line react-hooks/exhaustive-deps

    const setArchiveContentContext = (pathname: string): string => {
        const splittedPathname = pathname.split('/');
        splittedPathname[5] = "files";
        return splittedPathname.join('/')
    }
    const body = (
            loadingGenerateFileLink ? <Shimmer className={classNames.shimmer} /> :
            <TextField
                type="text"
                value={link}
                styles={classNames.subComponentStyles.textField}
                id="copy_field"
                componentRef={(tf) => inputRef = tf}
                onRenderSuffix={() => copied ?
                    <>
                        <Icon
                            iconName={"CheckMark"}
                            className={classNames.iconStyle}
                            style={{ animation: `${keyframes({ to: { transform: 'scale(2)', transition: 'all 0.2s ease-in-out 0.2s' } })} 1s linear` }}
                        />
                    </>
                    : <></>}
            />
    )

    const copyToClipboard = () => {
        if (inputRef) {
            inputRef.select();
            document.execCommand("copy");
            setCopied(true);
        }
    }

    const footer = (<div>
        <DefaultButton onClick={() => { setLink(''); setCopied(false); props.onClose && props.onClose() }} style={{ margin: '0px 4px' }}>
            {t('common:leave')}
        </DefaultButton>
        <PrimaryButton
            onClick={() => copyToClipboard()}
            style={{ margin: '0px 4px' }}>
            {t('action.' + copyLinkModalShown.type)}
        </PrimaryButton>
    </div>)
    return (
        <EdiModal
            isOpen={copyLinkModalShown.showModal}
            showCloseIcon={false}
            onCloseClick={() => { setLink(''); setCopied(false); props.onClose && props.onClose() }}
            title={title}
            body={body}
            footer={footer}
            width={500}
        />
    );
}