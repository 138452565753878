import { classNamesFunction, DefaultButton, Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import MultiStepModal from "../../../../common/components/multiStepModal/multiStepModal";
import { CreateWorkFlowPayload, WorkFlowTypes } from "../../../models/workflow";
import { WorkFlowStepTypes } from "../../../models/workflowStep";
import WorkflowDetails from "../../activities/workflow/common/workflowDetails/workflowDetails";
import { CreatingWorkFlowPage } from "../creatingWorkFlowPage";
import AddFile from "./addFile/addFile";
import { SecondPageData } from "./addFile/addFile.base";
import AddSteps from "./addSteps/addSteps";
import { ThirdPageData } from "./addSteps/addSteps.base";
import SetProperties from "./setProperties/setProperties";
import { FirstPageData } from "./setProperties/setProperties.base";
import { IWorkflowStartModalProps, IWorkflowStartModalPropsStyles, IWorkflowStartModalStyles } from "./workflowStartModal.types";

enum CreateWorkflowStep {
    setProperties = 0,
    addFile = 1,
    addSteps = 2,
    summary = 3,
    startingWorkflow = 4
}

const getClassNames = classNamesFunction<IWorkflowStartModalPropsStyles, IWorkflowStartModalStyles>();

export const WorkflowStartModalBase = (props: IWorkflowStartModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [currentStep, setCurrentStep] = useState(CreateWorkflowStep.setProperties);
    const [isMutating, setIsMutating] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [firstPageData, setFirstPageData] = useState<FirstPageData>({ name: "", description: "", activityKey: undefined, isValid: false, activityId: 0, activity: undefined });
    const [secondPageData, setSecondPageData] = useState<SecondPageData>({ isPdf: false, file: undefined });
    const [thirdPageData, setThirdPageData] = useState<ThirdPageData>({ digitalSignEnabled: false, steps: [] });
    const [request, setRequest] = useState<CreateWorkFlowPayload>();
    const { t } = useTranslation(['createWorkflow', 'common']);

    const previuous = useCallback(() => {
        switch (currentStep) {
            case CreateWorkflowStep.addFile: return setCurrentStep(CreateWorkflowStep.setProperties);
            case CreateWorkflowStep.addSteps: return setCurrentStep(CreateWorkflowStep.addFile);
            case CreateWorkflowStep.summary: return setCurrentStep(CreateWorkflowStep.addSteps);
            default: return () => undefined;
        }
    }, [currentStep])

    useEffect(() => {
        setThirdPageData({ ...thirdPageData, digitalSignEnabled: secondPageData.isPdf })
    }, [secondPageData]); //eslint-disable-line react-hooks/exhaustive-deps

    const createWorkflow = async () => {
        setRequest({
            ActivityId: firstPageData.activity?.id,
            Name: firstPageData.name,
            Description: firstPageData.description,
            FileId: secondPageData.file?.uniqueId,
            Steps: thirdPageData.steps.map(x => {
                return {
                    name: x.name,
                    order: x.order,
                    type: x.type,
                    approvers: x.approvers.map(x => x.user.id)
                }
            })
        });
        setCurrentStep(CreateWorkflowStep.startingWorkflow)
    }

    const next = useCallback(() => {
        switch (currentStep) {
            case CreateWorkflowStep.setProperties: return setCurrentStep(CreateWorkflowStep.addFile);
            case CreateWorkflowStep.addFile: return setCurrentStep(CreateWorkflowStep.addSteps);
            case CreateWorkflowStep.addSteps: return setCurrentStep(CreateWorkflowStep.summary);
            case CreateWorkflowStep.summary: return createWorkflow();
            default: return () => undefined;
        }
    }, [currentStep]) //eslint-disable-line react-hooks/exhaustive-deps

    const checkStepValidation = useCallback((): boolean => {
        let result = false;

        thirdPageData.steps.forEach(step => result = result || step.name === "" || step.approvers.length === 0 || step.approvers.length > 10 || (step.type === WorkFlowStepTypes.OnlyOneSignedApprover && step.approvers.length > 1));

        if (!thirdPageData.digitalSignEnabled) {
            thirdPageData.steps.forEach(step => {
                result = result || step.type === WorkFlowStepTypes.OnlyOneSignedApprover
            })
        }
        return result;
    }, [thirdPageData]);

    const footer = useMemo(() => {
        return (
            <div className={classNames.buttonsContainer} >
                <div style={{ textAlign: 'left', width: '100%' }}>
                    <span className={classNames.requiredTextLeft}>* {t('common:fieldRequired')}</span>
                </div>
                {(currentStep !== CreateWorkflowStep.setProperties) && <DefaultButton
                    disabled={isMutating}
                    text={t("common:previousButton")}
                    onClick={() => previuous()}
                />}
                <PrimaryButton
                    text={t("common:nextButton")}
                    disabled={
                        (currentStep === CreateWorkflowStep.setProperties &&
                            (!firstPageData.name ||
                                firstPageData.activityKey === undefined)) ||
                        (currentStep === CreateWorkflowStep.addFile && secondPageData.file === undefined) || isMutating ||
                        (currentStep === CreateWorkflowStep.addSteps && checkStepValidation())
                    }
                    onClick={() => next()}
                />
            </div>
        );
    }, [isMutating, previuous, t, next, checkStepValidation, classNames.buttonsContainer, currentStep, firstPageData, secondPageData.file, classNames.requiredTextLeft]);

    const steps = [
        {
            title: t('setProperties.title'),
            body: <SetProperties initialState={firstPageData} onWorkflowDataChange={setFirstPageData} />,
            footer: footer
        },
        {
            title: t("addFile.title") + firstPageData.name,
            body: <AddFile activityId={firstPageData.activityId} initialState={secondPageData} onWorkflowDataChange={setSecondPageData} />,
            footer: footer
        },
        {
            title: t("addSteps.title") + firstPageData.name,
            body: <AddSteps fileType={secondPageData.isPdf} initialState={thirdPageData} onWorkflowDataChange={setThirdPageData} />,
            footer: footer
        },
        {
            title: t("summary.title"),
            body: <WorkflowDetails
                onReload={() => console.log("reload")}
                workFlow={{
                    id: 0,
                    activityId: firstPageData.activity?.id,
                    activityName: firstPageData.activity?.name,
                    name: firstPageData.name,
                    description: firstPageData.description,
                    type: WorkFlowTypes.Approval,
                    documentName: secondPageData.file?.name,
                    documentUrl: secondPageData.file?.url,
                    steps: thirdPageData.steps.slice(),
                    involvedPeople: [],
                }}
                isSummary
            />,
            footer: footer
        },
        {
            title: '',
            body: <CreatingWorkFlowPage onSuccess={props.onSuccess} onClose={props.onClose} request={request}></CreatingWorkFlowPage>,
            footer: <></>
        }
    ]

    return (
        <>
            <MultiStepModal
                steps={steps}
                activeStep={currentStep}
                animateInitialStep
                width={700}
                hideScrollbarBody
                height={700}
                onStepChange={setIsMutating}
                isOpen={true}
                onCloseClick={() => currentStep !== CreateWorkflowStep.setProperties ? setShowDialog(true) : props.onClose()}
                showCloseIcon={currentStep !== CreateWorkflowStep.startingWorkflow}
            />
            <Dialog
                hidden={!showDialog}
                dialogContentProps={{
                    title: t('checkDeleteWorflow.title'),
                    subText: t('checkDeleteWorflow.message'),
                }}
            >
                <DialogFooter>
                    <DefaultButton onClick={() => setShowDialog(false)} text={t('checkDeleteWorflow.undo')} />
                    <PrimaryButton onClick={props.onClose} text={t('checkDeleteWorflow.confirm')} />
                </DialogFooter>
            </Dialog>
        </>
    );
}