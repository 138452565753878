import { DefaultEffects, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IDropdownStyles, IScrollablePaneStyles, IStyleFunction } from "@fluentui/react"
import { IFolderPermissionModalPropsStyles, IFolderPermissionModalStyles } from "./folderPermissionModal.types"

export const FolderPermissionModalGlobalClassNames = {
    root: 'edi-folder-permission-modal-root',
    headerRow: 'edi-folder-permission-modal-header-row',
    title: 'edi-folder-permission-modal-title',
    loaderContainer: 'edi-folder-permission-modal-loader-container',
    lowerSection: 'edi-folder-permission-modal-lower-section',
    upperSection: 'edi-folder-permission-modal-upper-section',
}

export const getStyles: IStyleFunction<IFolderPermissionModalPropsStyles, IFolderPermissionModalStyles> = (props: IFolderPermissionModalPropsStyles): IFolderPermissionModalStyles => {
    const { semanticColors, palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            FolderPermissionModalGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px'
            }
        ],
        peoplePickerStyles:[
            {             
            flex: '1 1 0',           
            input: {
                backgroundColor: 'transparent',
            },
            text: {
                border: '1px solid rgb(240, 240, 240) !impotant',
                borderRadius: 2,
                backgroundColor: 'rgb(240, 240, 240)',
            },
            itemsWrapper: {
                ".ms-PickerPersona-container": {
                    background: 'rgb(245, 245, 245)',
                    border: "1px solid rgb(245, 245, 245)",
                    ':hover': {
                        background: 'rgba(98, 100, 167, 0.2)',
                        border: "1px solid rgba(98, 100, 167, 0.2)",
                    }
                },
            },
            root: 
            {
                float: 'right',
                marginRight: '0 !important',
            },
            screenReaderText: {}
            }
        ],
        upperSection: [
            FolderPermissionModalGlobalClassNames.upperSection,
            {   
                flexDirection: 'row',
            }
        ],
        lowerSection: [
            FolderPermissionModalGlobalClassNames.lowerSection,
            {
                position: 'relative'
            }
        ],
        peoplePickerSuggestions: {
            padding: '8px 0'
        },
        peoplePickerSuggestionItem: {
            '::after': {
                display: 'none'
            },
            ".is-suggested": {
                background: palette.neutralQuaternary
            }
        },
        loaderContainer: [
            FolderPermissionModalGlobalClassNames.loaderContainer,
            {
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        ],
        headerRow: [
            FolderPermissionModalGlobalClassNames.headerRow,
            {
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                }
            }
        ],
        subComponentStyles: {
            textFieldStyle: {
                root: {
                  paddingBottom: 20,
                  paddingRight: '120px',
                  flex: '1 1 0'

                },
                field: {
                  fontSize: 14,
                  color: palette.black,
                  boxShadow: DefaultEffects.elevation8,
                  '::placeholder': {
                    fontSize: 14
                  },
                },
                fieldGroup: {
                  height: 30,
                  background: palette.white + ' !important'
                },
                errorMessage: {
                  position: 'absolute'
                }
              },
            list: (): IDetailsListStyles => {
                return {
                    root: {},
                    contentWrapper: {
                    },
                    focusZone: {},
                    headerWrapper: {
                        '> .ms-DetailsHeader': {
                            paddingTop: 0,
                            borderBottomColor: 'transparent'
                        }
                    },
                }
            },
            row: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                const { palette } = props.theme;

                return {
                    root: {
                        backgroundColor: palette.white,
                        ':hover': {
                            backgroundColor: palette.white
                        }
                    },
                    cell: {},
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
            scrollablePane: (): IScrollablePaneStyles => {
                return {
                    root: {},
                    contentContainer: {
                        width: '100%',
                        paddingRight: 17,
                        overflowY: 'overlay'
                    },
                    stickyAbove: {},
                    stickyBelow: {},
                    stickyBelowItems: {}
                }
            },
            rolesDropdown: (): IDropdownStyles => {
                return {
                    root: {},
                    label: {},
                    dropdown: {
                        '::after': {
                            display: 'none'
                        }
                    },
                    title: {
                        backgroundColor: 'transparent'
                    },
                    caretDownWrapper: {},
                    caretDown: {},
                    errorMessage: {},
                    dropdownItemsWrapper: {},
                    dropdownItems: {},
                    dropdownItem: {},
                    dropdownItemSelected: {},
                    dropdownItemDisabled: {},
                    dropdownItemSelectedAndDisabled: {},
                    dropdownItemHidden: {},
                    dropdownOptionText: {},
                    dropdownDivider: {},
                    dropdownItemHeader: {},
                    panel: {},
                    callout: {},
                    subComponentStyles: {
                        label: {},
                        multiSelectItem: {},
                        panel: {}
                    }
                }
            },
            removeButton: {
                root: {
                    paddingRight: 10
                },
                icon: {
                    color: semanticColors.bodyText
                },
                rootHovered: {
                    background: 'transparent'
                },
                rootPressed: {
                    background: 'transparent'
                },
                rootDisabled: {
                    background: 'transparent'
                }
            }
        }
    };
}