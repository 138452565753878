import { styled } from "@fluentui/react";
import { MemberViewBase } from "./memberView.base";
import { getStyles } from "./memberView.styles";
import { IMemberViewBaseProps, IMemberViewBasePropsStyles, IMemberViewBaseStyles } from "./memberView.types";

const MemberView = styled<IMemberViewBaseProps, IMemberViewBasePropsStyles, IMemberViewBaseStyles>(
    MemberViewBase, 
    getStyles,
    undefined,
    { scope: 'MemberView'}
)

export default MemberView;