import { styled } from "@fluentui/react";
import { getStyles } from "./graphWorkflowStatusCustomFilterModal.styles";
import { IGraphWorkflowStatusCustomFilterModalPropsStyles, IGraphWorkflowStatusCustomFilterModalStyles, IGraphWorkflowStatusCustomFilterModalProps } from "./graphWorkflowStatusCustomFilterModal.types";
import { GraphWorkflowStatusCustomFilterModalBase } from "./graphWorkflowStatusCustomFilterModal.base";

const GraphWorkflowStatusCustomFilterModal = styled<IGraphWorkflowStatusCustomFilterModalProps, IGraphWorkflowStatusCustomFilterModalPropsStyles, IGraphWorkflowStatusCustomFilterModalStyles>(
    GraphWorkflowStatusCustomFilterModalBase,
    getStyles,
    undefined,
    { scope: 'GraphWorkflowStatusCustomFilterModal' }
);

export default GraphWorkflowStatusCustomFilterModal;