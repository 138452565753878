import { styled } from "@fluentui/react";
import { DocumentTranslationModalBase } from "./documentTranslationModal.base";
import { getStyles } from "./documentTranslationModal.styles";
import { IDocumentTranslationModalProps, IDocumentTranslationModalPropsStyles, IDocumentTranslationModalStyles } from "./documentTranslationModal.types";

const DocumentTranslation = styled<IDocumentTranslationModalProps, IDocumentTranslationModalPropsStyles, IDocumentTranslationModalStyles>(
    DocumentTranslationModalBase,
    getStyles,
    undefined,
    { scope: 'DocumentTranslation' }
);

export default DocumentTranslation;