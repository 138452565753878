/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState } from "react";
import { classNamesFunction, Icon, IconButton, keyframes, Label, Stack, TooltipHost } from "@fluentui/react";
import { IDashboardContainerPropsStyles, IDashboardContainerStyles, IDashboardContainerProps } from "./dashboardContainer.types";
// import { Stack, IStackStyles, IStackTokens, IStackProps } from '@fluentui/react/lib/Stack';

import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { Bar, Doughnut } from "react-chartjs-2";
import TagCloudChart from "../tagCloudChart/tagCloudChart";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import { capacity, fileFormat, keyphrase, language, formalAspect, location } from "../../../services/analytics/analytics.contracts";
import { analyticsApi } from "../../../services/analytics/analytics.api";
import ProgressBar from "../progressbar/progressBar";
import { useTranslation } from "react-i18next";
import FormalAspects from "../formalAspects/formalAspects";
import WorldMapChart from "../worldMapChart/worldMapChart";
import { ErrorsType } from "../../../models/callsApi";
import { setToolbarActions, ToolbarAction } from "../../../features/toolbarActions";
import { useDocLabDispatch } from "../../../docLabStore";
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { Helpers } from "../../../../utilities/helpers";
import { getArchiveContentActionIds, getArchiveContentActionsByIds, SelectionScopes } from "../../../utilities/archiveContentActions";
import { ToolbarDelegateType } from "../../../hooks/useActionDelegate";
import _ from "lodash";

const getClassNames = classNamesFunction<IDashboardContainerPropsStyles, IDashboardContainerStyles>();


export const DashboardContainerBase = (props: IDashboardContainerProps) => {
  // eslint-disable-next-line
  const { palette } = props.theme!;
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const [keyphrases, setKeyphrases] = useState<keyphrase[]>([]);
  const [errorKeyphrases, setErrorkeyphrases] = useState<string>("");
  const [fileformats, setFileformat] = useState<fileFormat[]>([]);
  const [errorFileformats, setErrorFileformats] = useState<string>("");
  const [languages, setLanguages] = useState<language[]>([]);
  const [errorLanguagess, setErrorLanguages] = useState<string>("");
  const [locations, setLocations] = useState<location[]>([]);
  const [errorLocations, setErrorLocations] = useState<string>("");
  const [usagestats, setUsagestats] = useState<capacity>();
  const [errorUsagestats, setErrorUsagestats] = useState<string>("");
  const [formalAspects, setFormalAspects] = useState<formalAspect[]>();
  const [errorFormalAspects, setErrorFormalAspects] = useState<string>("");
  const [refresh, setRefresh] = useState<boolean>(true);
  const [globalLoading, setGlobalLoading] = useState(true);
  const dispatch = useDocLabDispatch();
  const { t } = useTranslation(['dashboard', 'common', 'archiveContentActions']);

  const genericErrorApiTraslate = t('common:genericErrorApi');
  const genericEmptyGraph = t('empty');
  // eslint-disable-next-line
  const currentArchive = useCurrentArchive();
  useOnMount(() => {
    dispatch(setToolbarActions([]));
  });
  const retryToRefresh = () => {
    _.delay(() => setRefresh(true), 500);
  }
  useEffect(() => {
    if (currentArchive && refresh) {
      GetKeyphrases();
      Getfileformats();
      Getlanguages();
      Getlocations();
      Getusagestats();
      GetFormalAspects();
      setRefresh(false);
    }
    else if(refresh){
      retryToRefresh();
    }

  }, [refresh, currentArchive])//eslint-disable-line react-hooks/exhaustive-deps

  const { execute: GetKeyphrases, loading: loadingGetKeyphrases } = useAsyncApi<void, keyphrase[]>({
    func: async () => {
      if (!currentArchive)
        return {} as keyphrase[];
      try {
        const result = await analyticsApi.getkeyphrases(currentArchive.id);
        setKeyphrases(result);
        return result;
      }
      catch (er) {
        const error: ErrorsType = er as ErrorsType;
        if (error.code === 403) {
          setErrorkeyphrases(t("errors.errorCode403"));
        }
        else if (error.code === 422) {
          setErrorkeyphrases(t("errors.errorCode422"));
        } else {
          setErrorkeyphrases(genericErrorApiTraslate);
        }
        return {} as keyphrase[];
      }
    }
  });

  const { execute: Getfileformats, loading: loadingGetfileformats } = useAsyncApi<void, fileFormat[]>({//eslint-disable-line @typescript-eslint/no-unused-vars
    func: async () => {
      if (!currentArchive)
        return {} as fileFormat[];
      try {
        const result = await analyticsApi.getfileformats(currentArchive.id);
        setFileformat(result);
        return result;
      }
      catch (er) {
        const error: ErrorsType = er as ErrorsType;
        if (error.code === 403) {
          setErrorFileformats(t("errors.errorCode403"));
        }
        else if (error.code === 422) {
          setErrorFileformats(t("errors.errorCode422"));
        } else {
          setErrorFileformats(genericErrorApiTraslate);
        }
        return {} as fileFormat[];
      }
    }
  });

  const { execute: Getlanguages, loading: loadingGetlanguages } = useAsyncApi<void, language[]>({
    func: async () => {
      if (!currentArchive)
        return {} as language[];
      try {
        const result = await analyticsApi.getlanguages(currentArchive.id);
        setLanguages(result);
        return result;
      }
      catch (er) {
        const error: ErrorsType = er as ErrorsType;
        if (error.code === 403) {
          setErrorLanguages(t("errors.errorCode403"));
        }
        else if (error.code === 422) {
          setErrorLanguages(t("errors.errorCode422"));
        } else {
          setErrorLanguages(genericErrorApiTraslate);
        }
        return {} as language[];
      }
    }
  });

  const { execute: Getlocations, loading: loadingGetlocations } = useAsyncApi<void, location[]>({
    func: async () => {
      if (!currentArchive)
        return {} as location[];
      try {
        const result = await analyticsApi.getlocations(currentArchive.id);
        setLocations(result);
        return result;
      }
      catch (er) {
        const error: ErrorsType = er as ErrorsType;
        if (error.code === 403) {
          setErrorLocations(t("errors.errorCode403"));
        }
        else if (error.code === 422) {
          setErrorLocations(t("errors.errorCode422"));
        } else {
          setErrorLocations(genericErrorApiTraslate);
        }
        return {} as location[];
      }
    }
  });

  const { execute: Getusagestats, loading: loadingGetusagestats } = useAsyncApi<void, capacity>({
    func: async () => {
      if (!currentArchive)
        return {} as capacity;
      try {
        const result = await analyticsApi.getusagestats(currentArchive.id);
        setUsagestats(result);
        return result;
      }
      catch (er) {
        const error: ErrorsType = er as ErrorsType;
        if (error.code === 403) {
          setErrorUsagestats(t("errors.errorCode403"));
        }
        else if (error.code === 422) {
          setErrorUsagestats(t("errors.errorCode422"));
        } else {
          setErrorUsagestats(genericErrorApiTraslate);
        }
        return {} as capacity;
      }
    }
  });

  const { execute: GetFormalAspects, loading: loadingGetFormalAspects } = useAsyncApi<void, formalAspect[]>({
    func: async () => {
      if (!currentArchive)
        return {} as formalAspect[];
      try {
        const result = await analyticsApi.GetFormalAspects(currentArchive.id);
        setFormalAspects(result);
        return result;
      }
      catch (er) {
        const error: ErrorsType = er as ErrorsType;
        if (error.code === 403) {
          setErrorFormalAspects(t("errors.errorCode403"));
        }
        else if (error.code === 422) {
          setErrorFormalAspects(t("errors.errorCode422"));
        } else {
          setErrorFormalAspects(genericErrorApiTraslate);
        }
        return {} as formalAspect[];
      }
    }
  });


  useEffect(() => {
    if(!currentArchive?.metadataJsonSchema)
    return;
    const actionsIds = getArchiveContentActionIds(SelectionScopes.Dashboard, currentArchive);
    const actions = getArchiveContentActionsByIds(actionsIds);
    const toolbarActions: ToolbarAction[] = []
    actions.forEach(el => {
      toolbarActions.push({
        id: el.id,
        type: ToolbarDelegateType.files,
        icon: el.icon,
        label: t('archiveContentActions:' + el.label)
      })
    })
    dispatch(setToolbarActions(toolbarActions));
  }, [currentArchive, dispatch, t]);

  const languagesData = {
    labels: languages.map(t => t.language),
    datasets: [
      {
        data: languages.map(t => t.count),
        backgroundColor: [palette.themePrimary, palette.themeSecondary, palette.themeTertiary],
        borderColor: 'transparent'
      },
    ],
  };

  const languagesoptions = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: 90,
    plugins: {
      legend: {
        position: 'bottom',
      }
    }
  }

  const fileformatsData = {
    labels: fileformats && fileformats.map(t => t.fileFormat),
    datasets: [
      {
        data: fileformats.map(t => t.count),
        backgroundColor: [palette.themePrimary],
      },
    ],
  };

  const fileformatsOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        max: fileformats && Helpers.getMaxScaleForDashBoard(fileformats.map(o => o.count)),
        ticks: {
          beginAtZero: true
        }
      }
    }
  };

  useEffect(() => {
    if (!(loadingGetKeyphrases || loadingGetfileformats || loadingGetFormalAspects || loadingGetusagestats || loadingGetlocations || loadingGetlanguages || refresh))
      setGlobalLoading(false)
  }, [loadingGetKeyphrases, loadingGetfileformats, loadingGetFormalAspects, loadingGetusagestats, loadingGetlocations, loadingGetlanguages, refresh])

  return (
    <>
      {!currentArchive && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsImage imageName={ImageName.Oops1} /></div>}
      {currentArchive && <div>
        <div style={{ height: 50 }}>
          <div style={{ position: 'absolute', right: 0, margin: '10px 30px', fontSize: '18px', cursor: 'pointer' }}>
            <IconButton
              disabled={refresh}
              className={classNames.refreshButton}
              styles={classNames.subComponentStyles.iconButton()}
              style={refresh ? { animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite` } : {}}
              iconProps={{ iconName: "Refresh" }}
              onClick={() => { setRefresh(true); setGlobalLoading(true) }}
            />
          </div>
          <div className={classNames.filecount}>
            {usagestats && usagestats.fileCount > 1 && <>{t('fileCount.description')} {usagestats?.fileCount} {t('fileCount.descriptionEnd')}</>}
            {usagestats && usagestats.fileCount === 0 && <>{t('fileCountNull.description')}</>}
            {usagestats && usagestats.fileCount === 1 && <>{t('fileCountOne.description')} {usagestats?.fileCount} {t('fileCountOne.descriptionEnd')}</>}
          </div>
        </div>

        <Stack className={classNames.root} tokens={{ childrenGap: 20, padding: 15 }}>

          <Stack>
            <Stack.Item style={{ flexBasis: '100%' }} className={classNames.stackItem}>
              <div className={classNames.title}>{t('tagCloudChart.title')}</div>
              <div style={{ height: 400 }}>
                {globalLoading && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
                {!errorKeyphrases && !globalLoading && keyphrases.length !== 0 && <TagCloudChart items={keyphrases} />}
                {!errorKeyphrases && !globalLoading && keyphrases.length === 0 && <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
                {errorKeyphrases && !globalLoading && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorKeyphrases}</Label>}
              </div>
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 20, padding: 0 }}>
            <Stack.Item align="stretch" style={{ width: '70%' }} className={classNames.stackItem}>
              <div style={{ height: 550, width: '100%' }}>
                <div className={classNames.title}>{t('worldMapChart.title')}</div>
                <div className={classNames.flexCenter} style={{ height: 490 }} >
                  {globalLoading && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
                  {!errorLocations && !globalLoading && locations.length !== 0 && <WorldMapChart data={locations} maxLocationCount={Math.max(...locations.map((x) => x.count), 0)} />}
                  {!errorLocations && !globalLoading && locations.length === 0 && <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
                  {errorLocations && !globalLoading && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorLocations}</Label>}
                </div>
              </div>
            </Stack.Item>

            <Stack.Item grow={1} align="stretch" style={{ width: '30%' }} className={classNames.stackItem}>
              <div style={{ height: 550 }}>
                <div className={classNames.title}>{t('fileformats.title')}</div>
                <div style={{ height: 490 }}>
                  {globalLoading && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
                  {!errorFileformats && !globalLoading && fileformats.length !== 0 && <Bar data={fileformatsData} options={fileformatsOptions} />}
                  {!errorFileformats && !globalLoading && fileformats.length === 0 && <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
                  {errorFileformats && !globalLoading && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorFileformats}</Label>}
                </div>
              </div>
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 20, padding: 0 }}>
            <Stack.Item align="stretch" style={{ width: '70%' }} className={classNames.stackItem}>
              <div style={{ height: 400 }}>
                <div className={classNames.title}>{t('formalAspects.title')}</div>
                <div style={{ height: 300 }}>
                  {globalLoading && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
                  {!errorFormalAspects && !globalLoading &&
                    <FormalAspects
                      wordCount={formalAspects?.filter(x => x.formalAspect === 0)}
                      tableCount={formalAspects?.filter(x => x.formalAspect === 1)}
                      pageCount={formalAspects?.filter(x => x.formalAspect === 2)}
                      paragraphCount={formalAspects?.filter(x => x.formalAspect === 3)}
                      imageCount={formalAspects?.filter(x => x.formalAspect === 4)}
                    />}
                  {errorFormalAspects && !globalLoading && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorFormalAspects}</Label>}
                </div>
              </div>
            </Stack.Item>

            <Stack.Item align="stretch" style={{ width: '30%' }} className={classNames.stackItem}>
              <div style={{ height: 400 }}>
                <div className={classNames.title}>{t('languages.title')}
                  {!errorLanguagess && !globalLoading && usagestats?.fileCount !== languages.map(t => t.count).reduce((sum, current) => sum + current, 0) &&
                    <TooltipHost content={t('languages.countMessage')}>
                      <Icon className={classNames.iconStyle} iconName={'info'} />
                    </TooltipHost>}
                </div>
                <div style={{ height: 300 }}>
                  {globalLoading && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
                  {!errorLanguagess && !globalLoading && languages.length !== 0 && <Doughnut data={languagesData} options={languagesoptions} />}
                  {!errorLanguagess && !globalLoading && languages.length === 0 && <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
                  {errorLanguagess && !globalLoading && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorLanguagess}</Label>}
                </div>
              </div>
            </Stack.Item>
          </Stack>

          <Stack>
            <Stack.Item style={{ flexBasis: '100%' }} className={classNames.stackItem}>
              <div className={classNames.title}>{t('progressBar.title')}</div>
              <div style={{ height: 50, margin: '15px 15px' }}>
                {globalLoading && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
                {!errorUsagestats && !globalLoading && usagestats && <ProgressBar data={usagestats} />}
                {!errorUsagestats && !globalLoading && !usagestats && <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
                {errorUsagestats && !globalLoading && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorUsagestats}</Label>}
              </div>
            </Stack.Item>
          </Stack>

        </Stack>
      </div>
      }
    </>
  );

  // return (
  //   <>
  //     {!currentArchive && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsImage imageName={ImageName.Oops1} /></div>}
  //     {currentArchive && <div>
  //       <div style={{ height: 50 }}>
  //         <div style={{ position: 'absolute', right: 0, margin: '10px 30px', fontSize: '18px', cursor: 'pointer' }}>
  //           <IconButton
  //             disabled={refresh}
  //             className={classNames.refreshButton}
  //             styles={classNames.subComponentStyles.iconButton()}
  //             style={refresh ? { animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite` } : {}}
  //             iconProps={{ iconName: "Refresh" }}
  //             onClick={() => { setRefresh(true) }}
  //           />
  //         </div>
  //         <div className={classNames.filecount}>
  //           {usagestats && usagestats.fileCount > 1 && <>{t('fileCount.description')} {usagestats?.fileCount} {t('fileCount.descriptionEnd')}</>}
  //           {usagestats && usagestats.fileCount === 0 && <>{t('fileCountNull.description')}</>}
  //           {usagestats && usagestats.fileCount === 1 && <>{t('fileCountOne.description')} {usagestats?.fileCount} {t('fileCountOne.descriptionEnd')}</>}
  //         </div>
  //       </div>

  //       <Stack className={classNames.root} tokens={{ childrenGap: 20, padding: 15 }}>

  //         <Stack>
  //           <Stack.Item style={{ flexBasis: '100%'}} className={classNames.stackItem}>
  //             <div className={classNames.title}>{t('tagCloudChart.title')}</div>
  //             <div style={{ height: 400 }}>
  //               {loadingGetKeyphrases && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
  //               {!errorKeyphrases && !loadingGetKeyphrases && keyphrases.length !== 0 && <TagCloudChart items={keyphrases} />}
  //               {!errorKeyphrases && !loadingGetKeyphrases && keyphrases.length === 0 && <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
  //               {errorKeyphrases && !loadingGetKeyphrases && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorKeyphrases}</Label>}
  //             </div>
  //           </Stack.Item>
  //         </Stack>

  //         <Stack horizontal tokens={{ childrenGap: 20, padding: 0 }}>
  //         <Stack.Item align="stretch" style={{ width: '70%'}} className={classNames.stackItem}>
  //           <div style={{height: 550, width: '100%'}}>
  //             <div className={classNames.title}>{t('worldMapChart.title')}</div>
  //             <div className={classNames.flexCenter} style={{height: 490}} >
  //               {loadingGetlocations && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
  //               {!errorLocations && !loadingGetlocations && locations.length !== 0 && <WorldMapChart data={locations} maxLocationCount={Math.max(...locations.map((x) => x.count), 0)}/>}
  //               {!errorLocations && !loadingGetlocations && locations.length === 0 && <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
  //               {errorLocations && !loadingGetlocations && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorLocations}</Label>}
  //             </div>
  //           </div>
  //         </Stack.Item>

  //      <Stack.Item grow={1} align="stretch" style={{ width: '30%'}} className={classNames.stackItem}>
  //           <div style={{ height: 550 }}>
  //             <div className={classNames.title}>{t('fileformats.title')}</div>
  //             <div style={{ height: 490 }}>
  //               {loadingGetfileformats && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
  //               {!errorFileformats && !loadingGetfileformats && fileformats.length !== 0 && <Bar data={fileformatsData} options={fileformatsOptions} />}
  //               {!errorFileformats && !loadingGetfileformats && fileformats.length === 0 && <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
  //               {errorFileformats && !loadingGetfileformats && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorFileformats}</Label>}
  //             </div>
  //           </div>
  //         </Stack.Item>
  //       </Stack>

  //         <Stack horizontal tokens={{ childrenGap: 20, padding: 0 }}>
  //           <Stack.Item align="stretch" style={{ width: '70%'}} className={classNames.stackItem}>
  //             <div style={{ height: 400 }}>
  //               <div className={classNames.title}>{t('formalAspects.title')}</div>
  //               <div style={{ height: 300 }}>
  //                 {loadingGetFormalAspects && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
  //                 {!errorFormalAspects && !loadingGetFormalAspects &&
  //                   <FormalAspects
  //                     wordCount={formalAspects?.filter(x => x.formalAspect === 0)}
  //                     tableCount={formalAspects?.filter(x => x.formalAspect === 1)}
  //                     pageCount={formalAspects?.filter(x => x.formalAspect === 2)}
  //                     paragraphCount={formalAspects?.filter(x => x.formalAspect === 3)}
  //                     imageCount={formalAspects?.filter(x => x.formalAspect === 4)}
  //                   />}
  //                 {errorFormalAspects && !loadingGetFormalAspects && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorFormalAspects}</Label>}
  //               </div>
  //             </div>
  //           </Stack.Item>

  //           <Stack.Item align="stretch" style={{ width: '30%'}} className={classNames.stackItem}>
  //             <div style={{ height: 400 }}>
  //               <div className={classNames.title}>{t('languages.title')}
  //                 {!errorLanguagess && !loadingGetlanguages && usagestats?.fileCount !== languages.map(t => t.count).reduce((sum, current) => sum + current, 0) &&
  //                   <TooltipHost content={t('languages.countMessage')}>
  //                     <Icon className={classNames.iconStyle} iconName={'info'} />
  //                   </TooltipHost>}
  //               </div>
  //               <div style={{ height: 300 }}>
  //                 {loadingGetlanguages && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
  //                 {!errorLanguagess && !loadingGetlanguages && languages.length !== 0 && <Doughnut data={languagesData} options={languagesoptions} />}
  //                 {!errorLanguagess && !loadingGetlanguages && languages.length === 0 && <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
  //                 {errorLanguagess && !loadingGetlanguages && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorLanguagess}</Label>}
  //               </div>
  //             </div>
  //           </Stack.Item>
  //         </Stack>

  //         <Stack>
  //           <Stack.Item style={{ flexBasis: '100%'}} className={classNames.stackItem}>
  //             <div className={classNames.title}>{t('progressBar.title')}</div>
  //             <div style={{ height: 50, margin: '15px 15px' }}>
  //               {loadingGetusagestats && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
  //               {!errorUsagestats && !loadingGetusagestats && usagestats && <ProgressBar data={usagestats} />}
  //               {!errorUsagestats && !loadingGetusagestats && !usagestats && <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
  //               {errorUsagestats && !loadingGetusagestats && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorUsagestats}</Label>}
  //             </div>
  //           </Stack.Item>
  //         </Stack>

  //       </Stack>
  //     </div>
  //     }
  //   </>
  // );
}