import { DetailsRowGlobalClassNames, IStyleFunction } from "@fluentui/react"
import { IFileNameCellPropsStyles, IFileNameCellStyles } from "./fileNameCell.types"

export const FileNameCellGlobalClassNames = {
    menuIconWrapper: 'edi-fileNameCell-menuIconWrapper',
    textWrapper: 'edi-fileNameCell-textWrapper',
    text: 'edi-fileNameCell-text',
    newItemIcon: 'edi-fileNameCell-newItemIcon'
}

export const getStyles: IStyleFunction<IFileNameCellPropsStyles, IFileNameCellStyles> = (props: IFileNameCellPropsStyles): IFileNameCellStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        menuIconWrapper: [
            FileNameCellGlobalClassNames.menuIconWrapper,
            {
                position: 'absolute',
                top: 0,
                right: 0,
                height: '100%',
                color: 'inherit',
                visibility: 'hidden',
                [`.${DetailsRowGlobalClassNames.root}:hover &`]: {
                    visibility: 'visible'
                }
            }
        ],
        textWrapper: [
            FileNameCellGlobalClassNames.textWrapper,
            {
                position: 'relative',
                paddingRight: 20,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        ],
        text: [
            FileNameCellGlobalClassNames.text,
            {
                cursor: 'pointer',
                ':hover': {
                    textDecoration: 'underline'
                }
            }
        ],
        newItemIcon: [
            FileNameCellGlobalClassNames.newItemIcon,
            {
                position: 'absolute',
                top: 7,
                left: 4,
                fontSize: 9,
                color: palette.themePrimary
            }
        ],
        subComponentStyles: {
            iconButton: {
                root: {
                    height: '100%'
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`
                },
                menuIcon: {
                    display: 'none !important'
                },
                rootExpanded: {
                    backgroundColor: 'inherit'
                },
                rootPressed: {
                    backgroundColor: 'inherit'
                },
                rootHovered: {
                    backgroundColor: 'inherit',
                }
            },
            disabledContextualMenuOptions: {
                title: {},
                container: {},
                root: {
                    backgroundColor: palette.neutralQuaternary, // 'rgb(237, 235, 233)',
                    color: palette.neutralTertiary, //'rgb(200, 198, 196)',
                    pointerEvents: 'none'
                },
                header: {},
                list: {}
            }
        }
    };
}