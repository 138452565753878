import React from "react";
import { ITagCloudChartProps } from "./tagCloudChart.types";
import ReactWordcloud, { Optional, Options } from "react-wordcloud";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

export const TagCloudChartBase = (props: ITagCloudChartProps) => {
    const { palette } = props.theme!;//eslint-disable-line @typescript-eslint/no-non-null-assertion

    const wordOptions: Optional<Options> = {
        // colors: ["#464EB8", "#7B83EB", "#505AC9"],
        colors: [palette.themePrimary, palette.themeSecondary, palette.themeTertiary],
        enableTooltip: true,
        deterministic: false,
        fontFamily: "impact",
        fontSizes: [35, 60],
        fontStyle: "normal",
        fontWeight: "normal",
        padding: 1,
        rotations: 3,
        rotationAngles: [0, 0],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 500
    };

    return (
        <>
            <ReactWordcloud words={props.items.map(i => { return { text: i.keyphrase, value: i.count } })} options={wordOptions} />
        </>
    );
}