import React, { useState } from "react";
import { classNamesFunction, DefaultButton, PrimaryButton, Spinner, SpinnerSize, Stack, StackItem } from "@fluentui/react";
import { IExportModalManagementReportBaseProps, IExportModalManagementReportBasePropsStyles, IExportModalManagementReportBaseStyles } from "./ExportModalManagementReport.types"
import MultiStepModal from "../../../../../common/components/multiStepModal/multiStepModal";
import { IModalStepData } from "../../../../../common/components/multiStepModal/multiStepModal.types";
import { useTranslation } from "react-i18next";
import { setArchiveExportScadenzaShow } from "../../../../features/archiveContent";
import { useDocLabDispatch, useDocLabState } from "../../../../docLabStore";
import { CreateReportMetadataRequest } from "../../../../services/archives/archives.contracts";
import { archivesApi } from "../../../../services/archives/archives.api";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ReportType } from "../../../../models/archive";

const  getClassNames = classNamesFunction<IExportModalManagementReportBasePropsStyles, IExportModalManagementReportBaseStyles>();

export enum ExportScadenzaStep {
    choice = 0,
    finalStep = 1,
}

export const ExportModalManagementReportBase = (props: IExportModalManagementReportBaseProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [currentStep, setCurrentStep] = useState<ExportScadenzaStep>(ExportScadenzaStep.choice);
    const [loading, setLoading] = useState<boolean>(false);
    const [labelError, setLabelError] = useState<boolean>(false);
    const { t } = useTranslation(['exportArchiveModal', 'common']);
    const dispatch = useDocLabDispatch();
    const docLabState = useDocLabState();

    const choiceContent = () => {
        return <>
            <div>
                <span>{t('createScadenzaReport.text')}</span>
            </div>
        </>        
    } 

    const choiceFooter= () => {
        return <Stack className={classNames.buttonContainer}>
            <div style={ !labelError ? {visibility: 'hidden'} : {color: 'red'}}>{t('operationFailed')}</div>
            <StackItem className={classNames.buttonFooter}>
                <DefaultButton style={{marginRight: '5px'}} onClick={() => {                    
                    dispatch(setArchiveExportScadenzaShow({reportId: 0, show: false, reportType: undefined}));
                }}>{t('common:previousButton')}</DefaultButton>
                <PrimaryButton
                    onClick={async () => {
                        await createExportMetadata();
                        // setCurrentStep(ExportScadenzaStep.finalStep);
                    }}
                >{t('common:nextButton')}</PrimaryButton>
            </StackItem>
        </Stack>
    }

    const createExportMetadata = async () => {
        setLoading(true);
        const request: CreateReportMetadataRequest = {
            archiveReportId: docLabState.archiveContent.selectedReportIdExportShown,
            type: docLabState.archiveContent.selectedReportType ?? ReportType.Metadata,
            additionalInput: null
        }
        try{
            await archivesApi.createReportMetadata(request);
            setLoading(false);
            setCurrentStep(ExportScadenzaStep.finalStep);
        }
        catch{
            setLabelError(true)
            setLoading(false);
            setCurrentStep(ExportScadenzaStep.choice);
        }        
    }
    

    const exportingContent = () => {
        return  <div className={classNames.secondFooterContainer}>
                {loading ?
                    <div className={classNames.centerContentContainer}><Spinner size={SpinnerSize.large} /></div> :
                        <>
                            <TeamsImage imageName={ImageName.Sandglass} className={classNames.sandglassImage} fullContainer scale={0.8} />
                            <div className={classNames.exportingFooter}>
                                {t('operationInProgress')}
                                <div>
                                    <PrimaryButton text={t('common:leave')}
                                        allowDisabledFocus
                                        onClick={() => dispatch(setArchiveExportScadenzaShow({reportId: 0, show: false}))}
                                        styles={{ root: { padding: '0px 80px', marginTop: '20px' } }}
                                    />
                                </div>
                            </div>
                        </>
                }
            </div>
    }

    const steps: IModalStepData[] = [
        {
            title: t('createScadenzaReport.title'),
            body: choiceContent(),
            footer: choiceFooter()
        },
        {
            bodyOnly: true,
            body: exportingContent()
        }
    ]

    return (
        <>
            <Stack>
                <MultiStepModal
                    isOpen={true}
                    width={'500px'}
                    height={'auto'}
                    showCloseIcon={true}
                    onCloseClick={props.onClose}
                    activeStep={currentStep}
                    steps={steps}
                    animateInitialStep
                />
            </Stack>                  
        </>
    )
}