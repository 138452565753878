import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import downloadStatus from '../docLab/features/downloadStatus';
import knowledgeLabContent from './features/knowledgeLabContent';
import knowledgeLabList from './features/knowledgeLabList';
import searchContent from './features/searchContent';
import toolbarActions from './features/toolbarActions';
const knowledgeLabStore = configureStore({
    reducer: {
        knowledgeLabList: knowledgeLabList,
        searchContent: searchContent,
        downloadStatus: downloadStatus,
        knowledgeLabContent: knowledgeLabContent,
        toolbarActions: toolbarActions,
    }
});
export type KnowledgeLabState = ReturnType<typeof knowledgeLabStore.getState>;
export type KnowledgeLabDispatch = typeof knowledgeLabStore.dispatch;
export default knowledgeLabStore;
export const useKnowledgeLabState = (): KnowledgeLabState => knowledgeLabStore.getState();
export const useKnowledgeLabDispatch = (): KnowledgeLabDispatch => useDispatch<KnowledgeLabDispatch>();
export const useKnowledgeLabSelector: TypedUseSelectorHook<KnowledgeLabState> = useSelector;