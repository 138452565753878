/*eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from "react";
import { classNamesFunction, Icon } from "@fluentui/react";
import { ICarouselPropsStyles, ICarouselStyles, ICarouselProps, StepMutation } from "./carousel.types";
import { noop } from "lodash";
import { AnalysisTypeId } from "../../../docLab/models/docAnalysis";

const getClassname = classNamesFunction<ICarouselPropsStyles, ICarouselStyles>();
export type carouselItem = {
    type: AnalysisTypeId,
    title: string,
    description: string,
    iconName?: string;
    enabled?: boolean;
}

export enum pageAction {
    back = 0,
    next = 1
}

export const CarouselBase = (props: ICarouselProps) => {
    const [currentPage, setCurrentPage] = useState(props.activeStep);
    const [stepMutation, setStepMutation] = useState(props.animateInitialStep ? StepMutation.fadeOnLoad : StepMutation.none);
    const mergedClassname = getClassname(props.styles, { theme: props.theme, className: props.className, stepMutation: stepMutation });

    useEffect(() => {
        if (props.activeStep === currentPage){
            return;
        }
        const callbacks = { onStepChange: noop, onStepEnter: noop, onStepExit: noop, ...props };

        let startAnimation = StepMutation.exitPrevious, endAnimation = StepMutation.enterNext;
        if (props.activeStep < currentPage) {
            startAnimation = StepMutation.exitNext;
            endAnimation = StepMutation.enterPrevious;
        }
        callbacks.onStepChange(true);
        callbacks.onStepExit(true);

        setStepMutation(startAnimation);
        setTimeout(() => {
            setCurrentPage(props.activeStep);
            setStepMutation(endAnimation);
            callbacks.onStepExit(false);
            callbacks.onStepEnter(true);
        }, 200);

        setTimeout(() => {
            setStepMutation(StepMutation.none);
            callbacks.onStepEnter(false);
            callbacks.onStepChange(false);
        }, 400);
    }, [currentPage, props, props.activeStep]);

    const changePage = (ev: React.MouseEvent<HTMLElement, MouseEvent>, action: pageAction) => {
        if ((currentPage === 0 && action === pageAction.back) || (props.pageCount === currentPage && action === pageAction.next) )
            return;
        ev.preventDefault();
        ev.stopPropagation();
        if (action === pageAction.back && props.back) {
            props.back();
        }
        else
            props.next && props.next();
    }

    const clickItemCarousel = (item: carouselItem) =>{
        if(!item.enabled)
            return;
        props.onClickItem(item);
    }
    return (
        <div className={mergedClassname.root}>
          
           <div className={mergedClassname.arrowContainer}>
                <Icon  onClick={(ev) =>{changePage(ev, pageAction.back)}} 
                className={currentPage !== 0 ? mergedClassname.arrowIcon : mergedClassname.disabledIcon} iconName="ChevronLeft" />
            </div>

            <div className={mergedClassname.animation}>
                {props.items.map((item, index) =>
                    <div onClick={()=>clickItemCarousel(item)} key={index} className={item.enabled ? mergedClassname.cardContainer : mergedClassname.disabledCardContainer}>
                        <div className={mergedClassname.cardTitleContainer}>
                            <span className={mergedClassname.cardTitle}>{item.title}</span>
                        </div>
                        <div className={mergedClassname.cardIconContainer}>
                            <Icon iconName={item.iconName} className={mergedClassname.cardIcon} />
                        </div>
                        <div className={mergedClassname.cardDescription}>{item.description}</div>
                    </div>
                )}
            </div>
     
            <div className={mergedClassname.arrowContainer}>
                <Icon onClick={(ev) => {changePage(ev, pageAction.next)}} 
                className={props.pageCount !== currentPage  ? mergedClassname.arrowIcon : mergedClassname.disabledIcon} iconName="ChevronRight" />
            </div>
            
        </div>

    );
}