import { styled } from "@fluentui/react";
import { getStyles } from "./exportMetadataModal.styles";
import { IExportArchiveModalPropsStyles, IExportArchiveModalStyles, IExportArchiveModalProps } from "./exportMetadataModal.types";
import { ExportMetadataModalBase } from "./exportMetadataModal.base";

const ExportMetadataModal = styled<IExportArchiveModalProps, IExportArchiveModalPropsStyles, IExportArchiveModalStyles>(
    ExportMetadataModalBase,
    getStyles,
    undefined,
    { scope: 'ExportMetadataModal' }
);

export default ExportMetadataModal;