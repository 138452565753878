import { IStyleFunction } from "@fluentui/react"
import { ICopyActionCommandPropsStyles, ICopyActionCommandStyles } from "./copyActionCommand.types"

export const CopyActionCommandGlobalClassNames = {
    root: 'edi-copy-action-command-root'
}

export const getStyles: IStyleFunction<ICopyActionCommandPropsStyles, ICopyActionCommandStyles> = (props: ICopyActionCommandPropsStyles): ICopyActionCommandStyles => {
    return {
        root: [
            CopyActionCommandGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}