import { styled } from "@fluentui/react";
import { getStyles } from "./search.styles";
import { ISearchPropsStyles, ISearchStyles, ISearchProps } from "./search.types";
import { SearchBase } from "./search.base";

const Search = styled<ISearchProps, ISearchPropsStyles, ISearchStyles>(
    SearchBase,
    getStyles,
    undefined,
    { scope: 'SearchBox' }
);

export default Search;