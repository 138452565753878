import { styled } from "@fluentui/react";
import { ExportModalManagementReportBase } from "./ExportModalManagementReport.base";
import { getStyles } from "./ExportModalManagementReport.styles";
import { IExportModalManagementReportBaseProps, IExportModalManagementReportBasePropsStyles, IExportModalManagementReportBaseStyles } from "./ExportModalManagementReport.types";

const ExportModalManagementReport = styled<IExportModalManagementReportBaseProps, IExportModalManagementReportBasePropsStyles, IExportModalManagementReportBaseStyles>(
    ExportModalManagementReportBase,
    getStyles,
    undefined,
    { scope: 'ExportModalManagementReport'}
)

export default ExportModalManagementReport;