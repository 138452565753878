import { styled } from "@fluentui/react";
import { getStyles } from "./accordionAnalysis.styles";
import { AccordionAnalysisBase } from "./accordionAnalysis.base";
import { IAccordionAnalysisProps, IAccordionAnalysisPropsStyles, IAccordionAnalysisStyles } from "./accordionAnalysis.types";

const AccordionAnalysis = styled<IAccordionAnalysisProps, IAccordionAnalysisPropsStyles, IAccordionAnalysisStyles>(
    AccordionAnalysisBase,
    getStyles,
    undefined,
    { scope: 'AccordionAnalysis' }
);

export default AccordionAnalysis;