import React from "react";
import Mark from "./Mark";
import { MarkedSpan } from "../types/annotate-types";

export interface SplitProps<T> {
  key: string;
  content: string;
  start: number;
  end: number;
  tag?: string;
  search?: string;
  color?: string;
  onClick: (span: T) => void;
  className?: string;
  mark?: boolean; 
  selectedElement:number;
  startCounter: number;
  
}

function replaceSpecialCharacters(searchString: string | undefined) {
  if (searchString === undefined || searchString === null)
    return searchString

  return searchString.replaceAll(
    /[.+*?[\](){}|^$\\]/g,
    (x: string) => `\\${x}`
  );
}

const marker = <T extends MarkedSpan>(props: SplitProps<T>, counter : number) =>{  
    
    const regex = new RegExp(replaceSpecialCharacters(props.search)||"","gmi");  
    const len = props.search?.length ?? 0;
    const index = props.content.search(regex);
    if (index === -1 || props.search === "" || props.search === undefined){     
      //console.log("terminato con "+counter)
      //props.onCounter(counter);
      return  <span data-start={props.start} data-end={props.end}>{props.content}</span>
    }
    else
    {
      counter++;   
      const idMatch = "idxMatch"+counter;
      let tmp  = <><span data-start={props.start} data-end={props.start+index}>{props.content.substring(0, index)}</span>
      <mark
      style={{
        backgroundColor: props.selectedElement === counter ? '#FF7F50' : '#FFFF66'
      }}
      data-start={props.start + index}
      data-end={props.start + index + len}
      id={idMatch}
    >
      {props.content.substring(index, index+len)}
    </mark>

      </>;
      tmp =  <>{tmp}{marker( {...props, start: props.start+index+len, end: props.end, content: props.content.substring(index+len)}, counter )}</>;
     return tmp;
    }
  
}


const SplitTag = <T extends MarkedSpan>(props: SplitProps<T>) => {
  
  if (props.mark) 
    return <Mark {...props} startCounter={props.startCounter} />;
  
  return (
    <span data-start={props.start} data-end={props.end}>
      {marker(props, props.startCounter)}
    </span>
  );
};

export default SplitTag;
