import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDocLabSelector } from '../docLabStore';
import { ToolbarDelegateType } from '../hooks/useActionDelegate';

export type ToolbarAction = {
    id: string;
    type: ToolbarDelegateType;
    label?: string;
    icon?: string;
    subActions?: ToolbarAction[];
}

export type ToolbarActionState = {
    actions: ToolbarAction[];
}

const initialState: ToolbarActionState = {
    actions: []
}

const ToolbarActionsSlice = createSlice({
    name: 'docLab-toolbar-actions',
    initialState,
    reducers: {
        setToolbarActions: (state: ToolbarActionState, action: PayloadAction<ToolbarAction[]>) => {
            state.actions = action.payload;
        }
    }
})

export default ToolbarActionsSlice.reducer;
export const { setToolbarActions } = ToolbarActionsSlice.actions;
export const useToolbarActions = () => useDocLabSelector(state => state.toolbarActions);