import { styled } from "@fluentui/react";
import { SearchItemHeaderBase } from "./searchItemHeader.base";
import { getStyles } from "./searchItemHeader.styles";
import { ISearchItemHeaderProps, ISearchItemHeaderPropsStyles, ISearchItemHeaderStyles } from "./searchItemHeader.types";

const SearchItemHeader = styled<ISearchItemHeaderProps, ISearchItemHeaderPropsStyles, ISearchItemHeaderStyles>(
    SearchItemHeaderBase,
    getStyles,
    undefined,
    { scope: 'SearchItemHeader' }
);

export default SearchItemHeader;