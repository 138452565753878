import { IStyleFunction } from "@fluentui/react"
import { IFileImporterOverviewPropsStyles, IFileImporterOverviewStyles } from "./fileImporterOverview.types"

export const FileImporterOverviewGlobalClassNames = {
    root: 'edi-file-importer-overview-root',
    subTitle: 'edi-file-importer-overview-subTitle',
    text: 'edi-file-importer-overview-text',
    iconContainer: 'edi-file-importer-overview-iconContainer',
    dropzoneBorders: 'edi-file-importer-overview-dropzoneBorders'
}

export const getStyles: IStyleFunction<IFileImporterOverviewPropsStyles, IFileImporterOverviewStyles> = (props: IFileImporterOverviewPropsStyles): IFileImporterOverviewStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            FileImporterOverviewGlobalClassNames.root,
            {
                height: '100%'
                // CSS PROPS AND SELECTORS
            }
        ],
        subTitle: [
            FileImporterOverviewGlobalClassNames.subTitle,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        text: [
            FileImporterOverviewGlobalClassNames.text,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        iconContainer: [
            FileImporterOverviewGlobalClassNames.iconContainer,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        dropzoneBorders: [
            FileImporterOverviewGlobalClassNames.dropzoneBorders,
            {
                border: '2px dashed ' + palette.neutralSecondary,
                borderRadius: '25px',
                width: '100%',
                padding: 20,
                height: '100%',
                boxSizing: 'border-box',
                minHeight: '200px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }
        ],
        dropzoneContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            color: palette.neutralSecondary,
            '> :not(:first-child)': {
                marginTop: 5
            }
        }
    };
}