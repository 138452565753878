import { Dropdown, IDropdownOption, IDropdownStyleProps, IDropdownStyles, IStyleFunctionOrObject } from "@fluentui/react";
import React, { useMemo } from "react";
import { useAsyncApi } from "../../../../../utilities/hooks";
import { PortfolioRoleId } from "../../../../models/constants";
import { User } from "../../../../models/user";
import { portfolioApi } from "../../../../services/portfolio/portfolio.api";
import currentUser from "../../../../../modules/authentication/currentUser";


export interface IMemberRoleDropdownProps {
    portfolioId: number;
    member: User;
    totalCount: number;
    dropdownStyles: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
    onRoleChangeCompleted: () => void;
}

export const AvailableRoles: IDropdownOption[] = [
    { key: PortfolioRoleId.Owner, text: "Owner" },
    { key: PortfolioRoleId.Viewer, text: "Viewer", selected: true }
]

export const MemberRoleDropdown = (props: IMemberRoleDropdownProps) => {
    const { member, dropdownStyles, portfolioId, onRoleChangeCompleted } = props;
    const disabled = useMemo(() => member.id === currentUser.currentUserId, [member.id]);

    const updateMemberRole = useAsyncApi<{ member: User, newRoledId: PortfolioRoleId }, void>({
        func: async (request) => {
            await portfolioApi.updatePortfolioMemberRole(portfolioId, request.member.id, request.newRoledId);
        }
    });

    return (
        <Dropdown
            disabled={disabled || updateMemberRole.loading}
            styles={dropdownStyles}
            defaultSelectedKey={member.roleId}
            options={AvailableRoles}
            onChange={async (_, option) => {
                if (!option)
                    return;

                await updateMemberRole.execute({ member, newRoledId: (option.key as PortfolioRoleId) });

                if (!updateMemberRole.error)
                    onRoleChangeCompleted();
            }}
        />);
}