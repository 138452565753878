import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { Helpers } from "../../utilities/helpers";
import { useDocLabSelector } from "../docLabStore";
import { Archive } from "../models/archive";
import { ArchiveTypeId } from "../models/constants";
import { archivesApi } from "../services/archives/archives.api";

export type ArchiveListState = {
  archives: Record<number, Archive>;
  archiveIds: number[];
  isLoading: boolean;
  isError: boolean;
};

const initialState: ArchiveListState = {
  archives: {},
  archiveIds: [],
  isLoading: false,
  isError: false,
};

export const loadArchives = createAsyncThunk(
  "archives/load-archives",
  async (archiveId?: number) =>  {
    const archives = await archivesApi.getArchives([ArchiveTypeId.Personal, ArchiveTypeId.Unit], undefined);
    let archive: Archive | undefined;
    if(archiveId)
      archive = await archivesApi.getArchive(archiveId);

    return { archives: archives, archive };
  }
);

export const loadArchive = createAsyncThunk("archives/load-archive", async (archiveId: number) => await archivesApi.getArchive(archiveId)
);

export const setArchive = createAsyncThunk("archives/set-archive", async (archive: Archive) => archive);


const archiveListSlice = createSlice({
  name: "docLab-archive-list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadArchives.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    }); 

    builder.addCase(loadArchives.fulfilled, (state, action) => {
      state.isLoading = false;
      state.archives = Helpers.normalize(action.payload.archives, (archive) => archive.id);
      state.archiveIds = action.payload.archives.map((archive) => archive.id);
      if(action.payload.archive) {
        const result = action.payload.archive;
        const archive = state.archives[result.id];
        archive.metadataJsonSchema = result.metadataJsonSchema;
        archive.metadataJsonSchemaFields = result.metadataJsonSchemaFields;
        archive.metadataUISchema = result.metadataUISchema;
        archive.metadataMapping = result.metadataMapping;
        archive.metadataShortJsonSchema = result.metadataShortJsonSchema;
        archive.displayedMetadataKey = result.displayedMetadataKey;
      }
    });

    builder.addCase(loadArchives.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.archives = [];
      state.archiveIds = [];
    });
    
    builder.addMatcher(isAnyOf(loadArchive.fulfilled, setArchive.fulfilled), (state, action) => {
      const result = action.payload;
      const archive = state.archives[result.id];
      archive.metadataJsonSchema = result.metadataJsonSchema;
      archive.metadataJsonSchemaFields = result.metadataJsonSchemaFields;
      archive.metadataUISchema = result.metadataUISchema;
      archive.metadataMapping = result.metadataMapping;
      archive.isCrossArchiveSearchable = result.isCrossArchiveSearchable;
      archive.isCrossFolderSearchable = result.isCrossFolderSearchable;
      archive.isTranslationAllowed = result.isTranslationAllowed;
      archive.isDefaultViewAdditionalMetadata = result.isDefaultViewAdditionalMetadata;
      archive.isReportDateFieldsInDateFormat = result.isReportDateFieldsInDateFormat;
      archive.isLogChangesMetadata = result.isLogChangesMetadata;
      archive.metadataShortJsonSchema = result.metadataShortJsonSchema;
      archive.displayedMetadataKey = result.displayedMetadataKey;
    });
  }
});

export default archiveListSlice.reducer;
export const useArchiveList = () => useDocLabSelector((state) => state.archiveList);