export type ConfigEntry = {
    connectionString: string;
}

export const environment: Record<string, ConfigEntry> = {
    'localhost:3000': {
        connectionString: "InstrumentationKey=3a42abef-7ac7-44d2-badd-eafa8a325212;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/",
    },
    'sd-edi-app.eni.com': {
        connectionString: "InstrumentationKey=b5e32921-4b03-4775-b72c-77de768d02a3;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/",
    },
    'st-edi-app.eni.com': {
        connectionString: "InstrumentationKey=c7ed4551-34a5-4b59-b702-41c7d46a32b0;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/",
    },
    'pp-edi-app.eni.com': {
        connectionString: "InstrumentationKey=a79a7d85-e40e-42e1-9b20-1eb2e2edc8a8;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/",
    },
    'edi-app.eni.com': {
        connectionString: "InstrumentationKey=d7fd9cf6-577d-4478-a6a2-ae7277e53f5c;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/",
    }
}