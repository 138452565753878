import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner"
import { DefaultButton, mergeStyleSets, PrimaryButton } from "@fluentui/react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import TeamsImage from "../../../common/components/teamsImage/teamsImage"
import { ImageName } from "../../../common/components/teamsImage/teamsImage.types"
import { Helpers } from "../../../utilities/helpers"
import { useOnMount } from "../../../utilities/hooks"
import { CreateWorkFlowPayload } from "../../models/workflow"
import { workflowApi } from "../../services/workflow/workflow.api"

export interface ICreateWorkFlowFirstPageProps {
    onClose?: () => void;
    onSuccess?: () => void;
    request?: CreateWorkFlowPayload;
}

const defaultRquest = {
    ActivityId: -1,
    Name: "",
    Description: "",
    FileId: "",
    Steps: []
};

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '> :not(:last-child)': {
            marginBottom: 20
        }
    },
    label: {
        fontSize: 24,
        fontWeight: 600,
        textAlign: 'center'
    },
    errorButtons: {
        display: 'flex',
        justifyContent: 'space-around',
        alignSelf: 'stretch'
    }
})

export const CreatingWorkFlowPage = (props: ICreateWorkFlowFirstPageProps) => {
    const [success, setSuccess] = useState<boolean | undefined>(undefined);
    const [creating, setCreating] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const { t } = useTranslation(['createWorkFlowModal', 'common']);

    useOnMount(() => void createWorkFlow());

    const createWorkFlow = async () => {
        if (success)
            return;

        setCreating(true);
        setSuccess(undefined);

        try { await Promise.all([workflowApi.createWorkflow(props.request || defaultRquest), Helpers.delay(500)]); setSuccess(true); }
        catch { setErrorCount(errorCount + 1); setSuccess(false); }
        finally { setCreating(false); }
    }

    return (
        <div className={classNames.container}>
            {creating && !success &&
                <>
                    <TeamsSpinner />
                    <span className={classNames.label}>{t('creatingWorkflow')}</span>
                </>
            }
            {!creating && success &&
                <>
                    <TeamsImage imageName={ImageName.WellDone} style={{ width: '60%' }} />
                    <span className={classNames.label}>{t('common:niceWork')}</span>
                    <PrimaryButton text={t('common:leave')} onClick={props.onSuccess} style={{ flex: '0 0 auto' }} />
                </>
            }
            {!creating && success === false &&
                <>
                    <div className={classNames.label}>{t('common:genericErrorApi')}</div>
                    <div className={classNames.errorButtons}>
                        {errorCount < 3 && <DefaultButton text={t('common:tryAgain')} onClick={createWorkFlow} />}
                        <PrimaryButton text={t('common:leave')} onClick={props.onClose} />
                    </div>
                </>
            }
        </div>
    )
}