/*eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, classNamesFunction, DefaultButton, Dropdown, Icon, IDropdownOption, IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, keyframes, Label, PrimaryButton, SearchBox, Spinner, SpinnerSize, Stack, TextField } from "@fluentui/react";
import { nanoid } from "@reduxjs/toolkit";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import IconTag from "../../../../common/components/iconTag/iconTag";
import MultiStepModal from "../../../../common/components/multiStepModal/multiStepModal";
import SelectProfileMember from "../../../../common/components/selectProfileMember/selectProfileMember";
import { Helpers } from "../../../../utilities/helpers";
import { useOnMount } from "../../../../utilities/hooks";
import { useDocLabDispatch } from "../../../docLabStore";
import { loadFolderContent, useArchiveContent } from "../../../features/archiveContent";
import { insertCall, setCall } from "../../../features/callNotification";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { Call, callsList, ErrorsType } from "../../../models/callsApi";
import { ArchiveRoleId, Constants, SelectionProfile } from "../../../models/constants";
import { UserProfile } from "../../../models/user";
import { archivesApi } from "../../../services/archives/archives.api";
import { ProfileMember, ProfileSelectedFolder, SelectionScopeProfiles } from "../../../services/archives/archives.contracts";
import { IFolderProfilePermissionModalProps, IFolderProfilePermissionModalPropsStyles, IFolderProfilePermissionModalStyles } from "./folderProfilePermissionModal.types";

const getClassNames = classNamesFunction<IFolderProfilePermissionModalPropsStyles, IFolderProfilePermissionModalStyles>();

enum FolderProfilePermissionStep {
    entryPage = 0,
    profilePage = 1,
    enableSubfolderPage = 2
}

export const FolderProfilePermissionModalBase = (props: IFolderProfilePermissionModalProps) => {
    const { manageProfilePermissionModalShown, manageProfilePermissionModalType, selectedItems, items, currentFolderId } = useArchiveContent();
    const { t } = useTranslation(['folderProfilePermission', 'createFolderModal', 'common']);
    const [validationIcon, setValidationIcon] = useState('Error');
    const [currentName, setCurrentName] = useState<string | undefined>(undefined);
    const [keyword, setKeyword] = useState("");
    const [keywordMembers, setKeywordMembers] = useState("");
    const [keywordProfileAdded, setKeywordProfileAdded] = useState("");
    const [visibilityPermission, setVisibilityPermission] = useState<SelectionScopeProfiles[]>([]);
    const [visibilityOption, setVisibilityOption] = useState<IDropdownOption>();
    const [loadingVisibility, setLoadingVisibility] = useState<boolean>(true); 
    const [profileSelectedFolders, setProfileSelectedFolders] = useState<ProfileSelectedFolder[]>([]);
    const [initialProfileSelectedFolders, setInitialProfileSelectedFolders] = useState<ProfileSelectedFolder[]>([]);
    const [loadingProfiles, setLoadingProfiles] = useState<boolean>(true); 
    const [currentStep, setCurrentStep] = useState(FolderProfilePermissionStep.entryPage);
    const [profileMembers, setProfileMembers] = useState<UserProfile[]>([]);
    const [loadingMembers, setLoadingMembers] = useState<boolean>(true); 
    const [toggleAllSubfolders, setToggleAllSubfolders] = useState<boolean>(false); 
    const dispatch = useDocLabDispatch();
    const currentArchive = useCurrentArchive();
    const folderName = _.find(items, {'id': selectedItems[0]?.id, 'isFolder': true})?.name; 
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const field = 'profile.id'; 

    const optionsDropDown: IDropdownOption[] = [
        { key: SelectionProfile.Owner, text: t('dropdownOwner'), disabled: currentArchive?.currentUserRoleId !== ArchiveRoleId.Owner },
        { key: SelectionProfile.Fathers, text: t('dropdownFathers') },
        { key: SelectionProfile.Brothers, text: t('dropdownBrothers') },
        { key: 'custom', text: t('dropdownCustomValue'), disabled: true, hidden: true}
    ]

    useOnMount(() => {
        async function fetchVisibilityPermission() {            
            const visibilityProfiles = await archivesApi.getProfilesRelatedTo(currentFolderId)
            const visibilityProfilesComputed = _.forEach(visibilityProfiles, function(option) {
                option.simplifiedHash = 
                _.join(
                    _.map(
                        _.orderBy(option.profiles, ['id'], ['asc'])
                        , 'id')
                    ,',');   

                return option;
            });
            setVisibilityPermission(visibilityProfilesComputed); 
            setLoadingVisibility(false); 
        }

        async function fetchProfilesSelectedFolders() {  
            const result = await archivesApi.getProfilesWithFolderVisiblity(currentFolderId, selectedItems[0]?.id); 
              
            setProfileSelectedFolders(result);             
            setInitialProfileSelectedFolders(_.filter(result, 'selected'));
            setLoadingProfiles(false);             
        }

        setCurrentName(folderName); 

        fetchVisibilityPermission(); 
        fetchProfilesSelectedFolders();                  
    });

    const goToProfilePage = async () => {
        if (!currentArchive) 
            return; 

        setLoadingMembers(true); 
        setCurrentStep(FolderProfilePermissionStep.profilePage); 
        
        const profileIds = _.map(
                _.filter(profileSelectedFolders, 'selected')
        , field) as number[];         
        
        const members = await archivesApi.getFlatMembersInProfiles(currentArchive.id, profileIds);  
        
        setProfileMembers(members); 
        setLoadingMembers(false);
    }
    

    const createFolder = async () => {
        if (!currentName)
            return;

        const createFolderCall: Call = {
            type: callsList.createFolder,
            nameOperation: t('messageCreate').concat(currentName).concat('"'),
            errors: [
                { code: 403, message: t('notPermissions') },
                { code: 404, message: t("notFound") },
                { code: 409, message: t('alreadyExists') },
                { code: 422, message: t("excessiveDepth") },
                { code: 500, message: t('common:createGenericErrorApi').concat(t('ofFolder')) }
            ]
        };
        const id = nanoid();
        const payload = { requestId: id, notification: createFolderCall }
        dispatch(insertCall(payload));
        props.onClose && props.onClose();


        try {
            const profiles =_.map(_.filter(profileSelectedFolders, 'selected'), 'profile') as ProfileMember[];         
            await Promise.all([archivesApi.createFolder(currentFolderId, currentName , profiles), Helpers.delay(500)]);
            const successPayload = { requestId: id, success: true, message: t('successMessage') }
            dispatch(setCall(successPayload));
            dispatch(loadFolderContent({ resetPage: true }));
        }
        catch (er) {
            const error : ErrorsType = er as ErrorsType;
            let errorMessage = '';
            switch (error.code) {
                case 403: errorMessage = t('notPermissions'); break;
                case 404: errorMessage = t("notFound"); break;
                case 409: errorMessage = t('alreadyExists'); break;
                case 422: errorMessage = t("excessiveDepth"); break;
                default: errorMessage = t('common:genericErrorApi'); break;
            }
            const failurePayload = { requestId: id, success: false, message: errorMessage }
            dispatch(setCall(failurePayload));
        }
    }

    const updatePermissionsFolder = async () => {
        if (!currentName)
            return;

        const updatePermissionsFolderCall: Call = {
            type: callsList.updatePermissionsFolder,
            nameOperation: t('messageEdit').concat(currentName).concat('"'),
            errors: [
                { code: 403, message: t('notPermissions') },
                { code: 500, message: t('common:createGenericErrorApi') }
            ]
        };
        const id = nanoid();
        const payload = { requestId: id, notification: updatePermissionsFolderCall }
        dispatch(insertCall(payload));
        props.onClose && props.onClose();

        try {
            await Promise.all([archivesApi.updateFolderProfilePermission(selectedItems[0]?.id, profileSelectedFolders), Helpers.delay(500)]);
            const successPayload = { requestId: id, success: true, message: t('successMessage') }
            dispatch(setCall(successPayload));
        }
        catch (er) {
            const error : ErrorsType = er as ErrorsType;
            let errorMessage = '';
            if (error.code === 403)
                errorMessage = t('notPermissions');
            else 
                errorMessage = t('common:genericErrorApi');
           
            const failurePayload = { requestId: id, success: false, message: errorMessage }
            dispatch(setCall(failurePayload));
        }
    }

    const changeVisibilityOption = (visibilityOption?: IDropdownOption) => {
        
        setVisibilityOption(visibilityOption); 
        const selectedProfiles = _.find(visibilityPermission, function(v) { return v.name === visibilityOption?.key; })?.profiles;    
            
        const newProfileSelectedFolders = _.forEach(profileSelectedFolders, function(profile) {               
            profile.selected = profile.profile.name === SelectionProfile.Owner 
                ? true
                :  _.some(selectedProfiles, profile.profile)
            return profile
        });                      

        setProfileSelectedFolders(newProfileSelectedFolders);  
    }

    const handleTagSelection = (profile: ProfileSelectedFolder) => {
        const newProfileSelectedFolders = profileSelectedFolders.slice();
        const index = _.findIndex(newProfileSelectedFolders, [field, profile.profile.id]); 
        if (index === -1)
            return; 

        //Update selection profiles
        newProfileSelectedFolders[index].selected = !profile.selected;
        setProfileSelectedFolders(newProfileSelectedFolders);  
    }

    const toggleEnableSubfolder = (profile: ProfileSelectedFolder, check?: boolean) => {
        const checkValue = check || false;
        const newProfileSelectedFolders = profileSelectedFolders.slice();
        const index = _.findIndex(newProfileSelectedFolders, [field, profile.profile.id]); 
        if (index === -1)
            return; 

        //Update selection profiles
        newProfileSelectedFolders[index].enableToSubfolders = checkValue;
        setProfileSelectedFolders(newProfileSelectedFolders); 
        if (!checkValue) 
            setToggleAllSubfolders(false);
    }

    const toggleAllAllowSubfolder = (check?: boolean) => {
        const checkValue = check || false;
        const newProfileSelectedFolders = profileSelectedFolders.map(val => {
            val.enableToSubfolders = checkValue; 
            return val; 
        })

        setToggleAllSubfolders(checkValue);        
        setProfileSelectedFolders(newProfileSelectedFolders);    
    }

    useEffect(() => {
        recognizeVisibilityOption(); 
    }, [loadingVisibility, loadingProfiles, profileSelectedFolders]); //eslint-disable-line react-hooks/exhaustive-deps
     
    const recognizeVisibilityOption = () => {
        //Calculate dropdown option each time a tag is changed
        const simplifiedHash = _.join(
            _.map(
                _.orderBy(
                    _.filter(profileSelectedFolders, 'selected')
                    ,[field], ['asc'])
            , field)
        , ',');                
        
        if (loadingProfiles || loadingVisibility)
            return;

        const visibilityPerm = _.find(visibilityPermission, function(v) { return v.simplifiedHash === simplifiedHash; });
        if (visibilityPerm) {
            const visibilityOption = _.find(optionsDropDown, function(v) { return v.key === visibilityPerm?.name; });
            setVisibilityOption(visibilityOption); 
        } 
        else {
            const visibilityOption = _.find(optionsDropDown, function(v) { return v.key === 'custom'; });
            setVisibilityOption(visibilityOption); 
        }       
    }

    const stylesIcon: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = () => {
        return {
            errorMessage: {
                position: 'absolute'
            },
            suffix: [
                { background: 'transparent', },
                validationIcon === 'Error' && { color: palette.redDark },
                validationIcon === 'Completed' && { color: palette.greenDark },
                validationIcon === 'ProgressRingDots' && {
                    '> i': {
                        animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite`
                    }
                }
            ]
        }
    }

    const validateFolderName = useCallback(async (value: string) => {
        if (!value)
            return `${t('common:fieldRequired')}`;
        setValidationIcon('ProgressRingDots');
        try {
            const [isValid] = await Promise.all([archivesApi.isFolderNameAvailable(value, currentFolderId), Helpers.delay(500)]);
            if (folderName && value.toLowerCase() === folderName.toLowerCase()) return ""
            return isValid ? "" : t('common:nameNotAvailable');
        }
        catch {
            return t('common:genericErrorCheckName');
        }
    }, [t, currentFolderId, folderName]);
    
    const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
        if(error === '')
            setCurrentName(value)
        else
            setCurrentName(undefined)
        setValidationIcon(error ? 'Error' : 'Completed');
    }, [currentName]); //eslint-disable-line react-hooks/exhaustive-deps
        
    const bodyentryPage = () => {
        return (
            <Stack className={classNames.root}>
                <Stack verticalAlign={'center'}>
                
                    <Stack.Item >
                        <Label>{t('inputFolder')}</Label>
                        <TextField
                            maxLength={Constants.MAX_LENGTH_ARCHIVE_ITEM_NAME}
                            placeholder={t('createFolderModal:namePlaceholder')}
                            deferredValidationTime={250}
                            onGetErrorMessage={validateFolderName}
                            defaultValue={currentName}
                            autoFocus
                            styles={stylesIcon}
                            autoComplete={"off"}
                            onNotifyValidationResult={onNameValidationResult}
                            disabled={manageProfilePermissionModalType !== 'NEW' }
                            onChange={(_, newValue) => 
                                setCurrentName(newValue)
                            }
                            onRenderSuffix={() => <Icon iconName={validationIcon} />                            
                        }
                        />
                    </Stack.Item>

                    <Stack.Item style={{ marginTop: 25 }} >
                        <Label>{t('manageVisibilityCoin')}</Label>
                        <Dropdown
                            options={optionsDropDown}                 
                            selectedKey={visibilityOption?.key}           
                            placeholder={`${t('selectProfileGroup')}`}
                            onChange={(_, option) => changeVisibilityOption(option)                            
                            }
                            disabled={loadingProfiles || loadingVisibility}
                        />
                    </Stack.Item>

                    <Stack.Item style={{ marginTop: 10 }} >
                        <Label>{t('viewProfiles')}</Label>
                        <SearchBox
                            defaultValue={keyword}
                            styles={{ root: { width: 300, marginRight: '15px' } }}
                            placeholder={t("searchProfiles")}
                            onSearch={(newValue) => {
                                setKeyword(newValue || '')
                            }}
                            onClear={() => {
                                setKeyword('')
                            }}
                        />
                    </Stack.Item>

                </Stack>

                <Stack horizontalAlign={'center'} className={classNames.tagPanel}>

                        {loadingProfiles && 
                            <Stack.Item style={{ marginTop: 10, height: '100%', display: 'flex' }} >
                                <Spinner size={SpinnerSize.large} />
                            </Stack.Item>
                        }
                        {!loadingProfiles && profileSelectedFolders.length > 0 &&
                            <div className={classNames.containerTag}>
                                {profileEntriesRender()}
                            </div>
                        }                                                                     
                </Stack>
            </Stack>

        );
    }

    const footer = (         
        <Stack horizontal horizontalAlign={currentStep === FolderProfilePermissionStep.entryPage ? "space-between" : "end"} >
 
            {currentStep === FolderProfilePermissionStep.entryPage &&
            <Stack.Item align="center" onClick={() => !loadingProfiles && goToProfilePage() }>            
                <span style={{textDecoration: 'underline', cursor: 'pointer'}}>                    
                    {t('viewUsers')}
                </span>
            </Stack.Item>
            }

            <Stack.Item >
                <DefaultButton 
                    onClick={() => { 
                        currentStep === FolderProfilePermissionStep.entryPage 
                            ? props.onClose && props.onClose()
                            : setCurrentStep(FolderProfilePermissionStep.entryPage)                        
                         }} 
                    style={{ margin: '0px 4px' }}
                >
                    {currentStep === FolderProfilePermissionStep.entryPage ? t('common:abort') : t('common:previousButton')}
                </DefaultButton>
                
                {(currentStep === FolderProfilePermissionStep.entryPage || currentStep === FolderProfilePermissionStep.profilePage) 
                  && manageProfilePermissionModalType === 'NEW' && 
                    <PrimaryButton
                        onClick={createFolder}
                        style={{ margin: '0px 4px' }}
                        disabled={loadingProfiles || loadingVisibility || validationIcon !== 'Completed'}
                    >
                        {t('common:save')}
                    </PrimaryButton>
                }

                {(currentStep === FolderProfilePermissionStep.entryPage || currentStep === FolderProfilePermissionStep.profilePage)
                  && manageProfilePermissionModalType !== 'NEW' && 
                    <PrimaryButton
                        onClick={() => setCurrentStep(FolderProfilePermissionStep.enableSubfolderPage)}
                        style={{ margin: '0px 4px' }}
                        disabled={loadingProfiles || loadingVisibility || validationIcon !== 'Completed'}
                    >
                        {t('common:nextButton')}
                    </PrimaryButton>
                }     

                {currentStep === FolderProfilePermissionStep.enableSubfolderPage &&
                    <PrimaryButton
                        onClick={updatePermissionsFolder}
                        style={{ margin: '0px 4px' }}
                        disabled={loadingProfiles || loadingVisibility || validationIcon !== 'Completed'}
                    >
                        {t('common:save')}
                    </PrimaryButton>
                }              

            </Stack.Item>            
        </Stack>        
    )

    const profileEntriesRender = () => {
        const profiles = keyword 
            ? profileSelectedFolders.filter(f => f.profile.name.toLowerCase().includes(keyword.toLowerCase()))
            : profileSelectedFolders; 

        return (
            <>
                {_.filter(profiles, function(p) { return p.profile.name === SelectionProfile.Owner })
                .map((tag, ind) => {
                    return <IconTag                             
                        key={ind}
                        label={tag.profile.name} 
                        tooltip 
                        labelClassName={classNames.iconTagLabel}
                        styles={{root: {
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}} 
                    />
                })}

                {_.orderBy(
                    _.filter(profiles, function(p) { return p.profile.name !== SelectionProfile.Owner })
                    ,['selected', 'profile.name'], ['desc', 'asc'])
                .map((tag, ind) => {
                    return (
                        <div style={{cursor: 'pointer'}} key={ind} onClick={() => handleTagSelection(tag)}>
                            <IconTag                             
                                styles={!tag.selected ? {root: {backgroundColor: '#c8c6c4', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}: {}}
                                key={ind+1}
                                label={tag.profile.name} 
                                tooltip 
                                labelClassName={classNames.iconTagLabel} 
                            />
                        </div>
                )})}
            </>
        )
    }

    const bodyProfilePage = () => {        
        return (
            <>            
            <Stack.Item style={{ marginTop: 10 }} >
                <Label>{t('subtitleMemberInvolved')}</Label>
                <SearchBox
                    styles={{ root: { width: 300, marginRight: '15px' } }}
                    placeholder={t("searchMembers")}
                    defaultValue={keywordMembers}
                    onSearch={(newValue) => {
                        setKeywordMembers(newValue || '')
                    }}
                    onClear={() => {
                        setKeywordMembers('')
                    }}
                />
            </Stack.Item>
            {!loadingMembers ? 
                <Stack.Item style={{ marginTop: 10, height: '85%', overflow: 'scroll' }} >
                    
                    <SelectProfileMember 
                        userToRender={keywordMembers 
                            ? profileMembers.filter(f => 
                                   f.firstName.toLowerCase().includes(keywordMembers.toLowerCase()) 
                                || f.lastName.toLowerCase().includes(keywordMembers.toLowerCase()))
                            : profileMembers
                        } 
                        archiveId={currentArchive?.id || 0} 
                        readonly={true}
                        visualizeMode={true}
                        selectedUser={(users) => {console.log('Not implemented')}}
                    /> 
                </Stack.Item>
                : 
                <Stack.Item style={{ marginTop: 10, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <Spinner size={SpinnerSize.large} />
                </Stack.Item>  
            }
            </>              
        ) 
    }

    const bodySubfolderPage = () => {
        let addedProfiles = _.filter(_.filter(profileSelectedFolders, 'selected'), function(pr) { 
            return !initialProfileSelectedFolders.some(function(inpr) {
                return pr.profile.id === inpr.profile.id
        })});
        if (keywordProfileAdded) {
            addedProfiles = addedProfiles.filter(f => f.profile.name.toLowerCase().includes(keywordProfileAdded.toLowerCase())); 
        } 
        
        return (
            <>
            <Stack horizontalAlign={'start'}>
                <Stack.Item style={{ marginTop: 20 }} >
                <Checkbox
                    label={t('enableAllSubfolders')}
                    checked={toggleAllSubfolders}
                    disabled={false}
                    onChange={(ev, checked) => toggleAllAllowSubfolder(checked)}
                />
                </Stack.Item>

                <Stack.Item style={{ marginTop: 20 }} >
                    <SearchBox
                        defaultValue={keywordProfileAdded}
                        styles={{ root: { width: 300, marginRight: '15px' } }}
                        placeholder={t("searchProfiles")}
                        onSearch={(newValue) => {
                            setKeywordProfileAdded(newValue || '')
                        }}
                        onClear={() => {
                            setKeywordProfileAdded('')
                        }}
                    />
                </Stack.Item>
            </Stack>

            <Stack className={classNames.tagPanel} horizontalAlign={'center'}>

                <div className={classNames.containerProfileCheckboxTag}>
                    {addedProfiles.map((tag,i) => { 
                        return (
                            <div key={i} className={classNames.checkboxTagElement}>
                            <Checkbox
                                id={i+'_checkbox'}
                                checked={tag.enableToSubfolders}
                                onChange={(ev, checked) => {toggleEnableSubfolder(tag, checked)} }
                                disabled={false}
                                className={classNames.checkboxTag}
                            />
                            <IconTag                             
                                key={i}
                                label={tag.profile.name} 
                                tooltip 
                                labelClassName={classNames.iconTagLabelProfile} 
                                />
                            </div>
                        )
                    })} 
                </div>               
            </Stack>
            </>
        );
    }


    const stepsForCreation = [
        {
            title: t('newTitle'),
            body: bodyentryPage(),
            footer: footer,
        },
        {
            title: t('newTitle'),            
            body: bodyProfilePage(),
            footer: footer,
        }
    ];

    const stepsForEdit = [
        {
            title: t('editTitle'),
            body: bodyentryPage(),
            footer: footer
        },
        {
            title: t('editTitle'),            
            body: bodyProfilePage(),
            footer: footer,
        },
        {
            title: t('editTitle'),            
            body: bodySubfolderPage(),
            footer: footer,
            subtitle: t('editSubtitle'),  
        }        
    ];

    return (
        <MultiStepModal
            isOpen={manageProfilePermissionModalShown}
            showCloseIcon={true}
            onCloseClick={props.onClose}
            steps={manageProfilePermissionModalType === 'NEW' 
                ? stepsForCreation 
                : stepsForEdit }
            activeStep={currentStep}
            height={700}
            width={800}
            hideScrollbarBody        
            className={classNames.root}
        />
    );
}