import { styled } from "@fluentui/react";
import { FilesApprovalDocumentModalBase } from "./filesApprovalDocumentModal.base";
import { getStyles } from "./filesApprovalDocumentModal.styles";
import { IFilesApprovalDocumentModalProps, IFilesApprovalDocumentModalPropsStyles, IFilesApprovalDocumentModalStyles } from "./filesApprovalDocumentModal.types";

const FilesApprovalDocumentModal = styled<IFilesApprovalDocumentModalProps, IFilesApprovalDocumentModalPropsStyles, IFilesApprovalDocumentModalStyles>(
    FilesApprovalDocumentModalBase,
    getStyles,
    undefined,
    { scope: 'FilesApprovalDocumentModal' }
);

export default FilesApprovalDocumentModal;