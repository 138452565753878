/* eslint-disable sonarjs/cognitive-complexity */
import { classNamesFunction, ConstrainMode, IColumn, Icon, IconButton, PrimaryButton, SelectionMode, ShimmeredDetailsList, Stack, StackItem, TextField } from "@fluentui/react";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import FileIconCell from "../../../../../common/components/fileIconCell/fileIconCell";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { ArchiveItem } from "../../../../../docLab/models/archiveItem";
import { Constants, ContentItemIndexingStatus, ContentItemScope } from "../../../../../docLab/models/constants";
import { archivesApi } from "../../../../../docLab/services/archives/archives.api";
import { Helpers } from "../../../../../utilities/helpers";
import { useAsyncApi, useOnMount } from "../../../../../utilities/hooks";
import { useArchiveContent } from "../../../../features/archiveContent";
import { FolderBreadcrumb } from "./folderNavigation.breadcrumb";
import { IFolderNavigationProps, IFolderNavigationPropsStyles, IFolderNavigationStyles } from "./folderNavigation.types";

const getClassNames = classNamesFunction<IFolderNavigationPropsStyles, IFolderNavigationStyles>();

interface IListData {
    indexingStatus: ContentItemIndexingStatus;
    scope: ContentItemScope;
    folderId: number;
    folderName: string;
    name: string;
    orderBy: string;
    isAsc: boolean;
    pageNumber: number;
    pageSize: number;
}

type GetFolderContentParams = {
    contentIndexingStatus?: ContentItemIndexingStatus;
    contentScope?: ContentItemScope;
    folderId?: number;
    folderName?: string;
    name?: string;
    orderBy?: string;
    isAsc?: boolean;
    resetPage: boolean;
}

const defaultListData: IListData = {
    indexingStatus: ContentItemIndexingStatus.All,
    scope: ContentItemScope.All,
    folderId: 0,
    name: '',
    orderBy: 'Name',
    folderName: '',
    isAsc: true,
    pageNumber: 0,
    pageSize: Constants.PAGE_SIZE_START_ANALYSIS
}

export const FolderNavigationBase = (props: IFolderNavigationProps) => {
    const [data, setData] = useState<IListData>(defaultListData);
    const currentArchive = props.selectedArchive; 
    const { t } = useTranslation(['navigationModal', 'common']);
    const [deferredLoading, setDeferredLoading] = useState(false);
    const [noMore, setNoMore] = useState(true);
    const [keyword, setKeyword] = useState("");
    const [archiveItems, setArchiveItems] = useState<ArchiveItem[]>([]);
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const {archiveRolesDest} = useArchiveContent();


    const { loading, error, execute: getFolderContent, value: items } = useAsyncApi<GetFolderContentParams, ArchiveItem[]>({
        func: async (input: GetFolderContentParams): Promise<ArchiveItem[]> => {
            const newData: IListData = {
                folderId: input.folderId ?? data.folderId,
                folderName: items?.find(i => i.id === input.folderId ?? data.folderId)?.name ?? currentArchive?.name ?? '',
                name: input.name || '',
                orderBy: input.orderBy ?? data.orderBy,
                isAsc: input.isAsc ?? data.isAsc,
                pageNumber: input.resetPage ? 0 : data.pageNumber + 1,
                pageSize: defaultListData.pageSize,
                scope: input.contentScope || ContentItemScope.All,
                indexingStatus: input.contentIndexingStatus || ContentItemIndexingStatus.All
            }

            setData(newData);

            if (input.resetPage)
                setNoMore(false);

            const response = await archivesApi.getFolderContent({
                id: newData.folderId,
                isAscending: newData.isAsc,
                orderBy: newData.orderBy,
                pageNumber: newData.pageNumber,
                pageSize: newData.pageSize,
                name: newData.name,
                scope: newData.scope,
                indexingStatus: props.contentIndexingStatusConfiguration
            });

            setNoMore(newData.pageSize > response.contentItems?.length || response.contentItems?.length === 0);

            const result = input.resetPage ? response.contentItems : (items ? items.concat(response.contentItems) : []);
            setArchiveItems(result);
            props.onUpdateFolder(newData.folderId);
             
            return result;
        }
    });

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, isEmptyList: !items || items.length === 0 });

    const setContentScope = (name: string) => {
        return  name === '' ? ContentItemScope.All : ContentItemScope.Folder 
    }

    useOnMount(() => {
        registerEvent();
        if (!currentArchive)
            return;

        const action = getFolderContent({ folderId: currentArchive.rootFolderId, resetPage: true, contentIndexingStatus: props.contentIndexingStatusConfiguration, name: keyword, contentScope: setContentScope(keyword) });
        Helpers.deferred(action, setDeferredLoading, 200, 250);
    });

    const registerEvent = useCallback(() => {
        const listElm = document.querySelector('#folderContentDetailsListZone'); //#modalListZone .ms-DetailsList-contentWrapper
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50) {
                const button = document.getElementById("click_FolderContent");
                button && button.click();
            }
        });
    }, []);

    const sortByColumn = (_: React.MouseEvent, column: IColumn) => {
        if (loading)
            return;

        const action = getFolderContent({
            orderBy: column.fieldName,
            isAsc: column.fieldName === data.orderBy ? !data.isAsc : true,
            resetPage: true,
            name: keyword, contentScope: setContentScope(keyword)
        });

        Helpers.deferred(action, setDeferredLoading, 200, 250);
    };

    const loadMore = useCallback(() => {
        if (loading || noMore) return;
        const action = getFolderContent({ resetPage: false });
        Helpers.deferred(action, setDeferredLoading, 200, 250);
    }, [loading, noMore, getFolderContent]);

    const emptyFolder = useCallback(() => {
        if (loading || (items?.length !== 0))
            return null;

        return (
            <div className={classNames.emptyFolder}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyFolder")}
                />
            </div>
        )
    }, [classNames.emptyFolder, items, t, loading]);

    const columns: IColumn[] = [        
    {
        key: 'file-type',
        name: 'File Type',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: '',
        minWidth: 20,
        maxWidth: 20,
        onRender: function getItemCell(item: ArchiveItem) {
            const extIndex = item.name.lastIndexOf('.');
            const ext = extIndex !== -1 ? item.name.substring(extIndex) : "";
            return <span className={item.isFolder && _.includes(archiveRolesDest, item.archiveRoleId)? "" : classNames.file}> <FileIconCell fileExtension={ext} isFolder={item.isFolder} /> </span>
        }
    }, {
        key: 'Name',
        name: t("common:name"),
        fieldName: 'Name',
        minWidth: 100,
        maxWidth: 350,
        isSorted: data.orderBy === 'Name',
        isSortedDescending: !data.isAsc,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: sortByColumn,
        onRender: function getItemName(item: ArchiveItem) {
            return (
                <span
                    onClick={() => { item.isFolder &&  _.includes(archiveRolesDest, item.archiveRoleId) && getFolderContent({ folderId: item.id, resetPage: true, name: keyword, contentScope: setContentScope(keyword) })  }}
                    style={{ cursor: item.isFolder && _.includes(archiveRolesDest, item.archiveRoleId) ? 'pointer' : 'default' }}
                    className={item.isFolder && _.includes(archiveRolesDest, item.archiveRoleId) ? "" : classNames.file}
                >
                    {item.name}
                </span>
            )
        }
    }, {
        key: 'CreatedOn',
        name: t("columns.createdOn"),
        fieldName: 'CreatedOn',
        minWidth: 130,
        maxWidth: 130,
        isResizable: true,
        isSorted: data.orderBy === 'CreatedOn',
        isSortedDescending: !data.isAsc,
        onColumnClick: sortByColumn,
        onRender: function render(item: ArchiveItem) {
            return <span className={item.isFolder && _.includes(archiveRolesDest, item.archiveRoleId) ? "" : classNames.file}>{Helpers.formatToRelativeDate(item.createdOn)}</span>
        }
    }, {
        key: 'CreatedBy',
        name: t("columns.createdBy"),
        fieldName: 'CreatedBy',
        minWidth: 130,
        maxWidth: 250,
        isResizable: true,
        isCollapsible: true,
        isSorted: data.orderBy === 'CreatedBy',
        isSortedDescending: !data.isAsc,
        onColumnClick: sortByColumn,
        onRender: function getCreatedBy(item: ArchiveItem) {
            return <span className={item.isFolder && _.includes(archiveRolesDest, item.archiveRoleId) ? "" : classNames.file}>{item.createdBy}</span>;
        },
    }];

    return (
        <div className={classNames.root} id={"modalListZone"}>
            {error &&
                <TeamsImage
                    imageName={ImageName.Error1}
                    scale={0.3}
                    fullContainer
                    caption={t("errorLoading")}
                />
            }
            {!error &&
                <>
                    <Stack horizontal>
                        <StackItem grow={2}> 
                        <div className={classNames.breadcrumbContainer}>
                            <div className={classNames.iconContainer}>
                                <Icon onClick={() => props.onLevelUp(true)} styles={{ root: { fontSize: '16px', cursor: 'pointer' } }} iconName="up" />
                            </div>
                            <FolderBreadcrumb
                                folderId={data.folderId}
                                folderName={data.folderName}
                                onClick={id => {
                                    setKeyword("");
                                    getFolderContent({ folderId: id, resetPage: true, name: keyword, contentScope: setContentScope(keyword) })
                                }}
                            />
                        </div>
                        </StackItem>
                        <StackItem align="end">
                            <div className={classNames.searchContainer}>
                                <TextField placeholder={t("searchFolders")}
                                    value={keyword}
                                    onChange={(_, value) => setKeyword(value || '')}
                                    styles={{
                                        field: { color: palette.neutralPrimary, 'input': { '&::placeholder': { color: palette.neutralPrimary } } }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && e.currentTarget.value) {
                                            getFolderContent({ folderId: data.folderId, resetPage: true, name: e.currentTarget.value, contentScope: setContentScope(e.currentTarget.value) })
                                        }

                                    }}
                                    maxLength={props.searchFieldMaxLenght || Constants.MAX_LENGHT_25}
                                />
                                <IconButton
                                    iconProps={{ iconName: "Cancel" }}
                                    styles={{
                                        rootHovered: { background: palette.neutralLight }
                                    }}
                                    style={{ height: 20, color: palette.black }}
                                    onClick={() => {
                                            setKeyword(''); (getFolderContent({ folderId: data.folderId, resetPage: true }))
                                    }}
                                />
                            </div>
                        </StackItem>
                    </Stack>


                    <div id={"folderContentDetailsListZone"} style={{ height: 'calc(100% - 55px)', overflow: 'overlay' }}>
                        <ShimmeredDetailsList
                            items={archiveItems ?? []}
                            columns={columns}
                            getKey={(item: ArchiveItem) => item && `${item.isFolder ? 'f' : ''}${item.id}`}
                            selectionMode={SelectionMode.none}
                            enableShimmer={data.pageNumber === 0 && (deferredLoading || loading)}
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderDetailsFooter={emptyFolder}
                            onShouldVirtualize={() => false}
                            compact={true}
                        />
                    </div>
                </>
            }
            <div className={classNames.load}>
                <PrimaryButton id={noMore ? "noClick_FolderContent" : "click_FolderContent"} onClick={loadMore} />
            </div>
        </div>
    );
}