import React from "react";
import { classNamesFunction, ProgressIndicator } from "@fluentui/react";
import { INotificationPanelProps, INotificationPanelPropsStyles, INotificationPanelStyles } from "./notificationPanel.types";
import { NotificationStatus, removeCall, showPanelAction, useCallNotification } from "../../../docLab/features/callNotification";
import { useDocLabDispatch } from "../../../docLab/docLabStore";
import { useTranslation } from "react-i18next";
import Panel from "../panel/panel";

const getClassNames = classNamesFunction<INotificationPanelPropsStyles, INotificationPanelStyles>();

export const NotificationPanelBase = (props: INotificationPanelProps) => {
    const { showPanel, notificationsQueue, status } = useCallNotification();
    const dispatch = useDocLabDispatch();
    const { t } = useTranslation(['notificationPanel', 'common']);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, noNotifications: notificationsQueue.length === 0 });

    const notificationEntry = (key: string, entry: NotificationStatus) => (
        <div key={key} className={classNames.root}>

            {entry.progress &&
                <div className={classNames.cardContainer}>
                    <div className={classNames.cardContainer}>
                        <span style={{ fontWeight: 'bold' }}>{entry.notification.nameOperation}</span>
                        <span>{t('common:loading')}</span>
                    </div>
                    <ProgressIndicator />
                </div>
            }
            {!entry.progress && entry.success === true &&
                <div className={classNames.cardContainer}>
                    <span className={classNames.successTitle}>{entry.notification.nameOperation}</span>
                    <span className={classNames.successMessage} >{entry.message}</span>
                </div>
            }
            {!entry.progress && entry.success === false &&
                <div className={classNames.cardContainer}>
                    <span className={classNames.errorTitle}>{entry.notification.nameOperation}</span>
                    <span className={classNames.errorMessage}>{entry.message}</span>
                </div>
            }
        </div>
    )

    const removeNotifications = () => {
        notificationsQueue.forEach(itemId => {
            const isLoading = status[itemId].progress;
            if (!isLoading) {
                dispatch(removeCall(itemId));
            }
        })
    }

    const content = () => (
        <>
            {notificationsQueue.length < 1 && <span style={{ fontSize: '25px' }}>{t('emptyPlaceHolder')}</span>}
            {notificationsQueue.map(itemId => notificationEntry(itemId, status[itemId]))}
        </>
    )
    return (
        <Panel
            headerTitle={t('title')}
            isOpen={showPanel}
            content={content()}
            isLightDismiss={true}
            smallPanel
            onDismiss={() => dispatch(showPanelAction(false))}
            onDismissed={() => removeNotifications()}
            styles={classNames.subComponentStyles.panelStyle}
        />
    );
}
