import { IStyleFunction } from "@fluentui/react"
import { IDocVerifyModalFooterPropsStyles, IDocVerifyModalFooterStyles } from "./docVerifyModalFooter.types";

export const DocVerifyLoadingPageGlobalClassNames = {
    root: 'edi-file-docVerifyModalFooter-root'
}

export const getStyles: IStyleFunction<IDocVerifyModalFooterPropsStyles, IDocVerifyModalFooterStyles> = (props: IDocVerifyModalFooterPropsStyles): IDocVerifyModalFooterStyles => {
    return {
        root: {
        },
        advancedFooterContainer: {
            paddingTop: '0px',
            marginTop: '0px',
            textAlign: 'right',
            marginRight: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
        },
        disableElement: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingBottom: '4px',
            gap: 5

        }
    };
}