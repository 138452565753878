import { IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IIconStyleProps, IIconStyles, IStyleFunction } from "@fluentui/react"
import { ISearchAndComparePropsStyles, ISearchAndCompareStyles } from "./searchAndCompareResults.types";

export const SearchAndCompareGlobalClassNames = {
    root: 'edi-toc-result-root',
    error: 'edi-toc-result-error',
    header: 'edi-toc-result-header',
    resultNok: 'edi-toc-result-resultKo',
    resultOk: 'edi-toc-result-resultOk',
    headerRow: 'edi-toc-result-headerRow',
}

export const getStyles: IStyleFunction<ISearchAndComparePropsStyles, ISearchAndCompareStyles> = (props: ISearchAndComparePropsStyles): ISearchAndCompareStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [ 
            SearchAndCompareGlobalClassNames.root,
            {
                color: palette.black,
                marginLeft: 25, 
                '> :not(:first-child)': {
                    marginTop: 10,
                    marginBottom: 15                    
                }
            }
        ],
        resultNok : [SearchAndCompareGlobalClassNames.resultNok,{
            fontWeight: 'bold',
            color: palette.red
        }],
        resultOk : [SearchAndCompareGlobalClassNames.resultOk,{
            fontWeight: 'bold',
            color: palette.green
        }],
        error: [
            SearchAndCompareGlobalClassNames.error, {
                color: palette.red
            }],
        header:[SearchAndCompareGlobalClassNames.header,{
            display: 'flex',
            alignItems: 'center',
            margin: '20px 0',
            color: palette.themeDark
            }],
            headerRow: [
                SearchAndCompareGlobalClassNames.headerRow,
                {
                    ':hover': {
                        backgroundColor: 'transparent'
                    },
                    ':active': {
                        backgroundColor: 'transparent'
                    }
                }
            ],
            itemHeader: {
                display: 'flex',
                paddingLeft: 15,
                color: palette.black,
                background: palette.white,
              },
              itemCell: [
                {
                  padding: 10,
                  minHeight: 54,
                  background: palette.white,
                  boxSizing: 'border-box',
                  borderBottom: `1px solid ` + palette.themeSecondary,
                  display: 'flex',
                },
              ],
              itemContent: {
                marginLeft: 15,
                overflow: 'hidden',
                flexGrow: 1
              },
              itemName: [
                {
                  fontStyle: 'italic',
                  fontSize: 13,
                  ':not(:first-child)': {
                    marginTop: '30px'
                  },
                  ':not(:last-child)': {
                    marginBottom: '10px',
                  }
                }
              ],
        subComponentStyles: {      
        iconButton: {
            root: {
              height: 19
            },
            iconHovered: {
              textShadow: `0 0 1px ${palette.black}`
            },
            menuIcon: {
              display: 'none !important'
            },
            rootExpanded: {
              backgroundColor: 'inherit'
            },
            rootPressed: {
              backgroundColor: 'inherit'
            },
            rootHovered: {
              backgroundColor: 'inherit',
            }
          },
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        },
                        overflow:'hidden'
                    },
                    headerWrapper: {
                        flex: '0 0 auto',
                        '> .ms-DetailsHeader': {
                            backgroundColor: palette.white,
                            border: 'none'
                        }
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        overflowY: 'auto',
                        overflowX:'auto',
                        '@media(max-width: 630px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            },
            icon: (props: IIconStyleProps & { ok: boolean }): IIconStyles => {
                return {
                    root: {
                        fontSize: '25px',
                        color: props.ok ? palette.green : palette.redDark
                    }
                }

            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        width: '100%',
                        background: palette.white + " !important",
                        borderBottom: 'none',
                    },
                    cell: {
                        textAlign:'left',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems:'left',
                        whiteSpace:'normal',
                        wordBreak: 'break-word',
                        paddingTop: 0                    
                    },
                    cellAnimation: {},
                    cellUnpadded: {},
                    cellPadded: {},
                    checkCell: {},
                    isRowHeader: {},
                    isMultiline: {},
                    fields: {
                        backgroundColor: palette.white,
                    },
                    cellMeasurer: {},
                    checkCover: {},
                    check: {}
                }
            }
        }
    };
}