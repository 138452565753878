import React from "react";
import { classNamesFunction, DefaultButton, DetailsList, DetailsListLayoutMode, IColumn, Icon, IconButton, MessageBar, MessageBarType, SelectionMode, Stack } from "@fluentui/react";
import { IFileImporterOverviewPropsStyles, IFileImporterOverviewStyles, IFileImporterOverviewProps, UploadItem } from "./fileImporterOverview.types";
import { useTranslation } from "react-i18next";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { NavigableItem } from "../../../models/navigableFile";
import FileIconCell from "../../../../common/components/fileIconCell/fileIconCell";
import UploadButton from "../../../../common/components/uploadButton/uploadButton";
import _ from "lodash";
import { UploadStatus } from "../fileUploader/fileUploader.types";
import { Helpers } from "../../../../utilities/helpers";
import Dropzone, { IFileWithMeta, StatusValue } from "react-dropzone-uploader";

const getClassNames = classNamesFunction<IFileImporterOverviewPropsStyles, IFileImporterOverviewStyles>();

export const FileImporterOverviewBase = (props: IFileImporterOverviewProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['importKnowledgeLabDocument']);
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion


    const _columns: IColumn[] = [
        {
            key: 'type', name: '', fieldName: 'type', minWidth: 40, maxWidth: 40, isResizable: false,
            onRender: // eslint-disable-next-line react/display-name
                (item?: NavigableItem) => {
                    if (!item) return <></>
                    return (
                        <FileIconCell key={''} isFolder={false} fileExtension={item.extension ? item.extension : ''} />
                    );
                }
        },
        { key: 'name', name: 'Name', fieldName: 'name', minWidth: 250, maxWidth: 350, isResizable: false },
        { key: 'size', name: 'Size', fieldName: 'size', minWidth: 100, maxWidth: 200, isResizable: true },
        {
            key: 'column0',
            name: '',
            minWidth: 30,
            maxWidth: 30,
            onRender: function columnRender(item: NavigableItem) {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <IconButton
                            iconProps={{ iconName: "Cancel" }}
                            style={{ height: 18, color: palette.black }}
                            onClick={() => removeItem(item)}
                        />
                    </div>
                )
            },
        }
    ];

    const removeItem = (item: NavigableItem) => {
        const copy = props.files.slice()
        const filtered = copy.filter((_) => _.id !== item.id);
        props.setFiles(filtered);
    };

    const addFiles = (inputFiles: File[]) => {
        const newItems = props.files.slice()
        _.forEach(inputFiles, function (file) {

            const sizeCheck = Helpers.isSizeInMbSmallerThan(file.size, 100);
            if (sizeCheck)
                newItems.push(
                    mapToUploadFile(file));
        });

        props.setFiles(newItems);
    }

    const mapToUploadFile = (inputFile: File): UploadItem => {
        return {
            name: inputFile.name,
            extension: inputFile.name.split('.')[inputFile.name.split('.').length - 1],
            size: Helpers.formatSize(inputFile.size),
            fileUploadStatus: UploadStatus.ToUpload,
            file: inputFile,
            isValid: true,
            id: _.uniqueId(),
            isNavigable: false,
        };
    }

    const handleChangeStatus = (meta: IFileWithMeta, status: StatusValue, files: IFileWithMeta[]) => {
        if (status === 'done') {
            addFiles([meta.file]);
            _.remove(files, function (file) {
                return file.file.name === meta.file.name //Clean files saved in dropzone state
            });
        }
    }

    const dropzoneContent = (suffix: string) => (
        <div key={`content_${suffix}`} className={classNames.dropzoneContent}>
            <Icon iconName="CloudUpload" style={{ fontSize: 80 }} />
            <span>{t('steps.localFileSelector.title')}</span>
            <UploadButton
                acceptedFile={props.fileTypes}
                isDisabled={props.files.length > 10000}
                uploadFile={(files: File[]) => {
                    addFiles(files)
                }}
                label={t('import')}
                multiple={true}
                isDefaultButton={true}
            />
        </div>
    );

    return (
        <div className={classNames.root}>
            <Stack horizontal style={{ height: '100%' }}>
                <Stack.Item style={{ width: '160px' }}>

                    {props.isLocalFile ?

                        <Dropzone
                            classNames={{ dropzone: classNames.dropzoneBorders }}
                            onChangeStatus={handleChangeStatus}
                            inputContent={dropzoneContent("noData")}
                            inputWithFilesContent={dropzoneContent("withContent")}
                            styles={{
                                input: { display: 'none' },
                                preview: { display: 'none' }
                            }}
                            multiple={true}
                        />
                        :
                        <div className={classNames.dropzoneBorders}>

                            <TeamsImage imageName={props.imageName} styles={{ root: { height: '40px', width: '40px', margin: '32px' } }} />
                            <label style={{ marginBottom: '20px' }}>{`${props.isEdiFile ? t('steps.overviewEdi.title') : t('steps.overviewSharepoint.title')}`}</label>
                            <DefaultButton onClick={props.onImport}>{t('import')}</DefaultButton>
                        </div>
                    }
                </Stack.Item>

                <Stack.Item style={{ width: 550, padding: '0 10px 0 10px' }}>
                    <MessageBar messageBarType={MessageBarType.info} styles={{ root: { display: 'flex', alignItems: 'center', marginBottom: 5 } }} >
                        {props.pickerMessage}
                    </MessageBar>
                    <DetailsList
                        isHeaderVisible={false}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        columns={_columns}
                        items={props.files}
                    />
                </Stack.Item>
            </Stack>
        </div>
    );
}