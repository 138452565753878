import { CancelToken } from "axios";
import QueryString from "qs";
import { apiClient } from "../../../modules/apiClient/apiClient";
import { svcPaths } from "../../../modules/apiClient/config";
import { Activity } from "../../models/activity";
import { User } from "../../models/user";
import { CreateActivityRequest, GetActivityAvaiableLeadersRequest } from "./activities.contracts";

class ActivitiesApiClass {
    async createActivity(request: CreateActivityRequest) {
        const response = await apiClient.post<Activity>(`${svcPaths.activities}/activities`, request);
        return response.data;
    }

    async getActivityAvaiableLeaders(request: GetActivityAvaiableLeadersRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<User[]>(`${svcPaths.activities}/activities/${request.portfolioId}/available-leaders`, {
            params: {
                keyword: request.keyword,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                excludeCurrentUser: request.excludeUser,
                userIds: request.userIds
            },
            paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken
        });
        return response.data;
    }

    async CheckExpiringActivitiesOrPhase(cancelToken?: CancelToken) {
        const response = await apiClient.post(`${svcPaths.activities}//activities/checkexpiringactivitiesorphase`, {
            cancelToken: cancelToken
        });
        return response.data;
    }
}

export const activitiesApi = new ActivitiesApiClass();