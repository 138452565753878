import { IStyleFunction } from "@fluentui/react";
import { ILabelRangePropsStyles, ILabelRangeStyles } from "./labelRange.types";

export const LabelRangeGlobalClassNames = {
    root: 'edi-label-range-root',
    errorPrefix: 'edi-label-range-error-prefix'
}

export const getStyles: IStyleFunction<ILabelRangePropsStyles, ILabelRangeStyles> = (props: ILabelRangePropsStyles): ILabelRangeStyles => {
    const { palette, semanticColors } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            LabelRangeGlobalClassNames.root,
            {

            }
        ],
        errorPrefix: [
            LabelRangeGlobalClassNames.errorPrefix,
            {
                ' .ms-TextField-prefix': {
                    backgroundColor: semanticColors.errorText,
                }
            }
        ],
        subComponentStyles: {
            range: {
                field: {
                    width: '175px',
                    maxWidth: '175px'
                },
                prefix: {
                    backgroundColor: palette.themePrimary,
                    color: palette.white
                },
                errorMessage: {
                    position: 'absolute'
                }
            }
        }
    };
}