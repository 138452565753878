import { styled } from "@fluentui/react";
import { EditViewProfileMemberBase } from "./editViewProfileMember.base";
import { getStyles } from "./editViewProfileMember.styles";
import { IEditViewProfileMemberBaseProps, IEditViewProfileMemberBasePropsStyles, IEditViewProfileMemberBaseStyles } from "./editViewProfileMember.types";

const EditViewProfileMember = styled<IEditViewProfileMemberBaseProps, IEditViewProfileMemberBasePropsStyles, IEditViewProfileMemberBaseStyles>(
    EditViewProfileMemberBase, 
    getStyles,
    undefined,
    { scope: 'EditViewProfileMember'}
)

export default EditViewProfileMember;