import { styled } from "@fluentui/react";
import { IFeedbackNerPanelProps, IFeedbackNerPanelPropsStyles, IFeedbackNerPanelStyles } from "./feedbackNerPanel.types";
import { FeedbackNerPanelBase } from "./feedbackNerPanel.base";
import { getStyles } from "./feedbackNerPanel.styles";

const FeedbackNerPanel = styled<IFeedbackNerPanelProps, IFeedbackNerPanelPropsStyles, IFeedbackNerPanelStyles>(
    FeedbackNerPanelBase,
    getStyles,
    undefined,
    { scope: 'FeedbackNerPanel' }
);

export default FeedbackNerPanel;