import { IStyleFunction } from "@fluentui/react"
import { ICustomWidgetDatePropsStyles, ICustomWidgetDateStyles } from "./customWidgetDate.types"

export const CustomWidgetDateGlobalClassNames = {
    root: 'edi-custom-widget-date-root'
}

export const getStyles: IStyleFunction<ICustomWidgetDatePropsStyles, ICustomWidgetDateStyles> = (props: ICustomWidgetDatePropsStyles): ICustomWidgetDateStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            CustomWidgetDateGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        label:{
            padding:'5px 0'
        },
        errorLabel:{
            color: palette.redDark
        },
        required:{
            color: palette.redDark,
            paddingLeft: '5px'
        },
        resetIconStyle:{
            padding: "10px 5px 10px !important",
            fontSize: 10,
            cursor: "pointer"
        }
    };
}