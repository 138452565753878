import { styled } from "@fluentui/react";
import { SearchFolderModalBase } from "./searchFolderModal.base";
import { getStyles } from "./searchFolderModal.style";
import { ISearchFolderModalBasePropsStyles, ISearchFolderModalBaseStyles, ISearchFolderModalBaseProps } from "./searchFolderModal.types";

const SearchFolderModal = styled<ISearchFolderModalBaseProps, ISearchFolderModalBasePropsStyles, ISearchFolderModalBaseStyles>(
    SearchFolderModalBase, 
    getStyles,
    undefined,
    { scope: 'SearchFolderModal'}
)

export default SearchFolderModal;