import { IStyleFunction } from "@fluentui/react";
import { IAdvancedDropdownPropsStyles, IAdvancedDropdownStyles } from "./advancedDropdown.types";

export const AdvancedDropdownGlobalClassNames = {
    root: 'edi-set-properties-root',
    dropdownItem: 'edi-set-properties-dropdownItem',
    dropdownItemMultiselect: 'edi-set-properties-dropdownItemMultiselect',
    cancelIcon: 'edi-set-properties-CancelIcon',
    chevronDownIcon: 'edi-set-properties-chevronDownIcon',
    checkboxItems: 'edi-set-properties-checkboxItems',
    dropdownElement: 'edi-set-properties-dropdownElement'
}

export const getStyles: IStyleFunction<IAdvancedDropdownPropsStyles, IAdvancedDropdownStyles> = (props: IAdvancedDropdownPropsStyles): IAdvancedDropdownStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            AdvancedDropdownGlobalClassNames.root,
            {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 20,
                alignItems: 'center'
            }
        ],
        dropdownItem: [AdvancedDropdownGlobalClassNames.dropdownItem, {
            width: '50%'
        }],
        dropdownItemMultiselect: [AdvancedDropdownGlobalClassNames.dropdownItemMultiselect, {
            width: '100%',
            minWidth: '75%'
        }],
        cancelIcon: [AdvancedDropdownGlobalClassNames.cancelIcon, {
            color: "rgb(96, 94, 92)",
            paddingRight: ".7em",
            "&:hover": {
                fontWeight: 800
            }
        }],
        chevronDownIcon: [AdvancedDropdownGlobalClassNames.chevronDownIcon, {
            color: "rgb(96, 94, 92)",
            "&:hover": {
            fontWeight: 800
            }
        }],
        checkboxItems:[AdvancedDropdownGlobalClassNames.checkboxItems, {
            height: '100%',
            alignItems: 'center'
        }],
        dropdownElement: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        subComponentStyles: {
            
            dropdown: {
                root: {
                    padding: '5px 0',
                },
                label: {
                    color: palette.black,
                    fontWeight: 400
                },
                title: {},
                caretDownWrapper: {},
                caretDown: {},
                errorMessage: {},
                dropdownItemsWrapper: {
                    maxHeight: '250px',
                },
                dropdownItems: {},
                dropdownItem: {},
                dropdownItemSelected: {},
                dropdownItemDisabled: {},
                dropdownItemSelectedAndDisabled: {},
                dropdownItemHidden: {},
                dropdownOptionText: {},
                dropdownDivider: {},
                dropdownItemHeader: {},
                panel: {},
                callout: {
                    '*::-webkit-scrollbar': {
                        width: 16,
                        height: 16
                    },
                    '*::-webkit-scrollbar-thumb': {
                        border: '5px solid transparent',
                        backgroundClip: 'padding-box',
                        backgroundColor: 'transparent',
                        borderRadius: 25
                    },
                    '*:hover::-webkit-scrollbar-thumb': {
                        backgroundColor: palette.neutralSecondary// 'rgba(66, 66, 66, 0.3)'
                    },
                    '*::-webkit-scrollbar-thumb:hover': {
                        border: '2px solid transparent'
                    }
                },
                subComponentStyles: {
                    label: {},
                    multiSelectItem: {},
                    panel: {}
                }
            },
        }
    };
}