import { IStyleFunction } from "@fluentui/react"
import { IActivityTypePropsStyles, IActivityTypeStyles } from "./activityType.types"

export const ActivityTypeGlobalClassNames = {
    root: 'edi-activity-type-root'
}

export const getStyles: IStyleFunction<IActivityTypePropsStyles, IActivityTypeStyles> = (props: IActivityTypePropsStyles): IActivityTypeStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            ActivityTypeGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        propertyName: {
            padding: '4px',
            paddingLeft: '10px',
            paddingRight: '10px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            // width: 'fit-content',
            // maxWidth: '120px',
            maxWidth: 'max-content',
            textOverflow: 'ellipsis',
            backgroundColor: palette.themePrimary,
            color: palette.white,
            borderRadius: '4px'
        },
        iconContainer: {
            position: 'absolute',
            paddingTop: 1
        }
    };
}