/*eslint-disable sonarjs/cognitive-complexity */
import React, { useMemo, useState } from "react";
import { classNamesFunction, DefaultButton, DetailsHeader, DetailsList, DetailsRow, Dialog, DialogFooter, IColumn, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack, StackItem, TooltipHost, TooltipOverflowMode } from "@fluentui/react";
import { IExportArchiveModalPropsStyles, IExportArchiveModalStyles, IExportArchiveModalProps } from "./exportMetadataModal.types";
import { useDocLabDispatch, useDocLabState } from "../../../../docLabStore";
import { showExportMetadataModal } from "../../../../features/archiveContent";
import MultiStepModal from "../../../../../common/components/multiStepModal/multiStepModal";
import { useCurrentArchive } from "../../../../hooks/useCurrentArchive";
import { useOnMount } from "../../../../../utilities/hooks";
import { IModalStepData } from "../../../../../common/components/multiStepModal/multiStepModal.types";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { useTranslation } from "react-i18next";
import { archivesApi } from "../../../../services/archives/archives.api";
import { CreateReportMetadataRequest } from "../../../../services/archives/archives.contracts";
import { JsonSchemaField, ReportType } from "../../../../models/archive";
import { IObjectWithKey, ISelection, Selection } from '@fluentui/react/lib/DetailsList';
import { MetadataSelect } from "../../../../models/metadataDependency";

const getClassNames = classNamesFunction<IExportArchiveModalPropsStyles, IExportArchiveModalStyles>();

enum ExportArchiveSteps {
    homepage = 4,
    metadataWork = 0,
    exporting = 1,
}

export const ExportMetadataModalBase = (props: IExportArchiveModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['exportArchiveModal', 'common']);
    const dispatch = useDocLabDispatch();
    const currentArchive = useCurrentArchive();
    const docLabState = useDocLabState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [count, setCount] = useState(0);
    const [showDialog, setShowDialog] = useState(false);
    const [selectedList, setSelectedList] = useState<MetadataSelect[]>([]);
    const [metadataList, setMetadataList] = useState<MetadataSelect[]>([]);
    const [currentStep, setCurrentStep] = useState<ExportArchiveSteps>(ExportArchiveSteps.metadataWork)

    useOnMount(() => {
        if (!currentArchive)
            return;
        const list: MetadataSelect[] = [];
        const keysMetadata = currentArchive.metadataJsonSchemaFields;
        keysMetadata.forEach((field: JsonSchemaField) => {
            list.push({
                metadata: field.name,
                title: field.title
            })
        });
        setMetadataList([...list]);
    })

    const getSelectedItems = useMemo((): ISelection => {
        const selection: Selection = new Selection({
            onSelectionChanged: () => {
                const selectionDetails = selection.getSelection();
                const res: MetadataSelect[] = [];
                selectionDetails.forEach(i => {
                    const item = i as MetadataSelect;
                    res.push(item)
                })
                setSelectedList(res);
            }
        });
        const items = [...selectedList];
    
        selection.setItems(items as IObjectWithKey[]);
        items.forEach((selectedItem,ind) => { 
             selection.setKeySelected(ind.toString(), true, false);
        });
        return selection;
    },[dispatch, currentStep])  //eslint-disable-line react-hooks/exhaustive-deps

    const metadataWorkContent = () => {
        return <div style={{ height: 'calc(100% - 10px)', overflow: 'hidden' }}>
            <DetailsList
                items={metadataList}
                getKey={(key: MetadataSelect) => key.metadata}
                columns={stepColumn}
                styles={classNames.subComponentStyles.detailsList}
                isHeaderVisible={true}
                onRenderDetailsHeader={props => props ? <DetailsHeader styles={classNames.subComponentStyles.detailsHeader} {...props} /> : null}
                setKey="none"
                selection={getSelectedItems}
                selectionMode={SelectionMode.multiple}
                onShouldVirtualize={() => false}
                onRenderRow={props => props ? <DetailsRow {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
        /></div>
    }

    const createExportDate = async () => {
        if (!currentArchive)
            return null;
        setCurrentStep(ExportArchiveSteps.exporting);
        try {
            setLoading(true);
            setError(false);
            const list = [...selectedList];
            const request: CreateReportMetadataRequest = {
                archiveReportId: docLabState.archiveContent.selectedReportIdExportShown,
                type: ReportType.Export,
                additionalInput:{
                    OptionalColumns: list.map(l=>l.metadata)
                } 
            }
            await archivesApi.createReportMetadata(request);
        }
        catch (ex: any) { //eslint-disable-line @typescript-eslint/no-explicit-any
            setCount(count + 1);
            console.log("Error during saving metadata report: ", ex.message);
            setError(true);
        }
        finally {
            setLoading(false);
        }
    }
    
    const metadataWorkFooter = () => {
        return <Stack>
            <StackItem className={classNames.buttonContainer} >
                <DefaultButton onClick={() => {
                    dispatch(showExportMetadataModal({show: false, reportId: 0}));
                    setShowDialog(false)
                }}>{t('common:previousButton')}</DefaultButton>
                <PrimaryButton
                    onClick={async () => { await createExportDate() }}
                    disabled={selectedList.length < 1}>{t('common:nextButton')}</PrimaryButton>
            </StackItem>
        </Stack>
    }

    const stepColumn: IColumn[] = [
        {
            key: 'title',
            name: t('fieldTitle'),
            ariaLabel: 'title',
            fieldName: 'title',
            minWidth: 130,
            maxWidth: 250,
            styles: classNames.subComponentStyles.columnHeader,
            isResizable: false,
            onRender: function getMetadata(item: MetadataSelect) {
                return <span>
                    <TooltipHost
                        overflowMode={TooltipOverflowMode.Parent}
                        content={item.metadata}>{item.title}
                    </TooltipHost>
                </span>
            }
        },
        {
            key: 'metadata',
            name: t('fieldName'),
            ariaLabel: 'metadata',
            fieldName: 'metadata',
            minWidth: 130,
            maxWidth: 250,
            styles: classNames.subComponentStyles.columnHeader,
            isResizable: false,
            onRender: function getMetadata(item: MetadataSelect) {
                return <span>
                    <TooltipHost
                        overflowMode={TooltipOverflowMode.Parent}
                        content={item.metadata}>{item.metadata}
                    </TooltipHost>
                </span>
            }
        }
    ];

    const renderError = () => {
        return <>
            <TeamsImage imageName={ImageName.Error1} className={classNames.sandglassImage} fullContainer scale={0.8} />
            <div className={classNames.exportingFooter}>
                {t('operationFailed')}
                <div style={{ marginTop: 10 }} className={classNames.buttonContainer} >
                    <DefaultButton text={t('common:close')}
                        allowDisabledFocus
                        onClick={() => dispatch(showExportMetadataModal({show: false, reportId: 0}))}
                    />
                    {count < 3 && <PrimaryButton text={t('common:retry')}
                        allowDisabledFocus
                        onClick={async () => await createExportDate()}
                    />}
                </div>
            </div></>
    }

    const exportingContent = () => {
        return <div className={classNames.secondFooterContainer}>
            {loading ?
                <div className={classNames.centerContentContainer}><Spinner size={SpinnerSize.large} /></div> :
                error ?
                    renderError() :
                    <><TeamsImage imageName={ImageName.Sandglass} className={classNames.sandglassImage} fullContainer scale={0.8} />
                        <div className={classNames.exportingFooter}>
                            {t('operationInProgress')}
                            <div>
                                <PrimaryButton text={t('common:leave')}
                                    allowDisabledFocus
                                    onClick={() => dispatch(showExportMetadataModal({show: false, reportId: 0}))}
                                    styles={{ root: { padding: '0px 80px', marginTop: '20px' } }}
                                />
                            </div>
                        </div></>}
        </div>
    }
    
    const steps: IModalStepData[] = [
        {
            title: t('titleMetadataWork'),
            body: metadataWorkContent(),
            footer: metadataWorkFooter()
        },
        {
            bodyOnly: true,
            body: exportingContent()
        }
    ]

    return (
        <>
            <MultiStepModal
                isOpen
                showCloseIcon
                width={currentStep === ExportArchiveSteps.homepage ? 'auto' : 600}
                height={currentStep === ExportArchiveSteps.homepage ? 'auto' :
                    currentStep === ExportArchiveSteps.metadataWork ? 600 : 450}
                styles={{
                    footer: {
                        marginTop: "0px !important"
                    },
                    content:{
                        minHeight: 190,
                        minWidth: 400
                    }
                }}
                hideScrollbarBody
                onCloseClick={currentStep === ExportArchiveSteps.homepage ? () => dispatch(showExportMetadataModal({show: false, reportId: 0})) : () => setShowDialog(true)}
                activeStep={currentStep}
                steps={steps}
                animateInitialStep
            />
            <Dialog
                hidden={!showDialog}
                dialogContentProps={{ title: `${t('stopCreating')}` }}
            >
                <DialogFooter>
                    <DefaultButton onClick={() => setShowDialog(false)} text={t('common:dialogButtonCancel')} />
                    <PrimaryButton onClick={() => dispatch(showExportMetadataModal({show: false, reportId: 0}))} text={t('common:dialogButtonConfirm')} />
                </DialogFooter>
            </Dialog>
        </>
    );
}
