import { styled } from "@fluentui/react";
import { ExportModalScadenzaBase } from "./ExportModalScadenza.base";
import { getStyles } from "./ExportModalScadenza.styles";
import { IExportModalScadenzaBaseProps, IExportModalScadenzaBasePropsStyles, IExportModalScadenzaBaseStyles } from "./ExportModalScadenza.types";

const ExportModalScadenza = styled<IExportModalScadenzaBaseProps, IExportModalScadenzaBasePropsStyles, IExportModalScadenzaBaseStyles>(
    ExportModalScadenzaBase,
    getStyles,
    undefined,
    { scope: 'ExportModalScadenza'}
)

export default ExportModalScadenza;