
import React, { useCallback, useState } from "react";

import CreateDatasetModal from "../../feedback/createDatasetModal/createDatasetModal";
import { classNamesFunction, CommandBar, ICommandBarItemProps, Pivot, PivotItem } from "@fluentui/react";
import { clearSelectedItems, setBaseFeedbackFilters, setRefresh, useFeedbackList } from "../../../features/feedbackList";
import { dashboardApi } from "../../../services/dashboard.api";
import { dashboardTab } from "../../../routes/routes";
import { resetDatasetFilters } from "../../../features/datasetList";
import { useDashboardDispatch } from "../../../dashboardStore";
import { useNavigator } from "../../../hooks/useNavigator";
import { useTranslation } from "react-i18next";

import { DashboardRoleId } from "../../../models/dashboardRoleId";
import { FeedbackStatus, FeedbackType } from "../../../models/feedback";
import { IToolbarProps, IToolbarPropsStyles, IToolbarStyles } from "./toolbar.types";
import FeedbackConfirmModal from "../../feedback/feedbackConfirmModal/feedbackConfirmModal";

const getClassNames = classNamesFunction<IToolbarPropsStyles, IToolbarStyles>();

export const ToolbarBase = (props: IToolbarProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    const { t } = useTranslation('dashboardToolbar');

    const navigator = useNavigator();
    const dispatch = useDashboardDispatch();
    const feedbackList = useFeedbackList();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
    const [action, setAction] = useState<FeedbackStatus>();

    const onClick = useCallback((item: PivotItem | undefined) => {
        if (item?.props.itemKey && navigator.currentTab !== item.props.itemKey) {
            navigator.changeTab(item.props.itemKey as dashboardTab);
            dispatch(setBaseFeedbackFilters())
            dispatch(resetDatasetFilters())
        }
    }, [dispatch, navigator]);

    // useEffect(() => {
    //     console.log("feedbackList.currentDashboardRoleId ", feedbackList.currentDashboardRoleId);

    // }, [feedbackList.currentDashboardRoleId])

    const rightTabs = useCallback(() => {
        return (
            <div style={{ height: '40px' }}>
                <Pivot
                    headersOnly={true}
                    styles={classNames.subComponentStyles.pivot}
                    selectedKey={navigator.currentTab ?? null}
                    onLinkClick={item => onClick(item)
                    }
                    style={{ height: '100%' }}
                >
                    {(feedbackList.currentDashboardRoleId !== DashboardRoleId.AdminDashboard) &&
                        <PivotItem key={dashboardTab.feedback} itemKey={dashboardTab.feedback} headerText={t('feedback')} style={{ height: '40px' }} />
                    }
                    {
                        (feedbackList.currentDashboardRoleId === DashboardRoleId.DataScientist) &&
                        <PivotItem key={dashboardTab.datasets} itemKey={dashboardTab.datasets} headerText={t('datasets')} />
                    }

                    {
                        (feedbackList.currentDashboardRoleId === DashboardRoleId.AdminDashboard ||
                            feedbackList.currentDashboardRoleId === DashboardRoleId.Owner) &&
                        <PivotItem key={dashboardTab.users} itemKey={dashboardTab.users} headerText={t('users')} />
                    }
                </Pivot >
            </div>
        );
    }, [classNames.subComponentStyles.pivot, feedbackList.currentDashboardRoleId, navigator, onClick, t]);

    const collapseAction: ICommandBarItemProps = {
        key: "menu",
        iconProps: { iconName: "CollapseMenu" },
        onClick: props.onMenuButtonClick
    }

    const approveFeedbackAction: ICommandBarItemProps = {
        key: "approveFeedback",
        text: t('actions.approveFeedback'),
        iconProps: { iconName: "StatusCircleCheckmark", style: { fontSize: 20 } },
        onClick: () => {
            setIsOpenConfirmModal(true);
            setAction(FeedbackStatus.Approved);
            //updateFeedbackStatus(FeedbackStatus.Approved)
        },
        disabled: !(feedbackList?.selectedItems?.length > 0
            && feedbackList.selectedItems.every(f =>
                f.feedbackStatus === FeedbackStatus.Pending ||
                f.feedbackStatus === FeedbackStatus.Rejected))
    }

    const approveFeedbackForDatasetAction: ICommandBarItemProps = {
        key: "approveFeedbackForDataset",
        text: t('actions.approveFeedbackForDataset'),
        iconProps: { iconName: "StatusCircleCheckmark", style: { fontSize: 20 } },
        onClick: () => {
            setIsOpenConfirmModal(true);
            setAction(FeedbackStatus.ReadyForDataset);
            //updateFeedbackStatus(FeedbackStatus.ReadyForDataset);
        },
        disabled: !(feedbackList?.selectedItems?.length > 0
            && feedbackList.selectedItems.every(f =>
                (f.feedbackStatus === FeedbackStatus.Pending
                    || f.feedbackStatus === FeedbackStatus.Rejected
                    || f.feedbackStatus === FeedbackStatus.Approved)
                && f.feedbackType !== FeedbackType.NER))

    }

    const rejectFeedbackAction: ICommandBarItemProps = {
        key: "rejectFeedback",
        text: t('actions.rejectFeedback'),
        iconProps: { iconName: "StatusCircleErrorX", style: { fontSize: 20 } },
        onClick: () => {
            setIsOpenConfirmModal(true);
            setAction(FeedbackStatus.Rejected);
            //updateFeedbackStatus(FeedbackStatus.Rejected)
        },
        disabled: !(feedbackList?.selectedItems?.length > 0
            && feedbackList.selectedItems.every(f =>
                f.feedbackStatus === FeedbackStatus.Pending ||
                f.feedbackStatus === FeedbackStatus.Approved ||
                f.feedbackStatus === FeedbackStatus.ReadyForDataset))
    }

    const createDatasetAction: ICommandBarItemProps = {
        key: "createDataset",
        text: t('actions.createDataset'),
        iconProps: { iconName: "AppIconDefaultAdd" },
        onClick: () => {
            setIsOpen(true);
        },
        disabled: !(feedbackList?.selectedItems?.length > 0
            && feedbackList.selectedItems.every(i =>
                i.feedbackStatus === FeedbackStatus.ReadyForDataset
                || i.feedbackStatus === FeedbackStatus.DatasetCreated)
            && feedbackList.selectedItems.every(i => i.skillName === feedbackList.selectedItems[0].skillName))
    }

    const updateFeedbackStatus = (status: FeedbackStatus, note?: string) => {

        dashboardApi.updateFeedback(feedbackList.selectedItems.map(f => f.id), status, undefined, note)
            .then(() => {
                dispatch(clearSelectedItems());
                dispatch(setRefresh(true));
            })
            .catch((e) => {
                console.log(e)
            });
    }

    const actions = () => {
        const acts = [] as ICommandBarItemProps[];

        acts.push(collapseAction);
        
        if (navigator.currentTab === dashboardTab.feedback) {
            if (feedbackList.currentDashboardRoleId === DashboardRoleId.DataScientist){
                acts.push(approveFeedbackForDatasetAction);
                acts.push(createDatasetAction);
            }
            else
                acts.push(approveFeedbackAction);

            acts.push(rejectFeedbackAction);
        }

        return acts;
    }

    return (
        <div className={classNames.root}>
            <CommandBar
                className={classNames.wrapper}
                items={actions()}
                farItems={[{ key: "right-tabs", commandBarButtonAs: rightTabs }]}
            >
            </CommandBar>

            <CreateDatasetModal isOpen={isOpen} onClose={() => {
                setIsOpen(false)
                dispatch(setRefresh(true))
            }} />
            <FeedbackConfirmModal
                isOpen={isOpenConfirmModal}
                onClose={() => setIsOpenConfirmModal(false)}
                onConfirm={(note) =>{
                    if(action)
                        updateFeedbackStatus(action, note);
                    setIsOpenConfirmModal(false)
                }}
                action={action}
            />
        </div>        
    );
}