import { IStyleFunction } from "@fluentui/react"
import { IGraphWorkflowStatusCustomFilterModalPropsStyles, IGraphWorkflowStatusCustomFilterModalStyles } from "./graphWorkflowStatusCustomFilterModal.types"

export const GraphWorkflowStatusCustomFilterModalGlobalClassNames = {
    root: 'edi-graph-workflow-status-custom-filter-modal-root'
}

export const getStyles: IStyleFunction<IGraphWorkflowStatusCustomFilterModalPropsStyles, IGraphWorkflowStatusCustomFilterModalStyles> = (props: IGraphWorkflowStatusCustomFilterModalPropsStyles): IGraphWorkflowStatusCustomFilterModalStyles => {
    return {
        root: [
            GraphWorkflowStatusCustomFilterModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}