import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import { DefaultButton, IButtonStyles, IconButton, ILabelStyleProps, ILabelStyles, IStyleFunctionOrObject, Label, mergeStyleSets, PrimaryButton, Stack, TooltipDelay, TooltipHost } from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EdiCallout from "../../../../../common/components/ediCallout/ediCallout";
import IconTag from "../../../../../common/components/iconTag/iconTag";
import { CheckListPage, CheckListPageData } from "../../../../../common/components/modalPages/checkListPage";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { useAsyncApi } from "../../../../../utilities/hooks";
import { Portfolio } from "../../../../models/portfolio";
import { TemplateActivity } from "../../../../models/templateActivity";
import { User } from "../../../../models/user";
import { portfolioApi } from "../../../../services/portfolio/portfolio.api";
import { templateApi } from "../../../../services/template/template.api";

export interface IMemberActivityTemplatesProps {
  member: User;
  itemIndex?: number;
  portfolio: Portfolio;
  loaderContainerStyle: string;
  templatesCountCircleStyle: IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles>;
  iconButtonStyles: IButtonStyles;
  onActivitiesSaved: () => void;
}

export const MemberActivityTemplates = (props: IMemberActivityTemplatesProps) => {
  const { member, portfolio, itemIndex, templatesCountCircleStyle, loaderContainerStyle, iconButtonStyles, onActivitiesSaved } = props;
  const { t } = useTranslation(["activityLabUsersSettings", 'common']);
  const [showMemberTemplatesEditor, setShowMemberTemplatesEditor] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState<number[]>([]);
  const [saveButtonDisabledStart, setSaveButtonDisabledStart] = useState<boolean>(true);
  const [saveButtonDisabledAdding, setSaveButtonDisabledAdding] = useState<boolean>(false);
  const calloutTarget = 'memberTemplatesEditorButton';


  const classNames = mergeStyleSets({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      columnGap: 10,
      rowGap: 5,
      //negli schermi zoomati o piccoli facciamo diventare colonne invece che righe la lista di template
      '@media(max-width:1182px)': {
        flexDirection: 'column',
        alignItems: 'baseline',
      }
    },
    teamsImage: {
      img: {
        width: '50%',
        height: '50%'
      }
    },
    iconTagLabel: {
      width: 100,
      overflow: 'hidden'
    },
    remainingIconTagContainer: {
      padding: '0 5px',
      width: '100%',
      textAlign: 'left',
      justifyContent: 'start'
    },
  });
  const iconButtonProps = {
    iconName: "Tag",
    styles: { root: { fontSize: 20 } }
  };

  const { execute: getAllTemplates, value: allTemplates, loading, error } =
    useAsyncApi<void, TemplateActivity[]>({
      func: async () => {
        if (!portfolio) return [] as TemplateActivity[];

        const result = (await templateApi.getTemplateList(portfolio.id)).filter(templates => templates.active);

        setSelectedTemplates(result?.filter(selected => member.templateActivities.some(t => t.id === selected.id)).map(selected => selected.id));

        return result;
      },
    });

  const addTemplatesToMember = async () => {
    setSaveButtonDisabledAdding(true);
    await portfolioApi.updateTemplateActivitiesForPortfolioMembers({
      portfolioId: portfolio.id,
      userId: member.id,
      templateActivities: selectedTemplates
    });
    setSaveButtonDisabledAdding(false);
    setShowMemberTemplatesEditor(false);
    onActivitiesSaved();
  }

  const openCallout = async () => {
    setShowMemberTemplatesEditor(true);
    await getAllTemplates();
  }

  const onCheckListDataChange = (data: number[]) => {
    setSelectedTemplates(data);
    if (setSaveButtonDisabledStart)
      setSaveButtonDisabledStart(false);
  }

  const calloutFooter = (
    <React.Fragment>
      <DefaultButton
        text={t("common:cancel")}
        style={{ marginRight: "2px" }}
        onClick={() => setShowMemberTemplatesEditor(false)}
      />
      <PrimaryButton
        text={t("common:apply")}
        onClick={addTemplatesToMember}
        disabled={loading || error !== null || (saveButtonDisabledStart && member.templateActivities.length === 0)
          || saveButtonDisabledAdding}
      />
    </React.Fragment>
  );

  const membersList = member.templateActivities.slice(0, 3);
  const remainingMembersList = member.templateActivities.slice(3);

  const remainingMembersTooltip = (): JSX.Element => {
    return (
      <Stack horizontalAlign={'start'} tokens={{ childrenGap: 2 }}>
        {remainingMembersList.map(m => <IconTag key={m.id} label={m.name} className={classNames.remainingIconTagContainer} noBackground />)}
      </Stack>);
  }

  return (
    <div className={classNames.container}>

      {membersList?.map((t: TemplateActivity) =>
        <IconTag key={t.id} label={t.name} labelClassName={classNames.iconTagLabel} tooltip />)}

      {remainingMembersList && remainingMembersList.length > 0 && (
        <TooltipHost
          id={'remainingTooltips'}
          content={remainingMembersTooltip()}
          delay={TooltipDelay.zero}>
          <Label styles={templatesCountCircleStyle}> +{remainingMembersList.length} </Label>
        </TooltipHost>
      )}
      {!portfolio.hasTemplates
        ? <TooltipHost
          id={'noTemplates'}
          content={t('createAtLeastOneTemplate')}
          delay={TooltipDelay.zero}>
          <IconButton disabled iconProps={iconButtonProps} styles={iconButtonStyles} />
        </TooltipHost>
        : <React.Fragment>
          <IconButton id={`${calloutTarget}${itemIndex}`} iconProps={iconButtonProps} styles={iconButtonStyles} disabled={showMemberTemplatesEditor}
            onClick={openCallout} />

          {showMemberTemplatesEditor &&
            <EdiCallout
              focusTrap
              target={`${calloutTarget}${itemIndex}`}
              targetType={'#'}
              title={t('templateSettings.title')}
              width={380}
              showCloseIcon
              onCloseClick={() => setShowMemberTemplatesEditor(false)}
              onDismissed={() => setShowMemberTemplatesEditor(false)}

              body={<React.Fragment>
                {loading && <div className={loaderContainerStyle}> <TeamsSpinner /> </div>}

                {!loading && error && <TeamsImage className={classNames.teamsImage}
                  imageName={ImageName.Error1} caption={t("common:genericErrorApi")} />}

                {!loading && !error && allTemplates !== undefined &&
                  <CheckListPage
                    onDataChange={onCheckListDataChange}
                    checklistData={allTemplates}
                    initialState={allTemplates.map((template: TemplateActivity) => {
                      return {
                        checked: member.templateActivities.some(mt => mt.id === template.id),
                        item: template
                      } as CheckListPageData<TemplateActivity>;
                    })}
                    maxHeight={200}
                    hasSelectAll
                    hasTextField
                  />}
              </React.Fragment>}
              footer={calloutFooter} />
          }
        </React.Fragment>}

    </div>
  );
};
