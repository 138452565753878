import { styled } from "@fluentui/react";
import { getStyles } from "./exportArchiveModal.styles";
import { IExportArchiveModalPropsStyles, IExportArchiveModalStyles, IExportArchiveModalProps } from "./exportArchiveModal.types";
import { ExportArchiveModalBase } from "./exportArchiveModal.base";

const ExportArchiveModal = styled<IExportArchiveModalProps, IExportArchiveModalPropsStyles, IExportArchiveModalStyles>(
    ExportArchiveModalBase,
    getStyles,
    undefined,
    { scope: 'ExportArchiveModal' }
);

export default ExportArchiveModal;