import { IStyleFunction } from "@fluentui/react"
import { IFormItemPropsStyles, IFormItemStyles } from "./formItem.types";

export const FormItemGlobalClassNames = {
    root: 'edi-activityLab-formItem-root',
    warning: 'edi-activityLab-formItem-warning',
    icon: 'edi-activityLab-formItem-icon',
    labelContainerClassname: 'edi-activityLab-formItem-labelContainerClassname'
}

export const getStyles: IStyleFunction<IFormItemPropsStyles, IFormItemStyles> = (props: IFormItemPropsStyles): IFormItemStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        subComponentStyles: {
            dropdown: {
                root: { padding: '5px 0', minWidth: props.alignHorizontal ? '200px' : '' }
            },
            datePicker:{
                root: {padding: '5px 0'}
            },
            textField:{
                errorMessage: {
                    position: 'absolute'
                },
                suffix: [
                    { background: 'transparent', }
                ]
            },
            icon: {
                root:{
                    color: palette.greenDark
                }
            },
            errorIcon: { root:{
                color: palette.redDark,
                cursor:'pointer'
            }}
        },
        warning: [FormItemGlobalClassNames.warning,{
            fontSize:12, 
            marginBottom:5,
            color: palette.orangeLighter
        }],
        labelContainerClassname: [FormItemGlobalClassNames,{
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between'
        }]
    };
}