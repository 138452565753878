/*eslint-disable sonarjs/cognitive-complexity*/
import TeamsSpinner from "@edi/fe-common/dist/components/teamsSpinner/teamsSpinner";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { knowledgeLabApi } from "../../../../knowledgeLab/services/knowledgeLab.api";
import { FileFormatExtension, filePreviewSubPath, FileType } from "../../../../utilities/constants";
import { Helpers } from "../../../../utilities/helpers";
import { ErrorsType } from "../../../models/callsApi";
import { archivesApi } from "../../../services/archives/archives.api";
import { previewResponse } from "../../../services/archives/archives.contracts";
import { IFilePreviewProps, IFilePreviewPropsStyles, IFilePreviewStyles } from "./filePreview.types";
import { dashboardApi } from "../../../../dashboard/services/dashboard.api";
import { PrimaryButton, classNamesFunction } from "@fluentui/react";

const getClassNames = classNamesFunction<IFilePreviewPropsStyles, IFilePreviewStyles>();

export const PreviewWrapper = (props: IFilePreviewProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const [loading, setLoading] = useState(false);
  const [documentUrl, setdocumentUrl] = useState<string>("");
  const [fileName, setfileName] = useState<string>("");
  const [fileExtension, setfileExtension] = useState<string>("");
  const [error, setError] = useState("");
  const { t } = useTranslation("common");
  const path = window.location.pathname.split("/");
  const fileId = path.pop();

  const subpath = path.pop()
  const isKnowledgeFile = subpath === filePreviewSubPath.knowledge;
  const isKnowledgeElement = subpath === filePreviewSubPath.element;
  const isFeedback = subpath === filePreviewSubPath.feedback;

  const noPreviewFile = [".p7m", ".tif", ".dwf"]

  const loadUrl = async () => {
    setdocumentUrl("");
    setError("");
    setLoading(true);
    try {
      if (!Helpers.isNumber(fileId)) {
        setdocumentUrl(decodeURIComponent(fileId || ""));
      }
      else {
        const [response] = await Promise.all([
          isKnowledgeFile ? knowledgeLabApi.preview(Number(fileId)) : isKnowledgeElement ? knowledgeLabApi.previewFromElement(Number(fileId)) : isFeedback ? dashboardApi.previewFromFeedback(Number(fileId)) : archivesApi.preview(Number(fileId)),
          Helpers.delay(500),
        ]);
        const tempResponse = calculateUrlPreview(response);
        setdocumentUrl(tempResponse);
      }
    } catch (er) {
      const error: ErrorsType = er as ErrorsType;
      switch (error.code) {
        case 403:
          setError("archiveLinkForbiddenMessage");
          break;
        case 404:
          setError("fileNotFound");
          break;
        default:
          setError("genericErrorApi");
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  const calculateUrlPreview = (preview: previewResponse) => {
    let url = "";
    const fileExtension = preview.name.substring(preview.name.lastIndexOf('.'), preview.name.length);
    setfileName(preview.name);
    setfileExtension(fileExtension);
    if(noPreviewFile.find(x => x === fileExtension)){
      url = "noPreviewFile";
      setError(url);
      return url;
    }

    switch (preview.mimeType) {
      case FileFormatExtension[FileType.Pdf].key:
        url = preview.url;
        break;

      case FileFormatExtension[FileType.Jpg].key:
        url = preview.url;
        break;

      case FileFormatExtension[FileType.Jpeg].key:
        url = preview.url;
        break;

      case FileFormatExtension[FileType.Zip].key:
        url = `${preview.url}?csf=1&web=1`;
        break;
      
      case FileFormatExtension[FileType.Rar].key:
        url = `${preview.url}?csf=1&web=1`;
        break;

      case FileFormatExtension[FileType.Msg].key:
        url = `${preview.url}?csf=1&web=1`;
        break;
      
      case FileFormatExtension[FileType.Eml].key:
        url = `${preview.url}?csf=1&web=1`;
        break;

      default:
        url = `${preview.sharepointSite}/_layouts/15/Doc.aspx?sourcedoc={${preview.uniqueId}}&file=${preview.name}&action=embedview&allowtyping=false&wddownloadbutton=false`;
        break;
    }

    return url;
  }

  useEffect(() => {
    loadUrl();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const downloadFile = async () => {    
    const id = fileId ? parseInt(fileId) : 0;
    const response = await archivesApi.downloadFile(id);
    const url = window.URL.createObjectURL(new Blob([response.data], { type: fileExtension }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
}

  const getTeamsImage = (imageName: ImageName) => {
    return (
      <div>
        <TeamsImage
          styles={{
            img: {
              marginTop: "200px",
              height: "25%",
              width: "25%",
            },
          }}
          imageName={imageName}
          fullContainer
          caption={t(error)}
        />
      </div>
    );
  }

  const getNoPreviewFileBody = () => {
    return (
      <>
        <div className={classNames.emptyFolder}>
          <TeamsImage
            styles={{
              img: {
                marginTop: "200px",
                height: "25%",
                width: "25%",
              },
            }}
            imageName={ImageName.EmptyFolderDrop}
          /> 
          <div style={{display: "flex", justifyContent: "center", textAlign: 'center'}}>  
            <span style={{color: 'rgb(37, 36, 35)', fontWeight: 400}}>{t("common:noPreview") + noPreviewFile.join(', ')}</span>
          </div> 
          <PrimaryButton 
          style={{margin: "auto", marginTop: 25, display: "flex", justifyContent: "center",}}
              onClick={() => downloadFile()}
          >
          {t("common:commands.downloadFile")}
          </PrimaryButton>
        </div>
      </>
    );
  }  
  
  !loading && documentUrl !== "" && documentUrl !== "noPreviewFile" && window.location.assign(documentUrl);
  switch (error) {
    case "archiveLinkForbiddenMessage":
      return getTeamsImage(ImageName.Forbidden);
    case ("genericErrorApi"):
      return getTeamsImage(ImageName.EmptySearch);
    case ("fileNotFound"):
      return getTeamsImage(ImageName.EmptySearch);
    case ("noPreviewFile"):
      return getNoPreviewFileBody();
    default:
      return (
        <TeamsSpinner
          styles={{
            root: {
              position: "absolute",
              top: "calc(50% - 19px)",
              left: "calc(50% - 19px)",
            },
          }}
        />
      );
  }
};

export const FilePreviewBase = (props: IFilePreviewProps) => {
  return <></>;
};
