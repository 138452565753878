import { styled } from "@fluentui/react";
import { getStyles } from "./labelRange.styles";
import { ILabelRangePropsStyles, ILabelRangeStyles, ILabelRangeProps } from "./labelRange.types";
import { LabelRangeBase } from "./labelRange.base";

const LabelRange = styled<ILabelRangeProps, ILabelRangePropsStyles, ILabelRangeStyles>(
    LabelRangeBase,
    getStyles,
    undefined,
    { scope: 'LabelRange' }
);

export default LabelRange;