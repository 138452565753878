import { styled } from "@fluentui/react";
import { KnowledgeLabListBase } from "./knowledgeLabList.base";
import { getStyles } from "./knowledgeLabList.styles";
import { IKnowledgeLabListProps, IKnowledgeLabListPropsStyles, IKnowledgeLabListStyles } from "./knowledgeLabList.types";


const knowledgeLabList = styled<IKnowledgeLabListProps, IKnowledgeLabListPropsStyles, IKnowledgeLabListStyles>(
    KnowledgeLabListBase,
    getStyles,
    undefined,
    { scope: 'KnowledgeLabList' }
);

export default knowledgeLabList;