import { IStyleFunction } from "@fluentui/react"
import { IRenameArchiveModalPropsStyles, IRenameArchiveModalStyles } from "./renameArchiveModal.types"

export const RenameElementModalGlobalClassNames = {
    root: 'edi-rename-element-modal-root'
}

export const getStyles: IStyleFunction<IRenameArchiveModalPropsStyles, IRenameArchiveModalStyles> = (props: IRenameArchiveModalPropsStyles): IRenameArchiveModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            RenameElementModalGlobalClassNames.root,
            {
            }
        ],
        innerContent: {
            display: 'flex',
            alignItems: 'baseline'
        },
        firstFooterContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        errorMessage: {
            color: palette.redDark,
            fontSize: '12px'
        },
        subComponentStyles: {
            textField: {
                errorMessage: {
                    position: 'absolute'
                },                
                root: {
                    minHeight: '55px',
                    overflow: 'hidden',
                    width: '100%'
                }
            }
        }
    }
}