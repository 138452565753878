import { styled } from "@fluentui/react";
import { ImportMetadataModalBase } from "./importMetadataModal.base";
import { getStyles } from "./importMetadataModal.styles";
import { IImportMetadataModalProps, IImportMetadataModalPropsStyles, IImportMetadataModalStyles } from "./importMetadataModal.types";

const ImportMetadataModal = styled<IImportMetadataModalProps, IImportMetadataModalPropsStyles, IImportMetadataModalStyles>(
    ImportMetadataModalBase,
    getStyles,
    undefined,
    { scope: 'ImportMetadataModal' }
);

export default ImportMetadataModal;