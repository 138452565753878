import { styled } from "@fluentui/react";
import { getStyles } from "./phase.styles";
import { IPhasePropsStyles, IPhaseStyles, IPhaseProps } from "./phase.types";
import { PhaseBase } from "./phase.base";

const Phase = styled<IPhaseProps, IPhasePropsStyles, IPhaseStyles>(
    PhaseBase,
    getStyles,
    undefined,
    { scope: 'Phase' }
);

export default Phase;