import { styled } from "@fluentui/react";
import { getStyles } from "./tagPanel.styles";
import { ITagPanelPropsStyles, ITagPanelStyles, ITagPanelProps } from "./tagPanel.types";
import { TagPanelBase } from "./tagPanel.base";

const TagPanel = styled<ITagPanelProps, ITagPanelPropsStyles, ITagPanelStyles>(
    TagPanelBase,
    getStyles,
    undefined,
    { scope: 'TagPanel' }
);

export default TagPanel;