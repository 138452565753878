/*eslint-disable sonarjs/cognitive-complexity */
import React from "react";
import { classNamesFunction, DefaultButton, Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";
import { resetCurrentAnalysis, setIsLoadingDeleteAnalysis, showDocAnalysisDeleteModal } from "../../../features/docAnalysisContent";
import { useDocLabDispatch } from "../../../docLabStore";
import { useTranslation } from "react-i18next";
import { Call, callsList, ErrorsType } from "../../../models/callsApi";
import { insertCall, setCall } from "../../../features/callNotification";
import { nanoid } from "@reduxjs/toolkit";
import { IDeleteAnalysisModalProps, IDeleteAnalysisModalPropsStyles, IDeleteAnalysisModalStyles } from "./deleteAnalysisModal.types";
import { useDocAnalysisContent } from "../../../features/docAnalysisContent";
import { docAnalyzesApi } from "../../../services/docAnalyzes/docAnalyzes.api";

const getClassNames = classNamesFunction<IDeleteAnalysisModalPropsStyles, IDeleteAnalysisModalStyles>();

export const DeleteAnalysisModalBase = (props: IDeleteAnalysisModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { deleteDocAnalysisModalShown, selectedDocAnalysis } = useDocAnalysisContent();
    const dispatch = useDocLabDispatch();
    const { t } = useTranslation(['deleteDocAnalysisModal', 'common']);


    const deleteItem = async () => {
        const deleteItemCall: Call = {
            type: callsList.deleteItem,
            nameOperation: t('operation'),
            errors: [
                { code: 403, message: t('notPermissions') },
                { code: 500, message: t('common:deleteGenericErrorApi').concat(t('ofFile')) }
            ]
        }
        const id = nanoid();
        const payload = { requestId: id, notification: deleteItemCall }
        dispatch(insertCall(payload));

        props.onClose && props.onClose();

        try {
            if(selectedDocAnalysis && selectedDocAnalysis.id) {
                await docAnalyzesApi.deleteAnalysis(selectedDocAnalysis.id);
                const successPayload = { requestId: id, success: true, message: t('successMessage') }
                dispatch(setCall(successPayload));
                dispatch(setIsLoadingDeleteAnalysis(true));
            }
        }
        catch (er) {
            const error : ErrorsType = er as ErrorsType;
            
            let errorMessage = '';
            if (error.code === 403) {
                errorMessage = t('notPermissions');
            }
            else if(error.code === 404) {
                errorMessage = t('notFound');
            }
            else if(error.code === 422) {
                errorMessage = t('Unprocessable');
            }
            else {
                errorMessage = t('common:genericErrorApi');
            }
            const failurePayload = { requestId: id, success: false, message: errorMessage }
            dispatch(setCall(failurePayload));
        }

        props.onConfirm && props.onConfirm();
        dispatch(resetCurrentAnalysis());
        dispatch(setIsLoadingDeleteAnalysis(false));
    }

    return (
        <Dialog
            isOpen={deleteDocAnalysisModalShown}
            dialogContentProps={{
                title: t('title'),
                showCloseButton: true,
                onDismiss: () => dispatch(showDocAnalysisDeleteModal(false)),
                subText: t('message'),
            }}
        >
            <div className={classNames.footerContainer}>
                <div className={classNames.buttonContainer}>
                    <DialogFooter styles={classNames.subComponentStyles.dialogFooterContainer}>
                        <DefaultButton onClick={() => dispatch(showDocAnalysisDeleteModal(false))} text={t('common:dialogButtonCancel')} />
                        <PrimaryButton styles={classNames.subComponentStyles.primaryButtonDisabled()} onClick={deleteItem} text={t('common:dialogButtonConfirmDeleting')} />
                    </DialogFooter>
                </div>
            </div>
        </Dialog>

    );
}
