/*eslint-disable sonarjs/no-duplicate-string */
import { ActivityPriority, ActivityStatus, Constants as DocLabConstants } from "../../../activityLab/models/constants";
import { WorkFlowRelativeStatus } from "../../../activityLab/models/workflow";
import { WorkFlowApproverStatus } from "../../../activityLab/models/workflowApprover";
import { WorkFlowStepRelativeStatus, WorkFlowStepTypes } from "../../../activityLab/models/workflowStep";
import { UploadErrors } from "../../../docLab/components/files/uploadFileModal/uploadFileModal.types";
import { AnalysisDocError, AnalysisDocWarning, Constants, FormalAspects, GlossaryExcelDataValidationErrors, ItemToCopy, Labels } from "../../../docLab/models/constants";
import { AnalysisTypeId } from "../../../docLab/models/docAnalysis";

export const it = {
    authentication: {
        errorMessage1: 'Si è verificato un errore durante l\'autenticazione.',
        errorMessage2: 'Controlla che siano stati dati i permessi per l\'apertura dei popup.',
        accessDenied: "Accesso all'applicativo non consentito. Contattare l'amministratore per farsi abilitare"
    },

    search: {
        searchBox: "Ricerca Edi",
        searchBoxKnowledge: 'Ricerca',
        createdBy: 'Creato da',
        createdOn: 'Data caricamento',
        relevance: 'Rilevanza',
        alphabetAsc: 'Creato da (A-Z)',
        alphabetDesc: 'Creato da (Z-A)',
        createdOnAsc: 'Meno recenti',
        createdOnDesc: 'Più recenti',
        errorMessage: 'Inserisci almeno due caratteri',
        filterMessage: 'Inserisci almeno due caratteri',
        title: 'Filtri di ricerca',
        knowledgeTitle: 'Ricerca filtri ed entità',
        docLabMultiselectPlaceholder: 'Seleziona gli Archivi',
        advancedDropdownLabel: 'Seleziona gli archivi su cui effettuare la ricerca',
        resetSearch: 'Reset ricerca',
        restartSearch: 'Ricomincia ricerca',
        refreshResults: 'Aggiorna risultati',
        documentArchive: 'Archivio del Documento',
        approvalStatus: 'Stato di Approvazione',
        additionalData: {
            contractSignDate: 'Data firma',
            legalSystem: 'Sistema giuridico',
            contractType: 'Tipo contratto',
            views: 'N° consultazioni',
        },
        commands: {
            open: 'Apri',
            openInBrowser: 'Apri nel browser',
            translateDocument: 'Traduci documento',
            createLink: 'Copia collegamento',
            copyDocumentId: 'Copia ID Documento',
            viewTag: "Visualizza Tag",
            download: 'Scarica',
            viewFile: 'Visualizza documento',
            downloadFile: 'Scarica documento',
            delete: 'Elimina',
            move: 'Sposta',
            copy: 'Copia',
            addToFavourites: 'Aggiungi ai preferiti',
            removeFromFavourites: 'Rimuovi dai preferiti',
            details: 'Apri dettaglio',
            addToCompare: 'Aggiungi per confrontare'
        },
        filters: {
            standard: {
                full: 'Entrambi',
                metadata: 'Ricerca nei metadati',
                text: 'Ricerca nel testo'
            },
            ranges: {
                range_1: 'Ultime 24 ore',
                range_2: 'Ultima settimana',
                range_3: 'Ultimo mese',
                range_4: 'Ultimi 6 mesi',
                range_5: 'Ultimo anno',
                range_6: 'Altro',
            },
            approvalStatus: {
                value_0: 'NA',
                value_1: 'Da Approvare',
                value_2: 'Approvato',
                value_3: 'Rigettato',
                value_4: 'Migrato'
            },
            alreadyApproved: {
                true: "Si",
                false: "No"
            },
            formalAspects: {
                from: 'Da',
                to: 'A',
                [FormalAspects[0].key]: 'Tabelle',
                [FormalAspects[1].key]: 'Paragrafi',
                [FormalAspects[2].key]: 'Pagine',
                [FormalAspects[3].key]: 'Parole',
                [FormalAspects[4].key]: 'Immagini',
            },
            folderFilter: {
                allFolderDefault: 'Tutte le cartelle',
                crossFolderSearch: 'Ricerca i risultati su:',
                folderModal: 'Seleziona',
                primaryTitle: 'Seleziona le cartelle su cui vuoi effettuare la ricerca',
                secondaryTitle: 'Selezione delle cartelle',
                description: 'Clicca sulla cartella in cui desideri visualizzare i risultati di ricerca',
                save: 'Seleziona',
                cancel: 'Annulla',
                warningMessage: 'Ricorda che selezionando le cartelle non sarà possibile effettuare la ricerca su più archivi'
            },
            filter: "FILTRA I RISULTATI",
            resetFilter: "Reset Filtri",
            showMore: "Mostra tutto",
            showLess: "Mostra meno"
        },
        multiLanguage: {
            labelCheckBox: "Mostra risultati in altre lingue",
            tooltipLabel: "Cerca documenti in Italiano, Inglese, Spagnolo o Francese"
        }
    },

    activity: {
        status: {
            [ActivityStatus.Planned]: 'Pianificati',
            [ActivityStatus.OnGoing]: 'In Corso',
            [ActivityStatus.Blocked]: 'Bloccati',
            [ActivityStatus.Closed]: 'Chiusi',
        },
        priority: {
            [ActivityPriority.P1]: 'Bassa priorità',
            [ActivityPriority.P2]: 'Media priorità',
            [ActivityPriority.P3]: 'Alta priorità',
        },
        activitiesBoard: {
            startDate: 'Data Inizio:',
            closeDate: 'Data Chiusura:',
            activityExpired: "Il Progetto non è stato concluso in tempo",
            activityNotStarted: 'Il Progetto non è stato avviato in tempo',
            dueDate: 'Data Scadenza:'
        },
        activitiesList: {
            column: {
                name: "Titolo Progetto",
                template: "Tipologia Progetto",
                createdOn: "Data Creazione",
                members: "Membri",
                status: "Contenitore"
            },
        },
    },

    workflow: {
        status: {
            [WorkFlowRelativeStatus.Ongoing]: 'Ongoing',
            [WorkFlowRelativeStatus.Approved]: 'Approvati',
            [WorkFlowRelativeStatus.Refused]: 'Rifiutati',
            [WorkFlowRelativeStatus.ToMe]: "In carico all'utente",
        },
        sentWorkflowRequests: 'Richieste di Workflow inviate',
        receivedWorkflowRequests: 'Richieste di Workflow ricevute',
        filters: {
            filterBy: 'Filtra per:',
            all: 'Tutto',
            lastMonth: 'Ultimo mese',
            lastWeek: 'Ultima settimana',
            custom: 'Personalizzato',
            startDate: 'Da',
            endDate: 'A',
            selectDay: 'Seleziona un giorno'
        }
    },


    portfoliosList: {
        title: 'I tuoi portfolio',
        createNew: 'Crea portfolio',
        commands: {
            settingsPortfolio: 'Impostazioni Project Lab',
            settingsUsers: 'Gestisci utenti',
            viewUsers: "Visualizza Utenti",
            renamePortfolio: "Rinomina"
        }
    },

    archiveList: {
        title: 'I tuoi Doc Lab',
        createNew: 'Crea Doc Lab',
        commands: {
            archiveSettings: "Impostazioni Doc Lab",
            viewTag: "Visualizza Tag",
            deleteArchive: "Elimina",
            exportArchive: "Report",
            importExport: "Import/Export",
            renameArchive: "Rinomina",
            docAnalysisSettings: "Impostazioni Doc Analysis",
            recycleBin: "Cestino",
            search: "Ricerca",
            copyLink: "Copia Collegamento",
            download: "Scarica",
            delete: "Elimina",
            manageTopicsOfInterest: "Gestisci Argomenti di interesse",
            manageInterests: "Gestisci i tuoi interessi"
        },
        tipForAddMembership: "Inizia a digitare un nome",
        ruleForAddMembership: "Puoi aggiungere persone interne all' organizzazione ENI.",
    },

    topicOfInterest: {
        manageTopics: 'Gestisci argomenti di interesse',
        manageInterests: 'Gestisci i tuoi interessi',
        topics: 'Argomenti',
        updateTopicsOfInterest: 'Aggiorna argomenti di interesse',
        searchTopic: 'Cerca argomento',
        deleteTopicsTitle: 'Cancella Argomenti',
        deleteTopicsMessage: 'Sei sicuro di voler cancellare tutti gli argomenti a te associati?',
        selectTopics: 'Seleziona gli argomenti per i quali vuoi ricevere la notifica',
        leave: 'Esci',
        viewTopics: 'Vista per argomenti',
        viewTopicUser: 'Associazione argomento utenti',
        topicSearch: 'Ricerca argomenti',
        topicInfo: 'Info Argomento',
        createNewTopic: 'Crea nuovo argomento',
        noTopic: 'Nessun argomento trovato',     
        KeyPhraseLab: 'KeyPhrases di interesse', 
        deleteTopicTitle: 'Cancella Argomento',
        deleteTopicMessage: 'Sei sicuro di voler cancellare questo argomento?',
        deleteErrorMessage: 'Associazione utente-argomento trovata',
        associationTopicUsers: 'Associazione argomento - Utenti',
        nameLabel: 'Nome',
        topicsLabel: 'Argomenti',
        titleAddUsersModal: "Assegna utente all'argomento",
        description1AddUsers: "Inizia a digitare un nome da associare all'argomento di interesse, affinchè possa essere notificato",
        description2AddUsers: "Puoi aggiungere gli utenti che sono abilitati all'archivio",
        minLengthAlert: 'Inserisci almeno 3 caratteri',
        noResults: 'Nessun utente trovato',
        deleteUserFromTopicTitle: 'Cancella Utente',
        deleteUserFromTopicSubtitle: "Sei sicuro di voler cancellare questo utente associato all'argomento?",
        deleteUsersFromTopicTitle: 'Cancella Utenti',
        deleteUsersFromTopicSubtitle: "Sei sicuro di voler cancellare tutti gli utenti associati all'argomento?",
        newTopic: {
            title:'Crea un nuovo Argomento',
            updateTitle: 'Modifica Argomento',
            subtitle: 'Inserisci i dettagli del nuovo Argomento',
            subtitleMetadata: 'Se sono presenti più valori per i metadati, inserisci tutti i valori separati da virgole',
            nameLabel: 'Nome',
            insertTopicName: 'Inserisci il nome del nuovo Argomento',
            descriptionLabel: 'Descrizione',
            cancel: 'Chiudi',
            next: 'Avanti',
            back: 'Indietro',
            save: 'Salva',
            metadataLabel: 'Metadato',
            metadataValues: 'Valori'
        }
    },

    knowledgeList: {
        title: 'I tuoi Knowledge Labs',
        createNew: 'Crea Knowledge Lab',
        commands: {
            archiveSettings: "Impostazioni Doc Lab",
            viewTag: "Visualizza Tag",
            deleteArchive: "Elimina",
            exportArchive: "Report",
            importExport: "Import/Export",
            renameArchive: "Rinomina",
            docAnalysisSettings: "Impostazioni Doc Analysis",
            recycleBin: "Cestino",
            search: "Ricerca",
            copyLink: "Copia Collegamento",
            download: "Scarica",
            delete: "Elimina"
        },
    },

    // MODALS
    uploadFileModal: {
        title: 'Caricamento file',
        titleTag: 'Tagga i file',
        titleMetadata: 'Tagga i documenti',
        verificationResultTitle: 'Esito verifica metadati',
        updateDependencies: 'Aggiorna Dipendenze',
        saveMetadata: 'Salva Metadati',
        noFilesInTheList: 'Non sono presenti file nella lista',
        endMessage: 'Stiamo analizzando i tuoi documenti. Presto potrai scoprirne il risultato!',
        subtitle: 'L\'attività di upload di tanti file può prendere molto tempo. Ti consigliamo di aprire l\'applicazione EDI in una nuova finestra, così da non perdere dati.',
        [UploadErrors.LengthOutOfRange]: 'Dimensione minima del file non consentita',
        [UploadErrors.NameInvalid]: 'Nome file non valido',
        [UploadErrors.MimeTypeNotAllowed]: 'Tipo di file non supportato',
        [UploadErrors.InvalidFileSignature]: 'Verifica tipologia file fallita',
        [UploadErrors.TooLarge]: "Dimensione file eccede il limite (100MB)",
        uploadGenericError: 'Errore durante il caricamento del file',
        uploadConflictError: 'File già presente',
        uploadUnprocessableError: "Limite di capacità dell'archivio raggiunto. ",
        uploadInhibitionError: "Stai cercando di caricare file in una cartella che non è all'ultimo livello",
        uploadUnauthorizedError: "L'utente non può caricare il file",
        invalidExcel: "Il file non è formattato correttamente. Seguire le istruzioni e riprovare.",
        uploadingText: 'Sto caricando il file',
        requiredField: '* Campo obbligatorio',
        of: 'di',
        uploadedText: 'files processati',
        picker: {
            fileAlreadyUploaded: "File già caricato",
            fileTypeNotAllowed: "Tipo di file non supportato",
            info: "Puoi caricare solo queste tipologie di file: ",
            disclaimer: 'Se abbandoni o chiudi la finestra il caricamento non andrà a buon fine',
        },
        dropzone: {
            msg1: "Trascina un file qui",
            msg2: "o",
            button: 'Esplora Risorse'
        },
        footer: {
            abort: 'Annulla',
            confirm: 'Continua',
            end: 'Ho capito',
            back: 'Indietro'
        },
        capacity: {
            used: 'Spazio Utilizzato : ',
            total: 'Spazio Totale : ',
            free: 'Spazio Libero : '
        },
        detailsList: {
            documentType: 'Seleziona la tipologia del documento',
            documentName: 'Nome Documento',
            documentTag: 'Tipologia Documento',
        },
        columns: {
            date: 'Data-Periodo del report',
            source: 'Fonte del report',
            country: 'Paesi'
        }
    },

    docVerifyPages: {
        messageLoading: 'E’ in corso un operazione di verifica dei metadati. Sarà disponibile a breve il risultato.',
        message: 'La verifica è terminata. Premi il tasto Avanti per proseguire.',
        errorMessage: 'Qualcosa è andato storto, chiudi la schermata e riprova.',
        nextButton: 'Avanti',
        backButton: 'Indietro',
        close: 'Chiudi',
        error: 'Errore: ',
        tableTitle1: 'I seguenti file non saranno caricati in archivio perchè è già presente un documento con gli stessi attributi. Per l’accesso ai documenti per i quali non sei autorizzato fai riferimento all’owner dell’archivio. Clicca Chiudi per completare l’operazione.',
        tableTitle2: 'E’ già presente un documento con gli stessi attributi. Le modifiche ai metadati non saranno effettuate. Se non sei autorizzato all’accesso fai riferimento all’owner dell’archivio.',
        tableTitle3: 'Per i seguenti file è già presente un documento con gli stessi attributi.',
        tableTitle4: 'Per i seguenti file è già presente un documento con gli stessi attributi. Clicca avanti se vuoi comunque procedere al caricamento.',
        tableTitle5: 'E’ già presente un documento con gli stessi attributi.',
        tableTitle6: 'E’ già presente un documento con gli stessi attributi. Clicca avanti se vuoi comunque procedere alla modifica.',
        columns: {
            nameDoc: 'Nome Documento',
            NamePrevDoc: 'Documento già presente in archivio',
            Attribute1: 'Attributo 1',
            Attribute2: 'Attributo 2',
            Attribute3: 'Attributo 3'
        }
    },

    folderPermission: {
        title: 'Gestisci Permessi',
        searchBox: 'Ricerca membri',
        peoplePicker: 'Aggiungi membri',
        minLengthAlert: "Inserisci almeno 3 caratteri",
        noResults: "Nessun membro trovato ",
        addMembers: "Aggiungi membro"
    },

    folderProfilePermission: {
        newTitle: 'Crea una nuova cartella',
        editTitle: 'Gestisci permessi',
        editSubtitle: 'Gestisci l\'accesso dei Profili appena aggiunti a tutte le sottocartelle già create',
        inputFolder: 'Nome cartella',
        selectProfileGroup: 'Seleziona il gruppo di profili',
        manageVisibilityCoin: 'Gestisci permessi di visibilità',
        viewProfiles: 'Visualizza profili con permesso di visibilità scelto',
        searchProfiles: 'Cerca profili',
        searchMembers: 'Cerca membri',
        viewUsers: 'Visualizza l\'elenco degli utenti associati',
        dropdownOwner: 'Solo owner',
        dropdownBrothers: 'Tutti i profili che hanno visibilità a tutte le cartelle dello stesso livello',
        dropdownFathers: 'Tutti i profili che hanno visibilità alla cartella superiore',
        dropdownCustomValue: 'Personalizzato',
        subtitleMemberInvolved: 'Visualizza l\'elenco dei membri con permesso di visibilità scelto',
        enableAllSubfolders: 'Abilita tutti i profili in tutte le sottocartelle',

        ofFolder: "della cartella",
        alreadyExists: "Cartella già esistente",
        notFound: "Cartella padre non trovata",
        notPermissions: "Non hai i permessi per accedere alla cartella",
        excessiveDepth: "Profondità albero superiore a 4",
        messageCreate: 'Creazione della cartella "',
        successMessage: 'Terminata con successo',
        messageEdit: 'Aggiornamento dei profili della cartella "',

    },

    createTemplate: {
        firstPageTitle: 'Aggiungi ToC di riferimento',
        viewFirstPageTitle: 'Visualizza ToC di riferimento',
        name: 'Nome ToC',
        description: 'Descrizione ToC',
        secondPageHeader: '',
        createTemplate: '',
        showToolBar: 'Mostra pulsanti',
        hideToolBar: 'Nascondi pulsanti',
        createChild: 'Crea nodo figlio',
        downgradesNode: 'Declassa nodo',
        promoteNode: 'Promuovi nodo',
        upNode: 'Sposta in alto',
        deleteNode: 'Cancella nodo',
        downNode: 'Sposta in basso',
        addSection: 'Aggiungi sezione',
        emptyField: 'Campo non compilato'
    },

    createPortfolioModal: {
        pageTitle: "Crea Portfolio",
        namePlaceholder: "Inserisci il nome del tuo portfolio",
        nameNotAvailable: "Nome portfolio non disponibile",
        creatingPortfolio: "Creazione portfolio in corso..."
    },

    createArchiveModal: {
        stopCreating: "Vuoi interrompere la creazione di questo Doc Lab?",
        dialogMetadataToChange:"Vuoi modificare i metadati già pre-esistenti?",
        firstStepTitle: "Crea Doc Lab",
        secondStepTitle: "Aggiungi membri a ",
        namePlaceholder: "Inserisci il nome del tuo Doc Lab",
        descriptionPlaceholder: "Inserisci una descrizione del tuo Doc Lab",
        nameNotAvailable: "Nome archivio non disponibile",
        creatingArchive: "Creazione archivio in corso...",
        secondStepDescriptionPt1: "Inizia a digitare un nome o un gruppo.",
        secondStepDescriptionPt2: "Puoi aggiungere persone interne all'organizzazione ENI.",
        ofArchive: "dell'archivio"
    },

    exportArchiveModal: {
        noReport: 'Nessun report disponibile',
        inError: 'In errore il ',
        exporting: "È già in corso la generazione di un report",
        stopCreating: "Vuoi interrompere l'operazione?",
        titleHomepage: "Export Archivio",
        date: "Ultimo report creato il: ",
        note: "Nota: i report più vecchi di un mese saranno cancellati",
        generateExport: "Genera Export Archivio",
        fieldName: "Nome Campo",
        fieldTitle: "Titolo",
        selectAll: "Seleziona/Deseleziona tutti",
        titleMetadataWork: "Selezione dei metadati",
        operationInProgress: "Operazione in corso. Il report sarà pronto tra qualche minuto.",
        operationFailed: "Errore durante la creazione del report.",
        noReportAvaiable: "Non sono stati configurati report per questo archivio",
        generateReportAction: "Genera report",
        managementReportCentralFileName: "Report istituzionale",
        createScadenzaReport: {
            title: "Creazione report",
            text: "Sei sicuro di voler avviare un nuovo report?"
        },
        matadataChanges:{
            subtitleDate:"Selezionare il range di date",
            from: "Da",
            to: "A"
        },
        reportName: {
            Scadenze: 'Scadenza contratti',
            Metadata: 'Export archivio',
            Keyphrases: 'Export tag',
            ENITER: 'Export ENITER',
            Import: 'Importa metadati',
            Export: 'Esporta metadati',            
            ManagementReport: 'Report direzionale',
            FilesMetadataChanges: 'Report modifica metadati'
        },
        folderZipModal: {
            titleExport: 'Seleziona una cartella'
        },
        report_export_description: 'Estrazione di un elenco dei documenti a cui l’utente ha accesso all’interno dell’archivio, con il dettaglio di tutti i metadati aggiuntivi',
        report_expire_description: 'Estrazione di tre elenchi distinti contenenti rispettivamente: i) una lista dei documenti in scadenza entro 2 mesi e 1 giorno, ii) una lista dei documenti per cui non è disponibile la data di scadenza in formato data (gg/mm/aa), e iii) una lista di contratti scaduti con status Active',
        report_keyphrases_description: "Elenco dei tag dei documenti visibili dall'utente.",
        report_eniter_description: "File zip che contiene i file selezionati e l'excel con i relativi metadati",
        report_import_metadata_description: 'Importa nuovi metadati',
        report_export_metadata_description: 'Esporta metadati personalizzati',
        report_export_management_description: 'Genera il report direzionale',
        report_files_metadata_changes_description: "Esporta le modifiche ai metadati dei file dell'archivio"
    },

    recycleBinMetadata:{
        title:'Cestino',
        columns:{
            name:"Nome",
            deletedon: "Eliminato il",
            deletedby: "Eliminato da"
        }
    },
    
    importExportMetadata: {
        title: 'Import/Export Metadati',
        titleImportAutoFill: 'Import valori popolamento automatico',
        noReport: 'Nessun file disponibile',
        inError: 'In errore il ',
        exporting: "È già in corso la generazione di un file",
        stopCreating: "Vuoi interrompere l'operazione?",
        date: "Ultimo file creato il: ",
        note: "Nota: i file più vecchi di un mese saranno cancellati",
        fieldName: "Nome Campo",
        fieldTitle: "Titolo",
        selectAll: "Seleziona/Deseleziona tutti",
        operationInProgress: "Operazione in corso. L'operazione sarà terminata tra qualche minuto.",
        operationFailed: "Errore durante l'esecuzione dell'operazione.",
        noReportAvaiable: "Non è stato configurato il json schema per questo archivio",
        generateReportActionImport: "Carica",
        generateReportActionExport: "Esporta",
        reportName: {
            Import: 'Importa metadati',
            Export: 'Esporta metadati',
            ImportACPV: 'Importa anagrafica ACPV',
            ImportDocsTree: 'Importa alberatura documenti'
        },
        fileName: {
            Import: 'EsitoImportMetadati',
            Export: 'ExportMetadati',
            ImportACPV: 'EsitoImportAnagraficaACPV',
            ImportDocsTree: 'EsitoImportAlberaturaDocumenti'
        },
        report_import_metadata_description: 'Importa nuovi metadati',
        report_export_metadata_description: 'Esporta metadati personalizzati',
        report_import_ACPV_description: 'Importa anagrafica ACPV',
        report_import_docstree_description: 'Importa alberatura documenti',
        report_export_management_description: 'Esporta report direzionale',
        report_files_metadata_changes_description: 'Esporta report modifica metadati',
        completedWithErrorMessage: 'Importazione completata, vedere la scheda Log di Excel per gli errori.'
    },

    importMetadataModal: {
        titleModal: "Importa Metadati",
        titleModalACPV: "Importa ACPV",
        titleModalDocsTree: "Importa alberatura documenti",
        operationInProgress: "Operazione in corso. L'import sarà finito tra qualche minuto.",
        operationFailed: "Errore durante il caricamento del file Excel per l'import.",
        stopCreating: "Vuoi interrompere l'operazione?",
        titleMetadataWork: "Selezionare i metadati che si desidera aggiornare per i file",
        dialogMetadataToChange:"Alcuni metadati sono già presenti, procedere con la selezione?",
        fieldName: "Nome Campo",
        confirmUpload: "Conferma caricamento",
        subConfirmUpload: "Seleziona 'Nuovo import' per caricare una nuova anagrafica ACPV o seleziona 'Avanti' per caricare una nuova anagrafica ACPV e scegliere i metadati da sovrascrivere nei documenti già presenti nell'archivio",
        newImport: "Nuovo Import",
        fieldTitle: "Titolo"
    },

    createWorkFlowModal: {
        creatingWorkflow: "Creazione workflow in corso..."
    },

    docAnalysisStartModal: {
        analysisName: 'Nome analisi',
        standard: 'Modello',
        noStandard: 'Nessun modello',
        name: 'Nome',
        createdBy: 'Creato da',
        createdOn: 'Creato il',
        secondPageHeaderFirstPart: 'Seleziona i file (',
        secondPageHeaderSecondPart: ' massimo) dai quali estrarre gli aspetti formali.',
        secondPageHeader: 'Seleziona i file (massimo 8) dai quali estrarre gli aspetti formali.',
        secondPageHeaderSecondPartToc: ' massimo) del tuo doc lab che vuoi confrontare con la ToC di riferimento',
        secondPageHeaderSecondPartS_C: ' massimo) che vuoi sottoporre all\'analisi Ricerca & Confronto',
        selectedNumber: 'Selezionati: ',
        thirdPageAnalisys: 'Stiamo analizzando i tuoi documenti, l\'operazione richiederà qualche minuto',
        end: 'OK',
        errorLoading: 'Si è verificato un errore durante il caricamento',
        noConfigs: 'Nessuno modello presente',
        notPermissions: "Non hai i permessi per accedere alla risorsa",
        errorAnalysis: "Si è verificato un errore durante l'avvio dell'analisi. Attendi qualche minuto e riprova",
        secondPageTitle: 'Seleziona uno o più documenti da analizzare',
        qualityCheckModal: {
            firstPageTitle: 'Seleziona uno modello per il Quality Check',
            firstPageSubHeader: 'Lunghezza del paragrafo misurata in \'numero di pagine\', al netto di immagini e tabelle',
        },
        tocStartModal: {
            firstPageTitle: 'Seleziona uno modello per la verifica ToC',
        },
        glossaryCheck: {
            firstPageTitle: 'Glossary Check',
            firstPageSubHeader: 'Carica il glossario di riferimento',
            secondPageSubHeader: 'Seleziona i documenti su cui vuoi effettuare la verifica del glossario',
            buttonForUploadFile: 'Upload',
            firstPhrase: 'Istruzioni per una corretta compilazione del file',
            secondPhrase: 'Il file deve essere in formato Excel',
            thirdPhrase: 'La struttura deve presentare nella prima colonna i termini, nella seconda colonna i termini correlati separati dalla virgola (senza spazi dopo la virgola).',
            searchDocument: 'Ricerca documenti',
            teamsImage: 'glossaryCheckInfoIt',
            excelLimit: 'A causa del limite di righe di Excel, il numero di risultati per ogni termine dipende dal numero di righe del glossario e dai file selezionati.',
            maxNumError1: 'Hai scelto più di ',
            maxNumError2: ' file.'
        },
        searchAndCompare: {
            firstPageTitle: 'Ricerca e confronto',
            fileTemplate: 'Seleziona il file da usare come riferimento per la ricerca',
            pastedTextPlaceholder: 'Incolla il testo per effettuare la ricerca',
            or: 'Oppure',
            noSelectAndNext: 'Procedi senza selezionare il documento',
            text4SearchAndCompare: 'Inserisci il testo che vuoi ricerca e prosegui per selezionare i documenti su cui vuoi effettuare la ricerca e il confronto',
        }
    },
    membersPageForCreateArchive: {
        description1: "Inizia a digitare un nome da aggiungere al tuo Doc Lab.",
        description2: "Puoi aggiungere persone Eni (*@eni.com o *@external.eni.com) che hanno già EDI sul loro Teams.",
        minLengthAlert: "Inserisci almeno 3 caratteri",
        noResults: "Nessuna corrispondenza trovata"
    },

    membersPageForAddArchiveMembers: {
        description1: "Inizia a digitare un nome da aggiungere al tuo Doc Lab.",
        description2: "Puoi aggiungere persone Eni (*@eni.com o *@external.eni.com) che hanno già EDI sul loro Teams.",
        minLengthAlert: "Inserisci almeno 3 caratteri",
        noResults: "Nessuna corrispondenza trovata",
        allowChildFolders: "Abilita a cartelle sottostanti"
    },

    membersPageForAddKnowledgeLabMembers: {
        description1: "Inizia a digitare un nome da aggiungere al tuo Knowledge Lab.",
        description2: "Puoi aggiungere persone Eni (*@eni.com o *@external.eni.com) che hanno già EDI sul loro Teams.",
        minLengthAlert: "Inserisci almeno 3 caratteri",
        noResults: "Nessuna corrispondenza trovata",
    },

    membersPageForAddFolderMembers: {
        description1: "Inizia a digitare un nome da aggiungere al tuo Doc Lab.",
        description2: "Puoi aggiungere persone Eni (*@eni.com o *@external.eni.com) che sono già presenti sull'archivio.",
        minLengthAlert: "Inserisci almeno 3 caratteri",
        noResults: "Nessuna corrispondenza trovata",
        allowChildFolders: "Abilita a cartelle sottostanti"
    },

    membersPageForAddPortfolioMembers: {
        description1: "Inizia a digitare un nome da aggiungere al tuo Portfolio.",
        description2: "Puoi aggiungere persone Eni (*@eni.com o *@external.eni.com) che hanno già EDI sul loro Teams.",
        minLengthAlert: "Inserisci almeno 3 caratteri",
        noResults: "Nessuna corrispondenza trovata"
    },

    createFolderModal: {
        namePlaceholder: "Inserisci il nome della tua cartella",
        creatingFolder: "Creazione cartella in corso...",
        createNewFolder: "Crea una nuova cartella",
        ofFolder: "della cartella",
        alreadyExists: "Cartella già esistente",
        notFound: "Cartella padre non trovata",
        notPermissions: "Non hai i permessi per accedere alla cartella",
        excessiveDepth: "Profondità albero superiore a 4",
        message: 'Creazione della cartella "',
        successMessage: 'Terminata con successo',
    },

    deleteModal: {
        title: 'Attenzione',
        messageFile: 'Sei sicuro di voler eliminare il file selezionato?',
        messageFolder: 'Sei sicuro di voler eliminare la cartella selezionata?',
        messageItems: 'Sei sicuro di voler eliminare gli elementi selezionati?',
        ofFolder: 'della cartella',
        ofFile: 'del file',
        ofItems: 'degli elementi',
        notPermissions: "Non hai i permessi per cancellare l'item",
        operationFolder: "Cancellazione cartella",
        operationFile: "Cancellazione file",
        operationMultiItems: "Cancellazioni elementi",
        successMessageFolder: "Cartella cancellata con successo",
        successMessageFile: "File cancellato con successo",
        successMessageItems: "Elementi cancellati con successo"
    },

    navigationModal: {
        copyTitle: 'Copia in',
        moveTitle: 'Sposta in',
        copyMessage: 'Clicca sulla cartella dove desideri copiare gli elementi selezionati oppure seleziona un altro archivio. Verranno copiati solo i documenti a cui hai accesso, con tag e metadati aggiuntivi. In caso l\'archivio di destinazione sia diverso da quello di partenza, i file verranno indicizzati nuovamente.',
        moveMessage: 'Clicca sulla cartella dove desideri spostare gli elementi selezionati oppure seleziona un altro archivio. Verranno spostati solo i documenti a cui hai accesso, con tag e metadati aggiuntivi. In caso l\'archivio di destinazione sia diverso da quello di partenza, i file verranno indicizzati nuovamente.',
        search: 'Cerca cartelle',
        secondaryAction: 'Annulla',
        primaryCopyAction: 'Copia qui',
        primaryMoveAction: 'Muovi qui',
        copyOperation: "Copia degli elementi",
        moveOperation: "Spostamento degli elementi",
        successMoveOperation: "Spostamento degli elementi terminato con successo",
        successCopyOperation: "Copia degli elementi terminata con successo",
        conflictError: "Elementi con lo stesso nome sono già presenti nella cartella di destinazione",
        searchFolders: 'Ricerca cartelle',
        searchDoclab: 'Ricerca DocLab',
        depthExceedError: 'Limite massimo dell\'alberatura (5) superato',
        sizeArchiveExceedError: 'La dimensione dell\'archivio supera la dimensione massima consentita',
        moveFolderInSubfolderError: 'La cartella di destinazione è una sottocartella della cartella di origine',
        errorMessage: 'La cartella di destinazione non è all’ultimo livello',
        folderError: 'La folder non è stata trovata',
        columns: {
            name: "Name",
            createdBy: "Creato da",
            createdOn: "Creato il",
        },
    },

    // TODO: change with deleteItemModal
    deleteArchiveModal: {
        operationName: 'EliminaArchivio',
        title: 'Elimina Archivio?',
        subtext: 'Sei sicuro di voler cancellare questo archivio? Tutti i file contenuti e i dati associati saranno cancellati',
        buttonConfirm: 'Si',
        buttonCancel: 'No',
        notPermissions: "Non hai i permessi per cancellare l'archivio",
        successOperation: "Archivio cancellato con successo",
        errorCode403: 'Permessi insufficienti per l\'operazione',
        errorCode404: 'Archivio non trovato',
    },

    downloadFilesModal: {
        operationName: 'DownloadFiles',
        title: 'Scaricare gli elementi selezionati?',
        titleMonoDownload:'Scaricare l\'elemento selezionato?',
        subtext: 'L\'attività di download di più file e/o cartelle può richiedere molto tempo. Ti consigliamo di aprire l\'applicazione EDI in una nuova finestra, così da non perdere dati.',
        subtextMultiploWarning: 'L\'attività di download di più file e/o cartelle può richiedere molto tempo. Ti consigliamo di aprire l\'applicazione EDI in una nuova finestra, così da non perdere dati.\nSi sta scaricando uno o più file con stato di approvazione Da Approvare o Rigettato',
        subTextDownloadMono:'Si sta scaricando un file non approvato',
        buttonConfirm: 'Prosegui',
        buttonCancel: 'Annulla',
        downloading: 'Sto scaricando...',
        longTime: 'Quest\'operazione potrebbe richiedere molto tempo. Non chiudere questa finestra.'
    },

    moveToRecycleBinModal: {
        title: 'Spostare nel cestino?',
        messageFile: 'Sei sicuro di voler spostare il file nel cestino?',
        messageItems: 'Sei sicuro di voler spostare gli elementi selezionati nel cestino?',
        ofFile: 'del file',
        ofItems: 'degli elementi',
        notPermissions: "Non hai i permessi per spostare il file nel cestino",
        operationFile: "Spostamento nel cestino di un file",
        operationMultiItems: "Spostamento nel cestino di più elementi",
        successMessageFile: "File spostato nel cestino con successo",
        successMessageItems: "Elementi spostati nel cestino con successo: "
    },

    restoreFromRecycleBinModal: {
        title: 'Proseguire con il ripristino?',
        messageFile: 'Sei sicuro di voler ripristinare il file?',
        messageItems: 'Sei sicuro di voler ripristinare gli elementi selezionati?',
        ofFile: 'del file',
        ofItems: 'degli elementi',
        notPermissions: "Non hai i permessi per ripristinare il file",
        operationFile: "Ripristino di un file",
        operationMultiItems: "Ripristino di più elementi",
        successMessageFile: "File ripristinato con successo",
        successMessageItems: "Elementi ripristinati con successo",
        fileAlreadyExists: "Un elemento con lo stesso nome esiste già nel percorso di ripristino",
        filesAlreadyExists: "Uno dei file selezionati è già presente nel percorso di ripristino con lo stesso nome",
        verificationResultTitle: 'I seguenti File non verranno ripristinati',
        verificationResultTitleWithErrorRules: 'I seguenti file non rispettano le regole di univocità, ma verranno comunque ripristinati',
        errorMultipleFiles: "Un file con lo stesso nome è già presente nel sistema"
    },

    deleteDocAnalysisModal: {
        title: 'Attenzione',
        message: "Sei sicuro di voler eliminare l'analisi?",
        notPermissions: "Non hai i permessi per cancellare l'analisi",
        operation: "Cancellazione analisi",
        successMessage: "Analisi cancellata con successo",
        notFound: "Analisi non trovata",
        Unprocessable: "Stato analisi in corso"
    },

    renameItemModal: {
        placeholder: 'Inserisci nome',
        rename: 'Rinomina',
        cancel: 'Annulla',
        save: 'Salva',
        errorCode403: 'Permessi insufficienti per l\'operazione',
        errorCode404: 'File o cartella non trovata',
        errorCode409: 'Nome già utilizzato',
        operation: 'Operazione di rinomina',
        successMessage: 'Terminata con successo'
    },

    renameArchiveModal: {
        placeholder: 'Inserisci un nuovo nome',
        title: 'Rinomina Archivio',
        label: "Nome",
        cancel: 'Annulla',
        save: 'Salva',
        nameNotAvailable: "Nome già in uso",
        errorCode403: 'Permessi insufficienti per l\'operazione',
        errorCode404: 'Archivio non trovato',
        errorCode409: 'Nome già utilizzato',
    },

    renamePortfolioModal: {
        placeholder: 'Inserisci un nuovo nome',
        title: 'Rinomina Portfolio',
        label: "Nome",
        cancel: 'Annulla',
        save: 'Salva',
        nameNotAvailable: "Nome già in uso",
        errorCode403: 'Permessi insufficienti per l\'operazione',
        errorCode404: 'Portfolio non trovato',
        errorCode409: 'Nome già utilizzato',
    },

    addMembersToArchive: {
        updateMembership: "Modifica condivisione ",
    },

    addMembersToFolders: {
        title: 'Gestisci permessi',
        addMembers: 'Aggiunta membri'
    },

    downloadNotification: {
        downloading: 'Download in corso',
        downloaded: 'Download completato',
        error: 'Errore nel download'
    },
    // END MODALS

    copyLinkModal: {
        title: {
            [ItemToCopy.DeepLink]: "Ottieni collegamento",
            [ItemToCopy.DocumentId]: "Ottieni ID Documento"
        },
        action: {
            [ItemToCopy.DeepLink]: "Copia link",
            [ItemToCopy.DocumentId]: "Copia"
        }
    },
    documentTranslation: {
        title: 'Traduzione Documento',
        subtitle: 'Selezionare la lingua per la traduzione del documento.',
        selectLanguages: 'Seleziona una lingua',
        English: 'Inglese',
        Italian: 'Italiano',
        French: 'Francese',
        Spanish: 'Spagnolo',
        Portuguese: 'Portoghese',
        German: 'Tedesco',
        Greek: 'Greco',
        Chinese: 'Cinese',
        Russian: 'Russo',
        Polish: 'Polacco',
        Romanian: 'Rumeno',
        Czech: 'Ceco',
        Slovak: 'Slovacco',
        Hungarian: 'Ungherese',
        Turkish: 'Turco',
        Swedish: 'Svedese',
        Vietnamese: 'Vietnamita',
        sentenceOne: 'Attendere prego.',
        sentenceTwo: 'Stiamo traducendo il tuo documento.',
        sentenceThree: 'Il download inizierà a breve.',
    },

    // TABS
    archiveMembersSettings: {
        searchForMembers: "Ricerca Membri",
        addMembers: "Aggiungi Membri"
    },

    archiveContent: {
        emptyFolder: 'La cartella è vuota',
        errorLoading: 'Errore durante il caricamento del contenuto',

        columns: {
            name: "Nome",
            createdby: "Creato da",
            createdon: "Creato il",
            indexStatus: "Stato",
            approvalStatus: "Stato di Approvazione",
            alreadyApproved: "Già processato"
        },
        indexStatus: {
            ToBeIndexed: "Da indicizzare",
            Indexed: "Indicizzato",
            undefined: ""
        },
        approvalStatus:{
            NA: "NA",
            ToBeApproved: "Da Approvare",
            Approved: "Approvato",
            Rejected: "Rigettato",
            Migrated: "Migrato"
        },
        metadataWarning: "Metadati aggiuntivi richiesti"
    },

    tagPanel: {
        dialogMessage: "Le modifiche apportate non saranno salvate. Procedere con la chiusura?",
        changeTabMessage: "Le modifiche apportate non saranno salvate. Procedere?",
        verificationResultTitle: 'Esito verifica metadati',
        keys: {
            Languages: "Lingue",
            Keyphrases: "Frasi importanti",
            FormalAspects: "Aspetti formali",
            Organizations: "Organizzazioni",
            Locations: "Luoghi",
            DateCreated: "Data Creazione",
            ApprovalStatus: "Stato di Approvazione",
            AlreadyApproved: "Già processato"

        },
        tooltipText: {
            Languages: "EDI riconosce fino a 3 lingue tra quelle più rilevanti presenti nel tuo documento.",
            Keyphrases: 'EDI riporta le 15 più importanti frasi o parole chiave del tuo documento per aiutarti nella sua ricerca e comprensione.',
            FormalAspects: "EDI individua nel tuo documento quante pagine, paragrafi, parole, tabelle e immagini sono presenti. L'algoritmo di intelligenza artificiale si basa su alcune assunzioni (es.: tabelle e immagini ben formattati; paragrafi definiti come header in word o come bookmark in pdf), che potranno essere superate, portando ad un miglioramento della funzionalità con l'evoluzione del prodotto EDI.",
            Organizations: 'EDI individua entità di tipologia "organizzazione" nel tuo documento e mostra i 5 risultati più rilevanti.',
            Locations: 'EDI individua entità di tipologia "location" nel tuo documento e mostra i 5 risultati più rilevanti.'
        },
        values: {
            TableCount: 'Tabelle',
            PageCount: 'Pagine',
            ParagraphCount: 'Paragrafi',
            WordCount: 'Parole',
            ImageCount: 'Immagini',
            FileFormatExtension: 'Estensione file'
        },
        title: 'Tag associati',
        forbiddenError: "Non hai i permessi per accedere alla risorsa",
        inProgressError: "Ancora nessun tag associato: stiamo analizzando il tuo documento, presto potrai scoprirne il risultato!",
        metadata: {
            success: "Salvataggio eseguito con successo",
            info: "Operazione terminata",
            requiredField: "Campo obbligatorio",
            pivotTag: "Tag",
            pivotMetadata: "Metadati aggiuntivi",
            confirmSave: "Sei sicuro di voler salvare i metadati inseriti?",
            placeHolderSearch: "Inserisci testo",
            errorSaving: "Errore durante l'operazione di salvataggio",
            tryAgain: "Riprovare?",
            other: "Altri ",
            items: " items trovati",
            noItems: "Non ci sono altri elementi",
            moreChar: "Inserire almeno 2 caratteri per filtrare",
            errorApi: "Errore durante il recupero dei metadata aggiuntivi",
            userNotFound: "Errore: Utente non trovato",
            fileNotFound: "Errore: File non trovato",
            noFileId: "L'ID del file non è stato trovato: ",
            noUserAccess: "Non hai accesso a questi file: ",
            fileJsonSchemaError: "Questo file non ha un JsonScema adatto: ",
            errorMainProperty: "Errore durante il salvataggio, i seguenti main property non sono validi: ",
            sameFile: "Non è possibile referenziare lo stesso file: "
        },
        formalAspectWithoutExtension: 'Non possiamo calcolare questo parametro per questo formato file',
        updateDependencies: 'Aggiorna Dipendenze'
    },

    docAnalysis: {
        [AnalysisTypeId[0]]: 'Quality Check',
        [AnalysisTypeId[1]]: 'Table Of Content',
        [AnalysisTypeId[2]]: 'Glossary Check',
        commands: {
            delete: 'Elimina',
            downloadReport: 'Scarica Report',
            createLink: 'Copia collegamento'
        },
        qualityCheck: {
            title: 'Quality Check',
            description: 'Verifica la qualità dei tuoi documenti, a partire da un modello di riferimento che hai definito.'
        },
        tableOfContent: {
            title: 'Tabella dei contenuti',
            description: 'Questa è la descrizione della doc analysis denominata "Tabella dei contenuti". Clicca nella presente card per effettuare l\' analisi desiderata',
        },
        tocVerification: {
            title: 'Verifica ToC',
            description: 'Questa è la descrizione della doc analysis denominata "Verifica ToC". Clicca nella presente card per effettuare l\' analisi desiderata'
        },
        glossaryCheck: {
            title: 'Glossary check',
            description: 'Verifica il glossario dei tuoi documenti, inserendo il glossario di riferimento'
        },
        searchAndCompare: {
            title: 'Ricerca e confronto',
            description: 'Questa è la descrizione della doc analysis chiamata "Ricerca e confronto". Clicca nella presente card per effettuare l\' analisi desiderata',
        },
        partsOfTest: {
            title: 'Confronto tra parti di testo',
            description: 'Questa è la descrizione della doc analysis denominata "Confronto tra parti di testo". Clicca nella presente card per effettuare l\' analisi desiderata'
        },
        textVsGlossary: {
            title: 'Confronto testo vs glossario',
            description: 'Questa è la descrizione della doc analysis denominata "Confronto testo vs glossario". Clicca nella presente card per effettuare l\' analisi desiderata'
        },
        addStandard: 'Aggiungi modello',
        namePlaceholder: `Aggiungi modello (max ${Constants.standardItemNameMaxLength} caratteri)`,
        label: 'Etichetta',
        labels: {
            types: {
                [Labels.Negligible]: 'Trascurabile',
                [Labels.Small]: 'Piccolo',
                [Labels.Medium]: 'Medio',
                [Labels.Large]: 'Grande'
            },
            from: 'Da',
            to: 'A',
        },
        operations: {
            addOperation: {
                name: 'Aggiunta modello',
                errors: {
                    errorCode403: '',
                    errorCode404: '',
                    errorCode409: '',
                    nameNotAvailable: 'Nome già esistente'
                },
                successMessage: 'Modello creato con successo'
            },
            deleteOperation: {
                name: 'Cancellazione modello',
                errors: {
                    errorCode403: 'Operazione non consentita'
                },
                successMessage: 'Modello cancellato con successo'
            }
        },
        forbidden: "Non hai i permessi per visualizzare i risultati dell'analisi",


    },

    archiveSettings: {
        yes:"Si",
        title: 'Impostazioni Doc Lab',
        tagging: 'Preferenze Tagging',
        whitelistTitle: 'Keyword in rilevanza',
        commonDescription: 'Aiuta EDI ad estrarre le parole maggiormente rilevanti per il tuo DocLab.',
        whitelistDescription: 'Le keyword rilevanti sono quelle che verranno presentate come prime nei risultati di ricerca e nei tag del documento',
        blacklistTitle: 'Keyword nascoste',
        blacklistDescription: 'Le keyword nascoste sono quelle che non verranno presentate nei tag del documento',
        maxLenghtKeyword: 'Keyword deve essere compresa tra 3 e 50 caratteri',
        maxLenghtList: 'Raggiunto numero massimo di Keywords',
        keywordAlreadyExist: 'Keyword già presente',
        keywordAlreadyExistBlacklist: 'Keyword già presente nella ',
        errorCode404: "Configurazione per l'archivio non trovata",
        removeButton: 'Sei sicuro di voler eliminare la Keyword ?',
        removeAllButton: 'Sei sicuro di voler eliminare tutte le Keywords ?',
        placeHolderSearch: 'Inserisci Keyword',
        clearAllButton: 'Cancella tutto',
        Name: 'Nome',
        NoItem: 'Nessuna keyword trovata',
        EmptyItem: 'Impossibile aggiungere una stringa vuota',
        taggingDescription: 'Imposta le famiglie di interesse per comunicare ai membri del tuo archivio di taggare i file in pochi click',
        status: 'Stato',
        family: 'Famiglia',
        wordNumber: 'Numero Parole',
        tableNumber: 'Numero Tabelle',
        pageNumber: 'Numero Pagine',
        paragraphNumber: 'Numero Paragrafi',
        imageNumber: 'Numero Immagini',
        formalAspect: 'Aspetti Formali',
        date: 'Data',
        location: 'Luoghi',
        organization: 'Organizzazioni',
        language: 'Lingue',
        keyphrases: 'Parole chiavi',
        enabled: 'è stato abilitato',
        disabled: 'è stato disabilitato',
        externalLov: 'Metadati aggiuntivi: Liste di Valori configurabili',
        otherSettings: 'Altre impostazioni',
        logChangeMetadata: 'Log modifica metadati',
        crossArchiveSearch: 'Ricerca cross archivi',
        crossFolderSearch: 'Ricerca cross cartelle',
        documentTranslator: 'Traduzione documenti',
        defaultAdditionalMetadata: 'Visualizza "Metadati aggiuntivi" di Default nel pannello Tag associati',
        reportFieldsWithDateFormat: 'Imposta i campi data dei report "Export archivio" e "Export tag" con il formato data'
    },

    formalAspectTresholds: {
        labelFrom: 'Da',
        labelTo: 'A',
        fieldRequired: 'Indicare le soglie',
        tresholds: 'Errore nella selezione delle soglie',
        columnTresholds: 'Soglie',
        addLabel: 'Aggiungi Label',
        duplicateLabel: 'Etichetta duplicata'
    },

    docAnalysisSettings: {
        title: 'Impostazioni Doc Analysis',
        preferences: 'Preferenze',

        qualityCheck: {
            addStandard: 'Aggiungi Modello',
            name: 'Nome',
            paragraphUoM: 'Lunghezza del paragrafo misurata in \'numero di pagine\', al netto di immagini e tabelle',
            createdOn: 'Creato il',
            createdBy: 'Creato da',

            deleteModal: {
                title: 'Sei sicuro di voler eliminare il modello selezionato?',
                description: ''
            }
        },
        tableOfContent: {
            addTemplate: 'Aggiungi ToC',
            name: 'Nome',
            createdOn: 'Creato il',
            createdBy: 'Creato da',
            deleteActionName: 'Cancellazione ToC',
            deleteSuccessMessage: 'ToC cancellata con successo',
            saveActionName: 'Creazione ToC',
            saveSuccessMessage: 'ToC creata con successo',
            saveAction403: "Non hai i permessi per creare un template valido per la ToC",
            saveAction422: "Raggiunto il livello massimo di profondità per elemento (max. 4)",
            deleteAction403: "Non hai i permessi per effettuare l'operazione",
            deleteModal: {
                title: 'Sei sicuro di voler eliminare il template selezionato?',
                description: ''
            }
        }
    },
    workflows: {
        addNew: 'Nuovo Workflow',
        column: {
            name: 'Nome Workflow',
            status: 'Stato',
            activity: 'Progetto',
            documentName: 'Nome Documento',
            createdOn: 'Data Creazione',
            createdBy: 'Richiesta da',
            assignedTo: 'In carico a',
            members: 'Utenti coinvolti',
        },
        confirmEliminate: 'Sì, elimina',
        undoEliminate: 'No, torna indietro'
    },
    workflowDetails: {
        info: {
            title: 'Info',
            status: {
                [WorkFlowRelativeStatus.Ongoing]: 'Workflow in corso e sono stato coinvolto negli step fino ad ora avviati, ma non devo fare operazioni oppure Workflow in corso e sono il richiedente del workflow',
                [WorkFlowRelativeStatus.Approved]: 'Workflow terminato con esito positivo',
                [WorkFlowRelativeStatus.Refused]: 'Workflow terminato con un rifiuto',
                [WorkFlowRelativeStatus.ToMe]: 'Workflow con richiesta azione in carico a me',
            },
            secondLineStatus: {
                [WorkFlowRelativeStatus.Ongoing]: 'Clicca sul documento allegato per visualizzare il contenuto e/o apportare modifiche',
                [WorkFlowRelativeStatus.Approved]: 'Clicca sul documento allegato per visualizzare il contenuto',
                [WorkFlowRelativeStatus.Refused]: 'Clicca sul documento allegato per visualizzare il contenuto',
                [WorkFlowRelativeStatus.ToMe]: 'Clicca sul documento allegato per visualizzare il contenuto e/o apportare modifiche',
            },
            activityName: 'Progetto: ',
            document: 'Documento allegato: ',
            createdBy: 'Richiedente: '
        },
        action: {
            title: 'Azione richiesta'
        },
        iter: {
            title: 'Iter Approvativo',
            column: {
                step: 'Step',
                status: 'Status',
                rules: 'Regole',
                actionHistory: 'Cronologia Azioni',
                comments: 'Commenti',
            },
            userActionWithDate: 'in data',
            userCommented: ' ha commentato: '
        }
    },
    workflowList: {
        column: {
            name: 'Nome Workflow',
            status: 'Stato',
            activity: 'Progetto',
            documentName: 'Nome documento',
            createdOn: 'Data creazione',
            createdBy: 'Richiesta da',
            assignedTo: 'In carico a',
            members: 'Utenti coinvolti'
        },
        deleteTitle: 'Attenzione',
        deleteMessage: 'Sei sicuro di voler eliminare il workflow?',
        status: {
            [WorkFlowRelativeStatus.Ongoing]: 'Ongoing',
            [WorkFlowRelativeStatus.Approved]: 'Approvata',
            [WorkFlowRelativeStatus.Refused]: 'Rifiutata',
            [WorkFlowRelativeStatus.ToMe]: 'Assegnata a me',
        },
        receivedRequests: 'Workflow ricevuti',
        sentRequests: 'Workflow creati',
    },

    stepStatus: {
        title: 'STEP #{{order}}:',
        userAction: 'ha',
        status: {
            [WorkFlowStepRelativeStatus.Idle]: '',
            [WorkFlowStepRelativeStatus.ToBeApproved]: 'In Corso',
            [WorkFlowStepRelativeStatus.Approved]: 'Approvato',
            [WorkFlowStepRelativeStatus.Refused]: 'Rifiutato',
            [WorkFlowStepRelativeStatus.ToMe]: 'In Corso',
        },
        approverStatus: {
            [WorkFlowApproverStatus.ToBeApproved]: 'In Corso',
            [WorkFlowApproverStatus.Approved]: 'Approvato',
            [WorkFlowApproverStatus.Refused]: 'Rifiutato',
        },
        type: {
            [WorkFlowStepTypes.OnlyOneApprover]: 'Solo un approvatore',
            [WorkFlowStepTypes.OnlyOneSignedApprover]: 'Solo un approvatore con firma',
            [WorkFlowStepTypes.EveryoneMustApprove]: 'Tutti devono approvare',
        }
    },

    activityLabUsersSettings: {
        searchBox: 'Cerca membri',
        addMembers: 'Aggiungi membri',
        errorRemoveMembers: "Il membro non può essere rimosso in quanto è presente in almeno un progetto del portfolio.",
        updateMembership: "Modifica condivisione ",
        createAtLeastOneTemplate: 'Crea almeno un template per il portfolio',
        templateSettings: {
            title: 'Rendi visibili le seguenti tipologie di progetto:'
        }
    },

    templateActivity: {
        columns: {
            name: "Template progetto",
            status: "Status",
            createdBy: "Creato da",
            createdOn: "Data creazione"
        },
        addTemplate: "Aggiungi template progetto",
        title: "Impostazioni Project Lab",
        type: "Tipologia Progetto"
    },
    // END TABS

    notificationPanel: {
        failure: 'Operazione non riuscita',
        emptyPlaceHolder: 'Non hai nuove notifiche',
        title: 'Notifiche'
    },

    // ACTIONS
    toolbar: {
        doclab: {
            dashboard: 'Dashboard',
            docAnalysis: 'Doc Analysis',
            files: 'File',
            search: 'Ricerca',
            selected: 'selezionati'
        },
        activityLab: {
            board: 'Bacheca',
            list: 'Elenco',
            workflow: 'Workflow',
            graphs: 'Grafici'
        }
    },

    dashboard: {
        progressBar: {
            title: "Spazio Disponibile",
            description: " liberi su  "
        },
        tagCloudChart: {
            title: "Wordcloud Keyphrases",
        },
        worldMapChart: {
            title: "Location Documenti",
        },
        fileformats: {
            title: "Formato Documenti",
        },
        languages: {
            title: "Lingua Documenti",
            countMessage: "La somma dei valori non coincide con il numero totale dei documenti visibili nell'archivio"
        },
        formalAspects: {
            title: "Aspetti Formali",
        },
        fileCount: {
            description: "Sono presenti ",
            descriptionEnd: " documenti"
        },
        fileCountNull: {
            description: "Non sono presenti documenti",
        },
        fileCountOne: {
            description: "É presente ",
            descriptionEnd: " documento"
        },
        formalAspectsError: "Non Applicabile",
        portfolio: {
            activitiesStatus: {
                title: 'Status Progetto',
            },
            activitiesPriorities: {
                title: 'Priorità Progetto'
            },
            activitiesLeaders: {
                title: 'Progetti per Project Leader'
            },
            workflowStatus: {
                title: 'Status Workflow'
            },
            workflowUsers: {
                title: 'Workflow per Utente',
            }
        },
        errors: {
            errorCode403: "Non hai i permessi necessari per vedere questa dashboard",
            errorCode422: "Disabilitato nelle impostazioni"
        },
        empty: "Non sono presenti dati"
    },

    listDocAnalysis: {
        inAnalysis: "In corso",
        ToBeValidated: "Da validare",
        Validated: "Validata",
        Completed: "Completata",
        Error: "In errore",
        notPermissions: "Non hai i permessi per accedere alla risorsa",
        column: {
            name: 'Nome analisi',
            type: 'Tipologia analisi',
            createdOn: 'Data creazione analisi',
            startedBy: 'Effettuata da',
            lastUpdatedOn: 'Data aggiornamento',
            validatedBy: 'Validata da',
            status: 'Stato'
        },
        multiple: "Cross archivio",
    },

    archiveContentActions: {
        newFolder: 'Nuova Cartella',
        uploadFile: 'Carica qui',
        open: 'Apri',
        openInBrowser: 'Apri nel browser',
        translateDocument: 'Traduci documento',
        createLink: 'Copia collegamento',
        copyDocumentId: 'Copia ID Documento',
        viewTag: "Visualizza Tag",
        managePermissions: 'Modifica permessi',
        exportArchive: "Export Archivio",
        download: 'Scarica',
        delete: 'Elimina',
        rename: 'Rinomina',
        move: 'Sposta',
        copy: 'Copia',
        viewWfDetails: 'Visualizza dettagli WF',
        downloadWfDetails: 'Scarica dettagli WF',
        approve: 'Approva',
        reject: 'Rigetta',
        resubmitApproval: 'Porta in stato Da Approvare',
        restore: 'Ripristina',
        moveToRecyclebin: 'Sposta nel cestino'
    },

    analysisResults: {
        saveReport: 'Salva report',
        empty: 'Analisi non presente',
        error: "Errore durante l'elaborazione dell'analisi",
        subtitle: 'Modello di riferimento',
        tooltipText: 'Lunghezza del paragrafo misurata in \'numero di pagine\', al netto di immagini e tabelle',
        qualityCheckResult: {
            length: 'Lunghezza',
            words: 'Parole',
            images: 'Immagini',
            tables: 'Tabelle',
            title: 'Risultati analisi Quality Check',
        },
        tocResult: {
            title: 'Risultati analisi ToC',
            summaryAnalysis: "Sintesi analisi",
            chapterName: "Nome capitolo template ToC",
            chapterNameFromDocument: "Nome capitolo da documento",
            percentage: "Percentuale",
            status: 'Stato',
            motivation: "Motivazione",
            matchElements: "Elementi individuati",
            noMatchElements: "Elementi non allocati",
            notAllocated: "Non allocato",
            missing: "Non trovato",
            warnings: {
                [AnalysisDocWarning.WrongExtension]: "Estensione file non supportata",
                [AnalysisDocWarning.TocNotFound]: "ToC non presente nel documento di confronto",
                [AnalysisDocWarning.InvalidToc]: "Reference toC non valida",
            },
            errors: {
                [AnalysisDocError.Generic]: "Si è verificato un errore",
                [AnalysisDocError.BlobNotFound]: "File non trovato",
                [AnalysisDocError.Parsing]: "Errore durante l'analisi del documento",
            }
        },
        searchAndCompareResult: {
            title: 'Risultati analisi Ricerca e Confronto',
            analysisName: 'Nome analisi:',
            subTitle: 'Hai ricercato la seguente sezione:',
            subTitleFile: 'Hai ricercato la seguente sezione del file',
            more: 'Espandi',
            less: 'Riduci',
            emptySnippet: 'Nessun risultato',
            searchResults: 'Risultati ricerca:'
        }
    },

    createActivityTemplate: {
        title: "Imposta template progetto",
        editTitle: "Modifica template progetto",
        subEditTitle: "Le modifiche non saranno applicate ai progetti già inseriti",
        stopCreating: "Vuoi interrompere la creazione di questo template?",
        creatingTemplate: "Creazione template in corso...",
        updateTemplate: "Modifica template in corso...",
        ofTemplate: " di creazione del template di progetto",
        namePlaceholder: "Inserisci il nome del template progetto",
        descriptionPlaceholder: "Inserisci la descrizione del template progetto",
        addPhase: "Aggiungi",
        text: "Imposta le sezioni del tuo progetto e decidi se creare in automatico i canali in Teams. Le sezioni consentono di definire le fasi o gli stream di attività del tuo progetto",
        namePhasePlaceholder: "Nome sezione",
        descriptionPhasePlaceholder: "Descrizione sezione",
        maxLength: 'Hai raggiunto il numero massimo di ' + DocLabConstants.MAX_PHASES_NUMBER + ' sezioni',
        noDescription: 'Descrizione non inserita',
        phaseNameError: 'Nome già in uso',
        toogleTooltip: "Utilizza questo toggle per predisporre un canale in Teams denominato come la sezione corrispondente",
        columns: {
            settedPhases: "Sezioni impostate",
            channelTeams: "Canale Teams"
        },
        nameNotAvailable: "Nome già utilizzato",
        addNewSection: "- Aggiungi nuova sezione",
        createTemplate: "Crea template",
        createTemplateSuccess: "Il template è stato creato",
        modifyTemplate: "Modifica template",
        modifyTemplateSuccess: "Il template è stato modificato"
    },

    activityPhaseStatus: {
        notStarted: 'Non iniziata',
        inProgress: 'In corso',
        done: 'Terminata'
    },

    infoAndStatus: {
        low: "Bassa",
        medium: "Media",
        high: "Alta",
        phases: "STEP",
        noPhases: "Non sono presenti step",
        getActivityError: "Errore durante il recupero dei dati del progetto",
        tabInfo: "Informazioni",
        descriptionLabel: "Descrizione",
        descriptionPlaceholder: "Inserisci una descrizione...",
        typeLabel: "Tipologia progetto",
        typePlaceHolder: "Inserisci tipologia...",
        priorityLabel: "Priorità",
        priorityPlaceHolder: "Inserisci priorità...",
        startDateLabel: "Data Inizio",
        startDatePlaceholder: "Inserisci una data di inizio...",
        dueDateLabel: "Data Scadenza",
        dueDatePlaceholder: "Inserisci una data di scadenza...",
        budgetPlaceholder: "Inserisci budget...",
        activityStatusLabel: "Status progetto",
        activityStatusPlaceholder: "Inserisci stato progetto...",
        addPhases: "Aggiungi step",
        warning: "Gli step su EDI non verranno modificati!",
        savingError: "Errore durante il salvataggio. Si prega di riprovare.",
        insertDatePlaceholder: "Seleziona una data...",
        phaseLabel: "Step",
        syncUsers: "Vai a Teams",
        status: {
            [ActivityStatus.Planned]: 'Pianificata',
            [ActivityStatus.OnGoing]: 'In Corso',
            [ActivityStatus.Blocked]: 'Bloccata',
            [ActivityStatus.Closed]: 'Chiusa',
        }
    },

    importKnowledgeLabDocument: {
        title: 'Importa un documento',
        confirmButton: 'Conferma',
        cancelButton: 'Cancella',
        selectFiles: 'Seleziona i file',
        import: 'Importa',
        search: 'Cerca...',
        overviewDisclaimer: 'Se abbandoni o chiudi la finestra il caricamento non andrà a buon fine',
        allowedFiles: 'Formati supportati: .pdf.',
        searchBox: 'Cerca Clausole/Contenuti',
        uploadFileAlreadyExists: "Un file con lo stesso nome è già presente nel sistema",
        uploadGenericError: 'Errore nel caricamento del file',
        uploadConflictError: 'File già esistente',
        uploadEntityTooLarge: 'Dimensioni del file eccessive',
        source: {
            sharepoint: 'Sharepoint',
            ediArchive: 'Edi Doc Lab',
            local: 'Computer Locale'
        },
        choiceInputSource: {
            sharepointSubtitle: 'Seleziona file da Sharepoint',
            localSubtitle: 'Seleziona file dal Computer Locale',
            ediSubtitle: 'Seleziona file da Edi Doc Lab'
        },
        importTitle: "Importa files",
        steps: {
            overviewSharepoint: {
                title: 'Sharepoint',
                import: 'Importa',
            },
            overviewEdi: {
                title: 'EDI DocLab',
                import: 'Importa',
            },
            sharepointFileSelector: {
                title: 'File Sharepoint Selezionati',
                selectMessage: 'Seleziona il documento da importare'
            },
            ediFileSelector: {
                title: 'Seleziona File',
                selectMessage: 'Seleziona il documento da importare'
            },
            localFileSelector: {
                title: 'File Utente',
                selectMessage: 'Seleziona il documento da importare'
            },
            uploadFiles: {
                title: 'Caricamento documenti',
                stopButton: 'Interrompi',
                picker: {
                    fileTypeNotAllowed: "File type not allowed"
                },
                uploadedText: 'files caricati',
                uploadingText: 'Sto caricando il file'
            },
            completed: {
                content: 'Stiamo analizzando i tuoi documenti. Presto potrai scoprirne il risultato!',
                closeButton: 'Ho capito'
            }
        }
    },

    knowledgeToolbar: {
        search: 'Cerca',
        favorites: 'Preferiti',
        documents: 'Documenti',
        actions: {
            importDocument: 'Importa documenti',
            delete: 'Elimina',
            view: 'Visualizza',
            download: 'Download',
            viewTags: 'Visualizza Tag',
            compareElements: 'Confronta elementi',
            compare: 'Confronta'
        }
    },

    knowledgeDsToolbar: {
        feedback: "Feedback",
        datasets: "Dataset",
        users: "Utenti",
        actions: {
            approveFeedback: "Approva",
            rejectFeedback: "Rifiuta",
            createDataset: "Crea Dataset",
            view: "Visualizza documento",
            details: "Visualizza testo elemento",
            documentText: "Visualizza dettaglio feedback e retagging",
            retag: "Visualizza testo documento",
            train: "Esegui retraining"
        },
    },

    knowledgeDsFeedback: {
        columns: {
            knowledgeLabName: "Nome Knowledge Lab",
            fileName: "Nome File",
            skillName: "Skill",
            metadataChanges: "Cronologia Metadato",
            createdBy: "Inserito da",
            updatedBy: "Aggiornato da",
            feedbackStatus: "Stato",
            createdOn: "Data feedback",
            updatedOn: "Ultima Modifica",

        },
        status: {
            pending: "Da Lavorare",
            approved: "Approvato",
            rejected: "Rifiutato",
            datasetCreated: "Dataset Creato"
        },
        filters: {
            filter: "Filtri Attivi",
            datasetName: "Nome Dataset",
            fileName: "Nome File",
            knowledgeLabName: "Knowledge Lab",
            skillName: "Skills AI",
            feedbackStatus: "Stato Feedback",
            creationDate: "Data creazione",
            resetFilters: "Ricomincia ricerca",
            from: "Da",
            to: "A",
            noDescription: "Nessuna descrizione disponibile"
        },
        elementText: "Testo Elemento",
        documentText: "Testo Documento"

    },

    feedbackConfirmModal: {
        cancel: "Chiudi",
        approve: "Approva",
        reject: "rifiuta",
        approveFeedback: "Approva Feedback",
        rejectFeedback: "Rifiuta Feedback",
        comment: "commenti/note"
    },


    dashboardManageUser: {
        addMembers: "Aggiungi",
        modalAddUser: "Aggiungi utente",        
        description1: "Inizia a digitare un nome da aggiungere.",
        description2: "Puoi aggiungere persone Eni (*@eni.com o *@external.eni.com) che sono già presenti sull'archivio.",
        minLengthAlert: "Inserisci almeno 3 caratteri",
        noResults: "Nessuna corrispondenza trovata",
        ownerBiz: "Owner Biz di riferimento",
        moduleLibrary: "Moduli/Librerie",
        noUser: "Nessun utente"
    },

    dashboardToolbar: {
        feedback: "Feedback",
        datasets: "Dataset",
        users: "Utenti",
        actions: {
            approveFeedback: "Approva",
            approveFeedbackForDataset: "Approva per dataset",
            rejectFeedback: "Rifiuta",
            createDataset: "Crea Dataset",
            view: "Visualizza documento",
            details: "Visualizza testo elemento",
            documentText: "Visualizza testo documento",
            train: "Esegui retraining"
        },
        metadataFromUser: "Metadato indicato da utente",
        previousMetadata: "Metadato precedente",
        metadataModifiedBy: "Metadato modificato da",
        dateEditMetadata: "Data modifica metadato",
        statusFeedback: "Stato feedback",
        lastUserEditFeedback: "Utente che ha effettuato l'ultima modifica al feedback",
        dateLastFeedbackEdit: "Data ultima modifica feedback",
        noteFeedback: "Note/Commenti",
        OriginalAiExtractedMetadata: "Metadato originale estratto da AI"
    },

    dashboardFeedback: {
        columns: {
            moduleInstanceName: "Modulo/Libreria",
            fileName: "Nome File",
            skillName: "Skill AI",
            metadataChanges: "Metadato precedente / nuovo",
            createdBy: "Inserito da",
            updatedBy: "Aggiornato da",
            extraProperty: "Proprietà Extra",
            feedbackStatus: "Stato",
            createdOn: "Data feedback",
            updatedOn: "Ultima Modifica",
        },
        status: {
            pending: "Da Lavorare",
            approved: "Approvato",
            rejected: "Rifiutato",
            readyForDataset: "Pronto per Dataset",
            datasetCreated: "Dataset Creato"
        },
        filters: {
            filter: "Filtri Attivi",
            fileName: "Nome File",
            property: "Attributo/Proprietà",
            extraPropertyMultiselectPlaceholder: 'Seleziona una Extra Property',
            moduleInstanceName: "Modulo/Libreria",
            skillName: "Skills AI",
            feedbackStatus: "Stato Feedback",
            creationDate: "Data creazione",
            resetFilters: "Ricomincia ricerca",
            attributeProperty: "Attributo/Proprietà",
            from: "Da",
            to: "A"
        },
        ner: {
            retagging: "Retagging",
            infoFeedback: "Info Feedback",
            feedbackDetails: "Dettgaglio Feedback",
            updatedOn: "Data modifica",
            updatedBy: "Modificato da",
            notChanged: "Metadata non modificati",
            changed: "Metadata modificati",
            fileName: "Nome file/documento",
            skill: "Nome skill",
            metadata: "Metadati",
            datonuovo: " dato nuovo",
            datoold: "Prevdato precedente"
        },
        elementText: "Testo Elemento",
        documentText: "Testo Documento",
        search : "Cerca",
        findResult : "Risultati trovati"
    },

    createDatasetModal: {
        confirmText: "Vuoi creare un Dataset?",
        createdText: "creato",
        continue: "Crea",
        close: "Chiudi"
    },

    knowledgeDsDatasets: {
        columns: {
            name: "Nome Dataset",
            skillName: "Skill",
            updatedBy: "Eseguito da",
            createdOn: "Data Creazione",
            updatedOn: "Data Ultimo Retraining",
        }
    },

    dashboardDatasets: {
        deleteTitle: "Conferma retraining",
        deleteSubTitle: "Sei sicuro di voler sottomettere questo dataset a retraining? ",
        deleteConfirm: "Conferma",
        deleteUndo: "Indietro",
        columns: {
            name: "Nome Dataset",
            skillName: "Skill",
            datasetStatus: "Stato",
            updatedBy: "Eseguito da",
            createdOn: "Data Creazione",
            updatedOn: "Data Ultimo Retraining",
        },
        status: {
            ready: "Pronto",
            sent: "Inviato",
            created: "Creato",
            training: "Allenamento",
            trained: "Allenato",
            deployed: "Rilasciato",
            error: "Errore"
        },
        filters: {
            filter: "Filtri Attivi",
            resetFilters: "Ricomincia Ricerca",
            skillName: "Skills AI",
            datasetName: "Nome Dataset",
            retrainingStatus: "Stato Retraining"
        }
    },

    knowledgeFavourites: {
        defaultTitle: "I miei preferiti",
        searchBox: "Cerca...",
        card: {
            views: "N° Consultazioni",
            actions: {
                remove: "Rimuovi dai preferiti",
                view: "Visualizza"
            }
        }
    },

    knowledgeDocuments: {
        title: 'Documenti',
        emptyDocumentList: 'Nessun documento',
        filters: {
            resetFilters: 'Ricomincia Ricerca',
            user: 'Utente',
            fileName: 'Nome file',
            documentStatus: 'Stato documento',
            indexed: 'Indicizzato',
            notIndexed: 'Da indicizzare',
            loadingDate: 'Data caricamento',
            from: 'Da',
            to: 'A'
        }
        , actions: {
            delete: 'Elimina',
            download: 'Scarica',
            view: 'Visualizza',
            viewTags: 'Visualizza Tag',
            addToFavourites: 'Aggiungi ai preferiti',
            removeFromFavourites: 'Rimuovi dai preferiti'
        },
        deleteModal: {
            title: 'Elimina Documenti',
            singleText: 'Sei sicuro di voler eliminare il file?',
            pluralText: 'Sei sicuro di voler eliminare gli elementi selezionati?',
            confirm: 'Sì, elimina',
            cancel: 'No, torna indietro',
            documents: 'documento/i',
        },
        delete: {
            error: 'Si è verificato un errore durante l\'eliminazione del documento',
            success: 'Documento eliminato con successo'
        },
        tagsPanel: {
            tagHeader: 'Tag',
            elementHeader: 'Clausole',
            nothingToShow: 'Non sono stati estratti elementi'
        },
        columns: {
            fileName: 'Nome',
            dataImport: 'Creato il',
            importedBy: 'Creato da',
            indexingStatus: 'Stato'
        },
        deleteElementModal: {
            title: 'Elimina',
            text: 'Sei sicuro di voler eliminare?',
            confirm: 'Sì, elimina',
            cancel: 'No, torna indietro',
        }
    },

    workflowApprovalStep: {
        title: 'Step',
        everyoneMustApproveApproverDesctioption: "Per questo step è prevista l'approvazione di tutti",
        onlyOneApproverDesctioption: "Per questo step è prevista almeno un'approvazione",
        onlyOneSignedApproverDesctioption: 'Per questo step è prevista la firma digitale',
        secondDesctioption: 'Lascia un commento',
        tooltipDescription: 'Il commento sarà visibile a tutti gli approvatori del workflow. In caso di rifiuto il commento è obbligatorio',
        approve: 'Approva',
        signedApprove: 'Approva con firma',
        reject: 'Rifiuta',
        rejectToolTip: 'Scrivi un commento per rifiutare',
        commentPlaceholder: 'Inserisci un commento',
        notPermissions: "Non hai il permesso di approvare",
        notFound: 'Step non trovato',
        unprocessable: 'La richiesta non è processabile',
    },

    workflowApprovalModal: {
        firsStepTitle: 'Firma il documento',
        userIdToolTipDescrioption: 'User ID',
        pinToolTipDescrioption: 'User ID',
        certificate: 'Seleziona un certificato',
        otpRequest: 'Richiesta OTP',
        insertOtp: 'Inserisci OTP',
        signatureInProgress: 'Firma in corso',
        signatureDescription: 'Stiamo apportando la firma sul documento. ',
        secondSignatureDescription: "Attendi qualche istante.",
        signatureSuccess: 'Firma apposta con successo',
        firsStepDescription: 'Non risulta alcun certificato di firma associato alla tua utenza',
        firsStepInfoDescription: 'Per maggiori informazioni',
        digitalSignature: 'firma digitale',
        insertPin: 'Inserisci Pin',
        insertUser: 'Inserisci User ID',
        notPermissions: "Non hai i permessi per accedere alla risorsa",
        notFound: 'Step non trovato',
        unprocessable: 'La richiesta non è processabile',
        signatureTooltip: 'Inserisci il codice OTP che ti è stato inviato via email',
        pinTooltip: 'Inserire un pin con 8 numeri',
        wrongRequest: 'Pin o certificato non sono validi',
        certificateNotFound: 'Certificato non trovato',
        editText: 'Le modifiche non saranno applicate ai progetti già inseriti'
    },

    createActivity: {
        low: "Bassa",
        medium: "Media",
        high: "Alta",
        title: 'Pianifica progetto',
        activityName: 'Nome progetto',
        activityNamePlaceholder: 'Inserisci Nome progetto',
        description: 'Descrizione',
        startDate: "Data Inizio",
        selectDay: 'Seleziona un giorno',
        endDate: 'Data Scadenza',
        selectBudget: 'Assegna un budget',
        priority: 'Priorità',
        toActivityLeader: 'Assegna ad un Project Leader',
        activityTemplate: 'Template progetto',
        messageForActivity: "Il project leader riceverà una notifica per l'assegnazione e l'automatica creazione di un Teams",
        analysisAlreadyExist: 'Nome progetto è già stato usato',
        endMessage: 'Il progetto è stato creato, è in corso la creazione del team',
        end: 'Ok',
        error: 'Si è verificato un errore durante la creazione del progetto. Attendi qualche minuto e riprova',
        dataPickerStrings: {
            "closeButtonAriaLabel": "Chiudi il datapicker",
            "dayMarkedAriaLabel": "segnato",
            "days": ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdi', 'Sabato', 'Domenica'],
            "goToToday": "Vai ad oggi",
            "invalidInputErrorMessage": "Formato non valido",
            "isRequiredErrorMessage": "Campo obbligatorio",
            "monthPickerHeaderAriaLabel": "{0}, cambia anno",
            "shortMonths": ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
            "months": ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
            "shortDays": ['D', 'L', 'M', 'M', 'G', 'V', 'S']
        },
        leadersPlaceholder: 'Inizia a digitare un nome',
        minLengthAlert: "Inserisci almeno 3 caratteri",
        noResults: "Nessuna corrispondenza trovata",
        creatingActivity: 'Creazione progetto in corso...'
    },

    createWorkflow: {
        checkDeleteWorflow: {
            title: "Vuoi interrompere la richiesta Workflow?",
            message: "Se chiudi la finestra senza completare la creazione del workflow i dettagli inseriti andranno persi. Vuoi interrompere la creazione di questo workflow?",
            confirm: "Sì, interrompi",
            undo: "No, torna indietro"
        },
        setProperties: {
            title: "Richiesta Workflow",
            nameLabel: "Nome Richiesta",
            namePlaceholder: "Inserisci nome...",
            descriptionLabel: "Descrizione",
            descriptionPlaceholder: "Inserisci descrizione...",
            activityLabel: "Seleziona progetto",
            activityPlaceholder: "Scegli progetto..."
        },
        addFile: {
            title: "Approvazione ",
            selectDoc: "Seleziona il documento da sottoporre a workflow",
            tooltipText: 'Attenzione! La selezione di un file con formato diverso da PDF non consente la scelta della regola "Approvazione con firma digitale"'
        },
        addSteps: {
            title: "Definizione Workflow ",
            namePlaceholder: "Inserisci nome...",
            nameLabel: "Nome dello step",
            dropdownLabel: "Seleziona la regola approvativa",
            dropdownPlaceholder: "Seleziona...",
            peoplePlaceholder: 'Inizia a digitare un nome',
            approvers: "Approvatori dello step",
            noResult: "Nessun risultato trovato",
            addStep: "Aggiungi step",
            minLengthAlert: "Inserisci almeno 3 caratteri",
            maxOneApprover: "Massimo un approvatore",
            errorType: "La regola selezionata è utilizzabile solo per file pdf"
        },
        summary: {
            title: 'Riepilogo'
        },
        creating: {
            title: 'Creazione Workflow'
        },
        workflowTypes: {
            onlyOneApprover: "Almeno uno approva",
            everyoneMustApprove: "Tutti approvano",
            onlyOneSignedApprover: "Approvazione con firma digitale"
        },
        errorRetrievingActivities: "Errore durante il recupero dei progetti.",
        noActivities: "Non ci sono progetti associati al portfolio corrente",
        columns: {
            name: "Nome",
            createdOn: "Creato il",
            createdBy: "Creato da"
        }
    },

    sharepointLink: {
        propertyName: 'Nome proprietà',
        link: 'Link',
        accessToList: 'Accedi alla lista',
        noProperties: 'Nessuna proprietà trovata',
        buttonLabel: 'Aggiorna le lov da fonti esterne'
    },

    folderTreeViewer: {
        searchFolders: 'Ricerca cartelle',
        name: 'Nome',
        createdOn: 'Creato Il',
        path: 'Path',
        createdBy: 'Creato Da',
        expandAll: 'Espandi tutto',
        expandAllBulk: 'Per grandi quantità di dati l\' "Espandi tutto" e\' disabilitato',
        collapseAll: 'Collassa tutto',
        search: 'Cerca'
    },

    profileManagement: {
        manageUser: 'Gestisci utenti',
        viewMember: 'Vista per membro',
        viewProfile: 'Vista per profilo',
        reportDonwload: 'Scarica report gestione utenti',
        search: 'Cerca membri',
        addMember: 'Aggiungi membri',
        add: 'Aggiungi',
        view: 'Visualizza membri',
        viewAndEdit: 'Visualizza e modifica membri',

        memberProfileView: {
            'profiles': 'Profili utente',
            'search': 'Cerca membri'
        },

        profileView: {
            members: 'Membri',
            view: 'Visualizza',
            viewAndModify: 'Visualizza e modifica',
            role: 'Ruolo',
            visibility: 'Visibilità cartelle Doc Lab',
            profileSearch: 'Cerca profili',
            createNewProfile: 'Crea nuovo profilo',
            duplicateProfile: 'Duplica profilo',
            downloadReportProfile: 'Scarica report gestione utenti',
            duplicateProfileAlreadyExistError: 'Esiste già una copia di questo profilo.',
            duplicateProfileError: 'Si è verificato un errore durante la duplicazione del profilo.',
            duplicateProfileTimeout: 'Duplicazione del profilo terminata per timeout',
            noProfile: 'Nessun profilo trovato',
            successMessage: 'Profilo inserito con successo!',
            cancelModalTitle: 'Cancella profilo',
            cancelModalText: "Stai cancellando il profilo. Non sarà più possibile associare i membri dell'archivio a tale profilo e sarà tolta l'associazione del profilo a tutti i membri attualmente associati. Vuoi proseguire?",
            editModal: {
                descriptionPlaceholder: 'Descrizione',
                descriptionTitle: 'Descrizione (Facoltativa)',
                nameTitle: 'Nome Profilo',
                namePlaceholder: 'Nome',
                title: 'Modifica Profilo',
                nameNotAvailable: 'Nome non disponibile'
            },
            newProfile: {
                insertProfileName: 'Inserisci il nome del Profilo che vuoi creare',
                selectProfileRole: 'Seleziona Ruolo',
                createNewProfile: 'Crea nuovo Profilo',
                folderVisibility: 'Visibilità delle cartelle',
                folderVisibilityDescription: 'Selezionare le singole cartelle che vuoi rendere visibili agli utenti associati a questo Profilo, oppure clicca sul radio button per selezionare l\'intero contenuto di ogni singola cartella.',
                userVisibility: 'Associa membri al profilo',
                userVisibilityDescription: 'Inizia a digitare un nome per aggiungere un nuovo membro abilitato alla visualizzazione del Doc Lab.',
                assignRole: 'Associa un ruolo ad un profilo',
                nameLabel: 'Nome',
                descriptionLabel: 'Descrizione',
                next: 'Avanti',
                previous: 'Indietro',
                cancel: 'Annulla',
                save: 'Salva',
                confirm: 'Conferma'
            },
            editFolderModal: {
                primaryTitle: 'Visualizza e modifica Cartelle',
                cancel: 'Annulla',
                save: 'Salva',
                secondaryTitle: 'Visibilità delle cartelle',
                description: 'Seleziona le singole cartelle che vuoi rendere visibili agli utenti associati a questo Profilo, oppure clicca sul radio button per selezionare l\'intero contenuto di ogni singola cartella.',
                errorProfileNotFound: 'Profilo non trovato',
                errorParentFolderNotFound: 'Cartella padre non trovata'
            }
        }
    },

    subParagraphModal: {
        subParagraph: 'sottoparagrafo',
        title: "Sottoparagrafo"
    },

    downloadWfDetailsModal: {
        downloadWfDetails: 'Scarica i dettagli del WorkFlow',
        title: 'Vuoi scaricare i dati di questo WorkFlow?',
        getwfError: 'Error: Non è stato possibile recuperare i dati del WorkFlow',
        downloadError: 'Error: Non è stato possibile generare il file richiesto'
    },

    filesApprovalDocumentModal: {
        titleApproveDocument: 'Approva Documento',
        titleApproveDocuments: 'Approva Documenti',
        titleRejectDocument: 'Rigetta Documento',
        titleRejectDocuments: 'Rigetta Documenti',
        titleResubmitApprovalDocument: 'Porta in stato Da Approvare il Documento',
        titleResubmitApprovalDocuments: 'Porta in stato Da Approvare i Documenti',
        rejectionNoteTitle: 'Nota di rigetto',
        emailsRecipientsTitle: 'Email destinatari',
        bodyTextResubmitApprovalDocument: "Sei sicuro di voler portare lo stato di questo documento a 'Da Approvare'?",
        bodyTextResubmitApprovalDocuments: "Sei sicuro di voler portare lo stato di questi documenti a 'Da Approvare'?",
        bodyTextApproveDocument: 'Sei sicuro di voler approvare questo documento?',
        bodyTextApproveDocuments: 'Sei sicuro di voler approvare questi documenti?',
        bodyTextRejectDocument: 'Sei sicuro di voler rifiutare questo documento?',
        bodyTextRejectDocuments: 'Sei sicuro di voler rifiutare questi documenti?',
        errorLabel: 'Si è verificato un errore, riprova',
        placeholderEmailsRecipients: 'email1@eni.com, email2@eni.com'
    },

    workflowDetailsModal: {
        viewWfDetails: 'Visualizza dettagli WF',
        title: 'Processo approvativo',
        yes: 'Si',
        no: 'No',
        wfLabName: 'Nome WorkFlow Lab: ',
        wfName: 'Nome WorkFlow: ',
        attachedDocuments: 'Documenti allegati: ',
        column: {
            step: 'Step',
            iter: 'Iter',
            status: 'Stato',
            rules: 'Regole',
            actionHistory: 'Storico Azioni',
            comments: 'Commenti',
            approvers: 'Approvatori',
            attachedDocuments: 'Documenti allegati'
        },
        userActionWithDate: 'il',
        userCommented: ' ha commentato: ',
        stepStatus: {
            title: 'STEP #{{order}}:',
            userAction: '',
            status: {
                [WorkFlowStepRelativeStatus.Idle]: '',
                [WorkFlowStepRelativeStatus.ToBeApproved]: 'In Corso',
                [WorkFlowStepRelativeStatus.Approved]: 'Approvato',
                [WorkFlowStepRelativeStatus.Refused]: 'Rifiutato',
                [WorkFlowStepRelativeStatus.ToMe]: 'In Corso',
            },
            approverStatus: {
                [WorkFlowApproverStatus.ToBeApproved]: 'In Corso',
                [WorkFlowApproverStatus.Approved]: 'Approvato',
                [WorkFlowApproverStatus.Refused]: 'Rifiutato',
            },
            type: {
                [WorkFlowStepTypes.OnlyOneApprover]: 'Solo un approvatore',
                [WorkFlowStepTypes.OnlyOneSignedApprover]: 'Solo un approvatore con firma',
                [WorkFlowStepTypes.EveryoneMustApprove]: 'Tutti devono approvare',
            }
        },
        error: {
            notFound: 'Informazioni del Workflow non trovate',
            notPermissions: 'Non hai i permessi per questo Workflow',
        }
    },

    // END ACTIONS
    common: {
        startNewSearchToSeeResults: "Seleziona un filtro o inserisci del testo per visualizzare i risultati.",
        nameNotAvailable: "Nome non disponibile",
        planActivity: 'Pianifica progetto',
        columns: {
            date: 'Data-Periodo del report',
            source: 'Fonte del report',
            country: 'Paesi'
        },
        commands: {
            open: 'Apri',
            openInBrowser: 'Apri nel browser',
            translateDocument: 'Traduci documento',
            createLink: 'Copia collegamento',
            copyDocumentId: 'Copia ID Documento',
            viewTag: "Visualizza Tag",
            download: 'Scarica',
            downloadFile: 'Scarica documento',
            addToFavourites: 'Aggiungi preferiti',
            removeToFavourites: 'Rimuovi dai preferiti',
            viewFile: 'Visualizza documento',
            delete: 'Elimina',
            edit: 'Modifica',
            move: 'Sposta',
            copy: 'Copia',
            copied: 'Copiato',
            addToCompare: 'Aggiungi per confrontare',
            viewSubParagraph: 'Visualizza sottoparagrafo'
        },
        statusLabel: {
            onGoing: "On Going",
            completed: "Completed",
            error: "Error",
            completedWithError: "Completed with Error"
        },
        archiveLinkForbiddenOperation: "Link collegamento",
        retryFolder: 'Torna alla cartella principale',
        archiveLinkForbiddenMessage: "Non hai i permessi per accedere alla risorsa",
        archiveForbidden: "Non hai accesso all'archivio",
        genericErrorApi: "Si è verificato un errore durante l'operazione",
        notFound: "Non è stato possibile trovare la risorsa... Potrebbe essere stata rimossa o potresti non avere più accesso",
        fieldRequired: 'Campo obbligatorio',
        nextButton: 'Avanti',
        previousButton: 'Indietro',
        leave: 'Chiudi',
        abort: 'Annulla',
        save: 'Salva',
        done: 'Fatto',
        dialogButtonCancel: 'No, torna indietro',
        dialogButtonConfirmClose: 'Si, chiudi',
        dialogButtonConfirm: 'Si, annulla',
        dialogButtonConfirmContinue: 'Si, continua',
        confirm: 'Conferma',
        confirmSave: 'Sei sicuro di voler salvare i tag attuali?',
        dialogButtonConfirmDeleting: 'Si, cancella',
        name: 'Nome',
        description: 'Descrizione',
        add: 'Aggiungi',
        field: 'Campo',
        niceWork: 'Ottimo lavoro!',
        tryAgain: 'Riprova',
        create: 'Crea',
        continue: 'Continua',
        cancel: 'Cancella',
        update: 'Aggiorna',
        preposition: ' di ',
        authErrorTitle: 'Errore di autenticazione',
        loading: 'Operazione in corso...',
        operationFailed: 'Operazione fallita',
        authErrorMessage: "Controllare che siano stati dati al browser i permessi per l'apertura dei popup e quindi riprovare.",
        retry: 'Riprova',
        close: 'Chiudi',
        genericErrorCheckName: "Si è verificato un errore durante la verifica del nome",
        emptyList: 'La lista è vuota',
        emptyFolder: 'La cartella è vuota',
        noTags: 'La sezione tag non è disponibile per i file con estensione: ',
        noPreview: 'La preview non è disponibile per i file con estensione: ',
        noMetadata: 'Questo archivio non ha metadati.',
        excelCel: 'Errore nella cella Excel',
        isInvalid: '',
        excelFormatError: 'La formattazione del file Excel non è valida',
        EmptyFile: ': l\'excel è vuoto',
        ColumnsPosition: ': devono essere compilate esattamente le prime due colonne (A B)',
        ColumnsCount: `: il numero di colonne dell'excel è superiore al numero massimo di colonne accettate (${GlossaryExcelDataValidationErrors.MAX_COUNT_COLUMNS} colonne)`,
        CellRequired: ': la cella è obbligatoria',
        RegexNotMatched: ': formattazione sbagliata',
        InvalidMinLenght: `: la cella deve avere almeno ${GlossaryExcelDataValidationErrors.MIN_LENGTH_CELL} caratteri`,
        fileNotFound: 'il file non è stato trovato',
        maxFilesSelected: `Impossibile selezionare tutti i file. La lista contiene più di ${Constants.PAGE_SIZE_START_ANALYSIS} file.`,
        zoomIn: 'Ingrandisci',
        zoomOut: 'Diminuisci',
        reset: 'Riposiziona',
        manageUsers: "Gestisci Utenti",
        viewUsers: "Visualizza Utenti",
        emptySearch: 'Nessun risultato trovato',
        emptyFavourites: 'Non hai ancora aggiunto nessun elemento ai preferiti.',
        role: 'Ruolo',
        activityVisibility: 'Visiblità Progetto',
        error: 'Errore',
        apply: 'Applica',
        search: 'Cerca',
        selectAll: 'Seleziona Tutto',
        deselectAll: 'Deseleziona Tutto',
        noResults: 'Nessun risultato',
        gotIt: 'Ho capito',
        compare: 'Confronta',
        requiredField: "Campo obbligatorio",
        remove: 'Rimuovi'
    }
}