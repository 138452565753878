import { styled } from "@fluentui/react";
import { PhaseFormItemBase } from "./phaseFormItem.base";
import { getStyles } from "./phaseFormItem.styles";
import { IPhaseFormItemProps, IPhaseFormItemPropsStyles, IPhaseFormItemStyles } from "./phaseFormItem.types";


const PhaseFormItem = styled<IPhaseFormItemProps, IPhaseFormItemPropsStyles, IPhaseFormItemStyles>(
    PhaseFormItemBase,
    getStyles,
    undefined,
    { scope: 'PhaseFormItemBase' }
);

export default PhaseFormItem;