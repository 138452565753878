import { styled } from "@fluentui/react";
import { getStyles } from "./createTableOfContentModal.styles";
import { ICreateTableOfContentModalPropsStyles, ICreateTableOfContentModalStyles, ICreateTableOfContentModalProps } from "./createTableOfContentModal.types";
import { CreateTableOfContentModalBase } from "./createTableOfContentModal.base";

const CreateTableOfContentModal = styled<ICreateTableOfContentModalProps, ICreateTableOfContentModalPropsStyles, ICreateTableOfContentModalStyles>(
    CreateTableOfContentModalBase,
    getStyles,
    undefined,
    { scope: 'CreateTableOfContentModal' }
);

export default CreateTableOfContentModal;