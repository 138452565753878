import { styled } from "@fluentui/react";
import { CreateActivityBase } from "./createActivity.base";
import { getStyles } from "./createActivity.styles";
import { ICreateActivityProps, ICreateActivityPropsStyles, ICreateActivityStyles } from "./createActivity.types";

const CreateActivity = styled<ICreateActivityProps, ICreateActivityPropsStyles, ICreateActivityStyles>(
    CreateActivityBase,
    getStyles,
    undefined,
    { scope: 'CreateActivity' }
);

export default CreateActivity;