import { styled } from "@fluentui/react";
import { getStyles } from "./downloadNotification.styles";
import { IDownloadNotificationPropsStyles, IDownloadNotificationStyles, IDownloadNotificationProps } from "./downloadNotification.types";
import { DownloadNotificationBase } from "./downloadNotification.base";

const DownloadNotification = styled<IDownloadNotificationProps, IDownloadNotificationPropsStyles, IDownloadNotificationStyles>(
    DownloadNotificationBase,
    getStyles,
    undefined,
    { scope: 'DownloadNotification' }
);

export default DownloadNotification;