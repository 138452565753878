import { IStyleFunction } from "@fluentui/merge-styles";
import { ICreateActivityPropsStyles, ICreateActivityStyles } from "./createActivity.types";

export const CreateActivityGlobalClassNames = {
    root: 'edi-modal-create-activity-root',
    container: 'edi-modal-create-activity-container',
    multiFormItem: 'edi-modal-create-activity-multi-form'  
}

export const getStyles: IStyleFunction<ICreateActivityPropsStyles, ICreateActivityStyles> = (props: ICreateActivityPropsStyles): ICreateActivityStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    
    return {
        root: [
            CreateActivityGlobalClassNames.root,
            {

            }
        ],
        container: [
            CreateActivityGlobalClassNames.container,
            {
                height: '100%',
            }
        ],
        wellDoneImage: {
            flexBasis: '50%',
            flexShrink: 0
        },
        secondFooterContainer: { 
            display: 'flex',
            alignItems: 'center',
            height: '100%'
        },        
        footer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center'
        },
        multiFormItem: [
            CreateActivityGlobalClassNames.multiFormItem,
            {
                display: 'flex',
                justifyContent: 'space-between'
            }
        ],
        iconWrapper:[],
        wrapper: [],
        requiredTextLeft: {
            display:'flex', 
            alignItems:'center',
            gap: 5,
            justifyContent: "flex-start",
            bottom: 0,
            left:40,
            color: palette.redDark,
            background: palette.white
        }
    };
}